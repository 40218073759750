import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import {
    sendOtp,
    verifyOtp,
    isLoggedIn,
    logOut,
    signUp,
    login,
    forgotPassword,
    checkUniqueNumber,
    checkUniqueEmail,
    getToken,
    googleSSOLogin,
} from './login.service'

const initialState = {
    status: 'loading',
    userProfile: {},
    moduleContainers: [],
    timer: {
        seconds: '00',
        minutes: '00',
        hours: '00',
    },
    socketClient: {},
    token: null,
    emailDisable: false,
}

export const sendOtpLogin = createAsyncThunk(
    'loginSlice/sendOtp',
    async (payload = {}) => {
        const response = await sendOtp(payload)
        return response
    }
)

export const verifyOtpLogin = createAsyncThunk(
    'loginSlice/verifyOtp',
    async (payload) => {
        const response = await verifyOtp(payload)
        return response
    }
)

export const isLoggedInLogin = createAsyncThunk(
    'loginSlice/isLoggedIn',
    async (payload = {}) => {
        const response = await isLoggedIn(payload)
        if (response?.data?.success) {
            localStorage.setItem(
                'currentProject',
                JSON.stringify({
                    id: response?.data?.data.activeProject?._id,
                    name: response?.data?.data?.activeProject?.name,
                })
            )
            return response
        }
        return response
    }
)

export const tokenGet = createAsyncThunk(
    'loginSlice/getToken',
    async (payload = {}) => {
        const response = await getToken(payload)
        return response
    }
)

export const signOut = createAsyncThunk(
    'loginSlice/logOut',
    async (payload = {}) => {
        const response = await logOut(payload)
        return response
    }
)

export const signUpForm = createAsyncThunk(
    'loginSlice/signUp',
    async (payload = {}) => {
        const response = await signUp(payload)
        return response
    }
)

export const loginGoogleSSO = createAsyncThunk(
    'loginSlice/googleSSOLogin',
    async (payload = {}) => {
        const response = await googleSSOLogin(payload)
        return response
    }
)

export const loginForm = createAsyncThunk(
    'loginSlice/login',
    async (payload = {}) => {
        const response = await login(payload)
        return response
    }
)

export const passwordForgot = createAsyncThunk(
    'loginSlice/forgotPassword',
    async (payload = {}) => {
        const response = await forgotPassword(payload)
        return response
    }
)

export const numberCheck = createAsyncThunk(
    'loginSlice/checkUniqueNumber',
    async (payload = {}) => {
        const response = await checkUniqueNumber(payload)
        return response
    }
)

export const emailCheck = createAsyncThunk(
    'loginSlice/checkUniqueEmail',
    async (payload = {}) => {
        const response = await checkUniqueEmail(payload)
        return response
    }
)

export const loginSlice = createSlice({
    name: 'loginSlice',
    initialState,
    reducers: {
        setUserProfile: (state, action) => {
            state.userProfile = action.payload
        },
        setEmailDisable: (state, action) => {
            state.emailDisable = action.payload
        },
        setSocketClient: (state, action) => {
            state.socketClient = action.payload.socket
            state.token = action.payload.token
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(isLoggedInLogin.pending, (state, action) => {
                // state.status = 'loading'
            })
            .addCase(isLoggedInLogin.fulfilled, (state, action) => {
                state.status = 'loaded'
                state.moduleContainers =
                    action.payload?.data?.data?.role?.containers
                state.userProfile = action.payload.data.data
            })
    },
})

export default loginSlice.reducer

export const loginState = (state) => {
    const { loginSlice } = state
    const {
        status,
        userProfile,
        timer,
        moduleContainers,
        socketClient,
        token,
        emailDisable,
    } = loginSlice
    return {
        status,
        userProfile,
        timer,
        moduleContainers,
        socketClient,
        token,
        emailDisable,
    }
}

export const { setUserProfile, setSocketClient, setEmailDisable } =
    loginSlice.actions
