import React from 'react'

function UploadFileIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 28 28"
        >
            <path
                stroke="#121212"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M10.5 19.833v-7m0 0l-2.333 2.334m2.333-2.334l2.333 2.334M25.667 11.667V17.5c0 5.833-2.334 8.167-8.167 8.167h-7c-5.833 0-8.167-2.334-8.167-8.167v-7c0-5.833 2.334-8.167 8.167-8.167h5.833"
            ></path>
            <path
                stroke="#121212"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M25.667 11.667H21c-3.5 0-4.667-1.167-4.667-4.667V2.333l9.334 9.334z"
            ></path>
        </svg>
    )
}

export default UploadFileIcon
