import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

// antd
import { Col, Row } from 'antd'

// slices
import { loginState } from '../login/login.slice'

// home components
import HomeLayout from './homeLayout'

// routes
import { PRIVATE_ROUTES } from '../../routes'

// constants
import { MEMBER } from '../../constants/roles'

function Home() {
    const navigate = useNavigate()

    const { userProfile } = useSelector(loginState)

    useEffect(() => {
        if (
            userProfile?.role?.roleName === MEMBER &&
            userProfile?.activeProject === null
        ) {
            navigate(PRIVATE_ROUTES.profile)
        }
        if (
            userProfile?.role?.roleName !== MEMBER &&
            userProfile?.activeProject === null
        ) {
            navigate(PRIVATE_ROUTES.projects.root)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <div
                className={'container'}
                style={{
                    zIndex: 10,
                    minHeight: '100vh',
                    padding: '35px',
                }}
            >
                <Row gutter={16}>
                    <Col xs={14} sm={14} md={12} lg={12} xl={12} xxl={14}>
                        <HomeLayout />
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default Home
