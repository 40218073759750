import React from 'react'

function NoTalentFoundIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="248"
            height="277"
            fill="none"
            viewBox="0 0 248 277"
        >
            <g clipPath="url(#clip0_4980_10173)">
                <path
                    fill="#E6E6E6"
                    d="M236.333 261.091a9.54 9.54 0 01-9.214-1.624c-3.227-2.711-4.24-7.176-5.062-11.311l-2.435-12.23 5.098 3.513c3.666 2.526 7.415 5.133 9.954 8.792 2.538 3.659 3.646 8.654 1.607 12.613"
                ></path>
                <path
                    fill="#F2F2F2"
                    d="M235.898 275.895c.516-3.761 1.046-7.571.684-11.372-.321-3.376-1.35-6.673-3.446-9.377a15.576 15.576 0 00-4-3.627c-.4-.252-.768.382-.37.633a14.823 14.823 0 015.862 7.078c1.277 3.248 1.482 6.789 1.26 10.239-.133 2.087-.415 4.159-.699 6.23a.382.382 0 00.038.277.383.383 0 00.218.175.37.37 0 00.451-.257l.002.001z"
                ></path>
                <path
                    fill="#fff"
                    d="M217.421 213.427H4.136V0H217.42v213.427z"
                ></path>
                <path
                    fill="#E5E5E5"
                    d="M217.421 213.427H4.136V0H217.42v213.427zM7.287 210.273h206.982V3.153H7.287v207.12z"
                ></path>
                <path
                    fill="#E5E5E5"
                    d="M179.637 33.39H87.275v2.88h92.362v-2.88zM179.637 40.875H87.275v2.879h92.362v-2.879zM179.637 48.363H87.275v2.88h92.362v-2.88zM179.637 97.668H87.275v2.879h92.362v-2.879zM179.637 105.152H87.275v2.879h92.362v-2.879zM179.637 112.637H87.275v2.879h92.362v-2.879zM179.637 161.941H87.275v2.879h92.362v-2.879zM179.637 169.43H87.275v2.879h92.362v-2.879zM179.637 176.914H87.275v2.879h92.362v-2.879z"
                ></path>
                <path
                    fill="#3F3D56"
                    d="M50.301 56.33a30.847 30.847 0 00-4.922-2.073l-.217-2.396-8.779-.947-1.115 3.063-3.15 1.183c-.114.043-.221.099-.32.166h-.004a1.564 1.564 0 00-.643 1.667l.167.683c.24.143.486.28.732.41l.157.08c.36.186.726.363 1.102.526.077.034.16.067.237.1l.016.007c.177.073.353.143.533.21a18.548 18.548 0 008.34 1.123 18.478 18.478 0 003.876-.783l.01-.003a18.185 18.185 0 002.328-.924c.003-.003.01-.003.014-.006.17-.08.333-.167.499-.25l.08-.04c.133-.07.266-.14.4-.213.333-.184.659-.377.982-.584.09-.053.176-.11.267-.17a9.51 9.51 0 00.376-.26c-.31-.2-.633-.39-.966-.57z"
                ></path>
                <path
                    fill="#3F3D56"
                    d="M33.825 57.807l-.55-2.483H31.84a.33.33 0 00-.043.004h-.003a3.11 3.11 0 00-1.446.5 3.2 3.2 0 00-.772.693c.426.323.869.623 1.325.91l.02.01c.13.08.263.16.396.236.24.143.487.28.733.41l.157.08c.36.186.726.363 1.102.526.077.034.16.067.236.1.007.004.01.004.017.007.177.073.353.143.533.21l-.27-1.203z"
                ></path>
                <path
                    fill="#FFB8B8"
                    d="M40.428 49.4a6.325 6.325 0 006.323-6.327 6.325 6.325 0 00-6.323-6.327 6.325 6.325 0 00-6.322 6.327 6.325 6.325 0 006.322 6.327z"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M36.183 39.989c.655.775 1.762.994 2.784 1.084a26.205 26.205 0 007.12-.582c.145 1.403-.25 2.917.577 4.072.387-1.398.612-2.836.671-4.286a4.104 4.104 0 00-.237-1.835 1.688 1.688 0 00-1.437-1.06 3.6 3.6 0 011.606-.688l-2.007-1.01.515-.529-3.634-.222 1.052-.67a22.81 22.81 0 00-4.782-.15c-.745 0-1.477.203-2.115.588-.6.43-.947 1.283-.574 1.913a2.775 2.775 0 00-2.116 1.68 4.488 4.488 0 00-.16 2.156 15.29 15.29 0 00.8 3.39l1.937-3.851z"
                ></path>
                <path
                    fill="#3F3D56"
                    d="M58.207 36.212a18.567 18.567 0 00-10.871-11.228 18.542 18.542 0 00-21.603 6.34 18.572 18.572 0 00.929 23.148l.037.04a17.995 17.995 0 001.775 1.776c.253.227.512.443.782.65.107.083.21.166.32.246.426.324.87.623 1.325.91l.02.01c.13.08.264.16.397.237.24.143.486.28.732.41l.157.08c.36.186.726.363 1.102.526.077.034.16.067.237.1l.016.007c.177.073.353.143.533.21a18.553 18.553 0 008.34 1.123 18.477 18.477 0 003.876-.783c.003 0 .007-.004.01-.004a18.2 18.2 0 002.328-.923c.003-.003.01-.003.014-.006.17-.08.333-.167.499-.25l.08-.04c.133-.07.266-.14.4-.214.333-.183.659-.376.982-.583.09-.053.176-.11.267-.17.126-.083.253-.17.376-.26.163-.113.326-.23.486-.35.05-.036.103-.073.153-.113a18.57 18.57 0 006.301-20.889zm-6.914 20.08c-.11.086-.223.17-.336.246-.11.083-.22.163-.333.236-.097.07-.193.137-.293.2l-.03.02c-.073.05-.147.097-.22.144-.276.173-.556.34-.84.5l-.09.05c-.126.07-.252.136-.379.203-.016.01-.036.017-.053.026l-.02.01c-.16.084-.323.164-.486.24-.63.304-1.278.567-1.942.787-.087.03-.173.06-.26.087a17.459 17.459 0 01-5.325.823 17.292 17.292 0 01-4.526-.593c-.757-.198-1.5-.45-2.221-.754l-.114-.046c-.04-.017-.076-.03-.113-.047a1.826 1.826 0 01-.116-.053 15.743 15.743 0 01-.926-.444l-.147-.076a16.563 16.563 0 01-1.395-.82l-.077-.05c-.236-.157-.473-.32-.702-.49-.157-.117-.313-.233-.467-.353-.256-.193-.503-.4-.742-.613-.127-.107-.25-.224-.37-.334-.472-.436-.92-.899-1.339-1.386-.14-.16-.276-.32-.406-.486a17.556 17.556 0 015.78-26.68 17.528 17.528 0 0119.408 2.45 17.55 17.55 0 015.067 18.907 17.548 17.548 0 01-5.987 8.295z"
                ></path>
                <path
                    fill="#fff"
                    d="M58.207 100.49a18.566 18.566 0 00-10.871-11.229 18.542 18.542 0 00-21.603 6.34 18.568 18.568 0 00-3.088 15.327 18.574 18.574 0 004.017 7.821l.036.04a18.012 18.012 0 001.776 1.776c.253.227.512.443.782.65.527.415 1.076.802 1.645 1.156l.02.01c.37.23.746.444 1.13.647l.156.08c.36.187.726.363 1.102.527l.237.099c.006.004.01.004.016.007.76.317 1.542.58 2.338.79a18.543 18.543 0 006.534.543 18.438 18.438 0 003.877-.783l.01-.003a18.334 18.334 0 002.328-.923c.003-.004.01-.004.013-.007.17-.08.333-.167.5-.25l.08-.04c.133-.07.266-.14.4-.213.332-.184.659-.377.982-.584a17.24 17.24 0 001.129-.779c.05-.037.103-.074.153-.114a18.57 18.57 0 006.301-20.888z"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M46.808 114.244a2.78 2.78 0 002.78-2.782 2.78 2.78 0 10-2.78 2.782z"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M47.583 108.755a2.777 2.777 0 012.388 1.356 2.787 2.787 0 01.06 2.747 2.788 2.788 0 00.167-3.241 2.779 2.779 0 00-4.785.184 2.778 2.778 0 012.17-1.046z"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M41.612 111.456c4.511 0 8.169-3.659 8.169-8.173s-3.657-8.174-8.169-8.174c-4.51 0-8.168 3.66-8.168 8.174 0 4.514 3.657 8.173 8.168 8.173z"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M35.202 98.21a8.164 8.164 0 0111.32-.857c-.067-.064-.134-.128-.203-.19a8.165 8.165 0 00-11.534.635 8.178 8.178 0 00.634 11.542l.047.041c.069.062.14.12.211.179a8.175 8.175 0 01-.475-11.35z"
                ></path>
                <path
                    fill="#A0616A"
                    d="M41.543 111.388a5.262 5.262 0 005.26-5.264 5.263 5.263 0 00-5.26-5.265 5.263 5.263 0 00-5.26 5.265 5.262 5.262 0 005.26 5.264z"
                ></path>
                <path
                    fill="#4F46E5"
                    d="M50.957 119.839v1.766c-.323.206-.65.4-.983.583-.133.073-.266.143-.4.213l-.08.04c-.166.084-.329.17-.499.25-.003.004-.01.004-.013.007-.754.361-1.532.669-2.328.923l-.01.003c-1.26.399-2.56.662-3.877.784a18.544 18.544 0 01-6.534-.544 17.853 17.853 0 01-2.338-.79l-.017-.006c-.076-.034-.16-.067-.236-.1-.376-.163-.743-.34-1.102-.527l-.157-.08c-.383-.203-.76-.416-1.129-.646l-.02-.01a18.15 18.15 0 01-1.645-1.157c-.27-.206-.53-.423-.783-.649a18.025 18.025 0 01-1.775-1.777c.058-.347.143-.689.254-1.023l.003-.003a1.49 1.49 0 01.07-.167 1.37 1.37 0 01.063-.14c.596-1.116 1.675-1.393 3.014-1.453.756-.033 1.592.004 2.475-.006.466-.004.949-.02 1.432-.064 3.047-.276 2.494-2.076 2.63-3.046.137-.94.983-.126 1.04-.073l.003.003a10.741 10.741 0 005.528.97c.327-.04.653-.063.98-.073.859-.01.672.236.376.44a3.834 3.834 0 01-.45.253s-.136.833-.276 2.079c-.133 1.193 2.151 1.753 2.341 1.797a.03.03 0 00.013.003 4.59 4.59 0 012.535.067h.003a2.994 2.994 0 011.892 2.153z"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M33.929 121.98c0 .247-.007.483-.017.706-.003.134-.01.264-.017.387-.006-.003-.01-.003-.016-.007-.077-.033-.16-.066-.237-.1-.376-.163-.742-.34-1.102-.526l-.157-.08c-.383-.203-.759-.417-1.129-.647.01-.116.027-.233.044-.35a10.73 10.73 0 00-.863-6.025v-.003c-.066-.134-.106-.207-.106-.207s.969-.833 2.35 0c.089.052.167.12.23.2.833.983 1.06 4.289 1.02 6.652zM49.052 121.529c-.014.374-.033.764-.057 1.16v.003c-.003.004-.01.004-.013.007a18.18 18.18 0 01-2.328.923c-.003 0-.007.004-.01.004l-.037-.937-.003-.123-.176-4.559a.765.765 0 01.762-.8h1.689c.083 0 .143.17.183.477v.003a28.89 28.89 0 01-.01 3.842zM37.044 99.543v5.265h1.218l1.55-1.662-.207 1.662h5.385l-.333-1.662.666 1.662h.859v-5.265h-9.138z"
                ></path>
                <path
                    fill="#A0616A"
                    d="M36.213 105.778c.23 0 .415-.342.415-.762 0-.421-.185-.762-.415-.762-.23 0-.415.341-.415.762 0 .42.186.762.415.762zM46.873 105.778c.23 0 .416-.342.416-.762 0-.421-.186-.762-.416-.762-.23 0-.415.341-.415.762 0 .42.186.762.415.762z"
                ></path>
                <path
                    fill="#4F46E5"
                    d="M52.086 120.827c-.28.21-.57.416-.863.61a18.118 18.118 0 01-1.249.753c-.133.073-.266.143-.4.213v-3.673a96.602 96.602 0 002.512 2.097z"
                ></path>
                <path
                    fill="#3F3D56"
                    d="M58.207 100.49a18.566 18.566 0 00-10.871-11.229 18.542 18.542 0 00-21.603 6.34 18.568 18.568 0 00-3.088 15.327 18.574 18.574 0 004.017 7.821l.036.04a18.012 18.012 0 001.776 1.776c.253.227.512.443.782.65.527.415 1.076.802 1.645 1.156l.02.01c.37.23.746.444 1.13.647l.156.08c.36.187.726.363 1.102.527l.237.099c.006.004.01.004.016.007.76.317 1.542.58 2.338.79a18.543 18.543 0 006.534.543 18.438 18.438 0 003.877-.783l.01-.003a18.334 18.334 0 002.328-.923c.003-.004.01-.004.013-.007.17-.08.333-.167.5-.25l.08-.04c.133-.07.266-.14.4-.213.332-.184.659-.377.982-.584a17.24 17.24 0 001.129-.779c.05-.037.103-.074.153-.114a18.57 18.57 0 006.301-20.888zm-6.914 20.079c-.11.086-.223.169-.336.246-.11.083-.22.163-.333.237-.097.07-.193.136-.293.2-.084.056-.167.11-.25.163-.276.173-.556.34-.84.5l-.09.05c-.126.07-.252.136-.379.203-.017.01-.036.017-.053.027l-.02.01c-.16.083-.323.163-.486.24-.63.303-1.279.566-1.942.786a17.407 17.407 0 01-5.585.91 17.293 17.293 0 01-4.526-.593 16.55 16.55 0 01-2.221-.753c-.077-.03-.154-.064-.227-.094a16.37 16.37 0 01-1.042-.496l-.147-.077a16.43 16.43 0 01-1.472-.87c-.4-.263-.79-.546-1.169-.843-.256-.193-.503-.4-.742-.613-.127-.107-.25-.223-.37-.333-.472-.437-.92-.899-1.339-1.387a11.772 11.772 0 01-.49-.589 17.559 17.559 0 015.865-26.578 17.527 17.527 0 0119.407 2.452 17.55 17.55 0 015.067 18.907 17.554 17.554 0 01-5.987 8.295z"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M40.79 179.212a7.272 7.272 0 007.27-7.274 7.272 7.272 0 00-7.27-7.274 7.272 7.272 0 00-7.268 7.274 7.272 7.272 0 007.269 7.274z"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M35.117 168.017c1.175-1.175 1.413-2.843.532-3.724-.881-.882-2.548-.644-3.722.532-1.175 1.175-1.413 2.843-.532 3.724.881.882 2.547.644 3.722-.532zM48.367 165.707c.49-1.147-.352-2.606-1.88-3.259s-3.163-.252-3.652.894c-.49 1.147.352 2.605 1.88 3.258 1.527.653 3.163.253 3.652-.893z"
                ></path>
                <path
                    fill="#CACACA"
                    d="M49.486 186.049l-.857-1.349a3.364 3.364 0 00-4.43-1.7 3.366 3.366 0 00-1.716 4.427l.243.874.277.991a18.99 18.99 0 003.317-.727 17.585 17.585 0 002.33-.924c.004-.004.008-.004.013-.008.168-.08.331-.164.5-.248a.786.786 0 00.079-.042 12.326 12.326 0 00.79-.437l-.546-.857z"
                ></path>
                <path
                    fill="#FFB6B6"
                    d="M41.021 179.847a6.171 6.171 0 006.17-6.174 6.171 6.171 0 00-6.17-6.173 6.171 6.171 0 00-6.169 6.173 6.171 6.171 0 006.17 6.174z"
                ></path>
                <path
                    fill="#CACACA"
                    d="M49.93 185.783a13.335 13.335 0 00-2.506-3.042 3.66 3.66 0 00-2.443-.932h-8.69a3.667 3.667 0 00-3.657 3.898c.019.282.07.561.151.832.062.196.14.387.231.571l.428.857c.034.013.067.03.1.042.01.005.014.005.018.009.176.071.352.143.533.21.59.226 1.193.419 1.805.579.985.265 1.99.446 3.006.542a18.443 18.443 0 003.53 0c.19-.016.379-.037.568-.058a18.994 18.994 0 003.316-.727 17.862 17.862 0 002.33-.924c.004-.005.008-.005.013-.009.168-.08.332-.164.5-.248a.625.625 0 00.08-.042 10.617 10.617 0 00.788-.437c.135-.071.26-.151.39-.231a14.43 14.43 0 00-.49-.89z"
                ></path>
                <path
                    fill="#CACACA"
                    d="M37.864 182.934a3.357 3.357 0 00-4.458 1.605l-.772 1.167-.37.559-.562.849.348.189.155.08c.361.185.727.361 1.105.525.041.021.088.038.134.059.033.012.067.029.1.042.009.004.013.004.017.008.177.071.353.143.533.21.591.226 1.194.42 1.806.58.985.264 1.99.446 3.006.542l.294-.996.285-.949a3.37 3.37 0 00-1.62-4.47z"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M34.54 169.79a8.388 8.388 0 004.794 1.483c-.61.424-1.306.71-2.038.839 2.277.489 4.63.502 6.911.039.507-.082.997-.25 1.447-.497a1.84 1.84 0 00.893-1.195c.151-.867-.523-1.655-1.225-2.185a9.017 9.017 0 00-7.592-1.518 4.154 4.154 0 00-2.248 1.271 2.05 2.05 0 00-.19 2.434l-.753-.671z"
                ></path>
                <path
                    fill="#3F3D56"
                    d="M58.21 164.765a18.567 18.567 0 00-10.872-11.23 18.535 18.535 0 00-15.59 1.062 18.562 18.562 0 00-9.25 12.601 18.58 18.58 0 004.2 15.867c.434.5.897.975 1.385 1.424.126.117.256.239.39.353.252.227.513.441.785.647.105.084.21.168.32.248.424.323.869.621 1.322.911.008 0 .015.003.02.009.131.079.266.159.4.235.126.075.256.151.382.222.117.064.23.127.348.19l.156.079c.36.185.726.362 1.104.525.042.021.088.038.134.059.034.013.067.03.1.042.01.005.014.005.018.009.176.071.352.143.533.21.59.226 1.193.419 1.805.58.985.264 1.99.445 3.006.541a18.443 18.443 0 003.53 0c.19-.016.379-.037.568-.058a18.992 18.992 0 003.316-.727 17.857 17.857 0 002.33-.924c.004-.005.008-.005.013-.009.168-.08.331-.164.5-.248a.619.619 0 00.079-.042 10.7 10.7 0 00.79-.437c.134-.071.26-.151.39-.231l.201-.126c.093-.054.177-.109.269-.172.126-.08.252-.168.373-.256.164-.114.328-.231.488-.353.053-.035.105-.073.155-.113a18.59 18.59 0 006.301-20.888zm-6.915 20.077a4.88 4.88 0 01-.336.248 8.851 8.851 0 01-.336.239c-.096.067-.193.134-.293.198l-.03.021c-.071.05-.147.096-.218.142-.05.03-.1.063-.151.093-.147.092-.294.18-.445.264-.08.051-.164.097-.244.143l-.088.051c-.13.071-.256.134-.382.201-.017.013-.038.017-.05.03a.033.033 0 00-.022.008c-.159.084-.323.164-.486.24-.63.303-1.28.566-1.944.785-.084.03-.172.063-.256.088a17.502 17.502 0 01-5.328.824 17.51 17.51 0 01-4.526-.593c-.757-.197-1.5-.45-2.22-.756-.038-.016-.076-.029-.114-.046-.042-.017-.075-.029-.113-.046-.042-.017-.076-.034-.118-.055a14.11 14.11 0 01-.81-.382c-.038-.021-.075-.038-.113-.059l-.147-.075-.26-.139a14.504 14.504 0 01-.828-.483c-.1-.063-.21-.13-.31-.198l-.076-.05c-.235-.16-.47-.319-.701-.492-.155-.117-.315-.231-.466-.352-.256-.194-.504-.4-.743-.614a8.687 8.687 0 01-.37-.332c-.472-.436-.92-.898-1.339-1.386-.138-.16-.277-.319-.407-.487-.03-.034-.054-.068-.084-.105a17.562 17.562 0 01-1.536-19.517 17.543 17.543 0 0117.477-8.799 17.552 17.552 0 0114.4 23.095 17.552 17.552 0 01-5.987 8.296z"
                ></path>
                <path
                    fill="#3F3D56"
                    d="M71.725 74.06a43.579 43.579 0 00-47.249-5.863 43.615 43.615 0 00-18.29 16.808 43.655 43.655 0 001.827 47.608 43.61 43.61 0 0019.524 15.356 43.58 43.58 0 0046.66-9.471l53.486 45.161a4.018 4.018 0 006.59-3.407 4.024 4.024 0 00-1.401-2.734l-.006-.005-53.486-45.161a43.657 43.657 0 00-7.655-58.293zm-3.448 53.944a32.65 32.65 0 01-50.501-.691 32.708 32.708 0 01-3.064-36.193 32.678 32.678 0 0113.593-13.236 32.65 32.65 0 0136.072 4.054 32.707 32.707 0 013.9 46.066z"
                ></path>
                <path
                    fill="#000"
                    d="M22.242 131.907a32.708 32.708 0 01-5.544-43.946 32.698 32.698 0 00-9.396 25.82 32.706 32.706 0 0011.485 22.224 32.665 32.665 0 0023.825 7.589 32.66 32.66 0 0023.854-13.612 32.663 32.663 0 01-21.737 9.6 32.658 32.658 0 01-22.487-7.675z"
                    opacity="0.3"
                ></path>
                <path
                    fill="#FFB6B6"
                    d="M179.552 185.347a4.524 4.524 0 00.942-3.701 4.512 4.512 0 00-2.23-3.098l-9.749-39.931-7.112 2.272 10.527 39.658a4.54 4.54 0 001.681 5.788 4.534 4.534 0 005.941-.988z"
                ></path>
                <path
                    fill="#3F3D56"
                    d="M174.513 159.808l-5.197 4.486-7.272 6.273-3.211-16.443-.336-1.71-.265-1.357 7.368-12.842 6.142.352.42.026.009.092.096.862 1.26 11.334.029.273.957 8.654z"
                ></path>
                <path
                    fill="#FFB6B6"
                    d="M140.541 271.417h-5.146l-2.449-19.866h7.597l-.002 19.866z"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M141.854 276.409l-16.596-.001v-.21a6.466 6.466 0 016.46-6.464l10.137.001-.001 6.674z"
                ></path>
                <path
                    fill="#FFB6B6"
                    d="M194.279 271.839h-5.147l-2.448-19.866h7.596l-.001 19.866z"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M195.592 276.831l-16.596-.001v-.21a6.462 6.462 0 016.459-6.464h.001l10.136.001v6.674zM198.393 263.929l-13.855 2.1-20.155-68.258-21.407 68.678-15.534-2.1 20.152-79.82v-6.301l2.729-9.453-.286-2.558-1.986-17.892-.86-7.759-.056-.502a1.706 1.706 0 011.341-1.855l6.465-1.361h10.567l6.234 1.718.428.118.194.054-.097.807-1.461 12.258-.08.672-1.41 11.818-.311 2.592c12.175 8.822 12.595 17.644 12.595 17.644l16.793 79.4z"
                ></path>
                <path
                    fill="#FFB6B6"
                    d="M158.948 131.535c5.694 0 10.311-4.62 10.311-10.318 0-5.699-4.617-10.319-10.311-10.319-5.695 0-10.312 4.62-10.312 10.319 0 5.698 4.617 10.318 10.312 10.318z"
                ></path>
                <path
                    fill="#3F3D56"
                    d="M169.778 164.514l-20.398 2.67.381 2.915 20.398-2.669-.381-2.916z"
                ></path>
                <path
                    fill="#FFB6B6"
                    d="M122.02 168.063c.195.126.379.267.552.423l20.175-5.147 1.377-4.87 7.617 1.118-1.599 8.859a3.367 3.367 0 01-2.954 2.745l-23.557 2.482a4.41 4.41 0 01-4.34 2.503 4.407 4.407 0 01-3.817-5.802 4.409 4.409 0 014.013-3 4.404 4.404 0 012.533.689z"
                ></path>
                <path
                    fill="#3F3D56"
                    d="M153.052 140.841l-3.568-2.731c-.965-.366-2.287.674-2.519 1.681l-5.038 20.585 10.495 7.562 1.47-2.311-.84-24.786z"
                ></path>
                <path
                    fill="#000"
                    d="M173.527 150.879a28.37 28.37 0 01-2.721.924c-.294.089-.596.173-.894.252a27.655 27.655 0 01-6.02.975c-.877.037-1.755.013-2.628-.071a14.812 14.812 0 01-2.767-.547c-.029-.008-.063-.016-.092-.025a12.138 12.138 0 01-5.017-2.852 9.984 9.984 0 01-.886-.987 8.932 8.932 0 01-1.914-6.945c.035-.222.082-.442.142-.659a10.62 10.62 0 01.626-1.723c.113-.26.239-.517.374-.769l4.743-1.604h9.035l6.235 1.718.419.025.009.093.193.054-.097.807 1.26 11.334z"
                    opacity="0.2"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M151.046 112.672c-1.928-.236-4.044-.069-5.548 1.163-1.503 1.232-1.988 3.793-.565 5.116 1.26 1.172 3.23.896 4.918 1.23a6.661 6.661 0 015.199 6.367c-.025 4.274-4.125 7.511-4.861 11.721-.503 2.874.708 5.898 2.802 7.927 2.094 2.03 4.972 3.127 7.872 3.424 2.9.297 5.833-.157 8.651-.902 2.83-.749 5.616-1.811 7.991-3.524s4.321-4.128 5.084-6.956c.762-2.828.215-6.071-1.75-8.242-2.25-2.487-5.993-3.342-7.939-6.073-2.181-3.062-1.374-7.422-3.14-10.741-1.178-2.216-3.41-3.728-5.79-4.524-2.379-.796-4.92-.953-7.427-1.031-1.318-.04-2.677-.054-3.905.427-1.228.481-2.307 1.577-2.371 2.895-.064 1.318.779 1.723.779 1.723z"
                ></path>
                <path
                    fill="#CACACA"
                    d="M247.58 277H87.627a.419.419 0 01-.296-.717.419.419 0 01.296-.123H247.58a.418.418 0 01.42.42.417.417 0 01-.42.42z"
                ></path>
            </g>
            <defs>
                <clipPath id="clip0_4980_10173">
                    <path fill="#fff" d="M0 0H248V277H0z"></path>
                </clipPath>
            </defs>
        </svg>
    )
}

export default NoTalentFoundIcon
