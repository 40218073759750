import React from 'react'

function TaskIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="26"
            height="26"
            fill="none"
            viewBox="0 0 26 26"
        >
            <path
                fill="#4F46E5"
                d="M18.238.5H7.761C3.212.5.5 3.212.5 7.763v10.475c0 4.55 2.712 7.262 7.263 7.262h10.475c4.55 0 7.262-2.712 7.262-7.262V7.761C25.5 3.212 22.788.5 18.238.5zm-7.775 16.125L7.65 19.438a.927.927 0 01-.663.274.907.907 0 01-.662-.274l-.938-.938a.92.92 0 010-1.325.943.943 0 011.326 0l.274.275 2.15-2.15a.943.943 0 011.325 0 .943.943 0 010 1.325zm0-8.75L7.65 10.688a.927.927 0 01-.663.274.907.907 0 01-.662-.274l-.938-.938a.92.92 0 010-1.325.943.943 0 011.326 0l.274.275 2.15-2.15a.943.943 0 011.325 0 .943.943 0 010 1.325zm9.487 10.9h-6.563a.944.944 0 01-.937-.938c0-.512.425-.937.938-.937h6.562a.938.938 0 010 1.875zm0-8.75h-6.563a.944.944 0 01-.937-.938c0-.512.425-.937.938-.937h6.562a.937.937 0 010 1.875z"
            ></path>
        </svg>
    )
}

export default TaskIcon
