import React from 'react'

function DeleteRoleIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="26"
            height="26"
            fill="none"
            viewBox="0 0 26 26"
        >
            <path
                fill="#FF3838"
                d="M19.25 5.5h6.25V8H23v16.25c0 .69-.56 1.25-1.25 1.25H4.25c-.69 0-1.25-.56-1.25-1.25V8H.5V5.5h6.25V1.75C6.75 1.06 7.31.5 8 .5h10c.69 0 1.25.56 1.25 1.25V5.5zm-10 6.25v7.5h2.5v-7.5h-2.5zm5 0v7.5h2.5v-7.5h-2.5zM9.25 3v2.5h7.5V3h-7.5z"
            ></path>
        </svg>
    )
}

export default DeleteRoleIcon
