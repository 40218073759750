// antd
import { Divider, Image, Row, Space, theme, Typography } from 'antd'
import { CertificateIcon, CertificatesLogoIcon } from '../../../assets/icons'
import { fileTypes, videoTypes } from '../../../constants/mediaTypes'
import ModalProfile from '../../../components/modal'
import ViewMultiMediaModal from '../modals/viewMultimediaModal'
import { useState } from 'react'

function CertificatesSummaryContent({ contentData }) {
    const { Title, Text } = Typography

    const { useToken } = theme
    const { token } = useToken()

    const [viewMediaCertificate, setViewMediaCertificate] = useState({
        open: false,
        file: {},
    })

    return (
        <>
            <Row
                style={{
                    margin: '25px 0px 50px 0px',
                    rowGap: '15px',
                }}
                align={'middle'}
            >
                {contentData?.length > 0 &&
                    contentData.map((certificateData) => {
                        const startDate =
                            certificateData?.dateReceived !== null
                                ? new Date(certificateData?.dateReceived)
                                : null
                        return (
                            <div key={certificateData?.title}>
                                <Row
                                    style={{
                                        columnGap: '20px',
                                        flexFlow: 'row nowrap',
                                    }}
                                >
                                    {certificateData?.fileLink?.length > 0 ? (
                                        fileTypes.some((r) =>
                                            certificateData?.fileLink[0]
                                                ?.toLowerCase()
                                                .includes(r)
                                        ) ? (
                                            <Image
                                                src={
                                                    certificateData?.fileLink[0]
                                                }
                                                width={'100px'}
                                                height={'100px'}
                                                preview={false}
                                                style={{
                                                    borderRadius: '10px',
                                                    objectFit: 'cover',
                                                    cursor: 'pointer',
                                                }}
                                                onClick={() => {
                                                    setViewMediaCertificate({
                                                        open: true,
                                                        file: {
                                                            name: certificateData?.title,
                                                            description:
                                                                certificateData?.description,
                                                            files: certificateData?.fileLink,
                                                        },
                                                    })
                                                }}
                                            />
                                        ) : videoTypes.some((r) =>
                                              certificateData?.fileLink[0].includes(
                                                  r
                                              )
                                          ) ? (
                                            <video
                                                src={
                                                    certificateData?.fileLink[0]
                                                }
                                                width={'100px'}
                                                height={'100px'}
                                                style={{
                                                    borderRadius: '10px',
                                                    objectFit: 'cover',
                                                    cursor: 'pointer',
                                                }}
                                                onClick={() => {
                                                    setViewMediaCertificate({
                                                        open: true,
                                                        file: {
                                                            name: certificateData?.title,
                                                            description:
                                                                certificateData?.description,
                                                            files: certificateData?.fileLink,
                                                        },
                                                    })
                                                }}
                                            />
                                        ) : (
                                            <div
                                                onClick={() => {
                                                    setViewMediaCertificate({
                                                        open: true,
                                                        file: {
                                                            name: certificateData?.title,
                                                            description:
                                                                certificateData?.description,
                                                            files: certificateData?.fileLink,
                                                        },
                                                    })
                                                }}
                                            >
                                                <iframe
                                                    src={`${certificateData?.fileLink[0]}#view=fitH`}
                                                    width={'100px'}
                                                    height={'100px'}
                                                    title="PDF"
                                                    style={{
                                                        borderRadius: '10px',
                                                        objectFit: 'cover',
                                                        cursor: 'pointer',
                                                    }}
                                                />
                                            </div>
                                        )
                                    ) : (
                                        <Row
                                            style={{
                                                height: '100px',
                                                width: '100px',
                                                borderRadius: '10px',
                                                padding: '20px 20px 20px 20px',
                                                border: `2px solid ${token.colorPalette.textColor.quaternary}`,
                                            }}
                                        >
                                            <div
                                                style={{
                                                    height: '56px',
                                                    width: '56px',
                                                }}
                                            >
                                                <CertificatesLogoIcon />
                                            </div>
                                        </Row>
                                    )}
                                    <div
                                        style={{
                                            marginRight: '50px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        <Row>
                                            <Space>
                                                {certificateData?.title && (
                                                    <Title
                                                        className={'titleSub'}
                                                    >
                                                        {certificateData?.title}
                                                    </Title>
                                                )}
                                                <Text className="titleLight">
                                                    {!certificateData?.isCompleted &&
                                                        `(Draft)`}
                                                </Text>
                                            </Space>
                                        </Row>
                                        <Space>
                                            {certificateData
                                                ?.certificateProvider?.name && (
                                                <Text
                                                    style={{
                                                        fontSize:
                                                            token.fontSize,
                                                    }}
                                                >
                                                    {
                                                        certificateData
                                                            ?.certificateProvider
                                                            ?.name
                                                    }
                                                </Text>
                                            )}
                                            {certificateData
                                                ?.certificateProvider?.name &&
                                                certificateData?.fieldOfStudy
                                                    ?.name &&
                                                '|'}
                                            {certificateData?.fieldOfStudy
                                                ?.name && (
                                                <Text
                                                    style={{
                                                        fontSize:
                                                            token.fontSize,
                                                    }}
                                                >
                                                    {
                                                        certificateData
                                                            ?.fieldOfStudy?.name
                                                    }
                                                </Text>
                                            )}
                                        </Space>
                                        <Text
                                            style={{
                                                fontSize: token.fontSizeLG,
                                            }}
                                            className={'titleLight'}
                                        >
                                            {startDate !== null &&
                                                startDate.toLocaleString(
                                                    'default',
                                                    {
                                                        year: 'numeric',
                                                        month: 'long',
                                                    }
                                                )}
                                        </Text>
                                        <Text
                                            style={{
                                                fontSize: token.fontSize,
                                            }}
                                        >
                                            {certificateData?.description}
                                        </Text>
                                    </div>
                                </Row>
                                <Divider type="vertical" />
                            </div>
                        )
                    })}
            </Row>
            <ModalProfile
                visibility={viewMediaCertificate?.open}
                handleCancel={() => {
                    setViewMediaCertificate({
                        open: false,
                        file: {},
                    })
                }}
                setMaxHeight={false}
                footer={false}
                icon={<CertificateIcon />}
                modalKey="viewVideo"
                modalTitle="Media"
                showTitle="Media"
                content={
                    <ViewMultiMediaModal
                        fileData={viewMediaCertificate?.file}
                    />
                }
            />
        </>
    )
}

export default CertificatesSummaryContent
