// antd
import { Image, Row, Drawer, theme, Typography, Space } from 'antd'

// helpers
import { downloadFileGet } from '../../helpers/fileDownload'

// constants
import {
    fileTypes,
    pdfTypes,
    videoTypes,
} from '../../constants/mediaTypes/index'

// assets
import { DownloadFileIcon } from '../../assets/icons'

// styles
import styles from '../profile/profile.module.css'

const RecruitViewMediaModal = ({ attachmentData, open, onClose }) => {
    const { useToken } = theme
    const { token } = useToken()

    const { Title, Text } = Typography

    const today = new Date(attachmentData?.files?.createdAt)
    const yyyy = today.getFullYear()
    let mm = today.getMonth() + 1 // Months start at 0!
    let dd = today.getDate()

    if (dd < 10) dd = '0' + dd
    if (mm < 10) mm = '0' + mm

    const formattedToday = dd + '-' + mm + '-' + yyyy

    return (
        <>
            <Drawer
                placement="right"
                closable={false}
                open={open}
                onClose={() => {
                    onClose()
                }}
                footer={null}
                title={
                    <Row align={'middle'} justify={'space-between'}>
                        <div>
                            <Title
                                className={'titleMiddle'}
                                style={{ textTransform: 'capitalize' }}
                            >
                                {attachmentData?.files?.displayName}
                            </Title>
                            <Space>
                                <Text
                                    style={{
                                        fontSize: token.fontSizeLG,
                                    }}
                                    className={'titleLight'}
                                >
                                    {formattedToday}
                                </Text>
                                <Text
                                    style={{
                                        fontSize: token.fontSizeLG,
                                    }}
                                    className={'titleLight'}
                                >
                                    {new Date(
                                        attachmentData?.files?.createdAt
                                    )?.toLocaleString('default', {
                                        hour: '2-digit',
                                        minute: '2-digit',
                                    })}
                                </Text>
                            </Space>
                        </div>
                        <div
                            onClick={() => {
                                downloadFileGet(
                                    attachmentData?.files?.name,
                                    attachmentData?.files?.displayName
                                )
                            }}
                            style={{
                                cursor: 'pointer',
                            }}
                        >
                            <DownloadFileIcon />
                        </div>
                    </Row>
                }
                style={{
                    height: '100vh',
                    minWidth: '900px',
                    width: '900px',
                }}
                styles={{
                    body: {
                        padding: '27px 35px',
                        backgroundColor: '#EBECED',
                        minHeight: '100%',
                    },
                    wrapper: {
                        width: 'auto !important',
                    },
                    header: {
                        padding: '30px 40px',
                    },
                }}
                rootClassName="talentViewDrawer"
            >
                {fileTypes.some((r) =>
                    attachmentData?.files?.name?.toLowerCase().includes(r)
                ) ? (
                    <>
                        <Image
                            src={attachmentData?.files?.name}
                            width={'663px'}
                            height={'517px'}
                            preview={false}
                            style={{
                                objectFit: 'contain',
                                padding: '30px',
                                minHeight: '517px',
                                minWidth: '663px',
                            }}
                        />
                    </>
                ) : videoTypes.some((r) =>
                      attachmentData?.files?.name?.toLowerCase().includes(r)
                  ) ? (
                    <video
                        width="100%"
                        height="540px"
                        autoPlay
                        controls
                        className={styles.videoViewContainer}
                    >
                        <source
                            src={attachmentData?.files?.name}
                            type="video/mp4"
                        />
                    </video>
                ) : pdfTypes.some((r) =>
                      attachmentData?.files?.name?.toLowerCase().includes(r)
                  ) ? (
                    <>
                        <div
                            style={{
                                height: '100%',
                            }}
                        >
                            <iframe
                                src={`${attachmentData?.files?.name}#view=fitH`}
                                height={'540px'}
                                width="100%"
                                title="PDF"
                            />
                        </div>
                    </>
                ) : (
                    <></>
                )}
            </Drawer>
        </>
    )
}

export default RecruitViewMediaModal
