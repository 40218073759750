import { useDispatch, useSelector } from 'react-redux'

// antd
import { Button, Divider, Row, theme, Typography } from 'antd'

// slices
import { changeTab, profileState } from '../profile.slice'

// assets
import {
    AddMoreIcon,
    CertificateIcon,
    EducationIcon,
    ExperienceIcon,
    ProjectsIcon,
    SummaryIcon,
    VideosIcon,
} from '../../../assets/icons'

// profile sections
import NoProfileAdded from './noProfileAdded'
import EducationSummaryContent from '../education/educationSummaryContent'
import CertificatesSummaryContent from '../certificates/certificatesSummaryContent'
import ExperienceSummaryContent from '../experience/experienceSummaryContent'
import ProjectsSummaryContent from '../projects/projectsSummaryContent'
import OverviewSummaryContent from '../summary/overviewSummaryContent'
import VideosSummaryContent from '../videos/videosSummaryContent'

function OverviewTabSection() {
    const { Title } = Typography

    const { useToken } = theme
    const { token } = useToken()

    const dispatch = useDispatch()

    const { profileData, status } = useSelector(profileState)

    // TAB CONTENT
    const tabContent = [
        {
            key: 'summary',
            title: 'Summary',
            icon: <SummaryIcon />,
            description: 'Please add your short description to the profile.',
        },
        {
            key: 'education',
            title: 'Education',
            icon: <EducationIcon />,
            description: 'Please add your short description to the profile.',
        },
        {
            key: 'certificates',
            title: 'Certificates',
            icon: <CertificateIcon />,
            description:
                'Please add all the certificates to show the achievements.',
        },
        {
            key: 'experience',
            title: 'Experience',
            icon: <ExperienceIcon />,
            description: 'Please add your experience details.',
        },
        {
            key: 'projects',
            title: 'Projects',
            icon: <ProjectsIcon />,
            description: 'Please add all the projects done by you.',
        },
        {
            key: 'videos',
            title: 'Videos',
            icon: <VideosIcon />,
            description:
                'Please add your video here as per the preference to the profile.',
        },
    ]

    function checkTabKey(key) {
        switch (key) {
            case 'summary':
                return (
                    <OverviewSummaryContent
                        contentData={profileData}
                        keyText={key}
                    />
                )

            case 'education':
                return (
                    <EducationSummaryContent
                        contentData={profileData?.data[key].slice(0, 2)}
                    />
                )

            case 'certificates':
                return (
                    <CertificatesSummaryContent
                        contentData={profileData?.data[key].slice(0, 2)}
                    />
                )

            case 'experience':
                return (
                    <ExperienceSummaryContent
                        contentData={profileData?.data[key].slice(0, 2)}
                    />
                )

            case 'projects':
                return (
                    <ProjectsSummaryContent
                        contentData={profileData?.data[key].slice(0, 4)}
                    />
                )

            case 'videos':
                return (
                    <VideosSummaryContent
                        contentData={profileData?.data[key].slice(0, 4)}
                    />
                )

            default:
                break
        }
    }

    return (
        <div
            style={{
                padding: '0px 25px 0px 25px',
            }}
        >
            {tabContent?.length > 0 &&
                tabContent.map((content) => (
                    <>
                        <Row
                            justify={'space-between'}
                            align={'middle'}
                            style={{
                                marginTop: '50px',
                            }}
                        >
                            {content?.key !== 'summary' && (
                                <Row
                                    style={{
                                        columnGap: '15px',
                                    }}
                                >
                                    <div
                                        style={{
                                            width: '4px',
                                            height: '40px',
                                            backgroundColor:
                                                token.colorPalette.baseColor
                                                    .tertiary,
                                        }}
                                    ></div>
                                    <Title level={4}>{content?.title}</Title>
                                </Row>
                            )}
                            {Object.keys(profileData)?.length !== 0 &&
                                status === 'loaded' &&
                                content?.key !== 'summary' &&
                                profileData?.data[content?.key]?.length > 0 && (
                                    <Button
                                        onClick={() => {
                                            if (
                                                content?.key === 'certificates'
                                            ) {
                                                dispatch(changeTab('education'))
                                            } else {
                                                dispatch(
                                                    changeTab(content?.key)
                                                )
                                            }
                                        }}
                                        style={{
                                            backgroundColor: 'transparent',
                                            color: token.colorPalette.baseColor
                                                .tertiary,
                                            fontSize: '18px',
                                            fontWeight: 500,
                                        }}
                                        className="viewMoreProfileBtn"
                                    >
                                        <Row
                                            style={{
                                                columnGap: '15px',
                                            }}
                                            align={'middle'}
                                        >
                                            View More
                                            <AddMoreIcon />
                                        </Row>
                                    </Button>
                                )}
                        </Row>

                        {Object.keys(profileData)?.length !== 0 &&
                        status === 'loaded' &&
                        ((content?.key !== 'summary' &&
                            profileData?.data[content?.key]?.length > 0) ||
                            (content?.key === 'summary' &&
                                profileData?.data[content?.key] &&
                                Object.keys(profileData?.data[content?.key])
                                    ?.length > 0))
                            ? checkTabKey(content?.key)
                            : status === 'loaded' && (
                                  <NoProfileAdded content={content} />
                              )}

                        <Divider
                            style={{
                                borderColor:
                                    token.colorPalette.textColor.quaternary,
                            }}
                        />
                    </>
                ))}
        </div>
    )
}

export default OverviewTabSection
