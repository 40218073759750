import React from 'react'

function CertificateIcon({ color }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            fill="none"
            viewBox="0 0 40 40"
        >
            <path
                fill={color ? color : '#B9B9B9'}
                fillRule="evenodd"
                d="M34.414 26.17a155.779 155.779 0 01-1.863-1.56 60.6 60.6 0 002.81.972l-.947.587zm.526 4.767c-1.034 3.328-2.984 5.278-6.313 6.314-.407.088-.932-.089-1.244-.4a1.45 1.45 0 01-.05-.054 155.005 155.005 0 00-8.653-9.929l5.878-5.878a155.274 155.274 0 008.654 7.623l.494.401c.26.211.52.421.781.63.018.015.036.032.053.05.313.312.515.844.4 1.243zM13.05 21.24a155.465 155.465 0 00-9.928-8.654.869.869 0 01-.054-.05c-.312-.312-.524-.833-.4-1.243 1.035-3.328 2.986-5.279 6.314-6.314.376-.125.917-.022 1.293.454l.63.781.402.494a155.27 155.27 0 007.622 8.654l-5.879 5.878zm1.288-16.681c.264.825.588 1.782.973 2.81a148.684 148.684 0 01-1.56-1.863l.587-.947zM39.904 24.93a1.326 1.326 0 00-.992-1.08c-.086-.022-7.102-1.784-12.257-4.957l-5.629-5.63c-3.169-5.152-4.935-12.17-4.956-12.256a1.326 1.326 0 00-2.414-.38l-1.7 2.74c-.963-.869-2.412-1.281-3.596-.968l-.097.026C4.067 3.71 1.403 6.376.117 10.572l-.026.097c-.295 1.308.139 2.776 1.104 3.741.072.073.148.143.226.208l.029.024c3.192 2.55 6.3 5.248 9.28 8.052-2.67 1.48-4.992 2.216-6.766 2.137a.675.675 0 00-.438 1.214l2.003 1.518-2.273 2.181a.677.677 0 00.438 1.163c2.008.09 4.217-.342 6.602-1.282-.942 2.385-1.372 4.593-1.283 6.601a.677.677 0 001.162.438l2.182-2.273 1.518 2.003a.675.675 0 001.213-.438c-.078-1.774.658-4.096 2.138-6.767a152.358 152.358 0 018.052 9.28l.024.031c.065.077.135.153.208.225.965.965 2.434 1.399 3.741 1.104l.097-.025c4.197-1.287 6.862-3.951 8.147-8.147 0 0 .7-2.111-.943-3.694l2.741-1.699c.453-.281.694-.806.61-1.333z"
                clipRule="evenodd"
            ></path>
        </svg>
    )
}

export default CertificateIcon
