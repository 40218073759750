import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// antd
import {
    Checkbox,
    Col,
    DatePicker,
    Form,
    InputNumber,
    Row,
    Select,
    Typography,
    message,
} from 'antd'

// slices
import {
    offerToHireAddRemove,
    offerToHireUpdate,
    rolesInquiryState,
    talentAddRemoveType,
    talentListType,
} from './rolesInquiry.slice'
import { inquiryState } from '../../inquiries.slice'

// components
import Button from '../../../../components/button/button'
import CardTalent from './components/cardTalent'

// constants
import { weeklyLimitOptions } from '../../../../constants/inquiries'

import dayjs from 'dayjs'

const OfferToHireForm = ({ onCancel, talentData, tabName, offerData }) => {
    const { Title, Text } = Typography

    const [form] = Form.useForm()

    const dispatch = useDispatch()

    const { selectedRole } = useSelector(rolesInquiryState)
    const { selectedInquiry } = useSelector(inquiryState)

    const [loadingButton, setLoadingButton] = useState(false)

    useEffect(() => {
        if (offerData) {
            form.setFieldsValue({
                ...offerData,
                proposalStartDate: dayjs(offerData?.proposalStartDate),
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [offerData])

    // FORMAT DATEPICKER
    const formatDatePicker = (value) => {
        return `${dayjs(value).format('DD MMM YY')}`
    }

    // ON FINISH OFFER TO HIRE
    const onFinishOfferToHire = async (value) => {
        setLoadingButton(true)
        const result = offerData
            ? await dispatch(
                  offerToHireUpdate({
                      updatedData: {
                          ...value,
                          proposalStartDate: new Date(
                              value?.proposalStartDate
                          ).getTime(),
                      },
                      roleId: selectedRole?._id,
                      talentId: offerData?._id,
                      workspaceId: selectedInquiry?.client?.workspaceId,
                  })
              )
            : await dispatch(
                  offerToHireAddRemove({
                      addRemoveData: {
                          fieldName: 'add',
                          offeredTalent: {
                              ...value,
                              proposalStartDate: new Date(
                                  value?.proposalStartDate
                              ).getTime(),
                              talent: talentData?._id,
                          },
                      },
                      roleId: selectedRole?._id,
                      workspaceId: selectedInquiry?.client?.workspaceId,
                  })
              )
        const data = result?.payload?.data
        if (data) {
            const { success, message: checkMessage } = data
            if (success) {
                if (offerData) {
                    await dispatch(
                        talentListType({
                            roleId: selectedRole?._id,
                            workspaceId: selectedInquiry?.client?.workspaceId,
                            updatedData: {
                                skip: 0,
                                limit: 10,
                                fieldName: tabName,
                            },
                        })
                    )
                } else {
                    const result = await dispatch(
                        talentAddRemoveType({
                            updatedData: {
                                fieldName: 'remove',
                                unShortListedTalent: talentData?._id,
                            },
                            workspaceId: selectedInquiry?.client?.workspaceId,
                            roleId: selectedRole?._id,
                        })
                    )
                    const data = result?.payload?.data
                    if (data) {
                        const { success, message: checkMessage } = data
                        if (success) {
                            await dispatch(
                                talentListType({
                                    roleId: selectedRole?._id,
                                    workspaceId:
                                        selectedInquiry?.client?.workspaceId,
                                    updatedData: {
                                        skip: 0,
                                        limit: 10,
                                        fieldName: tabName,
                                    },
                                })
                            )
                        } else {
                            if (checkMessage) {
                                message.error(checkMessage)
                            } else {
                                message.error('Something went wrong')
                            }
                        }
                    }
                }
                onCancel()
                message.success(checkMessage)
                setLoadingButton(false)
            } else {
                onCancel()
                setLoadingButton(false)
                if (checkMessage) {
                    message.error(checkMessage)
                } else {
                    message.error('Something went wrong')
                }
            }
        }
    }

    // ON FINISH FAILED
    const onFinishFailed = () => {}

    return (
        <>
            <Row justify={'center'}>
                <Title level={3}>
                    {offerData ? 'Update Offer' : 'Offer to Hire'}
                </Title>
            </Row>
            <CardTalent
                talentData={talentData}
                onCancel={onCancel}
                cardKey={'offerToHire'}
            />
            <Form
                labelCol={{
                    style: {
                        padding: '0 0 3px',
                    },
                }}
                layout="vertical"
                name="offerToHireForm"
                onFinish={onFinishOfferToHire}
                onFinishFailed={onFinishFailed}
                requiredMark={false}
                form={form}
                style={{
                    overflowX: 'hidden',
                    marginTop: '25px',
                }}
            >
                <Row
                    gutter={20}
                    style={{
                        rowGap: '15px',
                    }}
                >
                    <Col span={12}>
                        <Form.Item
                            label="Offer"
                            name="offer"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Enter Offer',
                                },
                            ]}
                            colon={false}
                        >
                            <InputNumber
                                style={{
                                    width: '100%',
                                }}
                                placeholder={'Enter Offer'}
                                name={'offer'}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Weekly Limit"
                            name="weeklyLimit"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Enter Weekly Limit',
                                },
                            ]}
                            colon={false}
                        >
                            <Select
                                options={weeklyLimitOptions}
                                allowClear={false}
                                placeholder="Select a Weekly Limit"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Proposal Start Date"
                            colon={false}
                            name={'proposalStartDate'}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Enter Proposal Start Date',
                                },
                            ]}
                        >
                            <DatePicker
                                allowClear={false}
                                name="proposalStartDate"
                                format={formatDatePicker}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Form.Item
                            name="termsOfService"
                            valuePropName="checked"
                            rules={[
                                {
                                    validator: (_, value) =>
                                        value
                                            ? Promise.resolve()
                                            : Promise.reject(
                                                  new Error(
                                                      'Should accept agreement'
                                                  )
                                              ),
                                },
                            ]}
                        >
                            <Checkbox
                                style={{
                                    marginTop: '25px',
                                    columnGap: '10px',
                                }}
                            >
                                <Text className="titleSub">
                                    Yes, I understand and agree to the
                                    SoftwareCo terms of service, including the
                                    User Agreement and Privacy Policy
                                </Text>
                            </Checkbox>
                        </Form.Item>
                    </Col>
                </Row>
                <Row
                    style={{
                        float: 'right',
                        marginTop: '35px',
                    }}
                >
                    <Button
                        props={{
                            htmlType: 'submit',
                            text: 'Offer',
                            style: {
                                borderRadius: '60px',
                            },
                            loadingButton: loadingButton,
                        }}
                    />
                </Row>
            </Form>
        </>
    )
}

export default OfferToHireForm
