import { useDispatch, useSelector } from 'react-redux'

// antd
import { Card, Col, Row, theme, Typography } from 'antd'

// slices
import {
    profileState,
    switchProfileModal,
    switchVideoPlayModal,
    updatedDataProfile,
} from '../profile.slice'
import { loginState } from '../../login/login.slice'

// assets
import { EditIcon, VideosIcon } from '../../../assets/icons'

// constants
import { videoSummaryData } from '../../../constants/profile'
import { SUPER_ADMIN } from '../../../constants/roles'

function OverviewSummaryContent({ contentData, keyText }) {
    const { Title, Text } = Typography

    const { useToken } = theme
    const { token } = useToken()

    const dispatch = useDispatch()

    const { userProfile } = useSelector(loginState)
    const { introductionVideoModal, profileData } = useSelector(profileState)

    let results
    if (contentData?.data?.videos?.length > 0) {
        results = profileData?.data?.videos?.filter(({ type: id1 }) =>
            videoSummaryData.some(({ key: id2 }) => id2 === id1)
        )
    }

    return (
        <Row
            style={{
                margin: '25px 0px 50px 0px',
                flexFlow: 'row nowrap',
                columnGap: '25px',
            }}
            justify={'space-between'}
        >
            <Col>
                <Row
                    justify={'space-between'}
                    style={{
                        marginBottom: '25px',
                    }}
                >
                    <Row
                        style={{
                            columnGap: '15px',
                        }}
                    >
                        <div
                            style={{
                                width: '4px',
                                height: '40px',
                                backgroundColor:
                                    token.colorPalette.baseColor.tertiary,
                            }}
                        ></div>
                        <Title
                            level={4}
                            style={{
                                textTransform: 'capitalize',
                            }}
                        >
                            {keyText}
                        </Title>
                    </Row>
                    {userProfile?.role?.roleName !== SUPER_ADMIN && (
                        <div
                            style={{
                                height: '26px',
                                width: '26px',
                                cursor: 'pointer',
                            }}
                            onClick={() => {
                                dispatch(
                                    switchProfileModal({
                                        name: 'summary',
                                        open: true,
                                    })
                                )
                                dispatch(
                                    updatedDataProfile({
                                        editData: {
                                            step: 'summary',
                                            extraFields: ['videos'],
                                        },
                                        _id: userProfile?._id,
                                    })
                                )
                            }}
                        >
                            <EditIcon
                                color={token.colorPalette.baseColor.tertiary}
                            />
                        </div>
                    )}
                </Row>
                <Text
                    style={{
                        fontSize: '18px',
                    }}
                >
                    {contentData?.data[keyText]?.summaryText || 'N/A'}
                </Text>
            </Col>
            <Col>
                {results === undefined && (
                    <Card
                        style={{
                            borderRadius: '10px',
                            position: 'relative',
                            width: '300px',
                            height: '170px',
                            maxWidth: '385px',
                            maxHeight: '216px',
                            float: 'right',
                            cursor: 'auto',
                            boxShadow:
                                '0px 3px 6px 0px rgba(140, 149, 159, 0.15)',
                            // border: `1px solid ${token.colorPalette.textColor.quaternary}`,
                        }}
                        styles={{
                            body: {
                                width: '300px',
                                height: '170px',
                                maxWidth: '385px',
                                maxHeight: '216px',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                            },
                        }}
                    >
                        <div
                            style={{
                                height: '50px',
                            }}
                        >
                            <VideosIcon
                                color={token.colorPalette.textColor.quinary}
                            />
                        </div>
                        <div
                            style={{
                                marginTop: '5px',
                            }}
                        >
                            <Text
                                className="titleLight"
                                style={{
                                    fontSize: token.fontSizeHeading5,
                                }}
                            >
                                No Videos Uploaded
                            </Text>
                        </div>
                    </Card>
                )}
                {results?.length > 0 && results !== undefined && (
                    <div
                        style={{
                            position: 'relative',
                            width: '300px',
                            height: '170px',
                            float: 'right',
                        }}
                    >
                        <>
                            <video
                                width={'300px'}
                                height={'170px'}
                                src={results[0]?.link}
                                style={{
                                    borderRadius: '10px',
                                    objectFit: 'cover',
                                    maxWidth: '385px',
                                    maxHeight: '216px',
                                    float: 'right',
                                    boxShadow:
                                        '0px 3px 6px 0px rgba(140, 149, 159, 0.15)',
                                }}
                            />
                            <div
                                style={{
                                    position: 'absolute',
                                    top: '40%',
                                    left: '40%',
                                    cursor:
                                        results === undefined
                                            ? 'auto'
                                            : 'pointer',
                                }}
                                onClick={() => {
                                    dispatch(
                                        switchVideoPlayModal({
                                            ...introductionVideoModal,
                                            open: true,
                                            videoData: {
                                                deleteId: '',
                                                link: results[0]?.link,
                                            },
                                            title: results[0]?.type,
                                        })
                                    )
                                }}
                            >
                                <div
                                    style={{
                                        height: '50px',
                                    }}
                                >
                                    <VideosIcon
                                        color={
                                            token.colorPalette.textColor.quinary
                                        }
                                    />
                                </div>
                                {results === undefined && (
                                    <div
                                        style={{
                                            marginTop: '5px',
                                        }}
                                    >
                                        <Text
                                            className="titleLight"
                                            style={{
                                                fontSize:
                                                    token.fontSizeHeading5,
                                            }}
                                        >
                                            No Videos Uploaded
                                        </Text>
                                    </div>
                                )}
                            </div>
                        </>
                    </div>
                )}
            </Col>
        </Row>
    )
}

export default OverviewSummaryContent
