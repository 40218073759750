export const SUPER_ADMIN = 'superAdmin'
export const WORKSPACE_ADMIN = 'workspaceAdmin'
export const MEMBER = 'member'
export const DOMAIN_LIST = [
    // 'pms.softwareco.com',
    'app.softwareco.com',
    'dev.softwareco.com',
    'stage.softwareco.com',
]
export const PRIVATE_ROUTE_CHECK = [
    'workspace',
    'inquiries',
    'talent',
    'global-settings',
]
export const CHECK_PUBLIC_ROUTE_LOGIN = [
    'callback-url',
    'time-tracking-app',
    '/set-password',
    '/login-with-app',
]
