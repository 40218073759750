// antd
import { Col, Image, Row } from 'antd'

function ViewCertificatesModal({ fileName }) {
    return (
        <>
            <Row align={'middle'}>
                <Col span={24}>
                    {fileName.includes('.pdf') ? (
                        <>
                            <div>
                                <iframe
                                    src={`${fileName}#view=fitH`}
                                    height={'387px'}
                                    width="100%"
                                    title="PDF"
                                />
                            </div>
                        </>
                    ) : (
                        <Image
                            src={fileName}
                            width={'100%'}
                            height={'387px'}
                            style={{
                                borderRadius: '15px',
                                objectFit: 'contain',
                            }}
                            preview={false}
                        />
                    )}
                </Col>
            </Row>
        </>
    )
}

export default ViewCertificatesModal
