import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// antd
import { Avatar, Divider, Row, Typography, theme, Col, message } from 'antd'
import { PlayCircleTwoTone } from '@ant-design/icons'

// slices
import {
    selectStandUpGet,
    standUpGet,
    standUpRemove,
    standUpsState,
} from './standups.slice'
import { loginState } from '../login/login.slice'

// components
import DeletePopUp from '../../components/deletePopUp/deletePopUp'

// assets
import { DeleteIcon, NoStandUpsIcon } from '../../assets/icons'

import styles from './standUps.module.css'
import { deletePermissionCheck } from '../../helpers/permissionCheck'
import { SUPER_ADMIN, WORKSPACE_ADMIN } from '../../constants/roles'

const StandUpsList = () => {
    const { useToken } = theme
    const { token } = useToken()

    const { Title, Text } = Typography

    const dispatch = useDispatch()

    const { standUpsList, timeStamp, pageLimitStandUps } =
        useSelector(standUpsState)

    const { userProfile } = useSelector(loginState)

    const [deleteModal, setDeleteModal] = useState({
        modalOpen: false,
        id: '',
        loadingButton: false,
    })

    // PREVIEW VIDEO
    function previewVideo(name, data) {
        const videoScreen = document.getElementById('videoScreen')
        const videoFace = document.getElementById('videoMute')

        if (videoScreen !== null) {
            videoScreen.load()
            // videoScreen.play()
        }
        if (videoFace !== null) {
            videoFace.load()
            // videoFace.play()
        }

        dispatch(selectStandUpGet({ name: name, data }))
    }

    const VideoFeed = ({ name, feedData, src }) => (
        <div
            style={{
                position: 'relative',
            }}
        >
            <video
                style={{
                    width: '83px',
                    height: '55px',
                    objectFit: 'cover',
                    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                    borderRadius: '10px',
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                    justifyContent: 'center',
                }}
                src={src}
                onClick={() => {
                    previewVideo(name, feedData)
                }}
            ></video>
            <PlayCircleTwoTone
                onClick={() => previewVideo(name, feedData)}
                style={{
                    fontSize: '22px',
                    position: 'absolute',
                    top: '35%',
                    right: '35%',
                }}
            />
        </div>
    )

    // DATE DISPLAY
    const dateDisplay = (date) => {
        // Get today's date
        const today = new Date()

        // Get yesterday's date
        const yesterday = new Date(today)
        yesterday.setDate(today.getDate() - 1)

        // Format yesterday's date as "YYYY-MM-DD"
        const formattedYesterday = formatDate(yesterday)

        // Function to format date as text (e.g., "YYYY-MM-DD")
        function formatDate(date) {
            const year = date.getFullYear()
            const month = String(date.getMonth() + 1).padStart(2, '0')
            const day = String(date.getDate()).padStart(2, '0')
            return `${year}-${month}-${day}`
        }

        function otherDateFormat() {
            const dateObject = new Date(date)

            const month = dateObject.toLocaleString('default', {
                month: 'long',
            })
            const weekDay = dateObject.toLocaleString('default', {
                weekday: 'long',
            })
            const day = dateObject.getDate()

            return `${weekDay}, ${month} ${day}`
        }

        if (date === today.toISOString().split('T')[0]) {
            return 'Today'
        } else if (date === formattedYesterday) {
            return 'Yesterday'
        } else {
            return otherDateFormat()
        }
    }

    // DELETE STAND UP
    const deleteStandUps = async () => {
        setDeleteModal({
            ...deleteModal,
            loadingButton: true,
        })
        const result = await dispatch(standUpRemove(deleteModal?.id))
        const data = result?.payload?.data
        if (data) {
            const { success, message: checkMessage } = data
            if (success) {
                setDeleteModal({
                    modalOpen: false,
                    id: '',
                    loadingButton: false,
                })
                message.success(checkMessage)
                dispatch(
                    standUpGet({
                        limit: pageLimitStandUps,
                        skip: 0,
                        titleType: 'standup',
                        projectId: userProfile?.activeProject?._id,
                        ...timeStamp,
                    })
                )
            } else {
                setDeleteModal({
                    modalOpen: false,
                    id: '',
                    loadingButton: false,
                })
                if (checkMessage) {
                    message.error(checkMessage)
                } else {
                    message.error('Something went wrong, try again later.')
                }
            }
        }
    }

    return (
        <>
            {standUpsList?.length > 0 ? (
                standUpsList.map((data) => (
                    <div key={data?.createdAt}>
                        <Divider>{dateDisplay(data?.createdAt)}</Divider>
                        {data?.data?.length > 0 &&
                            data?.data.map((standUpData) => (
                                <Row
                                    style={{
                                        marginBottom: '10px',
                                        padding: '15px',
                                    }}
                                    justify={'space-between'}
                                    key={standUpData?._id}
                                    className={[
                                        styles.standUpData,
                                        'standUpRow',
                                    ]}
                                >
                                    <Row
                                        style={{
                                            columnGap: '10px',
                                        }}
                                        className="standUpRow"
                                    >
                                        <Col>
                                            {standUpData?.userId?.profilePicture
                                                ?.length > 0 ? (
                                                <Avatar
                                                    style={{
                                                        width: '36px',
                                                        height: '36px',
                                                    }}
                                                    className={'uploadAvatar'}
                                                    src={
                                                        standUpData?.userId
                                                            ?.profilePicture
                                                    }
                                                />
                                            ) : (
                                                <Avatar
                                                    style={{
                                                        color: token
                                                            .colorPalette
                                                            .baseColor.white,
                                                        width: '36px',
                                                        height: '36px',
                                                        fontSize: '14px',
                                                    }}
                                                    className={'uploadAvatar'}
                                                >
                                                    {standUpData?.userId?.name[0].toUpperCase()}
                                                    {standUpData?.userId?.name.split(
                                                        ' '
                                                    )[1] !== undefined &&
                                                        standUpData?.userId?.name
                                                            .split(' ')[1][0]
                                                            .toUpperCase()}
                                                </Avatar>
                                            )}
                                        </Col>
                                        <Col>
                                            <Row
                                                align={'middle'}
                                                style={{
                                                    columnGap: '10px',
                                                    marginBottom: '10px',
                                                }}
                                                className="standUpRow"
                                            >
                                                <Title className="titleSub">
                                                    {standUpData?.userId?.name}
                                                </Title>
                                                <Text
                                                    className="titleSub"
                                                    style={{
                                                        color: token
                                                            .colorPalette
                                                            .textColor
                                                            .secondary,
                                                    }}
                                                >
                                                    {new Date(
                                                        standUpData?.updatedAt
                                                    ).toLocaleString(
                                                        'default',
                                                        {
                                                            hour: '2-digit',
                                                            minute: '2-digit',
                                                        }
                                                    )}
                                                </Text>
                                            </Row>
                                            {standUpData?.standup?.length > 0 &&
                                                standUpData?.standup.map(
                                                    (standUps) => {
                                                        return (
                                                            standUps
                                                                ?.description
                                                                ?.length !==
                                                                0 &&
                                                            standUps?.description !==
                                                                '<p><br></p>' && (
                                                                <Row
                                                                    style={{
                                                                        columnGap:
                                                                            '10px',
                                                                        marginBottom:
                                                                            '10px',
                                                                    }}
                                                                    key={
                                                                        standUps?._id
                                                                    }
                                                                    className="standUpRow"
                                                                >
                                                                    <div
                                                                        style={{
                                                                            width: '5px',
                                                                            minWidth:
                                                                                '5px',
                                                                            height: 'auto',
                                                                            borderRadius:
                                                                                '5px',
                                                                            backgroundColor:
                                                                                standUps?.color,
                                                                        }}
                                                                    />
                                                                    <div
                                                                        style={{
                                                                            display:
                                                                                'flex',
                                                                            flexDirection:
                                                                                'column',
                                                                        }}
                                                                        className="standUpDescription"
                                                                    >
                                                                        <Title
                                                                            level={
                                                                                5
                                                                            }
                                                                        >
                                                                            {
                                                                                standUps?.title
                                                                            }
                                                                        </Title>
                                                                        <div
                                                                            dangerouslySetInnerHTML={{
                                                                                __html: standUps.description,
                                                                            }}
                                                                            style={{
                                                                                marginBottom:
                                                                                    '0px !important',
                                                                                fontSize:
                                                                                    token.fontSizeHeading5,
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </Row>
                                                            )
                                                        )
                                                    }
                                                )}
                                            {standUpData?.recordings && (
                                                <Row
                                                    style={{
                                                        columnGap: '10px',
                                                    }}
                                                    key={
                                                        standUpData?.recordings
                                                            ?.screen
                                                    }
                                                >
                                                    <div
                                                        style={{
                                                            width: '5px',
                                                            height: 'auto',
                                                            borderRadius: '5px',
                                                            backgroundColor:
                                                                token
                                                                    .colorPalette
                                                                    .textColor
                                                                    .tertiary,
                                                        }}
                                                    />
                                                    <VideoFeed
                                                        name="record"
                                                        feedData={standUpData}
                                                        src={
                                                            standUpData
                                                                ?.recordings
                                                                ?.screen
                                                        }
                                                    />
                                                </Row>
                                            )}
                                            {standUpData?.files?.length > 0 &&
                                                standUpData?.files.map(
                                                    (fileData) => {
                                                        return (
                                                            <Row
                                                                style={{
                                                                    columnGap:
                                                                        '10px',
                                                                }}
                                                                key={fileData}
                                                            >
                                                                <div
                                                                    style={{
                                                                        width: '5px',
                                                                        height: 'auto',
                                                                        borderRadius:
                                                                            '5px',
                                                                        backgroundColor:
                                                                            token
                                                                                .colorPalette
                                                                                .textColor
                                                                                .tertiary,
                                                                    }}
                                                                />
                                                                <VideoFeed
                                                                    name="file"
                                                                    feedData={
                                                                        standUpData
                                                                    }
                                                                    src={
                                                                        fileData
                                                                    }
                                                                />
                                                            </Row>
                                                        )
                                                    }
                                                )}
                                        </Col>
                                    </Row>
                                    {(userProfile?.role?.roleName ===
                                        WORKSPACE_ADMIN ||
                                        userProfile?.role?.roleName ===
                                            SUPER_ADMIN ||
                                        standUpData?.userId?._id ===
                                            userProfile?._id ||
                                        deletePermissionCheck(
                                            'standups',
                                            userProfile
                                        )) && (
                                        <Col>
                                            <div
                                                style={{
                                                    cursor: 'pointer',
                                                }}
                                                onClick={() => {
                                                    setDeleteModal({
                                                        ...deleteModal,
                                                        modalOpen: true,
                                                        id: standUpData?._id,
                                                    })
                                                }}
                                            >
                                                <DeleteIcon />
                                            </div>
                                        </Col>
                                    )}
                                </Row>
                            ))}
                    </div>
                ))
            ) : (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginTop: '120px',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                        }}
                    >
                        <div
                            style={{
                                width: '400px',
                            }}
                        >
                            <NoStandUpsIcon />
                        </div>
                    </div>
                    <Text
                        className="titleSecondary"
                        style={{
                            marginTop: '20px',
                        }}
                    >
                        No Standups Found yet.
                    </Text>
                </div>
            )}
            <DeletePopUp
                previewDelete={deleteModal?.modalOpen}
                onDelete={() => {
                    deleteStandUps()
                }}
                loadingButton={deleteModal?.loadingButton}
                onCancel={() => {
                    setDeleteModal({
                        modalOpen: false,
                        id: '',
                        loadingButton: false,
                    })
                }}
                modalTitle="Delete Standup"
                pageKey="Standup"
            />
        </>
    )
}

export default StandUpsList
