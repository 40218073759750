import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { configureStore } from '@reduxjs/toolkit'
import { GoogleOAuthProvider } from '@react-oauth/google'

// antd
import { ConfigProvider } from 'antd'

import Client from './client.index'

// slices
import usersSlice from './users/users.slice'
import projectsSlice from './projects/projects.slice'
import feedSlice from './home/home.slice'
import tasksSlice from './tasks/tasks.slice'
import schedulesSlice from './schedules/schedules.slice'
import sprintsSlice from './sprints/sprints.slice'
import loginSlice from './login/login.slice'
import profileSlice from './profile/profile.slice'
import screenshotsSlice from './reports/screenshots/screenshots.slice'
import projectReportsSlice from './reports/projectReports/projectReports.slice'
import notificationsSlice from './notifications/notifications.slice'
import masterDataSlice from './masterDataSettings/masterDataPanel.slice'
import workspaceSlice from './workSpace/workSpace.slice'
import talentSlice from './talent/talent.slice'
import teamSlice from './team/team.slice'
import filesSlice from './files/files.slice'
import notesSlice from './notes/notes.slice'
import roleSlice from './role/role.slice'
import standUpsSlice from './standups/standups.slice'
import inquirySlice from './inquiries/inquiries.slice'
import rolesInquirySlice from './inquiries/tabs/roles/rolesInquiry.slice'
import chatSlice from './inquiries/tabs/chat/chat.slice'
import roadmapSlice from './roadmap/roadmap.slice'
import recruitSlice from './recruit/recruit.slice'
import { reportsReducer } from '../modules/reports/reportsTimeSheet/redux/slice'
import jobSlice from './jobs/store/job.slice'
import jobApplicantSlice from './jobApplications/store/jobApplicant.slice'

const RouteContext = () => {
    const redux = configureStore({
        reducer: {
            usersSlice,
            projectsSlice,
            tasksSlice,
            schedulesSlice,
            feedSlice,
            sprintsSlice,
            loginSlice,
            screenshotsSlice,
            notificationsSlice,
            profileSlice,
            reportTimesheet: reportsReducer,
            masterDataSlice,
            workspaceSlice,
            talentSlice,
            teamSlice,
            filesSlice,
            notesSlice,
            roleSlice,
            standUpsSlice,
            inquirySlice,
            rolesInquirySlice,
            chatSlice,
            roadmapSlice,
            recruitSlice,
            projectReportsSlice,
            jobSlice,
            jobApplicantSlice,
        },
    })

    const googleClientId = process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID

    return (
        <Provider store={redux}>
            <ConfigProvider
                theme={{
                    components: {
                        Button: {
                            colorBgContainer: '#4F46E5',
                            colorText: '#fff',
                            fontSize: '16px',
                            colorPrimaryHover: '#fff',
                            colorPrimaryActive: '#fff',
                        },
                    },
                    token: {
                        colorPalette: {
                            baseColor: {
                                primary: '#080325',
                                secondary: '#191A38',
                                tertiary: '#4F46E5',
                                quaternary: '#F7F5FF',
                                quinary: '#CFD7DB',
                                senary: '#F6F7F9',
                                white: '#FFFFFF',
                                black: '#000000',
                                error: '#FF3838',
                            },
                            textColor: {
                                primary: '#252525',
                                secondary: '#747474',
                                tertiary: '#DBD9FF',
                                quaternary: '#B9B9B9',
                                quinary: '#E3E3E3',
                                senary: '#9197A3',
                                gray: '#919EAB29',
                                error: '#FF0000',
                            },
                            iconColor: {
                                primary: '#CFD7DB',
                                secondary: '#DADADA',
                                tertiary: '#1B806A',
                                quaternary: '#B71D18',
                            },
                            registrationColor: {
                                primary: '##031C38',
                                secondary: '#F828A6',
                            },
                        },
                        breakPints: {
                            xs: '400',
                            sm: '768',
                            md: '1200',
                            lg: '1536',
                            xl: '1920',
                        },
                        fontSizes: {},
                        fontFamily: '"Nunito Sans", sans-serif',
                    },
                }}
            >
                <BrowserRouter>
                    <GoogleOAuthProvider clientId={googleClientId}>
                        <Client />
                    </GoogleOAuthProvider>
                </BrowserRouter>
            </ConfigProvider>
        </Provider>
    )
}

export default RouteContext
