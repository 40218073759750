export const weeklyLimitOptions = [
    {
        label: '20 hours',
        value: 20,
    },
    {
        label: '25 hours',
        value: 25,
    },
    {
        label: '30 hours',
        value: 30,
    },
    {
        label: '35 hours',
        value: 35,
    },
    {
        label: '40 hours',
        value: 40,
    },
    {
        label: '45 hours',
        value: 45,
    },
    {
        label: '50 hours',
        value: 50,
    },
]
