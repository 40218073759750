import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// antd
import { Form, Row, Select, Spin, Typography, message, theme } from 'antd'

// components
import Button from '../../components/button/button'

// slices
import { workSpaceUnAssign } from '../workSpace/workSpace.slice'
import { talentState } from './talent.slice'

// assets
import { UnAssignIcon } from '../../assets/icons'
import { DOMAIN_LIST } from '../../constants/roles'

const WorkSpaceUnAssignModal = ({ cancelModal, refreshTalent }) => {
    const { useToken } = theme
    const { token } = useToken()

    const [form] = Form.useForm()

    const dispatch = useDispatch()

    const { Title, Text } = Typography

    const { selectedData } = useSelector(talentState)

    const [buttonLoading, setButtonLoading] = useState(false)
    const [errorMessages, setErrorMessages] = useState([]) // State for error messages
    const [successMessages, setSuccessMessages] = useState([])

    function onFinishFailed() {}

    // UNASSIGN WORKSPACE
    const unAssignWorkSpace = async (value) => {
        setButtonLoading(true)
        const result = await dispatch(
            workSpaceUnAssign({
                workspaceId: value?.workSpace,
                userId: selectedData?._id,
            })
        )
        const data = result?.payload?.data

        if (data && Array.isArray(data.data)) {
            let allSuccess = true
            let errors = []
            let successMessage = []

            data.data.forEach((item) => {
                const { success, message: checkMessage } = item

                if (success) {
                    successMessage.push(
                        `${item?.name || ''}: ${
                            checkMessage || 'Workspace assign successfully'
                        }`
                    )
                } else {
                    allSuccess = false
                    errors.push(
                        `${item?.name || ''}: ${
                            checkMessage ||
                            'Something went wrong, try again later.'
                        }`
                    )
                }
            })

            // Set errors in state to display in modal if any
            if (errors.length > 0) {
                setErrorMessages(errors)
            }

            // Set success in state to display in modal if any
            if (successMessage.length > 0) {
                setSuccessMessages(successMessage)
            }

            setButtonLoading(false)

            if (allSuccess) {
                refreshTalent()
                cancelModal()
            }
        } else {
            const { success, message: checkMessage } = data
            if (success) {
                message.success(checkMessage)
                refreshTalent()
                cancelModal()
                setButtonLoading(false)
            } else {
                setButtonLoading(false)
                cancelModal()
                if (checkMessage) {
                    message.error(checkMessage)
                } else {
                    message.error('Something went wrong, try again later.')
                }
            }
        }
        // if (data) {
        //     const { success, message: checkMessage } = data
        //     if (success) {
        //         message.success(checkMessage)
        //         refreshTalent()
        //         cancelModal()
        //         setButtonLoading(false)
        //     } else {
        //         setButtonLoading(false)
        //         cancelModal()
        //         if (checkMessage) {
        //             message.error(checkMessage)
        //         } else {
        //             message.error('Something went wrong, try again later.')
        //         }
        //     }
        // }
    }

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                // alignItems: 'center',
                // justifyContent: 'center',
            }}
        >
            <Row
                style={{
                    marginTop: '18px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <div
                    style={{
                        height: '30px',
                        width: '30px',
                    }}
                >
                    <UnAssignIcon />
                </div>
                <Title level={3}>Unassign Workspace</Title>
            </Row>

            <Form
                name="unAssignWorkSpace"
                onFinish={unAssignWorkSpace}
                onFinishFailed={onFinishFailed}
                form={form}
                requiredMark={false}
            >
                <Form.Item
                    name={'workSpace'}
                    rules={[
                        {
                            required: true,
                            message: 'Please Select WorkSpace',
                        },
                    ]}
                >
                    {console.log(
                        'selectedData?.workspace',
                        selectedData?.workspace
                    )}
                    <Select
                        mode="multiple"
                        filterOption={false}
                        placeholder="Search Workspace"
                        style={{
                            width: '100%',
                            marginTop: '33px',
                        }}
                        showSearch
                        // notFoundContent={
                        //     fetching ? <Spin size="small" /> : null
                        // }
                        options={
                            selectedData?.workspace?.length > 0
                                ? selectedData?.workspace
                                      ?.filter(
                                          (w) =>
                                              !DOMAIN_LIST.includes(w?.domain)
                                      )
                                      .map((workSpace) => {
                                          return {
                                              label: workSpace?.companyName,
                                              value: workSpace?._id,
                                              logo: workSpace?.logo,
                                              domain: workSpace?.domain,
                                          }
                                      })
                                : {}
                        }
                    />
                </Form.Item>
                <Row
                    align={'middle'}
                    justify={'start'}
                    style={{
                        marginTop: '10px',
                        color: 'green',
                    }}
                >
                    <div>
                        {successMessages?.length > 0 &&
                            successMessages.map((msg, index) => (
                                <div key={index}>{msg}</div> // Display each message in a separate line
                            ))}
                    </div>
                </Row>
                <Row
                    align={'middle'}
                    justify={'start'}
                    style={{
                        color: 'red',
                    }}
                >
                    <div>
                        {errorMessages?.length > 0 &&
                            errorMessages.map((msg, index) => (
                                <div key={index}>{msg}</div> // Display each message in a separate line
                            ))}
                    </div>
                </Row>
                <Row
                    align={'middle'}
                    justify={'end'}
                    style={{
                        marginTop: '34px',
                    }}
                >
                    <Button
                        props={{
                            text: 'Cancel',
                            buttonType: 'link',
                            onClick: () => cancelModal(),
                            style: {
                                color: token.colorPalette.baseColor.black,
                            },
                        }}
                    />
                    <Button
                        props={{
                            text: 'Unassign',
                            htmlType: 'submit',
                            loadingButton: buttonLoading,
                        }}
                    />
                </Row>
            </Form>
            {/* <Row
                style={{
                    marginTop: '18px',
                }}
            >
                <Text className="titleSub" style={{ textAlign: 'center' }}>
                    Are you sure you want to unassigned this workspace from
                    user?
                </Text>
            </Row>
            <Divider
                style={{
                    border: `1px solid ${token.colorPalette.textColor.quaternary}`,
                }}
            />
            <Row align={'middle'}>
                <Button
                    props={{
                        text: 'Cancel',
                        buttonType: 'link',
                        onClick: () => cancelModal(),
                        style: {
                            color: token.colorPalette.baseColor.black,
                        },
                    }}
                />
                <Button
                    props={{
                        text: 'Unassign',
                        onClick: () => {
                            unAssignWorkSpace()
                        },
                        loadingButton: buttonLoading,
                    }}
                />
            </Row> */}
        </div>
    )
}

export default WorkSpaceUnAssignModal
