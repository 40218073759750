import React from 'react'

function SubTaskIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="26"
            height="26"
            fill="none"
            viewBox="0 0 26 26"
        >
            <path
                fill="#4BAEE8"
                d="M22.286 0H3.714A3.714 3.714 0 000 3.714v18.572A3.714 3.714 0 003.714 26h18.572A3.714 3.714 0 0026 22.286V3.714A3.714 3.714 0 0022.286 0z"
            ></path>
            <path
                stroke="#fff"
                d="M13.371 5.573H7.057c-.82 0-1.486.665-1.486 1.486v6.314c0 .82.665 1.486 1.486 1.486h6.314c.82 0 1.486-.665 1.486-1.486V7.06c0-.82-.665-1.486-1.486-1.486z"
            ></path>
            <path
                fill="#fff"
                stroke="#fff"
                d="M18.943 11.143h-6.315c-.82 0-1.485.665-1.485 1.485v6.315c0 .82.665 1.485 1.485 1.485h6.315c.82 0 1.485-.665 1.485-1.485v-6.315c0-.82-.665-1.485-1.485-1.485z"
            ></path>
        </svg>
    )
}

export default SubTaskIcon
