// antd
import { Row, Typography, theme } from 'antd'
import { LinkOutlined } from '@ant-design/icons'

// assets
import { DownloadFileIcon } from '../../assets/icons'

// helpers
import {
    downloadFileGet,
    splitLastOccurrence,
} from '../../helpers/fileDownload'

// styles
import styles from './standUps.module.css'

function PreviewStandupVideo({ data, files, selectedData }) {
    const { useToken } = theme

    const { token } = useToken()

    const { Title, Text } = Typography

    return (
        <>
            <Row
                align={'middle'}
                justify={'space-between'}
                style={{
                    padding: '12px 20px',
                }}
            >
                <div>
                    <Row
                        align={'middle'}
                        style={{
                            columnGap: '10px',
                        }}
                    >
                        <Title className="sub-title" level={4}>
                            Preview
                        </Title>
                        <Row
                            className={styles.tooltip}
                            onMouseOut={() => {
                                var tooltip =
                                    document.getElementById('myTooltip')
                                tooltip.innerHTML = 'Copy to clipboard'
                            }}
                        >
                            <span class={styles.tooltiptext} id="myTooltip">
                                Copy to clipboard
                            </span>
                            <LinkOutlined
                                onClick={() => {
                                    var tooltip =
                                        document.getElementById('myTooltip')
                                    tooltip.innerHTML = 'Copied'
                                    navigator.clipboard.writeText(
                                        `https://pms.softwareco.com/dashboard/home?video=${selectedData?._id}`
                                    )
                                }}
                            />
                        </Row>
                    </Row>
                    <div>
                        <Text
                            style={{
                                color: token.colorPalette.textColor.secondary,
                            }}
                        >
                            by {selectedData?.userId?.name},
                        </Text>
                    </div>
                </div>
                <div
                    style={{
                        cursor: 'pointer',
                    }}
                    onClick={() => {
                        if (files?.length > 0) {
                            downloadFileGet(
                                files[0],
                                splitLastOccurrence(
                                    files[0]?.split('.com/')[1],
                                    '_'
                                )
                            )
                        } else {
                            downloadFileGet(
                                data?.screen,
                                splitLastOccurrence(
                                    files[0]?.split('.com/')[1],
                                    '_'
                                )
                            )
                        }
                    }}
                >
                    <DownloadFileIcon />
                </div>
            </Row>
            <div
                style={{
                    // height: '70vh',
                    padding: '0px 66px 21px 66px',
                    backgroundColor: token.colorPalette.textColor.quinary,
                }}
            >
                <video
                    src={files?.length > 0 ? files[0] : data?.screen}
                    style={{
                        position: 'relative',
                        borderRadius: '5px',
                    }}
                    onPlay={() => {
                        const videoScreen =
                            document.getElementById('videoScreen')
                        const videoFace = document.getElementById('videoMute')
                        if (videoScreen !== null) {
                            videoScreen.play()
                        } else if (videoFace !== null) {
                            videoFace.play()
                        }
                    }}
                    onSeeked={() => {
                        const videoScreen =
                            document.getElementById('videoScreen')
                        const videoFace = document.getElementById('videoMute')
                        if (videoScreen !== null) {
                            const newTime = videoScreen.currentTime
                            videoFace.currentTime = newTime
                        }
                    }}
                    onPause={() => {
                        const videoScreen = document.getElementById('videoFace')
                        const videoFace = document.getElementById('videoMute')
                        if (videoScreen !== null) {
                            videoScreen.pause()
                        } else if (videoFace !== null) {
                            videoFace.pause()
                        }
                    }}
                    id="videoScreen"
                    loop
                    autoPlay
                    width="580px"
                    controls
                    height="325px"
                />
                <div
                    style={{
                        height: '100px',
                        width: '150px',
                        borderRadius: '10px',
                    }}
                >
                    <video
                        src={data?.face}
                        autoPlay
                        id="videoMute"
                        loop
                        style={{
                            position: 'absolute',
                            bottom: 18,
                            right: 40,
                            borderRadius: '10px',
                            objectFit: 'cover',
                        }}
                        height="100px"
                        width="150px"
                    />
                </div>
            </div>
        </>
    )
}

export default PreviewStandupVideo
