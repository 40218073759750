import axiosNew from '../../../helpers/axios'

// LIST JOB APPLICATIONS STATUS
export const apiGetStatusWiseCount = (params) => {
    return new Promise(async (resolve) => {
        try {
            const jobs = await axiosNew({
                url: 'users/get-status-wise-count',
                method: 'get',
                params,
            })
            resolve({
                data: jobs?.data ?? [],
            })
        } catch (error) {
            resolve({ data: [] })
        }
    })
}

// LIST USERS
export const apiJobApplicantListByStatus = (payload) => {
    const { search = '', skip = 0, limit = 0, filter = {} } = payload
    return new Promise(async (resolve) => {
        try {
            let data = {
                skip,
                filter,
                limit,
                select: [
                    'name',
                    'email',
                    'employeeNumber',
                    'phone',
                    'countryCode',
                    'flag',
                    'address',
                    'employmentType',
                    'state',
                    'department',
                    'designation',
                    'emergencyName',
                    'emergencyPhone',
                    'notes',
                    'role',
                    'isActive',
                    'profilePicture',
                    'isOurUser',
                    'isAssigned',
                    'isWorkspaceAssigned',
                    'isTalentUser',
                    'price',
                    'dailyWorkingHours',
                    'weeklyWorkingDays',
                    'status',
                    'timeZone',
                    'job',
                    'location',
                    'resume',
                ],
                populate: {
                    name: [
                        {
                            path: 'role',
                            select: 'roleName',
                        },
                        {
                            path: 'workspace',
                            select: 'domain logo companyName',
                        },
                        {
                            path: 'designation',
                            select: 'name',
                        },
                    ],
                },
            }
            if (search?.length > 0) {
                data['search'] = {
                    name: search,
                    email: search,
                    phone: search,
                }
            }
            const users = await axiosNew({
                url: 'users/read',
                data,
                method: 'post',
            })
            resolve({
                data:
                    users?.data && users?.data?.result
                        ? users?.data?.result
                        : [],
                isNext: users?.data?.isNext,
            })
        } catch (error) {
            resolve({ data: [] })
        }
    })
}

// UPDATE JOB APPLICATION STATUS
export const apiUpdateJobApplicationStatus = async (id, payload) => {
    return new Promise(async (resolve) => {
        try {
            const update = await axiosNew({
                url: 'users/update/' + id,
                data: { data: payload },
            })
            resolve({ data: update?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// ADD JOB APPLICATION NOTE
export const apiAddJobApplicationNote = async (payload) => {
    return new Promise(async (resolve) => {
        try {
            const update = await axiosNew({
                url: 'job-applicant/note/create',
                data: payload,
                method: 'post',
            })
            resolve({ data: update?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// GET JOB APPLICATION NOTES
export const apiGetJobApplicationNotes = async (params) => {
    return new Promise(async (resolve) => {
        try {
            const notes = await axiosNew({
                url: 'job-applicant/note/list',
                method: 'get',
                params,
            })
            resolve({ data: notes?.data ?? [] })
        } catch (error) {
            resolve({ data: [] })
        }
    })
}
