import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// antd
import { Row, theme, Select, Col } from 'antd'

// slices
import { sprintsState } from '../sprints/sprints.slice'
import { loginState } from '../login/login.slice'
import {
    clearFilterDropDown,
    tasksState,
    filterDropdown,
    taskList,
    searchTasks,
} from './tasks.slice'

// styles
import Button from '../../components/button/button'

const TasksFilter = ({ props }) => {
    const { clearSearch } = props

    const { useToken } = theme

    const { token } = useToken()

    const { pageLimit, skipPage } = useSelector(tasksState)
    const { sprints } = useSelector(sprintsState)
    const { userProfile } = useSelector(loginState)

    const dispatch = useDispatch()

    const [sprintsOption, setSprintsOption] = useState([])

    const initialValues = useMemo(() => {
        return {
            sprintId:
                localStorage.getItem('currentSprint') &&
                JSON.parse(localStorage.getItem('currentSprint'))?.id,
            assignee: [],
        }
    }, [])

    const [values, setValues] = useState({})

    useEffect(() => {
        setValues(initialValues)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const backlogId = sprints
            .filter((data) => data?.title === 'Backlog')
            .map((data) => data)
        localStorage.setItem(
            'currentSprint',
            JSON.stringify({
                id: backlogId[0]?._id,
                name: backlogId[0]?.title,
            })
        )

        initialValues['sprintId'] = backlogId[0]?._id
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sprints, sprints.length])

    // SET SPRINT OPTIONS DEFAULT
    useEffect(() => {
        if (sprints?.length > 0) {
            const options = sprints
                .filter((data) => !data?.isComplete)
                .map((data) => {
                    return {
                        label: data?.title,
                        value: data?._id,
                    }
                })
            setSprintsOption([...options])
        }
    }, [sprints])

    // APPLY FILTER
    function applyFilter(name, value) {
        setValues({
            ...values,
            [name]: value,
        })
        let newFilterValue = {}

        Object.keys(values).forEach((data) => {
            if (values[data] !== '' || values[data]?.length !== 0) {
                newFilterValue = { ...newFilterValue, [name]: value }
                dispatch(filterDropdown({ [name]: value }))
            } else {
                newFilterValue = { ...newFilterValue }
                dispatch(filterDropdown({}))
            }
        })

        dispatch(
            taskList({
                limit: pageLimit,
                skip: skipPage,
                filter: {
                    ...newFilterValue,
                    projectId: userProfile?.activeProject?._id,
                },
            })
        )

        const backlogId = sprints
            .filter((data) => data?.title === 'Backlog')
            .map((data) => data)
        localStorage.setItem(
            'currentSprint',
            JSON.stringify({
                id: backlogId[0]?._id,
                name: backlogId[0]?.title,
            })
        )
    }

    function clearFilter() {
        setValues(initialValues)
        clearSearch()
        dispatch(searchTasks(''))
        dispatch(clearFilterDropDown())
        const backlogId = sprints
            .filter((data) => data?.title === 'Backlog')
            .map((data) => data)
        localStorage.setItem(
            'currentSprint',
            JSON.stringify({
                id: backlogId[0]?._id,
                name: backlogId[0]?.title,
            })
        )
        dispatch(filterDropdown({ sprintId: backlogId[0]?._id }))

        dispatch(
            taskList({
                skip: skipPage,
                limit: pageLimit,
                filter: {
                    projectId: userProfile?.activeProject?._id,
                    sprintId: backlogId[0]?._id,
                },
            })
        )
    }

    return (
        <Row align={'middle'} gutter={[15, 15]}>
            <Col span={16}>
                <Select
                    placeholder="Sprints"
                    defaultValue={
                        initialValues?.sprintId &&
                        sprintsOption?.length > 0 &&
                        initialValues?.sprintId
                    }
                    className={['arrowStatusSelect']}
                    value={values?.sprintId}
                    onChange={(value) => {
                        applyFilter('sprintId', value)
                    }}
                    style={{
                        width: '100%',
                    }}
                    options={sprintsOption}
                />
            </Col>
            <Col span={6}>
                <Button
                    props={{
                        text: 'Clear Filter',
                        onClick: () => clearFilter(),
                        buttonType: 'link',
                        style: {
                            padding: '0px',
                            color: token.colorPalette.baseColor.black,
                        },
                    }}
                />
            </Col>
        </Row>
    )
}

export default TasksFilter
