import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
    count,
    read,
    addRemoveFile,
    update,
    fileGet,
    fileDownload,
} from './files.service'

const initialState = {
    search: '',
    status: 'loaded',
    getUpdatedStatus: 'loaded',
    selectedData: undefined,
    filesList: [],
    filesIsNext: false,
    filesPageLimit: 10,
    filesCurrentPage: 1,
    filesSkipPage: 0,
    filesCount: 0,
    editInput: { open: false, id: '' },
}

export const totalFiles = createAsyncThunk(
    'filesSlice/count',
    async (payload = {}) => {
        const response = await count(payload)
        return response
    }
)

export const filesListGet = createAsyncThunk(
    'filesSlice/read',
    async (payload = {}) => {
        const response = await read(payload)
        return response
    }
)

export const addRemoveProjectFiles = createAsyncThunk(
    'filesSlice/addRemoveFile',
    async (payload = {}) => {
        const response = await addRemoveFile(payload)
        return response
    }
)

export const updateFile = createAsyncThunk(
    'filesSlice/update',
    async (payload = {}) => {
        const response = await update(payload)
        return response
    }
)

export const getFile = createAsyncThunk(
    'filesSlice/fileGet',
    async (payload = {}) => {
        const response = await fileGet(payload)
        return response
    }
)

export const downloadFile = createAsyncThunk(
    'filesSlice/fileDownload',
    async (payload = {}) => {
        const response = await fileDownload(payload)
        return response
    }
)

export const filesSlice = createSlice({
    name: 'filesSlice',
    initialState,
    reducers: {
        changePage: (state, action) => {
            state.filesCurrentPage = action.payload.currentPage
            state.filesSkipPage = action.payload.skip
            state.filesPageLimit = action.payload.pageLimit
        },
        searchFile: (state, action) => {
            state.search = action.payload
        },
        clearUsers: (state) => {
            state.filesList = []
            state.search = ''
        },
        changeEditInput: (state, action) => {
            state.editInput = {
                id: action.payload.id,
                open: action.payload.open,
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(filesListGet.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(filesListGet.fulfilled, (state, action) => {
                state.status = 'loaded'
                state.filesList = action.payload.data
                state.filesIsNext = action.payload.isNext
            })
            .addCase(totalFiles.fulfilled, (state, action) => {
                state.filesCount = action.payload.data.totalFiles
            })
            .addCase(getFile.pending, (state) => {
                state.getUpdatedStatus = 'loading'
            })
            .addCase(getFile.fulfilled, (state, action) => {
                state.getUpdatedStatus = 'loaded'
                state.selectedData = action?.payload.data
            })
    },
})

export default filesSlice.reducer

export const filesState = (state) => {
    const { filesSlice } = state
    const {
        search,
        status,
        getUpdatedStatus,
        formButtonType,
        selectedData,
        modalVariations,
        filesList,
        filesIsNext,
        filesPageLimit,
        filesCurrentPage,
        filesSkipPage,
        filesCount,
        editInput,
    } = filesSlice
    return {
        search,
        status,
        getUpdatedStatus,
        formButtonType,
        selectedData,
        modalVariations,
        filesList,
        filesIsNext,
        filesPageLimit,
        filesCurrentPage,
        filesSkipPage,
        filesCount,
        editInput,
    }
}

export const { changePage, changeEditInput, searchFile, clearUsers } =
    filesSlice.actions
