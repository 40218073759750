import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'

// antd
import { Col, Form, Input, message, Row, theme, Typography } from 'antd'

// slices
import { passwordForgot } from './login.slice'
import { userGet } from '../users/users.slice'

// routes
import { PUBLIC_ROUTES } from '../../routes'

// components
import Button from '../../components/button/button'

// constants
import { PASSWORD_PATTERN } from '../../constants/patterns'

// assets
import { PasswordHideIcon, PasswordShowIcon } from '../../assets/icons'
import RegistrationVideo from '../../assets/videos/registration_video.mp4'
import Logo from '../../assets/images/logo.png'

function SetPassword() {
    const [form] = Form.useForm()

    const { Title } = Typography

    const { useToken } = theme

    const dispatch = useDispatch()

    const navigate = useNavigate()

    const { token } = useToken()
    const params = useLocation()

    const json = Object.fromEntries(new URLSearchParams(params?.search))

    const [loadingButton, setLoadingButton] = useState(false)
    const [linkExpire, setLinkExpire] = useState(false)

    function onFinishFailed() {}

    // GET USER DETAIL
    async function getUserDetail(id) {
        const result = await dispatch(userGet({ _id: id, password: true }))
        const data = result?.payload?.data
        if (data) {
            const { success, message: checkMessage } = data
            if (success) {
                if (data?.data?.password) {
                    navigate(PUBLIC_ROUTES.linkExpired)
                }
            } else {
                message.error(checkMessage)
            }
        }
    }

    useEffect(() => {
        if (json) {
            getUserDetail(json?.userId)
        }
    }, [])

    async function onUpdatePassword(value) {
        setLoadingButton(true)

        const result = await dispatch(
            passwordForgot({
                userId: json?.userId,
                password: value.password,
            })
        )
        const data = result?.payload?.data
        if (data) {
            const { success, message: checkMessage } = data
            if (success) {
                setLoadingButton(false)
                message.success(checkMessage)
                navigate(PUBLIC_ROUTES.successfullyResetPassword)
            } else {
                setLoadingButton(false)
                if (checkMessage) {
                    message.error(checkMessage)
                } else {
                    message.error('Something went wrong, try again later.')
                }
            }
        }
    }

    return (
        <div className={`main-div`}>
            <video
                src={RegistrationVideo}
                autoPlay
                loop
                style={{
                    objectFit: 'cover',
                    position: 'relative',
                }}
                width={'66%'}
                height={'100%'}
                muted
            />
            <img
                style={{
                    position: 'absolute',
                    top: '34px',
                    left: '60px',
                    objectFit: 'contain',
                }}
                src={Logo}
                alt="logo"
                height={'45px'}
            />
            <div className="background-img">
                <div
                    style={{
                        padding: '60px 60px 0px 60px',
                    }}
                >
                    <Title level={3}>Set Password</Title>
                    <Form
                        layout="vertical"
                        name="updatePassword"
                        onFinish={onUpdatePassword}
                        onFinishFailed={onFinishFailed}
                        requiredMark={false}
                        form={form}
                        style={{
                            marginTop: '20px',
                        }}
                    >
                        <Col
                            span={24}
                            style={{
                                padding: '0px',
                                marginBottom: '10px',
                            }}
                        >
                            <Form.Item
                                label="New Password"
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Enter Password',
                                    },
                                    {
                                        validator: async (_, value) => {
                                            const pattern = PASSWORD_PATTERN

                                            if (
                                                !pattern.test(value) &&
                                                value?.length > 0
                                            ) {
                                                return Promise.reject(
                                                    new Error(
                                                        'Minimum 8 characters and maximum 32 characters, at least one uppercase letter, one lowercase letter, one number and one special character are allowed'
                                                    )
                                                )
                                            }
                                        },
                                    },
                                ]}
                                colon={false}
                            >
                                <Input.Password
                                    placeholder="Enter Password"
                                    style={{
                                        padding: '0px 11px',
                                    }}
                                    iconRender={(visible) =>
                                        visible ? (
                                            <div
                                                onClick={() => {}}
                                                style={{
                                                    cursor: 'pointer',
                                                }}
                                            >
                                                <PasswordShowIcon />
                                            </div>
                                        ) : (
                                            <div
                                                onClick={() => {}}
                                                style={{
                                                    cursor: 'pointer',
                                                }}
                                            >
                                                <PasswordHideIcon />
                                            </div>
                                        )
                                    }
                                />
                            </Form.Item>
                        </Col>
                        <Col
                            span={24}
                            style={{
                                padding: '0px',
                                marginBottom: '10px',
                            }}
                        >
                            <Form.Item
                                label="Confirm Password"
                                name="passwordConfirm"
                                dependencies={['password']}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Enter Password',
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (
                                                !value ||
                                                getFieldValue('password') ===
                                                    value
                                            ) {
                                                return Promise.resolve()
                                            }
                                            return Promise.reject(
                                                new Error(
                                                    'The Password that you entered do not match!'
                                                )
                                            )
                                        },
                                    }),
                                ]}
                                colon={false}
                            >
                                <Input.Password
                                    placeholder="Enter Password"
                                    style={{
                                        padding: '0px 11px',
                                    }}
                                    iconRender={(visible) =>
                                        visible ? (
                                            <div
                                                onClick={() => {}}
                                                style={{
                                                    cursor: 'pointer',
                                                }}
                                            >
                                                <PasswordShowIcon />
                                            </div>
                                        ) : (
                                            <div
                                                onClick={() => {}}
                                                style={{
                                                    cursor: 'pointer',
                                                }}
                                            >
                                                <PasswordHideIcon />
                                            </div>
                                        )
                                    }
                                />
                            </Form.Item>
                        </Col>
                        <Row
                            gutter={20}
                            style={{
                                marginTop: '20px',
                            }}
                        >
                            <Col span={24}>
                                <Button
                                    props={{
                                        text: 'Log In',
                                        htmlType: 'submit',
                                        style: {
                                            backgroundColor:
                                                token.colorPalette
                                                    .registrationColor
                                                    .secondary,
                                            width: '100%',
                                        },
                                        disabled: loadingButton,
                                        loadingButton: loadingButton,
                                    }}
                                />
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
        </div>
    )
}

export default SetPassword
