import React, { useState } from 'react'
import { useSelector } from 'react-redux'

// antd
import { Row, Typography, Steps, Spin, Tooltip } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'

// slices
import { projectsState } from './projects.slice'

// components
import InviteMemberSection from './inviteMemberSection'
import NewProjectForm from './newProjectForm'

const FormModal = ({ formType, refreshProject, onCancel }) => {
    const { Title, Text } = Typography

    const { selectedProject, updatedDataStatus, stepProjectData } =
        useSelector(projectsState)

    const [current, setCurrent] = useState(0)

    const onChange = (value) => {
        setCurrent(value)
    }

    const items = [
        {
            title: 'Project Details',
            content: (
                <NewProjectForm
                    onCancel={onCancel}
                    refreshProject={refreshProject}
                    setStep={() => setCurrent(1)}
                />
            ),
        },
        {
            title: (
                <Row
                    align={'middle'}
                    style={{
                        columnGap: '6px',
                    }}
                >
                    <Text
                        style={{
                            fontSize: '16px',
                        }}
                    >
                        Add People
                    </Text>
                    <Tooltip title="Only Assigned user can be in the list">
                        <InfoCircleOutlined size={10} />
                    </Tooltip>
                </Row>
            ),
            content: (
                <>
                    <InviteMemberSection
                        setStep={() => setCurrent(0)}
                        onCancel={onCancel}
                        refreshProject={refreshProject}
                        formType={formType}
                    />
                </>
            ),
            disabled:
                stepProjectData &&
                Object.keys(stepProjectData).every(
                    (data) => stepProjectData[data]?.length > 0
                )
                    ? false
                    : true,
        },
    ]

    return updatedDataStatus === 'loading' && selectedProject === undefined ? (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Spin size="large" />
        </div>
    ) : (
        <>
            <Row
                align={'middle'}
                justify={'center'}
                style={{
                    marginBottom: '27px',
                }}
            >
                <Title level={4} style={{ margin: 0 }}>
                    {`${selectedProject ? 'Update' : 'Create'} Project`}
                </Title>
            </Row>
            {/* <FormContent /> */}
            <Row
                style={{
                    width: '100%',
                }}
                justify={'center'}
            >
                {!selectedProject && (
                    <Steps
                        current={current}
                        items={items}
                        onChange={onChange}
                        rootClassName="projectsStep"
                        labelPlacement="vertical"
                    />
                )}
                <div
                    style={{
                        marginTop: '30px',
                        width: '100%',
                    }}
                >
                    {items[current].content}
                </div>
            </Row>
        </>
    )
    // )
}

export default FormModal
