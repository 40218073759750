import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

// antd
import {
    Col,
    Form,
    Input,
    Row,
    Select as NewSelect,
    Space,
    Avatar,
    Typography,
    DatePicker,
    theme,
    Spin,
    Popover,
    Popconfirm,
    Button as ButtonAnt,
    message,
    Tabs,
    Divider,
    Tooltip,
    Image,
} from 'antd'
import { PauseCircleFilled, InfoCircleOutlined } from '@ant-design/icons'

// components
import DeletePopUp from '../../components/deletePopUp/deletePopUp'
import Button from '../../components/button/button'

// slices
import { getSprint, sprintSelect, sprintsState } from '../sprints/sprints.slice'
import { projectsState } from '../projects/projects.slice'
import {
    clearTaskFileUpload,
    getSubTaskList,
    selectSubTaskUpdate,
    selectTaskUpdate,
    setGlobalTimer,
    switchFormModal,
    taskById,
    taskCreate,
    taskDelete,
    taskList,
    taskUpdate,
    tasksState,
} from './tasks.slice'
import { isLoggedInLogin, loginState } from '../login/login.slice'

// assets
import {
    DeleteIcon,
    EllipsisIcon,
    TaskIcon,
    TaskLinkIcon,
    TimerIcon,
} from '../../assets/icons'

// sections
import CommentsSection from './comments.section'
import ActivitySection from './activity.section'

// services
import { startStopTimer } from './tasks.service'

// sections
import SubTaskSection from './subTask.section'

// attachments
import AttachmentFileSection from './attachment.section'

// styles
import styles from './tasks.module.css'

// constants
import {
    deletePermissionCheck,
    editFieldCheck,
    viewFieldCheck,
} from '../../helpers/permissionCheck'
import { isEmpty } from '../../helpers/fieldCheck'

// constants
import { ESTIMATION_PATTERN } from '../../constants/patterns'
import { priorityType } from '../../constants/tasks.index'

import dayjs from 'dayjs'

const DrawerTaskForm = ({
    assigneeList,
    onCancel,
    activeTab,
    activeTabName,
    listTaskCall,
}) => {
    const { Text } = Typography

    const { TextArea } = Input

    const { useToken } = theme

    const { token } = useToken()

    const { RangePicker } = DatePicker

    const { Option } = NewSelect

    const dispatch = useDispatch()

    const params = useLocation()

    const { epics, sprints } = useSelector(sprintsState)
    const { userProfile } = useSelector(loginState)
    const { currentProjectData } = useSelector(projectsState)

    const { pageLimit, skipPage, selectedTask, taskStatus, taskFiles } =
        useSelector(tasksState)

    const initialValues = useMemo(() => {
        return {
            title: '',
            type: [],
            epic: [],
            status: [],
            rangePicker: [],
            sprintId: activeTab ? activeTab : [],
            estimation: 0,
            start: '',
            end: '',
            assigneeId: [],
            description: '',
            projectId: userProfile?.activeProject?._id,
        }
    }, [])

    const [form] = Form.useForm()
    const [sprintsOption, setSprintsOption] = useState([])
    const [epicsOption, setEpicsOption] = useState([])
    const [swimLanesOption, setSwimLanesOption] = useState([])
    const [taskTypeOption, setTaskTypeOption] = useState([])
    const [values, setValues] = useState(initialValues)
    const [errors, setErrors] = useState(initialValues)
    const [updatedValues, setUpdatedValues] = useState({})
    const [taskLoadingButton, setTaskLoadingButton] = useState(false)
    const [timer, setTimer] = useState(false)
    const [selected, setSelected] = useState({})
    const [taskID, setTaskID] = useState('')
    const [deleteCheck, setDeleteCheck] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const [deleteButtonLoading, setDeleteButtonLoading] = useState(false)

    const [open, setOpen] = useState(false)

    const handleOpenChange = (newOpen) => {
        setOpen(newOpen)
        if (newOpen === true) {
            setDeleteCheck(true)
        } else {
            setDeleteCheck(false)
        }
    }

    const dropDownIcon = [
        {
            title: deletePermissionCheck('tasks', userProfile) ? (
                'Delete'
            ) : (
                <Tooltip
                    arrow={false}
                    placement="bottom"
                    title="You don't have permission to delete task"
                >
                    <Text
                        style={{
                            margin: '0px',
                        }}
                    >
                        Delete
                    </Text>
                </Tooltip>
            ),
            icon: <DeleteIcon />,
            onClick: (_id) => {
                if (deletePermissionCheck('tasks', userProfile)) {
                    setDeleteCheck(true)
                    setTaskID(_id)
                    setDeleteModal(true)
                }
            },
        },
    ]

    const dropDownContent = (id) => {
        return (
            <>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        rowGap: '8px',
                    }}
                >
                    {dropDownIcon?.length > 0 &&
                        dropDownIcon.map((data) => (
                            <Row
                                style={{
                                    cursor: 'pointer',
                                }}
                                align={'middle'}
                                onClick={() => data?.onClick(id)}
                            >
                                <div
                                    style={{
                                        marginRight: '10px',
                                    }}
                                >
                                    {data?.icon}
                                </div>
                                <Text className="sub-title">{data?.title}</Text>
                            </Row>
                        ))}
                </div>
            </>
        )
    }

    const tabs = [
        {
            key: 'comments',
            label: 'Comments',
            children: <CommentsSection />,
        },
        {
            key: 'activity',
            label: 'Activity',
            children: <ActivitySection />,
        },
    ]

    const formatDatePicker = (value) => {
        return `${dayjs(value).format('DD MMM YY')}`
    }

    // GET EPICS
    useEffect(() => {
        if (epics?.length > 0) {
            const options = epics.map((data) => {
                return {
                    label: data?.title,
                    value: data?._id,
                    epicKey: data?.epicKey,
                }
            })
            setEpicsOption(options)
        }
    }, [epics])

    // GET SPRINTS
    useEffect(() => {
        if (sprints?.length > 0) {
            const options = sprints
                .filter((data) => !data?.isComplete)
                .map((data) => {
                    return {
                        label: data?.title,
                        value: data?._id,
                    }
                })
            setSprintsOption(options)
        }
    }, [sprints])

    // GET SWIMLANES
    useEffect(() => {
        if (currentProjectData?.swimlanes?.length > 0) {
            const options = currentProjectData?.swimlanes.map((data) => {
                return {
                    label: data?.name,
                    value: data?._id,
                }
            })
            setSwimLanesOption(options)
        }
        if (currentProjectData?.taskType?.length > 0) {
            const options = currentProjectData?.taskType.map((data) => {
                return {
                    label: data,
                    value: data,
                }
            })
            setTaskTypeOption(options)
        }
    }, [currentProjectData])

    async function subTaskListGet() {
        await dispatch(
            getSubTaskList({
                skip: 0,
                limit: 10,
                parentId: selectedTask?._id,
            })
        )
    }

    // GET TASKS
    useEffect(() => {
        if (selectedTask) {
            subTaskListGet()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTask])

    useEffect(() => {
        if (selectedTask !== undefined) {
            const { _id: value, title: label } = {
                ...selectedTask?.epic[0],
            }
            const {
                _id: valueAssignee,
                name: labelAssignee,
                profilePicture,
            } = {
                ...selectedTask?.assigneeId[0],
            }
            const new_obj = Object.assign(
                {},
                {
                    label,
                    value,
                }
            )

            const new_obj_assignee = Object.assign(
                {},
                {
                    label: labelAssignee && (
                        <Space size="small">
                            {profilePicture ? (
                                <Image
                                    src={profilePicture}
                                    height={'30px'}
                                    preview={false}
                                    width={'30px'}
                                    style={{
                                        borderRadius: '50%',
                                    }}
                                />
                            ) : (
                                <Avatar
                                    style={{
                                        backgroundColor: 'rgb(0 10 129 / 25%)',
                                        cursor: 'pointer',
                                        height: '30px',
                                        width: '30px',
                                    }}
                                >
                                    {labelAssignee[0].toUpperCase()}
                                    {labelAssignee.split(' ')[1] !==
                                        undefined &&
                                    labelAssignee.split(' ')[1] !== ''
                                        ? labelAssignee
                                              .split(' ')[1][0]
                                              .toUpperCase()
                                        : ''}
                                </Avatar>
                            )}
                            <Text
                                style={{
                                    margin: 0,
                                }}
                            >
                                {labelAssignee && labelAssignee}
                            </Text>
                        </Space>
                    ),
                    value: valueAssignee,
                }
            )

            if (selectedTask) {
                const { _id: valueSprint, title: labelSprint } = {
                    ...selectedTask?.sprintId[0],
                }

                const type = [
                    {
                        label: selectedTask?.type,
                        value: selectedTask?.type,
                    },
                ]
                const swimLanes = [
                    {
                        label: selectedTask?.status,
                        value: selectedTask?.status,
                    },
                ]

                const new_obj_sprint = Object.assign(
                    {},
                    {
                        label: labelSprint,
                        value: valueSprint,
                    }
                )

                form.setFieldsValue({
                    ...selectedTask,
                    type,
                    rangePicker: [
                        selectedTask?.start
                            ? dayjs(selectedTask?.start, 'YYYY/MM/DD')
                            : '',
                        selectedTask?.end
                            ? dayjs(selectedTask?.end, 'YYYY/MM/DD')
                            : '',
                    ],
                    estimation: checkEstimation(selectedTask?.estimation)
                        .trimStart()
                        .trimEnd(),
                    start: selectedTask?.start
                        ? dayjs(selectedTask?.start, 'YYYY/MM/DD')
                        : '',
                    end: selectedTask?.end
                        ? dayjs(selectedTask?.end, 'YYYY/MM/DD')
                        : '',
                    sprintId: [{ ...new_obj_sprint }],
                    epic: [{ ...new_obj }],
                    assigneeId:
                        new_obj_assignee?.label === undefined &&
                        new_obj_assignee?.value === undefined
                            ? []
                            : [{ ...new_obj_assignee }],
                    status: swimLanes,
                })
            }
            if (activeTab) {
                if (selectedTask) {
                    form.setFieldsValue({
                        ...selectedTask,
                        estimation:
                            checkEstimation(selectedTask?.estimation)
                                .trimStart()
                                .trimEnd() || 0,
                        epic: [{ ...new_obj }],
                        assigneeId: [{ ...new_obj_assignee }],
                        sprintId: [{ label: activeTabName, value: activeTab }],
                    })
                }
            }
        } else {
            form.resetFields()
            dispatch(clearTaskFileUpload())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form, selectedTask, activeTab, params])

    // CHECK ESTIMATION
    function checkEstimation(value) {
        let estimateMinutes = `0m`
        let estimateHours = `0h`
        let estimateWeeks = `0w`
        let estimateDays = `0d`
        const defaultTime = currentProjectData?.defaultTime
        const defaultWeek = currentProjectData?.defaultWeek
        let minutes = value
        if (minutes > 60) {
            estimateMinutes = `${minutes % 60}m`
            const hours = parseInt(minutes / 60)
            if (hours >= defaultTime) {
                estimateHours = `${hours % defaultTime}h`
                const days = parseInt(hours / defaultTime)
                if (days > defaultWeek) {
                    estimateDays = `${days % defaultWeek}d`
                    estimateWeeks = `${parseInt(days / defaultWeek)}w`
                } else {
                    estimateDays = `${days}d`
                }
            } else {
                estimateHours = `${hours}h`
            }
        } else {
            estimateMinutes = `${minutes}m`
        }

        const estimationNew = `${
            estimateWeeks.split('w')[0] !== '0' ? `${estimateWeeks} ` : ''
        }${estimateDays.split('d')[0] !== '0' ? `${estimateDays} ` : ''}${
            estimateHours.split('h')[0] !== '0' ? `${estimateHours} ` : ''
        }${estimateMinutes.split('m')[0] !== '0' ? estimateMinutes : ''}`
        form.setFieldValue('estimation', estimationNew.trimStart().trimEnd())
        return estimationNew
    }

    // CHECK ESTIMATION
    function checkFinalEstimation(value) {
        let estimateMinutes = 0

        const splitValue = value.split(' ')

        const defaultTime = currentProjectData?.defaultTime
        const defaultWeek = currentProjectData?.defaultWeek

        splitValue.forEach((data) => {
            if (data.includes('w')) {
                let week = parseInt(data.split('w')[0])
                estimateMinutes += week * defaultWeek * defaultTime * 60
            }
            if (data.includes('d')) {
                let week = parseInt(data.split('d')[0])
                estimateMinutes += week * defaultTime * 60
            }
            if (data.includes('h')) {
                let week = parseInt(data.split('h')[0])
                estimateMinutes += week * 60
            }
            if (data.includes('m')) {
                let week = parseInt(data.split('m')[0])
                estimateMinutes += week
            } else if (
                !data.includes('w') &&
                !data.includes('d') &&
                !data.includes('h') &&
                !data.includes('m')
            ) {
                estimateMinutes = parseInt(data)
            }

            updateDetails('estimation', estimateMinutes)
            checkEstimation(estimateMinutes)
        })
    }

    // DELETE TASK
    async function deleteTask() {
        const result = await dispatch(taskDelete(taskID))
        const data = result?.payload?.data
        const sprintId = JSON.parse(localStorage.getItem('currentSprint'))?.id

        if (data) {
            const { success, message: checkMessage } = data
            if (success) {
                setDeleteButtonLoading(false)
                setDeleteModal(false)
                setTaskID('')
                dispatch(switchFormModal({ open: false }))
                message.success(checkMessage)

                if (activeTab) {
                    const result = await dispatch(getSprint({ id: activeTab }))
                    const data = result?.payload?.data
                    if (data) {
                        const { success } = data
                        if (success) {
                            dispatch(sprintSelect(data?.data))
                        }
                    }
                    listTaskCall(data?.data)
                } else {
                    dispatch(
                        await taskList({
                            limit: pageLimit,
                            skip: skipPage,
                            filter: {
                                projectId: userProfile?.activeProject?._id,
                                sprintId:
                                    localStorage.getItem('currentSprint') &&
                                    sprintId,
                            },
                        })
                    )
                }
            } else {
                setDeleteModal(false)
                setDeleteButtonLoading(false)
                setTaskID('')
                if (checkMessage) {
                    message.error(checkMessage)
                } else {
                    message.error('Something went wrong, try again later.')
                }
            }
        }
    }

    // UPDATE DETAILS
    function updateDetails(name, value) {
        setValues({
            ...values,
            [name]: value,
        })
        setErrors({ ...errors, [name]: '' })
        if (name === 'assigneeId' || name === 'sprint' || name === 'epic') {
            if (values[name][0]?.value !== value) {
                setUpdatedValues({
                    ...updatedValues,
                    [name]: value,
                })
            }
        } else {
            if (values[name] !== value) {
                setUpdatedValues({
                    ...updatedValues,
                    [name]: value,
                })
            }
        }
    }

    // ONFINISH
    const onFinish = async () => {
        try {
            let newFilterValue = {}
            const backlogId = sprintsOption
                .filter((data) => data?.label === 'Backlog')
                .map((data) => data?.value)
            Object.keys(values).forEach((data) => {
                if (data === 'estimation') {
                    newFilterValue = { ...newFilterValue, [data]: values[data] }
                }
                if (data === 'start' || data === 'end') {
                    if (values[data] !== '') {
                        newFilterValue = {
                            ...newFilterValue,
                            [data]: values[data],
                        }
                    } else {
                        newFilterValue = { ...newFilterValue }
                    }
                } else {
                    if (values[data] !== '' && values[data]?.length > 0) {
                        newFilterValue = {
                            ...newFilterValue,
                            [data]: values[data],
                        }
                    } else {
                        newFilterValue = {
                            ...newFilterValue,
                        }
                    }
                }
            })
            if (!newFilterValue['sprintId']) {
                newFilterValue = {
                    ...newFilterValue,
                    sprintId: activeTab ? activeTab : backlogId[0],
                }
            }

            if (newFilterValue) {
                newFilterValue = {
                    ...newFilterValue,
                    sprintId: JSON.parse(localStorage.getItem('currentSprint'))
                        ?.id,
                }
            }

            setTaskLoadingButton(true)
            const result = selectedTask
                ? await dispatch(
                      taskUpdate({
                          updateTask: {
                              ...updatedValues,
                              attachment: taskFiles,
                          },
                          _id: selectedTask?._id,
                      })
                  )
                : await dispatch(
                      taskCreate({ ...newFilterValue, attachment: taskFiles })
                  )
            const data = result?.payload?.data
            if (data) {
                const { success, message: checkMessage } = data
                if (success) {
                    if (activeTab) {
                        const result = await dispatch(
                            getSprint({ id: activeTab })
                        )
                        const data = result?.payload?.data
                        if (data) {
                            const { success } = data
                            if (success) {
                                dispatch(sprintSelect(data?.data))
                            }
                        }
                        listTaskCall(data?.data)
                    } else {
                        dispatch(
                            taskList({
                                limit: pageLimit,
                                skip: skipPage,
                                filter: {
                                    projectId: userProfile?.activeProject?._id,
                                    // sprintId: activeTab
                                    //     ? activeTab
                                    //     : filterDropDown?.sprintId ||
                                    //       JSON.parse(
                                    //           localStorage.getItem('currentSprint')
                                    //       )?.id,
                                    sprintId: JSON.parse(
                                        localStorage.getItem('currentSprint')
                                    )?.id,
                                },
                            })
                        )
                    }

                    if (selectedTask) {
                        const result = await dispatch(
                            taskById({ _id: selectedTask?._id })
                        )
                        const data = result?.payload?.data
                        if (data) {
                            dispatch(selectTaskUpdate(data?.data[0]))
                        } else {
                            message.error(
                                'Something went wrong, try again later.'
                            )
                        }
                    } else {
                        onCancel()
                    }
                    onCancel()
                    form.resetFields()
                    dispatch(clearTaskFileUpload())
                    setTaskLoadingButton(false)
                    message.success(checkMessage)
                } else {
                    onCancel()
                    form.resetFields()
                    setTaskLoadingButton(false)
                    if (typeof checkMessage === 'object') {
                        for (const key in checkMessage) {
                            message.error(checkMessage[key])
                        }
                    } else if (typeof checkMessage === 'string') {
                        message.error(checkMessage)
                    } else {
                        message.error('Something went wrong, try again later.')
                    }
                }
                setUpdatedValues({})
                listTaskCall()
            }
        } catch (error) {
            listTaskCall()
        }
    }

    const onFinishFailed = () => {}

    // START TIMER
    const handleStartTimer = async () => {
        try {
            const res = await startStopTimer({
                id: selectedTask?._id,
                data: { type: 'start' },
            })

            if (res?.data?.success) {
                setTimer(true)
                const result = await dispatch(
                    taskById({ _id: selectedTask?._id })
                )
                const data = result?.payload?.data
                if (data) {
                    message.success(res.data.message)
                    if (selected && selected?.currentStatus === 'on') {
                        dispatch(selectTaskUpdate(data?.data[0]))
                        const result = await dispatch(
                            taskById({ _id: selected?._id })
                        )
                        setTimer(false)
                        const dataTask = result?.payload?.data
                        if (dataTask) {
                            dispatch(selectSubTaskUpdate(dataTask?.data[0]))
                        } else {
                            message.error(
                                'Something went wrong, try again later.'
                            )
                        }
                    }
                } else {
                    setTimer(false)
                    message.error('Something went wrong, try again later.')
                }
                const checkLogin = await dispatch(isLoggedInLogin())

                const checkSuccessLogin = checkLogin?.payload?.data
                const { success: checkSuccess, data: timerData } =
                    checkSuccessLogin
                if (checkSuccess) {
                    dispatch(setGlobalTimer(timerData?.runningTask))
                }
            } else {
                setTimer(false)
                message.error(res.data.message)
            }
        } catch (error) {
            message.error(error)
        }
    }

    // PAUSE CONTENT
    const pauseContent = (
        <Row
            style={{
                gap: 10,
            }}
            align={'middle'}
        >
            <InfoCircleOutlined
                style={{
                    color: '#ffd713',
                }}
            />
            <Text
                style={{
                    fontWeight: 500,
                }}
            >
                If you want to pause the timer, Please Pause it from the Time
                Tracking App
            </Text>
        </Row>
    )

    const CopyTextButton = ({ text }) => {
        const handleCopy = () => {
            navigator.clipboard
                .writeText(text)
                .then(() => {
                    message.success('Link copied!')
                })
                .catch(() => {
                    message.error('Failed to copy link')
                })
        }

        return (
            <div
                style={{
                    height: '20px',
                    width: '20px',
                    cursor: 'pointer',
                }}
                onClick={handleCopy}
            >
                <TaskLinkIcon />
            </div>
        )
    }

    return (
        <>
            <DeletePopUp
                previewDelete={deleteModal}
                onDelete={() => {
                    setDeleteButtonLoading(true)
                    deleteTask()
                    setDeleteCheck(false)
                }}
                loadingButton={deleteButtonLoading}
                onCancel={() => {
                    setDeleteCheck(false)
                    setDeleteButtonLoading(false)
                    setDeleteModal(false)
                }}
                modalTitle="Delete Task"
                pageKey="Task"
            />
            <Spin spinning={taskStatus === 'loading' ? true : false}>
                <Form
                    layout="vertical"
                    name="addTaskForm"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    form={form}
                    style={{
                        minWidth: 'inherit',
                    }}
                    requiredMark={false}
                >
                    <div
                        style={{
                            backgroundColor:
                                token.colorPalette.baseColor.quaternary,
                            padding: '30px',
                        }}
                    >
                        <Row
                            align={'middle'}
                            justify={'space-between'}
                            style={{
                                width: '100%',
                                marginBottom: '27px',
                            }}
                            gutter={[20, 20]}
                        >
                            <Col span={selectedTask ? 14 : 24}>
                                <Row
                                    align={'middle'}
                                    style={{
                                        width: '100%',
                                    }}
                                    gutter={[10, 10]}
                                >
                                    <Col span={1}>
                                        <TaskIcon />
                                    </Col>
                                    <Col span={1}></Col>
                                    {(isEmpty(selectedTask) ||
                                        viewFieldCheck(
                                            'tasks',
                                            'title',
                                            userProfile
                                        )) && (
                                        <Col span={16}>
                                            <Form.Item
                                                name="title"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            'Enter Task Title',
                                                        whitespace: true,
                                                    },
                                                    {
                                                        max: 400,
                                                        message:
                                                            'Title should be maximum 400 characters long',
                                                    },
                                                ]}
                                                colon={false}
                                            >
                                                <Input
                                                    placeholder={
                                                        'Enter Task Title'
                                                    }
                                                    name={'title'}
                                                    readOnly={
                                                        selectedTask &&
                                                        editFieldCheck(
                                                            'tasks',
                                                            'title',
                                                            userProfile
                                                        ) === false
                                                    }
                                                    variant="borderless"
                                                    className={
                                                        styles.taskSelect
                                                    }
                                                    style={{
                                                        border: 'none',
                                                        backgroundColor:
                                                            'rgba(145, 158, 171, 0.08)',
                                                        borderRadius: '8px',
                                                    }}
                                                    onChange={(event) => {
                                                        updateDetails(
                                                            event.target.name,
                                                            event.target.value
                                                        )
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                    )}
                                    <Col span={6}>
                                        <Form.Item
                                            name="priority"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Task Priority is required',
                                                    whitespace: true,
                                                },
                                            ]}
                                            colon={false}
                                        >
                                            <NewSelect
                                                placeholder="Select a Priority"
                                                className={[
                                                    styles.taskSelect,
                                                    'arrowTaskSelect',
                                                ]}
                                                bordered={false}
                                                onChange={(event) =>
                                                    updateDetails(
                                                        'priority',
                                                        event
                                                    )
                                                }
                                                allowClear={false}
                                            >
                                                {priorityType?.length > 0 &&
                                                    priorityType.map((data) => (
                                                        <Option
                                                            value={data?.value}
                                                            key={data?.label}
                                                        >
                                                            <Row
                                                                align={'middle'}
                                                                style={{
                                                                    columnGap:
                                                                        '10px',
                                                                }}
                                                            >
                                                                {data?.icon}
                                                                {data?.label}
                                                            </Row>
                                                        </Option>
                                                    ))}
                                            </NewSelect>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                            {selectedTask && (
                                <Col span={10}>
                                    <Row
                                        align={'middle'}
                                        style={{
                                            columnGap: '20px',
                                        }}
                                        justify={'end'}
                                        gutter={[10, 10]}
                                    >
                                        <Col span={1}>
                                            <CopyTextButton
                                                text={`${window.location.origin}${window.location.pathname}?taskId=${selectedTask?._id}`}
                                            />
                                        </Col>
                                        <Col flex={'auto'}>
                                            <Form.Item
                                                name="status"
                                                colon={false}
                                            >
                                                <NewSelect
                                                    placeholder="Select a Status"
                                                    className={[
                                                        // styles.taskSelect,
                                                        'arrowStatusSelect',
                                                    ]}
                                                    onChange={(event) =>
                                                        updateDetails(
                                                            'status',
                                                            event
                                                        )
                                                    }
                                                    style={{
                                                        height: '40px !important',
                                                    }}
                                                    allowClear={false}
                                                >
                                                    {swimLanesOption?.length >
                                                        0 &&
                                                        swimLanesOption.map(
                                                            (data) => (
                                                                <Option
                                                                    value={
                                                                        data?.label
                                                                    }
                                                                    key={
                                                                        data?.label
                                                                    }
                                                                >
                                                                    {
                                                                        data?.label
                                                                    }
                                                                </Option>
                                                            )
                                                        )}
                                                </NewSelect>
                                            </Form.Item>
                                        </Col>
                                        {timer ||
                                        selectedTask?.currentStatus === 'on' ? (
                                            <Popover
                                                content={pauseContent}
                                                trigger={'click'}
                                            >
                                                <ButtonAnt
                                                    style={{
                                                        padding: '7px',
                                                        fontSize: '16px',
                                                        backgroundColor:
                                                            '#f5f6f8',
                                                        color: '#000000',
                                                    }}
                                                >
                                                    <Row
                                                        align={'middle'}
                                                        style={{
                                                            columnGap: '4px',
                                                        }}
                                                    >
                                                        <PauseCircleFilled
                                                            style={{
                                                                marginTop: 1,
                                                                color: '#d60606',
                                                            }}
                                                            className={'in-btn'}
                                                        />
                                                        <Text>Stop Timer</Text>
                                                    </Row>
                                                </ButtonAnt>
                                            </Popover>
                                        ) : (
                                            <Popconfirm
                                                title="Start the Timer"
                                                cancelButtonProps={{
                                                    hidden: true,
                                                }}
                                                overlayInnerStyle={{
                                                    width: '400px',
                                                }}
                                                description="Before you start your timer on this task, please make sure that your time doctor is currently active on the same project as this task."
                                                onConfirm={() => {
                                                    handleStartTimer()
                                                }}
                                                okText="Confirm"
                                                okButtonProps={{
                                                    style: {
                                                        height: 'unset',
                                                        backgroundColor:
                                                            token.colorPalette
                                                                .baseColor
                                                                .tertiary,
                                                    },
                                                }}
                                            >
                                                <ButtonAnt
                                                    style={{
                                                        padding: '7px',
                                                        fontSize: '16px',
                                                        backgroundColor:
                                                            '#f5f6f8',
                                                        color: '#000000',
                                                    }}
                                                >
                                                    <Row
                                                        align={'middle'}
                                                        style={{
                                                            columnGap: '3px',
                                                        }}
                                                    >
                                                        <TimerIcon />
                                                        <Text>Start Timer</Text>
                                                    </Row>
                                                </ButtonAnt>
                                            </Popconfirm>
                                        )}

                                        <Popover
                                            content={dropDownContent(
                                                selectedTask?._id
                                            )}
                                            trigger="click"
                                            placement="bottom"
                                            open={open}
                                            overlayInnerStyle={{
                                                padding: '10px',
                                            }}
                                            arrow={false}
                                            onOpenChange={handleOpenChange}
                                        >
                                            <div
                                                style={{
                                                    cursor: 'pointer',
                                                }}
                                            >
                                                <EllipsisIcon />
                                            </div>
                                        </Popover>
                                    </Row>
                                </Col>
                            )}
                        </Row>
                        <Row
                            style={{
                                flexWrap: 'nowrap',
                            }}
                            gutter={[10, 10]}
                            className="taskRootSelect"
                        >
                            {(isEmpty(selectedTask) ||
                                viewFieldCheck(
                                    'tasks',
                                    'assigneeId',
                                    userProfile
                                )) && (
                                <Col flex={'auto'}>
                                    <Form.Item name="assigneeId" colon={false}>
                                        <NewSelect
                                            placeholder="Select a Assignee"
                                            onChange={(event) =>
                                                updateDetails(
                                                    'assigneeId',
                                                    event
                                                )
                                            }
                                            variant="borderless"
                                            className={[
                                                styles.taskSelect,
                                                'arrowTaskSelect',
                                            ]}
                                            disabled={
                                                selectedTask &&
                                                editFieldCheck(
                                                    'tasks',
                                                    'assigneeId',
                                                    userProfile
                                                ) === false
                                            }
                                            allowClear={false}
                                        >
                                            {assigneeList.map((option) => {
                                                return (
                                                    <Option
                                                        style={{
                                                            textTransform:
                                                                'capitalize',
                                                        }}
                                                        key={option?.value}
                                                        value={option?.value}
                                                    >
                                                        <Space size="small">
                                                            {option?.profilePicture ? (
                                                                <Image
                                                                    src={
                                                                        option?.profilePicture
                                                                    }
                                                                    height={
                                                                        '30px'
                                                                    }
                                                                    preview={
                                                                        false
                                                                    }
                                                                    width={
                                                                        '30px'
                                                                    }
                                                                    style={{
                                                                        borderRadius:
                                                                            '50%',
                                                                    }}
                                                                />
                                                            ) : (
                                                                <Avatar
                                                                    style={{
                                                                        backgroundColor:
                                                                            'rgb(0 10 129 / 25%)',
                                                                        cursor: 'pointer',
                                                                        height: '30px',
                                                                        width: '30px',
                                                                    }}
                                                                >
                                                                    {option?.label[0].toUpperCase()}
                                                                    {option?.label.split(
                                                                        ' '
                                                                    )[1] !==
                                                                        undefined &&
                                                                    option?.label.split(
                                                                        ' '
                                                                    )[1] !== ''
                                                                        ? option?.label
                                                                              .split(
                                                                                  ' '
                                                                              )[1][0]
                                                                              .toUpperCase()
                                                                        : ''}
                                                                </Avatar>
                                                            )}
                                                            <Text
                                                                style={{
                                                                    margin: 0,
                                                                }}
                                                            >
                                                                {option?.label}
                                                            </Text>
                                                        </Space>
                                                    </Option>
                                                )
                                            })}
                                        </NewSelect>
                                    </Form.Item>
                                </Col>
                            )}
                            {(isEmpty(selectedTask) ||
                                viewFieldCheck(
                                    'tasks',
                                    'epic',
                                    userProfile
                                )) && (
                                <Col flex={'auto'}>
                                    <Form.Item
                                        name="epic"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please Select a Epic',
                                            },
                                        ]}
                                        colon={false}
                                    >
                                        <NewSelect
                                            placeholder="Select a Epic"
                                            className={[
                                                styles.taskSelect,
                                                'arrowTaskSelect',
                                            ]}
                                            bordered={false}
                                            onChange={(event) =>
                                                updateDetails('epic', event)
                                            }
                                            dropdownStyle={{
                                                textTransform: 'capitalize',
                                            }}
                                            allowClear={false}
                                            disabled={
                                                selectedTask &&
                                                editFieldCheck(
                                                    'tasks',
                                                    'epic',
                                                    userProfile
                                                ) === false
                                            }
                                        >
                                            {epicsOption?.length > 0 &&
                                                epicsOption.map((data) => (
                                                    <Option
                                                        value={data?.value}
                                                        key={data?.label}
                                                    >
                                                        <Space>
                                                            <Text>
                                                                {data?.epicKey}
                                                            </Text>
                                                            <Text>
                                                                {data?.label}
                                                            </Text>
                                                        </Space>
                                                    </Option>
                                                ))}
                                        </NewSelect>
                                    </Form.Item>
                                </Col>
                            )}
                            {(isEmpty(selectedTask) ||
                                viewFieldCheck(
                                    'tasks',
                                    'type',
                                    userProfile
                                )) && (
                                <Col flex={'auto'}>
                                    <Form.Item
                                        name="type"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    'Please Select a Task Type',
                                            },
                                        ]}
                                        colon={false}
                                    >
                                        <NewSelect
                                            placeholder="Select a Type"
                                            className={[
                                                styles.taskSelect,
                                                'arrowTaskSelect',
                                            ]}
                                            bordered={false}
                                            onChange={(event) =>
                                                updateDetails('type', event)
                                            }
                                            allowClear={false}
                                            disabled={
                                                selectedTask &&
                                                editFieldCheck(
                                                    'tasks',
                                                    'type',
                                                    userProfile
                                                ) === false
                                            }
                                        >
                                            {taskTypeOption?.length > 0 &&
                                                taskTypeOption.map((data) => (
                                                    <Option
                                                        value={data?.value}
                                                        key={data?.label}
                                                    >
                                                        {data?.label}
                                                    </Option>
                                                ))}
                                        </NewSelect>
                                    </Form.Item>
                                </Col>
                            )}
                            {(isEmpty(selectedTask) ||
                                viewFieldCheck(
                                    'tasks',
                                    'estimation',
                                    userProfile
                                )) && (
                                <Col flex={'auto'}>
                                    <Form.Item
                                        name="estimation"
                                        colon={false}
                                        rules={[
                                            {
                                                validator: async (_, names) => {
                                                    if (!names) {
                                                        return Promise.resolve()
                                                    }
                                                    const pattern =
                                                        ESTIMATION_PATTERN

                                                    const patternNumber =
                                                        /^[0-9]$/

                                                    if (
                                                        !pattern.test(names) &&
                                                        !patternNumber.test(
                                                            names
                                                        )
                                                    ) {
                                                        return Promise.reject(
                                                            new Error(
                                                                'Your estimate must be in the format 2w 4d 6h 45m.'
                                                            )
                                                        )
                                                    }
                                                },
                                            },
                                        ]}
                                    >
                                        <Input
                                            placeholder={'2w 4d 6h 45m'}
                                            bordered={false}
                                            className={styles.taskSelect}
                                            style={{
                                                border: 'none',
                                                backgroundColor:
                                                    'rgba(145, 158, 171, 0.08)',
                                                borderRadius: '8px',
                                            }}
                                            readOnly={
                                                selectedTask &&
                                                editFieldCheck(
                                                    'tasks',
                                                    'estimation',
                                                    userProfile
                                                ) === false
                                            }
                                            name={'estimation'}
                                            onBlurCapture={(event) => {
                                                if (
                                                    event.target.value?.length >
                                                    0
                                                )
                                                    checkFinalEstimation(
                                                        event.target.value.trimEnd()
                                                    )
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                            )}
                            {(isEmpty(selectedTask) ||
                                viewFieldCheck(
                                    'tasks',
                                    'sprintId',
                                    userProfile
                                )) && (
                                <Col flex={'auto'}>
                                    <Form.Item name="sprintId" colon={false}>
                                        <NewSelect
                                            placeholder="Select a Sprint"
                                            className={[
                                                styles.taskSelect,
                                                'arrowTaskSelect',
                                            ]}
                                            bordered={false}
                                            onChange={(event) =>
                                                updateDetails('sprintId', event)
                                            }
                                            allowClear={false}
                                            disabled={
                                                (activeTab &&
                                                    !selectedTask &&
                                                    true) ||
                                                editFieldCheck(
                                                    'tasks',
                                                    'sprintId',
                                                    userProfile
                                                ) === false
                                            }
                                        >
                                            {sprintsOption?.length > 0 &&
                                                sprintsOption.map((data) => (
                                                    <Option
                                                        value={data?.value}
                                                        key={data?.label}
                                                    >
                                                        {data?.label}
                                                    </Option>
                                                ))}
                                        </NewSelect>
                                    </Form.Item>
                                </Col>
                            )}
                            {(isEmpty(selectedTask) ||
                                viewFieldCheck(
                                    'tasks',
                                    'start',
                                    userProfile
                                )) && (
                                <Col flex={'auto'}>
                                    <Form.Item
                                        colon={false}
                                        name={'rangePicker'}
                                        className="rangePickerTask"
                                    >
                                        <RangePicker
                                            picker={'date'}
                                            allowClear={false}
                                            name={'rangePicker'}
                                            format={formatDatePicker}
                                            style={{
                                                width: '100%',
                                            }}
                                            bordered={false}
                                            disabled={
                                                selectedTask &&
                                                editFieldCheck(
                                                    'tasks',
                                                    'start',
                                                    userProfile
                                                ) === false
                                            }
                                            className={[styles.taskSelect]}
                                            onChange={(event) => {
                                                if (
                                                    form.getFieldValue(
                                                        'start'
                                                    ) !== undefined &&
                                                    new Date(
                                                        form.getFieldValue(
                                                            'start'
                                                        )['$d']
                                                    ).setHours(0, 0, 0, 0) !==
                                                        new Date(
                                                            event[0]['$d']
                                                        ).setHours(
                                                            0,
                                                            0,
                                                            0,
                                                            0
                                                        ) &&
                                                    selectedTask
                                                ) {
                                                    setUpdatedValues({
                                                        ...updatedValues,
                                                        start: new Date(
                                                            event[0]['$d']
                                                        ).setHours(
                                                            23,
                                                            59,
                                                            59,
                                                            999
                                                        ),
                                                    })
                                                }
                                                if (
                                                    form.getFieldValue(
                                                        'end'
                                                    ) !== undefined &&
                                                    new Date(
                                                        form.getFieldValue(
                                                            'end'
                                                        )['$d']
                                                    ).setHours(
                                                        23,
                                                        59,
                                                        59,
                                                        999
                                                    ) !==
                                                        new Date(
                                                            event[1]['$d']
                                                        ).setHours(
                                                            23,
                                                            59,
                                                            59,
                                                            999
                                                        ) &&
                                                    selectedTask
                                                ) {
                                                    setUpdatedValues({
                                                        ...updatedValues,
                                                        end: new Date(
                                                            event[1]['$d']
                                                        ).setHours(
                                                            23,
                                                            59,
                                                            59,
                                                            999
                                                        ),
                                                    })
                                                }
                                                setValues({
                                                    ...values,
                                                    start: event[0],
                                                    end: event[1],
                                                })
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                            )}
                        </Row>
                    </div>
                    <div
                        style={{
                            minHeight: '100%',
                        }}
                    >
                        <Row
                            style={{
                                padding: '30px',
                            }}
                        >
                            {selectedTask ? (
                                <Row
                                    style={{
                                        width: '100%',
                                    }}
                                >
                                    <Col span={13}>
                                        <Row
                                            style={{
                                                width: '100%',
                                            }}
                                        >
                                            <Col span={24}>
                                                <Form.Item
                                                    name="description"
                                                    colon={false}
                                                >
                                                    <TextArea
                                                        rows={6}
                                                        placeholder={
                                                            'Add a Description...'
                                                        }
                                                        readOnly={
                                                            selectedTask &&
                                                            editFieldCheck(
                                                                'tasks',
                                                                'description',
                                                                userProfile
                                                            ) === false
                                                        }
                                                        name={'description'}
                                                        onChange={(event) =>
                                                            updateDetails(
                                                                event.target
                                                                    .name,
                                                                event.target
                                                                    .value
                                                            )
                                                        }
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <AttachmentFileSection />
                                        <Button
                                            props={{
                                                text: 'Save',
                                                htmlType: 'submit',
                                                loadingButton:
                                                    taskLoadingButton,
                                                style: {
                                                    width: '100%',
                                                    marginTop: '10px',
                                                },
                                            }}
                                        />
                                        <SubTaskSection
                                            assigneeList={assigneeList}
                                            epicsOption={epicsOption}
                                            taskTypeOption={taskTypeOption}
                                            sprintsOption={sprintsOption}
                                            swimLanesOptionTask={
                                                swimLanesOption
                                            }
                                        />
                                    </Col>
                                    <Col span={1}>
                                        <Divider type="vertical" />
                                    </Col>
                                    <Col span={10}>
                                        <Row>
                                            <Col span={24}>
                                                <Tabs
                                                    defaultActiveKey={
                                                        'sub-tasks'
                                                    }
                                                    items={tabs}
                                                    className="generalTabs"
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            ) : (
                                <>
                                    <Col span={24}>
                                        <Form.Item
                                            name="description"
                                            colon={false}
                                        >
                                            <TextArea
                                                rows={8}
                                                placeholder={
                                                    'Add a Description...'
                                                }
                                                name={'description'}
                                                onChange={(event) =>
                                                    updateDetails(
                                                        event.target.name,
                                                        event.target.value
                                                    )
                                                }
                                            />
                                        </Form.Item>
                                    </Col>
                                    <AttachmentFileSection />
                                    <Row
                                        gutter={9}
                                        justify={'end'}
                                        style={{
                                            marginTop: '20px',
                                            width: '100%',
                                            bottom: 0,
                                        }}
                                    >
                                        <Button
                                            props={{
                                                text: 'Cancel',
                                                buttonType: 'link',
                                                onClick: () => {
                                                    form.setFieldsValue(
                                                        initialValues
                                                    )
                                                    onCancel()
                                                },
                                                style: {
                                                    color: token.colorPalette
                                                        .baseColor.black,
                                                },
                                            }}
                                        />
                                        <Button
                                            props={{
                                                text: selectedTask
                                                    ? 'Update'
                                                    : 'Create',
                                                htmlType: 'submit',
                                                loadingButton:
                                                    taskLoadingButton,
                                            }}
                                        />
                                    </Row>
                                </>
                            )}
                        </Row>
                    </div>
                </Form>
                <Row></Row>
            </Spin>
        </>
    )
}

export default DrawerTaskForm
