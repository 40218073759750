import React from 'react'

function EditIcon({ color }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 26 26"
        >
            <path
                stroke={color ? color : '#252525'}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M11.917 2.166H9.75c-5.416 0-7.583 2.167-7.583 7.583v6.5c0 5.417 2.167 7.584 7.583 7.584h6.5c5.417 0 7.584-2.167 7.584-7.584v-2.166"
            ></path>
            <path
                stroke={color ? color : '#252525'}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="1.5"
                d="M17.377 3.272L8.84 11.808c-.325.325-.65.965-.715 1.43L7.66 16.5c-.174 1.181.66 2.004 1.841 1.842l3.261-.466c.455-.065 1.094-.39 1.43-.715l8.537-8.537c1.473-1.473 2.166-3.185 0-5.351-2.167-2.167-3.879-1.474-5.352 0zM16.152 4.496a7.74 7.74 0 005.352 5.352"
            ></path>
        </svg>
    )
}

export default EditIcon
