import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { IoMdPerson } from 'react-icons/io'

// bootstrap
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css'
import '../../../node_modules/bootstrap/dist/js/bootstrap.bundle'

// css
import './time-tracking.css'

// assets
import logo from '../../assets/images/landingLogo.png'
import headerLogo from '../../assets/images/headerLogo.png'
import windows from '../../assets/images/windows.png'
import linux from '../../assets/images/linux-logo.png'
import mac from '../../assets/images/mac-os-logo.png'
import project from '../../assets/images/Project.jpg'

function TimeTrackingLandingPage() {
    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)

    return (
        <div className="App">
            <div className="header">
                <div>
                    <img src={headerLogo} className="headerlogo" alt="logo" />
                </div>
                <div className="menu">
                    <button className="model-download-btn">
                        <IoMdPerson size={20} /> &nbsp;Sign in
                    </button>
                </div>
            </div>
            <div className="main">
                <p className="text-center">Download</p>
                <h1 className="like">Time Tracking App</h1>
                <br />
                <p className="pre">
                    The only app you need for time tracking, avoiding
                    distractions and getting things done.
                    <br /> If you don't have an account, request SoftwareCo
                    Admin before intsalling the app.
                </p>
                <br />
                <div className="download">
                    <button
                        onClick={() => {
                            handleShow()
                        }}
                        className="download-btn"
                        type="button"
                        data-toggle="modal"
                        data-target="#myModal"
                    >
                        <img
                            src={logo}
                            className="btn-img"
                            style={{ backgroundColor: 'transparent' }}
                            alt="Logo"
                        />
                        <div className="btn-text">
                            <b className="dwn-text">
                                Download The App For Your System
                            </b>
                            <br />
                            <small className="v-text">
                                v1.1 - Compatible With Your System
                            </small>
                        </div>
                    </button>
                </div>
                <img
                    src="https://static.timedoctor.com/assets/images/new-design/download/desktop-app-3d.png"
                    width="80%"
                    style={{ margin: 'auto', display: 'block' }}
                    alt="images"
                />
            </div>
            <div className="grid-color">
                <div className=" mt-5">
                    <div className=" p-5">
                        <h1 className="text-start text-center">
                            Track Time Directly
                            <br /> From Web App
                        </h1>
                        <p className="text-start-text mt-5 text-center">
                            Time Tracking App allows constant syncing between
                            the web-app and the desktop app,
                            <br /> making sure the work you do is calculated
                            accurately on each tasks.
                        </p>
                    </div>
                    <div className="">
                        <div className="d-flex">
                            <img
                                src={project}
                                width="90%"
                                style={{ margin: 'auto', display: 'block' }}
                                alt="images"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer text-center">
                <b>
                    © 2023.{' '}
                    <a
                        href="https://www.softwareco.com/in/"
                        target="_blank"
                        rel="noreferrer"
                        style={{ textDecoration: 'none' }}
                    >
                        Software Co
                    </a>
                    . All Rights Reserved.
                </b>
            </div>
            <Modal show={show} onHide={handleClose} className="model">
                <Modal.Body>
                    <h5 className="mb-4">Identify Your System</h5>
                    <div className="system">
                        <div className="system-item">
                            <img
                                src={windows}
                                alt="windo"
                                width={70}
                                className="system-img"
                            />
                            <br />
                            <button className="model-download-btn">
                                Download
                            </button>
                        </div>
                        <div className="system-item">
                            <img
                                src={linux}
                                alt="linux"
                                width={70}
                                className="system-img"
                            />
                            <br />
                            <button className="model-download-btn">
                                Download
                            </button>
                        </div>
                        <div className="system-item">
                            <img
                                src={mac}
                                alt="mac"
                                width={70}
                                className="system-img"
                            />
                            <br />
                            <button className="model-download-btn">
                                Download
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default TimeTrackingLandingPage
