import React from 'react'

function RecruitIcon(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
            {...props}
        >
            <path
                fill="#fff"
                d="M21.09 21.5c0 .28-.22.5-.5.5H3.41c-.28 0-.5-.22-.5-.5 0-4.14 4.08-7.5 9.09-7.5 1.03 0 2.03.14 2.95.41-.59.7-.95 1.61-.95 2.59 0 .75.21 1.46.58 2.06.2.34.46.65.76.91.7.64 1.63 1.03 2.66 1.03 1.12 0 2.13-.46 2.85-1.2.16.54.24 1.11.24 1.7z"
            ></path>
            <path
                fill="#fff"
                d="M20.97 14.33A3.944 3.944 0 0018 13a3.995 3.995 0 00-4 4c0 .75.21 1.46.58 2.06.2.34.46.65.76.91.7.64 1.63 1.03 2.66 1.03 1.46 0 2.73-.78 3.42-1.94.21-.34.37-.73.46-1.13.08-.3.12-.61.12-.93 0-1.02-.39-1.96-1.03-2.67zm-1.47 3.4h-.75v.78c0 .41-.34.75-.75.75s-.75-.34-.75-.75v-.78h-.75c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h.75v-.71c0-.41.34-.75.75-.75s.75.34.75.75v.71h.75c.41 0 .75.34.75.75s-.34.75-.75.75zM12 12a5 5 0 100-10 5 5 0 000 10z"
            ></path>
        </svg>
    )
}

export default RecruitIcon
