import React from 'react'

function NoProjectsImageIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 247 247"
        >
            <path
                fill="#4F46E5"
                d="M190.233 222.221c-9.58-29.615-28.107-47.967-52.661-69.162 23.214-18.528 52.638-29.603 84.649-29.603V24.691h12.446c6.763 0 12.245 5.493 12.245 12.264v197.693c0 6.772-5.621 12.264-12.245 12.264H12.244C5.482 246.912 0 241.419 0 234.648V36.955c0-6.773 5.621-12.264 12.244-12.264h37.138V0h24.691v49.382H24.691v86.419c64.446 0 119.281 30.39 139.662 86.42h25.88zM197.529 0v49.382H98.765v-24.69h74.073V0h24.691zm-18.518 111.11c-10.227 0-18.518-8.291-18.518-18.518s8.291-18.519 18.518-18.519 18.518 8.292 18.518 18.519c0 10.227-8.291 18.518-18.518 18.518z"
                opacity="0.3"
            ></path>
        </svg>
    )
}

export default NoProjectsImageIcon
