import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// antd
import { Col, Image, Row, Space, Spin, Typography, theme } from 'antd'

// components
import Button from '../../../components/button/button'

// slices
import {
    profileState,
    setDeleteModalTitle,
    switchProfileModal,
    updatedDataProfile,
} from '../profile.slice'
import { loginState } from '../../login/login.slice'

// constants
import { SUPER_ADMIN } from '../../../constants/roles'

// sections
import NoIndividualContent from '../tabSection/noIndividualContent'

// assets
import {
    EditIcon,
    NoProjectsIcon,
    NoProjectsImageIcon,
} from '../../../assets/icons'

// modals
import MultiProjectMediaModal from '../modals/multiProjectMediaModal'

function ProjectsTabContent() {
    const { Title, Text } = Typography

    const { useToken } = theme
    const { token } = useToken()

    const dispatch = useDispatch()

    // SELECTORS
    const {
        profileData: {
            data: { projects },
        },
        status,
    } = useSelector(profileState)
    const { userProfile } = useSelector(loginState)

    const [viewMediaProject, setViewMediaProject] = useState({
        open: false,
        project: {},
    })

    return (
        <>
            <div
                style={{
                    padding: '50px',
                }}
            >
                <Row align="middle" justify={'space-between'}>
                    <Title level={4}>Projects</Title>
                    {userProfile?.role?.roleName !== SUPER_ADMIN && (
                        <Button
                            props={{
                                text: 'Add Project',
                                onClick: () => {
                                    dispatch(
                                        switchProfileModal({
                                            name: 'projects',
                                            open: true,
                                        })
                                    )
                                },
                            }}
                        />
                    )}
                </Row>
                <Spin spinning={status === 'loading' ? true : false}>
                    {status === 'loaded' && projects?.length > 0 ? (
                        <Row
                            style={{
                                marginTop: '35px',
                            }}
                            gutter={[16, 16]}
                        >
                            {projects.map((projectData) => (
                                <Col span={6} key={projectData?.projectName}>
                                    <Row
                                        style={{
                                            columnGap: '20px',
                                        }}
                                        key={projectData?.institute}
                                    >
                                        {projectData?.media?.length > 0 ? (
                                            <Image
                                                src={projectData?.media[0]}
                                                width={'100%'}
                                                height={'200px'}
                                                style={{
                                                    maxHeight: '250px',
                                                    borderRadius: '8px',
                                                    objectFit: 'contain',
                                                    cursor: 'pointer',
                                                    boxShadow:
                                                        '0px 3px 6px 0px rgba(140, 149, 159, 0.15)',
                                                }}
                                                preview={false}
                                                onClick={() => {
                                                    setViewMediaProject({
                                                        open: true,
                                                        project: projectData,
                                                    })
                                                }}
                                            />
                                        ) : (
                                            <div
                                                style={{
                                                    width: '100%',
                                                    borderRadius: '8px',
                                                    height: '200px',
                                                    boxShadow:
                                                        '0px 3px 6px 0px rgba(140, 149, 159, 0.15)',
                                                    backgroundColor:
                                                        token.colorPalette
                                                            .textColor.tertiary,
                                                    opacity: 0.4,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        width: '35px',
                                                        height: '35px',
                                                    }}
                                                >
                                                    <NoProjectsImageIcon />
                                                </div>
                                            </div>
                                        )}
                                        <Row
                                            justify={'space-between'}
                                            style={{
                                                marginTop: '22px',
                                                width: '100%',
                                            }}
                                        >
                                            <Row>
                                                <Space>
                                                    {projectData?.projectName && (
                                                        <Title
                                                            className={
                                                                'titleSub'
                                                            }
                                                        >
                                                            {
                                                                projectData?.projectName
                                                            }
                                                        </Title>
                                                    )}
                                                    <Text className="titleLight">
                                                        {!projectData?.isCompleted &&
                                                            `(Draft)`}
                                                    </Text>
                                                </Space>
                                            </Row>
                                            {userProfile?.role?.roleName !==
                                                SUPER_ADMIN && (
                                                <div
                                                    style={{
                                                        cursor: 'pointer',
                                                        height: '26px',
                                                        width: '26px',
                                                    }}
                                                    onClick={() => {
                                                        dispatch(
                                                            setDeleteModalTitle(
                                                                'project'
                                                            )
                                                        )
                                                        dispatch(
                                                            switchProfileModal({
                                                                name: 'projects',
                                                                open: true,
                                                                mode: 'edit',
                                                            })
                                                        )
                                                        dispatch(
                                                            updatedDataProfile({
                                                                editData: {
                                                                    step: 'projects',
                                                                    stepField:
                                                                        '_id',
                                                                    stepFieldValue:
                                                                        projectData?._id,
                                                                },
                                                                _id: userProfile?._id,
                                                            })
                                                        )
                                                    }}
                                                >
                                                    <EditIcon />
                                                </div>
                                            )}
                                        </Row>
                                        <Space>
                                            {projectData?.start !== null && (
                                                <Text
                                                    style={{
                                                        fontSize:
                                                            token.fontSizeLG,
                                                    }}
                                                    className={'titleLight'}
                                                >
                                                    {new Date(
                                                        projectData?.start
                                                    )?.toLocaleString(
                                                        'default',
                                                        {
                                                            year: 'numeric',
                                                            month: 'long',
                                                        }
                                                    )}
                                                </Text>
                                            )}
                                            {projectData?.start !== null &&
                                                (projectData?.currentWorking ||
                                                    projectData?.end !==
                                                        null) &&
                                                '-'}
                                            {(projectData?.currentWorking ||
                                                projectData?.end !== null) && (
                                                <Text
                                                    style={{
                                                        fontSize:
                                                            token.fontSizeLG,
                                                    }}
                                                    className={'titleLight'}
                                                >
                                                    {projectData?.currentWorking
                                                        ? 'Present'
                                                        : new Date(
                                                              projectData?.end
                                                          )?.toLocaleString(
                                                              'default',
                                                              {
                                                                  month: 'long',
                                                                  year: 'numeric',
                                                              }
                                                          )}
                                                </Text>
                                            )}
                                        </Space>
                                    </Row>
                                </Col>
                            ))}
                        </Row>
                    ) : status === 'loaded' ? (
                        <NoIndividualContent
                            content={{
                                key: 'projects',
                                title: 'Projects',
                                icon: <NoProjectsIcon />,
                            }}
                        />
                    ) : (
                        status === 'loading' && <Spin />
                    )}
                </Spin>
            </div>

            <MultiProjectMediaModal
                projectData={viewMediaProject?.project}
                open={viewMediaProject?.open}
                onClose={() => {
                    setViewMediaProject({
                        open: false,
                        project: {},
                    })
                }}
            />
        </>
    )
}

export default ProjectsTabContent
