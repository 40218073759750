import axiosNew from '../../../../helpers/axios'

export const timesheetApi = async (data) => {
    const res = await axiosNew({
        url: `reports/list?timezone=${
            Intl.DateTimeFormat().resolvedOptions().timeZone
        }`,
        data,
        method: 'POST',
    })
    return res
}

export const editTimeWithTask = async (data) => {
    const res = await axiosNew({
        url: 'reports/update',
        data,
        method: 'PUT',
    })

    return res
}
