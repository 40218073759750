import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { debounce } from 'lodash'

// antd
import {
    Checkbox,
    Col,
    Divider,
    Form,
    Input,
    Row,
    Select,
    Space,
    Typography,
    message,
    theme,
    Spin,
    Empty,
} from 'antd'
import { PlusOutlined } from '@ant-design/icons'

// slices
import {
    emailCheck,
    isLoggedInLogin,
    loginState,
    numberCheck,
    signUpForm,
} from './login.slice'
import {
    addSummary,
    changeModel,
    masterDataDropDownList,
    profileState,
    setMasterDataDropDown,
} from '../profile/profile.slice'

// components
import Button from '../../components/button/button'
import SocialButtonLogin from './socialButtonLogin'

//assets
import { PasswordHideIcon, PasswordShowIcon } from '../../assets/icons'

// constants
import { PHONE_COUNTRIES } from '../../constants/phone-countries'
import { EMAIL_PATTERN, PASSWORD_PATTERN } from '../../constants/patterns'
import { MASTER_DATA_FIELD } from '../../constants/masterData'

// routes
import { PUBLIC_ROUTES } from '../../routes'

function SignUpForm() {
    const [form] = Form.useForm()
    const timeoutRef = useRef()
    const sessionTokenRef = useRef()

    const { useToken } = theme

    const { token } = useToken()

    const { Title, Text } = Typography
    const { Option } = Select

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { userProfile, emailDisable } = useSelector(loginState)
    const { masterDataDropDown, masterDataDropDownStatus } =
        useSelector(profileState)

    const initialState = {
        emailValid: false,
        phoneValid: false,
        loadingButton: false,
        searchValue: '',
        phoneNumber: {
            default: null,
            phoneNo: null,
            code: null,
            flag: null,
        },
        timeZoneChange: '',
        suggestions: [],
    }

    const [state, setState] = useState(initialState)

    // ADD MASTER DATA
    async function addMasterData() {
        dispatch(
            setMasterDataDropDown({
                model: 'designation',
                data: state?.searchValue,
            })
        )
    }

    // SEARCH MASTER DATA
    const searchFn = debounce((value, model) => {
        if (value?.length > 0) {
            dispatch(changeModel(model))
            dispatch(
                masterDataDropDownList({
                    search: value,
                    id: model,
                })
            )
        }
        setState((prevState) => ({ ...prevState, searchValue: value }))
    }, 800)

    useEffect(() => {
        if (userProfile) {
            const designation = userProfile?.designation
                ? [
                      {
                          label: userProfile?.designation?.name,
                          value: userProfile?.designation?._id,
                      },
                  ]
                : []
            form.setFieldsValue({
                ...userProfile,
                designation,
            })
        }
    }, [form, userProfile])

    useEffect(() => {
        window.localStorage.clear()
    }, [])

    // GOOGLE PLACE API INTEGRATION
    const loadSuggestions = async (inputValue) => {
        clearTimeout(timeoutRef.current)

        if (!inputValue || inputValue.trim().length <= 1) {
            setState((prevState) => ({ ...prevState, suggestions: [] }))
            return
        }

        timeoutRef.current = setTimeout(async () => {
            if (!sessionTokenRef.current) {
                sessionTokenRef.current =
                    new window.google.maps.places.AutocompleteSessionToken()
            }

            const placesService = new window.google.maps.places.PlacesService(
                document.createElement('div')
            )
            new window.google.maps.places.AutocompleteService().getPlacePredictions(
                {
                    input: inputValue,
                    sessionToken: sessionTokenRef.current,
                },
                async (predictions, status) => {
                    if (
                        status ===
                        window.google.maps.places.PlacesServiceStatus
                            .ZERO_RESULTS
                    ) {
                        setState((prevState) => ({
                            ...prevState,
                            suggestions: [],
                        }))
                        return
                    }
                    if (
                        status !==
                            window.google.maps.places.PlacesServiceStatus.OK ||
                        !predictions
                    ) {
                        return
                    }

                    const newData = []

                    for (const predictionData of predictions) {
                        const placeId = predictionData?.place_id

                        const placeData = await new Promise(
                            (resolve, reject) => {
                                placesService.getDetails(
                                    { placeId, fields: ['geometry'] },
                                    async (place, status) => {
                                        if (
                                            status ===
                                                window.google.maps.places
                                                    .PlacesServiceStatus.OK &&
                                            place &&
                                            place.geometry &&
                                            place.geometry.location
                                        ) {
                                            const location =
                                                place.geometry.location

                                            try {
                                                const response = await fetch(
                                                    `https://maps.googleapis.com/maps/api/timezone/json?location=${location.lat()},${location.lng()}&timestamp=${Math.floor(
                                                        Date.now() / 1000
                                                    )}&key=AIzaSyC79hoAq6x6PIprpy39EPylaZ6i6ZVEsD4`
                                                )

                                                if (!response.ok) {
                                                    throw new Error(
                                                        `HTTP error! Status: ${response.status}`
                                                    )
                                                }

                                                const data =
                                                    await response.json()
                                                const timeZoneId =
                                                    data.timeZoneId

                                                resolve({
                                                    label: predictionData?.description,
                                                    value: predictionData?.description,
                                                    timeZone: timeZoneId,
                                                })
                                            } catch (error) {
                                                console.error(
                                                    'Error fetching timezone data:',
                                                    error
                                                )
                                                reject(error)
                                            }
                                        } else {
                                            console.error(
                                                'Error getting place details:',
                                                status
                                            )
                                            reject(status)
                                        }
                                    }
                                )
                            }
                        )
                        newData.push(placeData)
                    }

                    setState((prevState) => ({
                        ...prevState,
                        suggestions: newData,
                    }))
                }
            )
        }, 350)
    }

    function onFinishFailed() {}

    async function onSignUp(value) {
        setState((prevState) => ({ ...prevState, loadingButton: true }))

        const payload = { ...value }
        if (userProfile !== undefined && userProfile?._id) {
            delete payload?.email
            delete payload?.name
        }

        let checkMobile = await checkMobileNumber()

        let checkEmail = await checkEmailId(payload?.email)

        const designationKey = 'designation'

        if (checkMobile && checkEmail) {
            const result =
                userProfile !== undefined && userProfile?._id
                    ? await dispatch(
                          addSummary({
                              _id: userProfile?._id,
                              summary: {
                                  data: {
                                      ...payload,
                                      designation:
                                          masterDataDropDown[designationKey]
                                              ?.length > 0 &&
                                          masterDataDropDown[designationKey][0]
                                              ?.type === 'addTemp'
                                              ? {
                                                    name: masterDataDropDown[
                                                        designationKey
                                                    ][0]?.label,
                                                    logo: '',
                                                }
                                              : value.designation,
                                      countryCode: state?.phoneNumber?.code,
                                      phone: state?.phoneNumber?.phoneNo,
                                  },
                              },
                          })
                      )
                    : await dispatch(
                          signUpForm({
                              ...value,
                              designation:
                                  masterDataDropDown[designationKey]?.length >
                                      0 &&
                                  masterDataDropDown[designationKey][0]
                                      ?.type === 'addTemp'
                                      ? {
                                            name: masterDataDropDown[
                                                designationKey
                                            ][0]?.label,
                                            logo: '',
                                        }
                                      : value.designation,
                              countryCode: state?.phoneNumber?.code,
                              phone: state?.phoneNumber?.phoneNo,
                          })
                      )

            const data = result?.payload?.data
            if (data) {
                const { success, message: checkMessage } = data
                if (success) {
                    if (
                        userProfile === undefined ||
                        (userProfile && !userProfile?.phone)
                    ) {
                        // if (userProfile === undefined) {
                        localStorage.setItem(
                            'phoneData',
                            JSON.stringify({
                                phoneNumber: `${state?.phoneNumber?.phoneNo}`,
                                countryCode: `${state?.phoneNumber?.code}`,
                                ...value,
                                designation:
                                    masterDataDropDown[designationKey]?.length >
                                        0 &&
                                    masterDataDropDown[designationKey][0]
                                        ?.type === 'addTemp'
                                        ? {
                                              name: masterDataDropDown[
                                                  designationKey
                                              ][0]?.label,
                                              logo: '',
                                          }
                                        : value.designation,
                                step: 'login',
                            })
                        )
                        navigate(PUBLIC_ROUTES.verifyOtp)
                    } else {
                        dispatch(isLoggedInLogin())
                    }
                    setState((prevState) => ({
                        ...prevState,
                        loadingButton: false,
                        timeZoneChange: '',
                    }))
                    message.success(checkMessage)
                } else {
                    setState((prevState) => ({
                        ...prevState,
                        loadingButton: false,
                        timeZoneChange: '',
                    }))
                    if (checkMessage) {
                        message.error(checkMessage)
                    } else {
                        message.error('Something went wrong, try again later.')
                    }
                }
            }
        } else {
        }
    }

    // CHECK MOBILE NUMBER
    async function checkMobileNumber() {
        const result = await dispatch(
            numberCheck({
                countryCode: state?.phoneNumber?.code,
                phone: state?.phoneNumber?.phoneNo,
            })
        )

        const data = result?.payload?.data
        if (data) {
            const { success, isDuplicate } = data
            if (success) {
                if (!isDuplicate) {
                    return true
                }
            } else if (!success) {
                if (isDuplicate) {
                    form.setFieldValue('phone', '+91')
                    setState((prevState) => ({
                        ...prevState,
                        phoneValid: false,
                        loadingButton: false,
                        phoneNumber: {
                            ...prevState?.phoneNumber,
                            phoneNo: null,
                            code: null,
                        },
                    }))
                    message.info('Phone Number should be unique')
                    return false
                } else {
                    setState((prevState) => ({
                        ...prevState,
                        loadingButton: false,
                    }))
                    message.error(data?.message)
                    return false
                }
            }
        }
    }

    // CHECK EMAIL ID
    async function checkEmailId(value) {
        const result = await dispatch(
            emailCheck({
                email: value,
            })
        )

        const data = result?.payload?.data
        if (data) {
            const { success, isDuplicate } = data
            if (success) {
                if (!isDuplicate) {
                    return true
                }
            } else if (!success) {
                if (isDuplicate) {
                    form.setFieldValue('email', '')
                    message.info('Email Id should be unique')
                    setState((prevState) => ({
                        ...prevState,
                        emailValid: false,
                        loadingButton: false,
                    }))
                    return false
                } else {
                    setState((prevState) => ({
                        ...prevState,
                        loadingButton: false,
                    }))
                    message.error(data?.message)
                    return false
                }
            }
        }
    }

    const handleChangePhoneNumber = (number, country) => {
        const phoneNo = number.replace(country.dialCode, '')

        setState((prevState) => ({
            ...prevState,
            phoneNumber: {
                default: number,
                phoneNo,
                code: `+${country.dialCode}`,
                flag: country.countryCode.toUpperCase(),
            },
        }))
    }

    return (
        <div
            style={{
                padding: '60px 60px 0px 60px',
            }}
        >
            <Title level={3}>Register to Software Co</Title>
            <Form
                layout="vertical"
                name="signUpForm"
                onFinish={onSignUp}
                onFinishFailed={onFinishFailed}
                requiredMark={false}
                form={form}
                style={{
                    marginTop: '20px',
                }}
            >
                <Col
                    span={24}
                    style={{
                        padding: '0px',
                        marginBottom: '10px',
                    }}
                >
                    <Form.Item
                        label="I’m applying as...*"
                        name="designation"
                        rules={[
                            {
                                required: true,
                                message: 'Please Select Role',
                            },
                        ]}
                        colon={false}
                    >
                        {/* <Select placeholder="Select Role" allowClear={false}>
                            {designationOptions.map((option) => {
                                return (
                                    <Option
                                        key={option?.label}
                                        value={option?.value}
                                    >
                                        {option?.label}
                                    </Option>
                                )
                            })}
                        </Select> */}
                        <Select
                            placeholder="Search a Designation"
                            showSearch
                            onSearch={(value) => {
                                searchFn(value, MASTER_DATA_FIELD.designation)
                            }}
                            filterOption={false}
                            allowClear={false}
                            dropdownRender={(menu) => (
                                <>
                                    {menu}
                                    <Divider
                                        style={{
                                            margin: '8px 0',
                                        }}
                                    />
                                    <Space
                                        style={{
                                            padding: '0 8px 4px',
                                        }}
                                    >
                                        <Row
                                            align={'middle'}
                                            style={{
                                                columnGap: '5px',
                                                cursor:
                                                    state?.searchValue?.length >
                                                    0
                                                        ? 'pointer'
                                                        : 'not-allowed',
                                            }}
                                            onClick={() => {
                                                if (
                                                    state?.searchValue?.length >
                                                    0
                                                ) {
                                                    addMasterData()
                                                }
                                            }}
                                        >
                                            <PlusOutlined
                                                style={{
                                                    color: token.colorPalette
                                                        .baseColor.tertiary,
                                                    fontSize:
                                                        token.fontSizeHeading5,
                                                }}
                                            />
                                            <Text
                                                style={{
                                                    color: token.colorPalette
                                                        .baseColor.tertiary,
                                                    fontSize:
                                                        token.fontSizeHeading5,
                                                    fontWeight:
                                                        token.fontWeightStrong,
                                                }}
                                            >
                                                Add
                                            </Text>
                                        </Row>
                                    </Space>
                                </>
                            )}
                        >
                            {masterDataDropDown?.designation?.length > 0 &&
                            masterDataDropDownStatus === 'loaded'
                                ? masterDataDropDown?.designation.map(
                                      (option) => {
                                          return (
                                              <Option
                                                  key={option?.value}
                                                  value={option?.value}
                                              >
                                                  <Text>{option?.label}</Text>
                                              </Option>
                                          )
                                      }
                                  )
                                : masterDataDropDownStatus === 'loading' && (
                                      <Spin></Spin>
                                  )}
                        </Select>
                    </Form.Item>
                </Col>
                <Col
                    span={24}
                    style={{
                        padding: '0px',
                        marginBottom: '10px',
                    }}
                >
                    <Form.Item
                        label="Name*"
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: 'Please Enter Name',
                            },
                        ]}
                        colon={false}
                    >
                        <Input placeholder="Enter your Name" />
                    </Form.Item>
                </Col>
                <Col
                    span={24}
                    style={{
                        padding: '0px',
                        marginBottom: '10px',
                    }}
                >
                    <Form.Item
                        label="Email Id*"
                        name="email"
                        rules={[
                            {
                                validator: async (_, value) => {
                                    const pattern = EMAIL_PATTERN

                                    if (
                                        value?.length === 0 ||
                                        value === undefined
                                    ) {
                                        return Promise.reject(
                                            new Error('Please Enter Email')
                                        )
                                    }
                                    if (
                                        !pattern.test(value) &&
                                        value?.length > 0
                                    ) {
                                        setState((prevState) => ({
                                            ...prevState,
                                            emailValid: false,
                                        }))
                                        return Promise.reject(
                                            new Error(
                                                'Please Enter a valid Email Id'
                                            )
                                        )
                                    } else {
                                        setState((prevState) => ({
                                            ...prevState,
                                            emailValid: true,
                                        }))
                                    }
                                },
                            },
                        ]}
                        colon={false}
                    >
                        <Input
                            placeholder="Enter Email Id"
                            onBlur={(e) => {
                                if (state?.emailValid) {
                                    checkEmailId(e.target.value)
                                }
                            }}
                            disabled={emailDisable}
                            onChange={() => {
                                setState((prevState) => ({
                                    ...prevState,
                                    emailValid: false,
                                }))
                            }}
                        />
                    </Form.Item>
                </Col>
                <Col
                    span={24}
                    style={{
                        padding: '0px',
                        marginBottom: '10px',
                    }}
                >
                    <Form.Item
                        label="Password*"
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: 'Please Enter Password',
                            },
                            {
                                validator: async (_, value) => {
                                    const pattern = PASSWORD_PATTERN

                                    if (
                                        !pattern.test(value) &&
                                        value?.length > 0
                                    ) {
                                        return Promise.reject(
                                            new Error(
                                                'Minimum 8 characters and maximum 32 characters, at least one uppercase letter, one lowercase letter, one number and one special character are allowed'
                                            )
                                        )
                                    }
                                },
                            },
                        ]}
                        colon={false}
                    >
                        <Input.Password
                            placeholder="Enter Password"
                            style={{
                                padding: '0px 11px',
                            }}
                            iconRender={(visible) =>
                                visible ? (
                                    <div
                                        onClick={() => {}}
                                        style={{
                                            cursor: 'pointer',
                                        }}
                                    >
                                        <PasswordShowIcon />
                                    </div>
                                ) : (
                                    <div
                                        onClick={() => {}}
                                        style={{
                                            cursor: 'pointer',
                                        }}
                                    >
                                        <PasswordHideIcon />
                                    </div>
                                )
                            }
                        />
                    </Form.Item>
                </Col>
                <Col
                    span={24}
                    style={{
                        padding: '0px',
                        marginBottom: '10px',
                    }}
                >
                    <Form.Item
                        label="Location*"
                        name="location"
                        rules={[
                            {
                                required: true,
                                message: 'Please Select Location',
                            },
                        ]}
                        colon={false}
                    >
                        <Select
                            placeholder="Search a Location"
                            allowClear={false}
                            onSelect={(_, option) => {
                                setState((prevState) => ({
                                    ...prevState,
                                    timeZoneChange: option?.key,
                                }))
                            }}
                            notFoundContent={
                                <Empty
                                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                                    description="No Locations Searched"
                                ></Empty>
                            }
                            onSearch={(value) => {
                                loadSuggestions(value)
                            }}
                            showSearch
                        >
                            {state?.suggestions?.length > 0 &&
                                state?.suggestions.map((option) => (
                                    <Option
                                        key={option?.timeZone}
                                        value={option?.label}
                                    >
                                        <Text>{option?.label}</Text>
                                        &nbsp;
                                        <Text>({option?.timeZone})</Text>
                                    </Option>
                                ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col
                    span={12}
                    style={{
                        maxWidth: '100%',
                    }}
                >
                    <Form.Item
                        label="Phone Number*"
                        name="phone"
                        rules={[
                            {
                                validator: async () => {
                                    if (
                                        !state?.phoneValid &&
                                        state?.phoneNumber?.phoneNo?.length > 0
                                    ) {
                                        return Promise.reject(
                                            new Error(
                                                'Please Enter a valid Phone Number'
                                            )
                                        )
                                    }
                                    if (
                                        state?.phoneNumber?.phoneNo?.length ===
                                            0 ||
                                        state?.phoneNumber?.phoneNo === null
                                    ) {
                                        return Promise.reject(
                                            new Error(
                                                'Please Enter Phone Number'
                                            )
                                        )
                                    }
                                },
                            },
                        ]}
                        colon={false}
                    >
                        <PhoneInput
                            inputProps={{
                                name: 'phone',
                                required: true,
                            }}
                            onlyCountries={['in', 'au', 'us', 'gb']}
                            country={'in'}
                            specialLabel=""
                            inputStyle={{
                                height: '50px',
                                width: '100%',
                            }}
                            onBlur={() => {
                                if (
                                    state?.phoneValid &&
                                    state?.phoneNumber?.phoneNo?.length > 0 &&
                                    state?.phoneNumber?.phoneNo !== null
                                ) {
                                    checkMobileNumber()
                                }
                            }}
                            value={state?.phoneNumber?.default ?? null}
                            onChange={(number, country) => {
                                handleChangePhoneNumber(number, country)
                                const correspondingCountry =
                                    PHONE_COUNTRIES.find(
                                        (countryName) =>
                                            countryName.iso2 ===
                                            country.countryCode?.toUpperCase()
                                    )
                                let numberNew = `+${number}`
                                if (
                                    correspondingCountry &&
                                    number &&
                                    correspondingCountry?.validation.test(
                                        numberNew
                                    )
                                ) {
                                    setState((prevState) => ({
                                        ...prevState,
                                        phoneValid: true,
                                    }))
                                } else {
                                    setState((prevState) => ({
                                        ...prevState,
                                        phoneValid: false,
                                    }))
                                }
                            }}
                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        name="termsAndConditionsAgreed"
                        valuePropName="checked"
                        rules={[
                            {
                                validator: (_, value) =>
                                    value
                                        ? Promise.resolve()
                                        : Promise.reject(
                                              new Error(
                                                  'Should accept agreement'
                                              )
                                          ),
                            },
                        ]}
                    >
                        <Checkbox
                            style={{
                                marginTop: '12px',
                            }}
                        >
                            I agree to the{' '}
                            <span
                                style={{
                                    textDecoration: 'underline',
                                }}
                            >
                                Terms of Service
                            </span>{' '}
                            and{' '}
                            <span
                                style={{
                                    textDecoration: 'underline',
                                }}
                            >
                                Privacy Policy
                            </span>
                            .
                        </Checkbox>
                    </Form.Item>
                </Col>
                <Row
                    gutter={20}
                    style={{
                        marginTop: '20px',
                    }}
                >
                    <Col span={24}>
                        <Button
                            props={{
                                text: 'Get Started',
                                htmlType: 'submit',
                                style: {
                                    backgroundColor:
                                        token.colorPalette.registrationColor
                                            .secondary,
                                    width: '100%',
                                },
                                disabled: state?.loadingButton,
                                loadingButton: state?.loadingButton,
                            }}
                        />
                    </Col>
                </Row>
            </Form>
            <div
                style={{
                    marginTop: '20px',
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <Text className="titleLight sub-title">
                    Already have an account?{' '}
                    <span
                        style={{
                            color: token.colorPalette.registrationColor
                                .secondary,
                            textDecoration: 'underline',
                            cursor: 'pointer',
                        }}
                        className="sub-title"
                        onClick={() => navigate(PUBLIC_ROUTES.login)}
                    >
                        Log in
                    </span>
                </Text>
            </div>
            <SocialButtonLogin />
        </div>
    )
}

export default SignUpForm
