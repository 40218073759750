import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import InfiniteScroll from 'react-infinite-scroll-component'

// antd
import {
    Avatar,
    Col,
    Dropdown,
    Empty,
    Image,
    Pagination,
    Row,
    Spin,
    Table,
    Tooltip,
    Typography,
    message,
    theme,
} from 'antd'
import { EllipsisOutlined } from '@ant-design/icons'

// slices
import {
    changePage,
    deleteUsers,
    roleList,
    totalUsers,
    usersList,
    usersState,
} from './users.slice'
import { loginState } from '../login/login.slice'

// assets
import { EditIcon, TerminateIcon } from '../../assets/icons'

// users component
import UserGridList from './userGridList'

// components
import DeletePopUp from '../../components/deletePopUp/deletePopUp'

// helpers
import { SUPER_ADMIN, WORKSPACE_ADMIN } from '../../constants/roles'

const UserList = ({ props = {} }) => {
    const {
        filteredUsersData,
        userView,
        openTerminateModal,
        openRoleEditModal,
    } = props
    const { Text } = Typography

    const { useToken } = theme
    const { token } = useToken()

    const dispatch = useDispatch()

    const {
        status,
        users,
        currentPage,
        pageLimit,
        usersCount,
        isNext,
        skipPage,
        filterDropDown,
        currentUser,
        search,
    } = useSelector(usersState)
    const { userProfile } = useSelector(loginState)

    const [deleteModal, setDeleteModal] = useState({
        modalOpen: false,
        userId: '',
    })
    const [deleteButtonLoading, setDeleteButtonLoading] = useState(false)

    const userLastColumn = [
        {
            key: 'location',
            dataIndex: 'location',
            title: 'Location',
            render: (val, row) => {
                return (
                    <div style={{ fontWeight: 500 }}>
                        {val || row?.address || 'NA'}
                    </div>
                )
            },
        },
        {
            key: 'context',
            dataIndex: 'context',
            title: '  ',
            render: (_, row) => (
                <Row justify="end" style={{ paddingRight: 5 }}>
                    <Dropdown
                        trigger={['click']}
                        placement={'bottomRight'}
                        // getPopupContainer={(trigger) => trigger.parentNode}
                        menu={{
                            items: [
                                // editPermissionCheck('users', userProfile)
                                (userProfile?.role?.roleName ===
                                    WORKSPACE_ADMIN ||
                                    userProfile?.role?.roleName ===
                                        SUPER_ADMIN) && {
                                    key: '1',
                                    label: 'Role Edit',
                                    icon: (
                                        <div
                                            style={{
                                                height: '18px',
                                                width: '18px',
                                            }}
                                        >
                                            <EditIcon />
                                        </div>
                                    ),
                                    onClick: () => {
                                        dispatch(roleList())
                                        openRoleEditModal(row)
                                    },
                                },
                                {
                                    key: '2',
                                    label:
                                        row?.isOurUser &&
                                        (userProfile?.role?.roleName ===
                                            WORKSPACE_ADMIN ||
                                            userProfile?.role?.roleName ===
                                                SUPER_ADMIN) ? (
                                            <Row style={{ columnGap: '10px' }}>
                                                <div
                                                    style={{
                                                        height: '18px',
                                                        width: '18px',
                                                    }}
                                                >
                                                    <TerminateIcon />
                                                </div>
                                                <Text
                                                    style={{
                                                        fontSize: '14px',
                                                        color: token
                                                            .colorPalette
                                                            .baseColor.error,
                                                    }}
                                                >
                                                    Terminate
                                                </Text>
                                            </Row>
                                        ) : (
                                            <Tooltip title="You have no permission to terminate this user.">
                                                <Row
                                                    style={{
                                                        columnGap: '10px',
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            height: '18px',
                                                            width: '18px',
                                                        }}
                                                    >
                                                        <TerminateIcon />
                                                    </div>
                                                    <Text
                                                        style={{
                                                            fontSize: '14px',
                                                            color: token
                                                                .colorPalette
                                                                .baseColor
                                                                .error,
                                                        }}
                                                    >
                                                        Terminate
                                                    </Text>
                                                </Row>
                                            </Tooltip>
                                        ),
                                    onClick: () => {
                                        if (
                                            row?.isOurUser &&
                                            (userProfile?.role?.roleName ===
                                                WORKSPACE_ADMIN ||
                                                userProfile?.role?.roleName ===
                                                    SUPER_ADMIN)
                                        ) {
                                            openTerminateModal(row)
                                        }
                                    },
                                },
                            ],
                        }}
                    >
                        <EllipsisOutlined rotate={90} />
                    </Dropdown>
                </Row>
            ),
        },
    ]

    const userInitialColumn = [
        {
            title: 'Name',
            key: 'name',
            dataIndex: 'name',
            render: (val, item) => (
                <Row
                    align={'middle'}
                    style={{
                        columnGap: '12px',
                        flexWrap: 'nowrap',
                    }}
                >
                    {item?.profilePicture ? (
                        <Image
                            src={item?.profilePicture}
                            height={'30px'}
                            preview={false}
                            width={'30px'}
                            style={{
                                borderRadius: '50%',
                                minWidth: '30px',
                                objectFit: 'cover',
                            }}
                        />
                    ) : (
                        <Avatar
                            style={{
                                cursor: 'pointer',
                                backgroundColor: 'rgb(0 10 129 / 25%)',
                                minWidth: '30px',
                                fontSize: '14px',
                            }}
                        >
                            {item?.name
                                ? `${item?.name[0].toUpperCase()}${
                                      item?.name.split(' ')[1] !== undefined &&
                                      item?.name.split(' ')[1] !== ''
                                          ? item?.name
                                                .split(' ')[1][0]
                                                .toUpperCase()
                                          : ''
                                  }`
                                : ''}
                        </Avatar>
                    )}
                    <Text
                        style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        {val}
                    </Text>
                </Row>
            ),
        },
        {
            key: 'role',
            dataIndex: 'role',
            title: 'Role',
            render: (role) => (
                <Row style={{ paddingRight: 5, textTransform: 'capitalize' }}>
                    {role?.roleName || 'NA'}
                </Row>
            ),
        },

        {
            key: 'designation',
            dataIndex: 'designation',
            title: 'Designation',
            render: (designation) => (
                <Row style={{ paddingRight: 5, textTransform: 'capitalize' }}>
                    {designation?.name || 'NA'}
                </Row>
            ),
        },
        {
            key: 'email',
            dataIndex: 'email',
            title: 'Email',
            render: (val) => (
                <div style={{ fontWeight: 500 }}>{val || 'NA'}</div>
            ),
        },

        {
            key: 'phone',
            dataIndex: 'phone',
            title: 'Phone',
            render: (_, item) => (
                <div style={{ fontWeight: 500 }}>
                    {item?.countryCode && item?.phone
                        ? `${item?.countryCode}
                ${item?.phone}`
                        : 'NA'}
                </div>
            ),
        },
    ]

    const columns = [...userInitialColumn, ...userLastColumn]

    // FETCH MORE USERS ON SCROLL
    async function fetchMoreUsers() {
        if (isNext) {
            await dispatch(
                usersList({
                    skip: skipPage + pageLimit,
                    limit: pageLimit,
                    filter: filterDropDown,
                })
            )
            dispatch(
                changePage({
                    currentPage: currentPage + 1,
                    skip: skipPage + pageLimit,
                    pageLimit: pageLimit,
                })
            )
        }
    }

    // DELETE USER
    async function userDelete(id) {
        setDeleteButtonLoading(true)
        const result = await dispatch(deleteUsers(id))
        const data = result?.payload?.data
        if (data) {
            const { success, message: checkMessage } = data
            if (success) {
                setDeleteButtonLoading(false)
                setDeleteModal({
                    userId: '',
                    modalOpen: false,
                })
                message.success(checkMessage)
                if (currentUser === 1 && currentPage !== 1) {
                    dispatch(
                        await usersList({
                            skip: skipPage - pageLimit,
                            limit: pageLimit,
                        })
                    )
                } else {
                    dispatch(
                        await usersList({
                            skip: skipPage,
                            limit: pageLimit,
                        })
                    )
                }
                dispatch(await totalUsers({ filter: filterDropDown }))
            } else {
                setDeleteButtonLoading(false)
                setDeleteModal({
                    userId: '',
                    modalOpen: false,
                })
                if (checkMessage) {
                    message.error(checkMessage)
                } else {
                    message.error('Something went wrong, try again later.')
                }
            }
        }
    }

    return (
        <>
            <DeletePopUp
                previewDelete={deleteModal?.modalOpen}
                onDelete={() => {
                    userDelete(deleteModal?.userId)
                }}
                loadingButton={deleteButtonLoading}
                onCancel={() => {
                    setDeleteButtonLoading(false)
                    setDeleteModal({ modalOpen: false, userId: '' })
                }}
                modalTitle="Delete User"
                pageKey="User"
            />
            <Spin spinning={status === 'loading' ? true : false}>
                {users?.length > 0 ? (
                    userView === 'list' ? (
                        <Table
                            dataSource={users}
                            scroll={{
                                x: 'scroll',
                            }}
                            rootClassName="tableGlobal"
                            style={{
                                borderRadius: '16px',
                            }}
                            columns={columns}
                            pagination={false}
                            // rowSelection={rowSelection}
                        />
                    ) : (
                        <InfiniteScroll
                            dataLength={
                                filteredUsersData?.length > 0
                                    ? filteredUsersData?.length
                                    : 0
                            }
                            next={fetchMoreUsers}
                            hasMore={isNext}
                            style={{
                                overflow: 'inherit',
                            }}
                        >
                            <Row gutter={[36, 36]}>
                                {filteredUsersData?.length > 0 &&
                                    filteredUsersData.map((user) => (
                                        <Col
                                            key={user?._id}
                                            md={24}
                                            lg={12}
                                            xl={8}
                                            xxl={6}
                                        >
                                            <UserGridList
                                                user={user}
                                                active={false}
                                                openTerminateModal={() =>
                                                    openTerminateModal()
                                                }
                                                openRoleEditModal={() =>
                                                    openRoleEditModal()
                                                }
                                            />
                                        </Col>
                                    ))}
                            </Row>
                        </InfiniteScroll>
                    )
                ) : (
                    status !== 'loading' && (
                        <Empty
                            description={'No Users Found'}
                            style={{ margin: 'auto' }}
                        />
                    )
                )}
                {users?.length > 0 && userView === 'list' && (
                    <Row
                        justify={'end'}
                        align={'middle'}
                        style={{
                            border: `2px solid ${token.colorPalette.baseColor.quaternary}`,
                            padding: '25px',
                            borderRadius: '0px 0px 8px 8px',
                        }}
                    >
                        <Text>
                            {users?.length} - {pageLimit} of {usersCount}
                        </Text>
                        <Pagination
                            defaultCurrent={currentPage}
                            defaultPageSize={pageLimit}
                            pageSizeOptions={[5, 10, 20, 50]}
                            showSizeChanger={true}
                            total={
                                isNext === false &&
                                // filterView &&
                                currentPage === 1
                                    ? users?.length
                                    : usersCount
                            }
                            onChange={async (value, pageSize) => {
                                await dispatch(
                                    changePage({
                                        currentPage: value,
                                        skip: (value - 1) * pageLimit,
                                        pageLimit: pageSize,
                                        search,
                                    })
                                )
                                const dropDownValue = {
                                    ...filterDropDown,
                                }
                                Object.keys(dropDownValue).forEach((key) => {
                                    if (dropDownValue[key] === '') {
                                        delete dropDownValue[key]
                                    }
                                })
                                await dispatch(
                                    usersList({
                                        skip: (value - 1) * pageSize,
                                        limit: pageSize,
                                        filter: dropDownValue,
                                        search,
                                    })
                                )
                            }}
                        />
                    </Row>
                )}
            </Spin>
        </>
    )
}

export default UserList
