import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { Drawer, Spin } from 'antd'
import { getJobDetails, jobState } from '../../jobs/store/job.slice'

const JobInfoDrawer = ({ state, setState }) => {
    const params = useParams()
    const dispatch = useDispatch()

    const { jobDetails, jobLoading } = useSelector(jobState)

    useEffect(() => {
        if (state?.jobInfoDrawer) {
            dispatch(getJobDetails(params.id))
        }
    }, [state?.jobInfoDrawer])

    return (
        <>
            <Drawer
                placement="right"
                closable={false}
                open={state?.jobInfoDrawer}
                onClose={() => {
                    setState((prevState) => ({
                        ...prevState,
                        jobInfoDrawer: false,
                    }))
                }}
                footer={null}
                title={null}
                style={{
                    height: '100vh',
                    minWidth: '1000px',
                    width: '1000px',
                }}
                styles={{
                    body: {
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                        padding: 0,
                    },
                    wrapper: {
                        width: 'auto !important',
                    },
                }}
                rootClassName="talentViewDrawer"
            >
                {jobLoading ? (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                        }}
                    >
                        <Spin />
                    </div>
                ) : (
                    <div>
                        <div
                            style={{
                                padding: '20px 30px',
                                background: '#F7F5FF',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '10px',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '10px',
                                    }}
                                >
                                    <h3>{jobDetails?.title || '-'}</h3>
                                    <h3>({jobDetails?.noOfPositions})</h3>
                                    <span
                                        style={{
                                            color: '#4F46E5',
                                            fontSize: '20px',
                                            textTransform: 'capitalize',
                                        }}
                                    >
                                        {jobDetails?.status}
                                    </span>
                                </div>
                                <div>
                                    <span
                                        style={{
                                            background: jobDetails?.isActiveJob
                                                ? '#36B37E29'
                                                : '#FF563029',
                                            color: jobDetails?.isActiveJob
                                                ? '#1B806A'
                                                : '#B71D18',
                                            fontWeight: 700,
                                            fontSize: '18px',
                                            padding: '3px 12px',
                                            borderRadius: '9px',
                                        }}
                                    >
                                        {jobDetails?.isActiveJob
                                            ? 'Active'
                                            : 'Inactive'}
                                    </span>
                                </div>
                            </div>
                            <div style={{ color: '#919EAB', fontSize: '18px' }}>
                                {jobDetails?.location || '-'}
                            </div>
                        </div>

                        <div style={{ padding: '20px 30px' }}>
                            <h4>Job Description</h4>

                            <div
                                style={{ fontSize: '16px' }}
                                dangerouslySetInnerHTML={{
                                    __html: jobDetails?.description,
                                }}
                            />
                        </div>
                    </div>
                )}
            </Drawer>
        </>
    )
}

export default JobInfoDrawer
