import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// antd
import { Col, Form, Input, message, Row, theme, Typography } from 'antd'

// slices
import { loginState, passwordForgot } from './login.slice'

// routes
import { PUBLIC_ROUTES } from '../../routes'

// components
import Button from '../../components/button/button'

// constants
import { PASSWORD_PATTERN } from '../../constants/patterns'

// assets
import { PasswordHideIcon, PasswordShowIcon } from '../../assets/icons'

function UpdatePassword() {
    const [form] = Form.useForm()

    const { Title } = Typography

    const { useToken } = theme

    const dispatch = useDispatch()

    const navigate = useNavigate()

    const { token } = useToken()

    const { userProfile } = useSelector(loginState)

    const [loadingButton, setLoadingButton] = useState(false)

    function onFinishFailed() {}

    async function onUpdatePassword(value) {
        setLoadingButton(true)

        const result = await dispatch(
            passwordForgot({
                userId: localStorage.getItem('userId'),
                password: value.password,
            })
        )
        const data = result?.payload?.data
        if (data) {
            const { success, message: checkMessage } = data
            if (success) {
                setLoadingButton(false)
                message.success(checkMessage)
                navigate(PUBLIC_ROUTES.successfullyResetPassword)
            } else {
                setLoadingButton(false)
                if (checkMessage) {
                    message.error(checkMessage)
                } else {
                    message.error('Something went wrong, try again later.')
                }
            }
        }
    }

    return (
        <div
            style={{
                padding: '60px 60px 0px 60px',
            }}
        >
            <Title level={3}>Update Password</Title>
            <Form
                layout="vertical"
                name="updatePassword"
                onFinish={onUpdatePassword}
                onFinishFailed={onFinishFailed}
                requiredMark={false}
                form={form}
                style={{
                    marginTop: '20px',
                }}
            >
                <Col
                    span={24}
                    style={{
                        padding: '0px',
                        marginBottom: '10px',
                    }}
                >
                    <Form.Item
                        label="New Password"
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: 'Please Enter Password',
                            },
                            {
                                validator: async (_, value) => {
                                    const pattern = PASSWORD_PATTERN

                                    if (
                                        !pattern.test(value) &&
                                        value?.length > 0
                                    ) {
                                        return Promise.reject(
                                            new Error(
                                                'Minimum 8 characters and maximum 32 characters, at least one uppercase letter, one lowercase letter, one number and one special character are allowed'
                                            )
                                        )
                                    }
                                },
                            },
                        ]}
                        colon={false}
                    >
                        <Input.Password
                            placeholder="Enter Password"
                            style={{
                                padding: '0px 11px',
                            }}
                            iconRender={(visible) =>
                                visible ? (
                                    <div
                                        onClick={() => {}}
                                        style={{
                                            cursor: 'pointer',
                                        }}
                                    >
                                        <PasswordShowIcon />
                                    </div>
                                ) : (
                                    <div
                                        onClick={() => {}}
                                        style={{
                                            cursor: 'pointer',
                                        }}
                                    >
                                        <PasswordHideIcon />
                                    </div>
                                )
                            }
                        />
                    </Form.Item>
                </Col>
                <Col
                    span={24}
                    style={{
                        padding: '0px',
                        marginBottom: '10px',
                    }}
                >
                    <Form.Item
                        label="Confirm Password"
                        name="passwordConfirm"
                        dependencies={['password']}
                        rules={[
                            {
                                required: true,
                                message: 'Please Enter Password',
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (
                                        !value ||
                                        getFieldValue('password') === value
                                    ) {
                                        return Promise.resolve()
                                    }
                                    return Promise.reject(
                                        new Error(
                                            'The Password that you entered do not match!'
                                        )
                                    )
                                },
                            }),
                        ]}
                        colon={false}
                    >
                        <Input.Password
                            placeholder="Enter Password"
                            style={{
                                padding: '0px 11px',
                            }}
                            iconRender={(visible) =>
                                visible ? (
                                    <div
                                        onClick={() => {}}
                                        style={{
                                            cursor: 'pointer',
                                        }}
                                    >
                                        <PasswordShowIcon />
                                    </div>
                                ) : (
                                    <div
                                        onClick={() => {}}
                                        style={{
                                            cursor: 'pointer',
                                        }}
                                    >
                                        <PasswordHideIcon />
                                    </div>
                                )
                            }
                        />
                    </Form.Item>
                </Col>
                <Row
                    gutter={20}
                    style={{
                        marginTop: '20px',
                    }}
                >
                    <Col span={24}>
                        <Button
                            props={{
                                text: 'Log In',
                                htmlType: 'submit',
                                style: {
                                    backgroundColor:
                                        token.colorPalette.registrationColor
                                            .secondary,
                                    width: '100%',
                                },
                                disabled: loadingButton,
                                loadingButton: loadingButton,
                            }}
                        />
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default UpdatePassword
