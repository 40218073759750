import React from 'react'

function EditProfileIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 39 39"
        >
            <path
                fill="#fff"
                d="M35.75 19.5c0-8.954-7.296-16.25-16.25-16.25S3.25 10.546 3.25 19.5c0 4.712 2.031 8.954 5.249 11.927 0 .017 0 .017-.017.033.163.162.358.293.52.439.098.081.18.162.277.227.292.244.617.471.926.699l.325.227c.309.212.634.407.975.586.114.065.244.146.357.21a9.88 9.88 0 001.024.488c.13.065.26.13.39.179.358.146.715.276 1.073.39.13.049.26.097.39.13.39.114.78.211 1.17.309.114.032.227.065.357.081.455.098.91.163 1.382.211.064 0 .13.017.195.033.552.049 1.105.081 1.657.081s1.105-.032 1.641-.081c.065 0 .13-.016.195-.033.472-.049.927-.114 1.382-.211.113-.016.227-.065.357-.081.39-.098.796-.179 1.17-.309.13-.049.26-.097.39-.13.357-.13.731-.244 1.072-.39.13-.049.26-.114.39-.179.342-.146.683-.308 1.024-.487.13-.065.244-.146.358-.211.325-.195.65-.374.975-.585.113-.066.211-.147.325-.228.325-.228.634-.455.926-.699.098-.081.179-.162.276-.227.179-.146.358-.293.52-.439 0-.016 0-.016-.016-.033 3.234-2.973 5.265-7.215 5.265-11.927zm-8.223 8.076c-4.403-2.957-11.618-2.957-16.055 0a7.85 7.85 0 00-1.787 1.625A13.78 13.78 0 015.687 19.5c0-7.621 6.192-13.813 13.813-13.813S33.313 11.88 33.313 19.5c0 3.77-1.528 7.199-3.998 9.701a7.521 7.521 0 00-1.788-1.625z"
            ></path>
            <path
                fill="#fff"
                d="M19.5 11.261a6.096 6.096 0 00-6.094 6.094c0 3.299 2.584 5.98 6.013 6.078h.292a6.083 6.083 0 005.883-6.078 6.096 6.096 0 00-6.094-6.094z"
            ></path>
        </svg>
    )
}

export default EditProfileIcon
