// antd
import { Col, Row } from 'antd'

// styles
import styles from '../profile.module.css'

function ViewVideoModal({ content }) {
    return (
        <>
            <Row align={'middle'}>
                <Col span={24}>
                    <video
                        width="100%"
                        height="394px"
                        autoPlay
                        controls
                        style={{
                            objectFit: 'cover',
                        }}
                        className={styles.videoViewContainer}
                    >
                        <source
                            src={
                                typeof content?.videoData?.link === 'string'
                                    ? content?.videoData?.link
                                    : content?.videoData?.link[0]
                            }
                            type="video/mp4"
                        />
                    </video>
                    {typeof content?.videoData?.link !== 'string' && (
                        <div
                            style={{
                                // height: '100px',
                                width: '150px',
                                borderRadius: '10px',
                            }}
                        >
                            <video
                                src={content?.videoData?.link[1]}
                                autoPlay
                                id="videoMute"
                                loop
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    right: 0,
                                    borderRadius: '50%',
                                    objectFit: 'cover',
                                }}
                                height="150px"
                                width="150px"
                            />
                        </div>
                    )}
                </Col>
            </Row>
        </>
    )
}

export default ViewVideoModal
