import { schedulesListArray } from '../../constants/schedulesArray'

export const getSchedulesList = (search, skip) => {
    return new Promise((resolve) => {
        let schedules = schedulesListArray
        if (search?.length > 0) {
            schedules = schedules.filter((x) =>
                x.name.toLowerCase().includes(search.toLowerCase())
            )
        }
        setTimeout(
            () =>
                resolve({
                    data: schedules.slice(skip, skip + 20),
                    skip,
                }),
            300
        )
    })
}

export const getSchedulesCount = (search) => {
    let schedules = schedulesListArray
    if (search?.length > 0) {
        schedules = schedules.filter((x) =>
            x.name.toLowerCase().includes(search.toLowerCase())
        )
    }
    return new Promise((resolve) => {
        setTimeout(() => resolve({ data: schedules.length }), 200)
    })
}
