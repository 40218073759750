import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import {
    getTasksList,
    getTasksCount,
    listTask,
    createTask,
    updateTask,
    deleteTask,
    getTaskById,
    changeSwimLanes,
    addRemoveComments,
    addRemoveAttachments,
    listSubTasks,
    taskHistory,
} from './tasks.service'

const initialState = {
    search: '',
    status: 'loaded',
    historyStatus: 'loaded',
    taskStatus: 'loaded',
    tasks: [],
    subTasksList: [],
    taskFiles: [],
    historyTask: [],
    tasksCount: 0,
    isNextHistory: false,
    pageLimitHistory: 30,
    currentPageHistory: 1,
    skipPageHistory: 0,
    isNext: false,
    pageLimit: 30,
    currentPage: 1,
    skipPage: 0,
    formDrawer: false,
    viewDrawer: false,
    formModal: false,
    formSubTask: false,
    viewModal: false,
    pointerEvent: true,
    globalTimer: undefined,
    filterView: false,
    filterDropDown: {
        sprintId:
            // (localStorage.getItem('currentSprint') &&
            //     JSON.parse(localStorage.getItem('currentSprint'))?.id) ||
            '',
        assignee: [],
    },
    selectedTask: undefined,
    selectedSubTask: undefined,
    selectedFormat: 'cards',
    fileError: '',
}

export const tasksList = createAsyncThunk(
    'tasksSlice/getTasksList',
    async (payload) => {
        const response = await getTasksList(payload)
        return response
    }
)

export const totalTasks = createAsyncThunk(
    'tasksSlice/getTasksCount',
    async (payload) => {
        const response = await getTasksCount(payload)
        return response
    }
)
export const taskList = createAsyncThunk(
    'tasksSlice/listTask',
    async (payload) => {
        const response = await listTask(payload)
        return response
    }
)
export const getSubTaskList = createAsyncThunk(
    'tasksSlice/listSubTasks',
    async (payload) => {
        const response = await listSubTasks(payload)
        return response
    }
)
export const taskCreate = createAsyncThunk(
    'tasksSlice/createTask',
    async (payload) => {
        const response = await createTask(payload)
        return response
    }
)
export const taskUpdate = createAsyncThunk(
    'tasksSlice/updateTask',
    async (payload) => {
        const response = await updateTask(payload)
        return response
    }
)
export const taskDelete = createAsyncThunk(
    'tasksSlice/deleteTask',
    async (payload) => {
        const response = await deleteTask(payload)
        return response
    }
)
export const taskById = createAsyncThunk(
    'tasksSlice/getTaskById',
    async (payload) => {
        const response = await getTaskById(payload)
        return response
    }
)
export const swiLaneUpdate = createAsyncThunk(
    'tasksSlice/changeSwimLanes',
    async (payload) => {
        const response = await changeSwimLanes(payload)
        return response
    }
)
export const updateComment = createAsyncThunk(
    'tasksSlice/addRemoveComments',
    async (payload) => {
        const response = await addRemoveComments(payload)
        return response
    }
)
export const updateAttachments = createAsyncThunk(
    'tasksSlice/addRemoveAttachments',
    async (payload) => {
        const response = await addRemoveAttachments(payload)
        return response
    }
)
export const historyTaskGet = createAsyncThunk(
    'tasksSlice/taskHistory',
    async (payload) => {
        const response = await taskHistory(payload)
        return response
    }
)

export const tasksSlice = createSlice({
    name: 'tasksSlice',
    initialState,
    reducers: {
        switchFormModal: (state, action) => {
            state.selectedTask = undefined
            state.formModal = action.payload.open || false
        },
        taskFilesUpload: (state, action) => {
            state.taskFiles = [...state.taskFiles, action.payload]
        },
        taskFilesRemove: (state, action) => {
            state.taskFiles = action.payload
        },
        clearTaskFileUpload: (state) => {
            state.taskFiles = []
        },
        taskFileError: (state, action) => {
            state.fileError = action.payload
        },
        switchFormModalSubTask: (state, action) => {
            state.selectedSubTask = undefined
            state.formSubTask = action.payload.open ? true : false
        },
        setPointerEvent: (state, action) => {
            state.pointerEvent = action.payload
        },
        setGlobalTimer: (state, action) => {
            state.globalTimer = action.payload
        },
        selectTaskUpdate: (state, action) => {
            state.selectedTask = action.payload
        },
        selectSubTaskUpdate: (state, action) => {
            state.selectedSubTask = action.payload
        },
        searchTasks: (state, action) => {
            state.search = action.payload
        },
        setTasks: (state, action) => {
            state.tasks = action.payload
        },
        switchFilter: (state, action) => {
            if (action.payload) {
                state.filterView = true
            } else {
                state.filterView = state.filterView ? false : true
            }
        },
        clearTasks: (state) => {
            state.search = ''
            state.tasks = []
            state.selectedTask = undefined
        },
        switchFormDrawer: (state, action) => {
            if (action.payload) state.selectedTask = undefined
            state.viewDrawer = false
            state.formDrawer = state.formDrawer ? false : true
        },
        switchFormat: (state) => {
            if (state.selectedFormat === 'cards') {
                state.selectedFormat = 'lists'
            } else {
                state.selectedFormat = 'cards'
            }
        },
        filterDropdown: (state, action) => {
            const dropDownValue = {
                ...state.filterDropDown,
                ...action.payload,
            }
            Object.keys(dropDownValue).forEach((key) => {
                if (dropDownValue[key] === '') {
                    delete dropDownValue[key]
                }
            })
            state.filterDropDown = dropDownValue
        },
        clearFilterDropDown: (state) => {
            // localStorage.setItem(
            //     'currentSprint',
            //     JSON.stringify({
            //         id: 'Backlog',
            //         name: 'Backlog',
            //     })
            // )
            state.filterDropDown = initialState.filterDropDown
        },
        changePage: (state, action) => {
            state.currentPage = action.payload.currentPage
            state.skipPage = action.payload.skip
            state.pageLimit = action.payload.pageLimit
        },
        changeHistoryPage: (state, action) => {
            state.currentPageHistory = action.payload.currentPage
            state.skipPageHistory = action.payload.skip
            state.pageLimitHistory = action.payload.pageLimit
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(tasksList.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(getSubTaskList.fulfilled, (state, action) => {
                state.status = 'loaded'
                state.subTasksList = action.payload.data
            })
            .addCase(tasksList.fulfilled, (state, action) => {
                state.status = 'loaded'
                state.tasks = action.payload.data
            })
            .addCase(taskList.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(taskList.fulfilled, (state, action) => {
                state.status = 'loaded'
                state.tasks = action.payload.data
                    .sort(function (a, b) {
                        return a.position - b.position
                    })
                    .map((item) => {
                        return {
                            _id: item._id,
                            position: item?.position,
                            list:
                                item?.list?.length > 0
                                    ? item?.list.sort(function (a, b) {
                                          return a.position - b.position
                                      })
                                    : [],
                        }
                    })
            })
            .addCase(historyTaskGet.pending, (state) => {
                state.historyStatus = 'loading'
            })
            .addCase(historyTaskGet.fulfilled, (state, action) => {
                state.historyStatus = 'loaded'
                state.historyTask = action.payload.data.data
                state.isNextHistory = action.payload.isNext
            })
            .addCase(totalTasks.fulfilled, (state, action) => {
                state.tasksCount = action.payload.data
            })
            .addCase(taskById.pending, (state) => {
                state.taskStatus = 'loading'
            })
            .addCase(taskById.fulfilled, (state, action) => {
                state.taskStatus = 'loaded'
                if (action.payload.data?.data[0]?.parentTask?.length === 0) {
                    state.selectedTask = action.payload.data?.data[0]
                } else {
                    state.selectedSubTask = action.payload.data.data[0]
                }
            })
    },
})

export default tasksSlice.reducer

export const tasksState = (state) => {
    const { tasksSlice } = state
    const {
        search,
        status,
        tasks,
        tasksCount,
        formModal,
        filterView,
        viewModal,
        selectedTask,
        filterDropDown,
        changePage,
        skipPage,
        pageLimit,
        isNext,
        selectedSubTask,
        formSubTask,
        globalTimer,
        pointerEvent,
        historyTask,
        isNextHistory,
        pageLimitHistory,
        currentPageHistory,
        skipPageHistory,
        historyStatus,
        subTasksList,
        taskStatus,
        taskFiles,
        fileError,
    } = tasksSlice
    return {
        search,
        status,
        tasks,
        tasksCount,
        formModal,
        viewModal,
        selectedTask,
        filterView,
        filterDropDown,
        changePage,
        skipPage,
        pageLimit,
        isNext,
        selectedSubTask,
        formSubTask,
        pointerEvent,
        globalTimer,
        historyTask,
        isNextHistory,
        pageLimitHistory,
        currentPageHistory,
        skipPageHistory,
        historyStatus,
        subTasksList,
        taskStatus,
        taskFiles,
        fileError,
    }
}

export const {
    switchFormModal,
    switchFormModalSubTask,
    switchFilter,
    switchViewModal,
    searchTasks,
    setTasks,
    clearTasks,
    filterDropdown,
    switchFormDrawer,
    clearFilterDropDown,
    changePage,
    selectTaskUpdate,
    selectSubTaskUpdate,
    setGlobalTimer,
    setPointerEvent,
    changeHistoryPage,
    taskFilesUpload,
    clearTaskFileUpload,
    taskFilesRemove,
    taskFileError,
} = tasksSlice.actions
