import React from 'react'

function DownloadFileIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
        >
            <path
                stroke="#252525"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M9 11v6m0 0l2-2m-2 2l-2-2M22 10v5c0 5-2 7-7 7H9c-5 0-7-2-7-7V9c0-5 2-7 7-7h5"
            ></path>
            <path
                stroke="#252525"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M22 10h-4c-3 0-4-1-4-4V2l8 8z"
            ></path>
        </svg>
    )
}

export default DownloadFileIcon
