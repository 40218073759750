import React from 'react'

function MediumPriorityIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
        >
            <circle
                cx="12"
                cy="12"
                r="10"
                stroke="#F4A261"
                strokeWidth="2"
            ></circle>
            <path fill="#F4A261" d="M9 10h6v2H9z"></path>
            <circle cx="12" cy="12" r="1.5" fill="#F4A261"></circle>
        </svg>
    )
}

export default MediumPriorityIcon
