import { useSelector } from 'react-redux'

import {
    ChatComponent,
    ChatState,
    SidebarComponent,
    SidebarState,
    SocketState,
    WrapperComponent,
} from 'chat-socket-rollup'

// slices
import { loginState } from '../login/login.slice'

const ChatModuleComponent = () => {
    const { socketClient, token, userProfile } = useSelector(loginState)

    return (
        <>
            <div
                className={'container'}
                style={{
                    zIndex: 10,
                    minHeight: '100vh',
                    padding: '0px',
                }}
            >
                <SocketState
                    socketClient={socketClient}
                    token={token}
                    user={userProfile}
                >
                    <SidebarState>
                        <ChatState>
                            <WrapperComponent>
                                <SidebarComponent />
                                <ChatComponent />
                            </WrapperComponent>
                        </ChatState>
                    </SidebarState>
                </SocketState>
            </div>
        </>
    )
}

export default ChatModuleComponent
