import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import S3FileUpload from 'react-s3'

// antd
import { Row, Typography, Upload, Image, message, theme } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

// components
import ModalProfile from '../../components/modal'
import ViewMultiMediaModal from '../profile/modals/viewMultimediaModal'

// assets
import {
    BinIcon,
    FileAddIcon,
    PreviewIcon,
    VideosIcon,
} from '../../assets/icons'

// slices
import { loginState } from '../login/login.slice'
import {
    taskFileError,
    taskFilesRemove,
    taskFilesUpload,
    tasksState,
} from './tasks.slice'

// constants
import { fileTypes, pdfTypes, videoTypes } from '../../constants/mediaTypes'

import dayjs from 'dayjs'

// styles
import styles from './tasks.module.css'

const AttachmentFileSection = () => {
    const { Title, Text } = Typography

    const { useToken } = theme
    const { token } = useToken()

    const dispatch = useDispatch()

    const { userProfile } = useSelector(loginState)
    const { taskFiles, selectedTask, selectedSubTask, formSubTask, fileError } =
        useSelector(tasksState)

    const [stream, setStream] = useState(false)
    const [fileURL, setFileURL] = useState([])
    const [viewMedia, setViewMedia] = useState({
        open: false,
        file: {},
    })

    useEffect(() => {
        if (selectedSubTask && formSubTask) {
            dispatch(taskFilesRemove(selectedSubTask?.attachment))
            setFileURL(selectedSubTask?.attachment)
        } else if (selectedTask) {
            dispatch(taskFilesRemove(selectedTask?.attachment))
            setFileURL(selectedTask?.attachment)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTask, selectedSubTask, formSubTask])

    const uploadButton = (
        <div>
            <PlusOutlined />
        </div>
    )

    // UPLOADING BUTTON
    const uploadButtonLoading = (
        <div
            className={styles.uploadButtonBox}
            style={{
                marginRight: '10px',
            }}
        >
            <Text>Uploading...</Text>
        </div>
    )

    const props = {
        name: 'files',
        multiple: true,
        async onChange(info) {
            if (fileError?.length === 0) {
                setStream(true)

                const nameChange = info?.file?.name
                    .split('.')[0]
                    .concat(`_${dayjs(new Date()).unix()}`)
                    .concat(
                        `.${
                            info?.file?.name.split('.')[
                                info?.file?.name.split('.')?.length - 1
                            ]
                        }`
                    )

                const newFileData = new File([info?.file], nameChange, {
                    type: info?.file?.type,
                })
                window.Buffer = window.Buffer || require('buffer').Buffer

                S3FileUpload.uploadFile(newFileData, userProfile?.s3Config)
                    .then(async (data) => {
                        // fileUpload = [data?.location, ...fileUpload]
                        setFileURL((prevState) => [
                            ...prevState,
                            data?.location,
                        ])
                        dispatch(taskFilesUpload(data?.location))
                    })
                    .catch(() => {
                        message.error('Upload Failed!. Please Upload again')
                    })
                    .finally(async () => {
                        setStream(false)
                    })
            }
        },
        beforeUpload(file, fileListArray) {
            dispatch(taskFileError(''))

            if (
                taskFiles?.length > 6 ||
                (taskFiles?.length <= 6 &&
                    fileListArray?.length + taskFiles?.length > 6)
            ) {
                dispatch(taskFileError('You can only upload 6 files at most!'))
                return Upload.LIST_IGNORE
            }
            const isLt50M = file.size / 1024 / 1024 < 50
            if (!isLt50M) {
                message.error('Maximum File size would be 50 MB')
                return Upload.LIST_IGNORE
            } else {
                return false
            }
        },
        onDrop() {},
    }

    return (
        <>
            <div
                style={{
                    marginTop: '20px',
                }}
            >
                <Row
                    align={'middle'}
                    style={{
                        columnGap: '6px',
                        width: '100%',
                    }}
                >
                    <FileAddIcon />
                    <Title className="titleSub">Add Files</Title>
                    <span style={{ color: 'red' }}>
                        (Please upload a{' '}
                        {[...fileTypes, ...videoTypes, ...pdfTypes].join(', ')}{' '}
                        file.)
                    </span>
                </Row>
                <Row
                    style={{
                        width: '100%',
                        flexFlow: 'inherit',
                        marginTop: '20px',
                    }}
                    className="fileTask"
                >
                    {stream && uploadButtonLoading}
                    {taskFiles?.length > 0 &&
                        // !stream &&
                        taskFiles.map((data) => (
                            <div
                                style={{
                                    marginRight: '10px',
                                }}
                                className="uploadImage"
                            >
                                {fileTypes.some((r) =>
                                    data?.toLowerCase().includes(r)
                                ) ? (
                                    <Image
                                        src={data}
                                        preview={false}
                                        width={'75px'}
                                        height={'75px'}
                                        style={{
                                            borderRadius: '5px',
                                            border: '1px solid rgba(0, 0, 0, 0.2)',
                                            cursor: 'pointer',
                                        }}
                                    />
                                ) : videoTypes.some((r) =>
                                      data?.toLowerCase().includes(r)
                                  ) ? (
                                    <video
                                        width={'75px'}
                                        height={'75px'}
                                        src={data}
                                        style={{
                                            borderRadius: '5px',
                                            border: '1px solid rgba(0, 0, 0, 0.2)',
                                            cursor: 'pointer',
                                        }}
                                    ></video>
                                ) : pdfTypes.some((r) =>
                                      data?.toLowerCase().includes(r)
                                  ) ? (
                                    <>
                                        <div>
                                            <iframe
                                                src={`${data}#view=fitH`}
                                                width={'75px'}
                                                height={'75px'}
                                                title="PDF"
                                            />
                                        </div>
                                    </>
                                ) : (
                                    <></>
                                )}

                                <div
                                    className={'hoverLayerImageAttachment'}
                                ></div>
                                <div className={'hoverShowImageAttachment'}>
                                    <div
                                        style={{
                                            width: '25px',
                                            height: '25px',
                                            cursor: 'pointer',
                                            marginRight: '5px',
                                        }}
                                        onClick={() =>
                                            setViewMedia({
                                                open: true,
                                                file: {
                                                    files: taskFiles,
                                                    selectedFile: data,
                                                },
                                            })
                                        }
                                    >
                                        <PreviewIcon />
                                    </div>
                                    <div
                                        style={{
                                            width: '25px',
                                            height: '25px',
                                            cursor: 'pointer',
                                        }}
                                        onClick={async () => {
                                            const removeData = [...fileURL]
                                            removeData.splice(
                                                removeData.findIndex(
                                                    (item) => item === data
                                                ),
                                                1
                                            )
                                            setFileURL(removeData)
                                            await dispatch(
                                                taskFilesRemove(removeData)
                                            )
                                        }}
                                    >
                                        <BinIcon
                                            color={
                                                token.colorPalette.baseColor
                                                    .error
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        ))}
                    <Upload
                        listType="picture-card"
                        accept={[...fileTypes, ...videoTypes, ...pdfTypes]}
                        {...props}
                        fileList={[]}
                        // showUploadList={{
                        //     showPreviewIcon: false,
                        // }}
                    >
                        {uploadButton}
                    </Upload>
                </Row>
                {fileError?.length > 0 && (
                    <span style={{ color: 'red', margin: 2 }}>{fileError}</span>
                )}
            </div>
            <ModalProfile
                visibility={viewMedia?.open}
                handleCancel={() => {
                    setViewMedia({
                        open: false,
                        file: {},
                    })
                }}
                setMaxHeight={false}
                footer={false}
                icon={<VideosIcon />}
                modalKey="viewVideo"
                modalTitle="Media"
                showTitle="Media"
                content={
                    <ViewMultiMediaModal
                        open={viewMedia?.open}
                        fileData={viewMedia?.file}
                    />
                }
            />
        </>
    )
}

export default AttachmentFileSection
