import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// antd
import { Modal, Row, Spin, Steps, Tabs, Typography } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

// slices
import { inquiryState } from '../../inquiries.slice'
import {
    changeRoleStep,
    roleStepData,
    rolesInquiryList,
    rolesInquiryState,
    switchRoleModal,
} from './rolesInquiry.slice'

// tab content
import ActiveRoleTab from './activeRoleTab'

// components
import Button from '../../../../components/button/button'

// add role step components
import RoleDetails from './roleDetails'
import AddRoleForm from './stepsRoleAdd/addRoleForm'
import BudgetForm from './stepsRoleAdd/budgetForm'
import TalentMatch from './stepsRoleAdd/talentMatch'
import TalentViewPage from './talentViewPage'

// helpers
import { unique } from '../../../../helpers/uniqueArray'

const RoleView = ({ keyTab }) => {
    const { Title } = Typography

    const dispatch = useDispatch()

    const { roleDetailView, selectedInquiry, defaultTab } =
        useSelector(inquiryState)
    const {
        roleModalVariations,
        updatedDataStatus,
        selectedRole,
        defaultStep,
        createRoleId,
        pageLimit,
        skipPage,
        search,
        talentView,
        isNext,
        rolesList,
    } = useSelector(rolesInquiryState)

    const [current, setCurrent] = useState(0)
    const [tabName, setTabName] = useState('activeRoles')
    const [filteredRolesList, setFilteredRolesList] = useState([])

    const onChange = (value) => {
        setCurrent(value)
    }

    const inquiryRolesList = () => {
        dispatch(
            rolesInquiryList({
                filter: {
                    isActive: tabName === 'activeRoles' ? true : false,
                },
                limit: pageLimit,
                skip: skipPage,
                inquiryId: selectedInquiry?._id,
                workspaceId: selectedInquiry?.client?.workspaceId,
                search,
            })
        )
    }

    useEffect(() => {
        if (defaultTab === keyTab) {
            inquiryRolesList()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tabName, defaultTab])

    // CHECK FOR LAZY LOADING IS_NEXT
    useEffect(() => {
        if (rolesList?.length > 0) {
            let array = []
            rolesList?.length > 0 &&
                rolesList.forEach((data) => {
                    array = [...array, data]
                })
            let newArray = [...array, ...filteredRolesList]
            let finalArray = unique(newArray, '_id')
            setFilteredRolesList(finalArray)

            if (!isNext) {
                return
            }
        } else {
            setFilteredRolesList([])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rolesList])

    const roleTabs = [
        {
            label: 'Active Roles',
            key: 'activeRoles',
            children: (
                <ActiveRoleTab
                    tabName={tabName}
                    filteredRolesList={filteredRolesList}
                />
            ),
        },
        {
            label: 'Close Roles',
            key: 'closeRoles',
            children: (
                <ActiveRoleTab
                    tabName={tabName}
                    filteredRolesList={filteredRolesList}
                />
            ),
        },
    ]

    const items = [
        {
            title: 'Role Details',
            content: <AddRoleForm setStep={() => setCurrent(1)} />,
            disabled: defaultStep === 3,
        },
        {
            title: 'Budget',
            content: <BudgetForm setStep={() => setCurrent(2)} />,
            disabled: defaultStep < 2 || defaultStep === 3,
        },
        {
            title: 'View Talent',
            content: <TalentMatch setStep={() => setCurrent(0)} />,
            disabled: defaultStep < 3,
        },
    ]

    return (
        <>
            {talentView ? (
                <>
                    <TalentViewPage />
                </>
            ) : roleDetailView && !talentView ? (
                <RoleDetails
                    clearFilteredList={() => setFilteredRolesList([])}
                    setBackActive={() => {
                        inquiryRolesList()
                    }}
                />
            ) : (
                <Tabs
                    items={roleTabs}
                    rootClassName="generalTabs"
                    onChange={(activeKey) => {
                        setTabName(activeKey)
                        setFilteredRolesList([])
                    }}
                    tabBarExtraContent={
                        <>
                            <Button
                                props={{
                                    text: 'Add Role',
                                    icon: <PlusOutlined />,
                                    onClick: () => {
                                        dispatch(
                                            switchRoleModal({ open: true })
                                        )
                                    },
                                }}
                            />
                        </>
                    }
                />
            )}
            <Modal
                centered
                footer={null}
                closable={false}
                open={roleModalVariations?.open}
                destroyOnClose={true}
                style={{
                    minWidth: '1000px',
                }}
                styles={{
                    body: {
                        padding: '25px',
                    },
                }}
                onCancel={() => {
                    if (createRoleId || defaultStep === 3) {
                    } else {
                        dispatch(switchRoleModal({ open: false }))
                        dispatch(changeRoleStep(1))
                        setCurrent(0)
                        dispatch(roleStepData(undefined))
                    }
                }}
            >
                {updatedDataStatus === 'loading' &&
                selectedRole === undefined ? (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Spin size="large" />
                    </div>
                ) : (
                    <>
                        <Row
                            align={'middle'}
                            justify={'center'}
                            style={{
                                marginBottom: '27px',
                            }}
                        >
                            <Title level={4} style={{ margin: 0 }}>
                                {`${selectedRole ? 'Edit' : 'Add'} Role`}
                            </Title>
                        </Row>
                        <Row
                            style={{
                                width: '100%',
                            }}
                            justify={'center'}
                        >
                            {!selectedRole && (
                                <Steps
                                    current={current}
                                    items={items}
                                    rootClassName="projectsStep"
                                    onChange={onChange}
                                    labelPlacement="vertical"
                                />
                            )}
                            <div
                                style={{
                                    marginTop: '30px',
                                    width: '100%',
                                }}
                            >
                                {items[current].content}
                            </div>
                        </Row>
                    </>
                )}
            </Modal>
        </>
    )
}

export default RoleView
