export const STAND_UP_POST = [
    {
        title: 'What did you do since yesterday?',
        label: 'yesterday',
        placeholder: "Add Yesterday's Task...",
        color: '#C6D9DB',
        open: false,
        description: '',
        message: "Please Add Yesterday's Task",
        isTaskList: true,
    },
    {
        title: 'What will you do today?',
        label: 'today',
        placeholder: "Add Today's Task...",
        color: '#879AB9',
        open: false,
        description: '',
        message: "Please Add Today's Task",
        isTaskList: true,
    },
    {
        title: 'Anything blocking your progress?',
        label: 'block',
        placeholder: 'Add if anything blocking...',
        color: '#D99897',
        open: false,
        description: '',
        message: 'Please Add if anything blocking',
        isTaskList: true,
    },
]
