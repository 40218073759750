import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import S3FileUpload from 'react-s3'
import { debounce } from 'lodash'

// antd
import {
    Avatar,
    Card,
    Col,
    Form,
    Image,
    Row,
    Upload,
    theme,
    DatePicker,
    Input,
    Typography,
    Select,
    message,
    Spin,
} from 'antd'
import { PlusOutlined } from '@ant-design/icons'

// slices
import { loginState } from '../../login/login.slice'
import {
    inquiriesListGet,
    inquiriesListGetSwimlanes,
    inquiryCount,
    inquiryCreate,
    inquiryGet,
    inquiryState,
    inquiryUpdate,
    switchInquiryDrawer,
} from '../inquiries.slice'
import { workSpaceListGet } from '../../workSpace/workSpace.slice'

// components
import Button from '../../../components/button/button'

// assets
import { UploadImageIcon } from '../../../assets/icons'

// constants
import {
    DOMAIN_LIST,
    SUPER_ADMIN,
    WORKSPACE_ADMIN,
} from '../../../constants/roles'

import dayjs from 'dayjs'

import styles from '../inquiries.module.css'

const AddInquiryForm = ({ inquiryValue, setInquiry }) => {
    const { useToken } = theme
    const { token } = useToken()

    const { Text } = Typography

    const { TextArea } = Input

    const [form] = Form.useForm()

    const dispatch = useDispatch()

    const { userProfile } = useSelector(loginState)
    const {
        drawerVariations,
        selectedInquiry,
        skipPage,
        pageLimit,
        filterWorkSpace,
    } = useSelector(inquiryState)

    const [fileURL, setFileURL] = useState([])
    const [loadingButton, setLoadingButton] = useState(false)
    const [fetching, setFetching] = useState(false)
    const [options, setOptions] = useState([])
    const [notFoundOptions, setNotFoundOptions] = useState(false)
    const [clientDropDown, setClientDropDown] = useState({})
    const [stream, setStream] = useState(false)

    useEffect(() => {
        if (drawerVariations?.open === false) {
            form.resetFields()
            setFileURL([])
            setInquiry()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [drawerVariations?.open])

    useEffect(() => {
        if (selectedInquiry) {
            form.setFieldsValue({
                ...selectedInquiry,
                start: selectedInquiry?.start
                    ? dayjs(selectedInquiry?.start)
                    : '',
                end: selectedInquiry?.end ? dayjs(selectedInquiry?.end) : '',
                client: [
                    {
                        label: selectedInquiry?.client?.name,
                        value: selectedInquiry?.client?.workspaceId,
                    },
                ],
            })
            if (
                (userProfile?.role?.roleName === WORKSPACE_ADMIN ||
                    userProfile?.role?.roleName === SUPER_ADMIN) &&
                !DOMAIN_LIST.includes(userProfile?.currentWorkSpace?.domain)
            ) {
                setClientDropDown({
                    name: userProfile?.currentWorkSpace?.companyName,
                    workspaceId: userProfile?.currentWorkSpace?._id,
                })
            } else {
                setClientDropDown(selectedInquiry?.client)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form, selectedInquiry])

    // SEARCH USERS
    const searchFn = debounce(async (value) => {
        setFetching(true)

        const result = await dispatch(
            workSpaceListGet({ search: value, skip: 0 })
        )
        const data = result?.payload?.data

        let filterData = []

        if (data) {
            const { success } = data
            if (success) {
                if (data?.result?.length > 0) {
                    setFetching(false)
                    filterData = data?.result.map((workSpace) => {
                        return {
                            label: workSpace?.companyName,
                            value: workSpace?._id,
                            logo: workSpace?.logo,
                            domain: workSpace?.domain,
                        }
                    })
                } else {
                    setNotFoundOptions(true)
                }
                setOptions(filterData)
            } else {
                setOptions([])
                setFetching(false)
            }
        }
    }, 1000)

    // FORMAT DATEPICKER
    const formatDatePicker = (value) => {
        return `${dayjs(value).format('DD MMM YY')}`
    }

    // DISABLE START DATE
    const disabledDateEnd = (current) => {
        const startDate = form.getFieldValue('start')
        // Disable dates that are before the selected start date or dates that are in the past
        return startDate
            ? current && current.valueOf() < startDate.valueOf()
            : null
    }

    // DISABLE END DATE
    const disabledDateStart = (current) => {
        const endDate = form.getFieldValue('end')
        // Disable dates that are after the selected end date or dates that are in the future
        return endDate ? current && current.valueOf() > endDate.valueOf() : null
    }

    const onFinishSummary = async (value) => {
        setLoadingButton(true)

        let payload = {
            ...value,
            inquiryName: inquiryValue,
            start: new Date(value?.start).getTime(),
            end: new Date(value?.end).getTime(),
            inquiryProfile: fileURL?.length > 0 ? fileURL[0] : '',
        }

        if (
            (userProfile?.role?.roleName === WORKSPACE_ADMIN ||
                userProfile?.role?.roleName === SUPER_ADMIN) &&
            !DOMAIN_LIST.includes(userProfile?.currentWorkSpace?.domain)
        ) {
            if (selectedInquiry) {
                delete payload.client
            } else {
                payload.client = {
                    name: userProfile?.currentWorkSpace?.companyName,
                    workspaceId: userProfile?.currentWorkSpace?._id,
                }
            }
        } else {
            if (
                selectedInquiry?.client?.workspaceId !==
                clientDropDown?.workspaceId
            ) {
                payload.client = clientDropDown
            } else if (selectedInquiry) {
                delete payload.client
            }
        }

        const result = selectedInquiry
            ? await dispatch(
                  inquiryUpdate({
                      updatedData: {
                          ...payload,
                          status: selectedInquiry?.status,
                      },
                      _id: selectedInquiry?._id,
                      workspaceId:
                          (userProfile?.role?.roleName === WORKSPACE_ADMIN ||
                              userProfile?.role?.roleName === SUPER_ADMIN) &&
                          !DOMAIN_LIST.includes(
                              userProfile?.currentWorkSpace?.domain
                          )
                              ? userProfile?.currentWorkSpace?._id
                              : selectedInquiry?.client?.workspaceId !==
                                clientDropDown?.workspaceId
                              ? selectedInquiry?.client?.workspaceId
                              : clientDropDown?.workspaceId,
                  })
              )
            : await dispatch(
                  inquiryCreate({
                      ...payload,
                      status: 'New Inquiry',
                  })
              )

        const data = result?.payload?.data
        if (data) {
            const {
                success,
                message: checkMessage,
                data: inquirySuccessData,
            } = data
            if (success) {
                dispatch(
                    inquiryGet({
                        id: inquirySuccessData?._id,
                        workspaceId: selectedInquiry
                            ? (userProfile?.role?.roleName ===
                                  WORKSPACE_ADMIN ||
                                  userProfile?.role?.roleName ===
                                      SUPER_ADMIN) &&
                              !DOMAIN_LIST.includes(
                                  userProfile?.currentWorkSpace?.domain
                              )
                                ? userProfile?.currentWorkSpace?._id
                                : selectedInquiry?.client?.workspaceId !==
                                  clientDropDown?.workspaceId
                                ? clientDropDown?.workspaceId
                                : selectedInquiry?.client?.workspaceId
                            : (userProfile?.role?.roleName ===
                                  WORKSPACE_ADMIN ||
                                  userProfile?.role?.roleName ===
                                      SUPER_ADMIN) &&
                              !DOMAIN_LIST.includes(
                                  userProfile?.currentWorkSpace?.domain
                              )
                            ? userProfile?.currentWorkSpace?._id
                            : clientDropDown?.workspaceId,
                    })
                )

                if (
                    userProfile?.role?.roleName === SUPER_ADMIN &&
                    DOMAIN_LIST.includes(userProfile?.currentWorkSpace?.domain)
                ) {
                    dispatch(
                        inquiriesListGetSwimlanes({
                            limit: pageLimit,
                            skip: skipPage,
                            workspaceId:
                                filterWorkSpace !== undefined
                                    ? filterWorkSpace
                                    : 'all',
                        })
                    )
                } else {
                    dispatch(
                        inquiriesListGet({
                            workspaceId: userProfile?.currentWorkSpace?._id,
                            skip: 0,
                            limit: pageLimit,
                        })
                    )
                    dispatch(
                        inquiryCount({
                            workspaceId: userProfile?.currentWorkSpace?._id,
                        })
                    )
                }
                setLoadingButton(false)
                message.success(checkMessage)
            } else {
                setLoadingButton(false)
                if (checkMessage) {
                    if (typeof checkMessage === 'object') {
                        for (const key in checkMessage) {
                            message.error(checkMessage[key])
                        }
                    } else {
                        message.error(checkMessage)
                    }
                } else {
                    message.error('Something went wrong, try again later.')
                }
            }
        }
    }

    const onFinishFailed = () => {
        if (inquiryValue?.length === 0) {
            message.error('Please Enter Inquiry Name')
        }
    }

    // ONCHANGE FILE PROPS
    const props = {
        name: 'inquiryProfile',
        multiple: false,
        onChange(info) {
            const isLt5M = info?.file.size / 1024 / 1024 < 5
            if (!isLt5M) {
                message.error('Profile picture must be smaller than 5MB')
                return
            } else {
                setStream(true)
                const nameChange = info?.fileList[0]?.name
                    .split('.')[0]
                    .concat(`_${dayjs(new Date()).unix()}`)
                    .concat(`.${info?.fileList[0]?.name.split('.')[1]}`)

                const newFileData = new File(
                    [info?.fileList[0]?.originFileObj],
                    nameChange,
                    { type: info?.fileList[0]?.type }
                )
                window.Buffer = window.Buffer || require('buffer').Buffer

                S3FileUpload.uploadFile(newFileData, userProfile?.s3Config)
                    .then(async (data) => {
                        setFileURL([data?.location])
                        setStream(false)
                    })
                    .catch(() => {
                        message.error('Upload Failed!. Please Upload again')
                        setStream(false)
                    })
            }
        },

        beforeUpload() {
            return false
        },

        onDrop(e) {},
    }

    return (
        <>
            <Form
                labelCol={{
                    style: {
                        padding: '0 0 3px',
                    },
                }}
                layout="vertical"
                name="addInquiryForm"
                onFinish={onFinishSummary}
                onFinishFailed={onFinishFailed}
                requiredMark={false}
                form={form}
                style={{
                    overflowX: 'hidden',
                }}
            >
                <div>
                    <Row gutter={30}>
                        <Col span={6}>
                            <Card
                                style={{
                                    borderRadius: '16px',
                                    height: '100%',
                                    border: `1px solid ${token.colorPalette.textColor.quaternary}`,
                                }}
                                styles={{
                                    body: {
                                        padding: '30px 44px',
                                    },
                                }}
                            >
                                <Form.Item
                                    name="inquiryProfile"
                                    rootClassName="imageUpload"
                                >
                                    <Upload
                                        listType="picture-circle"
                                        className="avatar-uploader"
                                        {...props}
                                        fileList={[]}
                                        disabled={stream}
                                        showUploadList={false}
                                        onPreview={() => {}}
                                        accept=".png,.jpg,.jpeg"
                                        style={{
                                            height: '128px !important',
                                            width: '128px !important',
                                        }}
                                    >
                                        {fileURL?.length > 0 ? (
                                            <Image
                                                src={fileURL[0]}
                                                preview={false}
                                                style={{
                                                    height: '128px',
                                                    width: '128px',
                                                    borderRadius: '50%',
                                                    objectFit: 'contain',
                                                }}
                                            />
                                        ) : (
                                            <>
                                                <Avatar
                                                    style={{
                                                        color: token
                                                            .colorPalette
                                                            .baseColor.white,
                                                    }}
                                                    className={'uploadAvatar'}
                                                    size="large"
                                                >
                                                    {userProfile?.name[0].toUpperCase()}
                                                    {userProfile?.name.split(
                                                        ' '
                                                    )[1] !== undefined &&
                                                        userProfile?.name
                                                            .split(' ')[1][0]
                                                            .toUpperCase()}
                                                </Avatar>
                                            </>
                                        )}
                                        {stream ? (
                                            <div
                                                className={styles.loaderInquiry}
                                            ></div>
                                        ) : (
                                            <div className={'hoverLayer'}>
                                                <div className={'hoverShow'}>
                                                    <UploadImageIcon />
                                                    <div
                                                        style={{
                                                            marginTop: 8,
                                                            color: token
                                                                .colorPalette
                                                                .baseColor
                                                                .white,
                                                        }}
                                                    >
                                                        Update Picture
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </Upload>
                                </Form.Item>

                                <Row
                                    style={{
                                        marginTop: '10px',
                                    }}
                                >
                                    <Text
                                        className="titleSecondary"
                                        style={{
                                            fontSize: token.fontSizeIcon,
                                            textAlign: 'center',
                                        }}
                                    >
                                        Allowed *.jpeg, *.jpg, *.png, Max size
                                        of 5 MB
                                    </Text>
                                </Row>
                            </Card>
                        </Col>
                        <Col span={18}>
                            <Row
                                gutter={20}
                                style={{
                                    rowGap: '15px',
                                }}
                            >
                                {userProfile?.role?.roleName === SUPER_ADMIN &&
                                    DOMAIN_LIST.includes(
                                        userProfile?.currentWorkSpace?.domain
                                    ) && (
                                        <Col span={12}>
                                            <Form.Item
                                                label="Client Or Company"
                                                name="client"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            'Please Choose Company',
                                                    },
                                                ]}
                                                colon={false}
                                            >
                                                <Select
                                                    filterOption={false}
                                                    onSearch={searchFn}
                                                    disabled={
                                                        selectedInquiry?.isConvertedToProject
                                                    }
                                                    placeholder="Search Client or Company..."
                                                    style={{
                                                        width: '100%',
                                                    }}
                                                    onChange={(_, data) =>
                                                        setClientDropDown({
                                                            name: data?.label,
                                                            workspaceId:
                                                                data?.value,
                                                        })
                                                    }
                                                    showSearch
                                                    notFoundContent={
                                                        notFoundOptions &&
                                                        options?.length ===
                                                            0 ? (
                                                            <Row
                                                                style={{
                                                                    padding:
                                                                        '10px',
                                                                    cursor: 'pointer',
                                                                    columnGap:
                                                                        '5px',
                                                                }}
                                                                onClick={() => {
                                                                    dispatch(
                                                                        switchInquiryDrawer(
                                                                            {
                                                                                ...drawerVariations,
                                                                                clientModal: true,
                                                                            }
                                                                        )
                                                                    )
                                                                }}
                                                            >
                                                                <PlusOutlined
                                                                    style={{
                                                                        color: token
                                                                            .colorPalette
                                                                            .textColor
                                                                            .secondary,
                                                                    }}
                                                                />
                                                                <Text
                                                                    style={{
                                                                        color: token
                                                                            .colorPalette
                                                                            .textColor
                                                                            .secondary,
                                                                    }}
                                                                >
                                                                    Add Client
                                                                </Text>
                                                            </Row>
                                                        ) : (
                                                            fetching && (
                                                                <Spin size="small" />
                                                            )
                                                        )
                                                    }
                                                    options={options}
                                                />
                                            </Form.Item>
                                        </Col>
                                    )}
                                <Col
                                    span={
                                        (userProfile?.role?.roleName ===
                                            WORKSPACE_ADMIN ||
                                            userProfile?.role?.roleName ===
                                                SUPER_ADMIN) &&
                                        !DOMAIN_LIST.includes(
                                            userProfile?.currentWorkSpace
                                                ?.domain
                                        )
                                            ? 24
                                            : 12
                                    }
                                >
                                    <Form.Item
                                        label="Project Name"
                                        name="projectName"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    'Please Enter Project Name',
                                            },
                                            {
                                                max: 25,
                                                message:
                                                    'Project Name is maximum 25 characters long',
                                            },
                                        ]}
                                        colon={false}
                                    >
                                        <Input
                                            placeholder={'Enter Project Name'}
                                            name={'projectName'}
                                            disabled={
                                                selectedInquiry?.isConvertedToProject
                                            }
                                        />
                                    </Form.Item>
                                </Col>
                                <Col flex={'1 0 50%'}>
                                    <Form.Item
                                        label="Start Date"
                                        colon={false}
                                        name={'start'}
                                    >
                                        <DatePicker
                                            allowClear={false}
                                            name="start"
                                            disabled={
                                                selectedInquiry?.isConvertedToProject
                                            }
                                            disabledDate={disabledDateStart}
                                            format={formatDatePicker}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col flex={'1 0 50%'}>
                                    <Form.Item
                                        label="End Date"
                                        colon={false}
                                        name={'end'}
                                    >
                                        <DatePicker
                                            allowClear={false}
                                            disabled={
                                                selectedInquiry?.isConvertedToProject
                                            }
                                            disabledDate={disabledDateEnd}
                                            name="end"
                                            format={formatDatePicker}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row
                        gutter={30}
                        style={{
                            rowGap: '25px',
                            marginTop: '25px',
                        }}
                    >
                        <Col span={24}>
                            <Form.Item
                                label="Description"
                                name="description"
                                rules={[
                                    {
                                        max: 500,
                                        message:
                                            'Project description is maximum 500 characters long',
                                    },
                                ]}
                                colon={false}
                            >
                                <TextArea
                                    rows={5}
                                    placeholder={'Enter Project Description...'}
                                    name={'description'}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </div>
                <Row
                    align={'middle'}
                    style={{
                        position: 'absolute',
                        marginTop: '20px',
                        right: 0,
                    }}
                >
                    <Button
                        props={{
                            text: 'Cancel',
                            buttonType: 'link',
                            style: {
                                color: '#212B36',
                            },
                            onClick: () => {
                                dispatch(
                                    switchInquiryDrawer({
                                        ...drawerVariations,
                                        open: false,
                                    })
                                )
                            },
                        }}
                    />
                    <Button
                        props={{
                            text: 'Save',
                            htmlType: 'submit',
                            loadingButton: loadingButton,
                            disabled: stream || loadingButton,
                        }}
                    />
                </Row>
            </Form>
        </>
    )
}

export default AddInquiryForm
