export const downloadFileGet = async (url, fileName) => {
    await fetch(url)
        .then(async (response) => {
            const buffer = await response.arrayBuffer()
            let fileType = response.headers.get('Content-Type')
            if (!fileType) {
                // Fallback: Set a default type if Content-Type is not available
                fileType = 'application/octet-stream'
            }
            const urlnew = window.URL.createObjectURL(
                new Blob([buffer], { type: fileType })
            )

            const link = document.createElement('a')
            link.href = urlnew
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
        })
        .catch((error) => {
            console.log('error', error)
        })
}

export const splitLastOccurrence = (str, substring) => {
    if (str !== undefined) {
        const lastIndex = str.lastIndexOf(substring)
        const before = str.slice(0, lastIndex)
        const after = str.slice(lastIndex + 1).split('.')[1]
        return before.concat(`.${after}`)
    }
}
