import React from 'react'

function PreviewIcon({ color }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 26 23"
        >
            <path
                fill={color ? color : '#fff'}
                fillRule="evenodd"
                d="M12.949 7.36a3.709 3.709 0 00-3.713 3.712 3.709 3.709 0 003.713 3.714 3.709 3.709 0 003.713-3.714 3.709 3.709 0 00-3.713-3.713zm-5.213 3.712a5.209 5.209 0 015.213-5.213 5.209 5.209 0 015.213 5.213 5.209 5.209 0 01-5.213 5.213 5.209 5.209 0 01-5.213-5.213zM.959 7.428C3.915 2.782 8.23 0 12.949 0s9.034 2.782 11.99 7.429c.656 1.028.958 2.355.958 3.637s-.302 2.61-.958 3.638c-2.956 4.646-7.271 7.428-11.99 7.428-4.72 0-9.035-2.782-11.99-7.428C.303 13.676 0 12.348 0 11.066s.303-2.61.959-3.638zM12.949 1.5c-4.083 0-7.97 2.404-10.725 6.734-.466.73-.724 1.758-.724 2.832 0 1.074.258 2.102.724 2.832 2.754 4.33 6.642 6.734 10.725 6.734 4.082 0 7.97-2.404 10.724-6.733v-.001c.466-.73.724-1.758.724-2.832 0-1.074-.258-2.102-.723-2.831l-.001-.001C20.919 3.904 17.031 1.5 12.949 1.5z"
                clipRule="evenodd"
            ></path>
        </svg>
    )
}

export default PreviewIcon
