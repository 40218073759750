import React from 'react'

function PasswordHideIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="none"
            viewBox="0 0 20 20"
        >
            <g
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                clipPath="url(#clip0_1664_11555)"
            >
                <path d="M12.107 7.89l-4.216 4.218a2.981 2.981 0 114.216-4.217z"></path>
                <path d="M14.85 4.807c-1.458-1.1-3.125-1.7-4.85-1.7-2.942 0-5.683 1.734-7.592 4.734-.75 1.175-.75 3.15 0 4.325a11.938 11.938 0 002.259 2.641m2.35 1.467c.95.4 1.958.617 2.983.617 2.941 0 5.683-1.734 7.591-4.734.75-1.175.75-3.15 0-4.325a13.524 13.524 0 00-.883-1.225"></path>
                <path d="M12.924 10.583a2.97 2.97 0 01-2.35 2.35m-2.683-.825l-6.225 6.225M18.333 1.666l-6.225 6.225"></path>
            </g>
            <defs>
                <clipPath id="clip0_1664_11555">
                    <path fill="#fff" d="M0 0H20V20H0z"></path>
                </clipPath>
            </defs>
        </svg>
    )
}

export default PasswordHideIcon
