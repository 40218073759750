import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// antd
import { Card, Row, Skeleton } from 'antd'

// slices
import {
    rolesInquiryState,
    talentDataMatch,
    talentListType,
} from './rolesInquiry.slice'
import { inquiryState } from '../../inquiries.slice'

// components
import CardTalent from './components/cardTalent'

// assets
import { NoTalentFoundIcon } from '../../../../assets/icons'
import { dummyLoop } from '../../../../constants/general'

const AllMatchesTab = ({ tabName }) => {
    const dispatch = useDispatch()

    const { selectedInquiry } = useSelector(inquiryState)

    const { selectedRole, talentList, talentStatus } =
        useSelector(rolesInquiryState)

    useEffect(() => {
        if (tabName === 'allMatchesTalent') {
            dispatch(
                talentDataMatch({
                    roleId: selectedRole?._id,
                    workspaceId: selectedInquiry?.client?.workspaceId,
                    updatedData: {
                        skip: 0,
                        limit: 10,
                    },
                })
            )
        } else {
            dispatch(
                talentListType({
                    roleId: selectedRole?._id,
                    workspaceId: selectedInquiry?.client?.workspaceId,
                    updatedData: {
                        skip: 0,
                        limit: 10,
                        fieldName: tabName,
                    },
                })
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tabName])

    return (
        <>
            {talentStatus === 'loading' ? (
                dummyLoop?.length > 0 &&
                dummyLoop.map((_) => (
                    <Card
                        style={{
                            padding: '0px',
                            marginTop: '25px',
                        }}
                        styles={{
                            body: {
                                padding: '20px',
                            },
                        }}
                    >
                        <Row align={'middle'} justify={'space-between'}>
                            <Row
                                align={'middle'}
                                style={{
                                    columnGap: '34px',
                                }}
                            >
                                <Skeleton.Image
                                    active={true}
                                    style={{
                                        height: '153px',
                                        width: '153px',
                                        borderRadius: '6px',
                                    }}
                                />
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        rowGap: '10px',
                                    }}
                                >
                                    <Skeleton.Input
                                        active={true}
                                        style={{
                                            height: '18px',
                                        }}
                                    />
                                    <Skeleton.Input
                                        active={true}
                                        style={{
                                            height: '18px',
                                        }}
                                    />
                                    <Skeleton.Input
                                        active={true}
                                        style={{
                                            height: '18px',
                                        }}
                                    />
                                </div>
                            </Row>
                        </Row>
                    </Card>
                ))
            ) : talentList?.data?.length > 0 && talentStatus === 'loaded' ? (
                tabName === 'allMatchesTalent' ? (
                    talentList?.data.filter(
                        (data) =>
                            data?.isShortListed === false &&
                            data?.isOffered === false
                    )?.length > 0 ? (
                        talentList?.data
                            .filter(
                                (data) =>
                                    data?.isShortListed === false &&
                                    data?.isOffered === false
                            )
                            .map((data) => (
                                <>
                                    <CardTalent
                                        tabName={tabName}
                                        key={data?._id}
                                        talentData={data}
                                    />
                                </>
                            ))
                    ) : (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                width: '100%',
                                margin: '100px 0px',
                            }}
                        >
                            <NoTalentFoundIcon />
                        </div>
                    )
                ) : (
                    talentList?.data.map((data) => (
                        <>
                            <CardTalent
                                tabName={tabName}
                                key={data?._id}
                                talentData={
                                    tabName === 'offeredTalent'
                                        ? data?.talent
                                        : data
                                }
                                offerData={data}
                            />
                        </>
                    ))
                )
            ) : (
                talentList?.data?.length === 0 &&
                talentStatus === 'loaded' && (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            width: '100%',
                            margin: '100px 0px',
                        }}
                    >
                        <NoTalentFoundIcon />
                    </div>
                )
            )}
        </>
    )
}

export default AllMatchesTab
