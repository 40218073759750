import { Route } from 'react-router-dom'

// routes
import { PUBLIC_ROUTES, ROOT_ROUTE } from '../../routes'

// pages
import LoginForm from '../login/LoginForm'
import ForgotPassword from '../login/forgotPassword'
import LinkExpired from '../login/linkExpired'
import LoginPage from '../login/login.index'
import SignUpForm from '../login/signUpForm'
import SuccessfullyResetPassword from '../login/successfullyResetPassword'
import UpdatePassword from '../login/updatePassword'
import VerifyOtp from '../login/verifyOtp'
import TalentLogin from '../talent/talentLogin'
import WorkSpaceLogin from '../workSpace/workSpaceLogin'

const PublicRoutesList = () => {
    return (
        <>
            <Route element={<LoginPage />}>
                <Route path={ROOT_ROUTE} element={<></>} />
                <Route path={PUBLIC_ROUTES.login} element={<LoginForm />} />
                <Route
                    path={PUBLIC_ROUTES.loginWithApp}
                    element={<LoginForm />}
                />
                <Route path={PUBLIC_ROUTES.signUp} element={<SignUpForm />} />
                <Route
                    path={PUBLIC_ROUTES.forgotPassword}
                    element={<ForgotPassword />}
                />
                <Route path={PUBLIC_ROUTES.verifyOtp} element={<VerifyOtp />} />
                <Route
                    path={PUBLIC_ROUTES.updatePassword}
                    element={<UpdatePassword />}
                />
                <Route
                    path={PUBLIC_ROUTES.successfullyResetPassword}
                    element={<SuccessfullyResetPassword />}
                />
                <Route
                    path={PUBLIC_ROUTES.linkExpired}
                    element={<LinkExpired />}
                />
            </Route>
            <Route
                path={PUBLIC_ROUTES.workSpaceLogin}
                element={<WorkSpaceLogin />}
            />
            <Route path={PUBLIC_ROUTES.talentLogin} element={<TalentLogin />} />
        </>
    )
}

export default PublicRoutesList
