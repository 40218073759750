export const VIEW_DATA_ACCESS = 'dataAccessView'
export const ADD_EDIT_DATA_ACCESS = 'dataAccessAddEdit'
export const DELETE_DATA_ACCESS = 'dataAccessDelete'
export const IMPORT_EXPORT_DATA_ACCESS = 'dataAccessImportExport'

export const ALL_DATA_ACCESS = 'allDataAccess'
export const ASSIGN_DATA_ACCESS = 'assignedAccess'
export const NO_DATA_ACCESS = 'noDataAccess'

export const PERMISSION_ROLE = [
    {
        label: 'View',
        key: VIEW_DATA_ACCESS,
        updateKey: 'view',
    },
    {
        label: 'Add/Edit',
        key: ADD_EDIT_DATA_ACCESS,
        updateKey: 'add_edit',
    },
    {
        label: 'Delete',
        key: DELETE_DATA_ACCESS,
        updateKey: 'delete',
    },
    {
        label: 'Import/Export',
        key: IMPORT_EXPORT_DATA_ACCESS,
        updateKey: 'delete',
    },
]

export const VIEW_FIELD = 'view'
export const ADD_EDIT_FIELD = 'add_edit'
export const ALL_DATA_ACCESS_VIEW = 'allDataAccessView'
export const ALL_DATA_ACCESS_ADD_EDIT = 'allDataAccessAddEdit'
