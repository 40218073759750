import React from 'react'

function NoScreenshotsIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 473 400"
        >
            <g clipPath="url(#clip0_6566_19640)">
                <path
                    fill="#3F3D56"
                    d="M0 387.388c0 .427.342.769.769.769h471.179a.766.766 0 00.769-.769.766.766 0 00-.769-.768H.768a.766.766 0 00-.768.768z"
                ></path>
                <path
                    fill="#FFB6B6"
                    d="M261.875 348.688v25.411l19.214.62-.801-26.031h-18.413z"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M258.076 362.825c-.258 0-.516.032-.762.103-3.469.989-23.537 18.911-29.603 24.377a3.298 3.298 0 00-1.092 2.648 3.285 3.285 0 001.37 2.501c4.6 3.314 14.032 8.56 25.468 6.441 5.259-.975 10.57-1.589 15.26-2.125 10.034-1.157 17.955-2.074 17.955-5.621 0-6.028-1.654-21.902-3.734-22.568-.188-.058-.407.091-.659.439-3.179 4.452-14.111 2.087-14.576 1.984l-.123-.026-.071-.103c-.058-.084-5.407-8.037-9.433-8.037v-.013z"
                ></path>
                <path
                    fill="#FFB6B6"
                    d="M225.934 348.688v25.411l19.208.62-.802-26.031h-18.406z"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M218.188 166.798l-10.228 7.746-1.86 87.382 17.353 95.439 22.62.931.62-91.104 29.132-83.041-5.893-19.524-51.744 2.171z"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M265.908 181.67l9.917 2.481 7.127 173.214-25.721-2.171-17.043-122.399 25.72-51.125zM212.217 400c-8.819 0-15.977-3.896-19.802-6.525a4.528 4.528 0 01-1.964-3.457 4.59 4.59 0 011.512-3.721c9.497-8.541 25.921-23.014 29.235-23.963 4.296-1.234 10.066 6.984 10.809 8.076 1.202.245 11.041 2.125 13.813-1.764.53-.743 1.027-.775 1.35-.672 2.966.95 4.168 20.048 4.168 23.162 0 4.102-7.76 5.001-18.504 6.241-4.678.543-9.976 1.15-15.215 2.119a29.768 29.768 0 01-5.408.498l.006.006z"
                ></path>
                <path
                    fill="#FFB6B6"
                    d="M139.741 53.74c-3.282-7.249-2.048-14.847 2.759-16.96 4.807-2.112 11.365 2.055 14.647 9.31a19.268 19.268 0 011.796 9.233l13.503 30.908-15.235 6.112-11.694-31.115a19.59 19.59 0 01-5.776-7.481v-.007z"
                ></path>
                <path
                    fill="#6C63FF"
                    d="M233.08 66.862c8.231 7.766 7.721 21.01-1.092 28.11l-43.158 34.773c-4.71 3.792-11.7 2.455-14.672-2.817-11.339-18.362-13.478-19.37-20.074-38.183l18.588-6.5 10.479 13.122 22.884-26.993c6.913-8.16 19.26-8.845 27.039-1.512h.006z"
                ></path>
                <path
                    fill="#FFB6B6"
                    d="M239.438 53.617l7.055 14.22-26.677 12.883.323-22.8 19.299-4.303z"
                ></path>
                <path
                    fill="#FFB6B6"
                    d="M219.907 64.375c13.259 0 24.008-10.75 24.008-24.009 0-13.26-10.749-24.008-24.008-24.008-13.26 0-24.009 10.749-24.009 24.008 0 13.26 10.749 24.009 24.009 24.009z"
                ></path>
                <path
                    fill="#6C63FF"
                    d="M219.746 75.177l24.615-14.543c18.052 12.663 33.086 15.622 33.564 37.666l-3.327 52.585.194 22.645c.084 9.51-7.605 17.257-17.109 17.257h-35.146c-9.304 0-16.23-8.58-14.272-17.677l6.635-30.812-7.088-60.046 11.934-7.088v.013z"
                ></path>
                <path
                    fill="#3F3D56"
                    d="M82.497 45.005c0-1.26 1.021-2.274 2.275-2.274h25.655c1.26 0 2.275 1.02 2.275 2.274v.31l63.27 3.702a1.658 1.658 0 011.609-1.253h6.726v-4.917c0-1.098.891-1.983 1.983-1.983h1.37v-4.342c0-.691.562-1.253 1.253-1.253h3.831c.692 0 1.254.562 1.254 1.253v4.342h1.26a1.203 1.203 0 01-.143-.562c0-.924 1.086-1.68 2.423-1.68 1.338 0 2.423.75 2.423 1.68 0 .239-.077.471-.207.678a1.986 1.986 0 011.331 1.867v25.494a1.983 1.983 0 01-1.983 1.984H186.29a1.984 1.984 0 01-1.983-1.984v-3.424h-6.726c-.866 0-1.57-.665-1.648-1.512l-63.335 11.662a2.277 2.277 0 01-2.171 1.596H84.771a2.273 2.273 0 01-2.274-2.274c-.303-10.402.369-20.28 0-29.384z"
                ></path>
                <path
                    fill="#FFB6B6"
                    d="M181.568 61.015c-3.198-7.288-1.874-14.866 2.959-16.927 4.833-2.055 11.339 2.183 14.537 9.478a19.21 19.21 0 011.686 9.251l13.141 31.064-15.305 5.937-11.332-31.25a19.434 19.434 0 01-5.686-7.553z"
                ></path>
                <path
                    fill="#6C63FF"
                    d="M274.745 75.229c8.141 7.862 7.469 21.1-1.421 28.098l-43.559 34.261c-4.755 3.741-11.726 2.32-14.64-2.985-11.126-18.49-12.024-15.977-18.4-34.869l17.153-10.815 10.609 14.233 23.194-26.722c7.01-8.076 19.363-8.618 27.058-1.195h.006v-.006z"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M244.593 52.441l-4.438 3.18s-1.306-13.86-3.108-14.473c-1.803-.614-4.232 2.578-4.232 2.578l-1.777 5.2-5.872-2.448s-6.797-3.663-9.201-4.484c-2.403-.82-2.041-7.785-2.041-7.785s-28.841 3.948-24.513-12.676c0 0 1.493-12.23 4.685-9.801 3.191 2.43 3.236-3.592 3.236-3.592l7.237-1.557s9.93-13.374 29.519-1.997c19.589 11.378 10.499 47.843 10.499 47.843v.012h.006z"
                ></path>
            </g>
            <defs>
                <clipPath id="clip0_6566_19640">
                    <path fill="#fff" d="M0 0H472.717V400H0z"></path>
                </clipPath>
            </defs>
        </svg>
    )
}

export default NoScreenshotsIcon
