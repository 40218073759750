import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import axios from 'axios'

// antd
import { Typography, message } from 'antd'

// assets
import landingLogo from '../../assets/images/landingLogo.png'

// styles
import styles from './callBackPage.module.css'

function AuthorizationCallBackPage() {
    const { Title } = Typography

    const params = useLocation()

    // const json = Object.fromEntries(new URLSearchParams(params?.search))

    const [authorizeText, setAuthorizeText] = useState({
        text: 'Please wait while we authorizing...',
        loader: true,
    })

    async function callBackURLcheck() {
        try {
            const data = await axios.get(
                `${process.env.REACT_APP_TIME_TRACKING_URL}${params?.search}`
            )

            const result = data?.status
            if (result === 200) {
                setAuthorizeText({
                    text: 'Your Authorization gets successful',
                    loader: false,
                })
            } else {
                setAuthorizeText({
                    text: 'You are not Authorize',
                    loader: false,
                })
            }
        } catch (error) {
            message.error(error)
        }
    }

    useEffect(() => {
        callBackURLcheck()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className={styles.mainDiv}>
            <img
                src={landingLogo}
                alt=""
                height={60}
                width={60}
                style={{
                    marginBottom: '10px',
                }}
                className={
                    authorizeText?.loader ? 'rotate linear infinite' : ''
                }
            />
            <Title level={2}>{authorizeText?.text}</Title>
        </div>
    )
}

export default AuthorizationCallBackPage
