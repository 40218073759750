import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// antd
import { Row, Typography, theme } from 'antd'

// slices
import {
    timeStampChange,
    standUpGet,
    standUpsState,
    setCalendarDate,
} from './standups.slice'
import { loginState } from '../login/login.slice'

// assets
import { NextButton, PreviousButton } from '../../assets/icons'

import styles from './standUps.module.css'

const StandUpsCalendar = () => {
    const { useToken } = theme
    const { token } = useToken()

    const { Text, Title } = Typography

    const dispatch = useDispatch()

    const { pageLimitStandUps, filterUserId } = useSelector(standUpsState)
    const { userProfile } = useSelector(loginState)

    const [currentDate, setCurrentDate] = useState(new Date())
    const [currentSelectedDate, setCurrentSelectedDate] = useState(new Date())

    useEffect(() => {
        if (userProfile?.activeProject !== null) {
            const start = currentSelectedDate.setHours(0, 0, 0, 0)
            const end = currentSelectedDate.setHours(23, 59, 59, 999)
            dispatch(timeStampChange({ start, end }))

            let data = {
                limit: pageLimitStandUps,
                skip: 0,
                titleType: 'standup',
                projectId: userProfile?.activeProject?._id,
                start,
                end,
            }

            if (filterUserId !== null) {
                data['userId'] = filterUserId
            }

            dispatch(standUpGet(data))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentSelectedDate])

    const isFutureDate = (date) => date > new Date()

    // GET WEEKS DATE
    const getWeekDates = () => {
        let startOfWeek = new Date(currentDate)
        startOfWeek.setDate(currentDate.getDate() - currentDate.getDay()) // Start of the week (Sunday)

        const endOfWeek = new Date(startOfWeek)
        endOfWeek.setDate(startOfWeek.getDate() + 6) // End of the week (Saturday)

        return { startOfWeek, endOfWeek }
    }

    //  GET WEEK DATES
    const generateWeekDates = () => {
        const { startOfWeek, endOfWeek } = getWeekDates(currentDate)
        const weekDates = []

        while (startOfWeek <= endOfWeek) {
            const formattedDate = startOfWeek.toLocaleDateString('en-Us')
            weekDates.push(formattedDate)
            startOfWeek.setDate(startOfWeek.getDate() + 1)
        }

        // return weekDates
        return weekDates.map((data) => new Date(data))
    }

    // PREVIOUS BUTTON CLICK
    const handlePrevWeek = () => {
        const newCurrentDate = new Date(currentDate)
        newCurrentDate.setDate(currentDate.getDate() - 7)

        setCurrentDate(newCurrentDate)
    }

    // NEXT BUTTON CLICK
    const handleNextWeek = () => {
        const newCurrentDate = new Date(currentDate)
        newCurrentDate.setDate(currentDate.getDate() + 7)

        setCurrentDate(newCurrentDate)
    }

    // CURRENT WEK DATES ARRAY
    const currentWeekDates = generateWeekDates()

    // CHECK IF ARRAY CONTAINS CURRENT DATE AT LAST
    const checkLastDate = () => {
        if (currentWeekDates?.length > 0) {
            // return (
            //     new Date(
            //         currentWeekDates[currentWeekDates?.length - 1]
            //     ).toLocaleDateString() === new Date().toLocaleDateString()
            // )

            return currentWeekDates.some(
                (data) =>
                    new Date(data).toLocaleDateString() ===
                    new Date().toLocaleDateString()
            )
        }
    }

    // FIND CURRENT WEEK YEAR
    const currentWeekYear = () => {
        const array = currentWeekDates.map((dateString) => {
            const dateObject = new Date(dateString)
            return `${dateObject.toLocaleString('default', {
                month: 'short',
            })} ${dateObject.toLocaleString('default', {
                year: 'numeric',
            })}`
        })
        const uniq = [...new Set(array)]
        if (uniq?.length > 1) {
            return uniq.join(' - ')
        } else {
            return uniq[0]
        }
    }

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Row>
                    <Text
                        style={{
                            color: token.colorPalette.textColor.primary,
                            marginBottom: '2px',
                        }}
                    >
                        {currentWeekYear()}
                    </Text>
                </Row>
                <Row align={'middle'}>
                    <div
                        className={styles.calendarButton}
                        onClick={() => handlePrevWeek()}
                        style={{
                            marginRight: '25px',
                        }}
                    >
                        <PreviousButton />
                    </div>
                    {currentWeekDates.map((date) => (
                        <div
                            key={date}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                cursor: isFutureDate(date)
                                    ? 'not-allowed'
                                    : 'pointer',
                                marginRight: '25px',
                            }}
                            onClick={() => {
                                if (!isFutureDate(date)) {
                                    dispatch(setCalendarDate(new Date(date)))
                                    setCurrentSelectedDate(new Date(date))
                                }
                            }}
                        >
                            <Text
                                style={{
                                    color: token.colorPalette.textColor
                                        .secondary,
                                }}
                            >
                                {new Date(date).toLocaleString('default', {
                                    weekday: 'short',
                                })}
                            </Text>
                            <Title
                                level={5}
                                style={{
                                    marginTop: '0px',
                                    fontSize:
                                        new Date(date).toLocaleDateString() ===
                                        currentSelectedDate.toLocaleDateString()
                                            ? '20px'
                                            : '16px',
                                    color:
                                        new Date(date).toLocaleDateString() ===
                                        currentSelectedDate.toLocaleDateString()
                                            ? token.colorPalette.baseColor
                                                  .tertiary
                                            : isFutureDate(date)
                                            ? token.colorPalette.textColor
                                                  .quaternary
                                            : token.colorPalette.textColor
                                                  .primary,
                                }}
                                className={
                                    !isFutureDate(date) && styles.textDate
                                }
                            >
                                {new Date(date).toLocaleString('default', {
                                    day: '2-digit',
                                })}
                            </Title>
                            {new Date(date).toLocaleDateString() ===
                                currentSelectedDate.toLocaleDateString() && (
                                <div
                                    style={{
                                        height: '8px',
                                        width: '8px',
                                        backgroundColor:
                                            token.colorPalette.baseColor
                                                .tertiary,
                                        borderRadius: '50%',
                                    }}
                                />
                            )}
                        </div>
                    ))}
                    <div
                        className={styles.calendarButton}
                        onClick={() => {
                            if (!checkLastDate()) {
                                handleNextWeek()
                            }
                        }}
                        style={{
                            cursor:
                                checkLastDate() === true
                                    ? 'not-allowed'
                                    : 'pointer',
                        }}
                    >
                        <NextButton />
                    </div>
                </Row>
            </div>
        </>
    )
}

export default StandUpsCalendar
