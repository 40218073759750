import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// antd
import { message, theme, Tabs } from 'antd'

// slices
import {
    usersList,
    usersState,
    switchFormModal,
    switchViewModal,
    totalUsers,
    clearUsers,
    searchUsers,
    deleteUsers,
    changePage,
    switchFilter,
    roleList,
    userGet,
    clearFilterDropDown,
    filterDropdown,
} from './users.slice'
import { loginState } from '../login/login.slice'

// components
import DeletePopUp from '../../components/deletePopUp/deletePopUp'
import PanelHeader from '../../components/panelHeader/panelHeader'
import ModalComponent from '../../components/modal/modalComponent'

// helpers
import { unique } from '../../helpers/uniqueArray'

// users component
import UserAddForm from './userAddForm'
import UserList from './userList'

// assets
import { TeamIcon } from '../../assets/icons'

// helpers
import { addPermissionCheck } from '../../helpers/permissionCheck'

// modal
import TerminateModal from './terminateModal'
import GridViewButton from '../../components/gridView'
import RoleEditModal from './roleEditModal'

const Users = () => {
    const dispatch = useDispatch()

    const params = useLocation()

    const { useToken } = theme

    const { token } = useToken()

    const {
        search,
        users,
        pageLimit,
        skipPage,
        currentPage,
        isNext,
        filterDropDown,
        currentUser,
        selectedUser,
        modalVariations,
        status,
    } = useSelector(usersState)
    const { userProfile } = useSelector(loginState)

    const [deleteModal, setDeleteModal] = useState({
        modalOpen: false,
        userId: '',
    })
    const [deleteButtonLoading, setDeleteButtonLoading] = useState(false)
    const [userModal, setUserModal] = useState(false)
    const [userView, setUserView] = useState('list')
    const [filteredUsersData, setFilteredUsersData] = useState([])
    const [terminateModal, setTerminateModal] = useState({
        open: false,
        loading: false,
        userData: undefined,
    })
    const [roleEditModal, setRoleEditModal] = useState({
        open: false,
        loading: false,
        userData: undefined,
    })
    const [activeTab, setActiveTab] = useState('assigned')

    const items = [
        {
            key: 'assigned',
            label: 'Assigned',
        },
        {
            key: 'unAssigned',
            label: 'Unassigned',
        },
    ]

    useEffect(() => {
        if (users?.length > 0) {
            let array = []
            users?.length > 0 &&
                users.forEach((data) => {
                    array = [...array, data]
                })
            let newArray = [...filteredUsersData, ...array]
            let finalArray = unique(newArray, '_id')
            setFilteredUsersData(finalArray)
            if (!isNext) {
                return
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [users])

    const initialUserCall = (search, updatedFilters = filterDropDown) => {
        setFilteredUsersData([])
        dispatch(
            changePage({
                currentPage: 1,
                skip: 0,
                pageLimit,
            })
        )
        dispatch(totalUsers({ filter: updatedFilters }))
        dispatch(
            usersList({
                limit: pageLimit,
                skip: 0,
                filter: updatedFilters,
                search,
            })
        )
        dispatch(switchFilter(false))
        dispatch(clearFilterDropDown())
    }

    useEffect(() => {
        initialUserCall('')
        return () => dispatch(clearUsers())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch])

    useEffect(() => {
        if (params?.search.includes('userId')) {
            getUserDetail(params?.search.split('userId=')[1])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, params])

    // DELETE USER
    async function userDelete(id) {
        setDeleteButtonLoading(true)
        const result = await dispatch(deleteUsers(id))
        const data = result?.payload?.data
        if (data) {
            const { success, message: checkMessage } = data
            if (success) {
                setDeleteButtonLoading(false)
                setDeleteModal({
                    userId: '',
                    modalOpen: false,
                })
                message.success(checkMessage)
                if (currentUser === 1 && currentPage !== 1) {
                    dispatch(
                        await usersList({
                            skip: skipPage - pageLimit,
                            limit: pageLimit,
                            search,
                        })
                    )
                } else {
                    dispatch(
                        await usersList({
                            skip: skipPage,
                            limit: pageLimit,
                            search,
                        })
                    )
                }
                dispatch(await totalUsers({ filter: filterDropDown }))
            } else {
                setDeleteButtonLoading(false)
                setDeleteModal({
                    userId: '',
                    modalOpen: false,
                })
                if (checkMessage) {
                    message.error(checkMessage)
                } else {
                    message.error('Something went wrong, try again later.')
                }
            }
        }
    }

    // GET USER DETAIL
    async function getUserDetail(id) {
        const result = await dispatch(userGet({ _id: id }))
        const data = result?.payload?.data
        if (data) {
            const { success, message: checkMessage } = data
            if (success) {
                dispatch(switchViewModal({ open: true, userData: data?.data }))
            } else {
                message.error(checkMessage)
            }
        }
    }

    // REFRESH PAGE
    function refreshPage() {
        setFilteredUsersData([])
        dispatch(
            changePage({
                currentPage: 1,
                skip: 0,
                pageLimit,
            })
        )
    }

    const onTabChange = (value) => {
        setActiveTab(value)
        refreshPage()
        let filterNew = {
            ...filterDropDown,
            isAssigned: value === 'assigned' ? true : false,
        }
        dispatch(filterDropdown(filterNew))
        dispatch(
            totalUsers({
                filter: filterNew,
                search,
            })
        )
        dispatch(
            usersList({
                limit: pageLimit,
                skip: 0,
                filter: filterNew,
                search,
            })
        )
    }

    const openModal = (userData) => {
        setTerminateModal({
            ...terminateModal,
            open: true,
            userData: userData,
        })
    }

    const cancelModal = () => {
        setTerminateModal({
            loading: false,
            open: false,
            userData: undefined,
        })
    }

    const openRoleModal = (userData) => {
        setRoleEditModal({
            ...roleEditModal,
            open: true,
            userData: userData,
        })
    }

    const cancelRoleModal = () => {
        setRoleEditModal({
            loading: false,
            open: false,
            userData: undefined,
        })
    }

    const GridViewComponent = () => {
        let filter = {
            ...filterDropDown,
            isAssigned: activeTab === 'assigned' ? true : false,
        }

        return (
            <GridViewButton
                props={{
                    onClickButton: () => initialUserCall(search, filter),
                    viewList: userView,
                    setView: (key) => setUserView(key),
                }}
            />
        )
    }

    return (
        <>
            <ModalComponent
                visibility={modalVariations?.open}
                content={
                    <UserAddForm
                        userModal={userModal}
                        onCancel={() => setUserModal(false)}
                    />
                }
                modalTitle={'User'}
                showTitle={'User'}
                icon={<TeamIcon color={token.colorPalette.baseColor.white} />}
                buttonName="Save"
                masterDataState={modalVariations}
                selectedDataMaster={selectedUser}
                handleCancel={() => {
                    dispatch(
                        switchFormModal({ ...modalVariations, open: false })
                    )
                }}
            />
            <RoleEditModal
                modal={roleEditModal}
                onCancel={() => cancelRoleModal()}
                loading={() =>
                    setRoleEditModal({ ...roleEditModal, loading: true })
                }
            />
            <TerminateModal
                modal={terminateModal}
                onCancel={() => cancelModal()}
                loading={() =>
                    setTerminateModal({ ...terminateModal, loading: true })
                }
            />
            <DeletePopUp
                previewDelete={deleteModal?.modalOpen}
                onDelete={() => {
                    userDelete(deleteModal?.userId)
                }}
                loadingButton={deleteButtonLoading}
                onCancel={() => {
                    setDeleteButtonLoading(false)
                    setDeleteModal({ modalOpen: false, userId: '' })
                }}
                modalTitle="Delete User"
                pageKey="User"
            />
            <div
                className={'container'}
                style={{
                    zIndex: 10,
                    minHeight: '100vh',
                    padding: '35px',
                }}
            >
                <>
                    <PanelHeader
                        props={{
                            panelName: 'add user',
                            searchBar: true,
                            key: 'users',
                            filterBar: false,
                            teamMembersBar: false,
                            filterValue: filterDropDown,
                            gridView: true,
                            gridViewComponent: () => <GridViewComponent />,
                            permissionAdd: addPermissionCheck(
                                'users',
                                userProfile
                            ),
                            pageLimit: pageLimit,
                            action: usersList,
                            count: totalUsers,
                            search: searchUsers,
                            refreshPage: refreshPage,
                            onClick: () => {
                                dispatch(roleList())
                                dispatch(
                                    switchFormModal({
                                        ...modalVariations,
                                        open: true,
                                    })
                                )
                            },
                        }}
                    />
                    <Tabs
                        items={items.map((data) => {
                            return {
                                ...data,
                                children: (
                                    <UserList
                                        props={{
                                            userView,
                                            filteredUsersData,
                                            openTerminateModal: (data) =>
                                                openModal(data),
                                            closeTerminateModal: cancelModal,
                                            openRoleEditModal: (data) =>
                                                openRoleModal(data),
                                            closeRoleEditModal: cancelRoleModal,
                                        }}
                                    />
                                ),
                                disabled:
                                    data.key !== activeTab &&
                                    data.key &&
                                    status === 'loading'
                                        ? true
                                        : false,
                            }
                        })}
                        onChange={onTabChange}
                        rootClassName="generalTabs"
                        style={{
                            marginTop: '30px',
                        }}
                    />
                </>
            </div>
        </>
    )
}

export default Users
