import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// antd
import { Col, Form, Image, InputNumber, Row, Typography, message } from 'antd'

// section
import TaskSettings from './taskSettings.section'

// slices
import {
    idleTimeUpdate,
    projectsList,
    projectsState,
    screenShotUpdate,
    setGeneralSettingsData,
    timeUpdate,
    weekUpdate,
} from '../projects/projects.slice'

// assets
import ProjectSetting from '../../assets/images/masterData/projectsSetting.png'
import { BackIcon } from '../../assets/icons'

const GeneralSettings = () => {
    const { Title } = Typography

    const dispatch = useDispatch()

    const navigate = useNavigate()

    const [form] = Form.useForm()

    const { currentProjectData } = useSelector(projectsState)

    const initialValues = {
        defaultTime: '',
        defaultWeek: '',
        defaultScreenshot: 0,
        defaultIdleTime: 0,
    }

    const [values] = useState(initialValues)

    useEffect(() => {
        form.setFieldsValue({
            defaultTime: currentProjectData?.defaultTime || 0,
            defaultWeek: currentProjectData?.defaultWeek || 0,
            defaultScreenshot:
                currentProjectData?.defaultScreenshotTime / 60000 || 0,
            defaultIdleTime: currentProjectData?.defaultIdealTime / 60000 || 0,
        })
    }, [form, currentProjectData])

    // ACTIVE PROJECT DETAILS
    async function activeProjectDetails() {
        const result = await dispatch(
            projectsList({
                id: JSON.parse(localStorage.getItem('currentProject'))?.id,
            })
        )
        const data = result?.payload?.data
        if (data) {
            dispatch(setGeneralSettingsData(data))
        }
    }

    // CHANGE WORKING HOURS
    async function changeTime(value) {
        if (value === undefined) {
            message('Please Enter Valid Hours')
        } else {
            const result = await dispatch(
                timeUpdate({
                    project: {
                        defaultTime: value,
                    },
                    id: JSON.parse(localStorage.getItem('currentProject'))?.id,
                })
            )
            const data = result?.payload?.data
            if (data) {
                const { success, message: checkMessage } = data
                if (success) {
                    message.success(checkMessage)
                } else {
                    if (checkMessage) {
                        message.error(checkMessage)
                    } else {
                        message.error('Something went wrong, try again later.')
                    }
                }
            }
        }
    }

    // CHANGE WEEK
    async function changeWeek(value) {
        if (value === undefined) {
            message('Please Enter Valid Hours')
        } else {
            const result = await dispatch(
                weekUpdate({
                    project: {
                        defaultWeek: value,
                    },
                    id: JSON.parse(localStorage.getItem('currentProject'))?.id,
                })
            )
            const data = result?.payload?.data
            if (data) {
                const { success, message: checkMessage } = data
                if (success) {
                    message.success(checkMessage)
                } else {
                    if (checkMessage) {
                        message.error(checkMessage)
                    } else {
                        message.error('Something went wrong, try again later.')
                    }
                }
            }
        }
    }

    // CHANGE IDLE TIME
    async function changeIdleTime(value) {
        if (value === undefined) {
            message('Please Enter Valid Hours')
        } else {
            const result = await dispatch(
                idleTimeUpdate({
                    project: {
                        defaultIdealTime: parseInt(value) * 60000,
                    },
                    id: JSON.parse(localStorage.getItem('currentProject'))?.id,
                })
            )
            const data = result?.payload?.data
            if (data) {
                const { success, message: checkMessage } = data
                if (success) {
                    message.success(checkMessage)
                } else {
                    if (checkMessage) {
                        message.error(checkMessage)
                    } else {
                        message.error('Something went wrong, try again later.')
                    }
                }
            }
        }
    }

    // CHANGE SCREENSHOT TIME
    async function changeScreenShotTime(value) {
        if (value === undefined) {
            message('Please Enter Valid Hours')
        } else {
            const result = await dispatch(
                screenShotUpdate({
                    project: {
                        defaultScreenshotTime: parseInt(value) * 60000,
                    },
                    id: JSON.parse(localStorage.getItem('currentProject'))?.id,
                })
            )
            const data = result?.payload?.data
            if (data) {
                const { success, message: checkMessage } = data
                if (success) {
                    message.success(checkMessage)
                } else {
                    if (checkMessage) {
                        message.error(checkMessage)
                    } else {
                        message.error('Something went wrong, try again later.')
                    }
                }
            }
        }
    }

    useEffect(() => {
        activeProjectDetails()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onFinishFailed = () => {}

    return (
        <>
            <div
                className={'container'}
                style={{
                    zIndex: 10,
                    minHeight: '100vh',
                    padding: '35px',
                }}
            >
                <Row
                    align={'middle'}
                    justify={'space-between'}
                    style={{
                        marginBottom: '44px',
                    }}
                >
                    <Row
                        align={'middle'}
                        style={{
                            columnGap: '13px',
                        }}
                    >
                        <Image
                            src={ProjectSetting}
                            preview={false}
                            height={28}
                            width={28}
                        />
                        <Title
                            level={3}
                            style={{
                                marginBottom: 20,
                            }}
                        >
                            All Project Setting
                        </Title>
                    </Row>
                    <div
                        style={{
                            height: '30px',
                            width: '30px',
                            cursor: 'pointer',
                        }}
                        onClick={() => navigate(-1)}
                    >
                        <BackIcon />
                    </div>
                </Row>
                <TaskSettings
                    swimLanes={currentProjectData?.swimlanes}
                    taskTypes={currentProjectData?.taskType}
                    activeProjectDetails={activeProjectDetails}
                />
                <Form
                    layout="vertical"
                    name="changeHoursForm"
                    // onFinish={changeTime}
                    onFinishFailed={onFinishFailed}
                    requiredMark={false}
                    form={form}
                    initialValues={{ ...values }}
                >
                    <Row
                        style={{
                            marginTop: '20px',
                            rowGap: '20px',
                        }}
                        gutter={{ xs: 8, sm: 8 }}
                    >
                        <Col span={12}>
                            <Col
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={16}
                                xxl={16}
                            >
                                <Title level={5}>
                                    Daily Working Hours (e.g. 1 Day = 8 Hours)
                                </Title>
                                <Form.Item name="defaultTime">
                                    <InputNumber
                                        min={1}
                                        max={24}
                                        disabled
                                        onBlur={() => {
                                            changeTime(
                                                form.getFieldValue(
                                                    'defaultTime'
                                                )
                                            )
                                        }}
                                        style={{
                                            width: '100%',
                                            marginTop: '6px',
                                        }}
                                        onKeyDown={(event) =>
                                            event.key === '.' &&
                                            event.preventDefault()
                                        }
                                        onInput={(event) =>
                                            (event = event.replace(
                                                /[^0-9]*/g,
                                                ''
                                            ))
                                        }
                                    />
                                </Form.Item>
                            </Col>
                        </Col>
                        <Col span={12}>
                            <Col
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={16}
                                xxl={16}
                            >
                                <Title level={5}>
                                    Weekly Working Days (e.g. 1 Week = 5 Days)
                                </Title>
                                <Col>
                                    <Form.Item name="defaultWeek">
                                        <InputNumber
                                            min={1}
                                            max={7}
                                            disabled
                                            style={{
                                                width: '100%',
                                                marginTop: '6px',
                                            }}
                                            onBlur={() => {
                                                changeWeek(
                                                    form.getFieldValue(
                                                        'defaultWeek'
                                                    )
                                                )
                                            }}
                                            onKeyDown={(event) =>
                                                event.key === '.' &&
                                                event.preventDefault()
                                            }
                                            onInput={(event) =>
                                                (event = event.replace(
                                                    /[^0-9]*/g,
                                                    ''
                                                ))
                                            }
                                        />
                                    </Form.Item>
                                </Col>
                            </Col>
                        </Col>
                        <Col span={12}>
                            <Col
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={16}
                                xxl={16}
                            >
                                <Title level={5}>
                                    Default Screenshot Time / min
                                </Title>
                                <Col>
                                    <Form.Item name="defaultScreenshot">
                                        <InputNumber
                                            min={3}
                                            max={24}
                                            disabled
                                            style={{
                                                width: '100%',
                                                marginTop: '6px',
                                            }}
                                            onBlur={() => {
                                                changeScreenShotTime(
                                                    form.getFieldValue(
                                                        'defaultScreenshot'
                                                    )
                                                )
                                            }}
                                            onKeyDown={(event) =>
                                                event.key === '.' &&
                                                event.preventDefault()
                                            }
                                            onInput={(event) =>
                                                (event = event.replace(
                                                    /[^0-9]*/g,
                                                    ''
                                                ))
                                            }
                                        />
                                    </Form.Item>
                                </Col>
                            </Col>
                        </Col>
                        <Col span={12}>
                            <Col
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={16}
                                xxl={16}
                            >
                                <Title level={5}>Default Idle Time / min</Title>
                                <Col>
                                    <Form.Item name="defaultIdleTime">
                                        <InputNumber
                                            min={5}
                                            max={20}
                                            disabled
                                            style={{
                                                width: '100%',
                                                marginTop: '6px',
                                            }}
                                            onBlur={() => {
                                                changeIdleTime(
                                                    form.getFieldValue(
                                                        'defaultIdleTime'
                                                    )
                                                )
                                            }}
                                            onKeyDown={(event) =>
                                                event.key === '.' &&
                                                event.preventDefault()
                                            }
                                            onInput={(event) =>
                                                (event = event.replace(
                                                    /[^0-9]*/g,
                                                    ''
                                                ))
                                            }
                                        />
                                    </Form.Item>
                                </Col>
                            </Col>
                        </Col>
                    </Row>
                </Form>
            </div>
        </>
    )
}

export default GeneralSettings
