import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import S3FileUpload from 'react-s3'
import { FileIcon, defaultStyles } from 'react-file-icon'

// antd
import { Image, Row, Typography, Upload, message, theme } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

// slices
import {
    recruitFileError,
    recruitFilesRemove,
    recruitFilesUpload,
    recruitState,
} from './recruit.slice'
import { loginState } from '../login/login.slice'

// components
import Button from '../../components/button/button'

// modal
import RecruitViewMediaModal from './recruitViewMediaModal'

// constants
import { fileTypes, pdfTypes, videoTypes } from '../../constants/mediaTypes'

// assets
import { BinIcon, PreviewIcon } from '../../assets/icons'

import styles from '../tasks/tasks.module.css'

import dayjs from 'dayjs'

const RecruitAttachment = () => {
    const { useToken } = theme
    const { token } = useToken()

    const { Text } = Typography

    const dispatch = useDispatch()

    const { fileError, recruitFiles } = useSelector(recruitState)
    const { userProfile } = useSelector(loginState)

    const [stream, setStream] = useState(false)
    const [viewMedia, setViewMedia] = useState({
        open: false,
        file: {},
    })

    // UPLOADING BUTTON
    const uploadButtonLoading = (
        <div
            className={styles.uploadButtonBox}
            style={{
                marginRight: '10px',
            }}
        >
            <Text>Uploading...</Text>
        </div>
    )

    const props = {
        name: 'files',
        multiple: true,
        async onChange(info) {
            if (fileError?.length === 0) {
                setStream(true)

                const nameChange = info?.file?.name
                    .split('.')[0]
                    .concat(`_${dayjs(new Date()).unix()}`)
                    .concat(
                        `.${
                            info?.file?.name.split('.')[
                                info?.file?.name.split('.')?.length - 1
                            ]
                        }`
                    )

                const displayName = info?.file?.name?.slice(
                    0,
                    info?.file?.name?.lastIndexOf('.')
                )
                const newFileData = new File([info?.file], nameChange, {
                    type: info?.file?.type,
                })
                window.Buffer = window.Buffer || require('buffer').Buffer

                S3FileUpload.uploadFile(newFileData, userProfile?.s3Config)
                    .then(async (data) => {
                        const newData = {
                            name: data?.location,
                            displayName: displayName,
                            createdAt: new Date().getTime(),
                        }

                        dispatch(recruitFilesUpload(newData))
                    })
                    .catch(() => {
                        message.error('Upload Failed!. Please Upload again')
                    })
                    .finally(async () => {
                        setStream(false)
                    })
            }
        },
        beforeUpload(file, fileListArray) {
            dispatch(recruitFileError(''))

            if (
                recruitFiles?.length > 6 ||
                (recruitFiles?.length <= 6 &&
                    fileListArray?.length + recruitFiles?.length > 6)
            ) {
                dispatch(
                    recruitFileError('You can only upload 6 files at most!')
                )
                return Upload.LIST_IGNORE
            }
            const isLt50M = file.size / 1024 / 1024 < 50
            if (!isLt50M) {
                message.error('Maximum File size would be 50 MB')
                return Upload.LIST_IGNORE
            } else {
                return false
            }
        },
        onDrop() {},
    }

    return (
        <>
            <Row justify={'space-between'} align={'middle'}>
                {stream && uploadButtonLoading}
                <Row align={'middle'}>
                    {recruitFiles?.length > 0 &&
                        // !stream &&
                        recruitFiles.map((data) => {
                            const str = data?.name.split('.com/')[1]
                            const substring = '_'
                            const lastIndex = str.lastIndexOf(substring)
                            const after = str.slice(lastIndex + 1).split('.')[1]
                            return (
                                <div
                                    style={{
                                        marginRight: '10px',
                                    }}
                                    className="uploadImage"
                                >
                                    {fileTypes.some((r) =>
                                        data?.name?.toLowerCase().includes(r)
                                    ) ? (
                                        <>
                                            <Image
                                                src={data?.name}
                                                preview={false}
                                                width={'75px'}
                                                height={'75px'}
                                                style={{
                                                    borderRadius: '5px',
                                                    border: '1px solid rgba(0, 0, 0, 0.2)',
                                                    cursor: 'pointer',
                                                }}
                                            />
                                        </>
                                    ) : videoTypes.some((r) =>
                                          data?.name?.toLowerCase().includes(r)
                                      ) ? (
                                        <video
                                            width="75px"
                                            height="75px"
                                            style={{
                                                borderRadius: '5px',
                                                border: '1px solid rgba(0, 0, 0, 0.2)',
                                                cursor: 'pointer',
                                            }}
                                        >
                                            <source
                                                src={data?.name}
                                                type="video/mp4"
                                            />
                                        </video>
                                    ) : (
                                        <div
                                            style={{
                                                height: '75px',
                                                width: '75px',
                                                maxHeight: '75px',
                                                borderRadius: '5px',
                                                border: '1px solid rgba(0, 0, 0, 0.2)',
                                                cursor: 'pointer',
                                            }}
                                            className="recruitFileUpload"
                                        >
                                            <FileIcon
                                                extension={after}
                                                {...defaultStyles[after]}
                                            />
                                        </div>
                                    )}
                                    <div
                                        className={'hoverLayerImageAttachment'}
                                    ></div>
                                    <div className={'hoverShowImageAttachment'}>
                                        <div
                                            style={{
                                                width: '25px',
                                                height: '25px',
                                                cursor: 'pointer',
                                                marginRight: '5px',
                                            }}
                                            onClick={() =>
                                                setViewMedia({
                                                    open: true,
                                                    file: {
                                                        files: data,
                                                    },
                                                })
                                            }
                                        >
                                            <PreviewIcon />
                                        </div>
                                        <div
                                            style={{
                                                width: '25px',
                                                height: '25px',
                                                cursor: 'pointer',
                                            }}
                                            onClick={async () => {
                                                const removeData = [
                                                    ...recruitFiles,
                                                ]
                                                removeData.splice(
                                                    removeData.findIndex(
                                                        (item) => item === data
                                                    ),
                                                    1
                                                )
                                                await dispatch(
                                                    recruitFilesRemove(
                                                        removeData
                                                    )
                                                )
                                            }}
                                        >
                                            <BinIcon
                                                color={
                                                    token.colorPalette.baseColor
                                                        .error
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                </Row>
                <Upload
                    {...props}
                    // listType="picture-card"
                    accept={[...fileTypes, ...videoTypes, ...pdfTypes]}
                    fileList={[]}
                >
                    <Button
                        props={{
                            text: 'Attachment',
                            icon: <PlusOutlined />,
                            buttonType: 'link',
                            onClick: () => {},
                            style: {
                                letterSpacing: '0.7px',
                            },
                        }}
                    />
                </Upload>
            </Row>
            {fileError?.length > 0 && (
                <span style={{ color: 'red', margin: 2 }}>{fileError}</span>
            )}
            <RecruitViewMediaModal
                attachmentData={viewMedia?.file}
                open={viewMedia?.open}
                onClose={() => {
                    setViewMedia({
                        open: false,
                        file: {},
                    })
                }}
            />
        </>
    )
}

export default RecruitAttachment
