import { Row } from 'antd'
import Button from '../button/button'

function ModalFooter({ selectedState, buttonName }) {
    return (
        <>
            <Row
                align={'middle'}
                style={{
                    padding: '30px',
                    boxShadow: '4px 0px 10px 0px rgba(0,0,0,0.1)',
                }}
            >
                <Row
                    style={{
                        margin: '0px 0px 0px auto',
                    }}
                >
                    <Button
                        props={{
                            text: buttonName,
                            style: {
                                margin: '0px 0px 0px auto',
                            },
                            htmlType: 'submit',
                            id: 'saveButtonLoading',
                            disabled:
                                selectedState &&
                                selectedState?.saveButtonLoading,
                            loadingButton:
                                selectedState &&
                                selectedState?.saveButtonLoading,
                            form: selectedState && selectedState?.formName,
                        }}
                    />
                </Row>
            </Row>
        </>
    )
}

export default ModalFooter
