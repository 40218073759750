import axiosNew from '../../helpers/axios'

// CREATE ROLE
export const create = async (payload) => {
    return new Promise(async (resolve) => {
        try {
            let data = payload ?? {}
            const createRole = await axiosNew({
                url: 'role/add-role',
                data,
            })
            resolve({ data: createRole?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// GET ROLE
export const getRole = async (payload) => {
    return new Promise(async (resolve) => {
        try {
            let data = {
                id: payload?._id,
                select: [
                    'roleName',
                    'accessModule',
                    'modulePermission',
                    'containers',
                    'systemFieldAccess',
                    'isEditable',
                ],
            }
            const roleGet = await axiosNew({
                url: 'role/list',
                data,
            })
            resolve({ data: roleGet?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// COUNT ROLE
export const count = async (payload) => {
    const { filter = {}, search } = payload
    return new Promise(async (resolve) => {
        try {
            let data = { filter }
            if (search?.length > 0) {
                data['search'] = {
                    roleName: search,
                }
            }
            const count = await axiosNew({
                url: 'role/count',
                data,
            })
            resolve({ data: count?.data ?? 0 })
        } catch (error) {
            resolve({ data: 0 })
        }
    })
}

// LIST ROLE
export const read = (payload) => {
    const { search = '', skip = 0, limit = 0 } = payload
    return new Promise(async (resolve) => {
        try {
            let data = {
                skip,
                limit,
                select: [
                    'roleName',
                    'accessModule',
                    'modulePermission',
                    'containers',
                    'systemFieldAccess',
                    'isCreatedBySuperAdmin',
                    'isEditable',
                    'createdAt',
                ],
                populate: {
                    name: 'createdBy',
                    val: ['name', 'profilePicture'],
                },
            }
            if (search?.length > 0) {
                data['search'] = {
                    roleName: search,
                }
            }
            const users = await axiosNew({
                url: 'role/list/',
                data,
            })
            resolve({
                data: users?.data?.data ?? [],
                isNext: users?.data?.isNext,
            })
        } catch (error) {
            resolve({ data: [] })
        }
    })
}

// UPDATE ROLE
export const update = async (payload) => {
    return new Promise(async (resolve) => {
        try {
            let data = payload?.updatedData ?? {}
            const updateRole = await axiosNew({
                url: 'role/edit/' + payload._id + `/${payload.step}`,
                data,
            })
            resolve({ data: updateRole?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// GET ROLE WISE FIELD
export const getRoleWiseField = async (payload) => {
    return new Promise(async (resolve) => {
        try {
            const listFields = await axiosNew({
                url: '/role/list-field/' + payload._id,
                method: 'get',
            })
            resolve({ data: listFields?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}
