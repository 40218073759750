import React from 'react'

function UnAssignIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 17 20"
        >
            <path
                fill="#000"
                d="M9.433 12.536v6.792H.666a7.013 7.013 0 018.767-6.792zm-1.754-1.098a5.259 5.259 0 01-5.26-5.26A5.259 5.259 0 017.68.918a5.259 5.259 0 015.26 5.26 5.259 5.259 0 01-5.26 5.26zm6.137 3.143l1.86-1.86 1.24 1.24-1.86 1.86 1.86 1.86-1.24 1.24-1.86-1.86-1.86 1.86-1.24-1.24 1.86-1.86-1.86-1.86 1.24-1.24 1.86 1.86z"
            ></path>
        </svg>
    )
}

export default UnAssignIcon
