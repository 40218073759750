import { useDispatch, useSelector } from 'react-redux'

// antd
import { Checkbox, Col, Popover, Row, Tabs, Typography, message } from 'antd'
import { DownOutlined } from '@ant-design/icons'

// slices
import {
    getSprint,
    sprintSelect,
    sprintsState,
    switchTab,
    taskSprintList,
} from './sprints.slice'
import { loginState } from '../login/login.slice'

import dayjs from 'dayjs'

const SprintsTab = ({ props }) => {
    const { Text, Title } = Typography

    const dispatch = useDispatch()

    const { state, setState } = props
    const { sprints, skipPageTask, pageLimitTask } = useSelector(sprintsState)
    const { userProfile } = useSelector(loginState)

    const handleOpenChange = (newOpen) => {
        setState((prevState) => ({ ...prevState, open: newOpen }))
    }

    // LIST TASKS SPRINT WISE
    async function listTasksSprint(id, label, sprintData, name) {
        let sprintId = id !== 'Backlog' && id
        const result = await dispatch(
            taskSprintList({
                skip: skipPageTask,
                limit: pageLimitTask,
                projectId: userProfile?.activeProject?._id,
                sprintId,
            })
        )
        const data = result?.payload?.data
        if (data) {
            if (name === 'sprint') {
                setState((prevState) => ({
                    ...prevState,
                    activeTabName: '',
                    activeTab: '',
                }))
                // setSprintTasks(data)
            } else {
                setState((prevState) => ({
                    ...prevState,
                    activeTabName: label,
                    activeTab: id,
                }))
            }
            dispatch(switchTab(sprintData))
        }
    }

    // GET SPRINT BY ID
    async function getSprintById(id) {
        const result = await dispatch(getSprint({ id: id }))
        const data = result?.payload?.data
        if (data) {
            const { success, message: checkMessage } = data
            if (success) {
                dispatch(sprintSelect(data?.data))
            } else {
                if (checkMessage) {
                    message.error(checkMessage)
                } else {
                    message.error('Something went wrong, try again later.')
                }
            }
        }
    }

    return (
        <>
            <Tabs
                defaultActiveKey={state?.activeTab}
                activeKey={state?.activeTab}
                items={state?.tabSprints}
                rootClassName="generalTabs"
                style={{
                    marginTop: 10,
                }}
                onChange={(e) => {
                    sprints?.length > 0 &&
                        sprints.forEach((data) => {
                            if (data?._id === e) {
                                listTasksSprint(e, data?.title, data)
                                getSprintById(data?._id, 'getSprint')
                                setState((prevState) => ({
                                    ...prevState,
                                    tabChange: true,
                                }))
                                localStorage.setItem(
                                    'currentSprint',
                                    JSON.stringify({
                                        id: data?._id,
                                        name: data?.title,
                                    })
                                )
                            }
                        })
                }}
                tabBarExtraContent={{
                    right: state?.completedSprint?.length > 0 && (
                        <Row>
                            <Popover
                                overlayInnerStyle={{
                                    padding: 20,
                                }}
                                open={state?.open}
                                onOpenChange={handleOpenChange}
                                content={
                                    <>
                                        {state?.completedSprint?.length > 0 &&
                                            state?.completedSprint.map(
                                                (data) => (
                                                    <Row>
                                                        <Col span={24}>
                                                            <Checkbox
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const index =
                                                                        state?.completedSprint.findIndex(
                                                                            (
                                                                                item
                                                                            ) =>
                                                                                item?.value ===
                                                                                e
                                                                                    .target
                                                                                    .value
                                                                        )
                                                                    const tabIndex =
                                                                        state?.tabSprints.findIndex(
                                                                            (
                                                                                item
                                                                            ) =>
                                                                                item?.key ===
                                                                                e
                                                                                    .target
                                                                                    .value
                                                                        )

                                                                    if (
                                                                        tabIndex ===
                                                                        -1
                                                                    ) {
                                                                        const sprintData =
                                                                            sprints
                                                                                .filter(
                                                                                    (
                                                                                        data
                                                                                    ) =>
                                                                                        data?._id ===
                                                                                        e
                                                                                            .target
                                                                                            .value
                                                                                )
                                                                                .map(
                                                                                    (
                                                                                        data
                                                                                    ) =>
                                                                                        data
                                                                                )

                                                                        setState(
                                                                            (
                                                                                prevState
                                                                            ) => ({
                                                                                ...prevState,
                                                                                tabSprints:
                                                                                    [
                                                                                        ...prevState?.tabSprints,
                                                                                        {
                                                                                            key: prevState
                                                                                                ?.completedSprint[
                                                                                                index
                                                                                            ]
                                                                                                ?.value,
                                                                                            label: data?.label,
                                                                                        },
                                                                                    ],
                                                                            })
                                                                        )

                                                                        listTasksSprint(
                                                                            e
                                                                                .target
                                                                                .value,
                                                                            data?.label,
                                                                            sprintData[0]
                                                                        )
                                                                        getSprintById(
                                                                            e
                                                                                .target
                                                                                .value,
                                                                            'getSprint'
                                                                        )
                                                                        setState(
                                                                            (
                                                                                prevState
                                                                            ) => ({
                                                                                ...prevState,
                                                                                tabChange: true,
                                                                            })
                                                                        )
                                                                    } else {
                                                                        const removeOldSprint =
                                                                            [
                                                                                ...state?.tabSprints,
                                                                            ]

                                                                        removeOldSprint.splice(
                                                                            tabIndex,
                                                                            1
                                                                        )
                                                                        setState(
                                                                            (
                                                                                prevState
                                                                            ) => ({
                                                                                ...prevState,
                                                                                tabSprints:
                                                                                    [
                                                                                        ...removeOldSprint,
                                                                                    ],
                                                                            })
                                                                        )

                                                                        const lastSprintData =
                                                                            removeOldSprint[
                                                                                removeOldSprint?.length -
                                                                                    1
                                                                            ]
                                                                        const sprintData =
                                                                            sprints
                                                                                .filter(
                                                                                    (
                                                                                        data
                                                                                    ) =>
                                                                                        data?._id ===
                                                                                        lastSprintData?.key
                                                                                )
                                                                                .map(
                                                                                    (
                                                                                        data
                                                                                    ) =>
                                                                                        data
                                                                                )

                                                                        listTasksSprint(
                                                                            lastSprintData?.key,
                                                                            lastSprintData?.label,
                                                                            sprintData[0]
                                                                        )
                                                                        getSprintById(
                                                                            lastSprintData?.key,
                                                                            'getSprint'
                                                                        )
                                                                        setState(
                                                                            (
                                                                                prevState
                                                                            ) => ({
                                                                                ...prevState,
                                                                                tabChange: true,
                                                                            })
                                                                        )
                                                                    }
                                                                }}
                                                                value={
                                                                    data?.value
                                                                }
                                                            >
                                                                <Text
                                                                    style={{
                                                                        fontSize:
                                                                            '16px',
                                                                        marginLeft:
                                                                            '10px',
                                                                        fontWeight: 500,
                                                                    }}
                                                                >
                                                                    {
                                                                        data?.label
                                                                    }
                                                                </Text>
                                                            </Checkbox>
                                                            <Text>
                                                                {dayjs(
                                                                    data?.end
                                                                ).format(
                                                                    'DD MMM YYYY'
                                                                )}
                                                            </Text>
                                                        </Col>
                                                    </Row>
                                                )
                                            )}
                                    </>
                                }
                                trigger="click"
                                arrow={false}
                                placement="bottom"
                            >
                                <Row
                                    align={'middle'}
                                    style={{
                                        gap: '7px',
                                    }}
                                >
                                    <Title
                                        level={5}
                                        style={{
                                            margin: 0,
                                            cursor: 'pointer',
                                        }}
                                    >
                                        Completed Sprint
                                    </Title>
                                    <DownOutlined
                                        style={{
                                            fontSize: '14px',
                                        }}
                                    />
                                </Row>
                            </Popover>
                        </Row>
                    ),
                }}
            />
        </>
    )
}

export default SprintsTab
