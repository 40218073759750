import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// antd
import { Form, Modal, Row, Upload, message } from 'antd'
import { InboxOutlined, PlusOutlined } from '@ant-design/icons'

// slices
import { loginState } from '../login/login.slice'
import { taskById, tasksState, updateAttachments } from './tasks.slice'

// components
import Button from '../../components/button/button'

const AttachmentSection = ({ getFileList }) => {
    const { Dragger } = Upload

    const dispatch = useDispatch()

    const { userProfile } = useSelector(loginState)
    const { selectedTask } = useSelector(tasksState)

    const [uploadFileModal, setUploadFileModal] = useState(false)

    const [uploadLoadingButton, setUploadLoadingButton] = useState(false)

    const [fileList, setFileList] = useState([])

    const [fileURL, setFileURL] = useState([])

    const props = {
        name: 'file',
        multiple: true,
        onChange(info) {
            let fileUpload = []
            if (info?.fileList?.length > 0) {
                fileUpload.push(...info?.fileList)
            }
            setFileURL(fileUpload)
        },
        beforeUpload(file) {
            setFileList([...fileList, file])

            return false
        },
        onDrop(e) {},
    }

    // ON FILE UPLOAD
    const onFileUpload = async () => {
        const result = await dispatch(
            updateAttachments({
                id: selectedTask?._id,
                data: {
                    fieldName: 'add',
                    attachment: {
                        uId: userProfile?._id,
                        file: fileURL[0],
                        createdDate: new Date().getTime(),
                        username: userProfile?.name,
                    },
                },
            })
        )
        const data = result?.payload?.data
        if (data) {
            const { success, message: checkMessage } = data
            if (success) {
                setUploadLoadingButton(false)
                message.success(checkMessage)
                await dispatch(taskById({ _id: selectedTask?._id }))
                setUploadFileModal(false)
            } else {
                setUploadLoadingButton(false)
                setUploadFileModal(false)
                if (checkMessage) {
                    message.error(checkMessage)
                } else {
                    message.error('Something went wrong')
                }
            }
        }
    }
    const onFinishFailed = () => {}

    return (
        <>
            <Row
                style={{
                    float: 'right',
                }}
                align={'middle'}
            >
                <Button
                    props={{
                        text: 'Attachment',
                        icon: <PlusOutlined />,
                        buttonType: 'link',
                        type: 'files',
                        onClick: () => setUploadFileModal(true),
                        style: {
                            letterSpacing: '0.7px',
                            color: '#4F46E5',
                        },
                    }}
                />
            </Row>
            <Modal
                footer={null}
                closable={false}
                open={uploadFileModal}
                destroyOnClose={true}
                onCancel={() => {
                    setFileList([])
                    setUploadFileModal(false)
                }}
            >
                <Form
                    layout="vertical"
                    name="statusUpdate"
                    onFinish={onFileUpload}
                    onFinishFailed={onFinishFailed}
                    initialValues={{
                        file: [],
                    }}
                    requiredMark={false}
                >
                    <Form.Item name="dragger" noStyle className="fileDragger">
                        <Dragger
                            {...props}
                            showUploadList={{
                                showPreviewIcon: false,
                            }}
                            accept="*"
                        >
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">
                                Click or drag file to this area to upload
                            </p>
                            <p className="ant-upload-hint">
                                Support for a single or bulk upload. Strictly
                                prohibited from uploading company data or other
                                banned files.
                            </p>
                        </Dragger>
                    </Form.Item>
                    {fileList?.length === 0 ? (
                        <></>
                    ) : (
                        <Row
                            style={{
                                marginTop: 10,
                            }}
                        >
                            <Button
                                props={{
                                    text: 'Upload',
                                    loadingButton: uploadLoadingButton,
                                    onClick: () => {
                                        getFileList(fileURL)
                                        setUploadFileModal(false)
                                    },
                                    style: {
                                        width: '100%',
                                    },
                                }}
                            />
                        </Row>
                    )}
                </Form>
            </Modal>
        </>
    )
}

export default AttachmentSection
