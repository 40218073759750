import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

// antd
import { Card, Col, Image, Row, Space, Tooltip, Typography } from 'antd'

// slices
import { loginState } from '../login/login.slice'

// assets

import WavingIcon from '../../assets/icons/wavingIcon'

// styles
import styles from './home.module.css'

import dayjs from 'dayjs'

const HomeLayout = () => {
    const { Text, Title } = Typography

    // STATES
    const [weatherData, setWeatherData] = useState()
    const [messageTime, setMessageTime] = useState('')

    // SLICES
    const { userProfile } = useSelector(loginState)

    // GET WEATHER DATA
    function getWeatherData() {
        navigator.geolocation.getCurrentPosition(async (position) => {
            try {
                const response = await fetch(
                    `https://api.openweathermap.org/data/2.5/weather?lat=${position.coords.latitude}&lon=${position.coords.longitude}&units=metric&appid=14df025c8c0c82978efcaa1be6b69d57`
                )

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`)
                }

                const data = await response.json()

                setWeatherData(data)
            } catch (error) {
                console.error('Error fetching timezone data:', error)
            }
        })
    }

    // CHECK TIME AND SHOW MESSAGE
    function checkTime() {
        let date = new Date()
        let hours = date.getHours()
        let message =
            hours < 12
                ? 'Good Morning! Start your day with a prayer, coffee and standup 🌞'
                : hours < 18
                ? 'Good Afternoon! Finish your lunch and bugs on time ⏱'
                : 'Good Evening! Before you leave for the day, post your recap and git push 🚀'
        setMessageTime(`${message}`)
    }

    // GET WEATHER DATA
    useEffect(() => {
        getWeatherData()
        checkTime()
        const myInterval = setInterval(getWeatherData, 3600000)
        const checkInterval = setInterval(checkTime, 3600000)
        return () => {
            clearInterval(myInterval)
            clearInterval(checkInterval)
        }
    }, [])

    return (
        <>
            <Row align={'middle'} justify={'space-between'}>
                <Col span={12}>
                    <Row>
                        <Title
                            level={4}
                            style={{
                                marginBottom: '5px',
                            }}
                        >
                            {dayjs(new Date()).format('dddd')}
                        </Title>
                    </Row>
                    <Row>
                        <Title
                            level={4}
                            style={{
                                marginBottom: '5px',
                            }}
                        >
                            {dayjs(new Date()).format('DD/MM/YYYY')}
                        </Title>
                    </Row>
                </Col>
                <Col span={12}>
                    <Row>
                        <Space>
                            <Tooltip
                                arrow={false}
                                style={{
                                    textTransform: 'capitalize',
                                }}
                                title={weatherData?.weather[0]?.description}
                            >
                                <Image
                                    width={60}
                                    preview={false}
                                    src={`https://openweathermap.org/img/wn/${weatherData?.weather[0]?.icon}@2x.png`}
                                />
                            </Tooltip>
                            <Title
                                style={{
                                    marginBottom: 0,
                                }}
                                level={3}
                            >
                                {weatherData?.main?.temp}
                            </Title>
                            <Col>
                                <Row>
                                    <Text level={5}>°C | °F</Text>
                                </Row>
                            </Col>
                            <Col>
                                <Row>
                                    <Text>
                                        Humidity: {weatherData?.main?.humidity}%
                                    </Text>
                                </Row>
                                <Text>
                                    Wind:{' '}
                                    {Math.round(
                                        weatherData?.wind?.speed * 3.6,
                                        1
                                    )}{' '}
                                    km/h
                                </Text>
                            </Col>
                        </Space>
                    </Row>
                </Col>
            </Row>
            <Card className={styles.card}>
                <Row
                    align={'middle'}
                    style={{
                        marginBottom: '5px',
                    }}
                >
                    <div
                        style={{
                            height: '22px',
                            width: '22px',
                        }}
                    >
                        <WavingIcon />
                    </div>
                    &nbsp;&nbsp;
                    <Title
                        level={4}
                        style={{
                            margin: 0,
                        }}
                    >
                        Hi, {userProfile?.name}
                    </Title>
                </Row>
                <Text
                    style={{
                        fontSize: '18px',
                    }}
                >
                    {messageTime}
                </Text>
            </Card>
        </>
    )
}

export default HomeLayout
