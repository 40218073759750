import React from 'react'

import { Select, Card } from 'antd'

import { NotesRecruitIcon } from '../../../assets/icons'
import RightArrow from '../../../assets/icons/rightArrow'
import { JOB_APPLICATIONS_STATUS } from '../../../constants/jobs'

const JobApplicantInfoCard = ({
    state,
    setState,
    handleUpdateJobApplicationStatus,
    handleViewAndUpdateResume,
    handleViewNotes,
    JobApplicationState,
}) => {
    return (
        <>
            <Card>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            gap: '20px',
                            alignItems: 'center',
                            width: '50%',
                        }}
                    >
                        <div>
                            <img
                                src={
                                    state?.selectedApplicantData
                                        ?.profilePicture || ''
                                }
                                style={{
                                    width: '144px',
                                    height: '144px',
                                    borderRadius: '6px',
                                }}
                            />
                        </div>

                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                color: '#747474',
                                wordBreak: 'break-all',
                                gap: '5px',
                            }}
                        >
                            <h3 style={{ color: '#252525' }}>
                                {state?.selectedApplicantData?.name || ''}
                            </h3>
                            <span>
                                {state?.selectedApplicantData?.designation
                                    ?.name || ''}{' '}
                                | {state?.selectedApplicantData?.location || ''}
                            </span>
                            <span>
                                {state?.selectedApplicantData?.phone
                                    ? `${state?.selectedApplicantData?.countryCode} ${state?.selectedApplicantData?.phone}`
                                    : state?.selectedApplicantData?.phone}{' '}
                                | {state?.selectedApplicantData?.email || ''}
                            </span>
                            <span>
                                <span>Linked URL : </span>
                                {state?.selectedApplicantData?.linkedUrl ? (
                                    <a
                                        target="_blank"
                                        style={{
                                            color: '#4F46E5',
                                            wordBreak: 'break-all',
                                        }}
                                        href={
                                            state?.selectedApplicantData
                                                ?.linkedUrl
                                        }
                                    >
                                        {
                                            state?.selectedApplicantData
                                                ?.linkedUrl
                                        }
                                    </a>
                                ) : (
                                    '-'
                                )}
                            </span>
                        </div>
                    </div>

                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'flex-end',
                            flexDirection: 'column',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                gap: '10px',
                                marginBottom: '20px',
                                alignItems: 'center',
                            }}
                        >
                            {handleViewNotes && (
                                <span
                                    onClick={handleViewNotes}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <NotesRecruitIcon />
                                </span>
                            )}

                            {handleViewAndUpdateResume && (
                                <span
                                    style={{
                                        border: '1px solid #C9C9C9',
                                        borderRadius: '5px',
                                        fontWeight: 600,
                                        fontSize: '18px',
                                        padding: '9px 13px',
                                        cursor: 'pointer',
                                    }}
                                    onClick={handleViewAndUpdateResume}
                                >
                                    Resume <RightArrow />
                                </span>
                            )}

                            {handleUpdateJobApplicationStatus && (
                                <span>
                                    <Select
                                        placeholder="Select a Status"
                                        onChange={(event) => {
                                            handleUpdateJobApplicationStatus(
                                                event
                                            )
                                        }}
                                        className="recruitStatusSelect"
                                        style={{
                                            padding: '0px 0px',
                                        }}
                                        value={JobApplicationState?.status}
                                        options={JOB_APPLICATIONS_STATUS}
                                    />
                                </span>
                            )}
                        </div>

                        <div
                            style={{
                                display: 'flex',
                                gap: '10px',
                                alignItems: 'center',
                                marginBottom: '8px',
                            }}
                        >
                            <span
                                style={{ color: '#4F46E5', fontSize: '16px' }}
                            >
                                Expected
                            </span>
                            <span>
                                <span
                                    style={{
                                        fontSize: '22px',
                                        fontWeight: 700,
                                    }}
                                >
                                    $
                                    {state?.selectedApplicantData
                                        ?.expectedSalary || 0}{' '}
                                    /{' '}
                                </span>
                                <span
                                    style={{
                                        fontSize: '16px',
                                        fontWeight: 400,
                                    }}
                                >
                                    {state?.selectedApplicantData
                                        ?.expectedSalaryType || '-'}
                                </span>
                            </span>
                        </div>

                        <div style={{ fontWeight: 300, fontSize: '16px' }}>
                            <span>Available From : </span>
                            <span style={{ color: '#747474' }}>
                                {state?.selectedApplicantData?.availableFrom}
                            </span>
                        </div>
                    </div>
                </div>
            </Card>
        </>
    )
}

export default JobApplicantInfoCard
