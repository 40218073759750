// antd
import { Row, Typography, theme } from 'antd'

// assets
import { CloseModalIcon } from '../../assets/icons'

function ModelHeader({
    selectedDataProfile,
    modalTitle,
    showTitle,
    handleCancel,
    icon,
    description,
    modalKey,
}) {
    const { useToken } = theme
    const { token } = useToken()

    const { Title, Text } = Typography

    return (
        <>
            <Row
                justify={'space-between'}
                align={'middle'}
                style={{
                    padding: '25px 50px',
                    boxShadow: '0px 4px 15px 0px #0000000d',
                }}
            >
                <Row
                    align={'middle'}
                    style={{
                        columnGap: '20px',
                    }}
                >
                    <Row
                        style={{
                            height: '60px',
                            width: '60px',
                            borderRadius: '10px',
                            padding: '12px',
                            backgroundColor:
                                token.colorPalette.baseColor.tertiary,
                        }}
                        align={'middle'}
                        justify={'center'}
                    >
                        <div
                            style={{
                                alignItems: 'center',
                                justifyContent: 'center',
                                fill: 'red',
                            }}
                        >
                            {icon}
                        </div>
                    </Row>
                    <div>
                        <Title
                            className={'titleMiddle'}
                            style={{ textTransform: 'capitalize' }}
                        >
                            {modalKey === 'viewVideo'
                                ? `View ${showTitle}`
                                : selectedDataProfile || showTitle === 'profile'
                                ? `Edit ${showTitle}`
                                : `Add ${showTitle}`}
                        </Title>
                        <Text
                            className="titleSecondary"
                            style={{
                                fontSize: token.fontSizeHeading5,
                            }}
                        >
                            {description}
                        </Text>
                    </div>
                </Row>
                <div
                    onClick={() => handleCancel()}
                    style={{
                        cursor: 'pointer',
                        height: '30px',
                        width: '30px',
                    }}
                >
                    <CloseModalIcon />
                </div>
            </Row>
        </>
    )
}

export default ModelHeader
