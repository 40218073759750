import React from 'react'

function PermissionIcon({ color }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="20"
            fill="none"
            viewBox="0 0 18 20"
        >
            <path
                fill={color ? color : '#212B36'}
                d="M4 8h13a1 1 0 011 1v10a1 1 0 01-1 1H1a1 1 0 01-1-1V9a1 1 0 011-1h1V7a7 7 0 0113.262-3.131l-1.789.894A5 5 0 004 7v1zm3 5v2h4v-2H7z"
            ></path>
        </svg>
    )
}

export default PermissionIcon
