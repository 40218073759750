import axiosNew from '../../helpers/axios'

// CREATE USER
export const create = async (payload) => {
    return new Promise(async (resolve) => {
        try {
            let data = payload ?? {}
            const create = await axiosNew({
                url: 'users/create',
                data,
            })
            resolve({ data: create?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// GET USER
export const getUser = async (payload) => {
    return new Promise(async (resolve) => {
        try {
            const getUser = await axiosNew({
                url:
                    `users/${payload?.password ? 'set-password' : 'get'}/` +
                    payload?._id +
                    `${payload?.password ? `?setPassword=true` : ''}`,
                method: 'get',
            })
            resolve({ data: getUser?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// COUNT USERS
export const count = async (payload) => {
    const { search = '', filter = {} } = payload
    return new Promise(async (resolve) => {
        let newFilterValue = {
            isTalentUser: true,
        }

        Object.keys(filter).forEach((data) => {
            if (filter[data] !== '') {
                newFilterValue = { ...newFilterValue, [data]: filter[data] }
            } else {
                newFilterValue = { ...newFilterValue }
            }
        })
        try {
            let data = {
                filter: newFilterValue,
            }
            if (search?.length > 0) {
                data['search'] = {
                    name: payload?.search,
                    email: payload?.search,
                    phone: payload?.search,
                }
            }
            const count = await axiosNew({
                url: 'users/count',
                data,
            })
            resolve({ data: count?.data?.result ?? 0 })
        } catch (error) {
            resolve({ data: 0 })
        }
    })
}

// LIST USERS
export const read = (payload) => {
    const { search = '', skip = 0, limit = 0, filter = {} } = payload
    return new Promise(async (resolve) => {
        let newFilterValue = { isTalentUser: true }

        Object.keys(filter).forEach((data) => {
            if (filter[data] !== '') {
                newFilterValue = { ...newFilterValue, [data]: filter[data] }
            } else {
                newFilterValue = { ...newFilterValue }
            }
        })
        try {
            let data = {
                skip,
                filter: newFilterValue,
                limit,
                select: [
                    'name',
                    'email',
                    'employeeNumber',
                    'phone',
                    'countryCode',
                    'flag',
                    'address',
                    'employmentType',
                    'state',
                    'department',
                    'designation',
                    'emergencyName',
                    'emergencyPhone',
                    'notes',
                    'role',
                    'isActive',
                    'profilePicture',
                    'location',
                    'isOurUser',
                ],
                populate: {
                    name: [
                        {
                            path: 'role',
                            select: 'roleName',
                        },
                        {
                            path: 'designation',
                            select: 'name',
                        },
                        {
                            path: 'workspace',
                            select: 'domain logo companyName',
                        },
                    ],
                },
            }
            if (search?.length > 0) {
                data['search'] = {
                    name: search,
                    email: search,
                    phone: search,
                }
            }
            const users = await axiosNew({
                url: 'users/read',
                data,
            })
            resolve({
                data: users?.data?.result ?? [],
                isNext: users?.data?.isNext,
            })
        } catch (error) {
            resolve({ data: [] })
        }
    })
}

// UPDATE USER
export const update = async (payload) => {
    return new Promise(async (resolve) => {
        try {
            let data = payload?.updatedData ?? {}
            const update = await axiosNew({
                url: 'users/update/' + payload._id,
                data,
            })
            resolve({ data: update?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// DELETE USER
export const deleteUser = async (payload) => {
    return new Promise(async (resolve) => {
        try {
            const deleteUser = await axiosNew({
                url: 'users/delete/' + payload,
            })
            resolve({ data: deleteUser?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// LIST ROLES
export const listRoles = async () => {
    return new Promise(async (resolve) => {
        try {
            let data = { select: ['roleName'] }
            const roleList = await axiosNew({
                url: 'role/list/',
                data,
            })
            resolve({ data: roleList?.data?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// ADD JIRA API TOKEN
export const apiAddJiraApiToken = async (userId, data) => {
    return new Promise(async (resolve) => {
        try {
            const create = await axiosNew({
                url: `users/update/${userId}`,
                data: {data},
            })
            resolve({ data: create?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}
