// import { epicsList } from '../../constants/epics.index'
import axiosNew from '../../helpers/axios'
import data from '../../helpers/data.json'

// GET EPICS LIST
export const getEpicsList = (payload) => {
    return new Promise(async (resolve) => {
        try {
            const epicData = await axiosNew({
                url: `epics/list?projectId=${
                    JSON.parse(localStorage.getItem('currentProject'))?.id
                }&skip=${payload?.skip}&limit=${payload?.limit}`,

                method: 'get',
            })
            resolve({ data: epicData?.data?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// CREATE EPIC
export const createEpic = (payload) => {
    return new Promise(async (resolve) => {
        try {
            let data = payload ?? {}
            const createEpicData = await axiosNew({
                url: 'epics/create',
                data,
            })
            resolve({ data: createEpicData?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// DELETE EPIC
export const deleteEpic = async (payload) => {
    return new Promise(async (resolve) => {
        try {
            const deleteEpic = await axiosNew({
                url: 'epics/delete/' + payload,
                method: 'delete',
            })
            resolve({ data: deleteEpic?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// UPDATE EPIC
export const updateEpic = async (payload) => {
    return new Promise(async (resolve) => {
        try {
            let data = payload?.epicEdit ?? {}
            const updatedEpic = await axiosNew({
                url: 'epics/edit/' + payload?.id,
                method: 'put',
                data,
            })
            resolve({ data: updatedEpic?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// GET SPRINTS LIST
export const getSprintsList = (search) => {
    return new Promise((resolve) => {
        let sprints = data.sprints ?? []
        if (search?.length > 0) {
            sprints = sprints.filter((x) =>
                x.name.toLowerCase().includes(search.toLowerCase())
            )
        }
        setTimeout(() => resolve({ data: sprints }), 300)
    })
}

// GET SPRINT
export const getSprintById = (payload) => {
    return new Promise(async (resolve) => {
        try {
            const sprintData = await axiosNew({
                url: '/sprints/get/' + payload.id,
                method: 'get',
            })
            resolve({ data: sprintData?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// GET SPRINTS COUNT
export const getSprintsCount = (search) => {
    let sprints = data.sprints ?? []
    if (search?.length > 0) {
        sprints = sprints.filter((x) =>
            x.name.toLowerCase().includes(search.toLowerCase())
        )
    }
    return new Promise((resolve) => {
        setTimeout(() => resolve({ data: sprints.length }), 200)
    })
}

// GET SPRINTS LIST
export const read = (payload) => {
    const { search = '' } = payload
    return new Promise(async (resolve) => {
        try {
            let data = {
                filter: {
                    populate: {
                        name: 'projectId',
                        val: 'name',
                    },
                    projectId: JSON.parse(
                        localStorage.getItem('currentProject')
                    )?.id,
                },
            }
            if (search?.length > 0) {
                data['search'] = {
                    name: search,
                }
            }
            const projects = await axiosNew({
                url: 'sprints/list',
                data,
            })
            resolve({ data: projects?.data?.data ?? [] })
        } catch (error) {
            resolve({ data: [] })
        }
    })
}

// CREATE SPRINT
export const create = async (payload) => {
    return new Promise(async (resolve) => {
        try {
            let data = payload ?? {}
            const create = await axiosNew({
                url: 'sprints/add',
                data,
            })
            resolve({ data: create?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// UPDATE SPRINT
export const update = async (payload) => {
    return new Promise(async (resolve) => {
        try {
            let data = payload?.editSprint ?? {}
            const update = await axiosNew({
                url: 'sprints/update/' + payload._id,
                data,
                method: 'put',
            })
            resolve({ data: update?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// DELETE SPRINT
export const deleteSprint = async (payload) => {
    return new Promise(async (resolve) => {
        let data = payload?.move ?? {}
        try {
            const deleteSprint = await axiosNew({
                url: 'sprints/delete/' + payload?.id,
                method: 'delete',
                data,
            })
            resolve({ data: deleteSprint?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// GET TASKS BY EPIC
// export const listTaskSprint = (payload) => {
//     const { skip = 0, limit = 0, filter } = payload
//     return new Promise(async (resolve) => {
//         try {
//             let data = {
//                 skip,
//                 limit,
//                 filter,
//                 listType: 'epic',
//                 // filter: {
//                 //     sprintId: JSON.parse(localStorage.getItem('currentSprint'))
//                 //         ?.id,
//                 // },
//             }
//             const tasks = await axiosNew({
//                 url: 'tasks/list',
//                 data,
//             })
//             resolve({ data: tasks?.data?.data ?? [] })
//         } catch (error) {
//             resolve({ data: [] })
//         }
//     })
// }

// GET TASKS BY EPIC
export const listTaskSprintEpicWise = (payload) => {
    const { skip = 0, limit = 0, projectId, sprintId } = payload
    return new Promise(async (resolve) => {
        try {
            const tasks = await axiosNew({
                url: `epics/list-with-task?projectId=${projectId}&skip=${skip}&limit=${limit}&sprintId=${sprintId}`,
                method: 'get',
            })
            resolve({ data: tasks?.data?.data ?? [] })
        } catch (error) {
            resolve({ data: [] })
        }
    })
}

// DOWNLOAD SPRINTS DATA
export const downloadSprintsData = async (id) => {
    return new Promise(async (resolve) => {
        try {
            const downloadData = await axiosNew({
                url: 'sprints/export-sprints-data/' + id,
                method: 'get',
                responseType: 'blob',
            })
            resolve({ data: downloadData?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}
