import { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'

// antd
import { Col, Divider, Input, Row, Select, Space, Typography } from 'antd'
import { CloseOutlined, LockOutlined } from '@ant-design/icons'

// components
import Button from '../../components/button/button'

// slices
import { swimLanesUpdate, taskTypeUpdate } from '../projects/projects.slice'

// import styles from './generalSettings.module.css'

const TaskSettings = ({
    swimLanes: swimLane,
    taskTypes,
    activeProjectDetails,
}) => {
    const { Title /*Text*/ } = Typography

    const { Option } = Select

    const dispatch = useDispatch()

    const [items, setItems] = useState([])
    const [swimLanes, setSwimLanes] = useState([])

    // GET SWIMLANES
    useEffect(() => {
        if (swimLane?.length > 0) {
            const options = swimLane.map((data) => {
                return {
                    label: data?.name,
                    value: data?._id,
                    position: data?.position,
                }
            })
            setSwimLanes(options)
        }
    }, [swimLane])

    // GET TASK TYPES
    useEffect(() => {
        if (taskTypes?.length > 0) {
            const options = taskTypes.map((data) => {
                return {
                    label: data,
                    value: data,
                }
            })
            setItems(options)
        }
    }, [taskTypes])

    const [name, setName] = useState('')
    const [swimLaneName, setSwimLaneName] = useState('')
    const inputRef = useRef(null)
    const inputRefSwimLane = useRef(null)

    const onNameChange = (event) => {
        setName(event.target.value)
    }

    // ADD TASK TYPE
    const addType = async (e) => {
        const result = await dispatch(
            taskTypeUpdate({
                id: JSON.parse(localStorage.getItem('currentProject'))?.id,
                project: {
                    fieldName: 'add',
                    taskType: name,
                },
            })
        )
        const data = result?.payload?.data
        if (data) {
            const { success } = data
            if (success) {
                setName('')
                activeProjectDetails()
            } else {
                setName('')
            }
        }

        setTimeout(() => {
            inputRef.current?.focus()
        }, 0)
        // e.preventDefault()
    }

    // ADD SWIMLANES
    const addSwimLanes = async (e) => {
        const result = await dispatch(
            swimLanesUpdate({
                id: JSON.parse(localStorage.getItem('currentProject'))?.id,
                project: {
                    fieldName: 'add',
                    swimlanes: [
                        {
                            name: swimLaneName,
                            position: swimLane?.length,
                        },
                    ],
                },
            })
        )
        const data = result?.payload?.data
        if (data) {
            const { success } = data
            if (success) {
                setSwimLaneName('')
                activeProjectDetails()
            } else {
                setSwimLaneName('')
            }
        }

        setTimeout(() => {
            inputRef.current?.focus()
        }, 0)
        // e.preventDefault()
    }

    // REMOVE SWIMlANE
    const removeSwimLane = async (e, value, index) => {
        const result = await dispatch(
            swimLanesUpdate({
                id: JSON.parse(localStorage.getItem('currentProject'))?.id,
                project: {
                    fieldName: 'remove',
                    swimlanes: [
                        {
                            name: value,
                            position: index,
                        },
                    ],
                },
            })
        )
        const data = result?.payload?.data
        if (data) {
            const { success } = data
            if (success) {
                activeProjectDetails()
            } else {
            }
        }
        setTimeout(() => {
            inputRef.current?.focus()
        }, 0)
    }

    // REMOVE TASK TYPE
    const removeType = async (e, value) => {
        const result = await dispatch(
            taskTypeUpdate({
                id: JSON.parse(localStorage.getItem('currentProject'))?.id,
                project: {
                    fieldName: 'remove',
                    taskType: value,
                },
            })
        )
        const data = result?.payload?.data
        if (data) {
            const { success } = data
            if (success) {
                activeProjectDetails()
            } else {
            }
        }
        setTimeout(() => {
            inputRef.current?.focus()
        }, 0)
    }

    function handleChange() {}

    return (
        <div
            style={{
                marginTop: 10,
            }}
        >
            <Row gutter={{ xs: 8, sm: 8 }}>
                <Col span={12}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={16} xxl={16}>
                        <Title level={5}>Add Task Type</Title>
                        <Select
                            style={{
                                width: '100%',
                                marginTop: '6px',
                            }}
                            value={['Add Task Type']}
                            onChange={handleChange}
                            placeholder="Add Task Type"
                            allowClear={false}
                            dropdownRender={(menu) => (
                                <>
                                    <div>{menu}</div>

                                    <Divider
                                        style={{
                                            margin: '8px 0',
                                        }}
                                    />
                                    <Space
                                        style={{
                                            padding: '0 8px 4px',
                                        }}
                                    >
                                        <Input
                                            placeholder="Enter Type"
                                            ref={inputRef}
                                            value={name}
                                            onChange={onNameChange}
                                        />
                                        <Button
                                            props={{
                                                text: 'Add Type',
                                                disabled: name?.length === 0,
                                                onClick: () => addType(),
                                                style: {
                                                    height: '32px',
                                                    padding: '0px 16px',
                                                },
                                            }}
                                        >
                                            Add item
                                        </Button>
                                    </Space>
                                </>
                            )}
                        >
                            {items.map((item) => (
                                <Option key={item?.label}>
                                    <Row
                                        align={'middle'}
                                        justify={'space-between'}
                                    >
                                        {item?.value}
                                        {item?.label === 'Task' ||
                                        item?.label === 'Bug' ? (
                                            <LockOutlined
                                                style={{
                                                    fontSize: '14px',
                                                }}
                                            />
                                        ) : (
                                            <CloseOutlined
                                                style={{
                                                    fontSize: '14px',
                                                }}
                                                onClick={(e) =>
                                                    removeType(e, item?.label)
                                                }
                                            />
                                        )}
                                    </Row>
                                </Option>
                            ))}
                        </Select>
                    </Col>
                </Col>
                <Col span={12}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={16} xxl={16}>
                        <Title level={5}>Add SwimLanes</Title>
                        <Select
                            style={{
                                width: '100%',
                                marginTop: '6px',
                            }}
                            value={['Add SwimLanes']}
                            onChange={handleChange}
                            placeholder="Add SwimLanes"
                            allowClear={false}
                            dropdownRender={(menu) => (
                                <>
                                    <div>{menu}</div>

                                    <Divider
                                        style={{
                                            margin: '8px 0',
                                        }}
                                    />
                                    <Space
                                        style={{
                                            padding: '0 8px 4px',
                                        }}
                                    >
                                        <Input
                                            placeholder="Enter SwimLanes"
                                            ref={inputRefSwimLane}
                                            value={swimLaneName}
                                            onChange={(e) =>
                                                setSwimLaneName(e.target.value)
                                            }
                                        />
                                        <Button
                                            props={{
                                                text: 'Add SwimLanes',
                                                style: {
                                                    height: '32px',
                                                    padding: '0px 16px',
                                                },
                                                disabled:
                                                    swimLaneName?.length === 0,
                                                onClick: () => addSwimLanes(),
                                            }}
                                        >
                                            Add item
                                        </Button>
                                    </Space>
                                </>
                            )}
                        >
                            {swimLanes.map((item) => (
                                <Option key={item?.label}>
                                    <Row
                                        align={'middle'}
                                        justify={'space-between'}
                                    >
                                        {item?.label}
                                        {item?.label === 'To Do' ||
                                        item?.label === 'Done' ? (
                                            <LockOutlined
                                                style={{
                                                    fontSize: '14px',
                                                }}
                                            />
                                        ) : (
                                            <CloseOutlined
                                                style={{
                                                    fontSize: '14px',
                                                }}
                                                onClick={(e) =>
                                                    removeSwimLane(
                                                        e,
                                                        item?.label,
                                                        item?.position
                                                    )
                                                }
                                            />
                                        )}
                                    </Row>
                                </Option>
                            ))}
                        </Select>
                    </Col>
                </Col>
            </Row>
        </div>
    )
}

export default TaskSettings
