import React from 'react'

function NextButton() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="9"
            height="18"
            fill="none"
            viewBox="0 0 9 18"
        >
            <path
                stroke="#252525"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="1.5"
                d="M.91 16.918l6.52-6.52c.77-.77.77-2.03 0-2.8L.91 1.078"
            ></path>
        </svg>
    )
}

export default NextButton
