import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// antd
import { Col, Form, InputNumber, Row, message } from 'antd'

// slices
import {
    changeRoleStep,
    roleInquiryCreate,
    roleStepData,
    rolesInquiryState,
    setCreateRoleId,
} from '../rolesInquiry.slice'
import { inquiryState } from '../../../inquiries.slice'

// components
import Button from '../../../../../components/button/button'

const BudgetForm = ({ setStep }) => {
    const [form] = Form.useForm()

    const dispatch = useDispatch()

    const { stepRoleData } = useSelector(rolesInquiryState)
    const { selectedInquiry } = useSelector(inquiryState)

    const [loadingButton, setLoadingButton] = useState(false)

    // ON FINISH BUDGET
    const onFinishBudget = async (value) => {
        setLoadingButton(true)
        const result = await dispatch(
            roleInquiryCreate({
                addData: {
                    ...stepRoleData,
                    ...value,
                    start: stepRoleData?.start,
                    inquiry: selectedInquiry?._id,
                },
                workspaceId: selectedInquiry?.client?.workspaceId,
            })
        )
        const data = result?.payload?.data
        if (data) {
            const { success, message: checkMessage } = data
            if (success) {
                dispatch(roleStepData(undefined))
                dispatch(setCreateRoleId(data?.data?._id))
                message.success(checkMessage)
                setLoadingButton(false)
                dispatch(changeRoleStep(3))
                setStep()
            } else {
                setLoadingButton(false)
                if (checkMessage) {
                    message.error(checkMessage)
                } else {
                    message.error('Something went wrong')
                }
            }
        }
    }

    // ON FINISH FAILED
    const onFinishFailed = () => {}

    const handleFormatter = (value) => {
        // Remove any non-digit characters (including decimal points)
        return String(value).replace(/[^\d]/g, '')
    }

    return (
        <>
            <Form
                labelCol={{
                    style: {
                        padding: '0 0 3px',
                    },
                }}
                layout="vertical"
                name="addProjectForm"
                onFinish={onFinishBudget}
                onFinishFailed={onFinishFailed}
                requiredMark={false}
                form={form}
                style={{
                    overflowX: 'hidden',
                }}
            >
                <Row
                    gutter={30}
                    style={{
                        rowGap: '15px',
                    }}
                >
                    <Col span={12}>
                        <Form.Item
                            label="From"
                            name="startBudget"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Enter Start Budget',
                                },
                                {
                                    validator: async (value) => {
                                        if (
                                            form.getFieldValue('startBudget') >
                                            form.getFieldValue('endBudget')
                                        ) {
                                            return Promise.reject(
                                                new Error(
                                                    'Start Budget should not be greater than end budget'
                                                )
                                            )
                                        }
                                    },
                                },
                            ]}
                            colon={false}
                        >
                            <InputNumber
                                formatter={handleFormatter}
                                placeholder={'Enter Start Budget'}
                                name={'startBudget'}
                                min={1}
                                max={100000}
                                style={{
                                    minWidth: '100%',
                                    width: '100%',
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="To"
                            name="endBudget"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Enter End Budget',
                                },
                                {
                                    validator: async (value) => {
                                        if (
                                            form.getFieldValue('endBudget') <
                                            form.getFieldValue('startBudget')
                                        ) {
                                            return Promise.reject(
                                                new Error(
                                                    'End budget should not be less than start budget'
                                                )
                                            )
                                        }
                                    },
                                },
                            ]}
                            colon={false}
                        >
                            <InputNumber
                                placeholder={'Enter end Budget'}
                                name={'endBudget'}
                                formatter={handleFormatter}
                                min={1}
                                max={100000}
                                style={{
                                    minWidth: '100%',
                                    width: '100%',
                                }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row
                    gutter={12}
                    style={{
                        float: 'right',
                        marginTop: '35px',
                    }}
                >
                    <Col span={24}>
                        <Button
                            props={{
                                htmlType: 'submit',
                                loadingButton: loadingButton,
                                text: 'Continue',
                                style: {
                                    padding: '8px 27px',
                                },
                            }}
                        />
                    </Col>
                </Row>
            </Form>
        </>
    )
}

export default BudgetForm
