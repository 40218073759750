import { useSelector } from 'react-redux'

// slices
import { loginState } from '../../modules/login/login.slice'

// helpers
import { checkNavigation } from '../../helpers/checkNavigationPermission'

// constants
import { DOMAIN_LIST, SUPER_ADMIN } from '../../constants/roles'

function MemberRoutesNavigation(selected) {
    const { status, userProfile, moduleContainers } = useSelector(loginState)
    return status === 'loaded' &&
        moduleContainers?.length > 0 &&
        userProfile?.role?.roleName !== undefined &&
        ((userProfile?.role?.roleName === SUPER_ADMIN &&
            !DOMAIN_LIST.includes(userProfile?.currentWorkSpace?.domain)) ||
            userProfile?.role?.roleName !== SUPER_ADMIN)
        ? checkNavigation(userProfile, selected)
        : []
}

export default MemberRoutesNavigation
