// antd
import { Image, Modal, Spin, Typography, theme } from 'antd'

// helpers
import {
    downloadFileGet,
    splitLastOccurrence,
} from '../../helpers/fileDownload'

// assets
import { DownloadFileIcon } from '../../assets/icons'

// constants
import { fileTypes, pdfTypes, videoTypes } from '../../constants/mediaTypes'

// styles
import styles from '../profile/profile.module.css'

function ViewFileModalContent({ props = {} }) {
    const { visibility, onCancel, selectedData, status } = props

    const { Text, Title } = Typography

    const { useToken } = theme

    const { token } = useToken()

    return (
        <>
            <Modal
                footer={null}
                closable={false}
                open={visibility}
                width={700}
                centered
                title={null}
                destroyOnClose={true}
                onCancel={() => {
                    onCancel()
                }}
                styles={{
                    body: {
                        padding: '0px',
                    },
                }}
            >
                {status === 'loading' || selectedData === undefined ? (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Spin size="large" />
                    </div>
                ) : (
                    selectedData !== undefined && (
                        <>
                            <div
                                style={{
                                    padding: '12px 20px',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}
                            >
                                <div>
                                    <Title level={5}>
                                        {splitLastOccurrence(
                                            selectedData?.name.split(
                                                '.com/'
                                            )[1],
                                            '_'
                                        )}
                                    </Title>
                                    <Text
                                        style={{
                                            color: token.colorPalette.textColor
                                                .secondary,
                                        }}
                                    >
                                        by {selectedData?.createdBy},
                                    </Text>
                                </div>
                                <div
                                    onClick={() => {
                                        downloadFileGet(
                                            selectedData?.name,
                                            selectedData?.displayName
                                        )
                                    }}
                                    style={{
                                        cursor: 'pointer',
                                    }}
                                >
                                    <DownloadFileIcon />
                                </div>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: '100%',
                                }}
                            >
                                {fileTypes.some((r) =>
                                    selectedData?.name
                                        ?.toLowerCase()
                                        .includes(r)
                                ) ? (
                                    <>
                                        <Image
                                            src={selectedData?.name}
                                            height={'100%'}
                                            width={'100%'}
                                            preview={false}
                                        />
                                    </>
                                ) : videoTypes.some((r) =>
                                      selectedData?.name.includes(r)
                                  ) ? (
                                    <video
                                        width="100%"
                                        height="387px"
                                        autoPlay
                                        controls
                                        className={styles.videoViewContainer}
                                    >
                                        <source
                                            src={selectedData?.name}
                                            type="video/mp4"
                                        />
                                    </video>
                                ) : pdfTypes.some((r) =>
                                      selectedData?.name.includes(r)
                                  ) ? (
                                    <>
                                        <div>
                                            <iframe
                                                src={`${selectedData?.name}#view=fitH`}
                                                height={'387px'}
                                                width="100%"
                                                title="PDF"
                                            />
                                        </div>
                                    </>
                                ) : (
                                    <></>
                                )}
                            </div>
                        </>
                    )
                )}
            </Modal>
        </>
    )
}

export default ViewFileModalContent
