import React from 'react'

function InActiveIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 22 22"
        >
            <path
                fill="#FF3838"
                d="M18.443 3.555L3.107 18.891a2.5 2.5 0 01-.064-.605V5.37a3.546 3.546 0 013.538-3.538h8.837c1.283 0 2.41.688 3.025 1.723z"
            ></path>
            <path
                fill="#FF3838"
                d="M19.956 2.042a.708.708 0 00-1 0L2.045 18.964a.708.708 0 00.228 1.15.697.697 0 00.762-.16L19.956 3.032a.681.681 0 000-.99zm-2.273 5.024l-6.775 6.775a.91.91 0 00.175 1.43l6.774 4.06a.913.913 0 001.384-.788V7.708c.009-.816-.981-1.22-1.558-.642z"
            ></path>
        </svg>
    )
}

export default InActiveIcon
