import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'

// antd
import { Badge, Card, Image, Popover, Row, Typography, theme } from 'antd'

// slices
import { isLoggedInLogin, loginState } from '../login/login.slice'

// assets
import { ByPassIcon, EllipsisIcon } from '../../assets/icons'
import { DOMAIN_LIST, SUPER_ADMIN } from '../../constants/roles'

const WorkSpaceList = ({ props }) => {
    const { workSpace, dropDownIcon, handleDropdownChange, open } = props

    const { useToken } = theme

    const { token } = useToken()

    const { Title, Text } = Typography

    const dispatch = useDispatch()

    const { userProfile } = useSelector(loginState)

    // WORKSPACE LOGIN
    const workSpaceLogin = async (workSpace) => {
        if (window.location.hostname === 'localhost') {
            const response = await axios({
                url: `http://localhost:5000/backend/workspace/login?workSpaceId=${workSpace?._id}&userId=${userProfile?._id}`,
                method: 'get',
            })
            if (response?.data?.success) {
                dispatch(isLoggedInLogin())
            }
        } else {
            window.open(
                `https://${workSpace?.domain}/workspace/login?workSpaceId=${workSpace?._id}&userId=${userProfile?._id}`,
                '_blank'
            )
        }
    }

    // DROPDOWN CONTENT
    const dropDownContent = () => {
        return (
            <>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        rowGap: '8px',
                    }}
                >
                    {dropDownIcon?.length > 0 &&
                        dropDownIcon.map((data) => (
                            <Row
                                style={{
                                    cursor: 'pointer',
                                }}
                                onClick={() => data?.onClick(workSpace?._id)}
                            >
                                <div
                                    style={{
                                        marginRight: '15px',
                                    }}
                                >
                                    {data?.icon}
                                </div>
                                <Text className="sub-title">{data?.title}</Text>
                            </Row>
                        ))}
                </div>
            </>
        )
    }

    return (
        <Card
            style={{
                cursor: 'pointer',
                borderRadius: '16px',
                borderColor: 'hsla(210, 13%, 62%, 0.24)',
            }}
            styles={{
                body: {
                    padding: '16px 20px',
                    maxHeight: '150px',
                },
            }}
        >
            <Row align={'middle'} justify={'space-between'}>
                <Image
                    src={workSpace?.logo}
                    height={46}
                    width={46}
                    preview={false}
                    style={{
                        borderRadius: '50%',
                        objectFit: 'cover',
                        boxShadow: '0px 4px 5px 5px rgba(0, 0, 0, 0.02)',
                    }}
                />
                {((DOMAIN_LIST.includes(
                    userProfile?.currentWorkSpace?.domain
                ) &&
                    userProfile?.role?.roleName === SUPER_ADMIN) ||
                    !DOMAIN_LIST.includes(
                        userProfile?.currentWorkSpace?.domain
                    )) && (
                    <Row
                        align={'middle'}
                        style={{
                            columnGap: '16px',
                        }}
                    >
                        <Badge
                            count={workSpace?.isActive ? 'Active' : 'InActive'}
                            color={
                                workSpace?.isActive
                                    ? 'hsla(155, 54%, 46%, 0.16)'
                                    : 'hsla(11, 100%, 59%, 0.16)'
                            }
                            style={{
                                minWidth: '30px',
                                cursor: 'auto',
                                borderRadius: '6px',
                                display: 'flex',
                                alignItems: 'center',
                                color: workSpace?.isActive
                                    ? token.colorPalette.iconColor.tertiary
                                    : token.colorPalette.iconColor.quaternary,
                                padding: '12px 8px',
                                fontWeight: 700,
                            }}
                        />
                        <Popover
                            content={dropDownContent}
                            trigger="click"
                            placement="left"
                            open={workSpace?._id === open?.id && open?.open}
                            overlayInnerStyle={{
                                padding: '18px',
                            }}
                            arrow={false}
                            onOpenChange={handleDropdownChange}
                        >
                            <div
                                style={{
                                    padding: '0px 0px 0px 5px',
                                }}
                            >
                                <EllipsisIcon />
                            </div>
                        </Popover>
                    </Row>
                )}
            </Row>
            <div
                style={{
                    marginTop: '16px',
                }}
            >
                <Title level={4}>
                    {workSpace?.companyName?.length > 20
                        ? workSpace?.companyName.slice(0, 20).concat('...')
                        : workSpace?.companyName}
                </Title>
                <Row align={'middle'} justify={'space-between'}>
                    <Text
                        style={{
                            color: token.colorPalette.textColor.senary,
                            fontSize: '16px',
                        }}
                    >
                        {workSpace?.domain?.length > 25
                            ? workSpace?.domain.slice(0, 25).concat('...')
                            : workSpace?.domain}
                    </Text>
                    <div
                        onClick={() => {
                            workSpaceLogin(workSpace)
                        }}
                    >
                        <ByPassIcon />
                    </div>
                </Row>
            </div>
        </Card>
    )
}

export default WorkSpaceList
