import { Button, Input, message, Modal } from 'antd'
import React, { useState } from 'react'
import { apiAddJiraApiToken } from '../../modules/users/users.service'

const AddJiraApiTokenModal = ({ open, setOpen, userProfile }) => {
    const [token, setToken] = useState(null)
    const [loading, setLoading] = useState(false)

    const handleSubmit = async () => {
        try {
            const payload = {
                jiraApiToken: token,
            }

            setLoading(true)

            const res = await apiAddJiraApiToken( userProfile?._id, payload)
            if (res?.data?.success) {
                message.success('JIRA API Token Added Successfully')
                setOpen(false)
                setToken(null)
                setLoading(false)
            } else {
                message.error('Something went wrong')
                setOpen(false)
                setToken(null)
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)
            setOpen(false)
            setToken(null)
            message.error(error.message || 'Something went wrong')
        }
    }

    return (
        <>
            <Modal
                footer={null}
                open={open}
                closable
                onCancel={() => {
                    setOpen(false)
                    setToken(null)
                    setLoading(false)
                }}
            >
                <p>JIRA API Token</p>
                <Input.TextArea
                    placeholder="Add JIRA API Token"
                    value={token}
                    onChange={(event) => setToken(event?.target?.value)}
                />

                <div
                    style={{
                        marginTop: '10px',
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}
                    onClick={handleSubmit}
                >
                    <Button loading={loading} disabled={loading}>
                        Submit
                    </Button>
                </div>
            </Modal>
        </>
    )
}

export default AddJiraApiTokenModal
