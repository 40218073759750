import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
    changeStatusNotification,
    getMarkAsRead,
    getNotifications,
} from './notifications.service'

const initialState = {
    status: 'loaded',
    notificationsData: [],
    isRead: false,
}

export const notificationsGet = createAsyncThunk(
    'notificationsSlice/getScreenShots',
    async (payload) => {
        const response = await getNotifications(payload)
        return response
    }
)

export const statusChangeNotification = createAsyncThunk(
    'notificationsSlice/changeStatusNotification',
    async (payload) => {
        const response = await changeStatusNotification(payload)
        return response
    }
)

export const markAsRead = createAsyncThunk(
    'notificationsSlice/getMarkAsRead',
    async (payload) => {
        const response = await getMarkAsRead(payload)
        return response
    }
)

export const notificationsSlice = createSlice({
    name: 'notificationsSlice',
    initialState,
    reducers: {
        setReadNotification(state) {
            state.isRead = state.isRead ? false : true
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(notificationsGet.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(notificationsGet.fulfilled, (state, action) => {
                state.status = 'loaded'
                state.notificationsData = action.payload.data
            })
    },
})

export default notificationsSlice.reducer

export const notificationsState = (state) => {
    const { notificationsSlice } = state
    const { status, notificationsData, isRead } = notificationsSlice
    return { status, notificationsData, isRead }
}

export const { setReadNotification } = notificationsSlice.actions
