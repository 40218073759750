import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// antd
import { Tabs, theme } from 'antd'

// slices
import {
    changePage,
    clearFilter,
    filterChange,
    searchTalent,
    switchTalentModal,
    talentListGet,
    talentState,
    totalTalent,
} from '../talent/talent.slice'
import { changeRecruitTab, recruitState } from './recruit.slice'

// components
import PanelHeader from '../../components/panelHeader/panelHeader'
import ModalComponent from '../../components/modal/modalComponent'

// constants
import { RECRUIT_STATUS } from '../../constants/recruit'
import { RECRUIT_FILTER_STATUS } from '../../constants/general'

// modal content
import TalentModalContent from '../talent/talentModalContent'

// assets
import { RecruitIcon } from '../../assets/icons'

// helpers
import { unique } from '../../helpers/uniqueArray'

// talent pages
import RecruitList from './recruitList'

function Recruit() {
    const { useToken } = theme
    const { token } = useToken()

    const dispatch = useDispatch()

    const {
        modalVariations,
        pageLimit,
        isNext,
        selectedData,
        talentList,
        filter,
        search,
    } = useSelector(talentState)
    const { defaultTab } = useSelector(recruitState)

    const initialState = {
        talentView: 'list',
        filteredTalentData: [],
        assignModal: false,
        unAssignModal: false,
        addRatesModal: false,
    }

    // STATE
    const [state, setState] = useState(initialState)

    // CHECK FOR LAZY LOADING IS_NEXT
    useEffect(() => {
        if (talentList?.length > 0) {
            let array = []
            talentList?.length > 0 &&
                talentList.forEach((data) => {
                    array = [...array, data]
                })
            let newArray = [...state?.filteredTalentData, ...array]
            let finalArray = unique(newArray, '_id')
            setState((prevState) => ({
                ...prevState,
                filteredTalentData: finalArray,
            }))
            if (!isNext) {
                return
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [talentList])

    // REFRESH PAGE
    function refreshPage() {
        setState((prevState) => ({
            ...prevState,
            filteredTalentData: [],
        }))
        dispatch(
            changePage({
                currentPage: 1,
                skip: 0,
                pageLimit,
            })
        )
    }

    const initialRecruitCall = (filterStatus, search) => {
        setState((prevState) => ({
            ...prevState,
            filteredTalentData: [],
        }))
        dispatch(
            changePage({
                currentPage: 1,
                skip: 0,
                pageLimit: 10,
            })
        )
        dispatch(
            totalTalent({
                search: search,
                filter: { ...filter, status: filterStatus },
            })
        )
        dispatch(
            talentListGet({
                limit: 10,
                skip: 0,
                search: search,
                filter: { ...filter, status: filterStatus },
            })
        )
    }

    // GET INITIAL TALENT LIST
    useEffect(() => {
        dispatch(changeRecruitTab(RECRUIT_FILTER_STATUS))
        initialRecruitCall(RECRUIT_FILTER_STATUS, '')
        dispatch(searchTalent(''))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <div
                className={'container'}
                style={{
                    zIndex: 10,
                    minHeight: '100vh',
                    padding: '35px',
                }}
            >
                <>
                    <PanelHeader
                        props={{
                            panelName: 'add Candidate',
                            panelHeaderName: 'Talent',
                            searchBar: true,
                            filterBar: false,
                            teamMembersBar: false,
                            permissionAdd: true,
                            gridView: false,
                            count: totalTalent,
                            action: talentListGet,
                            search: searchTalent,
                            changeFilter: filterChange,
                            clearFilter: clearFilter,
                            pageLimit: pageLimit,
                            filterValue: filter,
                            changePageGrid: () => {
                                dispatch(
                                    changePage({
                                        currentPage: 1,
                                        skip: 0,
                                        pageLimit: 10,
                                    })
                                )
                            },
                            clearData: () =>
                                setState((prevState) => ({
                                    ...prevState,
                                    filteredTalentData: [],
                                })),
                            refreshPage: refreshPage,
                            onClick: () => {
                                dispatch(
                                    switchTalentModal({
                                        ...modalVariations,
                                        open: true,
                                    })
                                )
                            },
                        }}
                    />
                    <Tabs
                        items={RECRUIT_STATUS.filter(
                            (data) => data?.value !== 'accepted'
                        ).map((data) => {
                            return {
                                ...data,
                                children: <RecruitList />,
                            }
                        })}
                        activeKey={defaultTab}
                        onChange={(activeKey) => {
                            dispatch(filterChange({ status: activeKey }))
                            dispatch(changeRecruitTab(activeKey))
                            initialRecruitCall(activeKey, search)
                        }}
                    />
                    {/* <RecruitList /> */}
                </>
            </div>
            <ModalComponent
                visibility={modalVariations?.open}
                content={
                    <TalentModalContent
                        refreshTalent={() => {
                            setState((prevState) => ({
                                ...prevState,
                                filteredTalentData: [],
                            }))
                            dispatch(
                                changePage({
                                    currentPage: 1,
                                    skip: 0,
                                    pageLimit,
                                })
                            )
                        }}
                        page="recruit"
                    />
                }
                modalTitle={'Candidate'}
                showTitle={'Candidate'}
                icon={
                    <RecruitIcon color={token.colorPalette.baseColor.white} />
                }
                buttonName="Save"
                masterDataState={modalVariations}
                selectedDataMaster={selectedData}
                handleCancel={() => {
                    dispatch(
                        switchTalentModal({
                            ...modalVariations,
                            open: false,
                        })
                    )
                }}
            />
        </>
    )
}

export default Recruit
