import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import S3FileUpload from 'react-s3'
import { debounce } from 'lodash'

import styles from './editProfile.module.css'

// antd
import {
    Avatar,
    Card,
    Col,
    Empty,
    Form,
    Image,
    Input,
    message,
    Row,
    Select,
    theme,
    Typography,
    Upload,
    Divider,
    Space,
    Spin,
} from 'antd'
import { PlusOutlined } from '@ant-design/icons'

// slices
import {
    addSummary,
    changeModel,
    checkButtonType,
    masterDataAddDropDown,
    masterDataDropDownList,
    profileLoading,
    profileState,
    switchProfileModal,
    updateUserGet,
} from '../profile.slice'
import {
    emailCheck,
    loginState,
    numberCheck,
    sendOtpLogin,
} from '../../login/login.slice'

// modal
import VerifyOtpProfileModal from './verifyOtpProfileModal'

// assets
import { UploadImageIcon } from '../../../assets/icons'

// constants
import { PHONE_COUNTRIES } from '../../../constants/phone-countries'
import { EMAIL_PATTERN } from '../../../constants/patterns'

import dayjs from 'dayjs'
import { validateSpaces } from '../../../helpers/validateSpace'
import { MASTER_DATA_FIELD } from '../../../constants/masterData'

function EditProfileModal({ modalType }) {
    const { Text } = Typography

    const { Option } = Select

    const { useToken } = theme
    const { token } = useToken()

    const dispatch = useDispatch()

    // SELECTORS
    const {
        formButtonType,
        masterDataDropDown,
        profileData,
        masterDataDropDownStatus,
        modelType,
    } = useSelector(profileState)
    const { userProfile } = useSelector(loginState)

    const [form] = Form.useForm()

    const timeoutRef = useRef()
    const sessionTokenRef = useRef()

    const [otpModal, setOtpModal] = useState({ visibility: false, data: {} })

    // STATES
    const [phoneNumber, setPhoneNumber] = useState({
        phoneNo: null,
        code: null,
    })
    const [phoneValid, setPhoneValid] = useState(false)
    const [suggestions, setSuggestions] = useState([])
    const [fileURL, setFileURL] = useState([])
    const [searchValue, setSearchValue] = useState('')
    const [stream, setStream] = useState(false)

    useEffect(() => {
        if (profileData?.data) {
            setPhoneNumber({
                phoneNo: profileData?.data?.phone,
                code: profileData?.data?.countryCode,
            })
            setPhoneValid(true)
            if (profileData?.data?.profilePicture?.length > 0) {
                setFileURL([profileData?.data?.profilePicture])
            }
            form.setFieldsValue({
                ...profileData?.data,
                designation: [
                    {
                        label: profileData?.data?.designation?.name,
                        value: profileData?.data?.designation?._id,
                    },
                ],
                phone: `${profileData?.data?.countryCode}${profileData?.data?.phone}`,
            })
        }
    }, [form, profileData])

    // CHECK EMAIL ID
    async function checkEmailId(value) {
        const result = await dispatch(
            emailCheck({
                email: value,
            })
        )

        const data = result?.payload?.data
        if (data) {
            const { success, isDuplicate } = data
            if (success) {
                if (!isDuplicate) {
                    return true
                }
            } else if (!success) {
                if (isDuplicate) {
                    form.setFieldValue('email', '')
                    message.info('Email Id should be unique')
                    return false
                } else {
                    message.error(data?.message)
                    return false
                }
            }
        }
    }

    // CHECK MOBILE NUMBER
    async function checkMobileNumber() {
        const result = await dispatch(
            numberCheck({
                countryCode: phoneNumber?.code,
                phone: phoneNumber?.phoneNo,
            })
        )

        const data = result?.payload?.data
        if (data) {
            const { success, isDuplicate } = data
            if (success) {
                if (!isDuplicate) {
                    return true
                }
            } else if (!success) {
                if (isDuplicate) {
                    setPhoneValid(false)
                    form.setFieldValue('phone', '+91')
                    setPhoneNumber({
                        ...phoneNumber,
                        phoneNo: null,
                        code: null,
                    })
                    message.info('Phone Number should be unique')
                    return false
                } else {
                    message.error(data?.message)
                    return false
                }
            }
        }
    }

    // ADD MASTER DATA
    async function addMasterData() {
        const result = await dispatch(
            masterDataAddDropDown({
                addData: {
                    type: 'manual',
                    data: {
                        name: searchValue,
                        logo: '',
                        createdBy: userProfile?._id,
                    },
                },
                model: modelType,
            })
        )
        const data = result?.payload?.data

        if (data) {
            const { success, message: errorMessage } = data
            if (success) {
                dispatch(
                    masterDataDropDownList({
                        search: searchValue,
                        id: modelType,
                        // limit: pageLimit,
                        // skip: skipPage,
                    })
                )
            } else {
                if (errorMessage) {
                    message.error(errorMessage)
                } else {
                    message.error('Something went wrong, try again later.')
                }
            }
        }
    }

    // SEARCH MASTER DATA
    const searchFn = debounce((value, model) => {
        if (value?.length > 0) {
            dispatch(changeModel(model))
            dispatch(
                masterDataDropDownList({
                    search: value,
                    id: model,
                })
            )
        }
        setSearchValue(value)
    }, 800)

    // ONCHANGE FILE PROPS
    const props = {
        name: 'profilePicture',
        multiple: false,
        onChange(info) {
            const isLt5M = info?.file.size / 1024 / 1024 < 5
            if (!isLt5M) {
                message.error('Profile picture must be smaller than 5MB')
                return
            } else {
                setStream(true)
                const nameChange = info?.fileList[0]?.name
                    .split('.')[0]
                    .concat(`_${dayjs(new Date()).unix()}`)
                    .concat(`.${info?.fileList[0]?.name.split('.')[1]}`)

                const newFileData = new File(
                    [info?.fileList[0]?.originFileObj],
                    nameChange,
                    { type: info?.fileList[0]?.type }
                )
                window.Buffer = window.Buffer || require('buffer').Buffer

                S3FileUpload.uploadFile(newFileData, userProfile?.s3Config)
                    .then(async (data) => {
                        // fileUpload.push(data?.location)
                        setFileURL([data?.location])
                        setStream(false)
                    })
                    .catch(() => {
                        message.error('Upload Failed!. Please Upload again')
                        setStream(false)
                    })
            }
            // info?.preventDefault()
        },

        beforeUpload() {
            return false
        },

        onDrop(e) {},
    }

    function onFinishFailed() {}

    // CHANGE PHONE NUMBER
    const handleChangePhoneNumber = (number, country) => {
        const phoneNo = number.replace(country.dialCode, '')
        setPhoneNumber({
            default: number,
            phoneNo,
            code: `+${country.dialCode}`,
            flag: country.countryCode.toUpperCase(),
        })
    }

    // GOOGLE PLACE API INTEGRATION
    const loadSuggestions = async (inputValue) => {
        clearTimeout(timeoutRef.current)

        if (!inputValue || inputValue.trim().length <= 1) {
            setSuggestions([])
            return
        }

        timeoutRef.current = setTimeout(async () => {
            if (!sessionTokenRef.current) {
                sessionTokenRef.current =
                    new window.google.maps.places.AutocompleteSessionToken()
            }

            new window.google.maps.places.AutocompleteService().getPlacePredictions(
                {
                    input: inputValue,
                    sessionToken: sessionTokenRef.current,
                },
                (predictions, status) => {
                    if (
                        status ===
                        window.google.maps.places.PlacesServiceStatus
                            .ZERO_RESULTS
                    ) {
                        setSuggestions([])
                        return
                    }
                    if (
                        status !==
                            window.google.maps.places.PlacesServiceStatus.OK ||
                        !predictions
                    ) {
                        return
                    }
                    let newData =
                        predictions?.length > 0 &&
                        predictions.map((data) => {
                            return {
                                label: data?.description,
                                value: data?.description,
                            }
                        })
                    setSuggestions(newData)
                }
            )
        }, 350)
    }

    // CONDITION WISE ADD SUMMARY

    const summaryEmailCheck = async (value) => {
        const payload = {
            ...value,
            designation:
                typeof value.designation === 'object'
                    ? value.designation[0]?.value
                    : value.designation,
            profilePicture: fileURL?.length > 0 ? fileURL[0] : '',
        }

        delete payload.phone

        const result = await dispatch(
            addSummary({
                _id: userProfile?._id,
                summary: {
                    data: {
                        ...payload,
                    },
                },
            })
        )
        const data = result?.payload?.data

        if (data) {
            const { success, message: errorMessage } = data
            if (success) {
                dispatch(
                    profileLoading({
                        name: modalType,
                        buttonName: formButtonType,
                        loading: false,
                    })
                )
                message.success(errorMessage)
                dispatch(updateUserGet({ _id: userProfile?._id }))
                dispatch(
                    switchProfileModal({
                        name: 'profile',
                        open: false,
                    })
                )
            } else {
                dispatch(
                    profileLoading({
                        name: modalType,
                        buttonName: formButtonType,
                        loading: false,
                    })
                )
                dispatch(
                    switchProfileModal({
                        name: 'profile',
                        open: false,
                    })
                )
                if (errorMessage) {
                    message.error(errorMessage)
                } else {
                    message.error('Something went wrong, try again later.')
                }
            }
        }
    }

    // FINISH PROFILE EDIT
    async function finishProfileEdit(value) {
        dispatch(
            profileLoading({
                name: modalType,
                buttonName: formButtonType,
                loading: true,
            })
        )
        if (
            profileData?.data?.phone !== phoneNumber?.phoneNo ||
            profileData?.data?.countryCode !== phoneNumber?.code
        ) {
            let checkMobile = await checkMobileNumber()

            if (checkMobile) {
                const result = await dispatch(
                    sendOtpLogin({
                        countryCode: phoneNumber?.code,
                        phone: phoneNumber?.phoneNo,
                    })
                )

                const data = result?.payload?.data
                if (data) {
                    const { success, message: checkMessage } = data
                    if (success) {
                        setOtpModal({
                            visibility: true,
                            data: {
                                ...value,
                                designation:
                                    typeof value.designation === 'object'
                                        ? value.designation[0]?.value
                                        : value.designation,
                                phone: phoneNumber?.phoneNo,
                                countryCode: phoneNumber?.code,
                                profilePicture:
                                    fileURL?.length > 0 ? fileURL[0] : '',
                            },
                        })
                        dispatch(
                            profileLoading({
                                name: modalType,
                                buttonName: formButtonType,
                                loading: false,
                            })
                        )

                        message.success(checkMessage)
                    } else {
                        dispatch(
                            profileLoading({
                                name: modalType,
                                buttonName: formButtonType,
                                loading: false,
                            })
                        )

                        if (typeof checkMessage === 'string') {
                            message.error(checkMessage)
                        } else if (typeof checkMessage === 'object') {
                            for (const key in checkMessage) {
                                message.error(checkMessage[key])
                            }
                        } else {
                            message.error(
                                'Something went wrong, try again later.'
                            )
                        }
                    }
                }
            } else {
                dispatch(
                    profileLoading({
                        name: modalType,
                        buttonName: formButtonType,
                        loading: false,
                    })
                )
            }

            // SEND OTP
        } else {
            if (profileData?.data?.email !== value?.email) {
                let checkEmail = await checkEmailId(value?.email)
                if (checkEmail) {
                    summaryEmailCheck(value)
                } else {
                    dispatch(
                        profileLoading({
                            name: modalType,
                            buttonName: formButtonType,
                            loading: false,
                        })
                    )
                }
            } else {
                summaryEmailCheck(value)
            }
        }
    }

    return (
        <>
            <Form
                labelCol={{
                    style: {
                        padding: '0 0 3px',
                    },
                }}
                layout="vertical"
                name="addProfile"
                onFinish={finishProfileEdit}
                onFinishFailed={onFinishFailed}
                onSubmitCapture={async (e) => {
                    await dispatch(checkButtonType(e.nativeEvent.submitter.id))
                }}
                form={form}
                requiredMark={false}
                style={{
                    overflowX: 'hidden',
                }}
            >
                <Row gutter={30}>
                    <Col span={9}>
                        <Card
                            style={{
                                borderRadius: '16px',
                                height: '100%',
                            }}
                            styles={{
                                body: {
                                    padding: '30px 44px',
                                },
                            }}
                        >
                            <Form.Item
                                name="profilePicture"
                                rootClassName="imageUpload"
                                rules={[
                                    {
                                        validator: async () => {
                                            if (fileURL?.length === 0) {
                                                return Promise.reject(
                                                    new Error(
                                                        'Please Upload your Profile Picture'
                                                    )
                                                )
                                            } else {
                                                return
                                            }
                                        },
                                    },
                                ]}
                            >
                                <Upload
                                    listType="picture-circle"
                                    className="avatar-uploader"
                                    {...props}
                                    fileList={[]}
                                    disabled={stream}
                                    showUploadList={false}
                                    onPreview={() => {}}
                                    accept=".png,.jpg,.jpeg"
                                    style={{
                                        height: '128px !important',
                                        width: '128px !important',
                                    }}
                                >
                                    {fileURL?.length > 0 ? (
                                        <Image
                                            src={fileURL[0]}
                                            preview={false}
                                            style={{
                                                height: '128px',
                                                width: '128px',
                                                borderRadius: '50%',
                                            }}
                                        />
                                    ) : (
                                        <>
                                            <Avatar
                                                style={{
                                                    color: token.colorPalette
                                                        .baseColor.white,
                                                }}
                                                className={'uploadAvatar'}
                                                size="large"
                                            >
                                                {`${profileData?.data?.name[0].toUpperCase()}
                                                ${
                                                    profileData?.data?.name.split(
                                                        ' '
                                                    )[1] !== undefined &&
                                                    profileData?.data?.name
                                                        .split(' ')[1][0]
                                                        .toUpperCase()
                                                }`}
                                            </Avatar>
                                        </>
                                    )}

                                    {stream ? (
                                        <div
                                            className={styles.loaderEditProfile}
                                        ></div>
                                    ) : (
                                        <div className={'hoverLayer'}>
                                            <div className={'hoverShow'}>
                                                <UploadImageIcon />
                                                <div
                                                    style={{
                                                        marginTop: 8,
                                                        color: token
                                                            .colorPalette
                                                            .baseColor.white,
                                                    }}
                                                >
                                                    Update Picture
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </Upload>
                                <Row
                                    style={{
                                        marginTop: '10px',
                                    }}
                                >
                                    <Text
                                        className="titleSecondary"
                                        style={{
                                            fontSize: token.fontSizeIcon,
                                            textAlign: 'center',
                                        }}
                                    >
                                        Allowed *.jpeg, *.jpg, *.png, Max size
                                        of 5 MB
                                    </Text>
                                </Row>
                            </Form.Item>
                        </Card>
                    </Col>
                    <Col span={15}>
                        <Row
                            gutter={20}
                            style={{
                                rowGap: '15px',
                            }}
                        >
                            <Col span={12}>
                                <Form.Item
                                    name={'name'}
                                    label="Name*"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please Enter Name',
                                        },
                                        {
                                            max: 25,
                                            message:
                                                'Name should be maximum 25 characters long',
                                        },
                                        { validator: validateSpaces },
                                    ]}
                                >
                                    <Input placeholder="Enter Name" />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    label="Designation*"
                                    name="designation"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Please Select Designation',
                                        },
                                    ]}
                                    colon={false}
                                >
                                    <Select
                                        placeholder="Search a Designation"
                                        showSearch
                                        onSearch={(value) => {
                                            searchFn(
                                                value,
                                                MASTER_DATA_FIELD.designation
                                            )
                                        }}
                                        filterOption={false}
                                        allowClear={false}
                                        dropdownRender={(menu) => (
                                            <>
                                                {menu}
                                                <Divider
                                                    style={{
                                                        margin: '8px 0',
                                                    }}
                                                />
                                                <Space
                                                    style={{
                                                        padding: '0 8px 4px',
                                                    }}
                                                >
                                                    <Row
                                                        align={'middle'}
                                                        style={{
                                                            columnGap: '5px',
                                                            cursor:
                                                                searchValue?.length >
                                                                0
                                                                    ? 'pointer'
                                                                    : 'not-allowed',
                                                        }}
                                                        onClick={() => {
                                                            if (
                                                                searchValue?.length >
                                                                0
                                                            ) {
                                                                addMasterData()
                                                            }
                                                        }}
                                                    >
                                                        <PlusOutlined
                                                            style={{
                                                                color: token
                                                                    .colorPalette
                                                                    .baseColor
                                                                    .tertiary,
                                                                fontSize:
                                                                    token.fontSizeHeading5,
                                                            }}
                                                        />
                                                        <Text
                                                            style={{
                                                                color: token
                                                                    .colorPalette
                                                                    .baseColor
                                                                    .tertiary,
                                                                fontSize:
                                                                    token.fontSizeHeading5,
                                                                fontWeight:
                                                                    token.fontWeightStrong,
                                                            }}
                                                        >
                                                            Add
                                                        </Text>
                                                    </Row>
                                                </Space>
                                            </>
                                        )}
                                    >
                                        {masterDataDropDown?.designation
                                            ?.length > 0 &&
                                        masterDataDropDownStatus === 'loaded'
                                            ? masterDataDropDown?.designation.map(
                                                  (option) => {
                                                      return (
                                                          <Option
                                                              key={
                                                                  option?.value
                                                              }
                                                              value={
                                                                  option?.value
                                                              }
                                                          >
                                                              <Text>
                                                                  {
                                                                      option?.label
                                                                  }
                                                              </Text>
                                                          </Option>
                                                      )
                                                  }
                                              )
                                            : masterDataDropDownStatus ===
                                                  'loading' && <Spin></Spin>}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name={'email'}
                                    label="Email ID*"
                                    rules={
                                        formButtonType !==
                                            'draftButtonLoading' && [
                                            {
                                                validator: async (_, value) => {
                                                    const pattern =
                                                        EMAIL_PATTERN

                                                    if (
                                                        value?.length === 0 ||
                                                        value === undefined
                                                    ) {
                                                        return Promise.reject(
                                                            new Error(
                                                                'Please Enter Email'
                                                            )
                                                        )
                                                    }
                                                    if (
                                                        !pattern.test(value) &&
                                                        value?.length > 0
                                                    ) {
                                                        return Promise.reject(
                                                            new Error(
                                                                'Please Enter a valid Email Id'
                                                            )
                                                        )
                                                    } else {
                                                    }
                                                },
                                            },
                                        ]
                                    }
                                >
                                    <Input placeholder="Enter Email ID" />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    label="Mobile Number*"
                                    name="phone"
                                    rules={[
                                        {
                                            validator: async () => {
                                                if (
                                                    !phoneValid &&
                                                    phoneNumber?.phoneNo
                                                        ?.length > 0
                                                ) {
                                                    return Promise.reject(
                                                        new Error(
                                                            'Please Enter a valid Phone Number'
                                                        )
                                                    )
                                                }

                                                if (
                                                    phoneNumber?.phoneNo
                                                        ?.length === 0 ||
                                                    phoneNumber?.phoneNo ===
                                                        null
                                                ) {
                                                    return Promise.reject(
                                                        new Error(
                                                            'Please Enter Phone Number'
                                                        )
                                                    )
                                                }
                                            },
                                        },
                                    ]}
                                    colon={false}
                                >
                                    <PhoneInput
                                        inputProps={{
                                            name: 'phone',
                                            required: true,
                                        }}
                                        specialLabel=""
                                        inputStyle={{
                                            width: '100%',
                                        }}
                                        country={'in'}
                                        onlyCountries={['in', 'au', 'us', 'gb']}
                                        onChange={(number, country) => {
                                            handleChangePhoneNumber(
                                                number,
                                                country
                                            )
                                            const correspondingCountry =
                                                PHONE_COUNTRIES.find(
                                                    (countryName) =>
                                                        countryName.iso2 ===
                                                        country.countryCode?.toUpperCase()
                                                )
                                            let numberNew = `+${number}`
                                            if (
                                                correspondingCountry &&
                                                number &&
                                                correspondingCountry?.validation.test(
                                                    numberNew
                                                )
                                            ) {
                                                setPhoneValid(true)
                                            } else {
                                                setPhoneValid(false)
                                            }
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label="Location*"
                                    name="location"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please Select Location',
                                        },
                                    ]}
                                    colon={false}
                                >
                                    <Select
                                        placeholder="Search a Location"
                                        allowClear={false}
                                        notFoundContent={
                                            <Empty
                                                image={
                                                    Empty.PRESENTED_IMAGE_SIMPLE
                                                }
                                                description="No Locations Searched"
                                            ></Empty>
                                        }
                                        onSearch={(value) => {
                                            loadSuggestions(value)
                                        }}
                                        showSearch
                                        options={suggestions}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
            <VerifyOtpProfileModal
                visibility={otpModal.visibility}
                phone={phoneNumber?.phoneNo}
                countryCode={phoneNumber?.code}
                onCancelModal={() => {
                    setOtpModal({ visibility: false, data: otpModal?.data })
                }}
                modalType={modalType}
                payloadData={otpModal.data}
            />
        </>
    )
}

export default EditProfileModal
