import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { getSchedulesList, getSchedulesCount } from './schedules.service'

const initialState = {
    search: '',
    status: 'loaded',
    schedules: [],
    schedulesCount: 0,
    formModal: false,
    viewModal: false,
    selectedSchedule: undefined,
    selectedFormat: 'year',
    slots: [],
    slotWidth: undefined,
}

export const schedulesList = createAsyncThunk(
    'schedulesSlice/getSchedulesList',
    async (payload) => {
        const { search, skip } = payload
        const response = await getSchedulesList(search, skip)
        return response
    }
)

export const totalSchedules = createAsyncThunk(
    'schedulesSlice/getSchedulesCount',
    async (payload) => {
        const response = await getSchedulesCount(payload)
        return response
    }
)

export const schedulesSlice = createSlice({
    name: 'schedulesSlice',
    initialState,
    reducers: {
        switchFormModal: (state, action) => {
            if (action.payload) state.selectedSchedule = undefined
            state.viewModal = false
            state.formModal = state.formModal ? false : true
        },
        switchViewModal: (state, action) => {
            state.selectedSchedule = action.payload ? action.payload : undefined
            state.viewModal = state.viewModal ? false : true
        },
        switchFormat: (state, action) => {
            state.selectedFormat = action.payload
        },
        setSlots: (state, action) => {
            state.slots = action.payload
        },
        setSlotWidth: (state, action) => {
            state.slotWidth = action.payload
        },
        searchSchedules: (state, action) => {
            state.search = action.payload
        },
        clearSchedules: (state) => {
            state.schedules = []
            state.search = ''
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(schedulesList.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(schedulesList.fulfilled, (state, action) => {
                state.status = 'loaded'
                if (action.payload.skip > 0) {
                    state.schedules = [
                        ...state.schedules,
                        ...action.payload.data,
                    ]
                } else {
                    state.schedules = action.payload.data
                }
            })
            .addCase(totalSchedules.fulfilled, (state, action) => {
                state.schedulesCount = action.payload.data
            })
    },
})

export default schedulesSlice.reducer

export const schedulesState = (state) => {
    const { schedulesSlice } = state
    const {
        search,
        status,
        schedules,
        schedulesCount,
        formModal,
        viewModal,
        selectedSchedule,
        selectedFormat,
        slots,
        slotWidth,
    } = schedulesSlice
    return {
        search,
        status,
        schedules,
        schedulesCount,
        formModal,
        viewModal,
        selectedSchedule,
        selectedFormat,
        slots,
        slotWidth,
    }
}

export const {
    switchFormModal,
    switchViewModal,
    switchFormat,
    setSlots,
    setSlotWidth,
    searchSchedules,
    clearSchedules,
} = schedulesSlice.actions
