import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
    count,
    read,
    update,
    listRoles,
    addRemoveTeamMember,
} from './team.service'

const initialState = {
    search: '',
    status: 'loaded',
    formButtonType: 'saveButtonLoading',
    selectedData: undefined,
    modalVariations: {
        open: false,
        formName: 'talentDataForm',
        saveButtonLoading: false,
    },
    teamList: [],
    dataCount: 0,
    isNext: false,
    pageLimit: 10,
    currentPage: 1,
    skipPage: 0,
    filter: {
        isOurUser: '',
        isActive: '',
    },
}

export const updateTeam = createAsyncThunk(
    'teamSlice/update',
    async (payload = {}) => {
        const response = await update(payload)
        return response
    }
)

export const totalTeam = createAsyncThunk(
    'teamSlice/count',
    async (payload = {}) => {
        const response = await count(payload)
        return response
    }
)

export const teamListGet = createAsyncThunk(
    'teamSlice/read',
    async (payload = {}) => {
        const response = await read(payload)
        return response
    }
)

export const roleList = createAsyncThunk(
    'teamSlice/listRoles',
    async (payload = {}) => {
        const response = await listRoles(payload)
        return response
    }
)

export const teamMemberAddRemove = createAsyncThunk(
    'teamSlice/addRemoveTeamMember',
    async (payload = {}) => {
        const response = await addRemoveTeamMember(payload)
        return response
    }
)

export const teamSlice = createSlice({
    name: 'teamSlice',
    initialState,
    reducers: {
        switchTeamModal: (state, action) => {
            state.modalVariations = action.payload
            state.selectedData = undefined
        },
        checkButtonType: (state, action) => {
            state.formButtonType = action.payload
        },
        changePage: (state, action) => {
            state.currentPage = action.payload.currentPage
            state.skipPage = action.payload.skip
            state.pageLimit = action.payload.pageLimit
        },
        searchTeam: (state, action) => {
            state.search = action.payload
        },
        clearUsers: (state) => {
            state.teamList = []
            state.search = ''
        },
        filterChange: (state, action) => {
            const dropDownValue = {
                ...state.filter,
                ...action.payload,
            }
            Object.keys(dropDownValue).forEach((key) => {
                if (dropDownValue[key] === '') {
                    delete dropDownValue[key]
                }
            })
            state.filter = dropDownValue
        },
        clearFilter: (state) => {
            state.filter = {}
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(teamListGet.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(teamListGet.fulfilled, (state, action) => {
                state.status = 'loaded'
                state.teamList = action.payload.data
                state.isNext = action.payload.isNext
            })
            .addCase(totalTeam.fulfilled, (state, action) => {
                state.dataCount = action.payload.data
            })
    },
})

export default teamSlice.reducer

export const teamState = (state) => {
    const { teamSlice } = state
    const {
        search,
        status,
        formButtonType,
        selectedData,
        modalVariations,
        teamList,
        dataCount,
        isNext,
        pageLimit,
        currentPage,
        skipPage,
        filter,
    } = teamSlice
    return {
        search,
        status,
        formButtonType,
        selectedData,
        modalVariations,
        teamList,
        dataCount,
        isNext,
        pageLimit,
        currentPage,
        skipPage,
        filter,
    }
}

export const {
    checkButtonType,
    changePage,
    switchTeamModal,
    searchTeam,
    clearFilter,
    clearUsers,
    filterChange,
} = teamSlice.actions
