import { useSelector } from 'react-redux'

// slices
import { loginState } from '../login/login.slice'

// page FOF
import FoF from '../404/404.index'

function PermissionRoutes({ keyPath, children }) {
    const { userProfile } = useSelector(loginState)

    if (
        userProfile?.role?.containers.some(
            (data) => data?.container === keyPath
        )
    ) {
        return children
    } else {
        return <FoF />
    }
}

export default PermissionRoutes
