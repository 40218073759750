import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import InfiniteScroll from 'react-infinite-scroll-component'

// antd
import { Col, Row, Spin, Typography, theme } from 'antd'

// slices
import {
    changePage,
    searchData,
    switchPermissionModal,
    switchWorkSpaceModal,
    workSpaceDataListCount,
    workSpaceItemGet,
    workSpaceListGet,
    workspaceState,
} from './workSpace.slice'

// components
import PanelHeader from '../../components/panelHeader/panelHeader'
import ModalComponent from '../../components/modal/modalComponent'

// modal content
import WorkSpaceModalContent from './workSpaceModalContent'
import PermissionModalContent from './permissionModalContent'

// assets
import {
    EditWorkSpaceIcon,
    NoWorkspace,
    PermissionIcon,
    WorkSpaceIcon,
} from '../../assets/icons'

// helpers
import { unique } from '../../helpers/uniqueArray'

// constants
import { DOMAIN_LIST, SUPER_ADMIN } from '../../constants/roles'

// workspace component
import WorkSpaceList from './workspaceList'
import { loginState } from '../login/login.slice'
import NoWorkSpaceFind from './noWorkSpaceFind'

function WorkSpace() {
    const { useToken } = theme
    const { token } = useToken()

    const { Text } = Typography

    const dispatch = useDispatch()
    const { userProfile } = useSelector(loginState)

    const {
        modalVariations,
        pageLimit,
        skipPage,
        isNext,
        search,
        selectedData,
        currentPage,
        permissionModalVariations,
        workSpaceDataList,
        status,
        dataCount,
        filter,
    } = useSelector(workspaceState)

    const [filteredWorkSpaceData, setFilteredWorkSpaceData] = useState([])
    const [newWorkSpaceDataList, setNewWorkSpaceDataList] = useState([])
    const [open, setOpen] = useState({ open: false, id: '' })

    // GET TOTAL WORKSPACE COUNT AND WORKSPACE LIST
    useEffect(() => {
        dispatch(
            workSpaceDataListCount({
                search: search,
            })
        )
        dispatch(
            workSpaceListGet({
                limit: pageLimit,
                skip: skipPage,
                search: search,
            })
        )
        dispatch(
            changePage({
                currentPage: 1,
                skip: 0,
                pageLimit,
            })
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (userProfile?.role?.roleName !== SUPER_ADMIN) {
            setNewWorkSpaceDataList(userProfile?.workspace)

            let array = []
            userProfile?.workspace?.length > 0 &&
                userProfile?.workspace.forEach((data) => {
                    if (!DOMAIN_LIST.includes(data?.domain)) {
                        array = [...array, data]
                    }
                })
            let newArray = [...filteredWorkSpaceData, ...array]
            let finalArray = unique(newArray, '_id')
            setFilteredWorkSpaceData(finalArray)
        }
    }, [userProfile])

    useEffect(() => {
        if (userProfile?.role?.roleName === SUPER_ADMIN) {
            setNewWorkSpaceDataList(workSpaceDataList)

            if (workSpaceDataList?.length > 0) {
                let array = []
                workSpaceDataList?.length > 0 &&
                    workSpaceDataList.forEach((data) => {
                        if (!DOMAIN_LIST.includes(data?.domain)) {
                            array = [...array, data]
                        }
                    })
                console.log('array', array)
                let newArray = [...filteredWorkSpaceData, ...array]
                let finalArray = unique(newArray, '_id')
                setFilteredWorkSpaceData(finalArray)
                if (!isNext) {
                    return
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [workSpaceDataList])

    // // CHECK FOR LAZY LOADING IS_NEXT
    // useEffect(() => {
    //     if (workSpaceDataList?.length > 0) {
    //         let array = []
    //         workSpaceDataList?.length > 0 &&
    //             workSpaceDataList.forEach((data) => {
    //                 if (!DOMAIN_LIST.includes(data?.domain)) {
    //                     array = [...array, data]
    //                 }
    //             })
    //         let newArray = [...filteredWorkSpaceData, ...array]
    //         let finalArray = unique(newArray, '_id')
    //         setFilteredWorkSpaceData(finalArray)
    //         if (!isNext) {
    //             return
    //         }
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [workSpaceDataList])

    // FETCH MORE WORKSPACE
    async function fetchMoreWorkSpace() {
        if (isNext) {
            await dispatch(
                workSpaceListGet({
                    skip: skipPage + pageLimit,
                    limit: pageLimit,
                })
            )
            dispatch(
                changePage({
                    currentPage: currentPage + 1,
                    skip: skipPage + pageLimit,
                    pageLimit: pageLimit,
                })
            )
        }
    }

    // DROPDOWN ICON LIST
    const dropDownIcon = [
        {
            title: 'Edit',
            icon: <EditWorkSpaceIcon />,
            onClick: (id) => {
                dispatch(
                    workSpaceItemGet({
                        _id: id,
                    })
                )
                dispatch(
                    switchWorkSpaceModal({
                        ...modalVariations,
                        open: true,
                    })
                )
                setOpen({ open: false, id: '' })
            },
        },
        {
            title: 'Permission',
            icon: <PermissionIcon />,
            onClick: (id) => {
                dispatch(
                    workSpaceItemGet({
                        _id: id,
                    })
                )
                dispatch(
                    switchPermissionModal({
                        ...permissionModalVariations,
                        open: true,
                    })
                )
                setOpen({ open: false, id: '' })
            },
        },
    ]

    return (
        <>
            <Spin spinning={status === 'loading' ? true : false}>
                <div
                    className={'container'}
                    style={{
                        zIndex: 10,
                        minHeight: '100vh',
                        padding: '35px',
                    }}
                >
                    {/* <div
                        style={{
                            display: 'flex',
                            minHeight: 'inherit',
                            justifyContent: 'center',
                        }}
                    >
                        <NoWorkSpaceFind />
                    </div> */}
                    <>
                        {((DOMAIN_LIST.includes(
                            userProfile?.currentWorkSpace?.domain
                        ) &&
                            userProfile?.role?.roleName === SUPER_ADMIN) ||
                            !DOMAIN_LIST.includes(
                                userProfile?.currentWorkSpace?.domain
                            )) && (
                            <PanelHeader
                                props={{
                                    panelName: 'add workspace',
                                    search: searchData,
                                    count: workSpaceDataListCount,
                                    action: workSpaceListGet,
                                    filterView: filter,
                                    pageLimit: pageLimit,
                                    skipPage: skipPage,
                                    searchBar: true,
                                    filterBar: false,
                                    teamMembersBar: false,
                                    permissionAdd: true,
                                    searchValue: search,
                                    refreshPage: async () => {
                                        setFilteredWorkSpaceData([])
                                        dispatch(
                                            changePage({
                                                currentPage: 1,
                                                skip: 0,
                                                pageLimit,
                                            })
                                        )
                                    },
                                    onClick: () => {
                                        dispatch(
                                            switchWorkSpaceModal({
                                                ...modalVariations,
                                                open: true,
                                            })
                                        )
                                    },
                                }}
                            />
                        )}
                        {newWorkSpaceDataList?.length <= 1 ? (
                            <div
                                className={'sub-container'}
                                style={{ display: 'flex' }}
                            >
                                {status !== 'loading' && (
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            width: '100%',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            marginTop: '120px',
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: '400px',
                                                }}
                                            >
                                                <NoWorkspace />
                                            </div>
                                        </div>
                                        <Text
                                            className="titleSecondary"
                                            style={{
                                                marginTop: '20px',
                                            }}
                                        >
                                            No Workspace Found.
                                        </Text>
                                    </div>
                                )}
                            </div>
                        ) : (
                            <InfiniteScroll
                                dataLength={
                                    filteredWorkSpaceData?.length > 0
                                        ? filteredWorkSpaceData?.length
                                        : 0
                                }
                                next={fetchMoreWorkSpace}
                                hasMore={isNext}
                                style={{
                                    overflow: 'inherit',
                                }}
                            >
                                <Row gutter={[36, 36]}>
                                    {filteredWorkSpaceData?.length > 0 &&
                                        filteredWorkSpaceData.map(
                                            (workSpace) => (
                                                <Col
                                                    key={workSpace?._id}
                                                    md={24}
                                                    lg={12}
                                                    xl={8}
                                                    xxl={6}
                                                >
                                                    <WorkSpaceList
                                                        props={{
                                                            workSpace:
                                                                workSpace,
                                                            dropDownIcon:
                                                                dropDownIcon,
                                                            handleDropdownChange:
                                                                (newOpen) =>
                                                                    setOpen({
                                                                        open: newOpen,
                                                                        id: workSpace?._id,
                                                                    }),
                                                            open: open,
                                                        }}
                                                    />
                                                </Col>
                                            )
                                        )}
                                </Row>
                            </InfiniteScroll>
                        )}
                    </>
                </div>
                <Row justify={'start'}>
                    <p
                        style={{
                            color: '#788191',
                            marginTop: 20,
                        }}
                    >
                        Listed {filteredWorkSpaceData.length} of {dataCount}
                    </p>
                </Row>
            </Spin>
            <ModalComponent
                visibility={modalVariations?.open}
                content={
                    <WorkSpaceModalContent
                        refreshWorkSpace={() => {
                            setFilteredWorkSpaceData([])
                            dispatch(
                                changePage({
                                    currentPage: 1,
                                    skip: 0,
                                    pageLimit,
                                })
                            )
                        }}
                    />
                }
                modalTitle={'Workspace'}
                showTitle={'Workspace'}
                icon={
                    <WorkSpaceIcon color={token.colorPalette.baseColor.white} />
                }
                buttonName="Save"
                masterDataState={modalVariations}
                selectedDataMaster={selectedData}
                selectedDataProfile={selectedData}
                handleCancel={() => {
                    dispatch(
                        switchWorkSpaceModal({
                            ...modalVariations,
                            open: false,
                        })
                    )
                }}
            />
            <ModalComponent
                visibility={permissionModalVariations?.open}
                content={<PermissionModalContent />}
                modalTitle={'Permission'}
                showTitle={'Permission'}
                icon={
                    <PermissionIcon
                        color={token.colorPalette.baseColor.white}
                    />
                }
                buttonName="Save"
                masterDataState={permissionModalVariations}
                selectedDataMaster={selectedData}
                selectedDataProfile={selectedData}
                handleCancel={() => {
                    dispatch(
                        switchPermissionModal({
                            ...permissionModalVariations,
                            open: false,
                        })
                    )
                }}
            />
        </>
    )
}

export default WorkSpace
