import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// antd
import {
    Badge,
    Card,
    Col,
    Divider,
    Image,
    Row,
    Spin,
    Typography,
    theme,
} from 'antd'

// slices
import { screenshotsGet, screenshotsState } from './screenshots.slice'
import { projectReportsState } from '../projectReports/projectReports.slice'

// assets
import {
    KeyboardIcon,
    MouseIcon,
    NoScreenshotsIcon,
} from '../../../assets/icons'

const ScreenShotsPage = () => {
    const { Title, Text } = Typography

    const { useToken } = theme

    const { token } = useToken()

    const dispatch = useDispatch()

    const { screenshotsData, status, userChangeName } =
        useSelector(screenshotsState)

    const { filter, dateRanges } = useSelector(projectReportsState)

    async function DateRangeSet() {
        if (dateRanges?.start && dateRanges?.end) {
            const dataScreen = {
                start: dateRanges?.start,
                end: dateRanges?.end,
                projectId: filter?.projectId,
                assigneeId: filter?.assigneeId,
            }
            dispatch(screenshotsGet(dataScreen))
        }
    }

    useEffect(() => {
        DateRangeSet()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateRanges, userChangeName, filter])

    const ScreenShotComponent = () => {
        let data = Object.keys(screenshotsData?.data)
        return (
            <>
                {/* <InfiniteScroll
                    dataLength={
                        state?.filteredNotesData?.length > 0
                            ? state?.filteredNotesData?.length
                            : 0
                    }
                    next={fetchMoreScreenShots}
                    hasMore={isNext}
                    style={{
                        overflow: 'inherit',
                    }}
                > */}
                {data.map((date) => {
                    let subData = Object.keys(screenshotsData?.data[date])

                    return subData.map((subTime) => (
                        <>
                            <Row
                                style={{
                                    marginTop: '18px',
                                }}
                            >
                                <Col span={3}>
                                    <Row>
                                        <Title level={4}>{subTime}</Title>
                                    </Row>
                                    <Row>
                                        <Text>{date}</Text>
                                    </Row>
                                </Col>
                                <Col span={21}>
                                    <Image.PreviewGroup
                                        preview={{
                                            onChange: () => {},
                                        }}
                                    >
                                        <Row gutter={[30, 30]}>
                                            {screenshotsData?.data[date][
                                                subTime
                                            ]?.length > 0 &&
                                                screenshotsData?.data[date][
                                                    subTime
                                                ].map((item) => (
                                                    <Col
                                                        xs={24}
                                                        sm={24}
                                                        md={12}
                                                        lg={12}
                                                        xl={8}
                                                        xxl={6}
                                                    >
                                                        <Card
                                                            hoverable
                                                            bordered={false}
                                                            styles={{
                                                                body: {
                                                                    padding: 10,
                                                                },
                                                            }}
                                                            cover={
                                                                <Image
                                                                    height={120}
                                                                    src={
                                                                        item?.url.includes(
                                                                            'https'
                                                                        )
                                                                            ? item?.url
                                                                            : 'https://pms-bucket123.s3.amazonaws.com/locationImage_1717501529.jpg'
                                                                    }
                                                                    style={{
                                                                        objectFit:
                                                                            'cover',
                                                                    }}
                                                                    preview={
                                                                        item?.url.includes(
                                                                            'https'
                                                                        )
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    onClick={() => {
                                                                        if (
                                                                            !item?.url.includes(
                                                                                'https'
                                                                            )
                                                                        ) {
                                                                            const parsedData =
                                                                                JSON.parse(
                                                                                    item?.url
                                                                                )

                                                                            // Extract the values
                                                                            const lat =
                                                                                parsedData?.lat
                                                                            const lng =
                                                                                parsedData?.long // changed 'long' to 'lng' to avoid conflict with reserved keyword

                                                                            const url = `https://www.google.com/maps?q=${lat},${lng}`
                                                                            // Open the URL in a new tab
                                                                            window.open(
                                                                                url,
                                                                                '_blank'
                                                                            )
                                                                        }
                                                                    }}
                                                                />
                                                            }
                                                        >
                                                            <Row
                                                                align={'middle'}
                                                                style={{
                                                                    columnGap:
                                                                        '8px',
                                                                }}
                                                            >
                                                                <Title
                                                                    level={5}
                                                                    style={{
                                                                        margin: 0,
                                                                    }}
                                                                >
                                                                    {new Date(
                                                                        item?.createdAt
                                                                    ).toLocaleString(
                                                                        'default',
                                                                        {
                                                                            hour: 'numeric',
                                                                            minute: '2-digit',
                                                                        }
                                                                    )}
                                                                </Title>
                                                                {!item?.url.includes(
                                                                    'https'
                                                                ) && (
                                                                    <Badge
                                                                        count={`${
                                                                            JSON.parse(
                                                                                item?.url
                                                                            )
                                                                                ?.type
                                                                        } Time`}
                                                                        color={
                                                                            JSON.parse(
                                                                                item?.url
                                                                            )
                                                                                ?.type ===
                                                                            'start'
                                                                                ? 'hsla(155, 54%, 46%, 0.16)'
                                                                                : 'hsla(11, 100%, 59%, 0.16)'
                                                                        }
                                                                        style={{
                                                                            minWidth:
                                                                                '30px',
                                                                            cursor: 'auto',
                                                                            borderRadius:
                                                                                '6px',
                                                                            display:
                                                                                'flex',
                                                                            alignItems:
                                                                                'center',
                                                                            color:
                                                                                JSON.parse(
                                                                                    item?.url
                                                                                )
                                                                                    ?.type ===
                                                                                'start'
                                                                                    ? token
                                                                                          .colorPalette
                                                                                          .iconColor
                                                                                          .tertiary
                                                                                    : token
                                                                                          .colorPalette
                                                                                          .iconColor
                                                                                          .quaternary,
                                                                            padding:
                                                                                '12px 8px',
                                                                            fontWeight: 700,
                                                                            textTransform:
                                                                                'uppercase',
                                                                        }}
                                                                    />
                                                                )}
                                                            </Row>
                                                            <Row
                                                                style={{
                                                                    width: '100%',
                                                                }}
                                                                justify={
                                                                    'space-between'
                                                                }
                                                            >
                                                                {item?.url.includes(
                                                                    'https'
                                                                ) ? (
                                                                    <Row>
                                                                        <div
                                                                            style={{
                                                                                height: '16px',
                                                                                width: '16px',
                                                                                marginRight:
                                                                                    '10px',
                                                                            }}
                                                                        >
                                                                            <KeyboardIcon />
                                                                        </div>
                                                                        <span>
                                                                            Keystrokes
                                                                            /
                                                                            min
                                                                            &nbsp;
                                                                        </span>
                                                                    </Row>
                                                                ) : (
                                                                    <span>
                                                                        Latitude
                                                                        &nbsp;
                                                                    </span>
                                                                )}

                                                                <span
                                                                    style={{
                                                                        fontWeight: 600,
                                                                    }}
                                                                >
                                                                    {item?.url.includes(
                                                                        'https'
                                                                    )
                                                                        ? item?.total_mouse_movements
                                                                            ? parseInt(
                                                                                  item?.total_keypresses
                                                                              )
                                                                            : 0
                                                                        : JSON.parse(
                                                                              item?.url
                                                                          )
                                                                              ?.lat}
                                                                </span>
                                                            </Row>
                                                            <Row
                                                                style={{
                                                                    width: '100%',
                                                                }}
                                                                justify={
                                                                    'space-between'
                                                                }
                                                            >
                                                                {item?.url.includes(
                                                                    'https'
                                                                ) ? (
                                                                    <Row>
                                                                        <div
                                                                            style={{
                                                                                height: '16px',
                                                                                width: '16px',
                                                                                marginRight:
                                                                                    '10px',
                                                                            }}
                                                                        >
                                                                            <MouseIcon />
                                                                        </div>
                                                                        <span>
                                                                            Mouse
                                                                            moments
                                                                            /
                                                                            min
                                                                            &nbsp;
                                                                        </span>
                                                                    </Row>
                                                                ) : (
                                                                    <span>
                                                                        Longitude
                                                                        &nbsp;
                                                                    </span>
                                                                )}
                                                                <span
                                                                    style={{
                                                                        fontWeight: 600,
                                                                    }}
                                                                >
                                                                    {item?.url.includes(
                                                                        'https'
                                                                    )
                                                                        ? item?.total_mouse_movements
                                                                            ? parseInt(
                                                                                  item?.total_mouse_movements
                                                                              )
                                                                            : 0
                                                                        : JSON.parse(
                                                                              item?.url
                                                                          )
                                                                              ?.long}
                                                                </span>
                                                            </Row>
                                                        </Card>
                                                    </Col>
                                                ))}
                                        </Row>
                                    </Image.PreviewGroup>
                                </Col>
                            </Row>
                            <Divider />
                        </>
                    ))
                })}
                {/* </InfiniteScroll> */}
            </>
        )
    }

    return (
        <>
            {status === 'loading' ? (
                <div
                    style={{
                        textAlign: 'center',
                    }}
                >
                    <Spin spinning={true} />
                </div>
            ) : screenshotsData?.data &&
              Object.keys(screenshotsData?.data)?.length > 0 &&
              status === 'loaded' ? (
                ScreenShotComponent()
            ) : (
                screenshotsData?.data &&
                Object.keys(screenshotsData?.data)?.length === 0 && (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginTop: '120px',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                            }}
                        >
                            <div
                                style={{
                                    width: '400px',
                                }}
                            >
                                <NoScreenshotsIcon />
                            </div>
                        </div>
                        <Text
                            className="titleSecondary"
                            style={{
                                marginTop: '20px',
                            }}
                        >
                            No Screenshots Found yet.
                        </Text>
                    </div>
                )
            )}
        </>
    )
}

export default ScreenShotsPage
