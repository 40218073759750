import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// antd
import {
    Col,
    Form,
    Modal,
    Row,
    Input,
    DatePicker,
    Spin,
    message,
    theme,
} from 'antd'

// components
import Button from '../../components/button/button'

// slices
import {
    createSprint,
    sprintsList,
    sprintsState,
    switchFormModal,
    updateSprint,
} from './sprints.slice'
import { loginState } from '../login/login.slice'

// helpers
import {
    addPermissionCheck,
    editFieldCheck,
    viewFieldCheck,
} from '../../helpers/permissionCheck'
import { isEmpty } from '../../helpers/fieldCheck'

import dayjs from 'dayjs'

const FormModalSprint = ({ setTabSet }) => {
    const { TextArea } = Input

    const { RangePicker } = DatePicker

    const { useToken } = theme

    const { token } = useToken()

    const dispatch = useDispatch()

    const { getSprintStatus, selectedSprint, sprints, modalVariations } =
        useSelector(sprintsState)
    const { userProfile } = useSelector(loginState)

    const [form] = Form.useForm()

    const initialValues = useMemo(() => {
        return {
            title: '',
            start: '',
            end: '',
            description: '',
            rangePicker: [],
            estimate: '',
            number: sprints?.length + 1,
            projectId: JSON.parse(localStorage.getItem('currentProject'))?.id,
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const [values, setValues] = useState(initialValues)
    const [updatedValues, setUpdatedValues] = useState({})
    const [errors, setErrors] = useState(initialValues)

    // ONFINISH FAILED
    const onFinishFailed = () => {}

    const formatDatePicker = (value) => {
        return `${dayjs(value).format('DD MMM YY')}`
    }

    // DISABLE START DATE
    const disabledDate = (current) => {
        // Can not select days before today and today
        return current && current < dayjs().startOf('day')
    }

    // ONFINISH
    const submit = async () => {
        dispatch(
            switchFormModal({
                ...modalVariations,
                saveButtonLoading: true,
            })
        )
        const date1 = dayjs(values.start)
        const date2 = dayjs(values.end)
        const diff = date2.diff(date1, 'day')
        const weekModulo = diff % 7
        const week = parseInt(diff / 7)
        let days = 0
        if (weekModulo !== 0) {
            days = weekModulo % 7
        }

        const weekString =
            week > 0 && days > 0
                ? `${week}w ${days}d`
                : week > 0
                ? `${week}w`
                : days > 0
                ? `${days}d`
                : ''

        const result =
            selectedSprint && modalVariations?.create === false
                ? await dispatch(
                      updateSprint({
                          editSprint: {
                              ...updatedValues,
                              estimate: weekString,
                          },
                          _id: selectedSprint?._id,
                      })
                  )
                : await dispatch(
                      createSprint({
                          ...values,
                          estimate: weekString,
                      })
                  )
        const data = result?.payload?.data
        if (data) {
            const { success, message: checkMessage } = data
            if (success) {
                message.success(checkMessage)
                setTabSet()
                dispatch(await sprintsList())
                dispatch(
                    switchFormModal({
                        ...modalVariations,
                        saveButtonLoading: false,
                        open: false,
                    })
                )
            } else {
                if (typeof checkMessage === 'object') {
                    for (const key in checkMessage) {
                        message.error(checkMessage[key])
                    }
                    dispatch(
                        switchFormModal({
                            ...modalVariations,
                            saveButtonLoading: false,
                        })
                    )
                } else if (typeof checkMessage === 'string') {
                    dispatch(
                        switchFormModal({
                            ...modalVariations,
                            saveButtonLoading: false,
                            open: false,
                        })
                    )
                    message.error(checkMessage)
                } else {
                    dispatch(
                        switchFormModal({
                            ...modalVariations,
                            saveButtonLoading: false,
                            open: false,
                        })
                    )
                    message.error('Something went wrong, try again later.')
                }
            }
            setUpdatedValues({})
        }
    }

    // SET FORM FIELDS VALUE
    useEffect(() => {
        if (selectedSprint !== undefined && modalVariations?.create === false) {
            form.setFieldsValue({
                ...selectedSprint,
                rangePicker: [
                    selectedSprint?.start
                        ? dayjs(selectedSprint?.start, 'YYYY/MM/DD')
                        : '',
                    selectedSprint?.end
                        ? dayjs(selectedSprint?.end, 'YYYY/MM/DD')
                        : '',
                ],
                start: selectedSprint?.start
                    ? dayjs(selectedSprint?.start, 'YYYY/MM/DD')
                    : '',
                end: selectedSprint?.end
                    ? dayjs(selectedSprint?.end, 'YYYY/MM/DD')
                    : '',
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form, selectedSprint])

    // ERROR COMPONENT
    const ErrorComponent = ({ name }) => {
        return (
            errors[name] && (
                <span style={{ color: 'red', margin: 2 }}>{errors[name]}</span>
            )
        )
    }

    function updateDetails(name, value) {
        setValues({
            ...values,
            [name]: value,
        })
        setErrors({ ...errors, [name]: '' })
        if (values[name] !== value) {
            setUpdatedValues({
                ...updatedValues,
                [name]: value,
            })
        }
    }

    const removeFormValue = () => {
        form.setFieldsValue(initialValues)
    }

    return (
        <Modal
            centered
            footer={null}
            closable={false}
            open={modalVariations.open}
            styles={{
                body: {
                    padding: '30px 25px',
                },
            }}
            width={700}
            afterClose={removeFormValue}
            destroyOnClose={true}
            onCancel={() =>
                dispatch(
                    switchFormModal({
                        ...modalVariations,
                        open: false,
                        create: true,
                    })
                )
            }
        >
            <Spin spinning={getSprintStatus === 'loading' ? true : false}>
                <Form
                    layout="vertical"
                    name="sprintAddForm"
                    onFinish={submit}
                    onFinishFailed={onFinishFailed}
                    requiredMark={false}
                    form={form}
                    initialValues={{
                        ...values,
                    }}
                >
                    <Row gutter={[16, 18]}>
                        {(isEmpty(selectedSprint) ||
                            viewFieldCheck(
                                'sprints',
                                'title',
                                userProfile
                            )) && (
                            <Col span={24}>
                                <Form.Item
                                    label="Sprint Title"
                                    name="title"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Please Enter Sprint Title',
                                            whitespace: true,
                                        },
                                    ]}
                                    colon={false}
                                    // normalize={(value, prevVal, prevVals) => value.trim()}
                                >
                                    <Input
                                        placeholder="Enter Sprint Title"
                                        name="title"
                                        readOnly={
                                            selectedSprint &&
                                            editFieldCheck(
                                                'sprints',
                                                'title',
                                                userProfile
                                            ) === false
                                        }
                                        onChange={(event) =>
                                            updateDetails(
                                                event.target.name,
                                                event.target.value
                                            )
                                        }
                                    />
                                </Form.Item>
                                <ErrorComponent name="title" />
                            </Col>
                        )}
                        {(isEmpty(selectedSprint) ||
                            viewFieldCheck(
                                'sprints',
                                'start',
                                userProfile
                            )) && (
                            <Col span={24}>
                                <Form.Item
                                    label="Start / Ends On"
                                    colon={false}
                                    name={'rangePicker'}
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Please Enter Sprint Start and End Date',
                                        },
                                    ]}
                                >
                                    <RangePicker
                                        picker={'date'}
                                        allowClear={false}
                                        name={'rangePicker'}
                                        format={formatDatePicker}
                                        disabledDate={disabledDate}
                                        inputReadOnly={
                                            selectedSprint &&
                                            editFieldCheck(
                                                'sprints',
                                                'start',
                                                userProfile
                                            ) === false
                                        }
                                        disabled={
                                            selectedSprint &&
                                            editFieldCheck(
                                                'sprints',
                                                'start',
                                                userProfile
                                            ) === false
                                        }
                                        style={{ width: '100%' }}
                                        onChange={(event) => {
                                            if (
                                                form.getFieldValue('start') !==
                                                    event[0] &&
                                                selectedSprint
                                            ) {
                                                setUpdatedValues({
                                                    ...updatedValues,
                                                    start: event[0],
                                                })
                                            }
                                            if (
                                                form.getFieldValue('end') !==
                                                    event[1] &&
                                                selectedSprint
                                            ) {
                                                setUpdatedValues({
                                                    ...updatedValues,
                                                    end: event[1],
                                                })
                                            }
                                            setValues({
                                                ...values,
                                                start: event[0],
                                                end: event[1],
                                            })
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                        )}
                        {(isEmpty(selectedSprint) ||
                            viewFieldCheck(
                                'sprints',
                                'description',
                                userProfile
                            )) && (
                            <Col span={24}>
                                <Form.Item
                                    label="Sprint Description"
                                    name="description"
                                    colon={false}
                                >
                                    <TextArea
                                        rows={2}
                                        placeholder={
                                            'Enter Sprint Description...'
                                        }
                                        name={'description'}
                                        readOnly={
                                            selectedSprint &&
                                            editFieldCheck(
                                                'sprints',
                                                'description',
                                                userProfile
                                            ) === false
                                        }
                                        onChange={(event) =>
                                            updateDetails(
                                                event.target.name,
                                                event.target.value
                                            )
                                        }
                                    />
                                </Form.Item>
                            </Col>
                        )}
                        <Col span={24}>
                            {addPermissionCheck('sprints', userProfile) && (
                                <Row
                                    style={{
                                        marginTop: '40px',
                                        float: 'right',
                                    }}
                                >
                                    <Button
                                        props={{
                                            text: 'Cancel',
                                            buttonType: 'link',
                                            onClick: () => {
                                                dispatch(
                                                    switchFormModal({
                                                        ...modalVariations,
                                                        open: false,
                                                        create: true,
                                                    })
                                                )
                                            },
                                            style: {
                                                color: token.colorPalette
                                                    .baseColor.black,
                                            },
                                        }}
                                    />
                                    <Button
                                        props={{
                                            htmlType: 'submit',
                                            loadingButton:
                                                modalVariations.saveButtonLoading,
                                            text:
                                                selectedSprint &&
                                                modalVariations?.create ===
                                                    false
                                                    ? 'Update Sprint'
                                                    : 'Create Sprint',
                                        }}
                                    />
                                </Row>
                            )}
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </Modal>
    )
}

export default FormModalSprint
