import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import S3FileUpload from 'react-s3'

// antd
import {
    Card,
    Col,
    Form,
    Image,
    Input,
    message,
    Row,
    Space,
    Spin,
    Switch,
    theme,
    Typography,
    Upload,
} from 'antd'

// slices
import { checkButtonType, profileState } from '../profile/profile.slice'
import { loginState } from '../login/login.slice'
import {
    masterDataAdd,
    masterDataListCount,
    masterDataListGet,
    masterDataState,
    masterDataUpdate,
    switchMasterDataModal,
} from './masterDataPanel.slice'

// assets
import { UploadImageIcon } from '../../assets/icons'

import dayjs from 'dayjs'

function MasterDataModelContent({ modalType }) {
    const { Text } = Typography

    const { useToken } = theme
    const { token } = useToken()

    const dispatch = useDispatch()

    // SELECTORS
    const { profileData } = useSelector(profileState)
    const {
        modalVariations,
        modelType,
        pageLimit,
        skipPage,
        search,
        updatedDataStatus,
        selectedData,
    } = useSelector(masterDataState)
    const { userProfile } = useSelector(loginState)

    const [fileURL, setFileURL] = useState([])
    const [activeCheck, setActiveCheck] = useState(false)

    const [form] = Form.useForm()

    // EDIT MODAL DATA SET INITIALLY
    useEffect(() => {
        if (selectedData) {
            if (selectedData?.logo?.length > 0) {
                setFileURL([selectedData?.logo])
            }
            setActiveCheck(selectedData?.isActive)
            form.setFieldsValue({
                ...selectedData,
            })
        }
    }, [form, selectedData])

    useEffect(() => {
        if (profileData?.data) {
            if (profileData?.data?.profilePicture?.length > 0) {
                setFileURL([profileData?.data?.profilePicture])
            }
            form.setFieldsValue({
                ...profileData?.data,
            })
        }
    }, [form, profileData])

    // ONCHANGE FILE PROPS
    const props = {
        name: 'logo',
        multiple: false,
        onChange(info) {
            // setStream(true)
            const isLt5M = info?.file.size / 1024 / 1024 < 5
            if (!isLt5M) {
                message.error('Profile picture must be smaller than 5MB')
                return
            } else {
                const nameChange = info?.fileList[0]?.name
                    .split('.')[0]
                    .concat(`_${dayjs(new Date()).unix()}`)
                    .concat(`.${info?.fileList[0]?.name.split('.')[1]}`)

                const newFileData = new File(
                    [info?.fileList[0]?.originFileObj],
                    nameChange,
                    { type: info?.fileList[0]?.type }
                )
                window.Buffer = window.Buffer || require('buffer').Buffer

                S3FileUpload.uploadFile(newFileData, userProfile?.s3Config)
                    .then(async (data) => {
                        // fileUpload.push(data?.location)
                        setFileURL([data?.location])
                    })
                    .catch(() => {
                        message.error('Upload Failed!. Please Upload again')
                    })
            }
            // info?.preventDefault()
        },

        beforeUpload() {
            return false
        },

        onDrop(e) {},
    }

    function onFinishFailed() {}

    // FINISH PROFILE EDIT
    async function saveMasterData(value) {
        dispatch(
            switchMasterDataModal({
                ...modalVariations,
                saveButtonLoading: true,
            })
        )

        const result = await dispatch(
            selectedData
                ? masterDataUpdate({
                      editData: {
                          ...value,
                          isActive: activeCheck,
                          logo: fileURL?.length > 0 ? fileURL[0] : '',
                      },
                      model: modelType?.name,
                      _id: selectedData?._id,
                  })
                : masterDataAdd({
                      addData: {
                          data: {
                              ...value,
                              logo: fileURL?.length > 0 ? fileURL[0] : '',
                              createdBy: userProfile?._id,
                          },
                          type: 'manual',
                      },
                      model: modelType?.name,
                  })
        )
        const data = result?.payload?.data
        if (data) {
            const { success, message: checkMessage } = data
            if (success) {
                dispatch(
                    masterDataListCount({
                        _id: modelType?.name,
                        // filter: { isActive: filter.isActive },
                    })
                )
                dispatch(
                    masterDataListGet({
                        limit: pageLimit,
                        skip: skipPage,
                        search: search,
                        id: modelType?.name,
                    })
                )
                message.success(checkMessage)
                dispatch(
                    switchMasterDataModal({
                        ...modalVariations,
                        open: false,
                        saveButtonLoading: false,
                    })
                )
                // if (currentUser === 1 && currentPage !== 1) {
                //     dispatch(
                //         await usersList({
                //             skip: skipPage - pageLimit,
                //             limit: pageLimit,
                //         })
                //     )
                // } else {
                //     dispatch(
                //         await usersList({
                //             skip: skipPage,
                //             limit: pageLimit,
                //         })
                //     )
                // }
            } else {
                dispatch(
                    switchMasterDataModal({
                        ...modalVariations,
                        open: false,
                        saveButtonLoading: false,
                    })
                )
                if (checkMessage) {
                    message.error(checkMessage)
                } else {
                    message.error('Something went wrong, try again later.')
                }
            }
        }
    }

    return updatedDataStatus === 'loading' ? (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
            }}
        >
            <Spin size="large" />
        </div>
    ) : (
        <>
            <Form
                labelCol={{
                    style: {
                        padding: '0 0 3px',
                    },
                }}
                layout="vertical"
                name="masterDataForm"
                onFinish={saveMasterData}
                onFinishFailed={onFinishFailed}
                onSubmitCapture={async (e) => {
                    await dispatch(checkButtonType(e.nativeEvent.submitter.id))
                }}
                form={form}
                requiredMark={false}
                style={{
                    overflowX: 'hidden',
                }}
            >
                <Row gutter={30}>
                    {modelType?.name !== 'skills' &&
                        modelType?.name !== 'degree' &&
                        modelType?.name !== 'fieldOfStudy' &&
                        modelType?.name !== 'designation' &&
                        modelType?.name !== 'employmentType' && (
                            <Col span={9}>
                                <Card
                                    style={{
                                        borderRadius: '16px',
                                        height: '100%',
                                    }}
                                    styles={{
                                        body: {
                                            padding: '30px 44px',
                                        },
                                    }}
                                >
                                    <Form.Item
                                        name="logo"
                                        rootClassName="imageUpload"
                                        rules={[
                                            {
                                                validator: async () => {
                                                    if (fileURL?.length === 0) {
                                                        return Promise.reject(
                                                            new Error(
                                                                `Please Upload ${modalType} logo`
                                                            )
                                                        )
                                                    } else {
                                                        return
                                                    }
                                                },
                                            },
                                        ]}
                                        help={fileURL?.length === 0 ? null : ''}
                                    >
                                        <Upload
                                            listType="picture-circle"
                                            className="avatar-uploader"
                                            {...props}
                                            fileList={[]}
                                            showUploadList={false}
                                            onPreview={() => {}}
                                            accept=".png,.jpg,.jpeg,.svg"
                                            style={{
                                                height: '128px !important',
                                                width: '128px !important',
                                            }}
                                        >
                                            {fileURL?.length > 0 ? (
                                                <Image
                                                    src={fileURL[0]}
                                                    preview={false}
                                                    style={{
                                                        height: '128px',
                                                        width: '128px',
                                                        borderRadius: '50%',
                                                    }}
                                                />
                                            ) : selectedData ? (
                                                <div className={'hoverLayer'}>
                                                    <div
                                                        className={'hoverShow'}
                                                    >
                                                        <UploadImageIcon />
                                                        <div
                                                            style={{
                                                                marginTop: 8,
                                                                color: token
                                                                    .colorPalette
                                                                    .baseColor
                                                                    .white,
                                                            }}
                                                        >
                                                            Update logo
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <UploadImageIcon
                                                        color={
                                                            token.colorPalette
                                                                .textColor
                                                                .senary
                                                        }
                                                    />
                                                    <div
                                                        style={{
                                                            marginTop: 8,
                                                            color: token
                                                                .colorPalette
                                                                .textColor
                                                                .senary,
                                                        }}
                                                    >
                                                        Upload logo
                                                    </div>
                                                </div>
                                            )}
                                        </Upload>
                                        <Row
                                            style={{
                                                marginTop: '10px',
                                            }}
                                        >
                                            <Text
                                                className="titleSecondary"
                                                style={{
                                                    fontSize:
                                                        token.fontSizeIcon,
                                                    textAlign: 'center',
                                                }}
                                            >
                                                Allowed *.jpeg, *.jpg, *.png,
                                                Max size of 5 MB
                                            </Text>
                                        </Row>
                                    </Form.Item>
                                </Card>
                            </Col>
                        )}
                    <Col
                        span={
                            modelType?.name !== 'skills' &&
                            modelType?.name !== 'degree' &&
                            modelType?.name !== 'fieldOfStudy' &&
                            modelType?.name !== 'designation' &&
                            modelType?.name !== 'employmentType'
                                ? 15
                                : 24
                        }
                    >
                        <Row
                            gutter={20}
                            style={{
                                rowGap: '28px',
                            }}
                        >
                            {selectedData && (
                                <Col span={24}>
                                    <Space>
                                        <Text
                                            style={{
                                                fontWeight:
                                                    token.fontWeightStrong,
                                            }}
                                        >
                                            Active
                                        </Text>
                                        <Switch
                                            defaultChecked={activeCheck}
                                            onChange={(checked) =>
                                                setActiveCheck(checked)
                                            }
                                            checked={activeCheck}
                                        />
                                    </Space>
                                </Col>
                            )}
                            <Col span={24}>
                                <Form.Item
                                    name={'name'}
                                    label="Name*"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please Enter Name',
                                        },
                                        {
                                            max: 25,
                                            message:
                                                'Name is maximum 25 characters long.',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Enter Name" />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

export default MasterDataModelContent
