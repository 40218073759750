import React from 'react'

function VerifyTalentIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 20 20"
        >
            <path
                fill="#1B806A"
                d="M8.174.927a2.75 2.75 0 00-3.296 1.365l-.739 1.446a.917.917 0 01-.4.4l-1.445.738A2.75 2.75 0 00.93 8.172l.5 1.544c.06.184.06.381 0 .565l-.5 1.544a2.75 2.75 0 001.365 3.296l1.446.739a.917.917 0 01.4.4l.738 1.445a2.75 2.75 0 003.296 1.365l1.544-.5a.917.917 0 01.565 0l1.544.5a2.75 2.75 0 003.296-1.365l.739-1.446a.917.917 0 01.4-.4l1.445-.738a2.75 2.75 0 001.365-3.296l-.5-1.544a.917.917 0 010-.565l.5-1.544a2.75 2.75 0 00-1.365-3.296l-1.446-.739a.917.917 0 01-.4-.399l-.738-1.446A2.75 2.75 0 0011.827.927l-1.544.5a.917.917 0 01-.565 0l-1.544-.5zm-2.977 8.85L6.493 8.48l2.593 2.592 5.186-5.185 1.296 1.296-6.482 6.482-3.889-3.889z"
            ></path>
        </svg>
    )
}

export default VerifyTalentIcon
