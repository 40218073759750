import React from 'react'

function TerminateIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 33 38"
        >
            <path
                fill="#FF3838"
                d="M14 20.668c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10zm5 8.333a5 5 0 017.156-4.512l-6.668 6.668A4.98 4.98 0 0119 29.001zm2.845 4.513l6.668-6.668a5 5 0 01-6.668 6.668zM24 20.668a8.333 8.333 0 100 16.667 8.333 8.333 0 000-16.667zm-10 1.667c.14 0 .28.002.42.006a11.614 11.614 0 00-2.086 6.66c0 2.479.773 4.777 2.09 6.667H.668c0-7.364 5.97-13.333 13.333-13.333z"
            ></path>
        </svg>
    )
}

export default TerminateIcon
