import React from 'react'

function SprintCreateIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            fill="none"
            viewBox="0 0 22 22"
        >
            <path
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="1.5"
                d="M12.155 3.3L4.63 11.265c-.285.303-.56.899-.615 1.311l-.339 2.97c-.119 1.073.651 1.806 1.714 1.623l2.952-.504c.413-.074.99-.376 1.274-.688l7.526-7.966c1.302-1.375 1.888-2.942-.137-4.858-2.017-1.898-3.548-1.228-4.85.147z"
            ></path>
            <path
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="1.5"
                d="M10.9 4.629a5.615 5.615 0 004.995 4.72M2.75 20.167h16.5"
            ></path>
        </svg>
    )
}

export default SprintCreateIcon
