import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
    create,
    count,
    read,
    update,
    getRole,
    getRoleWiseField,
} from './role.service'

const initialState = {
    search: '',
    status: 'loaded',
    updatedDataStatus: 'loaded',
    fieldGetStatus: 'loaded',
    formButtonType: 'saveButtonLoading',
    selectedData: undefined,
    selectedFieldData: undefined,
    modalVariations: {
        open: false,
        // formName: 'roleAddForm',
        // saveButtonLoading: false,
    },
    moduleSelected: {},
    roleName: '',
    roleList: [],
    tableData: [],
    roleCount: 0,
    isNext: false,
    pageLimit: 10,
    currentPage: 1,
    skipPage: 0,
    permissionOptionText: '',
    filter: {
        isOurUser: '',
        isActive: '',
    },
}

export const addRole = createAsyncThunk(
    'roleSlice/create',
    async (payload = {}) => {
        const response = await create(payload)
        return response
    }
)

export const updateRole = createAsyncThunk(
    'roleSlice/update',
    async (payload = {}) => {
        const response = await update(payload)
        return response
    }
)

export const totalRoleCount = createAsyncThunk(
    'roleSlice/count',
    async (payload = {}) => {
        const response = await count(payload)
        return response
    }
)

export const roleListGet = createAsyncThunk(
    'roleSlice/read',
    async (payload = {}) => {
        const response = await read(payload)
        return response
    }
)

export const roleGet = createAsyncThunk(
    'roleSlice/getRole',
    async (payload = {}) => {
        const response = await getRole(payload)
        return response
    }
)

export const fieldGet = createAsyncThunk(
    'roleSlice/getRoleWiseField',
    async (payload = {}) => {
        const response = await getRoleWiseField(payload)
        return response
    }
)

export const roleSlice = createSlice({
    name: 'roleSlice',
    initialState,
    reducers: {
        switchRoleModal: (state, action) => {
            state.modalVariations = action.payload
            state.selectedData = undefined
        },
        checkButtonType: (state, action) => {
            state.formButtonType = action.payload
        },
        moduleChangeName: (state, action) => {
            state.moduleSelected = {
                ...action.payload,
            }
        },
        changePage: (state, action) => {
            state.currentPage = action.payload.currentPage
            state.skipPage = action.payload.skip
            state.pageLimit = action.payload.pageLimit
        },
        searchRole: (state, action) => {
            state.search = action.payload
        },
        clearUsers: (state) => {
            state.teamList = []
            state.search = ''
        },
        filterChange: (state, action) => {
            const dropDownValue = {
                ...state.filter,
                ...action.payload,
            }
            Object.keys(dropDownValue).forEach((key) => {
                if (dropDownValue[key] === '') {
                    delete dropDownValue[key]
                }
            })
            state.filter = dropDownValue
        },
        clearFilter: (state) => {
            state.filter = {}
        },
        tableDataSet: (state, action) => {
            state.tableData = action.payload
        },
        permissionOption: (state, action) => {
            state.permissionOptionText = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(roleListGet.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(roleListGet.fulfilled, (state, action) => {
                state.status = 'loaded'
                state.roleList = action.payload.data
                state.isNext = action.payload.isNext
            })
            .addCase(totalRoleCount.pending, (state, action) => {
                state.roleCount = 0
            })
            .addCase(totalRoleCount.fulfilled, (state, action) => {
                state.roleCount = action.payload.data.data
            })
            .addCase(roleGet.pending, (state) => {
                state.updatedDataStatus = 'loading'
            })
            .addCase(roleGet.fulfilled, (state, action) => {
                state.updatedDataStatus = 'loaded'
                state.selectedData = action?.payload.data?.data
            })
            .addCase(fieldGet.pending, (state) => {
                state.fieldGetStatus = 'loading'
            })
            .addCase(fieldGet.fulfilled, (state, action) => {
                state.fieldGetStatus = 'loaded'
                state.selectedFieldData = action?.payload.data?.data
                state.roleName = action?.payload?.data?.roleName
                // state.moduleSelected =
                //     action.payload.data.data?.length > 0
                //         ? action.payload.data.data[0]
                //         : ''
            })
    },
})

export default roleSlice.reducer

export const roleState = (state) => {
    const { roleSlice } = state
    const {
        search,
        status,
        updatedDataStatus,
        formButtonType,
        selectedData,
        modalVariations,
        roleList,
        roleCount,
        isNext,
        pageLimit,
        currentPage,
        skipPage,
        filter,
        fieldGetStatus,
        selectedFieldData,
        moduleSelected,
        tableData,
        roleName,
        permissionOptionText,
    } = roleSlice
    return {
        search,
        status,
        updatedDataStatus,
        formButtonType,
        selectedData,
        modalVariations,
        roleList,
        roleCount,
        isNext,
        pageLimit,
        currentPage,
        skipPage,
        filter,
        fieldGetStatus,
        selectedFieldData,
        moduleSelected,
        tableData,
        roleName,
        permissionOptionText,
    }
}

export const {
    checkButtonType,
    changePage,
    switchRoleModal,
    searchRole,
    clearFilter,
    clearUsers,
    filterChange,
    tableDataSet,
    moduleChangeName,
    permissionOption,
} = roleSlice.actions
