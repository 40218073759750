import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import OtpInput from 'react-otp-input'

// antd
import { Col, Divider, Form, Row, Typography, message, theme } from 'antd'

// slices
import { isLoggedInLogin, sendOtpLogin, verifyOtpLogin } from './login.slice'
import { setGlobalTimer } from '../tasks/tasks.slice'

// components
import Button from '../../components/button/button'

// assets
import { VerifyOtpIcon } from '../../assets/icons'

// routes
import { PUBLIC_ROUTES } from '../../routes'

// css
import './login.css'

function VerifyOtp() {
    const [form] = Form.useForm()

    const dispatch = useDispatch()

    const navigate = useNavigate()

    const { useToken } = theme

    const { token } = useToken()

    const { Title, Text } = Typography

    const [loadingButton, setLoadingButton] = useState(false)
    const [isOTP, setIsOTP] = useState('')

    function onFinishFailed() {}

    async function onVerifyOtp() {
        setLoadingButton(true)
        const result = await dispatch(
            verifyOtpLogin({
                otp: isOTP,
                ...JSON.parse(localStorage?.getItem('phoneData')),
                phone: JSON.parse(localStorage?.getItem('phoneData'))
                    ?.phoneNumber,
                loginFlag:
                    JSON.parse(localStorage?.getItem('phoneData'))?.step ===
                    'login'
                        ? true
                        : false,
            })
        )
        const data = result?.payload?.data
        if (data) {
            const { success, message: otpMessage, data: otpSuccessData } = data
            if (success) {
                message.success(otpMessage)
                if (
                    JSON.parse(localStorage?.getItem('phoneData'))?.step ===
                    'login'
                ) {
                    const checkLogin = await dispatch(isLoggedInLogin())
                    const checkSuccessLogin = checkLogin?.payload?.data
                    const {
                        success: checkSuccess,
                        message: checkMessage,
                        data: userProfile,
                    } = checkSuccessLogin
                    if (checkSuccess) {
                        dispatch(setGlobalTimer(userProfile?.runningTask))
                        message.success(checkMessage)
                        localStorage.setItem('phoneData', '')
                        localStorage.setItem(
                            'currentProject',
                            JSON.stringify({
                                id: checkSuccessLogin?.data?.activeProject?._id,
                                name: checkSuccessLogin?.data?.activeProject
                                    ?.name,
                            })
                        )
                        setLoadingButton(false)
                    } else {
                        setLoadingButton(false)
                        if (checkMessage) {
                            message.error(checkMessage)
                        } else {
                            message.error(
                                'Something went wrong, try again later.'
                            )
                        }
                    }
                } else {
                    localStorage.setItem('userId', otpSuccessData?._id)
                    navigate(PUBLIC_ROUTES.updatePassword)
                }
            } else {
                if (typeof otpMessage === 'object') {
                    for (const key in otpMessage) {
                        message.error(otpMessage[key])
                    }
                } else if (typeof otpMessage === 'string') {
                    message.error(otpMessage)
                } else {
                    message.error('Something went wrong, try again later.')
                }
                setLoadingButton(false)
                setIsOTP('')
                form.setFieldValue('otpInput', '')
            }
        }
    }

    useEffect(() => {
        if (!localStorage.getItem('phoneData')) {
            navigate(PUBLIC_ROUTES.signUp)
            // navigate(PUBLIC_ROUTES.login)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // SEND OTP
    const handleSendOtp = async () => {
        const result = await dispatch(
            sendOtpLogin({
                countryCode: JSON.parse(localStorage?.getItem('phoneData'))
                    ?.countryCode,
                phone: JSON.parse(localStorage?.getItem('phoneData'))
                    ?.phoneNumber,
            })
        )
        const data = result?.payload?.data
        if (data) {
            const { success, message: checkMessage } = data
            if (success) {
                setIsOTP('')
                form.setFieldValue('otpInput', '')
                setLoadingButton(false)
                message.success(checkMessage)
            } else {
                setIsOTP('')
                form.setFieldValue('otpInput', '')
                setLoadingButton(false)
                if (checkMessage) {
                    message.error(checkMessage)
                } else {
                    message.error('Something went wrong, try again later.')
                }
            }
        }
    }

    const handleChangeOTP = (otp) => {
        setIsOTP(otp)
    }

    return (
        <div
            style={{
                padding: '60px 60px 0px 60px',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginBottom: '35px',
                }}
            >
                <VerifyOtpIcon />
            </div>
            <Title level={3} style={{ textAlign: 'center' }}>
                Please check your phone
            </Title>
            <div
                style={{
                    marginTop: '20px',
                    textAlign: 'center',
                }}
            >
                <Text
                    style={{
                        textAlign: 'center',
                    }}
                >
                    {`Please check your phone ${
                        localStorage.getItem('phoneData') &&
                        JSON.parse(localStorage?.getItem('phoneData'))
                            ?.phoneNumber
                    } for a validation code and
                    enter it below to complete your password change`}
                </Text>
            </div>
            <Form
                layout="vertical"
                name="verifyOtp"
                onFinish={onVerifyOtp}
                onFinishFailed={onFinishFailed}
                requiredMark={false}
                form={form}
                style={{
                    marginTop: '20px',
                }}
            >
                <Col
                    span={24}
                    style={{
                        padding: '0px',
                        marginBottom: '10px',
                    }}
                >
                    <Form.Item
                        name="otpInput"
                        rules={[
                            {
                                required: true,
                                message: 'Please Enter OTP',
                            },
                        ]}
                        colon={false}
                    >
                        <OtpInput
                            value={isOTP}
                            renderInput={(props) => <input {...props} />}
                            onChange={handleChangeOTP}
                            numInputs={6}
                            shouldAutoFocus
                            inputStyle="input-otp-style"
                            containerStyle={'otp-style'}
                            hasErrored={true}
                            isInputNum={true}
                        />
                    </Form.Item>
                </Col>
                <Row
                    gutter={20}
                    style={{
                        marginTop: '20px',
                    }}
                >
                    <Col span={24}>
                        <Button
                            props={{
                                text: 'Verify',
                                htmlType: 'submit',
                                style: {
                                    backgroundColor:
                                        token.colorPalette.registrationColor
                                            .secondary,
                                    width: '100%',
                                },
                                disabled: loadingButton,
                                loadingButton: loadingButton,
                            }}
                        />
                    </Col>
                </Row>
                <Divider />
                <div
                    style={{
                        marginTop: '20px',
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <Text
                        className="sub-title"
                        style={{
                            fontWeight: 400,
                        }}
                    >
                        Didn’t received code?{' '}
                        <span
                            style={{
                                color: token.colorPalette.registrationColor
                                    .secondary,
                                cursor: 'pointer',
                            }}
                            className="sub-title"
                            onClick={() => handleSendOtp()}
                        >
                            Resend Code
                        </span>
                    </Text>
                </div>
            </Form>
        </div>
    )
}

export default VerifyOtp
