import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import S3FileUpload from 'react-s3'

// antd
import {
    Avatar,
    Col,
    Form,
    Image,
    Row,
    Typography,
    Select,
    DatePicker,
    theme,
    Input,
    Upload,
    message,
    Card,
} from 'antd'

// slices
import { addProject, projectsState } from '../projects/projects.slice'
import { loginState } from '../login/login.slice'
import {
    inquiriesListGet,
    inquiriesListGetSwimlanes,
    inquiryCount,
    inquiryGet,
    inquiryState,
    inquiryUpdate,
} from './inquiries.slice'

// helpers
import { editFieldCheck, viewFieldCheck } from '../../helpers/permissionCheck'
import { isEmpty } from '../../helpers/fieldCheck'

// assets
import { UploadImageIcon } from '../../assets/icons'

// components
import Button from '../../components/button/button'

// constants
import { projectProfilePictures, projectType } from '../../constants/projects'
import { DOMAIN_LIST, SUPER_ADMIN } from '../../constants/roles'

import dayjs from 'dayjs'

const ConvertToProjectForm = ({ onCancel }) => {
    const { Text } = Typography
    const { TextArea } = Input

    const { useToken } = theme
    const { token } = useToken()

    const [form] = Form.useForm()

    const { selectedProject } = useSelector(projectsState)
    const { userProfile } = useSelector(loginState)
    const { selectedInquiry, skipPage, pageLimit, filterWorkSpace } =
        useSelector(inquiryState)

    const dispatch = useDispatch()

    useEffect(() => {
        if (selectedInquiry) {
            form.setFieldsValue({
                name: selectedInquiry?.projectName,
            })
        }
    }, [selectedInquiry, form])

    const [fileURL, setFileURL] = useState([])
    const [loadingButton, setLoadingButton] = useState(false)

    // ONCHANGE FILE PROPS
    const props = {
        name: 'projectProfile',
        multiple: false,
        onChange(info) {
            const isLt5M = info?.file.size / 1024 / 1024 < 5
            if (!isLt5M) {
                message.error('Profile picture must be smaller than 5MB')
                return
            } else {
                const nameChange = info?.fileList[0]?.name
                    .split('.')[0]
                    .concat(`_${dayjs(new Date()).unix()}`)
                    .concat(`.${info?.fileList[0]?.name.split('.')[1]}`)

                const newFileData = new File(
                    [info?.fileList[0]?.originFileObj],
                    nameChange,
                    { type: info?.fileList[0]?.type }
                )
                window.Buffer = window.Buffer || require('buffer').Buffer

                S3FileUpload.uploadFile(newFileData, userProfile?.s3Config)
                    .then(async (data) => {
                        setFileURL([data?.location])
                    })
                    .catch(() => {
                        message.error('Upload Failed!. Please Upload again')
                    })
            }
        },

        beforeUpload() {
            return false
        },

        onDrop() {},
    }

    // FORMAT DATEPICKER
    const formatDatePicker = (value) => {
        return `${dayjs(value).format('DD MMM YY')}`
    }

    // DISABLE START DATE
    const disabledDateEnd = (current) => {
        const startDate = form.getFieldValue('start')
        // Disable dates that are before the selected start date or dates that are in the past
        return startDate
            ? current && current.valueOf() < startDate.valueOf()
            : null
    }

    // DISABLE END DATE
    const disabledDateStart = (current) => {
        const endDate = form.getFieldValue('end')
        // Disable dates that are after the selected end date or dates that are in the future
        return endDate && current && current.valueOf() > endDate.valueOf()
    }

    const onFinishFailed = () => {}

    // ON FINISH PROJECT
    const onFinishConvert = async (value) => {
        setLoadingButton(true)

        const payload = {
            ...value,
            start: value?.start ? new Date(value?.start).getTime() : null,
            end: value?.end ? new Date(value?.end).getTime() : null,
            projectKey: value?.projectKey?.toUpperCase(),
            projectLead: userProfile?._id,
            projectProfile:
                fileURL?.length > 0
                    ? fileURL[0]
                    : projectProfilePictures[
                          (Math.random() * projectProfilePictures.length) | 0
                      ],
            workspaceId: selectedInquiry?.client?.workspaceId,
        }

        const result = await dispatch(
            addProject({
                ...payload,
            })
        )
        const data = result?.payload?.data
        if (data) {
            const { success, message: checkMessage } = data
            if (success) {
                const resultNew = await dispatch(
                    inquiryUpdate({
                        updatedData: {
                            isConvertedToProject: true,
                        },
                        _id: selectedInquiry?._id,
                        workspaceId: selectedInquiry?.client?.workspaceId,
                    })
                )

                const dataNew = resultNew?.payload?.data
                if (dataNew) {
                    const {
                        success: successNew,
                        message: checkMessageNew,
                        data: inquirySuccessData,
                    } = dataNew
                    if (successNew) {
                        dispatch(
                            inquiryGet({
                                id: inquirySuccessData?._id,
                                workspaceId:
                                    selectedInquiry?.client?.workspaceId,
                            })
                        )

                        if (
                            userProfile?.role?.roleName === SUPER_ADMIN &&
                            DOMAIN_LIST.includes(
                                userProfile?.currentWorkSpace?.domain
                            )
                        ) {
                            dispatch(
                                inquiriesListGetSwimlanes({
                                    limit: pageLimit,
                                    skip: skipPage,
                                    workspaceId:
                                        filterWorkSpace !== undefined
                                            ? filterWorkSpace
                                            : 'all',
                                })
                            )
                        } else {
                            dispatch(
                                inquiriesListGet({
                                    workspaceId:
                                        userProfile?.currentWorkSpace?._id,
                                    skip: 0,
                                    limit: pageLimit,
                                })
                            )
                            dispatch(
                                inquiryCount({
                                    workspaceId:
                                        userProfile?.currentWorkSpace?._id,
                                })
                            )
                        }
                        setLoadingButton(false)
                        message.success(checkMessage)
                    } else {
                        setLoadingButton(false)
                        if (checkMessageNew) {
                            if (typeof checkMessageNew === 'object') {
                                for (const key in checkMessageNew) {
                                    message.error(checkMessageNew[key])
                                }
                            } else {
                                message.error(checkMessageNew)
                            }
                        } else {
                            message.error(
                                'Something went wrong, try again later.'
                            )
                        }
                    }
                }
                onCancel()
                setLoadingButton(false)
                message.success(checkMessage)
            } else {
                onCancel()
                setLoadingButton(false)
                if (typeof checkMessage === 'object') {
                    for (const key in checkMessage) {
                        message.error(checkMessage[key])
                    }
                } else if (typeof checkMessage === 'string') {
                    message.error(checkMessage)
                } else {
                    message.error('Something went wrong, try again later.')
                }
            }
        }
    }
    return (
        <Form
            labelCol={{
                style: {
                    padding: '0 0 3px',
                },
            }}
            layout="vertical"
            name="convertToProjectForm"
            onFinish={onFinishConvert}
            onFinishFailed={onFinishFailed}
            requiredMark={false}
            form={form}
            style={{
                overflowX: 'hidden',
            }}
        >
            <Row gutter={30}>
                {(isEmpty(selectedProject) ||
                    viewFieldCheck(
                        'projects',
                        'projectProfile',
                        userProfile
                    )) && (
                    <Col span={9}>
                        <Card
                            style={{
                                borderRadius: '16px',
                                height: '100%',
                                border: `1px solid ${token.colorPalette.textColor.quaternary}`,
                            }}
                            styles={{
                                body: {
                                    padding: '30px 44px',
                                },
                            }}
                        >
                            <Form.Item
                                name="projectProfile"
                                rootClassName="imageUpload"
                            >
                                <Upload
                                    listType="picture-circle"
                                    className="avatar-uploader"
                                    {...props}
                                    fileList={[]}
                                    showUploadList={false}
                                    onPreview={() => {}}
                                    accept=".png,.jpg,.jpeg"
                                    style={{
                                        height: '128px !important',
                                        width: '128px !important',
                                    }}
                                    disabled={
                                        selectedProject &&
                                        editFieldCheck(
                                            'projects',
                                            'projectProfile',
                                            userProfile
                                        ) === false
                                    }
                                >
                                    {fileURL?.length > 0 ? (
                                        <Image
                                            src={fileURL[0]}
                                            preview={false}
                                            style={{
                                                height: '128px',
                                                width: '128px',
                                                borderRadius: '50%',
                                                objectFit: 'contain',
                                            }}
                                        />
                                    ) : (
                                        <>
                                            <Avatar
                                                style={{
                                                    color: token.colorPalette
                                                        .baseColor.white,
                                                }}
                                                className={'uploadAvatar'}
                                                size="large"
                                            >
                                                {userProfile?.name[0].toUpperCase()}
                                                {userProfile?.name.split(
                                                    ' '
                                                )[1] !== undefined &&
                                                    userProfile?.name
                                                        .split(' ')[1][0]
                                                        .toUpperCase()}
                                            </Avatar>
                                        </>
                                    )}
                                </Upload>
                            </Form.Item>
                            <div className={'hoverLayer'}>
                                <div className={'hoverShow'}>
                                    <UploadImageIcon />
                                    <div
                                        style={{
                                            marginTop: 8,
                                            color: token.colorPalette.baseColor
                                                .white,
                                        }}
                                    >
                                        Update logo
                                    </div>
                                </div>
                            </div>
                            <Row
                                style={{
                                    marginTop: '10px',
                                }}
                            >
                                <Text
                                    className="titleSecondary"
                                    style={{
                                        fontSize: token.fontSizeIcon,
                                        textAlign: 'center',
                                    }}
                                >
                                    Allowed *.jpeg, *.jpg, *.png, Max size of 5
                                    MB
                                </Text>
                            </Row>
                        </Card>
                    </Col>
                )}
                <Col span={15}>
                    <Row
                        gutter={20}
                        style={{
                            rowGap: '15px',
                        }}
                    >
                        {(isEmpty(selectedProject) ||
                            viewFieldCheck(
                                'projects',
                                'name',
                                userProfile
                            )) && (
                            <Col span={24}>
                                <Form.Item
                                    label="Project Name*"
                                    name="name"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Please Enter Project Name',
                                        },
                                        {
                                            max: 25,
                                            message:
                                                'Project Name is maximum 25 characters long',
                                        },
                                    ]}
                                    colon={false}
                                >
                                    <Input
                                        placeholder={'Enter Project Name'}
                                        name={'name'}
                                        readOnly={
                                            selectedProject &&
                                            editFieldCheck(
                                                'projects',
                                                'name',
                                                userProfile
                                            ) === false
                                        }
                                    />
                                </Form.Item>
                            </Col>
                        )}
                        {(isEmpty(selectedProject) ||
                            viewFieldCheck(
                                'projects',
                                'projectType',
                                userProfile
                            )) && (
                            <Col span={24}>
                                <Form.Item
                                    label="Project Type*"
                                    name="projectType"
                                    colon={false}
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Please Enter Project Type',
                                        },
                                    ]}
                                >
                                    <Select
                                        options={projectType}
                                        allowClear={false}
                                        placeholder="Select a Type"
                                        disabled={
                                            selectedProject &&
                                            editFieldCheck(
                                                'projects',
                                                'projectType',
                                                userProfile
                                            ) === false
                                        }
                                    />
                                </Form.Item>
                            </Col>
                        )}
                        {(isEmpty(selectedProject) ||
                            viewFieldCheck(
                                'projects',
                                'projectKey',
                                userProfile
                            )) && (
                            <Col span={24}>
                                <Form.Item
                                    label="Project Key*"
                                    name="projectKey"
                                    colon={false}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please Enter Project Key',
                                        },
                                        {
                                            max: 10,
                                            message:
                                                'Project Key  is maximum 10 characters long',
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder={'Enter Project Key'}
                                        name={'projectKey'}
                                        readOnly={
                                            selectedProject ? true : false
                                        }
                                    />
                                </Form.Item>
                            </Col>
                        )}
                    </Row>
                </Col>
            </Row>
            <Row
                gutter={30}
                style={{
                    rowGap: '25px',
                    marginTop: '25px',
                }}
            >
                {(isEmpty(selectedProject) ||
                    viewFieldCheck('projects', 'start', userProfile)) && (
                    <Col flex={'1 0 50%'}>
                        <Form.Item
                            label="Start Date"
                            colon={false}
                            name={'start'}
                        >
                            <DatePicker
                                allowClear={false}
                                name="start"
                                disabledDate={disabledDateStart}
                                format={formatDatePicker}
                                disabled={
                                    selectedProject &&
                                    editFieldCheck(
                                        'projects',
                                        'start',
                                        userProfile
                                    ) === false
                                }
                            />
                        </Form.Item>
                    </Col>
                )}
                {(isEmpty(selectedProject) ||
                    viewFieldCheck('projects', 'end', userProfile)) && (
                    <Col flex={'1 0 50%'}>
                        <Form.Item label="End Date" colon={false} name={'end'}>
                            <DatePicker
                                allowClear={false}
                                disabledDate={disabledDateEnd}
                                name="end"
                                format={formatDatePicker}
                                disabled={
                                    selectedProject &&
                                    editFieldCheck(
                                        'projects',
                                        'end',
                                        userProfile
                                    ) === false
                                }
                            />
                        </Form.Item>
                    </Col>
                )}
                {(isEmpty(selectedProject) ||
                    viewFieldCheck('projects', 'description', userProfile)) && (
                    <Col span={24}>
                        <Form.Item
                            label="Description"
                            name="description"
                            rules={[
                                {
                                    max: 500,
                                    message:
                                        'Project description is maximum 500 characters long',
                                },
                            ]}
                            colon={false}
                        >
                            <TextArea
                                rows={5}
                                placeholder={'Enter Project Description...'}
                                name={'description'}
                                readOnly={
                                    selectedProject &&
                                    editFieldCheck(
                                        'projects',
                                        'description',
                                        userProfile
                                    ) === false
                                }
                            />
                        </Form.Item>
                    </Col>
                )}
            </Row>
            <Row
                gutter={12}
                style={{
                    float: 'right',
                    marginTop: '35px',
                }}
            >
                <Col span={24}>
                    <Button
                        props={{
                            htmlType: 'submit',
                            loadingButton: loadingButton,
                            text: 'Convert',
                            style: {
                                padding: '8px 27px',
                            },
                        }}
                    />
                </Col>
            </Row>
        </Form>
    )
}

export default ConvertToProjectForm
