import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// antd
import { Card, Typography, message, theme } from 'antd'

// assets
import { RoleStepIcon, RoleStepLoadingIcon } from '../../../../../assets/icons'

// slices
import {
    changeRoleStep,
    roleInquiryCount,
    rolesInquiryList,
    rolesInquiryState,
    setCreateRoleId,
    switchRoleModal,
    talentDataMatch,
} from '../rolesInquiry.slice'
import { inquiryState } from '../../../inquiries.slice'

const TalentMatch = ({ setStep }) => {
    const { Text, Title } = Typography

    const { useToken } = theme

    const { token } = useToken()

    const dispatch = useDispatch()

    const { pageLimit, skipPage, createRoleId, search } =
        useSelector(rolesInquiryState)
    const { selectedInquiry } = useSelector(inquiryState)

    const [stepTimeData, setStepTimeData] = useState([
        {
            title: 'Processing job requirements',
            icon: <RoleStepIcon />,
            step: 1,
        },
    ])

    useEffect(() => {
        // Define an array of timeouts with different durations
        const timeouts = [
            {
                time: 1000,
                title: 'Connecting to SoftwareCo Network',
                icon: <RoleStepIcon />,
                step: 2,
            },
            {
                time: 2000,
                title: 'Fetching skill sets',
                icon: <RoleStepIcon />,
                step: 3,
            },
            {
                time: 3000,
                title: 'Matching the best talent for your job opportunity',
                icon: <RoleStepLoadingIcon />,
                step: 4,
            },
        ]

        // Helper function to fetch and push data to state
        const fetchDataAndPush = async (title) => {
            try {
                // Update state by pushing new data
                setStepTimeData((prevData) => [...prevData, title])
                if (title?.step === 3) {
                    const result = await dispatch(
                        talentDataMatch({
                            roleId: createRoleId,
                            workspaceId: selectedInquiry?.client?.workspaceId,
                        })
                    )
                    const data = result?.payload?.data
                    if (data) {
                        const { success, message: checkMessage } = data
                        if (success) {
                            dispatch(setCreateRoleId(undefined))
                            if (typeof checkMessage === 'object') {
                                for (const key in checkMessage) {
                                    message.success(checkMessage[key])
                                }
                            } else if (typeof checkMessage === 'string') {
                                message.success(checkMessage)
                            }
                            dispatch(
                                rolesInquiryList({
                                    filter: {
                                        isActive: true,
                                    },
                                    limit: pageLimit,
                                    skip: skipPage,
                                    inquiryId: selectedInquiry?._id,
                                    workspaceId:
                                        selectedInquiry?.client?.workspaceId,
                                    search,
                                })
                            )
                            dispatch(
                                roleInquiryCount({
                                    inquiryId: selectedInquiry?._id,
                                    workspaceId:
                                        selectedInquiry?.client?.workspaceId,
                                    search,
                                })
                            )
                            dispatch(changeRoleStep(1))
                            setStep()
                            dispatch(switchRoleModal({ open: false }))
                        } else {
                            setStep()
                            if (typeof checkMessage === 'object') {
                                for (const key in checkMessage) {
                                    message.error(checkMessage[key])
                                }
                            } else if (typeof checkMessage === 'string') {
                                message.error(checkMessage)
                            } else {
                                message.error(
                                    'Something went wrong, try again later.'
                                )
                            }
                        }
                    } else {
                    }
                }
            } catch (error) {
                console.error('Error fetching data:', error)
            }
        }

        // after different durations
        const timeoutIds = timeouts.map((duration, index) => {
            return setTimeout(() => {
                fetchDataAndPush(timeouts[index])
            }, duration.time)
        })

        // Clean up function to clear timeouts when component unmounts
        return () => {
            timeoutIds.forEach((timeoutId) => clearTimeout(timeoutId))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <Text>
                Wait a few seconds while we search the best talent for you.
            </Text>
            {stepTimeData?.length > 0 &&
                stepTimeData.map((data) => (
                    <Card
                        style={{
                            backgroundColor:
                                token.colorPalette.baseColor.quaternary,
                            padding: '0px',
                            marginTop: '25px',
                            borderRadius: '5px',
                        }}
                        styles={{
                            body: {
                                padding: '15px 20px',
                            },
                        }}
                        bordered={false}
                    >
                        <div
                            style={{
                                display: 'flex',
                                columnGap: '13px',
                            }}
                        >
                            <div>{data?.icon}</div>
                            <Title level={5}>{data?.title}</Title>
                        </div>
                    </Card>
                ))}
        </>
    )
}

export default TalentMatch
