import React from 'react'

function DeleteIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="none"
            viewBox="0 0 20 20"
        >
            <path
                fill="#FF3838"
                d="M19 4.001h-5v-1.67a2.42 2.42 0 00-2.5-2.33h-3A2.42 2.42 0 006 2.331v1.67H1a1 1 0 000 2h1v11a3 3 0 003 3h10a3 3 0 003-3v-11h1a1 1 0 100-2zM8 2.331c0-.16.21-.33.5-.33h3c.29 0 .5.17.5.33v1.67H8v-1.67zm8 14.67a1 1 0 01-1 1H5a1 1 0 01-1-1v-11h12v11z"
            ></path>
            <path
                fill="#FF3838"
                d="M7 15.001a1 1 0 001-1v-4a1 1 0 10-2 0v4a1 1 0 001 1zM13 15.001a1 1 0 001-1v-4a1 1 0 00-2 0v4a1 1 0 001 1z"
            ></path>
        </svg>
    )
}

export default DeleteIcon
