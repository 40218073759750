import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
    addMasterDataDropDown,
    createSummary,
    favIconGet,
    getTalentProfile,
    getUserDeleteData,
    getUserUpdate,
    getUserUpdatedData,
    listMasterDataDropDown,
} from './profile.service'

const initialState = {
    search: '',
    status: 'loaded',
    updatedDataStatus: 'loaded',
    defaultTab: 'summary',
    formButtonType: 'saveButtonLoading',
    selectedData: undefined,
    videoRecordingSummary: false,
    retakeVideo: {
        visibility: false,
    },
    modelType: '',
    deleteModalTitle: '',
    masterDataDropDownStatus: 'loaded',
    masterDataDropDown: {
        institute: [],
        certificateProvider: [],
        company: [],
        degree: [],
        designation: [],
        employmentType: [],
        fieldOfStudy: [],
        linkType: [],
        skills: [],
        tools: [],
    },
    profileModal: {
        profile: {
            open: false,
            formName: 'addProfile',
            saveButtonLoading: false,
            formNameVideo: 'saveVideo',
            saveVideoLoading: false,
            disableVideoLoading: false,
        },
        summary: {
            open: false,
            formName: 'addSummary',
            saveButtonLoading: false,
            addMoreButtonLoading: false,
            draftButtonLoading: false,
        },
        education: {
            open: false,
            formName: 'addEducation',
            saveButtonLoading: false,
            addMoreButtonLoading: false,
            draftButtonLoading: false,
        },
        certificates: {
            open: false,
            formName: 'addCertificates',
            loading: false,
            saveButtonLoading: false,
            addMoreButtonLoading: false,
            draftButtonLoading: false,
        },
        experience: {
            open: false,
            formName: 'addExperience',
            loading: false,
            saveButtonLoading: false,
            addMoreButtonLoading: false,
            draftButtonLoading: false,
        },
        projects: {
            open: false,
            formName: 'addProjects',
            loading: false,
            saveButtonLoading: false,
            addMoreButtonLoading: false,
            draftButtonLoading: false,
        },
        videos: {
            open: false,
            formName: 'addVideos',
            loading: false,
            saveButtonLoading: false,
            addMoreButtonLoading: false,
            draftButtonLoading: false,
        },
    },
    introductionVideoModal: {
        open: false,
        videos: [],
        videosTab: [],
        type: '',
        category: '',
    },
    videoPlayModal: {
        delete: false,
        open: false,
        videoData: {
            deleteId: '',
            link: '',
        },
        title: 'Introduction Video',
    },
    profileData: {},
}

export const addSummary = createAsyncThunk(
    'profileSlice/create',
    async (payload = {}) => {
        const response = await createSummary(payload)
        return response
    }
)

export const updateUserGet = createAsyncThunk(
    'profileSlice/getUserUpdate',
    async (payload = {}) => {
        const response = await getUserUpdate(payload)
        return response
    }
)

export const talentGetProfile = createAsyncThunk(
    'profileSlice/getTalentProfile',
    async (payload = {}) => {
        const response = await getTalentProfile(payload)
        return response
    }
)

export const updatedDataProfile = createAsyncThunk(
    'profileSlice/getUserUpdatedData',
    async (payload = {}) => {
        const response = await getUserUpdatedData(payload)
        return response
    }
)

export const deleteDataProfile = createAsyncThunk(
    'profileSlice/getUserDeleteData',
    async (payload = {}) => {
        const response = await getUserDeleteData(payload)
        return response
    }
)

export const masterDataDropDownList = createAsyncThunk(
    'profileSlice/listMasterDataDropDown',
    async (payload = {}) => {
        const response = await listMasterDataDropDown(payload)
        return response
    }
)

export const masterDataAddDropDown = createAsyncThunk(
    'profileSlice/addMasterDataDropDown',
    async (payload = {}) => {
        const response = await addMasterDataDropDown(payload)
        return response
    }
)

export const iconGet = createAsyncThunk(
    'profileSlice/favIconGet',
    async (payload = {}) => {
        const response = await favIconGet(payload)
        return response
    }
)

export const profileSlice = createSlice({
    name: 'profileSlice',
    initialState,
    reducers: {
        switchProfileModal: (state, action) => {
            let data = state.profileModal
            Object.keys(data).forEach((item) => {
                if (item === action.payload.name) {
                    data[item] = {
                        ...data[item],
                        open: action.payload.open,
                    }
                } else {
                    data[item] = {
                        ...data[item],
                        open: false,
                    }
                }
            })
            state.selectedData = undefined
            state.profileModal = data
        },
        changeModel: (state, action) => {
            state.modelType = action.payload
        },
        videoRecordSummary: (state, action) => {
            state.videoRecordingSummary = action.payload
        },
        retakeVideoChange: (state, action) => {
            state.retakeVideo = action.payload
        },
        disableVideoSave: (state, action) => {
            state.profileModal.profile = {
                ...state.profileModal.profile,
                disableVideoLoading: action.payload,
            }
        },
        profileLoading: (state, action) => {
            let data = state.profileModal
            data[action.payload.name] = {
                ...data[action.payload.name],
                [action.payload.buttonName]: action.payload.loading,
            }
            state.profileModal = data
        },
        setMasterDataDropDown: (state, action) => {
            state.masterDataDropDown[action.payload.model] = [
                {
                    label: action.payload.data,
                    value: action.payload.data,
                    logo: '',
                    type: 'addTemp',
                },
            ]
        },
        switchIntroductionModal: (state, action) => {
            state.introductionVideoModal = action.payload
        },
        switchVideoPlayModal: (state, action) => {
            state.videoPlayModal = action.payload
        },
        checkButtonType: (state, action) => {
            state.formButtonType = action.payload
        },
        changeTab: (state, action) => {
            state.defaultTab = action.payload
        },
        setDeleteModalTitle: (state, action) => {
            state.deleteModalTitle = action.payload
        },
        clearProfileData: (state) => {
            state.profileData = {}
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(updateUserGet.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(updateUserGet.fulfilled, (state, action) => {
                state.status = 'loaded'
                state.profileData = action?.payload?.data
            })
            .addCase(talentGetProfile.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(talentGetProfile.fulfilled, (state, action) => {
                state.status = 'loaded'
                state.profileData = action?.payload?.data
            })
            .addCase(updatedDataProfile.pending, (state) => {
                state.updatedDataStatus = 'loading'
            })
            .addCase(updatedDataProfile.fulfilled, (state, action) => {
                state.updatedDataStatus = 'loaded'
                state.selectedData = action?.payload.data?.result
            })
            .addCase(masterDataDropDownList.pending, (state) => {
                state.masterDataDropDownStatus = 'loading'
            })
            .addCase(masterDataDropDownList.fulfilled, (state, action) => {
                state.masterDataDropDownStatus = 'loaded'
                state.masterDataDropDown[state.modelType] =
                    action?.payload.data?.result?.length > 0
                        ? action.payload.data?.result.map((data) => {
                              return {
                                  label: data?.name,
                                  value: data?._id,
                                  logo: data?.logo,
                              }
                          })
                        : []
            })
    },
})

export default profileSlice.reducer

export const profileState = (state) => {
    const { profileSlice } = state
    const {
        search,
        profileModal,
        introductionVideoModal,
        videoPlayModal,
        status,
        profileData,
        formButtonType,
        selectedData,
        updatedDataStatus,
        defaultTab,
        videoRecordingSummary,
        retakeVideo,
        masterDataDropDown,
        masterDataDropDownStatus,
        modelType,
        deleteModalTitle,
    } = profileSlice
    return {
        search,
        profileModal,
        introductionVideoModal,
        videoPlayModal,
        status,
        profileData,
        formButtonType,
        selectedData,
        updatedDataStatus,
        defaultTab,
        videoRecordingSummary,
        retakeVideo,
        masterDataDropDown,
        masterDataDropDownStatus,
        modelType,
        deleteModalTitle,
    }
}

export const {
    switchProfileModal,
    switchIntroductionModal,
    switchVideoPlayModal,
    profileLoading,
    checkButtonType,
    changeTab,
    videoRecordSummary,
    retakeVideoChange,
    disableVideoSave,
    changeModel,
    setMasterDataDropDown,
    setDeleteModalTitle,
    clearProfileData,
} = profileSlice.actions
