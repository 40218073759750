import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import dayjs from 'dayjs'

import S3FileUpload from 'react-s3/lib/ReactS3'

import { Avatar, Button, Drawer, Input, message, Spin, Upload } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import JobApplicantInfoCard from './JobApplicantInfoCard'
import PDFImg from '../../../assets/images/pdf.png'
import { loginState } from '../../login/login.slice'
import { apiAddJobApplicationNote } from '../store/jobApplicant.service'
import {
    getJobApplicationNotes,
    jobApplicantState,
} from '../store/jobApplicant.slice'

const initialNoteState = {
    submitDescription: null,
    attachment: null,
    isSubmit: false,
    fileList: [],
}

const JobApplicantNotes = ({
    state,
    setState,
    JobApplicationState,
    handleUpdateJobApplicationStatus,
}) => {
    const params = useParams()
    const dispatch = useDispatch()

    const { userProfile } = useSelector(loginState)
    const jobApplicantData = useSelector(jobApplicantState)

    const [noteState, setNoteState] = useState(initialNoteState)

    const handleDownloadResume = async (data) => {
        const resume = data?.attachments

        if (resume?.url && resume?.name) {
            try {
                const response = await fetch(resume.url)
                if (!response.ok) {
                    throw new Error('Network response was not ok')
                }
                const blob = await response.blob()
                const url = window.URL.createObjectURL(blob)
                const a = document.createElement('a')
                a.href = url
                a.download = resume.name
                document.body.appendChild(a)
                a.click()
                document.body.removeChild(a)
                window.URL.revokeObjectURL(url)
            } catch (error) {
                console.error('Failed to download resume:', error)
            }
        } else {
            console.error('Resume URL or name is missing')
        }
    }

    const uploadS3File = (file, fileList) => {
        const nameChange = file?.name
            .split('.')[0]
            .replace(/\s+/g, '_')
            .concat(`_${dayjs(new Date()).unix()}`)
            .concat(`.${file?.name.split('.')[1]}`)

        const newFileData = new File([file?.originFileObj], nameChange, {
            type: file?.type,
        })

        window.Buffer = window.Buffer || require('buffer').Buffer

        setNoteState((prevState) => ({
            ...prevState,
            isSubmit: true,
        }))

        S3FileUpload.uploadFile(newFileData, userProfile?.s3Config)
            .then(async (data) => {
                setNoteState((prevState) => ({
                    ...prevState,
                    attachment: {
                        name: file?.name,
                        url: data?.location,
                        fileType: file?.type,
                    },
                    isSubmit: false,
                    fileList: fileList,
                }))
            })
            .catch(() => {
                setNoteState((prevState) => ({
                    ...prevState,
                    attachment: null,
                    isSubmit: false,
                }))
                message.error('Upload Failed!. Please Upload again')
            })
    }

    const props = {
        name: 'file',
        accept: '.pdf',
        beforeUpload: (file, fileList) => {
            uploadS3File(file, fileList)
            return false
        },
        maxCount: 1,
        onChange(info) {
            if (info.file.status !== 'uploading') {
                console.log('---uploaded')
            }
        },
        onRemove(file, fileList) {
            setNoteState((prevState) => ({
                ...prevState,
                attachment: null,
                fileList: [],
            }))
        },
    }

    const handleSubmitNotes = async () => {
        try {
            const data = {
                description: noteState?.submitDescription,
                attachments: noteState?.attachment,
                userId: state?.selectedApplicantData?._id,
                jobId: params?.id,
                createBy: userProfile?._id,
            }

            setNoteState((prevState) => ({
                ...prevState,
                isSubmit: true,
            }))

            const res = await apiAddJobApplicationNote(data)

            if (res?.data?.success) {
                message.success('Note submitted successfully')
                setNoteState(initialNoteState)
                dispatch(
                    getJobApplicationNotes({
                        userId: state?.selectedApplicantData?._id,
                    })
                )
            } else {
                message.error('Failed to submit note')
                setNoteState(initialNoteState)
            }
        } catch (error) {
            message.error('Failed to submit note')
            setNoteState(initialNoteState)
        }
    }

    useEffect(() => {
        if (state?.selectedApplicantData?._id) {
            dispatch(
                getJobApplicationNotes({
                    userId: state?.selectedApplicantData?._id,
                })
            )
        }
    }, [state?.selectedApplicantData?._id])

    return (
        <>
            <Drawer
                placement="right"
                closable={false}
                open={state?.jobApplicantNotesDrawer}
                onClose={() => {
                    setState((prevState) => ({
                        ...prevState,
                        jobApplicantNotesDrawer: false,
                    }))
                }}
                footer={null}
                title={null}
                style={{
                    height: '100vh',
                    minWidth: '1000px',
                    width: '1000px',
                }}
                styles={{
                    body: {
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                        padding: 0,
                    },
                    wrapper: {
                        width: 'auto !important',
                    },
                }}
                rootClassName="talentViewDrawer"
            >
                <div style={{ padding: '10px 30px' }}>
                    <JobApplicantInfoCard
                        state={state}
                        setState={setState}
                        handleUpdateJobApplicationStatus={
                            handleUpdateJobApplicationStatus
                        }
                        JobApplicationState={JobApplicationState}
                    />
                </div>

                <div
                    style={{
                        height: window.innerHeight - 200,
                        margin: '0 30px',
                        overflowY: 'auto',
                    }}
                >
                    {jobApplicantData?.notesLoading ? (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100%',
                            }}
                        >
                            <Spin />
                        </div>
                    ) : (
                        <div>
                            {jobApplicantData &&
                            jobApplicantData?.notesList?.length > 0 ? (
                                jobApplicantData?.notesList?.map((data) => (
                                    <>
                                        <div
                                            style={{
                                                display: 'flex',
                                                gap: '10px',
                                                alignItems: 'center',
                                                marginTop: '10px',
                                            }}
                                        >
                                            <span>
                                                {data?.createBy
                                                    ?.profilePicture ? (
                                                    <Avatar
                                                        src={
                                                            data?.createBy
                                                                ?.profilePicture
                                                        }
                                                    />
                                                ) : (
                                                    <Avatar>
                                                        {data?.createBy?.name
                                                            ? data?.createBy
                                                                  ?.name[0]
                                                            : ''}
                                                    </Avatar>
                                                )}
                                            </span>
                                            <span>{data?.createBy?.name}</span>
                                            <span>
                                                {data?.createdAt
                                                    ? dayjs(
                                                          data?.createdAt
                                                      ).format(
                                                          'DD-MM-YYYY hh:mm A'
                                                      )
                                                    : '-'}
                                            </span>
                                        </div>

                                        <div style={{ paddingLeft: '41px' }}>
                                            <div>{data?.description}</div>
                                        </div>

                                        {data?.attachments && (
                                            <div
                                                style={{
                                                    marginLeft: '41px',
                                                    width: '300px',
                                                    border: '1px solid #00000033',
                                                    padding: '10px',
                                                    marginTop: '10px',
                                                    borderRadius: '5px',
                                                    background: '#F4F6F8',
                                                    height: '60px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    cursor: 'pointer',
                                                }}
                                                onClick={() =>
                                                    handleDownloadResume(data)
                                                }
                                            >
                                                {data?.attachments?.fileType
                                                    ?.toLowerCase()
                                                    .includes('pdf') && (
                                                    <span>
                                                        <img
                                                            src={PDFImg}
                                                            alt="pdf"
                                                        />
                                                    </span>
                                                )}
                                                <span>
                                                    {data?.attachments?.name}
                                                </span>
                                            </div>
                                        )}
                                    </>
                                ))
                            ) : (
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        height: '100%',
                                    }}
                                >
                                    No notes found
                                </div>
                            )}
                        </div>
                    )}
                </div>

                <div
                    style={{
                        padding: '10px 30px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-end',
                        gap: '10px',
                    }}
                >
                    <Upload fileList={noteState.fileList} {...props}>
                        <span
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '5px',
                                color: '#1875F6',
                                cursor: 'pointer',
                            }}
                        >
                            <PlusOutlined /> Attachement
                        </span>
                    </Upload>
                    <Input.TextArea
                        value={noteState?.submitDescription}
                        onChange={(event) => {
                            setNoteState((prevState) => ({
                                ...prevState,
                                submitDescription: event.target.value,
                            }))
                        }}
                        placeholder="Write note."
                    />
                    <Button
                        onClick={handleSubmitNotes}
                        disabled={noteState?.isSubmit}
                        loading={noteState?.isSubmit}
                        style={{ height: '30px' }}
                    >
                        Add
                    </Button>
                </div>
            </Drawer>
        </>
    )
}

export default JobApplicantNotes
