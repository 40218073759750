import React from 'react'

function MobileIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            fill="none"
            viewBox="0 0 12 12"
        >
            <path
                fill="#212B36"
                d="M11.25 8.578v2.063c0 .306-.237.56-.542.582a9.236 9.236 0 01-.625.027A9.333 9.333 0 01.75 1.917c0-.161.009-.37.027-.625A.583.583 0 011.359.75h2.063c.15 0 .275.113.29.262a8.109 8.109 0 00.742 2.656.266.266 0 01-.086.331l-1.26.9A7.61 7.61 0 007.102 8.89L8 7.634a.27.27 0 01.335-.086 8.106 8.106 0 002.654.74c.149.015.262.14.262.29z"
            ></path>
        </svg>
    )
}

export default MobileIcon
