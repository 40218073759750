import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Mention, MentionsInput } from 'react-mentions'

// antd
import { Avatar, Image, Row, Tabs, Typography, message, theme } from 'antd'
import { UserOutlined } from '@ant-design/icons'

// slices
import { loginState } from '../login/login.slice'
import {
    addRemoveProjectComments,
    getProjectNote,
    notesState,
} from './notes.slice'

// components
import Button from '../../components/button/button'
import DeletePopUp from '../../components/deletePopUp/deletePopUp'

// helpers
import { deletePermissionCheck } from '../../helpers/permissionCheck'

// assets
import { BackIcon, BinIcon } from '../../assets/icons'

import dayjs from 'dayjs'
import { PRIVATE_ROUTES } from '../../routes'
import { SUPER_ADMIN, WORKSPACE_ADMIN } from '../../constants/roles'

const CommentSection = () => {
    const { Text, Title } = Typography

    const { useToken } = theme
    const { token } = useToken()

    const dispatch = useDispatch()
    const params = useParams()

    const navigate = useNavigate()

    const { userProfile } = useSelector(loginState)
    const { selectedNote } = useSelector(notesState)

    const initialState = {
        deleteModal: false,
        deleteButtonLoading: false,
        comment: '',
        addCommentLoader: false,
        filteredMembers: [],
    }

    const [state, setState] = useState(initialState)

    // DELETE COMMENT
    async function deleteComment() {
        const result = await dispatch(
            addRemoveProjectComments({
                id: selectedNote?._id,
                comment: {
                    fieldName: 'remove',
                    comment: {
                        name: state?.comment?.name,
                        time: new Date(state?.comment?.time).getTime(),
                        content: state?.comment?.content,
                    },
                },
            })
        )
        const data = result?.payload?.data
        if (data) {
            const { success, message: checkMessage } = data
            setState((prevState) => ({ ...prevState, comment: '' }))
            if (success) {
                message.success(checkMessage)
                dispatch(getProjectNote({ _id: params?.id }))
                setState((prevState) => ({
                    ...prevState,
                    deleteModal: false,
                    deleteButtonLoading: false,
                }))
            } else {
                setState((prevState) => ({
                    ...prevState,
                    deleteModal: false,
                    deleteButtonLoading: false,
                }))
                if (checkMessage) {
                    message.error(checkMessage)
                } else {
                    message.error('Something went wrong, try again later.')
                }
            }
        }
    }

    const CommentSectionChildren = () => {
        const [mentionData, setMentionData] = useState({
            name: 'React',
            value: '',
            mentionData: null,
            mentionDataList: [],
            comment: '',
            users: [],
        })

        // ADD COMMENT
        async function addComment() {
            setState((prevState) => ({ ...prevState, addCommentLoader: true }))
            const result = await dispatch(
                addRemoveProjectComments({
                    id: selectedNote?._id,
                    comment: {
                        fieldName: 'add',
                        comment: {
                            uId: userProfile?._id,
                            time: new Date().getTime(),
                            content: mentionData?.comment,
                            mentionId: mentionData?.mentionDataList,
                        },
                    },
                })
            )
            const data = result?.payload?.data
            if (data) {
                setMentionData({ ...mentionData, value: '' })
                const { success, message: checkMessage } = data
                if (success) {
                    message.success(checkMessage)
                    dispatch(getProjectNote({ _id: selectedNote?._id }))
                    setState((prevState) => ({
                        ...prevState,
                        addCommentLoader: false,
                    }))
                } else {
                    if (checkMessage) {
                        message.error(checkMessage)
                        setState((prevState) => ({
                            ...prevState,
                            addCommentLoader: false,
                        }))
                    } else {
                        message.error('Something went wrong, try again later.')
                        setState((prevState) => ({
                            ...prevState,
                            addCommentLoader: false,
                        }))
                    }
                }
            }
        }

        // HANDLE CHANGE MENTIONS DATA
        const handleChangeMentions = (
            _,
            newValue,
            newPlainTextValue,
            mentions
        ) => {
            const mentionNew =
                mentions?.length > 0 ? mentions.map((data) => data?.id) : []

            setMentionData({
                ...mentionData,
                value: newValue.trimStart(),
                comment: newPlainTextValue.trimStart(),
                mentionDataList: [...new Set(mentionNew)],
            })
        }

        return (
            <>
                {selectedNote?.comments?.length > 0 &&
                    selectedNote?.comments.map((data) => (
                        <Row
                            style={{
                                marginBottom: 10,
                                width: '100%',
                            }}
                            key={data?._id}
                        >
                            <Row
                                align={'middle'}
                                justify={'space-between'}
                                style={{
                                    width: '100%',
                                }}
                            >
                                <Row
                                    align={'middle'}
                                    style={{
                                        columnGap: '10px',
                                    }}
                                >
                                    {data?.uId?.profilePicture ? (
                                        <>
                                            <Image
                                                src={data?.uId?.profilePicture}
                                                height={36}
                                                width={36}
                                                style={{
                                                    borderRadius: '50%',
                                                }}
                                                preview={false}
                                            />
                                        </>
                                    ) : (
                                        <Avatar
                                            style={{
                                                backgroundColor: '#f56a00',
                                                cursor: 'pointer',
                                                marginRight: 10,
                                                height: '30px',
                                                width: '30px',
                                            }}
                                        >
                                            <Text
                                                style={{
                                                    fontSize: '12px',
                                                    color: '#fff',
                                                }}
                                            >
                                                {data?.name
                                                    ? data?.name[0].toUpperCase()
                                                    : `${data?.uId?.name[0].toUpperCase()}${
                                                          data?.uId?.name.split(
                                                              ' '
                                                          )[1] !== undefined
                                                              ? data?.uId?.name
                                                                    .split(
                                                                        ' '
                                                                    )[1][0]
                                                                    .toUpperCase()
                                                              : ''
                                                      }` ?? (
                                                          <UserOutlined
                                                              style={{
                                                                  fontSize:
                                                                      '16px',
                                                              }}
                                                          />
                                                      )}
                                            </Text>
                                        </Avatar>
                                    )}
                                    <Title level={5} style={{ margin: 0 }}>
                                        {data?.uId?.name}
                                    </Title>
                                    <Text style={{ color: '#a9a4a4' }}>
                                        {dayjs(data?.time).fromNow()}
                                    </Text>
                                </Row>
                                {(deletePermissionCheck('notes', userProfile) ||
                                    userProfile?._id === data?.uId?._id) &&
                                    data?.uId?.role?.roleName !==
                                        WORKSPACE_ADMIN &&
                                    data?.uId?.role?.roleName !==
                                        SUPER_ADMIN && (
                                        <div
                                            style={{
                                                cursor: 'pointer',
                                                height: '20px',
                                                width: '20px',
                                            }}
                                            onClick={() => {
                                                setState((prevState) => ({
                                                    ...prevState,
                                                    deleteModal: true,
                                                    comment: data,
                                                }))
                                            }}
                                        >
                                            <BinIcon
                                                color={
                                                    token.colorPalette.baseColor
                                                        .error
                                                }
                                            />
                                        </div>
                                    )}
                            </Row>
                            <Text style={{ marginTop: 10 }}>
                                {data?.content}
                            </Text>
                        </Row>
                    ))}
                <MentionsInput
                    value={mentionData?.value}
                    onChange={handleChangeMentions}
                    placeholder="Write comment. Try @mentioning people to get their attention..."
                    className="mentions"
                    style={{
                        minWidth: '100%',
                        marginTop: '20px',
                        // minHeight: '100%',
                    }}
                    rows={5}
                    allowSpaceInQuery={true}
                >
                    <Mention
                        type="user"
                        trigger="@"
                        rows={5}
                        cols={40}
                        displayTransform={(_, display) => {
                            return `@${display}`
                        }}
                        appendSpaceOnAdd={true}
                        data={state?.filteredMembers}
                        className="mentions__mention"
                    />
                </MentionsInput>

                <Row
                    justify={'end'}
                    style={{
                        columnGap: 10,
                        marginTop: 20,
                    }}
                >
                    <Button
                        props={{
                            text: 'Send',
                            loadingButton: state?.addCommentLoader,
                            onClick: () => addComment(),
                            disabled: mentionData?.comment?.length === 0,
                        }}
                    />
                </Row>
            </>
        )
    }

    const items = [
        {
            key: 'comments',
            label: 'Comments',
            children: <CommentSectionChildren />,
        },
    ]

    return (
        <div
            style={{
                padding: '25px 0px 25px 25px',
            }}
        >
            <Tabs
                items={items}
                defaultActiveKey="1"
                rootClassName="generalTabs"
                tabBarExtraContent={{
                    right: (
                        <div
                            style={{
                                height: '30px',
                                width: '30px',
                                cursor: 'pointer',
                            }}
                            onClick={() => navigate(PRIVATE_ROUTES.notes.root)}
                        >
                            <BackIcon />
                        </div>
                    ),
                }}
            />
            <DeletePopUp
                previewDelete={state?.deleteModal}
                onDelete={() => {
                    deleteComment()
                    setState((prevState) => ({
                        ...prevState,
                        deleteButtonLoading: true,
                    }))
                }}
                loadingButton={state?.deleteButtonLoading}
                onCancel={() => {
                    setState((prevState) => ({
                        ...prevState,
                        deleteModal: false,
                        deleteButtonLoading: false,
                    }))
                }}
                modalTitle="Delete Comment"
                pageKey="Comment"
            />
        </div>
    )
}

export default CommentSection
