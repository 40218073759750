import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

// antd
import {
    Col,
    Row,
    Typography,
    Avatar,
    Tooltip,
    Space,
    Popover,
    Select as NewSelect,
    message,
    Empty,
    theme,
    Drawer,
    Input,
} from 'antd'
import { UserOutlined } from '@ant-design/icons'

// slices
import { projectsState } from '../projects/projects.slice'
import {
    getSubTaskList,
    switchFormModalSubTask,
    taskById,
    taskCreate,
    taskList,
    taskUpdate,
    tasksState,
} from './tasks.slice'
import { loginState } from '../login/login.slice'

// components
import Button from '../../components/button/button'

// styles
import styles from './tasks.module.css'

// assets
import { EditIcon, SubTaskIcon } from '../../assets/icons'

// routes
import { PRIVATE_ROUTES } from '../../routes'

// sub Task Drawer
import SubTaskDrawerForm from './subTaskDrawerForm.index'

// helpers
import {
    addPermissionCheck,
    viewFieldCheck,
} from '../../helpers/permissionCheck'
import { formatTitle } from '../../helpers/formatTitle'

const SubTaskSection = ({
    assigneeList,
    epicsOption,
    taskTypeOption,
    sprintsOption,
    swimLanesOptionTask,
}) => {
    const { Text, Title } = Typography

    const { Option } = NewSelect

    const { useToken } = theme

    const { token } = useToken()

    const dispatch = useDispatch()

    const navigate = useNavigate()

    const { params } = useParams()

    const { currentProjectData } = useSelector(projectsState)
    const { pageLimit, skipPage, selectedTask, subTasksList, formSubTask } =
        useSelector(tasksState)
    const { userProfile } = useSelector(loginState)

    const [selected, setSelected] = useState({})
    const [swimLanesOption, setSwimLanesOption] = useState([])
    const [createChild, setCreateChild] = useState(false)
    const [subTaskLoadingButton, setSubTaskLoadingButton] = useState(false)
    const [titleValue, setTitleValue] = useState('')

    async function subTaskListGet() {
        await dispatch(
            getSubTaskList({
                skip: 0,
                limit: 10,
                parentId: selectedTask?._id,
            })
        )
    }

    // UPDATE TASK ASSIGNEE
    async function updateTaskAssignee(value, Id, name) {
        let assigneeIdValue = {
            assigneeId: value,
        }
        let statusValue = {
            status: value,
        }

        const result = await dispatch(
            taskUpdate({
                updateTask: name === 'status' ? assigneeIdValue : statusValue,
                _id: Id,
            })
        )
        const data = result?.payload?.data
        if (data) {
            const { success } = data
            if (success) {
                dispatch(subTaskListGet())
                dispatch(
                    await taskList({
                        limit: pageLimit,
                        skip: skipPage,
                        filter: {
                            projectId: JSON.parse(
                                localStorage.getItem('currentProject')
                            )?.id,
                            sprintId: JSON.parse(
                                localStorage.getItem('currentSprint')
                            )?.id,
                        },
                    })
                )
            } else {
                message.error('Something went wrong, try again later.')
            }
        }
    }

    // CREATE SUB TASK
    const createChildTask = async () => {
        setSubTaskLoadingButton(true)
        const result = await dispatch(
            taskCreate({
                title: titleValue,
                parent: selectedTask?._id,
                projectId: JSON.parse(localStorage.getItem('currentProject'))
                    ?.id,
                sprintId: selectedTask?.sprintId[0]?._id,
            })
        )
        const data = result?.payload?.data
        if (data) {
            const { success, message: checkMessage } = data
            if (success) {
                setSubTaskLoadingButton(false)
                subTaskListGet()
                setSelected()
                dispatch(
                    taskList({
                        limit: pageLimit,
                        skip: skipPage,
                        filter: {
                            projectId: JSON.parse(
                                localStorage.getItem('currentProject')
                            )?.id,
                            sprintId: JSON.parse(
                                localStorage.getItem('currentSprint')
                            )?.id,
                        },
                    })
                )
                message.success(checkMessage)
                setCreateChild(false)
            } else {
                setSubTaskLoadingButton(false)
                setSelected()
                setCreateChild(false)
                if (checkMessage) {
                    message.error(checkMessage)
                } else {
                    message.error('Something went wrong, try again later.')
                }
            }
        }
    }

    // VIEW SUB TASK
    async function viewSubTask(st) {
        dispatch(switchFormModalSubTask({ open: true }))
        const result = await dispatch(taskById({ _id: st?._id }))
        const data = result?.payload?.data
        if (data) {
            const { success } = data
            if (success) {
                setSelected(data?.data[0])
                // setSubTaskFormShow(true)
            } else {
                message.error('Something went wrong, try again later.')
            }
        }
    }

    // GET SWIMLANES
    useEffect(() => {
        if (currentProjectData?.swimlanes?.length > 0) {
            const options = currentProjectData?.swimlanes.map((data) => {
                return {
                    label: data?.name,
                    value: data?._id,
                }
            })
            setSwimLanesOption(options)
        }
        if (currentProjectData?.taskType?.length > 0) {
            const options = currentProjectData?.taskType.map((data) => {
                return {
                    label: data,
                    value: data,
                }
            })
            // setTaskTypeOption(options)
        }
    }, [currentProjectData])

    return (
        <>
            <div
                style={{
                    padding: 4,
                }}
            >
                <Row
                    justify={'space-between'}
                    align={'middle'}
                    style={{
                        marginTop: '26px',
                    }}
                >
                    <Row
                        style={{
                            columnGap: '10px',
                        }}
                    >
                        <SubTaskIcon />
                        <Title level={4}>Sub task</Title>
                    </Row>
                    {addPermissionCheck('tasks', userProfile) && (
                        <div
                            style={{
                                cursor: 'pointer',
                                height: '26px',
                                width: '26px',
                            }}
                            onClick={() => {
                                setCreateChild(true)
                            }}
                        >
                            <EditIcon />
                        </div>
                    )}
                </Row>
                {createChild && (
                    <Row
                        align={'middle'}
                        style={{
                            width: '100%',
                            marginTop: '24px',
                        }}
                        justify={'space-between'}
                        className={styles.subTaskList}
                    >
                        <Input
                            variant={'borderless'}
                            style={{
                                maxWidth: '50%',
                                paddingLeft: '0px',
                            }}
                            disabled={subTaskLoadingButton}
                            onChange={(e) => {
                                setTitleValue(e.target.value.trimStart())
                            }}
                            placeholder="Enter Title"
                        />
                        <Row
                            align={'middle'}
                            style={{
                                columnGap: '10px',
                            }}
                        >
                            <Button
                                props={{
                                    text: 'Cancel',
                                    buttonType: 'link',
                                    onClick: () => setCreateChild(false),
                                    style: {
                                        color: token.colorPalette.baseColor
                                            .black,
                                        padding: '0px',
                                    },
                                }}
                            />
                            <Button
                                props={{
                                    text: 'Save',
                                    onClick: () => {
                                        if (
                                            titleValue?.length > 0 &&
                                            titleValue?.length <= 400
                                        ) {
                                            createChildTask()
                                        } else if (titleValue?.length > 400) {
                                            message.error(
                                                'SubTask Title should be maximum 400 characters long'
                                            )
                                        } else {
                                            message.error(
                                                'SubTask Title should be required'
                                            )
                                        }
                                    },
                                    loadingButton: subTaskLoadingButton,
                                    style: {
                                        padding: '2px 23px',
                                    },
                                }}
                            />
                        </Row>
                    </Row>
                )}
                {viewFieldCheck('tasks', 'subTask', userProfile) &&
                subTasksList?.length > 0 ? (
                    // !subTaskFormShow &&
                    <div
                        style={{
                            marginTop: createChild ? '15px' : '26px',
                        }}
                    >
                        {subTasksList.map((st) => (
                            <Row
                                align={'middle'}
                                justify={'space-between'}
                                className={styles.subTaskList}
                                style={{
                                    flexFlow: 'row nowrap',
                                }}
                            >
                                <Col>
                                    <Row
                                        gutter={12}
                                        align={'middle'}
                                        style={{
                                            flexFlow: 'row nowrap',
                                        }}
                                    >
                                        <Col>
                                            <Text
                                                // className={styles.keySubTask}
                                                style={{
                                                    color: '#4BAEE8',
                                                    cursor: 'pointer',
                                                }}
                                                onClick={() => {
                                                    viewSubTask(st)
                                                }}
                                            >
                                                {st?.taskKey}
                                            </Text>
                                        </Col>
                                        <Col>
                                            <div
                                                style={{
                                                    fontSize: 14,
                                                    fontWeight: 500,
                                                    wordBreak: 'break-word',
                                                }}
                                            >
                                                {formatTitle(st?.title, 20)}
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col>
                                    <Row
                                        gutter={12}
                                        align={'middle'}
                                        style={{
                                            flexFlow: 'row nowrap',
                                        }}
                                    >
                                        <Col>
                                            <NewSelect
                                                placeholder="Select a Status"
                                                onChange={(event) => {
                                                    if (event !== st?.status) {
                                                        updateTaskAssignee(
                                                            event,
                                                            st?._id
                                                        )
                                                    }
                                                }}
                                                className={[
                                                    styles.statusSelect,
                                                    'arrowStatusSelectSubTask',
                                                ]}
                                                defaultValue={st?.status}
                                                style={{
                                                    padding: '0px 0px',
                                                }}
                                                showArrow={false}
                                            >
                                                {swimLanesOption?.length > 0 &&
                                                    swimLanesOption.map(
                                                        (data) => (
                                                            <Option
                                                                value={
                                                                    data?.label
                                                                }
                                                                key={
                                                                    data?.label
                                                                }
                                                            >
                                                                {data?.label}
                                                            </Option>
                                                        )
                                                    )}
                                            </NewSelect>
                                        </Col>
                                        <Col>
                                            {st?.assigneeId ? (
                                                <Popover
                                                    className={
                                                        styles.popOverOut
                                                    }
                                                    overlayInnerStyle={{
                                                        padding: 5,
                                                    }}
                                                    content={
                                                        <>
                                                            {assigneeList
                                                                .filter(
                                                                    (data) =>
                                                                        data?.value !==
                                                                        st
                                                                            ?.assigneeId[0]
                                                                            ?._id
                                                                )
                                                                .map(
                                                                    (
                                                                        option
                                                                    ) => {
                                                                        return (
                                                                            <Row
                                                                                className={
                                                                                    styles.popOverClick
                                                                                }
                                                                                style={{
                                                                                    cursor: 'pointer',
                                                                                    padding: 10,
                                                                                    borderRadius: 5,
                                                                                }}
                                                                                onClick={() => {
                                                                                    updateTaskAssignee(
                                                                                        option?.value,
                                                                                        st?._id,
                                                                                        'status'
                                                                                    )
                                                                                }}
                                                                            >
                                                                                <Space size="small">
                                                                                    <Avatar
                                                                                        style={{
                                                                                            backgroundColor:
                                                                                                '#f56a00',
                                                                                            cursor: 'pointer',
                                                                                            height: '30px',
                                                                                            width: '30px',
                                                                                        }}
                                                                                    >
                                                                                        {option?.label[0].toUpperCase()}
                                                                                    </Avatar>
                                                                                    <Text
                                                                                        style={{
                                                                                            margin: 0,
                                                                                        }}
                                                                                    >
                                                                                        {
                                                                                            option?.label
                                                                                        }
                                                                                    </Text>
                                                                                </Space>
                                                                            </Row>
                                                                        )
                                                                    }
                                                                )}
                                                        </>
                                                    }
                                                    trigger="click"
                                                    arrow={false}
                                                    placement="bottom"
                                                >
                                                    <Tooltip
                                                        title={
                                                            st?.assigneeId
                                                                ?.length > 0
                                                                ? st
                                                                      ?.assigneeId[0]
                                                                      ?.name
                                                                : 'Unassigned'
                                                        }
                                                    >
                                                        {st?.assigneeId
                                                            ?.length > 0 ? (
                                                            <Avatar
                                                                size={'small'}
                                                                style={{
                                                                    backgroundColor:
                                                                        '#f56a00',
                                                                    cursor: 'pointer',
                                                                    fontSize:
                                                                        '11px',
                                                                }}
                                                            >
                                                                {st?.assigneeId[0]?.name[0].toUpperCase()}
                                                                {st?.assigneeId[0]?.name.split(
                                                                    ' '
                                                                )[1] !==
                                                                    undefined &&
                                                                    st?.assigneeId[0]?.name
                                                                        .split(
                                                                            ' '
                                                                        )[1][0]
                                                                        .toUpperCase()}
                                                            </Avatar>
                                                        ) : (
                                                            <Avatar
                                                                size={'small'}
                                                                icon={
                                                                    <UserOutlined />
                                                                }
                                                            />
                                                        )}
                                                    </Tooltip>
                                                </Popover>
                                            ) : (
                                                <Tooltip title={'Unassigned'}>
                                                    <Avatar
                                                        size={'default'}
                                                        icon={<UserOutlined />}
                                                    />
                                                </Tooltip>
                                            )}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        ))}
                    </div>
                ) : (
                    <Empty description="No Subtask Found" />
                )}
            </div>
            <Drawer
                placement="right"
                size={'large'}
                style={{
                    height: '100vh',
                    minWidth: '1000px',
                }}
                styles={{
                    body: {
                        padding: '0px',
                    },
                }}
                rootClassName="taskHeader"
                onClose={() => {
                    dispatch(switchFormModalSubTask({ open: false }))
                    if (params?.search.includes('taskId')) {
                        navigate(PRIVATE_ROUTES.tasks)
                    }
                }}
                open={formSubTask}
            >
                <SubTaskDrawerForm
                    onCancel={() => {
                        setSelected()
                        // setSubTaskFormShow(false)
                    }}
                    onCreateChild={() => {
                        setSelected()
                        // subTaskListGet()
                        // setSubTaskFormShow(false)
                    }}
                    // parentValues={values}
                    // selectedSubTask={selected}
                    // subTaskFormShow={subTaskFormShow}
                    assigneeList={assigneeList}
                    swimLanes={swimLanesOptionTask}
                    taskType={taskTypeOption}
                    sprints={sprintsOption}
                    epics={epicsOption}
                />
            </Drawer>
        </>
    )
}

export default SubTaskSection
