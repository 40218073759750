import { useRef, useEffect } from "react";

const useGooglePlace = (inputValue, setState) => {
    const timeoutRef = useRef();
    const sessionTokenRef = useRef();

    useEffect(() => {
        clearTimeout(timeoutRef.current);

        if (!inputValue || inputValue.trim().length <= 1) {
            setState([]);
            return;
        }

        timeoutRef.current = setTimeout(async () => {
            if (!sessionTokenRef.current) {
                sessionTokenRef.current =
                    new window.google.maps.places.AutocompleteSessionToken();
            }

            new window.google.maps.places.AutocompleteService().getPlacePredictions(
                {
                    input: inputValue,
                    sessionToken: sessionTokenRef.current,
                },
                (predictions, status) => {
                    if (
                        status ===
                        window.google.maps.places.PlacesServiceStatus
                            .ZERO_RESULTS
                    ) {
                        setState([]);
                        return;
                    }
                    if (
                        status !==
                        window.google.maps.places.PlacesServiceStatus.OK ||
                        !predictions
                    ) {
                        return;
                    }
                    const newData =
                        predictions?.length > 0 &&
                        predictions.map((data) => {
                            return {
                                label: data?.description,
                                value: data?.description,
                            };
                        });
                    setState(newData);
                }
            );
        }, 350);
    }, [inputValue, setState]);
};

export default useGooglePlace;