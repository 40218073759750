import React from 'react'

function InstituteDefaultIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 75 88"
        >
            <g fill="#4F46E5" opacity="0.6">
                <path d="M0 40H52V88H0z" opacity="0.3"></path>
                <path d="M23 0H75V88H23z" opacity="0.3"></path>
            </g>
        </svg>
    )
}

export default InstituteDefaultIcon
