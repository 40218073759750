import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// antd
import { Col, Form, InputNumber, Row, Typography, message, theme } from 'antd'

// components
import Button from '../../components/button/button'

// slices
import {
    talentListGet,
    talentState,
    totalTalent,
    updateTalent,
} from './talent.slice'
import { TALENT_FILTER_STATUS } from '../../constants/general'

const AddRates = ({ onCancel }) => {
    const { Title } = Typography

    const { useToken } = theme

    const { token } = useToken()

    const [form] = Form.useForm()

    const dispatch = useDispatch()

    const { skipPage, pageLimit, selectedData, filter } =
        useSelector(talentState)

    const [loadingButton, setLoadingButton] = useState(false)

    const onFinishFailed = () => {}

    useEffect(() => {
        if (selectedData) {
            form.setFieldsValue({
                price: selectedData?.price,
                dailyWorkingHours: selectedData?.dailyWorkingHours,
                weeklyWorkingDays: selectedData?.weeklyWorkingDays,
            })
        }
    }, [selectedData, form])

    // ON FINISH ADD RATES
    const onFinishAddRates = async (value) => {
        setLoadingButton(true)

        const result = await dispatch(
            updateTalent({
                _id: selectedData?._id,
                updatedData: {
                    data: {
                        ...value,
                    },
                },
            })
        )

        const data = result?.payload?.data
        if (data) {
            const { success, message: errorMessage } = data
            if (success) {
                setLoadingButton(false)
                onCancel()
                dispatch(
                    await totalTalent({
                        filter: { ...filter, status: TALENT_FILTER_STATUS },
                    })
                )
                dispatch(
                    await talentListGet({
                        skip: skipPage,
                        limit: pageLimit,
                        filter: { ...filter, status: TALENT_FILTER_STATUS },
                    })
                )
                message.success(errorMessage)
            } else {
                setLoadingButton(false)
                onCancel()
                if (errorMessage) {
                    message.error(errorMessage)
                } else {
                    message.error('Something went wrong, try again later.')
                }
            }
        }
    }

    return (
        <>
            <Row
                align={'middle'}
                justify={'center'}
                style={{
                    marginBottom: '27px',
                }}
            >
                <Title level={4} style={{ margin: 0 }}>
                    Add Rates
                </Title>
                <Form
                    labelCol={{
                        style: {
                            padding: '0 0 3px',
                        },
                    }}
                    layout="vertical"
                    name="addRatesForm"
                    onFinish={onFinishAddRates}
                    onFinishFailed={onFinishFailed}
                    requiredMark={false}
                    form={form}
                    style={{
                        overflowX: 'hidden',
                        marginTop: '20px',
                    }}
                >
                    <Row
                        gutter={30}
                        style={{
                            rowGap: '15px',
                        }}
                    >
                        <Col span={24}>
                            <Form.Item
                                label="Cost (per Hour) *"
                                name="price"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Enter Talent Cost',
                                    },
                                ]}
                                colon={false}
                            >
                                <InputNumber
                                    placeholder={'Enter Talent Cost'}
                                    name={'price'}
                                    min={1}
                                    style={{
                                        width: '100%',
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Title level={5}>
                                Daily Working Hours (e.g. 1 Day = 8 Hours)
                            </Title>
                            <Form.Item
                                name="dailyWorkingHours"
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            'Please Enter Daily Working Hours',
                                    },
                                ]}
                            >
                                <InputNumber
                                    min={1}
                                    max={24}
                                    style={{
                                        width: '100%',
                                        marginTop: '6px',
                                    }}
                                    onKeyDown={(event) =>
                                        event.key === '.' &&
                                        event.preventDefault()
                                    }
                                    onInput={(event) =>
                                        (event = event.replace(/[^0-9]*/g, ''))
                                    }
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Title level={5}>
                                Weekly Working Days (e.g. 1 Week = 5 Days)
                            </Title>

                            <Form.Item
                                name="weeklyWorkingDays"
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            'Please Enter Weekly Working Days',
                                    },
                                ]}
                            >
                                <InputNumber
                                    min={1}
                                    max={7}
                                    style={{
                                        width: '100%',
                                        marginTop: '6px',
                                    }}
                                    onKeyDown={(event) =>
                                        event.key === '.' &&
                                        event.preventDefault()
                                    }
                                    onInput={(event) =>
                                        (event = event.replace(/[^0-9]*/g, ''))
                                    }
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row
                        gutter={12}
                        style={{
                            float: 'right',
                            marginTop: '35px',
                        }}
                    >
                        <Col span={24}>
                            <Button
                                props={{
                                    text: 'Cancel',
                                    onClick: () => {
                                        onCancel()
                                    },
                                    buttonType: 'link',
                                    style: {
                                        color: token.colorPalette.baseColor
                                            .black,
                                        fontWeight: token.fontWeightStrong,
                                    },
                                }}
                            />
                            <Button
                                props={{
                                    text: 'Add Rates',
                                    htmlType: 'submit',
                                    disabled: loadingButton,
                                    loadingButton: loadingButton,
                                }}
                            />
                        </Col>
                    </Row>
                </Form>
            </Row>
        </>
    )
}

export default AddRates
