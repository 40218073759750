import React from 'react'

function SprintsIcon(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="23"
            height="22"
            fill="none"
            viewBox="0 0 23 22"
            {...props}
        >
            <g fill="#fff" clipPath="url(#clip0_404_13436)">
                <path d="M0 19.738c.158-.402.457-.53.877-.53 3.059.011 6.117 0 9.176.008 1.477.003 2.891-.27 4.203-.967 2.61-1.387 4.268-3.529 4.725-6.468.583-3.748-.763-6.732-3.83-8.93-.049-.034-.1-.06-.189-.118.027.118.045.194.06.272a.7.7 0 01-.469.808.657.657 0 01-.835-.44 47.611 47.611 0 01-.525-1.962.646.646 0 01.45-.772A38.78 38.78 0 0115.57.116a.663.663 0 01.713.264.676.676 0 01.107.247c.083.348-.127.696-.491.813l-.243.078c.07.056.118.097.17.133 2.683 1.805 4.24 4.321 4.61 7.546.592 5.157-2.914 10.047-7.946 11.142-.794.168-1.602.251-2.413.247-3.067 0-6.13-.003-9.198.006-.418 0-.719-.133-.877-.534L0 19.738z"></path>
                <path d="M0 9.626c.06-.399.1-.8.174-1.195.47-2.523 1.712-4.584 3.69-6.192A9.883 9.883 0 018.625.124c.514-.082 1.04-.1 1.56-.122a.664.664 0 01.654.382c.038.082.06.17.063.26.022.382-.256.677-.667.723-.683.077-1.382.093-2.044.257C5.25 2.35 3.12 4.13 2.025 6.96c-1.37 3.545-.689 6.773 1.881 9.579.198.216.37.428.304.744a.645.645 0 01-.461.519.66.66 0 01-.692-.171 10.223 10.223 0 01-3.02-6.501 1.19 1.19 0 00-.039-.177l.001-1.328z"></path>
                <path d="M10.276 16.461a6.118 6.118 0 01-4.332-1.8 6.204 6.204 0 01-1.81-4.354 6.204 6.204 0 011.79-4.374 6.118 6.118 0 014.338-1.819c1.624 0 3.182.647 4.333 1.8a6.202 6.202 0 011.344 6.722 6.181 6.181 0 01-1.327 2.008 6.134 6.134 0 01-1.989 1.343 6.098 6.098 0 01-2.347.474zm-.706-5.127c-.297-.31-.565-.611-.854-.887a.708.708 0 00-1.002.014.707.707 0 00-.164.776.708.708 0 00.152.233c.457.477.922.945 1.396 1.406.254.244.668.288.91.047.972-.95 1.93-1.915 2.875-2.89.205-.212.204-.504.063-.771-.14-.266-.357-.383-.649-.383-.237 0-.412.118-.573.282-.701.715-1.405 1.424-2.154 2.175v-.002zM20.883 20.586h-2.37c-.183 0-.365.007-.547-.005-.41-.026-.687-.323-.672-.71.014-.379.297-.652.703-.655.862-.007 1.724 0 2.586 0h.297c-.095-.106-.155-.17-.21-.236a.68.68 0 01.02-.931.665.665 0 01.925-.04c.498.466.987.943 1.467 1.43a.652.652 0 01.138.717.653.653 0 01-.144.212c-.469.486-.945.965-1.428 1.437a.673.673 0 01-.927.022c-.272-.25-.303-.645-.067-.948.06-.085.127-.165.229-.293z"></path>
            </g>
            <defs>
                <clipPath id="clip0_404_13436">
                    <path fill="#fff" d="M0 0H23.269V22H0z"></path>
                </clipPath>
            </defs>
        </svg>
    )
}

export default SprintsIcon
