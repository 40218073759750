import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
    addRemoveOfferToHire,
    addRemoveRoleAttachments,
    addRemoveTypeTalent,
    countInquiryRole,
    createInquiryRole,
    getInquiryRole,
    getTalent,
    listInquiryRoles,
    listTypeTalent,
    matchTalentData,
    updateInquiryRole,
    updateOfferToHire,
} from './rolesInquiry.service'

const initialState = {
    search: '',
    status: 'loaded',
    updatedDataStatus: 'loaded',
    talentStatus: 'loaded',
    attachmentGetStatus: 'loaded',
    formButtonType: 'saveButtonLoading',
    selectedRole: undefined,
    selectedRoleDetails: undefined,
    selectedAttachment: undefined,
    roleModalVariations: {
        open: false,
        clientModal: false,
    },
    roleDetailView: false,
    talentView: false,
    createRoleId: undefined,
    rolesList: [],
    inquiryCount: 0,
    isNext: false,
    pageLimit: 10,
    currentPage: 1,
    skipPage: 0,
    talentList: [],
    talentCount: 0,
    isNextTalent: false,
    pageLimitTalent: 10,
    currentPageTalent: 1,
    skipPageTalent: 0,
    filter: {
        isOurUser: '',
        isActive: '',
    },
    stepRoleData: undefined,
    defaultStep: 1,
}

export const rolesInquiryList = createAsyncThunk(
    'rolesInquirySlice/listInquiryRoles',
    async (payload = {}) => {
        const response = await listInquiryRoles(payload)
        return response
    }
)

export const roleInquiryCreate = createAsyncThunk(
    'rolesInquirySlice/createInquiryRole',
    async (payload = {}) => {
        const response = await createInquiryRole(payload)
        return response
    }
)

export const roleInquiryCount = createAsyncThunk(
    'rolesInquirySlice/countInquiryRole',
    async (payload = {}) => {
        const response = await countInquiryRole(payload)
        return response
    }
)

export const roleInquiryGet = createAsyncThunk(
    'rolesInquirySlice/getInquiryRole',
    async (payload = {}) => {
        const response = await getInquiryRole(payload)
        return response
    }
)

export const roleInquiryUpdate = createAsyncThunk(
    'rolesInquirySlice/updateInquiryRole',
    async (payload = {}) => {
        const response = await updateInquiryRole(payload)
        return response
    }
)

export const talentListType = createAsyncThunk(
    'rolesInquirySlice/listTypeTalent',
    async (payload = {}) => {
        const response = await listTypeTalent(payload)
        return response
    }
)

export const talentAddRemoveType = createAsyncThunk(
    'rolesInquirySlice/addRemoveTypeTalent',
    async (payload = {}) => {
        const response = await addRemoveTypeTalent(payload)
        return response
    }
)

export const offerToHireAddRemove = createAsyncThunk(
    'rolesInquirySlice/addRemoveOfferToHire',
    async (payload = {}) => {
        const response = await addRemoveOfferToHire(payload)
        return response
    }
)

export const offerToHireUpdate = createAsyncThunk(
    'rolesInquirySlice/updateOfferToHire',
    async (payload = {}) => {
        const response = await updateOfferToHire(payload)
        return response
    }
)

export const talentGet = createAsyncThunk(
    'rolesInquirySlice/getTalent',
    async (payload = {}) => {
        const response = await getTalent(payload)
        return response
    }
)

export const talentDataMatch = createAsyncThunk(
    'rolesInquirySlice/matchTalentData',
    async (payload = {}) => {
        const response = await matchTalentData(payload)
        return response
    }
)

export const roleAttachmentsAddRemove = createAsyncThunk(
    'rolesInquirySlice/addRemoveRoleAttachments',
    async (payload = {}) => {
        const response = await addRemoveRoleAttachments(payload)
        return response
    }
)

export const rolesInquirySlice = createSlice({
    name: 'rolesInquirySlice',
    initialState,
    reducers: {
        switchRoleModal: (state, action) => {
            state.roleModalVariations = action.payload
            state.selectedRole = undefined
        },
        changePage: (state, action) => {
            state.currentPage = action.payload.currentPage
            state.skipPage = action.payload.skip
            state.pageLimit = action.payload.pageLimit
        },
        setCreateRoleId: (state, action) => {
            state.createRoleId = action.payload
        },
        changeRoleStep: (state, action) => {
            state.defaultStep = action.payload
        },
        roleStepData: (state, action) => {
            state.stepRoleData = action.payload
        },
        changeRoleView: (state, action) => {
            state.roleDetailView = action.payload
        },
        talentViewShow: (state, action) => {
            state.talentView = action.payload
        },
        filterChange: (state, action) => {
            const dropDownValue = {
                ...state.filter,
                ...action.payload,
            }
            Object.keys(dropDownValue).forEach((key) => {
                if (dropDownValue[key] === '') {
                    delete dropDownValue[key]
                }
            })
            state.filter = dropDownValue
        },
        clearFilter: (state) => {
            state.filter = {}
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(roleInquiryGet.pending, (state) => {
                state.updatedDataStatus = 'loading'
            })
            .addCase(roleInquiryGet.fulfilled, (state, action) => {
                state.updatedDataStatus = 'loaded'
                state.selectedRole = action?.payload.data
                state.selectedRoleDetails = action?.payload.data
            })
            .addCase(rolesInquiryList.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(rolesInquiryList.fulfilled, (state, action) => {
                state.status = 'loaded'
                state.rolesList = action?.payload.data
            })
            .addCase(talentDataMatch.pending, (state) => {
                state.talentStatus = 'loading'
            })
            .addCase(talentDataMatch.fulfilled, (state, action) => {
                state.talentStatus = 'loaded'
                state.talentList = action?.payload.data
            })
            .addCase(talentListType.pending, (state) => {
                state.talentStatus = 'loading'
            })
            .addCase(talentListType.fulfilled, (state, action) => {
                state.talentStatus = 'loaded'
                state.talentList = action?.payload.data
            })
            .addCase(roleInquiryCount.fulfilled, (state, action) => {
                state.inquiryCount = action.payload.data
            })
    },
})

export default rolesInquirySlice.reducer

export const rolesInquiryState = (state) => {
    const { rolesInquirySlice } = state
    const {
        search,
        status,
        updatedDataStatus,
        formButtonType,
        selectedRole,
        selectedRoleDetails,
        roleModalVariations,
        rolesList,
        dataCount,
        isNext,
        pageLimit,
        currentPage,
        skipPage,
        filter,
        talentList,
        talentCount,
        isNextTalent,
        pageLimitTalent,
        currentPageTalent,
        skipPageTalent,
        talentStatus,
        attachmentGetStatus,
        selectedAttachment,
        roleDetailView,
        stepRoleData,
        defaultStep,
        createRoleId,
        talentView,
    } = rolesInquirySlice
    return {
        search,
        status,
        updatedDataStatus,
        formButtonType,
        selectedRole,
        selectedRoleDetails,
        roleModalVariations,
        rolesList,
        dataCount,
        isNext,
        pageLimit,
        currentPage,
        skipPage,
        filter,
        talentList,
        talentCount,
        isNextTalent,
        pageLimitTalent,
        currentPageTalent,
        skipPageTalent,
        talentStatus,
        attachmentGetStatus,
        selectedAttachment,
        roleDetailView,
        stepRoleData,
        defaultStep,
        createRoleId,
        talentView,
    }
}

export const {
    changePage,
    switchRoleModal,
    clearFilter,
    filterChange,
    getTalentDetails,
    changeRoleView,
    roleStepData,
    changeRoleStep,
    setCreateRoleId,
    talentViewShow,
} = rolesInquirySlice.actions
