import React from 'react'

function ViewIcon({ color }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            fill="none"
            viewBox="0 0 22 22"
        >
            <path
                fill={color ? color : '#000'}
                d="M19.48 8.388C17.362 5.06 14.264 3.145 11 3.145c-1.631 0-3.217.476-4.665 1.365-1.449.899-2.75 2.21-3.814 3.878-.916 1.439-.916 3.777 0 5.216 2.118 3.336 5.216 5.243 8.48 5.243 1.631 0 3.217-.477 4.665-1.366 1.449-.898 2.75-2.209 3.814-3.877.916-1.43.916-3.777 0-5.216zM11 14.704A3.699 3.699 0 017.298 11a3.699 3.699 0 013.704-3.703A3.699 3.699 0 0114.704 11a3.699 3.699 0 01-3.703 3.704z"
            ></path>
            <path
                fill={color ? color : '#000'}
                d="M10.998 8.379a2.617 2.617 0 100 5.234 2.625 2.625 0 002.622-2.612 2.633 2.633 0 00-2.622-2.622z"
            ></path>
        </svg>
    )
}

export default ViewIcon
