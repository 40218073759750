import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Drawer, Modal, Typography, message } from 'antd'
import Dragger from 'antd/es/upload/Dragger'
import useToken from 'antd/es/theme/useToken'

import dayjs from 'dayjs'
import S3FileUpload from 'react-s3/lib/ReactS3'

import DownloadFileIcon from '../../../assets/icons/files/downloadFileIcon'
import { loginState } from '../../login/login.slice'
import Button from '../../../components/button/button'
import { apiUpdateJobApplicationStatus } from '../store/jobApplicant.service'
import { profileState, updateUserGet } from '../../profile/profile.slice'

const { Text } = Typography

const initialResumeState = {
    newResume: null,
    isReplaceResume: false,
    isUploading: false,
    oldResume: null,
}

const JobApplicantResume = ({ state, setState }) => {
    const [resumeState, setResumeState] = useState(initialResumeState)

    const dispatch = useDispatch()
    const { token } = useToken()

    const { userProfile } = useSelector(loginState)
    const { profileData: jobApplicantData } = useSelector(profileState)

    // ONCHANGE FILE PROPS
    const props = {
        name: 'fileLink',
        multiple: false,
        onChange(info) {
            const nameChange = info?.fileList[0]?.name
                .split('.')[0]
                .concat(`_${dayjs(new Date()).unix()}`)
                .concat(`.${info?.fileList[0]?.name.split('.')[1]}`)

            const newFileData = new File(
                [info?.fileList[0]?.originFileObj],
                nameChange,
                { type: info?.fileList[0]?.type }
            )
            setResumeState((prevState) => ({
                ...prevState,
                isUploading: true,
            }))
            window.Buffer = window.Buffer || require('buffer').Buffer

            S3FileUpload.uploadFile(newFileData, userProfile?.s3Config)
                .then(async (data) => {
                    setResumeState((prevState) => ({
                        ...prevState,
                        newResume: {
                            name: info?.fileList[0]?.name,
                            url: data?.location,
                            date: new Date().getTime(),
                        },
                        isUploading: false,
                    }))
                })
                .catch(() => {
                    setResumeState((prevState) => ({
                        ...prevState,
                        isUploading: true,
                    }))
                    message.error('Upload Failed!. Please Upload again')
                })
        },

        beforeUpload() {
            return false
        },

        onDrop() {},
    }

    const handleDownloadResume = async () => {
        const resume = jobApplicantData?.data?.resume

        if (resume?.url && resume?.name) {
            try {
                const response = await fetch(resume.url)
                if (!response.ok) {
                    throw new Error('Network response was not ok')
                }
                const blob = await response.blob()
                const url = window.URL.createObjectURL(blob)
                const a = document.createElement('a')
                a.href = url
                a.download = resume.name
                document.body.appendChild(a)
                a.click()
                document.body.removeChild(a)
                window.URL.revokeObjectURL(url)
            } catch (error) {
                console.error('Failed to download resume:', error)
            }
        } else {
            console.error('Resume URL or name is missing')
        }
    }

    const replaceResume = () => {
        setResumeState((prevState) => ({
            ...prevState,
            isReplaceResume: true,
        }))
    }

    const uploadNewResume = async () => {
        try {
            const updateRes = await apiUpdateJobApplicationStatus(
                state?.selectedApplicantData?._id,
                { resume: resumeState?.newResume }
            )

            if (updateRes?.data?.success) {
                setResumeState(initialResumeState)
                setState((prevState) => ({
                    ...prevState,
                    jobApplicantResumeDrawer: false,
                }))
                dispatch(
                    updateUserGet({
                        _id: state?.selectedApplicantData?._id,
                    })
                )
                message.success('Resume replaced successfully')
            } else {
                message.error('Failed to replace resume')
            }
        } catch (error) {
            message.error('Failed to replace resume', error)
            console.error('Failed to replace resume:', error)
        }
    }

    useEffect(() => {
        if (state?.jobApplicantResumeDrawer) {
            dispatch(
                updateUserGet({
                    _id: state?.selectedApplicantData?._id,
                })
            )
        }
    }, [state?.jobApplicantResumeDrawer])

    return (
        <>
            <Drawer
                placement="right"
                closable={false}
                open={state?.jobApplicantResumeDrawer}
                onClose={() => {
                    setState((prevState) => ({
                        ...prevState,
                        jobApplicantResumeDrawer: false,
                    }))
                }}
                footer={null}
                title={null}
                style={{
                    height: '100vh',
                    minWidth: '1000px',
                    width: '1000px',
                }}
                styles={{
                    body: {
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                        padding: 0,
                        background: '#EBECED',
                    },
                    wrapper: {
                        width: 'auto !important',
                    },
                }}
                rootClassName="talentViewDrawer"
            >
                <div>
                    <div
                        style={{
                            padding: '20px 30px',
                            background: '#fff',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '10px',
                                justifyContent: 'space-between',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '10px',
                                }}
                            >
                                <h4>
                                    {jobApplicantData?.data?.resume?.name ||
                                        '-'}
                                </h4>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    gap: '20px',
                                    alignItems: 'center',
                                }}
                            >
                                <span
                                    style={{
                                        background: '#4F46E5',
                                        fontSize: '16px',
                                        padding: '10px 20px',
                                        color: '#fff',
                                        borderRadius: '5px',
                                        cursor: 'pointer',
                                    }}
                                    onClick={replaceResume}
                                >
                                    + Replace New Resume
                                </span>
                                <span
                                    style={{ cursor: 'pointer' }}
                                    onClick={handleDownloadResume}
                                >
                                    <DownloadFileIcon />
                                </span>
                            </div>
                        </div>
                        <div style={{ color: '#919EAB', fontSize: '16px' }}>
                            <span>
                                {jobApplicantData?.data?.resume?.date
                                    ? dayjs(
                                          jobApplicantData?.data?.resume?.date
                                      )?.format('DD-MM-YYYY hh:mm A')
                                    : '-'}
                            </span>
                        </div>
                    </div>

                    <div style={{ padding: '20px 30px' }}>
                        <iframe
                            src={jobApplicantData?.data?.resume?.url || '-'}
                            height={window.innerHeight - 200}
                            width="100%"
                            title="PDF"
                        />
                    </div>
                </div>
            </Drawer>

            <Modal
                open={resumeState?.isReplaceResume}
                onClose={() => setResumeState(initialResumeState)}
                footer={null}
                closable={false}
            >
                {!resumeState?.newResume && !resumeState?.newResume?.url && (
                    <Dragger
                        disabled={resumeState?.isUploading}
                        {...props}
                        fileList={[]}
                        previewFile={false}
                        style={{
                            border: `1px dashed ${
                                token && token?.colorPalette
                                    ? token.colorPalette.textColor.quaternary
                                    : '#747474'
                            }`,
                            padding: '20px',
                            height: '185px',
                        }}
                        accept=".PDF"
                    >
                        <Text className="titleSecondary">Click or Drag</Text>
                        <br />
                        <Text className="titleSecondary">File to Upload</Text>
                        <br />
                        <br />
                        <span style={{ color: '#747474' }}>
                            Support for a single or bulk upload. Strictly
                            prohibited from uploading company data or other
                            banned files.
                        </span>
                    </Dragger>
                )}

                {resumeState?.newResume && resumeState?.newResume?.url && (
                    <>
                        <Text
                            style={{
                                color: token?.colorPalette.textColor.primary,
                                fontSize: '16px',
                            }}
                        >
                            {resumeState?.newResume?.name}
                        </Text>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <Button
                                props={{
                                    text: 'Remove File',
                                    onClick: () =>
                                        setResumeState((prevState) => ({
                                            ...prevState,
                                            newResume: null,
                                        })),
                                    style: {
                                        marginTop: '25px',
                                    },
                                }}
                            />

                            <Button
                                props={{
                                    text: 'Upload',
                                    onClick: uploadNewResume,
                                    style: {
                                        marginTop: '25px',
                                    },
                                }}
                            />
                        </div>
                    </>
                )}
            </Modal>
        </>
    )
}

export default JobApplicantResume
