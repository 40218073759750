import React from 'react'

function FileAddIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            fill="none"
            viewBox="0 0 18 18"
        >
            <path
                stroke="#080325"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16.08 8.288L9.187 15.18A4.502 4.502 0 012.82 8.813L9.712 1.92a3.002 3.002 0 014.245 4.245l-6.9 6.893a1.501 1.501 0 01-2.122-2.123l6.367-6.36"
            ></path>
        </svg>
    )
}

export default FileAddIcon
