import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// antd
import {
    Card,
    Col,
    Divider,
    Form,
    Input,
    message,
    Row,
    Spin,
    theme,
    Typography,
} from 'antd'

// slices
import {
    addSummary,
    checkButtonType,
    profileLoading,
    profileState,
    switchIntroductionModal,
    switchProfileModal,
    switchVideoPlayModal,
    updateUserGet,
} from '../profile.slice'
import { loginState } from '../../login/login.slice'

// assets
import { VideosIcon } from '../../../assets/icons'

// constants
import { videoSummaryData } from '../../../constants/profile'

// helpers
import { isEmpty } from '../../../helpers/fieldCheck'

function SummaryModal({ modalType }) {
    const { Title, Text } = Typography

    const { TextArea } = Input

    const { useToken } = theme
    const { token } = useToken()

    const dispatch = useDispatch()

    const {
        profileModal,
        formButtonType,
        introductionVideoModal,
        selectedData,
        profileData,
        updatedDataStatus,
    } = useSelector(profileState)
    const { userProfile } = useSelector(loginState)

    const [form] = Form.useForm()

    // EDIT MODAL SET DATA INITIALLY
    useEffect(() => {
        if (selectedData) {
            dispatch(
                switchIntroductionModal({
                    open: false,
                    videos: selectedData?.videos,
                })
            )

            form.setFieldsValue({
                ...selectedData?.summary,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form, selectedData])

    // PROFILE DATA
    useEffect(() => {
        if (profileData?.data?.videos?.length > 0) {
            const results = profileData?.data?.videos.filter(({ type: id1 }) =>
                videoSummaryData.some(({ key: id2 }) => id2 === id1)
            )
            dispatch(
                switchIntroductionModal({
                    open: false,
                    videos: results,
                })
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profileData])

    function onFinishFailed() {}

    // FINISH SUMMARY
    async function finishSummary(value) {
        let modalState = { ...profileModal }

        if (
            (isEmpty(profileData?.data?.summary) &&
                selectedData?.videos?.length > 0 &&
                !selectedData?.videos.some(
                    (data) => data?.type === 'Introduction Video'
                )) ||
            (profileData?.data?.videos?.length > 0 &&
                !profileData?.data?.videos.some(
                    (data) => data?.type === 'Introduction Video'
                ))
        ) {
            message.error('Please Upload Introduction Video')
            return
        }

        dispatch(
            profileLoading({
                name: modalType,
                buttonName: formButtonType,
                loading: true,
            })
        )

        const result = await dispatch(
            addSummary({
                _id: userProfile?._id,
                summary: {
                    step: modalType,
                    data: {
                        summary: {
                            ...value,
                            isCompleted:
                                value?.summaryText !== undefined &&
                                selectedData?.videos?.length > 0 &&
                                selectedData?.videos.some(
                                    (data) =>
                                        data?.type === 'Introduction Video'
                                ) &&
                                profileData?.data?.profilePicture?.length > 0 &&
                                profileData?.data?.location !== ''
                                    ? true
                                    : false,
                        },
                    },
                },
            })
        )
        const data = result?.payload?.data

        if (data) {
            const { success, message: errorMessage } = data
            if (success) {
                dispatch(
                    profileLoading({
                        name: modalType,
                        buttonName: formButtonType,
                        loading: false,
                    })
                )
                dispatch(updateUserGet({ _id: userProfile?._id }))
                dispatch(switchProfileModal({ name: 'summary', open: false }))
                message.success(errorMessage)
            } else {
                dispatch(switchProfileModal(modalState))
                if (errorMessage) {
                    message.error(errorMessage)
                } else {
                    message.error('Something went wrong, try again later.')
                }
            }
        }
    }

    return updatedDataStatus === 'loading' ? (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
            }}
        >
            <Spin size="large" />
        </div>
    ) : (
        <>
            <Form
                labelCol={{
                    style: {
                        padding: '0 0 3px',
                    },
                }}
                layout="vertical"
                name="addSummary"
                onFinish={finishSummary}
                onFinishFailed={onFinishFailed}
                onSubmitCapture={async (e) => {
                    await dispatch(checkButtonType(e.nativeEvent.submitter.id))
                }}
                form={form}
                requiredMark={false}
            >
                <Row
                    style={{
                        rowGap: '20px',
                    }}
                >
                    <Col span={24}>
                        <Form.Item
                            label="Summary*"
                            name="summaryText"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Enter Summary',
                                },
                                {
                                    min: 8,
                                    max: 250,
                                    message:
                                        'Summary should be minimum 8 and maximum 250 characters long',
                                },
                            ]}
                            colon={false}
                        >
                            <TextArea
                                rows={4}
                                placeholder={'Enter Summary...'}
                                name={'summaryText'}
                                onChange={(e) => {
                                    form.setFieldValue(
                                        'summaryText',
                                        e.target.value.trimStart()
                                    )
                                }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <Divider
                style={{
                    margin: '35px 0px',
                }}
            />
            <div>
                <Title className={'titleMiddle'}>Add Video</Title>
                <Text
                    className="titleSecondary"
                    style={{
                        fontSize: token.fontSizeHeading5,
                    }}
                >
                    Please add your video here as per the preference to the
                    profile
                </Text>
            </div>
            <Row
                style={{
                    margin: '35px 0px',
                    width: '100%',
                }}
            >
                {videoSummaryData?.length > 0 &&
                    videoSummaryData.map((video) => (
                        <Col span={8} key={video?.key}>
                            <Text
                                style={{
                                    color: token.colorPalette.baseColor.black,
                                    fontSize: token.fontSize,
                                }}
                            >
                                {video?.key === 'Introduction Video'
                                    ? `${video?.title}*`
                                    : video?.title}
                            </Text>
                            {introductionVideoModal?.videos?.length > 0 &&
                            introductionVideoModal?.videos.some(
                                (item) => item?.type === video?.key
                            ) ? (
                                <>
                                    <video
                                        height="138px"
                                        width={'208px'}
                                        src={
                                            introductionVideoModal?.videos.find(
                                                (data) =>
                                                    data?.type === video.key
                                            )?.link
                                        }
                                        style={{
                                            borderRadius: '10px',
                                            objectFit: 'cover',
                                            marginTop: '5px',
                                            position: 'relative',
                                            border: `1px solid ${token.colorPalette.textColor.quaternary}`,
                                        }}
                                    />
                                    <div
                                        style={{
                                            height: '30px',
                                            position: 'absolute',
                                            top: '45%',
                                            right: '45%',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => {
                                            dispatch(
                                                switchVideoPlayModal({
                                                    delete: true,
                                                    videoData: {
                                                        deleteId:
                                                            introductionVideoModal?.videos.find(
                                                                (data) =>
                                                                    data?.type ===
                                                                    video.key
                                                            )?._id,
                                                        link: introductionVideoModal?.videos.find(
                                                            (data) =>
                                                                data?.type ===
                                                                video.key
                                                        )?.link,
                                                    },
                                                    open: true,
                                                    title: video?.title,
                                                })
                                            )
                                        }}
                                    >
                                        <VideosIcon
                                            color={
                                                token.colorPalette.textColor
                                                    .quinary
                                            }
                                        />
                                    </div>
                                </>
                            ) : (
                                <Card
                                    style={{
                                        width: 208,
                                        height: 138,
                                        marginTop: '5px',
                                        display: 'flex',
                                        cursor: 'pointer',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        border: `1px solid ${token.colorPalette.textColor.quaternary}`,
                                    }}
                                    onClick={() => {
                                        dispatch(
                                            switchIntroductionModal({
                                                ...introductionVideoModal,
                                                open: true,
                                                type: video?.key,
                                                category: video?.category,
                                            })
                                        )
                                    }}
                                >
                                    <div
                                        style={{
                                            height: '57px',
                                            marginBottom: '5px',
                                        }}
                                    >
                                        <VideosIcon
                                            color={
                                                token.colorPalette.textColor
                                                    .quinary
                                            }
                                        />
                                    </div>
                                    <Text
                                        style={{
                                            color: token.colorPalette.textColor
                                                .senary,
                                        }}
                                    >
                                        Click to Upload
                                    </Text>
                                </Card>
                            )}
                        </Col>
                    ))}
            </Row>
        </>
    )
}

export default SummaryModal
