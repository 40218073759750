import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

// redux
import { editTimeWithTask, timesheetApi } from './redux/apis'

// antd
import {
    Badge,
    Divider,
    Image,
    message,
    Modal,
    Popover,
    Progress,
    Row,
    Table,
    theme,
    TimePicker,
    Tooltip,
    Typography,
} from 'antd'
import {
    BugOutlined,
    CheckOutlined,
    ProjectOutlined,
    CheckSquareOutlined,
    CloseSquareOutlined,
} from '@ant-design/icons'

// slices
import { projectReportsState } from '../projectReports/projectReports.slice'

// components
import AvatarComponent from '../../../components/avatar'

// assets
import { EditIcon } from '../../../assets/icons'

import dayjs from 'dayjs'

// styles
import styles from './../../tasks/tasks.module.css'

const ReportsTimeSheet = ({ userProfile }) => {
    const { useToken } = theme

    const { token } = useToken()

    const { Title, Text } = Typography

    const [loadingData, setLoadingData] = useState(false)
    const [timesheetData, setTimesheetData] = useState(null)
    const [timesheetRowCol, setTimesheetRowCol] = useState({
        rows: [],
        columns: [],
    })
    const [editModal, setEditModal] = useState({ open: false, editData: [] })
    const [selectedTask, setSelectedTask] = useState(null)
    const [selectedTaskTime, setSelectedTaskTime] = useState(null)

    const { dateRanges, dateType, filter } = useSelector(projectReportsState)

    async function DateRangeSet() {
        if (dateRanges?.start && dateRanges?.end) {
            const data = {
                start: dateRanges?.start,
                end: dateRanges?.end,
                projectId: filter?.projectId,
                assigneeId: filter?.assigneeId,
            }

            setLoadingData(true)
            const res = await timesheetApi(data)

            if (res.data.success) {
                setLoadingData(false)
                setTimesheetData(res.data)
            } else {
                setLoadingData(false)
                message.error(res.data.message)
            }
        } else {
            setLoadingData(true)
            setTimesheetData([])
            setTimeout(() => {
                setLoadingData(false)
            }, 1000)
        }
    }

    function hourMinuteSecond(timeInMiliseconds) {
        let h, m, s
        h = Math.floor(timeInMiliseconds / 1000 / 60 / 60)
        m = Math.floor((timeInMiliseconds / 1000 / 60 / 60 - h) * 60)
        s = Math.floor(((timeInMiliseconds / 1000 / 60 / 60 - h) * 60 - m) * 60)
        return `${h}h ${m}m ${s}s`
    }

    useEffect(() => {
        DateRangeSet()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateRanges, filter /* pdfData*/])

    const handleEditTime = (task) => {
        setSelectedTask(task)
    }

    const handleTimePickerValChange = (val) => {
        // time picker value
        if (val) {
            setSelectedTaskTime(val)
        } else {
            setSelectedTaskTime(null)
        }
    }

    const handleSaveChangeTime = async () => {
        // set logic of pervious time and new time and update api call
        if (selectedTask) {
            const latest =
                (selectedTaskTime['$H'] * 60 * 60 +
                    selectedTaskTime['$m'] * 60 +
                    selectedTaskTime['$s']) *
                1000
            const newEndLog = latest - selectedTask?.totalTimeLogged

            // send api data in payload
            const data = {
                id: selectedTask?._id,
                // index: selectedTask?.index,
                newEndLog,
            }
            const updatedRes = await editTimeWithTask(data)
            if (updatedRes?.data?.success) {
                message.success(updatedRes?.data?.message)
                setEditModal({ open: false, editData: [] })
                DateRangeSet()
                setSelectedTask(null)
                setSelectedTaskTime(null)
            } else {
                message.error(updatedRes?.data?.message)
            }
        } else {
            message.error('Please select time properly')
        }
    }

    // VIEW TASK
    const handleViewTasks = (tasks) => {
        const mergedTasks = tasks?.list.reduce((acc, task) => {
            const existingTask = acc.find((t) => t._id === task._id)
            if (existingTask) {
                // If end time is available, add the difference between end and start
                if (task.timelogs.end) {
                    existingTask.totalTimeLogged +=
                        task.timelogs.end - task.timelogs.start
                } else {
                    // If end time is not available, add the difference from start to now
                    existingTask.totalTimeLogged +=
                        new Date().getTime() - task.timelogs.start
                }
            } else {
                // Initialize the task and set the totalTimeLogged
                acc.push({
                    ...task,
                    totalTimeLogged: task.timelogs.end
                        ? task.timelogs.end - task.timelogs.start
                        : new Date().getTime() - task.timelogs.start,
                })
            }
            return acc
        }, [])

        // Apply hourMinuteSecond formatting
        const formattedTasks = mergedTasks.map((task) => ({
            ...task,
            formattedTimeLogged: hourMinuteSecond(task.totalTimeLogged),
        }))

        return (
            <div>
                <Row align={'middle'} justify={'space-between'}>
                    <Title level={5}>Tasks</Title>
                    {userProfile?.role?.roleName !== 'member' && (
                        <div
                            style={{
                                height: '20px',
                                width: '20px',
                                cursor: 'pointer',
                            }}
                            onClick={() => {
                                setEditModal({
                                    editData: formattedTasks,
                                    open: true,
                                })
                            }}
                        >
                            <EditIcon
                                color={token.colorPalette.baseColor.tertiary}
                            />
                        </div>
                    )}
                </Row>
                {formattedTasks.map((v) => (
                    <>
                        <Divider
                            style={{ p: 5, margin: '10px 0px 10px 0px' }}
                        />
                        <Row
                            style={{
                                width: '320px',
                                flexFlow: 'row nowrap',
                            }}
                            align={'middle'}
                            justify={'space-between'}
                        >
                            <Row
                                style={{
                                    columnGap: '8px',
                                    flexFlow: 'row nowrap',
                                }}
                            >
                                <Tooltip title={v?.status}>
                                    {v?.type === 'Bug' ? (
                                        <div
                                            className={styles.taskTypeSection}
                                            style={{
                                                backgroundColor: 'red',
                                            }}
                                        >
                                            <BugOutlined
                                                style={{
                                                    fontSize: '12px',
                                                    color: 'white',
                                                }}
                                            />
                                        </div>
                                    ) : v?.type === 'Task' ? (
                                        <div
                                            className={styles.taskTypeSection}
                                            style={{
                                                backgroundColor:
                                                    token.colorPalette.baseColor
                                                        .tertiary,
                                            }}
                                        >
                                            <CheckOutlined
                                                style={{
                                                    fontSize: '10px',
                                                    color: 'white',
                                                }}
                                            />
                                        </div>
                                    ) : (
                                        <div
                                            className={styles.taskTypeSection}
                                            style={{
                                                backgroundColor: '#7a16ff',
                                            }}
                                        >
                                            <ProjectOutlined
                                                style={{
                                                    fontSize: '10px',
                                                    color: 'white',
                                                }}
                                            />
                                        </div>
                                    )}
                                </Tooltip>
                                <Text
                                    style={{
                                        fontWeight: 500,
                                        wordBreak: 'break-word',
                                    }}
                                >
                                    {v?.title}
                                </Text>
                            </Row>
                            <Text style={{ width: '100px' }}>
                                {hourMinuteSecond(v?.totalTimeLogged)}
                            </Text>
                        </Row>
                    </>
                ))}
            </div>
        )
    }

    useEffect(() => {
        if (timesheetData?.success) {
            let finalRows = []

            // columns set
            let datesCol = timesheetData?.tableHead?.map((date, index) => {
                if (date?.dataIndex === 'name') {
                    return {
                        title: date?.title,
                        dataIndex: date?.dataIndex,
                        fixed: dateType === 'month' && 'left',
                        render: (val, item) => {
                            return (
                                <>
                                    <Row
                                        align={'middle'}
                                        style={{
                                            columnGap: '16px',
                                        }}
                                    >
                                        {item?.profilePicture ? (
                                            <>
                                                <Image
                                                    src={item?.profilePicture}
                                                    height={40}
                                                    width={40}
                                                    style={{
                                                        borderRadius: '50%',
                                                    }}
                                                    preview={false}
                                                />
                                            </>
                                        ) : (
                                            <AvatarComponent
                                                props={{
                                                    avatarName: item?.name,
                                                    size: 40,
                                                    styleAvatar: {
                                                        fontSize: '16px',
                                                    },
                                                }}
                                            />
                                        )}
                                        <Title
                                            level={5}
                                            style={{
                                                textTransform: 'capitalize',
                                            }}
                                        >
                                            {val}
                                        </Title>
                                    </Row>
                                </>
                            )
                        },
                    }
                }
                if (date?.dataIndex === 'isActive') {
                    return {
                        title: date?.title,
                        dataIndex: date?.dataIndex,
                        render: (val) => {
                            return (
                                <Badge
                                    count={val ? 'Active' : 'InActive'}
                                    color={
                                        val
                                            ? 'hsla(155, 54%, 46%, 0.16)'
                                            : 'hsla(11, 100%, 59%, 0.16)'
                                    }
                                    style={{
                                        minWidth: '30px',
                                        cursor: 'auto',
                                        borderRadius: '6px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        color: val
                                            ? token.colorPalette.iconColor
                                                  .tertiary
                                            : token.colorPalette.iconColor
                                                  .quaternary,
                                        padding: '12px 8px',
                                        fontWeight: 700,
                                    }}
                                />
                            )
                        },
                    }
                } else {
                    return {
                        title: dayjs(date?.title).format('ddd, DD MMM, YYYY'),
                        dataIndex: date?.dataIndex,
                        fixed:
                            dateType === 'month' &&
                            timesheetData?.tableHead?.length > 0 &&
                            timesheetData?.tableHead?.length - 1 === index &&
                            'right',
                        render: (_, elm) => {
                            const isFound =
                                elm?.body != null &&
                                elm?.body?.list?.length > 0 &&
                                elm?.body?.list.some((element) => {
                                    if (
                                        date?.dataIndex ===
                                        element?._id?.startLogDate
                                    ) {
                                        return true
                                    }

                                    return false
                                })
                            return (
                                <>
                                    <div style={{ fontWeight: 500 }}>
                                        {elm?.body != null ? (
                                            elm?.body?.list?.length > 0 &&
                                            elm?.body?.list.map(
                                                (item) =>
                                                    date?.dataIndex ===
                                                        item?._id
                                                            ?.startLogDate && (
                                                        <>
                                                            <Popover
                                                                content={handleViewTasks(
                                                                    item
                                                                )}
                                                            >
                                                                <div
                                                                    style={{
                                                                        display:
                                                                            'flex',
                                                                        flexDirection:
                                                                            'column',
                                                                        justifyContent:
                                                                            'center',
                                                                        alignItems:
                                                                            'center',
                                                                        cursor: 'pointer',
                                                                        rowGap: '10px',
                                                                    }}
                                                                >
                                                                    <Progress
                                                                        percent={
                                                                            100
                                                                        }
                                                                        style={{
                                                                            height: '9px',
                                                                        }}
                                                                        showInfo={
                                                                            false
                                                                        }
                                                                        strokeLinecap="butt"
                                                                        strokeColor={
                                                                            token
                                                                                .colorPalette
                                                                                .baseColor
                                                                                .tertiary
                                                                        }
                                                                    />
                                                                    <Title
                                                                        level={
                                                                            5
                                                                        }
                                                                    >
                                                                        {hourMinuteSecond(
                                                                            item
                                                                                ?.totalLog
                                                                                ?.total
                                                                        )}
                                                                    </Title>
                                                                </div>
                                                            </Popover>
                                                        </>
                                                    )
                                            )
                                        ) : (
                                            <>
                                                <div
                                                    style={{
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    <Progress
                                                        percent={100}
                                                        showInfo={false}
                                                        strokeColor="#f3f9ff"
                                                    />
                                                    <span>0 h</span>
                                                </div>
                                            </>
                                        )}
                                        {isFound === false &&
                                            elm?.body !== null && (
                                                <div
                                                    style={{
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    <Progress
                                                        percent={100}
                                                        showInfo={false}
                                                        strokeColor="#f3f9ff"
                                                    />
                                                    <span>0 h</span>
                                                </div>
                                            )}
                                    </div>
                                </>
                            )
                        },
                    }
                }
            })

            if (timesheetData?.tableBody?.length > 0) {
                timesheetData?.tableBody?.map((row) =>
                    // eslint-disable-next-line
                    timesheetData?.users?.map((user) => {
                        if (row?._id?.assigneeId === user?._id) {
                            let obj = {
                                name: user.name,
                                id: user._id,
                                isActive: user?.isActive,
                                profilePicture: user?.profilePicture,
                                body: row,
                            }
                            finalRows.push(obj)
                        } else if (row?._id?.assigneeId !== user?._id) {
                            let obj = {
                                name: user.name,
                                id: user._id,
                                isActive: user?.isActive,
                                profilePicture: user?.profilePicture,
                                body: null,
                            }
                            finalRows.push(obj)
                        }
                    })
                )
            } else {
                // eslint-disable-next-line
                timesheetData?.users?.map((user) => {
                    let obj = {
                        name: user.name,
                        id: user._id,
                        isActive: user?.isActive,
                        profilePicture: user?.profilePicture,
                        body: null,
                    }
                    finalRows.push(obj)
                })
            }

            let uniqueArr = Object.values(
                finalRows?.reduce(
                    (r, o) =>
                        !r[o.name] ||
                        (r[o.name].body === null && o.body !== null)
                            ? { ...r, [o.name]: o }
                            : r,
                    {}
                )
            )

            setTimesheetRowCol({
                columns: datesCol,
                rows: uniqueArr,
                key: timesheetData?.tableBody,
            })
        } else {
            setLoadingData(true)
            setTimesheetRowCol({
                columns: [],
                rows: [],
                key: [],
            })
            setTimeout(() => {
                setLoadingData(false)
            }, 1000)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timesheetData])

    return (
        <>
            <div
                className="table-responsive"
                style={{
                    marginTop: '25px',
                }}
            >
                <Table
                    rootClassName="tableGlobal"
                    style={{
                        borderRadius: '16px',
                        overflowX: dateType === 'month' ? 'scroll' : 'hidden',
                    }}
                    columns={timesheetRowCol.columns}
                    dataSource={timesheetRowCol.rows ?? []}
                    loading={loadingData}
                    scroll={{
                        x: dateType === 'month' && '2500px',
                    }}
                />
            </div>
            <Modal
                open={editModal?.open}
                onCancel={() => {
                    setSelectedTask(null)
                    setEditModal({ open: false, editData: [] })
                }}
                centered
                footer={null}
                closable={false}
                destroyOnClose={true}
                width={700}
            >
                <Row align={'middle'}>
                    <Title level={4}>Edit Timelogs</Title>
                </Row>
                {editModal?.editData.map((v) => (
                    <>
                        <Divider
                            style={{ p: 5, margin: '10px 0px 10px 0px' }}
                        />
                        <Row
                            style={{
                                flexFlow: 'row nowrap',
                            }}
                            align={'middle'}
                            justify={'space-between'}
                        >
                            <Row
                                style={{
                                    columnGap: '8px',
                                    flexFlow: 'row nowrap',
                                }}
                                align={'middle'}
                            >
                                <Tooltip title={v?.status}>
                                    {v?.type === 'Bug' ? (
                                        <div
                                            className={styles.taskTypeSection}
                                            style={{
                                                backgroundColor: 'red',
                                            }}
                                        >
                                            <BugOutlined
                                                style={{
                                                    fontSize: '12px',
                                                    color: 'white',
                                                }}
                                            />
                                        </div>
                                    ) : v?.type === 'Task' ? (
                                        <div
                                            className={styles.taskTypeSection}
                                            style={{
                                                backgroundColor:
                                                    token.colorPalette.baseColor
                                                        .tertiary,
                                            }}
                                        >
                                            <CheckOutlined
                                                style={{
                                                    fontSize: '10px',
                                                    color: 'white',
                                                }}
                                            />
                                        </div>
                                    ) : (
                                        <div
                                            className={styles.taskTypeSection}
                                            style={{
                                                backgroundColor: '#7a16ff',
                                            }}
                                        >
                                            <ProjectOutlined
                                                style={{
                                                    fontSize: '10px',
                                                    color: 'white',
                                                }}
                                            />
                                        </div>
                                    )}
                                </Tooltip>
                                <Text
                                    style={{
                                        fontWeight: 500,
                                        wordBreak: 'break-word',
                                        width: '400px',
                                    }}
                                >
                                    {v?.title}
                                </Text>
                            </Row>
                            {selectedTask &&
                            selectedTask?.timelogs?._id === v?.timelogs?._id ? (
                                <Row align={'middle'}>
                                    <span
                                        style={{
                                            marginRight: 10,
                                        }}
                                    >
                                        <TimePicker
                                            defaultValue={
                                                dayjs(
                                                    hourMinuteSecond(
                                                        v?.totalTimeLogged,
                                                        'edit'
                                                    ),
                                                    'HH:mm:ss'
                                                )
                                                // v?.timelogs?.end
                                                //     ? dayjs(
                                                //           hourMinuteSecond(
                                                //               v?.timelogs?.end -
                                                //                   v?.timelogs
                                                //                       ?.start,
                                                //               'edit'
                                                //           ),
                                                //           'HH:mm:ss'
                                                //       )
                                                //     : dayjs(
                                                //           hourMinuteSecond(
                                                //               new Date().getTime() -
                                                //                   v?.timelogs
                                                //                       ?.start,
                                                //               'edit'
                                                //           ),
                                                //           'HH:mm:ss'
                                                //       )
                                            }
                                            size="small"
                                            onChange={handleTimePickerValChange}
                                        />
                                    </span>
                                    <CheckSquareOutlined
                                        style={{
                                            marginRight: 5,
                                            color: 'green',
                                        }}
                                        onClick={handleSaveChangeTime}
                                    />
                                    <CloseSquareOutlined
                                        style={{
                                            color: 'red',
                                        }}
                                        onClick={() => {
                                            setSelectedTask(null)
                                            setSelectedTaskTime(null)
                                        }}
                                    />
                                </Row>
                            ) : (
                                <>
                                    <Text>
                                        {hourMinuteSecond(v?.totalTimeLogged)}
                                    </Text>
                                    <div
                                        style={{
                                            height: '20px',
                                            width: '20px',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => {
                                            handleEditTime(v)
                                        }}
                                    >
                                        <EditIcon
                                            color={
                                                token.colorPalette.baseColor
                                                    .tertiary
                                            }
                                        />
                                    </div>
                                </>
                            )}
                        </Row>
                    </>
                ))}
            </Modal>
        </>
    )
}

export default ReportsTimeSheet
