import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import S3FileUpload from 'react-s3'

// antd
import { Form, Modal, Row, Spin, Typography, Upload, message } from 'antd'
import { InboxOutlined } from '@ant-design/icons'

// components
import Button from '../../components/button/button'

// slices
import { loginState } from '../login/login.slice'

import dayjs from 'dayjs'
import {
    fileTypes,
    otherTypesFile,
    pdfTypes,
    videoTypes,
} from '../../constants/mediaTypes'

function FilesModalContent({ props = {} }) {
    const {
        visibility,
        onCancel,
        pageLimit,
        skipPage,
        addRemoveFiles,
        filesGet,
        totalFiles,
        modalKey,
        selectedInquiry,
        workspaceId,
    } = props
    const { Dragger } = Upload

    const { Text } = Typography

    const dispatch = useDispatch()

    const { userProfile } = useSelector(loginState)

    const [uploadLoadingButton, setUploadLoadingButton] = useState(false)
    const [stream, setStream] = useState(false)
    const [fileURL, setFileURL] = useState([])
    const [errorFile, setErrorFile] = useState('')
    const [fileListCount, setFileListCount] = useState()

    // FILE PROPS OF UPLOAD
    const fileProps = {
        name: 'files',
        multiple: true,
        onChange(info) {
            if (errorFile?.length === 0) {
                setFileListCount(info?.fileList?.length)
                if (info?.file?.status === 'removed') {
                    const newData =
                        fileURL?.length > 0 &&
                        fileURL
                            .filter(
                                (data) =>
                                    info?.file?.name.includes(
                                        data?.displayName
                                    ) === false
                            )
                            .map((data) => {
                                return data
                            })

                    setFileURL(newData)
                } else {
                    setStream(true)
                    const nameChange = info?.file?.name
                        .split('.')[0]
                        .concat(`_${dayjs(new Date()).unix()}`)
                        .concat(
                            `.${
                                info?.file?.name.split('.')[
                                    info?.file?.name.split('.')?.length - 1
                                ]
                            }`
                        )

                    const displayName = info?.file?.name?.slice(
                        0,
                        info?.file?.name?.lastIndexOf('.')
                    )

                    const newFileData = new File([info?.file], nameChange, {
                        type: info?.file?.type,
                    })
                    window.Buffer = window.Buffer || require('buffer').Buffer
                    S3FileUpload.uploadFile(newFileData, userProfile?.s3Config)
                        .then(async (data) => {
                            const newData = {
                                name: data?.location,
                                displayName: displayName,
                                fileSize: info?.file?.size,
                                createdBy: userProfile?._id,
                                projectId: userProfile?.activeProject?._id,
                            }

                            setFileURL((prevState) => [newData, ...prevState])
                        })
                        .catch((error) => {
                            if (error?.status === 503) {
                                // message.info(error.statusText)
                            } else {
                                message.error(
                                    'Upload Failed!. Please Upload again'
                                )
                            }
                        })
                }
            }
        },
        beforeUpload(file, fileListArray) {
            setErrorFile('')
            if (
                fileURL?.length > 6 ||
                (fileURL?.length < 6 &&
                    fileListArray?.length + fileURL?.length > 6)
            ) {
                setErrorFile('You can only upload 6 files at most!')
                return Upload.LIST_IGNORE
            }
            const isLt50M = file.size / 1024 / 1024 < 50
            if (!isLt50M) {
                message.error('Maximum File size would be 50 MB')
                return Upload.LIST_IGNORE
            } else {
                return false
            }
        },
        onDrop() {},
    }

    useEffect(() => {
        if (fileURL?.length === fileListCount) {
            setStream(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fileURL])

    // FILE UPLOAD CHANGE
    const onFileUpload = async () => {
        setUploadLoadingButton(true)

        let payload = {
            id:
                modalKey === 'inquiry'
                    ? selectedInquiry
                    : userProfile?.activeProject?._id,
            fileObject: {
                fieldName: 'add',
                files: [...fileURL],
            },
        }

        if (modalKey === 'inquiry') {
            payload.workspaceId = workspaceId
        }

        const result = await dispatch(addRemoveFiles(payload))
        const data = result?.payload?.data
        if (data) {
            const { success, message: checkMessage } = data
            if (success) {
                message.success(checkMessage)
                setUploadLoadingButton(false)

                let payload = { limit: pageLimit, skip: skipPage }

                const projectId = userProfile?.activeProject?._id
                const inquiryId = selectedInquiry

                if (modalKey === 'files') {
                    payload['projectId'] = projectId
                } else {
                    payload['id'] = inquiryId
                    payload['workspaceId'] = workspaceId
                }

                dispatch(
                    filesGet({
                        ...payload,
                    })
                )
                dispatch(
                    totalFiles(
                        modalKey === 'files'
                            ? { projectId }
                            : { id: inquiryId, workspaceId: workspaceId }
                    )
                )
                onCancel()
                setFileURL([])
                setErrorFile('')
            } else {
                setUploadLoadingButton(false)
                onCancel()
                setFileURL([])
                setErrorFile('')
                if (typeof checkMessage === 'object') {
                    for (const key in checkMessage) {
                        message.error(checkMessage[key])
                    }
                } else if (typeof checkMessage === 'string') {
                    message.error(checkMessage)
                } else {
                    message.error('Something went wrong, try again later.')
                }
            }
        }
    }

    const onFinishFailed = () => {}

    const dummyRequest = ({ onSuccess }) => {
        setTimeout(() => {
            onSuccess('ok')
        }, 1000)
    }

    return (
        <>
            <Modal
                footer={null}
                closable={false}
                open={visibility}
                centered
                destroyOnClose={true}
                onCancel={() => {
                    setFileURL([])
                    setErrorFile('')
                    setStream(false)
                    onCancel()
                }}
            >
                <Form
                    layout="vertical"
                    name="statusUpdate"
                    onFinish={onFileUpload}
                    onFinishFailed={onFinishFailed}
                    initialValues={{
                        files: [],
                    }}
                    requiredMark={false}
                >
                    <Form.Item name="dragger" noStyle className="fileDragger">
                        <Dragger
                            {...fileProps}
                            // showUploadList={
                            //     errorFile?.length > 0 ? false : true
                            // }
                            customRequest={dummyRequest}
                            maxCount={6}
                            // previewFile={errorFile?.length > 0 ? false : true}
                            accept={[
                                ...fileTypes,
                                ...videoTypes,
                                ...pdfTypes,
                                ...otherTypesFile,
                            ]}
                        >
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">
                                Click or drag file to this area to upload
                            </p>
                            <p className="ant-upload-hint">
                                Support for a single or bulk upload. Strictly
                                prohibited from uploading company data or other
                                banned files.
                            </p>
                        </Dragger>
                    </Form.Item>
                    {stream && (
                        <Row
                            justify={'center'}
                            style={{
                                marginTop: '10px',
                                width: '100%',
                            }}
                        >
                            <Text
                                style={{
                                    marginRight: '5px',
                                }}
                            >
                                Uploading...
                            </Text>
                            <Spin spinning={true}></Spin>
                        </Row>
                    )}
                    {errorFile?.length > 0 && (
                        <span style={{ color: 'red', margin: 2 }}>
                            {errorFile}
                        </span>
                    )}
                    {fileURL?.length > 0 && !stream ? (
                        <Row
                            style={{
                                marginTop: 10,
                            }}
                        >
                            <Button
                                props={{
                                    text: 'Upload',
                                    disabled:
                                        stream === true || uploadLoadingButton,
                                    loadingButton: uploadLoadingButton,
                                    htmlType: 'submit',
                                }}
                            />
                        </Row>
                    ) : (
                        <></>
                    )}
                </Form>
            </Modal>
        </>
    )
}

export default FilesModalContent
