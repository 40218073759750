import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import OtpInput from 'react-otp-input'

// antd
import {
    Col,
    Row,
    Divider,
    Form,
    Modal,
    Typography,
    message,
    theme,
} from 'antd'

// slices
import {
    loginState,
    sendOtpLogin,
    verifyOtpLogin,
} from '../../login/login.slice'
import {
    addSummary,
    profileLoading,
    profileState,
    switchProfileModal,
    updateUserGet,
} from '../profile.slice'

// components
import Button from '../../../components/button/button'

function VerifyOtpProfileModal({
    visibility,
    phone,
    countryCode,
    onCancelModal,
    modalType,
    payloadData,
}) {
    const { Title, Text } = Typography

    const [form] = Form.useForm()

    const { userProfile } = useSelector(loginState)
    const { formButtonType } = useSelector(profileState)

    const { useToken } = theme
    const { token } = useToken()

    const dispatch = useDispatch()

    const [loadingButton, setLoadingButton] = useState(false)
    const [isOTP, setIsOTP] = useState('')

    function onFinishFailed() {}

    async function onVerifyOtp() {
        setLoadingButton(true)
        const result = await dispatch(
            verifyOtpLogin({
                otp: isOTP,
                countryCode: countryCode,
                phone: phone,
                loginFlag: false,
            })
        )
        const data = result?.payload?.data
        if (data) {
            const { success, message: otpMessage } = data
            if (success) {
                const result = await dispatch(
                    addSummary({
                        _id: userProfile?._id,
                        summary: {
                            data: {
                                ...payloadData,
                            },
                        },
                    })
                )
                const data = result?.payload?.data

                if (data) {
                    const { success, message: errorMessage } = data
                    if (success) {
                        dispatch(
                            profileLoading({
                                name: modalType,
                                buttonName: formButtonType,
                                loading: false,
                            })
                        )
                        message.success(errorMessage)
                        dispatch(updateUserGet({ _id: userProfile?._id }))
                        dispatch(
                            switchProfileModal({ name: 'profile', open: false })
                        )
                    } else {
                        dispatch(
                            profileLoading({
                                name: modalType,
                                buttonName: formButtonType,
                                loading: false,
                            })
                        )
                        dispatch(
                            switchProfileModal({ name: 'profile', open: false })
                        )
                        if (errorMessage) {
                            message.error(errorMessage)
                        } else {
                            message.error(
                                'Something went wrong, try again later.'
                            )
                        }
                    }
                }
                setLoadingButton(false)
                onCancelModal()
                message.success(otpMessage)
            } else {
                setIsOTP('')
                form.setFieldValue('otpInput', '')
                setLoadingButton(false)
                // onCancelModal()
                message.error(otpMessage)
            }
        }
    }

    // SEND OTP
    const handleSendOtp = async () => {
        const result = await dispatch(
            sendOtpLogin({
                countryCode: countryCode,
                phone: phone,
            })
        )
        const data = result?.payload?.data
        if (data) {
            const { success, message: checkMessage } = data
            if (success) {
                setIsOTP('')
                form.setFieldValue('otpInput', '')
                message.success(checkMessage)
            } else {
                setIsOTP('')
                form.setFieldValue('otpInput', '')
                if (checkMessage) {
                    message.error(checkMessage)
                } else {
                    message.error('Something went wrong, try again later.')
                }
            }
        }
    }

    const handleChangeOTP = (otp) => {
        setIsOTP(otp)
    }

    return (
        <Modal
            open={visibility}
            style={{
                minWidth: '545px',
                overflowY: 'hidden',
                padding: '0px !important',
                borderRadius: '20px',
            }}
            centered
            closeIcon={<></>}
            styles={{
                body: {
                    padding: '66px 85px',
                },
            }}
            footer={null}
            onCancel={() => onCancelModal()}
        >
            <Title level={3}>Please check your phone</Title>
            <div
                style={{
                    marginTop: '20px',
                    textAlign: 'center',
                }}
            >
                <Text
                    style={{
                        textAlign: 'center',
                    }}
                >
                    {`Please check your phone ${phone} for a validation code and
                    enter it below to complete your password change`}
                </Text>
            </div>
            <Form
                layout="vertical"
                name="verifyOtpProfile"
                onFinish={onVerifyOtp}
                onFinishFailed={onFinishFailed}
                requiredMark={false}
                form={form}
                style={{
                    marginTop: '20px',
                }}
            >
                <Col
                    span={24}
                    style={{
                        padding: '0px',
                        marginBottom: '10px',
                    }}
                >
                    <Form.Item
                        name="otpInput"
                        rules={[
                            {
                                required: true,
                                message: 'Please Enter OTP',
                            },
                        ]}
                        colon={false}
                    >
                        <OtpInput
                            value={isOTP}
                            onChange={handleChangeOTP}
                            numInputs={6}
                            shouldAutoFocus
                            renderInput={(props) => <input {...props} />}
                            inputStyle="input-otp-style"
                            containerStyle={'otp-style'}
                            hasErrored={true}
                            isInputNum={true}
                        />
                    </Form.Item>
                </Col>
                <Row
                    gutter={20}
                    style={{
                        marginTop: '20px',
                    }}
                >
                    <Col span={24}>
                        <Button
                            props={{
                                style: {
                                    width: '100%',
                                },
                                text: 'Verify',
                                htmlType: 'submit',
                                disabled: loadingButton,
                                loadingButton: loadingButton,
                            }}
                        />
                    </Col>
                </Row>
                <Divider />
                <div
                    style={{
                        marginTop: '20px',
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <Text
                        className="sub-title"
                        style={{
                            fontWeight: 400,
                        }}
                    >
                        Didn’t received code?{' '}
                        <span
                            style={{
                                cursor: 'pointer',
                                color: token.colorPalette.baseColor.tertiary,
                            }}
                            className="sub-title"
                            onClick={() => handleSendOtp()}
                        >
                            Resend Code
                        </span>
                    </Text>
                </div>
            </Form>
        </Modal>
    )
}

export default VerifyOtpProfileModal
