import data from '../../helpers/data.json'
import axiosNew from '../../helpers/axios'

export const getTasksList = (search) => {
    return new Promise((resolve) => {
        let tasks = data.tasks
        if (search?.length > 0) {
            tasks = tasks.filter((x) =>
                x.name.toLowerCase().includes(search.toLowerCase())
            )
        }
        setTimeout(() => resolve({ data: tasks }), 300)
    })
}

export const getTasksCount = (search) => {
    let tasks = data.tasks
    if (search?.length > 0) {
        tasks = tasks.filter((x) =>
            x.name.toLowerCase().includes(search.toLowerCase())
        )
    }
    return new Promise((resolve) => {
        setTimeout(() => resolve({ data: tasks.length }), 200)
    })
}

// LIST TASKS
export const listTask = (payload) => {
    const { skip = 0, limit = 0, filter, search = '' } = payload
    return new Promise(async (resolve) => {
        try {
            let data = {
                skip,
                limit,
                filter,
                listType: 'status',
            }
            if (search?.length > 0) {
                data['search'] = {
                    title: search,
                }
            }
            const tasks = await axiosNew({
                url: 'tasks/list',
                data,
            })
            resolve({ data: tasks?.data?.data ?? [] })
        } catch (error) {
            resolve({ data: [] })
        }
    })
}

// LIST SUB TASKS
export const listSubTasks = (payload) => {
    const { skip = 0, limit = 0, parentId } = payload
    return new Promise(async (resolve) => {
        try {
            let data = {
                skip,
                limit,
                parentId,
                // filter,
                // listType: 'status',
            }

            const tasks = await axiosNew({
                url: 'tasks/list-child',
                data,
            })
            resolve({ data: tasks?.data?.data ?? [] })
        } catch (error) {
            resolve({ data: [] })
        }
    })
}

// CREATE TASK
export const createTask = async (payload) => {
    return new Promise(async (resolve) => {
        try {
            let data = payload ?? {}
            const create = await axiosNew({
                url: 'tasks/create',
                data,
            })
            resolve({ data: create?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// UPDATE TASK
export const updateTask = async (payload) => {
    return new Promise(async (resolve) => {
        try {
            let data = payload?.updateTask ?? {}
            const update = await axiosNew({
                url: 'tasks/update/' + payload._id,
                data,
                method: 'put',
            })
            resolve({ data: update?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// CHANGE SWIMLANES
export const changeSwimLanes = async (payload) => {
    return new Promise(async (resolve) => {
        try {
            let data = payload ?? {}
            const changeSwimlane = await axiosNew({
                url: 'tasks/changes-swimlanes',
                data,
                method: 'put',
            })
            resolve({ data: changeSwimlane?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// DELETE TASK
export const deleteTask = async (payload) => {
    return new Promise(async (resolve) => {
        try {
            const deleteTask = await axiosNew({
                url: 'tasks/delete/' + payload,
                method: 'delete',
            })
            resolve({ data: deleteTask?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// GET TASK BY ID
export const getTaskById = async (payload) => {
    return new Promise(async (resolve) => {
        try {
            const getTask = await axiosNew({
                url: 'tasks/get/' + payload._id,
                method: 'get',
            })
            resolve({ data: getTask?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// start and stop timer
export const startStopTimer = async (payload) => {
    return new Promise(async (resolve) => {
        try {
            let data = payload?.data ?? {}
            const startStopRes = await axiosNew({
                url: 'tasks/start-end-timer/' + payload.id,
                data,
                method: 'put',
            })
            resolve({ data: startStopRes.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// ADD / REMOVE COMMENTS
export const addRemoveComments = async (payload) => {
    return new Promise(async (resolve) => {
        try {
            let data = payload?.data ?? {}
            const addRemoveComments = await axiosNew({
                url: 'tasks/add-remove-comments/' + payload.id,
                data,
                method: 'put',
            })
            resolve({ data: addRemoveComments.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// ADD / REMOVE ATTACHMENTS
export const addRemoveAttachments = async (payload) => {
    return new Promise(async (resolve) => {
        try {
            let data = payload?.data ?? {}
            const addRemoveAttachments = await axiosNew({
                url: 'tasks/add-remove-attachment/' + payload.id,
                data,
                method: 'put',
            })
            resolve({ data: addRemoveAttachments.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}
// ADD / REMOVE ATTACHMENTS
export const taskHistory = async (payload) => {
    return new Promise(async (resolve) => {
        try {
            let data = payload ?? {}
            const taskHistoryData = await axiosNew({
                url: 'tasks/list-history',
                data,
            })
            resolve({ data: taskHistoryData.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}
