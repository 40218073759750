import { useState } from 'react'
import { useDispatch } from 'react-redux'

// antd
import {
    Avatar,
    Card,
    Image,
    Row,
    Space,
    Typography,
    theme,
    Badge,
    Popover,
} from 'antd'

// assets
import {
    AssignIcon,
    BypassIcon,
    ChangeCostIcon,
    EllipsisIcon,
    MailIcon,
    MobileIcon,
    NonVerifyTalentIcon,
    UnAssignIcon,
    VerifyTalentIcon,
    ViewIcon,
} from '../../assets/icons'

// slices
import { getTalentDetails } from './talent.slice'
import { updateUserGet } from '../profile/profile.slice'

const TalentGrid = ({ props }) => {
    const { Text, Title } = Typography

    const {
        talent,
        modalOpen,
        unAssignModalOpen,
        addRatesModalOpen,
        talentLogin,
        updateStatus,
        talentModalOpen,
    } = props

    const { useToken } = theme

    const { token } = useToken()

    const dispatch = useDispatch()

    const [open, setOpen] = useState(false)

    const handleOpenChange = (newOpen) => {
        setOpen(newOpen)
    }
    const dropDownIcon = [
        {
            title: <Text>View</Text>,
            icon: <ViewIcon />,
            onClick: () => {
                setOpen(false)
                dispatch(
                    updateUserGet({
                        _id: talent?._id,
                    })
                )
                talentModalOpen()
            },
        },
        talent?.isActive &&
            talent?.phone && {
                title: <Text>Login</Text>,
                icon: <BypassIcon />,
                onClick: () => {
                    talentLogin(talent)
                },
            },
        !talent?.isWorkspaceAssigned && {
            title: (
                <Text
                    style={{
                        color:
                            talent?.isActive && talent?.isOurUser
                                ? token.colorPalette.baseColor.black
                                : token.colorPalette.textColor.senary,
                    }}
                >
                    Workspace Assign
                </Text>
            ),
            icon: (
                <div
                    style={{
                        width: '20px',
                        height: '20px',
                        margin: '0px',
                    }}
                >
                    <AssignIcon
                        color={
                            (!talent?.isActive || !talent?.isOurUser) &&
                            token.colorPalette.textColor.senary
                        }
                    />
                </div>
            ),
            onClick: () => {
                dispatch(getTalentDetails(talent))
                if (
                    talent?.isActive &&
                    talent?.isOurUser &&
                    !talent?.isWorkspaceAssigned
                ) {
                    modalOpen()
                    setOpen(false)
                }
            },
        },
        talent?.isWorkspaceAssigned && {
            title: <Text>Workspace UnAssign</Text>,
            icon: (
                <div
                    style={{
                        width: '20px',
                        height: '20px',
                        margin: '0px',
                    }}
                >
                    <div
                        style={{
                            height: '17px',
                            width: '17px',
                        }}
                    >
                        <UnAssignIcon />
                    </div>
                </div>
            ),
            onClick: () => {
                setOpen(false)
                dispatch(getTalentDetails(talent))
                unAssignModalOpen()
            },
        },
        talent?.isOurUser &&
            !talent?.isWorkspaceAssigned && {
                icon: (
                    <div
                        style={{
                            width: '20px',
                            height: '20px',
                            margin: '0px',
                        }}
                    >
                        <NonVerifyTalentIcon />
                    </div>
                ),
                title: (
                    <Text
                        style={{
                            color: token.colorPalette.baseColor.error,
                        }}
                    >
                        {'Not Verified'}
                    </Text>
                ),
                onClick: () => {
                    updateStatus(talent?._id, talent?.isOurUser)
                },
            },
        !talent?.isOurUser && {
            icon: (
                <div
                    style={{
                        width: '20px',
                        height: '20px',
                        margin: '0px',
                    }}
                >
                    <VerifyTalentIcon />
                </div>
            ),
            title: (
                <Text
                    style={{
                        color: token.colorPalette.iconColor.tertiary,
                    }}
                >
                    {'Verified'}
                </Text>
            ),
            onClick: () => {
                updateStatus(talent?._id, talent?.isOurUser)
            },
        },
        {
            title: <Text>Change Rates</Text>,
            icon: (
                <div
                    style={{
                        width: '20px',
                        height: '20px',
                        margin: '0px',
                    }}
                >
                    <ChangeCostIcon />
                </div>
            ),
            onClick: () => {
                setOpen(false)
                dispatch(getTalentDetails(talent))
                addRatesModalOpen()
            },
        },
    ]

    const dropDownContent = () => {
        return (
            <>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        rowGap: '8px',
                    }}
                >
                    {dropDownIcon?.length > 0 &&
                        dropDownIcon.map((data) => {
                            return (
                                data !== false && (
                                    <Row
                                        style={{
                                            cursor: 'pointer',
                                        }}
                                        onClick={() =>
                                            data?.onClick(talent?._id)
                                        }
                                    >
                                        <div
                                            style={{
                                                marginRight: '15px',
                                            }}
                                        >
                                            {data?.icon}
                                        </div>
                                        <Text className="sub-title">
                                            {data?.title}
                                        </Text>
                                    </Row>
                                )
                            )
                        })}
                </div>
            </>
        )
    }

    return (
        <Card
            style={{
                cursor: 'pointer',
                borderRadius: '16px',
                border: 'transparent',
                boxShadow: '0 0 20px rgba(0,0,0,0.05)',
                height: '100%',
            }}
            styles={{
                body: {
                    padding: '22px 20px',
                },
            }}
        >
            <Row
                align={'start'}
                style={{
                    alignItems: 'flex-start',
                }}
                justify={'space-between'}
            >
                {talent?.profilePicture ? (
                    <>
                        <Image
                            src={talent?.profilePicture}
                            height={64}
                            width={64}
                            style={{
                                borderRadius: '50%',
                                position: 'relative',
                            }}
                            preview={false}
                        />
                        <div
                            style={{
                                width: '17px',
                                position: 'absolute',
                                left: 70,
                            }}
                        >
                            {talent?.isOurUser ? (
                                <VerifyTalentIcon />
                            ) : (
                                <NonVerifyTalentIcon />
                            )}
                        </div>
                    </>
                ) : (
                    <>
                        <Avatar
                            style={{
                                cursor: 'pointer',
                                position: 'relative',
                                backgroundColor: 'rgb(0 10 129 / 25%)',
                            }}
                            size={64}
                        >
                            {talent?.name[0].toUpperCase()}
                            {talent?.name.split(' ')[1] !== undefined &&
                                talent?.name.split(' ')[1] !== '' &&
                                talent?.name.split(' ')[1][0].toUpperCase()}
                        </Avatar>
                        <div
                            style={{
                                width: '17px',
                                position: 'absolute',
                                left: 70,
                            }}
                        >
                            {talent?.isOurUser ? (
                                <VerifyTalentIcon />
                            ) : (
                                <NonVerifyTalentIcon />
                            )}
                        </div>
                    </>
                )}
                <Row align={'middle'}>
                    <Space size={'large'}>
                        <Badge
                            count={talent?.isActive ? 'Active' : 'InActive'}
                            color={
                                talent?.isActive
                                    ? 'hsla(155, 54%, 46%, 0.16)'
                                    : 'hsla(11, 100%, 59%, 0.16)'
                            }
                            style={{
                                minWidth: '30px',
                                cursor: 'auto',
                                borderRadius: '6px',
                                display: 'flex',
                                alignItems: 'center',
                                color: talent?.isActive
                                    ? token.colorPalette.iconColor.tertiary
                                    : token.colorPalette.iconColor.quaternary,
                                padding: '12px 8px',
                                fontWeight: 700,
                            }}
                        />
                        <Popover
                            content={dropDownContent}
                            trigger="click"
                            placement="left"
                            open={open}
                            overlayInnerStyle={{
                                padding: '18px',
                            }}
                            arrow={false}
                            onOpenChange={handleOpenChange}
                        >
                            <div>
                                <EllipsisIcon />
                            </div>
                        </Popover>
                    </Space>
                </Row>
            </Row>
            <Row
                style={{
                    marginTop: '10px',
                    columnGap: '6px',
                }}
                align={'middle'}
            >
                <Title level={5}>{talent?.name}</Title>
                <Text
                    style={{
                        color: token.colorPalette.textColor.senary,
                    }}
                >
                    {talent?.designation?.name}
                </Text>
            </Row>
            <Row
                style={{
                    marginTop: '10px',
                }}
                align={'middle'}
            >
                <Space>
                    <MobileIcon />
                    <Text>
                        {talent?.countryCode}
                        {talent?.phone}
                    </Text>
                </Space>
            </Row>
            <Row
                style={{
                    marginTop: '10px',
                }}
                align={'middle'}
            >
                <Space>
                    <MailIcon />
                    <Text>{talent?.email}</Text>
                </Space>
            </Row>
            <Row
                style={{
                    marginTop: '10px',
                }}
                align={'middle'}
            >
                {talent?.isWorkspaceAssigned ? (
                    <Row
                        align={'middle'}
                        style={{
                            columnGap: '8px',
                        }}
                    >
                        {talent?.workspace?.length > 0 ? (
                            <Image
                                src={
                                    talent?.workspace[
                                        talent?.workspace?.length - 1
                                    ].logo
                                }
                                height={'25px'}
                                preview={false}
                                width={'25px'}
                                style={{
                                    borderRadius: '50%',
                                    minWidth: '25px',
                                    objectFit: 'contain',
                                }}
                            />
                        ) : (
                            'NA'
                        )}
                        <Text>
                            {
                                talent?.workspace[talent?.workspace?.length - 1]
                                    ?.companyName
                            }
                        </Text>
                    </Row>
                ) : (
                    <Text
                        style={{
                            color: token.colorPalette.baseColor.error,
                        }}
                    >
                        Unassigned
                    </Text>
                )}
            </Row>
        </Card>
    )
}

export default TalentGrid
