import { Avatar, Popover } from 'antd'

const AvatarComponent = ({ props }) => {
    const { avatarName, tooltipAvatar, avatarData } = props
    return (
        <>
            {tooltipAvatar ? (
                <Popover
                    content={avatarName}
                    trigger="hover"
                    style={{
                        textTransform: 'capitalize',
                    }}
                    key={avatarData?._id}
                >
                    <Avatar
                        style={{
                            cursor: 'pointer',
                            backgroundColor: 'rgb(0 10 129 / 25%)',
                            ...props.styleAvatar,
                        }}
                        {...props}
                    >
                        {avatarName[0].toUpperCase() ||
                            avatarName[0].toUpperCase()}
                        {avatarName.split(' ')[1] !== undefined &&
                            avatarName.split(' ')[1] !== '' &&
                            avatarName.split(' ')[1][0].toUpperCase()}
                    </Avatar>
                </Popover>
            ) : (
                <Avatar
                    style={{
                        cursor: 'pointer',
                        backgroundColor: 'rgb(0 10 129 / 25%)',
                        ...props.styleAvatar,
                    }}
                    {...props}
                >
                    {avatarName[0].toUpperCase() || avatarName[0].toUpperCase()}
                    {avatarName.split(' ')[1] !== undefined &&
                        avatarName.split(' ')[1] !== '' &&
                        avatarName.split(' ')[1][0].toUpperCase()}
                </Avatar>
            )}
        </>
    )
}

export default AvatarComponent
