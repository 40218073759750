function correctListNesting(description) {
    const parser = new DOMParser()
    const doc = parser.parseFromString(description, 'text/html')

    // Function to check if there are connected OL and UL elements
    function hasConnectedLists(node) {
        const ols = node.getElementsByTagName('ol')
        const uls = node.getElementsByTagName('ul')

        return ols.length > 0 && uls.length > 0
    }

    if (hasConnectedLists(doc.body)) {
        const parentOl = document.createElement('ol')

        function appendChildrenToParentOl(node, parentOl) {
            const childNodes = Array.from(node.childNodes)

            childNodes.forEach((child) => {
                if (child.nodeType === Node.ELEMENT_NODE) {
                    if (child.tagName === 'OL') {
                        appendChildrenToParentOl(child, parentOl)
                    } else if (child.tagName === 'LI') {
                        parentOl.appendChild(child)
                    } else if (child.tagName === 'UL') {
                        parentOl.appendChild(child)
                    } else {
                        parentOl.appendChild(child)
                    }
                } else {
                    parentOl.appendChild(child)
                }
            })
        }

        appendChildrenToParentOl(doc.body, parentOl)

        return parentOl.outerHTML
    } else {
        return description
    }
}

export function cleanDescription(description) {
    // Parse the HTML string into a document
    const parser = new DOMParser()
    const doc = parser.parseFromString(description, 'text/html')

    // Recursive function to clean empty elements and trim spaces
    function cleanNode(node) {
        if (node.nodeType === Node.ELEMENT_NODE) {
            // Remove <br> elements that are at the end of the parent node
            if (node.tagName === 'BR' && !node.nextSibling) {
                node.remove()
                return
            }

            // Clean child nodes
            let child = node.firstChild
            while (child) {
                const nextSibling = child.nextSibling
                cleanNode(child)
                child = nextSibling
            }

            // Remove empty <p> elements
            if (
                node.tagName === 'P' &&
                node.textContent.trim() === '' &&
                !node.querySelector('br')
            ) {
                node.remove()
            }
        } else if (node.nodeType === Node.TEXT_NODE) {
            // Trim text node content
            node.textContent = node.textContent.trim()
        }
    }

    // Clean the document body
    cleanNode(doc.body)

    // Remove spaces between tags by replacing them in the HTML string
    let cleanedHTML = doc.body.innerHTML.replace(/>\s+</g, '><').trim()

    return correctListNesting(cleanedHTML)
}
