import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

// antd
import {
    Avatar,
    Col,
    Divider,
    Drawer,
    Row,
    Space,
    Switch,
    Typography,
    message,
    Modal,
} from 'antd'

// header sections
import HeaderLeftSection from './headerLeftSection'
import HeaderRightSection from './headerRightSection'

// slices
import { tasksState } from '../../modules/tasks/tasks.slice'
import {
    isLoggedInLogin,
    loginState,
    signOut,
} from '../../modules/login/login.slice'
import {
    changePage,
    projectStepData,
    projectsListDropDown,
    projectsState,
} from '../../modules/projects/projects.slice'
import {
    markAsRead,
    notificationsGet,
    notificationsState,
    setReadNotification,
    statusChangeNotification,
} from '../../modules/notifications/notifications.slice'

// helpers
import { unique } from '../../helpers/uniqueArray'

// components
import DeletePopUp from '../../components/deletePopUp/deletePopUp'
import Button from '../../components/button/button'
import FormModal from '../../modules/projects/form.section'

// assets
import { NoNotificationsIcon } from '../../assets/icons'

// routes
import { PUBLIC_ROUTES } from '../../routes'

function HeaderComponent() {
    const { Text, Title } = Typography

    const dispatch = useDispatch()

    const navigate = useNavigate()

    const { globalTimer } = useSelector(tasksState)
    const { projectsListData, pageLimitList, skipPageList, pageLimit } =
        useSelector(projectsState)
    const { userProfile, socketClient } = useSelector(loginState)
    const { notificationsData, isRead } = useSelector(notificationsState)

    const initialState = {
        seconds: '00',
        minutes: '00',
        hours: '00',
        openProfile: false,
        deleteModal: false,
        deleteButtonLoading: false,
        openDrawer: false,
        filteredProjects: [],
        timer: globalTimer?.totalLog || 0,
        projectFormModal: false,
    }

    const [state, setState] = useState(initialState)

    let timeNew = 1000

    // UPDATE TIME
    function updateTime() {
        const start = globalTimer?.totalLog + timeNew
        let h, m, s
        h = Math.floor(start / 1000 / 60 / 60)
        m = Math.floor((start / 1000 / 60 / 60 - h) * 60)
        s = Math.floor(((start / 1000 / 60 / 60 - h) * 60 - m) * 60)
        setState((prevState) => ({
            ...prevState,
            hours: h,
            minutes: m,
            seconds: s,
        }))

        timeNew = timeNew + 1000
    }

    useEffect(() => {
        dispatch(
            projectsListDropDown({ limit: pageLimitList, skip: skipPageList })
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (
            globalTimer?.lastTask !== undefined ||
            globalTimer?.lastTask !== null
        ) {
            setState({ ...state, timer: globalTimer?.totalLog })
            updateTime()
            if (globalTimer?.currentStatus === 'on') {
                setState({ ...state, timer: globalTimer?.totalLog })

                const start = setInterval(updateTime, 1000)
                return () => {
                    clearInterval(start)
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [globalTimer])

    useMemo(() => {
        if (typeof document !== 'undefined' && document.addEventListener) {
            document.addEventListener('visibilitychange', (e) => {
                e.preventDefault()
            })
        }
    }, [])

    useEffect(() => {
        if (projectsListData?.length > 0) {
            let array = []
            projectsListData?.length > 0 &&
                projectsListData.forEach((data) => {
                    array = [...array, data]
                })
            let newArray = [...array]
            let finalArray = unique(newArray, '_id')
            setState((prevState) => ({
                ...prevState,
                filteredProjects: finalArray.slice(0, 5),
            }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectsListData])

    // LOGOUT
    const logOut = async () => {
        const result = await dispatch(signOut())
        const data = result?.payload?.data
        if (data) {
            const { success, message: logOutMessage } = data
            if (success) {
                if (Object.keys(socketClient).length !== 0) {
                    socketClient.disconnect()
                }
                setState({ ...state, deleteButtonLoading: false })
                message.success(logOutMessage)
                const result = await dispatch(isLoggedInLogin())

                const data = result?.payload?.data
                if (data) {
                    const { success } = data
                    if (!success) {
                        navigate(PUBLIC_ROUTES.login)
                        window.localStorage.clear()
                    }
                }
            } else {
                setState({ ...state, deleteButtonLoading: false })

                message.error('Something went wrong, try again later.')
            }
        }
    }

    // NOTIFICATIONS REDIRECTION
    async function checkType(type, routeId, userId, notification) {
        setState({ ...state, openDrawer: false })
        if (notification?.notificationData?.isReaded === false) {
            const result = await dispatch(
                statusChangeNotification({
                    notificationId: notification?._id,
                    notificationClick: { status: true },
                })
            )
            const data = result?.payload?.data
            if (data) {
                const { success } = data
                if (success) {
                    dispatch(
                        notificationsGet({
                            userId: userProfile?._id,
                            unRead: false,
                        })
                    )
                } else {
                    message.error('Something went wrong, try again later.')
                }
            }
        }
        switch (type) {
            case 'home':
                navigate(`/dashboard/${type}`)
                break

            case 'users':
                userProfile?.role?.roleName === 'admin' &&
                    navigate(`/dashboard/${type}?userId=${routeId}`)
                break

            case 'projects':
                navigate(`/dashboard/${type}/${routeId}`)
                break

            case 'notes':
                navigate(
                    `/dashboard/projects/${routeId[0]}/view-note/${routeId[1]}`
                )
                break

            case 'sprints':
                userProfile?.activeProject !== null &&
                    navigate(`/dashboard/${type}?sprintId=${routeId}`)
                break

            case 'tasks':
                userProfile?.activeProject !== null &&
                    navigate(`/dashboard/${type}?taskId=${routeId}`)
                break

            default:
                break
        }
    }

    // MARK ALL AS READ
    async function markAllRead() {
        const result = await dispatch(
            markAsRead({
                userId: userProfile?._id,
            })
        )
        const data = result?.payload?.data
        if (data) {
            const { success } = data
            if (success) {
                dispatch(
                    notificationsGet({
                        userId: userProfile?._id,
                        unRead: false,
                    })
                )
            } else {
                message.error('Something went wrong, try again later.')
            }
        }
    }

    return (
        <>
            <Drawer
                title="Notifications"
                placement="right"
                closable={false}
                onClose={() => {
                    setState({ ...state, openDrawer: false })
                }}
                open={state?.openDrawer}
                styles={{
                    body: {
                        padding: '14px 24px',
                    },
                    content: {
                        height: '100vh',
                    },
                    wrapper: {
                        minHeight: '100vh',
                    },
                }}
                extra={
                    <Space>
                        <Text>Only show unread</Text>
                        <Switch
                            onChange={(checked) => {
                                dispatch(setReadNotification())
                                if (checked) {
                                    dispatch(
                                        notificationsGet({
                                            userId: userProfile?._id,
                                            unRead: true,
                                        })
                                    )
                                } else {
                                    dispatch(
                                        notificationsGet({
                                            userId: userProfile?._id,
                                            unRead: false,
                                        })
                                    )
                                }
                            }}
                            checked={isRead}
                        />
                    </Space>
                }
            >
                {notificationsData?.data?.length > 0 ? (
                    <>
                        <Row
                            justify={'space-between'}
                            style={{
                                marginBottom: '14px',
                            }}
                        >
                            <Text>LATEST</Text>
                            {notificationsData?.data.find(
                                (ele) => !ele?.notificationData?.isReaded
                            ) && (
                                <Button
                                    props={{
                                        buttonType: 'link',
                                        text: 'Mark all as read',
                                        style: { padding: 0 },
                                        onClick: () => {
                                            markAllRead()
                                        },
                                    }}
                                />
                            )}
                        </Row>
                        {notificationsData?.data.map((notification) => {
                            const dateNotification = new Date(
                                notification?.notificationTime
                            )
                            return (
                                <>
                                    <div
                                        style={{
                                            backgroundColor:
                                                !notification?.notificationData
                                                    ?.isReaded && '#f3f3f3',
                                            padding: '10px',
                                            borderRadius: 8,
                                            columnGap: 8,
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => {
                                            checkType(
                                                notification?.notificationType,
                                                notification?.routeId,
                                                notification?.userId,
                                                notification
                                            )
                                        }}
                                        key={notification?._id}
                                    >
                                        <Row justify={'space-between'}>
                                            <Col span={20}>
                                                <Row>
                                                    <Col span={4}>
                                                        <Avatar
                                                            style={{
                                                                cursor: 'pointer',
                                                                backgroundColor:
                                                                    'rgb(0 10 129 / 25%)',
                                                            }}
                                                        >
                                                            {notification?.notificationData?.notifyBy[0].toUpperCase() ||
                                                                notification?.notificationData?.notifyBy[0].toUpperCase()}
                                                            {notification?.notificationData?.notifyBy.split(
                                                                ' '
                                                            )[1] !==
                                                                undefined &&
                                                                notification?.notificationData?.notifyBy
                                                                    .split(
                                                                        ' '
                                                                    )[1][0]
                                                                    .toUpperCase()}
                                                        </Avatar>
                                                    </Col>
                                                    <Col span={20}>
                                                        <div>
                                                            <Title
                                                                level={5}
                                                                style={{
                                                                    margin: 0,
                                                                    fontWeight: 500,
                                                                }}
                                                            >
                                                                {
                                                                    notification
                                                                        ?.notificationData
                                                                        ?.notificationBody
                                                                }
                                                            </Title>
                                                            <Text>
                                                                {dateNotification.toLocaleString(
                                                                    'default',
                                                                    {
                                                                        day: 'numeric',
                                                                        month: 'long',
                                                                        year: 'numeric',
                                                                    }
                                                                )}{' '}
                                                                at{' '}
                                                                {dateNotification.toLocaleString(
                                                                    'default',
                                                                    {
                                                                        hour: '2-digit',
                                                                        minute: '2-digit',
                                                                    }
                                                                )}
                                                            </Text>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            {!notification?.notificationData
                                                ?.isReaded && (
                                                <Col>
                                                    <div
                                                        style={{
                                                            height: '9px',
                                                            width: '9px',
                                                            borderRadius: '50%',
                                                            marginTop: '8px',
                                                            backgroundColor:
                                                                '#1677ff',
                                                        }}
                                                    />
                                                </Col>
                                            )}
                                        </Row>
                                    </div>
                                    <Divider
                                        style={{
                                            margin: '14px 0px',
                                        }}
                                    />
                                </>
                            )
                        })}
                    </>
                ) : (
                    <Row justify={'center'} align={'middle'}>
                        <div
                            style={{
                                height: '200px',
                                width: '200px',
                            }}
                        >
                            <NoNotificationsIcon />
                        </div>
                    </Row>
                )}
            </Drawer>
            <DeletePopUp
                previewDelete={state?.deleteModal}
                onDelete={() => {
                    setState({ ...state, deleteButtonLoading: true })
                    logOut()
                }}
                loadingButton={state?.deleteButtonLoading}
                onCancel={() => {
                    setState({
                        ...state,
                        deleteButtonLoading: false,
                        deleteModal: false,
                    })
                }}
                modalTitle="Log Out"
                keyModal="logout"
            />
            <Row align={'middle'} justify={'space-between'}>
                <HeaderLeftSection
                    props={{
                        filteredProjects: state?.filteredProjects,
                        setState: (data) => setState(data),
                        state: state,
                    }}
                />
                <HeaderRightSection
                    props={{
                        state: state,
                        setState: (data) => setState(data),
                    }}
                />
            </Row>
            <Modal
                centered
                footer={null}
                closable={false}
                open={state?.projectFormModal}
                destroyOnClose={true}
                width={700}
                styles={{
                    body: {
                        padding: '25px',
                    },
                }}
                onCancel={async () => {
                    setState({ ...state, projectFormModal: false })
                    dispatch(projectStepData())
                }}
            >
                <FormModal
                    onCancel={() => {
                        setState({ ...state, projectFormModal: false })
                        dispatch(projectStepData())
                    }}
                    refreshProject={async () => {
                        setState((prevState) => ({
                            ...prevState,
                            filteredProjects: [],
                        }))
                        dispatch(
                            changePage({
                                currentPage: 1,
                                skip: 0,
                                pageLimit,
                            })
                        )
                        window.location.reload()
                    }}
                />
            </Modal>
        </>
    )
}

export default React.memo(HeaderComponent)
