import axiosNew from '../../../../helpers/axios'

// GET CHAT USER DATA
export const getChatUserData = async (payload) => {
    return new Promise(async (resolve) => {
        try {
            let data = payload.fileObject ?? {}
            const getData = await axiosNew({
                url: 'http://localhost:8080/api/v1/channel/chat-channel?search=Chat&sortType=desc&sort=updatedAt',
                data,
                method: 'GET',
            })
            resolve({ data: getData?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// GET CHAT MESSAGES
export const getMessage = async (payload) => {
    const { channelId } = payload

    return new Promise(async (resolve) => {
        try {
            const getMessage = await axiosNew({
                url: 'http://localhost:8080/api/v1/messages',
                data: channelId,
            })
            resolve({ data: getMessage?.data ?? [] })
        } catch (error) {
            resolve({ data: 0 })
        }
    })
}
