import React from 'react'
import { useSelector } from 'react-redux'

import { Spin } from 'antd'

import ProfileSection from '../../profile'
import { profileState } from '../../profile/profile.slice'

const JobApplicantPersonalDetails = () => {
    const { status: profileStatus } = useSelector(profileState)

    return (
        <>
            <div
                style={{
                    overflowY: 'auto',
                    height: 'calc(100vh - 200px)',
                    marginTop: '10px',
                }}
            >
                <Spin spinning={profileStatus === 'loading' ? true : false}>
                    <ProfileSection profileOpenKey="job" pageKeyProfile="job" />
                </Spin>
            </div>
        </>
    )
}

export default JobApplicantPersonalDetails
