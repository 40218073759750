import React from 'react'

function NoStandUpsIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 612 400"
        >
            <g clipPath="url(#clip0_6558_17881)">
                <path
                    fill="#E6E6E6"
                    d="M31.352 370.155a15.017 15.017 0 0014.756-2.657c5.168-4.434 6.788-11.74 8.106-18.504l3.9-20.009-8.164 5.747c-5.872 4.133-11.875 8.398-15.94 14.384-4.065 5.987-5.84 14.158-2.574 20.636"
                ></path>
                <path
                    fill="#F2F2F2"
                    d="M32.05 394.38c-.825-6.153-1.675-12.386-1.096-18.605.515-5.523 2.163-10.918 5.52-15.341a25.154 25.154 0 016.404-5.933c.64-.413 1.23.624.593 1.036a24.146 24.146 0 00-9.388 11.579c-2.044 5.314-2.372 11.107-2.02 16.752.213 3.414.665 6.806 1.12 10.193a.624.624 0 01-.41.738.594.594 0 01-.448-.06.598.598 0 01-.274-.359z"
                ></path>
                <path
                    fill="#E6E6E6"
                    d="M40.01 383.492a11.18 11.18 0 009.8 5.171c4.962-.241 9.098-3.781 12.82-7.142l11.013-9.94-7.288-.356c-5.242-.257-10.618-.496-15.61 1.157-4.993 1.652-9.597 5.629-10.51 10.911"
                ></path>
                <path
                    fill="#F2F2F2"
                    d="M29.716 397.917c3.977-7.194 8.59-15.19 16.834-17.746a18.419 18.419 0 017.08-.747c.752.066.564 1.251-.186 1.185a17.164 17.164 0 00-11.3 3.056c-3.186 2.217-5.666 5.299-7.766 8.567-1.286 2.002-2.438 4.089-3.59 6.174-.368.666-1.445.185-1.072-.489z"
                ></path>
                <path
                    fill="#9E616A"
                    d="M241.01 182.884a8.129 8.129 0 01-3.598 10.767 7.741 7.741 0 01-1.201.473l-1.346 28.389-11.662-8.719 3.066-25.621a8.098 8.098 0 014.209-8.967 7.775 7.775 0 016.042-.38 7.779 7.779 0 014.49 4.058zM258.054 387.427l7.539-1.522-2.157-30.451-11.127 2.246 5.745 29.727z"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M255.645 385.3l14.846-2.999h.001a9.403 9.403 0 017.237 1.481 9.808 9.808 0 014.094 6.28l.061.315-24.309 4.909-1.93-9.986z"
                ></path>
                <path
                    fill="#9E616A"
                    d="M231.622 391.889h7.685l3.656-30.302h-11.342l.001 30.302z"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M229.662 389.324l15.134-.001a9.487 9.487 0 016.82 2.888 9.904 9.904 0 012.825 6.972v.32l-24.778.001-.001-10.18z"
                ></path>
                <path
                    fill="#3F3D56"
                    d="M127.402 326.869c-8.53-.004-15.441-7.076-15.437-15.797 0-2.102.413-4.184 1.214-6.128l2.274.984a13.375 13.375 0 006.919 17.375 12.707 12.707 0 009.84.1l.917 2.345a15.072 15.072 0 01-5.727 1.121z"
                ></path>
                <path
                    fill="#3F3D56"
                    d="M151.176 326.553h-19.143v-5.049l15.438-4.42 3.705 9.469z"
                ></path>
                <path
                    fill="#3F3D56"
                    d="M144.383 321.503h50.636a10.62 10.62 0 01-10.498 10.732h-29.64a10.618 10.618 0 01-10.498-10.732z"
                ></path>
                <path
                    fill="#CACACA"
                    d="M199.794 327.888h-56.193a7.577 7.577 0 010-15.15h56.193a7.575 7.575 0 015.25 12.873 7.58 7.58 0 01-5.25 2.277z"
                ></path>
                <path
                    fill="#E6E6E6"
                    d="M236.08 210.714l-.107 5.102-.477 22.915a11.529 11.529 0 01-3.459 8.132 11.547 11.547 0 01-8.198 3.299 11.287 11.287 0 01-7.491-3.077l-31.94-30.72a12.046 12.046 0 01-2.911-4.245 12.044 12.044 0 01.444-10.047 12.051 12.051 0 013.275-3.972 11.484 11.484 0 0115.652 1.402l21.094 24.264 2.026-15.248 12.092 2.195z"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M243.925 379.42l-15.101 1.403-8.236-61.045c-27.15 4.669-52.764 6.165-74.918.517a14.318 14.318 0 01-10.241-17.3c.086-.358.186-.713.299-1.064 2.651-8.09 7.323-16.759 12.791-25.656l36.378 13.331 1.372 5.614 38.802-5.021a14.307 14.307 0 0115.917 12.615c.052.421.086.844.102 1.269l2.835 75.337z"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M266.882 366.565l-15.1 1.403-27.38-55.995c-27.15 4.669-52.763 6.165-74.918.517a14.321 14.321 0 01-10.241-17.3c.086-.358.186-.713.299-1.064 2.652-8.09 7.323-16.759 12.791-25.657l42.212 3.552-4.461 15.393 38.801-5.021a14.306 14.306 0 0115.918 12.616c.052.421.086.844.102 1.268l21.977 70.288z"
                ></path>
                <path
                    fill="#9E616A"
                    d="M195.731 186.906c10.235 0 18.532-8.482 18.532-18.945 0-10.463-8.297-18.945-18.532-18.945s-18.532 8.482-18.532 18.945c0 10.463 8.297 18.945 18.532 18.945z"
                ></path>
                <path
                    fill="#3F3D56"
                    d="M216.895 199.134a1.484 1.484 0 00.545 1.991c.496.061 12.141 7.572 12.473 6.712a1.438 1.438 0 00.865-.707l18.741-35.082a1.491 1.491 0 00.068-1.26c.518-.904-12.92-4.371-13.378-4.642a1.41 1.41 0 00-1.66.689l-17.654 32.299z"
                ></path>
                <path
                    fill="#6C63FF"
                    d="M246.828 171.308s-11.247-3.166-11.057-3.3c-.305-.013-.919-.452-1.115.001l-16.693 30.767c-.718.67 11.081 6.149 11.181 6.474.932.587 17.412-33.193 17.92-33.366a.403.403 0 00-.236-.576z"
                ></path>
                <path
                    fill="#E4E4E4"
                    d="M206.104 197.359a5.12 5.12 0 00.326-1.318 5.025 5.025 0 00-4.371-5.571l-8.616-.968a4.96 4.96 0 00-4.889 2.605 4.97 4.97 0 00-.561 1.864 5.12 5.12 0 00.769 3.302 22.754 22.754 0 00-19.267 15.941c-8.512 15.82-14.639 34.119-17.522 55.794l38.249 18.164 14.568-31.16 2.932-4.599a43.38 43.38 0 001.634-1.831 40.55 40.55 0 007.159-11.527 30.281 30.281 0 002.09-9.467c.584-10.388-4.289-20.799-12.501-31.229z"
                ></path>
                <path
                    fill="#000"
                    d="M216.516 238.054a40.545 40.545 0 01-7.16 11.528 42.747 42.747 0 01-1.634 1.83l-2.931 4.6c-10.807-8.1-19.562-39.993-19.562-39.993l12.192-2.372 19.095 24.407z"
                    opacity="0.2"
                ></path>
                <path
                    fill="#9E616A"
                    d="M223.897 188.676a8.148 8.148 0 002.751 11.025c.371.221.759.412 1.16.569l-.863 28.408 12.302-7.744-1.066-25.79a8.126 8.126 0 00-3.499-9.28 7.78 7.78 0 00-5.986-.871 7.759 7.759 0 00-2.772 1.356 7.76 7.76 0 00-2.027 2.327z"
                ></path>
                <path
                    fill="#E6E6E6"
                    d="M225.863 217.955l13.529 1.924-.582 28.015a11.727 11.727 0 01-5.731 9.879 11.236 11.236 0 01-13.416-1.523l-31.94-30.722a12.112 12.112 0 01-.893-16.629 11.493 11.493 0 017.989-4.047 11.484 11.484 0 018.466 2.92c.316.282.616.581.9.896l21.09 24.262.588-14.975z"
                ></path>
                <path
                    fill="#CACACA"
                    d="M115.194 312.107a11.88 11.88 0 01-11.732-11.994v-67.546a11.737 11.737 0 017.182-11.076 11.73 11.73 0 019.101 0 11.753 11.753 0 013.839 2.611 11.752 11.752 0 013.343 8.465v67.546a11.878 11.878 0 01-11.733 11.994z"
                ></path>
                <path
                    fill="#3F3D56"
                    d="M188.844 391.298h-14.203v-61.832h-4.94v61.832h-14.202a8.117 8.117 0 00-8.028 8.206h49.4a8.116 8.116 0 00-8.027-8.206z"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M211.848 146.492c-3.959-2.329-8.779-2.334-13.347-2.276l-9.93.126c-4.018.051-8.258.165-11.681 2.317-3.422 2.152-5.496 7.11-3.236 10.507-6.721-5.97-16.786-6.913-25.209-3.968-4.123 1.441-8.024 3.78-10.824 7.193-3.041 3.706-4.625 8.479-5.261 13.273-.637 4.795-.388 9.66-.139 14.492.158 3.038.502 6.464 2.84 8.345 2.158 1.736 5.319 1.474 7.825.327 4.368-2.001 7.389-6.194 9.647-10.509 2.258-4.316 3.979-8.956 6.79-12.917 2.812-3.961 7.053-7.275 11.84-7.332-3.255 4.722-3.849 11.584-.447 16.196 2.447 3.317 6.529 5.038 10.57 5.476 4.04.437 8.107-.258 12.113-.951 2.713-.469 5.903-1.33 6.801-3.989.88-2.607-1.007-5.24-1.815-7.871-1.559-5.079 1.639-11.149 6.645-12.613l-.408-.403c4.145.591 9.11.043 11.317-3.592 2.409-3.969-.131-9.501-4.091-11.831z"
                ></path>
                <path
                    fill="#CACACA"
                    d="M610.774 19.05H254.132a.53.53 0 010-1.059h356.642a.53.53 0 010 1.059z"
                ></path>
                <path
                    fill="#3F3D56"
                    d="M265.782 11.642c3.144 0 5.694-2.607 5.694-5.821 0-3.215-2.55-5.821-5.694-5.821-3.145 0-5.694 2.606-5.694 5.82 0 3.215 2.549 5.822 5.694 5.822zM285.452 11.642c3.144 0 5.694-2.607 5.694-5.821 0-3.215-2.55-5.821-5.694-5.821-3.145 0-5.694 2.606-5.694 5.82 0 3.215 2.549 5.822 5.694 5.822zM305.122 11.642c3.144 0 5.693-2.607 5.693-5.821 0-3.215-2.549-5.821-5.693-5.821-3.145 0-5.694 2.606-5.694 5.82 0 3.215 2.549 5.822 5.694 5.822zM598.99 3.56h-13.976a1.06 1.06 0 01-.734-1.798 1.06 1.06 0 01.734-.318h13.976a1.06 1.06 0 010 2.116zM598.99 7.529h-13.976a1.06 1.06 0 01-.734-1.799 1.06 1.06 0 01.734-.318h13.976a1.06 1.06 0 010 2.117zM598.99 11.498h-13.976a1.06 1.06 0 01-.734-1.799 1.06 1.06 0 01.734-.318h13.976a1.06 1.06 0 01.733 1.799 1.06 1.06 0 01-.733.318z"
                ></path>
                <path
                    fill="#6C63FF"
                    d="M540.888 186.106H310.275a4.329 4.329 0 01-4.271-4.366V78.28a4.323 4.323 0 014.271-4.367h230.613a4.323 4.323 0 014.271 4.366V181.74a4.324 4.324 0 01-4.271 4.366z"
                ></path>
                <path
                    fill="#fff"
                    d="M357.239 109.975a2.966 2.966 0 00-2.054 5.038c.542.554 1.28.873 2.054.89h136.685a2.963 2.963 0 002.05-5.035 2.964 2.964 0 00-2.05-.893H357.239zM357.238 127.15a2.963 2.963 0 00-2.051 5.035 2.968 2.968 0 002.051.893h136.684a2.968 2.968 0 002.895-2.964 2.966 2.966 0 00-2.895-2.964H357.238zM357.239 144.116a2.966 2.966 0 00-2.054 5.037c.542.554 1.28.874 2.054.891h58.816a2.964 2.964 0 000-5.928h-58.816z"
                ></path>
                <path
                    fill="#3F3D56"
                    d="M541.312 197.254c9.608 0 17.396-7.962 17.396-17.784 0-9.821-7.788-17.783-17.396-17.783-9.607 0-17.396 7.962-17.396 17.783 0 9.822 7.789 17.784 17.396 17.784z"
                ></path>
                <path
                    fill="#fff"
                    d="M539.861 187.334l-5.521-7.257 3.211-2.554 2.614 3.436 8.831-9.53 2.954 2.86-12.089 13.045z"
                ></path>
                <path
                    fill="#CACACA"
                    d="M400.442 400H.736a.75.75 0 01-.736-.752.754.754 0 01.736-.751h399.706a.754.754 0 01.736.751.749.749 0 01-.736.752z"
                ></path>
            </g>
            <defs>
                <clipPath id="clip0_6558_17881">
                    <path fill="#fff" d="M0 0H611.292V400H0z"></path>
                </clipPath>
            </defs>
        </svg>
    )
}

export default NoStandUpsIcon
