import React from 'react'

const RightArrow = () => {
    return (
        <svg width="5" height="10" viewBox="0 0 5 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 10L5 5L0 0V10Z" fill="#212B36" />
        </svg>
    )
}

export default RightArrow