import React from 'react'

function BypassIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="none"
            viewBox="0 0 20 20"
        >
            <path
                fill="#000"
                d="M8.167 9.082H.879a9.168 9.168 0 110 1.833h7.288v2.75L12.75 10 8.167 6.332v2.75z"
            ></path>
        </svg>
    )
}

export default BypassIcon
