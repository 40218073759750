import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { getRoadmapList } from './roadmap.service'

const initialState = {
    search: '',
    status: 'loaded',
    roadmapTasks: [],
    pageLimit: 30,
    skipPage: 0,
    schedulesCount: 0,
    selectedSchedule: undefined,
    selectedFormat: 'year',
    slots: [],
    slotWidth: undefined,
}

export const roadmapList = createAsyncThunk(
    'roadmapSlice/getRoadmapList',
    async (payload) => {
        const response = await getRoadmapList(payload)
        return response
    }
)

export const roadmapSlice = createSlice({
    name: 'roadmapSlice',
    initialState,
    reducers: {
        setSlots: (state, action) => {
            state.slots = action.payload
        },
        setSlotWidth: (state, action) => {
            state.slotWidth = action.payload
        },
        setRoadMapTask: (state, action) => {
            state.roadmapTasks = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(roadmapList.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(roadmapList.fulfilled, (state, action) => {
                state.status = 'loaded'
                state.roadmapTasks = action.payload.data
            })
    },
})

export default roadmapSlice.reducer

export const roadmapState = (state) => {
    const { roadmapSlice } = state
    const {
        search,
        status,
        schedules,
        schedulesCount,
        selectedSchedule,
        selectedFormat,
        slots,
        slotWidth,
        roadmapTasks,
        pageLimit,
        skipPage,
    } = roadmapSlice
    return {
        search,
        status,
        schedules,
        schedulesCount,
        selectedSchedule,
        selectedFormat,
        slots,
        slotWidth,
        roadmapTasks,
        pageLimit,
        skipPage,
    }
}

export const { setSlots, setSlotWidth, setRoadMapTask } = roadmapSlice.actions
