import React from 'react'

function NoFilesIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 601 400"
        >
            <g clipPath="url(#clip0_6556_17828)">
                <path
                    fill="#FFB6B6"
                    d="M381.524 222.146a8.183 8.183 0 006.365-9.557 7.89 7.89 0 00-.394-1.365l21.839-18.826-14.278-4.039-18.549 18.496a8.219 8.219 0 00-3.928 10.847 7.999 7.999 0 008.945 4.444z"
                ></path>
                <path
                    fill="#F2F2F2"
                    d="M523.334 355.648l.793-17.847a57.635 57.635 0 0126.886-6.805c-12.913 10.557-11.299 30.907-20.054 45.103a34.663 34.663 0 01-25.424 16.022l-10.806 6.616a58.09 58.09 0 0112.246-47.078 56.128 56.128 0 0110.313-9.583c2.588 6.824 6.046 13.572 6.046 13.572z"
                ></path>
                <path
                    fill="#FFB6B6"
                    d="M495.492 389.553l-9.785-.001-4.654-37.74 14.441.001-.002 37.74z"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M497.987 399.037l-31.549-.001v-.399a12.282 12.282 0 0112.279-12.28h.001l5.763-4.372 10.752 4.373h2.754v12.679z"
                ></path>
                <path
                    fill="#FFB6B6"
                    d="M450.035 389.553l-9.785-.001-4.654-37.74 14.441.001-.002 37.74z"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M452.53 399.037l-31.55-.001v-.399a12.288 12.288 0 013.597-8.683 12.285 12.285 0 018.683-3.597h.001l5.763-4.372 10.752 4.373h2.754v12.679zM445.799 183.226l63.642 1.591c12.088 6.565 12.271 20.774 8.311 37.644 0 0 1.337 10.027-1.337 12.701-2.674 2.674-4.011 2.674-2.674 7.354 1.337 4.679-5.727 12.322-4.869 13.18.858.858 2.863 5.537 2.863 5.537l-6.684 38.103s-4.68 66.18-6.017 67.517c-1.337 1.337-2.674 0-1.337 3.342 1.337 3.343 2.674 2.006 1.337 3.343a49.245 49.245 0 00-3.342 4.011H479.53s-1.219-6.685-1.219-7.354c0-.668-1.337-4.679-1.337-5.347 0-.669 1.182-1.851 1.182-1.851a22.04 22.04 0 00.824-4.166c0-1.337 4.011-52.81 4.011-52.81l-8.022-66.848-19.386 65.511s0 57.49-1.337 58.827c-1.337 1.337-1.337.668-.668 3.342.668 2.674 3.342 2.005 1.336 4.011-2.005 2.005-2.673-1.337-2.005 2.005l.669 3.343-20.055.287s-2.674-5.635-1.337-7.641c1.337-2.005 1.257-1.458-.374-4.739-1.631-3.282-2.3-3.951-1.631-4.619.668-.669.668-4.224.668-4.224l3.343-63.961s.668-67.517.668-69.522a6.684 6.684 0 00-.555-3.008v-2.722l2.561-9.645 8.933-23.192z"
                ></path>
                <path
                    fill="#E6E6E6"
                    d="M380.787 200.433l10.756 8.673 58.635-45.451-3.469-79.105a31.952 31.952 0 00-11.813 21.582l-10.046 44.686-44.063 49.615z"
                ></path>
                <path
                    fill="#E6E6E6"
                    d="M436.301 203.208c20.755 2.653 39.878-5.384 57.941-11.449 8.155-2.739 16.37.12 23.94-4.511-3.273-32.244-.095-68.564 6.859-107.269a9.173 9.173 0 00-6.236-10.371l-22.407-6.429-6.899-7.61-17.817 4.523-6.237 11.273-21.433 14.482a7.83 7.83 0 00-3.336 7.828 256.899 256.899 0 01-4.375 109.533z"
                ></path>
                <path
                    fill="#FFB6B6"
                    d="M502.542 213.188a8.19 8.19 0 003.516-10.931 7.95 7.95 0 00-.751-1.207l15.873-24.07-14.838.01-12.798 22.856a8.212 8.212 0 00-.82 11.507 7.996 7.996 0 009.818 1.835z"
                ></path>
                <path
                    fill="#E6E6E6"
                    d="M498.059 190.718l9.714 12.49 34.002-50.655-2.941-51.463a38.925 38.925 0 00-19.958-31.807l.694 79.106-21.511 42.329z"
                ></path>
                <path
                    fill="#FFB6B6"
                    d="M473.646 52.624c12.009 0 21.744-9.735 21.744-21.744s-9.735-21.744-21.744-21.744-21.744 9.735-21.744 21.744c0 12.01 9.735 21.744 21.744 21.744z"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M459.792 18.12c.841.006 1.678.116 2.493.326 1.606.386 3.236.66 4.88.819a22.617 22.617 0 0120.25 19.182c1.263-1.102 4.138-3.128 6.495-1.245.036.03.057.045.14.006 1.565-.742 4.343-9.88 4.427-17.927.044-4.27-.628-9.723-4.082-11.45l-.182-.091-.048-.198c-.23-.96-2.809-2.6-7.289-3.877-8.143-2.32-20.281-6.837-27.116-.188-.518 2.242-2.201 7.039-3.901 7.582-1.882.602-3.508 1.122-3.764 3.43a70.635 70.635 0 00.047 9.868 10.07 10.07 0 013.579-5.029 7.097 7.097 0 014.071-1.209z"
                ></path>
                <path
                    fill="#CCC"
                    d="M600.697 399.174a.821.821 0 01-.826.826H.826a.825.825 0 110-1.651h599.046a.819.819 0 01.825.825z"
                ></path>
                <path
                    fill="#E6E6E6"
                    d="M247.917 70.17l-81.621 18.363a8.467 8.467 0 00-6.394 10.107l22.381 99.482 95.9-21.576a4.195 4.195 0 002.077-1.146l-22.236-98.836a8.463 8.463 0 00-10.107-6.394z"
                ></path>
                <path
                    fill="#fff"
                    d="M218.268 83.868l-44.73 10.064a7.71 7.71 0 00-5.825 9.202l21.043 93.532 85.173-19.162-15.325-68.116a33.787 33.787 0 00-40.336-25.52z"
                ></path>
                <path
                    fill="#E4E4E4"
                    d="M257.552 172.461l-56.153 12.634a1.866 1.866 0 00-1.176.826 1.874 1.874 0 001.267 2.853c.243.042.492.035.733-.019l56.152-12.633a1.88 1.88 0 001.442-1.51 1.87 1.87 0 00-1.533-2.171 1.898 1.898 0 00-.732.02zM255.923 165.223l-56.153 12.633a1.876 1.876 0 10.825 3.664l56.152-12.633a1.878 1.878 0 10-.824-3.664zM222.483 165.145l-24.341 5.476a1.879 1.879 0 00-1.419 2.242 1.877 1.877 0 002.242 1.419l24.341-5.477a1.879 1.879 0 001.442-1.51 1.884 1.884 0 00-.316-1.403 1.893 1.893 0 00-.531-.505 1.866 1.866 0 00-1.418-.242zM250.883 142.831l-56.152 12.634a1.873 1.873 0 00-1.174.828 1.875 1.875 0 00.582 2.59c.421.267.931.355 1.417.246l56.152-12.634a1.886 1.886 0 001.175-.828 1.885 1.885 0 00.245-1.416 1.858 1.858 0 00-.298-.669 1.861 1.861 0 00-1.214-.769 1.884 1.884 0 00-.733.018zM249.257 135.597l-56.153 12.633a1.884 1.884 0 00-1.176.826 1.884 1.884 0 00.052 2.086 1.865 1.865 0 001.215.768c.243.042.492.035.733-.02l56.152-12.633a1.876 1.876 0 10-.823-3.66zM215.816 135.515l-24.341 5.477a1.873 1.873 0 00-1.174.828 1.869 1.869 0 00.052 2.085 1.869 1.869 0 001.947.751l24.341-5.477a1.886 1.886 0 001.175-.828 1.885 1.885 0 00-.052-2.086 1.906 1.906 0 00-.531-.505 1.897 1.897 0 00-1.417-.245z"
                ></path>
                <path
                    fill="#E6E6E6"
                    d="M244.217 113.202l-56.152 12.633a1.876 1.876 0 10.825 3.664l56.152-12.633a1.889 1.889 0 001.175-.828 1.897 1.897 0 00.245-1.417 1.875 1.875 0 00-2.245-1.419zM242.591 105.967L186.438 118.6a1.873 1.873 0 00-1.174.828 1.876 1.876 0 001.999 2.836l56.152-12.633a1.886 1.886 0 001.175-.828 1.89 1.89 0 00.245-1.416 1.878 1.878 0 00-2.244-1.42zM209.15 105.885l-24.341 5.477a1.876 1.876 0 00-.591 3.419c.42.266.93.354 1.416.245l24.341-5.477a1.886 1.886 0 001.175-.828 1.885 1.885 0 00.245-1.416 1.873 1.873 0 00-1.512-1.438 1.884 1.884 0 00-.733.018z"
                ></path>
                <path
                    fill="#3F3D56"
                    d="M85.083 185.595a2.008 2.008 0 00-2.007 2.007v207.512a2.012 2.012 0 002.007 2.007h260.179a2.006 2.006 0 002.006-2.007V220.912a2.006 2.006 0 00-2.006-2.007H195.936a6.087 6.087 0 01-4.099-1.584l-33.57-30.502a4.699 4.699 0 00-3.166-1.224H85.083z"
                ></path>
                <path
                    fill="#E6E6E6"
                    d="M395.583 131.32L256.271 71.428a15.346 15.346 0 00-20.136 8.028l-72.998 169.796 163.683 70.37a7.62 7.62 0 004.267.528l72.524-168.694a15.345 15.345 0 00-8.028-20.136z"
                ></path>
                <path
                    fill="#fff"
                    d="M337.5 119.877l-76.346-32.822a13.977 13.977 0 00-18.338 7.306l-68.632 159.641L319.558 316.5l49.981-116.259a61.243 61.243 0 00-32.039-80.364z"
                ></path>
                <path
                    fill="#E4E4E4"
                    d="M300.888 291.675l-95.841-41.204a3.414 3.414 0 00-2.605-.037 3.39 3.39 0 00-1.867 1.816 3.395 3.395 0 00.691 3.718c.312.32.684.575 1.095.751l95.841 41.203a3.395 3.395 0 003.719-.684c.32-.312.576-.684.753-1.094a3.395 3.395 0 00-.691-3.718 3.396 3.396 0 00-1.095-.751zM306.199 279.32l-95.841-41.203a3.405 3.405 0 00-4.47 1.783 3.405 3.405 0 001.781 4.471l95.841 41.203a3.398 3.398 0 003.719-.688 3.405 3.405 0 00-1.03-5.566zM257.211 243.63l-41.545-17.861a3.412 3.412 0 00-3.717.685 3.408 3.408 0 00-1.029 2.393 3.411 3.411 0 00.966 2.418c.311.32.683.575 1.094.751l41.545 17.861a3.397 3.397 0 003.717-.686 3.387 3.387 0 001.029-2.392 3.408 3.408 0 00-.965-2.418 3.396 3.396 0 00-1.095-.751zM322.628 241.103l-95.841-41.204a3.403 3.403 0 00-2.688 6.254l95.841 41.204a3.414 3.414 0 002.607.037 3.406 3.406 0 001.902-4.425 3.387 3.387 0 00-.725-1.114 3.376 3.376 0 00-1.096-.752zM327.937 228.754l-95.841-41.203a3.399 3.399 0 00-4.748 3.077 3.393 3.393 0 002.062 3.17l95.841 41.204a3.39 3.39 0 002.604.038 3.412 3.412 0 001.867-1.817 3.394 3.394 0 00-.69-3.718 3.396 3.396 0 00-1.095-.751zM278.954 193.057l-41.545-17.861a3.405 3.405 0 00-4.505 4.388 3.404 3.404 0 001.816 1.866l41.546 17.861a3.394 3.394 0 002.605.036 3.408 3.408 0 001.902-4.423 3.4 3.4 0 00-1.819-1.867z"
                ></path>
                <path
                    fill="#6C63FF"
                    d="M344.371 190.53l-95.84-41.203a3.403 3.403 0 00-2.689 6.254l95.841 41.203a3.394 3.394 0 002.605.036 3.399 3.399 0 002.144-3.117 3.413 3.413 0 00-.966-2.42 3.401 3.401 0 00-1.095-.753zM349.679 178.182l-95.841-41.203a3.403 3.403 0 00-2.688 6.253l95.84 41.204a3.407 3.407 0 004.481-1.779 3.405 3.405 0 00-1.792-4.475zM300.696 142.485l-41.545-17.861a3.405 3.405 0 00-4.47 1.783 3.405 3.405 0 001.781 4.471l41.546 17.861a3.413 3.413 0 002.605.035 3.396 3.396 0 001.867-1.817 3.403 3.403 0 00-1.784-4.472z"
                ></path>
                <path
                    fill="#E6E6E6"
                    d="M197.263 192.992l-81.621 18.363a8.468 8.468 0 00-6.395 10.107l22.382 99.482 95.9-21.576a4.195 4.195 0 002.077-1.146l-22.236-98.836a8.47 8.47 0 00-10.107-6.394z"
                ></path>
                <path
                    fill="#fff"
                    d="M167.614 206.69l-44.73 10.064a7.708 7.708 0 00-5.825 9.202l21.042 93.532 85.174-19.162-15.325-68.116a33.787 33.787 0 00-40.336-25.52z"
                ></path>
                <path
                    fill="#E4E4E4"
                    d="M206.895 295.283l-56.152 12.634a1.878 1.878 0 00-1.422 2.242c.054.241.155.468.298.67a1.865 1.865 0 001.215.767c.243.042.492.035.733-.019l56.152-12.633a1.88 1.88 0 001.441-1.51 1.865 1.865 0 00-.315-1.403 1.868 1.868 0 00-1.217-.768 1.904 1.904 0 00-.733.02zM205.266 288.045l-56.152 12.633a1.88 1.88 0 00-.592 3.419c.421.266.931.355 1.416.245l56.153-12.633a1.876 1.876 0 10-.825-3.664zM171.829 287.967l-24.341 5.477a1.881 1.881 0 00-1.174.827 1.867 1.867 0 00-.245 1.414 1.866 1.866 0 00.827 1.174 1.88 1.88 0 001.415.245l24.341-5.476a1.88 1.88 0 001.442-1.51 1.883 1.883 0 00-.316-1.404 1.872 1.872 0 00-1.949-.747zM200.229 265.654l-56.152 12.633a1.878 1.878 0 10.824 3.664l56.153-12.633a1.88 1.88 0 001.419-2.244 1.876 1.876 0 00-2.244-1.42zM198.602 258.419l-56.152 12.633a1.876 1.876 0 10.824 3.66l56.152-12.633a1.871 1.871 0 001.438-1.51 1.879 1.879 0 00-.846-1.906 1.872 1.872 0 00-1.416-.244zM165.162 258.337l-24.341 5.477a1.878 1.878 0 10.824 3.664l24.342-5.477a1.876 1.876 0 00.591-3.419 1.88 1.88 0 00-1.416-.245z"
                ></path>
                <path
                    fill="#E6E6E6"
                    d="M193.563 236.024l-56.152 12.633a1.878 1.878 0 10.824 3.664l56.153-12.633a1.873 1.873 0 001.438-1.512 1.878 1.878 0 00-2.263-2.152zM191.934 228.789l-56.152 12.633a1.878 1.878 0 10.824 3.664l56.153-12.633a1.874 1.874 0 001.419-2.244 1.876 1.876 0 00-2.244-1.42zM158.496 228.707l-24.341 5.477a1.878 1.878 0 10.824 3.664l24.342-5.477a1.873 1.873 0 001.174-.828 1.869 1.869 0 00-.052-2.085 1.869 1.869 0 00-1.947-.751z"
                ></path>
                <path
                    fill="#3F3D56"
                    d="M83.359 240.099a2.004 2.004 0 00-1.855 1.238c-.1.244-.152.505-.152.768l.061 154.622a2.007 2.007 0 001.995 1.782h279.488a2.007 2.007 0 001.994-1.782l17.396-154.396a1.996 1.996 0 00-.497-1.562 1.988 1.988 0 00-1.496-.67H83.358z"
                ></path>
                <path
                    fill="#6C63FF"
                    d="M103.082 248.655a10.794 10.794 0 00-10.762 10.668l.35 119.774a10.766 10.766 0 006.621 10.028c1.312.547 2.719.828 4.14.828h177.417a82.694 82.694 0 0082.238-73.492l6.292-55.839a10.775 10.775 0 00-2.67-8.376 10.76 10.76 0 00-8.025-3.591H103.082zm271.882 0l-.032-.694h.032v.694z"
                ></path>
                <path
                    fill="#E4E4E4"
                    d="M180.579 338.39H111.85a2.679 2.679 0 010-5.356h68.729c3.453-.048 3.488 5.404 0 5.356zM273.165 352.397H111.85a2.678 2.678 0 110-5.356h161.315c3.453-.048 3.488 5.404 0 5.356z"
                ></path>
            </g>
            <defs>
                <clipPath id="clip0_6556_17828">
                    <path fill="#fff" d="M0 0H600.697V400H0z"></path>
                </clipPath>
            </defs>
        </svg>
    )
}

export default NoFilesIcon
