import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// antd
import {
    Col,
    Form,
    Input,
    Row,
    Select as NewSelect,
    Space,
    Avatar,
    Typography,
    DatePicker,
    theme,
    Spin,
    Popover,
    Popconfirm,
    Button as ButtonAnt,
    message,
    Tabs,
    Divider,
    Tooltip,
} from 'antd'
import { PauseCircleFilled, InfoCircleOutlined } from '@ant-design/icons'

// components
import Button from '../../components/button/button'

// slices
import { projectsState } from '../projects/projects.slice'
import {
    getSubTaskList,
    selectSubTaskUpdate,
    selectTaskUpdate,
    switchFormModalSubTask,
    taskById,
    taskCreate,
    taskDelete,
    taskList,
    taskUpdate,
    tasksState,
} from './tasks.slice'
import { loginState } from '../login/login.slice'

// assets
import {
    DeleteIcon,
    EllipsisIcon,
    TaskIcon,
    TaskLinkIcon,
    TimerIcon,
} from '../../assets/icons'

// constants
import { ESTIMATION_PATTERN } from '../../constants/patterns'

// sections
import CommentsSection from './comments.section'
import ActivitySection from './activity.section'
import AttachmentFileSection from './attachment.section'

// services
import { startStopTimer } from './tasks.service'

// helpers
import {
    deletePermissionCheck,
    editFieldCheck,
    viewFieldCheck,
} from '../../helpers/permissionCheck'

// styles
import styles from './tasks.module.css'

import dayjs from 'dayjs'
import DeletePopUp from '../../components/deletePopUp/deletePopUp'

const SubTaskDrawerForm = ({
    assigneeList,
    onCancel,
    swimLanes,
    taskType,
    sprints,
    epics,
    // selectedSubTask,
}) => {
    const { Text } = Typography

    const { TextArea } = Input

    const { useToken } = theme

    const { token } = useToken()

    const { RangePicker } = DatePicker

    const { Option } = NewSelect

    const dispatch = useDispatch()

    const { currentProjectData } = useSelector(projectsState)
    const { userProfile } = useSelector(loginState)
    const {
        pageLimit,
        skipPage,
        selectedSubTask,
        selectedTask,
        taskStatus,
        formSubTask,
    } = useSelector(tasksState)

    const initialValues = useMemo(() => {
        return {
            title: '',
            type: [],
            epic: [],
            status: [],
            rangePicker: [],
            sprintId: [],
            estimation: 0,
            start: '',
            end: '',
            assigneeId: [],
            description: '',
            projectId: JSON.parse(localStorage.getItem('currentProject'))?.id,
        }
    }, [])

    const formatDatePicker = (value) => {
        return `${dayjs(value).format('DD MMM YY')}`
    }

    const [sprintsOption] = useState(sprints || [])
    const [epicsOption] = useState(epics || [])
    const [swimLanesOption] = useState(swimLanes || [])
    const [taskTypeOption] = useState(taskType || [])
    const [valuesChild, setValuesChild] = useState(initialValues)
    const [errorsChild, setErrorsChild] = useState(initialValues)
    const [updatedValuesChild, setUpdatedValuesChild] = useState({})
    const [subTaskLoadingButton, setSubTaskLoadingButton] = useState(false)
    const [timer, setTimer] = useState(false)
    const [taskID, setTaskID] = useState('')
    const [deleteCheck, setDeleteCheck] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const [deleteButtonLoading, setDeleteButtonLoading] = useState(false)

    const [open, setOpen] = useState(false)

    const handleOpenChange = (newOpen) => {
        setOpen(newOpen)
        if (newOpen === true) {
            setDeleteCheck(true)
        } else {
            setDeleteCheck(false)
        }
    }

    const dropDownIcon = [
        {
            title: deletePermissionCheck('tasks', userProfile) ? (
                'Delete'
            ) : (
                <Tooltip
                    arrow={false}
                    placement="bottom"
                    title="You don't have permission to delete task"
                >
                    <Text
                        style={{
                            margin: '0px',
                        }}
                    >
                        Delete
                    </Text>
                </Tooltip>
            ),
            icon: <DeleteIcon />,
            onClick: (_id) => {
                if (deletePermissionCheck('tasks', userProfile)) {
                    setDeleteCheck(true)
                    setTaskID(_id)
                    setDeleteModal(true)
                }
            },
        },
    ]

    const dropDownContent = (id) => {
        return (
            <>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        rowGap: '8px',
                    }}
                >
                    {dropDownIcon?.length > 0 &&
                        dropDownIcon.map((data) => (
                            <Row
                                style={{
                                    cursor: 'pointer',
                                }}
                                align={'middle'}
                                onClick={() => data?.onClick(id)}
                            >
                                <div
                                    style={{
                                        marginRight: '10px',
                                    }}
                                >
                                    {data?.icon}
                                </div>
                                <Text className="sub-title">{data?.title}</Text>
                            </Row>
                        ))}
                </div>
            </>
        )
    }

    const [subTaskForm] = Form.useForm()

    const tabs = [
        {
            key: 'comments',
            label: 'Comments',
            children: <CommentsSection />,
        },
        {
            key: 'activity',
            label: 'Activity',
            children: <ActivitySection />,
        },
    ]

    useEffect(() => {
        if (selectedSubTask !== undefined) {
            const { _id: value, title: label } = { ...selectedTask?.epic[0] }
            const { _id: valueAssignee, name: labelAssignee } = {
                ...selectedSubTask?.assigneeId[0],
            }
            const { _id: valueSprint, title: labelSprint } = {
                ...selectedTask?.sprintId[0],
            }
            const type = [
                {
                    label: selectedSubTask?.type,
                    value: selectedSubTask?.type,
                },
            ]
            const swimLanes = [
                {
                    label: selectedSubTask?.status,
                    value: selectedSubTask?.status,
                },
            ]

            const new_obj_assignee = Object.assign(
                {},
                {
                    label: labelAssignee && (
                        <Space size="small">
                            <Avatar
                                style={{
                                    backgroundColor: '#f56a00',
                                    cursor: 'pointer',
                                    height: '30px',
                                    width: '30px',
                                }}
                            >
                                {labelAssignee &&
                                    labelAssignee[0].toUpperCase()}
                            </Avatar>
                            <Text
                                style={{
                                    margin: 0,
                                }}
                            >
                                {labelAssignee && labelAssignee}
                            </Text>
                        </Space>
                    ),
                    value: valueAssignee,
                }
            )

            const new_obj = Object.assign(
                {},
                {
                    label,
                    value,
                }
            )
            const new_obj_sprint = Object.assign(
                {},
                {
                    label: labelSprint,
                    value: valueSprint,
                }
            )

            subTaskForm.setFieldsValue({
                ...selectedSubTask,
                type,
                rangePicker: [
                    selectedSubTask?.start
                        ? dayjs(selectedSubTask?.start, 'YYYY/MM/DD')
                        : '',
                    selectedSubTask?.end
                        ? dayjs(selectedSubTask?.end, 'YYYY/MM/DD')
                        : '',
                ],
                start: selectedSubTask?.start
                    ? dayjs(selectedSubTask?.start, 'YYYY/MM/DD')
                    : '',
                end: selectedSubTask?.end
                    ? dayjs(selectedSubTask?.end, 'YYYY/MM/DD')
                    : '',
                sprintId: [{ ...new_obj_sprint }],
                epic: [{ ...new_obj }],
                assigneeId:
                    new_obj_assignee?.label === undefined &&
                    new_obj_assignee?.value === undefined
                        ? []
                        : [{ ...new_obj_assignee }],
                status: swimLanes,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formSubTask, selectedSubTask])

    // UPDATE DETAILS SUB TASK
    function updateDetailsSubTask(name, value) {
        setValuesChild({
            ...valuesChild,
            [name]: value,
        })
        setErrorsChild({ ...errorsChild, [name]: '' })
        if (name === 'assigneeId' || name === 'sprint' || name === 'epic') {
            if (valuesChild[name][0]?.value !== value) {
                setUpdatedValuesChild({
                    ...updatedValuesChild,
                    [name]: value,
                })
            }
        } else {
            if (valuesChild[name] !== value) {
                setUpdatedValuesChild({
                    ...updatedValuesChild,
                    [name]: value,
                })
            }
        }
    }

    // ON FINISH CHILD TASK
    const onFinishChildTask = async () => {
        setSubTaskLoadingButton(true)

        const result = selectedSubTask
            ? await dispatch(
                  taskUpdate({
                      updateTask: {
                          ...updatedValuesChild,
                      },
                      _id: selectedSubTask?._id,
                  })
              )
            : await dispatch(
                  taskCreate({
                      title: updatedValuesChild.title,
                      parent: selectedTask?._id,
                      projectId: JSON.parse(
                          localStorage.getItem('currentProject')
                      )?.id,
                      sprintId: selectedTask?.sprintId[0]?._id,
                  })
              )
        const data = result?.payload?.data
        if (data) {
            const { success, message: checkMessage } = data
            if (success) {
                setSubTaskLoadingButton(false)
                // onCreateChild()
                dispatch(
                    taskList({
                        limit: pageLimit,
                        skip: skipPage,
                        filter: {
                            projectId: JSON.parse(
                                localStorage.getItem('currentProject')
                            )?.id,
                            sprintId: JSON.parse(
                                localStorage.getItem('currentSprint')
                            )?.id,
                        },
                    })
                )
                message.success(checkMessage)
            } else {
                setSubTaskLoadingButton(false)
                dispatch(switchFormModalSubTask({ open: false }))
                onCancel()
                if (checkMessage) {
                    setErrorsChild(checkMessage)
                } else {
                    message.error('Something went wrong, try again later.')
                }
            }
            setUpdatedValuesChild({})
        }
    }

    const CopyTextButton = ({ text }) => {
        const handleCopy = () => {
            navigator.clipboard
                .writeText(text)
                .then(() => {
                    message.success('Link copied!')
                })
                .catch(() => {
                    message.error('Failed to copy link')
                })
        }

        return (
            <div
                style={{
                    height: '20px',
                    width: '20px',
                    cursor: 'pointer',
                }}
                onClick={handleCopy}
            >
                <TaskLinkIcon />
            </div>
        )
    }

    const handleStartTimer = async () => {
        try {
            const res = await startStopTimer({
                id: selectedSubTask?._id,
                data: { type: 'start' },
            })

            if (res?.data?.success) {
                setTimer(true)
                const result = await dispatch(
                    taskById({ _id: selectedSubTask?._id })
                )
                const data = result?.payload?.data
                if (data) {
                    dispatch(selectSubTaskUpdate(data?.data[0]))
                    message.success(res?.data?.message)
                    if (selectedTask && selectedTask?.currentStatus === 'on') {
                        const result = await dispatch(
                            taskById({ _id: selectedTask?._id })
                        )
                        const dataTask = result?.payload?.data
                        if (dataTask) {
                            dispatch(selectTaskUpdate(dataTask?.data[0]))
                        } else {
                            message.error(
                                'Something went wrong, try again later.'
                            )
                        }
                    }
                } else {
                    message.error('Something went wrong, try again later.')
                }
            } else {
                setTimer(false)
                message.error(res?.data?.message)
            }
        } catch (error) {
            message.error(error)
        }
    }

    // CHECK ESTIMATION
    function checkEstimation(value) {
        let estimateMinutes = `0m`
        let estimateHours = `0h`
        let estimateWeeks = `0w`
        let estimateDays = `0d`
        const defaultTime = currentProjectData?.defaultTime
        const defaultWeek = currentProjectData?.defaultWeek
        let minutes = value
        if (minutes > 60) {
            estimateMinutes = `${minutes % 60}m`
            const hours = parseInt(minutes / 60)
            if (hours >= defaultTime) {
                estimateHours = `${hours % defaultTime}h`
                const days = parseInt(hours / defaultTime)
                if (days > defaultWeek) {
                    estimateDays = `${days % defaultWeek}d`
                    estimateWeeks = `${parseInt(days / defaultWeek)}w`
                } else {
                    estimateDays = `${days}d`
                }
            } else {
                estimateHours = `${hours}h`
            }
        } else {
            estimateMinutes = `${minutes}m`
        }

        const estimationNew = `${
            estimateWeeks.split('w')[0] !== '0' ? `${estimateWeeks} ` : ''
        }${estimateDays.split('d')[0] !== '0' ? `${estimateDays} ` : ''}${
            estimateHours.split('h')[0] !== '0' ? `${estimateHours} ` : ''
        }${estimateMinutes.split('m')[0] !== '0' ? estimateMinutes : ''}`
        subTaskForm.setFieldValue(
            'estimation',
            estimationNew.trimStart().trimEnd()
        )
        return estimationNew
    }

    // CHECK ESTIMATION
    function checkFinalEstimation(value) {
        let estimateMinutes = 0

        const splitValue = value.split(' ')

        const defaultTime = currentProjectData?.defaultTime
        const defaultWeek = currentProjectData?.defaultWeek

        splitValue.forEach((data) => {
            if (data.includes('w')) {
                let week = parseInt(data.split('w')[0])
                estimateMinutes += week * defaultWeek * defaultTime * 60
            }
            if (data.includes('d')) {
                let week = parseInt(data.split('d')[0])
                estimateMinutes += week * defaultTime * 60
            }
            if (data.includes('h')) {
                let week = parseInt(data.split('h')[0])
                estimateMinutes += week * 60
            }
            if (data.includes('m')) {
                let week = parseInt(data.split('m')[0])
                estimateMinutes += week
            } else if (
                !data.includes('w') &&
                !data.includes('d') &&
                !data.includes('h') &&
                !data.includes('m')
            ) {
                estimateMinutes = parseInt(data)
            }

            updateDetailsSubTask('estimation', estimateMinutes)
            checkEstimation(estimateMinutes)
        })
    }

    const onFinishFailed = () => {}

    // PAUSE CONTENT
    const pauseContent = (
        <Row
            style={{
                gap: 10,
            }}
            align={'middle'}
        >
            <InfoCircleOutlined
                style={{
                    color: '#ffd713',
                }}
            />
            <Text
                style={{
                    fontWeight: 500,
                }}
            >
                If you want to pause the timer, Please Pause it from the Time
                Tracking App
            </Text>
        </Row>
    )

    // DELETE TASK
    async function deleteTask() {
        const result = await dispatch(taskDelete(taskID))
        const data = result?.payload?.data
        const sprintId = JSON.parse(localStorage.getItem('currentSprint'))?.id

        if (data) {
            const { success, message: checkMessage } = data
            if (success) {
                setDeleteButtonLoading(false)
                setDeleteModal(false)
                setTaskID('')
                dispatch(switchFormModalSubTask({ open: false }))
                message.success(checkMessage)

                await dispatch(
                    getSubTaskList({
                        skip: 0,
                        limit: 10,
                        parentId: selectedTask?._id,
                    })
                )
            } else {
                setDeleteModal(false)
                setDeleteButtonLoading(false)
                setTaskID('')
                if (checkMessage) {
                    message.error(checkMessage)
                } else {
                    message.error('Something went wrong, try again later.')
                }
            }
        }
    }

    return (
        <>
            <DeletePopUp
                previewDelete={deleteModal}
                onDelete={() => {
                    setDeleteButtonLoading(true)
                    deleteTask()
                    setDeleteCheck(false)
                }}
                loadingButton={deleteButtonLoading}
                onCancel={() => {
                    setDeleteCheck(false)
                    setDeleteButtonLoading(false)
                    setDeleteModal(false)
                }}
                modalTitle="Delete Sub Task"
                pageKey="Task"
            />
            <Spin spinning={taskStatus === 'loading' ? true : false}>
                <Form
                    layout="vertical"
                    name="addSubTaskForm"
                    onFinish={onFinishChildTask}
                    onFinishFailed={onFinishFailed}
                    form={subTaskForm}
                    initialValues={{
                        ...valuesChild,
                    }}
                    requiredMark={false}
                >
                    <div
                        style={{
                            backgroundColor:
                                token.colorPalette.baseColor.quaternary,
                            padding: '30px',
                        }}
                    >
                        <Row
                            align={'middle'}
                            justify={'space-between'}
                            style={{
                                width: '100%',
                                marginBottom: '27px',
                            }}
                        >
                            {viewFieldCheck(
                                'tasks',
                                'subTask',
                                userProfile
                            ) && (
                                <Col span={14}>
                                    <Row
                                        align={'middle'}
                                        style={{
                                            width: '100%',
                                        }}
                                    >
                                        <Col span={1}>
                                            <TaskIcon />
                                        </Col>
                                        <Col span={23}>
                                            <Form.Item
                                                name="title"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: '',
                                                        whitespace: true,
                                                    },
                                                    {
                                                        max: 400,
                                                        message:
                                                            'Title should be maximum 400 characters long',
                                                    },
                                                ]}
                                                colon={false}
                                            >
                                                <Input
                                                    placeholder={
                                                        'Enter Task Title'
                                                    }
                                                    name={'title'}
                                                    readOnly={
                                                        editFieldCheck(
                                                            'tasks',
                                                            'subTask',
                                                            userProfile
                                                        ) === false
                                                    }
                                                    className={
                                                        styles.taskSelect
                                                    }
                                                    style={{
                                                        border: 'none',
                                                        backgroundColor:
                                                            'rgba(145, 158, 171, 0.08)',
                                                        borderRadius: '8px',
                                                    }}
                                                    bordered={false}
                                                    onChange={(event) => {
                                                        updateDetailsSubTask(
                                                            event.target.name,
                                                            event.target.value
                                                        )
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                            )}
                            {selectedSubTask && (
                                <Col span={10}>
                                    <Row
                                        align={'middle'}
                                        style={{
                                            columnGap: '20px',
                                        }}
                                        justify={'end'}
                                    >
                                        <CopyTextButton
                                            text={`${window.location.origin}${window.location.pathname}?subTaskId=${selectedSubTask?._id}?perentId=${selectedSubTask?.parentTask[0]?._id}`}
                                        />
                                        <Form.Item name="status" colon={false}>
                                            <NewSelect
                                                placeholder="Select a Status"
                                                className={[
                                                    // styles.taskSelect,
                                                    'arrowStatusSelect',
                                                ]}
                                                onChange={(event) =>
                                                    updateDetailsSubTask(
                                                        'status',
                                                        event
                                                    )
                                                }
                                                style={{
                                                    height: '40px !important',
                                                }}
                                                allowClear={false}
                                            >
                                                {swimLanesOption?.length > 0 &&
                                                    swimLanesOption.map(
                                                        (data) => (
                                                            <Option
                                                                value={
                                                                    data?.label
                                                                }
                                                                key={
                                                                    data?.label
                                                                }
                                                            >
                                                                {data?.label}
                                                            </Option>
                                                        )
                                                    )}
                                            </NewSelect>
                                        </Form.Item>

                                        {timer ||
                                        selectedSubTask?.currentStatus ===
                                            'on' ? (
                                            <Popover
                                                content={pauseContent}
                                                trigger={'click'}
                                            >
                                                <ButtonAnt
                                                    style={{
                                                        padding: '7px',
                                                        fontSize: '16px',
                                                        backgroundColor:
                                                            '#f5f6f8',
                                                        color: '#000000',
                                                    }}
                                                >
                                                    <Row
                                                        align={'middle'}
                                                        style={{
                                                            columnGap: '4px',
                                                        }}
                                                    >
                                                        <PauseCircleFilled
                                                            style={{
                                                                marginTop: 1,
                                                                color: '#d60606',
                                                            }}
                                                            className={'in-btn'}
                                                        />
                                                        <Text>Stop Timer</Text>
                                                    </Row>
                                                </ButtonAnt>
                                            </Popover>
                                        ) : (
                                            <Popconfirm
                                                title="Start the Timer"
                                                cancelButtonProps={{
                                                    hidden: true,
                                                }}
                                                overlayInnerStyle={{
                                                    width: '400px',
                                                }}
                                                description="Before you start your timer on this task, please make sure that your time doctor is currently active on the same project as this task."
                                                onConfirm={() => {
                                                    handleStartTimer()
                                                }}
                                                okText="Confirm"
                                                okButtonProps={{
                                                    style: {
                                                        height: 'unset',
                                                        backgroundColor:
                                                            token.colorPalette
                                                                .baseColor
                                                                .tertiary,
                                                    },
                                                }}
                                            >
                                                <ButtonAnt
                                                    style={{
                                                        padding: '7px',
                                                        fontSize: '16px',
                                                        backgroundColor:
                                                            '#f5f6f8',
                                                        color: '#000000',
                                                    }}
                                                >
                                                    <Row
                                                        align={'middle'}
                                                        style={{
                                                            columnGap: '3px',
                                                        }}
                                                    >
                                                        <TimerIcon />
                                                        <Text>Start Timer</Text>
                                                    </Row>
                                                </ButtonAnt>
                                            </Popconfirm>
                                        )}
                                        <Popover
                                            content={dropDownContent(
                                                selectedSubTask?._id
                                            )}
                                            trigger="click"
                                            placement="bottom"
                                            open={open}
                                            overlayInnerStyle={{
                                                padding: '10px',
                                            }}
                                            arrow={false}
                                            onOpenChange={handleOpenChange}
                                        >
                                            <div
                                                style={{
                                                    cursor: 'pointer',
                                                }}
                                            >
                                                <EllipsisIcon />
                                            </div>
                                        </Popover>
                                    </Row>
                                </Col>
                            )}
                        </Row>
                        <Row gutter={[10, 10]}>
                            <Col span={4}>
                                <Form.Item
                                    // label="Assignee"
                                    name="assigneeId"
                                    colon={false}
                                >
                                    <NewSelect
                                        placeholder="Select a Assignee"
                                        onChange={(event) =>
                                            updateDetailsSubTask(
                                                'assigneeId',
                                                event
                                            )
                                        }
                                        bordered={false}
                                        className={[
                                            styles.taskSelect,
                                            'arrowTaskSelect',
                                        ]}
                                        disabled={
                                            editFieldCheck(
                                                'tasks',
                                                'subTask',
                                                userProfile
                                            ) === false
                                        }
                                        allowClear={false}
                                    >
                                        {assigneeList.map((option) => {
                                            return (
                                                <Option
                                                    style={{
                                                        textTransform:
                                                            'capitalize',
                                                    }}
                                                    key={option?.value}
                                                    value={option?.value}
                                                >
                                                    <Space size="small">
                                                        <Avatar
                                                            style={{
                                                                backgroundColor:
                                                                    '#f56a00',
                                                                cursor: 'pointer',
                                                                height: '30px',
                                                                width: '30px',
                                                            }}
                                                        >
                                                            {option?.label[0].toUpperCase()}
                                                        </Avatar>
                                                        <Text
                                                            style={{
                                                                margin: 0,
                                                            }}
                                                        >
                                                            {option?.label}
                                                        </Text>
                                                    </Space>
                                                </Option>
                                            )
                                        })}
                                    </NewSelect>
                                </Form.Item>
                            </Col>
                            <Col span={3}>
                                <Form.Item
                                    name="epic"
                                    rules={[
                                        {
                                            required: true,
                                            message: '',
                                        },
                                    ]}
                                    colon={false}
                                >
                                    <NewSelect
                                        placeholder="Select a Epic"
                                        className={[
                                            styles.taskSelect,
                                            'arrowTaskSelect',
                                        ]}
                                        bordered={false}
                                        onChange={(event) =>
                                            updateDetailsSubTask('epic', event)
                                        }
                                        dropdownStyle={{
                                            textTransform: 'capitalize',
                                        }}
                                        disabled={
                                            editFieldCheck(
                                                'tasks',
                                                'subTask',
                                                userProfile
                                            ) === false
                                        }
                                        allowClear={false}
                                    >
                                        {epicsOption?.length > 0 &&
                                            epicsOption.map((data) => (
                                                <Option
                                                    value={data?.value}
                                                    key={data?.label}
                                                >
                                                    <Space>
                                                        <Text>
                                                            {data?.epicKey}
                                                        </Text>
                                                        <Text>
                                                            {data?.label}
                                                        </Text>
                                                    </Space>
                                                </Option>
                                            ))}
                                    </NewSelect>
                                </Form.Item>
                            </Col>

                            <Col span={3}>
                                <Form.Item
                                    name="type"
                                    rules={[
                                        {
                                            required: true,
                                            message: '',
                                        },
                                    ]}
                                    colon={false}
                                >
                                    <NewSelect
                                        placeholder="Select a Type"
                                        className={[
                                            styles.taskSelect,
                                            'arrowTaskSelect',
                                        ]}
                                        bordered={false}
                                        onChange={(event) =>
                                            updateDetailsSubTask('type', event)
                                        }
                                        disabled={
                                            editFieldCheck(
                                                'tasks',
                                                'subTask',
                                                userProfile
                                            ) === false
                                        }
                                        allowClear={false}
                                    >
                                        {taskTypeOption?.length > 0 &&
                                            taskTypeOption.map((data) => (
                                                <Option
                                                    value={data?.value}
                                                    key={data?.label}
                                                >
                                                    {data?.label}
                                                </Option>
                                            ))}
                                    </NewSelect>
                                </Form.Item>
                            </Col>
                            <Col span={3}>
                                <Form.Item
                                    name="estimation"
                                    colon={false}
                                    rules={[
                                        {
                                            validator: async (_, names) => {
                                                if (!names) {
                                                    return Promise.resolve()
                                                }

                                                const pattern =
                                                    ESTIMATION_PATTERN

                                                const patternNumber = /^[0-9]$/

                                                if (
                                                    !pattern.test(names) &&
                                                    !patternNumber.test(names)
                                                ) {
                                                    return Promise.reject(
                                                        new Error(
                                                            'Your estimate must be in the format 2w 4d 6h 45m.'
                                                        )
                                                    )
                                                }
                                            },
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder={'2w 4d 6h 45m'}
                                        bordered={false}
                                        className={styles.taskSelect}
                                        style={{
                                            border: 'none',
                                            backgroundColor:
                                                'rgba(145, 158, 171, 0.08)',
                                            borderRadius: '8px',
                                        }}
                                        name={'estimation'}
                                        onBlurCapture={(event) => {
                                            if (event.target.value?.length > 0)
                                                checkFinalEstimation(
                                                    event.target.value.trimEnd()
                                                )
                                        }}
                                        readOnly={
                                            editFieldCheck(
                                                'tasks',
                                                'subTask',
                                                userProfile
                                            ) === false
                                        }
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item name="sprintId" colon={false}>
                                    <NewSelect
                                        placeholder="Select a Sprint"
                                        className={[
                                            styles.taskSelect,
                                            'arrowTaskSelect',
                                        ]}
                                        bordered={false}
                                        onChange={(event) =>
                                            updateDetailsSubTask(
                                                'sprintId',
                                                event
                                            )
                                        }
                                        allowClear={false}
                                        disabled={
                                            editFieldCheck(
                                                'tasks',
                                                'subTask',
                                                userProfile
                                            ) === false
                                        }
                                    >
                                        {sprintsOption?.length > 0 &&
                                            sprintsOption.map((data) => (
                                                <Option
                                                    value={data?.value}
                                                    key={data?.label}
                                                >
                                                    {data?.label}
                                                </Option>
                                            ))}
                                    </NewSelect>
                                </Form.Item>
                            </Col>
                            <Col span={5}>
                                <Form.Item
                                    colon={false}
                                    name={'rangePicker'}
                                    className="rangePickerTask"
                                >
                                    <RangePicker
                                        picker={'date'}
                                        allowClear={false}
                                        name={'rangePicker'}
                                        format={formatDatePicker}
                                        style={{
                                            width: '100%',
                                        }}
                                        disabled={
                                            editFieldCheck(
                                                'tasks',
                                                'subTask',
                                                userProfile
                                            ) === false
                                        }
                                        bordered={false}
                                        className={[styles.taskSelect]}
                                        onChange={(event) => {
                                            if (
                                                subTaskForm.getFieldValue(
                                                    'start'
                                                ) !== event[0] &&
                                                selectedTask
                                            ) {
                                                setUpdatedValuesChild({
                                                    ...updatedValuesChild,
                                                    start: event[0],
                                                })
                                            }
                                            if (
                                                subTaskForm.getFieldValue(
                                                    'end'
                                                ) !== event[1] &&
                                                selectedTask
                                            ) {
                                                setUpdatedValuesChild({
                                                    ...updatedValuesChild,
                                                    end: event[1],
                                                })
                                            }
                                            setValuesChild({
                                                ...valuesChild,
                                                start: event[0],
                                                end: event[1],
                                            })
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                    <div
                        style={{
                            minHeight: '100%',
                        }}
                    >
                        <Row
                            style={{
                                padding: '30px',
                                width: '100%',
                            }}
                        >
                            <Col span={13}>
                                <Row
                                    style={{
                                        width: '100%',
                                    }}
                                >
                                    <Col span={24}>
                                        <Form.Item
                                            name="description"
                                            colon={false}
                                        >
                                            <TextArea
                                                rows={6}
                                                placeholder={
                                                    'Add a Description...'
                                                }
                                                name={'description'}
                                                readOnly={
                                                    editFieldCheck(
                                                        'tasks',
                                                        'subTask',
                                                        userProfile
                                                    ) === false
                                                }
                                                onChange={(event) =>
                                                    updateDetailsSubTask(
                                                        event.target.name,
                                                        event.target.value
                                                    )
                                                }
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Row
                                        style={{
                                            marginTop: '20px',
                                            width: '100%',
                                        }}
                                    >
                                        <Button
                                            props={{
                                                text: 'Update',
                                                htmlType: 'submit',
                                                style: {
                                                    width: '100%',
                                                },
                                                // loadingButton: taskLoadingButton,
                                            }}
                                        />
                                    </Row>
                                </Row>
                                <AttachmentFileSection />
                            </Col>
                            <Col span={1}>
                                <Divider type="vertical" />
                            </Col>
                            <Col span={10}>
                                <Row>
                                    <Col span={24}>
                                        <Tabs
                                            defaultActiveKey={'sub-tasks'}
                                            items={tabs}
                                            className="generalTabs"
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </Form>
                <Row></Row>
            </Spin>
        </>
    )
}

export default SubTaskDrawerForm
