import React from 'react'
import JobDetailsBanner from './components/JobDetailsBanner'
import { Card } from 'antd'

const JobApplication = () => {
    return (
        <div>
            <Card>
                <JobDetailsBanner />
            </Card>
        </div>
    )
}

export default JobApplication