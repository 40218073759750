import React from 'react'

function CrossIcon({ color }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 13 13"
        >
            <path
                fill={color ? color : '#fff'}
                fillRule="evenodd"
                d="M12.208 10.817L7.644 6.253l4.564-4.563A.99.99 0 0010.809.29L6.246 4.855 1.683.29A.985.985 0 10.289 1.683l4.565 4.564L.29 10.81a.99.99 0 101.398 1.4l4.564-4.566 4.563 4.566a.99.99 0 001.398 0 .982.982 0 00-.004-1.394z"
                clipRule="evenodd"
            ></path>
        </svg>
    )
}

export default CrossIcon
