import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// antd
import { DatePicker, Row, Tooltip, theme } from 'antd'
import { DownloadOutlined } from '@ant-design/icons'

// slices
import {
    clearProjects,
    projectsList,
    projectsState,
} from '../projects/projects.slice'

// components
import Button from '../../components/button/button'

// modal
import DownloadPopUp from './reportsTimeSheet/downloadPopUp'

import dayjs from 'dayjs'

const pdfDivStyle = {
    margin: '0px 10px',
}

const DateFilter = ({ props }) => {
    const { dateRangesList, name } = props

    const { useToken } = theme

    const { token } = useToken()

    const dispatch = useDispatch()

    const { skipPage } = useSelector(projectsState)

    const [pickerVal, setPickerVal] = useState('day')
    const [changedVal, setChangeVal] = useState(null)
    const [changedFormat, setChangeFormat] = useState('DD/MM/YYYY')
    const [dateRanges, setDateRanges] = useState({ start: null, end: null })
    const [downloadModal, setDownloadModal] = useState(false)

    // SELECT DATE
    const handleDayPick = () => {
        setChangeFormat('DD/MM/YYYY')
        setPickerVal('day')
    }

    // SELECT WEEK
    const handleWeekPic = () => {
        setChangeFormat('DD/MM')
        setPickerVal('week')
    }

    // SELECT MONTH
    const handleMonthPic = () => {
        setChangeFormat('MM/YYYY')
        setPickerVal('month')
    }

    // CHANGE DATE
    const handleChangeDate = (val) => {
        if (val === undefined || val === null) {
            setChangeVal(null)
            setDateRanges({ start: null, end: null })
        } else {
            setChangeVal(val)
            let startDate = null
            let endDate = null

            if (pickerVal === 'day') {
                startDate = new Date(val).setHours(0, 0, 0, 0)
                endDate = new Date(val).setHours(23, 59, 59, 999)

                setDateRanges({ start: startDate, end: endDate })
            } else if (pickerVal === 'week') {
                let weekStartDate = new Date(val).getDate() - val['$W']
                startDate = new Date(val).setDate(weekStartDate)

                let weekEndDate = new Date(startDate).getDate() + 6
                let weekLastDate = new Date(val).setDate(weekEndDate)

                endDate = new Date(weekLastDate).setHours(23, 59, 59, 999)

                setDateRanges({ start: startDate, end: endDate })
            } else if (pickerVal === 'month') {
                let startMonth = new Date(val)
                let endMonth = new Date(
                    startMonth.getFullYear(),
                    startMonth.getMonth() + 1,
                    0
                )

                endDate = new Date(endMonth).setHours(23, 59, 59, 999)
                setDateRanges({
                    start: new Date(startMonth).getTime(),
                    end: endDate,
                })
            }
        }
    }

    useEffect(() => {
        let startDate = null
        let endDate = null

        if (pickerVal === 'day') {
            setChangeVal(
                dayjs(dayjs(new Date()).format('DD/MM/YYYY'), changedFormat)
            )
            startDate = new Date().setHours(0, 0, 0, 0)
            endDate = new Date().setHours(23, 59, 59, 999)

            setDateRanges({ start: startDate, end: endDate })
        } else if (pickerVal === 'week') {
            setChangeVal(
                dayjs(dayjs(new Date()).format('DD/MM'), changedFormat)
            )
            let now = new Date()
            let weekStartDate = now.getDate() - now.getDay()
            let startDate = new Date(now.setDate(weekStartDate))
            startDate.setHours(0, 0, 0, 0) // Ensure time is set to start of the day

            let weekEndDate = new Date(startDate)
            weekEndDate.setDate(startDate.getDate() + 6)
            weekEndDate.setHours(23, 59, 59, 999) // Ensure time is set to end of the day

            const lastDayOfMonth = new Date(
                weekEndDate.getFullYear(),
                weekEndDate.getMonth() + 1,
                0
            ).getDate()

            if (weekEndDate.getDate() > lastDayOfMonth) {
                weekEndDate = new Date(
                    weekEndDate.getFullYear(),
                    weekEndDate.getMonth() + 1,
                    weekEndDate.getDate() - lastDayOfMonth
                )
                weekEndDate.setHours(23, 59, 59, 999)
            }

            setDateRanges({
                start: startDate.getTime(),
                end: weekEndDate.getTime(),
            })
        } else if (pickerVal === 'month') {
            setChangeVal(
                dayjs(dayjs(new Date()).format('MM/YYYY'), changedFormat)
            )

            let startMonth = new Date(
                new Date().getFullYear(),
                new Date().getMonth(),
                1
            )
            let endMonth = new Date(
                startMonth.getFullYear(),
                startMonth.getMonth() + 1,
                0
            )

            endDate = new Date(endMonth).setHours(23, 59, 59, 999)
            setDateRanges({
                start: new Date(startMonth).getTime(),
                end: endDate,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pickerVal])

    useEffect(() => {
        if (pickerVal && dateRanges) {
            dispatch(
                dateRangesList({
                    dateRanges,
                    dateType: pickerVal,
                })
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateRanges])

    useEffect(() => {
        if (localStorage.getItem('authToken') && downloadModal) {
            dispatch(projectsList({ limit: 30, skip: skipPage }))
        }
        return () => dispatch(clearProjects())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [downloadModal])

    return (
        <>
            <DownloadPopUp
                previewModal={downloadModal}
                onComplete={() => {}}
                onCancel={() => {
                    setDownloadModal(false)
                }}
                modalTitle="Download Reports"
            />
            <Row
                align={'middle'}
                style={{
                    columnGap: '20px',
                    width: '100%',
                    flexFlow: 'row nowrap',
                }}
                justify={'end'}
            >
                <div className="reportsDate">
                    <DatePicker
                        picker={pickerVal}
                        format={changedFormat}
                        value={changedVal}
                        onChange={handleChangeDate}
                    />
                </div>
                <Button
                    props={{
                        text: 'Day',
                        onClick: handleDayPick,
                        style: {
                            backgroundColor:
                                pickerVal !== 'day' && 'transparent',
                            color:
                                pickerVal !== 'day' &&
                                token.colorPalette.textColor.senary,
                        },
                    }}
                />
                <Button
                    props={{
                        text: 'Week',
                        onClick: handleWeekPic,
                        style: {
                            color:
                                pickerVal !== 'week' &&
                                token.colorPalette.textColor.senary,
                            backgroundColor:
                                pickerVal !== 'week' && 'transparent',
                        },
                    }}
                />
                <Button
                    props={{
                        text: 'Month',
                        onClick: handleMonthPic,
                        style: {
                            color:
                                pickerVal !== 'month' &&
                                token.colorPalette.textColor.senary,
                            backgroundColor:
                                pickerVal !== 'month' && 'transparent',
                        },
                    }}
                />
                {name === 'timeSheet' && (
                    <div style={pdfDivStyle}>
                        <Tooltip title="Export as PDF">
                            <DownloadOutlined
                                onClick={() => setDownloadModal(true)}
                            />
                        </Tooltip>
                    </div>
                )}
            </Row>
        </>
    )
}

export default DateFilter
