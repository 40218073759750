// antd
import { Typography } from 'antd'

// assets
import { NoWorkSpaceIcon } from '../../assets/icons'

function NoTaskFound() {
    const { Title, Text } = Typography

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                columnGap: '35px',
            }}
        >
            <NoWorkSpaceIcon />
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Title level={3}>No task found!</Title>
                <Text>Please Contact Admin</Text>
            </div>
        </div>
    )
}

export default NoTaskFound
