import React from 'react'

function ChangeCostIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="20"
            fill="none"
            viewBox="0 0 18 20"
        >
            <path
                fill="#000"
                d="M14.004 14.003h2v-12h-10v2h8v10zm0 2v3c0 .552-.45 1-1.007 1H1.01a1.001 1.001 0 01-1.007-1l.003-14c0-.552.45-1 1.006-1h2.99v-3a1 1 0 011-1h12a1 1 0 011 1v14a1 1 0 01-1 1h-3zm-10-2v2h2v1h2v-1h.5a2.5 2.5 0 100-5h-3a.5.5 0 110-1h4.5v-2h-2v-1h-2v1h-.5a2.5 2.5 0 000 5h3a.5.5 0 010 1h-4.5z"
            ></path>
        </svg>
    )
}

export default ChangeCostIcon
