import axiosNew from '../../helpers/axios'

// CREATE PROJECT
export const create = async (payload) => {
    return new Promise(async (resolve) => {
        try {
            let data = payload ?? {}
            const create = await axiosNew({
                url: 'projects/create',
                data,
            })
            resolve({ data: create?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// COUNT TOTAL PROJECTS
export const count = async (payload) => {
    return new Promise(async (resolve) => {
        try {
            let data = {}
            if (payload?.length > 0) {
                data['search'] = {
                    name: payload,
                    client: payload,
                }
            }
            const count = await axiosNew({
                url: 'projects/count',
                data,
            })
            resolve({ data: count?.data?.result ?? 0 })
        } catch (error) {
            resolve({ data: 0 })
        }
    })
}

// LIST PROJECTS / LIST SINGLE PROJECT BY ID
export const read = (payload) => {
    const { search = '', skip = 0, id, filter = {}, limit = 0 } = payload
    return new Promise(async (resolve) => {
        try {
            let data = {
                skip,
                filter,
                limit,
                select: [
                    'name',
                    'client',
                    'latitude',
                    'longitude',
                    'radius',
                    'manager',
                    'start',
                    'end',
                    'projectKey',
                    'statusHistory',
                    'clientNumber',
                    'summary',
                    'description',
                    'status',
                    'teamMembers',
                    'files',
                    'notes',
                    'swimlanes',
                    'taskType',
                    'isActiveProject',
                    'defaultTime',
                    'defaultWeek',
                    'defaultScreenshotTime',
                    'defaultIdealTime',
                    'companyName',
                    'projectType',
                    'projectProfile',
                    'projectLead',
                ],
                populate: {
                    name: 'projectLead',
                    val: ['name', 'profilePicture'],
                },
            }
            if (search?.length > 0) {
                data['search'] = {
                    name: search,
                    client: search,
                }
            }
            const projects = await axiosNew({
                url: `projects/read${id ? `/${id}` : ''}`,
                data,
            })
            resolve({
                data: projects?.data?.result ?? [],
                isNext: projects?.data?.isNext,
            })
        } catch (error) {
            resolve({ data: [] })
        }
    })
}

// GET PROJECT
export const getProject = (payload) => {
    const { id } = payload

    const data = {
        populate: {
            name: [
                // {
                //     path: 'teamMembers',
                //     select: 'name profilePicture',
                // },
                {
                    path: 'projectLead',
                    select: 'name profilePicture',
                },
            ],
        },
        filter: {},
    }
    return new Promise(async (resolve) => {
        try {
            const projects = await axiosNew({
                url: `projects/read/${id}`,
                data,
            })
            resolve({
                data: projects?.data?.result ?? [],
            })
        } catch (error) {
            resolve({ data: [] })
        }
    })
}

// UPDATE PROJECT
export const update = async (payload) => {
    return new Promise(async (resolve) => {
        const { _id } = payload
        try {
            let data = payload.updatedData ?? {}
            const update = await axiosNew({
                url: 'projects/update/' + _id,
                data,
            })
            resolve({ data: update?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// DELETE PROJECT
export const deleteProject = async (payload) => {
    return new Promise(async (resolve) => {
        try {
            const deleteProject = await axiosNew({
                url: 'projects/delete/' + payload,
            })
            resolve({ data: deleteProject?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// UPDATE PROJECT STATUS
export const updateStatus = async (payload) => {
    return new Promise(async (resolve) => {
        try {
            let data = payload?.status ?? {}
            const updateStatus = await axiosNew({
                url: 'projects/status-change/' + payload?.id,
                method: 'put',
                data,
            })
            resolve({ data: updateStatus?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// ADD / REMOVE TEAM MEMBER
export const addRemoveTeamMember = async (payload) => {
    return new Promise(async (resolve) => {
        try {
            let data = payload?.member ?? {}
            const addRemoveMember = await axiosNew({
                url: 'projects/add-remove-team-members/' + payload?.id,
                method: 'put',
                data,
            })
            resolve({ data: addRemoveMember?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// ADD / REMOVE FILE
export const addRemoveFile = async (payload) => {
    return new Promise(async (resolve) => {
        try {
            let data = payload?.fileObject ?? {}
            let progressCount
            const addRemoveFile = await axiosNew({
                url: 'projects/add-remove-files/' + payload?.id,
                method: 'put',
                data,
                onUploadProgress: (progressEvent) => {
                    // console.log(progressEvent.loaded)
                    progressCount = progressEvent.loaded
                },
            })
            resolve({
                data: addRemoveFile?.data ?? {},
                progressCount,
            })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// TOTAL NOTES COUNT
export const countNotes = async (payload) => {
    const { search = '', filter = {} } = payload
    return new Promise(async (resolve) => {
        try {
            let data = {
                filter,
            }
            if (search?.length > 0) {
                data['search'] = {
                    name: payload,
                    email: payload,
                }
            }
            const count = await axiosNew({
                url: 'notes/total',
                data,
            })
            resolve({ data: count?.data?.data ?? 0 })
        } catch (error) {
            resolve({ data: 0 })
        }
    })
}

// CREATE NOTE
export const addNotes = async (payload) => {
    return new Promise(async (resolve) => {
        try {
            let data = payload ?? {}
            const addNotes = await axiosNew({
                url: 'notes/add/',
                data,
            })
            resolve({ data: addNotes?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// LIST NOTES
export const listNotes = async (payload) => {
    const { search = '', skip = 0, filter = {}, limit = 0 } = payload

    return new Promise(async (resolve) => {
        try {
            let data = {
                skip,
                limit,
                filter,
                populate: {
                    name: 'userId',
                    val: 'name',
                },
            }
            if (search?.length > 0) {
                data['search'] = search
            }
            const listNotes = await axiosNew({
                url: 'notes/list/',
                data,
            })
            resolve({
                data: listNotes?.data?.data ?? {},
                isNext: listNotes?.data?.isNext,
            })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// UPDATE NOTE
export const updateNotes = async (payload) => {
    return new Promise(async (resolve) => {
        try {
            let data = payload?.editNote ?? {}
            const editNotes = await axiosNew({
                url: 'notes/edit/' + payload.id,
                data,
                method: 'put',
            })
            resolve({ data: editNotes?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// DELETE NOTE
export const deleteNotes = async (payload) => {
    return new Promise(async (resolve) => {
        try {
            const deleteNotes = await axiosNew({
                url: 'notes/delete/' + payload,
                method: 'delete',
            })
            resolve({ data: deleteNotes?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// GET NOTE BY ID
export const getNote = async (payload) => {
    return new Promise(async (resolve) => {
        try {
            const getNote = await axiosNew({
                url: 'notes/get/' + payload._id,
                method: 'get',
            })
            resolve({ data: getNote?.data?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// ADD / REMOVE COMMENT
export const addRemoveComment = async (payload) => {
    return new Promise(async (resolve) => {
        try {
            let data = payload?.comment ?? {}
            const addRemoveComment = await axiosNew({
                url: 'notes/add-remove-comments/' + payload?.id,
                method: 'put',
                data,
            })
            resolve({ data: addRemoveComment?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// SET CURRENT PROJECT
export const setAsCurrentProject = async (payload) => {
    return new Promise(async (resolve) => {
        try {
            let data = payload?.project ?? {}
            const currentProject = await axiosNew({
                url: 'projects/isActive-project/' + payload?.id,
                method: 'put',
                data,
            })
            resolve({ data: currentProject?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// ADD / REMOVE SWIMLANES
export const addRemoveSwimlanes = async (payload) => {
    return new Promise(async (resolve) => {
        try {
            let data = payload?.project ?? {}
            const addRemoveSwimLanes = await axiosNew({
                url: 'projects/add-remove-swimlanes/' + payload?.id,
                method: 'put',
                data,
            })
            resolve({ data: addRemoveSwimLanes?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// ADD / REMOVE TASK TYPE
export const addRemoveTaskType = async (payload) => {
    return new Promise(async (resolve) => {
        try {
            let data = payload?.project ?? {}
            const addRemoveTaskType = await axiosNew({
                url: 'projects/add-remove-task-type/' + payload?.id,
                method: 'put',
                data,
            })
            resolve({ data: addRemoveTaskType?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// DEFAULT TIME
export const defaultTimeSet = async (payload) => {
    return new Promise(async (resolve) => {
        try {
            let data = payload?.project ?? {}
            const defaultTime = await axiosNew({
                url: 'projects/set-default-time/' + payload?.id,
                method: 'put',
                data,
            })
            resolve({ data: defaultTime?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// DEFAULT WEEK
export const defaultWeekSet = async (payload) => {
    return new Promise(async (resolve) => {
        try {
            let data = payload?.project ?? {}
            const defaultWeek = await axiosNew({
                url: 'projects/set-default-week/' + payload?.id,
                method: 'put',
                data,
            })
            resolve({ data: defaultWeek?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// DEFAULT IDLE TIME FOR STOP TIMER
export const defaultIdleTime = async (payload) => {
    return new Promise(async (resolve) => {
        try {
            let data = payload?.project ?? {}
            const defaultIdleTime = await axiosNew({
                url: 'projects/set-default-ideal-time/' + payload?.id,
                method: 'put',
                data,
            })
            resolve({ data: defaultIdleTime?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// DEFAULT SCREENSHOT TIME
export const defaultScreenshotTime = async (payload) => {
    return new Promise(async (resolve) => {
        try {
            let data = payload?.project ?? {}
            const defaultScreenshotTime = await axiosNew({
                url: 'projects/set-default-screenshot-time/' + payload?.id,
                method: 'put',
                data,
            })
            resolve({ data: defaultScreenshotTime?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// COUNT PROJECT FILES
export const countProjectFiles = async (payload) => {
    return new Promise(async (resolve) => {
        try {
            const count = await axiosNew({
                url: `projects/files-count/${payload?.id}`,
                method: 'get',
            })
            resolve({ data: count?.data?.totalFiles ?? 0 })
        } catch (error) {
            resolve({ data: 0 })
        }
    })
}

// GET PROJECT FILES
export const getProjectFiles = async (payload) => {
    return new Promise(async (resolve) => {
        try {
            const getProjectFiles = await axiosNew({
                url: `projects/files/${payload?.id}?skip=${payload?.skip}&limit=${payload?.limit}`,
                method: 'get',
            })
            resolve({ data: getProjectFiles?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}
