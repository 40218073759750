import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import InfiniteScroll from 'react-infinite-scroll-component'
import axios from 'axios'

// antd
import { Col, Modal, Row, Spin, message, Drawer, Typography } from 'antd'

// slices
import {
    changePage,
    talentListGet,
    talentState,
    totalTalent,
    updateTalent,
} from './talent.slice'
import { loginState } from '../login/login.slice'
import { changeTab, profileState } from '../profile/profile.slice'

// modal
import WorkspaceAssignModal from './workspaceAssignModal'
import WorkSpaceUnAssignModal from './workspaceUnAssignModal'
import AddRates from './addRatesForm'

// talent components
import TalentList from './talentList'
import TalentGrid from './talentGrid'
import ProfileSection from '../profile'

// constants
import { TALENT_FILTER_STATUS } from '../../constants/general'
import { NoRecruitIcon } from '../../assets/icons'

const TalentView = ({ props }) => {
    const { initialTalentCall, talentView, filteredTalentData, filteredData } =
        props

    const { Text } = Typography

    const {
        status,
        talentList,
        currentPage,
        pageLimit,
        isNext,
        skipPage,
        filter,
        search,
    } = useSelector(talentState)
    const { userProfile } = useSelector(loginState)
    const { status: profileStatus } = useSelector(profileState)

    const navigate = useNavigate()

    const dispatch = useDispatch()

    const initialState = {
        assignModal: false,
        unAssignModal: false,
        addRatesModal: false,
        talentModal: false,
    }

    const [state, setState] = useState(initialState)

    // TALENT LOGIN
    const talentLogin = async (talent) => {
        if (window.location.hostname === 'localhost') {
            try {
                const response = await axios({
                    url: `http://localhost:5000/workspace/user-workspace-login?workSpaceId=${userProfile?.currentWorkSpace?._id}&userId=${talent?._id}`,
                    method: 'get',
                })
                if (response?.data?.success) {
                    navigate('/')
                    window.location.reload()
                }
            } catch (error) {
                message.error(error?.response?.data?.message)
            }
        } else {
            const response = await axios({
                url: `https://${userProfile?.currentWorkSpace?.domain}/backend/workspace/user-workspace-login?workSpaceId=${userProfile?.currentWorkSpace?._id}&userId=${talent?._id}`,
                method: 'get',
            })
            if (response?.data?.success) {
                navigate('/')
                window.location.reload()
                // const checkLogin = await dispatch(isLoggedInLogin())
                // const checkSuccessLogin = checkLogin?.payload?.data
                // const { success } = checkSuccessLogin
                // if (success) {
                // navigate(PRIVATE_ROUTES.overview)
                // }
            }
        }
    }

    // UPDATE STATUS
    async function updateStatus(_id, verifiedFlag) {
        const result = await dispatch(
            updateTalent({
                _id: _id,
                updatedData: {
                    data: {
                        isOurUser: !verifiedFlag,
                    },
                },
            })
        )

        const data = result?.payload?.data
        if (data) {
            const { success, message: errorMessage } = data
            if (success) {
                filteredData()
                dispatch(
                    await totalTalent({
                        filter: { ...filter, status: TALENT_FILTER_STATUS },
                    })
                )
                dispatch(
                    await talentListGet({
                        skip: 0,
                        limit: pageLimit,
                        search,
                        filter: { ...filter, status: TALENT_FILTER_STATUS },
                    })
                )
                message.success(errorMessage)
            } else {
                if (errorMessage) {
                    message.error(errorMessage)
                } else {
                    message.error('Something went wrong, try again later.')
                }
            }
        }
    }

    // FETCH MORE TALENT
    async function fetchMoreTalent() {
        if (isNext) {
            dispatch(
                changePage({
                    currentPage: currentPage + 1,
                    skip: skipPage + pageLimit,
                    pageLimit: pageLimit,
                })
            )
            await dispatch(
                talentListGet({
                    skip: skipPage + pageLimit,
                    limit: pageLimit,
                    filter: { ...filter, status: TALENT_FILTER_STATUS },
                })
            )
        }
    }

    return (
        <>
            <Spin spinning={status === 'loading' ? true : false}>
                {talentList?.length > 0 ? (
                    talentView === 'list' ? (
                        <TalentList
                            props={{
                                modalOpen: () =>
                                    setState((prevState) => ({
                                        ...prevState,
                                        assignModal: true,
                                    })),
                                talentView: talentView,
                                unAssignModalOpen: () =>
                                    setState((prevState) => ({
                                        ...prevState,
                                        unAssignModal: true,
                                    })),
                                addRatesModalOpen: () =>
                                    setState((prevState) => ({
                                        ...prevState,
                                        addRatesModal: true,
                                    })),
                                talentLogin: (talent) => talentLogin(talent),
                                talentModalOpen: () =>
                                    setState((prevState) => ({
                                        ...prevState,
                                        talentModal: true,
                                    })),
                                updateStatus: (id, flag) =>
                                    updateStatus(id, flag),
                            }}
                        />
                    ) : (
                        <InfiniteScroll
                            dataLength={
                                filteredTalentData?.length > 0
                                    ? filteredTalentData?.length
                                    : 0
                            }
                            next={fetchMoreTalent}
                            hasMore={isNext}
                            style={{
                                overflow: 'inherit',
                            }}
                        >
                            <Row gutter={[36, 36]}>
                                {filteredTalentData?.length > 0 &&
                                    filteredTalentData.map((talent) => (
                                        <Col
                                            key={talent?._id}
                                            md={24}
                                            lg={12}
                                            xl={8}
                                            xxl={6}
                                        >
                                            <TalentGrid
                                                props={{
                                                    talent: talent,
                                                    modalOpen: () =>
                                                        setState(
                                                            (prevState) => ({
                                                                ...prevState,
                                                                assignModal: true,
                                                            })
                                                        ),
                                                    unAssignModalOpen: () =>
                                                        setState(
                                                            (prevState) => ({
                                                                ...prevState,
                                                                unAssignModal: true,
                                                            })
                                                        ),
                                                    addRatesModalOpen: () =>
                                                        setState(
                                                            (prevState) => ({
                                                                ...prevState,
                                                                addRatesModal: true,
                                                            })
                                                        ),
                                                    talentModalOpen: () =>
                                                        setState(
                                                            (prevState) => ({
                                                                ...prevState,
                                                                talentModal: true,
                                                            })
                                                        ),
                                                    talentLogin: (talent) =>
                                                        talentLogin(talent),
                                                    updateStatus: (id, flag) =>
                                                        updateStatus(id, flag),
                                                }}
                                            />
                                        </Col>
                                    ))}
                            </Row>
                        </InfiniteScroll>
                    )
                ) : (
                    status !== 'loading' && (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '100%',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginTop: '120px',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                }}
                            >
                                <div
                                    style={{
                                        width: '400px',
                                    }}
                                >
                                    <NoRecruitIcon />
                                </div>
                            </div>
                            <Text
                                className="titleSecondary"
                                style={{
                                    marginTop: '20px',
                                }}
                            >
                                No Talent Found.
                            </Text>
                        </div>
                    )
                )}
            </Spin>
            <Modal
                closable={false}
                open={state?.assignModal}
                onCancel={() => {
                    setState((prevState) => ({
                        ...prevState,
                        assignModal: false,
                    }))
                }}
                destroyOnClose={true}
                footer={null}
                centered
                title={null}
            >
                <WorkspaceAssignModal
                    cancelModal={() =>
                        setState((prevState) => ({
                            ...prevState,
                            assignModal: false,
                        }))
                    }
                    refreshTalent={() => initialTalentCall()}
                />
            </Modal>
            <Modal
                centered
                open={state?.addRatesModal}
                footer={null}
                title={null}
                closable={false}
                destroyOnClose={true}
                width={900}
                styles={{
                    body: {
                        padding: '25px',
                    },
                }}
                onCancel={() => {
                    setState((prevState) => ({
                        ...prevState,
                        addRatesModal: false,
                    }))
                }}
            >
                <AddRates
                    onCancel={() =>
                        setState((prevState) => ({
                            ...prevState,
                            addRatesModal: false,
                        }))
                    }
                />
            </Modal>
            <Modal
                closable={false}
                open={state?.unAssignModal}
                onCancel={() => {
                    setState((prevState) => ({
                        ...prevState,
                        unAssignModal: false,
                    }))
                }}
                destroyOnClose={true}
                footer={null}
                centered
                title={null}
                width={'430px'}
                styles={{
                    body: {
                        padding: '30px 0px',
                    },
                }}
            >
                <WorkSpaceUnAssignModal
                    cancelModal={() =>
                        setState((prevState) => ({
                            ...prevState,
                            unAssignModal: false,
                        }))
                    }
                    refreshTalent={() => initialTalentCall()}
                />
            </Modal>
            <Drawer
                placement="right"
                closable={false}
                open={state?.talentModal}
                onClose={() => {
                    setState((prevState) => ({
                        ...prevState,
                        talentModal: false,
                    }))
                    dispatch(changeTab('summary'))
                }}
                footer={null}
                title={null}
                style={{
                    height: '100vh',
                    minWidth: '1200px',
                    width: '1200px',
                }}
                styles={{
                    body: {
                        padding: '0px',
                    },
                    wrapper: {
                        width: 'auto !important',
                    },
                }}
                rootClassName="talentViewDrawer"
            >
                <Spin
                    spinning={profileStatus === 'loading' ? true : false}
                    style={{
                        width: '1200px',
                    }}
                >
                    <ProfileSection profileOpenKey="talent" />
                </Spin>
            </Drawer>
        </>
    )
}

export default TalentView
