// antd
import { Divider, Modal, Row, Typography } from 'antd'

// assets
import { BinIcon } from '../../assets/icons'

// components
import Button from '../button/button'

const DeletePopUp = ({
    previewDelete,
    onCancel,
    onDelete,
    modalTitle,
    loadingButton,
    keyModal,
    pageKey,
    profileModalState,
}) => {
    const { Title, Text } = Typography

    const checkKeyModal = (key) => {
        switch (key) {
            case 'closeRole':
                return 'Are you sure you want to close the role?'

            case 'teamMember':
                return 'Are you sure you want to remove your Team Member?'

            case 'logout':
                return 'Are you sure you want to logout?'

            case 'revokedOffer':
                return 'Are you sure you want to Revoked this offer?'

            default:
                return `Are you sure you want to delete your ${
                    profileModalState?.delete ? 'Video' : pageKey
                }?`
        }
    }

    return (
        <Modal
            closable={false}
            open={previewDelete}
            destroyOnClose={true}
            onCancel={onCancel}
            centered
            style={{
                width: '426px',
                maxWidth: '426px',
                padding: '0px',
                borderRadius: '10px',
            }}
            title={null}
            footer={null}
        >
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                }}
            >
                <div
                    style={{
                        height: '40px',
                        width: '40px',
                        marginBottom: '15px',
                    }}
                >
                    <BinIcon />
                </div>
                <Title
                    level={4}
                    style={{ margin: 0, textTransform: 'capitalize' }}
                >
                    {modalTitle}
                </Title>
                <Text
                    style={{
                        fontSize: '16px',
                        marginTop: '15px',
                        textAlign: 'center',
                    }}
                >
                    {checkKeyModal(keyModal)}
                </Text>
                <Divider />
                <Row align={'middle'} justify={'space-between'}>
                    <Button
                        props={{
                            text: 'Cancel',
                            onClick: onCancel,
                            buttonType: 'text',
                        }}
                    />
                    <Button
                        props={{
                            text:
                                keyModal === 'revokedOffer'
                                    ? 'Revoked'
                                    : keyModal === 'closeRole'
                                    ? 'Close'
                                    : keyModal === 'logout'
                                    ? 'Logout'
                                    : keyModal === 'teamMember'
                                    ? 'Remove'
                                    : 'Delete',
                            onClick: onDelete,
                            danger: true,
                            loadingButton,
                        }}
                    />
                </Row>
            </div>
        </Modal>
    )
}

export default DeletePopUp
