import React from 'react'

function PreviousButton() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="9"
            height="18"
            fill="none"
            viewBox="0 0 9 18"
        >
            <path
                stroke="#252525"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="1.5"
                d="M8 16.918l-6.52-6.52c-.77-.77-.77-2.03 0-2.8L8 1.078"
            ></path>
        </svg>
    )
}

export default PreviousButton
