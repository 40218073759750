import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// antd
import { Card, Image, Modal, Row, Tag, Typography, message, theme } from 'antd'

// components
import Button from '../../../../../components/button/button'

// slices
import {
    offerToHireAddRemove,
    rolesInquiryState,
    talentAddRemoveType,
    talentDataMatch,
    talentListType,
    talentViewShow,
} from '../rolesInquiry.slice'
import { talentGetProfile } from '../../../../profile/profile.slice'
import { inquiryState } from '../../../inquiries.slice'

// assets
import {
    AddressIcon,
    FillThumbIcon,
    RoleExperienceIcon,
    ThumbIcon,
} from '../../../../../assets/icons'

// form
import OfferToHireForm from '../offerToHireForm'

// components
import DeletePopUp from '../../../../../components/deletePopUp/deletePopUp'

import dayjs from 'dayjs'

// styles
import styles from '../../../inquiries.module.css'

const CardTalent = ({ talentData, offerData, cardKey, tabName }) => {
    const { Title, Text } = Typography

    const { useToken } = theme

    const { token } = useToken()

    const dispatch = useDispatch()

    const { selectedInquiry } = useSelector(inquiryState)
    const { selectedRole } = useSelector(rolesInquiryState)

    const [modalOpen, setModalOpen] = useState(false)
    const [talentDataGet, setTalentDataGet] = useState()
    const [offerDataGet, setOfferDataGet] = useState()
    const [revokedModal, setRevokedModal] = useState({
        modalOpen: false,
        buttonLoading: false,
    })
    const [thumbLoadingIcon, setThumbLoadingIcon] = useState(false)

    // CHECK EXPERIENCE
    const checkExperience = () => {
        let monthsCount = 0
        if (talentData?.experience?.length > 0) {
            talentData?.experience.map((data) => {
                let count = dayjs(data?.end).diff(
                    dayjs(data?.start),
                    'month',
                    true
                )
                monthsCount += count
            })
        }
        return `${parseInt(monthsCount)} yrs of professional experience`
    }

    //  REVOKED OFFER
    const revokedOffer = async () => {
        setRevokedModal({ ...revokedModal, buttonLoading: true })
        const result = await dispatch(
            offerToHireAddRemove({
                addRemoveData: {
                    fieldName: 'remove',
                    offeredTalent: talentData?._id,
                },
                roleId: selectedRole?._id,
                workspaceId: selectedInquiry?.client?.workspaceId,
            })
        )
        const data = result?.payload?.data
        if (data) {
            const { success, message: checkMessage } = data
            if (success) {
                dispatch(
                    talentListType({
                        roleId: selectedRole?._id,
                        workspaceId: selectedInquiry?.client?.workspaceId,
                        updatedData: {
                            skip: 0,
                            limit: 10,
                            fieldName: tabName,
                        },
                    })
                )
                setRevokedModal({ modalOpen: false, buttonLoading: false })
                message.success(checkMessage)
            } else {
                setRevokedModal({ modalOpen: false, buttonLoading: false })
                if (checkMessage) {
                    message.error(checkMessage)
                } else {
                    message.error('Something went wrong')
                }
            }
        }
    }

    // ADD / REMOVE SHORTLISTED
    const addRemoveShortListed = async () => {
        setThumbLoadingIcon(true)
        const result = await dispatch(
            talentAddRemoveType({
                updatedData: {
                    fieldName:
                        tabName === 'shortListedTalent' ? 'remove' : 'add',
                    [tabName === 'shortListedTalent'
                        ? 'unShortListedTalent'
                        : 'shortListedTalent']: talentData?._id,
                },
                workspaceId: selectedInquiry?.client?.workspaceId,
                roleId: selectedRole?._id,
            })
        )
        const data = result?.payload?.data
        if (data) {
            const { success, message: checkMessage } = data
            if (success) {
                if (tabName === 'allMatchesTalent') {
                    dispatch(
                        talentDataMatch({
                            roleId: selectedRole?._id,
                            workspaceId: selectedInquiry?.client?.workspaceId,
                            updatedData: {
                                skip: 0,
                                limit: 10,
                            },
                        })
                    )
                } else {
                    dispatch(
                        talentListType({
                            roleId: selectedRole?._id,
                            workspaceId: selectedInquiry?.client?.workspaceId,
                            updatedData: {
                                skip: 0,
                                limit: 10,
                                fieldName: tabName,
                            },
                        })
                    )
                }
                setThumbLoadingIcon(false)

                message.success(checkMessage)
            } else {
                setThumbLoadingIcon(false)
                if (checkMessage) {
                    message.error(checkMessage)
                } else {
                    message.error('Something went wrong')
                }
            }
        }
    }

    function convertToUTC(time, offset) {
        const [hours, minutes] = time.split(':').map(Number)
        const date = new Date(Date.UTC(1970, 0, 1, hours, minutes))
        date.setUTCMinutes(date.getUTCMinutes() - offset)
        return date
    }

    // OVERLAPPING LOGIC
    function calculateOverlap(offset1, offset2) {
        const workingHoursStart = '08:00'
        const workingHoursEnd = '19:00' // 5 PM

        // Convert working hours to UTC
        const workingStartUTC1 = convertToUTC(workingHoursStart, offset1)
        const workingEndUTC1 = convertToUTC(workingHoursEnd, offset1)
        const workingStartUTC2 = convertToUTC(workingHoursStart, offset2)
        const workingEndUTC2 = convertToUTC(workingHoursEnd, offset2)

        // Calculate the overlap in UTC
        const overlapStartUTC = new Date(
            Math.max(workingStartUTC1, workingStartUTC2)
        )
        const overlapEndUTC = new Date(Math.min(workingEndUTC1, workingEndUTC2))

        if (overlapStartUTC < overlapEndUTC) {
            const overlapMilliseconds = overlapEndUTC - overlapStartUTC
            const overlapHours = Math.floor(
                overlapMilliseconds / (1000 * 60 * 60)
            )
            const overlapMinutes = Math.floor(
                (overlapMilliseconds % (1000 * 60 * 60)) / (1000 * 60)
            )
            return `${String(overlapHours).padStart(2, '0')}:${String(
                overlapMinutes
            ).padStart(2, '0')}`
        } else {
            return '00:00' // No overlap
        }
    }

    function convertTimeToDecimal(time) {
        const [hours, minutes] = time.split(':').map(Number)
        return parseInt(hours) + parseInt(minutes || 0) / 60
    }

    function extractUTCOffset(timezoneString) {
        console.log('-------', timezoneString, selectedRole, talentData)
        const match = timezoneString.match(/UTC([+-]\d{1,2}(?::\d{2})?)/)
        if (match) {
            return match[1] // The first capture group contains the offset
        }
        return '0' // Return '0' if no match is found
    }

    const checkTimeZone = () => {
        // Example usage:
        // const time1 = extractUTCOffset(selectedRole?.timeZone.trim())
        // const time2 = extractUTCOffset(talentData?.timeZone.trim())

        // const decimal1 = convertTimeToDecimal(time1) // 5.5
        // const decimal2 = convertTimeToDecimal(time2) // 10

        // const offsetSydney = decimal1 * 60 // UTC+10 in minutes
        // const offsetCalcutta = decimal2 * 60 // UTC+5:30 in minutes

        // const overlap = calculateOverlap(offsetSydney, offsetCalcutta)
        const overlap = 0

        // Set the state with the difference
        // return `${timeDifference} overlapping hours`
        // return `${convertTimeToDecimal(overlap)} overlapping hours`
        return `${overlap} overlapping hours`
    }

    return (
        <>
            <Card
                style={{
                    padding: '0px',
                    marginTop: '25px',
                    cursor: 'pointer',
                }}
                styles={{
                    body: {
                        padding: '20px',
                    },
                }}
            >
                <Row align={'middle'} justify={'space-between'}>
                    <Row
                        align={'middle'}
                        style={{
                            columnGap: '34px',
                        }}
                    >
                        <Image
                            src={talentData?.profilePicture}
                            alt="img"
                            width={'153px'}
                            height={'153px'}
                            preview={false}
                            style={{
                                borderRadius: '6px',
                            }}
                        />
                        <div>
                            <Title
                                level={4}
                                onClick={() => {
                                    dispatch(talentViewShow(true))
                                    dispatch(
                                        talentGetProfile({
                                            userId: talentData?._id,
                                        })
                                    )
                                }}
                            >
                                {talentData?.name}
                            </Title>
                            <Row
                                align={'middle'}
                                style={{
                                    columnGap: '6px',
                                    marginTop: '8px',
                                }}
                            >
                                {talentData?.address && (
                                    <>
                                        <AddressIcon />
                                        <Text
                                            style={{
                                                color: token.colorPalette
                                                    .textColor.primary,
                                            }}
                                        >
                                            {talentData?.address}
                                        </Text>
                                    </>
                                )}
                                <div
                                    style={{
                                        height: '6px',
                                        width: '6px',
                                        borderRadius: '50%',
                                        backgroundColor:
                                            token.colorPalette.baseColor.black,
                                    }}
                                />
                                <Text
                                    style={{
                                        color: token.colorPalette.textColor
                                            .primary,
                                    }}
                                >
                                    {checkTimeZone()}
                                </Text>
                            </Row>
                            <Row
                                align={'middle'}
                                style={{
                                    columnGap: '7px',
                                    marginTop: '8px',
                                }}
                            >
                                <RoleExperienceIcon />
                                <Text
                                    style={{
                                        color: token.colorPalette.textColor
                                            .primary,
                                    }}
                                >
                                    {checkExperience() > 12
                                        ? checkExperience()
                                        : 'Less than a year of professional experience'}
                                </Text>
                            </Row>
                            <Row
                                style={{
                                    columnGap: '10px',
                                    marginTop: '8px',
                                }}
                            >
                                {talentData?.skills?.length > 0 &&
                                    talentData?.skills.map((skill) => (
                                        <Tag
                                            style={{
                                                borderRadius: '44px',
                                                color: token.colorPalette
                                                    .textColor.primary,
                                                fontWeight:
                                                    token.fontWeightStrong,
                                                padding: '5px 14px',
                                                fontSize: '14px',
                                            }}
                                            key={skill}
                                            color={'#E1F2F4'}
                                        >
                                            {skill}
                                        </Tag>
                                    ))}
                            </Row>
                        </div>
                    </Row>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                        }}
                    >
                        {cardKey !== 'offerToHire' && (
                            <Row
                                align={'middle'}
                                justify={'end'}
                                style={{
                                    columnGap: '8px',
                                }}
                            >
                                <div
                                    style={{
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                        addRemoveShortListed()
                                    }}
                                >
                                    {thumbLoadingIcon ? (
                                        <div
                                            style={{
                                                marginBottom: '10px',
                                            }}
                                            className={styles.loaderAllMatches}
                                        />
                                    ) : tabName === 'shortListedTalent' ? (
                                        <FillThumbIcon />
                                    ) : (
                                        tabName === 'allMatchesTalent' && (
                                            <ThumbIcon />
                                        )
                                    )}
                                </div>
                                {tabName === 'shortListedTalent' && (
                                    <Button
                                        props={{
                                            text: 'Offer to Hire',
                                            onClick: () => {
                                                setTalentDataGet(talentData)
                                                setModalOpen(true)
                                            },
                                            style: {
                                                borderRadius: '28px',
                                            },
                                        }}
                                    />
                                )}
                                {tabName === 'offeredTalent' && (
                                    <Row
                                        align={'middle'}
                                        style={{
                                            columnGap: '10px',
                                            marginBottom: '10px',
                                        }}
                                    >
                                        <Button
                                            props={{
                                                text: 'Update Offer',
                                                onClick: (e) => {
                                                    e.preventDefault()
                                                    setTalentDataGet(talentData)
                                                    setOfferDataGet(offerData)
                                                    setModalOpen(true)
                                                },
                                                style: {
                                                    borderRadius: '28px',
                                                },
                                            }}
                                        />
                                        <Button
                                            props={{
                                                text: 'Revoked Offer',
                                                onClick: () => {
                                                    setRevokedModal({
                                                        ...revokedModal,
                                                        modalOpen: true,
                                                    })
                                                },
                                                style: {
                                                    borderRadius: '28px',
                                                    backgroundColor:
                                                        token.colorPalette
                                                            .baseColor.error,
                                                },
                                            }}
                                        />
                                    </Row>
                                )}
                            </Row>
                        )}

                        <span
                            style={{
                                fontSize: '16px',
                                fontWeight: 500,
                            }}
                        >
                            Talent Price: ${talentData?.price} /{' '}
                            <span>hour</span>
                        </span>
                        {tabName === 'offeredTalent' && (
                            <span
                                style={{
                                    fontSize: '16px',
                                    fontWeight: 500,
                                }}
                            >
                                Offered Price: ${offerData?.offer} /{' '}
                                <span>hour</span>
                            </span>
                        )}
                        {cardKey === 'offerToHire' && (
                            <Text>
                                ${talentData?.price} Hour based on 160 Hours per
                                month
                            </Text>
                        )}
                    </div>
                </Row>
            </Card>
            <Modal
                open={modalOpen}
                footer={null}
                centered
                closable={false}
                style={{
                    padding: '0px',
                    minWidth: '940px',
                }}
                styles={{
                    body: {
                        padding: '30px 25px',
                    },
                }}
                onCancel={() => setModalOpen(false)}
            >
                <OfferToHireForm
                    onCancel={() => {
                        setOfferDataGet()
                        setModalOpen(false)
                    }}
                    talentData={talentDataGet}
                    offerData={offerDataGet}
                    tabName={tabName}
                />
            </Modal>
            <DeletePopUp
                previewDelete={revokedModal?.modalOpen}
                onDelete={() => {
                    setRevokedModal({
                        ...revokedModal,
                        buttonLoading: true,
                    })
                    revokedOffer()
                }}
                loadingButton={revokedModal?.buttonLoading}
                onCancel={() => {
                    setRevokedModal({
                        modalOpen: false,
                        buttonLoading: false,
                    })
                }}
                modalTitle="Revoked Offer"
                keyModal="revokedOffer"
            />
        </>
    )
}

export default CardTalent
