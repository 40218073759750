import React from 'react'
import { BackIcon, EditRoleIcon } from '../../assets/icons'

const BackAndEdit = ({
    isBack = true,
    onBack = null,
    isEdit = true,
    editName = 'Edit',
    editIconColor = '#4F46E5',
    editIconChange = null,
    onEdit = null,
}) => {
    return (
        <div style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
            {isBack && (
                <span
                    style={{
                        cursor: 'pointer',
                        height: '24px',
                        width: '24px',
                    }}
                    onClick={onBack}
                >
                    <BackIcon />
                </span>
            )}
            {
                isEdit && (
                    <span onClick={onEdit} style={{ display: 'flex', gap: '5px', alignItems: 'center', cursor: 'pointer' }}>
                        {!editIconChange ? (
                            <EditRoleIcon fill={editIconColor} />
                        ) : (
                            editIconChange
                        )}
                        <span
                            style={{
                                color: editIconColor,
                                fontWeight: 'bold',
                                fontSize: '18px',
                            }}
                        >
                            {editName}
                        </span>
                    </span>
                )
            }
        </div>
    )
}

export default BackAndEdit
