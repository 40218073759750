import React from 'react'

function EarthIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="17"
            fill="none"
            viewBox="0 0 18 17"
        >
            <path
                fill="#000"
                d="M9 .168a8.333 8.333 0 110 16.667A8.333 8.333 0 019 .168zm3.337 9.065c-.288-.437-.495-.753-1.285-.628-1.492.237-1.657.498-1.729.928l-.02.13-.02.138c-.081.57-.078.785.183 1.06 1.054 1.105 1.686 1.903 1.877 2.37.094.227.334.916.169 1.598a6.822 6.822 0 002.626-1.864c.092-.312.158-.7.158-1.17v-.088c0-.768 0-1.119-.543-1.43-.23-.13-.4-.208-.537-.27-.306-.14-.51-.23-.782-.63a9.488 9.488 0 01-.097-.144zM9 1.696a6.786 6.786 0 00-4.913 2.096c.148.103.276.246.364.445.17.381.17.774.17 1.12 0 .274 0 .534.088.722.12.257.638.366 1.096.462.163.034.332.07.485.112.422.117.748.495 1.01.8.108.126.269.312.349.358a.85.85 0 00.243-.415c.05-.182.036-.345-.038-.433-.466-.55-.441-1.609-.297-2 .227-.615.935-.57 1.453-.536.193.013.376.024.512.007.518-.065.678-.853.79-1.008.244-.333.989-.835 1.452-1.146A6.782 6.782 0 009 1.696z"
            ></path>
        </svg>
    )
}

export default EarthIcon
