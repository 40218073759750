import axiosNew from '../../helpers/axios'

// GET FEED DATA
export const getFeed = async (payload) => {
    return new Promise(async (resolve) => {
        try {
            const getFeed = await axiosNew({
                url: `feed/list?limit=${payload.limit}&skip=${payload.skip}${
                    payload?.start ? `&start=${payload?.start}` : ''
                }${payload?.end ? `&end=${payload?.end}` : ''}${
                    payload?.projectId ? `&projectId=${payload?.projectId}` : ''
                }${payload?.titleType ? `&titleType=${payload?.titleType}` : ''}
                `,
                method: 'get',
            })
            resolve({ data: getFeed?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// GET FEED DATA
export const getIndividualFeed = async (payload) => {
    return new Promise(async (resolve) => {
        try {
            const getFeedData = await axiosNew({
                url: `feed/get/${payload?.id}`,
                method: 'get',
            })
            resolve({ data: getFeedData?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// CREATE FEED
export const createFeed = async (payload) => {
    return new Promise(async (resolve) => {
        try {
            let data = payload ?? {}

            const createFeed = await axiosNew({
                url: 'feed/create',
                data,
            })
            resolve({ data: createFeed?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// DELETE FEED
export const deleteFeed = async (payload) => {
    return new Promise(async (resolve) => {
        try {
            const deleteFeed = await axiosNew({
                url: 'feed/delete/' + payload,
                method: 'delete',
            })
            resolve({ data: deleteFeed?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}
