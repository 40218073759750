import React from 'react'

function RecommendationIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="15"
            fill="none"
            viewBox="0 0 15 15"
        >
            <path
                fill="#000"
                d="M.334 14.164a5.333 5.333 0 1110.667 0H.334zm5.333-6c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm4.909 1.489a5.003 5.003 0 013.747 4.511h-1.989c0-1.74-.666-3.324-1.758-4.511zM9.227 8.135a5.32 5.32 0 001.774-3.97 5.31 5.31 0 00-.677-2.603 3.333 3.333 0 01-1.097 6.573z"
            ></path>
        </svg>
    )
}

export default RecommendationIcon
