import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component'

// antd
import {
    Avatar,
    message,
    Row,
    Space,
    theme,
    Typography,
    Col,
    Spin,
    Card,
    Table,
    Pagination,
    Dropdown,
    Popover,
    Image,
} from 'antd'
import { EllipsisOutlined } from '@ant-design/icons'

// slices
import {
    changeNotesPage,
    clearNote,
    countTotalNotes,
    deleteProjectNotes,
    listProjectNotes,
    notesState,
    searchNotes,
} from './notes.slice'
import { loginState } from '../login/login.slice'

// routes
import { PRIVATE_ROUTES } from '../../routes'

// assets
import {
    DeleteIcon,
    EditIcon,
    EllipsisIcon,
    NoNotesIcon,
    ViewIcon,
} from '../../assets/icons'

// components
import PanelHeader from '../../components/panelHeader/panelHeader'
import DeletePopUp from '../../components/deletePopUp/deletePopUp'
import GridViewButton from '../../components/gridView'

// helpers
import {
    addPermissionCheck,
    deletePermissionCheck,
} from '../../helpers/permissionCheck'
import { unique } from '../../helpers/uniqueArray'

// css
import './notes.css'

import dayjs from 'dayjs'
import { SUPER_ADMIN, WORKSPACE_ADMIN } from '../../constants/roles'

const ProjectNotes = () => {
    const { Title, Text } = Typography

    const { useToken } = theme
    const { token } = useToken()

    const dispatch = useDispatch()
    const navigate = useNavigate()

    // SELECTORS
    const { userProfile } = useSelector(loginState)
    const {
        noteStatus,
        notesCount,
        notesCurrentPage,
        notesIsNext,
        notesList,
        notesPageLimit,
        notesSkipPage,
        search,
    } = useSelector(notesState)

    const initialState = {
        deleteModal: {
            modalOpen: false,
            id: '',
            loadingButton: false,
        },
        noteView: 'list',
        filteredNotesData: [],
    }

    // STATES
    const [state, setState] = useState(initialState)

    useEffect(() => {
        if (notesList?.length > 0) {
            let array = []
            notesList?.length > 0 &&
                notesList.forEach((data) => {
                    array = [...array, data]
                })
            let newArray = [...state?.filteredNotesData, ...array]
            let finalArray = unique(newArray, '_id')
            setState((prevState) => ({
                ...prevState,
                filteredNotesData: finalArray,
            }))
            if (!notesIsNext) {
                return
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notesList])

    const relativeTime = require('dayjs/plugin/relativeTime')
    dayjs.extend(relativeTime)

    // INITIAL NOTE LIST
    const initialNoteList = (search) => {
        refreshPage()
        dispatch(
            countTotalNotes({
                filter: {
                    projectParent: userProfile?.activeProject?._id,
                },
                search,
            })
        )
        dispatch(
            listProjectNotes({
                limit: notesPageLimit,
                skip: 0,
                filter: {
                    projectParent: userProfile?.activeProject?._id,
                },
                search,
            })
        )
    }

    const fetchMoreNotes = async () => {
        if (notesIsNext) {
            await dispatch(
                listProjectNotes({
                    skip: notesSkipPage + notesPageLimit,
                    limit: notesPageLimit,
                    filter: {
                        projectParent: userProfile?.activeProject?._id,
                    },
                })
            )
            dispatch(
                changeNotesPage({
                    currentPage: notesCurrentPage + 1,
                    skip: notesSkipPage + notesPageLimit,
                    pageLimit: notesPageLimit,
                })
            )
        }
    }

    useEffect(() => {
        if (userProfile?.activeProject !== null) {
            initialNoteList('')
            dispatch(searchNotes(''))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function refreshPage() {
        setState((prevState) => ({
            ...prevState,
            filteredNotesData: [],
        }))
        dispatch(
            changeNotesPage({
                currentPage: 1,
                skip: 0,
                notesPageLimit,
            })
        )
    }

    // DELETE PROJECT NOTES
    async function deleteProjectNote() {
        setState((prevState) => ({
            ...prevState,
            deleteModal: {
                ...prevState?.deleteModal,
                loadingButton: true,
            },
        }))
        const result = await dispatch(
            deleteProjectNotes(state?.deleteModal?.id)
        )
        const data = result?.payload?.data
        if (data) {
            const { success, message: checkMessage } = data
            if (success) {
                setState((prevState) => ({
                    ...prevState,
                    deleteModal: {
                        id: '',
                        loadingButton: false,
                        modalOpen: false,
                    },
                }))
                initialNoteList(search)
                message.success(checkMessage)
            } else {
                setState((prevState) => ({
                    ...prevState,
                    deleteModal: {
                        id: '',
                        loadingButton: false,
                        modalOpen: false,
                    },
                }))
                if (checkMessage) {
                    message.error(checkMessage)
                } else {
                    message.error('Something went wrong, try again later.')
                }
            }
        }
    }

    const columns = [
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            render: (_, item) => (
                <Title level={5} style={{ textTransform: 'capitalize' }}>
                    {item?.title}
                </Title>
            ),
        },
        {
            title: 'Created By',
            dataIndex: 'userId',
            key: 'userId',
            render: (_, item) => (
                <>
                    <Space size="middle">
                        {item?.userId?.profilePicture ? (
                            <>
                                <Image
                                    src={item?.userId?.profilePicture}
                                    height={40}
                                    width={40}
                                    style={{
                                        borderRadius: '50%',
                                    }}
                                    preview={false}
                                />
                            </>
                        ) : (
                            <Avatar
                                style={{
                                    backgroundColor: '#f56a00',
                                    cursor: 'pointer',
                                    height: '40px',
                                    width: '40px',
                                }}
                            >
                                {item?.userId?.name[0].toUpperCase()}
                                {item?.userId?.name.split(' ')[1] !==
                                    undefined &&
                                item?.userId?.name.split(' ')[1] !== ''
                                    ? item?.userId?.name
                                          .split(' ')[1][0]
                                          .toUpperCase()
                                    : ''}
                            </Avatar>
                        )}
                        <Text style={{ margin: 0 }}>
                            {item?.userId?.name ?? '-'}
                        </Text>
                    </Space>
                </>
            ),
        },
        {
            title: 'Created At',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (_, item) => (
                <>
                    <Space size={'small'} align="middle">
                        <Text>
                            {dayjs(item?.createdAt).format('DD MMM YY')}
                        </Text>
                    </Space>
                </>
            ),
        },
        {
            key: 'context',
            dataIndex: 'context',
            title: '  ',
            render: (_, row) => (
                <Row justify="end" style={{ paddingRight: 5 }}>
                    <Dropdown
                        trigger={['click']}
                        placement={'bottomRight'}
                        menu={{
                            items: [
                                {
                                    key: '1',
                                    label: 'View',
                                    icon: (
                                        <div
                                            style={{
                                                height: '20px',
                                                width: '20px',
                                            }}
                                        >
                                            <ViewIcon
                                                color={
                                                    token.colorPalette.baseColor
                                                        .tertiary
                                                }
                                            />
                                        </div>
                                    ),
                                    style: {
                                        columnGap: '10px',
                                    },
                                    onClick: () => {
                                        navigate(
                                            PRIVATE_ROUTES.notes.viewNote(
                                                row?._id
                                            )
                                        )
                                    },
                                },
                                {
                                    key: '2',
                                    label: 'Edit',
                                    icon: (
                                        <div
                                            style={{
                                                height: '20px',
                                                width: '20px',
                                            }}
                                        >
                                            <EditIcon
                                                color={
                                                    token.colorPalette.baseColor
                                                        .tertiary
                                                }
                                            />
                                        </div>
                                    ),
                                    style: {
                                        columnGap: '10px',
                                    },
                                    onClick: () => {
                                        navigate(
                                            PRIVATE_ROUTES.notes.editNote(
                                                row?._id
                                            )
                                        )
                                    },
                                },
                                (userProfile?.role?.roleName ===
                                    WORKSPACE_ADMIN ||
                                    userProfile?.role?.roleName ===
                                        SUPER_ADMIN ||
                                    row?.userId?._id === userProfile?._id ||
                                    deletePermissionCheck(
                                        'notes',
                                        userProfile
                                    )) && {
                                    key: '3',
                                    label: 'Delete',
                                    icon: <DeleteIcon />,
                                    style: {
                                        columnGap: '10px',
                                    },
                                    onClick: () => {
                                        setState((prevState) => ({
                                            ...prevState,
                                            deleteModal: {
                                                ...prevState?.deleteModal,
                                                modalOpen: true,
                                                id: row?._id,
                                            },
                                        }))
                                    },
                                },
                            ],
                        }}
                    >
                        <EllipsisOutlined rotate={90} />
                    </Dropdown>
                </Row>
            ),
        },
    ]

    const CardWrapper = ({ note }) => {
        const [open, setOpen] = useState(false)

        const handleOpenChange = (newOpen) => {
            setOpen(newOpen)
        }
        const dropDownIcon = [
            {
                title: 'View',
                icon: (
                    <div
                        style={{
                            height: '20px',
                            width: '20px',
                        }}
                    >
                        <ViewIcon
                            color={token.colorPalette.baseColor.tertiary}
                        />
                    </div>
                ),
                onClick: (id) => {
                    navigate(PRIVATE_ROUTES.notes.viewNote(id))
                },
            },
            {
                title: 'Edit',
                icon: (
                    <div
                        style={{
                            height: '20px',
                            width: '20px',
                        }}
                    >
                        <EditIcon
                            color={token.colorPalette.baseColor.tertiary}
                        />
                    </div>
                ),
                onClick: (id) => {
                    navigate(PRIVATE_ROUTES.notes.editNote(id))
                },
            },
            (userProfile?.role?.roleName === WORKSPACE_ADMIN ||
                userProfile?.role?.roleName === SUPER_ADMIN ||
                note?.userId?._id === userProfile?._id ||
                deletePermissionCheck('notes', userProfile)) && {
                title: 'Delete',
                icon: <DeleteIcon />,
                onClick: (id) => {
                    setState((prevState) => ({
                        ...prevState,
                        deleteModal: {
                            ...prevState.deleteModal,
                            modalOpen: true,
                            id,
                        },
                    }))
                },
            },
        ]

        const dropDownContent = () => {
            return (
                <>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            rowGap: '8px',
                        }}
                    >
                        {dropDownIcon?.length > 0 &&
                            dropDownIcon.map((data) => (
                                <Row
                                    style={{
                                        cursor: 'pointer',
                                    }}
                                    key={data?.title}
                                    onClick={() => data?.onClick(note?._id)}
                                >
                                    <div
                                        style={{
                                            marginRight: '10px',
                                        }}
                                    >
                                        {data?.icon}
                                    </div>
                                    <Text className="sub-title">
                                        {data?.title}
                                    </Text>
                                </Row>
                            ))}
                    </div>
                </>
            )
        }

        return (
            <>
                <Card
                    style={{
                        cursor: 'pointer',
                        marginTop: 20,
                        height: '100%',
                    }}
                    styles={{
                        body: {
                            padding: 16,
                        },
                    }}
                    className={'cardView'}
                >
                    <Row
                        align={'middle'}
                        justify="space-between"
                        style={{
                            marginBottom: 15,
                        }}
                    >
                        <Title
                            level={4}
                            style={{
                                textTransform: 'capitalize',
                            }}
                        >
                            {note?.title?.length > 15
                                ? note.title.substring(0, 14) + '...'
                                : note?.title}
                        </Title>
                        <Popover
                            content={dropDownContent}
                            trigger="click"
                            placement="left"
                            open={open}
                            overlayInnerStyle={{
                                padding: '10px',
                            }}
                            arrow={false}
                            onOpenChange={handleOpenChange}
                        >
                            <div>
                                <EllipsisIcon />
                            </div>
                        </Popover>
                    </Row>
                    <Row
                        align={'middle'}
                        style={{
                            marginTop: '6px',
                            columnGap: '6px',
                        }}
                    >
                        {note?.userId?.profilePicture ? (
                            <>
                                <Image
                                    src={note?.userId?.profilePicture}
                                    height={25}
                                    width={25}
                                    style={{
                                        borderRadius: '50%',
                                    }}
                                    preview={false}
                                />
                            </>
                        ) : (
                            <Avatar
                                style={{
                                    backgroundColor: '#f56a00',
                                    cursor: 'pointer',
                                    marginRight: 10,
                                    height: '25px',
                                    width: '25px',
                                }}
                            >
                                {note?.userId?.name[0].toUpperCase()}
                                {note?.userId?.name.split(' ')[1] !==
                                    undefined &&
                                note?.userId?.name.split(' ')[1] !== ''
                                    ? note?.userId?.name
                                          .split(' ')[1][0]
                                          .toUpperCase()
                                    : ''}
                            </Avatar>
                        )}
                        <Text>
                            Created by {note?.userId ? note?.userId?.name : '-'}
                        </Text>
                    </Row>
                    <div
                        className="noteDangerous"
                        dangerouslySetInnerHTML={{
                            __html: note?.description,
                        }}
                    ></div>
                    <Space size={'small'} style={{ marginTop: 10 }}>
                        <Text
                            style={{
                                color: '#817c7c',
                            }}
                        >
                            {/* Updated {dayjs(note?.updatedAt).format('DD MMM YY')} */}
                            Created {dayjs(note?.createdAt).format('DD MMM YY')}
                        </Text>
                    </Space>
                </Card>
            </>
        )
    }

    const GridViewComponent = () => (
        <GridViewButton
            props={{
                onClickButton: () => initialNoteList(search),
                viewList: state?.noteView,
                setView: (key) => {
                    setState((prevState) => ({ ...prevState, noteView: key }))
                },
            }}
        />
    )

    return (
        <>
            <div
                className={'container'}
                style={{
                    zIndex: 10,
                    minHeight: '100vh',
                    padding: '35px',
                }}
            >
                <PanelHeader
                    props={{
                        panelName: 'create note',
                        searchBar: true,
                        filterBar: false,
                        teamMembersBar: false,
                        gridView: true,
                        gridViewComponent: () => <GridViewComponent />,
                        permissionAdd: addPermissionCheck('notes', userProfile),
                        action: listProjectNotes,
                        count: countTotalNotes,
                        search: searchNotes,
                        pageLimit: notesPageLimit,
                        filterValue: {
                            projectParent: userProfile?.activeProject?._id,
                        },
                        // filterValue: filter,
                        refreshPage: refreshPage,
                        onClick: () => {
                            dispatch(clearNote())
                            navigate(PRIVATE_ROUTES.notes.createNote)
                        },
                    }}
                />
                <Spin spinning={noteStatus === 'loading' ? true : false}>
                    {notesList?.length > 0 ? (
                        state?.noteView === 'list' ? (
                            <Table
                                scroll={{
                                    x: 'scroll',
                                }}
                                rootClassName="tableGlobal"
                                style={{
                                    borderRadius: '16px',
                                }}
                                showHeader={true}
                                columns={columns}
                                dataSource={notesList?.length > 0 && notesList}
                                pagination={false}
                            />
                        ) : (
                            <InfiniteScroll
                                dataLength={
                                    state?.filteredNotesData?.length > 0
                                        ? state?.filteredNotesData?.length
                                        : 0
                                }
                                next={fetchMoreNotes}
                                hasMore={notesIsNext}
                                style={{
                                    overflow: 'inherit',
                                }}
                            >
                                <Row gutter={[25, 25]}>
                                    {notesList?.length > 0 &&
                                        notesList.map((data) => (
                                            <Col
                                                // className="gutter-row"
                                                xs={24}
                                                sm={24}
                                                md={12}
                                                lg={8}
                                                xl={8}
                                                xxl={6}
                                                key={data?._id}
                                            >
                                                <CardWrapper note={data} />
                                            </Col>
                                        ))}
                                </Row>
                            </InfiniteScroll>
                        )
                    ) : (
                        noteStatus === 'loaded' && (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '100%',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    marginTop: '120px',
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                    }}
                                >
                                    <div
                                        style={{
                                            width: '400px',
                                        }}
                                    >
                                        <NoNotesIcon />
                                    </div>
                                </div>
                                <Text
                                    className="titleSecondary"
                                    style={{
                                        marginTop: '20px',
                                    }}
                                >
                                    No Notes Found yet.
                                </Text>
                            </div>
                        )
                    )}
                    {notesList?.length > 0 && state?.noteView === 'list' && (
                        <Row
                            justify={'end'}
                            align={'middle'}
                            style={{
                                border: `2px solid ${token.colorPalette.baseColor.quaternary}`,
                                padding: '25px',
                                borderRadius: '0px 0px 8px 8px',
                            }}
                        >
                            <Text>
                                {notesList?.length} - {notesPageLimit} of{' '}
                                {notesCount}
                            </Text>

                            <Pagination
                                defaultCurrent={notesCurrentPage}
                                current={notesCurrentPage}
                                defaultPageSize={notesPageLimit}
                                pageSizeOptions={[5, 10, 20, 50]}
                                showSizeChanger={true}
                                total={
                                    notesIsNext === false &&
                                    notesCurrentPage === 1
                                        ? notesList?.length
                                        : notesCount
                                }
                                onChange={async (value, pageSize) => {
                                    await dispatch(
                                        changeNotesPage({
                                            currentPage: value,
                                            skip: (value - 1) * notesPageLimit,
                                            pageLimit: pageSize,
                                        })
                                    )
                                    await dispatch(
                                        listProjectNotes({
                                            skip: (value - 1) * pageSize,
                                            limit: pageSize,
                                            search: search,
                                            filter: {
                                                projectParent:
                                                    userProfile?.activeProject
                                                        ?._id,
                                            },
                                        })
                                    )
                                }}
                            />
                        </Row>
                    )}
                </Spin>
            </div>
            <DeletePopUp
                previewDelete={state?.deleteModal?.modalOpen}
                onDelete={() => {
                    deleteProjectNote()
                }}
                loadingButton={state?.deleteModal?.loadingButton}
                onCancel={() => {
                    setState((prevState) => ({
                        ...prevState,
                        deleteModal: {
                            modalOpen: false,
                            id: '',
                            loadingButton: false,
                        },
                    }))
                }}
                pageKey={'Note'}
                modalTitle={`Delete Note`}
            />
        </>
    )
}

export default ProjectNotes
