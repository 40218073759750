import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// antd
import { Image, Select, Space, Spin, Typography, theme } from 'antd'

// slices
import { workspaceState } from '../workSpace/workSpace.slice'
import {
    changeWorkspace,
    inquiriesListGetSwimlanes,
    inquiryState,
} from './inquiries.slice'

// helpers
import { unique } from '../../helpers/uniqueArray'

// components
import AvatarComponent from '../../components/avatar'

// style
import styles from './inquiries.module.css'

const FilterWorkspace = () => {
    const { Text } = Typography

    const { Option } = Select

    const { useToken } = theme
    const { token } = useToken()

    const dispatch = useDispatch()

    const { workSpaceDataList } = useSelector(workspaceState)
    const { filterWorkSpace } = useSelector(inquiryState)

    const [filteredWorkSpaceData, setFilteredWorkSpaceData] = useState([])

    // CHECK FOR LAZY LOADING IS_NEXT
    useEffect(() => {
        if (workSpaceDataList?.length > 0) {
            let array = []
            workSpaceDataList?.length > 0 &&
                workSpaceDataList.forEach((data) => {
                    array = [
                        ...array,
                        {
                            label: data?.companyName,
                            value: data?._id,
                            logo: data?.logo,
                        },
                    ]
                })
            let newArray = [...filteredWorkSpaceData, ...array]
            let finalArray = unique(newArray, 'value')
            setFilteredWorkSpaceData(finalArray)
            // if (!isNext) {
            //     return
            // }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [workSpaceDataList])

    return (
        <>
            <Select
                placeholder="Select a Workspace"
                onChange={(value) => {
                    dispatch(changeWorkspace(value))
                    if (value === undefined) {
                        dispatch(inquiriesListGetSwimlanes())
                    } else {
                        dispatch(
                            inquiriesListGetSwimlanes({
                                workspaceId: value,
                            })
                        )
                    }
                }}
                rootClassName={'workspaceFilter'}
                defaultValue={filterWorkSpace}
                // notFoundContent={optionLoading ? <Spin size="small" /> : null}
                allowClear={true}
                // onPopupScroll={onScroll}
                style={{
                    minWidth: '170px',
                }}
            >
                {filteredWorkSpaceData?.length > 0 &&
                    filteredWorkSpaceData.map((option) => {
                        return (
                            <Option
                                style={{
                                    textTransform: 'capitalize',
                                }}
                                key={option?.value}
                                value={option?.value}
                            >
                                <Space size="small">
                                    {option?.logo ? (
                                        <Image
                                            src={option?.logo}
                                            height={'24px'}
                                            width={'24px'}
                                            preview={false}
                                            style={{
                                                borderRadius: '50%',
                                            }}
                                        />
                                    ) : (
                                        <AvatarComponent
                                            props={{
                                                avatarName: option?.label,
                                                size: 24,
                                                styleAvatar: {
                                                    fontSize: '14px',
                                                },
                                            }}
                                        />
                                    )}
                                    <Text
                                        style={{
                                            margin: 0,
                                            color: token.colorPalette.textColor
                                                .primary,
                                        }}
                                    >
                                        {option?.label}
                                    </Text>
                                </Space>
                            </Option>
                        )
                    })}
            </Select>
        </>
    )
}

export default FilterWorkspace
