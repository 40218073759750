import React from 'react'

function NonVerifyTalentIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 22 22"
        >
            <path
                fill="#FF3838"
                d="M9.174 1.927a2.75 2.75 0 00-3.296 1.365l-.739 1.446a.917.917 0 01-.4.4l-1.445.738A2.75 2.75 0 001.93 9.172l.5 1.544c.06.184.06.381 0 .565l-.5 1.544a2.75 2.75 0 001.365 3.296l1.446.739a.917.917 0 01.4.4l.738 1.445a2.75 2.75 0 003.296 1.365l1.544-.5a.917.917 0 01.565 0l1.544.5a2.75 2.75 0 003.296-1.365l.739-1.446a.917.917 0 01.4-.4l1.445-.738a2.75 2.75 0 001.365-3.295l-.5-1.545a.917.917 0 010-.565l.5-1.544a2.75 2.75 0 00-1.365-3.296l-1.446-.739a.917.917 0 01-.4-.399l-.738-1.446a2.75 2.75 0 00-3.296-1.365l-1.544.5a.917.917 0 01-.565 0l-1.544-.5z"
            ></path>
            <path
                fill="#fff"
                d="M11.223 9.925L8.63 7.332 7.334 8.628l2.593 2.593-2.593 2.593L8.63 15.11l2.593-2.592 2.593 2.592 1.296-1.296-2.593-2.593 2.593-2.593-1.296-1.296-2.593 2.593z"
            ></path>
        </svg>
    )
}

export default NonVerifyTalentIcon
