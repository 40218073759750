import { useState } from 'react'
import { useSelector } from 'react-redux'

// antd
import {
    Avatar,
    Badge,
    Card,
    Image,
    Popover,
    Row,
    Space,
    Tooltip,
    Typography,
    theme,
} from 'antd'

// slices
import { loginState } from '../login/login.slice'

// helpers
import { deletePermissionCheck } from '../../helpers/permissionCheck'

// assets
import { DeleteIcon, EllipsisIcon, MailIcon } from '../../assets/icons'

const TeamGrid = ({ props }) => {
    const { team, setDeleteModal } = props

    const { Text, Title } = Typography

    const { useToken } = theme

    const { token } = useToken()

    const { userProfile } = useSelector(loginState)

    const [open, setOpen] = useState(false)

    const handleOpenChange = (newOpen) => {
        setOpen(newOpen)
    }
    const dropDownIcon = [
        {
            title: 'Remove',
            icon: <DeleteIcon />,
            onClick: () => {
                if (userProfile?._id !== team?._id) {
                    setOpen(false)
                    setDeleteModal()
                }
            },
        },
    ]

    const dropDownContent = () => {
        return (
            <>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    {dropDownIcon?.length > 0 &&
                        dropDownIcon.map((data) =>
                            userProfile?._id !== team?._id ? (
                                <Row
                                    style={{
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => data?.onClick(team?._id)}
                                >
                                    <div
                                        style={{
                                            marginRight: '10px',
                                        }}
                                    >
                                        {data?.icon}
                                    </div>
                                    <Text className="sub-title">
                                        {data?.title}
                                    </Text>
                                </Row>
                            ) : (
                                <Tooltip title="You will not be able to self remove">
                                    <Row
                                        style={{
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => data?.onClick(team?._id)}
                                    >
                                        <div
                                            style={{
                                                marginRight: '10px',
                                            }}
                                        >
                                            {data?.icon}
                                        </div>
                                        <Text className="sub-title">
                                            {data?.title}
                                        </Text>
                                    </Row>
                                </Tooltip>
                            )
                        )}
                </div>
            </>
        )
    }

    return (
        <Card
            style={{
                cursor: 'pointer',
                borderRadius: '16px',
                border: 'transparent',
                boxShadow: '0 0 20px rgba(0,0,0,0.05)',
                height: '100%',
            }}
            styles={{
                body: {
                    padding: '22px 20px',
                },
            }}
        >
            <Row
                align={'center'}
                style={{
                    alignItems: 'flex-start',
                }}
                justify={'space-between'}
            >
                {team?.profilePicture ? (
                    <>
                        <Image
                            src={team?.profilePicture}
                            height={64}
                            width={64}
                            style={{
                                borderRadius: '50%',
                            }}
                            preview={false}
                        />
                    </>
                ) : (
                    <Avatar
                        style={{
                            cursor: 'pointer',
                            backgroundColor: 'rgb(0 10 129 / 25%)',
                        }}
                        size={64}
                    >
                        {team?.name[0].toUpperCase()}
                        {team?.name.split(' ')[1] !== undefined &&
                            team?.name.split(' ')[1][0].toUpperCase()}
                    </Avatar>
                )}
                <Row align={'middle'}>
                    <Space size={'large'}>
                        <Badge
                            count={team?.isActive ? 'Active' : 'InActive'}
                            color={
                                team?.isActive
                                    ? 'hsla(155, 54%, 46%, 0.16)'
                                    : 'hsla(11, 100%, 59%, 0.16)'
                            }
                            style={{
                                minWidth: '30px',
                                cursor: 'auto',
                                borderRadius: '6px',
                                display: 'flex',
                                alignItems: 'center',
                                color: team?.isActive
                                    ? token.colorPalette.iconColor.tertiary
                                    : token.colorPalette.iconColor.quaternary,
                                padding: '12px 8px',
                                fontWeight: 700,
                            }}
                        />
                        <Popover
                            content={dropDownContent}
                            trigger="click"
                            placement="left"
                            open={open}
                            overlayInnerStyle={{
                                padding: '10px',
                            }}
                            arrow={false}
                            onOpenChange={handleOpenChange}
                        >
                            {deletePermissionCheck('teams', userProfile) && (
                                <div>
                                    <EllipsisIcon />
                                </div>
                            )}
                        </Popover>
                    </Space>
                </Row>
            </Row>
            <Row
                style={{
                    marginTop: '10px',
                    columnGap: '6px',
                }}
                align={'middle'}
            >
                <Title level={5}>{team?.name}</Title>
                <Text
                    style={{
                        color: token.colorPalette.textColor.senary,
                    }}
                >
                    {team?.designation?.name}
                </Text>
            </Row>
            <Row
                style={{
                    marginTop: '10px',
                }}
                align={'middle'}
            >
                <Space>
                    <MailIcon />
                    <Text>{team?.email}</Text>
                </Space>
            </Row>
        </Card>
    )
}

export default TeamGrid
