import { useId, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Draggable, Droppable } from 'react-beautiful-dnd'

// antd
import {
    Card,
    Col,
    Input,
    Row,
    Space,
    Tooltip,
    Typography,
    message,
    Badge,
    Avatar,
    Popover,
    theme,
    Image,
} from 'antd'
import {
    PlusOutlined,
    CheckOutlined,
    CloseOutlined,
    BugOutlined,
    UserOutlined,
} from '@ant-design/icons'

// slices
import {
    changeEditInput,
    createEpicList,
    deleteEpicList,
    epicsList,
    setEpics,
    sprintsList,
    sprintsState,
    taskSprintList,
    updateEpicList,
} from './sprints.slice'
import {
    switchFormModal,
    taskById,
    taskDelete,
    // taskUpdate,
} from '../tasks/tasks.slice'
import { loginState } from '../login/login.slice'
import { projectsState } from '../projects/projects.slice'

// components
import DeletePopUp from '../../components/deletePopUp/deletePopUp'
import AvatarComponent from '../../components/avatar'

// assets
import { DeleteIcon, EllipsisIcon, EpicIcon } from '../../assets/icons'

// css
import styles from './sprints.module.css'

// helpers
import {
    addPermissionCheck,
    deletePermissionCheck,
    editPermissionCheck,
} from '../../helpers/permissionCheck'
import { estimateFn } from '../../helpers/estimationCalculation'

const EpicCardSectionFinal = ({ props }) => {
    const { useToken } = theme
    const { token } = useToken()
    const { Text } = Typography
    const { card, tasks, sprintId } = props
    const { _id, title, position } = card

    const createEpicId = useId()

    const dispatch = useDispatch()

    const {
        epics,
        editInput,
        skipPageEpic,
        pageLimitEpic,
        pageLimitTask,
        skipPageTask,
        pointerEvent,
    } = useSelector(sprintsState)
    const { currentProjectData } = useSelector(projectsState)
    const { userProfile } = useSelector(loginState)

    const initialState = {
        epicTitle: '',
        deleteEpicModal: false,
        deleteButtonLoading: false,
        deleteModal: false,
        deleteButtonLoadingTask: false,
        taskID: '',
        deleteCheck: false,
        open: false,
    }

    const [state, setState] = useState(initialState)
    const [createEpicLoading, setCreateEpicLoading] = useState(false)

    const handleOpenChange = (newOpen) => {
        setState({ ...state, open: newOpen })
    }

    // DELETE TASK
    async function deleteTask() {
        const result = await dispatch(taskDelete(state?.taskID))
        const data = result?.payload?.data
        if (data) {
            const { success, message: checkMessage } = data
            if (success) {
                setState({
                    ...state,
                    deleteButtonLoadingTask: false,
                    deleteModal: false,
                    taskID: '',
                })
                message.success(checkMessage)
                dispatch(sprintsList())
                dispatch(
                    epicsList({ skip: skipPageEpic, limit: pageLimitEpic })
                )
            } else {
                setState({
                    ...state,
                    deleteButtonLoadingTask: false,
                    deleteModal: false,
                    taskID: '',
                })
                if (checkMessage) {
                    message.error(checkMessage)
                } else {
                    message.error('Something went wrong, try again later.')
                }
            }
        }
    }

    // async function updateTaskAssignee(value, Id) {
    //     const result = await dispatch(
    //         taskUpdate({
    //             updateTask: {
    //                 assigneeId: value,
    //             },
    //             _id: Id,
    //         })
    //     )
    //     const data = result?.payload?.data
    //     if (data) {
    //         const { success } = data
    //         if (success) {
    //             dispatch(sprintsList())
    //             dispatch(
    //                 epicsList({ skip: skipPageEpic, limit: pageLimitEpic })
    //             )
    //         } else {
    //             alert.error('Something went wrong, try again later.')
    //         }
    //     }
    // }

    // CREATE NEW EPIC CARD
    const createEpicCard = (id) => {
        const newEpics = structuredClone(epics)

        // AFTER ARRAY FROM EPIC CREATE

        const index = newEpics.findIndex((x) => x._id === id)

        let newAfterArray = []
        // ADD CREATE EPIC CARD AND REMOVE OTHER DATA
        if (newEpics[index + 1]?.type !== 'create') {
            const afterArray = newEpics.slice(index + 1)

            // UPDATED NEW AFTER ARRAY
            if (afterArray?.length > 0) {
                afterArray.map((data) =>
                    newAfterArray.push({
                        ...data,
                        position: data?.position + 1,
                    })
                )
            }
            newEpics.splice(
                newEpics.findIndex((x) => x._id === id) + 1,
                newAfterArray?.length,
                {
                    position: newEpics.findIndex((x) => x._id === id) + 1,
                    projectId: JSON.parse(
                        localStorage.getItem('currentProject')
                    )?.id,
                    _id: createEpicId,
                    type: 'create',
                }
            )
        }
        dispatch(setEpics([...newEpics, ...newAfterArray]))
    }

    // CREATE NEW EPIC
    const createEpic = async (position) => {
        let result

        if (editInput?.open) {
            result = await dispatch(
                updateEpicList({
                    epicEdit: {
                        title: state?.epicTitle,
                    },
                    id: editInput?.id,
                })
            )
        } else {
            result = await dispatch(
                createEpicList({
                    projectId: JSON.parse(
                        localStorage.getItem('currentProject')
                    )?.id,
                    title: state?.epicTitle,
                    position: position,
                })
            )
        }
        const data = result?.payload?.data
        if (data) {
            dispatch(
                changeEditInput({
                    open: false,
                    id: '',
                })
            )
            const { success, message: checkMessage } = data
            if (success) {
                await dispatch(
                    epicsList({ skip: skipPageEpic, limit: pageLimitEpic })
                )
                setCreateEpicLoading(false)
                await dispatch(
                    taskSprintList({
                        skip: skipPageTask,
                        limit: pageLimitTask,
                        projectId: JSON.parse(
                            localStorage.getItem('currentProject')
                        )?.id,
                        sprintId,
                    })
                )
                message.success(checkMessage)
            } else {
                setCreateEpicLoading(false)
                if (typeof checkMessage === 'object') {
                    for (const key in checkMessage) {
                        message.error(checkMessage[key])
                    }
                } else if (typeof checkMessage === 'string') {
                    message.error(checkMessage)
                } else {
                    message.error('Something went wrong, try again later.')
                }
            }
        }
    }

    // CANCEL NEW EPIC
    const cancelEpic = (position) => {
        const newEpics = structuredClone(epics)
        newEpics.splice(position, 1)
        const afterArray = []
        newEpics.map((data, index) =>
            afterArray.push({ ...data, position: index })
        )
        dispatch(setEpics(afterArray))
    }

    // VIEW TASK
    async function viewTask(taskID) {
        dispatch(
            switchFormModal({
                open: true,
            })
        )
        dispatch(taskById({ _id: taskID }))
    }

    // DELETE EPIC
    async function deleteEpic() {
        setState({ ...state, deleteButtonLoading: true })

        const result = await dispatch(deleteEpicList(_id))
        const data = result?.payload?.data
        if (data) {
            const { success, message: checkMessage } = data
            if (success) {
                setState({
                    ...state,
                    deleteEpicModal: false,
                    deleteButtonLoading: false,
                })
                dispatch(
                    epicsList({ skip: skipPageEpic, limit: pageLimitEpic })
                )
                message.success(checkMessage)
            } else {
                if (checkMessage) {
                    setState({
                        ...state,
                        deleteEpicModal: false,
                        deleteButtonLoading: false,
                    })
                    message.error(checkMessage)
                } else {
                    setState({
                        ...state,
                        deleteEpicModal: false,
                        deleteButtonLoading: false,
                    })
                    message.error('Something went wrong, try again later.')
                }
            }
        }
    }

    // DELETE TASK CONDITION COMPONENT
    const DeleteTaskComponent = () => 'Delete'

    const CardSection = ({ props }) => {
        const { card } = props
        const { _id, title, epicPosition, type, status } = card
        const [epicDropDown, setEpicDropDown] = useState(false)

        const handleOpenEpicChange = (newOpen) => {
            setEpicDropDown(newOpen)
        }
        return (
            <>
                <Draggable key={_id} draggableId={_id} index={epicPosition}>
                    {(provided) => {
                        return (
                            <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                onClick={(e) => {
                                    if (
                                        !e.target.classList.contains(
                                            'active-tooltip'
                                        ) &&
                                        !e.target.classList.contains(
                                            'ant-avatar-string'
                                        ) &&
                                        // e.target.classList.contains(
                                        //     'ant-popover-content'
                                        // ) &&
                                        e.target.localName !== 'svg' &&
                                        e.target.localName !== 'path' &&
                                        !state?.deleteCheck
                                    ) {
                                        viewTask(_id)
                                    }
                                }}
                            >
                                <Card
                                    bordered={false}
                                    className={styles.epicCard}
                                    title={null}
                                    style={{
                                        borderRadius: '6px',
                                        marginTop: '15px',
                                    }}
                                    styles={{
                                        body: {
                                            padding: '20px 16px',
                                            minHeight: '100%',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'space-between',
                                        },
                                    }}
                                >
                                    <Row
                                        align={'middle'}
                                        justify={'space-between'}
                                    >
                                        <Text
                                            style={{
                                                textTransform: 'capitalize',
                                                fontWeight:
                                                    token.fontWeightStrong,
                                            }}
                                            ellipsis={{
                                                rows: 1,
                                                expandable: false,
                                            }}
                                            onClick={() => {}}
                                        >
                                            {title?.length > 30
                                                ? title
                                                      .substring(0, 30)
                                                      .concat('...')
                                                : title}
                                        </Text>
                                        <Popover
                                            content={dropDownContent(
                                                dropDownEpicIcon,
                                                _id
                                            )}
                                            trigger="click"
                                            placement="bottom"
                                            open={epicDropDown}
                                            overlayInnerStyle={{
                                                padding: '10px',
                                            }}
                                            arrow={false}
                                            onOpenChange={handleOpenEpicChange}
                                        >
                                            <div
                                                style={{
                                                    cursor: 'pointer',
                                                }}
                                            >
                                                <EllipsisIcon />
                                            </div>
                                        </Popover>
                                        {/* <Dropdown
                                             trigger={['click']}
                                             placement={'bottomRight'}
                                             onOpenChange={(e) => {
                                                 if (e === true) {
                                                     setDeleteCheck(true)
                                                 } else {
                                                     setDeleteCheck(false)
                                                 }
                                             }}
                                             menu={{
                                                 items: [
                                                     {
                                                         key: '1',
                                                         danger:
                                                             userProfile?.role
                                                                 ?.roleName !==
                                                                 'member' &&
                                                             true,
                                                         label:
                                                             userProfile?.role
                                                                 ?.roleName ===
                                                             'member' ? (
                                                                 <Tooltip
                                                                     arrow={
                                                                         false
                                                                     }
                                                                     placement="bottom"
                                                                     title="You don't have permission to delete task"
                                                                 >
                                                                     <Row
                                                                         align={
                                                                             'middle'
                                                                         }
                                                                         style={{
                                                                             columnGap:
                                                                                 '10px',
                                                                         }}
                                                                     >
                                                                         <DeleteOutlined
                                                                             style={{
                                                                                 fontSize:
                                                                                     '14px',
                                                                             }}
                                                                         />
                                                                         <Text
                                                                             style={{
                                                                                 margin: '0px',
                                                                             }}
                                                                         >
                                                                             Delete
                                                                         </Text>
                                                                     </Row>
                                                                 </Tooltip>
                                                             ) : (
                                                                 <DeleteTaskComponent />
                                                             ),
                                                         onClick: (e) => {
                                                             if (
                                                                 userProfile
                                                                     ?.role
                                                                     ?.roleName !==
                                                                 'member'
                                                             ) {
                                                                 setDeleteCheck(
                                                                     true
                                                                 )
                                                                 setTaskID(_id)
                                                                 setDeleteModal(
                                                                     true
                                                                 )
                                                             }
                                                         },
                                                     },
                                                 ],
                                             }}
                                         >
                                             <EllipsisIcon />
                                         </Dropdown> */}
                                    </Row>
                                    <Row
                                        justify={'space-between'}
                                        align={'middle'}
                                        style={{
                                            marginTop: '14px',
                                        }}
                                    >
                                        <Col>
                                            <Row align={'middle'}>
                                                <Space size={'small'}>
                                                    <Tooltip
                                                        title={type}
                                                        arrow={false}
                                                    >
                                                        {type === 'Bug' ? (
                                                            <div
                                                                className={
                                                                    styles.taskTypeSection
                                                                }
                                                                style={{
                                                                    backgroundColor:
                                                                        'red',
                                                                }}
                                                            >
                                                                <BugOutlined
                                                                    style={{
                                                                        fontSize:
                                                                            '12px',
                                                                        color: 'white',
                                                                    }}
                                                                />
                                                            </div>
                                                        ) : (
                                                            <div
                                                                className={
                                                                    styles.taskTypeSection
                                                                }
                                                                style={{
                                                                    backgroundColor:
                                                                        token
                                                                            .colorPalette
                                                                            .baseColor
                                                                            .tertiary,
                                                                }}
                                                            >
                                                                <CheckOutlined
                                                                    style={{
                                                                        fontSize:
                                                                            '10px',
                                                                        color: 'white',
                                                                    }}
                                                                />
                                                            </div>
                                                        )}
                                                    </Tooltip>
                                                    <Tooltip
                                                        arrow={false}
                                                        title={`Status : ${status}`}
                                                    >
                                                        {status === 'To Do' ? (
                                                            <div
                                                                className={
                                                                    styles.taskTypeSection
                                                                }
                                                                style={{
                                                                    backgroundColor:
                                                                        '#d5d5d5',
                                                                }}
                                                            ></div>
                                                        ) : status ===
                                                          'Done' ? (
                                                            <div
                                                                className={
                                                                    styles.taskTypeSection
                                                                }
                                                                style={{
                                                                    backgroundColor:
                                                                        '#C8E5CE',
                                                                }}
                                                            ></div>
                                                        ) : (
                                                            <div
                                                                className={
                                                                    styles.taskTypeSection
                                                                }
                                                                style={{
                                                                    backgroundColor:
                                                                        '#05445E',
                                                                }}
                                                            ></div>
                                                        )}
                                                    </Tooltip>
                                                    <Text
                                                        className={
                                                            styles.keyText
                                                        }
                                                        // onClick={() => viewTask(_id)}
                                                    >
                                                        {card?.taskKey}
                                                    </Text>
                                                </Space>
                                            </Row>
                                        </Col>
                                        <Row align={'middle'}>
                                            <Space size={'small'}>
                                                <Tooltip
                                                    arrow={false}
                                                    title={
                                                        card?.estimation === 0
                                                            ? 'Unestimated'
                                                            : estimateFn(
                                                                  card?.estimation,
                                                                  currentProjectData
                                                              )
                                                    }
                                                >
                                                    <Badge
                                                        count={
                                                            card?.estimation ===
                                                            0
                                                                ? '0m'
                                                                : estimateFn(
                                                                      card?.estimation,
                                                                      currentProjectData
                                                                  )
                                                        }
                                                        color="#85BBF9"
                                                        style={{
                                                            minWidth: '30px',
                                                            cursor: 'auto',
                                                        }}
                                                    />
                                                </Tooltip>
                                                {card?.info?.assigneeId
                                                    ?.length > 0 ? (
                                                    <div>
                                                        {/* <Popover
                                                        className={
                                                            styles.popOverOut
                                                        }
                                                        overlayInnerStyle={{
                                                            padding: 5,
                                                        }}
                                                        content={
                                                            <>
                                                                {filteredAssignees
                                                                    .filter(
                                                                        (
                                                                            data
                                                                        ) =>
                                                                            data?.value !==
                                                                            card
                                                                                ?.assigneeId[0]
                                                                                ?._id
                                                                    )
                                                                    .map(
                                                                        (
                                                                            option
                                                                        ) => {
                                                                            return (
                                                                                <Row
                                                                                    className={
                                                                                        styles.popOverClick
                                                                                    }
                                                                                    style={{
                                                                                        cursor: 'pointer',
                                                                                        padding: 10,
                                                                                        borderRadius: 5,
                                                                                    }}
                                                                                    onClick={() => {
                                                                                        updateTaskAssignee(
                                                                                            option?.value,
                                                                                            card?._id
                                                                                        )
                                                                                    }}
                                                                                >
                                                                                    <Space size="small">
                                                                                        <Avatar
                                                                                            style={{
                                                                                                backgroundColor:
                                                                                                    '#f56a00',
                                                                                                cursor: 'pointer',
                                                                                                height: '30px',
                                                                                                width: '30px',
                                                                                            }}
                                                                                        >
                                                                                            {option?.label[0].toUpperCase()}
                                                                                        </Avatar>
                                                                                        <Text
                                                                                            style={{
                                                                                                margin: 0,
                                                                                            }}
                                                                                        >
                                                                                            {
                                                                                                option?.label
                                                                                            }
                                                                                        </Text>
                                                                                    </Space>
                                                                                </Row>
                                                                            )
                                                                        }
                                                                    )}
                                                            </>
                                                        }
                                                        trigger="click"
                                                        arrow={false}
                                                        placement="bottom"
                                                    > */}
                                                        <Tooltip
                                                            title={
                                                                card?.info
                                                                    ?.assigneeId
                                                                    ?.length >
                                                                    0 ||
                                                                Object.keys(
                                                                    card?.assigneeId
                                                                )?.length > 0
                                                                    ? card?.info
                                                                          ?.assigneeId[0]
                                                                          ?.name
                                                                    : 'Unassigned'
                                                            }
                                                        >
                                                            {card?.info
                                                                ?.assigneeId
                                                                ?.length > 0 ||
                                                            Object.keys(
                                                                card?.assigneeId
                                                            )?.length > 0 ? (
                                                                card?.info
                                                                    ?.assigneeId[0]
                                                                    ?.profilePicture ? (
                                                                    <Image
                                                                        src={
                                                                            card
                                                                                ?.info
                                                                                ?.assigneeId[0]
                                                                                ?.profilePicture
                                                                        }
                                                                        height={
                                                                            22
                                                                        }
                                                                        width={
                                                                            22
                                                                        }
                                                                        style={{
                                                                            borderRadius:
                                                                                '50%',
                                                                            minWidth:
                                                                                '22px',
                                                                        }}
                                                                        preview={
                                                                            false
                                                                        }
                                                                    />
                                                                ) : (
                                                                    <AvatarComponent
                                                                        props={{
                                                                            avatarName:
                                                                                card
                                                                                    ?.info
                                                                                    ?.assigneeId[0]
                                                                                    ?.name,
                                                                            styleAvatar:
                                                                                {
                                                                                    fontSize:
                                                                                        '11px',
                                                                                },
                                                                            className:
                                                                                [
                                                                                    'active-tooltip',
                                                                                    styles.avatarCard,
                                                                                ],
                                                                        }}
                                                                    />
                                                                )
                                                            ) : (
                                                                <Avatar
                                                                    icon={
                                                                        <UserOutlined
                                                                            style={{
                                                                                fontSize:
                                                                                    '14px',
                                                                            }}
                                                                        />
                                                                    }
                                                                    className={[
                                                                        'active-tooltip',
                                                                        styles.avatarCard,
                                                                    ]}
                                                                />
                                                            )}
                                                        </Tooltip>
                                                        {/* </Popover> */}
                                                    </div>
                                                ) : (
                                                    <Tooltip
                                                        title={'Unassigned'}
                                                    >
                                                        <Avatar
                                                            className={
                                                                styles.avatarCard
                                                            }
                                                            icon={
                                                                <UserOutlined
                                                                    style={{
                                                                        fontSize:
                                                                            '14px',
                                                                    }}
                                                                />
                                                            }
                                                        />
                                                    </Tooltip>
                                                )}
                                            </Space>
                                        </Row>
                                    </Row>
                                </Card>
                            </div>
                        )
                    }}
                </Draggable>
            </>
        )
    }

    const dropDownIcon = [
        {
            title: deletePermissionCheck('epics', userProfile) ? (
                'Delete'
            ) : (
                <Tooltip
                    arrow={false}
                    placement="bottom"
                    title="You don't have permission to delete task"
                >
                    Delete
                </Tooltip>
            ),
            icon: <DeleteIcon />,
            onClick: () => {
                setState((prevState) => ({ ...prevState, open: false }))
                if (deletePermissionCheck('epics', userProfile)) {
                    setState((prevState) => ({
                        ...prevState,
                        deleteEpicModal: true,
                    }))
                }
            },
        },
    ]

    const dropDownEpicIcon = [
        {
            title: deletePermissionCheck('tasks', userProfile) ? (
                <DeleteTaskComponent />
            ) : (
                <Tooltip
                    arrow={false}
                    placement="bottom"
                    title="You don't have permission to delete task"
                >
                    Delete
                </Tooltip>
            ),
            icon: <DeleteIcon />,
            onClick: (_id) => {
                if (deletePermissionCheck('tasks', userProfile)) {
                    setState({
                        ...state,
                        deleteCheck: true,
                        taskID: _id,
                        deleteModal: true,
                    })
                }
            },
        },
    ]

    const dropDownContent = (dropDownIcon, _id) => {
        return (
            <>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        rowGap: '8px',
                    }}
                >
                    {dropDownIcon?.length > 0 &&
                        dropDownIcon.map((data) => (
                            <Row
                                style={{
                                    cursor: 'pointer',
                                }}
                                align={'middle'}
                                onClick={(e) => {
                                    data?.onClick(_id)
                                    e.stopPropagation()
                                }}
                            >
                                <div
                                    style={{
                                        marginRight: '10px',
                                    }}
                                >
                                    {data?.icon}
                                </div>
                                <Text className="sub-title">{data?.title}</Text>
                            </Row>
                        ))}
                </div>
            </>
        )
    }

    return (
        <>
            <DeletePopUp
                previewDelete={state?.deleteEpicModal}
                onDelete={() => {
                    deleteEpic()
                }}
                loadingButton={state?.deleteButtonLoading}
                onCancel={() => {
                    setState({
                        ...state,
                        deleteButtonLoading: false,
                        deleteEpicModal: false,
                    })
                }}
                modalTitle="Delete Epic"
                pageKey="epic"
            />
            <DeletePopUp
                previewDelete={state?.deleteModal}
                onDelete={() => {
                    setState({
                        ...state,
                        deleteButtonLoadingTask: true,
                        deleteCheck: false,
                    })
                    deleteTask()
                }}
                loadingButton={state?.deleteButtonLoadingTask}
                onCancel={() => {
                    setState({
                        ...state,
                        deleteCheck: false,
                        deleteButtonLoadingTask: false,
                        deleteModal: false,
                    })
                }}
                modalTitle="Delete Task"
                pageKey="Task"
            />
            <Draggable
                key={_id}
                draggableId={_id}
                index={position}
                isDragDisabled={card?.type === 'create'}
            >
                {(provided) => (
                    <>
                        <Col
                            xs={12}
                            sm={12}
                            md={12}
                            lg={10}
                            xl={6}
                            xxl={5}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                                height: 'max-content',
                                ...provided.draggableProps.style,
                                pointerEvents: pointerEvent ? 'auto' : 'none',
                            }}
                        >
                            <Card
                                bordered={false}
                                className={styles.epicCard}
                                title={null}
                                style={{
                                    borderRadius: '6px',
                                }}
                                styles={{
                                    body: {
                                        padding: '20px 16px',
                                        minHeight: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-between',
                                        opacity: createEpicLoading ? 0.5 : 1,
                                        pointerEvents: createEpicLoading
                                            ? 'none'
                                            : 'auto',
                                    },
                                }}
                            >
                                {createEpicLoading && (
                                    <div className={styles.loaderEpic}></div>
                                )}
                                <Row align={'middle'} justify={'space-between'}>
                                    {card?.type === 'create' ||
                                    (editInput.id === _id &&
                                        editPermissionCheck(
                                            'epics',
                                            userProfile
                                        )) ? (
                                        <Input
                                            style={{
                                                textTransform: 'capitalize',
                                                backgroundColor: 'transparent',
                                            }}
                                            name="epicTitle"
                                            className="active-epic"
                                            defaultValue={title}
                                            onKeyUp={(e) => {
                                                if (!createEpicLoading) {
                                                    if (
                                                        e.target.value?.length >
                                                        0
                                                    ) {
                                                        if (
                                                            e.key === 'Enter' ||
                                                            e.key === 13
                                                        ) {
                                                            if (
                                                                e.target.value[0].includes(
                                                                    ' '
                                                                )
                                                            ) {
                                                                message.error(
                                                                    'No space is allowed at the beginning'
                                                                )
                                                            } else if (
                                                                e.target.value
                                                                    ?.length >
                                                                20
                                                            ) {
                                                                message.error(
                                                                    'Epic name should be maximum 20 characters long'
                                                                )
                                                            } else {
                                                                setCreateEpicLoading(
                                                                    true
                                                                )
                                                                createEpic(
                                                                    position
                                                                )
                                                            }
                                                        }
                                                    } else if (
                                                        e.key !== 'Backspace' &&
                                                        e.key !== 8
                                                    ) {
                                                        message.error(
                                                            'Epic name should not be empty'
                                                        )
                                                    }
                                                    setState({
                                                        ...state,
                                                        epicTitle:
                                                            e.target.value.trimStart(),
                                                    })
                                                }
                                            }}
                                        />
                                    ) : (
                                        <Text
                                            style={{
                                                textTransform: 'capitalize',
                                                cursor: 'pointer',
                                                fontWeight: 600,
                                            }}
                                            onClick={() => {
                                                dispatch(
                                                    changeEditInput({
                                                        open: true,
                                                        id: _id,
                                                    })
                                                )
                                            }}
                                        >
                                            {title?.length > 30
                                                ? title
                                                      .substring(0, 30)
                                                      .concat('...')
                                                : title}
                                        </Text>
                                    )}
                                    {card?.type !== 'create' &&
                                        title !== 'general' &&
                                        editInput?.id !== _id && (
                                            <Popover
                                                content={dropDownContent(
                                                    dropDownIcon
                                                )}
                                                trigger="click"
                                                placement="bottom"
                                                open={state?.open}
                                                overlayInnerStyle={{
                                                    padding: '10px',
                                                }}
                                                arrow={false}
                                                onOpenChange={handleOpenChange}
                                            >
                                                <div
                                                    style={{
                                                        cursor: 'pointer',
                                                    }}
                                                >
                                                    <EllipsisIcon />
                                                </div>
                                            </Popover>
                                        )}
                                </Row>
                                <Row
                                    justify={'space-between'}
                                    align={'middle'}
                                    style={{
                                        marginTop:
                                            card?.type === 'create'
                                                ? '10px'
                                                : '13px',
                                    }}
                                >
                                    <Row
                                        align={'middle'}
                                        style={{
                                            columnGap: '10px',
                                        }}
                                    >
                                        <Tooltip title="Epic">
                                            <div
                                                className={
                                                    styles.epicButtonMain
                                                }
                                            >
                                                <EpicIcon />
                                            </div>
                                        </Tooltip>
                                        {card?.epicKey && (
                                            <Text
                                                style={{
                                                    fontWeight: 500,
                                                }}
                                            >
                                                {card?.epicKey}
                                            </Text>
                                        )}
                                    </Row>
                                    {card?.type === 'create' && (
                                        <Row
                                            style={{
                                                float: 'right',
                                            }}
                                        >
                                            <Space size={'small'}>
                                                <div
                                                    className={
                                                        styles.epicButtonMain
                                                    }
                                                    onClick={() => {
                                                        if (
                                                            !createEpicLoading
                                                        ) {
                                                            if (
                                                                state?.epicTitle
                                                                    ?.length > 0
                                                            ) {
                                                                if (
                                                                    state?.epicTitle[0].includes(
                                                                        ' '
                                                                    )
                                                                ) {
                                                                    message.error(
                                                                        'No space is allowed at the beginning'
                                                                    )
                                                                } else if (
                                                                    state
                                                                        ?.epicTitle
                                                                        ?.length >
                                                                    20
                                                                ) {
                                                                    message.error(
                                                                        'Epic name should be maximum 20 characters long'
                                                                    )
                                                                } else {
                                                                    setCreateEpicLoading(
                                                                        true
                                                                    )
                                                                    createEpic(
                                                                        position
                                                                    )
                                                                }
                                                            } else {
                                                                message.error(
                                                                    'Epic name should not be empty'
                                                                )
                                                            }
                                                        }
                                                    }}
                                                >
                                                    <CheckOutlined
                                                        style={{
                                                            fontSize: '10px',
                                                            color: token
                                                                .colorPalette
                                                                .baseColor
                                                                .white,
                                                            cursor:
                                                                state?.epicTitle
                                                                    ?.length ===
                                                                0
                                                                    ? 'not-allowed'
                                                                    : 'pointer',
                                                        }}
                                                    />
                                                </div>
                                                <div
                                                    className={
                                                        styles.epicButtonMain
                                                    }
                                                    onClick={() =>
                                                        cancelEpic(position)
                                                    }
                                                >
                                                    <CloseOutlined
                                                        style={{
                                                            fontSize: '10px',
                                                            color: token
                                                                .colorPalette
                                                                .baseColor
                                                                .white,
                                                        }}
                                                    />
                                                </div>
                                            </Space>
                                        </Row>
                                    )}
                                </Row>
                                {addPermissionCheck('epics', userProfile) && (
                                    <div className={styles.epicCreate}>
                                        <div
                                            style={{
                                                backgroundColor: '#1677ff',
                                                height: '20px',
                                                width: '20px',
                                                borderRadius: 3,
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                opacity: 1,
                                                zIndex: 999999,
                                            }}
                                            onClick={() => {
                                                createEpicCard(_id)
                                            }}
                                        >
                                            <PlusOutlined
                                                style={{
                                                    color: '#ffffff',
                                                    fontSize: '14px',
                                                }}
                                            />
                                        </div>
                                        <div
                                            style={{
                                                backgroundColor: '#1677ff',
                                                height: '85px',
                                                width: '3px',
                                                borderRadius: 5,
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                        />
                                    </div>
                                )}
                            </Card>
                            <Droppable
                                droppableId={_id}
                                type={'column'}
                                direction={'vertical'}
                            >
                                {(provided) => (
                                    <div
                                        ref={provided.innerRef}
                                        className={styles.column}
                                        style={{
                                            display: 'flex',
                                            height: 'fitContent',
                                            flexDirection: 'column',
                                        }}
                                        {...provided.droppableProps}
                                    >
                                        {tasks?.length > 0 &&
                                            tasks.map(
                                                (item) =>
                                                    item?.list?.length > 0 &&
                                                    item?._id === _id &&
                                                    item.list.map(
                                                        (card) =>
                                                            card?.info
                                                                ?.parentTask
                                                                ?.length ===
                                                                0 && (
                                                                <CardSection
                                                                    key={
                                                                        card?._id
                                                                    }
                                                                    props={{
                                                                        card,
                                                                    }}
                                                                />
                                                            )
                                                    )
                                            )}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </Col>
                    </>
                )}
            </Draggable>
        </>
    )
}

export default EpicCardSectionFinal
