import { useDispatch, useSelector } from 'react-redux'

// antd
import { Row, Typography, Avatar, Popover, theme, Select, message } from 'antd'

// slices
import { loginState } from '../../login/login.slice'
import {
    talentListGet,
    talentState,
    totalTalent,
    updateTalent,
} from '../../talent/talent.slice'
import { profileState, updateUserGet } from '../profile.slice'
import { recruitState } from '../../recruit/recruit.slice'

// constants
import { RECRUIT_STATUS } from '../../../constants/recruit'
import { TALENT_FILTER_STATUS } from '../../../constants/general'

// assets
import { IncompleteStepIcon } from '../../../assets/icons'

const TalentBasicDetail = ({ profileData, pageKeyProfile }) => {
    const { Option } = Select
    const { useToken } = theme
    const { token } = useToken()

    const { Text, Title } = Typography

    const dispatch = useDispatch()

    const { userProfile } = useSelector(loginState)
    const { pageLimit, search, filter } = useSelector(talentState)
    const { defaultTab } = useSelector(recruitState)
    const { status } = useSelector(profileState)

    // UPDATE RECRUIT STATUS
    async function updateStatus(status) {
        const result = await dispatch(
            updateTalent({
                _id: profileData?.data?._id,
                updatedData: {
                    data: {
                        status,
                    },
                },
            })
        )

        const data = result?.payload?.data
        if (data) {
            const { success, message: errorMessage } = data
            if (success) {
                // filteredData()
                dispatch(
                    await totalTalent({
                        filter: {
                            ...filter,
                            status:
                                pageKeyProfile === 'recruit'
                                    ? defaultTab
                                    : TALENT_FILTER_STATUS,
                        },
                    })
                )
                dispatch(
                    await talentListGet({
                        skip: 0,
                        limit: pageLimit,
                        search,
                        filter: {
                            ...filter,
                            status:
                                pageKeyProfile === 'recruit'
                                    ? defaultTab
                                    : TALENT_FILTER_STATUS,
                        },
                    })
                )
                dispatch(
                    updateUserGet({
                        _id: profileData?.data?._id,
                    })
                )
                message.success(errorMessage)
            } else {
                if (errorMessage) {
                    message.error(errorMessage)
                } else {
                    message.error('Something went wrong, try again later.')
                }
            }
        }
    }

    return (
        <>
            <Row
                justify={'space-between'}
                align={'middle'}
                style={{
                    width: pageKeyProfile === 'recruit' ? '100%' : 'auto',
                    flexFlow: 'row nowrap',
                }}
            >
                <Row
                    style={{
                        columnGap: '30px',
                        flexFlow: 'row nowrap',
                    }}
                    align={'middle'}
                >
                    {profileData?.data?.profilePicture?.length > 0 ? (
                        <>
                            <Avatar
                                style={{
                                    width: '110px',
                                    height: '110px',
                                    minWidth: '110px',
                                }}
                                className={'uploadAvatar'}
                                src={profileData?.data?.profilePicture}
                            ></Avatar>
                        </>
                    ) : (
                        <Avatar
                            style={{
                                color: token.colorPalette.baseColor.white,
                                width: '110px',
                                height: '110px',
                                minWidth: '110px',
                                fontSize: '32px',
                            }}
                            className={'uploadAvatar'}
                        >
                            {userProfile?.name[0].toUpperCase()}
                            {userProfile?.name.split(' ')[1] !== undefined &&
                                userProfile?.name
                                    .split(' ')[1][0]
                                    .toUpperCase()}
                        </Avatar>
                    )}

                    <div>
                        <Row
                            style={{
                                columnGap: '7px',
                            }}
                            align={'middle'}
                        >
                            <Title
                                className={'titleMain'}
                                style={{
                                    marginBottom: '5px',
                                }}
                            >
                                {profileData?.data?.name || 'N/A'}
                            </Title>
                            {profileData?.data?.profilePicture === '' &&
                                profileData?.data?.location === '' && (
                                    <Popover
                                        content={
                                            'Please complete your personal information.'
                                        }
                                    >
                                        <div
                                            style={{
                                                height: '20px',
                                                width: '20px',
                                            }}
                                        >
                                            <IncompleteStepIcon />
                                        </div>
                                    </Popover>
                                )}
                        </Row>
                        <Row
                            align={'middle'}
                            style={{
                                flexFlow: 'row wrap',
                            }}
                        >
                            <Text className="titleSecondary">
                                {profileData?.data?.designation?.name || 'N/A'}
                            </Text>
                            &nbsp; | &nbsp;
                            <Text className="titleSecondary">
                                {profileData?.data?.location || 'N/A'}
                            </Text>
                        </Row>
                        <Row align={'middle'}>
                            <Text className="titleSecondary">
                                {profileData?.data?.countryCode}{' '}
                                {profileData?.data?.phone || 'N/A'}
                            </Text>
                            &nbsp; | &nbsp;
                            <Text className="titleSecondary">
                                {profileData?.data?.email || 'N/A'}
                            </Text>
                        </Row>
                    </div>
                </Row>
                {pageKeyProfile === 'recruit' && status === 'loaded' && (
                    <>
                        <Select
                            placeholder="Select a Status"
                            onChange={(event) => {
                                if (event !== profileData?.data?.status) {
                                    updateStatus(event)
                                }
                            }}
                            className="recruitStatusSelect"
                            defaultValue={profileData?.data?.status}
                            style={{
                                padding: '0px 0px',
                            }}
                        >
                            {RECRUIT_STATUS?.length > 0 &&
                                RECRUIT_STATUS.map((data) => (
                                    <Option
                                        value={data?.value}
                                        key={data?.label}
                                    >
                                        {data?.label}
                                    </Option>
                                ))}
                        </Select>
                    </>
                )}
            </Row>
        </>
    )
}

export default TalentBasicDetail
