import React from 'react'

function EditWorkSpaceIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="16"
            fill="none"
            viewBox="0 0 17 16"
        >
            <path
                fill="#212B36"
                fillRule="evenodd"
                d="M13.66.6l2.74 2.74a1.92 1.92 0 01.07 2.71l-9 9a2 2 0 01-1.21.57L2.09 16H2a1 1 0 01-1-1.09l.43-4.17A2 2 0 012 9.53l9-9a2 2 0 012.66.07zM10.32 4L13 6.68l2-1.95L12.27 2l-1.95 2z"
                clipRule="evenodd"
            ></path>
        </svg>
    )
}

export default EditWorkSpaceIcon
