import React from 'react'

function NoReportsFoundIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 517 400"
        >
            <g clipPath="url(#clip0_6884_12137)">
                <path
                    fill="#F0F0F0"
                    d="M70.03 387.574l5.544-8.908 1.108 5.09c.194.892.388 1.798.568 2.696 1.574-1.344 3.25-2.588 4.889-3.782 5.033-3.652 10.064-7.31 15.092-10.972l-1.575 9.132c-.97 5.622-1.984 11.36-4.386 16.501a25.638 25.638 0 01-.863 1.719H67.011a7.552 7.552 0 01-.388-1.611.568.568 0 01-.007-.108c-.367-3.487 1.56-6.773 3.415-9.757z"
                ></path>
                <path
                    fill="#E6E6E6"
                    d="M422.342 0H93.038a10.69 10.69 0 00-10.673 10.673v222.215a10.683 10.683 0 0010.673 10.673h329.304a10.68 10.68 0 0010.673-10.673V10.673A10.691 10.691 0 00422.342 0z"
                ></path>
                <path
                    fill="#fff"
                    d="M420.313 236.204H204.541a114.433 114.433 0 01-114.1-114.1V13.428A6.08 6.08 0 0196.51 7.35h323.804a6.078 6.078 0 016.067 6.08v216.708a6.078 6.078 0 01-1.779 4.288 6.078 6.078 0 01-4.288 1.779z"
                ></path>
                <path
                    fill="#CACACA"
                    d="M403.315 37.566H100.333a1.206 1.206 0 010-2.41h302.982a1.204 1.204 0 010 2.41z"
                ></path>
                <path
                    fill="#3F3D56"
                    d="M120.958 29.005c3.899 0 7.061-3.232 7.061-7.219 0-3.986-3.162-7.218-7.061-7.218-3.9 0-7.062 3.232-7.062 7.218 0 3.987 3.162 7.219 7.062 7.219zM145.35 29.005c3.9 0 7.061-3.232 7.061-7.219 0-3.986-3.161-7.218-7.061-7.218s-7.061 3.232-7.061 7.218c0 3.987 3.161 7.219 7.061 7.219zM169.745 29.005c3.899 0 7.061-3.232 7.061-7.219 0-3.986-3.162-7.218-7.061-7.218-3.9 0-7.061 3.232-7.061 7.218 0 3.987 3.161 7.219 7.061 7.219z"
                ></path>
                <path
                    fill="#6C63FF"
                    d="M306.668 145.879H183.971a8.78 8.78 0 01-8.861-8.039 8.623 8.623 0 012.109-6.261 8.641 8.641 0 016.499-2.956h122.95a8.627 8.627 0 110 17.256z"
                ></path>
                <path
                    fill="#E6E6E6"
                    d="M306.897 97.557a7.795 7.795 0 100-15.59 7.795 7.795 0 000 15.59z"
                ></path>
                <path
                    fill="#fff"
                    d="M305.832 93.819a.872.872 0 01-.693-.347l-2.127-2.835a.872.872 0 01-.056-.965.86.86 0 01.533-.4.867.867 0 01.91.324l1.392 1.855 3.573-5.36a.868.868 0 011.447.957l-.004.005-4.253 6.38a.871.871 0 01-.698.386h-.024z"
                ></path>
                <path
                    fill="#E6E6E6"
                    d="M306.897 192.466a7.795 7.795 0 100-15.59 7.795 7.795 0 000 15.59z"
                ></path>
                <path
                    fill="#fff"
                    d="M305.832 188.728a.868.868 0 01-.693-.347l-2.127-2.835a.87.87 0 01.477-1.364.87.87 0 01.91.323l1.392 1.855 3.573-5.36a.867.867 0 011.447.957l-.004.005-4.253 6.38a.875.875 0 01-.698.386h-.024z"
                ></path>
                <path
                    fill="#6C63FF"
                    d="M244.115 98.425h-60.144a8.779 8.779 0 01-8.861-8.04 8.627 8.627 0 014.777-8.317 8.634 8.634 0 013.831-.9h60.397a8.629 8.629 0 010 17.257z"
                ></path>
                <path
                    fill="#3F3D56"
                    d="M306.668 98.784h-122.95a8.986 8.986 0 01-8.988-8.987 8.986 8.986 0 018.988-8.988h122.95a8.99 8.99 0 018.988 8.988 8.99 8.99 0 01-8.988 8.987zm-122.95-16.537a7.55 7.55 0 000 15.1h122.95a7.55 7.55 0 100-15.1h-122.95zM306.668 146.239h-122.95a8.984 8.984 0 01-6.355-2.633 8.984 8.984 0 010-12.71 8.987 8.987 0 016.355-2.632h122.95c2.384 0 4.67.947 6.356 2.632a8.99 8.99 0 010 12.71 8.988 8.988 0 01-6.356 2.633zm-122.95-16.537a7.549 7.549 0 100 15.099h122.95a7.55 7.55 0 100-15.099h-122.95z"
                ></path>
                <path
                    fill="#6C63FF"
                    d="M218.23 193.334h-34.259a8.78 8.78 0 01-8.861-8.039 8.631 8.631 0 018.608-9.217h34.512a8.627 8.627 0 110 17.256z"
                ></path>
                <path
                    fill="#3F3D56"
                    d="M306.668 193.693h-122.95a8.987 8.987 0 110-17.975h122.95a8.988 8.988 0 010 17.975zm-122.95-16.537a7.553 7.553 0 00-7.55 7.55 7.55 7.55 0 007.55 7.549h122.95a7.55 7.55 0 100-15.099h-122.95z"
                ></path>
                <path
                    fill="#CCC"
                    d="M206.726 71.9h-28.76a2.877 2.877 0 01-2.034-4.909 2.878 2.878 0 012.034-.842h28.76a2.878 2.878 0 012.034 4.91c-.54.539-1.271.842-2.034.842zM206.726 119.355h-28.76a2.879 2.879 0 01-2.876-2.876 2.876 2.876 0 012.876-2.876h28.76a2.875 2.875 0 010 5.752zM206.726 166.81h-28.76a2.875 2.875 0 010-5.752h28.76a2.879 2.879 0 012.876 2.876 2.875 2.875 0 01-2.876 2.876z"
                ></path>
                <path
                    fill="#3F3D56"
                    d="M163.594 72.52c1.726 0 3.125-1.43 3.125-3.195s-1.399-3.195-3.125-3.195-3.125 1.43-3.125 3.195c0 1.764 1.399 3.195 3.125 3.195zM163.594 119.974c1.726 0 3.125-1.43 3.125-3.195 0-1.764-1.399-3.194-3.125-3.194s-3.125 1.43-3.125 3.194c0 1.765 1.399 3.195 3.125 3.195zM163.594 167.429c1.726 0 3.125-1.431 3.125-3.195 0-1.765-1.399-3.195-3.125-3.195s-3.125 1.43-3.125 3.195c0 1.764 1.399 3.195 3.125 3.195z"
                ></path>
                <path
                    fill="#FFB6B6"
                    d="M369.531 391.278l-7.119-.001-3.387-27.459 10.507.001-.001 27.459z"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M369.782 398.704l-21.891-.001v-.277a8.518 8.518 0 018.52-8.52l4-3.034 7.461 3.034h1.911l-.001 8.798z"
                ></path>
                <path
                    fill="#FFB6B6"
                    d="M397.572 391.278l-7.119-.001-3.387-27.459 10.507.001-.001 27.459z"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M397.823 398.704l-21.891-.001v-.277a8.518 8.518 0 018.52-8.52l4-3.034 7.461 3.034h1.911l-.001 8.798zM345.033 219.297L340 236.554l5.929 73.083s-3.053 4.57-.896 4.57 4.923 1.013 3.181 3.742c-1.743 2.729 4.213 12.526 4.213 12.526l8.424 52.037 10.734-.158s5.458-3.04 2.395-6.115c-3.063-3.074-5.22-3.074-2.344-5.95 2.876-2.876 2.876-2.01 2.876-4.24 0-2.231 2.882-29.491-3.951-39.948l2.059-42.633 4.849 41.818 9.986 55.788 10.34-.085s4.776-4.604 4.767-8.011c-.008-3.408-2.95-3.691-.76-5.706 2.19-2.016 2.19-32.979-4.281-50.189l.049-76.985s6.358-17.279-.081-24.073c-6.439-6.794-52.456 3.272-52.456 3.272z"
                ></path>
                <path
                    fill="#FFB6B6"
                    d="M409.846 189.19l4.335-44.902a7.28 7.28 0 10-14.52-1.086l-3.073 46.78-3.065 28.347a6.347 6.347 0 00-4.515 5.727 6.36 6.36 0 00.943 3.705 6.36 6.36 0 006.704 2.872 6.36 6.36 0 004.987-5.321 6.348 6.348 0 00-.623-3.772l8.827-32.35z"
                ></path>
                <path
                    fill="#3F3D56"
                    d="M393.591 121.528l15.618 1.958s11.703 22.058 9.294 23.93c-2.408 1.872-13.293 13.551-21.317 8.197-8.025-5.354-3.595-34.085-3.595-34.085z"
                ></path>
                <path
                    fill="#FFB6B6"
                    d="M366.692 100.365c9.156 0 16.578-7.422 16.578-16.577 0-9.156-7.422-16.578-16.578-16.578-9.155 0-16.577 7.422-16.577 16.578 0 9.155 7.422 16.577 16.577 16.577z"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M373.098 99.006c-4.757-.156-5.189-1.412-9.946-1.569-1.515-.05-3.302-.257-4.035-1.584a3.495 3.495 0 01.178-3.112 28.313 28.313 0 001.465-2.882 5.037 5.037 0 00-2.619-6.16 5.036 5.036 0 00-2.369-.479c-1.448.063-2.808.744-4.251.868-1.98.17-6.358-6.366-5.447-8.132.888-1.719 2.673-3.016 2.954-4.93.184-1.251-.333-2.495-.404-3.758-.104-1.873 4.643 2.35 3.774.688-2.133-4.078 17.966-9.133 21.275-4.025 1.237 1.909 3.441 1.778 5.405 2.925 1.288.751 4.539 3.343 5.278 4.959.679 1.648 1 3.422.941 5.204.03 8.288-1.232 15.911-7.917 20.81"
                ></path>
                <path
                    fill="#3F3D56"
                    d="M362.291 104.975l13.661-2.876 10.493 5.047s24.819 9.984 24.735 19.828c-.085 9.844-4.447 31.414-9.456 33.571-5.009 2.157 4.338 9.347 1.462 13.661-2.876 4.314-7.232 33.787-7.232 33.787s10.185 20.289 1.568 24.247c-8.617 3.957-45.031 7.657-47.365 2.493-2.335-5.163-2.356-2.345-7.738-3.754-5.382-1.409.006-21.162 4.862-23.149 4.856-1.987 7.732-45.128 7.732-45.128l-7.732-33.544 11.797-16.605 3.213-7.578z"
                ></path>
                <path
                    fill="#FFB6B6"
                    d="M352.722 197.18l12.342-43.389a7.286 7.286 0 00-4.927-8.949 7.28 7.28 0 00-9.16 5.269l-11.439 45.463-8.115 27.333a6.358 6.358 0 00-5.472 4.821 6.358 6.358 0 006.339 7.846 6.36 6.36 0 005.863-4.337 6.354 6.354 0 00.066-3.823l14.503-30.234z"
                ></path>
                <path
                    fill="#3F3D56"
                    d="M348.903 127.698l15.012 4.736s9.764 14.337 6.108 16.383c-3.655 2.045-5.026 1.613-3.149 5.221 1.878 3.608 4.584 2.2 1.878 3.608-2.706 1.408-15.514 10.938-22.444 4.228-6.931-6.71 2.595-34.176 2.595-34.176z"
                ></path>
                <path
                    fill="#CCC"
                    d="M0 399.144a.856.856 0 00.524.79.85.85 0 00.327.066h514.304a.855.855 0 100-1.711H.855a.844.844 0 00-.79.524.85.85 0 00-.065.326v.005z"
                ></path>
                <path
                    fill="#6C63FF"
                    d="M306.895 151.893c8.106 0 14.676-6.571 14.676-14.676 0-8.106-6.57-14.677-14.676-14.677-8.105 0-14.676 6.571-14.676 14.677 0 8.105 6.571 14.676 14.676 14.676z"
                ></path>
                <path
                    fill="#fff"
                    d="M304.894 144.855a1.639 1.639 0 01-1.306-.653l-4.004-5.339a1.636 1.636 0 01-.31-1.21c.061-.429.29-.816.637-1.075a1.63 1.63 0 012.285.326l2.62 3.493 6.728-10.092a1.63 1.63 0 012.961.575c.087.424.001.865-.238 1.226l-.007.01-8.007 12.012a1.63 1.63 0 01-1.314.727h-.045z"
                ></path>
            </g>
            <defs>
                <clipPath id="clip0_6884_12137">
                    <path fill="#fff" d="M0 0H516.01V400H0z"></path>
                </clipPath>
            </defs>
        </svg>
    )
}

export default NoReportsFoundIcon
