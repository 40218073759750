import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'

// routes
import { PUBLIC_ROUTES } from '../../routes'

function TalentLogin() {
    const navigate = useNavigate()

    const loginInTalent = async () => {
        try {
            const response = await axios({
                url: `${window.location.origin}/backend${PUBLIC_ROUTES.talentLogin}/${window.location.search}`,
                method: 'get',
            })
            if (response?.data?.success) {
                // const checkLogin = await dispatch(isLoggedInLogin())
                // const checkSuccessLogin = checkLogin?.payload?.data
                // const { success } = checkSuccessLogin
                // if (success) {
                //     navigate(PRIVATE_ROUTES.overview)
                // }
                navigate('/')
                // window.location.reload()
            } else {
                navigate('/')
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        loginInTalent()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <></>
}

export default TalentLogin
