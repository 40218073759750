import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
    create,
    count,
    read,
    update,
    deleteUser,
    listRoles,
    getUser,
} from './talent.service'
import { RECRUIT_FILTER_STATUS } from '../../constants/general'

const initialState = {
    search: '',
    status: 'loaded',
    updatedDataStatus: 'loaded',
    formButtonType: 'saveButtonLoading',
    selectedData: undefined,
    modalVariations: {
        open: false,
        formName: 'talentDataForm',
        saveButtonLoading: false,
    },
    talentList: [],
    dataCount: 0,
    isNext: false,
    pageLimit: 10,
    currentPage: 1,
    skipPage: 0,
    filter: {
        isOurUser: '',
        isActive: '',
        status: RECRUIT_FILTER_STATUS,
    },
}

export const addTalent = createAsyncThunk(
    'talentSlice/create',
    async (payload = {}) => {
        const response = await create(payload)
        return response
    }
)

export const updateTalent = createAsyncThunk(
    'talentSlice/update',
    async (payload = {}) => {
        const response = await update(payload)
        return response
    }
)

export const totalTalent = createAsyncThunk(
    'talentSlice/count',
    async (payload = {}) => {
        const response = await count(payload)
        return response
    }
)

export const talentListGet = createAsyncThunk(
    'talentSlice/read',
    async (payload = {}) => {
        const response = await read(payload)
        return response
    }
)

export const deleteTalent = createAsyncThunk(
    'talentSlice/delete',
    async (payload = {}) => {
        const response = await deleteUser(payload)
        return response
    }
)

export const roleList = createAsyncThunk(
    'talentSlice/listRoles',
    async (payload = {}) => {
        const response = await listRoles(payload)
        return response
    }
)

export const talentGet = createAsyncThunk(
    'talentSlice/getUser',
    async (payload = {}) => {
        const response = await getUser(payload)
        return response
    }
)

export const talentSlice = createSlice({
    name: 'talentSlice',
    initialState,
    reducers: {
        switchTalentModal: (state, action) => {
            state.modalVariations = action.payload
            state.selectedData = undefined
        },
        getTalentDetails: (state, action) => {
            state.selectedData = action.payload
        },
        checkButtonType: (state, action) => {
            state.formButtonType = action.payload
        },
        changePage: (state, action) => {
            state.currentPage = action.payload.currentPage
            state.skipPage = action.payload.skip
            state.pageLimit = action.payload.pageLimit
        },
        searchTalent: (state, action) => {
            state.search = action.payload
        },
        clearUsers: (state) => {
            state.talentList = []
            state.search = ''
        },
        filterChange: (state, action) => {
            const dropDownValue = {
                ...state.filter,
                ...action.payload,
            }
            Object.keys(dropDownValue).forEach((key) => {
                if (dropDownValue[key] === '') {
                    delete dropDownValue[key]
                }
            })
            state.filter = dropDownValue
        },
        clearFilter: (state) => {
            state.filter = {
                isOurUser: '',
                isActive: '',
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(talentListGet.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(talentListGet.fulfilled, (state, action) => {
                state.status = 'loaded'
                state.talentList = action.payload.data
                state.isNext = action.payload.isNext
            })
            .addCase(totalTalent.fulfilled, (state, action) => {
                state.dataCount = action.payload.data
            })
            .addCase(talentGet.pending, (state) => {
                state.updatedDataStatus = 'loading'
            })
            .addCase(talentGet.fulfilled, (state, action) => {
                state.updatedDataStatus = 'loaded'
                state.selectedData = action?.payload.data?.data
            })
    },
})

export default talentSlice.reducer

export const talentState = (state) => {
    const { talentSlice } = state
    const {
        search,
        status,
        updatedDataStatus,
        formButtonType,
        selectedData,
        modalVariations,
        talentList,
        dataCount,
        isNext,
        pageLimit,
        currentPage,
        skipPage,
        filter,
    } = talentSlice
    return {
        search,
        status,
        updatedDataStatus,
        formButtonType,
        selectedData,
        modalVariations,
        talentList,
        dataCount,
        isNext,
        pageLimit,
        currentPage,
        skipPage,
        filter,
    }
}

export const {
    checkButtonType,
    changePage,
    switchTalentModal,
    searchTalent,
    clearFilter,
    clearUsers,
    filterChange,
    getTalentDetails,
} = talentSlice.actions
