import axiosNew from '../../helpers/axios'

// ADD / REMOVE FILE
export const addRemoveFile = async (payload) => {
    return new Promise(async (resolve) => {
        try {
            let data = payload?.fileObject ?? {}
            let progressCount
            const addRemoveFile = await axiosNew({
                url: 'files/add-remove/' + payload?.id,
                data,
                onUploadProgress: (progressEvent) => {
                    progressCount = progressEvent.loaded
                },
            })
            resolve({
                data: addRemoveFile?.data ?? {},
                progressCount,
            })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// COUNT FILES
export const count = async (payload) => {
    const { search, projectId } = payload
    return new Promise(async (resolve) => {
        try {
            let data = {
                filter: {
                    projectId,
                },
            }
            if (search?.length > 0) {
                data['search'] = {
                    displayName: search,
                }
            }
            const count = await axiosNew({
                url: 'files/files-count',
                data,
            })
            resolve({ data: count?.data ?? 0 })
        } catch (error) {
            resolve({ data: 0 })
        }
    })
}

// LIST FILES
export const read = (payload) => {
    const { search = '', skip = 0, limit = 0, projectId } = payload
    return new Promise(async (resolve) => {
        try {
            let data = {
                skip,
                limit,
                filter: {
                    projectId,
                },
                populate: {
                    name: [
                        {
                            path: 'createdBy',
                            select: 'name profilePicture',
                        },
                    ],
                },
            }
            if (search?.length > 0) {
                data['search'] = {
                    displayName: search,
                }
            }
            const filesData = await axiosNew({
                url: 'files/list',
                data,
            })
            resolve({
                data: filesData?.data?.data ?? [],
                isNext: filesData?.data?.isNext,
            })
        } catch (error) {
            resolve({ data: [] })
        }
    })
}

// GET INDIVIDUAL FILE
export const fileGet = (payload) => {
    const { fileId } = payload
    return new Promise(async (resolve) => {
        try {
            const filesData = await axiosNew({
                url: `files/list/${fileId}`,
            })
            resolve({
                data: filesData?.data?.data ?? [],
                isNext: filesData?.data?.isNext,
            })
        } catch (error) {
            resolve({ data: [] })
        }
    })
}

// GET DOWNLOAD FILE
export const fileDownload = (payload) => {
    return new Promise(async (resolve) => {
        try {
            const filesData = await axiosNew({
                url: payload,
                method: 'get',
                responseType: 'blob',
                headers: {
                    'Cache-Control': 'no-cache',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': 'Content-Type',
                    'Access-Control-Allow-Methods':
                        'GET, PUT, POST, DELETE, OPTIONS',
                },
            })
            resolve({
                data: filesData?.data,
            })
        } catch (error) {
            resolve({ data: [] })
        }
    })
}

// UPDATE FILE NAME
export const update = async (payload) => {
    return new Promise(async (resolve) => {
        try {
            let data = payload?.updatedData ?? {}
            const update = await axiosNew({
                url: `files/update-file/${payload?.fileId}`,
                data,
            })
            resolve({ data: update?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}
