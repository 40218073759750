import React from 'react'

function MailIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="13"
            height="12"
            fill="none"
            viewBox="0 0 13 12"
        >
            <path
                fill="#212B36"
                d="M1.167 2.208V1.33c0-.32.265-.579.578-.579h10.51c.32 0 .578.26.578.58v9.34c0 .32-.265.58-.578.58H1.745a.58.58 0 01-.578-.58v-.587h10.5V3.258L7 7.458l-5.833-5.25zM0 4.833h2.917V6H0V4.833zM0 7.75h4.667v1.167H0V7.75z"
            ></path>
        </svg>
    )
}

export default MailIcon
