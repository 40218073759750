import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// antd
import { Row, Tabs, Typography, message, theme } from 'antd'

// slices
import { changeRoleView, inquiryState } from '../../inquiries.slice'
import {
    roleInquiryGet,
    roleInquiryUpdate,
    rolesInquiryList,
    rolesInquiryState,
    switchRoleModal,
} from './rolesInquiry.slice'

// components
import DeletePopUp from '../../../../components/deletePopUp/deletePopUp'

// assets
import {
    BackIcon,
    DeleteRoleIcon,
    EditRoleIcon,
} from '../../../../assets/icons'

// role details page and tabs
import BasicRoleDetails from './basicRoleDetails'
import AllMatchesTab from './allMatchesTab'

const RoleDetails = ({ clearFilteredList, setBackActive }) => {
    const { Text, Title } = Typography
    const { useToken } = theme

    const { token } = useToken()

    const dispatch = useDispatch()

    const { selectedInquiry } = useSelector(inquiryState)

    const {
        selectedRoleDetails,
        updatedDataStatus,
        talentStatus,
        pageLimit,
        skipPage,
        search,
    } = useSelector(rolesInquiryState)

    const [tabName, setTabName] = useState('roleDetails')
    const [closeModal, setCloseModal] = useState({
        open: false,
        buttonLoading: false,
    })

    const closeModalCall = async () => {
        const result = await dispatch(
            roleInquiryUpdate({
                updatedData: {
                    isActive: false,
                },
                roleId: selectedRoleDetails?._id,
                workspaceId: selectedInquiry?.client?.workspaceId,
            })
        )
        const data = result?.payload?.data
        if (data) {
            const { success, message: checkMessage } = data
            if (success) {
                dispatch(changeRoleView(false))
                dispatch(
                    rolesInquiryList({
                        filter: {
                            isActive: true,
                        },
                        limit: pageLimit,
                        skip: skipPage,
                        inquiryId: selectedInquiry?._id,
                        workspaceId: selectedInquiry?.client?.workspaceId,
                        search,
                    })
                )
                clearFilteredList()
                setCloseModal({
                    open: false,
                    buttonLoading: false,
                })
                message.success(checkMessage)
            } else {
                setCloseModal({
                    open: false,
                    buttonLoading: false,
                })
                if (checkMessage) {
                    message.error(checkMessage)
                } else {
                    message.error('Something went wrong, try again later.')
                }
            }
        }
    }

    const roleDetailsTab = [
        {
            label: 'ROLE DETAILS',
            key: 'roleDetails',
            children: <BasicRoleDetails tabName={tabName} />,
            disabled:
                updatedDataStatus !== 'loading' && talentStatus !== 'loading'
                    ? false
                    : true,
        },
        {
            label: 'ALL MATCHES',
            key: 'allMatchesTalent',
            children: tabName === 'allMatchesTalent' && (
                <AllMatchesTab tabName={tabName} />
            ),
            disabled:
                updatedDataStatus !== 'loading' && talentStatus !== 'loading'
                    ? false
                    : true,
        },
        {
            label: 'SHORTLISTED',
            key: 'shortListedTalent',
            children: tabName === 'shortListedTalent' && (
                <AllMatchesTab tabName={tabName} />
            ),
            disabled:
                updatedDataStatus !== 'loading' && talentStatus !== 'loading'
                    ? false
                    : true,
        },
        {
            label: 'OFFERED',
            key: 'offeredTalent',
            children: tabName === 'offeredTalent' && (
                <AllMatchesTab tabName={tabName} />
            ),
            disabled:
                updatedDataStatus !== 'loading' && talentStatus !== 'loading'
                    ? false
                    : true,
        },
    ]

    return (
        <>
            <Row
                align={'middle'}
                justify={'space-between'}
                style={{
                    marginBottom: '46px',
                }}
            >
                <Row
                    align={'middle'}
                    style={{
                        columnGap: '15px',
                    }}
                >
                    <div
                        style={{
                            cursor: 'pointer',
                            height: '24px',
                            width: '24px',
                        }}
                        onClick={() => {
                            clearFilteredList()
                            setBackActive()
                            dispatch(changeRoleView(false))
                        }}
                    >
                        <BackIcon />
                    </div>
                    <Title level={3}>{selectedRoleDetails?.title}</Title>
                </Row>
                <Row
                    align={'middle'}
                    style={{
                        columnGap: '25px',
                    }}
                >
                    <Row
                        align={'middle'}
                        style={{
                            columnGap: '12px',
                            cursor:
                                updatedDataStatus !== 'loading'
                                    ? 'pointer'
                                    : 'not-allowed',
                        }}
                        onClick={() => {
                            if (updatedDataStatus !== 'loading') {
                                dispatch(switchRoleModal({ open: true }))
                                dispatch(
                                    roleInquiryGet({
                                        inquiryId: selectedInquiry?._id,
                                        roleId: selectedRoleDetails?._id,
                                        workspaceId:
                                            selectedInquiry?.client
                                                ?.workspaceId,
                                    })
                                )
                            }
                        }}
                    >
                        <EditRoleIcon />
                        <Text
                            className="titleSub"
                            style={{
                                color: token.colorPalette.baseColor.tertiary,
                                fontWeight: token.fontWeightStrong,
                            }}
                        >
                            Edit
                        </Text>
                    </Row>
                    <Row
                        align={'middle'}
                        style={{
                            columnGap: '12px',
                            cursor:
                                updatedDataStatus !== 'loading'
                                    ? 'pointer'
                                    : 'not-allowed',
                        }}
                        onClick={() => {
                            if (updatedDataStatus !== 'loading') {
                                setCloseModal({
                                    ...closeModal,
                                    open: true,
                                })
                            }
                        }}
                    >
                        <DeleteRoleIcon />
                        <Text
                            className="titleSub"
                            style={{
                                color: token.colorPalette.baseColor.error,
                                fontWeight: token.fontWeightStrong,
                            }}
                        >
                            Close Role
                        </Text>
                    </Row>
                </Row>
            </Row>
            <Tabs
                items={roleDetailsTab}
                onChange={(activeKey) => {
                    setTabName(activeKey)
                }}
                rootClassName="generalTabs"
                className="roleDetailsTab"
            />
            <DeletePopUp
                previewDelete={closeModal?.open}
                onDelete={() => {
                    setCloseModal({
                        ...closeModal,
                        buttonLoading: true,
                    })
                    closeModalCall()
                }}
                pageKey="Role"
                loadingButton={closeModal?.buttonLoading}
                onCancel={() => {
                    setCloseModal({
                        open: false,
                        buttonLoading: false,
                    })
                }}
                modalTitle="Close Role"
                keyModal="closeRole"
            />
        </>
    )
}

export default RoleDetails
