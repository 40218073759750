import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { createStandUp, getStandUps, removeStandUp } from './standups.service'

const initialState = {
    search: '',
    status: 'loaded',
    standUpsList: [],
    isNextStandUps: false,
    pageLimitStandUps: 10,
    currentPageStandUps: 1,
    skipPageStandUps: 0,
    countStandUps: 0,
    formDrawer: false,
    selectedStandUps: undefined,
    timeStamp: {
        start: '',
        end: '',
    },
    previewVideo: false,
    previewModal: false,
    recordings: {
        face: '',
        screen: '',
    },
    files: [],
    retakeVideo: {
        visibility: false,
    },
    videoRecordingSummary: false,
    standupVideoModal: {
        open: false,
        videos: [],
    },
    selectedFeed: undefined,
    filterUserId: null,
    currentCalendarDate: new Date(),
}

export const standUpCreate = createAsyncThunk(
    'standUpsSlice/createStandUp',
    async (payload) => {
        const response = await createStandUp(payload)
        return response
    }
)

export const standUpGet = createAsyncThunk(
    'standUpsSlice/getStandUps',
    async (payload) => {
        const response = await getStandUps(payload)
        return response
    }
)

export const standUpRemove = createAsyncThunk(
    'standUpsSlice/removeStandUp',
    async (payload) => {
        const response = await removeStandUp(payload)
        return response
    }
)

let object = {}

export const standUpsSlice = createSlice({
    name: 'standUpsSlice',
    initialState,
    reducers: {
        changePage: (state, action) => {
            state.currentPageStandUps = action.payload.currentPage
            state.skipPageStandUps = action.payload.skip
            state.pageLimitStandUps = action.payload.pageLimit
            state.recordings = initialState.recordings
        },
        switchFormDrawer: (state, action) => {
            state.formDrawer = action.payload.open || false
        },
        timeStampChange: (state, action) => {
            state.timeStamp = action.payload
        },
        videoRecordSummaryStandup: (state, action) => {
            state.videoRecordingSummary = action.payload
        },
        retakeVideoChangeStandup: (state, action) => {
            state.retakeVideo = action.payload
        },
        switchStandupModal: (state, action) => {
            state.standupVideoModal = action.payload
        },
        selectStandUpGet: (state, action) => {
            state.previewModal = state.previewModal ? false : true
            if (state.previewModal === false) {
                state.selectedFeed = undefined
            } else {
                state.selectedFeed = action.payload.data
                if (action.payload.name === 'record') {
                    state.recordings = action.payload.data.recordings
                } else {
                    state.files = action.payload.data.files
                }
            }
        },
        onClickPreview: (state, action) => {
            state.previewModal = state.previewModal ? false : true
            state.recordings = action.payload
            state.files = []
        },
        clearFilterDropDown: (state) => {
            state.timeStamp = initialState.timeStamp
        },
        previewVideoModal: (state, action) => {
            state.previewVideo = action.payload
        },
        setRecordings: (state, action) => {
            if (action.payload.includes('screen')) {
                object = {
                    ...object,
                    screen: action.payload,
                }
            }
            if (action.payload.includes('video')) {
                object = {
                    ...object,
                    face: action.payload,
                }
            }
            state.recordings = {
                ...object,
            }
            localStorage.setItem('videoShow', JSON.stringify(object))
            // state.recordings = action.payload
        },
        clearRecordings: (state) => {
            if (
                JSON.parse(localStorage.getItem('videoShow'))?.screen !==
                    state.recordings.screen &&
                JSON.parse(localStorage.getItem('videoShow'))?.face !==
                    state.recordings.face
            ) {
                state.recordings = {
                    face: '',
                    screen: '',
                }
                localStorage.setItem(
                    'videoShow',
                    JSON.stringify({ face: '', screen: '' })
                )
            }
        },
        clearScreen: (state) => {
            state.recordings = {
                face: '',
                screen: '',
            }
            localStorage.setItem(
                'videoShow',
                JSON.stringify({ face: '', screen: '' })
            )
        },
        setFilterUser: (state, action) => {
            state.filterUserId = action.payload
        },
        setCalendarDate: (state, action) => {
            state.currentCalendarDate = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(standUpGet.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(standUpGet.fulfilled, (state, action) => {
                state.status = 'loaded'
                state.standUpsList = action.payload.data.data
                // state.projectStandFeeds = action.payload.data
                state.isNextStandUps = action.payload.data.isNext
                state.countStandUps = action.payload.data.count
            })
    },
})

export default standUpsSlice.reducer

export const standUpsState = (state) => {
    const { standUpsSlice } = state
    const {
        search,
        status,
        standUpsList,
        isNextStandUps,
        pageLimitStandUps,
        currentPageStandUps,
        skipPageStandUps,
        countStandUps,
        formDrawer,
        selectedStandUps,
        timeStamp,
        previewVideo,
        recordings,
        selectedFeed,
        previewModal,
        files,
        retakeVideo,
        videoRecordingSummary,
        standupVideoModal,
        filterUserId,
        currentCalendarDate,
    } = standUpsSlice
    return {
        search,
        status,
        standUpsList,
        isNextStandUps,
        pageLimitStandUps,
        currentPageStandUps,
        skipPageStandUps,
        countStandUps,
        formDrawer,
        selectedStandUps,
        timeStamp,
        previewVideo,
        recordings,
        selectedFeed,
        previewModal,
        files,
        retakeVideo,
        videoRecordingSummary,
        standupVideoModal,
        filterUserId,
        currentCalendarDate,
    }
}

export const {
    switchFormDrawer,
    timeStampChange,
    clearFilterDropDown,
    previewVideoModal,
    setRecordings,
    clearRecordings,
    changePage,
    selectStandUpGet,
    onClickPreview,
    videoRecordSummaryStandup,
    retakeVideoChangeStandup,
    switchStandupModal,
    clearScreen,
    setFilterUser,
    setCalendarDate,
} = standUpsSlice.actions
