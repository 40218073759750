import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import JoditEditor from 'jodit-react'

// antd
import { message } from 'antd'

// slices
import { inquiryGet, inquiryState, inquiryUpdate } from '../../inquiries.slice'

// components
import Button from '../../../../components/button/button'

const AddInquiryNotes = () => {
    const ref = useRef(null)

    const dispatch = useDispatch()

    const { selectedInquiry } = useSelector(inquiryState)

    // INITIAL STATE
    const initialState = {
        content: '',
        loadingButton: false,
    }

    useEffect(() => {
        if (selectedInquiry) {
            setState((prevState) => ({
                ...prevState,
                content: selectedInquiry?.note,
            }))
        } else {
            setState((prevState) => ({ ...prevState, content: '' }))
        }
    }, [selectedInquiry])

    const [state, setState] = useState(initialState)

    // UPDATE INQUIRY NOTE
    const updateInquiryNote = async () => {
        setState({ ...state, loadingButton: true })

        const result = await dispatch(
            inquiryUpdate({
                updatedData: {
                    note: state?.content,
                },
                _id: selectedInquiry?._id,
                workspaceId: selectedInquiry?.client?.workspaceId,
            })
        )

        const data = result?.payload?.data
        if (data) {
            const { success, message: checkMessage } = data
            if (success) {
                dispatch(
                    inquiryGet({
                        id: selectedInquiry?._id,
                        workspaceId: selectedInquiry?.client?.workspaceId,
                    })
                )
                setState({ ...state, loadingButton: false })
                message.success(checkMessage)
            } else {
                setState({ ...state, loadingButton: false })
                if (checkMessage) {
                    if (typeof checkMessage === 'object') {
                        for (const key in checkMessage) {
                            message.error(checkMessage[key])
                        }
                    } else {
                        message.error(checkMessage)
                    }
                } else {
                    message.error('Something went wrong, try again later.')
                }
            }
        }
    }

    // HANDLE BEFORE PASTE
    const handleBeforePaste = (event) => {
        const items = (event.clipboardData || event.originalEvent.clipboardData)
            .items
        let hasImage = false
        for (let i = 0; i < items.length; i++) {
            if (items[i].type.indexOf('image') === 0) {
                hasImage = true
                break
            }
        }
        if (hasImage) {
            message.error("you can't paste the images")
            return false
        }
        const data = (
            event.clipboardData || event.originalEvent.clipboardData
        ).getData('text/html')
        if (!data) return
        const doc = new DOMParser().parseFromString(data, 'text/html')
        const images = doc.querySelectorAll('img')
        if (!images.length) return

        message.error("you can't paste the images")
        return false
    }

    return (
        <div>
            <JoditEditor
                ref={ref}
                value={state?.content}
                tabIndex={1}
                config={{
                    defaultActionOnPaste: 'insert_only_text',
                    editHTMLDocumentMode: false,
                    iframe: false,
                    disablePlugins: ['paste'],
                    events: {
                        paste: handleBeforePaste,
                    },
                }}
                onBlur={(content) => {
                    setState({ ...state, content: content })
                }}
            />

            <Button
                props={{
                    text: 'Save Changes',
                    style: {
                        position: 'absolute',
                        right: 0,
                        marginTop: '20px',
                    },
                    loadingButton: state?.loadingButton,
                    disabled: state?.loadingButton,
                    onClick: () => {
                        updateInquiryNote()
                    },
                }}
            />
        </div>
    )
}

export default AddInquiryNotes
