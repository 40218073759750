import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

// antd
import {
    Avatar,
    Empty,
    Image,
    Pagination,
    Row,
    Spin,
    Table,
    Typography,
    theme,
    Dropdown,
} from 'antd'
import { EllipsisOutlined } from '@ant-design/icons'

// slices
import { roleListGet, roleState, changePage, roleGet } from './role.slice'
import { loginState } from '../login/login.slice'

// assets
import { EditIcon, ViewIcon } from '../../assets/icons'

// routes
import { PRIVATE_ROUTES } from '../../routes'

// constants
import { MEMBER, SUPER_ADMIN, WORKSPACE_ADMIN } from '../../constants/roles'

import dayjs from 'dayjs'

const RoleList = ({ openModal }) => {
    const { Text } = Typography

    const dispatch = useDispatch()

    const navigate = useNavigate()

    const { useToken } = theme

    const { token } = useToken()

    const { pageLimit, currentPage, roleCount, isNext, roleList, status } =
        useSelector(roleState)
    const { userProfile } = useSelector(loginState)

    const columns = [
        {
            title: 'Role Name',
            key: 'roleName',
            dataIndex: 'roleName',
            render: (val) => <Text>{val}</Text>,
        },
        {
            title: 'Created Date',
            key: 'createdAt',
            dataIndex: 'createdAt',
            render: (val) => (
                <Row
                    align={'middle'}
                    style={{
                        columnGap: '16px',
                    }}
                >
                    {dayjs(new Date(val)).format('DD/MM/YYYY')}
                </Row>
            ),
        },
        {
            title: 'Created By',
            key: 'createdBy',
            dataIndex: 'createdBy',
            render: (val, item) => (
                <Row
                    align={'middle'}
                    style={{
                        columnGap: '16px',
                    }}
                >
                    {item?.isCreatedBySuperAdmin ? (
                        <Text
                            style={{
                                textTransform: 'capitalize',
                            }}
                        >
                            {userProfile?.role?.roleName}
                        </Text>
                    ) : item?.profilePicture ? (
                        <Image
                            src={item?.profilePicture}
                            height={'30px'}
                            preview={false}
                            width={'30px'}
                            style={{
                                borderRadius: '50%',
                            }}
                        />
                    ) : (
                        item?.name?.length > 0 && (
                            <Avatar
                                style={{
                                    cursor: 'pointer',
                                    backgroundColor: 'rgb(0 10 129 / 25%)',
                                }}
                            >
                                {item?.name[0].toUpperCase()}
                                {item?.name.split(' ')[1] !== undefined &&
                                    item?.name.split(' ')[1] !== '' &&
                                    item?.name.split(' ')[1][0].toUpperCase()}
                            </Avatar>
                        )
                    )}
                    <Text>{val}</Text>
                </Row>
            ),
        },
        {
            key: 'context',
            dataIndex: 'context',
            title: '  ',
            render: (_, row) => (
                <Row justify="end" style={{ paddingRight: 5 }}>
                    <Dropdown
                        trigger={['click']}
                        placement={'bottomRight'}
                        menu={{
                            items: [
                                {
                                    key: '1',
                                    label: 'View',
                                    icon: <ViewIcon />,
                                    style: {
                                        columnGap: '5px',
                                    },
                                    onClick: () => {
                                        navigate(
                                            PRIVATE_ROUTES.roleSetting.permissionModule(
                                                row?._id
                                            )
                                        )
                                    },
                                },
                                row?.roleName !== MEMBER &&
                                    row?.roleName !== WORKSPACE_ADMIN && {
                                        key: '2',
                                        label: 'Edit',
                                        icon: (
                                            <div
                                                style={{
                                                    height: '20px',
                                                    width: '20px',
                                                }}
                                            >
                                                <EditIcon />
                                            </div>
                                        ),
                                        onClick: () => {
                                            openModal()
                                            dispatch(roleGet({ _id: row?._id }))
                                        },
                                    },
                            ],
                        }}
                    >
                        <EllipsisOutlined rotate={90} />
                    </Dropdown>
                </Row>
            ),
        },
    ]

    return (
        <>
            <Spin spinning={status === 'loading' ? true : false}>
                {roleList?.length > 0 ? (
                    <Table
                        dataSource={
                            roleList?.length > 0 &&
                            roleList
                                .filter(
                                    (data) => data?.roleName !== SUPER_ADMIN
                                )
                                .map((data) => data)
                        }
                        scroll={{
                            x: 'scroll',
                        }}
                        rootClassName="tableGlobal"
                        style={{
                            borderRadius: '16px',
                        }}
                        columns={columns}
                        pagination={false}
                    />
                ) : (
                    status !== 'loading' && (
                        <Empty
                            description={'No Roles Found'}
                            style={{ margin: 'auto' }}
                        />
                    )
                )}
                {roleList?.length > 0 && (
                    <Row
                        justify={'end'}
                        align={'middle'}
                        style={{
                            border: `2px solid ${token.colorPalette.baseColor.quaternary}`,
                            padding: '25px',
                            borderRadius: '0px 0px 8px 8px',
                        }}
                    >
                        <Text>
                            {roleList?.length} - {pageLimit} of {roleCount}
                        </Text>
                        <Pagination
                            defaultCurrent={currentPage}
                            defaultPageSize={pageLimit}
                            pageSizeOptions={[5, 10, 20, 50]}
                            showSizeChanger={true}
                            total={
                                isNext === false &&
                                // filterView &&
                                currentPage === 1
                                    ? roleList?.length
                                    : roleCount
                            }
                            onChange={async (value, pageSize) => {
                                // const dropDownValue = {
                                //     ...filter,
                                // }
                                // Object.keys(dropDownValue).forEach(
                                //     (key) => {
                                //         if (dropDownValue[key] === '') {
                                //             delete dropDownValue[key]
                                //         }
                                //     }
                                // )
                                await dispatch(
                                    changePage({
                                        currentPage: value,
                                        skip: (value - 1) * pageLimit,
                                        pageLimit: pageSize,
                                    })
                                )
                                await dispatch(
                                    roleListGet({
                                        skip: (value - 1) * pageSize,
                                        limit: pageSize,
                                    })
                                )
                            }}
                        />
                    </Row>
                )}
            </Spin>
        </>
    )
}

export default RoleList
