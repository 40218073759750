// assets
import MasterDataSettings from '../../assets/images/masterData/masterDataSetting.png'
import ProjectSetting from '../../assets/images/masterData/projectsSetting.png'
import ReportsSetting from '../../assets/images/masterData/reportsSetting.png'
import UserSetting from '../../assets/images/masterData/userSetting.png'

// routes
import { PRIVATE_ROUTES } from '../../routes'

// constants
import { DOMAIN_LIST } from '../roles'

export const SETTINGS_MENU = [
    {
        label: 'Master Data',
        icon: MasterDataSettings,
        navigate: PRIVATE_ROUTES.masterData.root('institute'),
        // workSpace: ['pms.softwareco.com', 'app.softwareco.com'],
        workSpace: DOMAIN_LIST,
    },
    {
        label: 'Users',
        icon: UserSetting,
        navigate: PRIVATE_ROUTES.roleSetting.root,
        workSpace: ['other'],
    },
    {
        label: 'Projects',
        icon: ProjectSetting,
        navigate: PRIVATE_ROUTES.projectSetting.root,
        workSpace: ['other'],
    },
    {
        label: 'Reports',
        icon: ReportsSetting,
        navigate: PRIVATE_ROUTES.masterData.root,
        workSpace: ['other'],
    },
]
