import React from 'react'

function ToolsIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            fill="none"
            viewBox="0 0 18 18"
        >
            <path
                fill="#4F46E5"
                d="M14 5H4v2h4v7h2V7h4V5zM1 0h16a1 1 0 011 1v16a1 1 0 01-1 1H1a1 1 0 01-1-1V1a1 1 0 011-1z"
            ></path>
        </svg>
    )
}

export default ToolsIcon
