import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
    addWorkSpaceData,
    assignWorkspace,
    changePermission,
    countWorkSpace,
    deleteWorkSpaceData,
    getWorkSpace,
    listWorkSpaceData,
    unAssignWorkspace,
    updateWorkSpaceData,
    verifySMTP,
} from './workSpace.service'

const initialState = {
    search: '',
    status: 'loaded',
    updatedDataStatus: 'loaded',
    formButtonType: 'saveButtonLoading',
    selectedData: undefined,
    modalVariations: {
        open: false,
        formName: 'workSpaceDataForm',
        saveButtonLoading: false,
    },
    permissionModalVariations: {
        open: false,
        formName: 'permissionDataForm',
        saveButtonLoading: false,
    },
    workSpaceDataList: [],
    dataCount: 0,
    isNext: false,
    pageLimit: 10,
    currentPage: 1,
    skipPage: 0,
    filter: {
        isActive: '',
    },
}

export const workSpaceDataListCount = createAsyncThunk(
    'workspaceSlice/countWorkSpace',
    async (payload = {}) => {
        const response = await countWorkSpace(payload)
        return response
    }
)

export const workSpaceListGet = createAsyncThunk(
    'workspaceSlice/listWorkSpaceData',
    async (payload = {}) => {
        const response = await listWorkSpaceData(payload)
        return response
    }
)

export const workSpaceItemGet = createAsyncThunk(
    'workspaceSlice/getWorkSpace',
    async (payload = {}) => {
        const response = await getWorkSpace(payload)
        return response
    }
)

export const workSpaceAdd = createAsyncThunk(
    'workspaceSlice/addWorkSpaceData',
    async (payload = {}) => {
        const response = await addWorkSpaceData(payload)
        return response
    }
)

export const workSpaceUpdate = createAsyncThunk(
    'workspaceSlice/updateWorkSpaceData',
    async (payload = {}) => {
        const response = await updateWorkSpaceData(payload)
        return response
    }
)

export const workSpaceDelete = createAsyncThunk(
    'workspaceSlice/deleteWorkSpaceData',
    async (payload = {}) => {
        const response = await deleteWorkSpaceData(payload)
        return response
    }
)

export const permissionChange = createAsyncThunk(
    'workspaceSlice/changePermission',
    async (payload = {}) => {
        const response = await changePermission(payload)
        return response
    }
)

export const SMTPverify = createAsyncThunk(
    'workspaceSlice/verifySMTP',
    async (payload = {}) => {
        const response = await verifySMTP(payload)
        return response
    }
)

export const workSpaceAssign = createAsyncThunk(
    'workspaceSlice/assignWorkspace',
    async (payload = {}) => {
        const response = await assignWorkspace(payload)
        return response
    }
)

export const workSpaceUnAssign = createAsyncThunk(
    'workspaceSlice/unAssignWorkspace',
    async (payload = {}) => {
        const response = await unAssignWorkspace(payload)
        return response
    }
)

export const workspaceSlice = createSlice({
    name: 'workspaceSlice',
    initialState,
    reducers: {
        switchWorkSpaceModal: (state, action) => {
            state.modalVariations = action.payload
            state.selectedData = undefined
        },
        switchPermissionModal: (state, action) => {
            state.permissionModalVariations = action.payload
            state.selectedData = undefined
        },
        checkButtonType: (state, action) => {
            state.formButtonType = action.payload
        },
        changePage: (state, action) => {
            state.currentPage = action.payload.currentPage
            state.skipPage = action.payload.skip
            state.pageLimit = action.payload.pageLimit
        },
        searchData: (state, action) => {
            state.search = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(workSpaceListGet.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(workSpaceListGet.fulfilled, (state, action) => {
                state.status = 'loaded'
                state.workSpaceDataList = action.payload.data?.result
                state.isNext = action.payload.data.isNext
            })
            .addCase(workSpaceDataListCount.fulfilled, (state, action) => {
                state.dataCount = action.payload.data
            })
            .addCase(workSpaceItemGet.pending, (state) => {
                state.updatedDataStatus = 'loading'
            })
            .addCase(workSpaceItemGet.fulfilled, (state, action) => {
                state.updatedDataStatus = 'loaded'
                state.selectedData = action?.payload.data?.data
            })
    },
})

export default workspaceSlice.reducer

export const workspaceState = (state) => {
    const { workspaceSlice } = state
    const {
        search,
        status,
        updatedDataStatus,
        formButtonType,
        selectedData,
        workSpaceDataList,
        dataCount,
        isNext,
        pageLimit,
        currentPage,
        skipPage,
        filter,
        modalVariations,
        permissionModalVariations,
    } = workspaceSlice
    return {
        search,
        status,
        updatedDataStatus,
        formButtonType,
        selectedData,
        workSpaceDataList,
        dataCount,
        isNext,
        pageLimit,
        modalVariations,
        currentPage,
        skipPage,
        filter,
        permissionModalVariations,
    }
}

export const {
    checkButtonType,
    changePage,
    switchWorkSpaceModal,
    switchPermissionModal,
    searchData,
} = workspaceSlice.actions
