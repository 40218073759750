import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import JoditEditor from 'jodit-react'

import dayjs from 'dayjs'

// antd
import {
    Row,
    Form,
    Col,
    Input,
    theme,
    Spin,
    Tooltip,
    Divider,
    message,
    Typography,
} from 'antd'

// components
import Button from '../../components/button/button'

// routes
import { PRIVATE_ROUTES } from '../../routes'

// slices
import {
    addProjectNotes,
    deleteProjectNotes,
    getProjectNote,
    notesState,
    updateProjectNotes,
} from './notes.slice'
import { loginState } from '../login/login.slice'

// assets
import { BackIcon, DeleteIcon } from '../../assets/icons'

// comment section
import CommentSection from './commentSection.index'

// helpers
import {
    addPermissionCheck,
    deletePermissionCheck,
    editFieldCheck,
    editPermissionCheck,
    viewFieldCheck,
} from '../../helpers/permissionCheck'
import { isEmpty } from '../../helpers/fieldCheck'

// components
import DeletePopUp from '../../components/deletePopUp/deletePopUp'

// constants
import { VIEW_NOTE } from '../../constants/notes'

const CreateNote = () => {
    const ref = useRef(null)

    const params = useParams()

    const location = useLocation()

    const { Title } = Typography

    const { useToken } = theme

    const { token } = useToken()

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [form] = Form.useForm()

    const initialValues = {
        title: '',
        description: '',
    }
    const { selectedNote, updatedDataStatus } = useSelector(notesState)
    const { userProfile } = useSelector(loginState)

    const initialState = {
        content: '',
        isWrite: false,
        submitLoader: false,
        values: initialValues,
        updatedValues: {},
        deleteModal: {
            modalOpen: false,
            id: '',
            loadingButton: false,
        },
    }

    const [state, setState] = useState(initialState)

    useEffect(() => {
        if (params?.id) {
            dispatch(getProjectNote({ _id: params?.id }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params?.id, dispatch])

    useEffect(() => {
        if (selectedNote) {
            form.setFieldsValue({
                title: selectedNote?.title,
            })
            setState((prevState) => ({
                ...prevState,
                content: selectedNote?.description,
            }))
        }
    }, [form, selectedNote])

    const relativeTime = require('dayjs/plugin/relativeTime')
    dayjs.extend(relativeTime)

    useEffect(() => {
        if (state?.content || !state?.isWrite) {
            const tables = document.querySelectorAll('table')
            if (tables.length > 0) {
                tables.forEach((table) => {
                    table.setAttribute('border', '1px')
                })
            }
        }
    }, [state?.isWrite, state?.content])

    const handleSaveContent = async (values) => {
        let result
        if (state?.content !== '<p><br></p>') {
            setState((prevState) => ({ ...prevState, submitLoader: true }))
            if (params?.id) {
                result = await dispatch(
                    updateProjectNotes({
                        id: selectedNote?._id,
                        editNote: {
                            ...values,
                            description: state?.content,
                        },
                    })
                )
            } else {
                result = await dispatch(
                    addProjectNotes({
                        ...values,
                        description: state?.content,
                        type: 'projects',
                        projectId: userProfile?.activeProject?._id,
                        userId: userProfile?._id,
                    })
                )
            }

            const data = result?.payload?.data
            if (data) {
                const { success, message: checkMessage } = data
                if (success) {
                    navigate(PRIVATE_ROUTES.notes.root)
                    setState((prevState) => ({
                        ...prevState,
                        submitLoader: false,
                    }))
                    message.success(checkMessage)
                } else {
                    if (typeof checkMessage === 'object') {
                        setState((prevState) => ({
                            ...prevState,
                            submitLoader: false,
                        }))
                        form.setFieldsValue({ title: '' })
                        for (const key in checkMessage) {
                            message.error(checkMessage[key])
                        }
                    } else if (typeof checkMessage === 'string') {
                        message.error(checkMessage)
                    } else {
                        setState((prevState) => ({
                            ...prevState,
                            submitLoader: false,
                        }))

                        message.error('Something went wrong, try again later.')
                    }
                }
            }
            setTimeout(() => {
                setState((prevState) => ({ ...prevState, isWrite: false }))
            }, 1500)
        } else {
            message.info('Please Enter Description')
        }
    }

    // DELETE PROJECT NOTES
    async function deleteProjectNote() {
        setState((prevState) => ({
            ...prevState,
            deleteModal: {
                ...prevState?.deleteModal,
                loadingButton: true,
            },
        }))
        const result = await dispatch(
            deleteProjectNotes(state?.deleteModal?.id)
        )
        const data = result?.payload?.data
        if (data) {
            const { success, message: checkMessage } = data
            if (success) {
                setState((prevState) => ({
                    ...prevState,
                    deleteModal: {
                        id: '',
                        loadingButton: false,
                        modalOpen: false,
                    },
                }))
                message.success(checkMessage)
                navigate(PRIVATE_ROUTES.notes.root)
            } else {
                setState((prevState) => ({
                    ...prevState,
                    deleteModal: {
                        id: '',
                        loadingButton: false,
                        modalOpen: false,
                    },
                }))
                if (checkMessage) {
                    message.error(checkMessage)
                } else {
                    message.error('Something went wrong, try again later.')
                }
            }
        }
    }

    function updateDetails(name, value) {
        setState((prevState) => ({
            ...prevState,
            values: {
                ...prevState?.values,
                [name]: value,
            },
        }))

        if (state?.values[name] !== value) {
            setState((prevState) => ({
                ...prevState,
                updatedValues: {
                    ...prevState?.updatedValues,
                    [name]: value,
                },
            }))
        }
    }

    const onFinishFailed = () => {}

    // HANDLE BEFORE PASTE
    const handleBeforePaste = (event) => {
        const items = (event.clipboardData || event.originalEvent.clipboardData)
            .items
        let hasImage = false
        for (let i = 0; i < items.length; i++) {
            if (items[i].type.indexOf('image') === 0) {
                hasImage = true
                break
            }
        }
        if (hasImage) {
            message.error("you can't paste the images")
            return false
        }
        const data = (
            event.clipboardData || event.originalEvent.clipboardData
        ).getData('text/html')
        if (!data) return
        const doc = new DOMParser().parseFromString(data, 'text/html')
        const images = doc.querySelectorAll('img')
        if (!images.length) return

        message.error("you can't paste the images")
        return false
    }

    // HANDLE KEY DOWN
    const handleKeyDown = (e) => {
        const { key } = e
        // Check if the pressed key is the space key and the cursor is at the beginning of the content
        if (key === ' ' && e.target.textContent === '') {
            e.preventDefault()
        }
    }

    return (
        <>
            <div
                className={'container'}
                style={{
                    zIndex: 10,
                    minHeight: '100vh',
                    padding: '0px',
                    // padding: '35px',
                }}
            >
                <Spin spinning={updatedDataStatus === 'loading' ? true : false}>
                    <Row
                        style={{
                            height: 'inherit',
                        }}
                    >
                        <Col span={selectedNote ? 14 : 24}>
                            <Form
                                layout="vertical"
                                name="addNoteForm"
                                onFinish={handleSaveContent}
                                onFinishFailed={onFinishFailed}
                                requiredMark={false}
                                style={{
                                    padding: '35px',
                                }}
                                initialValues={
                                    params?.id?.length > 0
                                        ? {
                                              title: selectedNote?.title,
                                              description:
                                                  selectedNote?.description,
                                          }
                                        : {
                                              title: '',
                                              description: '',
                                          }
                                }
                                form={form}
                            >
                                <Row gutter={[0, 18]} align={'middle'}>
                                    {(isEmpty(selectedNote) ||
                                        viewFieldCheck(
                                            'notes',
                                            'title',
                                            userProfile
                                        )) && (
                                        <>
                                            <Col span={selectedNote ? 18 : 23}>
                                                <Form.Item
                                                    name="title"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message:
                                                                'Note Title is required',
                                                        },
                                                        {
                                                            validator: (
                                                                _,
                                                                value,
                                                                callback
                                                            ) => {
                                                                if (
                                                                    value &&
                                                                    value.trimStart() !==
                                                                        value
                                                                ) {
                                                                    callback(
                                                                        'Note Title must not contain whitespace at the beginning!'
                                                                    )
                                                                } else {
                                                                    callback()
                                                                }
                                                            },
                                                        },
                                                        {
                                                            max: 100,
                                                            message:
                                                                'Notes title should be maximum 100 characters long',
                                                        },
                                                    ]}
                                                    colon={false}
                                                >
                                                    <Input
                                                        placeholder={
                                                            'Enter Note Title'
                                                        }
                                                        name={'title'}
                                                        bordered={false}
                                                        style={{
                                                            border: 'none',
                                                            paddingLeft: '0px',
                                                            fontWeight: 500,
                                                            fontSize: '18px',
                                                        }}
                                                        readOnly={
                                                            location?.pathname.includes(
                                                                VIEW_NOTE
                                                            ) ||
                                                            editFieldCheck(
                                                                'notes',
                                                                'title',
                                                                userProfile
                                                            ) === false
                                                        }
                                                    />
                                                </Form.Item>
                                            </Col>
                                            {!selectedNote && (
                                                <Col span={1}>
                                                    <div
                                                        style={{
                                                            height: '30px',
                                                            width: '30px',
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={() =>
                                                            navigate(
                                                                PRIVATE_ROUTES
                                                                    .notes.root
                                                            )
                                                        }
                                                    >
                                                        <BackIcon />
                                                    </div>
                                                </Col>
                                            )}
                                        </>
                                    )}
                                    {selectedNote &&
                                        !location?.pathname.includes(
                                            VIEW_NOTE
                                        ) && (
                                            <Col span={6}>
                                                <Row
                                                    justify={'end'}
                                                    align={'middle'}
                                                    style={{
                                                        columnGap: '17px',
                                                    }}
                                                >
                                                    {deletePermissionCheck(
                                                        'notes',
                                                        userProfile
                                                    ) && (
                                                        <Tooltip title="Delete Note">
                                                            <div
                                                                style={{
                                                                    cursor: 'pointer',
                                                                }}
                                                                onClick={() => {
                                                                    setState(
                                                                        (
                                                                            prevState
                                                                        ) => ({
                                                                            ...prevState,
                                                                            deleteModal:
                                                                                {
                                                                                    ...prevState?.deleteModal,
                                                                                    modalOpen: true,
                                                                                    id: selectedNote?._id,
                                                                                },
                                                                        })
                                                                    )
                                                                }}
                                                            >
                                                                <DeleteIcon />
                                                            </div>
                                                        </Tooltip>
                                                    )}
                                                </Row>
                                            </Col>
                                        )}
                                    {(isEmpty(selectedNote) ||
                                        viewFieldCheck(
                                            'notes',
                                            'description',
                                            userProfile
                                        )) && (
                                        <Col span={24}>
                                            <Title level={5}>Description</Title>
                                            <br />
                                            <JoditEditor
                                                ref={ref}
                                                value={state?.content}
                                                tabIndex={1}
                                                config={{
                                                    defaultActionOnPaste:
                                                        'insert_only_text',
                                                    editHTMLDocumentMode: false,
                                                    iframe: false,
                                                    readonly:
                                                        location?.pathname.includes(
                                                            VIEW_NOTE
                                                        ) ||
                                                        editFieldCheck(
                                                            'notes',
                                                            'description',
                                                            userProfile
                                                        ) === false,
                                                    disablePlugins: ['paste'],
                                                    events: {
                                                        paste: handleBeforePaste,
                                                        keydown: handleKeyDown,
                                                        // keypress: handleKeyDown,
                                                    },
                                                }}
                                                onBlur={(content) => {
                                                    const trimmedContent =
                                                        content.replace(
                                                            /^\s+/,
                                                            ''
                                                        )

                                                    setState((prevState) => ({
                                                        ...prevState,
                                                        content: trimmedContent,
                                                    }))
                                                    updateDetails(
                                                        'description',
                                                        content
                                                    )
                                                }}
                                            />
                                        </Col>
                                    )}
                                </Row>
                                {!location?.pathname.includes(VIEW_NOTE) && (
                                    <Row
                                        justify={'end'}
                                        style={{
                                            columnGap: 10,
                                            marginTop: 30,
                                        }}
                                    >
                                        <Button
                                            props={{
                                                text: 'Cancel',
                                                type: 'link',
                                                onClick: () => {
                                                    navigate(
                                                        PRIVATE_ROUTES.notes
                                                            .root
                                                    )
                                                    setState((prevState) => ({
                                                        ...prevState,
                                                        isWrite: false,
                                                    }))
                                                },
                                                style: {
                                                    color: token.colorPalette
                                                        .baseColor.black,
                                                },
                                            }}
                                        />
                                        {editPermissionCheck(
                                            'notes',
                                            userProfile
                                        ) && (
                                            <Button
                                                props={{
                                                    htmlType: 'submit',
                                                    loadingButton:
                                                        state?.submitLoader,
                                                    disabled:
                                                        location?.pathname.includes(
                                                            VIEW_NOTE
                                                        ),
                                                    text: params?.id
                                                        ? 'Update Changes'
                                                        : 'Save Changes',
                                                }}
                                            />
                                        )}
                                    </Row>
                                )}
                            </Form>
                        </Col>
                        {selectedNote &&
                            addPermissionCheck('notes', userProfile) && (
                                <>
                                    <Divider
                                        type="vertical"
                                        style={{
                                            height: '100vh',
                                        }}
                                    />
                                    <Col span={9}>
                                        <CommentSection />
                                    </Col>
                                </>
                            )}
                    </Row>
                </Spin>
            </div>
            <DeletePopUp
                previewDelete={state?.deleteModal?.modalOpen}
                onDelete={() => {
                    deleteProjectNote()
                }}
                loadingButton={state?.deleteModal?.loadingButton}
                onCancel={() => {
                    setState((prevState) => ({
                        ...prevState,
                        deleteModal: {
                            id: '',
                            loadingButton: false,
                            modalOpen: false,
                        },
                    }))
                }}
                pageKey={'Note'}
                modalTitle={`Delete Note`}
            />
        </>
    )
}

export default CreateNote
