import axiosNew from '../../helpers/axios'

// COUNT MASTER DATA
export const countMasterData = async (payload) => {
    const { search = '', filter = {}, _id } = payload

    return new Promise(async (resolve) => {
        try {
            let data = { filter }
            if (payload?.length > 0) {
                data['search'] = {
                    name: search,
                    email: search,
                    phone: search,
                }
            }
            const count = await axiosNew({
                url: 'master-data/count/' + _id,
                data,
            })
            resolve({ data: count?.data?.result ?? 0 })
        } catch (error) {
            resolve({ data: 0 })
        }
    })
}

// LIST MASTER DATA
export const listMasterData = async (payload) => {
    const { search = '', skip = 0, limit = 0, filter = {}, id } = payload
    return new Promise(async (resolve) => {
        try {
            let data = {
                skip,
                filter,
                limit,
                select: [
                    'name',
                    'isActive',
                    'logo',
                    'createdBy',
                    'isActive',
                    'createdAt',
                    'updatedAt',
                ],
                populate: {
                    name: 'createdBy',
                    val: ['name', 'profilePicture'],
                },
            }
            if (search?.length > 0) {
                data['search'] = {
                    name: search,
                }
            }
            const listData = await axiosNew({
                url: 'master-data/list/' + id,
                data,
            })
            resolve({ data: listData?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// GET MASTER DATA
export const getMasterData = async (payload) => {
    return new Promise(async (resolve) => {
        try {
            const getData = await axiosNew({
                url: `master-data/list/${payload?.model}/${payload?._id}`,
            })
            resolve({ data: getData?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// ADD MASTER DATA
export const addMasterData = async (payload) => {
    return new Promise(async (resolve) => {
        try {
            let data = payload?.addData ?? {}
            const addData = await axiosNew({
                url: 'master-data/add/' + payload.model,
                data,
            })
            resolve({ data: addData?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// UPDATE MASTER DATA
export const updateMasterData = async (payload) => {
    return new Promise(async (resolve) => {
        try {
            let data = payload?.editData ?? {}
            const updateData = await axiosNew({
                url: 'master-data/update/' + payload.model + `/${payload?._id}`,
                data,
            })
            resolve({ data: updateData?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// DELETE MASTER DATA
export const deleteMasterData = async (payload) => {
    return new Promise(async (resolve) => {
        try {
            let data = payload?.deletedData ?? {}
            const deleteData = await axiosNew({
                url: 'master-data/delete/' + payload.model,
                data,
            })
            resolve({ data: deleteData?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// BULK DATA UPDATE MASTER DATA
export const bulkDataUpdate = async (payload) => {
    return new Promise(async (resolve) => {
        try {
            let data = payload?.editData ?? {}
            const deleteData = await axiosNew({
                url: 'master-data/bulk-update/' + payload.model,
                data,
            })
            resolve({ data: deleteData?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}
