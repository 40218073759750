import React from 'react'

function CompanyDefaultIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 20 15"
        >
            <path
                fill="#CFD7DB"
                fillRule="evenodd"
                d="M5.403 0v14.981h3.53v-3.346h2.123v3.346h3.53V0H5.404zm5.236 3.732h2.122V1.765H10.64v1.967zm-3.411 0H9.35V1.765H7.228v1.967zm3.41 3.162h2.123V4.927H10.64v1.967zm-3.41 0H9.35V4.927H7.228v1.967zm3.41 3.16h2.123V8.089H10.64v1.967zm-3.41 0H9.35V8.089H7.228v1.967zM15.411 1.342v10.593h1.364v3.046h3.214V1.34h-4.578zm.984 3.398h1.932V2.95h-1.933v1.79zm0 2.878h1.932v-1.79h-1.933v1.79zm0 2.878h1.932v-1.79h-1.933v1.79zM0 1.342V14.98h3.215v-3.046h1.363V1.341H0zM1.662 4.74h1.932V2.95H1.662v1.79zm0 2.878h1.932V5.827H1.662v1.79zm0 2.878h1.932v-1.79H1.662v1.79z"
                clipRule="evenodd"
            ></path>
        </svg>
    )
}

export default CompanyDefaultIcon
