import React from 'react'

function NoWorkspace() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 720 400"
        >
            <g clipPath="url(#clip0_1_2)">
                <path
                    fill="#2F2E43"
                    d="M0 398.674c0 .712.81 1.281 1.826 1.281H717.69c1.007 0 1.825-.569 1.825-1.281s-.81-1.28-1.825-1.28H1.826c-1.008 0-1.826.568-1.826 1.28z"
                ></path>
                <path
                    fill="#D7D7D8"
                    d="M564.007 0H113.042c-5.992 0-10.862 4.87-10.862 10.863v302.176c0 5.991 4.87 10.862 10.862 10.862h450.965c5.992 0 10.863-4.871 10.863-10.862V10.863C574.87 4.87 569.999 0 564.007 0z"
                ></path>
                <path
                    fill="#fff"
                    d="M565.984 10.204H114.36v255.438h451.624V10.204z"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M574.87 276.83v36.209c0 5.991-4.871 10.862-10.863 10.862H113.042c-5.992 0-10.862-4.871-10.862-10.862V276.83h472.69z"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M396.137 316.993H284.216v81.636h111.921v-81.636z"
                ></path>
                <path
                    fill="#D7D7D8"
                    d="M396.455 388.418H283.882v3.295h112.573v-3.295zM498.726 34.254v5.735H178.991v-5.735a5.567 5.567 0 015.567-5.567h308.6a5.568 5.568 0 015.568 5.567z"
                ></path>
                <path
                    fill="#fff"
                    d="M185.801 35.906a1.636 1.636 0 10-.001-3.273 1.636 1.636 0 00.001 3.273zM191.247 35.906a1.636 1.636 0 100-3.272 1.636 1.636 0 000 3.272zM196.686 35.906a1.636 1.636 0 100-3.272 1.636 1.636 0 000 3.272z"
                ></path>
                <path
                    fill="#D7D7D8"
                    d="M463.471 45.973H210.707a4.22 4.22 0 000 8.438h252.764a4.22 4.22 0 100-8.438zM290.495 146.016h-56.267a6.168 6.168 0 01-6.159-6.158V91.756a6.168 6.168 0 016.159-6.158h56.267a6.168 6.168 0 016.159 6.159v48.101a6.168 6.168 0 01-6.159 6.158zm-56.267-58.532a4.27 4.27 0 00-4.265 4.265v48.101a4.27 4.27 0 004.265 4.265h56.267a4.269 4.269 0 004.265-4.265V91.749a4.27 4.27 0 00-4.265-4.265h-56.267zM366.996 146.016h-56.268a6.168 6.168 0 01-6.158-6.158V91.756a6.168 6.168 0 016.158-6.158h56.268a6.168 6.168 0 016.158 6.159v48.101a6.168 6.168 0 01-6.158 6.158zm-56.268-58.532a4.27 4.27 0 00-4.265 4.265v48.101a4.27 4.27 0 004.265 4.265h56.268a4.269 4.269 0 004.264-4.265V91.749a4.269 4.269 0 00-4.264-4.265h-56.268zM443.488 146.016h-56.267a6.168 6.168 0 01-6.159-6.158V91.756a6.168 6.168 0 016.159-6.158h56.267a6.168 6.168 0 016.159 6.159v48.101a6.168 6.168 0 01-6.159 6.158zm-56.267-58.532a4.27 4.27 0 00-4.265 4.265v48.101a4.27 4.27 0 004.265 4.265h56.267a4.269 4.269 0 004.265-4.265V91.749a4.269 4.269 0 00-4.265-4.265h-56.267zM290.495 221.221h-56.267a6.168 6.168 0 01-6.159-6.158v-48.102a6.168 6.168 0 016.159-6.158h56.267a6.168 6.168 0 016.159 6.158v48.102a6.168 6.168 0 01-6.159 6.158zm-56.267-58.532a4.27 4.27 0 00-4.265 4.265v48.101a4.27 4.27 0 004.265 4.265h56.267a4.269 4.269 0 004.265-4.265v-48.101a4.27 4.27 0 00-4.265-4.265h-56.267zM366.996 221.221h-56.268a6.168 6.168 0 01-6.158-6.158v-48.102a6.168 6.168 0 016.158-6.158h56.268a6.168 6.168 0 016.158 6.158v48.102a6.168 6.168 0 01-6.158 6.158zm-56.268-58.532a4.27 4.27 0 00-4.265 4.265v48.101a4.27 4.27 0 004.265 4.265h56.268a4.269 4.269 0 004.264-4.265v-48.101a4.269 4.269 0 00-4.264-4.265h-56.268z"
                ></path>
                <path
                    fill="#6C63FF"
                    d="M443.488 221.221h-56.267a6.168 6.168 0 01-6.159-6.158v-48.102a6.168 6.168 0 016.159-6.158h56.267a6.168 6.168 0 016.159 6.158v48.102a6.168 6.168 0 01-6.159 6.158z"
                ></path>
                <path
                    fill="#2F2E43"
                    d="M595.807 114.126l9.295-7.235s10.605 3.303 10.878 3.439c.272.137 16.46 37.679 16.46 37.679s-41.466 21.225-38.436 19.149c3.03-2.06 2.402-30.035-.416-33.678-2.826-3.651-.417-12.817-.417-12.817l2.613-6.545h.016l.007.008z"
                ></path>
                <path
                    fill="#F3A3A6"
                    d="M621.502 131.041h-12.598v19.998l13.893-4.568-1.295-15.43zM580.324 260.006a11.395 11.395 0 00-.379-5.5l4.394-18.884-9.371-1.962-3.439 19.369a11.119 11.119 0 00-2.121 5.06c-.757 4.583 1.083 8.734 4.098 9.257 3.015.53 6.068-2.757 6.825-7.34h-.007zM617.57 370.359h-13.014v18.46h13.014v-18.46z"
                ></path>
                <path
                    fill="#2F2E43"
                    d="M591.891 400c5.712 0 14.052-.598 14.112-.606 2.235.197 13.37 1.076 13.923-1.47.508-2.34-.242-4.787-.348-5.128-1.076-10.643-1.47-10.772-1.712-10.84-.379-.113-1.485.417-3.28 1.576l-.114.076-.023.128c-.03.159-.825 4.068-4.598 3.477-2.583-.409-3.424-.984-3.689-1.265.22-.098.493-.272.682-.568.28-.432.326-.992.144-1.666-.485-1.765-1.939-4.379-2-4.492l-.166-.296-14.787 9.977-9.135 2.613a2.328 2.328 0 00-1.523 1.348 2.346 2.346 0 00.598 2.659c1.659 1.492 4.962 4.045 8.416 4.363.917.084 2.136.122 3.508.122l-.008-.008z"
                ></path>
                <path
                    fill="#F3A3A6"
                    d="M654.625 355.862l-11.043 6.886 9.769 15.664 11.043-6.887-9.769-15.663z"
                ></path>
                <path
                    fill="#2F2E43"
                    d="M639.955 397.477c1.818 0 3.825-.257 5.514-1.113 4.265-2.144 14.635-9.681 14.696-9.734 1.992-1.007 11.908-6.158 11.029-8.613-.811-2.257-2.742-3.939-3.007-4.166-6.545-8.469-6.939-8.363-7.181-8.295-.387.106-1.038 1.137-1.955 3.068l-.053.121.053.122c.061.159 1.455 3.893-2.06 5.378-2.409 1.022-3.424.985-3.795.879.136-.205.272-.493.28-.849.015-.515-.242-1.022-.758-1.492-1.348-1.242-3.961-2.689-4.075-2.75l-.295-.159-7.272 16.294-6.371 7.045a2.33 2.33 0 00-.576 1.954 2.358 2.358 0 001.917 1.939c1 .175 2.401.364 3.931.364l-.022.007zM627.153 215.972s4.219 0 10.832 13.226c6.613 13.234-1.803 66.335-1.803 66.335l23.559 60.964-16.529 11.574-30.172-65.297 7.439 72.327h-18.597L587.414 249.87l3.303-33.89h36.436v-.008z"
                ></path>
                <path
                    fill="#6C63FF"
                    d="M629.986 143.229h-25.21l-22.733 74.811h63.229l-15.286-74.811z"
                ></path>
                <path
                    fill="#6C63FF"
                    d="M608.761 143.236l-25.627 106.218h-12.817s19.423-98.362 21.082-102.498c1.651-4.136 17.354-3.72 17.354-3.72h.008z"
                ></path>
                <path
                    fill="#F3A3A6"
                    d="M610.624 136.57c7.648 0 13.847-6.199 13.847-13.847 0-7.647-6.199-13.847-13.847-13.847s-13.847 6.2-13.847 13.847c0 7.648 6.199 13.847 13.847 13.847z"
                ></path>
                <path
                    fill="#2F2E43"
                    d="M593.376 120.201l3.393.575c3.167-12.68 10.34-8.537 11.022-8.681.682-.143 10.878 11.015 10.052 22.317-.826 11.294 13.779 16.589 13.779 16.589s5.106 9.59.553 26.944c-4.553 17.355 5.098 23.18 5.098 23.18 19.013-1.614 7.583-28.141 4.553-32.959-3.03-4.825-.962-13.226-.962-13.226 4.689-4.136-.826-13.226-.826-13.226s.97-12.128-1.924-14.468c-2.894-2.341-3.03-8.128-3.03-8.128-5.106-22.18-21.771-17.908-21.771-17.908-21.354-2.34-19.93 18.983-19.93 18.983l-.007.008z"
                ></path>
                <path
                    fill="#F3A3A6"
                    d="M656.067 257.786a11.417 11.417 0 00-2.311-5.007l-2.606-19.218-9.461 1.5 3.659 19.332a11.387 11.387 0 00-.182 5.484c.917 4.553 4.106 7.772 7.113 7.196 3.007-.575 4.704-4.734 3.78-9.287h.008z"
                ></path>
                <path
                    fill="#6C63FF"
                    d="M642.719 248.204h12.817s-19.422-98.362-21.081-102.498c-1.651-4.136-15.483-2.379-15.483-2.379l23.747 104.877z"
                ></path>
            </g>
            <defs>
                <clipPath id="clip0_1_2">
                    <path fill="#fff" d="M0 0H719.515V400H0z"></path>
                </clipPath>
            </defs>
        </svg>
    )
}

export default NoWorkspace
