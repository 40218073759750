import React from 'react'

function ExpandIcon(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="10"
            fill="none"
            viewBox="0 0 20 10"
            transform={props.isActive ? 'rotate(180)' : 'rotate(0)'}
        >
            <path
                fill="#252525"
                fillRule="evenodd"
                d="M20 8.963a.99.99 0 01-.341.735 1.259 1.259 0 01-1.637 0L9.998 2.506 1.976 9.698a1.262 1.262 0 01-1.639 0 .968.968 0 010-1.47L9.182.305a1.252 1.252 0 011.636 0l8.84 7.925a.985.985 0 01.342.734z"
                clipRule="evenodd"
            ></path>
        </svg>
    )
}

export default ExpandIcon
