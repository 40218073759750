import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import { Divider, Spin } from 'antd'

import RightArrow from '../../../assets/icons/rightArrow'
import BackAndEdit from '../../../components/backAndEdit'
import {
    getJobDetails,
    jobState,
    switchJobModal,
} from '../../jobs/store/job.slice'
import AddEditJob from '../../jobs/components/AddEditJob'
import JobApplications from './JobApplications'
import {
    getJobApplicantListByStatus,
    getStatusWiseCount,
    jobApplicantState,
} from '../store/jobApplicant.slice'
import { JOB_APPLICATIONS_STATUS } from '../../../constants/jobs'
import JobInfoDrawer from './JobInfoDrawer'

const tabStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '140px',
    cursor: 'pointer',
    padding: '7px 13px',
}

const tabSelectionStyle = {
    background: '#EAE4FF',
    borderRadius: '5px',
    color: 'black',
}

export const initialState = {
    jobStatus: 'matches',
    jobIndex: 0,
    selectedApplicantData: null,
    selectedApplicantIndex: null,
    jobStatusList: [],
    jobInfoDrawer: false,
    jobApplicantResumeDrawer: false,
    jobApplicantNotesDrawer: false,
}

const JobDetailsBanner = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const params = useParams()

    const [state, setState] = useState(initialState)

    const { modalVariations, jobDetails, jobLoading } = useSelector(jobState)
    const jobApplicantStateData = useSelector(jobApplicantState)

    const handleBack = () => {
        dispatch(
            switchJobModal({
                ...modalVariations,
                open: false,
                isEdit: false,
                selectedJobID: null,
            })
        )
        navigate(-1)
    }

    const handleEditJobDetails = () => {
        if (params.id) {
            dispatch(
                switchJobModal({
                    ...modalVariations,
                    open: true,
                    isEdit: true,
                    selectedJobID: params.id,
                })
            )
        }
    }

    const handleSelectApplicationStatus = (status, index) => {
        setState((prevState) => ({
            ...prevState,
            jobStatus: status.key,
            jobIndex: index,
            selectedApplicantData: null,
            selectedApplicantIndex: null,
        }))

        dispatch(
            getJobApplicantListByStatus({
                filter: { status: status.key, job: params.id },
            })
        )
    }

    const handleViewJobInfo = () => {
        setState((prevState) => ({
            ...prevState,
            jobInfoDrawer: true,
        }))
    }

    useEffect(() => {
        if (params.id) {
            dispatch(getJobDetails(params.id))
            dispatch(getStatusWiseCount({ job: params.id }))
            dispatch(
                getJobApplicantListByStatus({
                    filter: { status: 'matches', job: params.id },
                })
            )
        }
    }, [params.id])

    useEffect(() => {
        let updatedList = []
        let jobApplicantionsStatus = JOB_APPLICATIONS_STATUS

        if (jobApplicantStateData?.statusList?.length > 0) {
            jobApplicantionsStatus.forEach((status) => {
                const foundItem = jobApplicantStateData?.statusList.find(
                    (item) => status?.value === item?._id
                )
                const value = foundItem ? foundItem.count : 0
                updatedList.push({
                    name: status?.label,
                    value,
                    key: status?.value,
                })
            })
        }

        if (jobApplicantStateData?.statusList?.length === 0) {
            jobApplicantionsStatus.map((status) => {
                updatedList.push({
                    name: status?.label,
                    value: 0,
                    key: status?.value,
                })
            })
        }

        setState((prevState) => ({
            ...prevState,
            jobStatusList: updatedList,
        }))
    }, [jobApplicantStateData])

    useEffect(() => {
        if (jobApplicantStateData?.loaders?.applicantLoading) {
            setState((prevState) => ({
                ...prevState,
                selectedApplicantData: null,
                selectedApplicantIndex: null,
            }))
        }
    }, [jobApplicantStateData?.loaders?.applicantLoading])

    return (
        <>
            {jobLoading ? (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Spin />
                </div>
            ) : (
                <>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '10px',
                            }}
                        >
                            <h3>{jobDetails?.title || ''}</h3>
                            <h3>({jobDetails?.noOfPositions || 0})</h3>
                            <span
                                style={{
                                    color: '#4F46E5',
                                    fontSize: '20px',
                                    textTransform: 'capitalize',
                                }}
                            >
                                {jobDetails?.status || ''}
                            </span>
                            <span
                                style={{
                                    background: jobDetails?.isActiveJob
                                        ? '#36B37E29'
                                        : '#FF563029',
                                    color: jobDetails?.isActiveJob
                                        ? '#1B806A'
                                        : '#B71D18',
                                    fontWeight: 700,
                                    fontSize: '18px',
                                    padding: '3px 12px',
                                    borderRadius: '9px',
                                }}
                            >
                                {jobDetails?.isActiveJob
                                    ? 'Active'
                                    : 'Inactive'}
                            </span>
                            <span
                                style={{
                                    border: '1px solid #C9C9C9',
                                    borderRadius: '5px',
                                    fontWeight: 600,
                                    fontSize: '18px',
                                    padding: '3px 12px',
                                    cursor: 'pointer',
                                }}
                                onClick={handleViewJobInfo}
                            >
                                Job Info <RightArrow />
                            </span>
                        </div>

                        <BackAndEdit
                            onBack={handleBack}
                            onEdit={handleEditJobDetails}
                        />
                    </div>

                    <div>
                        <span
                            style={{
                                fontWeight: 400,
                                color: '#919EAB',
                                fontSize: '20px',
                            }}
                        >
                            {jobDetails?.location || ''}
                        </span>
                    </div>

                    <div
                        style={{
                            display: 'flex',
                            gap: '10px',
                            alignItems: 'center',
                            marginTop: '20px',
                            flexWrap: 'wrap',
                        }}
                    >
                        {state.jobStatusList?.map((status, index) => (
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <div
                                    onClick={() =>
                                        handleSelectApplicationStatus(
                                            status,
                                            index
                                        )
                                    }
                                    key={index}
                                    style={{
                                        ...tabStyle,
                                        ...(state.jobIndex === index &&
                                            tabSelectionStyle),
                                    }}
                                >
                                    <span
                                        style={{
                                            fontSize: '18px',
                                            fontWeight: 700,
                                        }}
                                    >
                                        {status.value}
                                    </span>
                                    <span
                                        style={{
                                            fontWeight: 500,
                                            color: '#919EAB',
                                        }}
                                    >
                                        {status.name}
                                    </span>
                                </div>

                                {state?.jobStatusList?.length - 1 ===
                                index ? null : (
                                    <Divider
                                        type="vertical"
                                        style={{
                                            height: '65px',
                                            marginRight: 0,
                                        }}
                                    />
                                )}
                            </div>
                        ))}
                    </div>
                </>
            )}

            <Divider />

            <div>
                <JobApplications state={state} setState={setState} />
            </div>

            <AddEditJob from="applicant" />

            <JobInfoDrawer state={state} setState={setState} />
        </>
    )
}

export default JobDetailsBanner
