import React from 'react'

function SuccessfullyPasswordIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="269"
            height="227"
            fill="none"
            viewBox="0 0 269 227"
        >
            <g clipPath="url(#clip0_1655_11472)">
                <path
                    fill="#F0F0F0"
                    d="M240.733 212.221l-4.515 4.158 3.588-6.022c-2.827-5.127-7.451-9.558-7.451-9.558s-9.593 9.191-9.593 16.416 4.295 9.747 9.593 9.747 9.593-2.522 9.593-9.747a12.66 12.66 0 00-1.215-4.994z"
                ></path>
                <path
                    fill="#fff"
                    d="M235.056 216.602v.356c-.021 4.139-.719 7.369-2.089 9.644-.019.033-.04.065-.06.098l-.153-.093-.146-.092c1.519-2.456 2.057-5.927 2.076-9.538.002-.116.004-.234.002-.352a48.244 48.244 0 00-.284-4.93 70.415 70.415 0 00-.881-5.867 70.012 70.012 0 00-1.196-5.243l-.024-.092.167-.047.002-.002.169-.048c.002.008.032.108.081.295a73.396 73.396 0 011.226 5.439c.25 1.334.495 2.826.689 4.391.049.393.093.781.132 1.164a48.199 48.199 0 01.289 4.917z"
                ></path>
                <path
                    fill="#fff"
                    d="M233.881 205.786c-.118.015-.238.031-.36.044-.33.034-.662.051-.994.051a9.46 9.46 0 01-4.09-.924l-.224.282a9.8 9.8 0 004.314.994 9.896 9.896 0 001.419-.102 9.752 9.752 0 002.812-.851l-.222-.286a9.444 9.444 0 01-2.655.792zM234.767 211.687c-.121.007-.243.01-.364.01-.037.002-.076.002-.113.002a9.51 9.51 0 01-7.807-4.078c-.07.104-.141.208-.21.314a9.87 9.87 0 008.017 4.116l.148-.001a9.812 9.812 0 005.594-1.844l-.171-.312a9.455 9.455 0 01-5.094 1.793z"
                ></path>
                <path
                    fill="#fff"
                    d="M235.056 216.603a10.469 10.469 0 01-.767.032 9.534 9.534 0 01-9.142-6.862c-.08.143-.159.284-.235.427a9.89 9.89 0 009.377 6.788c.133 0 .265-.002.395-.009a9.86 9.86 0 006.878-3.195c-.037-.13-.08-.259-.122-.389a9.501 9.501 0 01-6.384 3.208z"
                ></path>
                <path
                    fill="#F0F0F0"
                    d="M202.974 56.048l.19-3.604.966 4.007c3.297.993 7.063.955 7.063.955s.255-7.809-2.716-10.846c-2.97-3.038-5.813-2.333-8.041-.154-2.227 2.178-2.996 5.005-.026 8.042a7.458 7.458 0 002.564 1.6z"
                ></path>
                <path
                    fill="#fff"
                    d="M203.559 51.872l-.147-.15c-1.693-1.749-2.727-3.394-3.086-4.913l-.015-.066.102-.024.1-.022c.37 1.658 1.572 3.338 3.048 4.864l.144.15c.631.64 1.306 1.251 1.986 1.821a41.51 41.51 0 002.943 2.238 41.498 41.498 0 002.658 1.713l.048.029-.051.088v.002l-.051.09a43.5 43.5 0 01-2.907-1.873 42.152 42.152 0 01-2.79-2.132 28.407 28.407 0 01-1.982-1.815z"
                ></path>
                <path
                    fill="#fff"
                    d="M208.502 55.937a5.647 5.647 0 01.53-.597 5.565 5.565 0 012.099-1.293l-.022-.211a5.772 5.772 0 00-2.777 1.982 5.73 5.73 0 00-.832 1.514l.211.029c.192-.511.458-.991.791-1.424zM205.702 53.82a3.744 3.744 0 01.196-.202 5.591 5.591 0 014.959-1.495l-.041-.218a5.803 5.803 0 00-5.063 1.565l-.062.061-.149.155a5.77 5.77 0 00-1.444 2.92l.2.061a5.556 5.556 0 011.404-2.848z"
                ></path>
                <path
                    fill="#fff"
                    d="M203.56 51.872a6.11 6.11 0 01.309-.329 5.611 5.611 0 016.665-.874c-.025-.093-.05-.185-.077-.276a5.811 5.811 0 00-7.044 1.329 5.8 5.8 0 00-1.429 4.01c.069.04.14.076.211.113a5.592 5.592 0 011.365-3.973z"
                ></path>
                <path
                    fill="#F0F0F0"
                    d="M66.033 82.833l.19-3.604.966 4.007c3.297.993 7.063.955 7.063.955s.254-7.808-2.716-10.846c-2.97-3.037-5.814-2.332-8.041-.154-2.228 2.178-2.996 5.005-.026 8.042a7.453 7.453 0 002.564 1.6z"
                ></path>
                <path
                    fill="#fff"
                    d="M66.617 78.657l-.146-.15c-1.693-1.749-2.727-3.394-3.087-4.913l-.015-.066.103-.024.1-.022c.37 1.658 1.571 3.338 3.047 4.864a28.277 28.277 0 002.29 2.105 41.483 41.483 0 002.783 2.104 41.353 41.353 0 002.659 1.713l.048.029-.051.089v.001l-.052.09-.155-.09a43.43 43.43 0 01-2.582-1.665l-.17-.119a42.293 42.293 0 01-2.629-1.997 28.245 28.245 0 01-2.143-1.948z"
                ></path>
                <path
                    fill="#fff"
                    d="M71.558 82.722a5.728 5.728 0 01.53-.597 5.564 5.564 0 012.1-1.293l-.022-.21a5.77 5.77 0 00-2.777 1.981c-.353.46-.633.97-.832 1.514l.21.029c.193-.511.459-.991.791-1.424zM68.76 80.605a3.623 3.623 0 01.197-.202 5.592 5.592 0 014.958-1.495 11.28 11.28 0 00-.04-.218 5.8 5.8 0 00-5.064 1.565l-.061.061-.15.156a5.77 5.77 0 00-1.444 2.92l.2.06a5.555 5.555 0 011.405-2.847z"
                ></path>
                <path
                    fill="#fff"
                    d="M66.617 78.657a5.105 5.105 0 01.309-.329 5.608 5.608 0 016.665-.874c-.025-.092-.05-.184-.077-.276a5.814 5.814 0 00-7.044 1.33 5.796 5.796 0 00-1.43 4.009c.07.04.14.076.212.114a5.59 5.59 0 011.365-3.974z"
                ></path>
                <path
                    fill="#F0F0F0"
                    d="M88.898 37.103l.19-3.605.966 4.008c3.297.993 7.063.955 7.063.955s.254-7.809-2.716-10.846c-2.97-3.038-5.813-2.333-8.04-.154-2.228 2.178-2.997 5.004-.027 8.042a7.454 7.454 0 002.564 1.6z"
                ></path>
                <path
                    fill="#fff"
                    d="M89.483 32.927l-.147-.15c-1.693-1.75-2.727-3.394-3.086-4.914l-.016-.066.103-.023.1-.022c.37 1.657 1.571 3.338 3.048 4.864.047.05.094.1.144.149a28.253 28.253 0 002.146 1.956 41.518 41.518 0 002.783 2.104 41.384 41.384 0 002.658 1.713l.048.028-.051.09-.052.09-.155-.09a43.365 43.365 0 01-2.581-1.665l-.17-.118a42.247 42.247 0 01-2.63-1.998 28.267 28.267 0 01-2.143-1.948z"
                ></path>
                <path
                    fill="#fff"
                    d="M94.424 36.991c.043-.055.086-.111.133-.166a5.563 5.563 0 012.496-1.724l-.022-.21a5.769 5.769 0 00-2.777 1.982 5.72 5.72 0 00-.832 1.514l.21.029c.193-.512.46-.992.791-1.425zM91.626 34.874a3.75 3.75 0 01.196-.202 5.593 5.593 0 014.958-1.494 11.003 11.003 0 00-.04-.219 5.801 5.801 0 00-6.718 4.702l.2.061a5.555 5.555 0 011.404-2.848z"
                ></path>
                <path
                    fill="#fff"
                    d="M89.482 32.926a5.117 5.117 0 01.31-.328 5.607 5.607 0 016.664-.874c-.025-.093-.05-.185-.077-.276a5.813 5.813 0 00-7.044 1.329 5.797 5.797 0 00-1.43 4.01c.07.039.14.076.212.113a5.59 5.59 0 011.365-3.974z"
                ></path>
                <path
                    fill="#F0F0F0"
                    d="M36.805 201.098l-7.823 7.202 6.217-10.431c-4.898-8.882-12.908-16.558-12.908-16.558S5.67 197.233 5.67 209.749c0 12.516 7.441 16.886 16.62 16.886 9.178 0 16.619-4.37 16.619-16.886 0-2.786-.825-5.74-2.105-8.651z"
                ></path>
                <path
                    fill="#fff"
                    d="M26.97 208.691v.616c-.036 7.172-1.245 12.767-3.618 16.706-.034.058-.07.113-.104.171l-.266-.161-.253-.159c2.632-4.255 3.564-10.268 3.597-16.523.003-.201.006-.406.003-.611a83.29 83.29 0 00-.43-7.928c-.018-.202-.04-.406-.061-.611a122.221 122.221 0 00-1.527-10.164c-.92-4.819-1.857-8.31-2.071-9.083-.024-.095-.04-.147-.043-.159l.29-.082.004-.003.293-.083c.003.012.055.186.14.51.327 1.21 1.176 4.493 2.01 8.824l.113.598c.433 2.312.858 4.896 1.194 7.608.085.681.162 1.353.229 2.016.024.204.046.409.064.61.281 2.813.427 5.449.437 7.908z"
                ></path>
                <path
                    fill="#fff"
                    d="M24.932 189.949a17.081 17.081 0 01-2.345.165 16.403 16.403 0 01-7.086-1.6l-.388.488a16.993 16.993 0 007.474 1.723 17.375 17.375 0 002.458-.177 16.89 16.89 0 004.871-1.475c-.13-.168-.26-.333-.384-.495a16.333 16.333 0 01-4.6 1.371zM26.469 200.172c-.211.012-.422.018-.633.018-.064.003-.13.003-.195.003a16.476 16.476 0 01-13.524-7.064c-.122.18-.244.36-.363.544a17.089 17.089 0 0013.887 7.131c.085 0 .171 0 .257-.003a16.994 16.994 0 009.69-3.195c-.098-.18-.195-.36-.296-.54a16.372 16.372 0 01-8.823 3.106z"
                ></path>
                <path
                    fill="#fff"
                    d="M26.97 208.689a15.333 15.333 0 01-1.329.055 16.519 16.519 0 01-15.838-11.887c-.138.248-.275.492-.407.74a17.128 17.128 0 0016.245 11.758c.23 0 .458-.003.684-.015.217-.007.431-.019.645-.034a17.078 17.078 0 0011.27-5.5c-.065-.226-.138-.449-.211-.675a16.467 16.467 0 01-11.06 5.558z"
                ></path>
                <path
                    fill="#E4E4E4"
                    d="M237.718 139.863c11.337-9.919 16.337-22.748 11.17-28.654-5.168-5.906-18.547-2.652-29.883 7.268-11.337 9.919-16.337 22.748-11.17 28.654 5.168 5.906 18.547 2.652 29.883-7.268zM70.212 147.131c5.168-5.906.167-18.735-11.17-28.654-11.336-9.92-24.715-13.174-29.882-7.268-5.168 5.906-.167 18.735 11.169 28.654 11.336 9.92 24.715 13.174 29.883 7.268zM95.744 226.346c15.063 0 27.274-6.362 27.274-14.209 0-7.848-12.211-14.209-27.274-14.209-15.064 0-27.275 6.361-27.275 14.209 0 7.847 12.211 14.209 27.275 14.209zM182.304 226.346c15.064 0 27.275-6.362 27.275-14.209 0-7.848-12.211-14.209-27.275-14.209-15.063 0-27.275 6.361-27.275 14.209 0 7.847 12.212 14.209 27.275 14.209z"
                ></path>
                <path
                    fill="#3F3D56"
                    d="M139.023 206.749c-36.923 0-66.962-30.039-66.962-66.963 0-36.923 30.039-66.962 66.962-66.962s66.962 30.039 66.962 66.962c0 36.924-30.039 66.963-66.962 66.963zm0-133.272c-36.563 0-66.31 29.747-66.31 66.309 0 36.563 29.747 66.309 66.31 66.309s66.309-29.746 66.309-66.309c0-36.562-29.746-66.308-66.309-66.308z"
                ></path>
                <path
                    fill="#F0F0F0"
                    d="M122.7 184.386a58.945 58.945 0 01-38.603-67.394 58.94 58.94 0 0012.263 69.162 58.938 58.938 0 0069.748 8.306 58.942 58.942 0 0028.486-39.457 58.944 58.944 0 01-71.894 29.383z"
                ></path>
                <path
                    fill="#3F3D56"
                    d="M119.302 134.45c-3.39 0-6.148-3.588-6.148-7.999s2.758-8 6.148-8c3.39 0 6.147 3.589 6.147 8s-2.757 7.999-6.147 7.999zM156.538 134.45c-3.39 0-6.147-3.588-6.147-7.999s2.757-8 6.147-8 6.148 3.589 6.148 8-2.758 7.999-6.148 7.999z"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M134.776 11.74c3.247 0 5.88-1.9 5.88-4.246 0-2.345-2.633-4.246-5.88-4.246s-5.88 1.901-5.88 4.246c0 2.346 2.633 4.247 5.88 4.247z"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M134.777 5.19c1.984 0 3.593-1.162 3.593-2.595 0-1.433-1.609-2.595-3.593-2.595-1.985 0-3.593 1.162-3.593 2.595 0 1.433 1.608 2.595 3.593 2.595zM130.549 9.192c-2.023-2.023-2.663-4.75-1.654-6.505-.24.137-.46.305-.656.5-1.658 1.658-1.141 4.863 1.155 7.16 2.296 2.296 5.502 2.813 7.16 1.154.194-.195.362-.416.499-.656-1.755 1.01-4.482.37-6.504-1.653z"
                ></path>
                <path
                    fill="#FFB6B6"
                    d="M74.254 114.297l-2.065-3.431 12.252-9.598 3.047 5.063-13.234 7.966z"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M71.456 117.174l-6.66-11.063.14-.084a5.025 5.025 0 016.899 1.713l4.067 6.758-4.446 2.676z"
                ></path>
                <path
                    fill="#FFB6B6"
                    d="M203.32 109.725l2.065-3.431-12.251-9.599-3.048 5.064 13.234 7.966z"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M201.674 109.925l4.067-6.757a5.027 5.027 0 016.898-1.714l.14.084-6.659 11.063-4.446-2.676zM123.253 63.935l-.78 3.115-21.046 16.848-22.65 21.167 2.43 5.649 53.986-36.133 60.865 31.55 2.719-6.228-27.775-18.618-22.969-15.84-24.78-1.51z"
                ></path>
                <path
                    fill="#F828A6"
                    d="M130.341 30.61l-9.097 2.984 1.23 33.135s15.82 9.499 25.56-1.283V32.303l-5.432-1.694h-12.261z"
                ></path>
                <path
                    fill="#F828A6"
                    d="M146.015 34.58l2.019-2.277s5.614 2.05 5.146 8.204c-.469 6.153-3.768 25.725-3.768 25.725l-.62 12.766h-5.86l2.335-33.015.748-11.402zM123.803 35.561l-2.019-2.278s-5.613 2.05-5.145 8.204c.468 6.154 3.767 25.725 3.767 25.725l.621 12.767h5.86l-2.336-33.015-.748-11.403z"
                ></path>
                <path
                    fill="#FFB6B6"
                    d="M123.997 85.237a3.593 3.593 0 100-7.185 3.593 3.593 0 000 7.185zM145.882 85.237a3.593 3.593 0 100-7.185 3.593 3.593 0 000 7.185z"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M134.625 27.888c5.496 0 9.951-4.455 9.951-9.95 0-5.496-4.455-9.952-9.951-9.952s-9.951 4.456-9.951 9.951c0 5.496 4.455 9.951 9.951 9.951z"
                ></path>
                <path
                    fill="#FFB6B6"
                    d="M134.925 28.217a8.023 8.023 0 100-16.045 8.023 8.023 0 000 16.045z"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M126.494 15.148a10.91 10.91 0 006.236 1.927 6.683 6.683 0 01-2.65 1.09c2.96.635 6.02.652 8.987.05a5.81 5.81 0 001.881-.645 2.378 2.378 0 001.162-1.554c.197-1.126-.681-2.15-1.593-2.84a11.753 11.753 0 00-9.873-1.972c-1.102.285-2.207.767-2.924 1.652-.716.886-.928 2.252-.246 3.164l-.98-.872z"
                ></path>
                <path
                    fill="#3F3D56"
                    d="M268.175 227.001H.327a.326.326 0 110-.653h267.848a.326.326 0 110 .653zM128.896 165.918a9.146 9.146 0 0018.293 0h-18.293z"
                ></path>
                <path
                    fill="#F0F0F0"
                    d="M171.728 154.486a7.84 7.84 0 100-15.68 7.84 7.84 0 000 15.68zM104.113 154.486c4.33 0 7.839-3.51 7.839-7.84a7.838 7.838 0 00-7.839-7.839 7.84 7.84 0 000 15.679z"
                ></path>
            </g>
            <defs>
                <clipPath id="clip0_1655_11472">
                    <path fill="#fff" d="M0 0H268.502V227H0z"></path>
                </clipPath>
            </defs>
        </svg>
    )
}

export default SuccessfullyPasswordIcon
