import { useDispatch, useSelector } from 'react-redux'

// antd
import { Row, Spin, Typography } from 'antd'

// components
import CardTalent from './components/cardTalent'

// slices
import { rolesInquiryState, talentViewShow } from './rolesInquiry.slice'
import { profileState } from '../../../profile/profile.slice'

// section
import TabSectionProfile from '../../../profile/tabSection/tab.section'

// assets
import { BackIcon } from '../../../../assets/icons'

const TalentViewPage = () => {
    const { Title } = Typography

    const dispatch = useDispatch()

    const { selectedRole } = useSelector(rolesInquiryState)
    const { status, profileData } = useSelector(profileState)

    return (
        <>
            <Row
                align={'middle'}
                style={{
                    columnGap: '15px',
                    marginBottom: '25px',
                }}
            >
                <div
                    style={{
                        cursor: 'pointer',
                        height: '24px',
                        width: '24px',
                    }}
                    onClick={() => dispatch(talentViewShow(false))}
                >
                    <BackIcon />
                </div>
                <Title level={3}>{selectedRole?.title}</Title>
            </Row>
            <CardTalent talentData={profileData?.data} />
            <div
                style={{
                    marginTop: '25px',
                }}
            >
                {status === 'loading' ? (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Spin spinning={true} />
                    </div>
                ) : (
                    <TabSectionProfile />
                )}
            </div>
        </>
    )
}

export default TalentViewPage
