import React from 'react'

function NotesIcon(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="20"
            fill="none"
            viewBox="0 0 16 20"
            {...props}
        >
            <path
                fill="#fff"
                d="M12 2.25c0 1.24-1.01 2.25-2.25 2.25h-3.5c-.62 0-1.18-.25-1.59-.66C4.25 3.43 4 2.87 4 2.25 4 1.01 5.01 0 6.25 0h3.5c.62 0 1.18.25 1.59.66.41.41.66.97.66 1.59z"
            ></path>
            <path
                fill="#fff"
                d="M14.83 3.029a2.831 2.831 0 00-.77-.45c-.29-.11-.58.12-.64.42-.34 1.71-1.85 3-3.67 3h-3.5c-1 0-1.94-.39-2.65-1.1a3.7 3.7 0 01-1.02-1.89c-.06-.3-.36-.54-.65-.42C.77 3.059 0 4.119 0 6.249v9.75c0 3 1.79 4 4 4h8c2.21 0 4-1 4-4v-9.75c0-1.63-.45-2.63-1.17-3.22zM4 10.249h4c.41 0 .75.34.75.75s-.34.75-.75.75H4c-.41 0-.75-.34-.75-.75s.34-.75.75-.75zm8 5.5H4c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h8c.41 0 .75.34.75.75s-.34.75-.75.75z"
            ></path>
        </svg>
    )
}

export default NotesIcon
