import React from 'react'

function ProviderIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="none"
            viewBox="0 0 20 20"
        >
            <path
                fill="#4F46E5"
                d="M17 20H3a3 3 0 01-3-3V1a1 1 0 011-1h14a1 1 0 011 1v12h4v4a3 3 0 01-3 3zm-1-5v2a1 1 0 102 0v-2h-2zM4 5v2h8V5H4zm0 4v2h8V9H4zm0 4v2h5v-2H4z"
            ></path>
        </svg>
    )
}

export default ProviderIcon
