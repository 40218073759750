import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import {
    apiGetJobApplicationNotes,
    apiGetStatusWiseCount,
    apiJobApplicantListByStatus,
} from './jobApplicant.service'

const initialState = {
    statusList: [],
    applicantList: [],
    isNext: false,
    loaders: {
        jobStatusLoading: false,
        applicantLoading: false,
    },
    notesList: [],
    notesLoading: false,
}

export const getStatusWiseCount = createAsyncThunk(
    'jobApplicant/getStatusWiseCount',
    async (params) => {
        const response = await apiGetStatusWiseCount(params)
        return response
    }
)

export const getJobApplicantListByStatus = createAsyncThunk(
    'jobApplicant/getJobApplicantListByStatus',
    async (params) => {
        const response = await apiJobApplicantListByStatus(params)
        return response
    }
)

export const getJobApplicationNotes = createAsyncThunk(
    'jobApplicant/getJobApplicationNotes',
    async (params) => {
        const response = await apiGetJobApplicationNotes(params)
        return response
    }
)

export const jobApplicantSlice = createSlice({
    name: 'jobApplicantSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getStatusWiseCount.pending, (state) => {
                state.statusList = []
                state.loaders.jobStatusLoading = true
            })
            .addCase(getStatusWiseCount.fulfilled, (state, action) => {
                state.statusList = action.payload.data?.data ?? []
                state.loaders.jobStatusLoading = false
            })
            .addCase(getStatusWiseCount.rejected, (state) => {
                state.statusList = []
                state.loaders.jobStatusLoading = false
            })
            .addCase(getJobApplicantListByStatus.pending, (state) => {
                state.applicantList = []
                state.isNext = false
                state.loaders.applicantLoading = true
            })
            .addCase(getJobApplicantListByStatus.fulfilled, (state, action) => {
                state.applicantList = action.payload.data || []
                state.isNext = action.payload?.isNext || false
                state.loaders.applicantLoading = false
            })
            .addCase(getJobApplicantListByStatus.rejected, (state) => {
                state.applicantList = []
                state.isNext = false
                state.loaders.applicantLoading = false
            })
            .addCase(getJobApplicationNotes.pending, (state) => {
                state.notesList = []
                state.notesLoading = true
            })
            .addCase(getJobApplicationNotes.fulfilled, (state, action) => {
                state.notesList = action.payload.data?.data || []
                state.notesLoading = false
            })
            .addCase(getJobApplicationNotes.rejected, (state) => {
                state.notesList = []
                state.notesLoading = false
            })
    },
})

export default jobApplicantSlice.reducer

export const jobApplicantState = (state) => state?.jobApplicantSlice

export const jobApplicantActions = jobApplicantSlice.actions
