// constants
import {
    ADD_EDIT_DATA_ACCESS,
    ADD_EDIT_FIELD,
    ALL_DATA_ACCESS,
    ASSIGN_DATA_ACCESS,
    DELETE_DATA_ACCESS,
    VIEW_FIELD,
} from '../constants/permissionField'
import { SUPER_ADMIN, WORKSPACE_ADMIN } from '../constants/roles'

// ADD PERMISSION CHECK
export function addPermissionCheck(key, userState) {
    if (
        userState?.role?.roleName === WORKSPACE_ADMIN ||
        userState?.role?.roleName === SUPER_ADMIN
    ) {
        return true
    } else if (userState?.role?.containers?.length > 0) {
        const permissionObject = userState?.role?.containers.find(
            (data) => data?.key === key
        )
        if (permissionObject !== undefined) {
            const hasTrueKeys = Object.keys(
                permissionObject[ADD_EDIT_DATA_ACCESS]
            ).some((data) => {
                if (data === ALL_DATA_ACCESS || data === ASSIGN_DATA_ACCESS) {
                    return permissionObject[ADD_EDIT_DATA_ACCESS][data]
                }
            })
            return hasTrueKeys
        }
    }
}

// DELETE PERMISSION CHECK
export function deletePermissionCheck(key, userState) {
    if (
        userState?.role?.roleName === WORKSPACE_ADMIN ||
        userState?.role?.roleName === SUPER_ADMIN
    ) {
        return true
    } else if (userState?.role?.containers?.length > 0) {
        const permissionObject = userState?.role?.containers.find(
            (data) => data?.key === key
        )
        if (permissionObject !== undefined) {
            const hasTrueKeys = Object.keys(
                permissionObject[DELETE_DATA_ACCESS]
            ).some((data) => {
                if (
                    data === ALL_DATA_ACCESS &&
                    permissionObject[DELETE_DATA_ACCESS][ALL_DATA_ACCESS] ===
                        true
                ) {
                    return permissionObject[DELETE_DATA_ACCESS][data]
                }
            })
            return hasTrueKeys
        }
    }
}

// EDIT PERMISSION CHECK
export function editPermissionCheck(key, userState) {
    if (
        userState?.role?.roleName === WORKSPACE_ADMIN ||
        userState?.role?.roleName === SUPER_ADMIN
    ) {
        return true
    } else if (userState?.role?.containers?.length > 0) {
        const permissionObject = userState?.role?.containers.find(
            (data) => data?.key === key
        )
        if (permissionObject !== undefined) {
            const hasTrueKeys = Object.keys(
                permissionObject[ADD_EDIT_DATA_ACCESS]
            ).some((data) => {
                if (
                    (data === ALL_DATA_ACCESS &&
                        permissionObject[ADD_EDIT_DATA_ACCESS][data]) ||
                    (data === ASSIGN_DATA_ACCESS &&
                        permissionObject[ADD_EDIT_DATA_ACCESS][data])
                ) {
                    return true
                }
            })
            return hasTrueKeys
        }
    }
}

// EDIT FIELD PERMISSION CHECK
export function editFieldCheck(key, fieldName, userState) {
    if (
        userState?.role?.roleName === WORKSPACE_ADMIN ||
        userState?.role?.roleName === SUPER_ADMIN
    ) {
        return true
    } else if (userState?.role?.containers?.length > 0) {
        const permissionObject = userState?.role?.containers.find(
            (data) => data?.key === key
        )
        if (permissionObject !== undefined) {
            const hasTrueKeys = Object.keys(
                permissionObject[ADD_EDIT_FIELD]
            ).some((data) => {
                if (
                    permissionObject[ADD_EDIT_FIELD][data]?.combinedFields
                        ?.length > 0
                ) {
                    if (
                        permissionObject[ADD_EDIT_FIELD][
                            data
                        ]?.combinedFields.includes(fieldName)
                    ) {
                        return permissionObject[ADD_EDIT_FIELD][data]
                            ?.hasPermission
                    } else {
                        return false
                    }
                } else {
                    if (data === fieldName) {
                        return permissionObject[ADD_EDIT_FIELD][data]
                            ?.hasPermission
                    } else {
                        return false
                    }
                }
            })
            return hasTrueKeys
        }
    }
}

// VIEW FIELDS PERMISSION CHECK
export function viewFieldCheck(key, fieldName, userState) {
    if (
        userState?.role?.roleName === WORKSPACE_ADMIN ||
        userState?.role?.roleName === SUPER_ADMIN
    ) {
        return true
    } else if (userState?.role?.containers?.length > 0) {
        const permissionObject = userState?.role?.containers.find(
            (data) => data?.key === key
        )
        if (permissionObject !== undefined) {
            const hasTrueKeys = Object.keys(permissionObject[VIEW_FIELD]).some(
                (data) => {
                    if (
                        permissionObject[VIEW_FIELD][data]?.combinedFields
                            ?.length > 0
                    ) {
                        if (
                            permissionObject[VIEW_FIELD][
                                data
                            ]?.combinedFields.includes(fieldName)
                        ) {
                            return permissionObject[VIEW_FIELD][data]
                                ?.hasPermission
                        } else {
                            return false
                        }
                    } else {
                        if (data === fieldName) {
                            return permissionObject[VIEW_FIELD][data]
                                ?.hasPermission
                        } else {
                            return false
                        }
                    }
                }
            )
            return hasTrueKeys
        }
    }
}
