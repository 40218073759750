import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

// antd
import { Result } from 'antd'

// components
import Button from '../../components/button/button'

// constants
import {
    DOMAIN_LIST,
    SUPER_ADMIN,
    WORKSPACE_ADMIN,
} from '../../constants/roles'

// routes
import { PRIVATE_ROUTES } from '../../routes'

// slices
import { loginState } from '../login/login.slice'

// styles
import styles from './404.module.css'

const FoF = () => {
    const navigate = useNavigate()

    const { userProfile } = useSelector(loginState)

    // CHECK ROLE
    function roleCheck() {
        if (
            userProfile?.role?.roleName === SUPER_ADMIN &&
            DOMAIN_LIST.includes(userProfile?.currentWorkSpace?.domain)
        ) {
            return PRIVATE_ROUTES.workSpace
        } else if (
            userProfile?.role?.roleName === WORKSPACE_ADMIN ||
            (userProfile?.role?.roleName === SUPER_ADMIN &&
                !DOMAIN_LIST.includes(userProfile?.currentWorkSpace?.domain) &&
                userProfile?.activeProject === null)
        ) {
            return `${PRIVATE_ROUTES.projects.root}`
        } else if (
            userProfile?.role?.roleName !== SUPER_ADMIN &&
            userProfile?.role?.roleName !== WORKSPACE_ADMIN &&
            userProfile?.activeProject === null &&
            DOMAIN_LIST.includes(userProfile?.currentWorkSpace?.domain)
        ) {
            return `${PRIVATE_ROUTES.profile}`
        } else if (
            userProfile?.role?.roleName !== SUPER_ADMIN &&
            userProfile?.role?.roleName !== WORKSPACE_ADMIN &&
            userProfile?.activeProject !== null
        ) {
            return `${PRIVATE_ROUTES.overview}`
        } else {
            return `${PRIVATE_ROUTES.projects.root}`
        }
    }

    return (
        <div className={styles.fof_page}>
            <div className={styles.fof_box}>
                <Result
                    status="404"
                    title="404"
                    subTitle="Sorry, the page you want to visit does not exist."
                    extra={
                        <Button
                            props={{
                                text: 'Go Home',
                                onClick: () =>
                                    navigate(roleCheck(), { replace: true }),
                            }}
                        />
                    }
                />
            </div>
        </div>
    )
}

export default FoF
