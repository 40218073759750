import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { theme } from 'antd'

import ModalComponent from '../../../components/modal/modalComponent'
import { clearJobDetails, jobState, switchJobModal } from '../store/job.slice'
import { TeamIcon } from '../../../assets/icons'
import AddEditJobForm from './AddEditJobForm'

const AddEditJob = ({ from }) => {
    const { useToken } = theme
    const { token } = useToken()

    const dispatch = useDispatch()

    const { modalVariations, selectedData } = useSelector(jobState)

    return (
        <div>
            <ModalComponent
                visibility={modalVariations?.open}
                content={
                    <>
                        <AddEditJobForm />
                    </>
                }
                modalTitle={modalVariations?.isEdit ? 'Edit Job' : 'Add Job'}
                showTitle={'Job'}
                icon={<TeamIcon color={token.colorPalette.baseColor.white} />}
                buttonName={modalVariations?.isEdit ? 'Save' : 'Add'}
                masterDataState={modalVariations}
                selectedDataMaster={selectedData}
                selectedDataProfile={modalVariations?.selectedJobID}
                handleCancel={() => {
                    dispatch(
                        switchJobModal({
                            ...modalVariations,
                            open: false,
                            isEdit: false,
                            selectedJobID: null,
                        })
                    )

                    if (from !== 'applicant') {
                        dispatch(clearJobDetails())
                    }
                }}
            />
        </div>
    )
}

export default AddEditJob
