import React from 'react'

function ProjectsIcon({ color }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="30"
            fill="none"
            viewBox="0 0 28 30"
        >
            <path
                fill={color ? color : '#B9B9B9'}
                fillRule="evenodd"
                d="M14.104 12.552l4.185-3.515 2.252 2.252-3.516 4.185-2.921-2.922zM4.168 9.538l-1.22-2.595.891-.891 2.595 1.221-2.266 2.265zm13.848 6.926l3.893-4.634c.92-.004 2.678-.25 4.266-1.839 3.224-3.223.598-9.317.485-9.575a.698.698 0 00-1.332.207c-.003.024-.28 2.396-1.75 2.55-.179.018-.348.027-.517.027-.425 0-.755-.058-1.075-.114-.283-.05-.55-.096-.842-.096-.646 0-1.234.244-1.908.79-1.384 1.124-1.563 3.066-1.568 3.956l-4.554 3.826-4.991-4.991-.008-.006a.686.686 0 00-.189-.132L2.98 4.104.995 3.17a.698.698 0 00-.855.213l-.006.007C.12 3.407.11 3.426.1 3.444l-.023.038-.016.04a.6.6 0 00-.025.057l-.009.035c-.006.022-.013.044-.017.067l-.003.036c-.003.024-.006.046-.007.07l.002.033c0 .024 0 .048.003.072l.007.031c.004.025.008.05.016.074.003.011.009.022.013.034.007.022.015.045.025.067L3.33 11.03a.718.718 0 00.045.082l.011.015a.692.692 0 00.076.092l.005.007 5.5 5.5c-1.991 2.098-4.044 4.446-5.631 6.456-1.07 1.353-1.875 2.482-2.396 3.354-.576.966-1.028 1.94-.433 2.535a.982.982 0 00.717.28c1.797 0 7.734-5.052 11.627-8.741l9.135 9.134a.694.694 0 00.986 0l1.34-1.34L5.796 9.885l.987-.986L25.3 27.417l1.341-1.341a.697.697 0 000-.987l-8.625-8.625z"
                clipRule="evenodd"
            ></path>
        </svg>
    )
}

export default ProjectsIcon
