import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// antd
import {
    Col,
    Divider,
    Image,
    Row,
    Space,
    Spin,
    Tag,
    Typography,
    theme,
} from 'antd'

// components
import Button from '../../../components/button/button'
import ModalProfile from '../../../components/modal'

// slices
import {
    profileState,
    setDeleteModalTitle,
    switchProfileModal,
    updatedDataProfile,
} from '../profile.slice'
import { loginState } from '../../login/login.slice'

// constants
import { SUPER_ADMIN } from '../../../constants/roles'
import { fileTypes, videoTypes } from '../../../constants/mediaTypes'

// assets
import {
    CertificateIcon,
    CertificatesLogoIcon,
    EditIcon,
    NoCertificatesIcon,
    NoEducationIcon,
} from '../../../assets/icons'

// section
import NoIndividualContent from '../tabSection/noIndividualContent'

// modals
import ViewMultiMediaModal from '../modals/viewMultimediaModal'

// styles
import styles from '../profile.module.css'

function EducationTabContent() {
    const { Title, Text } = Typography

    const { useToken } = theme
    const { token } = useToken()

    const dispatch = useDispatch()

    const {
        profileData: {
            data: { education, certificates },
        },
        status,
    } = useSelector(profileState)
    const { userProfile } = useSelector(loginState)

    const [viewMediaCertificate, setViewMediaCertificate] = useState({
        open: false,
        file: {},
    })

    return (
        <>
            <div
                style={{
                    padding: '50px',
                }}
            >
                <Row align="middle" justify={'space-between'}>
                    <Title level={4}>Education</Title>
                    {userProfile?.role?.roleName !== SUPER_ADMIN && (
                        <Button
                            props={{
                                text: 'Add Education',
                                onClick: () => {
                                    dispatch(
                                        switchProfileModal({
                                            name: 'education',
                                            open: true,
                                        })
                                    )
                                },
                            }}
                        />
                    )}
                </Row>
                <Spin spinning={status === 'loading' ? true : false}>
                    {status === 'loaded' && education?.length > 0 ? (
                        education.map((educationData, index) => (
                            <>
                                <Row
                                    justify={'space-between'}
                                    style={{
                                        width: '100%',
                                        marginTop: '35px',
                                    }}
                                >
                                    <Col span={23}>
                                        <Row
                                            key={
                                                educationData?.eduInfo
                                                    ?.institute?.name
                                            }
                                            style={{
                                                columnGap: '20px',
                                                flexFlow: 'row nowrap',
                                            }}
                                        >
                                            <Row
                                                style={{
                                                    height: '100px',
                                                    width: '100px',
                                                    minWidth: '100px',
                                                    borderRadius: '10px',
                                                    padding:
                                                        '20px 20px 0px 20px',
                                                    border: `2px solid ${token.colorPalette.textColor.quaternary}`,
                                                }}
                                            >
                                                {educationData?.eduInfo
                                                    ?.institute?.logo ? (
                                                    <Image
                                                        src={
                                                            educationData
                                                                ?.eduInfo
                                                                ?.institute
                                                                ?.logo
                                                        }
                                                        width={'100%'}
                                                        height={'100%'}
                                                        style={{
                                                            objectFit: 'cover',
                                                        }}
                                                        preview={false}
                                                    />
                                                ) : (
                                                    <div
                                                        className={
                                                            styles.educationSvgLogo
                                                        }
                                                    />
                                                )}
                                            </Row>

                                            <div
                                                style={{
                                                    marginRight: '50px',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                }}
                                            >
                                                <Row>
                                                    <Space>
                                                        {educationData?.eduInfo
                                                            ?.degree?.name && (
                                                            <Title
                                                                className={
                                                                    'titleSub'
                                                                }
                                                            >
                                                                {
                                                                    educationData
                                                                        ?.eduInfo
                                                                        ?.degree
                                                                        ?.name
                                                                }
                                                            </Title>
                                                        )}
                                                        <Text className="titleLight">
                                                            {!educationData?.isCompleted &&
                                                                `(Draft)`}
                                                        </Text>
                                                    </Space>
                                                </Row>
                                                <Space>
                                                    {educationData?.eduInfo
                                                        ?.institute?.name && (
                                                        <Text
                                                            style={{
                                                                fontSize:
                                                                    token.fontSize,
                                                            }}
                                                        >
                                                            {
                                                                educationData
                                                                    ?.eduInfo
                                                                    ?.institute
                                                                    ?.name
                                                            }
                                                        </Text>
                                                    )}
                                                    {educationData?.eduInfo
                                                        ?.institute?.name &&
                                                        educationData?.eduInfo
                                                            ?.location &&
                                                        '|'}
                                                    {educationData?.eduInfo
                                                        ?.location && (
                                                        <Text
                                                            style={{
                                                                fontSize:
                                                                    token.fontSize,
                                                            }}
                                                        >
                                                            {
                                                                educationData
                                                                    ?.eduInfo
                                                                    ?.location
                                                            }
                                                        </Text>
                                                    )}
                                                </Space>
                                                <Space>
                                                    {educationData?.eduInfo
                                                        ?.start !== null && (
                                                        <Text
                                                            style={{
                                                                fontSize:
                                                                    token.fontSizeLG,
                                                                marginTop:
                                                                    '15px',
                                                            }}
                                                            className={
                                                                'titleLight'
                                                            }
                                                        >
                                                            {new Date(
                                                                educationData?.eduInfo?.start
                                                            )?.toLocaleString(
                                                                'default',
                                                                {
                                                                    year: 'numeric',
                                                                    month: 'long',
                                                                }
                                                            )}
                                                        </Text>
                                                    )}
                                                    {educationData?.eduInfo
                                                        ?.start !== null &&
                                                        (educationData?.eduInfo
                                                            ?.currentStudy ||
                                                            educationData
                                                                ?.eduInfo
                                                                ?.end !==
                                                                null) &&
                                                        '-'}
                                                    {(educationData?.eduInfo
                                                        ?.currentStudy ||
                                                        educationData?.eduInfo
                                                            ?.end !== null) && (
                                                        <Text
                                                            style={{
                                                                fontSize:
                                                                    token.fontSizeLG,
                                                                marginTop:
                                                                    '15px',
                                                            }}
                                                            className={
                                                                'titleLight'
                                                            }
                                                        >
                                                            {educationData
                                                                ?.eduInfo
                                                                ?.currentStudy
                                                                ? 'Present'
                                                                : new Date(
                                                                      educationData?.eduInfo?.end
                                                                  )?.toLocaleString(
                                                                      'default',
                                                                      {
                                                                          month: 'long',
                                                                          year: 'numeric',
                                                                      }
                                                                  )}
                                                        </Text>
                                                    )}
                                                </Space>
                                                {educationData?.skills?.length >
                                                    0 && (
                                                    <div
                                                        style={{
                                                            marginTop: '15px',
                                                        }}
                                                    >
                                                        <Text
                                                            style={{
                                                                fontSize:
                                                                    token.fontSizeLG,
                                                                fontWeight:
                                                                    token.fontWeightStrong,
                                                            }}
                                                        >
                                                            Skills
                                                        </Text>
                                                        <Row
                                                            style={{
                                                                rowGap: '10px',
                                                                marginTop:
                                                                    '8px',
                                                                columnGap:
                                                                    '10px',
                                                            }}
                                                        >
                                                            {educationData?.skills.map(
                                                                (skill) => (
                                                                    <Tag
                                                                        style={{
                                                                            padding:
                                                                                '9px 32px',
                                                                            cursor: 'text',
                                                                        }}
                                                                        key={
                                                                            skill?.label
                                                                        }
                                                                        color={
                                                                            token
                                                                                .colorPalette
                                                                                .baseColor
                                                                                .senary
                                                                        }
                                                                    >
                                                                        <Text
                                                                            style={{
                                                                                fontWeight:
                                                                                    token.fontWeightStrong,
                                                                            }}
                                                                        >
                                                                            {skill?.name?.toUpperCase()}
                                                                        </Text>
                                                                    </Tag>
                                                                )
                                                            )}
                                                        </Row>
                                                    </div>
                                                )}
                                                {educationData?.tools?.length >
                                                    0 && (
                                                    <div
                                                        style={{
                                                            marginTop: '15px',
                                                        }}
                                                    >
                                                        <Text
                                                            style={{
                                                                fontSize:
                                                                    token.fontSizeLG,
                                                                fontWeight:
                                                                    token.fontWeightStrong,
                                                            }}
                                                        >
                                                            Tools
                                                        </Text>
                                                        <Row
                                                            style={{
                                                                columnGap:
                                                                    '15px',
                                                                marginTop:
                                                                    '8px',
                                                            }}
                                                        >
                                                            {educationData?.tools.map(
                                                                (tool) => (
                                                                    <div
                                                                        key={
                                                                            tool?.label
                                                                        }
                                                                    >
                                                                        <div
                                                                            className={
                                                                                styles.toolIconDefault
                                                                            }
                                                                            style={{
                                                                                border: `2px solid ${token.colorPalette.baseColor.tertiary}`,
                                                                            }}
                                                                        >
                                                                            <Image
                                                                                src={
                                                                                    tool?.logo
                                                                                }
                                                                                preview={
                                                                                    false
                                                                                }
                                                                                width={
                                                                                    '100%'
                                                                                }
                                                                            />
                                                                        </div>
                                                                        <div
                                                                            style={{
                                                                                width: '60px',
                                                                                whiteSpace:
                                                                                    'nowrap',
                                                                                overflow:
                                                                                    'hidden',
                                                                                textOverflow:
                                                                                    'ellipsis',
                                                                            }}
                                                                        >
                                                                            <Text
                                                                                style={{
                                                                                    marginTop:
                                                                                        '2px',
                                                                                    fontWeight:
                                                                                        token.fontWeightStrong,
                                                                                    textTransform:
                                                                                        'capitalize',
                                                                                }}
                                                                            >
                                                                                {
                                                                                    tool?.name
                                                                                }
                                                                            </Text>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            )}
                                                        </Row>
                                                    </div>
                                                )}
                                            </div>
                                            {/* </Col> */}
                                        </Row>
                                    </Col>
                                    <Col span={1}>
                                        {userProfile?.role?.roleName !==
                                            SUPER_ADMIN && (
                                            <div
                                                style={{
                                                    cursor: 'pointer',
                                                    height: '26px',
                                                    width: '26px',
                                                }}
                                                onClick={() => {
                                                    dispatch(
                                                        setDeleteModalTitle(
                                                            'education'
                                                        )
                                                    )
                                                    dispatch(
                                                        switchProfileModal({
                                                            name: 'education',
                                                            open: true,
                                                            mode: 'edit',
                                                        })
                                                    )
                                                    dispatch(
                                                        updatedDataProfile({
                                                            editData: {
                                                                step: 'education',
                                                                stepField:
                                                                    '_id',
                                                                stepFieldValue:
                                                                    educationData?._id,
                                                            },
                                                            _id: userProfile?._id,
                                                        })
                                                    )
                                                }}
                                            >
                                                <EditIcon />
                                            </div>
                                        )}
                                    </Col>
                                </Row>
                                {education?.length - 1 !== index && <Divider />}
                            </>
                        ))
                    ) : status === 'loaded' ? (
                        <NoIndividualContent
                            content={{
                                key: 'education',
                                title: 'Education',
                                icon: <NoEducationIcon />,
                            }}
                        />
                    ) : (
                        status === 'loading' && <Spin />
                    )}
                </Spin>
                <Row
                    align="middle"
                    justify={'space-between'}
                    style={{
                        marginTop: '20px',
                    }}
                >
                    <Title level={4}>Certificates</Title>
                    {userProfile?.role?.roleName !== SUPER_ADMIN && (
                        <Button
                            props={{
                                text: 'Add Certificate',
                                onClick: () => {
                                    dispatch(
                                        switchProfileModal({
                                            name: 'certificates',
                                            open: true,
                                        })
                                    )
                                },
                            }}
                        />
                    )}
                </Row>
                <Spin spinning={status === 'loading' ? true : false}>
                    {status === 'loaded' && certificates?.length > 0 ? (
                        certificates.map((certificatesData, index) => (
                            <>
                                <Row
                                    justify={'space-between'}
                                    style={{
                                        width: '100%',
                                        marginTop: '35px',
                                    }}
                                >
                                    <Col span={23}>
                                        <Row
                                            style={{
                                                flexFlow: 'row nowrap',
                                                columnGap: '20px',
                                            }}
                                            key={
                                                certificatesData?.institute
                                                    ?.name
                                            }
                                        >
                                            {certificatesData?.fileLink
                                                ?.length > 0 ? (
                                                fileTypes.some((r) =>
                                                    certificatesData?.fileLink[0]
                                                        ?.toLowerCase()
                                                        .includes(r)
                                                ) ? (
                                                    <Image
                                                        src={
                                                            certificatesData
                                                                ?.fileLink[0]
                                                        }
                                                        width={'100px'}
                                                        height={'100px'}
                                                        preview={false}
                                                        style={{
                                                            borderRadius:
                                                                '10px',
                                                            objectFit: 'cover',
                                                            cursor: 'pointer',
                                                            minWidth: '100px',
                                                        }}
                                                        onClick={() => {
                                                            setViewMediaCertificate(
                                                                {
                                                                    open: true,
                                                                    file: {
                                                                        name: certificatesData?.title,
                                                                        description:
                                                                            certificatesData?.description,
                                                                        files: certificatesData?.fileLink,
                                                                    },
                                                                }
                                                            )
                                                        }}
                                                    />
                                                ) : videoTypes.some((r) =>
                                                      certificatesData?.fileLink[0].includes(
                                                          r
                                                      )
                                                  ) ? (
                                                    <video
                                                        src={
                                                            certificatesData
                                                                ?.fileLink[0]
                                                        }
                                                        width={'100px'}
                                                        height={'100px'}
                                                        style={{
                                                            borderRadius:
                                                                '10px',
                                                            objectFit: 'cover',
                                                            cursor: 'pointer',
                                                            minWidth: '100px',
                                                        }}
                                                        onClick={() => {
                                                            setViewMediaCertificate(
                                                                {
                                                                    open: true,
                                                                    file: {
                                                                        name: certificatesData?.title,
                                                                        description:
                                                                            certificatesData?.description,
                                                                        files: certificatesData?.fileLink,
                                                                    },
                                                                }
                                                            )
                                                        }}
                                                    />
                                                ) : (
                                                    <div
                                                        onClick={() => {
                                                            setViewMediaCertificate(
                                                                {
                                                                    open: true,
                                                                    file: {
                                                                        name: certificatesData?.title,
                                                                        description:
                                                                            certificatesData?.description,
                                                                        files: certificatesData?.fileLink,
                                                                    },
                                                                }
                                                            )
                                                        }}
                                                    >
                                                        <iframe
                                                            src={`${certificatesData?.fileLink[0]}#view=fitH`}
                                                            width={'100px'}
                                                            height={'100px'}
                                                            title="PDF"
                                                            style={{
                                                                borderRadius:
                                                                    '10px',
                                                                objectFit:
                                                                    'cover',
                                                                cursor: 'pointer',
                                                                minWidth:
                                                                    '100px',
                                                            }}
                                                        />
                                                    </div>
                                                )
                                            ) : (
                                                <Row
                                                    style={{
                                                        height: '100px',
                                                        width: '100px',
                                                        borderRadius: '10px',
                                                        padding:
                                                            '20px 20px 20px 20px',
                                                        border: `2px solid ${token.colorPalette.textColor.quaternary}`,
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            height: '56px',
                                                            width: '56px',
                                                        }}
                                                    >
                                                        <CertificatesLogoIcon />
                                                    </div>
                                                </Row>
                                            )}
                                            {/* {certificatesData
                                                ?.certificateProvider?.logo ? (
                                                <Image
                                                    src={
                                                        certificatesData
                                                            ?.certificateProvider
                                                            ?.logo
                                                    }
                                                    width={'100%'}
                                                    height={'100%'}
                                                    preview={false}
                                                />
                                            ) : (
                                                <div
                                                    style={{
                                                        height: '56px',
                                                        width: '56px',
                                                    }}
                                                >
                                                    <CertificatesLogoIcon />
                                                </div>
                                            )} */}
                                            <div
                                                style={{
                                                    marginRight: '50px',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                }}
                                            >
                                                <Row>
                                                    <Space>
                                                        {certificatesData?.title && (
                                                            <Title
                                                                className={
                                                                    'titleSub'
                                                                }
                                                            >
                                                                {
                                                                    certificatesData?.title
                                                                }
                                                            </Title>
                                                        )}
                                                        <Text className="titleLight">
                                                            {!certificatesData?.isCompleted &&
                                                                `(Draft)`}
                                                        </Text>
                                                    </Space>
                                                </Row>
                                                <Space>
                                                    {certificatesData
                                                        ?.certificateProvider
                                                        ?.name && (
                                                        <Text
                                                            style={{
                                                                fontSize:
                                                                    token.fontSize,
                                                            }}
                                                        >
                                                            {
                                                                certificatesData
                                                                    ?.certificateProvider
                                                                    ?.name
                                                            }
                                                        </Text>
                                                    )}
                                                    {certificatesData
                                                        ?.certificateProvider
                                                        ?.name &&
                                                        certificatesData
                                                            ?.fieldOfStudy
                                                            ?.name &&
                                                        '|'}
                                                    {certificatesData
                                                        ?.fieldOfStudy
                                                        ?.name && (
                                                        <Text
                                                            style={{
                                                                fontSize:
                                                                    token.fontSize,
                                                            }}
                                                        >
                                                            {
                                                                certificatesData
                                                                    ?.fieldOfStudy
                                                                    ?.name
                                                            }
                                                        </Text>
                                                    )}
                                                </Space>
                                                <Text
                                                    style={{
                                                        fontSize:
                                                            token.fontSizeLG,
                                                    }}
                                                    className={'titleLight'}
                                                >
                                                    {certificatesData?.dateReceived !==
                                                        null &&
                                                        new Date(
                                                            certificatesData?.dateReceived
                                                        )?.toLocaleString(
                                                            'default',
                                                            {
                                                                year: 'numeric',
                                                                month: 'long',
                                                            }
                                                        )}
                                                </Text>
                                                <Text
                                                    style={{
                                                        fontSize:
                                                            token.fontSize,
                                                    }}
                                                >
                                                    {
                                                        certificatesData?.description
                                                    }
                                                </Text>
                                            </div>
                                        </Row>
                                    </Col>
                                    <Col span={1}>
                                        {userProfile?.role?.roleName !==
                                            SUPER_ADMIN && (
                                            <div
                                                style={{
                                                    cursor: 'pointer',
                                                    height: '26px',
                                                    width: '26px',
                                                }}
                                                onClick={() => {
                                                    dispatch(
                                                        setDeleteModalTitle(
                                                            'certificate'
                                                        )
                                                    )
                                                    dispatch(
                                                        switchProfileModal({
                                                            name: 'certificates',
                                                            open: true,
                                                            mode: 'edit',
                                                        })
                                                    )
                                                    dispatch(
                                                        updatedDataProfile({
                                                            editData: {
                                                                step: 'certificates',
                                                                stepField:
                                                                    '_id',
                                                                stepFieldValue:
                                                                    certificatesData?._id,
                                                            },
                                                            _id: userProfile?._id,
                                                        })
                                                    )
                                                }}
                                            >
                                                <EditIcon />
                                            </div>
                                        )}
                                    </Col>
                                </Row>
                                {certificates?.length - 1 !== index && (
                                    <Divider />
                                )}
                            </>
                        ))
                    ) : status === 'loaded' ? (
                        <NoIndividualContent
                            content={{
                                key: 'certificates',
                                title: 'Certificates',
                                icon: <NoCertificatesIcon />,
                            }}
                        />
                    ) : (
                        status === 'loading' && <Spin />
                    )}
                </Spin>
            </div>
            <ModalProfile
                visibility={viewMediaCertificate?.open}
                handleCancel={() => {
                    setViewMediaCertificate({
                        open: false,
                        file: {},
                    })
                }}
                setMaxHeight={false}
                footer={false}
                icon={<CertificateIcon />}
                modalKey="viewVideo"
                modalTitle="Media"
                showTitle="Media"
                content={
                    <ViewMultiMediaModal
                        fileData={viewMediaCertificate?.file}
                    />
                }
            />
        </>
    )
}

export default EducationTabContent
