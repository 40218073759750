import axiosNew from '../../helpers/axios'

// CREATE USER
export const create = async (payload) => {
    return new Promise(async (resolve) => {
        try {
            let data = payload ?? {}
            const create = await axiosNew({
                url: 'users/create',
                data,
            })
            resolve({ data: create?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// GET USER
export const getUser = async (payload) => {
    return new Promise(async (resolve) => {
        try {
            const getUser = await axiosNew({
                url: 'users/get/' + payload._id,
                method: 'get',
            })
            resolve({ data: getUser?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// COUNT USERS
export const count = async (payload) => {
    const { filter = {}, search } = payload
    return new Promise(async (resolve) => {
        const dropDownValue = { ...filter }
        Object.keys(dropDownValue).forEach((key) => {
            if (dropDownValue[key] === '') {
                delete dropDownValue[key]
            }
        })
        try {
            let data = {
                filter: {
                    ...dropDownValue,
                    isTalentUser: true,
                },
            }
            if (search?.length > 0) {
                data['search'] = {
                    name: search,
                    email: search,
                    phone: search,
                }
            }
            const count = await axiosNew({
                url: 'users/count',
                data,
            })
            resolve({ data: count?.data?.result ?? 0 })
        } catch (error) {
            resolve({ data: 0 })
        }
    })
}

// LIST USERS
export const read = (payload) => {
    const { search = '', skip = 0, limit = 0, filter = {} } = payload
    return new Promise(async (resolve) => {
        const dropDownValue = { ...filter }
        Object.keys(dropDownValue).forEach((key) => {
            if (dropDownValue[key] === '') {
                delete dropDownValue[key]
            }
        })
        try {
            let data = {
                skip,
                filter: {
                    ...dropDownValue,
                    isTalentUser: true,
                },
                limit,
                select: [
                    'name',
                    'email',
                    'employeeNumber',
                    'phone',
                    'countryCode',
                    'flag',
                    'address',
                    'employmentType',
                    'state',
                    'department',
                    'designation',
                    'emergencyName',
                    'emergencyPhone',
                    'notes',
                    'role',
                    'isActive',
                    'profilePicture',
                    'isOurUser',
                    'isAssigned',
                    'isWorkspaceAssigned',
                    'isTalentUser',
                    'price',
                    'dailyWorkingHours',
                    'weeklyWorkingDays',
                    'status',
                    'timeZone',
                ],
                populate: {
                    name: [
                        {
                            path: 'role',
                            select: 'roleName',
                        },
                        {
                            path: 'workspace',
                            select: 'domain logo companyName',
                        },
                    ],
                },
            }
            if (search?.length > 0) {
                data['search'] = {
                    name: search,
                    email: search,
                    phone: search,
                }
            }
            const users = await axiosNew({
                url: 'users/read',
                data,
            })
            resolve({
                data: users?.data?.result ?? [],
                isNext: users?.data?.isNext,
            })
        } catch (error) {
            resolve({ data: [] })
        }
    })
}

// UPDATE USER
export const update = async (payload) => {
    return new Promise(async (resolve) => {
        try {
            let data = payload?.updatedData ?? {}
            const update = await axiosNew({
                url: 'users/update/' + payload._id,
                data,
            })
            resolve({ data: update?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// DELETE USER
export const deleteUser = async (payload) => {
    return new Promise(async (resolve) => {
        try {
            const deleteUser = await axiosNew({
                url: 'users/delete/' + payload,
            })
            resolve({ data: deleteUser?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// LIST ROLES
export const listRoles = async () => {
    return new Promise(async (resolve) => {
        try {
            let data = { select: ['roleName'] }
            const roleList = await axiosNew({
                url: 'role/list/',
                data,
            })
            resolve({ data: roleList?.data?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}
