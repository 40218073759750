import React from 'react'

function VerifyOtpIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="177"
            height="227"
            fill="none"
            viewBox="0 0 177 227"
        >
            <g clipPath="url(#clip0_1655_11377)">
                <path
                    fill="#3F3D56"
                    d="M176.885 50.346h-1.164v-31.89a18.448 18.448 0 00-5.406-13.05A18.447 18.447 0 00157.265 0H89.703a18.457 18.457 0 00-18.457 18.457v174.947a18.458 18.458 0 0018.457 18.457h67.562a18.456 18.456 0 0018.456-18.457V73.045h1.164V50.346z"
                ></path>
                <path
                    fill="#fff"
                    d="M158.01 4.8h-8.819a6.538 6.538 0 01-.628 6.127 6.548 6.548 0 01-5.435 2.895h-38.705a6.55 6.55 0 01-6.063-9.021h-8.237A13.783 13.783 0 0076.34 18.584v174.691a13.784 13.784 0 0013.783 13.783h67.887a13.778 13.778 0 0012.734-8.509 13.773 13.773 0 001.049-5.274V18.584a13.777 13.777 0 00-4.037-9.746A13.774 13.774 0 00158.01 4.8z"
                ></path>
                <path
                    fill="#E6E6E6"
                    d="M127.628 194.686a26.152 26.152 0 01-16.478-5.833 26.152 26.152 0 01-6.824-32.236 26.157 26.157 0 0149.406 13.387 26.163 26.163 0 01-6.761 16.12l5.463 6.123-2.726 1.156c-4.217 1.789-8.344.049-10.66-1.34a26.204 26.204 0 01-11.42 2.623zm0-48.017a21.887 21.887 0 1010.521 41.082l1.167-.642 1.085.773a11.496 11.496 0 004.141 1.894l-3.437-3.853 1.507-1.416a21.694 21.694 0 006.902-15.952 21.912 21.912 0 00-21.886-21.886z"
                ></path>
                <path
                    fill="#fff"
                    d="M151.639 168.554a23.998 23.998 0 10-12.465 21.055c1.659 1.181 5.708 3.536 9.708 1.84l-4.815-5.397a23.921 23.921 0 005.603-7.963 23.926 23.926 0 001.969-9.535z"
                ></path>
                <path
                    fill="#F828A6"
                    d="M132.421 163.646h-17.155v1.594h17.155v-1.594z"
                ></path>
                <path
                    fill="#E6E6E6"
                    d="M145.166 169.156h-29.9v1.593h29.9v-1.593zM145.137 174.668h-29.871v1.593h29.871v-1.593zM127.628 78.534a26.157 26.157 0 01-23.302-38.07 26.158 26.158 0 1142.645 29.507l5.463 6.124-2.726 1.156c-4.217 1.789-8.344.048-10.66-1.34a26.19 26.19 0 01-11.42 2.623zm0-48.018a21.89 21.89 0 00-21.424 17.44 21.893 21.893 0 004.776 18.647 21.892 21.892 0 0017.657 7.664 21.883 21.883 0 009.512-2.669l1.167-.64 1.085.771a11.476 11.476 0 004.141 1.895l-3.437-3.854 1.507-1.416a21.69 21.69 0 006.902-15.951 21.91 21.91 0 00-21.886-21.887z"
                ></path>
                <path
                    fill="#fff"
                    d="M151.639 52.404a23.998 23.998 0 00-33.003-22.237 24 24 0 00-14.831 25.051 24.001 24.001 0 0035.369 18.241c1.66 1.18 5.708 3.536 9.708 1.84l-4.815-5.397a23.927 23.927 0 007.572-17.498z"
                ></path>
                <path
                    fill="#F828A6"
                    d="M132.421 47.494h-17.155v1.593h17.155v-1.593z"
                ></path>
                <path
                    fill="#E6E6E6"
                    d="M145.166 53.006h-29.9v1.593h29.9v-1.593zM145.137 58.518h-29.871v1.593h29.871v-1.593zM160.886 80.766a3.784 3.784 0 100-7.568 3.784 3.784 0 000 7.568zM160.886 196.918a3.784 3.784 0 100-7.568 3.784 3.784 0 000 7.568z"
                ></path>
                <path
                    fill="#F828A6"
                    d="M77.311 138.57c-2.77 1.66-7.703 3.742-12.746 1.603l-3.259-1.383 6.532-7.321a31.195 31.195 0 0120.164-52.012 31.193 31.193 0 11-10.69 59.113z"
                ></path>
                <path
                    fill="#000"
                    d="M117.278 110.317a28.708 28.708 0 00-26.169-28.593 28.705 28.705 0 0122.824 48.885 28.706 28.706 0 01-20.287 8.415c-.853 0-1.698-.041-2.533-.114a28.703 28.703 0 0026.165-28.593z"
                    opacity="0.1"
                ></path>
                <path
                    fill="#fff"
                    d="M94.993 104.594H74.48v1.905h20.512v-1.905zM110.231 111.182h-35.75v1.904h35.75v-1.904zM110.196 117.771H74.481v1.905h35.715v-1.905z"
                ></path>
                <path
                    fill="#CCC"
                    d="M-36.709 227H74.203a.29.29 0 00.29-.291.293.293 0 00-.29-.291H-36.71a.293.293 0 00-.206.497.29.29 0 00.206.085z"
                ></path>
                <path
                    fill="#E6E6E6"
                    d="M16.5 101.748l.438-2.192s6.448-2.192 7.17 2.192H16.5z"
                ></path>
                <path
                    fill="#9F616A"
                    d="M28.876 222.434l4.227-.001 2.011-16.304h-6.239v16.305z"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M27.797 221.053h8.325a5.305 5.305 0 015.305 5.305v.172l-13.63.001v-5.478z"
                ></path>
                <path
                    fill="#9F616A"
                    d="M8.497 222.434l4.227-.001 2.011-16.304H8.496l.001 16.305z"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M7.418 221.053h8.325a5.305 5.305 0 015.305 5.305v.172l-13.63.001v-5.478zM10.363 110.954l-4.822-.438-3.068 14.466.438 13.151 4.822 17.535 6.576-3.946-3.507-17.973-.439-22.795z"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M7.31 154.132l1.08 9.206 1.013 21.48-2.329 32.009 8.33-.446 4.081-33.316 2.494-15.343 3.506 17.534 2.192 32.878 8.549-.685v-32.308l.219-23.556-3.069-9.206-26.066 1.753z"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M25.267 102.187s-3.07-4.384-8.768-1.316l-2.63 3.507-8.33 6.138 1.754 20.603-2.191 25.864 31.124-.439-5.26-25.864v-27.617l-5.7-.876z"
                ></path>
                <path
                    fill="#000"
                    d="M8.392 117.791L5.88 147.845l3.17-16.288-.657-13.766z"
                    opacity="0.25"
                ></path>
                <path
                    fill="#9F616A"
                    d="M60.71 142.989a3.227 3.227 0 01-2.72-.299 3.232 3.232 0 01-1.517-2.276l-7.172-1.773.014-4.621 10.094 2.645a3.249 3.249 0 013.459 2.584 3.251 3.251 0 01-2.158 3.74z"
                ></path>
                <path
                    fill="#E6E6E6"
                    d="M53.21 133.964l-1.206 6.909 3.023.528 1.206-6.91-3.023-.527z"
                ></path>
                <path
                    fill="#9F616A"
                    d="M24.614 96.044a8.803 8.803 0 10-8.445-15.448 8.803 8.803 0 008.445 15.448z"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M21.049 96.084A8.93 8.93 0 0115.207 79.3c1.547-.69 3.24-.994 4.93-.885 2.633.139 5.192 1.112 7.827 1.04l.382.614 1.44-.349.308 1.143.38-.013c.678 1.262-.415 3.07-1.848 3.054-.567-.006-1.104-.243-1.659-.362-.555-.119-1.208-.089-1.59.33-.498.545-.281 1.395-.178 2.125.104.73-.155 1.714-.892 1.738-.377.012-.735-.258-1.105-.187-.43.082-.649.603-.586 1.037.107.426.28.833.516 1.204 1.068 2.133.166 5.034-1.924 6.185M28.874 104.756l2.092-1.693s4.223-.294 4.146 4.183l6.757 21.135 12.962 4.592-1.746 9.024-18.34-6.986-9.315-15.44 3.444-14.815z"
                ></path>
            </g>
            <defs>
                <clipPath id="clip0_1655_11377">
                    <path fill="#fff" d="M0 0H177V227H0z"></path>
                </clipPath>
            </defs>
        </svg>
    )
}

export default VerifyOtpIcon
