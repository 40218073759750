import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import S3FileUpload from 'react-s3'
import PhoneInput from 'react-phone-input-2'

// antd
import {
    Card,
    Col,
    Form,
    Image,
    Input,
    Row,
    Typography,
    Upload,
    theme,
    message,
} from 'antd'

// slices
import { loginState, numberCheck } from '../../login/login.slice'
import { inquiryState, switchInquiryDrawer } from '../inquiries.slice'
import { workSpaceAdd } from '../../workSpace/workSpace.slice'

// components
import Button from '../../../components/button/button'

// assets
import { UploadImageIcon } from '../../../assets/icons'

// constants
import { PHONE_COUNTRIES } from '../../../constants/phone-countries'

import dayjs from 'dayjs'

const AddClientModal = () => {
    const { useToken } = theme

    const { token } = useToken()

    const { Title, Text } = Typography

    const [form] = Form.useForm()

    const dispatch = useDispatch()

    const { userProfile } = useSelector(loginState)
    const { selectedInquiry, drawerVariations } = useSelector(inquiryState)

    const [phoneValid, setPhoneValid] = useState(false)
    const [phoneNumber, setPhoneNumber] = useState({
        phoneNo: null,
        code: null,
    })
    const [fileURL, setFileURL] = useState([])
    const [buttonLoading, setButtonLoading] = useState(false)

    // CHANGE PHONE NUMBER
    const handleChangePhoneNumber = (number, country) => {
        const phoneNo = number.replace(country.dialCode, '')
        setPhoneNumber({
            default: number,
            phoneNo,
            code: `+${country.dialCode}`,
            flag: country.countryCode.toUpperCase(),
        })
    }

    const onFinishFailed = () => {}

    const onFinishClient = async (value) => {
        setButtonLoading(true)

        const resultNumber = await dispatch(
            numberCheck({
                countryCode: phoneNumber?.code,
                phone: phoneNumber?.phoneNo,
            })
        )

        const dataNumber = resultNumber?.payload?.data
        if (dataNumber) {
            const {
                success: successNumber,
                isDuplicate,
                message: successNumberMessage,
            } = dataNumber
            if (!successNumber && isDuplicate) {
                setPhoneValid(false)
                form.setFieldValue('phone', '+91')
                message.info(successNumberMessage)
                setPhoneNumber({
                    ...phoneNumber,
                    phoneNo: null,
                    code: null,
                })
                setButtonLoading(false)

                return
            }
        }

        const result = await dispatch(
            workSpaceAdd({
                addData: {
                    ...value,
                    logo: fileURL?.length > 0 ? fileURL[0] : '',
                },
            })
        )
        const data = result?.payload?.data
        if (data) {
            const { success, message: checkMessage } = data
            if (success) {
                setButtonLoading(false)
                dispatch(
                    switchInquiryDrawer({
                        ...drawerVariations,
                        clientModal: false,
                    })
                )
                message.success(checkMessage)
            } else {
                setButtonLoading(false)
                dispatch(
                    switchInquiryDrawer({
                        ...drawerVariations,
                        clientModal: false,
                    })
                )
                if (checkMessage) {
                    message.error(checkMessage)
                } else {
                    message.error('Something went wrong, try again later.')
                }
            }
        }
    }

    // ONCHANGE FILE PROPS
    const props = {
        name: 'logo',
        multiple: false,
        onChange(info) {
            // setStream(true)
            const isLt5M = info?.file.size / 1024 / 1024 < 5
            if (!isLt5M) {
                message.error('Profile picture must be smaller than 5MB')
                return
            } else {
                const nameChange = info?.fileList[0]?.name
                    .split('.')[0]
                    .concat(`_${dayjs(new Date()).unix()}`)
                    .concat(`.${info?.fileList[0]?.name.split('.')[1]}`)

                const newFileData = new File(
                    [info?.fileList[0]?.originFileObj],
                    nameChange,
                    { type: info?.fileList[0]?.type }
                )
                window.Buffer = window.Buffer || require('buffer').Buffer

                S3FileUpload.uploadFile(newFileData, userProfile?.s3Config)
                    .then(async (data) => {
                        // fileUpload.push(data?.location)
                        setFileURL([data?.location])
                    })
                    .catch(() => {
                        message.error('Upload Failed!. Please Upload again')
                    })
            }
            // info?.preventDefault()
        },
        beforeUpload() {
            return false
        },
        onDrop(e) {},
    }

    return (
        <>
            <Row align={'middle'} justify={'center'}>
                <Title level={3}>Add Client</Title>
            </Row>
            <Form
                labelCol={{
                    style: {
                        padding: '0 0 3px',
                    },
                }}
                layout="vertical"
                name="addProfile"
                onFinish={onFinishClient}
                onFinishFailed={onFinishFailed}
                form={form}
                requiredMark={false}
                style={{
                    overflowX: 'hidden',
                    marginTop: '38px',
                }}
            >
                <Row gutter={30}>
                    <Col span={9}>
                        <Card
                            style={{
                                borderRadius: '16px',
                                height: '100%',
                            }}
                            styles={{
                                body: {
                                    padding: '30px 44px',
                                },
                            }}
                        >
                            <Form.Item
                                name="logo"
                                rootClassName="imageUpload"
                                rules={[
                                    {
                                        validator: async () => {
                                            if (fileURL?.length === 0) {
                                                return Promise.reject(
                                                    new Error(
                                                        `Please Upload photo`
                                                    )
                                                )
                                            } else {
                                                return
                                            }
                                        },
                                    },
                                ]}
                                help={fileURL?.length === 0 ? null : ''}
                            >
                                <Upload
                                    listType="picture-circle"
                                    className="avatar-uploader"
                                    {...props}
                                    fileList={[]}
                                    showUploadList={false}
                                    onPreview={() => {}}
                                    accept=".png,.jpg,.jpeg,.svg"
                                    style={{
                                        height: '128px !important',
                                        width: '128px !important',
                                    }}
                                >
                                    {fileURL?.length > 0 ? (
                                        <Image
                                            src={fileURL[0]}
                                            preview={false}
                                            style={{
                                                height: '128px',
                                                width: '128px',
                                                borderRadius: '50%',
                                            }}
                                        />
                                    ) : selectedInquiry ? (
                                        <div className={'hoverLayer'}>
                                            <div className={'hoverShow'}>
                                                <UploadImageIcon />
                                                <div
                                                    style={{
                                                        marginTop: 8,
                                                        color: token
                                                            .colorPalette
                                                            .baseColor.white,
                                                    }}
                                                >
                                                    Update logo
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <UploadImageIcon
                                                color={
                                                    token.colorPalette.textColor
                                                        .senary
                                                }
                                            />
                                            <div
                                                style={{
                                                    marginTop: 8,
                                                    color: token.colorPalette
                                                        .textColor.senary,
                                                }}
                                            >
                                                Upload photo
                                            </div>
                                        </div>
                                    )}
                                </Upload>
                                <Row
                                    style={{
                                        marginTop: '10px',
                                    }}
                                >
                                    <Text
                                        className="titleSecondary"
                                        style={{
                                            fontSize: token.fontSizeIcon,
                                            textAlign: 'center',
                                        }}
                                    >
                                        Allowed *.jpeg, *.jpg, *.png, Max size
                                        of 5 MB
                                    </Text>
                                </Row>
                            </Form.Item>
                        </Card>
                    </Col>
                    <Col span={15}>
                        <Row
                            gutter={20}
                            style={{
                                rowGap: '15px',
                            }}
                        >
                            <Col span={24}>
                                <Form.Item
                                    name={'companyName'}
                                    label="Company Name*"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Please Enter Company Name',
                                        },
                                        {
                                            max: 25,
                                            message:
                                                'Name is maximum 25 characters long.',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Enter Name" />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name={'companyEmail'}
                                    label="Company E-Mail*"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please Enter Mail',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Enter Mail" />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name={'domain'}
                                    label="Domain Name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please Enter Domain Name',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Enter Domain Name" />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label="Phone Number*"
                                    name="phone"
                                    rules={[
                                        {
                                            validator: async () => {
                                                if (
                                                    !phoneValid &&
                                                    phoneNumber?.phoneNo
                                                        ?.length > 0
                                                ) {
                                                    return Promise.reject(
                                                        new Error(
                                                            'Please Enter a valid Phone Number'
                                                        )
                                                    )
                                                }
                                                if (
                                                    phoneNumber?.phoneNo
                                                        ?.length === 0 ||
                                                    phoneNumber?.phoneNo ===
                                                        null
                                                ) {
                                                    return Promise.reject(
                                                        new Error(
                                                            'Please Enter Phone Number'
                                                        )
                                                    )
                                                }
                                            },
                                        },
                                    ]}
                                    colon={false}
                                >
                                    <PhoneInput
                                        inputProps={{
                                            name: 'phone',
                                            required: true,
                                        }}
                                        specialLabel=""
                                        onlyCountries={['in', 'au', 'us', 'gb']}
                                        country={'in'}
                                        inputStyle={{
                                            height: '50px',
                                            width: '100%',
                                        }}
                                        value={phoneNumber?.default ?? null}
                                        onChange={(number, country) => {
                                            handleChangePhoneNumber(
                                                number,
                                                country
                                            )
                                            const correspondingCountry =
                                                PHONE_COUNTRIES.find(
                                                    (countryName) =>
                                                        countryName.iso2 ===
                                                        country.countryCode?.toUpperCase()
                                                )
                                            let numberNew = `+${number}`
                                            if (
                                                correspondingCountry &&
                                                number &&
                                                correspondingCountry?.validation.test(
                                                    numberNew
                                                )
                                            ) {
                                                setPhoneValid(true)
                                            } else {
                                                setPhoneValid(false)
                                            }
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row
                    style={{
                        float: 'right',
                        marginTop: '35px',
                    }}
                >
                    <Button
                        props={{
                            htmlType: 'submit',
                            text: 'Add',
                            loadingButton: buttonLoading,
                            style: {
                                borderRadius: '60px',
                                width: '130px',
                            },
                        }}
                    />
                </Row>
            </Form>
        </>
    )
}

export default AddClientModal
