import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// antd
import { Modal, Spin, theme } from 'antd'

// slices
import { loginState } from '../login/login.slice'
import {
    onClickPreview,
    setCalendarDate,
    standUpsState,
    switchFormDrawer,
} from './standups.slice'
import { profileState, switchVideoPlayModal } from '../profile/profile.slice'

// components
import PanelHeader from '../../components/panelHeader/panelHeader'
import ModalProfile from '../../components/modal'

// helpers
import { addPermissionCheck } from '../../helpers/permissionCheck'

// assets
import { VideosIcon } from '../../assets/icons'

// standUp Components
import StandUpFormDrawer from './standUpFormDrawer'
import StandUpsList from './standUpsList'
import StandUpsCalendar from './standUpsCalendar'
import AssigneeFilter from './assigneeFilter'
import PreviewStandupVideo from './previewStandupVideo'

// modal
import ViewVideoModal from '../profile/summary/viewVideoModal'

const StandUps = () => {
    const { userProfile } = useSelector(loginState)
    const {
        status,
        previewModal,
        recordings,
        files,
        selectedFeed,
        currentCalendarDate,
    } = useSelector(standUpsState)
    const { videoPlayModal } = useSelector(profileState)

    const { useToken } = theme

    const { token } = useToken()

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setCalendarDate(new Date()))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <Modal
                width="fit-content"
                footer={null}
                closable={false}
                centered
                destroyOnClose={true}
                open={previewModal}
                title={null}
                className="standUpModal"
                onCancel={() => {
                    dispatch(onClickPreview({ face: '', screen: '' }))
                    const id = document.getElementById('videoScreen')
                    id.pause()
                }}
            >
                <PreviewStandupVideo
                    data={recordings}
                    selectedData={selectedFeed}
                    files={files?.length > 0 ? files : []}
                />
            </Modal>
            <StandUpFormDrawer />
            <div
                className={'container'}
                style={{
                    zIndex: 10,
                    minHeight: '100vh',
                    padding: '35px',
                }}
            >
                <PanelHeader
                    props={{
                        panelName: 'post standup',
                        searchBar: false,
                        filterBar: false,
                        teamMembersBar: false,
                        showCalendar: true,
                        calendarShowComponent: () => <StandUpsCalendar />,
                        icon: false,
                        showProject: false,
                        assigneeFilter: true,
                        assigneeFilterComponent: () => <AssigneeFilter />,
                        permissionAdd:
                            addPermissionCheck('standups', userProfile) &&
                            new Date(currentCalendarDate).setHours(
                                0,
                                0,
                                0,
                                0
                            ) >= new Date().setHours(0, 0, 0, 0),
                        onClick: () => {
                            dispatch(switchFormDrawer({ open: true }))
                        },
                    }}
                />
                <Spin spinning={status === 'loading' ? true : false}>
                    <StandUpsList />
                </Spin>
            </div>
            <ModalProfile
                profileModalState={videoPlayModal}
                visibility={videoPlayModal?.open}
                handleCancel={() => {
                    dispatch(
                        switchVideoPlayModal({
                            delete: false,
                            videoData: {
                                deleteId: '',
                                link: '',
                            },
                            open: false,
                            title: '',
                        })
                    )
                }}
                footer={false}
                modalKey="viewVideo"
                modalTitle={'Standup Video'}
                showTitle={'Standup Video'}
                icon={<VideosIcon color={token.colorPalette.baseColor.white} />}
                content={<ViewVideoModal content={videoPlayModal} />}
            />
        </>
    )
}

export default StandUps
