import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import S3FileUpload from 'react-s3'
import { debounce } from 'lodash'

// antd
import {
    Avatar,
    Card,
    Col,
    Empty,
    Form,
    Image,
    Input,
    message,
    Row,
    Select,
    theme,
    Typography,
    Upload,
    Divider,
    Space,
    Spin,
} from 'antd'
import { PlusOutlined } from '@ant-design/icons'

// slices
import {
    changeModel,
    checkButtonType,
    masterDataAddDropDown,
    masterDataDropDownList,
    profileState,
} from '../profile/profile.slice'
import { emailCheck, loginState, numberCheck } from '../login/login.slice'
import {
    addTalent,
    switchTalentModal,
    talentListGet,
    talentState,
    totalTalent,
} from './talent.slice'
import { changeRecruitTab } from '../recruit/recruit.slice'

// assets
import { UploadImageIcon } from '../../assets/icons'

// constants
import { PHONE_COUNTRIES } from '../../constants/phone-countries'
import { EMAIL_PATTERN } from '../../constants/patterns'
import { MASTER_DATA_FIELD } from '../../constants/masterData'
import {
    RECRUIT_FILTER_STATUS,
    TALENT_FILTER_STATUS,
} from '../../constants/general'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc' // Import the utc plugin for time zone conversion
import timezone from 'dayjs/plugin/timezone' // Import the timezone plugin for time zone support

dayjs.extend(utc) // Extend dayjs with the utc plugin
dayjs.extend(timezone)

function TalentModalContent({ refreshTalent, page }) {
    const { Text } = Typography

    const { Option } = Select

    const { useToken } = theme
    const { token } = useToken()

    const dispatch = useDispatch()

    // SELECTORS
    const {
        formButtonType,
        masterDataDropDown,
        masterDataDropDownStatus,
        modelType,
    } = useSelector(profileState)
    const { userProfile } = useSelector(loginState)
    const { modalVariations, pageLimit, filter } = useSelector(talentState)

    const [form] = Form.useForm()

    const timeoutRef = useRef()
    const sessionTokenRef = useRef()

    // STATES
    const [phoneNumber, setPhoneNumber] = useState({
        phoneNo: null,
        code: null,
    })
    const [phoneValid, setPhoneValid] = useState(false)
    const [suggestions, setSuggestions] = useState([])
    const [fileURL, setFileURL] = useState([])
    const [searchValue, setSearchValue] = useState('')
    const [timeZoneChange, setTimeZoneChange] = useState('')
    const [timezones, setTimezones] = useState([])

    // useEffect(() => {
    //     var aryIanaTimeZones = Intl.supportedValuesOf('timeZone')

    //     let newData = []
    //     aryIanaTimeZones.map((timeZone) => {
    //         const timeZoneOffset = new Date().toLocaleTimeString('en-US', {
    //             timeZoneName: 'longOffset',
    //             timeZone,
    //         })
    //         const gmtOffset = timeZoneOffset.split(' ')[2]

    //         newData.push({
    //             label: `${timeZone}`,
    //             value: `${timeZone}`,
    //             offset: gmtOffset,
    //         })
    //     })
    //     setTimezones(newData)
    // }, [])

    // console.log(timezones)

    useEffect(() => {
        const aryIanaTimeZones = Intl.supportedValuesOf('timeZone')

        const newData = aryIanaTimeZones.map((timeZone) => {
            const date = new Date()
            const formatter = new Intl.DateTimeFormat('en-US', {
                timeZone,
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                timeZoneName: 'shortOffset',
                hour12: false,
            })

            const parts = formatter.formatToParts(date)
            const timeZoneOffset = parts.find(
                (part) => part.type === 'timeZoneName'
            ).value

            return {
                label: `${timeZone}`,
                value: `UTC${timeZoneOffset.replace('GMT', '')} ${timeZone}`,
                offset: `UTC${timeZoneOffset.replace('GMT', '')}`,
            }
        })

        setTimezones(newData)
    }, [])

    // CHECK EMAIL ID
    async function checkEmailId(value) {
        const result = await dispatch(
            emailCheck({
                email: value,
            })
        )

        const data = result?.payload?.data
        if (data) {
            const { success, isDuplicate } = data
            if (success) {
                if (!isDuplicate) {
                    return true
                }
            } else if (!success) {
                if (isDuplicate) {
                    form.setFieldValue('email', '')
                    message.info('Email Id should be unique')
                    dispatch(
                        switchTalentModal({
                            ...modalVariations,
                            saveButtonLoading: false,
                        })
                    )
                    return false
                } else {
                    dispatch(
                        switchTalentModal({
                            ...modalVariations,
                            saveButtonLoading: false,
                        })
                    )
                    message.error(data?.message)
                    return false
                }
            }
        }
    }

    // ADD MASTER DATA
    async function addMasterData() {
        const result = await dispatch(
            masterDataAddDropDown({
                addData: {
                    type: 'manual',
                    data: {
                        name: searchValue,
                        logo: '',
                        createdBy: userProfile?._id,
                    },
                },
                model: modelType,
            })
        )
        const data = result?.payload?.data

        if (data) {
            const { success, message: errorMessage } = data
            if (success) {
                dispatch(
                    masterDataDropDownList({
                        search: searchValue,
                        id: modelType,
                    })
                )
            } else {
                if (errorMessage) {
                    message.error(errorMessage)
                } else {
                    message.error('Something went wrong, try again later.')
                }
            }
        }
    }

    // SEARCH MASTER DATA
    const searchFn = debounce((value, model) => {
        if (value?.length > 0) {
            dispatch(changeModel(model))
            dispatch(
                masterDataDropDownList({
                    search: value,
                    id: model,
                })
            )
        }
        setSearchValue(value)
    }, 800)

    // ONCHANGE FILE PROPS
    const props = {
        name: 'profilePicture',
        multiple: false,
        onChange(info) {
            const isLt5M = info?.file.size / 1024 / 1024 < 5
            if (!isLt5M) {
                message.error('Profile picture must be smaller than 5MB')
                return
            } else {
                const nameChange = info?.fileList[0]?.name
                    .split('.')[0]
                    .concat(`_${dayjs(new Date()).unix()}`)
                    .concat(`.${info?.fileList[0]?.name.split('.')[1]}`)

                const newFileData = new File(
                    [info?.fileList[0]?.originFileObj],
                    nameChange,
                    { type: info?.fileList[0]?.type }
                )
                window.Buffer = window.Buffer || require('buffer').Buffer

                S3FileUpload.uploadFile(newFileData, userProfile?.s3Config)
                    .then(async (data) => {
                        setFileURL([data?.location])
                    })
                    .catch(() => {
                        message.error('Upload Failed!. Please Upload again')
                    })
            }
            // info?.preventDefault()
        },

        beforeUpload() {
            return false
        },

        onDrop(e) {},
    }

    function onFinishFailed() {}

    // CHECK MOBILE NUMBER
    async function checkMobileNumber() {
        const result = await dispatch(
            numberCheck({
                countryCode: phoneNumber?.code,
                phone: phoneNumber?.phoneNo,
            })
        )

        const data = result?.payload?.data
        if (data) {
            const { success, isDuplicate } = data
            if (success) {
                if (!isDuplicate) {
                    return true
                }
            } else if (!success) {
                if (isDuplicate) {
                    setPhoneValid(false)
                    form.setFieldValue('phone', '+91')
                    setPhoneNumber({
                        ...phoneNumber,
                        phoneNo: null,
                        code: null,
                    })
                    dispatch(
                        switchTalentModal({
                            ...modalVariations,
                            saveButtonLoading: false,
                        })
                    )
                    message.info('Phone Number should be unique')
                    return false
                } else {
                    dispatch(
                        switchTalentModal({
                            ...modalVariations,
                            saveButtonLoading: false,
                        })
                    )
                    message.error(data?.message)
                    return false
                }
            }
        }
    }

    // CHANGE PHONE NUMBER
    const handleChangePhoneNumber = (number, country) => {
        const phoneNo = number.replace(country.dialCode, '')
        setPhoneNumber({
            phoneNo,
            code: `+${country.dialCode}`,
        })
    }

    // GOOGLE PLACE API INTEGRATION
    const loadSuggestions = async (inputValue) => {
        clearTimeout(timeoutRef.current)

        if (!inputValue || inputValue.trim().length <= 1) {
            setSuggestions([])
            return
        }

        timeoutRef.current = setTimeout(async () => {
            if (!sessionTokenRef.current) {
                sessionTokenRef.current =
                    new window.google.maps.places.AutocompleteSessionToken()
            }

            const placesService = new window.google.maps.places.PlacesService(
                document.createElement('div')
            )
            new window.google.maps.places.AutocompleteService().getPlacePredictions(
                {
                    input: inputValue,
                    sessionToken: sessionTokenRef.current,
                },
                async (predictions, status) => {
                    if (
                        status ===
                        window.google.maps.places.PlacesServiceStatus
                            .ZERO_RESULTS
                    ) {
                        setSuggestions([])
                        return
                    }
                    if (
                        status !==
                            window.google.maps.places.PlacesServiceStatus.OK ||
                        !predictions
                    ) {
                        return
                    }

                    const newData = []

                    for (const predictionData of predictions) {
                        const placeId = predictionData?.place_id

                        const placeData = await new Promise(
                            (resolve, reject) => {
                                placesService.getDetails(
                                    { placeId, fields: ['geometry'] },
                                    async (place, status) => {
                                        if (
                                            status ===
                                                window.google.maps.places
                                                    .PlacesServiceStatus.OK &&
                                            place &&
                                            place.geometry &&
                                            place.geometry.location
                                        ) {
                                            const location =
                                                place.geometry.location

                                            try {
                                                const response = await fetch(
                                                    `https://maps.googleapis.com/maps/api/timezone/json?location=${location.lat()},${location.lng()}&timestamp=${Math.floor(
                                                        Date.now() / 1000
                                                    )}&key=AIzaSyC79hoAq6x6PIprpy39EPylaZ6i6ZVEsD4`
                                                )

                                                if (!response.ok) {
                                                    throw new Error(
                                                        `HTTP error! Status: ${response.status}`
                                                    )
                                                }

                                                const data =
                                                    await response.json()
                                                const timeZoneId =
                                                    data.timeZoneId

                                                resolve({
                                                    label: predictionData?.description,
                                                    value: predictionData?.description,
                                                    timeZone: timeZoneId,
                                                })
                                            } catch (error) {
                                                console.error(
                                                    'Error fetching timezone data:',
                                                    error
                                                )
                                                reject(error)
                                            }
                                        } else {
                                            console.error(
                                                'Error getting place details:',
                                                status
                                            )
                                            reject(status)
                                        }
                                    }
                                )
                            }
                        )
                        newData.push(placeData)
                    }

                    setSuggestions(newData)
                }
            )
        }, 350)
    }

    // FINISH PROFILE EDIT
    async function finishTalentAdd(value) {
        dispatch(
            switchTalentModal({
                ...modalVariations,
                saveButtonLoading: true,
            })
        )

        const payload = {
            ...value,
            timeZone: timeZoneChange,
            createdBy: userProfile?._id,
            phone: phoneNumber?.phoneNo,
            countryCode: phoneNumber?.code,
            isTalentUser: true,
            designation:
                typeof value.designation === 'object'
                    ? value.designation[0]?.value
                    : value.designation,
            profilePicture: fileURL?.length > 0 ? fileURL[0] : '',
            status:
                page === 'recruit'
                    ? RECRUIT_FILTER_STATUS
                    : TALENT_FILTER_STATUS,
        }

        let checkMobile = await checkMobileNumber()

        let checkEmail = await checkEmailId(value?.email)

        if (checkMobile && checkEmail) {
            const result = await dispatch(addTalent(payload))
            const data = result?.payload?.data

            if (data) {
                const { success, message: errorMessage } = data
                if (success) {
                    refreshTalent()
                    dispatch(
                        totalTalent({
                            filter: {
                                ...filter,
                                status:
                                    page === 'recruit'
                                        ? RECRUIT_FILTER_STATUS
                                        : TALENT_FILTER_STATUS,
                            },
                        })
                    )
                    dispatch(
                        talentListGet({
                            limit: pageLimit,
                            skip: 0,
                            filter: {
                                ...filter,
                                status:
                                    page === 'recruit'
                                        ? RECRUIT_FILTER_STATUS
                                        : TALENT_FILTER_STATUS,
                            },
                        })
                    )
                    dispatch(changeRecruitTab(RECRUIT_FILTER_STATUS))
                    dispatch(
                        switchTalentModal({
                            ...modalVariations,
                            saveButtonLoading: false,
                            open: false,
                        })
                    )
                    setTimeZoneChange('')
                    message.success(errorMessage)
                } else {
                    dispatch(
                        switchTalentModal({
                            ...modalVariations,
                            saveButtonLoading: false,
                            open: false,
                        })
                    )
                    setTimeZoneChange('')
                    if (errorMessage) {
                        if (typeof errorMessage === 'object') {
                            for (const key in errorMessage) {
                                message.error(errorMessage[key])
                            }
                        } else {
                            message.error(errorMessage)
                        }
                    } else {
                        message.error('Something went wrong, try again later.')
                    }
                }
            }
        } else {
        }
    }

    return (
        <>
            <Form
                labelCol={{
                    style: {
                        padding: '0 0 3px',
                    },
                }}
                layout="vertical"
                name="talentDataForm"
                onFinish={finishTalentAdd}
                onFinishFailed={onFinishFailed}
                onSubmitCapture={async (e) => {
                    await dispatch(checkButtonType(e.nativeEvent.submitter.id))
                }}
                form={form}
                requiredMark={false}
                style={{
                    overflowX: 'hidden',
                }}
            >
                <Row gutter={30}>
                    <Col span={9}>
                        <Card
                            style={{
                                borderRadius: '16px',
                                height: '100%',
                            }}
                            styles={{
                                body: {
                                    padding: '30px 44px',
                                },
                            }}
                        >
                            <Form.Item
                                name="profilePicture"
                                rootClassName="imageUpload"
                                rules={[
                                    {
                                        validator: async () => {
                                            if (fileURL?.length === 0) {
                                                return Promise.reject(
                                                    new Error(
                                                        'Please Upload your Profile Picture'
                                                    )
                                                )
                                            } else {
                                                return
                                            }
                                        },
                                    },
                                ]}
                                help={fileURL?.length === 0 ? null : ''}
                            >
                                <Upload
                                    listType="picture-circle"
                                    className="avatar-uploader"
                                    {...props}
                                    fileList={[]}
                                    showUploadList={false}
                                    onPreview={() => {}}
                                    accept=".png,.jpg,.jpeg"
                                    style={{
                                        height: '128px !important',
                                        width: '128px !important',
                                    }}
                                >
                                    {fileURL?.length > 0 ? (
                                        <Image
                                            src={fileURL[0]}
                                            preview={false}
                                            style={{
                                                height: '128px',
                                                width: '128px',
                                                borderRadius: '50%',
                                            }}
                                        />
                                    ) : (
                                        <>
                                            <Avatar
                                                style={{
                                                    color: token.colorPalette
                                                        .baseColor.white,
                                                }}
                                                className={'uploadAvatar'}
                                                size="large"
                                            >
                                                {userProfile?.name[0].toUpperCase()}
                                                {userProfile?.name.split(
                                                    ' '
                                                )[1] !== undefined &&
                                                    userProfile?.name
                                                        .split(' ')[1][0]
                                                        .toUpperCase()}
                                            </Avatar>
                                        </>
                                    )}
                                    <div className={'hoverLayer'}>
                                        <div className={'hoverShow'}>
                                            <UploadImageIcon />
                                            <div
                                                style={{
                                                    marginTop: 8,
                                                    color: token.colorPalette
                                                        .baseColor.white,
                                                }}
                                            >
                                                Update logo
                                            </div>
                                        </div>
                                    </div>
                                </Upload>
                                <Row
                                    style={{
                                        marginTop: '10px',
                                    }}
                                >
                                    <Text
                                        className="titleSecondary"
                                        style={{
                                            fontSize: token.fontSizeIcon,
                                            textAlign: 'center',
                                        }}
                                    >
                                        Allowed *.jpeg, *.jpg, *.png, Max size
                                        of 5 MB
                                    </Text>
                                    {/* <Button
                                        props={{
                                            text: 'Random',
                                            onClick: () => {
                                                setRandomAvatar(true)
                                            },
                                        }}
                                    /> */}
                                </Row>
                            </Form.Item>
                        </Card>
                    </Col>
                    <Col span={15}>
                        <Row
                            gutter={20}
                            style={{
                                rowGap: '15px',
                            }}
                        >
                            <Col span={12}>
                                <Form.Item
                                    name={'name'}
                                    label="Name*"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please Enter Name',
                                            max: 25,
                                        },
                                    ]}
                                >
                                    <Input placeholder="Enter Name" />
                                </Form.Item>
                            </Col>
                            {/* <div style={{ width: '200px' }}>
                                <BigHead {...getRandomOptions()} />
                            </div> */}
                            <Col span={12}>
                                <Form.Item
                                    label="Designation*"
                                    name="designation"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Please Select Designation',
                                        },
                                    ]}
                                    colon={false}
                                >
                                    <Select
                                        placeholder="Search a Designation"
                                        showSearch
                                        onSearch={(value) => {
                                            searchFn(
                                                value,
                                                MASTER_DATA_FIELD.designation
                                            )
                                        }}
                                        filterOption={false}
                                        allowClear={false}
                                        dropdownRender={(menu) => (
                                            <>
                                                {menu}
                                                <Divider
                                                    style={{
                                                        margin: '8px 0',
                                                    }}
                                                />
                                                <Space
                                                    style={{
                                                        padding: '0 8px 4px',
                                                    }}
                                                >
                                                    <Row
                                                        align={'middle'}
                                                        style={{
                                                            columnGap: '5px',
                                                            cursor:
                                                                searchValue?.length >
                                                                0
                                                                    ? 'pointer'
                                                                    : 'not-allowed',
                                                        }}
                                                        onClick={() => {
                                                            if (
                                                                searchValue?.length >
                                                                0
                                                            ) {
                                                                addMasterData()
                                                            }
                                                        }}
                                                    >
                                                        <PlusOutlined
                                                            style={{
                                                                color: token
                                                                    .colorPalette
                                                                    .baseColor
                                                                    .tertiary,
                                                                fontSize:
                                                                    token.fontSizeHeading5,
                                                            }}
                                                        />
                                                        <Text
                                                            style={{
                                                                color: token
                                                                    .colorPalette
                                                                    .baseColor
                                                                    .tertiary,
                                                                fontSize:
                                                                    token.fontSizeHeading5,
                                                                fontWeight:
                                                                    token.fontWeightStrong,
                                                            }}
                                                        >
                                                            Add
                                                        </Text>
                                                    </Row>
                                                </Space>
                                            </>
                                        )}
                                    >
                                        {masterDataDropDown?.designation
                                            ?.length > 0 &&
                                        masterDataDropDownStatus === 'loaded'
                                            ? masterDataDropDown?.designation.map(
                                                  (option) => {
                                                      return (
                                                          <Option
                                                              key={
                                                                  option?.value
                                                              }
                                                              value={
                                                                  option?.value
                                                              }
                                                          >
                                                              <Text>
                                                                  {
                                                                      option?.label
                                                                  }
                                                              </Text>
                                                          </Option>
                                                      )
                                                  }
                                              )
                                            : masterDataDropDownStatus ===
                                                  'loading' && <Spin></Spin>}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name={'email'}
                                    label="Email ID*"
                                    rules={
                                        formButtonType !==
                                            'draftButtonLoading' && [
                                            {
                                                validator: async (_, value) => {
                                                    const pattern =
                                                        EMAIL_PATTERN

                                                    if (
                                                        value?.length === 0 ||
                                                        value === undefined
                                                    ) {
                                                        return Promise.reject(
                                                            new Error(
                                                                'Please Enter Email'
                                                            )
                                                        )
                                                    }
                                                    if (
                                                        !pattern.test(value) &&
                                                        value?.length > 0
                                                    ) {
                                                        return Promise.reject(
                                                            new Error(
                                                                'Please Enter a valid Email Id'
                                                            )
                                                        )
                                                    } else {
                                                    }
                                                },
                                            },
                                        ]
                                    }
                                >
                                    <Input
                                        placeholder="Enter Email ID"
                                        // onBlur={(e) => {
                                        //     if (emailValid) {
                                        //         checkEmailId(e.target.value)
                                        //     }
                                        // }}
                                        onChange={() => {}}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label="Mobile Number*"
                                    name="phone"
                                    rules={[
                                        {
                                            validator: async () => {
                                                if (
                                                    !phoneValid &&
                                                    phoneNumber?.phoneNo
                                                        ?.length > 0
                                                ) {
                                                    return Promise.reject(
                                                        new Error(
                                                            'Please Enter a valid Phone Number'
                                                        )
                                                    )
                                                }

                                                if (
                                                    phoneNumber?.phoneNo
                                                        ?.length === 0 ||
                                                    phoneNumber?.phoneNo ===
                                                        null
                                                ) {
                                                    return Promise.reject(
                                                        new Error(
                                                            'Please Enter Phone Number'
                                                        )
                                                    )
                                                }
                                            },
                                        },
                                    ]}
                                    colon={false}
                                >
                                    <PhoneInput
                                        inputProps={{
                                            name: 'phone',
                                            required: true,
                                        }}
                                        inputStyle={{
                                            width: '100%',
                                        }}
                                        specialLabel=""
                                        onlyCountries={['in', 'au', 'us', 'gb']}
                                        country={'in'}
                                        // onBlur={() => {
                                        //     if (
                                        //         phoneValid &&
                                        //         phoneNumber?.phoneNo?.length >
                                        //             0 &&
                                        //         phoneNumber?.phoneNo !== null
                                        //     ) {
                                        //         checkMobileNumber()
                                        //     }
                                        // }}
                                        // value={phoneNumber?.phoneNo ?? null}
                                        onChange={(number, country) => {
                                            handleChangePhoneNumber(
                                                number,
                                                country
                                            )
                                            const correspondingCountry =
                                                PHONE_COUNTRIES.find(
                                                    (countryName) =>
                                                        countryName.iso2 ===
                                                        country.countryCode?.toUpperCase()
                                                )
                                            let numberNew = `+${number}`
                                            if (
                                                correspondingCountry &&
                                                number &&
                                                correspondingCountry?.validation.test(
                                                    numberNew
                                                )
                                            ) {
                                                setPhoneValid(true)
                                            } else {
                                                setPhoneValid(false)
                                            }
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label="Timezone*"
                                    name="timeZone"
                                    colon={false}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please Enter Timezone',
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        placeholder="Enter Timezone"
                                    >
                                        {timezones?.length > 0 &&
                                            timezones.map((data) => (
                                                <Option key={data?.value}>
                                                    <Row
                                                        align={'middle'}
                                                        style={{
                                                            columnGap: '8px',
                                                        }}
                                                    >
                                                        <Text>
                                                            ({data?.offset})
                                                        </Text>
                                                        <Text>
                                                            {data?.label}
                                                        </Text>
                                                    </Row>
                                                </Option>
                                            ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label="Location*"
                                    name="location"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please Select Location',
                                        },
                                    ]}
                                    colon={false}
                                >
                                    <Select
                                        placeholder="Search a Location"
                                        allowClear={false}
                                        onSelect={(_, option) =>
                                            setTimeZoneChange(option?.key)
                                        }
                                        notFoundContent={
                                            <Empty
                                                image={
                                                    Empty.PRESENTED_IMAGE_SIMPLE
                                                }
                                                description="No Locations Searched"
                                            ></Empty>
                                        }
                                        onSearch={(value) => {
                                            loadSuggestions(value)
                                        }}
                                        showSearch
                                    >
                                        {suggestions?.length > 0 &&
                                            suggestions.map((option) => (
                                                <Option
                                                    key={option?.timeZone}
                                                    value={option?.label}
                                                >
                                                    <Text>{option?.label}</Text>
                                                    &nbsp;
                                                    <Text>
                                                        ({option?.timeZone})
                                                    </Text>
                                                </Option>
                                            ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

export default TalentModalContent
