import React from 'react'

function FillThumbIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="21"
            fill="none"
            viewBox="0 0 22 21"
        >
            <path
                fill="#4F46E5"
                d="M13.6 8.001H20a2 2 0 012 2v2.105c0 .26-.051.52-.15.761l-3.095 7.515a1 1 0 01-.925.62H1a1 1 0 01-1-1v-10a1 1 0 011-1h3.482a1 1 0 00.817-.424L10.752.852a.5.5 0 01.632-.159l1.814.907a2.5 2.5 0 011.305 2.853L13.6 8.001zm-9.6 3H2v8h2v-8z"
            ></path>
        </svg>
    )
}

export default FillThumbIcon
