import React, { useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'

// antd
import { Button, Image, Layout, Row, Tooltip, Typography, theme } from 'antd'
import { RightOutlined, LeftOutlined } from '@ant-design/icons'

// navigation
import Navigation from '../navigation/navigation'

// components
import HeaderComponent from '../header/header'

// constants
import { DOMAIN_LIST, SUPER_ADMIN } from '../../constants/roles'

// styles
import styles from '../../layouts/layouts.module.css'

const { Sider, Content, Header } = Layout

const SideLayout = ({ userProfile }) => {
    const { Text } = Typography

    const { useToken } = theme
    const { token } = useToken()

    const params = useLocation()
    const [collapsed, setCollapsed] = useState(false)

    return (
        <>
            <Layout>
                <Header
                    style={{
                        backgroundColor: '#fff',
                        position: 'sticky',
                        top: 0,
                        height: 'auto',
                        zIndex: 999,
                        padding: '15px 25px 15px 42px',
                        boxShadow: '0px 4px 15px 0px #00000026',
                    }}
                >
                    <HeaderComponent />
                </Header>
                <Layout>
                    {((DOMAIN_LIST.includes(
                        userProfile?.currentWorkSpace?.domain
                    ) &&
                        userProfile?.role?.roleName === SUPER_ADMIN) ||
                        !DOMAIN_LIST.includes(
                            userProfile?.currentWorkSpace?.domain
                        )) && (
                        <Sider
                            width={245}
                            trigger={null}
                            // onCollapse={(value) => setCollapsed(value)}
                            collapsible
                            collapsed={collapsed}
                            style={{
                                position: 'relative',
                                display:
                                    params.pathname.includes('profile') ||
                                    params.pathname.includes(
                                        'global-settings'
                                    ) ||
                                    params.pathname.includes('projects') ||
                                    params.pathname.includes('users') ||
                                    params.pathname.includes('reports') ||
                                    (params.pathname.includes('inquiries') &&
                                        !DOMAIN_LIST.includes(
                                            userProfile?.currentWorkSpace
                                                ?.domain
                                        ))
                                        ? 'none'
                                        : 'block',
                            }}
                            className={
                                collapsed
                                    ? 'collapseSideLayout'
                                    : 'openSideLayout'
                            }
                        >
                            <Tooltip
                                title={collapsed ? 'Expand' : 'Collapse'}
                                arrow={false}
                                placement="right"
                            >
                                <div className={styles.collapseButton}>
                                    <Button
                                        type="text"
                                        style={{
                                            width: 25,
                                            padding: 0,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            cursor: 'pointer',
                                        }}
                                        icon={
                                            collapsed ? (
                                                <RightOutlined
                                                    style={{
                                                        fontSize: '12px',
                                                        color: '#000',
                                                    }}
                                                />
                                            ) : (
                                                <LeftOutlined
                                                    style={{
                                                        fontSize: '12px',
                                                        color: '#000',
                                                    }}
                                                />
                                            )
                                        }
                                        onClick={() => setCollapsed(!collapsed)}
                                    />
                                </div>
                            </Tooltip>
                            {userProfile?.activeProject !== null &&
                                ((userProfile?.role?.roleName !== SUPER_ADMIN &&
                                    !DOMAIN_LIST.includes(
                                        userProfile?.currentWorkSpace?.domain
                                    )) ||
                                    (userProfile?.role?.roleName ===
                                        SUPER_ADMIN &&
                                        !DOMAIN_LIST.includes(
                                            userProfile?.currentWorkSpace
                                                ?.domain
                                        )) ||
                                    (userProfile?.role?.roleName !==
                                        SUPER_ADMIN &&
                                        DOMAIN_LIST.includes(
                                            userProfile?.currentWorkSpace
                                                ?.domain
                                        ))) &&
                                (!collapsed ? (
                                    <Row
                                        style={{
                                            columnGap: '11px',
                                            padding: '36px 36px 0px 36px',
                                            flexFlow: 'nowrap',
                                        }}
                                        align={'middle'}
                                    >
                                        <Image
                                            src={
                                                userProfile?.activeProject
                                                    ?.projectProfile
                                            }
                                            width={40}
                                            height={40}
                                            preview={false}
                                            style={{
                                                borderRadius: '50%',
                                                minWidth: '40px',
                                            }}
                                        />

                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                rowGap: '2px',
                                            }}
                                        >
                                            <Text
                                                style={{
                                                    color: token.colorPalette
                                                        .baseColor.white,
                                                }}
                                            >
                                                {
                                                    userProfile?.activeProject
                                                        ?.name
                                                }
                                            </Text>
                                            <Text
                                                style={{
                                                    color: token.colorPalette
                                                        .textColor.senary,
                                                }}
                                            >
                                                {
                                                    userProfile?.activeProject
                                                        ?.projectType
                                                }
                                            </Text>
                                        </div>
                                    </Row>
                                ) : (
                                    <div
                                        style={{
                                            padding: '36px 25px 0px 25px',
                                        }}
                                    >
                                        <Image
                                            src={
                                                userProfile?.activeProject
                                                    ?.projectProfile
                                            }
                                            width={30}
                                            height={30}
                                            preview={false}
                                            style={{
                                                borderRadius: '50%',
                                                minWidth: '30px',
                                            }}
                                        />
                                    </div>
                                ))}
                            <div className="mainScrollDiv">
                                <Navigation userProfile={userProfile} />
                            </div>
                        </Sider>
                    )}
                    <Layout
                        style={{
                            margin: '25px',
                            minHeight: '100vh',
                        }}
                    >
                        <Content>
                            <Outlet />
                        </Content>
                    </Layout>
                </Layout>
            </Layout>
        </>
    )
}

export default React.memo(SideLayout)
