import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// antd
import {
    Col,
    Form,
    Modal,
    Row,
    Typography,
    Select,
    DatePicker,
    message,
    theme,
} from 'antd'

// redux
import { timesheetApi } from './redux/apis'
import { reportsActions } from './redux/slice'

// slices
import { projectsState } from '../../projects/projects.slice'
import { teamListGet, teamState } from '../../team/team.slice'

// components
import Button from '../../../components/button/button'
import EditReport from './EditReport'

// helpers
import { unique } from '../../../helpers/uniqueArray'

import dayjs from 'dayjs'

// slices

const DownloadPopUp = ({
    previewModal,
    onCancel,
    modalTitle,
    loadingButton,
}) => {
    const { Title } = Typography

    const { useToken } = theme
    const { token } = useToken()

    const { RangePicker } = DatePicker

    const initialValues = useMemo(() => {
        return {
            projects: [],
            assignee: [],
            rangePicker: [],
            start: '',
            end: '',
        }
    }, [])

    const dispatch = useDispatch()

    const [filteredProjects, setFilteredProjects] = useState([])
    const [filteredAssignees, setFilteredAssignees] = useState([])
    const [value, setValue] = useState([])
    const [values, setValues] = useState(initialValues)
    const [errors, setErrors] = useState(initialValues)
    const [isSubmiting, setSubmiting] = useState(false)
    const [isEditReport, setIsEditReport] = useState(false)

    const pdfData = useSelector((state) => state.reportTimesheet)
    const { projects } = useSelector(projectsState)
    const { pageLimit, teamList } = useSelector(teamState)

    const pdfReportData = pdfData?.pdfData
    const pdfProjectData = pdfData?.pdfSelectedData

    const formatDatePicker = (value) => {
        return `${dayjs(value).format('DD MMM YY')}`
    }

    useEffect(() => {
        if (projects?.length > 0) {
            let filterProjects = []

            const projectsList = projects.map((data) => {
                return {
                    label: data?.name,
                    value: data?._id,
                    projectProfile: data?.projectProfile,
                }
            })
            filterProjects = [...projectsList]

            setFilteredProjects(filterProjects)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projects])

    // CHECK FOR LAZY LOADING IS_NEXT
    useEffect(() => {
        if (teamList?.length > 0) {
            let array = []
            teamList?.length > 0 &&
                teamList.forEach((data) => {
                    array = [
                        ...array,
                        {
                            label: data?.name,
                            value: data?._id,
                        },
                    ]
                })
            let newArray = [...filteredAssignees, ...array]
            let finalArray = unique(newArray, 'value')
            setFilteredAssignees(finalArray)
            // if (!isNext) {
            //     return
            // }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [teamList])

    useEffect(() => {
        if (values?.projects !== '' && previewModal) {
            dispatch(
                teamListGet({
                    limit: pageLimit,
                    skip: 0,
                    projectId: values.projects,
                })
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.projects])

    function updateDetails(name, value) {
        setValues({
            ...values,
            [name]: value,
        })
        setErrors({ ...errors, [name]: '' })
    }

    async function onFinish() {
        let projectName = filteredProjects?.filter(
            (project) => project?.value === values?.projects
        )

        let assigneesId = values?.assignee?.map((v) => v.value)

        setSubmiting(true)
        let start = new Date(values?.start).setHours(0, 0, 0, 0)
        let end = new Date(values?.end).setHours(23, 59, 59, 999)

        const data = {
            projectId: values?.projects,
            start,
            end,
            assigneeId: assigneesId,
        }

        const reportRes = await timesheetApi(data)
        if (reportRes?.data?.success) {
            setSubmiting(false)
            setIsEditReport(true)
            dispatch(
                reportsActions.downloadPdfData({
                    pdfData: reportRes?.data,
                    pdfSelectedData: {
                        assignees: values?.assignee,
                        start,
                        end,
                        project: {
                            id: values?.projects,
                            name: projectName[0]?.label,
                        },
                    },
                })
            )
        } else {
            setSubmiting(false)
            setIsEditReport(false)
            message.error(reportRes?.data?.message)
        }
    }

    function onFinishFailed() {}

    const onCloseModal = () => {
        setValues(initialValues)
        setIsEditReport(false)
        onCancel()
        dispatch(
            reportsActions.downloadPdfData({
                pdfData: null,
                pdfSelectedData: null,
            })
        )
    }

    return (
        <>
            <Modal
                closable={false}
                open={previewModal}
                destroyOnClose={true}
                footer={null}
                onCancel={() => {
                    onCloseModal()
                }}
                width={700}
                confirmLoading={loadingButton}
                title={
                    <Row align={'middle'} style={{ columnGap: 10 }}>
                        <Title level={4} style={{ margin: 0 }}>
                            {modalTitle}
                        </Title>
                    </Row>
                }
            >
                <Form
                    layout="vertical"
                    name="exportReports"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    requiredMark={false}
                    labelCol={{
                        style: {
                            padding: '0 0 3px',
                        },
                    }}
                >
                    <Row gutter={[16, 18]}>
                        <Col span={24}>
                            <Form.Item
                                label="Projects"
                                name="projects"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Select Project',
                                    },
                                ]}
                                colon={false}
                            >
                                <Select
                                    placeholder="Select a Project"
                                    onChange={(event) => {
                                        setValue([])
                                        setValues({ ...values, assignee: [] })
                                        updateDetails('projects', event)
                                    }}
                                    allowClear={false}
                                    showArrow={false}
                                    options={filteredProjects}
                                >
                                    {/* {filteredProjects?.length > 0 &&
                                    filteredProjects.map((data) => (
                                        <Option
                                            value={data?.value}
                                            key={data?.label}
                                        >
                                            {data?.label}
                                        </Option>
                                    ))} */}
                                </Select>
                            </Form.Item>
                        </Col>
                        {values?.projects?.length > 0 && (
                            <Col span={24}>
                                <Form.Item
                                    label="Assignee"
                                    name="assignee"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please Select Assignee',
                                        },
                                    ]}
                                    colon={false}
                                >
                                    <Select
                                        mode="multiple"
                                        value={value}
                                        defaultValue={value}
                                        placeholder="Select Assignee..."
                                        labelInValue
                                        showArrow={false}
                                        filterOption={true}
                                        options={filteredAssignees}
                                        onChange={(_, value) => {
                                            setValue(value)
                                            updateDetails('assignee', value)
                                        }}
                                        style={{ height: 'auto !important' }}
                                    />
                                </Form.Item>
                            </Col>
                        )}
                        {values?.assignee?.length > 0 &&
                            values?.projects?.length > 0 && (
                                <Col span={24}>
                                    <Form.Item
                                        label="Date Range"
                                        colon={false}
                                        name={'rangePicker'}
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    'Please Select Date Range',
                                            },
                                        ]}
                                    >
                                        <RangePicker
                                            picker={'date'}
                                            allowClear={false}
                                            name={'rangePicker'}
                                            format={formatDatePicker}
                                            style={{ width: '100%' }}
                                            onChange={(event) => {
                                                setValues({
                                                    ...values,
                                                    start: event[0],
                                                    end: event[1],
                                                })
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                            )}
                    </Row>
                    <Row
                        justify={'end'}
                        style={{
                            marginTop: 30,
                            columnGap: 10,
                        }}
                    >
                        <Button
                            props={{
                                text: 'Cancel',
                                onClick: onCloseModal,
                                type: 'link',
                                style: {
                                    color: token.colorPalette.baseColor.black,
                                },
                            }}
                        />
                        {!pdfProjectData && !pdfReportData && (
                            <Button
                                props={{
                                    text: 'Next',
                                    htmlType: 'submit',
                                    loadingButton: isSubmiting,
                                }}
                            />
                        )}
                    </Row>
                </Form>
            </Modal>

            <EditReport
                isEditReport={isEditReport}
                setIsEditReport={setIsEditReport}
            />
        </>
    )
}

export default DownloadPopUp
