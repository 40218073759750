import { Navigate, Outlet, Route, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'

// slices
import { loginState } from '../login/login.slice'

// constants
import {
    DOMAIN_LIST,
    PRIVATE_ROUTE_CHECK,
    SUPER_ADMIN,
    WORKSPACE_ADMIN,
} from '../../constants/roles'

// routes
import { PRIVATE_ROOT_ROUTE, PRIVATE_ROUTES, PUBLIC_ROUTES } from '../../routes'

// pages
import FoF from '../404/404.index'

const PrivateRoute = () => {
    const { status, userProfile } = useSelector(loginState)

    const params = useLocation()

    if (
        status !== 'loading' &&
        userProfile?.role?.roleName !== undefined &&
        userProfile?.phone
    ) {
        if (
            params.pathname.includes('workspace') &&
            !DOMAIN_LIST.includes(userProfile?.currentWorkSpace?.domain)
        ) {
            return <Navigate to={PRIVATE_ROUTES.overview} />
        } else if (
            (PRIVATE_ROUTE_CHECK.includes(params.pathname) &&
                DOMAIN_LIST.includes(userProfile?.currentWorkSpace?.domain)) ||
            (params.pathname.includes('global-settings') &&
                (userProfile?.role?.roleName === SUPER_ADMIN ||
                    userProfile?.role?.roleName === WORKSPACE_ADMIN) &&
                !DOMAIN_LIST.includes(userProfile?.currentWorkSpace?.domain))
        ) {
            return <Outlet />
        } else {
            const found = userProfile?.role?.containers.some((data) =>
                data?.key ===
                params.pathname.split(`${PRIVATE_ROOT_ROUTE}/`)[1].includes('/')
                    ? params.pathname
                          .split(`${PRIVATE_ROOT_ROUTE}/`)[1]
                          .split('/')[0]
                    : params.pathname.split(`${PRIVATE_ROOT_ROUTE}/`)[1]
            )

            // if (
            //     userProfile?.activeProject === null &&
            //     params.pathname === '/dashboard/profile'
            // ) {
            //     return <ProfileSection />

            //     // return <Navigate to={PRIVATE_ROUTES.profile} />
            // } else if (found && userProfile?.activeProject !== null) {
            //     return <Outlet />
            // } else {
            //     return <FoF />
            // }

            if (found) {
                return <Outlet />
            } else {
                return <FoF />
            }
        }
    } else if (
        status !== 'loading' &&
        userProfile !== undefined &&
        userProfile?.role?.roleName !== undefined &&
        !userProfile?.phone
    ) {
        return <Route path={PUBLIC_ROUTES.signUp} />
    } else {
        return <Route path={PUBLIC_ROUTES.login} />
    }
}

export default PrivateRoute
