import React from 'react'

function NoInquiriesIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="190"
            height="180"
            fill="none"
            viewBox="0 0 190 180"
        >
            <g clipPath="url(#clip0_4469_18198)">
                <path
                    fill="#3F3D56"
                    d="M103.406 169.936c47.285 0 85.618-4.281 85.618-9.562 0-5.28-38.333-9.562-85.618-9.562-47.286 0-85.619 4.282-85.619 9.562 0 5.281 38.333 9.562 85.619 9.562z"
                ></path>
                <path
                    fill="#FFB8B8"
                    d="M145.869 149.793l1.476 4.547s7.112 7.344 4.54 9.335c-2.572 1.99-8.343-8.503-8.343-8.503l-1.063-5.134 3.39-.245z"
                ></path>
                <path
                    fill="#4F46E5"
                    d="M132.225 114.596l2.152-.717s3.348.956 3.827 4.303c.478 3.346 4.065 20.319 4.065 20.319s1.435.717.479 1.912c-.957 1.195 4.783 11.953 4.783 11.953l-4.544 2.151s-9.088-9.562-9.088-10.518c0-.956.239-1.912.239-2.869 0-.956-.239-.956 0-1.673.16-.625.24-1.267.239-1.912l-1.674-3.825-.478-19.124zM22.6 130.996c11.943 0 21.625-19.745 21.625-44.102 0-24.356-9.682-44.101-21.624-44.101C10.658 42.793.977 62.538.977 86.894c0 24.357 9.681 44.102 21.624 44.102z"
                ></path>
                <path
                    fill="#3F3D56"
                    d="M22.403 87.35c1.078-19.198 3.252-32.165 3.274-32.294l-.472-.08c-.022.13-2.199 13.117-3.28 32.34-.996 17.74-1.237 44.573 3.28 71.662l.472-.078c-4.51-27.043-4.27-53.836-3.274-71.55z"
                ></path>
                <path
                    fill="#3F3D56"
                    d="M41.929 77.95L22.377 88.41l.226.422 19.551-10.46-.225-.422zM1.962 83.192l-.226.421 19.555 10.455.225-.422L1.962 83.192z"
                ></path>
                <path
                    fill="#E6E6E6"
                    d="M73.816 120.699c16.342 0 29.59-27.02 29.59-60.35S90.158 0 73.816 0C57.472 0 44.224 27.02 44.224 60.35s13.248 60.349 29.59 60.349z"
                ></path>
                <path
                    fill="#3F3D56"
                    d="M73.457 60.968c1.476-26.273 4.45-44.02 4.48-44.197l-.47-.08c-.03.177-3.01 17.945-4.487 44.243-1.365 24.273-1.694 60.986 4.486 98.046l.472-.078c-6.172-37.015-5.844-73.687-4.481-97.933z"
                ></path>
                <path
                    fill="#3F3D56"
                    d="M100.304 48.187L73.549 62.5l.226.422 26.754-14.314-.225-.421zM45.53 55.364l-.225.421 26.758 14.306.226-.421L45.53 55.364z"
                ></path>
                <path
                    fill="#E6E6E6"
                    d="M49.165 144.217c6.106 0 11.056-10.095 11.056-22.548 0-12.453-4.95-22.548-11.056-22.548-6.106 0-11.056 10.095-11.056 22.548 0 12.453 4.95 22.548 11.056 22.548z"
                ></path>
                <path
                    fill="#3F3D56"
                    d="M49.156 122.641l.065.122 9.996-5.348-.225-.422-9.816 5.251c.544-9.992 1.654-16.7 1.677-16.832l-.472-.08c-.024.144-1.344 8.11-1.813 19.637l-9.899-5.297-.226.422 9.997 5.348.119-.223c-.375 9.513-.165 21.409 1.822 33.326l.472-.079c-2.166-12.993-2.214-25.961-1.697-35.825z"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M135.152 91.906c-3.844-6.549-11.45-6.854-11.45-6.854s-7.411-.955-12.165 9.018c-4.431 9.297-10.547 18.273-.985 20.449l1.728-5.42 1.069 5.824c1.362.098 2.728.122 4.092.07 10.241-.333 19.993.098 19.679-3.607-.417-4.925 1.731-13.179-1.968-19.48zM108.356 144.361c-.107.684-.263 1.36-.466 2.023-.229.648-.582 1.248-.791 1.903-.666 2.087.258 4.415 1.733 6.027a9.744 9.744 0 004.756 2.847c1.332.338 2.716.386 4.088.433 3.799.13 7.695.246 11.295-.985a19.416 19.416 0 003.282-1.503c.33-.159.621-.39.851-.676.275-.394.31-.905.307-1.386-.008-1.624-.334-3.239-.257-4.861.043-.898.21-1.796.128-2.692a4.949 4.949 0 00-3.764-4.277c-1.021-.221-2.08-.1-3.12.001a79.165 79.165 0 01-8.119.366c-2.77-.018-5.523-.444-8.279-.489-.816-.013-.799.24-1.059 1.035a19.561 19.561 0 00-.585 2.234z"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M97.524 143.042a8.108 8.108 0 00-2.28-.244 5.764 5.764 0 00-4.156 2.178 5.865 5.865 0 00-1.126 4.585c.185.867.49 1.704.903 2.489.605 1.237 1.33 2.471 2.45 3.264.673.435 1.4.784 2.16 1.037l5.896 2.239c.867.329 1.735.659 2.61.964a50.683 50.683 0 0013.635 2.733c1.416.085 2.853.109 4.234-.216.338-.045.646-.213.867-.472.112-.198.179-.419.194-.645l.362-2.561a2.156 2.156 0 00-.03-1.079c-.167-.43-.583-.701-.98-.931-3.876-2.244-8.401-3.483-11.664-6.563-.768-.725-1.41-1.874-2.343-2.367-1.059-.558-2.305-.877-3.398-1.392-2.391-1.125-4.742-2.418-7.334-3.019z"
                ></path>
                <path
                    fill="#000"
                    d="M103.409 146.495c2.084.658 10.882 5.219 12.39 6.811-.203.073-7.06-3.801-7.266-3.864-1.871-.566-3.752-1.135-5.528-1.964-.357-.166-2.321-.95-2.233-1.404.101-.513 2.267.304 2.637.421z"
                    opacity="0.1"
                ></path>
                <path
                    fill="#FFB8B8"
                    d="M129.406 163.614a.483.483 0 00.33-.098.513.513 0 00.103-.383l-.009-3.488c-.995-.478-2.108-.631-3.201-.778l-5.096-.687c.117.015-.797 2.213-.657 2.506.247.517 2.135.976 2.667 1.22 1.852.848 3.777 1.781 5.863 1.708z"
                ></path>
                <path
                    fill="#FFB8B8"
                    d="M113.414 159.634c-.962.155-2.036.291-2.659 1.047-.79.957-.454 2.385-.077 3.571.056.24.168.463.328.65.257.256.651.294 1.012.317.878.056 1.812.1 2.578-.334.348-.232.682-.482 1.002-.751a6.413 6.413 0 011.842-.849 18.565 18.565 0 013.696-.768 5.671 5.671 0 001.456-.258 1.63 1.63 0 001.026-1.004c.177-.634-.188-1.28-.538-1.836-.437-.693-.9-1.422-1.551-1.932-.949-.744-1.419-.173-2.376.303a20.03 20.03 0 01-5.739 1.844z"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M110.9 160.507l-6.11-1.821a8.066 8.066 0 00-2.274-.444c-.775.001-1.586.257-2.089.852-.473.56-.609 1.326-.727 2.053l-.253 1.555a7.874 7.874 0 00-.141 2.388c.115.792.516 1.584 1.214 1.967.845.465 1.881.238 2.825.043a30.62 30.62 0 015.791-.626c.731-.008 1.498.001 2.144-.344.769-.411 1.228-1.279 1.307-2.152.12-1.328-.319-3.063-1.687-3.471zM131.809 158.919c.792-.14 1.491-.594 2.25-.862 1.068-.377 2.226-.377 3.358-.373.415.002.858.011 1.204.243.498.335.626 1.005.711 1.603l.511 3.606c.097.679.192 1.375.042 2.044-.15.668-.602 1.313-1.26 1.485-.799.209-1.582-.305-2.336-.643-1.886-.845-4.078-.629-6.044-1.261-.365-.117-.756-.296-.904-.652a1.48 1.48 0 01-.071-.623c.034-1.159-.209-2.742.138-3.85.311-.997 1.516-.717 2.401-.717z"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M144.738 141.591a10.523 10.523 0 013.737-.258c1 .111 2.029.392 2.759 1.089.888.849 1.173 2.161 1.197 3.395a10.658 10.658 0 01-2.287 6.773c-.369.486-.81.913-1.307 1.269a8.587 8.587 0 01-1.7.826l-13.203 5.25c-3.473 1.381-6.959 2.766-10.581 3.677a.812.812 0 01-.641.032.818.818 0 01-.469-.438 27.645 27.645 0 01-2.894-5.336.489.489 0 01-.037-.4.485.485 0 01.195-.167l9.338-5.409a9.422 9.422 0 001.901-1.321 4.78 4.78 0 00.976-1.369c.193-.408.211-1.37.458-1.671.243-.295 1.071-.319 1.453-.435.54-.166 1.068-.368 1.582-.602 2.088-.943 3.948-2.311 5.951-3.415a15.157 15.157 0 013.572-1.49z"
                ></path>
                <path
                    fill="#000"
                    d="M139.444 145.629c-1.605 1.214-3.682 2.331-5.272 3.578-.543.425-9.589 5.553-9.717 6.234 1.484.252 10.933-6.413 12.285-7.078 1.352-.665 2.551-1.603 3.738-2.534.303-.237 1.585-.95.717-1.215-.49-.149-1.39.772-1.751 1.015z"
                    opacity="0.1"
                ></path>
                <path
                    fill="#FFB8B8"
                    d="M123.377 108.382c5.019 0 9.088-4.067 9.088-9.083 0-5.017-4.069-9.084-9.088-9.084-5.019 0-9.088 4.067-9.088 9.084 0 5.016 4.069 9.083 9.088 9.083z"
                ></path>
                <path
                    fill="#FFB8B8"
                    d="M127.203 106.949s-1.435 3.586 1.196 5.02c2.631 1.434-8.131 12.909-8.131 12.909l-4.784-13.865s4.066-1.434 3.349-5.259l8.37 1.195z"
                ></path>
                <path
                    fill="#4F46E5"
                    d="M121.224 116.748l5.792-6.392 7.84 4.001-1.196 28.208-1.913 2.151s-8.131 7.172-20.567 1.195c0 0-4.305-3.585-4.066-9.083.239-5.499-.478-6.216-.478-6.216l-.24-19.363 11.808-2.315 3.02 7.814z"
                ></path>
                <path
                    fill="#FFB8B8"
                    d="M94.667 147.164l-1.477 4.547s-7.112 7.344-4.54 9.335c2.573 1.99 8.343-8.503 8.343-8.503l1.064-5.134-3.39-.245z"
                ></path>
                <path
                    fill="#4F46E5"
                    d="M108.31 111.967l-2.152-.717s-3.349.956-3.827 4.303c-.478 3.347-4.066 20.319-4.066 20.319s-1.435.717-.478 1.912c.957 1.196-4.783 11.953-4.783 11.953l4.544 2.151s9.088-9.562 9.088-10.518c0-.956-.239-1.912-.239-2.869 0-.956.239-.956 0-1.673a7.659 7.659 0 01-.239-1.912l1.674-3.825.478-19.124z"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M133.451 91.273l-7.514-3.968-10.376 1.623-2.147 9.56 5.344-.208 1.493-3.512v3.454l2.466-.095 1.431-5.592.895 5.953 8.766-.18-.358-7.035z"
                ></path>
                <path
                    fill="#FF6584"
                    d="M80.188 152.771v13.789H38.053c0-3.657 1.453-7.164 4.04-9.75a13.801 13.801 0 019.754-4.039c3.951-1.231 8.52-1.623 13.474-1.494 4.676.12 9.696.706 14.867 1.494z"
                ></path>
                <path
                    fill="#3F3D56"
                    d="M74.168 163.551h19.814a1.254 1.254 0 110 2.507H74.168v-2.507zM77.6 140.972l5.31-6.435a1.391 1.391 0 011.377-.786 1.395 1.395 0 011.222 1.009l2.094 7.391-10.003-1.179z"
                ></path>
                <path
                    fill="#FF6584"
                    d="M71.158 144.248l2.007-8.097a1.393 1.393 0 012.44-.922l5.084 5.76-9.53 3.259zM38.05 166.559h4.014v8.523a4.01 4.01 0 01-4.013 4.011v-12.534zM73.416 164.051H93.23a1.255 1.255 0 110 2.507H73.416v-2.507z"
                ></path>
                <path
                    fill="#000"
                    d="M80.187 152.771v13.712a13.555 13.555 0 01-11.12-4.001 13.544 13.544 0 01-3.746-11.205c4.676.12 9.696.706 14.866 1.494z"
                    opacity="0.2"
                ></path>
                <path
                    fill="#FF6584"
                    d="M80.186 166.559c7.48 0 13.543-6.061 13.543-13.537 0-7.477-6.063-13.538-13.543-13.538-7.48 0-13.543 6.061-13.543 13.538 0 7.476 6.063 13.537 13.543 13.537z"
                ></path>
                <path
                    fill="#3F3D56"
                    d="M93.23 157.535c.83 0 1.504-1.571 1.504-3.51 0-1.938-.673-3.509-1.505-3.509-.83 0-1.504 1.571-1.504 3.509 0 1.939.673 3.51 1.504 3.51zM89.593 151.267c.208 0 .376-.393.376-.878 0-.484-.168-.877-.376-.877s-.376.393-.376.877c0 .485.168.878.376.878zM92.35 149.513c.209 0 .377-.393.377-.878 0-.484-.168-.877-.376-.877s-.376.393-.376.877c0 .485.168.878.376.878zM79.052 139.334s-.753-1.309-.753-2.107l.753 2.107zM79.383 138.488s.41-1.453.981-2.011l-.981 2.011z"
                ></path>
            </g>
            <defs>
                <clipPath id="clip0_4469_18198">
                    <path
                        fill="#fff"
                        d="M0 0H188.048V179.094H0z"
                        transform="translate(.977)"
                    ></path>
                </clipPath>
            </defs>
        </svg>
    )
}

export default NoInquiriesIcon
