import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PDFDownloadLink } from '@react-pdf/renderer'

// antd
import {
    Divider,
    Modal,
    Spin,
    TimePicker,
    Tooltip,
    message,
    Button as AntButton,
    Row,
    Typography,
} from 'antd'
import {
    EditOutlined,
    CloseSquareOutlined,
    CheckSquareOutlined,
} from '@ant-design/icons'

// redux
import { reportsActions } from './redux/slice'
import { editTimeWithTask, timesheetApi } from './redux/apis'

// components
import ReportDocument from './reportDocument'

import dayjs from 'dayjs'
import Button from '../../../components/button/button'

const EditReport = ({ isEditReport, setIsEditReport }) => {
    const { Title, Text } = Typography

    const dispatch = useDispatch()

    const pdfDataInRedux = useSelector((state) => state.reportTimesheet)
    const pdfData = pdfDataInRedux?.pdfData
    const projectData = pdfDataInRedux?.pdfSelectedData

    const [totalHours, setTotalHours] = useState(0)
    const [isGeneratePdf, setIsGeneratePdf] = useState(false)
    const [selectedTask, setSelectedTask] = useState(null)
    const [selectedTaskTime, setSelectedTaskTime] = useState(null)
    const [isLoadData, setLoadData] = useState(false)
    const [isLoadDataGeneratePdf, setLoadDataGeneratePdf] = useState(false)

    useEffect(() => {
        if (pdfData?.tableBody?.length > 0) {
            let hours = pdfData?.tableBody.map(
                (data) =>
                    data?.list?.length > 0 &&
                    data?.list.reduce(function (acc, obj) {
                        return acc + obj?.totalLog?.total
                    }, 0)
            )
            const sum = hours.reduce((partialSum, a) => partialSum + a, 0)
            setTotalHours(sum)
            setIsGeneratePdf(false)
        }
    }, [pdfData?.tableBody])

    const handleEditTime = (task) => {
        setSelectedTask(task)
        setIsGeneratePdf(false)
    }

    const handleGeneratePDF = () => {
        setLoadDataGeneratePdf(true)
        setTimeout(() => {
            setLoadDataGeneratePdf(false)
            setIsGeneratePdf(true)
        }, 1500)
    }

    function hourMinuteSecond(timeInMilliseconds, type) {
        let h, m, s
        h = Math.floor(timeInMilliseconds / 1000 / 60 / 60)
        m = Math.floor((timeInMilliseconds / 1000 / 60 / 60 - h) * 60)
        s = Math.floor(
            ((timeInMilliseconds / 1000 / 60 / 60 - h) * 60 - m) * 60
        )

        if (type === 'edit') {
            return `${h}:${m}:${s}s`
        } else {
            return `${h}h ${m}m ${s}s`
        }
    }

    const handleSaveChangeTime = async () => {
        // set logic of pervious time and new time and update api call

        if (selectedTask) {
            setLoadData(true)
            let endLog = selectedTask?.timelogs?.end
                ? selectedTask?.timelogs?.end
                : new Date().getTime()
            let timeDiff = endLog - selectedTask.timelogs?.start

            // Default editedTime to 0 if selectedTaskTime is null
            let editedTime = 0

            if (selectedTaskTime !== null) {
                editedTime =
                    (selectedTaskTime['$H'] * 60 * 60 +
                        selectedTaskTime['$m'] * 60 +
                        selectedTaskTime['$s']) *
                    1000
            }

            let latestTimeDiff =
                editedTime > timeDiff
                    ? editedTime - timeDiff
                    : timeDiff - editedTime
            let newEndLog =
                editedTime > timeDiff
                    ? endLog + latestTimeDiff
                    : endLog - latestTimeDiff

            // send api data in payload

            const data = {
                id: selectedTask?._id,
                index: selectedTask?.index,
                newEndLog,
            }

            const updatedRes = await editTimeWithTask(data)

            if (updatedRes?.data?.success) {
                message.success(updatedRes?.data?.message)
                setSelectedTask(null)
                setSelectedTaskTime(null)

                let assigneesId = projectData?.assignees?.map((v) => v.value)

                const reportNewData = {
                    projectId: projectData?.project?.id,
                    start: projectData?.start,
                    end: projectData?.end,
                    assigneeId: assigneesId,
                }

                const reportRes = await timesheetApi(reportNewData)
                if (reportRes?.data?.success) {
                    setLoadData(false)
                    dispatch(
                        reportsActions.downloadPdfData({
                            pdfData: reportRes?.data,
                            pdfSelectedData: projectData,
                        })
                    )
                    setIsGeneratePdf(false)
                } else {
                    setLoadData(false)
                    message.error(reportRes?.data?.message)
                    setIsGeneratePdf(false)
                }
            } else {
                setLoadData(false)
                message.error(updatedRes?.data?.message)
                setIsGeneratePdf(false)
            }
        } else {
            message.error('Please select time properly')
        }
    }

    const handleCancleTime = () => {
        // cancle icon
        setSelectedTask(null)
        setSelectedTaskTime(null)
        setIsGeneratePdf(false)
    }

    const handleTimePickerValChange = (val) => {
        // time picker value
        if (val) {
            setSelectedTaskTime(val)
        } else {
            setSelectedTaskTime(null)
        }
    }

    return (
        <>
            <Modal
                closable={false}
                open={isEditReport}
                destroyOnClose={true}
                footer={null}
                styles={{
                    body: {
                        padding: '30px 25px',
                    },
                }}
                width={1000}
                onCancel={() => {
                    setIsEditReport(false)
                    dispatch(
                        reportsActions.downloadPdfData({
                            pdfData: null,
                            pdfSelectedData: null,
                        })
                    )
                }}
            >
                <>
                    <Row align={'middle'} justify={'space-between'}>
                        <Title level={4}>Report Preview</Title>
                        {isGeneratePdf ? (
                            <PDFDownloadLink
                                document={
                                    <ReportDocument
                                        pdfData={pdfData}
                                        selectPdfData={projectData}
                                    />
                                }
                                fileName={projectData?.project?.name}
                                style={{ textDecoration: 'none' }}
                            >
                                <Button
                                    props={{
                                        text: 'Download PDF',
                                        onClick: () => {},
                                    }}
                                />
                            </PDFDownloadLink>
                        ) : (
                            <Button
                                props={{
                                    loadingButton: isLoadDataGeneratePdf,
                                    disabled: isLoadDataGeneratePdf,
                                    onClick: () => {
                                        handleGeneratePDF()
                                    },
                                    text: 'Generate PDF',
                                }}
                            />
                        )}
                    </Row>
                    {isLoadData ? (
                        <div
                            style={{
                                width: '100%',
                                height: '30vh',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Spin />
                        </div>
                    ) : (
                        <>
                            {pdfData && projectData && (
                                <>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: 3,
                                            fontWeight: 600,
                                            marginBottom: 20,
                                            alignItems: 'flex-end',
                                            marginTop: '40px',
                                        }}
                                    >
                                        <span>Progess Report</span>
                                        <span>
                                            {dayjs(projectData?.start).format(
                                                'DD MMM YYYY'
                                            )}{' '}
                                            -{' '}
                                            {dayjs(projectData?.end).format(
                                                'DD MMM YYYY'
                                            )}
                                        </span>
                                        <span>
                                            Project Name:{' '}
                                            {projectData?.project?.name}
                                        </span>
                                    </div>

                                    <div
                                        style={{
                                            marginBottom: 10,
                                            fontWeight: 600,
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <div>
                                            <span>Summary</span>
                                        </div>
                                        <div
                                            style={{
                                                flexDirection: 'column',
                                                display: 'flex',
                                            }}
                                        >
                                            <span>
                                                {hourMinuteSecond(totalHours)}
                                            </span>
                                            <span>Total Hours</span>
                                        </div>
                                    </div>
                                    {pdfData?.tableBody?.length > 0 ? (
                                        pdfData?.tableBody.map(
                                            (item) =>
                                                projectData?.assignees?.length >
                                                    0 &&
                                                projectData?.assignees?.map(
                                                    (assignee) => (
                                                        <>
                                                            {assignee?.value ===
                                                                item?._id
                                                                    ?.assigneeId && (
                                                                <>
                                                                    <Divider />
                                                                    <div>
                                                                        <div
                                                                            style={{
                                                                                marginBottom: 10,
                                                                                fontWeight: 600,
                                                                                display:
                                                                                    'flex',
                                                                                justifyContent:
                                                                                    'space-between',
                                                                                alignItems:
                                                                                    'center',
                                                                            }}
                                                                        >
                                                                            <div>
                                                                                <Title
                                                                                    level={
                                                                                        5
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        assignee?.label
                                                                                    }
                                                                                </Title>
                                                                            </div>
                                                                            <div
                                                                                style={{
                                                                                    flexDirection:
                                                                                        'column',
                                                                                    display:
                                                                                        'flex',
                                                                                }}
                                                                            >
                                                                                <span>
                                                                                    {hourMinuteSecond(
                                                                                        item?.list.reduce(
                                                                                            (
                                                                                                acc,
                                                                                                obj
                                                                                            ) => {
                                                                                                return (
                                                                                                    acc +
                                                                                                    obj
                                                                                                        ?.totalLog
                                                                                                        ?.total
                                                                                                )
                                                                                            },
                                                                                            0
                                                                                        )
                                                                                    )}
                                                                                </span>
                                                                                <span>
                                                                                    Total
                                                                                    Time
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        {item
                                                                            ?.list
                                                                            ?.length >
                                                                            0 &&
                                                                            item?.list.map(
                                                                                (
                                                                                    row
                                                                                ) => (
                                                                                    <>
                                                                                        <ul>
                                                                                            {row?.list?.map(
                                                                                                (
                                                                                                    l
                                                                                                ) => (
                                                                                                    <>
                                                                                                        <li>
                                                                                                            <div
                                                                                                                style={{
                                                                                                                    display:
                                                                                                                        'flex',
                                                                                                                    justifyContent:
                                                                                                                        'space-between',
                                                                                                                    marginBottom:
                                                                                                                        '15px',
                                                                                                                }}
                                                                                                            >
                                                                                                                <Title
                                                                                                                    level={
                                                                                                                        5
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        l?.title
                                                                                                                    }
                                                                                                                </Title>
                                                                                                                {selectedTask &&
                                                                                                                selectedTask
                                                                                                                    ?.timelogs
                                                                                                                    ?._id ===
                                                                                                                    l
                                                                                                                        ?.timelogs
                                                                                                                        ?._id ? (
                                                                                                                    <div
                                                                                                                        style={{
                                                                                                                            display:
                                                                                                                                'flex',
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <span
                                                                                                                            style={{
                                                                                                                                marginRight: 10,
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <TimePicker
                                                                                                                                defaultValue={
                                                                                                                                    l
                                                                                                                                        ?.timelogs
                                                                                                                                        ?.end
                                                                                                                                        ? dayjs(
                                                                                                                                              hourMinuteSecond(
                                                                                                                                                  l
                                                                                                                                                      ?.timelogs
                                                                                                                                                      ?.end -
                                                                                                                                                      l
                                                                                                                                                          ?.timelogs
                                                                                                                                                          ?.start,
                                                                                                                                                  'edit'
                                                                                                                                              ),
                                                                                                                                              'HH:mm:ss'
                                                                                                                                          )
                                                                                                                                        : dayjs(
                                                                                                                                              hourMinuteSecond(
                                                                                                                                                  new Date().getTime() -
                                                                                                                                                      l
                                                                                                                                                          ?.timelogs
                                                                                                                                                          ?.start,
                                                                                                                                                  'edit'
                                                                                                                                              ),
                                                                                                                                              'HH:mm:ss'
                                                                                                                                          )
                                                                                                                                }
                                                                                                                                allowClear={
                                                                                                                                    false
                                                                                                                                }
                                                                                                                                size="small"
                                                                                                                                onChange={
                                                                                                                                    handleTimePickerValChange
                                                                                                                                }
                                                                                                                            />
                                                                                                                        </span>
                                                                                                                        <span
                                                                                                                            style={{
                                                                                                                                display:
                                                                                                                                    'flex',
                                                                                                                                alignItems:
                                                                                                                                    'center',
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <CheckSquareOutlined
                                                                                                                                style={{
                                                                                                                                    marginRight: 5,
                                                                                                                                    color: 'green',
                                                                                                                                }}
                                                                                                                                onClick={
                                                                                                                                    handleSaveChangeTime
                                                                                                                                }
                                                                                                                            />
                                                                                                                            <CloseSquareOutlined
                                                                                                                                style={{
                                                                                                                                    color: 'red',
                                                                                                                                }}
                                                                                                                                onClick={
                                                                                                                                    handleCancleTime
                                                                                                                                }
                                                                                                                            />
                                                                                                                        </span>
                                                                                                                    </div>
                                                                                                                ) : (
                                                                                                                    <span>
                                                                                                                        <div>
                                                                                                                            <span>
                                                                                                                                {l
                                                                                                                                    ?.timelogs
                                                                                                                                    ?.end
                                                                                                                                    ? hourMinuteSecond(
                                                                                                                                          l
                                                                                                                                              ?.timelogs
                                                                                                                                              ?.end -
                                                                                                                                              l
                                                                                                                                                  ?.timelogs
                                                                                                                                                  ?.start
                                                                                                                                      )
                                                                                                                                    : hourMinuteSecond(
                                                                                                                                          new Date().getTime() -
                                                                                                                                              l
                                                                                                                                                  ?.timelogs
                                                                                                                                                  ?.start
                                                                                                                                      )}
                                                                                                                            </span>
                                                                                                                            <span
                                                                                                                                onClick={() =>
                                                                                                                                    handleEditTime(
                                                                                                                                        l
                                                                                                                                    )
                                                                                                                                }
                                                                                                                                style={{
                                                                                                                                    marginLeft: 10,
                                                                                                                                }}
                                                                                                                            >
                                                                                                                                <Tooltip title="Edit Time">
                                                                                                                                    <EditOutlined />
                                                                                                                                </Tooltip>
                                                                                                                            </span>
                                                                                                                        </div>
                                                                                                                    </span>
                                                                                                                )}
                                                                                                            </div>
                                                                                                        </li>
                                                                                                        <ul
                                                                                                            style={{
                                                                                                                listStyleType:
                                                                                                                    'none',
                                                                                                                marginBottom: 20,
                                                                                                            }}
                                                                                                        >
                                                                                                            <li>
                                                                                                                <Text>
                                                                                                                    {
                                                                                                                        l?.description
                                                                                                                    }
                                                                                                                </Text>
                                                                                                            </li>
                                                                                                        </ul>
                                                                                                    </>
                                                                                                )
                                                                                            )}
                                                                                        </ul>
                                                                                    </>
                                                                                )
                                                                            )}
                                                                    </div>
                                                                </>
                                                            )}
                                                        </>
                                                    )
                                                )
                                        )
                                    ) : (
                                        <div>No Data</div>
                                    )}

                                    <Divider />
                                </>
                            )}
                        </>
                    )}
                </>
            </Modal>
        </>
    )
}

export default EditReport
