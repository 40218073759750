import React from 'react'

function EllipsisIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="4"
            height="16"
            fill="none"
            viewBox="0 0 4 16"
        >
            <path
                fill="#637381"
                d="M3.667 8A1.667 1.667 0 11.334 8a1.667 1.667 0 013.333 0zM3.667 2.167a1.667 1.667 0 11-3.333 0 1.667 1.667 0 013.333 0zM3.667 13.833a1.667 1.667 0 11-3.333 0 1.667 1.667 0 013.333 0z"
            ></path>
        </svg>
    )
}

export default EllipsisIcon
