import React from 'react'

function EmploymentTypeIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="22"
            fill="none"
            viewBox="0 0 16 22"
        >
            <path
                fill="#4F46E5"
                d="M14.364 9.98a4 4 0 010 5.656l-5.657 5.657a1 1 0 01-1.414 0l-5.657-5.657a4 4 0 115.657-5.657l.707.707.707-.707a4 4 0 015.657 0zM8 0a4 4 0 110 8 4 4 0 010-8z"
            ></path>
        </svg>
    )
}

export default EmploymentTypeIcon
