import React from 'react'

function CertificatesLogoIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 64 80"
        >
            <path
                fill="#E0DEFB"
                d="M4.541-.006h45.951c.04.031.075.08.121.094 3.055.912 4.412 2.714 4.412 5.88V30.56c-1.45-.196-2.783-.474-4.126-.53-1.351-.07-2.707 0-4.044.209-12.067 2.046-19.684 15.852-13.332 27.45.154.295.236.622.24.955.017 3.073 0 6.147 0 9.22v.915H5.577a5.565 5.565 0 01-.933-.065c-2.838-.478-4.64-2.68-4.64-5.681V7.074c0-.599-.027-1.2 0-1.798C.127 3.155 1.12 1.572 3.017.596a14.972 14.972 0 011.524-.602zm21.023 17.51h10.469c.858 0 1.595-.242 2.144-.915.652-.795.796-1.693.35-2.626-.483-1.02-1.33-1.462-2.457-1.462-6.901.008-13.803.008-20.704 0-1.723 0-2.861 1.008-2.85 2.506.01 1.497 1.154 2.482 2.89 2.482l10.158.015zm.077 5.011H15.328c-1.67 0-2.8 1.002-2.813 2.479-.012 1.476 1.128 2.52 2.775 2.52h20.703c1.65 0 2.785-1.044 2.775-2.52-.01-1.477-1.148-2.477-2.815-2.479H25.641zM21.294 37.52h6.015c1.585 0 2.714-1.063 2.714-2.506 0-1.443-1.133-2.487-2.724-2.489H15.268c-1.608 0-2.728 1.017-2.744 2.466-.017 1.449 1.121 2.526 2.762 2.533 1.996-.009 4.003-.013 6.008-.013v.008z"
            ></path>
            <path
                fill="#CAC7F8"
                d="M50.041 35.01A13.78 13.78 0 0163.776 48.8a13.78 13.78 0 01-13.812 13.717A13.78 13.78 0 0136.27 48.761 13.78 13.78 0 0150.04 35.01zm-1.96 14.173c-.627-.405-1.19-.812-1.792-1.16a2.479 2.479 0 00-3.362.793 2.437 2.437 0 00.6 3.34 71.232 71.232 0 003.954 2.64c1.126.701 2.525.459 3.268-.627a304.367 304.367 0 005.182-7.788c.658-1.021.403-2.234-.478-3.047a2.368 2.368 0 00-3.019-.254 4.374 4.374 0 00-1.031 1.144c-1.111 1.627-2.195 3.274-3.322 4.96zM38.77 63.855c7.548 4.901 14.969 4.895 22.46.034.018.209.045.395.045.584V77.21c0 2.377-1.946 3.498-4.022 2.322-2.194-1.253-4.385-2.487-6.565-3.758-.494-.295-.858-.272-1.34.012-2.153 1.266-4.326 2.495-6.5 3.725-2.174 1.23-4.074.107-4.076-2.384V64.628l-.002-.773z"
            ></path>
        </svg>
    )
}

export default CertificatesLogoIcon
