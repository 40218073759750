import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { debounce } from 'lodash'

// antd
import {
    Avatar,
    Image,
    Modal,
    Row,
    Select,
    Spin,
    Tooltip,
    Typography,
    message,
} from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'

// slices
import { searchUsers, usersList, usersState } from '../users/users.slice'
import { loginState } from '../login/login.slice'
import {
    changePage,
    teamListGet,
    teamMemberAddRemove,
    teamState,
    totalTeam,
} from './team.slice'

// components
import Button from '../../components/button/button'

// assets
import { CloseModalIcon } from '../../assets/icons'

function TeamModalContent({ visibility, onCancel }) {
    const { Title } = Typography

    const { Option } = Select

    const dispatch = useDispatch()

    const { status, users, search: userSearch } = useSelector(usersState)
    const {
        pageLimit,
        skipPage,
        search,
        teamList,
        status: teamStatus,
    } = useSelector(teamState)
    const { userProfile } = useSelector(loginState)

    const [value, setValue] = useState([])
    const [options, setOptions] = useState([])
    const [memberLoadingButton, setMemberLoadingButton] = useState(false)
    const [loadingButton] = useState(false)

    // ADD TEAM MEMBER
    async function addTeamMember() {
        setMemberLoadingButton(true)
        const newValue = value?.length > 0 && value.map((data) => data?.value)
        const result = await dispatch(
            teamMemberAddRemove({
                id: userProfile?.activeProject?._id,
                member: {
                    fieldName: 'add',
                    teamMembers: [...newValue],
                },
            })
        )
        const data = result?.payload?.data
        if (data) {
            const { success, message: checkMessage } = data
            if (success) {
                setMemberLoadingButton(false)
                message.success(checkMessage)
                dispatch(searchUsers(''))
                dispatch(
                    totalTeam({
                        search: search,
                        projectId: userProfile?.activeProject?._id,
                    })
                )
                dispatch(
                    teamListGet({
                        limit: pageLimit,
                        skip: skipPage,
                        search: search,
                        projectId: userProfile?.activeProject?._id,
                    })
                )
                dispatch(
                    changePage({
                        currentPage: 1,
                        skip: 0,
                        pageLimit,
                    })
                )
                onCancel()
            } else {
                setMemberLoadingButton(false)
                if (checkMessage) {
                    message.error(checkMessage)
                } else {
                    message.error('Something went wrong, try again later.')
                }
                onCancel()
            }
        }
    }

    useEffect(() => {
        if (users?.length > 0) {
            // CHECK IF ALREADY TEAM MEMBER ADDED
            if (teamList?.length > 0) {
                const result = users.filter(
                    (user) => !teamList.some((team) => team?._id === user?._id)
                )
                if (result?.length > 0) {
                    const dataOptions = result.map((data) => {
                        return {
                            label: data?.name,
                            value: data?._id,
                            role: data?.role?.roleName,
                            profilePicture: data?.profilePicture,
                            id: data?._id,
                        }
                    })
                    setOptions(dataOptions)
                } else if (userSearch?.length > 0) {
                    setOptions([])
                    message.info('This member is already in your team')
                }
            } else {
                const results = users.map((data) => data)
                const dataOptions = results.map((data) => {
                    return {
                        label: data?.name,
                        value: data?._id,
                        role: data?.role?.roleName,
                        profilePicture: data?.profilePicture,
                        id: data?._id,
                    }
                })
                setOptions(dataOptions)
            }
        } else {
            setOptions([])
            if (teamStatus === 'loaded' && userSearch?.length > 0) {
                message.info('No User Found')
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [users])

    // SEARCH USERS
    const searchFn = debounce(async (value) => {
        if (value?.length > 0) {
            dispatch(searchUsers(value))
            dispatch(
                usersList({
                    search: value,
                    skip: 0,
                    filter: {
                        isAssigned: true,
                    },
                })
            )
        }
    }, 1000)

    return (
        <>
            <Modal
                closable={false}
                open={visibility}
                onCancel={() => {
                    onCancel()
                    setOptions([])
                    dispatch(searchUsers(''))
                    setValue([])
                }}
                destroyOnClose={true}
                width={700}
                confirmLoading={loadingButton}
                footer={null}
                centered
                title={null}
                styles={{
                    body: {
                        padding: '20px',
                    },
                }}
            >
                <Row
                    align={'middle'}
                    justify={'center'}
                    style={{
                        marginBottom: '27px',
                        columnGap: '10px',
                    }}
                >
                    <Title level={4} style={{ margin: 0 }}>
                        Invite with email
                    </Title>
                    <Tooltip title="Only Assigned user can be in the list">
                        <InfoCircleOutlined />
                    </Tooltip>
                </Row>
                <Row
                    align={'middle'}
                    style={{
                        columnGap: '20px',
                        flexFlow: 'row nowrap',
                    }}
                >
                    <Select
                        mode="multiple"
                        value={value}
                        removeIcon={
                            <div
                                style={{
                                    height: '14px',
                                    width: '14px',
                                }}
                            >
                                <CloseModalIcon />
                            </div>
                        }
                        placeholder="Search"
                        filterOption={false}
                        onSearch={searchFn}
                        maxTagCount={2}
                        disabled={memberLoadingButton}
                        notFoundContent={
                            status === 'loading' ? (
                                <Spin size="small" />
                            ) : users?.length === 0 ? null : null
                        }
                        loading={status === 'loading'}
                        onChange={(_, value) => {
                            setValue(value)
                        }}
                        rootClassName="p-0-multiSelect"
                        style={{
                            width: '100%',
                            padding: '0px',
                        }}
                    >
                        {options?.length > 0 &&
                            options.map((data) => {
                                return (
                                    <Option
                                        value={data?.value}
                                        key={data?.label}
                                    >
                                        <Row
                                            align={'middle'}
                                            style={{
                                                columnGap: '10px',
                                            }}
                                        >
                                            {data?.profilePicture ? (
                                                <>
                                                    <Image
                                                        src={
                                                            data?.profilePicture
                                                        }
                                                        height={22}
                                                        width={22}
                                                        style={{
                                                            borderRadius: '50%',
                                                        }}
                                                        preview={false}
                                                    />
                                                </>
                                            ) : (
                                                <Avatar
                                                    style={{
                                                        cursor: 'pointer',
                                                        backgroundColor:
                                                            'rgb(0 10 129 / 25%)',
                                                        textTransform:
                                                            'capitalize',
                                                    }}
                                                    size={22}
                                                >
                                                    {data?.label[0].toUpperCase()}
                                                    {data?.label.split(
                                                        ' '
                                                    )[1] !== undefined &&
                                                        data?.label.split(
                                                            ' '
                                                        )[1] !== '' &&
                                                        data?.label
                                                            .split(' ')[1][0]
                                                            .toUpperCase()}
                                                </Avatar>
                                            )}
                                            {data?.label}
                                        </Row>
                                    </Option>
                                )
                            })}
                    </Select>
                    <Button
                        props={{
                            text: 'Invite',
                            disabled: value?.length === 0,
                            loadingButton: memberLoadingButton,
                            onClick: () => addTeamMember(),
                        }}
                    />
                </Row>
            </Modal>
        </>
    )
}

export default TeamModalContent
