import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { message } from 'antd'

import JobApplicantInfoCard from './JobApplicantInfoCard'
import JobApplicantPersonalDetails from './JobApplicantPersonalDetails'
import { profileState, updateUserGet } from '../../profile/profile.slice'
import { apiUpdateJobApplicationStatus } from '../store/jobApplicant.service'
import {
    getJobApplicantListByStatus,
    getStatusWiseCount,
} from '../store/jobApplicant.slice'
import JobOfferModel from './JobOfferModel'
import JobApplicantResume from './JobApplicantResume'
import JobApplicantNotes from './JobApplicantNotes'

const initialJobApplicationState = {
    status: null,
    jobOfferModalOpen: false,
    jobOfferModalData: null,
}

const JobApplicantDetails = ({ state, setState }) => {
    const dispatch = useDispatch()
    const params = useParams()

    const [JobApplicationState, setJobApplicationState] = useState(
        initialJobApplicationState
    )

    const { profileData } = useSelector(profileState)

    useEffect(() => {
        if (profileData) {
            setJobApplicationState((prevState) => ({
                ...prevState,
                status: profileData?.data?.status,
            }))
        }
    }, [profileData])

    const handleUpdateJobApplicationStatus = async (event) => {
        try {
            if (event !== 'offered') {
                const updateRes = await apiUpdateJobApplicationStatus(
                    profileData?.data?._id,
                    {
                        status: event,
                    }
                )

                if (updateRes?.data?.success) {
                    dispatch(getStatusWiseCount({ job: params.id }))
                    dispatch(
                        getJobApplicantListByStatus({
                            filter: {
                                status: JobApplicationState?.status,
                                job: params.id,
                            },
                        })
                    )
                    dispatch(
                        updateUserGet({
                            _id: profileData?.data?._id,
                        })
                    )
                    setJobApplicationState((prevState) => ({
                        ...prevState,
                        status: event,
                    }))
                    message.success(
                        'Job Application status updated successfully'
                    )
                }
            } else {
                setJobApplicationState((prevState) => ({
                    ...prevState,
                    status: event,
                    jobOfferModalOpen: true,
                }))
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleViewAndUpdateResume = () => {
        setState((prevState) => ({
            ...prevState,
            jobApplicantResumeDrawer: true,
        }))
    }

    const handleViewNotes = () => {
        setState((prevState) => ({
            ...prevState,
            jobApplicantNotesDrawer: true,
        }))
    }

    return (
        <>
            <div>
                <JobApplicantInfoCard
                    state={state}
                    setState={setState}
                    handleUpdateJobApplicationStatus={
                        handleUpdateJobApplicationStatus
                    }
                    JobApplicationState={JobApplicationState}
                    handleViewAndUpdateResume={handleViewAndUpdateResume}
                    handleViewNotes={handleViewNotes}
                />

                <JobApplicantPersonalDetails />
            </div>

            <JobOfferModel
                state={JobApplicationState}
                setState={setJobApplicationState}
            />

            <JobApplicantResume state={state} setState={setState} />

            <JobApplicantNotes
                state={state}
                setState={setState}
                JobApplicationState={JobApplicationState}
                handleUpdateJobApplicationStatus={
                    handleUpdateJobApplicationStatus
                }
            />
        </>
    )
}

export default JobApplicantDetails
