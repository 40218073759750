import {
    CriticalPriorityIcon,
    HighPriorityIcon,
    LowPriorityIcon,
    MediumPriorityIcon,
} from '../assets/icons'

export const issueType = [
    {
        label: 'Bug',
        value: 'Bug',
        remove: false,
    },
    {
        label: 'Task',
        value: 'Task',
        remove: false,
    },
]
export const swimLanesType = [
    {
        label: 'To Do',
        value: 'To Do',
        remove: false,
    },
    {
        label: 'Done',
        value: 'Done',
        remove: false,
    },
]

export const priorityType = [
    {
        label: 'Low',
        value: 'Low',
        icon: <LowPriorityIcon />,
    },
    {
        label: 'Medium',
        value: 'Medium',
        icon: <MediumPriorityIcon />,
    },
    {
        label: 'High',
        value: 'High',
        icon: <HighPriorityIcon />,
    },
    {
        label: 'Critical',
        value: 'Critical',
        icon: <CriticalPriorityIcon />,
    },
]
// export const issueType = [
//     {
//         label: 'Default',
//         options: [
//             {
//                 label: 'Bug',
//                 value: 'Bug',
//                 remove: false,
//             },
//             {
//                 label: 'Story',
//                 value: 'Story',
//                 remove: false,
//             },
//             {
//                 label: 'Epic',
//                 value: 'Epic',
//                 remove: false,
//             },
//         ],
//     },
//     {
//         label: 'New Added',
//         options: [],
//     },
// ]
