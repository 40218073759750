import React from 'react'
import { useSelector } from 'react-redux'

import { Divider, Spin } from 'antd'

import JobApplicationList from './JobApplicationList'
import JobApplicantDetails from './JobApplicantDetails'
import NoSelectUser from './NoSelectUser'
import { jobApplicantState } from '../store/jobApplicant.slice'

const JobApplications = ({ state, setState }) => {
    const jobApplicantStateData = useSelector(jobApplicantState)

    return (
        <>
            {jobApplicantStateData?.loaders?.applicantLoading ? (
                <div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100vh',
                        }}
                    >
                        <Spin />
                    </div>
                </div>
            ) : (
                <div style={{ display: 'flex', gap: '10px', height: '100vh' }}>
                    <div style={{ width: '25%', overflowY: 'auto' }}>
                        <JobApplicationList state={state} setState={setState} />
                    </div>
                    <Divider style={{ height: '100vh' }} type="vertical" />
                    <div style={{ width: '75%' }}>
                        {state?.selectedApplicantData ? (
                            <JobApplicantDetails
                                state={state}
                                setState={setState}
                            />
                        ) : (
                            <NoSelectUser />
                        )}
                    </div>
                </div>
            )}
        </>
    )
}

export default JobApplications
