import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import InfiniteScroll from 'react-infinite-scroll-component'
import { FileIcon, defaultStyles } from 'react-file-icon'

// antd
import {
    Card,
    Col,
    Input,
    message,
    Pagination,
    Row,
    Spin,
    Table,
    theme,
    Tooltip,
    Typography,
} from 'antd'

// slices
import {
    changePage,
    searchFile,
    filesListGet,
    totalFiles,
    filesState,
    changeEditInput,
    updateFile,
    addRemoveProjectFiles,
    getFile,
} from './files.slice'
import { loginState } from '../login/login.slice'

// components
import PanelHeader from '../../components/panelHeader/panelHeader'
import DeletePopUp from '../../components/deletePopUp/deletePopUp'
import GridViewButton from '../../components/gridView'

// modal content
import FilesModalContent from './filesModalContent'
import ViewFileModalContent from './viewFileModalContent'

// helpers
import {
    addPermissionCheck,
    deletePermissionCheck,
} from '../../helpers/permissionCheck'
import { downloadFileGet } from '../../helpers/fileDownload'
import { unique } from '../../helpers/uniqueArray'
import { formatBytes } from '../../helpers/fileFormat'

// assets
import { DeleteIcon, DownloadFileIcon, NoFilesIcon } from '../../assets/icons'

import dayjs from 'dayjs'
import { SUPER_ADMIN, WORKSPACE_ADMIN } from '../../constants/roles'

function Files() {
    const { Text, Title } = Typography

    const { useToken } = theme
    const { token } = useToken()

    const dispatch = useDispatch()

    const {
        filesIsNext,
        filesPageLimit,
        filesCurrentPage,
        filesSkipPage,
        filesCount,
        search,
        filesList,
        status,
        filter,
        editInput,
        selectedData,
        getUpdatedStatus,
    } = useSelector(filesState)
    const { userProfile } = useSelector(loginState)

    const initialState = {
        fileView: 'list',
        filteredFilesData: [],
        uploadFileModal: false,
        viewFileModal: false,
        fileTitle: '',
        fileTitleCard: '',
        deleteModal: {
            modalOpen: false,
            id: '',
        },
        deleteButtonLoading: false,
    }

    const [state, setState] = useState(initialState)

    const relativeTime = require('dayjs/plugin/relativeTime')
    dayjs.extend(relativeTime)

    const fileTypes = [
        '.png',
        '.jpg',
        '.jpeg',
        '.gif',
        '.svg',
        '.mp4',
        '.mov',
        '.wmv',
        '.mkv',
    ]

    // const videoTypes = ['.mp4', '.mov', '.wmv', '.mkv']

    // CHECK FOR LAZY LOADING IS_NEXT
    useEffect(() => {
        if (filesList?.length > 0) {
            let array = []
            filesList?.length > 0 &&
                filesList.forEach((data) => {
                    array = [...array, data]
                })
            let newArray = [...state?.filteredFilesData, ...array]
            let finalArray = unique(newArray, '_id')
            setState((prevState) => ({
                ...prevState,
                filteredFilesData: finalArray,
            }))
            if (!filesIsNext) {
                return
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filesList])

    // INITIAL FILE CALL
    const initialFileCall = (search) => {
        setState((prevState) => ({
            ...prevState,
            filteredFilesData: [],
        }))
        dispatch(
            totalFiles({
                search: search,
                projectId: userProfile?.activeProject?._id,
            })
        )
        dispatch(
            filesListGet({
                limit: filesPageLimit,
                skip: 0,
                search: search,
                projectId: userProfile?.activeProject?._id,
            })
        )
        dispatch(
            changePage({
                currentPage: 1,
                skip: 0,
                pageLimit: filesPageLimit,
            })
        )
    }

    // GET TOTAL PROJECTS COUNT AND PROJECTS LIST
    useEffect(() => {
        if (userProfile?.activeProject !== null) {
            dispatch(searchFile(''))
            initialFileCall('')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // REFRESH PROJECT
    function refreshPage() {
        setState((prevState) => ({
            ...prevState,
            filteredFilesData: [],
        }))
        dispatch(
            changePage({
                currentPage: 1,
                skip: 0,
                pageLimit: filesPageLimit,
            })
        )
    }

    // FETCH MORE FILES
    const fetchMoreFiles = async () => {
        if (filesIsNext) {
            dispatch(
                filesListGet({
                    skip: filesSkipPage + filesPageLimit,
                    limit: filesPageLimit,
                    projectId: userProfile?.activeProject?._id,
                })
            )
            dispatch(
                changePage({
                    currentPage: filesCurrentPage + 1,
                    skip: filesSkipPage + filesPageLimit,
                    pageLimit: filesPageLimit,
                })
            )
        }
    }

    // UPDATE FILE NAME
    const updateFileName = async () => {
        if (editInput?.open) {
            const result = await dispatch(
                updateFile({
                    updatedData: {
                        displayName:
                            state?.fileView === 'list'
                                ? state?.fileTitle
                                : state?.fileTitleCard,
                    },
                    fileId: editInput?.id,
                    projectId: userProfile?.activeProject?._id,
                })
            )

            const data = result?.payload?.data
            if (data) {
                dispatch(
                    changeEditInput({
                        open: false,
                        id: '',
                    })
                )
                const { success, message: checkMessage } = data
                if (success) {
                    dispatch(
                        changePage({
                            currentPage: 1,
                            skip: 0,
                            pageLimit: filesPageLimit,
                        })
                    )
                    dispatch(
                        filesListGet({
                            skip: 0,
                            limit: filesPageLimit,
                            search: search,
                            projectId: userProfile?.activeProject?._id,
                        })
                    )
                    dispatch(
                        totalFiles({
                            search: search,
                            projectId: userProfile?.activeProject?._id,
                        })
                    )
                    setState((prevState) => ({
                        ...prevState,
                        filteredFilesData: [],
                    }))
                    message.success(checkMessage)
                } else {
                    if (checkMessage) {
                        message.error(checkMessage)
                    } else {
                        message.error('Something went wrong, try again later.')
                    }
                }
            }
        }
    }

    const CardWrapper = ({ file }) => {
        const str = file?.name.split('.com/')[1]
        const substring = '_'
        const lastIndex = str.lastIndexOf(substring)
        const after = str.slice(lastIndex + 1).split('.')[1]
        return (
            <Card
                style={{
                    borderRadius: '10px',
                    border: 'transparent',
                    boxShadow: '0 0 20px rgba(0,0,0,0.05)',
                }}
                styles={{
                    body: {
                        padding: '0px',
                    },
                }}
            >
                <div
                    style={{
                        padding: '27px 54px',
                        borderBottom: `1px solid ${token.colorPalette.textColor.quinary}`,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'pointer',
                    }}
                    onClick={() => {
                        if (
                            fileTypes.some((r) =>
                                file?.name?.toLowerCase().includes(r)
                            )
                        ) {
                            viewIndividualFile(file)
                        } else {
                            window.open(file?.name, '_blank')
                        }
                    }}
                >
                    <div
                        style={{
                            height: '112px',
                            width: '90px',
                        }}
                    >
                        <FileIcon extension={after} {...defaultStyles[after]} />
                    </div>
                </div>
                <Row
                    style={{
                        padding: '11px 14px',
                    }}
                    align={'middle'}
                    justify={'space-between'}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        {editInput.id === file?._id ? (
                            <Input
                                style={{
                                    backgroundColor: 'transparent',
                                    padding: '6px 11px',
                                    fontSize: '16px',
                                    fontWeight: 500,
                                }}
                                className="active-file-edit"
                                name="fileTitleNew"
                                defaultValue={file?.displayName}
                                onKeyUp={(e) => {
                                    if (e.target.value?.length > 0) {
                                        if (e.key === 'Enter' || e.key === 13) {
                                            if (
                                                e.target.value[0].includes(' ')
                                            ) {
                                                message.error(
                                                    'No space is allowed at the beginning'
                                                )
                                            } else {
                                                updateFileName(file?._id)
                                            }
                                        }
                                    } else {
                                        message.error(
                                            'File name should not be empty'
                                        )
                                    }
                                    setState((prevState) => ({
                                        ...prevState,
                                        fileTitleCard:
                                            e.target.value.trimStart(),
                                    }))
                                }}
                            />
                        ) : (
                            <Tooltip title={file?.displayName}>
                                <Title
                                    level={5}
                                    onClick={() => {
                                        dispatch(
                                            changeEditInput({
                                                open: true,
                                                id: file?._id,
                                            })
                                        )
                                    }}
                                >
                                    {file?.displayName?.length > 20
                                        ? file?.displayName
                                              .substring(0, 20)
                                              .concat('...')
                                        : file?.displayName}
                                </Title>
                            </Tooltip>
                        )}
                        <Text>{`by ${file?.createdBy?.name}, ${dayjs(
                            file?.updatedAt
                        ).fromNow()}`}</Text>
                    </div>
                    <Row
                        align={'middle'}
                        justify={'end'}
                        style={{
                            columnGap: '10px',
                        }}
                    >
                        <div
                            style={{
                                cursor: 'pointer',
                            }}
                            onClick={() =>
                                downloadFileGet(file?.name, file?.displayName)
                            }
                        >
                            <DownloadFileIcon />
                        </div>
                        {(userProfile?.role?.roleName === WORKSPACE_ADMIN ||
                            userProfile?.role?.roleName === SUPER_ADMIN ||
                            file?.createdBy?._id === userProfile?._id ||
                            deletePermissionCheck('files', userProfile)) && (
                            <div
                                style={{
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    setState((prevState) => ({
                                        ...prevState,
                                        deleteModal: {
                                            modalOpen: true,
                                            id: file?._id,
                                        },
                                    }))
                                }}
                            >
                                <DeleteIcon />
                            </div>
                        )}
                    </Row>
                </Row>
            </Card>
        )
    }

    // VIEW INDIVIDUAL FILE
    function viewIndividualFile(item) {
        setState((prevState) => ({ ...prevState, viewFileModal: true }))
        dispatch(
            getFile({
                projectId: userProfile?.activeProject?._id,
                fileId: item?._id,
            })
        )
    }

    const columns = [
        {
            title: 'Name',
            key: 'name',
            dataIndex: 'name',
            render: (val, item) => {
                const str = val?.length > 0 ? val.split('.com/')[1] : ''
                const substring = '_'
                const lastIndex = str.lastIndexOf(substring)
                const after = str.slice(lastIndex + 1).split('.')[1]

                return (
                    <Row
                        align={'middle'}
                        style={{
                            columnGap: '16px',
                        }}
                    >
                        <div
                            style={{
                                height: '60px',
                                width: '60px',
                                cursor: 'pointer',
                            }}
                            onClick={() => {
                                if (
                                    fileTypes.some((r) =>
                                        item?.name?.toLowerCase().includes(r)
                                    )
                                ) {
                                    viewIndividualFile(item)
                                } else {
                                    window.open(item?.name, '_blank')
                                }
                            }}
                        >
                            <FileIcon
                                extension={after}
                                {...defaultStyles[after]}
                            />
                        </div>
                        <div>
                            {editInput.id === item?._id ? (
                                <Input
                                    style={{
                                        backgroundColor: 'transparent',
                                        padding: '6px 11px',
                                        fontSize: '16px',
                                        fontWeight: 500,
                                    }}
                                    className="active-file-edit"
                                    name="fileTitle"
                                    defaultValue={item?.displayName}
                                    onKeyUp={(e) => {
                                        if (e.target.value?.length > 0) {
                                            if (
                                                e.key === 'Enter' ||
                                                e.key === 13
                                            ) {
                                                if (
                                                    e.target.value[0].includes(
                                                        ' '
                                                    )
                                                ) {
                                                    message.error(
                                                        'No space is allowed at the beginning'
                                                    )
                                                } else {
                                                    updateFileName(item?._id)
                                                }
                                            }
                                        } else {
                                            message.error(
                                                'File name should not be empty'
                                            )
                                        }
                                        setState((prevState) => ({
                                            ...prevState,
                                            fileTitle:
                                                e.target.value.trimStart(),
                                        }))
                                    }}
                                />
                            ) : (
                                <Title
                                    level={5}
                                    onClick={() => {
                                        dispatch(
                                            changeEditInput({
                                                open: true,
                                                id: item?._id,
                                            })
                                        )
                                    }}
                                >
                                    {item?.displayName}
                                </Title>
                            )}
                            <Text
                                style={{
                                    color: token.colorPalette.textColor
                                        .secondary,
                                    fontSize: token.fontSizeHeading5,
                                }}
                            >
                                by {item?.createdBy?.name},{' '}
                                {dayjs(item?.updatedAt).fromNow()} -{' '}
                                {formatBytes(item?.fileSize)}
                            </Text>
                        </div>
                    </Row>
                )
            },
        },
        {
            key: 'context',
            dataIndex: 'context',
            title: '  ',
            render: (_, item) => (
                <Row justify="end" style={{ columnGap: '22px' }}>
                    <div
                        style={{
                            cursor: 'pointer',
                        }}
                        onClick={() =>
                            downloadFileGet(item?.name, item?.displayName)
                        }
                    >
                        <DownloadFileIcon />
                    </div>
                    {(userProfile?.role?.roleName === WORKSPACE_ADMIN ||
                        userProfile?.role?.roleName === SUPER_ADMIN ||
                        item?.createdBy?._id === userProfile?._id ||
                        deletePermissionCheck('files', userProfile)) && (
                        <div
                            style={{
                                cursor: 'pointer',
                            }}
                            onClick={() => {
                                setState((prevState) => ({
                                    ...prevState,
                                    deleteModal: {
                                        modalOpen: true,
                                        id: item?._id,
                                    },
                                }))
                            }}
                        >
                            <DeleteIcon />
                        </div>
                    )}
                </Row>
            ),
        },
    ]

    //  DELETE PROFILE DATA
    async function deleteFileData() {
        setState((prevState) => ({ ...prevState, deleteButtonLoading: true }))

        const result = await dispatch(
            addRemoveProjectFiles({
                id: userProfile?.activeProject?._id,
                fileObject: {
                    fieldName: 'remove',
                    files: state?.deleteModal?.id,
                },
            })
        )

        const data = result?.payload?.data

        if (data) {
            const { success, message: errorMessage } = data
            if (success) {
                dispatch(
                    filesListGet({
                        limit: filesPageLimit,
                        skip: filesSkipPage,
                        projectId: userProfile?.activeProject?._id,
                    })
                )
                dispatch(
                    totalFiles({ projectId: userProfile?.activeProject?._id })
                )
                setState((prevState) => ({
                    ...prevState,
                    deleteButtonLoading: false,
                    deleteModal: {
                        modalOpen: false,
                        id: '',
                    },
                    filteredFilesData: [],
                }))

                message.success(errorMessage)
            } else {
                setState((prevState) => ({
                    ...prevState,
                    deleteButtonLoading: false,
                    deleteModal: {
                        modalOpen: false,
                        id: '',
                    },
                }))
                if (errorMessage) {
                    message.error(errorMessage)
                } else {
                    message.error('Something went wrong, try again later.')
                }
            }
        }
    }

    const GridViewComponent = () => (
        <GridViewButton
            props={{
                onClickButton: () => initialFileCall(search),
                viewList: state?.fileView,
                setView: (key) => {
                    setState((prevState) => ({ ...prevState, fileView: key }))
                },
            }}
        />
    )

    return (
        <>
            <div
                className={'container'}
                style={{
                    zIndex: 10,
                    minHeight: '100vh',
                    padding: '35px',
                }}
                onClick={(e) => {
                    if (
                        editInput?.open === true &&
                        !e.target.classList.contains('active-file-edit')
                    ) {
                        dispatch(
                            changeEditInput({
                                open: false,
                                id: '',
                            })
                        )
                    }
                }}
            >
                <>
                    <PanelHeader
                        props={{
                            panelName: 'upload files',
                            searchBar: true,
                            filterBar: false,
                            teamMembersBar: false,
                            permissionAdd: addPermissionCheck(
                                'files',
                                userProfile
                            ),
                            gridView: true,
                            gridViewComponent: () => <GridViewComponent />,
                            action: filesListGet,
                            count: totalFiles,
                            filterValue: {
                                projectId: userProfile?.activeProject?._id,
                            },
                            userProfile: userProfile,
                            search: searchFile,
                            pageLimit: filesPageLimit,
                            filterValue: filter,
                            refreshPage: refreshPage,
                            onClick: () => {
                                setState((prevState) => ({
                                    ...prevState,
                                    uploadFileModal: true,
                                }))
                            },
                        }}
                    />
                    <Spin spinning={status === 'loading' ? true : false}>
                        {filesList?.length > 0 ? (
                            state?.fileView === 'list' ? (
                                <Table
                                    dataSource={filesList}
                                    showHeader={false}
                                    scroll={{
                                        x: 'scroll',
                                    }}
                                    rootClassName="tableGlobal"
                                    style={{
                                        borderRadius: '16px',
                                    }}
                                    columns={columns}
                                    pagination={false}
                                    // rowSelection={rowSelection}
                                />
                            ) : (
                                <InfiniteScroll
                                    dataLength={
                                        state?.filteredFilesData?.length > 0
                                            ? state?.filteredFilesData?.length
                                            : 0
                                    }
                                    next={fetchMoreFiles}
                                    hasMore={filesIsNext}
                                    style={{
                                        overflow: 'inherit',
                                    }}
                                >
                                    <Row gutter={[20, 20]}>
                                        {state?.filteredFilesData?.length > 0 &&
                                            state?.filteredFilesData.map(
                                                (file) => (
                                                    <Col
                                                        key={file?._id}
                                                        xs={24}
                                                        sm={24}
                                                        md={12}
                                                        lg={8}
                                                        xl={8}
                                                        xxl={6}
                                                    >
                                                        <CardWrapper
                                                            file={file}
                                                            active={false}
                                                        />
                                                    </Col>
                                                )
                                            )}
                                    </Row>
                                </InfiniteScroll>
                            )
                        ) : (
                            status !== 'loading' && (
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        width: '100%',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        marginTop: '120px',
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                        }}
                                    >
                                        <div
                                            style={{
                                                width: '400px',
                                            }}
                                        >
                                            <NoFilesIcon />
                                        </div>
                                    </div>
                                    <Text
                                        className="titleSecondary"
                                        style={{
                                            marginTop: '20px',
                                        }}
                                    >
                                        No Files Found yet.
                                    </Text>
                                </div>
                            )
                        )}
                        {filesList?.length > 0 &&
                            state?.fileView === 'list' && (
                                <Row
                                    justify={'end'}
                                    align={'middle'}
                                    style={{
                                        border: `2px solid ${token.colorPalette.baseColor.quaternary}`,
                                        padding: '25px',
                                        borderRadius: '0px 0px 8px 8px',
                                    }}
                                >
                                    <Text>
                                        {filesList?.length} - {filesPageLimit}{' '}
                                        of {filesCount}
                                    </Text>
                                    <Pagination
                                        defaultCurrent={filesCurrentPage}
                                        defaultPageSize={filesPageLimit}
                                        pageSizeOptions={[5, 10, 20, 50]}
                                        showSizeChanger={true}
                                        total={
                                            filesIsNext === false &&
                                            filesCurrentPage === 1
                                                ? filesList?.length
                                                : filesCount
                                        }
                                        onChange={async (value, pageSize) => {
                                            await dispatch(
                                                changePage({
                                                    currentPage: value,
                                                    skip:
                                                        (value - 1) *
                                                        filesPageLimit,
                                                    pageLimit: pageSize,
                                                })
                                            )
                                            await dispatch(
                                                filesListGet({
                                                    skip:
                                                        (value - 1) * pageSize,
                                                    limit: pageSize,
                                                    projectId:
                                                        userProfile
                                                            ?.activeProject
                                                            ?._id,
                                                })
                                            )
                                        }}
                                    />
                                </Row>
                            )}
                    </Spin>
                </>
            </div>
            <FilesModalContent
                props={{
                    visibility: state?.uploadFileModal,
                    onCancel: () => {
                        setState((prevState) => ({
                            ...prevState,
                            uploadFileModal: false,
                        }))
                    },
                    pageLimit: filesPageLimit,
                    skipPage: filesSkipPage,
                    addRemoveFiles: addRemoveProjectFiles,
                    filesGet: filesListGet,
                    totalFiles: totalFiles,
                    modalKey: 'files',
                }}
            />
            <ViewFileModalContent
                props={{
                    visibility: state?.viewFileModal,
                    onCancel: () => {
                        setState((prevState) => ({
                            ...prevState,
                            viewFileModal: false,
                        }))
                    },
                    selectedData: selectedData,
                    status: getUpdatedStatus,
                }}
            />
            <DeletePopUp
                previewDelete={state?.deleteModal?.modalOpen}
                onDelete={() => {
                    deleteFileData()
                }}
                loadingButton={state?.deleteButtonLoading}
                onCancel={() => {
                    setState((prevState) => ({
                        ...prevState,
                        deleteButtonLoading: false,
                    }))
                    setState((prevState) => ({
                        ...prevState,
                        deleteModal: {
                            modalOpen: false,
                            id: '',
                        },
                    }))
                }}
                pageKey={'File'}
                modalTitle={`Delete File`}
            />
        </>
    )
}

export default Files
