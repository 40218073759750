import { useEffect, useState } from 'react'

// antd
import { Image, Row, Col, Drawer, theme, Typography } from 'antd'

// constants
import { fileTypes, pdfTypes, videoTypes } from '../../../constants/mediaTypes'

// assets
import { ProjectsIcon } from '../../../assets/icons'

// styles
import styles from '../profile.module.css'

const MultiProjectMediaModal = ({ projectData, open, onClose }) => {
    const { useToken } = theme
    const { token } = useToken()

    const { Title, Text } = Typography

    const [primaryImage, setPrimaryImage] = useState(
        projectData?.media?.length > 0 ? projectData?.media[0] : ''
    )

    useEffect(() => {
        setPrimaryImage(
            projectData?.media?.length > 0 ? projectData?.media[0] : ''
        )
    }, [projectData])

    return (
        <>
            <Drawer
                placement="right"
                closable={false}
                open={open}
                onClose={() => {
                    onClose()
                }}
                footer={null}
                title={
                    <Row
                        align={'middle'}
                        style={{
                            columnGap: '20px',
                        }}
                    >
                        <Row
                            style={{
                                height: '60px',
                                width: '60px',
                                borderRadius: '10px',
                                padding: '12px',
                                backgroundColor:
                                    token.colorPalette.baseColor.tertiary,
                            }}
                            align={'middle'}
                            justify={'center'}
                        >
                            <div
                                style={{
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    fill: 'red',
                                }}
                            >
                                <ProjectsIcon />
                            </div>
                        </Row>
                        <div>
                            <Title
                                className={'titleMiddle'}
                                style={{ textTransform: 'capitalize' }}
                            >
                                {projectData?.projectName}
                            </Title>
                            <Text
                                style={{
                                    fontSize: token.fontSizeLG,
                                }}
                                className={'titleLight'}
                            >
                                {new Date(projectData?.start)?.toLocaleString(
                                    'default',
                                    {
                                        year: 'numeric',
                                        month: 'long',
                                    }
                                )}{' '}
                                -{' '}
                                {new Date(projectData?.end)?.toLocaleString(
                                    'default',
                                    {
                                        year: 'numeric',
                                        month: 'long',
                                    }
                                )}
                            </Text>
                        </div>
                    </Row>
                }
                style={{
                    height: '100vh',
                    minWidth: '1200px',
                    width: '1200px',
                }}
                styles={{
                    body: {
                        padding: '0px',
                        backgroundColor: '#EBECED',
                    },
                    wrapper: {
                        width: 'auto !important',
                    },
                }}
                rootClassName="talentViewDrawer"
            >
                <Row
                    style={{
                        minHeight: '100%',
                    }}
                >
                    <Col span={14}>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                rowGap: '20px',
                            }}
                        >
                            {fileTypes.some((r) =>
                                primaryImage?.toLowerCase().includes(r)
                            ) ? (
                                <>
                                    <Image
                                        src={primaryImage}
                                        width={'663px'}
                                        height={'517px'}
                                        preview={false}
                                        style={{
                                            objectFit: 'contain',
                                            padding: '30px',
                                            minHeight: '517px',
                                            minWidth: '663px',
                                        }}
                                    />
                                </>
                            ) : videoTypes.some((r) =>
                                  primaryImage?.toLowerCase().includes(r)
                              ) ? (
                                <video
                                    width="100%"
                                    height="540px"
                                    autoPlay
                                    controls
                                    className={styles.videoViewContainer}
                                >
                                    <source
                                        src={primaryImage}
                                        type="video/mp4"
                                    />
                                </video>
                            ) : pdfTypes.some((r) =>
                                  primaryImage?.toLowerCase().includes(r)
                              ) ? (
                                <>
                                    <div>
                                        <iframe
                                            src={`${primaryImage}#view=fitH`}
                                            height={'540px'}
                                            width="100%"
                                            title="PDF"
                                        />
                                    </div>
                                </>
                            ) : (
                                <></>
                            )}
                            <Row
                                align={'middle'}
                                style={{
                                    columnGap: '15px',
                                    overflowX: 'scroll',
                                    flexWrap: 'nowrap',
                                    paddingLeft: '30px',
                                }}
                            >
                                {projectData?.media?.length > 0 &&
                                    projectData?.media.map((file) =>
                                        fileTypes.some((r) =>
                                            file?.toLowerCase().includes(r)
                                        ) ? (
                                            <>
                                                <Image
                                                    src={file}
                                                    width={'133px'}
                                                    height={'88px'}
                                                    style={{
                                                        cursor: 'pointer',
                                                        borderRadius: '10px',
                                                        objectFit: 'contain',
                                                        minWidth: '133px',
                                                        boxShadow:
                                                            '0px 3px 6px 0px rgba(140, 149, 159, 0.15)',
                                                    }}
                                                    onClick={() => {
                                                        setPrimaryImage(file)
                                                    }}
                                                    preview={false}
                                                />
                                            </>
                                        ) : videoTypes.some((r) =>
                                              file?.toLowerCase().includes(r)
                                          ) ? (
                                            <video
                                                width="133px"
                                                height="88px"
                                                autoPlay
                                                controls
                                                className={
                                                    styles.videoViewContainer
                                                }
                                                style={{
                                                    cursor: 'pointer',
                                                    borderRadius: '10px',
                                                    minWidth: '133px',
                                                }}
                                                onClick={() => {
                                                    setPrimaryImage(file)
                                                }}
                                            >
                                                <source
                                                    src={file}
                                                    type="video/mp4"
                                                />
                                            </video>
                                        ) : pdfTypes.some((r) =>
                                              file?.toLowerCase().includes(r)
                                          ) ? (
                                            <>
                                                <div
                                                    style={{
                                                        cursor: 'pointer',
                                                    }}
                                                >
                                                    <iframe
                                                        src={`${file}#view=fitH`}
                                                        height={'88px'}
                                                        width="133px"
                                                        title="PDF"
                                                        style={{
                                                            borderRadius:
                                                                '10px',
                                                            minWidth: '133px',
                                                        }}
                                                        onClick={() => {
                                                            setPrimaryImage(
                                                                file
                                                            )
                                                        }}
                                                    />
                                                </div>
                                            </>
                                        ) : (
                                            <></>
                                        )
                                    )}
                            </Row>
                        </div>
                    </Col>
                    <Col
                        span={10}
                        style={{
                            backgroundColor: token.colorPalette.baseColor.white,
                            height: 'inherit',
                            padding: '20px',
                            boxShadow: '0px 4px 5px 5px rgba(0, 0, 0, 0.02)',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                rowGap: '10px',
                            }}
                        >
                            {projectData?.links?.length > 0 ? (
                                projectData?.links.map((link) =>
                                    link?.url?.length > 0 ? (
                                        <Text
                                            style={{
                                                fontSize:
                                                    token.fontSizeHeading5,
                                                color: '#192EE7',
                                                textDecoration: 'underline',
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => {
                                                window.open(link?.url, '_blank')
                                            }}
                                        >
                                            {link?.url}
                                        </Text>
                                    ) : (
                                        <></>
                                    )
                                )
                            ) : (
                                <></>
                            )}
                            <Text
                                style={{
                                    fontSize: '18px',
                                }}
                            >
                                {projectData?.summary}
                            </Text>
                        </div>
                    </Col>
                </Row>
            </Drawer>
        </>
    )
}

export default MultiProjectMediaModal
