import { useSelector } from 'react-redux'

// antd
import { Divider, Row, Space, Typography, theme } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

// slices
import { profileState } from '../../modules/profile/profile.slice'

const DropDownMultiple = ({ menu, searchValue, name, addMasterData }) => {
    const { Text } = Typography
    const { useToken } = theme

    const { masterDataDropDown } = useSelector(profileState)

    const { token } = useToken()

    return (
        <>
            {menu}
            <Divider
                style={{
                    margin: '8px 0',
                }}
            />
            <Space
                style={{
                    padding: '0 8px 4px',
                    cursor:
                        searchValue?.length > 0 &&
                        ((masterDataDropDown[name]?.length > 0 &&
                            masterDataDropDown[name].every(
                                (data) =>
                                    data?.label?.toLowerCase() !==
                                    searchValue?.toLowerCase()
                            )) ||
                            masterDataDropDown[name]?.length === 0)
                            ? 'pointer'
                            : 'not-allowed',
                }}
            >
                <Row
                    align={'middle'}
                    style={{
                        columnGap: '5px',
                    }}
                    onClick={() => {
                        if (
                            searchValue?.length > 0 &&
                            ((masterDataDropDown[name]?.length > 0 &&
                                masterDataDropDown[name].every(
                                    (data) =>
                                        data?.label?.toLowerCase() !==
                                        searchValue?.toLowerCase()
                                )) ||
                                masterDataDropDown[name]?.length === 0)
                        ) {
                            addMasterData()
                        }
                    }}
                >
                    <PlusOutlined
                        style={{
                            color: token.colorPalette.baseColor.tertiary,
                            fontSize: token.fontSizeHeading5,
                        }}
                    />
                    <Text
                        style={{
                            color: token.colorPalette.baseColor.tertiary,
                            fontSize: token.fontSizeHeading5,
                            fontWeight: token.fontWeightStrong,
                        }}
                    >
                        Add
                    </Text>
                </Row>
            </Space>
        </>
    )
}

export default DropDownMultiple
