import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// antd
import {
    Popover,
    Row,
    Typography,
    Card,
    Avatar,
    theme,
    Space,
    Badge,
    Image,
    Tooltip,
} from 'antd'

// slices
import { loginState } from '../login/login.slice'
import { roleList } from './users.slice'

// assets
import {
    EditIcon,
    EllipsisIcon,
    MailIcon,
    TerminateIcon,
} from '../../assets/icons'

// constants
import { SUPER_ADMIN, WORKSPACE_ADMIN } from '../../constants/roles'

const UserGridList = ({ user, openTerminateModal, openRoleEditModal }) => {
    const { useToken } = theme

    const { token } = useToken()

    const { Title, Text } = Typography

    const dispatch = useDispatch()

    const { userProfile } = useSelector(loginState)

    const [open, setOpen] = useState(false)

    const handleOpenChange = (newOpen) => {
        setOpen(newOpen)
    }
    const dropDownIcon = [
        // editPermissionCheck('users', userProfile)
        (userProfile?.role?.roleName === WORKSPACE_ADMIN ||
            userProfile?.role?.roleName === SUPER_ADMIN) && {
            key: '1',
            title: (
                <Row style={{ columnGap: '10px' }}>
                    <div
                        style={{
                            height: '18px',
                            width: '18px',
                        }}
                    >
                        <EditIcon />
                    </div>
                    <Text> Role Edit</Text>
                </Row>
            ),
            onClick: () => {
                setOpen(false)
                dispatch(roleList())
                openRoleEditModal(user)
            },
        },
        {
            key: '2',
            title:
                user?.isOurUser &&
                (userProfile?.role?.roleName === WORKSPACE_ADMIN ||
                    userProfile?.role?.roleName === SUPER_ADMIN) ? (
                    <Row style={{ columnGap: '10px' }}>
                        <div
                            style={{
                                height: '18px',
                                width: '18px',
                            }}
                        >
                            <TerminateIcon />
                        </div>
                        <Text
                            style={{
                                fontSize: '14px',
                                color: token.colorPalette.baseColor.error,
                            }}
                        >
                            Terminate
                        </Text>
                    </Row>
                ) : (
                    <Tooltip title="You have no permission to terminate this user.">
                        <Row style={{ columnGap: '10px' }}>
                            <div
                                style={{
                                    height: '18px',
                                    width: '18px',
                                }}
                            >
                                <TerminateIcon />
                            </div>
                            <Text
                                style={{
                                    fontSize: '14px',
                                    color: token.colorPalette.baseColor.error,
                                }}
                            >
                                Terminate
                            </Text>
                        </Row>
                    </Tooltip>
                ),

            onClick: () => {
                if (
                    user?.isOurUser &&
                    (userProfile?.role?.roleName === WORKSPACE_ADMIN ||
                        userProfile?.role?.roleName === SUPER_ADMIN)
                ) {
                    setOpen(false)
                    openTerminateModal(user)
                }
            },
        },
    ]

    const dropDownContent = () => {
        return (
            <>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        rowGap: '6px',
                    }}
                >
                    {dropDownIcon?.length > 0 &&
                        dropDownIcon.map((data) => (
                            <Row
                                style={{
                                    cursor: 'pointer',
                                }}
                                onClick={() => data?.onClick(user?._id)}
                            >
                                <div>{data?.icon}</div>
                                <Text className="sub-title">{data?.title}</Text>
                            </Row>
                        ))}
                </div>
            </>
        )
    }

    return (
        <Card
            style={{
                cursor: 'pointer',
                borderRadius: '16px',
                border: 'transparent',
                boxShadow: '0 0 20px rgba(0,0,0,0.05)',
            }}
            styles={{
                body: {
                    padding: '22px 20px',
                },
            }}
        >
            <Row
                align={'center'}
                style={{
                    alignItems: 'flex-start',
                }}
                justify={'space-between'}
            >
                {user?.profilePicture ? (
                    <>
                        <Image
                            src={user?.profilePicture}
                            height={64}
                            width={64}
                            style={{
                                borderRadius: '50%',
                            }}
                            preview={false}
                        />
                    </>
                ) : (
                    <Avatar
                        style={{
                            cursor: 'pointer',
                            backgroundColor: 'rgb(0 10 129 / 25%)',
                        }}
                        size={64}
                    >
                        {user?.name[0].toUpperCase()}
                        {user?.name.split(' ')[1] !== undefined &&
                            user?.name.split(' ')[1][0].toUpperCase()}
                    </Avatar>
                )}
                <Row align={'middle'}>
                    <Space size={'large'}>
                        <Badge
                            count={user?.isActive ? 'Active' : 'InActive'}
                            color={
                                user?.isActive
                                    ? 'hsla(155, 54%, 46%, 0.16)'
                                    : 'hsla(11, 100%, 59%, 0.16)'
                            }
                            style={{
                                minWidth: '30px',
                                cursor: 'auto',
                                borderRadius: '6px',
                                display: 'flex',
                                alignItems: 'center',
                                color: user?.isActive
                                    ? token.colorPalette.iconColor.tertiary
                                    : token.colorPalette.iconColor.quaternary,
                                padding: '12px 8px',
                                fontWeight: 700,
                            }}
                        />
                        <Popover
                            content={dropDownContent}
                            trigger={'click'}
                            placement="left"
                            open={open}
                            overlayInnerStyle={{
                                padding: '10px',
                            }}
                            arrow={false}
                            onOpenChange={handleOpenChange}
                            getPopupContainer={(triggerNode) => triggerNode}
                        >
                            <div>
                                <EllipsisIcon />
                            </div>
                        </Popover>
                    </Space>
                </Row>
            </Row>
            <Row
                style={{
                    marginTop: '10px',
                    columnGap: '6px',
                }}
                align={'middle'}
            >
                <Title level={5}>{user?.name}</Title>
                <Text
                    style={{
                        color: token.colorPalette.textColor.senary,
                    }}
                >
                    {user?.designation?.name}
                </Text>
            </Row>
            <Row
                style={{
                    marginTop: '10px',
                }}
                align={'middle'}
            >
                <Space>
                    <MailIcon />
                    <Text>{user?.email}</Text>
                </Space>
            </Row>
        </Card>
    )
}

export default UserGridList
