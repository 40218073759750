import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// antd
import { Empty, Spin, Typography, theme } from 'antd'

// slices
import {
    jobState,
    changePage,
    getListJobs,
    searchJob,
    filterChange,
    clearFilter,
    switchJobModal,
} from './store/job.slice'

// components
import PanelHeader from '../../components/panelHeader/panelHeader'
import JobCards from './components/JobCards'

// assets
import {
    ActiveIcon,
    InActiveIcon,
    NonVerifyTalentIcon,
    VerifyTalentIcon,
} from '../../assets/icons'

// helpers
import { unique } from '../../helpers/uniqueArray'
import AddEditJob from './components/AddEditJob'

function Jobs() {
    const { useToken } = theme
    const { token } = useToken()

    const { Text } = Typography

    const dispatch = useDispatch()

    const {
        modalVariations,
        pageLimit,
        isNext,
        status,
        jobList,
        skipPage,
        filter,
    } = useSelector(jobState)

    const initialState = {
        jobView: 'grid',
        filteredJobData: [],
    }

    // STATE
    const [state, setState] = useState(initialState)

    // CHECK FOR LAZY LOADING IS_NEXT
    useEffect(() => {
        if (jobList?.length > 0) {
            let array = []
            jobList?.length > 0 &&
                jobList.forEach((data) => {
                    array = [...array, data]
                })
            let newArray = [...state?.filteredJobData, ...array]
            let finalArray = unique(newArray, '_id')
            setState((prevState) => ({
                ...prevState,
                filteredJobData: finalArray,
            }))
            if (!isNext) {
                return
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [jobList])

    // REFRESH PAGE
    function refreshPage() {
        setState((prevState) => ({
            ...prevState,
            filteredJobData: [],
        }))
        dispatch(
            changePage({
                currentPage: 1,
                skip: 0,
                pageLimit,
            })
        )
    }

    const initialJobCall = (search) => {
        setState((prevState) => ({
            ...prevState,
            filteredJobData: [],
        }))
        dispatch(
            changePage({
                currentPage: 1,
                skip: 0,
                pageLimit: state?.jobView === 'grid' ? pageLimit : 10,
            })
        )
        dispatch(
            getListJobs({
                limit: state?.jobView === 'grid' ? pageLimit : 10,
                skip: 0,
                search: search,
                filter: { ...filter },
            })
        )
    }

    // GET INITIAL TALENT LIST
    useEffect(() => {
        initialJobCall('')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const rootSubmenuKeys = ['verification', 'status']

    const filterType = [
        {
            key: 'status',
            label: 'Status',
            children: [
                {
                    label: 'Active',
                    name: 'isActive',
                    key: 'active',
                    flag: true,
                    icon: (
                        <div
                            style={{
                                height: '20px',
                                width: '20px',
                            }}
                        >
                            <ActiveIcon />
                        </div>
                    ),
                },
                {
                    label: 'InActive',
                    name: 'isActive',
                    key: 'inActive',
                    flag: false,
                    icon: (
                        <div
                            style={{
                                height: '20px',
                                width: '20px',
                            }}
                        >
                            <InActiveIcon />
                        </div>
                    ),
                },
            ],
        },
        {
            label: (
                <Text
                    style={{
                        color: token.colorPalette.baseColor.error,
                    }}
                >
                    Clear Filter
                </Text>
            ),
            name: 'clear',
        },
    ]

    return (
        <>
            <div
                className={'container'}
                style={{
                    zIndex: 10,
                    minHeight: '100vh',
                    padding: '35px',
                }}
            >
                <>
                    <PanelHeader
                        props={{
                            panelName: 'add job',
                            panelHeaderName: 'Job',
                            searchBar: true,
                            filterBar: true,
                            isCount: false,
                            teamMembersBar: false,
                            permissionAdd: true,
                            gridView: false,
                            rootSubmenuKeys,
                            filterType: null,
                            action: getListJobs,
                            search: searchJob,
                            changeFilter: filterChange,
                            clearFilter: clearFilter,
                            pageLimit: pageLimit,
                            skipPage: skipPage,
                            filterValue: {
                                ...filter,
                            },
                            changePageGrid: () => {
                                dispatch(
                                    changePage({
                                        currentPage: 1,
                                        skip: 0,
                                        pageLimit: 10,
                                    })
                                )
                            },

                            clearData: () =>
                                setState((prevState) => ({
                                    ...prevState,
                                    filteredJobData: [],
                                })),
                            refreshPage: refreshPage,
                            onClick: () => {
                                dispatch(
                                    switchJobModal({
                                        ...modalVariations,
                                        open: true,
                                    })
                                )
                            },
                        }}
                    />

                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: 20 }}>
                        {status === 'loading' ? (
                            <>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        height: '50vh',
                                        width: '100%',
                                    }}
                                >
                                    <Spin />
                                </div>
                            </>
                        ) : (
                            <>
                                {jobList?.length > 0 ? (
                                    jobList?.map((job) => (
                                        <JobCards job={job} />
                                    ))
                                ) : (
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            height: '50vh',
                                            width: '100%',
                                        }}
                                    >
                                        <Empty />
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </>
            </div>

            <AddEditJob />
        </>
    )
}

export default Jobs
