import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import S3FileUpload from 'react-s3'
import { debounce } from 'lodash'

// antd
import {
    Col,
    DatePicker,
    Divider,
    Form,
    Row,
    Select,
    Typography,
    Input,
    theme,
    Upload,
    message,
    Image,
    Space,
    Spin,
} from 'antd'

// slices
import { loginState } from '../../login/login.slice'
import {
    addSummary,
    changeModel,
    checkButtonType,
    masterDataAddDropDown,
    masterDataDropDownList,
    profileLoading,
    profileState,
    switchProfileModal,
    updateUserGet,
} from '../profile.slice'

// components
import ModalProfile from '../../../components/modal'
import DropDownMultiple from '../../../components/dropDownMultiple'

// assets
import {
    BinIcon,
    CertificateIcon,
    PdfIcon,
    PreviewIcon,
    ProviderDefaultIcon,
} from '../../../assets/icons'

// modals
import ViewCertificatesModal from './viewCertificatesModal'

// constants
import { MASTER_DATA_FIELD } from '../../../constants/masterData'

// helpers
import { isEmpty } from '../../../helpers/fieldCheck'

import dayjs from 'dayjs'

function CertificateModal({ modalType }) {
    const [form] = Form.useForm()

    const { Text } = Typography
    const { Dragger } = Upload

    const { Option } = Select

    const { TextArea } = Input

    const { useToken } = theme
    const { token } = useToken()

    const dispatch = useDispatch()

    // SELECTORS
    const {
        profileModal,
        formButtonType,
        selectedData,
        updatedDataStatus,
        modelType,
        masterDataDropDown,
        masterDataDropDownStatus,
    } = useSelector(profileState)
    const { userProfile } = useSelector(loginState)

    // STATES
    const [stream, setStream] = useState(false)
    const [fileURL, setFileURL] = useState([])
    const [viewCertificatesModal, setViewCertificatesModal] = useState({
        open: false,
        fileName: '',
    })
    const [errorFile, setErrorFile] = useState('')
    const [searchValue, setSearchValue] = useState('')

    // FORMAT DATEPICKER
    const formatDatePicker = (value) => {
        return `${dayjs(value).format('DD MMM YY')}`
    }

    // EDIT MODAL DATA INITIALLY
    useEffect(() => {
        if (selectedData) {
            setFileURL(selectedData?.fileLink)

            const {
                _id: valueCertificate,
                name: labelCertificate,
                logo: logoCertificate,
            } = {
                ...selectedData?.certificateProvider,
            }

            const new_obj_certificate = Object.assign(
                {},
                {
                    label: labelCertificate && (
                        <Space size={'large'}>
                            <div
                                style={{
                                    width: '30px',
                                    height: '30px',
                                    padding: '8px 5px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    border: `1px solid ${token.colorPalette.textColor.quinary}`,
                                }}
                            >
                                {logoCertificate ? (
                                    <Image
                                        src={logoCertificate}
                                        height={'30px'}
                                        width={'30px'}
                                        preview={false}
                                    />
                                ) : (
                                    <div
                                        style={{
                                            width: '30px',
                                            height: '30px',
                                        }}
                                    >
                                        <ProviderDefaultIcon />
                                    </div>
                                )}
                            </div>
                            <Text>{labelCertificate}</Text>
                        </Space>
                    ),
                    value: valueCertificate,
                }
            )

            form.setFieldsValue({
                ...selectedData,
                certificateProvider: selectedData?.certificateProvider
                    ? [{ ...new_obj_certificate }]
                    : [],
                fieldOfStudy: selectedData?.fieldOfStudy
                    ? [
                          {
                              label: selectedData?.fieldOfStudy?.name,
                              value: selectedData?.fieldOfStudy?._id,
                          },
                      ]
                    : [],
                dateReceived: selectedData?.dateReceived
                    ? dayjs(selectedData?.dateReceived)
                    : '',
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form, selectedData])

    // SAVE / SAVE AND ADD MORE / SAVE AS DRAFT CERTIFICATES
    async function onFinishCertificates(value) {
        let modalState = { ...profileModal }
        setErrorFile('')

        delete value['fileLink']

        if (formButtonType === 'draftButtonLoading') {
            if (
                Object.keys(value).every((item) => isEmpty(value[item])) &&
                fileURL?.length === 0
            ) {
                message.info('Please Fill at-least one Field')
                form.resetFields()

                return
            }
        }
        dispatch(
            profileLoading({
                name: modalType,
                buttonName: formButtonType,
                loading: true,
            })
        )

        const result = await dispatch(
            addSummary({
                _id: userProfile?._id,
                summary: {
                    step: modalType,
                    data: {
                        ...value,
                        certificateProvider:
                            typeof value.certificateProvider === 'object'
                                ? value.certificateProvider[0]?.value
                                : value.certificateProvider,
                        fieldOfStudy:
                            typeof value.fieldOfStudy === 'object'
                                ? value.fieldOfStudy[0]?.value
                                : value.fieldOfStudy,
                        _id: selectedData && selectedData?._id,
                        fileLink: fileURL?.length > 0 ? fileURL : [],
                        dateReceived: new Date(value?.dateReceived).getTime(),
                        isCompleted:
                            formButtonType === 'draftButtonLoading'
                                ? false
                                : true,
                    },
                },
            })
        )
        const data = result?.payload?.data

        if (data) {
            const { success, message: errorMessage } = data
            if (success) {
                dispatch(
                    profileLoading({
                        name: modalType,
                        buttonName: formButtonType,
                        loading: false,
                    })
                )
                if (formButtonType === 'addMoreButtonLoading') {
                    form.resetFields()
                    setFileURL([])
                    dispatch(
                        switchProfileModal({
                            name: 'certificates',
                            open: true,
                        })
                    )
                } else {
                    dispatch(
                        switchProfileModal({
                            name: 'certificates',
                            open: false,
                        })
                    )
                }
                dispatch(updateUserGet({ _id: userProfile?._id }))
                message.success(errorMessage)
            } else {
                dispatch(switchProfileModal(modalState))
                if (errorMessage) {
                    message.error(errorMessage)
                } else {
                    message.error('Something went wrong, try again later.')
                }
            }
        }
    }

    function onFinishFailed() {}

    // ADD MASTER DATA
    async function addMasterData() {
        const result = await dispatch(
            masterDataAddDropDown({
                addData: {
                    type: 'manual',
                    data: {
                        name: searchValue,
                        logo:
                            modelType === 'certificateProvider'
                                ? 'https://pms-bucket123.s3.amazonaws.com/Layer_1_1689661346.svg'
                                : '',
                        createdBy: userProfile?._id,
                    },
                },
                model: modelType,
            })
        )
        const data = result?.payload?.data

        if (data) {
            const { success, message: errorMessage } = data
            if (success) {
                dispatch(
                    masterDataDropDownList({
                        search: searchValue,
                        id: modelType,
                        // limit: pageLimit,
                        // skip: skipPage,
                    })
                )
            } else {
                if (errorMessage) {
                    message.error(errorMessage)
                } else {
                    message.error('Something went wrong, try again later.')
                }
            }
        }
    }

    // SEARCH MASTER DATA
    const searchFn = debounce((value, model) => {
        // dispatch(clear())
        // dispatch(
        //     count({
        //         _id: modelType?.name,
        //     })
        // )
        // dispatch(search(value))
        if (value?.length > 0) {
            dispatch(
                masterDataDropDownList({
                    search: value,
                    id: model,
                    // limit: pageLimit,
                    // skip: skipPage,
                })
            )
        }
        setSearchValue(value)
    }, 800)

    // DISABLE END DATE
    const disabledDateEnd = (current) => {
        const endDate = form.getFieldValue('dateReceived')
        // Disable dates that are after the selected end date or dates that are in the future
        return (
            (endDate && current && current.valueOf() > endDate.valueOf()) ||
            (current && current.valueOf() > Date.now())
        )
    }

    // ONCHANGE FILE PROPS
    const props = {
        name: 'file',
        multiple: true,
        onChange(info) {
            let fileUpload = []
            setErrorFile('')
            if (
                fileURL?.length > 6 ||
                (fileURL?.length < 6 &&
                    info?.fileList?.length + fileURL?.length > 6)
            ) {
                setErrorFile('Maximum limit of Certificate is 6')
                return
            }

            // CHECK FILE SIZE
            const isLt5M =
                info?.fileList?.length > 0 &&
                info?.fileList.some((data) => data?.size / 1024 / 1024 < 5)

            if (!isLt5M) {
                message.error('File must be smaller than 5MB')
                return
            }

            const fileUploadCheck =
                info?.fileList?.length > 0 &&
                info?.fileList
                    .filter((data) => data?.size / 1024 / 1024 < 5)
                    .map((data) => data)

            setStream(true)
            fileUploadCheck?.length > 0 &&
                fileUploadCheck.forEach((data) => {
                    const nameChange = data?.name
                        .split('.')[0]
                        .replaceAll('\\s+', '')
                        .concat(`_${dayjs(new Date()).unix()}`)
                        .concat(`.${data?.name.split('.')[1]}`)

                    const newFileData = new File(
                        [data?.originFileObj],
                        nameChange,
                        { type: data?.type }
                    )
                    window.Buffer = window.Buffer || require('buffer').Buffer

                    S3FileUpload.uploadFile(newFileData, userProfile?.s3Config)
                        .then(async (data) => {
                            if (
                                fileUploadCheck?.length ===
                                fileUpload?.length + 1
                            ) {
                                setStream(false)
                            }
                            fileUpload = [...fileUpload, data?.location]
                            setFileURL([...fileURL, ...fileUpload])
                        })
                        .catch(() => {
                            message.error('Upload Failed!. Please Upload again')
                        })
                })
            // info?.preventDefault()
        },
        beforeUpload() {
            return false
        },
        onDrop() {},
    }

    return updatedDataStatus === 'loading' ? (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
            }}
        >
            <Spin size="large" />
        </div>
    ) : (
        <>
            <Form
                labelCol={{
                    style: {
                        padding: '0 0 3px',
                    },
                }}
                layout="vertical"
                name="addCertificates"
                onFinish={onFinishCertificates}
                onSubmitCapture={async (e) => {
                    await dispatch(checkButtonType(e.nativeEvent.submitter.id))
                }}
                onFinishFailed={onFinishFailed}
                requiredMark={false}
                form={form}
                style={{
                    marginBottom: '20px',
                    overflowX: 'hidden',
                }}
            >
                <Row
                    style={{
                        rowGap: '20px',
                    }}
                    justify={'space-between'}
                    gutter={16}
                >
                    <Col span={12}>
                        <Form.Item
                            name={'title'}
                            label="Title*"
                            rules={
                                formButtonType !== 'draftButtonLoading' && [
                                    {
                                        required: true,
                                        message: 'Please Enter Title',
                                    },
                                    {
                                        max: 50,
                                        message:
                                            'Title should be maximum 50 characters long',
                                    },
                                ]
                            }
                        >
                            <Input
                                placeholder="Enter Title"
                                onChange={(e) => {
                                    form.setFieldValue(
                                        'title',
                                        e.target.value.trimStart()
                                    )
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Certificate Provider*"
                            name={MASTER_DATA_FIELD.certificateProvider}
                            rules={
                                formButtonType !== 'draftButtonLoading' && [
                                    {
                                        required: true,
                                        message: 'Please Select Provider',
                                    },
                                ]
                            }
                            colon={false}
                        >
                            <Select
                                placeholder="Search Certificate Provider"
                                showSearch
                                onSearch={(value) => {
                                    dispatch(
                                        changeModel(
                                            MASTER_DATA_FIELD.certificateProvider
                                        )
                                    )
                                    searchFn(
                                        value,
                                        MASTER_DATA_FIELD.certificateProvider
                                    )
                                }}
                                filterOption={false}
                                allowClear={false}
                                dropdownRender={(menu) => (
                                    <DropDownMultiple
                                        menu={menu}
                                        searchValue={searchValue}
                                        name={
                                            MASTER_DATA_FIELD.certificateProvider
                                        }
                                        addMasterData={() => addMasterData()}
                                    />
                                )}
                                getPopupContainer={(trigger) =>
                                    trigger.parentNode
                                }
                            >
                                {masterDataDropDown?.certificateProvider
                                    ?.length > 0 &&
                                masterDataDropDownStatus === 'loaded'
                                    ? masterDataDropDown?.certificateProvider.map(
                                          (option) => {
                                              return (
                                                  <Option
                                                      key={option?.value}
                                                      value={option?.value}
                                                  >
                                                      <Space size={'large'}>
                                                          <div
                                                              style={{
                                                                  width: '30px',
                                                                  height: '30px',
                                                                  padding:
                                                                      '8px 5px',
                                                                  display:
                                                                      'flex',
                                                                  alignItems:
                                                                      'center',
                                                                  justifyContent:
                                                                      'center',
                                                                  border: `1px solid ${token.colorPalette.textColor.quinary}`,
                                                              }}
                                                          >
                                                              {option?.logo ? (
                                                                  <Image
                                                                      src={
                                                                          option?.logo
                                                                      }
                                                                      height={
                                                                          '30px'
                                                                      }
                                                                      width={
                                                                          '30px'
                                                                      }
                                                                      preview={
                                                                          false
                                                                      }
                                                                  />
                                                              ) : (
                                                                  <div
                                                                      style={{
                                                                          width: '30px',
                                                                          height: '30px',
                                                                      }}
                                                                  >
                                                                      <ProviderDefaultIcon />
                                                                  </div>
                                                              )}
                                                          </div>
                                                          <Text>
                                                              {option?.label}
                                                          </Text>
                                                      </Space>
                                                  </Option>
                                              )
                                          }
                                      )
                                    : masterDataDropDownStatus ===
                                          'loading' && <Spin></Spin>}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Field of Study*"
                            name={MASTER_DATA_FIELD.fieldOfStudy}
                            rules={
                                formButtonType !== 'draftButtonLoading' && [
                                    {
                                        required: true,
                                        message: 'Please Select Field of Study',
                                    },
                                ]
                            }
                            colon={false}
                        >
                            <Select
                                placeholder="Search a Field of Study"
                                showSearch
                                onSearch={(value) => {
                                    dispatch(
                                        changeModel(
                                            MASTER_DATA_FIELD.fieldOfStudy
                                        )
                                    )
                                    searchFn(
                                        value,
                                        MASTER_DATA_FIELD.fieldOfStudy
                                    )
                                }}
                                filterOption={false}
                                allowClear={false}
                                dropdownRender={(menu) => (
                                    <DropDownMultiple
                                        menu={menu}
                                        searchValue={searchValue}
                                        name={MASTER_DATA_FIELD.fieldOfStudy}
                                        addMasterData={() => addMasterData()}
                                    />
                                )}
                                getPopupContainer={(trigger) =>
                                    trigger.parentNode
                                }
                            >
                                {masterDataDropDown?.fieldOfStudy?.length > 0 &&
                                masterDataDropDownStatus === 'loaded'
                                    ? masterDataDropDown?.fieldOfStudy.map(
                                          (option) => {
                                              return (
                                                  <Option
                                                      key={option?.value}
                                                      value={option?.value}
                                                  >
                                                      <Text>
                                                          {option?.label}
                                                      </Text>
                                                  </Option>
                                              )
                                          }
                                      )
                                    : masterDataDropDownStatus ===
                                          'loading' && <Spin></Spin>}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Date Received*"
                            name="dateReceived"
                            rules={
                                formButtonType !== 'draftButtonLoading' && [
                                    {
                                        required: true,
                                        message: 'Please Select Date',
                                    },
                                ]
                            }
                            colon={false}
                        >
                            <DatePicker
                                allowClear={false}
                                name={'dateReceived'}
                                format={formatDatePicker}
                                disabledDate={disabledDateEnd}
                                style={{ width: '100%' }}
                                getPopupContainer={() => document.body}
                                // getPopupContainer={(trigger) =>
                                //     trigger.parentNode
                                // }
                            />
                        </Form.Item>
                    </Col>
                    <Divider />
                    <Col span={12}>
                        <Form.Item
                            name={'fileLink'}
                            rules={
                                formButtonType !== 'draftButtonLoading' && [
                                    {
                                        validator: async () => {
                                            if (fileURL?.length === 0) {
                                                return Promise.reject(
                                                    new Error(
                                                        'Please Upload at-lease one Certificate'
                                                    )
                                                )
                                            } else {
                                                return
                                            }
                                        },
                                    },
                                ]
                            }
                            className="fileDragger"
                            help={fileURL?.length === 0 ? null : ''}
                        >
                            <Dragger
                                {...props}
                                fileList={[]}
                                previewFile={false}
                                listType="picture-card"
                                style={{
                                    border: `2px dashed ${token.colorPalette.textColor.quaternary}`,
                                    padding: '20px',
                                    height: '148px',
                                }}
                                accept=".png,.jpg,.jpeg,.pdf"
                                disabled={stream}
                            >
                                {stream ? (
                                    <>
                                        <Text
                                            className="titleLight titleMiddle"
                                            style={{}}
                                        >
                                            Uploading....
                                        </Text>
                                    </>
                                ) : (
                                    <>
                                        <Text
                                            className="titleLight titleMiddle"
                                            style={{}}
                                        >
                                            Click or Drag
                                        </Text>
                                        <br />
                                        <Text
                                            className="titleLight"
                                            style={{
                                                fontSize:
                                                    token.fontSizeHeading5,
                                            }}
                                        >
                                            File to Upload (Max 6 Certificates)
                                        </Text>
                                    </>
                                )}
                            </Dragger>
                        </Form.Item>
                        {errorFile?.length > 0 && (
                            <span style={{ color: 'red', margin: 2 }}>
                                {errorFile}
                            </span>
                        )}
                        {fileURL?.length > 0 && (
                            <Row
                                style={{
                                    marginTop: '14px',
                                }}
                                gutter={[15, 15]}
                            >
                                {fileURL.map((link) => (
                                    <Col span={8} key={link}>
                                        <div className="uploadImage">
                                            {link.includes('.pdf') ? (
                                                <div
                                                    style={{
                                                        // width: '100px',
                                                        height: '60px',
                                                        width: '100%',
                                                        borderRadius: '6px',
                                                        objectFit: 'cover',
                                                    }}
                                                >
                                                    <PdfIcon />
                                                </div>
                                            ) : (
                                                <Image
                                                    preview={false}
                                                    // width={'100px'}
                                                    width={'100%'}
                                                    height={'60px'}
                                                    src={link}
                                                    style={{
                                                        objectFit: 'cover',
                                                        borderRadius: '6px',
                                                    }}
                                                />
                                            )}
                                            <div
                                                className={'hoverLayerImage'}
                                            ></div>
                                            <div className={'hoverShowImage'}>
                                                <div
                                                    style={{
                                                        width: '16px',
                                                        height: '16px',
                                                        cursor: 'pointer',
                                                        marginRight: '2px',
                                                    }}
                                                    onClick={() =>
                                                        setViewCertificatesModal(
                                                            {
                                                                open: true,
                                                                fileName: link,
                                                            }
                                                        )
                                                    }
                                                >
                                                    <PreviewIcon />
                                                </div>
                                                <div
                                                    style={{
                                                        width: '16px',
                                                        height: '16px',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={() => {
                                                        const removeData = [
                                                            ...fileURL,
                                                        ]
                                                        removeData.splice(
                                                            removeData.findIndex(
                                                                (item) =>
                                                                    item ===
                                                                    link
                                                            ),
                                                            1
                                                        )
                                                        setFileURL(removeData)
                                                    }}
                                                >
                                                    <BinIcon
                                                        color={
                                                            token.colorPalette
                                                                .baseColor.error
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                ))}
                            </Row>
                        )}
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name={'description'}
                            label="Description*"
                            rules={
                                formButtonType !== 'draftButtonLoading' && [
                                    {
                                        required: true,
                                        message: 'Please Enter Description',
                                        transform: (value) =>
                                            value?.trimStart(),
                                    },
                                    {
                                        max: 250,
                                        min: 10,
                                        message:
                                            'Description should be minimum 10 and maximum 250 characters long',
                                    },
                                ]
                            }
                        >
                            <TextArea
                                rows={10}
                                placeholder={'Enter Description...'}
                                name={'description'}
                                onChange={(e) => {
                                    form.setFieldValue(
                                        'description',
                                        e.target.value.trimStart()
                                    )
                                }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Divider />
            </Form>
            <ModalProfile
                visibility={viewCertificatesModal?.open}
                handleCancel={() => {
                    setViewCertificatesModal({ open: false, fileName: '' })
                }}
                footer={false}
                icon={<CertificateIcon />}
                modalKey="viewVideo"
                modalTitle="Certificates"
                showTitle={'Certificates'}
                content={
                    <ViewCertificatesModal
                        fileName={viewCertificatesModal?.fileName}
                    />
                }
            />
        </>
    )
}

export default CertificateModal
