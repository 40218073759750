import { useDispatch, useSelector } from 'react-redux'
import InfiniteScroll from 'react-infinite-scroll-component'

// antd
import {
    Avatar,
    Card,
    Col,
    Row,
    Spin,
    Tag,
    Tooltip,
    Typography,
    theme,
} from 'antd'

// slices
import { changeRoleView, inquiryState } from '../../inquiries.slice'
import {
    changePage,
    roleInquiryGet,
    rolesInquiryList,
    rolesInquiryState,
} from './rolesInquiry.slice'

// assets
import { NoRolFoundIcon, RecommendationIcon } from '../../../../assets/icons'

import dayjs from 'dayjs'

const ActiveRoleTab = ({ tabName, filteredRolesList }) => {
    const { useToken } = theme

    const { token } = useToken()

    const { Title, Text } = Typography

    const dispatch = useDispatch()

    const { selectedInquiry } = useSelector(inquiryState)
    const { isNext, status, skipPage, pageLimit, search, currentPage } =
        useSelector(rolesInquiryState)

    // FETCH MORE HISTORY
    async function fetchMoreRoles() {
        if (isNext) {
            dispatch(
                rolesInquiryList({
                    filter: {
                        isActive: tabName === 'activeRoles' ? true : false,
                    },
                    skip: skipPage + pageLimit,
                    limit: pageLimit,
                    inquiryId: selectedInquiry?._id,
                    workspaceId: selectedInquiry?.client?.workspaceId,
                    search,
                })
            )
            dispatch(
                changePage({
                    currentPage: currentPage + 1,
                    skip: skipPage + pageLimit,
                    pageLimit: pageLimit,
                })
            )
        }
    }

    return (
        <Spin spinning={status === 'loading' ? true : false}>
            {status === 'loaded' && filteredRolesList?.length > 0 ? (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        rowGap: '20px',
                        paddingRight: '250px',
                    }}
                >
                    <InfiniteScroll
                        dataLength={
                            filteredRolesList?.length > 0
                                ? filteredRolesList?.length
                                : 0
                        }
                        next={fetchMoreRoles}
                        hasMore={isNext}
                        style={{
                            overflow: 'inherit',
                        }}
                    >
                        {filteredRolesList?.length > 0 &&
                            filteredRolesList.map((data) => (
                                <Card
                                    style={{
                                        borderRadius: '7px',
                                        cursor: data?.isActive
                                            ? 'pointer'
                                            : 'auto',
                                        marginTop: '20px',
                                        opacity: !data?.isActive
                                            ? '50%'
                                            : '100%',
                                    }}
                                    onClick={() => {
                                        if (data?.isActive) {
                                            dispatch(changeRoleView(true))
                                            dispatch(
                                                roleInquiryGet({
                                                    inquiryId:
                                                        selectedInquiry?._id,
                                                    roleId: data?._id,
                                                    workspaceId:
                                                        selectedInquiry?.client
                                                            ?.workspaceId,
                                                })
                                            )
                                        }
                                    }}
                                    key={data?.title}
                                >
                                    <Text>
                                        Updated At:{' '}
                                        {dayjs(data?.updatedAt).format(
                                            'DD/MM/YYYY hh:mm A'
                                        )}
                                    </Text>
                                    <Row
                                        style={{
                                            columnGap: '10px',
                                            marginTop: '10px',
                                        }}
                                    >
                                        <Title level={4}>{data?.title}</Title>
                                        <Tag
                                            style={{
                                                borderRadius: '44px',
                                            }}
                                            color={
                                                token.colorPalette.baseColor
                                                    .tertiary
                                            }
                                        >
                                            {data?.workingHours}h
                                        </Tag>
                                    </Row>
                                    <Card
                                        style={{
                                            borderRadius: '27px',
                                            margin: '20px 0px',
                                            padding: '0px',
                                        }}
                                        styles={{
                                            body: {
                                                padding: '10px 15px',
                                            },
                                        }}
                                    >
                                        <Row
                                            align={'middle'}
                                            justify={'space-between'}
                                        >
                                            <Avatar.Group>
                                                {data?.matchedTalent?.length >
                                                    0 &&
                                                    data?.matchedTalent.map(
                                                        (talent) => (
                                                            <Tooltip
                                                                title={
                                                                    talent?.name
                                                                }
                                                                arrow={false}
                                                            >
                                                                <Avatar
                                                                    src={
                                                                        talent?.profilePicture
                                                                    }
                                                                    key={
                                                                        talent?.profilePicture
                                                                    }
                                                                />
                                                            </Tooltip>
                                                        )
                                                    )}
                                            </Avatar.Group>
                                            <Card
                                                bordered={false}
                                                style={{
                                                    borderRadius: '44px',
                                                    padding: '0px',
                                                    backgroundColor:
                                                        'rgba(219, 217, 255, 0.5)',
                                                }}
                                                styles={{
                                                    body: {
                                                        padding: '10px 12px',
                                                    },
                                                }}
                                            >
                                                <Row
                                                    align={'middle'}
                                                    style={{
                                                        columnGap: '10px',
                                                    }}
                                                >
                                                    <RecommendationIcon />
                                                    <Text>
                                                        {
                                                            data?.matchedTalent
                                                                ?.length
                                                        }{' '}
                                                        new recommendations
                                                    </Text>
                                                </Row>
                                            </Card>
                                        </Row>
                                    </Card>
                                    <Row
                                        align={'start'}
                                        justify={'space-between'}
                                    >
                                        <Col span={20}>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    rowGap: '5px',
                                                }}
                                            >
                                                <Text
                                                    style={{
                                                        color: token
                                                            .colorPalette
                                                            .textColor.primary,
                                                    }}
                                                >
                                                    Must have Skills:
                                                </Text>
                                                <Row
                                                    style={{
                                                        columnGap: '5px',
                                                        rowGap: '10px',
                                                    }}
                                                >
                                                    {data?.skills?.length > 0 &&
                                                        data?.skills.map(
                                                            (skill) => (
                                                                <Tag
                                                                    style={{
                                                                        borderRadius:
                                                                            '44px',
                                                                        color: token
                                                                            .colorPalette
                                                                            .textColor
                                                                            .primary,
                                                                        fontWeight:
                                                                            token.fontWeightStrong,
                                                                        whiteSpace:
                                                                            'nowrap',
                                                                        overflow:
                                                                            'hidden',
                                                                        textOverflow:
                                                                            'ellipsis',
                                                                    }}
                                                                    key={
                                                                        skill?._id
                                                                    }
                                                                    color={
                                                                        token
                                                                            .colorPalette
                                                                            .iconColor
                                                                            .primary
                                                                    }
                                                                >
                                                                    {
                                                                        skill?.name
                                                                    }
                                                                </Tag>
                                                            )
                                                        )}
                                                </Row>
                                            </div>
                                        </Col>
                                        <Col span={4}>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    rowGap: '5px',
                                                    alignItems: 'end',
                                                }}
                                            >
                                                <Text
                                                    style={{
                                                        float: 'right',
                                                        color: token
                                                            .colorPalette
                                                            .textColor.primary,
                                                    }}
                                                >
                                                    Monthly budget
                                                </Text>
                                                <Title
                                                    style={{
                                                        marginTop: '0px',
                                                        color: token
                                                            .colorPalette
                                                            .textColor.primary,
                                                    }}
                                                    level={5}
                                                >
                                                    ${data?.startBudget} to $
                                                    {data?.endBudget}
                                                </Title>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card>
                            ))}
                    </InfiniteScroll>
                </div>
            ) : (
                status === 'loaded' &&
                filteredRolesList?.length === 0 && (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'column',
                            justifyContent: 'center',
                        }}
                    >
                        <NoRolFoundIcon />
                        <Text className="titleSecondary">
                            No Roles Found yet.
                        </Text>
                    </div>
                )
            )}
        </Spin>
    )
}

export default ActiveRoleTab
