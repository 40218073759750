import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
    addRemoveInquiryFiles,
    countInquiry,
    countInquiryFiles,
    createInquiry,
    getInquiry,
    getInquiryAttachment,
    listInquiry,
    listInquiryFiles,
    listInquirySwimLanes,
    updateInquiry,
    updateInquiryAttachment,
    updateInquiryPosition,
} from './inquiries.service'

const initialState = {
    search: '',
    status: 'loaded',
    updatedDataStatus: 'loaded',
    attachmentStatus: 'loaded',
    attachmentGetStatus: 'loaded',
    formButtonType: 'saveButtonLoading',
    defaultTab: 'summary',
    selectedInquiry: undefined,
    selectedAttachment: undefined,
    drawerVariations: {
        open: false,
        clientModal: false,
    },
    roleDetailView: false,
    inquiryList: [],
    inquiryCount: 0,
    isNext: false,
    pageLimit: 30,
    currentPage: 1,
    skipPage: 0,
    attachmentList: [],
    attachmentCount: 0,
    isNextAttachment: false,
    pageLimitAttachment: 30,
    currentPageAttachment: 1,
    skipPageAttachment: 0,
    filterWorkSpace: undefined,
    editInputAttachment: { open: false, id: '' },
    pointerEventInquiry: true,
}

export const inquiriesListGetSwimlanes = createAsyncThunk(
    'inquirySlice/listInquirySwimLanes',
    async (payload = {}) => {
        const response = await listInquirySwimLanes(payload)
        return response
    }
)

export const inquiryCount = createAsyncThunk(
    'inquirySlice/countInquiry',
    async (payload = {}) => {
        const response = await countInquiry(payload)
        return response
    }
)

export const inquiriesListGet = createAsyncThunk(
    'inquirySlice/listInquiry',
    async (payload = {}) => {
        const response = await listInquiry(payload)
        return response
    }
)

export const inquiryCreate = createAsyncThunk(
    'inquirySlice/createInquiry',
    async (payload = {}) => {
        const response = await createInquiry(payload)
        return response
    }
)

export const inquiryUpdate = createAsyncThunk(
    'inquirySlice/updateInquiry',
    async (payload = {}) => {
        const response = await updateInquiry(payload)
        return response
    }
)

export const inquiryPositionUpdate = createAsyncThunk(
    'inquirySlice/updateInquiryPosition',
    async (payload = {}) => {
        const response = await updateInquiryPosition(payload)
        return response
    }
)

export const inquiryGet = createAsyncThunk(
    'inquirySlice/getInquiry',
    async (payload = {}) => {
        const response = await getInquiry(payload)
        return response
    }
)

export const inquiryListFiles = createAsyncThunk(
    'inquirySlice/listInquiryFiles',
    async (payload = {}) => {
        const response = await listInquiryFiles(payload)
        return response
    }
)

export const inquiryAddRemoveFiles = createAsyncThunk(
    'inquirySlice/addRemoveInquiryFiles',
    async (payload = {}) => {
        const response = await addRemoveInquiryFiles(payload)
        return response
    }
)

export const inquiryCountFiles = createAsyncThunk(
    'inquirySlice/countInquiryFiles',
    async (payload = {}) => {
        const response = await countInquiryFiles(payload)
        return response
    }
)

export const inquiryAttachmentGet = createAsyncThunk(
    'inquirySlice/getInquiryAttachment',
    async (payload = {}) => {
        const response = await getInquiryAttachment(payload)
        return response
    }
)

export const inquiryAttachmentUpdate = createAsyncThunk(
    'inquirySlice/updateInquiryAttachment',
    async (payload = {}) => {
        const response = await updateInquiryAttachment(payload)
        return response
    }
)

export const inquirySlice = createSlice({
    name: 'inquirySlice',
    initialState,
    reducers: {
        switchInquiryDrawer: (state, action) => {
            state.drawerVariations = action.payload
            state.selectedInquiry = undefined
        },
        changePage: (state, action) => {
            state.currentPage = action.payload.currentPage
            state.skipPage = action.payload.skip
            state.pageLimit = action.payload.pageLimit
        },
        changePageInquiryAttachment: (state, action) => {
            state.currentPageAttachment = action.payload.currentPage
            state.skipPageAttachment = action.payload.skip
            state.pageLimitAttachment = action.payload.pageLimit
        },
        changeEditInputAttachMent: (state, action) => {
            state.editInputAttachment = {
                id: action.payload.id,
                open: action.payload.open,
            }
        },
        setInquiries: (state, action) => {
            state.inquiryList = action.payload
        },
        changeWorkspace: (state, action) => {
            state.filterWorkSpace = action.payload
        },
        changeTab: (state, action) => {
            state.defaultTab = action.payload
        },
        changeRoleView: (state, action) => {
            state.roleDetailView = action.payload
        },
        setPointerEventInquiry: (state, action) => {
            state.pointerEventInquiry = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(inquiriesListGetSwimlanes.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(inquiriesListGetSwimlanes.fulfilled, (state, action) => {
                state.status = 'loaded'
                state.inquiryList = action.payload.data
                    .sort(function (a, b) {
                        return a.position - b.position
                    })
                    .map((item) => {
                        return {
                            _id: item._id,
                            position: item?.position,
                            list:
                                item?.list?.length > 0
                                    ? item?.list.sort(function (a, b) {
                                          return a.position - b.position
                                      })
                                    : [],
                        }
                    })
                state.isNext = action.payload.isNext
            })
            .addCase(inquiriesListGet.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(inquiriesListGet.fulfilled, (state, action) => {
                state.status = 'loaded'
                state.inquiryList = action.payload.data
                state.isNext = action.payload.isNext
            })
            .addCase(inquiryGet.pending, (state) => {
                state.updatedDataStatus = 'loading'
            })
            .addCase(inquiryGet.fulfilled, (state, action) => {
                state.updatedDataStatus = 'loaded'
                state.selectedInquiry = action?.payload.data
            })
            .addCase(inquiryCount.fulfilled, (state, action) => {
                state.inquiryCount = action.payload.data
            })
            .addCase(inquiryListFiles.pending, (state) => {
                state.attachmentStatus = 'loading'
            })
            .addCase(inquiryListFiles.fulfilled, (state, action) => {
                state.attachmentStatus = 'loaded'
                state.attachmentList = action.payload.data
                state.isNextAttachment = action.payload.data.isNext
            })
            .addCase(inquiryAttachmentGet.pending, (state) => {
                state.attachmentGetStatus = 'loading'
            })
            .addCase(inquiryAttachmentGet.fulfilled, (state, action) => {
                state.attachmentGetStatus = 'loaded'
                state.selectedAttachment = action?.payload.data
            })
    },
})

export default inquirySlice.reducer

export const inquiryState = (state) => {
    const { inquirySlice } = state
    const {
        search,
        status,
        updatedDataStatus,
        formButtonType,
        selectedInquiry,
        drawerVariations,
        inquiryList,
        inquiryCount,
        isNext,
        pageLimit,
        currentPage,
        skipPage,
        attachmentList,
        attachmentCount,
        isNextAttachment,
        pageLimitAttachment,
        currentPageAttachment,
        skipPageAttachment,
        attachmentStatus,
        editInputAttachment,
        attachmentGetStatus,
        selectedAttachment,
        roleDetailView,
        defaultTab,
        filterWorkSpace,
        pointerEventInquiry,
    } = inquirySlice
    return {
        search,
        status,
        updatedDataStatus,
        formButtonType,
        selectedInquiry,
        drawerVariations,
        inquiryList,
        inquiryCount,
        isNext,
        pageLimit,
        currentPage,
        skipPage,
        attachmentList,
        attachmentCount,
        isNextAttachment,
        pageLimitAttachment,
        currentPageAttachment,
        skipPageAttachment,
        attachmentStatus,
        editInputAttachment,
        attachmentGetStatus,
        selectedAttachment,
        roleDetailView,
        defaultTab,
        filterWorkSpace,
        pointerEventInquiry,
    }
}

export const {
    changePage,
    switchInquiryDrawer,
    getTalentDetails,
    changeEditInputAttachMent,
    changePageInquiryAttachment,
    changeRoleView,
    changeTab,
    changeWorkspace,
    setInquiries,
    setPointerEventInquiry,
} = inquirySlice.actions
