import { useDispatch, useSelector } from 'react-redux'

// antd
import { Divider, Form, Modal, Row, Typography, message } from 'antd'

// slices
import { loginState } from '../login/login.slice'
import { workSpaceUnAssign } from '../workSpace/workSpace.slice'
import { totalUsers, usersList, usersState } from './users.slice'

// components
import Button from '../../components/button/button'

// assets
import { TerminateIcon } from '../../assets/icons'

const TerminateModal = ({ modal, onCancel, loading }) => {
    const [form] = Form.useForm()

    const { Title, Text } = Typography

    const { userProfile } = useSelector(loginState)
    const { filterDropDown, skipPage, pageLimit } = useSelector(usersState)

    const dispatch = useDispatch()

    const onFinishTerminate = async () => {
        loading()
        const result = await dispatch(
            workSpaceUnAssign({
                workspaceId: userProfile?.currentWorkSpace?._id,
                userId: modal?.userData?._id,
            })
        )
        const data = result?.payload?.data
        if (data) {
            const { success, message: checkMessage } = data
            if (success) {
                dispatch(
                    await totalUsers({
                        filter: filterDropDown,
                    })
                )
                dispatch(
                    await usersList({
                        skip: skipPage,
                        limit: pageLimit,
                        filter: filterDropDown,
                    })
                )
                message.success(checkMessage)
                form.resetFields()
                onCancel()
            } else {
                form.resetFields()
                onCancel()
                if (checkMessage) {
                    message.error(checkMessage)
                } else {
                    message.error('Something went wrong, try again later.')
                }
            }
        }
    }

    return (
        <>
            <Modal
                closable={false}
                open={modal?.open}
                destroyOnClose={true}
                onCancel={() => {
                    form.resetFields()
                    onCancel()
                }}
                centered
                style={{
                    width: '426px',
                    maxWidth: '426px',
                    padding: '0px',
                    borderRadius: '10px',
                }}
                title={null}
                footer={null}
            >
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                    }}
                >
                    <div
                        style={{
                            height: '40px',
                            width: '40px',
                            marginBottom: '15px',
                        }}
                    >
                        <TerminateIcon />
                    </div>
                    <Title
                        level={4}
                        style={{ margin: 0, textTransform: 'capitalize' }}
                    >
                        Terminate
                    </Title>
                    <Text
                        style={{
                            fontSize: '16px',
                            marginTop: '15px',
                            textAlign: 'center',
                        }}
                    >
                        Are you sure you want to terminate{' '}
                        {modal?.userData?.name}?
                    </Text>
                </div>

                <Divider />
                <Row
                    align={'middle'}
                    justify={'end'}
                    style={{
                        columnGap: '8px',
                    }}
                >
                    <Button
                        props={{
                            text: 'Cancel',
                            onClick: onCancel,
                            buttonType: 'text',
                        }}
                    />
                    <Button
                        props={{
                            text: 'Terminate',
                            onClick: () => onFinishTerminate(),
                            danger: true,
                            loadingButton: modal?.loading,
                        }}
                    />
                </Row>
            </Modal>
        </>
    )
}

export default TerminateModal
