import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ReactQuill from 'react-quill'
import { debounce, set } from 'lodash'
import S3FileUpload from 'react-s3/lib/ReactS3'
import dayjs from 'dayjs'

import {
    Col,
    Empty,
    Form,
    Input,
    InputNumber,
    Row,
    Select,
    message,
    Typography,
    Spin,
    Card,
    Tag,
    Switch,
} from 'antd'
import useToken from 'antd/es/theme/useToken'

import useGooglePlace from '../../../helpers/googlePlacesFunction'
import {
    BUDGET_TYPE_OPTIONS,
    JOB_STATUS_OPTIONS,
    jobRules,
} from '../../../constants/jobs'
import { departmentOptions } from '../../../constants/users.index'
import { CrossIcon } from '../../../assets/icons'
import {
    changeModel,
    getJobDetails,
    getListJobs,
    jobState,
    masterDataAddDropDown,
    masterDataDropDownList,
    switchJobModal,
} from '../store/job.slice'
import { MASTER_DATA_FIELD } from '../../../constants/masterData'
import DropDownMultiple from '../../../components/dropDownMultiple'
import { loginState } from '../../login/login.slice'
import { STAND_UP_POST } from '../../../constants/standUps'
import Dragger from 'antd/es/upload/Dragger'
import Button from '../../../components/button/button'
// import {
//     cleanObject,
//     removeExistingData,
// } from '../../../helpers/removeEmptyObjectValue'
import { apiJobCreate, apiJobUpdate } from '../store/job.service'

const { Option } = Select
const { Text } = Typography

const AddEditJobForm = () => {
    const [addEditJobAntdForm] = Form.useForm()

    const dispatch = useDispatch()

    const {
        modelType,
        masterDataDropDown,
        masterDataDropDownStatus,
        modalVariations,
        pageLimit,
        search,
        filter,
        jobDetails,
    } = useSelector(jobState)
    const { userProfile } = useSelector(loginState)

    const { token } = useToken()

    // For Google Place API
    const [inputValue, setInputValue] = useState('')
    const [places, setPlaces] = useState([])
    const [searchValue, setSearchValue] = useState('')
    const [skillsData, setSkillsData] = useState([])
    const [description, setDescription] = useState(STAND_UP_POST)
    const [fileURL, setFileURL] = useState([])
    const [isActive, setIsActive] = useState(false)

    // For Google Plce API
    useGooglePlace(inputValue, setPlaces)

    // For Budget Type
    const selectAfterBudget = (formName) => (
        <Form.Item name={formName}>
            <Select placeholder="Type" style={{ width: '103px' }}>
                {BUDGET_TYPE_OPTIONS &&
                    BUDGET_TYPE_OPTIONS.map((type) => (
                        <Option value={type.value}>{type.label}</Option>
                    ))}
            </Select>
        </Form.Item>
    )

    // SEARCH MASTER DATA
    const searchFn = debounce((value, model) => {
        if (value?.length > 0) {
            dispatch(changeModel(model))
            dispatch(
                masterDataDropDownList({
                    search: value,
                    id: model,
                })
            )
        }
        setSearchValue(value)
    }, 800)

    // ADD MASTER DATA
    async function addMasterData() {
        const result = dispatch(
            masterDataAddDropDown({
                addData: {
                    type: 'manual',
                    data: {
                        name: searchValue,
                        logo:
                            modelType === 'tools'
                                ? 'https://pms-bucket123.s3.amazonaws.com/Layer_1_1689237094.svg'
                                : modelType === 'institute'
                                ? 'https://pms-bucket123.s3.amazonaws.com/Group 35301_1689226177.svg'
                                : '',
                        createdBy: userProfile?._id,
                    },
                },
                model: modelType,
            })
        )
        const data = result?.payload?.data

        if (data) {
            const { success, message: errorMessage } = data
            if (success) {
                dispatch(
                    masterDataDropDownList({
                        search: searchValue,
                        id: modelType,
                    })
                )
            } else {
                if (errorMessage) {
                    message.error(errorMessage)
                } else {
                    message.error('Something went wrong, try again later.')
                }
            }
        }
    }

    // ONCHANGE FILE PROPS
    const props = {
        name: 'fileLink',
        multiple: false,
        onChange(info) {
            // setStream(true)

            const nameChange = info?.fileList[0]?.name
                .split('.')[0]
                .concat(`_${dayjs(new Date()).unix()}`)
                .concat(`.${info?.fileList[0]?.name.split('.')[1]}`)

            const newFileData = new File(
                [info?.fileList[0]?.originFileObj],
                nameChange,
                { type: info?.fileList[0]?.type }
            )
            window.Buffer = window.Buffer || require('buffer').Buffer

            S3FileUpload.uploadFile(newFileData, userProfile?.s3Config)
                .then(async (data) => {
                    // fileUpload.push(data?.location)
                    setFileURL([data?.location])
                })
                .catch(() => {
                    message.error('Upload Failed!. Please Upload again')
                })
        },

        beforeUpload() {
            return false
        },

        onDrop() {},
    }

    const onFinishJob = async (values) => {
        const finaleValues = values

        try {
            const data = {
                title: finaleValues?.title,
                location: finaleValues?.location,
                status: finaleValues?.status,
                description: finaleValues?.description,
                department: finaleValues?.department,
                noOfPositions: finaleValues?.numberOfPosition,
                budgetFrom: {
                    fromType: finaleValues?.budgetFromType,
                    fromValue: finaleValues?.budgetFrom,
                },
                budgetTo: {
                    toType: finaleValues?.budgetToType,
                    toValue: finaleValues?.budgetTo,
                },
                skills:
                    skillsData?.length > 0
                        ? skillsData.map((skill) => skill?.value)
                        : [],
                files: fileURL,
            }

            let response = null

            if (modalVariations?.isEdit) {
                // let finalData = removeExistingData(jobDetails, data)

                const payload = {
                    updatedData: data,
                    _id: modalVariations?.selectedJobID,
                }

                response = await apiJobUpdate(payload)
            } else {
                response = await apiJobCreate(data)
            }

            dispatch(
                switchJobModal({
                    ...modalVariations,
                    saveButtonLoading: true,
                })
            )

            if (response?.data?.success) {
                dispatch(
                    switchJobModal({
                        ...modalVariations,
                        saveButtonLoading: false,
                        open: false,
                    })
                )
                setFileURL([])
                setSkillsData([])
                message.success(response?.data?.message)
                addEditJobAntdForm.resetFields()
                dispatch(
                    getListJobs({
                        limit: pageLimit,
                        skip: 0,
                        search: search,
                        filter: { ...filter },
                    })
                )
            } else {
                dispatch(
                    switchJobModal({
                        ...modalVariations,
                        saveButtonLoading: false,
                        open: false,
                    })
                )
                message.error(response?.data?.message)
            }
        } catch (error) {
            console.log('error ====>', error)
        }
    }

    // GET JOB DETAILS
    useEffect(() => {
        if (modalVariations?.isEdit) {
            dispatch(getJobDetails(modalVariations?.selectedJobID))
        }
    }, [modalVariations?.isEdit])

    // EDIT JOB
    useEffect(() => {
        if (modalVariations?.isEdit && jobDetails) {
            addEditJobAntdForm.setFieldsValue({
                title: jobDetails?.title,
                location: jobDetails?.location,
                status: jobDetails?.status,
                numberOfPosition: jobDetails?.noOfPositions,
                department: jobDetails?.department,
                budgetFrom: jobDetails?.budgetFrom?.fromValue,
                budgetFromType: jobDetails?.budgetFrom?.fromType,
                budgetTo: jobDetails?.budgetTo?.toValue,
                budgetToType: jobDetails?.budgetTo?.toType,
                description: jobDetails?.description,
            })

            if (jobDetails?.skills?.length > 0) {
                setSkillsData(
                    jobDetails?.skills?.map((skill) => {
                        return {
                            label: skill?.name,
                            value: skill?._id,
                        }
                    })
                )
            }

            if (jobDetails?.files?.length > 0) {
                setFileURL(jobDetails?.files)
            }

            setIsActive(jobDetails?.isActiveJob)
        }
    }, [jobDetails])

    if (!jobDetails && modalVariations?.isEdit) {
        return (
            <div
                style={{
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Spin />
            </div>
        )
    }

    return (
        <>
            {modalVariations?.isEdit && (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        gap: '10px',
                        marginBottom: '20px',
                    }}
                >
                    <span>Active</span>
                    <Switch
                        value={isActive}
                        onChange={(checked) => {
                            setIsActive(checked)
                        }}
                        style={{
                            background: `${isActive ? '#00AB55' : '#c3c3c3'}`,
                        }}
                    />
                </div>
            )}

            <div>
                <Form
                    labelCol={{
                        style: {
                            padding: '15px 0 3px',
                        },
                    }}
                    layout="vertical"
                    name="addEditJobAntdForm"
                    onFinish={onFinishJob}
                    requiredMark={false}
                    form={addEditJobAntdForm}
                    style={{
                        marginBottom: '20px',
                        overflowX: 'hidden',
                    }}
                >
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Form.Item
                                name="title"
                                label="Job Title"
                                rules={jobRules.title}
                            >
                                <Input placeholder="Job Title" />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="Location"
                                name="location"
                                colon={false}
                            >
                                <Select
                                    placeholder="Search a Location"
                                    allowClear={false}
                                    notFoundContent={
                                        <Empty
                                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                                            description="No Locations Searched"
                                        ></Empty>
                                    }
                                    onSearch={(value) => {
                                        setInputValue(value)
                                    }}
                                    showSearch
                                    options={places}
                                    getPopupContainer={(trigger) =>
                                        trigger.parentNode
                                    }
                                />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                name="numberOfPosition"
                                label="Number Of Position"
                            >
                                <InputNumber
                                    style={{ width: '100%' }}
                                    min={0}
                                    placeholder="Number Of Position"
                                />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item name="status" label="Status">
                                <Select
                                    placeholder="Status"
                                    options={JOB_STATUS_OPTIONS}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item name="department" label="Department">
                                <Select
                                    placeholder="Department"
                                    options={departmentOptions}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item name="budgetFrom" label="Budget From">
                                <Input
                                    placeholder="Budget From"
                                    addonAfter={selectAfterBudget(
                                        'budgetFromType'
                                    )}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item name="budgetTo" label="Budget To">
                                <Input
                                    placeholder="Budget To"
                                    addonAfter={selectAfterBudget(
                                        'budgetToType'
                                    )}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Select
                                placeholder="Search Skills"
                                showSearch
                                onSearch={(value) => {
                                    searchFn(value, MASTER_DATA_FIELD.skills)
                                }}
                                tagRender={() => null}
                                onSelect={(e, options) => {
                                    const trimSkills = options?.key.trimStart()
                                    if (trimSkills?.length > 0) {
                                        setSkillsData([
                                            ...skillsData,
                                            { label: trimSkills, value: e },
                                        ])
                                    }
                                }}
                                getPopupContainer={(trigger) =>
                                    trigger.parentNode
                                }
                                onDeselect={(e) => {
                                    const removeData = [...skillsData]
                                    removeData.splice(
                                        removeData.findIndex(
                                            (item) => item.label === e
                                        ),
                                        1
                                    )
                                    setSkillsData(removeData)
                                }}
                                style={{
                                    width: '100%',
                                }}
                                mode="multiple"
                                rootClassName={[
                                    'p-0-multiSelect',
                                    skillsData?.length > 0
                                        ? 'p-0-multiselect-setMargin'
                                        : '',
                                ]}
                                filterOption={false}
                                allowClear={false}
                                dropdownRender={(menu) => (
                                    <DropDownMultiple
                                        menu={menu}
                                        searchValue={searchValue}
                                        name={MASTER_DATA_FIELD.skills}
                                        addMasterData={() => addMasterData()}
                                    />
                                )}
                            >
                                {masterDataDropDown?.skills?.length > 0 &&
                                masterDataDropDownStatus === 'loaded'
                                    ? masterDataDropDown?.skills.map(
                                          (option) => {
                                              return (
                                                  <Option
                                                      key={option?.label}
                                                      value={option?.value}
                                                  >
                                                      <Text>
                                                          {option?.label}
                                                      </Text>
                                                  </Option>
                                              )
                                          }
                                      )
                                    : masterDataDropDownStatus ===
                                          'loading' && <Spin></Spin>}
                            </Select>
                            <div
                                style={{
                                    marginTop: '30px',
                                }}
                            >
                                <Text>My Skills</Text>
                                <Card
                                    style={{
                                        border: `2px dashed ${
                                            token && token?.colorPalette
                                                ? token?.colorPalette?.textColor
                                                      ?.quaternary
                                                : '#F7F5FF'
                                        }`,
                                        margin: '4px 0px 14px 0px',
                                        padding: '0px',
                                        minHeight: '110px',
                                    }}
                                    styles={{
                                        body: {
                                            padding: '15px',
                                        },
                                    }}
                                >
                                    <Row style={{ rowGap: '10px' }}>
                                        {skillsData?.length > 0 &&
                                            skillsData.map((skill, index) => (
                                                <Tag
                                                    style={{
                                                        padding: '10px 20px',
                                                        whiteSpace: 'normal',
                                                        overflow: 'hidden',
                                                    }}
                                                    color={
                                                        token &&
                                                        token?.colorPalette
                                                            ? token
                                                                  ?.colorPalette
                                                                  ?.baseColor
                                                                  ?.tertiary
                                                            : '#4F46E5'
                                                    }
                                                >
                                                    <Row
                                                        align={'middle'}
                                                        style={{
                                                            columnGap: '30px',
                                                        }}
                                                    >
                                                        <Text
                                                            style={{
                                                                color:
                                                                    token &&
                                                                    token?.colorPalette
                                                                        ? token
                                                                              ?.colorPalette
                                                                              ?.baseColor
                                                                              .white
                                                                        : '#FFFFFF',
                                                            }}
                                                        >
                                                            {skill?.label?.toUpperCase()}
                                                        </Text>
                                                        <div
                                                            style={{
                                                                width: '13px',
                                                            }}
                                                            onClick={() => {
                                                                searchFn(
                                                                    '',
                                                                    MASTER_DATA_FIELD.skills
                                                                )
                                                                const removeData =
                                                                    [
                                                                        ...skillsData,
                                                                    ]
                                                                removeData.splice(
                                                                    index,
                                                                    1
                                                                )
                                                                setSkillsData(
                                                                    removeData
                                                                )
                                                            }}
                                                        >
                                                            <CrossIcon />
                                                        </div>
                                                    </Row>
                                                </Tag>
                                            ))}
                                    </Row>
                                </Card>
                            </div>
                        </Col>

                        <Col span={24}>
                            <Form.Item name="description" label="Decription">
                                <ReactQuill
                                    theme="snow"
                                    style={{
                                        backgroundColor: '#f8f8f8',
                                        borderRadius: '12px',
                                        border: 'none',
                                        margin: '10px 0px',
                                    }}
                                    onChange={(value) => {
                                        const descriptionSetData =
                                            description.map(
                                                (descriptionData) => {
                                                    let isExist =
                                                        STAND_UP_POST.find(
                                                            (data) =>
                                                                data?.label ===
                                                                descriptionData?.label
                                                        )

                                                    if (isExist) {
                                                        return {
                                                            ...descriptionData,
                                                            description: value,
                                                        }
                                                    } else {
                                                        return descriptionData
                                                    }
                                                }
                                            )
                                        setDescription(descriptionSetData)
                                    }}
                                    placeholder={'Description...'}
                                    modules={{
                                        toolbar: [
                                            [
                                                {
                                                    list: 'ordered',
                                                },
                                                {
                                                    list: 'bullet',
                                                },
                                            ],
                                            [
                                                {
                                                    header: [1, 2, false],
                                                },
                                            ],
                                            [
                                                'bold',
                                                'italic',
                                                'underline',
                                                'strike',
                                                'blockquote',
                                            ],
                                            ['link'],
                                            [
                                                {
                                                    indent: '-1',
                                                },
                                                {
                                                    indent: '+1',
                                                },
                                            ],
                                            [{ align: [] }],
                                            ['clean'],
                                        ],
                                    }}
                                    formats={[
                                        'list',
                                        'bullet',
                                        'indent',
                                        'header',
                                        'bold',
                                        'italic',
                                        'underline',
                                        'strike',
                                        'blockquote',
                                        'link',
                                    ]}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            {fileURL?.length > 0 ? (
                                <Card
                                    style={{
                                        minHeight: '185px',
                                        padding: 0,
                                        position: 'relative',
                                    }}
                                    styles={{
                                        body: {
                                            padding: 0,
                                        },
                                    }}
                                >
                                    {fileURL?.join(',')}
                                </Card>
                            ) : (
                                <Form.Item name="fileUpload" label="">
                                    <Dragger
                                        {...props}
                                        fileList={[]}
                                        previewFile={false}
                                        style={{
                                            border: `2px dashed ${
                                                token && token?.colorPalette
                                                    ? token.colorPalette
                                                          .textColor.quaternary
                                                    : '#F7F5FF'
                                            }`,
                                            padding: '20px',
                                            height: '185px',
                                        }}
                                        accept=".MP4,.MOV,.WMV,.AVI,.AVCHD,.FLV,.F4V,.SWF,.MKV,.WEBM,.HTML5,.PDF,.DOC,.DOCX,.PPT,.PPTX,.XLS,.XLSX,.TXT,.CSV,.RTF,.ODT,.ODS,.JPEG,.JPG,.PNG"
                                    >
                                        <Text className="titleSecondary">
                                            Click or Drag
                                        </Text>
                                        <br />
                                        <Text className="titleSecondary">
                                            File to Upload
                                        </Text>
                                    </Dragger>
                                </Form.Item>
                            )}

                            {fileURL?.length > 0 && (
                                <Button
                                    props={{
                                        text: 'Remove File',
                                        onClick: () => setFileURL([]),
                                        style: {
                                            width: '100%',
                                            marginTop: '25px',
                                        },
                                    }}
                                />
                            )}
                        </Col>
                    </Row>
                </Form>
            </div>
        </>
    )
}

export default AddEditJobForm
