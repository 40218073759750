import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import S3FileUpload from 'react-s3'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import dayjs from 'dayjs'

// antd
import {
    Card,
    Col,
    Empty,
    Form,
    Image,
    Input,
    message,
    Radio,
    Row,
    Select,
    Space,
    Spin,
    Switch,
    theme,
    Typography,
    Upload,
} from 'antd'

// slices
import { checkButtonType, profileState } from '../profile/profile.slice'
import { loginState } from '../login/login.slice'
import {
    SMTPverify,
    switchWorkSpaceModal,
    workSpaceAdd,
    workSpaceDataListCount,
    workSpaceListGet,
    workspaceState,
    workSpaceUpdate,
} from './workSpace.slice'

// assets
import {
    PasswordHideIcon,
    PasswordShowIcon,
    UploadImageIcon,
} from '../../assets/icons'

// constants
import { PHONE_COUNTRIES } from '../../constants/phone-countries'
import {
    EMAIL_PATTERN,
    HOST_PATTERN,
    PORT_PATTERN,
} from '../../constants/patterns'
import { DOMAIN_LIST } from '../../constants/roles'

// components
import Button from '../../components/button/button'

function WorkSpaceModalContent({ refreshWorkSpace }) {
    const { Text } = Typography

    const { useToken } = theme
    const { token } = useToken()

    const dispatch = useDispatch()

    const timeoutRef = useRef()
    const sessionTokenRef = useRef()

    // SELECTORS
    const { profileData } = useSelector(profileState)
    const {
        modalVariations,
        pageLimit,
        skipPage,
        search,
        updatedDataStatus,
        selectedData,
    } = useSelector(workspaceState)
    const { userProfile } = useSelector(loginState)

    const [fileURL, setFileURL] = useState([])
    const [phoneNumber, setPhoneNumber] = useState({
        phoneNo: null,
        code: null,
    })
    const [activeCheck, setActiveCheck] = useState(false)
    const [phoneValid, setPhoneValid] = useState(false)
    const [radioValue, setRadioValue] = useState('gmail')
    const [suggestions, setSuggestions] = useState([])
    const [checkVerify, setCheckVerify] = useState(false)
    const [verifyLoading, setVerifyLoading] = useState(false)

    const [form] = Form.useForm()

    // CHANGE RADIO VALUE
    const onChangeRadio = (e) => {
        setRadioValue(e.target.value)
    }

    // GOOGLE PLACE API INTEGRATION
    const loadSuggestions = async (inputValue) => {
        clearTimeout(timeoutRef.current)

        if (!inputValue || inputValue.trim().length <= 1) {
            setSuggestions([])
            return
        }

        timeoutRef.current = setTimeout(async () => {
            if (!sessionTokenRef.current) {
                sessionTokenRef.current =
                    new window.google.maps.places.AutocompleteSessionToken()
            }

            new window.google.maps.places.AutocompleteService().getPlacePredictions(
                {
                    input: inputValue,
                    sessionToken: sessionTokenRef.current,
                },
                (predictions, status) => {
                    if (
                        status ===
                        window.google.maps.places.PlacesServiceStatus
                            .ZERO_RESULTS
                    ) {
                        setSuggestions([])
                        return
                    }
                    if (
                        status !==
                            window.google.maps.places.PlacesServiceStatus.OK ||
                        !predictions
                    ) {
                        return
                    }
                    let newData =
                        predictions?.length > 0 &&
                        predictions.map((data) => {
                            return {
                                label: data?.description,
                                value: data?.description,
                            }
                        })
                    setSuggestions(newData)
                }
            )
        }, 350)
    }

    // EDIT MODAL DATA SET INITIALLY
    useEffect(() => {
        if (selectedData) {
            if (selectedData?.logo?.length > 0) {
                setFileURL([selectedData?.logo])
            }
            setActiveCheck(selectedData?.isActive)
            setPhoneNumber({
                phoneNo: profileData?.data?.phone,
                code: profileData?.data?.countryCode,
            })
            if (!selectedData?.mailConfig?.isGmailOption) {
                setRadioValue('other')
            }
            setPhoneValid(true)
            form.setFieldsValue({
                ...selectedData,
                emailName: selectedData?.mailConfig?.emailName,
                email: selectedData?.mailConfig?.auth?.email,
                password: selectedData?.mailConfig?.auth?.password,
                host: selectedData?.mailConfig?.host,
                port: selectedData?.mailConfig?.port,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form, selectedData])

    // ONCHANGE FILE PROPS
    const props = {
        name: 'logo',
        multiple: false,
        onChange(info) {
            // setStream(true)
            const isLt5M = info?.file.size / 1024 / 1024 < 5
            if (!isLt5M) {
                message.error('Profile picture must be smaller than 5MB')
                return
            } else {
                const nameChange = info?.fileList[0]?.name
                    .split('.')[0]
                    .concat(`_${dayjs(new Date()).unix()}`)
                    .concat(`.${info?.fileList[0]?.name.split('.')[1]}`)

                const newFileData = new File(
                    [info?.fileList[0]?.originFileObj],
                    nameChange,
                    { type: info?.fileList[0]?.type }
                )
                window.Buffer = window.Buffer || require('buffer').Buffer

                S3FileUpload.uploadFile(newFileData, userProfile?.s3Config)
                    .then(async (data) => {
                        // fileUpload.push(data?.location)
                        setFileURL([data?.location])
                    })
                    .catch(() => {
                        message.error('Upload Failed!. Please Upload again')
                    })
            }
            // info?.preventDefault()
        },
        beforeUpload() {
            return false
        },
        onDrop(e) {},
    }

    function onFinishFailed() {}

    // VERIFY HOST, PORT, EMAIL, PASSWORD
    async function VerifyHostPort() {
        if (
            form.getFieldValue('host')?.length === undefined ||
            form.getFieldValue('port')?.length === undefined ||
            form.getFieldValue('email')?.length === undefined ||
            form.getFieldValue('password')?.length === undefined
        ) {
            message.info('Please Filled out Host, Port, Email and Password')
        } else {
            setVerifyLoading(true)
            const result = await dispatch(
                SMTPverify({
                    smtpConfig: {
                        host: form.getFieldValue('host'),
                        port: form.getFieldValue('port'),
                        auth: {
                            email: form.getFieldValue('email'),
                            password: form.getFieldValue('password'),
                        },
                        isGmailOption: true,
                    },
                })
            )
            const data = result?.payload?.data
            if (data) {
                const { success, message: checkMessage } = data
                if (success) {
                    setVerifyLoading(false)
                    message.success(checkMessage)
                } else {
                    setVerifyLoading(false)
                    setCheckVerify(false)
                    if (checkMessage) {
                        message.error(checkMessage)
                    } else {
                        message.error('Something went wrong, try again later.')
                    }
                }
            }
        }
    }

    // CHANGE PHONE NUMBER
    const handleChangePhoneNumber = (number, country) => {
        const phoneNo = number.replace(country.dialCode, '')
        setPhoneNumber({
            phoneNo,
            code: `+${country.dialCode}`,
        })
    }

    // FINISH PROFILE EDIT
    async function saveWorkspaceData(value) {
        if (!checkVerify && radioValue === 'other') {
            message.info('Please Verify Host, Port, Email, Password')
            return
        }

        dispatch(
            switchWorkSpaceModal({
                ...modalVariations,
                saveButtonLoading: true,
            })
        )

        let payload = { ...value }

        const gmailPayload = {
            mailConfig: {
                emailName: value.emailName,
                auth: {
                    email: value?.email,
                    password: value?.password,
                },
                isGmailOption: radioValue === 'other' ? false : true,
            },
        }
        if (selectedData) {
            if (selectedData?.companyEmail === payload?.companyEmail) {
                delete payload?.companyEmail
            }
            if (selectedData?.phone === payload?.phone) {
                delete payload?.phone
            }
        }

        const radioOptions =
            radioValue === 'other'
                ? {
                      ...gmailPayload,
                      host: value.host,
                      port: value.port,
                  }
                : { ...gmailPayload }

        const result = await dispatch(
            selectedData
                ? workSpaceUpdate({
                      editData: {
                          ...payload,
                          ...radioOptions,
                          isActive: activeCheck,
                          logo: fileURL?.length > 0 ? fileURL[0] : '',
                      },
                      _id: selectedData?._id,
                  })
                : workSpaceAdd({
                      addData: {
                          ...payload,
                          ...radioOptions,
                          logo: fileURL?.length > 0 ? fileURL[0] : '',
                      },
                  })
        )
        const data = result?.payload?.data
        if (data) {
            const { success, message: checkMessage } = data
            if (success) {
                setCheckVerify(false)
                refreshWorkSpace()
                dispatch(workSpaceDataListCount())
                dispatch(
                    workSpaceListGet({
                        limit: pageLimit,
                        skip: skipPage,
                        search: search,
                    })
                )
                dispatch(
                    switchWorkSpaceModal({
                        ...modalVariations,
                        open: false,
                        saveButtonLoading: false,
                    })
                )
                message.success(checkMessage)
            } else {
                if (typeof checkMessage === 'string') {
                    message.error(checkMessage)
                    dispatch(
                        switchWorkSpaceModal({
                            ...modalVariations,
                            open: false,
                            saveButtonLoading: false,
                        })
                    )
                } else if (typeof checkMessage === 'object') {
                    form.setFields([
                        {
                            name: Object.keys(checkMessage)[0],
                            errors: [
                                checkMessage[Object.keys(checkMessage)[0]],
                            ],
                        },
                    ])
                    dispatch(
                        switchWorkSpaceModal({
                            ...modalVariations,
                            saveButtonLoading: false,
                        })
                    )
                } else {
                    message.error('Something went wrong, try again later.')
                }
            }
        }
    }

    return updatedDataStatus === 'loading' ? (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
            }}
        >
            <Spin size="large" />
        </div>
    ) : (
        <>
            <Form
                labelCol={{
                    style: {
                        padding: '0 0 3px',
                    },
                }}
                layout="vertical"
                name="workSpaceDataForm"
                onFinish={saveWorkspaceData}
                onFinishFailed={onFinishFailed}
                onSubmitCapture={async (e) => {
                    await dispatch(checkButtonType(e.nativeEvent.submitter.id))
                }}
                form={form}
                requiredMark={false}
                style={{
                    overflowX: 'hidden',
                    marginBottom: '10px',
                }}
            >
                <Row gutter={30}>
                    <Col span={9}>
                        <Card
                            style={{
                                borderRadius: '16px',
                                height: '100%',
                            }}
                            styles={{
                                body: {
                                    padding: '30px 44px',
                                },
                            }}
                        >
                            <Form.Item
                                name="logo"
                                rootClassName="imageUpload"
                                rules={[
                                    {
                                        validator: async () => {
                                            if (fileURL?.length === 0) {
                                                return Promise.reject(
                                                    new Error(
                                                        `Please Upload photo`
                                                    )
                                                )
                                            } else {
                                                return
                                            }
                                        },
                                    },
                                ]}
                                help={fileURL?.length === 0 ? null : ''}
                            >
                                <Upload
                                    listType="picture-circle"
                                    className="avatar-uploader"
                                    {...props}
                                    fileList={[]}
                                    showUploadList={false}
                                    onPreview={() => {}}
                                    accept=".png,.jpg,.jpeg,.svg"
                                    style={{
                                        height: '128px !important',
                                        width: '128px !important',
                                    }}
                                >
                                    {fileURL?.length > 0 ? (
                                        <Image
                                            src={fileURL[0]}
                                            preview={false}
                                            style={{
                                                height: '128px',
                                                width: '128px',
                                                borderRadius: '50%',
                                            }}
                                        />
                                    ) : selectedData ? (
                                        <div className={'hoverLayer'}>
                                            <div className={'hoverShow'}>
                                                <UploadImageIcon />
                                                <div
                                                    style={{
                                                        marginTop: 8,
                                                        color: token
                                                            .colorPalette
                                                            .baseColor.white,
                                                    }}
                                                >
                                                    Update logo
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <UploadImageIcon
                                                color={
                                                    token.colorPalette.textColor
                                                        .senary
                                                }
                                            />
                                            <div
                                                style={{
                                                    marginTop: 8,
                                                    color: token.colorPalette
                                                        .textColor.senary,
                                                }}
                                            >
                                                Upload photo
                                            </div>
                                        </div>
                                    )}
                                </Upload>
                                <Row
                                    style={{
                                        marginTop: '10px',
                                    }}
                                >
                                    <Text
                                        className="titleSecondary"
                                        style={{
                                            fontSize: token.fontSizeIcon,
                                            textAlign: 'center',
                                        }}
                                    >
                                        Allowed *.jpeg, *.jpg, *.png, Max size
                                        of 5 MB
                                    </Text>
                                </Row>
                            </Form.Item>
                        </Card>
                    </Col>
                    <Col span={15}>
                        <Row
                            gutter={20}
                            style={{
                                rowGap: '15px',
                            }}
                        >
                            {selectedData &&
                                !DOMAIN_LIST.includes(selectedData?.domain) && (
                                    <Col span={24}>
                                        <Space>
                                            <Text
                                                style={{
                                                    fontWeight:
                                                        token.fontWeightStrong,
                                                }}
                                            >
                                                Active
                                            </Text>
                                            <Switch
                                                defaultChecked={activeCheck}
                                                onChange={(checked) =>
                                                    setActiveCheck(checked)
                                                }
                                                checked={activeCheck}
                                            />
                                        </Space>
                                    </Col>
                                )}
                            <Col span={24}>
                                <Form.Item
                                    name={'companyName'}
                                    label="Company Name*"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Please Enter Company Name',
                                        },
                                        {
                                            max: 25,
                                            message:
                                                'Name is maximum 25 characters long.',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Enter Name" />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name={'companyEmail'}
                                    label="Company E-Mail*"
                                    rules={[
                                        {
                                            validator: async (_, value) => {
                                                const pattern = EMAIL_PATTERN

                                                if (
                                                    value?.length === 0 ||
                                                    value === undefined
                                                ) {
                                                    return Promise.reject(
                                                        new Error(
                                                            'Please Enter Email'
                                                        )
                                                    )
                                                }
                                                if (
                                                    !pattern.test(value) &&
                                                    value?.length > 0
                                                ) {
                                                    return Promise.reject(
                                                        new Error(
                                                            'Please Enter a valid Email Id'
                                                        )
                                                    )
                                                }
                                            },
                                        },
                                        // {},
                                    ]}
                                >
                                    <Input
                                        placeholder="Enter Mail"
                                        onChange={() => {}}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name={'domain'}
                                    label="Domain Name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please Enter Domain Name',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Enter Domain Name" />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label="Phone Number*"
                                    name="phone"
                                    rules={[
                                        {
                                            validator: async () => {
                                                if (
                                                    !phoneValid &&
                                                    phoneNumber?.phoneNo
                                                        ?.length > 0
                                                ) {
                                                    return Promise.reject(
                                                        new Error(
                                                            'Please Enter a valid Phone Number'
                                                        )
                                                    )
                                                }
                                                if (
                                                    phoneNumber?.phoneNo
                                                        ?.length === 0 ||
                                                    phoneNumber?.phoneNo ===
                                                        null
                                                ) {
                                                    return Promise.reject(
                                                        new Error(
                                                            'Please Enter Phone Number'
                                                        )
                                                    )
                                                }
                                            },
                                        },
                                    ]}
                                    colon={false}
                                >
                                    <PhoneInput
                                        inputProps={{
                                            name: 'phone',
                                            required: true,
                                        }}
                                        specialLabel=""
                                        onlyCountries={['in', 'au', 'us', 'gb']}
                                        country={'in'}
                                        inputStyle={{
                                            height: '50px',
                                            width: '100%',
                                        }}
                                        value={phoneNumber?.default ?? null}
                                        onChange={(number, country) => {
                                            handleChangePhoneNumber(
                                                number,
                                                country
                                            )
                                            const correspondingCountry =
                                                PHONE_COUNTRIES.find(
                                                    (countryName) =>
                                                        countryName.iso2 ===
                                                        country.countryCode?.toUpperCase()
                                                )
                                            let numberNew = `+${number}`
                                            if (
                                                correspondingCountry &&
                                                number &&
                                                correspondingCountry?.validation.test(
                                                    numberNew
                                                )
                                            ) {
                                                setPhoneValid(true)
                                            } else {
                                                setPhoneValid(false)
                                            }
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row
                    gutter={30}
                    style={{
                        rowGap: '25px',
                        marginTop: '25px',
                    }}
                >
                    <Col span={24}>
                        <Form.Item
                            name={'address'}
                            label="Address*"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Enter Address',
                                },
                            ]}
                        >
                            <Select
                                placeholder="Search Address"
                                allowClear={false}
                                notFoundContent={
                                    <Empty
                                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                                        description="No Address Found"
                                    ></Empty>
                                }
                                onSearch={(value) => {
                                    loadSuggestions(value)
                                }}
                                showSearch
                                options={suggestions}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item>
                            <Radio.Group
                                onChange={onChangeRadio}
                                value={radioValue}
                            >
                                <Radio value="gmail"> Mail </Radio>
                                <Radio value="other"> Other </Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name={'emailName'}
                            label="Email Name*"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Enter Email Name',
                                },
                                {
                                    max: 25,
                                    message:
                                        'Email Name is maximum 25 characters long.',
                                },
                            ]}
                        >
                            <Input placeholder="Enter Email Name" />
                        </Form.Item>
                    </Col>
                    {radioValue === 'other' && (
                        <>
                            <Col span={12}>
                                <Form.Item
                                    name={'host'}
                                    label="Host*"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please Enter Host',
                                        },
                                        {
                                            validator: async (_, value) => {
                                                const pattern = HOST_PATTERN

                                                if (
                                                    !pattern.test(value) &&
                                                    value?.length > 0
                                                ) {
                                                    return Promise.reject(
                                                        new Error(
                                                            'Host name should be valid'
                                                        )
                                                    )
                                                }
                                            },
                                        },
                                    ]}
                                >
                                    <Input placeholder="Enter Host" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name={'port'}
                                    label="Port*"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please Enter Port',
                                        },
                                        {
                                            validator: async (_, value) => {
                                                const pattern = PORT_PATTERN

                                                if (
                                                    !pattern.test(value) &&
                                                    value?.length > 0
                                                ) {
                                                    return Promise.reject(
                                                        new Error(
                                                            'Port name should be valid'
                                                        )
                                                    )
                                                }
                                            },
                                        },
                                    ]}
                                >
                                    <Input placeholder="Enter Port" />
                                </Form.Item>
                            </Col>
                        </>
                    )}
                    <Col span={12}>
                        <Form.Item
                            name={'email'}
                            label="Email Address*"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Enter Email Address',
                                },
                                {
                                    max: 25,
                                    message:
                                        'Email Address is maximum 25 characters long.',
                                },
                            ]}
                        >
                            <Input placeholder="Enter Address" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Password"
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Enter Password',
                                },
                            ]}
                            colon={false}
                        >
                            <Input.Password
                                placeholder="Enter Password"
                                style={{
                                    padding: '0px 11px',
                                }}
                                iconRender={(visible) =>
                                    visible ? (
                                        <div
                                            onClick={() => {}}
                                            style={{
                                                cursor: 'pointer',
                                            }}
                                        >
                                            <PasswordShowIcon />
                                        </div>
                                    ) : (
                                        <div
                                            onClick={() => {}}
                                            style={{
                                                cursor: 'pointer',
                                            }}
                                        >
                                            <PasswordHideIcon />
                                        </div>
                                    )
                                }
                            />
                        </Form.Item>
                    </Col>
                    {radioValue === 'other' && (
                        <Col span={24}>
                            <Button
                                props={{
                                    text: 'Verify',
                                    onClick: () => {
                                        VerifyHostPort()
                                    },
                                    disabled: verifyLoading,
                                    loadingButton: verifyLoading,
                                }}
                            />
                        </Col>
                    )}
                </Row>
            </Form>
        </>
    )
}

export default WorkSpaceModalContent
