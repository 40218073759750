import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// antd
import { Row, Spin, Typography, theme } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

// files modal
import FilesModalContent from '../../../files/filesModalContent'

// components
import Button from '../../../../components/button/button'

// assets
import { NoAttachmentsIcon } from '../../../../assets/icons'

// slices
import {
    inquiryAddRemoveFiles,
    inquiryCountFiles,
    inquiryListFiles,
    inquiryState,
} from '../../inquiries.slice'

// attachment list
import ListInquiryAttachment from './listInquiryAttachment'

const AddInquiryAttachment = ({ keyTab }) => {
    const { Text } = Typography

    const { useToken } = theme
    const { token } = useToken()

    const dispatch = useDispatch()

    const {
        pageLimitAttachment,
        skipPageAttachment,
        selectedInquiry,
        attachmentList,
        attachmentStatus,
        defaultTab,
    } = useSelector(inquiryState)

    const [uploadFileModal, setUploadFileModal] = useState(false)

    useEffect(() => {
        if (defaultTab === keyTab) {
            dispatch(
                inquiryListFiles({
                    skip: 0,
                    limit: 10,
                    id: selectedInquiry?._id,
                    workspaceId: selectedInquiry?.client?.workspaceId,
                })
            )
            dispatch(
                inquiryCountFiles({
                    id: selectedInquiry?._id,
                    workspaceId: selectedInquiry?.client?.workspaceId,
                })
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultTab])

    return (
        <>
            <Row justify={'end'}>
                <Button
                    props={{
                        text: 'Upload Files',
                        icon: <PlusOutlined />,
                        onClick: () => setUploadFileModal(true),
                    }}
                />
            </Row>
            <Spin spinning={attachmentStatus === 'loading' ? true : false}>
                {attachmentList?.length > 0 ? (
                    <div
                        style={{
                            marginBottom: '30px',
                        }}
                    >
                        <ListInquiryAttachment />
                    </div>
                ) : (
                    attachmentStatus !== 'loading' && (
                        <div
                            style={{
                                width: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                flexDirection: 'column',
                                rowGap: '19px',
                            }}
                        >
                            <NoAttachmentsIcon />
                            <Text
                                style={{
                                    color: token.colorPalette.textColor
                                        .secondary,
                                    fontSize: token.fontSizeHeading4,
                                }}
                            >
                                No Attachments Uploaded yet
                            </Text>
                        </div>
                    )
                )}
            </Spin>
            <FilesModalContent
                props={{
                    visibility: uploadFileModal,
                    onCancel: () => setUploadFileModal(false),
                    pageLimit: pageLimitAttachment,
                    skipPage: skipPageAttachment,
                    addRemoveFiles: inquiryAddRemoveFiles,
                    filesGet: inquiryListFiles,
                    totalFiles: inquiryCountFiles,
                    modalKey: 'inquiry',
                    selectedInquiry: selectedInquiry?._id,
                    workspaceId: selectedInquiry?.client.workspaceId,
                }}
                visibility={uploadFileModal}
                onCancel={() => setUploadFileModal(false)}
                // pageLimit={filesPageLimit}
                // skipPage={filesSkipPage}
            />
        </>
    )
}

export default AddInquiryAttachment
