import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// antd
import {
    Col,
    Divider,
    Image,
    Row,
    Space,
    Spin,
    Tag,
    Typography,
    theme,
} from 'antd'

// components
import Button from '../../../components/button/button'
import ModalProfile from '../../../components/modal'

// slices
import {
    profileState,
    setDeleteModalTitle,
    switchProfileModal,
    updatedDataProfile,
} from '../profile.slice'
import { loginState } from '../../login/login.slice'

// assets
import {
    EditIcon,
    ExperienceIcon,
    ExperienceLogoIcon,
    NoExperienceIcon,
} from '../../../assets/icons'

// tab
import NoIndividualContent from '../tabSection/noIndividualContent'

// constants
import { SUPER_ADMIN } from '../../../constants/roles'

// modals
import ViewMediaModal from './viewMediaModal'

// styles
import styles from '../profile.module.css'

function ExperienceTabContent() {
    const { Title, Text } = Typography

    const { useToken } = theme
    const { token } = useToken()
    const [viewMediaModalExperience, setViewMediaModalExperience] = useState({
        open: false,
        fileName: '',
    })

    const dispatch = useDispatch()

    const {
        profileData: {
            data: { experience },
        },
        status,
    } = useSelector(profileState)

    const { userProfile } = useSelector(loginState)

    return (
        <>
            <div
                style={{
                    padding: '50px',
                }}
            >
                <Row align="middle" justify={'space-between'}>
                    <Title level={4}>Experience</Title>
                    {userProfile?.role?.roleName !== SUPER_ADMIN && (
                        <Button
                            props={{
                                text: 'Add Experience',
                                onClick: () => {
                                    dispatch(
                                        switchProfileModal({
                                            name: 'experience',
                                            open: true,
                                        })
                                    )
                                },
                            }}
                        />
                    )}
                </Row>
                <Spin spinning={status === 'loading' ? true : false}>
                    {status === 'loaded' && experience?.length > 0 ? (
                        experience.map((experienceData, index) => (
                            <>
                                <Row
                                    justify={'space-between'}
                                    style={{
                                        width: '100%',
                                        marginTop: '35px',
                                    }}
                                >
                                    <Col span={23}>
                                        <Row
                                            key={
                                                experienceData?.companyName
                                                    ?.name
                                            }
                                            style={{
                                                columnGap: '20px',
                                                flexFlow: 'row nowrap',
                                            }}
                                        >
                                            <Row
                                                style={{
                                                    height: '100px',
                                                    width: '100px',
                                                    minWidth: '100px',
                                                    borderRadius: '10px',
                                                    padding:
                                                        '20px 20px 20px 20px',
                                                    border: `2px solid ${token.colorPalette.textColor.quaternary}`,
                                                }}
                                            >
                                                {experienceData?.companyName
                                                    ?.logo ? (
                                                    <Image
                                                        src={
                                                            experienceData
                                                                ?.companyName
                                                                ?.logo
                                                        }
                                                        width={'100%'}
                                                        height={'100%'}
                                                        preview={false}
                                                    />
                                                ) : (
                                                    <div
                                                        style={{
                                                            height: '56px',
                                                            width: '56px',
                                                        }}
                                                    >
                                                        <ExperienceLogoIcon />
                                                    </div>
                                                )}
                                            </Row>

                                            <div
                                                style={{
                                                    marginRight: '50px',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                }}
                                            >
                                                <Row>
                                                    <Space>
                                                        {experienceData?.title && (
                                                            <Title
                                                                className={
                                                                    'titleSub'
                                                                }
                                                            >
                                                                {
                                                                    experienceData?.title
                                                                }
                                                            </Title>
                                                        )}
                                                        <Text className="titleLight">
                                                            {!experienceData?.isCompleted &&
                                                                `(Draft)`}
                                                        </Text>
                                                    </Space>
                                                </Row>
                                                <Space>
                                                    {experienceData?.companyName
                                                        ?.name && (
                                                        <Text
                                                            style={{
                                                                fontSize:
                                                                    token.fontSize,
                                                            }}
                                                        >
                                                            {
                                                                experienceData
                                                                    ?.companyName
                                                                    ?.name
                                                            }
                                                        </Text>
                                                    )}
                                                    {experienceData?.companyName
                                                        ?.name &&
                                                        experienceData?.location &&
                                                        '|'}
                                                    {experienceData?.location && (
                                                        <Text
                                                            style={{
                                                                fontSize:
                                                                    token.fontSize,
                                                            }}
                                                        >
                                                            {
                                                                experienceData?.location
                                                            }
                                                        </Text>
                                                    )}
                                                </Space>
                                                <Space>
                                                    {experienceData?.start !==
                                                        null && (
                                                        <Text
                                                            style={{
                                                                fontSize:
                                                                    token.fontSizeLG,
                                                                marginTop:
                                                                    '15px',
                                                            }}
                                                            className={
                                                                'titleLight'
                                                            }
                                                        >
                                                            {new Date(
                                                                experienceData?.start
                                                            )?.toLocaleString(
                                                                'default',
                                                                {
                                                                    year: 'numeric',
                                                                    month: 'long',
                                                                }
                                                            )}
                                                        </Text>
                                                    )}
                                                    {experienceData?.start !==
                                                        null &&
                                                        (experienceData?.currentlyWorking ||
                                                            experienceData?.end !==
                                                                null) &&
                                                        '-'}
                                                    {(experienceData?.currentlyWorking ||
                                                        experienceData?.end !==
                                                            null) && (
                                                        <Text
                                                            style={{
                                                                fontSize:
                                                                    token.fontSizeLG,
                                                                marginTop:
                                                                    '15px',
                                                            }}
                                                            className={
                                                                'titleLight'
                                                            }
                                                        >
                                                            {experienceData?.currentlyWorking
                                                                ? 'Present'
                                                                : new Date(
                                                                      experienceData?.end
                                                                  )?.toLocaleString(
                                                                      'default',
                                                                      {
                                                                          month: 'long',
                                                                          year: 'numeric',
                                                                      }
                                                                  )}
                                                        </Text>
                                                    )}
                                                </Space>
                                                {experienceData?.skills
                                                    ?.length > 0 && (
                                                    <div
                                                        style={{
                                                            marginTop: '15px',
                                                        }}
                                                    >
                                                        <Text
                                                            style={{
                                                                fontSize:
                                                                    token.fontSizeLG,
                                                                fontWeight:
                                                                    token.fontWeightStrong,
                                                            }}
                                                        >
                                                            Skills
                                                        </Text>
                                                        <Row
                                                            style={{
                                                                rowGap: '10px',
                                                                marginTop:
                                                                    '8px',
                                                                columnGap:
                                                                    '10px',
                                                            }}
                                                        >
                                                            {experienceData?.skills.map(
                                                                (skill) => (
                                                                    <Tag
                                                                        style={{
                                                                            padding:
                                                                                '9px 32px',
                                                                            cursor: 'text',
                                                                        }}
                                                                        key={
                                                                            skill?.name
                                                                        }
                                                                        color={
                                                                            token
                                                                                .colorPalette
                                                                                .baseColor
                                                                                .senary
                                                                        }
                                                                    >
                                                                        <Text
                                                                            style={{
                                                                                fontWeight:
                                                                                    token.fontWeightStrong,
                                                                            }}
                                                                        >
                                                                            {skill?.name?.toUpperCase()}
                                                                        </Text>
                                                                    </Tag>
                                                                )
                                                            )}
                                                        </Row>
                                                    </div>
                                                )}
                                                {experienceData?.tools?.length >
                                                    0 && (
                                                    <div
                                                        style={{
                                                            marginTop: '15px',
                                                        }}
                                                    >
                                                        <Text
                                                            style={{
                                                                fontSize:
                                                                    token.fontSizeLG,
                                                                fontWeight:
                                                                    token.fontWeightStrong,
                                                            }}
                                                        >
                                                            Tools
                                                        </Text>
                                                        <Row
                                                            style={{
                                                                columnGap:
                                                                    '15px',
                                                                marginTop:
                                                                    '8px',
                                                            }}
                                                        >
                                                            {experienceData?.tools.map(
                                                                (tool) => (
                                                                    <div
                                                                        key={
                                                                            tool?.label
                                                                        }
                                                                    >
                                                                        <div
                                                                            className={
                                                                                styles.toolIconDefault
                                                                            }
                                                                            style={{
                                                                                border: `2px solid ${token.colorPalette.baseColor.tertiary}`,
                                                                            }}
                                                                        >
                                                                            <Image
                                                                                src={
                                                                                    tool?.logo
                                                                                }
                                                                                preview={
                                                                                    false
                                                                                }
                                                                                width={
                                                                                    '100%'
                                                                                }
                                                                            />
                                                                        </div>
                                                                        <div
                                                                            style={{
                                                                                width: '60px',
                                                                                whiteSpace:
                                                                                    'nowrap',
                                                                                overflow:
                                                                                    'hidden',
                                                                                textOverflow:
                                                                                    'ellipsis',
                                                                            }}
                                                                        >
                                                                            <Text
                                                                                style={{
                                                                                    marginTop:
                                                                                        '2px',
                                                                                    fontWeight:
                                                                                        token.fontWeightStrong,
                                                                                    textTransform:
                                                                                        'capitalize',
                                                                                }}
                                                                            >
                                                                                {
                                                                                    tool?.name
                                                                                }
                                                                            </Text>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            )}
                                                        </Row>
                                                    </div>
                                                )}
                                                {experienceData?.fileLink
                                                    ?.length > 0 && (
                                                    <div
                                                        style={{
                                                            marginTop: '15px',
                                                            display: 'flex',
                                                            flexDirection:
                                                                'column',
                                                            rowGap: '5px',
                                                        }}
                                                    >
                                                        <Text
                                                            style={{
                                                                fontSize:
                                                                    token.fontSizeLG,
                                                                fontWeight:
                                                                    token.fontWeightStrong,
                                                            }}
                                                        >
                                                            Media
                                                        </Text>
                                                        <Row
                                                            align={'top'}
                                                            style={{
                                                                columnGap:
                                                                    '18px',
                                                            }}
                                                        >
                                                            <video
                                                                src={
                                                                    experienceData?.fileLink
                                                                }
                                                                width={'194px'}
                                                                height={'110px'}
                                                                style={{
                                                                    objectFit:
                                                                        'cover',
                                                                    borderRadius:
                                                                        '15px',
                                                                    cursor: 'pointer',
                                                                }}
                                                                preview={false}
                                                                onClick={() => {
                                                                    setViewMediaModalExperience(
                                                                        {
                                                                            open: true,
                                                                            fileName:
                                                                                experienceData?.fileLink,
                                                                        }
                                                                    )
                                                                }}
                                                            />
                                                            <div>
                                                                {experienceData?.fileTitle && (
                                                                    <Title
                                                                        level={
                                                                            5
                                                                        }
                                                                    >
                                                                        {
                                                                            experienceData?.fileTitle
                                                                        }
                                                                    </Title>
                                                                )}
                                                                {experienceData?.description && (
                                                                    <Text>
                                                                        {
                                                                            experienceData?.description
                                                                        }
                                                                    </Text>
                                                                )}
                                                            </div>
                                                        </Row>
                                                    </div>
                                                )}
                                            </div>
                                        </Row>
                                    </Col>
                                    <Col span={1}>
                                        {userProfile?.role?.roleName !==
                                            SUPER_ADMIN && (
                                            <div
                                                style={{
                                                    cursor: 'pointer',
                                                    height: '26px',
                                                    width: '26px',
                                                }}
                                                onClick={() => {
                                                    dispatch(
                                                        setDeleteModalTitle(
                                                            'experience'
                                                        )
                                                    )
                                                    dispatch(
                                                        switchProfileModal({
                                                            name: 'experience',
                                                            open: true,
                                                            mode: 'edit',
                                                        })
                                                    )
                                                    dispatch(
                                                        updatedDataProfile({
                                                            editData: {
                                                                step: 'experience',
                                                                stepField:
                                                                    '_id',
                                                                stepFieldValue:
                                                                    experienceData?._id,
                                                            },
                                                            _id: userProfile?._id,
                                                        })
                                                    )
                                                }}
                                            >
                                                <EditIcon />
                                            </div>
                                        )}
                                    </Col>
                                </Row>
                                {experience?.length - 1 !== index && (
                                    <Divider />
                                )}
                            </>
                        ))
                    ) : status === 'loaded' ? (
                        <NoIndividualContent
                            content={{
                                key: 'experience',
                                title: 'Experience',
                                icon: <NoExperienceIcon />,
                            }}
                        />
                    ) : (
                        status === 'loading' && <Spin />
                    )}
                </Spin>
            </div>
            <ModalProfile
                visibility={viewMediaModalExperience.open}
                handleCancel={() => {
                    setViewMediaModalExperience({
                        open: false,
                        fileName: '',
                    })
                }}
                icon={<ExperienceIcon />}
                modalKey="viewVideo"
                modalTitle="Media"
                showTitle="Media"
                footer={false}
                content={
                    <ViewMediaModal
                        fileName={viewMediaModalExperience?.fileName}
                    />
                }
            />
        </>
    )
}

export default ExperienceTabContent
