import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// antd
import {
    Col,
    Popover,
    Row,
    Skeleton,
    Tag,
    Tooltip,
    Typography,
    message,
    theme,
} from 'antd'

// components
import Button from '../../components/button/button'

// slices
import { loginState } from '../login/login.slice'
import {
    getSprint,
    sprintSelect,
    sprintsList,
    sprintsState,
    switchFormModal,
    switchTab,
    taskSprintList,
    updateSprint,
} from './sprints.slice'
import { projectsState } from '../projects/projects.slice'

// assets
import { DeleteIcon, EditIcon, EllipsisIcon } from '../../assets/icons'

// helpers
import { deletePermissionCheck } from '../../helpers/permissionCheck'
import { estimateFn } from '../../helpers/estimationCalculation'

import dayjs from 'dayjs'

const SprintsTabHeader = ({ props }) => {
    const { data: newSprintData, selectedSprint, state, setState } = props

    const { Text } = Typography

    const { useToken } = theme
    const { token } = useToken()

    const dispatch = useDispatch()

    const { userProfile } = useSelector(loginState)
    const { modalVariations, skipPageTask, pageLimitTask } =
        useSelector(sprintsState)
    const { currentProjectData } = useSelector(projectsState)

    const [openEpicMenu, setOpenEpicMenu] = useState(false)
    const handleOpenEpicMenu = (newOpen) => {
        setOpenEpicMenu(newOpen)
    }

    const DeleteSprintComponent = () => 'Delete'

    // LIST TASKS SPRINT WISE
    async function listTasksSprint(id, label, sprintData, name) {
        let sprintId = id !== 'Backlog' && id
        const result = await dispatch(
            taskSprintList({
                skip: skipPageTask,
                limit: pageLimitTask,
                projectId: userProfile?.activeProject?._id,
                sprintId,
            })
        )
        const data = result?.payload?.data
        if (data) {
            if (name === 'sprint') {
                setState((prevState) => ({
                    ...prevState,
                    activeTabName: '',
                    activeTab: '',
                }))
                // setSprintTasks(data)
            } else {
                setState((prevState) => ({
                    ...prevState,
                    activeTabName: label,
                    activeTab: id,
                }))
            }
            dispatch(switchTab(sprintData))
        }
    }

    // UPDATE SPRINT STATUS (START, COMPLETED)
    async function updateSprintStatus(id, name, sprintId) {
        const result = await dispatch(
            updateSprint({
                editSprint: {
                    isActive: name === 'moveSprint' ? false : true,
                    moveSprintId: name === 'moveSprint' && sprintId,
                },
                _id: id,
            })
        )
        const data = result?.payload?.data
        if (data) {
            const { success, message: checkMessage } = data
            if (success) {
                if (name === 'moveSprint') {
                    setState({
                        ...state,
                        startSprintLoading: false,
                        completeSprintModalLoading: false,
                        completeSprintModal: false,
                    })
                    message.success('Sprint Completed Successfully')
                    listTasksSprint(
                        state?.activeTab,
                        state?.activeTabName,
                        data?.data
                    )
                } else {
                    setState({
                        ...state,
                        startSprintLoading: false,
                        completeSprintModalLoading: false,
                    })
                    getSprintById(state?.activeTab, 'getSprint')
                    message.success('Sprint Started Successfully')
                }
                await dispatch(sprintsList())
            } else {
                setState({
                    ...state,
                    startSprintLoading: false,
                })
                if (name === 'moveSprint') {
                    setState({
                        ...state,
                        completeSprintModalLoading: false,
                        completeSprintModal: false,
                    })
                }
                if (typeof checkMessage === 'object') {
                    for (const key in checkMessage) {
                        message.error(checkMessage[key])
                    }
                } else if (typeof checkMessage === 'string') {
                    message.error(checkMessage)
                } else {
                    message.error('Something went wrong, try again later.')
                }
            }
        }
    }

    // GET SPRINT BY ID
    async function getSprintById(id, name) {
        const result = await dispatch(getSprint({ id: id }))
        const data = result?.payload?.data
        if (data) {
            const { success, message: checkMessage } = data
            if (success) {
                dispatch(sprintSelect(data?.data))
            } else {
                if (checkMessage) {
                    message.error(checkMessage)
                } else {
                    message.error('Something went wrong, try again later.')
                }
            }
        }
    }

    const dropDownIcon = [
        {
            title: 'Edit',
            icon: (
                <div
                    style={{
                        height: '20px',
                        width: '20px',
                    }}
                >
                    <EditIcon color={token.colorPalette.baseColor.tertiary} />
                </div>
            ),
            onClick: () => {
                setOpenEpicMenu(false)
                dispatch(
                    switchFormModal({
                        ...modalVariations,
                        open: true,
                        create: false,
                    })
                )
                // getSprintById(newSprintData?._id)
                getSprintById(selectedSprint?._id)
            },
        },
        {
            icon: <DeleteIcon />,
            title: deletePermissionCheck('sprints', userProfile) ? (
                <DeleteSprintComponent />
            ) : (
                <Tooltip
                    arrow={false}
                    placement="bottom"
                    title="You don't have permission to delete sprint"
                >
                    <Text>Delete</Text>
                </Tooltip>
            ),
            onClick: () => {
                if (deletePermissionCheck('sprints', userProfile)) {
                    setOpenEpicMenu(false)
                    setState((prevState) => ({
                        ...prevState,
                        deleteSprintId: selectedSprint?._id,
                        // deleteSprintId: newSprintData?._id,
                    }))
                    if (selectedSprint?.tasksList?.length === 0) {
                        setState((prevState) => ({
                            ...prevState,
                            deleteSprintModal: true,
                        }))
                    } else {
                        setState((prevState) => ({
                            ...prevState,
                            deleteSprintPopUp: true,
                        }))
                    }
                }
            },
        },
    ]

    const dropDownContent = () => {
        return (
            <>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        rowGap: '8px',
                    }}
                >
                    {dropDownIcon?.length > 0 &&
                        dropDownIcon.map((data) => (
                            <Row
                                style={{
                                    cursor: 'pointer',
                                }}
                                onClick={() => data?.onClick()}
                            >
                                <div
                                    style={{
                                        marginRight: '15px',
                                    }}
                                >
                                    {data?.icon}
                                </div>
                                <Text className="sub-title">{data?.title}</Text>
                            </Row>
                        ))}
                </div>
            </>
        )
    }

    return (
        <div
            style={{
                marginBottom: 12,
                minWidth: '100%',
            }}
            key={newSprintData?._id}
        >
            {newSprintData === undefined && selectedSprint === undefined ? (
                <>
                    <Skeleton.Input
                        active={true}
                        size="large"
                        block={true}
                        rootClassName=""
                        style={{
                            width: '100% !important',
                            height: '48px',
                        }}
                    />
                </>
            ) : (
                <Row
                    align={'middle'}
                    wrap={false}
                    justify={'space-between'}
                    style={{
                        padding: '12px 16px',
                        backgroundColor:
                            token.colorPalette.baseColor.quaternary,
                        borderRadius: '10px',
                    }}
                >
                    <Col span={14}>
                        <Row gutter={12} align={'middle'}>
                            <Col>
                                <div className={'sub-title'}>
                                    {selectedSprint?.title}
                                    {/* {newSprintData?.title} */}
                                </div>
                            </Col>
                            {selectedSprint?.title !== 'Backlog' && (
                                // {newSprintData?.title !== 'Backlog' && (
                                <Col>
                                    <span
                                        style={{
                                            fontSize: 16,
                                            color: '#788191',
                                        }}
                                    >
                                        (
                                        {/* {dayjs(newSprintData?.start).format(
                                            'DD MMM YY '
                                        )}{' '}
                                        -
                                        {dayjs(newSprintData?.end).format(
                                            'DD MMM YY '
                                        )}
                                        ) */}
                                        {dayjs(selectedSprint?.start).format(
                                            'DD MMM YY '
                                        )}{' '}
                                        -
                                        {dayjs(selectedSprint?.end).format(
                                            'DD MMM YY '
                                        )}
                                        )
                                    </span>
                                </Col>
                            )}
                        </Row>
                    </Col>
                    <Col span={10}>
                        <Row align={'middle'} gutter={16} justify={'end'}>
                            <Col>
                                <Row align={'middle'} gutter={1}>
                                    <Col>
                                        <Tag
                                            color="blue"
                                            style={{
                                                padding: '6px 8px',
                                                color: '#000',
                                                fontWeight:
                                                    token.fontWeightStrong,
                                            }}
                                        >
                                            {/* {newSprintData?.estimation === 0
                                                ? '0m'
                                                : estimateFn(
                                                      newSprintData?.estimation,
                                                      currentProjectData
                                                  )} */}
                                            {selectedSprint?.estimation === 0
                                                ? '0m'
                                                : estimateFn(
                                                      selectedSprint?.estimation,
                                                      currentProjectData
                                                  )}
                                        </Tag>
                                    </Col>
                                </Row>
                            </Col>
                            {/* {newSprintData?.title !== 'Backlog' &&
                                newSprintData?.isComplete === false && ( */}
                            {selectedSprint?.title !== 'Backlog' &&
                                selectedSprint?.isComplete === false && (
                                    <>
                                        <Col>
                                            <Button
                                                props={{
                                                    text: selectedSprint?.isActive
                                                        ? 'Complete Sprint'
                                                        : 'Start Sprint',
                                                    disabled:
                                                        selectedSprint
                                                            ?.tasksList
                                                            ?.length === 0,
                                                    loadingButton:
                                                        !selectedSprint?.isActive &&
                                                        state?.startSprintLoading,
                                                    style: {
                                                        padding: '6px 10px',
                                                    },
                                                    onClick: () => {
                                                        if (
                                                            selectedSprint?.isActive
                                                        ) {
                                                            setState({
                                                                ...state,
                                                                completeSprintModal: true,
                                                            })
                                                        } else {
                                                            setState({
                                                                ...state,
                                                                startSprintLoading: true,
                                                            })

                                                            updateSprintStatus(
                                                                selectedSprint?._id
                                                            )
                                                        }
                                                    },
                                                }}
                                            />
                                        </Col>
                                        <Col>
                                            <Popover
                                                content={dropDownContent}
                                                trigger="click"
                                                placement="bottom"
                                                open={openEpicMenu}
                                                overlayInnerStyle={{
                                                    padding: '12px',
                                                }}
                                                arrow={false}
                                                onOpenChange={
                                                    handleOpenEpicMenu
                                                }
                                            >
                                                <div
                                                    style={{
                                                        cursor: 'pointer',
                                                    }}
                                                >
                                                    <EllipsisIcon />
                                                </div>
                                            </Popover>
                                        </Col>
                                    </>
                                )}
                        </Row>
                    </Col>
                </Row>
            )}
        </div>
    )
}

export default SprintsTabHeader
