import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

// antd
import {
    Card,
    Checkbox,
    Row,
    Table,
    Tag,
    Typography,
    message,
    theme,
} from 'antd'

// slices
import {
    fieldGet,
    permissionOption,
    roleState,
    tableDataSet,
    updateRole,
} from '../role/role.slice'

// constants
import {
    ADD_EDIT_FIELD,
    ALL_DATA_ACCESS,
    ALL_DATA_ACCESS_VIEW,
    ASSIGN_DATA_ACCESS,
    NO_DATA_ACCESS,
    PERMISSION_ROLE,
    VIEW_FIELD,
} from '../../constants/permissionField'

// styles
import styles from './permissionRole.module.css'

// routes
import { PRIVATE_ROUTES } from '../../routes'

function PermissionRole() {
    const { Title } = Typography

    const { useToken } = theme

    const dispatch = useDispatch()

    const params = useParams()

    const { token } = useToken()

    const navigate = useNavigate()

    const { moduleSelected, tableData, permissionOptionText } =
        useSelector(roleState)

    // CHANGE PERMISSION FIELD
    const changePermission = async (updatedData) => {
        const result = await dispatch(
            updateRole({
                step: 'module',
                _id: params?.id,
                updatedData,
            })
        )
        const data = result?.payload?.data
        if (data) {
            const { success, message: checkMessage } = data
            if (success) {
                dispatch(permissionOption(''))
                dispatch(fieldGet({ _id: params?.id }))
                message.success(checkMessage)
            } else {
                if (checkMessage) {
                    message.error(checkMessage)
                } else {
                    message.error('Something went wrong, try again later.')
                }
            }
        }
    }

    let fieldArray = []

    // COLUMNS
    const columns = [
        {
            key: 'fieldName',
            dataIndex: 'fieldName',
            title: `Field Name`,
            render: (_, data) => <Title level={5}>{data?.fieldName}</Title>,
        },
        {
            key: 'viewPermission',
            dataIndex: 'viewPermission',
            title: `View Permission`,
            render: (data, item) => (
                <>
                    <Checkbox
                        checked={data}
                        name={item?.name}
                        onChange={async (e) => {
                            const arrayFinal = tableData.map((data) => {
                                if (data?.keyView === item?.name) {
                                    return {
                                        ...data,
                                        viewPermission: e.target.checked,
                                    }
                                } else {
                                    return data
                                }
                            })

                            let updatedData = {
                                ...moduleSelected,
                                [ALL_DATA_ACCESS_VIEW]:
                                    tableData?.length > 0 &&
                                    tableData.every(
                                        (data) => data?.viewPermission
                                    ),
                                [VIEW_FIELD]: {
                                    ...moduleSelected[VIEW_FIELD],
                                    [item.name]: {
                                        ...moduleSelected[VIEW_FIELD][
                                            item.name
                                        ],
                                        hasPermission: e.target.checked,
                                    },
                                },
                            }
                            dispatch(tableDataSet(arrayFinal))
                            changePermission(updatedData)
                        }}
                    />
                </>
            ),
        },
        {
            key: 'editPermission',
            dataIndex: 'editPermission',
            title: `Edit Permission`,
            render: (data, item) => (
                <>
                    <Checkbox
                        checked={data}
                        name={item?.name}
                        onChange={async (e) => {
                            const arrayFinal = tableData.map((dataTable) => {
                                if (
                                    (item?.editKey !== undefined &&
                                        dataTable?.editName !== undefined &&
                                        item?.editKey ===
                                            dataTable?.editName) ||
                                    dataTable?.keyView === item?.name
                                ) {
                                    return {
                                        ...dataTable,
                                        editPermission: e.target.checked,
                                    }
                                } else {
                                    return dataTable
                                }
                            })
                            let updatedData = {
                                ...moduleSelected,
                                [ADD_EDIT_FIELD]: {
                                    ...moduleSelected[ADD_EDIT_FIELD],
                                    [item.editName
                                        ? item?.editName
                                        : item?.name]: {
                                        ...moduleSelected[ADD_EDIT_FIELD][
                                            item?.editName
                                                ? item?.editName
                                                : item?.name
                                        ],
                                        hasPermission: e.target.checked,
                                    },
                                },
                            }

                            dispatch(tableDataSet(arrayFinal))
                            changePermission(updatedData)
                        }}
                    />
                </>
            ),
        },
    ]

    let fieldTotalArray = []
    let newArray = []
    let newData = {}

    // CHECK EDIT PERMISSION (ALL DATA / NO DATA / ASSIGN DATA)
    function checkEdit(data, permissionKey, flag) {
        let foundNew = {}
        let editKeys = Object.keys(moduleSelected[permissionKey])

        editKeys.map((editField) => {
            if (
                moduleSelected[permissionKey][editField].combinedFields
                    ?.length > 0 &&
                moduleSelected[permissionKey][
                    editField
                ].combinedFields.includes(data)
            ) {
                foundNew = {
                    data: {
                        ...moduleSelected[permissionKey][editField],
                        hasPermission:
                            flag !== undefined &&
                            permissionKey === ADD_EDIT_FIELD
                                ? flag
                                : moduleSelected[permissionKey][editField]
                                      .hasPermission,
                    },
                    fieldName: data,
                    editKey: editField,
                    editName: editField,
                    viewPermission:
                        moduleSelected[VIEW_FIELD][data]?.combinedFields
                            ?.length > 0
                            ? moduleSelected[VIEW_FIELD][editField]
                                  ?.hasPermission
                            : moduleSelected[VIEW_FIELD][data]?.hasPermission,
                    editPermission:
                        flag !== undefined && permissionKey === ADD_EDIT_FIELD
                            ? flag
                            : moduleSelected[permissionKey][editField]
                                  .hasPermission,
                }
            } else if (
                moduleSelected[permissionKey][editField].combinedFields
                    ?.length === 0 &&
                data === editField
            ) {
                foundNew = {
                    data: {
                        ...moduleSelected[permissionKey][editField],
                        hasPermission:
                            flag !== undefined &&
                            permissionKey === ADD_EDIT_FIELD
                                ? flag
                                : moduleSelected[permissionKey][editField]
                                      .hasPermission,
                    },
                    fieldName: editField,
                    editKey: editField,
                    editName: editField,
                    viewPermission:
                        moduleSelected[VIEW_FIELD][data]?.hasPermission,
                    editPermission:
                        flag !== undefined && permissionKey === ADD_EDIT_FIELD
                            ? flag
                            : moduleSelected[permissionKey][editField]
                                  .hasPermission,
                }
            }
        })
        return foundNew
    }

    // CHECK VIEW PERMISSION (ALL DATA / NO DATA / ASSIGN DATA)
    const checkDataSet = (
        permissionKey,
        flag,
        name,
        allDataKey,
        dataAccess
    ) => {
        let viewKeys = Object.keys(moduleSelected[permissionKey])
        let editKeys = Object.keys(moduleSelected[ADD_EDIT_FIELD])

        viewKeys.forEach((field) => {
            const combineData =
                moduleSelected[permissionKey][field].combinedFields
            if (combineData?.length > 0) {
                combineData.forEach((data) => {
                    fieldTotalArray.push(data)
                })
            } else {
                fieldTotalArray.push(field)
            }

            if (permissionKey === 'view') {
                if (combineData?.length > 0) {
                    combineData.forEach((data) => {
                        fieldArray.push({
                            data: {
                                ...moduleSelected[permissionKey][field],
                                hasPermission:
                                    flag !== undefined &&
                                    permissionKey === VIEW_FIELD
                                        ? flag
                                        : moduleSelected[permissionKey][field]
                                              .hasPermission,
                            },
                            fieldName: data,
                            keyView: field,
                            name: field,
                            editPermission:
                                moduleSelected[ADD_EDIT_FIELD][field] !==
                                undefined
                                    ? moduleSelected[ADD_EDIT_FIELD][field]
                                          ?.hasPermission
                                    : moduleSelected[ADD_EDIT_FIELD][editKeys]
                                          ?.combinedFields?.length > 0 &&
                                      moduleSelected[ADD_EDIT_FIELD][
                                          editKeys
                                      ].combinedFields.includes(data)
                                    ? moduleSelected[ADD_EDIT_FIELD][editKeys]
                                          ?.hasPermission
                                    : moduleSelected[ADD_EDIT_FIELD][field]
                                          ?.hasPermission,
                            viewPermission:
                                flag !== undefined &&
                                permissionKey === VIEW_FIELD
                                    ? flag
                                    : moduleSelected[permissionKey][field]
                                          ?.hasPermission,
                        })
                    })
                } else {
                    fieldArray.push({
                        data: {
                            ...moduleSelected[permissionKey][field],
                            hasPermission:
                                flag !== undefined &&
                                permissionKey === VIEW_FIELD
                                    ? flag
                                    : moduleSelected[permissionKey][field]
                                          .hasPermission,
                        },
                        fieldName: field,
                        keyView: field,
                        name: field,
                        editPermission:
                            moduleSelected[ADD_EDIT_FIELD][field] !== undefined
                                ? moduleSelected[ADD_EDIT_FIELD][field]
                                      ?.hasPermission
                                : editKeys.some((data) => {
                                      if (
                                          moduleSelected[ADD_EDIT_FIELD][
                                              data
                                          ] !== undefined &&
                                          moduleSelected[ADD_EDIT_FIELD][data]
                                              ?.combinedFields?.length > 0
                                      ) {
                                          if (
                                              moduleSelected[ADD_EDIT_FIELD][
                                                  data
                                              ]?.combinedFields.includes(field)
                                          ) {
                                              return moduleSelected[
                                                  ADD_EDIT_FIELD
                                              ][data]?.hasPermission
                                          }
                                      } else {
                                          return moduleSelected[ADD_EDIT_FIELD][
                                              data
                                          ]?.hasPermission
                                      }
                                  }),
                        // moduleSelected[ADD_EDIT_FIELD][editKeys]
                        //       ?.combinedFields?.length > 0 &&
                        //   moduleSelected[ADD_EDIT_FIELD][
                        //       editKeys
                        //   ].combinedFields.includes(field)
                        // ? moduleSelected[ADD_EDIT_FIELD][editKeys]
                        //       ?.hasPermission
                        // : moduleSelected[ADD_EDIT_FIELD][field]
                        //       ?.hasPermission
                        viewPermission:
                            flag !== undefined && permissionKey === VIEW_FIELD
                                ? flag
                                : moduleSelected[permissionKey][field]
                                      ?.hasPermission,
                    })
                }
            }
        })

        if (name === 'component' && permissionKey === 'add_edit') {
            newArray = fieldTotalArray.map((data) => {
                newData = checkEdit(data, permissionKey, flag, fieldTotalArray)
                return { ...newData }
            })
        } else if (permissionKey === 'add_edit') {
            newArray = fieldTotalArray.map((data) => {
                newData = checkEdit(data, ADD_EDIT_FIELD)
                return { ...newData }
            })
        } else {
            newArray = fieldArray.map((data) => {
                return { ...data }
            })
        }

        var unique = []
        newArray.forEach((item) => {
            if (
                unique.some((el) => el.fieldName === item.fieldName) === false
            ) {
                unique.push(item)
            }
        })

        const changeDataKey = () => {
            let object = {}
            for (const key in moduleSelected[allDataKey]) {
                if (key === dataAccess) {
                    object[key] = true
                } else {
                    object[key] = false
                }
            }
            return object
        }

        let updatedData = {
            ...moduleSelected,
            [allDataKey]: changeDataKey(),
            [permissionKey]: Object.keys(moduleSelected[permissionKey]).reduce(
                (acc, key) => {
                    return {
                        ...acc,
                        [key]: {
                            ...moduleSelected[permissionKey][key],
                            hasPermission: flag,
                        },
                    }
                },
                {}
            ),
        }

        dispatch(tableDataSet(unique))
        if (allDataKey !== undefined) {
            changePermission(updatedData)
        }
    }

    useEffect(() => {
        const getTableData = async () => {
            if (moduleSelected) {
                checkDataSet(
                    VIEW_FIELD,
                    undefined,
                    undefined,
                    undefined,
                    undefined
                )
            }
        }
        getTableData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [moduleSelected])

    const PermissionComponent = ({ permissionData }) => {
        return (
            <div>
                <Title level={5}>{permissionData?.label}</Title>
                <Card
                    style={{
                        borderRadius: '5px',
                        marginTop: '10px',
                    }}
                    styles={{
                        body: {
                            padding: '15px',
                        },
                    }}
                >
                    <Row align={'middle'}>
                        <Tag
                            className={
                                moduleSelected[permissionData?.key][
                                    ALL_DATA_ACCESS
                                ]
                                    ? styles.allDataActive
                                    : styles.allData
                            }
                            onClick={() => {
                                checkDataSet(
                                    permissionData?.updateKey,
                                    true,
                                    'component',
                                    permissionData?.key,
                                    ALL_DATA_ACCESS
                                )
                                dispatch(
                                    permissionOption(permissionData?.updateKey)
                                )
                            }}
                        >
                            All Data
                        </Tag>
                        <Tag
                            className={
                                permissionData?.key ===
                                    'dataAccessImportExport' ||
                                permissionData?.key === 'dataAccessDelete'
                                    ? styles.assignDataDisable
                                    : moduleSelected[permissionData?.key][
                                          ASSIGN_DATA_ACCESS
                                      ] ||
                                      //   &&
                                      //       moduleSelected[
                                      //           permissionData?.updateKey
                                      //       ] &&
                                      //       Object.values(
                                      //           moduleSelected[
                                      //               permissionData?.updateKey
                                      //           ]
                                      //       ).some(
                                      //           (data) => data?.hasPermission
                                      //       )
                                      (permissionOptionText?.length > 0 &&
                                          permissionOptionText ===
                                              permissionData?.updateKey)
                                    ? styles.assignDataActive
                                    : styles.assignData
                            }
                            onClick={() => {
                                dispatch(
                                    permissionOption(permissionData?.updateKey)
                                )
                                if (
                                    permissionData.key !==
                                    'allDataAccessImportExport'
                                ) {
                                    checkDataSet(
                                        VIEW_FIELD,
                                        undefined,
                                        undefined,
                                        permissionData?.key,
                                        ASSIGN_DATA_ACCESS
                                    )
                                }
                            }}
                        >
                            Assign Data
                        </Tag>
                        <Tag
                            className={
                                moduleSelected[permissionData?.key][
                                    NO_DATA_ACCESS
                                ]
                                    ? // &&
                                      // moduleSelected[permissionData?.updateKey] &&
                                      // Object.values(
                                      //     moduleSelected[permissionData?.updateKey]
                                      // ).every((data) => !data?.hasPermission)
                                      styles.noDataActive
                                    : styles.noData
                            }
                            onClick={() => {
                                dispatch(
                                    permissionOption(permissionData?.updateKey)
                                )
                                checkDataSet(
                                    permissionData?.updateKey,
                                    false,
                                    'component',
                                    permissionData?.key,
                                    NO_DATA_ACCESS
                                )
                            }}
                        >
                            No Data
                        </Tag>
                    </Row>
                </Card>
            </div>
        )
    }

    return (
        <>
            <div
                style={{
                    zIndex: 10,
                    minHeight: '100vh',
                    padding: '40px 25px 40px 25px',
                }}
            >
                <Row
                    align={'middle'}
                    style={{
                        marginBottom: '30px',
                    }}
                >
                    <Title
                        className="titleSub"
                        style={{
                            cursor: 'pointer',
                            color: token.colorPalette.textColor.quaternary,
                            fontWeight: 400,
                        }}
                        onClick={() =>
                            navigate(PRIVATE_ROUTES.roleSetting.root)
                        }
                    >
                        Roles
                    </Title>
                    <div
                        style={{
                            width: '4px',
                            height: '4px',
                            backgroundColor:
                                token.colorPalette.textColor.quaternary,
                            borderRadius: '50%',
                            margin: '0px 16px',
                        }}
                    ></div>
                    <Title className="titleSub">{moduleSelected?.label}</Title>
                </Row>
                <Row
                    style={{
                        marginBottom: '25px',
                        columnGap: '13px',
                    }}
                    align={'middle'}
                >
                    {PERMISSION_ROLE?.length > 0 &&
                        PERMISSION_ROLE.map((data) => (
                            <PermissionComponent
                                permissionData={data}
                                key={data?.label}
                            />
                        ))}
                </Row>
                <Title level={4}>Field Permission</Title>
                <Table
                    dataSource={tableData}
                    scroll={{
                        x: 'scroll',
                    }}
                    rootClassName="tableGlobal"
                    style={{
                        borderRadius: '16px',
                        marginTop: '20px',
                    }}
                    columns={columns}
                    pagination={false}
                />
            </div>
        </>
    )
}

export default PermissionRole
