// antd
import { Modal, Row } from 'antd'

// components
import Button from '../button/button'
import ModelHeader from './modelHeader'

function ModalProfile({
    visibility,
    handleCancel,
    content,
    modalTitle,
    buttonText,
    modalKey,
    profileModalState,
    wholeObjectProfile,
    handleDelete,
    selectedDataProfile,
    icon,
    description,
    showTitle,
    footer,
    setMaxHeight,
}) {
    return (
        <>
            <Modal
                className="profileModal"
                open={visibility}
                title={
                    <ModelHeader
                        selectedDataProfile={selectedDataProfile}
                        modalTitle={modalTitle}
                        showTitle={showTitle}
                        handleCancel={handleCancel}
                        icon={icon}
                        description={description}
                        modalKey={modalKey}
                    />
                }
                onCancel={handleCancel}
                destroyOnClose={true}
                closeIcon={<></>}
                cancelButtonProps={{
                    hidden: true,
                }}
                footer={
                    footer ? (
                        <Row
                            align={'middle'}
                            style={{
                                padding: '30px',
                                boxShadow: '4px 0px 10px 0px rgba(0,0,0,0.1)',
                            }}
                        >
                            {modalKey === 'saveVideo' &&
                                wholeObjectProfile?.visibility && (
                                    <Button
                                        props={{
                                            text: `Retake`,
                                            className: 'textPlainButton',
                                            buttonType: 'text',
                                            htmlType: 'submit',
                                            id: 'retakeButton',
                                            form:
                                                profileModalState &&
                                                profileModalState['profile']
                                                    ?.formNameVideo,
                                        }}
                                    />
                                )}
                            {modalKey === 'viewVideo' &&
                                profileModalState?.delete && (
                                    <Button
                                        props={{
                                            text: `Delete Video`,
                                            className: 'textPlainButton',
                                            buttonType: 'text',
                                            onClick: handleDelete,
                                        }}
                                    />
                                )}
                            {(modalTitle === 'education' ||
                                modalTitle === 'experience' ||
                                modalTitle === 'certificates' ||
                                modalTitle === 'projects') && (
                                <>
                                    {!selectedDataProfile?.isCompleted && (
                                        <div
                                            style={{
                                                marginRight: '20px',
                                            }}
                                        >
                                            <Button
                                                props={{
                                                    text: 'Save as Draft',
                                                    buttonType: 'text',
                                                    className:
                                                        'textPlainButton',
                                                    htmlType: 'submit',
                                                    disabled:
                                                        profileModalState &&
                                                        profileModalState[
                                                            modalTitle
                                                        ]?.draftButtonLoading,
                                                    id: 'draftButtonLoading',
                                                    form:
                                                        profileModalState &&
                                                        profileModalState[
                                                            modalTitle
                                                        ]?.formName,
                                                }}
                                            />
                                        </div>
                                    )}
                                    {selectedDataProfile && (
                                        <Button
                                            props={{
                                                text: `Delete ${showTitle}`,
                                                className: 'textPlainButton',
                                                buttonType: 'text',
                                                onClick: handleDelete,
                                            }}
                                        />
                                    )}
                                </>
                            )}
                            <Row
                                style={{
                                    margin: '0px 0px 0px auto',
                                }}
                            >
                                {(modalTitle === 'education' ||
                                    modalTitle === 'experience' ||
                                    modalTitle === 'certificates' ||
                                    modalTitle === 'projects') && (
                                    <Button
                                        props={{
                                            text: 'Save & Add More',
                                            style: {
                                                margin: '0px 10px 0px 0px',
                                            },
                                            id: 'addMoreButtonLoading',
                                            htmlType: 'submit',
                                            disabled:
                                                profileModalState &&
                                                profileModalState[modalTitle]
                                                    ?.addMoreButtonLoading,
                                            loadingButton:
                                                profileModalState &&
                                                profileModalState[modalTitle]
                                                    ?.addMoreButtonLoading,
                                            form:
                                                profileModalState &&
                                                profileModalState[modalTitle]
                                                    ?.formName,
                                        }}
                                    />
                                )}
                                {modalKey !== 'saveVideo' &&
                                    modalKey !== 'viewVideo' && (
                                        <Button
                                            props={{
                                                text: buttonText || 'Save',
                                                style: {
                                                    margin: '0px 0px 0px auto',
                                                },
                                                htmlType: 'submit',
                                                id: 'saveButtonLoading',
                                                disabled:
                                                    profileModalState &&
                                                    profileModalState[
                                                        modalTitle
                                                    ]?.saveButtonLoading,
                                                loadingButton:
                                                    profileModalState &&
                                                    profileModalState[
                                                        modalTitle
                                                    ]?.saveButtonLoading,
                                                form:
                                                    profileModalState &&
                                                    profileModalState[
                                                        modalTitle
                                                    ]?.formName,
                                            }}
                                        />
                                    )}
                                {modalKey === 'saveVideo' && (
                                    <Button
                                        props={{
                                            text: 'Save Video',
                                            style: {
                                                margin: '0px 0px 0px auto',
                                            },
                                            htmlType: 'submit',
                                            id: 'saveVideoLoading',
                                            disabled:
                                                (profileModalState &&
                                                    profileModalState['profile']
                                                        ?.saveVideoLoading) ||
                                                (profileModalState &&
                                                    profileModalState['profile']
                                                        ?.disableVideoLoading),
                                            loadingButton:
                                                profileModalState &&
                                                profileModalState['profile']
                                                    ?.saveVideoLoading,
                                            form:
                                                profileModalState &&
                                                profileModalState['profile']
                                                    ?.formNameVideo,
                                        }}
                                    />
                                )}
                            </Row>
                        </Row>
                    ) : null
                }
                closable={false}
                centered
                style={{
                    minWidth: '790px',
                    // overflowY: 'hidden',
                    padding: 0,
                    borderRadius: '20px',
                    overflow: 'visible',
                }}
                styles={{
                    body: {
                        padding: '25px 50px',
                    },
                }}
            >
                <div
                    style={{
                        overflowY: 'scroll',
                        maxHeight: setMaxHeight ? '50vh' : 'auto',
                    }}
                >
                    {content}
                </div>
            </Modal>
        </>
    )
}
export default ModalProfile
