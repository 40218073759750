import { useDispatch, useSelector } from 'react-redux'

// antd
import { Row, theme, Typography } from 'antd'

// slices
import { switchProfileModal } from '../profile.slice'

// components
import Button from '../../../components/button/button'
import { loginState } from '../../login/login.slice'
import { SUPER_ADMIN } from '../../../constants/roles'

function NoProfileAdded({ content }) {
    const { Title, Text } = Typography

    const { useToken } = theme
    const { token } = useToken()
    const dispatch = useDispatch()

    const { userProfile } = useSelector(loginState)

    return (
        <>
            <Row
                style={{
                    margin: '25px 0px 50px 0px',
                }}
                justify={'space-between'}
                align={'middle'}
            >
                <Row
                    align={'middle'}
                    style={{
                        columnGap: '20px',
                    }}
                >
                    <Row
                        style={{
                            height: '80px',
                            width: '80px',
                            borderRadius: '10px',
                            padding: '20px',
                            border: `2px solid ${token.colorPalette.textColor.quaternary}`,
                        }}
                        align={'middle'}
                        justify={'center'}
                    >
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            {content?.icon}
                        </div>
                    </Row>
                    <div>
                        <Title className={'titleSub'}>
                            {`You don’t have any ${content?.title} added to your profile`}
                        </Title>
                        <Text
                            className="titleSecondary"
                            style={{
                                fontSize: token.fontSizeHeading5,
                            }}
                        >
                            {content?.description}
                        </Text>
                    </div>
                </Row>
                {userProfile?.role?.roleName !== SUPER_ADMIN && (
                    <Button
                        props={{
                            text: `Add ${content?.title}`,
                            onClick: () => {
                                dispatch(
                                    switchProfileModal({
                                        name: content?.key,
                                        open: true,
                                    })
                                )
                            },
                        }}
                    />
                )}
            </Row>
        </>
    )
}

export default NoProfileAdded
