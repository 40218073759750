import React from 'react'

function ListViewIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="10"
            fill="none"
            viewBox="0 0 16 10"
        >
            <path
                fill="#080325"
                d="M1.333 1.667a.833.833 0 100-1.667.833.833 0 000 1.667zM1.333 5.833a.833.833 0 100-1.666.833.833 0 000 1.666zM1.333 10a.833.833 0 100-1.667.833.833 0 000 1.667zM14.717 4.167h-10.1a.783.783 0 00-.784.783v.1c0 .433.351.783.784.783h10.1c.432 0 .783-.35.783-.783v-.1a.783.783 0 00-.783-.783zM14.717 8.333h-10.1a.783.783 0 00-.784.784v.1c0 .432.351.783.784.783h10.1c.432 0 .783-.35.783-.783v-.1a.783.783 0 00-.783-.784zM14.717 0h-10.1a.783.783 0 00-.784.783v.1c0 .433.351.784.784.784h10.1c.432 0 .783-.351.783-.784v-.1A.783.783 0 0014.717 0z"
            ></path>
        </svg>
    )
}

export default ListViewIcon
