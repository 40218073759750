import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { debounce } from 'lodash'

// antd
import {
    Card,
    Checkbox,
    Col,
    Collapse,
    DatePicker,
    Divider,
    Empty,
    Form,
    Image,
    Row,
    Select,
    Space,
    Spin,
    Tag,
    Typography,
    message,
    theme,
} from 'antd'

// slices
import {
    addSummary,
    changeModel,
    checkButtonType,
    masterDataAddDropDown,
    masterDataDropDownList,
    profileLoading,
    profileState,
    switchProfileModal,
    updateUserGet,
} from '../profile.slice'
import { loginState } from '../../login/login.slice'

// components
import DropDownMultiple from '../../../components/dropDownMultiple'

// assets
import {
    CrossIcon,
    ExpandIcon,
    InstituteDefaultIcon,
    SkillsIcon,
    ToolsIcon,
} from '../../../assets/icons'

// constants
import { MASTER_DATA_FIELD } from '../../../constants/masterData'

// styles
import styles from '../profile.module.css'

import dayjs from 'dayjs'

function EducationModal({ modalType }) {
    const [form] = Form.useForm()

    const { Text, Title } = Typography

    const { Option } = Select

    const { useToken } = theme
    const { token } = useToken()
    const dispatch = useDispatch()

    const timeoutRef = useRef()
    const sessionTokenRef = useRef()

    const {
        formButtonType,
        selectedData,
        updatedDataStatus,
        modelType,
        masterDataDropDown,
        masterDataDropDownStatus,
    } = useSelector(profileState)
    const { userProfile } = useSelector(loginState)

    // FORMAT DATEPICKER
    const formatDatePicker = (value) => {
        return `${dayjs(value).format('DD MMM YY')}`
    }

    const [skillsData, setSkillsData] = useState([])
    const [toolsData, setToolsData] = useState([])
    const [checked, setChecked] = useState(false)
    const [suggestions, setSuggestions] = useState([])
    const [searchValue, setSearchValue] = useState('')

    // ADD MASTER DATA
    async function addMasterData() {
        const result = await dispatch(
            masterDataAddDropDown({
                addData: {
                    type: 'manual',
                    data: {
                        name: searchValue,
                        logo:
                            modelType === 'tools'
                                ? 'https://pms-bucket123.s3.amazonaws.com/Layer_1_1689237094.svg'
                                : modelType === 'institute'
                                ? 'https://pms-bucket123.s3.amazonaws.com/Group 35301_1689226177.svg'
                                : '',
                        createdBy: userProfile?._id,
                    },
                },
                model: modelType,
            })
        )
        const data = result?.payload?.data

        if (data) {
            const { success, message: errorMessage } = data
            if (success) {
                dispatch(
                    masterDataDropDownList({
                        search: searchValue,
                        id: modelType,
                    })
                )
            } else {
                if (errorMessage) {
                    message.error(errorMessage)
                } else {
                    message.error('Something went wrong, try again later.')
                }
            }
        }
    }

    // SEARCH MASTER DATA
    const searchFn = debounce((value, model) => {
        if (value?.length > 0) {
            dispatch(changeModel(model))
            dispatch(
                masterDataDropDownList({
                    search: value,
                    id: model,
                })
            )
        }
        setSearchValue(value)
    }, 800)

    // GOOGLE PLACE API INTEGRATION
    const loadSuggestions = async (inputValue) => {
        clearTimeout(timeoutRef.current)

        if (!inputValue || inputValue.trim().length <= 1) {
            setSuggestions([])
            return
        }

        timeoutRef.current = setTimeout(async () => {
            if (!sessionTokenRef.current) {
                sessionTokenRef.current =
                    new window.google.maps.places.AutocompleteSessionToken()
            }

            new window.google.maps.places.AutocompleteService().getPlacePredictions(
                {
                    input: inputValue,
                    sessionToken: sessionTokenRef.current,
                },
                (predictions, status) => {
                    if (
                        status ===
                        window.google.maps.places.PlacesServiceStatus
                            .ZERO_RESULTS
                    ) {
                        setSuggestions([])
                        return
                    }
                    if (
                        status !==
                            window.google.maps.places.PlacesServiceStatus.OK ||
                        !predictions
                    ) {
                        return
                    }
                    let newData =
                        predictions?.length > 0 &&
                        predictions.map((data) => {
                            return {
                                label: data?.description,
                                value: data?.description,
                            }
                        })
                    setSuggestions(newData)
                }
            )
        }, 350)
    }

    function onFinishFailed() {}

    // ON EDIT MODAL SELECTED DATA INITIALLY
    useEffect(() => {
        if (selectedData) {
            if (selectedData?.eduInfo?.currentStudy) {
                setChecked(true)
            }

            setSkillsData(
                selectedData?.skills?.length > 0
                    ? selectedData?.skills.map((data) => {
                          return {
                              label: data?.name,
                              value: data?._id,
                          }
                      })
                    : []
            )
            setToolsData(
                selectedData?.tools?.length > 0
                    ? selectedData?.tools.map((data) => {
                          return {
                              label: data?.name,
                              value: data?._id,
                              logo: data?.logo,
                          }
                      })
                    : []
            )

            const {
                _id: valueInstitute,
                name: labelInstitute,
                logo: logoInstitute,
            } = {
                ...selectedData?.eduInfo?.institute,
            }

            const new_obj_institute = Object.assign(
                {},
                {
                    label: labelInstitute && (
                        <Space size={'large'}>
                            <div
                                style={{
                                    width: '30px',
                                    height: '30px',
                                    padding: '8px 5px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    border: `1px solid ${token.colorPalette.textColor.quinary}`,
                                }}
                            >
                                {logoInstitute ? (
                                    <Image
                                        src={logoInstitute}
                                        height={'30px'}
                                        width={'30px'}
                                        preview={false}
                                    />
                                ) : (
                                    <div
                                        style={{
                                            width: '30px',
                                            height: '30px',
                                        }}
                                    >
                                        <InstituteDefaultIcon />
                                    </div>
                                )}
                            </div>
                            <Text>{labelInstitute}</Text>
                        </Space>
                    ),
                    value: valueInstitute,
                }
            )

            form.setFieldsValue({
                ...selectedData?.eduInfo,
                institute: selectedData?.eduInfo?.institute
                    ? [{ ...new_obj_institute }]
                    : [],
                degree: selectedData?.eduInfo?.degree
                    ? [
                          {
                              label: selectedData?.eduInfo?.degree?.name,
                              value: selectedData?.eduInfo?.degree?._id,
                          },
                      ]
                    : [],
                fieldOfStudy: selectedData?.eduInfo?.fieldOfStudy
                    ? [
                          {
                              label: selectedData?.eduInfo?.fieldOfStudy?.name,
                              value: selectedData?.eduInfo?.fieldOfStudy?._id,
                          },
                      ]
                    : [],
                start: selectedData?.eduInfo?.start
                    ? dayjs(selectedData?.eduInfo?.start)
                    : '',
                end: selectedData?.eduInfo?.end
                    ? dayjs(selectedData?.eduInfo?.end)
                    : '',
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form, selectedData])

    // DISABLE START DATE
    const disabledDateEnd = (current) => {
        const startDate = form.getFieldValue('start')
        // Disable dates that are before the selected start date or dates that are in the past
        return startDate
            ? (current && current.valueOf() < startDate.valueOf()) ||
                  (current && current.valueOf() > Date.now())
            : current && current.valueOf() > Date.now()
    }

    // DISABLE END DATE
    const disabledDateStart = (current) => {
        const endDate = form.getFieldValue('end')
        // Disable dates that are after the selected end date or dates that are in the future
        return (
            (endDate && current && current.valueOf() > endDate.valueOf()) ||
            (current && current.valueOf() > Date.now())
        )
    }
    // SAVE / SAVE AND ADD MORE / SAVE AS DRAFT EDUCATION
    async function onFinishEducation(value) {
        if (formButtonType === 'draftButtonLoading') {
            if (
                Object.keys(value).every((item) => value[item] === undefined) &&
                skillsData?.length === 0 &&
                toolsData?.length === 0
            ) {
                message.info('Please Fill at-least one Field')
                form.resetFields()

                return
            }
        }
        dispatch(
            profileLoading({
                name: modalType,
                buttonName: formButtonType,
                loading: true,
            })
        )

        const result = await dispatch(
            addSummary({
                _id: userProfile?._id,
                summary: {
                    step: modalType,
                    data: {
                        eduInfo: {
                            ...value,
                            institute:
                                typeof value.institute === 'object'
                                    ? value.institute[0]?.value
                                    : value.institute,
                            degree:
                                typeof value.degree === 'object'
                                    ? value.degree[0]?.value
                                    : value.degree,
                            fieldOfStudy:
                                typeof value.fieldOfStudy === 'object'
                                    ? value.fieldOfStudy[0]?.value
                                    : value.fieldOfStudy,
                            start: new Date(value?.start).getTime(),
                            end: !checked && new Date(value?.end).getTime(),
                        },
                        skills:
                            skillsData?.length > 0
                                ? skillsData.map((data) => data?.value)
                                : [],
                        tools:
                            toolsData?.length > 0
                                ? toolsData.map((data) => data?.value)
                                : [],
                        _id: selectedData && selectedData?._id,
                        isCompleted:
                            formButtonType === 'draftButtonLoading'
                                ? false
                                : true,
                    },
                },
            })
        )
        const data = result?.payload?.data

        if (data) {
            const { success, message: errorMessage } = data
            if (success) {
                dispatch(
                    profileLoading({
                        name: modalType,
                        buttonName: formButtonType,
                        loading: false,
                    })
                )
                if (formButtonType === 'addMoreButtonLoading') {
                    form.resetFields()
                    setSkillsData([])
                    setToolsData([])
                    dispatch(
                        switchProfileModal({ name: 'education', open: true })
                    )
                } else {
                    dispatch(
                        switchProfileModal({ name: 'education', open: false })
                    )
                }
                dispatch(updateUserGet({ _id: userProfile?._id }))
                message.success(errorMessage)
            } else {
                dispatch(switchProfileModal({ name: 'education', open: false }))
                dispatch(
                    profileLoading({
                        name: modalType,
                        buttonName: formButtonType,
                        loading: false,
                    })
                )
                if (errorMessage) {
                    message.error(errorMessage)
                } else {
                    message.error('Something went wrong, try again later.')
                }
            }
        }
    }

    // COLLAPSE MENU HEADER
    const CollapseLabel = ({ name, icon, description }) => (
        <Row
            align={'middle'}
            style={{
                columnGap: '20px',
                marginBottom: '18px',
            }}
        >
            <Row
                style={{
                    height: '50px',
                    width: '50px',
                    borderRadius: '10px',
                    padding: '12px',
                    backgroundColor: token.colorPalette.baseColor.tertiary,
                }}
                align={'middle'}
                justify={'center'}
            >
                <div
                    style={{
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '30px',
                    }}
                >
                    {icon}
                </div>
            </Row>
            <div>
                <Title
                    style={{
                        fontSize: token.fontSizeHeading4,
                    }}
                >
                    {name}
                </Title>
                <Text className="sub-title">{description}</Text>
            </div>
        </Row>
    )

    // COLLAPSE ITEMS
    const getItems = () => [
        {
            key: '1',
            label: (
                <CollapseLabel
                    name={`Add Skills ${
                        skillsData?.length > 0 ? `(${skillsData?.length})` : ''
                    }`}
                    icon={
                        <SkillsIcon
                            color={token.colorPalette.baseColor.white}
                        />
                    }
                    description="Please add the skills suitable to your expertise."
                />
            ),
            children: (
                <>
                    <Select
                        placeholder="Search Skills"
                        showSearch
                        onSearch={(value) => {
                            searchFn(value, MASTER_DATA_FIELD.skills)
                        }}
                        tagRender={() => null}
                        onSelect={(e, options) => {
                            const trimSkills = options?.key.trimStart()
                            if (trimSkills?.length > 0) {
                                setSkillsData([
                                    ...skillsData,
                                    { label: trimSkills, value: e },
                                ])
                            }
                        }}
                        getPopupContainer={(trigger) => trigger.parentNode}
                        onDeselect={(e) => {
                            const removeData = [...skillsData]
                            removeData.splice(
                                removeData.findIndex(
                                    (item) => item.label === e
                                ),
                                1
                            )
                            setSkillsData(removeData)
                        }}
                        style={{
                            width: '100%',
                        }}
                        mode="multiple"
                        rootClassName={[
                            'p-0-multiSelect',
                            skillsData?.length > 0
                                ? 'p-0-multiselect-setMargin'
                                : '',
                        ]}
                        filterOption={false}
                        allowClear={false}
                        dropdownRender={(menu) => (
                            <DropDownMultiple
                                menu={menu}
                                searchValue={searchValue}
                                name={MASTER_DATA_FIELD.skills}
                                addMasterData={() => addMasterData()}
                            />
                        )}
                    >
                        {masterDataDropDown?.skills?.length > 0 &&
                        masterDataDropDownStatus === 'loaded'
                            ? masterDataDropDown?.skills.map((option) => {
                                  return (
                                      <Option
                                          key={option?.label}
                                          value={option?.value}
                                      >
                                          <Text>{option?.label}</Text>
                                      </Option>
                                  )
                              })
                            : masterDataDropDownStatus === 'loading' && (
                                  <Spin></Spin>
                              )}
                    </Select>
                    <div
                        style={{
                            marginTop: '30px',
                        }}
                    >
                        <Text>My Skills</Text>
                        <Card
                            style={{
                                border: `2px dashed ${token.colorPalette.textColor.quaternary}`,
                                margin: '4px 0px 14px 0px',
                                padding: '0px',
                                minHeight: '110px',
                            }}
                            styles={{
                                body: {
                                    padding: '15px',
                                },
                            }}
                        >
                            <Row style={{ rowGap: '10px' }}>
                                {skillsData?.length > 0 &&
                                    skillsData.map((skill, index) => (
                                        <Tag
                                            style={{
                                                padding: '10px 20px',
                                                whiteSpace: 'normal',
                                                overflow: 'hidden',
                                            }}
                                            color={
                                                token.colorPalette.baseColor
                                                    .tertiary
                                            }
                                        >
                                            <Row
                                                align={'middle'}
                                                style={{
                                                    columnGap: '30px',
                                                }}
                                            >
                                                <Text
                                                    style={{
                                                        color: token
                                                            .colorPalette
                                                            .baseColor.white,
                                                    }}
                                                >
                                                    {skill?.label?.toUpperCase()}
                                                </Text>
                                                <div
                                                    style={{
                                                        width: '13px',
                                                    }}
                                                    onClick={() => {
                                                        searchFn(
                                                            '',
                                                            MASTER_DATA_FIELD.skills
                                                        )
                                                        const removeData = [
                                                            ...skillsData,
                                                        ]
                                                        removeData.splice(
                                                            index,
                                                            1
                                                        )
                                                        setSkillsData(
                                                            removeData
                                                        )
                                                    }}
                                                >
                                                    <CrossIcon />
                                                </div>
                                            </Row>
                                        </Tag>
                                    ))}
                            </Row>
                        </Card>
                    </div>
                </>
            ),
        },
        {
            key: '2',
            label: (
                <CollapseLabel
                    name={`Add Tools ${
                        toolsData?.length > 0 ? `(${toolsData?.length})` : ''
                    }`}
                    icon={
                        <ToolsIcon color={token.colorPalette.baseColor.white} />
                    }
                    description="Please add tools suitable to your hands-on experience."
                />
            ),
            children: (
                <>
                    <Select
                        placeholder="Search Tools"
                        showSearch
                        onSearch={(value) => {
                            searchFn(value, MASTER_DATA_FIELD.tools)
                        }}
                        getPopupContainer={(trigger) => trigger.parentNode}
                        tagRender={() => null}
                        onSelect={(e, options) => {
                            const key = JSON.parse(options?.key)
                            const trimTools = key?.label.trimStart()
                            if (trimTools?.length > 0) {
                                setToolsData([
                                    ...toolsData,
                                    {
                                        label: trimTools,
                                        value: e,
                                        logo: key?.logo,
                                    },
                                ])
                            }
                        }}
                        onDeselect={(e) => {
                            const removeData = [...toolsData]
                            removeData.splice(
                                removeData.findIndex(
                                    (item) => item.label === e
                                ),
                                1
                            )
                            setToolsData(removeData)
                        }}
                        style={{
                            width: '100%',
                        }}
                        mode="multiple"
                        rootClassName={[
                            'p-0-multiSelect',
                            toolsData?.length > 0
                                ? 'p-0-multiselect-setMargin'
                                : '',
                        ]}
                        filterOption={false}
                        allowClear={false}
                        dropdownRender={(menu) => (
                            <DropDownMultiple
                                menu={menu}
                                searchValue={searchValue}
                                name={MASTER_DATA_FIELD.tools}
                                addMasterData={() => addMasterData()}
                            />
                        )}
                    >
                        {masterDataDropDown?.tools?.length > 0 &&
                        masterDataDropDownStatus === 'loaded'
                            ? masterDataDropDown?.tools.map((option) => {
                                  return (
                                      <Option
                                          key={JSON.stringify({
                                              label: option?.label,
                                              logo: option?.logo,
                                          })}
                                          value={option?.value}
                                          children={option?.logo}
                                      >
                                          <Space size={'large'}>
                                              {option?.logo ? (
                                                  <Image
                                                      src={option?.logo}
                                                      height={'30px'}
                                                      width={'30px'}
                                                      preview={false}
                                                  />
                                              ) : (
                                                  <div
                                                      style={{
                                                          width: '30px',
                                                          height: '30px',
                                                      }}
                                                  >
                                                      <ToolsIcon />
                                                  </div>
                                              )}
                                              <Text>{option?.label}</Text>
                                          </Space>
                                      </Option>
                                  )
                              })
                            : masterDataDropDownStatus === 'loading' && (
                                  <Spin></Spin>
                              )}
                    </Select>
                    <div
                        style={{
                            marginTop: '30px',
                        }}
                    >
                        <Text>My Tools</Text>
                        <Card
                            style={{
                                border: `2px dashed ${token.colorPalette.textColor.quaternary}`,
                                marginTop: '4px',
                                padding: '0px',
                                minHeight: '110px',
                            }}
                            styles={{
                                body: {
                                    padding: '15px',
                                },
                            }}
                        >
                            <Row style={{ rowGap: '10px' }}>
                                <Row
                                    style={{
                                        columnGap: '15px',
                                    }}
                                >
                                    {toolsData?.length > 0 &&
                                        toolsData.map((tool, index) => (
                                            <div>
                                                <div
                                                    className={
                                                        styles.toolIconDefault
                                                    }
                                                    style={{
                                                        border: `2px solid ${token.colorPalette.baseColor.tertiary}`,
                                                    }}
                                                >
                                                    {/* <ToolsIcon
                                                        color={
                                                            token.colorPalette
                                                                .baseColor
                                                                .tertiary
                                                        }
                                                    /> */}
                                                    <Image src={tool?.logo} />
                                                    <div
                                                        className={
                                                            styles.toolsIconCross
                                                        }
                                                        style={{
                                                            backgroundColor:
                                                                token
                                                                    .colorPalette
                                                                    .baseColor
                                                                    .tertiary,
                                                        }}
                                                        onClick={() => {
                                                            const removeData = [
                                                                ...toolsData,
                                                            ]
                                                            removeData.splice(
                                                                index,
                                                                1
                                                            )
                                                            setToolsData(
                                                                removeData
                                                            )
                                                        }}
                                                    >
                                                        <Row
                                                            style={{
                                                                height: '100%',
                                                                width: '100%',
                                                            }}
                                                        >
                                                            <CrossIcon />
                                                        </Row>
                                                    </div>
                                                </div>
                                                <div
                                                    style={{
                                                        width: '60px',
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow:
                                                            'ellipsis',
                                                    }}
                                                >
                                                    <Text
                                                        style={{
                                                            marginTop: '2px',
                                                            fontWeight:
                                                                token.fontWeightStrong,
                                                            textTransform:
                                                                'capitalize',
                                                        }}
                                                    >
                                                        {tool?.label}
                                                    </Text>
                                                </div>
                                            </div>
                                        ))}
                                </Row>
                            </Row>
                        </Card>
                    </div>
                </>
            ),
        },
    ]

    return updatedDataStatus === 'loading' ? (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
            }}
        >
            <Spin size="large" />
        </div>
    ) : (
        <>
            <Form
                labelCol={{
                    style: {
                        padding: '0 0 3px',
                    },
                }}
                layout="vertical"
                name="addEducation"
                onFinish={onFinishEducation}
                onFinishFailed={onFinishFailed}
                requiredMark={false}
                onSubmitCapture={async (e) => {
                    await dispatch(checkButtonType(e.nativeEvent.submitter.id))
                }}
                form={form}
                style={{
                    marginBottom: '20px',
                    overflowX: 'hidden',
                }}
            >
                <Row
                    style={{
                        rowGap: '20px',
                    }}
                    justify={'space-between'}
                    gutter={16}
                >
                    <Col span={12}>
                        <Form.Item
                            name={MASTER_DATA_FIELD.institute}
                            fieldId={MASTER_DATA_FIELD.institute}
                            label="Institute*"
                            rules={
                                formButtonType !== 'draftButtonLoading' && [
                                    {
                                        required: true,
                                        message: 'Please Enter Institute',
                                    },
                                ]
                            }
                        >
                            <Select
                                placeholder="Search Institute"
                                showSearch
                                onSearch={(value) => {
                                    searchFn(value, MASTER_DATA_FIELD.institute)
                                }}
                                filterOption={false}
                                allowClear={false}
                                dropdownRender={(menu) => (
                                    <DropDownMultiple
                                        menu={menu}
                                        searchValue={searchValue}
                                        name={MASTER_DATA_FIELD.institute}
                                        addMasterData={() => addMasterData()}
                                    />
                                )}
                                getPopupContainer={(trigger) =>
                                    trigger.parentNode
                                }
                            >
                                {masterDataDropDown?.institute?.length > 0 &&
                                masterDataDropDownStatus === 'loaded'
                                    ? masterDataDropDown?.institute.map(
                                          (option) => {
                                              return (
                                                  <Option
                                                      key={option?.value}
                                                      value={option?.value}
                                                  >
                                                      <Space size={'large'}>
                                                          <div
                                                              style={{
                                                                  width: '30px',
                                                                  height: '30px',
                                                                  padding:
                                                                      '8px 5px',
                                                                  display:
                                                                      'flex',
                                                                  alignItems:
                                                                      'center',
                                                                  justifyContent:
                                                                      'center',
                                                                  border: `1px solid ${token.colorPalette.textColor.quinary}`,
                                                              }}
                                                          >
                                                              {option?.logo ? (
                                                                  <Image
                                                                      src={
                                                                          option?.logo
                                                                      }
                                                                      height={
                                                                          '30px'
                                                                      }
                                                                      width={
                                                                          '30px'
                                                                      }
                                                                      preview={
                                                                          false
                                                                      }
                                                                  />
                                                              ) : (
                                                                  <div
                                                                      style={{
                                                                          width: '30px',
                                                                          height: '30px',
                                                                      }}
                                                                  >
                                                                      <InstituteDefaultIcon />
                                                                  </div>
                                                              )}
                                                          </div>
                                                          <Text>
                                                              {option?.label}
                                                          </Text>
                                                      </Space>
                                                  </Option>
                                              )
                                          }
                                      )
                                    : masterDataDropDownStatus ===
                                          'loading' && <Spin></Spin>}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Degree*"
                            name={MASTER_DATA_FIELD.degree}
                            rules={
                                formButtonType !== 'draftButtonLoading' && [
                                    {
                                        required: true,
                                        message: 'Please Select Degree',
                                    },
                                ]
                            }
                            colon={false}
                        >
                            <Select
                                placeholder="Search a Degree"
                                showSearch
                                onSearch={(value) => {
                                    searchFn(value, MASTER_DATA_FIELD.degree)
                                }}
                                filterOption={false}
                                allowClear={false}
                                dropdownRender={(menu) => (
                                    <DropDownMultiple
                                        menu={menu}
                                        searchValue={searchValue}
                                        name={MASTER_DATA_FIELD.degree}
                                        addMasterData={() => addMasterData()}
                                    />
                                )}
                                getPopupContainer={(trigger) =>
                                    trigger.parentNode
                                }
                            >
                                {masterDataDropDown?.degree?.length > 0 &&
                                masterDataDropDownStatus === 'loaded'
                                    ? masterDataDropDown?.degree.map(
                                          (option) => {
                                              return (
                                                  <Option
                                                      key={option?.value}
                                                      value={option?.value}
                                                  >
                                                      <Text>
                                                          {option?.label}
                                                      </Text>
                                                  </Option>
                                              )
                                          }
                                      )
                                    : masterDataDropDownStatus ===
                                          'loading' && <Spin></Spin>}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Field of Study*"
                            name={MASTER_DATA_FIELD.fieldOfStudy}
                            rules={
                                formButtonType !== 'draftButtonLoading' && [
                                    {
                                        required: true,
                                        message: 'Please Select Field of Study',
                                    },
                                ]
                            }
                            colon={false}
                        >
                            <Select
                                placeholder="Search a Field of Study"
                                showSearch
                                onSearch={(value) => {
                                    searchFn(
                                        value,
                                        MASTER_DATA_FIELD.fieldOfStudy
                                    )
                                }}
                                filterOption={false}
                                allowClear={false}
                                dropdownRender={(menu) => (
                                    <DropDownMultiple
                                        menu={menu}
                                        searchValue={searchValue}
                                        name={MASTER_DATA_FIELD.fieldOfStudy}
                                        addMasterData={() => addMasterData()}
                                    />
                                )}
                                getPopupContainer={(trigger) =>
                                    trigger.parentNode
                                }
                            >
                                {masterDataDropDown?.fieldOfStudy?.length > 0 &&
                                masterDataDropDownStatus === 'loaded'
                                    ? masterDataDropDown?.fieldOfStudy.map(
                                          (option) => {
                                              return (
                                                  <Option
                                                      key={option?.value}
                                                      value={option?.value}
                                                  >
                                                      <Text>
                                                          {option?.label}
                                                      </Text>
                                                  </Option>
                                              )
                                          }
                                      )
                                    : masterDataDropDownStatus ===
                                          'loading' && <Spin></Spin>}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Location*"
                            name="location"
                            rules={
                                formButtonType !== 'draftButtonLoading' && [
                                    {
                                        required: true,
                                        message: 'Please Select Location',
                                    },
                                ]
                            }
                            colon={false}
                        >
                            <Select
                                placeholder="Search a Location"
                                allowClear={false}
                                notFoundContent={
                                    <Empty
                                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                                        description="No Locations Searched"
                                    ></Empty>
                                }
                                onSearch={(value) => {
                                    loadSuggestions(value)
                                }}
                                showSearch
                                options={suggestions}
                                getPopupContainer={(trigger) =>
                                    trigger.parentNode
                                }
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Start Date*"
                            colon={false}
                            name={'start'}
                            rules={
                                formButtonType !== 'draftButtonLoading' && [
                                    {
                                        required: true,
                                        message: 'Please Select Start Date',
                                    },
                                ]
                            }
                        >
                            <DatePicker
                                allowClear={false}
                                name="start"
                                disabledDate={disabledDateStart}
                                format={formatDatePicker}
                                // getPopupContainer={(trigger) =>
                                //     trigger.parentNode
                                // }
                                getPopupContainer={() => document.body}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="End Date*"
                            colon={false}
                            name={'end'}
                            rules={
                                formButtonType !== 'draftButtonLoading' &&
                                !checked && [
                                    {
                                        required: true,
                                        message: 'Please Select End Date',
                                    },
                                ]
                            }
                        >
                            <DatePicker
                                allowClear={false}
                                disabled={checked}
                                disabledDate={disabledDateEnd}
                                name="end"
                                format={formatDatePicker}
                                // getPopupContainer={(trigger) =>
                                //     trigger.parentNode
                                // }
                                getPopupContainer={() => document.body}
                            />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item name="currentStudy" valuePropName="checked">
                            <Checkbox
                                onChange={(e) => {
                                    setChecked(e.target.checked)
                                    if (e.target.checked === true) {
                                        form.setFields([
                                            {
                                                name: 'end',
                                                errors: [],
                                            },
                                        ])
                                    }
                                    form.setFieldValue('end', '')
                                }}
                            >
                                I currently study here
                            </Checkbox>
                        </Form.Item>
                    </Col>
                </Row>
                <Divider />
                <Collapse
                    bordered={false}
                    defaultActiveKey={['1', '2']}
                    expandIcon={({ isActive }) => {
                        return <ExpandIcon isActive={!isActive} />
                    }}
                    expandIconPosition="right"
                    style={{
                        backgroundColor: 'transparent',
                    }}
                    items={getItems()}
                />
            </Form>
        </>
    )
}

export default EducationModal
