import React, { useEffect } from 'react'
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import 'react-quill/dist/quill.snow.css'
import 'rc-slider/assets/index.css'
import { io } from 'socket.io-client'

// antd
import { message } from 'antd'

// slices
import {
    isLoggedInLogin,
    loginState,
    setSocketClient,
} from './login/login.slice'
import { setGlobalTimer } from './tasks/tasks.slice'

// pages
import FoF from './404/404.index'

// component sections
import Layout from '../layouts/sidebar/sidebar'
import TimeTrackingLandingPage from './time-tracking/timeTracking.section'
import ProfileSection from './profile'

// css
import './client.module.css'

// routes
import { PRIVATE_ROUTES, PUBLIC_ROUTES, ROOT_ROUTE } from '../routes'

// pages
import AuthorizationCallBackPage from './callbackPage'

// constants
import { CHECK_PUBLIC_ROUTE_LOGIN, SUPER_ADMIN } from '../constants/roles'
import { CHAT_EVENT } from '../constants/chat'

// helpers
import { checkRoutePermission } from '../helpers/routecheckPermission'

// LOGIN PAGE
import SetPassword from './login/setPassword'

// PRIVATE / PUBLIC ROUTES
import PublicRoute from './allRoutes/publicRoute'
import PrivateRoute from './allRoutes/privateRoute'
import PublicRoutesList from './allRoutes/publicRoutesList'
import WorkSpace from './workSpace/workSpace.index'

const Client = () => {
    const dispatch = useDispatch()

    const navigate = useNavigate()

    const params = useLocation()

    const { userProfile, status } = useSelector(loginState)

    // CONNECT CLIENT WITH SOCKET
    const connectClient = (token, userId) => {
        const socket = io.connect(process.env.REACT_APP_SOCKET_URL, {
            query: { token },
        })
        try {
            socket.on(CHAT_EVENT.connect, () => {
                dispatch(setSocketClient({ socket: socket, token: token }))

                socket.emit(CHAT_EVENT.joinChat, userId)
            })
        } catch (error) {
            console.log('error', error)
        }
    }

    // CHECK IS LOGGED IN OR NOT
    async function checkIsLogin() {
        if (CHECK_PUBLIC_ROUTE_LOGIN.includes(params.pathname)) {
            return
        } else {
            const checkLogin = await dispatch(isLoggedInLogin())
            const checkSuccessLogin = checkLogin?.payload?.data
            const { success: checkSuccess, data } = checkSuccessLogin
            if (checkSuccess && data?.phone && data?.phone !== null) {
                if (data?.runningTask) {
                    dispatch(setGlobalTimer(data?.runningTask))
                }
                if (data?.activeProject) {
                    localStorage.setItem(
                        'currentProject',
                        JSON.stringify({
                            id: data?.activeProject?._id,
                            name: data?.activeProject?.name,
                        })
                    )
                }
                const response = await fetch(
                    `${process.env.REACT_APP_SOCKET_URL}/api/v1/get-token?userId=${data?._id}&clientId=${process.env.REACT_APP_CLIENT_ID}`
                )

                const tokenData = await response.json()

                if (tokenData?.success) {
                    connectClient(tokenData?.data, data?._id)
                }
            } else if (checkSuccess && !data?.phone) {
                message.error('Please Fill the mandatory field')
                navigate(PUBLIC_ROUTES.signUp)
            } else {
                for (const key in PUBLIC_ROUTES) {
                    if (ROOT_ROUTE === params.pathname) {
                        navigate(PUBLIC_ROUTES['login'])
                    } else if (params?.pathname === PUBLIC_ROUTES.signUp) {
                        navigate(PUBLIC_ROUTES.signUp)
                        // navigate(PUBLIC_ROUTES.login)
                    } else if (PUBLIC_ROUTES[key] === params?.pathname) {
                        navigate(PUBLIC_ROUTES[key])
                    }
                }
                if (params.pathname !== PUBLIC_ROUTES.signUp) {
                    for (const key in PRIVATE_ROUTES) {
                        if (
                            (PRIVATE_ROUTES[key] === params?.pathname &&
                                typeof key === 'string') ||
                            typeof PRIVATE_ROUTES[key] === 'object'
                        ) {
                            navigate(PUBLIC_ROUTES['login'])
                        } else {
                        }
                    }
                }
                window.localStorage.clear()
            }
        }
    }

    useEffect(() => {
        checkIsLogin()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch])

    return (
        <Routes>
            <Route
                path={PUBLIC_ROUTES.timeTrackingApp}
                element={<TimeTrackingLandingPage />}
            />
            <Route
                path={PUBLIC_ROUTES.callBackUrl}
                element={<AuthorizationCallBackPage />}
            />
            <Route path={PUBLIC_ROUTES.setPassword} element={<SetPassword />} />
            <Route element={<PublicRoute />}>{PublicRoutesList()}</Route>
            {status !== 'loading' &&
                userProfile !== undefined &&
                userProfile?.phone && (
                    <>
                        <Route element={<PrivateRoute />}>
                            <Route path={ROOT_ROUTE} element={<></>} />
                            <Route
                                element={<Layout userProfile={userProfile} />}
                            >
                                {userProfile?.role?.roleName !==
                                    SUPER_ADMIN && (
                                    <>
                                        <Route
                                            path={PRIVATE_ROUTES.profile}
                                            element={<ProfileSection />}
                                        />
                                    </>
                                )}
                                {userProfile?.role?.roleName !==
                                    SUPER_ADMIN && (
                                    <>
                                        <Route
                                            path={PRIVATE_ROUTES.userWorkSpace}
                                            element={<WorkSpace />}
                                        />
                                    </>
                                )}
                                {checkRoutePermission(userProfile)}
                            </Route>
                            <Route path={'*'} element={<FoF />} />
                        </Route>
                    </>
                )}
        </Routes>
    )
}

export default Client
