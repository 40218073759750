import { useEffect, useState } from 'react'

// antd
import { Image, Row } from 'antd'

// constants
import { fileTypes, pdfTypes, videoTypes } from '../../../constants/mediaTypes'

// styles
import styles from '../profile.module.css'

const ViewMultiMediaModal = ({ fileData, open }) => {
    const [primaryImage, setPrimaryImage] = useState(null)

    useEffect(() => {
        if (open) {
            const havePrimaryImage = fileData?.selectedFile
                ? fileData.selectedFile
                : fileData?.files[0]

            setPrimaryImage(havePrimaryImage)
        }
    }, [open])

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '20px',
                }}
            >
                {fileTypes.some((r) =>
                    primaryImage?.toLowerCase().includes(r)
                ) ? (
                    <>
                        <Image
                            src={primaryImage}
                            width={'100%'}
                            height={'540px'}
                            preview={false}
                            style={{
                                objectFit: 'cover',
                            }}
                        />
                    </>
                ) : videoTypes.some((r) =>
                      primaryImage?.toLowerCase().includes(r)
                  ) ? (
                    <video
                        width="100%"
                        height="540px"
                        autoPlay
                        controls
                        className={styles.videoViewContainer}
                    >
                        <source src={primaryImage} type="video/mp4" />
                    </video>
                ) : pdfTypes.some((r) =>
                      primaryImage?.toLowerCase().includes(r)
                  ) ? (
                    <>
                        <div>
                            <iframe
                                src={`${primaryImage}#view=fitH`}
                                height={'540px'}
                                width="100%"
                                title="PDF"
                            />
                        </div>
                    </>
                ) : (
                    <></>
                )}
                <Row
                    align={'middle'}
                    style={{
                        columnGap: '15px',
                        overflowX: 'scroll',
                        flexWrap: 'nowrap',
                    }}
                >
                    {fileData?.files?.length > 0 &&
                        fileData?.files.map((file) =>
                            fileTypes.some((r) =>
                                file?.toLowerCase().includes(r)
                            ) ? (
                                <>
                                    <Image
                                        src={file}
                                        width={'133px'}
                                        height={'88px'}
                                        style={{
                                            cursor: 'pointer',
                                            borderRadius: '10px',
                                            objectFit: 'cover',
                                            minWidth: '133px',
                                        }}
                                        onClick={() => {
                                            setPrimaryImage(file)
                                        }}
                                        preview={false}
                                    />
                                </>
                            ) : videoTypes.some((r) =>
                                  file?.toLowerCase().includes(r)
                              ) ? (
                                <video
                                    width="133px"
                                    height="88px"
                                    className={styles.videoViewContainer}
                                    style={{
                                        cursor: 'pointer',
                                        borderRadius: '10px',
                                        minWidth: '133px',
                                    }}
                                    onClick={() => {
                                        setPrimaryImage(file)
                                    }}
                                    src={file}
                                ></video>
                            ) : pdfTypes.some((r) =>
                                  file?.toLowerCase().includes(r)
                              ) ? (
                                <>
                                    <div
                                        style={{
                                            cursor: 'pointer',
                                        }}
                                    >
                                        <iframe
                                            src={`${file}#view=fitH`}
                                            height={'88px'}
                                            width="133px"
                                            title="PDF"
                                            style={{
                                                borderRadius: '10px',
                                                minWidth: '133px',
                                            }}
                                            onClick={() => {
                                                setPrimaryImage(file)
                                            }}
                                        />
                                    </div>
                                </>
                            ) : (
                                <></>
                            )
                        )}
                </Row>
            </div>
        </>
    )
}

export default ViewMultiMediaModal
