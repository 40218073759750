import React from 'react'

function ToolsIcon({ color }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 28 29"
        >
            <path
                fill={color ? color : '#fff'}
                fillRule="evenodd"
                d="M.744 24.035c-.992 1.042-.992 2.726 0 3.766a2.468 2.468 0 001.792.783c.653 0 1.305-.26 1.802-.783l8.328-8.757-3.585-3.767-8.337 8.758zM21.574 7.802l3.628-1.971L27.1 1.968 25.333.111l-3.676 1.995-1.877 3.811-4.42 4.645 1.793 1.884 4.421-4.644z"
                clipRule="evenodd"
            ></path>
            <path
                fill={color ? color : '#fff'}
                fillRule="evenodd"
                d="M22.368 17.924l-.338-.036c-.62 0-1.206.131-1.76.349L9.85 7.288a5.47 5.47 0 00.33-1.849l-.032-.355C9.966 2.306 7.793.11 5.107.11c-.785 0-1.521.2-2.181.53l3.372 3.55c.18.182.304.41.378.634.224.635.108 1.37-.378 1.875a1.647 1.647 0 01-1.191.522c-.208 0-.404-.042-.586-.122a1.59 1.59 0 01-.612-.4L.537 3.157a5.433 5.433 0 00-.512 2.282c0 2.831 2.099 5.116 4.735 5.307l.347.033c.62 0 1.205-.14 1.751-.347l10.42 10.947a5.588 5.588 0 00-.332 1.84l.034.365c.182 2.768 2.355 4.973 5.05 4.973.776 0 1.512-.2 2.172-.538l-3.372-3.542a1.873 1.873 0 01-.387-.633c-.218-.634-.1-1.374.387-1.876.332-.347.762-.52 1.2-.52.198 0 .395.042.586.122.215.087.429.207.602.398l3.38 3.542a5.553 5.553 0 00.502-2.29c0-2.823-2.088-5.105-4.732-5.296z"
                clipRule="evenodd"
            ></path>
        </svg>
    )
}

export default ToolsIcon
