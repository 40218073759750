import axiosNew from '../../helpers/axios'

// SEND OTP
export const sendOtp = async (payload) => {
    let data = payload ?? {}
    return new Promise(async (resolve) => {
        try {
            const sendOtp = await axiosNew({
                url: 'auth/send-otp',
                data,
            })
            resolve({ data: sendOtp?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// VERIFY OTP
export const verifyOtp = async (payload) => {
    let data = payload ?? {}
    return new Promise(async (resolve) => {
        try {
            const verifyOtp = await axiosNew({
                url: 'auth/verify-otp',
                data,
            })
            resolve({ data: verifyOtp?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// CHECK LOGIN OR NOT
export const isLoggedIn = async () => {
    return new Promise(async (resolve) => {
        try {
            const isLoggedIn = await axiosNew({
                url: 'auth/isLoggedIn',
                method: 'get',
            })
            resolve({ data: isLoggedIn?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// GET TOKEN
export const getToken = async (payload) => {
    let data = payload ?? {}
    return new Promise(async (resolve) => {
        try {
            const tokenGet = await axiosNew({
                url: `${process.env.REACT_APP_SOCKET_URL}/get-token?userId=${data?.userId}&clientId=${data?.clientId}`,
                method: 'get',
            })
            resolve({ data: tokenGet?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// LOG OUT
export const logOut = async () => {
    return new Promise(async (resolve) => {
        try {
            const logOut = await axiosNew({
                url: 'auth/logout',
                method: 'get',
            })
            resolve({ data: logOut?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// SIGN UP
export const signUp = async (payload) => {
    let data = payload ?? {}
    return new Promise(async (resolve) => {
        try {
            const signUp = await axiosNew({
                url: 'auth/signup',
                data,
            })
            resolve({ data: signUp?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// GOOGLE SSO LOGIN
export const googleSSOLogin = async (payload) => {
    let data = payload ?? {}
    return new Promise(async (resolve) => {
        try {
            const signUp = await axiosNew({
                url: 'auth/google-signup',
                data,
            })
            resolve({ data: signUp?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// LOGIN
export const login = async (payload) => {
    return new Promise(async (resolve) => {
        let data = payload ?? {}
        try {
            const signUp = await axiosNew({
                url: 'auth/login',
                data,
            })
            resolve({ data: signUp?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// FORGOT PASSWORD
export const forgotPassword = async (payload) => {
    return new Promise(async (resolve) => {
        let data = payload ?? {}
        try {
            const forgotPassword = await axiosNew({
                url: 'auth/forgot-password',
                data,
            })
            resolve({ data: forgotPassword?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// CHECK UNIQUE NUMBER
export const checkUniqueNumber = async (payload) => {
    return new Promise(async (resolve) => {
        let data = payload ?? {}
        try {
            const checkData = await axiosNew({
                url: 'users/check-phone',
                data,
            })
            resolve({ data: checkData?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// CHECK UNIQUE EMAIL
export const checkUniqueEmail = async (payload) => {
    return new Promise(async (resolve) => {
        let data = payload ?? {}
        try {
            const checkData = await axiosNew({
                url: 'users/check-email',
                data,
            })
            resolve({ data: checkData?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}
