import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// antd
import { Col, Row, Spin, Typography, theme } from 'antd'

// components
import Button from '../../../components/button/button'

// slices
import {
    profileState,
    setDeleteModalTitle,
    switchIntroductionModal,
    switchProfileModal,
    switchVideoPlayModal,
} from '../profile.slice'
import { loginState } from '../../login/login.slice'

// assets
import { EditIcon, NoVideosIcon, VideosIcon } from '../../../assets/icons'

// constants
import { videosTitle } from '../../../constants/profile'
import { SUPER_ADMIN } from '../../../constants/roles'

// sections
import NoIndividualContent from '../tabSection/noIndividualContent'

function VideosTabContent() {
    const { Title } = Typography

    const { useToken } = theme
    const { token } = useToken()

    const dispatch = useDispatch()

    const {
        profileData: {
            data: { videos },
        },
        status,
        introductionVideoModal,
        defaultTab,
    } = useSelector(profileState)
    const { userProfile } = useSelector(loginState)

    // VIDEO SET INITIALLY
    useEffect(() => {
        if (videos?.length > 0) {
            const results = videos.filter(({ type: id1 }) =>
                videosTitle.some(({ key: id2 }) => id2 === id1)
            )
            dispatch(
                switchIntroductionModal({
                    ...introductionVideoModal,
                    open: false,
                    videosTab: results,
                })
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [videos, defaultTab])

    return (
        <div
            style={{
                padding: '50px',
            }}
        >
            <Row align="middle" justify={'space-between'}>
                <Title level={4}>Videos</Title>
                {userProfile?.role?.roleName !== SUPER_ADMIN && (
                    <Button
                        props={{
                            text: 'Add Videos',
                            onClick: () => {
                                dispatch(
                                    switchProfileModal({
                                        name: 'videos',
                                        open: true,
                                    })
                                )
                            },
                        }}
                    />
                )}
            </Row>
            <Spin spinning={status === 'loading' ? true : false}>
                {status === 'loaded' && videos?.length > 0 ? (
                    <Row
                        style={{
                            marginTop: '35px',
                        }}
                        gutter={[16, 16]}
                    >
                        {videos.map((videosData) => (
                            <Col span={6} key={videosData?.type}>
                                <Row
                                    style={{
                                        columnGap: '20px',
                                    }}
                                >
                                    <div
                                        style={{
                                            width: '100%',
                                            position: 'relative',
                                        }}
                                    >
                                        <video
                                            src={videosData?.link}
                                            width={'100%'}
                                            height={'200px'}
                                            style={{
                                                maxHeight: '250px',
                                                objectFit: 'cover',
                                                borderRadius: '8px',
                                                boxShadow:
                                                    '0px 3px 6px 0px rgba(140, 149, 159, 0.15)',
                                            }}
                                            preview={false}
                                        />
                                        <div
                                            style={{
                                                position: 'absolute',
                                                top: '40%',
                                                right: '45%',
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => {
                                                dispatch(
                                                    switchVideoPlayModal({
                                                        delete: true,
                                                        videoData: {
                                                            deleteId:
                                                                videosData?._id,
                                                            link: videosData.link,
                                                        },
                                                        open: true,
                                                        title: videosData?.type,
                                                    })
                                                )
                                            }}
                                        >
                                            <div
                                                style={{
                                                    height: '50px',
                                                }}
                                            >
                                                <VideosIcon
                                                    color={
                                                        token.colorPalette
                                                            .textColor.quinary
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <Row
                                        justify={'space-between'}
                                        style={{
                                            marginTop: '22px',
                                            width: '100%',
                                        }}
                                    >
                                        <Title className={'titleSub'}>
                                            {videosData?.type || 'N/A'}
                                        </Title>
                                        {userProfile?.role?.roleName !==
                                            SUPER_ADMIN && (
                                            <div
                                                style={{
                                                    cursor: 'pointer',
                                                    height: '26px',
                                                    width: '26px',
                                                }}
                                                onClick={() => {
                                                    dispatch(
                                                        setDeleteModalTitle(
                                                            'video'
                                                        )
                                                    )
                                                    dispatch(
                                                        switchVideoPlayModal({
                                                            delete: true,
                                                            videoData: {
                                                                deleteId:
                                                                    introductionVideoModal
                                                                        ?.videosTab
                                                                        ?.length >
                                                                        0 &&
                                                                    introductionVideoModal?.videosTab.find(
                                                                        (
                                                                            data
                                                                        ) =>
                                                                            data?.type ===
                                                                            videosData?.type
                                                                    )?._id,
                                                                link:
                                                                    introductionVideoModal
                                                                        ?.videosTab
                                                                        ?.length >
                                                                        0 &&
                                                                    introductionVideoModal?.videosTab.find(
                                                                        (
                                                                            data
                                                                        ) =>
                                                                            data?.type ===
                                                                            videosData?.type
                                                                    )?.link,
                                                            },
                                                            open: true,
                                                            title: videosData?.type,
                                                        })
                                                    )
                                                }}
                                            >
                                                <EditIcon />
                                            </div>
                                        )}
                                    </Row>
                                </Row>
                            </Col>
                        ))}
                    </Row>
                ) : status === 'loaded' ? (
                    <NoIndividualContent
                        content={{
                            key: 'videos',
                            title: 'Videos',
                            icon: <NoVideosIcon />,
                        }}
                    />
                ) : (
                    status === 'loading' && <Spin />
                )}
            </Spin>
        </div>
    )
}

export default VideosTabContent
