import React from 'react'

function AssignIcon({ color }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="19"
            fill="none"
            viewBox="0 0 16 19"
        >
            <path
                fill={color ? color : '#000'}
                d="M7.514 11.06v6.721H0c0-3.74 2.99-6.774 6.68-6.774.282 0 .56.018.834.053zm-.835-.9c-2.767 0-5.01-2.273-5.01-5.08S3.913 0 6.68 0c2.768 0 5.01 2.273 5.01 5.08s-2.242 5.08-5.01 5.08zm4.837 5.855l2.952-2.994 1.18 1.198-4.132 4.191-2.952-2.994 1.18-1.197 1.772 1.796z"
            ></path>
        </svg>
    )
}

export default AssignIcon
