import React from 'react'

// antd
import { Button as ButtonComponent } from 'antd'

// styles
import styles from './button.module.css'

const Button = ({ props = {} }) => {
    const {
        onClick,
        disabled,
        text,
        htmlType,
        loadingButton,
        buttonType,
        danger,
    } = props
    return (
        <ButtonComponent
            style={{
                backgroundColor: danger && 'red',
                color: buttonType === 'text' ? '#000' : '#fff',
                ...props.style,
            }}
            disabled={disabled}
            onClick={() => !htmlType && onClick()}
            htmlType={htmlType}
            className={styles.buttonMain}
            type={buttonType}
            loading={loadingButton}
            danger={danger}
            {...props}
        >
            {text}
        </ButtonComponent>
    )
}

export default Button
