import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

// antd
import { Card, Image, Row, Typography, theme } from 'antd'

// constants
import { loginState } from '../login/login.slice'

// helpers
import { checkSettingModule } from '../../helpers/settingsModuleCheck'

// assets
import { BackIcon, SettingsIcon } from '../../assets/icons'

function GlobalSetting() {
    const { useToken } = theme
    const { token } = useToken()

    const navigate = useNavigate()

    const { userProfile } = useSelector(loginState)

    const { Title } = Typography

    return (
        <>
            <div
                className={'container'}
                style={{
                    zIndex: 10,
                    minHeight: '100vh',
                    padding: '35px',
                }}
            >
                <Row
                    align={'middle'}
                    justify={'space-between'}
                    style={{
                        marginBottom: '20px',
                    }}
                >
                    <Row
                        align={'middle'}
                        style={{
                            columnGap: '13px',
                        }}
                    >
                        <SettingsIcon
                            color={token.colorPalette.baseColor.tertiary}
                        />
                        <Title level={4}>Setup</Title>
                    </Row>
                    <div
                        style={{
                            height: '30px',
                            width: '30px',
                            cursor: 'pointer',
                        }}
                        onClick={() => navigate(-1)}
                    >
                        <BackIcon />
                    </div>
                </Row>
                <Row
                    style={{
                        marginTop: '40px',
                        columnGap: '20px',
                    }}
                >
                    {checkSettingModule(
                        userProfile?.role?.roleName,
                        userProfile?.currentWorkSpace?.domain
                    ).map((data) => {
                        return (
                            <>
                                <Card
                                    styles={{
                                        body: {
                                            padding: '25px 40px',
                                        },
                                    }}
                                    key={data?.label}
                                    style={{
                                        cursor: 'pointer',
                                        width: '200px',
                                        borderRadius: '16px',
                                    }}
                                    onClick={() => navigate(data?.navigate)}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <Image
                                            src={data?.icon}
                                            preview={false}
                                            height={54}
                                            width={54}
                                        />
                                        <div
                                            style={{
                                                marginTop: '10px',
                                            }}
                                        >
                                            <Title level={4}>
                                                {data?.label}
                                            </Title>
                                        </div>
                                    </div>
                                </Card>
                            </>
                        )
                    })}
                </Row>
            </div>
        </>
    )
}

export default GlobalSetting
