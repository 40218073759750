import React from 'react'

function InquiryAttachmentIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="20"
            fill="none"
            viewBox="0 0 18 20"
        >
            <path
                fill="#747474"
                d="M17.997.992L18 19.008a1 1 0 01-.993.992H.993A.993.993 0 010 19.008V.992A1 1 0 01.993 0h16.01c.549 0 .994.444.994.992zM6 11V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 10-6 0v4a5 5 0 0010 0V6h-2v5a3 3 0 11-6 0z"
            ></path>
        </svg>
    )
}

export default InquiryAttachmentIcon
