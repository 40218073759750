import React from 'react'

function DegreeIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 24 22"
        >
            <path
                fill="#4F46E5"
                d="M12 .602l-12 5.6 3.2 1.44v2.72c-.96.32-1.6 1.28-1.6 2.24 0 .96.64 1.92 1.6 2.24v.16l-1.44 3.36c-.48 1.44-.16 3.04 2.24 3.04 2.4 0 2.72-1.6 2.24-3.04l-1.44-3.36c.96-.48 1.6-1.28 1.6-2.4 0-1.12-.64-1.92-1.6-2.24v-1.92l7.2 3.36 12-5.6-12-5.6zm7.04 10.4l-7.2 3.2L8 12.442v.16c0 1.12-.48 2.08-1.28 2.88l.96 2.24v.16c.16.64.32 1.28.16 1.92 1.12.48 2.4.8 4 .8 5.28 0 7.2-3.2 7.2-4.8v-4.8z"
            ></path>
        </svg>
    )
}

export default DegreeIcon
