import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import InfiniteScroll from 'react-infinite-scroll-component'
import { FileIcon, defaultStyles } from 'react-file-icon'

// antd
import { Divider, Input, Row, Typography, message, theme } from 'antd'

// slices
import {
    changeEditInputAttachMent,
    changePageInquiryAttachment,
    inquiryAddRemoveFiles,
    inquiryAttachmentGet,
    inquiryAttachmentUpdate,
    inquiryCountFiles,
    inquiryListFiles,
    inquiryState,
} from '../../inquiries.slice'

// components
import DeletePopUp from '../../../../components/deletePopUp/deletePopUp'

// helpers
import { formatBytes } from '../../../../helpers/fileFormat'
import { downloadFileGet } from '../../../../helpers/fileDownload'
import { unique } from '../../../../helpers/uniqueArray'

import dayjs from 'dayjs'

// assets
import { DeleteIcon, DownloadFileIcon } from '../../../../assets/icons'

// modal content
import ViewFileModalContent from '../../../files/viewFileModalContent'

const ListInquiryAttachment = () => {
    const { useToken } = theme
    const { token } = useToken()

    const { Text, Title } = Typography

    const dispatch = useDispatch()

    const fileTypes = [
        '.png',
        '.jpg',
        '.jpeg',
        '.gif',
        '.svg',
        '.mp4',
        '.mov',
        '.wmv',
        '.mkv',
    ]

    const {
        attachmentList,
        isNextAttachment,
        skipPageAttachment,
        currentPageAttachment,
        pageLimitAttachment,
        editInputAttachment,
        selectedInquiry,
        attachmentGetStatus,
        selectedAttachment,
    } = useSelector(inquiryState)

    const [filteredFilesData, setFilteredFilesData] = useState([])
    const [viewFileModal, setViewFileModal] = useState(false)
    const [fileTitle, setFileTitle] = useState('')
    const [deleteModal, setDeleteModal] = useState({
        modalOpen: false,
        id: '',
    })
    const [deleteButtonLoading, setDeleteButtonLoading] = useState(false)

    // CHECK FOR LAZY LOADING IS_NEXT
    useEffect(() => {
        if (attachmentList?.length > 0) {
            let array = []
            attachmentList?.length > 0 &&
                attachmentList.forEach((data) => {
                    array = [...array, data]
                })
            let newArray = [...filteredFilesData, ...array]
            let finalArray = unique(newArray, '_id')
            setFilteredFilesData(finalArray)
            if (!isNextAttachment) {
                return
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [attachmentList])

    // FETCH MORE ATTACHMENTS
    const fetchMoreFiles = async () => {
        if (isNextAttachment) {
            dispatch(
                inquiryListFiles({
                    skip: skipPageAttachment + pageLimitAttachment,
                    limit: pageLimitAttachment,
                    id: selectedInquiry?._id,
                    workspaceId: selectedInquiry?.client?.workspaceId,
                })
            )
            dispatch(
                inquiryCountFiles({
                    id: selectedInquiry?._id,
                    workspaceId: selectedInquiry?.client?.workspaceId,
                })
            )
            dispatch(
                changePageInquiryAttachment({
                    currentPage: currentPageAttachment + 1,
                    skip: skipPageAttachment + pageLimitAttachment,
                    pageLimit: pageLimitAttachment,
                })
            )
        }
    }

    // UPDATE ATTACHMENT NAME
    const updateFileName = async () => {
        if (editInputAttachment?.open) {
            const result = await dispatch(
                inquiryAttachmentUpdate({
                    updatedData: {
                        displayName: fileTitle,
                    },
                    fileId: editInputAttachment?.id,
                    id: selectedInquiry?._id,
                    workspaceId: selectedInquiry?.client?.workspaceId,
                })
            )

            const data = result?.payload?.data
            if (data) {
                dispatch(
                    changeEditInputAttachMent({
                        open: false,
                        id: '',
                    })
                )
                const { success, message: checkMessage } = data
                if (success) {
                    dispatch(
                        changePageInquiryAttachment({
                            currentPage: 1,
                            skip: 0,
                            pageLimitAttachment,
                        })
                    )
                    dispatch(
                        inquiryListFiles({
                            skip: 0,
                            limit: pageLimitAttachment,
                            id: selectedInquiry?._id,
                            workspaceId: selectedInquiry?.client?.workspaceId,
                        })
                    )
                    dispatch(
                        inquiryCountFiles({
                            id: selectedInquiry?._id,
                            workspaceId: selectedInquiry?.client?.workspaceId,
                        })
                    )
                    setFilteredFilesData([])
                    message.success(checkMessage)
                } else {
                    if (checkMessage) {
                        message.error(checkMessage)
                    } else {
                        message.error('Something went wrong, try again later.')
                    }
                }
            }
        }
    }

    //  DELETE ATTACHMENT DATA
    async function deleteAttachment() {
        setDeleteButtonLoading(true)

        const result = await dispatch(
            inquiryAddRemoveFiles({
                id: selectedInquiry?._id,
                workspaceId: selectedInquiry?.client?.workspaceId,
                fileObject: {
                    fieldName: 'remove',
                    files: deleteModal?.id,
                },
            })
        )

        const data = result?.payload?.data

        if (data) {
            const { success, message: errorMessage } = data
            if (success) {
                dispatch(
                    inquiryListFiles({
                        skip: 0,
                        limit: pageLimitAttachment,
                        id: selectedInquiry?._id,
                        workspaceId: selectedInquiry?.client?.workspaceId,
                    })
                )
                dispatch(
                    inquiryCountFiles({
                        id: selectedInquiry?._id,
                        workspaceId: selectedInquiry?.client?.workspaceId,
                    })
                )
                setFilteredFilesData([])
                setDeleteButtonLoading(false)
                setDeleteModal({ modalOpen: false, id: '' })
                message.success(errorMessage)
            } else {
                setDeleteButtonLoading(false)
                setDeleteModal({ modalOpen: false, id: '' })
                if (errorMessage) {
                    message.error(errorMessage)
                } else {
                    message.error('Something went wrong, try again later.')
                }
            }
        }
    }

    // VIEW INDIVIDUAL FILE
    function viewIndividualFile(item) {
        setViewFileModal(true)
        dispatch(
            inquiryAttachmentGet({
                inquiryId: selectedInquiry?._id,
                fileId: item?._id,
                workspaceId: selectedInquiry?.client?.workspaceId,
            })
        )
    }

    return (
        <>
            <InfiniteScroll
                dataLength={
                    filteredFilesData?.length > 0
                        ? filteredFilesData?.length
                        : 0
                }
                next={fetchMoreFiles}
                hasMore={isNextAttachment}
                style={{
                    overflow: 'inherit',
                    marginTop: '15px',
                }}
            >
                {filteredFilesData.map((item, index) => {
                    const str = item?.name.split('.com/')[1]
                    const substring = '_'
                    const lastIndex = str.lastIndexOf(substring)
                    const after = str.slice(lastIndex + 1).split('.')[1]
                    return (
                        <>
                            {index > 0 && <Divider />}
                            <Row align={'middle'} justify={'space-between'}>
                                <Row
                                    align={'middle'}
                                    style={{
                                        columnGap: '16px',
                                    }}
                                >
                                    <div
                                        style={{
                                            height: '60px',
                                            width: '60px',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => {
                                            if (
                                                fileTypes.some((r) =>
                                                    item?.name
                                                        ?.toLowerCase()
                                                        .includes(r)
                                                )
                                            ) {
                                                viewIndividualFile(item)
                                            } else {
                                                window.open(
                                                    item?.name,
                                                    '_blank'
                                                )
                                            }
                                        }}
                                    >
                                        <FileIcon
                                            extension={after}
                                            {...defaultStyles[after]}
                                        />
                                    </div>
                                    <div>
                                        {editInputAttachment.id ===
                                        item?._id ? (
                                            <Input
                                                style={{
                                                    backgroundColor:
                                                        'transparent',
                                                    padding: '6px 11px',
                                                    fontSize: '16px',
                                                    fontWeight: 500,
                                                }}
                                                className="active-file-edit"
                                                name="fileTitle"
                                                defaultValue={item?.displayName}
                                                onKeyUp={(e) => {
                                                    if (
                                                        e.target.value?.length >
                                                        0
                                                    ) {
                                                        if (
                                                            e.key === 'Enter' ||
                                                            e.key === 13
                                                        ) {
                                                            if (
                                                                e.target.value[0].includes(
                                                                    ' '
                                                                )
                                                            ) {
                                                                message.error(
                                                                    'No space is allowed at the beginning'
                                                                )
                                                            } else {
                                                                updateFileName(
                                                                    item?._id
                                                                )
                                                            }
                                                        }
                                                    } else {
                                                        message.error(
                                                            'File name should not be empty'
                                                        )
                                                    }
                                                    setFileTitle(
                                                        e.target.value.trimStart()
                                                    )
                                                }}
                                            />
                                        ) : (
                                            <Title
                                                level={5}
                                                onClick={() => {
                                                    dispatch(
                                                        changeEditInputAttachMent(
                                                            {
                                                                open: true,
                                                                id: item?._id,
                                                            }
                                                        )
                                                    )
                                                }}
                                            >
                                                {item?.displayName}
                                            </Title>
                                        )}
                                        <Text
                                            style={{
                                                color: token.colorPalette
                                                    .textColor.secondary,
                                                fontSize:
                                                    token.fontSizeHeading5,
                                            }}
                                        >
                                            by {item?.createdBy},{' '}
                                            {dayjs(item?.updatedAt).fromNow()} -{' '}
                                            {formatBytes(item?.fileSize)}
                                        </Text>
                                    </div>
                                </Row>
                                <Row
                                    justify="end"
                                    style={{ columnGap: '22px' }}
                                >
                                    <div
                                        style={{
                                            cursor: 'pointer',
                                        }}
                                        onClick={() =>
                                            downloadFileGet(
                                                item?.name,
                                                item?.displayName
                                            )
                                        }
                                    >
                                        <DownloadFileIcon />
                                    </div>
                                    <div
                                        style={{
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => {
                                            setDeleteModal({
                                                modalOpen: true,
                                                id: item?._id,
                                            })
                                        }}
                                    >
                                        <DeleteIcon />
                                    </div>
                                </Row>
                            </Row>
                        </>
                    )
                })}
            </InfiniteScroll>
            <ViewFileModalContent
                props={{
                    visibility: viewFileModal,
                    onCancel: () => setViewFileModal(false),
                    selectedData: selectedAttachment,
                    status: attachmentGetStatus,
                }}
            />
            <DeletePopUp
                previewDelete={deleteModal?.modalOpen}
                onDelete={() => {
                    deleteAttachment()
                }}
                loadingButton={deleteButtonLoading}
                onCancel={() => {
                    setDeleteButtonLoading(false)
                    setDeleteModal({ modalOpen: false, id: '' })
                }}
                pageKey={'Attachment'}
                modalTitle={`Delete Attachment`}
            />
        </>
    )
}

export default ListInquiryAttachment
