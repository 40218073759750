import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// antd
import { Input, Modal, Row, Spin, Typography, message, theme } from 'antd'

// slices
import { searchUsers } from '../users/users.slice'
import {
    addRole,
    changePage,
    roleListGet,
    roleState,
    totalRoleCount,
    updateRole,
} from './role.slice'
import { loginState } from '../login/login.slice'

// components
import Button from '../../components/button/button'

// constants
import { SUPER_ADMIN } from '../../constants/roles'

function AddRoleModal({ visibility, onCancel }) {
    const { useToken } = theme
    const { token } = useToken()

    const { Title } = Typography

    const dispatch = useDispatch()

    const { pageLimit, skipPage, search, updatedDataStatus, selectedData } =
        useSelector(roleState)
    const { userProfile } = useSelector(loginState)

    const [value, setValue] = useState('')
    const [roleLoading, setRoleLoading] = useState(false)

    useEffect(() => {
        if (selectedData) {
            setValue(selectedData?.roleName)
        } else {
            setValue('')
        }
    }, [selectedData])

    // CREATE ROLE
    async function createRole() {
        setRoleLoading(true)

        const payload = {
            roleName: value,
        }

        if (userProfile?.role?.roleName === SUPER_ADMIN) {
            payload.isCreatedBySuperAdmin = true
        } else {
            payload.createBy = userProfile?._id
        }

        const result = selectedData
            ? await dispatch(
                  updateRole({
                      updatedData: { ...payload },
                      _id: selectedData?._id,
                      step: 'role-name',
                  })
              )
            : await dispatch(addRole(payload))
        const data = result?.payload?.data
        if (data) {
            const { success, message: checkMessage } = data
            if (success) {
                setRoleLoading(false)
                message.success(checkMessage)
                dispatch(searchUsers(''))
                dispatch(
                    totalRoleCount({
                        search: search,
                    })
                )
                dispatch(
                    roleListGet({
                        limit: pageLimit,
                        skip: skipPage,
                        search: search,
                    })
                )
                dispatch(
                    changePage({
                        currentPage: 1,
                        skip: 0,
                        pageLimit,
                    })
                )
                onCancel()
            } else {
                setRoleLoading(false)
                setValue('')
                if (typeof checkMessage === 'object') {
                    for (const key in checkMessage) {
                        message.error(checkMessage[key])
                    }
                } else if (typeof checkMessage === 'string') {
                    message.error(checkMessage)
                } else {
                    message.error('Something went wrong, try again later.')
                }
            }
        }
    }

    return (
        <>
            <Modal
                closable={false}
                open={visibility}
                onCancel={() => {
                    onCancel()
                    // setValue('')
                }}
                destroyOnClose={true}
                width={440}
                footer={null}
                centered
                title={null}
            >
                <Spin spinning={updatedDataStatus === 'loading' ? true : false}>
                    <Row
                        align={'middle'}
                        justify={'center'}
                        style={{
                            marginBottom: '27px',
                        }}
                    >
                        <Title level={4} style={{ margin: 0 }}>
                            {selectedData ? 'Edit Role' : 'Add Role'}
                        </Title>
                    </Row>
                    <Title
                        level={5}
                        style={{
                            color: token.colorPalette.textColor.secondary,
                            fontWeight: 400,
                        }}
                    >
                        Role Name
                    </Title>
                    <Input
                        placeholder="Add Role"
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                        style={{
                            height: '50px',
                            marginTop: '4px',
                        }}
                        readOnly={selectedData?.isEditable === false}
                    />
                    <Row
                        justify={'end'}
                        style={{
                            marginTop: 35,
                            columnGap: 10,
                        }}
                    >
                        <Button
                            props={{
                                text: 'Cancel',
                                onClick: onCancel,
                                type: 'link',
                                style: {
                                    color: token.colorPalette.baseColor.black,
                                },
                            }}
                        />
                        <Button
                            props={{
                                text: selectedData ? 'Save' : 'Add',
                                disabled: value?.length <= 3,
                                loadingButton: roleLoading,
                                onClick: () => createRole(),
                                style: {
                                    padding: '0px 30px',
                                    height: '40px',
                                },
                            }}
                        />
                    </Row>
                </Spin>
            </Modal>
        </>
    )
}

export default AddRoleModal
