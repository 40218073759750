import React from 'react'

function SkillsIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 35 30"
        >
            <path
                fill="#fff"
                fillRule="evenodd"
                d="M14.612 6.596H32.56c1.24 0 2.244-1.033 2.244-2.308S33.8 1.98 32.56 1.98H14.612c-1.24 0-2.244 1.033-2.244 2.308s1.004 2.308 2.244 2.308zM32.56 12.75H14.612c-1.24 0-2.244 1.032-2.244 2.307s1.004 2.308 2.244 2.308H32.56c1.24 0 2.244-1.033 2.244-2.308S33.8 12.75 32.56 12.75zM32.56 23.519H14.612c-1.24 0-2.244 1.033-2.244 2.307 0 1.275 1.004 2.308 2.244 2.308H32.56c1.24 0 2.244-1.033 2.244-2.308 0-1.274-1.005-2.307-2.244-2.307zM7.297 13.22l-1.494-.223-.668-1.392a1.12 1.12 0 00-1.006-.643c-.427 0-.817.25-1.006.643l-.668 1.392-1.495.223a1.132 1.132 0 00-.905.786c-.132.418-.022.876.284 1.183l1.08 1.084-.255 1.53c-.072.433.101.87.446 1.129a1.1 1.1 0 001.182.088l1.337-.723 1.336.723c.164.088.343.133.522.133h.003c.62 0 1.122-.517 1.122-1.154 0-.097-.012-.192-.034-.281l-.24-1.445 1.08-1.084c.306-.307.416-.765.284-1.183a1.13 1.13 0 00-.905-.786zM7.297 2.451l-1.494-.223L5.135.835A1.12 1.12 0 004.129.192c-.427 0-.817.25-1.006.643l-.668 1.393-1.495.223a1.132 1.132 0 00-.905.786c-.132.418-.022.876.284 1.182l1.08 1.084-.255 1.531c-.072.433.101.87.446 1.129a1.1 1.1 0 001.182.087l1.337-.722 1.336.722c.164.09.343.134.522.134h.003c.62 0 1.122-.517 1.122-1.154 0-.097-.012-.192-.034-.281l-.24-1.446 1.08-1.084c.306-.306.416-.764.284-1.182a1.13 1.13 0 00-.905-.786zM7.297 23.99l-1.494-.223-.668-1.393a1.12 1.12 0 00-1.006-.643c-.427 0-.817.25-1.006.643l-.668 1.393-1.495.223a1.132 1.132 0 00-.905.786c-.132.417-.022.875.284 1.182l1.08 1.084-.255 1.53c-.072.434.101.87.446 1.129a1.099 1.099 0 001.182.088l1.337-.723 1.336.723c.164.089.343.133.522.133h.003c.62 0 1.122-.517 1.122-1.154 0-.097-.012-.191-.034-.28l-.24-1.446 1.08-1.084c.306-.307.416-.765.284-1.182a1.13 1.13 0 00-.905-.786z"
                clipRule="evenodd"
            ></path>
        </svg>
    )
}

export default SkillsIcon
