export const degreeList = [
    {
        label: 'Bachelors',
        value: 'Bachelors',
    },
    {
        label: 'Masters',
        value: 'Masters',
    },
    {
        label: 'Associate',
        value: 'Associate',
    },
    {
        label: 'Doctoral ',
        value: 'Doctoral ',
    },
    {
        label: 'Professional',
        value: 'Professional',
    },
    {
        label: 'Undergraduate',
        value: 'Undergraduate',
    },
    {
        label: 'Bachelor of Arts',
        value: 'Bachelor of Arts',
    },
    {
        label: 'BSc',
        value: 'BSc',
    },
    {
        label: 'Foundation degree',
        value: 'Foundation degree',
    },
    {
        label: 'Bachelor of Fine Arts',
        value: 'Bachelor of Fine Arts',
    },
    {
        label: 'Postgraduate education',
        value: 'Postgraduate education',
    },
    {
        label: 'Bachelor of Business Administration',
        value: 'Bachelor of Business Administration',
    },
    {
        label: 'MBA',
        value: 'MBA',
    },
    {
        label: 'Bachelor of Engineering',
        value: 'Bachelor of Engineering',
    },
    {
        label: 'Bachelor of Architecture',
        value: 'Bachelor of Architecture',
    },
]

export const fieldOfStudyList = [
    {
        label: 'Architecture',
        value: 'Architecture',
    },
    {
        label: 'Biological and Biomedical Sciences',
        value: 'Biological and Biomedical Sciences',
    },
    { label: 'Business', value: 'Business' },
    {
        label: 'Communications and Journalism',
        value: 'Communications and Journalism',
    },
    {
        label: 'Computer Sciences',
        value: 'Computer Sciences',
    },
    { label: 'Education', value: 'Education' },
    { label: 'Engineering', value: 'Engineering' },
    {
        label: 'Legal',
        value: 'Legal',
    },
    {
        label: 'Liberal Arts and Humanities',
        value: 'Liberal Arts and Humanities',
    },
    {
        label: 'Medical and Health Professions',
        value: 'Medical and Health Professions',
    },
    {
        label: 'Physical Sciences',
        value: 'Physical Sciences',
    },
    { label: 'Psychology', value: 'Psychology' },
    {
        label: 'Visual and Performing Arts',
        value: 'Visual and Performing Arts',
    },
]

export const instituteList = [
    {
        label: 'Ahmedabad University',
        value: 'Ahmedabad University',
    },
    {
        label: 'Gujarat University',
        value: 'Gujarat University',
    },
    {
        label: 'Indus University',
        value: 'Indus University',
    },
    {
        label: 'Nirma University',
        value: 'Nirma University',
    },
]

export const locationList = [
    {
        label: 'Ahmedabad',
        value: 'Ahmedabad',
    },
    {
        label: 'Chittor',
        value: 'chittor',
    },
    {
        label: 'Delhi',
        value: 'Delhi',
    },
    {
        label: 'Mumbai',
        value: 'Mumbai',
    },
    {
        label: 'Pune',
        value: 'Pune',
    },
    {
        label: 'Abu',
        value: 'Abu',
    },
    {
        label: 'Jaipur',
        value: 'Jaipur',
    },
    {
        label: 'Chennai',
        value: 'Chennai',
    },
    {
        label: 'Hyderabad',
        value: 'Hyderabad',
    },
]

export const employmentTypeList = [
    {
        labe: 'Full-time',
        value: 'Full-time',
    },
    {
        labe: 'Part-time',
        value: 'Part-time',
    },
    {
        labe: 'Casual',
        value: 'Casual',
    },
    {
        labe: 'Contract',
        value: 'Contract',
    },
    {
        labe: 'Apprenticeship',
        value: 'Apprenticeship',
    },
    {
        labe: 'Traineeship',
        value: 'Traineeship',
    },
    {
        labe: 'Employment on commission',
        value: 'Employment on commission',
    },
    {
        labe: 'Probation',
        value: 'Probation',
    },
]

export const companyNameList = [
    {
        label: 'Infosys',
        value: 'Infosys',
    },
    {
        label: 'Tata Consultancy Services',
        value: 'Tata Consultancy Services',
    },
    {
        label: 'Bajaj Finance',
        value: 'Bajaj Finance',
    },
    {
        label: 'Wipro',
        value: 'Wipro',
    },
    {
        label: 'Tata Motors',
        value: 'Tata Motors',
    },
]

export const providerList = [
    {
        label: 'Adobe',
        value: 'Adobe',
    },
    {
        label: 'Udemy',
        value: 'Udemy',
    },
    {
        label: 'GoDaddy',
        value: 'GoDaddy',
    },
    {
        label: 'DigiCert',
        value: 'DigiCert',
    },
    {
        label: 'Entrust',
        value: 'Entrust',
    },
    {
        label: 'Thawte',
        value: 'Thawte',
    },
]

export const linkTypeList = [
    {
        label: 'Vercel',
        value: 'Vercel',
    },
    {
        label: 'Behance',
        value: 'Behance',
    },
    {
        label: 'DigitalOcean',
        value: 'DigitalOcean',
    },
    {
        label: 'Firebase',
        value: 'Firebase',
    },
    {
        label: 'Serverless',
        value: 'Serverless',
    },
    {
        label: 'AWS Lambda',
        value: 'AWS Lambda',
    },
    {
        label: 'Netlify',
        value: 'Netlify',
    },
]

export const videoSummaryData = [
    {
        key: 'Introduction Video',
        title: 'Introduction Video',
        category: 'Profile Summary',
    },
    {
        key: 'Past Work Experience',
        title: 'Past Work Experience',
        category: 'Profile Summary',
    },
    {
        key: 'Most Proud Achievements',
        title: 'Most Proud Achievements',
        category: 'Profile Summary',
    },
]

export const videosTitle = [
    {
        key: 'Introduction Video',
        title: 'Introduction Video',
        category: 'Profile Video',
    },
    {
        key: 'Past Work Experience',
        title: 'Past Work Experience',
        category: 'Profile Video',
    },
    {
        key: 'Most Proud Achievements',
        title: 'Most Proud Achievements',
        category: 'Profile Video',
    },
    {
        key: 'Goals and Objectives',
        title: 'Goals and Objectives',
        category: 'Profile Video',
    },
    {
        key: 'Strength and Weakness',
        title: 'Strength and Weakness',
        category: 'Profile Video',
    },
    {
        key: 'Management Style',
        title: 'Management Style',
        category: 'Profile Video',
    },
    {
        key: 'My Specialty',
        title: 'My Specialty',
        category: 'Profile Video',
    },
    {
        key: 'Other',
        title: 'Other',
        category: 'Profile Video',
    },
]
