import React from 'react'

function CriticalPriorityIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
        >
            <circle
                cx="12"
                cy="12"
                r="10"
                stroke="red"
                strokeWidth="2"
            ></circle>
            <path
                stroke="red"
                strokeLinecap="round"
                strokeWidth="2"
                d="M8 9h8m-8 3h8m-8 3h8M12 3v3m-5.25-.25l2.121 2.121M17.25 5.75l-2.121 2.121M6.75 18.25l2.121-2.121m8.379 2.121l-2.121-2.121"
            ></path>
        </svg>
    )
}

export default CriticalPriorityIcon
