import React from 'react'

function PasswordShowIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="none"
            viewBox="0 0 20 20"
        >
            <path
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M12.983 10A2.98 2.98 0 0110 12.985a2.98 2.98 0 01-2.983-2.983A2.98 2.98 0 0110 7.018 2.98 2.98 0 0112.983 10z"
            ></path>
            <path
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M10 16.891c2.941 0 5.683-1.733 7.591-4.733.75-1.175.75-3.15 0-4.325-1.908-3-4.65-4.733-7.591-4.733-2.942 0-5.683 1.733-7.592 4.733-.75 1.175-.75 3.15 0 4.325 1.909 3 4.65 4.733 7.592 4.733z"
            ></path>
        </svg>
    )
}

export default PasswordShowIcon
