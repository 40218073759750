// antd
import { Col, Image, Row, Space, theme, Typography } from 'antd'
import { NoProjectsImageIcon } from '../../../assets/icons'
import MultiProjectMediaModal from '../modals/multiProjectMediaModal'
import { useState } from 'react'

function ProjectsSummaryContent({ contentData }) {
    const { Title, Text } = Typography

    const { useToken } = theme
    const { token } = useToken()

    const [viewMediaProject, setViewMediaProject] = useState({
        open: false,
        project: {},
    })

    return (
        <>
            <Row
                style={{
                    margin: '25px 0px 50px 0px',
                }}
                align={'middle'}
                gutter={16}
            >
                {contentData.map((projectData) => {
                    const startDate =
                        projectData?.start !== null
                            ? new Date(projectData?.start)
                            : null
                    const endDate =
                        projectData?.end !== null
                            ? new Date(projectData?.end)
                            : null
                    return (
                        <Col span={6} key={projectData?.projectName}>
                            <Row
                                align={'middle'}
                                style={{
                                    columnGap: '20px',
                                }}
                            >
                                {projectData?.media?.length > 0 ? (
                                    <Image
                                        src={projectData?.media[0]}
                                        width={'100%'}
                                        height={'200px'}
                                        style={{
                                            maxHeight: '250px',
                                            borderRadius: '8px',
                                            cursor: 'pointer',
                                            boxShadow:
                                                '0px 3px 6px 0px rgba(140, 149, 159, 0.15)',
                                        }}
                                        preview={false}
                                        onClick={() => {
                                            setViewMediaProject({
                                                open: true,
                                                project: projectData,
                                            })
                                        }}
                                    />
                                ) : (
                                    <div
                                        style={{
                                            width: '100%',
                                            borderRadius: '8px',
                                            height: '200px',
                                            boxShadow:
                                                '0px 3px 6px 0px rgba(140, 149, 159, 0.15)',
                                            backgroundColor:
                                                token.colorPalette.textColor
                                                    .tertiary,
                                            opacity: 0.4,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <div
                                            style={{
                                                width: '35px',
                                                height: '35px',
                                            }}
                                        >
                                            <NoProjectsImageIcon />
                                        </div>
                                    </div>
                                )}
                                <Row
                                    style={{
                                        marginTop: '22px',
                                        width: '100%',
                                    }}
                                >
                                    <Space>
                                        {projectData?.projectName && (
                                            <Title className={'titleSub'}>
                                                {projectData?.projectName}
                                            </Title>
                                        )}
                                        <Text className="titleLight">
                                            {!projectData?.isCompleted &&
                                                `(Draft)`}
                                        </Text>
                                    </Space>
                                </Row>
                                <Space>
                                    {startDate !== null && (
                                        <Text
                                            style={{
                                                fontSize: token.fontSizeLG,
                                            }}
                                            className={'titleLight'}
                                        >
                                            {startDate.toLocaleString(
                                                'default',
                                                {
                                                    year: 'numeric',
                                                    month: 'long',
                                                }
                                            )}
                                        </Text>
                                    )}
                                    {startDate !== null &&
                                        (projectData?.currentWorking ||
                                            endDate !== null) &&
                                        '-'}
                                    {(projectData?.currentWorking ||
                                        endDate !== null) && (
                                        <Text
                                            style={{
                                                fontSize: token.fontSizeLG,
                                            }}
                                            className={'titleLight'}
                                        >
                                            {projectData?.currentWorking
                                                ? 'Present'
                                                : new Date(
                                                      endDate
                                                  )?.toLocaleString('default', {
                                                      month: 'long',
                                                      year: 'numeric',
                                                  })}
                                        </Text>
                                    )}
                                </Space>
                            </Row>
                        </Col>
                    )
                })}
            </Row>
            <MultiProjectMediaModal
                projectData={viewMediaProject?.project}
                open={viewMediaProject?.open}
                onClose={() => {
                    setViewMediaProject({
                        open: false,
                        project: {},
                    })
                }}
            />
        </>
    )
}

export default ProjectsSummaryContent
