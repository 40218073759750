import React from 'react'

function GridViewIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="none"
            viewBox="0 0 16 16"
        >
            <path
                fill="#637381"
                d="M2.167.492H5.5A1.667 1.667 0 017.167 2.16v3.333A1.667 1.667 0 015.5 7.16H2.167A1.667 1.667 0 01.5 5.492V2.16A1.667 1.667 0 012.167.492zM10.5.492h3.333A1.666 1.666 0 0115.5 2.16v3.333a1.667 1.667 0 01-1.667 1.667H10.5a1.667 1.667 0 01-1.667-1.667V2.16A1.667 1.667 0 0110.5.492zM5.5 8.826H2.167A1.667 1.667 0 00.5 10.492v3.333a1.667 1.667 0 001.667 1.667H5.5a1.667 1.667 0 001.667-1.667v-3.333A1.667 1.667 0 005.5 8.826zM10.5 8.826h3.333a1.666 1.666 0 011.667 1.666v3.333a1.666 1.666 0 01-1.667 1.667H10.5a1.667 1.667 0 01-1.667-1.667v-3.333A1.667 1.667 0 0110.5 8.826z"
            ></path>
        </svg>
    )
}

export default GridViewIcon
