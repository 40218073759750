import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { debounce } from 'lodash'

// antd
import { Avatar, Image, Row, Select, Spin, Switch, message, theme } from 'antd'

// slices
import {
    addProject,
    projectsList,
    projectsState,
    searchPeople,
    searchUsers,
    totalProjects,
} from '../projects/projects.slice'
import { loginState } from '../login/login.slice'

// components
import Button from '../../components/button/button'

// assets
import { CloseModalIcon } from '../../assets/icons'

function InviteMemberSection({ onCancel, setStep, refreshProject }) {
    const { Option } = Select

    const dispatch = useDispatch()
    const { useToken } = theme
    const { token } = useToken()

    const {
        searchUserStatus,
        searchUsersList,
        stepProjectData,
        pageLimit,
        skipPage,
        peopleSearch,
    } = useSelector(projectsState)
    const { userProfile } = useSelector(loginState)

    const initialState = {
        value: [],
        options: [],
        memberLoadingButton: false,
    }

    const [state, setState] = useState(initialState)
    const [jiraSyncToggle, setJiraSyncToggle] = useState(false)

    // ADD TEAM MEMBER
    async function addTeamMember() {
        setState((prevState) => ({ ...prevState, memberLoadingButton: true }))
        const newValue =
            state?.value?.length > 0
                ? state?.value.map((data) => data?.value)
                : []
        const result = await dispatch(
            addProject({
                ...stepProjectData,
                projectLead: userProfile?._id,
                teamMembers: [userProfile?._id, ...newValue],
                isJiraWithSyncProject: jiraSyncToggle
            })
        )
        const data = result?.payload?.data
        if (data) {
            const { success, message: checkMessage } = data
            if (success) {
                // dispatch(searchUsers(''))
                onCancel()
                setStep()
                refreshProject()
                dispatch(await totalProjects())
                dispatch(
                    await projectsList({ limit: pageLimit, skip: skipPage })
                )
                setState((prevState) => ({
                    ...prevState,
                    memberLoadingButton: false,
                }))
                message.success(checkMessage)
            } else {
                onCancel()
                setStep()
                setState((prevState) => ({
                    ...prevState,
                    memberLoadingButton: false,
                }))
                if (typeof checkMessage === 'object') {
                    for (const key in checkMessage) {
                        message.error(checkMessage[key])
                    }
                } else if (typeof checkMessage === 'string') {
                    message.error(checkMessage)
                } else {
                    message.error('Something went wrong, try again later.')
                }
            }
            // setUpdatedValues({})
        }
    }

    useEffect(() => {
        if (searchUsersList?.length > 0 && searchUserStatus === 'loaded') {
            const results = searchUsersList.map((data) => data)
            const dataOptions = results.map((data) => {
                return {
                    label: data?.name,
                    value: data?._id,
                    role: data?.role?.roleName,
                    profilePicture: data?.profilePicture,
                    id: data?._id,
                }
            })
            setState((prevState) => ({ ...prevState, options: dataOptions }))
        } else {
            setState((prevState) => ({ ...prevState, options: [] }))
            if (
                searchUsersList?.length === 0 &&
                searchUserStatus === 'loaded' &&
                peopleSearch?.length > 0
            ) {
                message.info('No User Found')
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchUsersList])

    // SEARCH USERS
    const searchFn = debounce(async (value) => {
        dispatch(searchPeople(value))
        await dispatch(
            searchUsers({
                search: value,
                skip: 0,
                filter: {
                    isAssigned: true,
                },
            })
        )
    }, 1000)


    return (
        <>
            <Select
                mode="multiple"
                value={state?.value}
                removeIcon={
                    <div
                        style={{
                            height: '14px',
                            width: '14px',
                        }}
                    >
                        <CloseModalIcon />
                    </div>
                }
                placeholder="Search"
                filterOption={false}
                onSearch={searchFn}
                disabled={state?.memberLoadingButton}
                notFoundContent={
                    searchUserStatus === 'loading' ? (
                        <Spin size="small" />
                    ) : searchUsersList?.length === 0 ? null : null
                }
                loading={searchUserStatus === 'loading'}
                onChange={(_, value) => {
                    setState((prevState) => ({ ...prevState, value: value }))
                }}
                style={{
                    width: '100%',
                    padding: '0px',
                }}
                rootClassName="p-0-multiSelect"
            >
                {state?.options?.length > 0 &&
                    state?.options.map((data) => {
                        return (
                            <Option value={data?.value} key={data?.label}>
                                <Row
                                    align={'middle'}
                                    style={{
                                        columnGap: '10px',
                                    }}
                                >
                                    {data?.profilePicture ? (
                                        <>
                                            <Image
                                                src={data?.profilePicture}
                                                height={22}
                                                width={22}
                                                style={{
                                                    borderRadius: '50%',
                                                }}
                                                preview={false}
                                            />
                                        </>
                                    ) : (
                                        <Avatar
                                            style={{
                                                backgroundColor:
                                                    'rgb(0 10 129 / 25%)',
                                                height: '22px',
                                                width: '22px',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                fontSize: '10px',
                                            }}
                                        >
                                            {data?.label[0].toUpperCase()}
                                            {data?.label.split(' ')[1] !==
                                                undefined &&
                                                data?.label.split(' ')[1] !==
                                                    '' &&
                                                data?.label
                                                    .split(' ')[1][0]
                                                    .toUpperCase()}
                                        </Avatar>
                                    )}
                                    {data?.label}
                                </Row>
                            </Option>
                        )
                    })}
            </Select>

            <div style={{
                display: 'flex',
                alignItems: 'center',
                margin: '20px 0',
                gap: '20px'                
            }} >
                <span>JIRA with Project Sync:- </span>
                <Switch
                    value={jiraSyncToggle}
                    onChange={() => setJiraSyncToggle(!jiraSyncToggle)}
                />
            </div>

            <Row
                justify={'end'}
                style={{
                    marginTop: 10,
                    columnGap: 10,
                }}
            >
                <Button
                    props={{
                        text: 'Cancel',
                        onClick: onCancel,
                        type: 'link',
                        style: {
                            color: token.colorPalette.baseColor.black,
                        },
                    }}
                />
                <Button
                    props={{
                        text: 'Confirm',
                        loadingButton: state?.memberLoadingButton,
                        onClick: () => addTeamMember(),
                    }}
                />
            </Row>
        </>
    )
}

export default InviteMemberSection
