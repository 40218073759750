import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

// antd
import {
    Avatar,
    Image,
    Row,
    Collapse,
    Space,
    Typography,
    Card,
    Col,
    theme,
    Progress,
    Spin,
    Drawer,
} from 'antd'

// slices
import { projectReportsGet, projectReportsState } from './projectReports.slice'
import {
    switchFormModal as switchFormModalTask,
    taskById,
    tasksState,
} from '../../tasks/tasks.slice'

// helpers
import { estimateFn } from '../../../helpers/estimationCalculation'

// assets
import { ExpandReportsIcon } from '../../../assets/icons'
import NoReportsFoundIcon from '../../../assets/icons/reports/noReportsFoundIcon'

// task form
import DrawerTaskForm from '../../tasks/taskForm.index'

// routes
import { PRIVATE_ROUTES } from '../../../routes'
import { teamState } from '../../team/team.slice'

const ProjectsReport = () => {
    const { Text, Title } = Typography

    const { useToken } = theme

    const { token } = useToken()

    const dispatch = useDispatch()

    const navigate = useNavigate()

    const params = useLocation()

    const { projectReportsData, status, dateRanges, filter } =
        useSelector(projectReportsState)
    const { formModal } = useSelector(tasksState)
    const { teamList } = useSelector(teamState)

    const initialState = {
        filteredAssignees: [],
    }

    const [state, setState] = useState(initialState)

    async function DateRangeSet() {
        if (dateRanges?.start && dateRanges?.end) {
            const dataScreen = {
                start: dateRanges?.start,
                end: dateRanges?.end,
                projectId: filter?.projectId,
                assigneeId: filter?.assigneeId,
            }
            dispatch(projectReportsGet(dataScreen))
        }
    }

    // HOUR, MINUTE, SECONDS LOGIC
    function hourMinuteSecond(timeInSeconds) {
        let h, m, s
        h = Math.floor(timeInSeconds / 60 / 60)
        m = Math.floor((timeInSeconds / 60 / 60 - h) * 60)
        s = Math.floor(((timeInSeconds / 60 / 60 - h) * 60 - m) * 60)
        return `${h}h ${m}m ${s}s`
    }

    useEffect(() => {
        DateRangeSet()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateRanges, filter])

    // VIEW TASK
    async function viewTask(taskID) {
        dispatch(
            switchFormModalTask({
                open: true,
            })
        )
        dispatch(taskById({ _id: taskID }))
    }

    useEffect(() => {
        // TEAM MEMBER DATA SET WITH FILTER
        if (teamList?.length > 0) {
            let array = []
            teamList?.length > 0 &&
                teamList.forEach((data) => {
                    // if (userProfile?._id !== data?._id) {
                    array = [
                        ...array,
                        {
                            label: data?.name,
                            value: data?._id,
                            profilePicture: data?.profilePicture,
                        },
                    ]
                    // }
                })
            setState((prevState) => ({
                ...prevState,
                filteredAssignees: array,
            }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [teamList])

    // COLLAPSE COMPONENT
    const CollapseComponent = () => {
        // TASK LIST COMPONENT
        const TaskList = ({ taskList, projectData }) => {
            return (
                taskList?.taskList?.length > 0 &&
                taskList?.taskList.map((taskData, index) => (
                    <Row
                        align={'middle'}
                        style={{
                            marginBottom:
                                taskList?.taskList?.length - 1 === index
                                    ? '0px'
                                    : '20px',
                        }}
                        key={taskData?._id}
                    >
                        <Col span={8}>
                            <Row
                                align={'middle'}
                                style={{
                                    columnGap: '16px',
                                    flexWrap: 'nowrap',
                                }}
                            >
                                <Space>
                                    <Text
                                        style={{
                                            fontSize: '18px',
                                            color: token.colorPalette.textColor
                                                .secondary,
                                            marginLeft: '40px',
                                        }}
                                    >
                                        {taskData?.title}
                                    </Text>
                                    <Text>-</Text>
                                    <Text
                                        style={{
                                            fontSize: '18px',
                                            color: token.colorPalette.baseColor
                                                .tertiary,
                                            textDecoration: 'underline',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => viewTask(taskData?._id)}
                                    >
                                        {taskData?.taskKey}
                                    </Text>
                                </Space>
                            </Row>
                        </Col>
                        <Col span={16}>
                            <Row
                                align={'middle'}
                                style={{
                                    columnGap: '20px',
                                    flexFlow: 'row nowrap',
                                }}
                            >
                                <Title
                                    level={5}
                                    style={{
                                        fontWeight: 600,
                                        fontSize: '18px',
                                        marginLeft: '14px',
                                    }}
                                >
                                    {
                                        taskData?.totalLog === 0
                                            ? 0
                                            : hourMinuteSecond(
                                                  taskData?.totalLog
                                              )
                                        // estimateFn(
                                        //       taskData?.totalLog / 60,
                                        //       projectData,
                                        //       'reports'
                                        //   )
                                    }
                                </Title>
                                <Progress
                                    percent={
                                        taskData?.totalLog === 0
                                            ? 100
                                            : (taskData?.totalLog /
                                                  taskList?.totalLog) *
                                              100
                                    }
                                    rootClassName="projectReportsProgress"
                                    showInfo={false}
                                    strokeLinecap="butt"
                                    trailColor={
                                        taskData?.totalLog === 0
                                            ? token.colorPalette.baseColor
                                                  .quaternary
                                            : 'transparent'
                                    }
                                    strokeColor={
                                        taskData?.totalLog === 0
                                            ? token.colorPalette.baseColor
                                                  .quaternary
                                            : token.colorPalette.baseColor
                                                  .tertiary
                                    }
                                />
                            </Row>
                        </Col>
                    </Row>
                ))
            )
        }

        // NESTED ASSIGNEES DATA
        const nestedItems = (assignees, projectData) => {
            return (
                assignees?.length > 0 &&
                assignees.map((assigneeData) => {
                    return {
                        key: assigneeData?._id,
                        label: (
                            <Row align={'middle'}>
                                <Col span={8}>
                                    <Row
                                        align={'middle'}
                                        style={{
                                            columnGap: '16px',
                                        }}
                                    >
                                        {assigneeData?.profilePicture ? (
                                            <Image
                                                src={
                                                    assigneeData?.profilePicture
                                                }
                                                height={'30px'}
                                                preview={false}
                                                width={'30px'}
                                                style={{
                                                    borderRadius: '50%',
                                                    minWidth: '30px',
                                                }}
                                            />
                                        ) : (
                                            <Avatar
                                                style={{
                                                    cursor: 'pointer',
                                                    backgroundColor:
                                                        'rgb(0 10 129 / 25%)',
                                                }}
                                            >
                                                {assigneeData?.name[0].toUpperCase()}
                                                {assigneeData?.name.split(
                                                    ' '
                                                )[1] !== undefined &&
                                                assigneeData?.name.split(
                                                    ' '
                                                )[1] !== ''
                                                    ? assigneeData?.name
                                                          .split(' ')[1][0]
                                                          .toUpperCase()
                                                    : ''}
                                            </Avatar>
                                        )}
                                        <Title
                                            level={5}
                                            style={{
                                                fontWeight: 600,
                                                fontSize: '18px',
                                            }}
                                        >
                                            {assigneeData?.name}
                                        </Title>
                                    </Row>
                                </Col>
                                <Col span={16}>
                                    <Row
                                        align={'middle'}
                                        style={{
                                            columnGap: '20px',
                                            flexFlow: 'row nowrap',
                                        }}
                                    >
                                        <Title
                                            level={5}
                                            style={{
                                                fontWeight: 600,
                                                fontSize: '18px',
                                            }}
                                        >
                                            {
                                                assigneeData?.totalLog === 0
                                                    ? 0
                                                    : hourMinuteSecond(
                                                          assigneeData?.totalLog
                                                      )
                                                //  estimateFn(
                                                //       assigneeData?.totalLog,
                                                //       projectData,
                                                //       'reports'
                                                //   )
                                            }
                                        </Title>
                                        <Progress
                                            percent={
                                                assigneeData?.totalLog === 0
                                                    ? 100
                                                    : (assigneeData?.totalLog /
                                                          projectData?.totalLog) *
                                                      100
                                            }
                                            rootClassName="projectReportsProgress"
                                            showInfo={false}
                                            strokeLinecap="butt"
                                            trailColor={
                                                assigneeData?.totalLog === 0
                                                    ? token.colorPalette
                                                          .baseColor.quaternary
                                                    : 'transparent'
                                            }
                                            strokeColor={
                                                assigneeData?.totalLog === 0
                                                    ? token.colorPalette
                                                          .baseColor.quaternary
                                                    : token.colorPalette
                                                          .baseColor.tertiary
                                            }
                                        />
                                    </Row>
                                </Col>
                            </Row>
                        ),
                        children: (
                            <div>
                                <TaskList
                                    taskList={assigneeData}
                                    projectData={projectData}
                                />
                            </div>
                        ),
                    }
                })
            )
        }

        // ALL PROJECT LIST DATA
        const items =
            projectReportsData?.length > 0 &&
            projectReportsData.map((projectData) => {
                return {
                    key: projectData?._id,
                    label: (
                        <>
                            <Row
                                align={'middle'}
                                style={{
                                    marginLeft:
                                        projectData?.assigneeList?.length === 0
                                            ? '25px'
                                            : '0px',
                                }}
                            >
                                <Col span={8}>
                                    <Row
                                        align={'middle'}
                                        style={{
                                            columnGap: '16px',
                                        }}
                                    >
                                        <Image
                                            src={projectData?.projectProfile}
                                            height={'40px'}
                                            preview={false}
                                            width={'40px'}
                                            style={{
                                                borderRadius: '50%',
                                                objectFit: 'contain',
                                                minWidth: '40px',
                                            }}
                                        />
                                        <Title
                                            style={{
                                                fontWeight: 700,
                                                fontSize: '18px',
                                            }}
                                            level={5}
                                        >
                                            {projectData?.name}
                                        </Title>
                                    </Row>
                                </Col>
                                <Col span={16}>
                                    <Title
                                        level={5}
                                        style={{
                                            fontWeight: 700,
                                            fontSize: token.fontSizeHeading4,
                                        }}
                                    >
                                        {projectData?.totalLog === 0
                                            ? 0
                                            : hourMinuteSecond(
                                                  projectData?.totalLog
                                              )}
                                        {/* estimateFn(
                                                  projectData?.totalLog,
                                                  projectData,
                                                  'reports'
                                              )} */}
                                    </Title>
                                </Col>
                            </Row>
                        </>
                    ),
                    children: (
                        <Collapse
                            defaultActiveKey={['1']}
                            items={nestedItems(
                                projectData?.assigneeList,
                                projectData
                            )}
                            ghost
                            expandIcon={({ isActive, panelKey }) => {
                                const projectDataNew =
                                    projectData?.assigneeList.find(
                                        (data) => data._id === panelKey
                                    )
                                return projectDataNew?.taskList?.length ===
                                    0 ? null : (
                                    <div
                                        style={{ width: '11px', height: '7px' }}
                                    >
                                        <ExpandReportsIcon
                                            isActive={isActive}
                                        />
                                    </div>
                                )
                            }}
                        />
                    ),
                }
            })

        const allKeys = items?.length > 0 && items.map((item) => item.key)

        return (
            <Collapse
                defaultActiveKey={allKeys}
                ghost
                items={items}
                expandIcon={({ isActive, panelKey }) => {
                    const projectData = projectReportsData.find(
                        (data) => data._id === panelKey
                    )
                    return projectData?.assigneeList?.length === 0 ? null : (
                        <div style={{ width: '12px', height: '8px' }}>
                            <ExpandReportsIcon isActive={isActive} />
                        </div>
                    )
                }}
            />
        )
    }

    return (
        <>
            <Drawer
                placement="right"
                size={'large'}
                style={{
                    height: '100vh',
                    minWidth: '1000px',
                }}
                styles={{
                    body: {
                        padding: '0px',
                    },
                }}
                rootClassName="taskHeader"
                onClose={() => {
                    dispatch(switchFormModalTask({ open: false }))
                    if (params?.search.includes('taskId')) {
                        navigate(PRIVATE_ROUTES.tasks)
                    }
                }}
                open={formModal}
            >
                <DrawerTaskForm
                    assigneeList={state?.filteredAssignees}
                    onCancel={() => {
                        dispatch(switchFormModalTask({ open: false }))
                    }}
                />
            </Drawer>
            <Card
                style={{
                    marginTop: '25px',
                    borderRadius: '16px',
                }}
                title={
                    <Row>
                        <Col span={8}>
                            <Text>Projects</Text>
                        </Col>
                        <Col span={16}>
                            <Text>Time Tracked</Text>
                        </Col>
                    </Row>
                }
                styles={{
                    header: {
                        backgroundColor:
                            token.colorPalette.baseColor.quaternary,
                    },
                }}
            >
                {status === 'loading' ? (
                    <div
                        style={{
                            textAlign: 'center',
                        }}
                    >
                        <Spin spinning={true} />
                    </div>
                ) : projectReportsData?.length > 0 && status === 'loaded' ? (
                    CollapseComponent()
                ) : (
                    projectReportsData?.length === 0 &&
                    status === 'loaded' && (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '100%',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginTop: '120px',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                }}
                            >
                                <div
                                    style={{
                                        width: '400px',
                                    }}
                                >
                                    <NoReportsFoundIcon />
                                </div>
                            </div>
                            <Text
                                className="titleSecondary"
                                style={{
                                    marginTop: '20px',
                                }}
                            >
                                No Reports Found yet.
                            </Text>
                        </div>
                    )
                )}
            </Card>
        </>
    )
}

export default ProjectsReport
