// assets
import {
    ChatIcon,
    FilesIcon,
    InquiriesIcon,
    NotesIcon,
    OverViewIcon,
    RoadMapIcon,
    SprintsIcon,
    StandUpIcon,
    TasksIcon,
    TeamIcon,
} from '../assets/icons'

// constants
import { DOMAIN_LIST, SUPER_ADMIN, WORKSPACE_ADMIN } from '../constants/roles'

// routes
import { PRIVATE_ROUTES } from '../routes'

// styles
import styles from '../layouts/navigation/navigation.module.css'

// ICON RENDER DYNAMICALLY
function iconRender(key, flag) {
    switch (key) {
        case 'overview':
            return (
                <OverViewIcon flag={flag} className={styles.icon + ' in-btn'} />
            )

        case 'roadMap':
            return (
                <RoadMapIcon flag={flag} className={styles.icon + ' in-btn'} />
            )

        case 'standups':
            return (
                <StandUpIcon flag={flag} className={styles.icon + ' in-btn'} />
            )

        case 'notes':
            return <NotesIcon flag={flag} className={styles.icon + ' in-btn'} />

        case 'reports':
            return <NotesIcon flag={flag} className={styles.icon + ' in-btn'} />

        case 'tasks':
            return <TasksIcon flag={flag} className={styles.icon + ' in-btn'} />

        case 'sprints':
            return (
                <SprintsIcon flag={flag} className={styles.icon + ' in-btn'} />
            )

        case 'files':
            return <FilesIcon flag={flag} className={styles.icon + ' in-btn'} />

        case 'teams':
            return <TeamIcon flag={flag} className={styles.icon + ' in-btn'} />

        case 'chat':
            return <ChatIcon flag={flag} className={styles.icon + ' in-btn'} />

        default:
            break
    }
}

// SIDE BAR NAVIGATION DYNAMICALLY
export function checkNavigation(moduleContainers, selected) {
    if (moduleContainers?.role?.containers?.length > 0) {
        const output = moduleContainers?.role?.containers
            .filter((data) => data?.isSidebar && data?.isFromHeader === false)
            .map((data) => {
                let newLabel = data.label

                if (data?.label === 'Note') {
                    newLabel = 'Notes'
                }

                if (data?.label === 'Task') {
                    newLabel = 'Tasks'
                }

                if (data?.label === 'Sprint') {
                    newLabel = 'Sprints'
                }

                return {
                    key: data?.key,
                    label: newLabel,
                    value: data?.value,
                    icon: iconRender(data?.key, data?.key === selected),
                    route: data?.container,
                    headerSidebarPosition: data?.headerSidebarPosition,
                }
            })
            .sort((a, b) => a.headerSidebarPosition - b.headerSidebarPosition)

        const overviewModule = {
            key: 'overview',
            label: 'Overview',
            value: 'overview',
            icon: (
                <OverViewIcon flag={true} className={styles.icon + ' in-btn'} />
            ),
            route: PRIVATE_ROUTES.overview,
            headerSidebarPosition: 4,
            rolePosition: 4,
        }
        // if (
        //     (moduleContainers?.role?.roleName === WORKSPACE_ADMIN ||
        //         moduleContainers?.role?.roleName === SUPER_ADMIN) &&
        //     !DOMAIN_LIST.includes(moduleContainers?.currentWorkSpace?.domain)
        // ) {
        //     return [
        //         overviewModule,
        //         {
        //             key: 'inquiries',
        //             label: 'Inquiries',
        //             value: 'inquiries',
        //             isSidebar: false,
        //             isFromHeader: true,
        //             icon: (
        //                 <InquiriesIcon
        //                     flag={true}
        //                     className={styles.icon + ' in-btn'}
        //                 />
        //             ),
        //             route: PRIVATE_ROUTES.inquiries,
        //             headerSidebarPosition: 3,
        //             rolePosition: 3,
        //         },
        //     ].concat(output)
        // } else {
        return [overviewModule].concat(output)

        // }
    }
}

// HEADER NAVIGATION DYNAMICALLY
export function checkNavigationHeader(moduleContainers) {
    if (moduleContainers?.role?.containers?.length > 0) {
        const output = moduleContainers?.role?.containers
            .filter((data) => data?.isFromHeader === true)
            .map((data) => {
                return {
                    key: data?.key,
                    label: data?.label,
                    value: data?.value,
                    route: data?.container,
                    headerSidebarPosition: data?.headerSidebarPosition,
                }
            })

        if (
            (moduleContainers?.role?.roleName === WORKSPACE_ADMIN ||
                moduleContainers?.role?.roleName === SUPER_ADMIN) &&
            !DOMAIN_LIST.includes(moduleContainers?.currentWorkSpace?.domain)
        ) {
            return output.concat({
                key: 'inquiries',
                label: 'Inquiries',
                value: 'inquiries',
                isSidebar: false,
                isFromHeader: true,
                icon: (
                    <InquiriesIcon
                        flag={true}
                        className={styles.icon + ' in-btn'}
                    />
                ),
                route: PRIVATE_ROUTES.inquiries,
                headerSidebarPosition: 3,
                rolePosition: 3,
            })
        }
        return output.sort(
            (a, b) => a.headerSidebarPosition - b.headerSidebarPosition
        )
    }
}
