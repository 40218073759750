import React from 'react'

function InquiryNotesIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="18"
            fill="none"
            viewBox="0 0 17 18"
        >
            <path
                fill="#747474"
                d="M1.999 11.501v-10c0-.46.373-.833.833-.833h13.333c.46 0 .834.373.834.833v13.334a2.5 2.5 0 01-2.5 2.5H2.832a2.5 2.5 0 01-2.5-2.5v-1.667h13.333v1.667a.833.833 0 101.667 0V11.5H1.999z"
            ></path>
        </svg>
    )
}

export default InquiryNotesIcon
