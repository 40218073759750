import React from 'react'

function RoleStepIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="24"
            fill="none"
            viewBox="0 0 25 24"
        >
            <circle cx="12.593" cy="11.996" r="12" fill="#4F46E5"></circle>
            <path
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M8.622 12.71l2.434 2.434 6.492-6.492"
            ></path>
        </svg>
    )
}

export default RoleStepIcon
