import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import {
    create,
    count,
    read,
    update,
    deleteUser,
    listRoles,
    getUser,
} from './users.service'

const initialState = {
    search: '',
    status: 'loaded',
    getUserStatus: 'loaded',
    roleListStatus: 'loaded',
    users: [],
    userRole: [],
    usersCount: 0,
    isNext: false,
    pageLimit: 10,
    currentPage: 1,
    formButtonType: 'saveButtonLoading',
    skipPage: 0,
    currentUser: 0,
    formModal: false,
    modalVariations: {
        open: false,
        formName: 'userAddForm',
        saveButtonLoading: false,
    },
    viewModal: false,
    filterView: false,
    filterDropDown: {
        role: '',
        department: '',
        designation: '',
        isAssigned: true,
    },
    selectedUser: undefined,
}

export const addUser = createAsyncThunk(
    'usersSlice/create',
    async (payload = {}) => {
        const response = await create(payload)
        return response
    }
)

export const updateUser = createAsyncThunk(
    'usersSlice/update',
    async (payload = {}) => {
        const response = await update(payload)
        return response
    }
)

export const totalUsers = createAsyncThunk(
    'usersSlice/count',
    async (payload = {}) => {
        const response = await count(payload)
        return response
    }
)

export const usersList = createAsyncThunk(
    'usersSlice/read',
    async (payload = {}) => {
        const response = await read(payload)
        return response
    }
)

export const deleteUsers = createAsyncThunk(
    'usersSlice/delete',
    async (payload = {}) => {
        const response = await deleteUser(payload)
        return response
    }
)

export const roleList = createAsyncThunk(
    'usersSlice/listRoles',
    async (payload = {}) => {
        const response = await listRoles(payload)
        return response
    }
)

export const userGet = createAsyncThunk(
    'usersSlice/getUser',
    async (payload = {}) => {
        const response = await getUser(payload)
        return response
    }
)

export const usersSlice = createSlice({
    name: 'usersSlice',
    initialState,
    reducers: {
        switchFormModal: (state, action) => {
            state.modalVariations = action.payload
            state.selectedUser = undefined
        },
        switchFilter: (state, action) => {
            state.filterView = action.payload || false
        },
        filterDropdown: (state, action) => {
            const dropDownValue = {
                ...state.filterDropDown,
                ...action.payload,
            }
            // Object.keys(dropDownValue).forEach((key) => {
            //     if (dropDownValue[key] === '') {
            //         delete dropDownValue[key]
            //     }
            // })
            state.filterDropDown = dropDownValue
        },
        clearFilterDropDown: (state) => {
            state.filterDropDown = initialState.filterDropDown
        },
        switchViewModal: (state, action) => {
            state.selectedUser = action.payload?.userData
                ? action.payload?.userData
                : undefined
            state.viewModal = action.payload?.open
        },
        checkButtonType: (state, action) => {
            state.formButtonType = action.payload
        },
        searchUsers: (state, action) => {
            state.search = action.payload
        },
        clearUsers: (state) => {
            state.users = []
            state.search = ''
        },
        changePage: (state, action) => {
            state.currentPage = action.payload.currentPage
            state.skipPage = action.payload.skip
            state.pageLimit = action.payload.pageLimit
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(usersList.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(usersList.fulfilled, (state, action) => {
                state.status = 'loaded'
                if (action.payload.skip > 0) {
                    state.users = [...state.users, ...action.payload.data]
                } else {
                    state.users = action.payload.data
                }
                state.isNext = action.payload.isNext
                state.currentUser = action.payload.currentCount
            })
            .addCase(userGet.pending, (state) => {
                state.getUserStatus = 'loading'
            })
            .addCase(userGet.fulfilled, (state, action) => {
                state.getUserStatus = 'loaded'
                state.selectedUser = action.payload.data.data
            })
            .addCase(roleList.pending, (state, action) => {
                state.roleListStatus = 'loading'
            })
            .addCase(roleList.fulfilled, (state, action) => {
                state.roleListStatus = 'loaded'
                state.userRole = action.payload.data
            })
            .addCase(totalUsers.fulfilled, (state, action) => {
                state.usersCount = action.payload.data
            })
    },
})

export default usersSlice.reducer

export const usersState = (state) => {
    const { usersSlice } = state
    const {
        search,
        status,
        users,
        usersCount,
        formModal,
        viewModal,
        modalVariations,
        selectedUser,
        filterView,
        pageLimit,
        filterDropDown,
        currentPage,
        skipPage,
        userRole,
        isNext,
        currentUser,
        getUserStatus,
        formButtonType,
        roleListStatus,
    } = usersSlice
    return {
        search,
        status,
        users,
        usersCount,
        formModal,
        viewModal,
        modalVariations,
        selectedUser,
        filterView,
        filterDropDown,
        pageLimit,
        currentPage,
        skipPage,
        userRole,
        isNext,
        currentUser,
        getUserStatus,
        formButtonType,
        roleListStatus,
    }
}

export const {
    switchFormModal,
    switchViewModal,
    switchFilter,
    searchUsers,
    filterDropdown,
    clearFilterDropDown,
    clearUsers,
    changePage,
    checkButtonType,
} = usersSlice.actions
