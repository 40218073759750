import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

// antd
import { Col, Row, Select, Spin, Typography, theme, Image, Space } from 'antd'

// slices
import {
    changePageList,
    masterDataListGetDropdown,
    masterDataState,
} from './masterDataPanel.slice'

// helpers
import { unique } from '../../helpers/uniqueArray'

const DeleteModalMasterData = ({ modelType, replaceId }) => {
    const { Text } = Typography

    const { Option } = Select

    const { useToken } = theme
    const { token } = useToken()

    const dispatch = useDispatch()

    const params = useLocation()

    const {
        pageLimitDropdownList,
        isNextDropdownList,
        currentPageDropdownList,
        skipPageDropdownList,
        masterDataDropdownList,
    } = useSelector(masterDataState)

    const [optionLoading, setOptionLoading] = useState(false)
    const [filteredMasterData, setFilteredMasterData] = useState([])

    useEffect(() => {
        dispatch(
            masterDataListGetDropdown({
                limit: pageLimitDropdownList,
                skip: skipPageDropdownList,
                filter: { isActive: true },
                id:
                    modelType?.length > 0
                        ? modelType
                        : params.pathname.split('/')[3],
            })
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (masterDataDropdownList?.length > 0) {
            let array = []
            masterDataDropdownList?.length > 0 &&
                masterDataDropdownList.forEach((data) => {
                    array = [
                        ...array,
                        {
                            label: data?.name,
                            value: data?.name,
                            _id: data?._id,
                            logo: data?.logo,
                        },
                    ]
                })
            let newArray = [...filteredMasterData, ...array]
            let finalArray = unique(newArray, '_id')
            setFilteredMasterData(finalArray)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [masterDataDropdownList])

    // FETCH MORE PROJECTS
    async function fetchMoreMasterData(e) {
        let scroll =
            Math.ceil(e.target.offsetHeight + e.target.scrollTop) >=
            e.target.scrollHeight
        if (scroll) {
            if (isNextDropdownList) {
                await dispatch(
                    masterDataListGetDropdown({
                        skip: skipPageDropdownList + pageLimitDropdownList,
                        limit: pageLimitDropdownList,
                        filter: { isActive: true },
                        id:
                            modelType?.length > 0
                                ? modelType
                                : params.pathname.split('/')[3],
                    })
                )
                dispatch(
                    changePageList({
                        currentPage: currentPageDropdownList + 1,
                        skip: skipPageDropdownList + pageLimitDropdownList,
                        pageLimit: pageLimitDropdownList,
                    })
                )
                setOptionLoading(false)
            }
        }
    }

    return (
        <>
            <Row
                style={{
                    marginBottom: 10,
                }}
            >
                <Text
                    style={{
                        fontSize: '16px',
                    }}
                >
                    {`Select ${modelType} you need to replace with selected Data`}
                </Text>
            </Row>
            <Text
                style={{
                    fontSize: token.fontSizeHeading5,
                }}
            >
                Replace By
            </Text>
            <Row>
                <Col span={24}>
                    <Select
                        placeholder={`Search and Select ${modelType}`}
                        showSearch
                        notFoundContent={
                            optionLoading ? <Spin size="small" /> : null
                        }
                        allowClear={false}
                        onPopupScroll={fetchMoreMasterData}
                        dropdownStyle={{
                            padding: '5px',
                        }}
                        getPopupContainer={(trigger) => trigger.parentNode}
                        onChange={(_, label) => {
                            replaceId(label?.key)
                        }}
                        style={{
                            marginTop: '14px',
                            width: '100%',
                        }}
                        rootClassName="masterDataSelect"
                    >
                        {filteredMasterData?.length > 0 &&
                            filteredMasterData.map((option) => {
                                return (
                                    <Option
                                        value={option?.value}
                                        key={option?._id}
                                    >
                                        <Row
                                            key={option?.value}
                                            align={'middle'}
                                            className="rowMasterData"
                                        >
                                            <Space size={'large'}>
                                                {modelType?.name !== 'skills' &&
                                                    modelType?.name !==
                                                        'degree' &&
                                                    modelType?.name !==
                                                        'fieldOfStudy' &&
                                                    modelType?.name !==
                                                        'designation' &&
                                                    modelType?.name !==
                                                        'employmentType' && (
                                                        <div
                                                            style={{
                                                                border: `1px solid ${token.colorPalette.iconColor.primary}`,
                                                                height: '30px',
                                                                width: '30px',

                                                                display: 'flex',
                                                                alignItems:
                                                                    'center',
                                                                justifyContent:
                                                                    'center',
                                                            }}
                                                        >
                                                            <Image
                                                                src={
                                                                    option?.logo
                                                                }
                                                                height={'20px'}
                                                                preview={false}
                                                                width={'20px'}
                                                            />
                                                        </div>
                                                    )}
                                                <Text
                                                    style={{
                                                        margin: '0px 3px 0px 0px',
                                                    }}
                                                >
                                                    {option?.label}
                                                </Text>
                                            </Space>
                                        </Row>
                                    </Option>
                                )
                            })}
                    </Select>
                </Col>
            </Row>
        </>
    )
}

export default DeleteModalMasterData
