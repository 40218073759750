import React from 'react'

function BackIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 24 24"
        >
            <path
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="1.5"
                d="M7.13 18.309h8c2.76 0 5-2.24 5-5s-2.24-5-5-5h-11"
            ></path>
            <path
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M6.43 10.811l-2.56-2.56 2.56-2.56"
            ></path>
        </svg>
    )
}

export default BackIcon
