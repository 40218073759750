import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// antd
import { Modal, Typography, theme } from 'antd'

// slices
import {
    changePage,
    clearFilter,
    filterChange,
    searchTalent,
    switchTalentModal,
    talentListGet,
    talentState,
    totalTalent,
} from './talent.slice'

// components
import PanelHeader from '../../components/panelHeader/panelHeader'
import ModalComponent from '../../components/modal/modalComponent'
import WorkspaceAssignModal from './workspaceAssignModal'
import GridViewButton from '../../components/gridView'

// modal content
import WorkSpaceUnAssignModal from './workspaceUnAssignModal'
import TalentModalContent from './talentModalContent'

// assets
import {
    ActiveIcon,
    InActiveIcon,
    NonVerifyTalentIcon,
    TeamIcon,
    VerifyTalentIcon,
} from '../../assets/icons'

// helpers
import { unique } from '../../helpers/uniqueArray'

// talent pages
import AddRates from './addRatesForm'
import TalentView from './talentView'
import { TALENT_FILTER_STATUS } from '../../constants/general'

function Talent() {
    const { useToken } = theme
    const { token } = useToken()

    const { Text } = Typography

    const dispatch = useDispatch()

    const {
        modalVariations,
        pageLimit,
        isNext,
        search,
        selectedData,
        talentList,
        filter,
    } = useSelector(talentState)

    const initialState = {
        talentView: 'list',
        filteredTalentData: [],
        assignModal: false,
        unAssignModal: false,
        addRatesModal: false,
    }

    // STATE
    const [state, setState] = useState(initialState)

    // CHECK FOR LAZY LOADING IS_NEXT
    useEffect(() => {
        if (talentList?.length > 0) {
            let array = []
            talentList?.length > 0 &&
                talentList.forEach((data) => {
                    array = [...array, data]
                })
            let newArray = [...state?.filteredTalentData, ...array]
            let finalArray = unique(newArray, '_id')
            setState((prevState) => ({
                ...prevState,
                filteredTalentData: finalArray,
            }))
            if (!isNext) {
                return
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [talentList])

    // REFRESH PAGE
    function refreshPage() {
        setState((prevState) => ({
            ...prevState,
            filteredTalentData: [],
        }))
        dispatch(
            changePage({
                currentPage: 1,
                skip: 0,
                pageLimit,
            })
        )
    }

    const initialTalentCall = (search) => {
        setState((prevState) => ({
            ...prevState,
            filteredTalentData: [],
        }))
        dispatch(
            changePage({
                currentPage: 1,
                skip: 0,
                pageLimit: state?.talentView === 'grid' ? pageLimit : 10,
            })
        )
        dispatch(
            totalTalent({
                search: search,
                filter: { ...filter, status: TALENT_FILTER_STATUS },
            })
        )
        dispatch(
            talentListGet({
                limit: state?.talentView === 'grid' ? pageLimit : 10,
                skip: 0,
                search: search,
                filter: { ...filter, status: TALENT_FILTER_STATUS },
            })
        )
    }

    // GET INITIAL TALENT LIST
    useEffect(() => {
        initialTalentCall('')
        dispatch(searchTalent(''))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const rootSubmenuKeys = ['verification', 'status']

    const filterType = [
        {
            key: 'verification',
            label: 'Verification',
            children: [
                {
                    key: 'verified',
                    label: 'Yes',
                    name: 'isOurUser',
                    flag: true,
                    icon: (
                        <div
                            style={{
                                height: '20px',
                                width: '20px',
                            }}
                        >
                            <VerifyTalentIcon />
                        </div>
                    ),
                },
                {
                    key: 'nonVerified',
                    label: 'No',
                    name: 'isOurUser',
                    flag: false,
                    icon: (
                        <div
                            style={{
                                height: '20px',
                                width: '20px',
                            }}
                        >
                            <NonVerifyTalentIcon />
                        </div>
                    ),
                },
            ],
        },
        {
            key: 'status',
            label: 'Status',
            children: [
                {
                    label: 'Active',
                    name: 'isActive',
                    key: 'active',
                    flag: true,
                    icon: (
                        <div
                            style={{
                                height: '20px',
                                width: '20px',
                            }}
                        >
                            <ActiveIcon />
                        </div>
                    ),
                },
                {
                    label: 'InActive',
                    name: 'isActive',
                    key: 'inActive',
                    flag: false,
                    icon: (
                        <div
                            style={{
                                height: '20px',
                                width: '20px',
                            }}
                        >
                            <InActiveIcon />
                        </div>
                    ),
                },
            ],
        },
        {
            label: (
                <Text
                    style={{
                        color: token.colorPalette.baseColor.error,
                    }}
                >
                    Clear Filter
                </Text>
            ),
            name: 'clear',
        },
    ]

    const GridViewComponent = () => (
        <GridViewButton
            props={{
                onClickButton: () => initialTalentCall(search),
                viewList: state?.talentView,
                setView: (key) => {
                    setState((prevState) => ({ ...prevState, talentView: key }))
                },
            }}
        />
    )

    return (
        <>
            <div
                className={'container'}
                style={{
                    zIndex: 10,
                    minHeight: '100vh',
                    padding: '35px',
                }}
            >
                <>
                    <PanelHeader
                        props={{
                            panelName: 'add talent',
                            panelHeaderName: 'Talent',
                            searchBar: true,
                            filterBar: true,
                            teamMembersBar: false,
                            permissionAdd: true,
                            gridView: true,
                            gridViewComponent: () => <GridViewComponent />,
                            rootSubmenuKeys,
                            filterType,
                            count: totalTalent,
                            action: talentListGet,
                            search: searchTalent,
                            changeFilter: filterChange,
                            clearFilter: clearFilter,
                            pageLimit: pageLimit,
                            filterValue: {
                                ...filter,
                                status: TALENT_FILTER_STATUS,
                            },

                            changePageGrid: () => {
                                dispatch(
                                    changePage({
                                        currentPage: 1,
                                        skip: 0,
                                        pageLimit: 10,
                                    })
                                )
                            },
                            clearData: () =>
                                setState((prevState) => ({
                                    ...prevState,
                                    filteredTalentData: [],
                                })),
                            refreshPage: refreshPage,
                            onClick: () => {
                                dispatch(
                                    switchTalentModal({
                                        ...modalVariations,
                                        open: true,
                                    })
                                )
                            },
                        }}
                    />
                    <TalentView
                        props={{
                            talentView: state?.talentView,
                            filteredTalentData: state?.filteredTalentData,
                            initialTalentCall: () => initialTalentCall(search),
                            filteredData: () =>
                                setState((prevState) => ({
                                    ...prevState,
                                    filteredTalentData: [],
                                })),
                        }}
                    />
                </>
            </div>
            <ModalComponent
                visibility={modalVariations?.open}
                content={
                    <TalentModalContent
                        refreshTalent={() => {
                            setState((prevState) => ({
                                ...prevState,
                                filteredTalentData: [],
                            }))
                            dispatch(
                                changePage({
                                    currentPage: 1,
                                    skip: 0,
                                    pageLimit,
                                })
                            )
                        }}
                    />
                }
                modalTitle={'Talent'}
                showTitle={'Talent'}
                icon={<TeamIcon color={token.colorPalette.baseColor.white} />}
                buttonName="Save"
                masterDataState={modalVariations}
                selectedDataMaster={selectedData}
                handleCancel={() => {
                    dispatch(
                        switchTalentModal({
                            ...modalVariations,
                            open: false,
                        })
                    )
                }}
            />
            <Modal
                closable={false}
                open={state?.assignModal}
                onCancel={() => {
                    setState((prevState) => ({
                        ...prevState,
                        assignModal: false,
                    }))
                }}
                destroyOnClose={true}
                footer={null}
                centered
                title={null}
            >
                <WorkspaceAssignModal
                    cancelModal={() =>
                        setState((prevState) => ({
                            ...prevState,
                            assignModal: false,
                        }))
                    }
                    refreshTalent={() => initialTalentCall(search)}
                />
            </Modal>
            <Modal
                centered
                open={state?.addRatesModal}
                footer={null}
                title={null}
                closable={false}
                destroyOnClose={true}
                width={900}
                styles={{
                    body: {
                        padding: '25px',
                    },
                }}
                onCancel={() => {
                    setState((prevState) => ({
                        ...prevState,
                        addRatesModal: false,
                    }))
                }}
            >
                <AddRates
                    onCancel={() =>
                        setState((prevState) => ({
                            ...prevState,
                            addRatesModal: false,
                        }))
                    }
                />
            </Modal>
            <Modal
                closable={false}
                open={state?.unAssignModal}
                onCancel={() => {
                    setState((prevState) => ({
                        ...prevState,
                        unAssignModal: false,
                    }))
                }}
                destroyOnClose={true}
                footer={null}
                centered
                title={null}
                width={'430px'}
                styles={{
                    body: {
                        padding: '30px 0px',
                    },
                }}
            >
                <WorkSpaceUnAssignModal
                    cancelModal={() =>
                        setState((prevState) => ({
                            ...prevState,
                            unAssignModal: false,
                        }))
                    }
                    refreshTalent={() => initialTalentCall(search)}
                />
            </Modal>
        </>
    )
}

export default Talent
