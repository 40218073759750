import axiosNew from '../../helpers/axios'

// UPDATE SUMMARY
export const createSummary = async (payload) => {
    return new Promise(async (resolve) => {
        try {
            let data = payload?.summary ?? {}
            const update = await axiosNew({
                url: 'users/update/' + payload._id,
                data,
            })
            resolve({ data: update?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// GET UPDATE USER PROFILE
export const getUserUpdate = async (payload) => {
    return new Promise(async (resolve) => {
        try {
            const getUser = await axiosNew({
                url: 'users/get/' + payload._id,
                method: 'get',
            })
            resolve({ data: getUser?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data?.data ?? {} })
        }
    })
}

// GET PARTICULAR TALENT
export const getTalentProfile = async (payload) => {
    return new Promise(async (resolve) => {
        const { userId } = payload
        try {
            const talentData = await axiosNew({
                url: 'inquiry-role/get-talent/' + userId,
                method: 'GET',
            })
            resolve({ data: talentData?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// GET DELETE USER PROFILE
export const getUserUpdatedData = async (payload) => {
    return new Promise(async (resolve) => {
        try {
            let data = payload?.editData ?? {}
            const getUser = await axiosNew({
                url: '/users/read/step/' + payload._id,
                data,
            })
            resolve({ data: getUser?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// GET DELETE USER PROFILE
export const getUserDeleteData = async (payload) => {
    return new Promise(async (resolve) => {
        try {
            let data = payload?.deleteData ?? {}
            const getUser = await axiosNew({
                url: '/users/delete/step/' + payload._id,
                data,
            })
            resolve({ data: getUser?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// LIST MASTER DATA
export const listMasterDataDropDown = async (payload) => {
    const { search = '', skip = 0, limit = 0, filter = {}, id } = payload
    return new Promise(async (resolve) => {
        try {
            let data = {
                skip,
                filter,
                limit,
                select: [
                    'name',
                    'isActive',
                    'logo',
                    'createdBy',
                    'isActive',
                    'createdAt',
                    'updatedAt',
                ],
                populate: {
                    name: 'createdBy',
                    val: ['name', 'profilePicture'],
                },
            }
            if (search?.length > 0) {
                data['search'] = {
                    name: search,
                }
            }
            const listData = await axiosNew({
                url: 'master-data/list/' + id,
                data,
            })
            resolve({ data: listData?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// ADD MASTER DATA
export const addMasterDataDropDown = async (payload) => {
    return new Promise(async (resolve) => {
        try {
            let data = payload?.addData ?? {}
            const addData = await axiosNew({
                url: 'master-data/add/' + payload.model,
                data,
            })
            resolve({ data: addData?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

export const favIconGet = async (payload) => {
    return new Promise(async (resolve) => {
        try {
            const getIconData = await axiosNew({
                url: 'users/get-icon?url=' + payload,
                method: 'get',
            })
            resolve({ data: getIconData?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}
