import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// antd
import {
    Avatar,
    Badge,
    Dropdown,
    Empty,
    Image,
    Pagination,
    Row,
    Spin,
    Table,
    Typography,
    message,
    theme,
} from 'antd'
import { EllipsisOutlined } from '@ant-design/icons'

// slices
import {
    changePage,
    masterDataItemGet,
    masterDataListCount,
    masterDataListGet,
    masterDataState,
    masterDataUpdate,
    searchData,
    switchMasterDataModal,
} from './masterDataPanel.slice'

// assets
import {
    ActiveIcon,
    InActiveIcon,
    InstituteIcon,
    ViewIcon,
} from '../../assets/icons'

// master-data components
import MasterDataHeader from './masterDataHeader'
import MasterDataModelContent from './masterDataModelContent'
import ModalComponent from '../../components/modal/modalComponent'

function MasterDataItem() {
    const { Text } = Typography
    const { useToken } = theme
    const { token } = useToken()

    const dispatch = useDispatch()

    const params = useLocation()

    const {
        status,
        search,
        masterDataList,
        dataCount,
        pageLimit,
        skipPage,
        currentPage,
        isNext,
        modelType,
        filter,
        modalVariations,
        selectedData,
    } = useSelector(masterDataState)

    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [selectedRowData, setSelectedRowData] = useState([])

    async function updateStatus(id, statusActive) {
        const result = await dispatch(
            masterDataUpdate({
                editData: {
                    isActive: !statusActive,
                },
                _id: id,
                model: modelType?.name,
            })
        )
        const data = result?.payload?.data
        if (data) {
            const { success, message: checkMessage } = data
            if (success) {
                dispatch(
                    masterDataListCount({
                        _id:
                            modelType?.name?.length > 0
                                ? modelType?.name
                                : params.pathname.split('/')[3],
                        filter: { isActive: filter.isActive },
                    })
                )
                dispatch(
                    masterDataListGet({
                        limit: pageLimit,
                        skip: skipPage,
                        search: search,
                        id:
                            modelType?.name?.length > 0
                                ? modelType?.name
                                : params.pathname.split('/')[3],
                    })
                )
                message.success(checkMessage)
                // if (currentUser === 1 && currentPage !== 1) {
                //     dispatch(
                //         await usersList({
                //             skip: skipPage - pageLimit,
                //             limit: pageLimit,
                //         })
                //     )
                // } else {
                //     dispatch(
                //         await usersList({
                //             skip: skipPage,
                //             limit: pageLimit,
                //         })
                //     )
                // }
            } else {
                if (checkMessage) {
                    message.error(checkMessage)
                } else {
                    message.error('Something went wrong, try again later.')
                }
            }
        }
    }

    const onSelectChange = (newSelectedRowKeys, data) => {
        setSelectedRowKeys(newSelectedRowKeys)
        setSelectedRowData(data)
    }
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    }

    const columns = [
        {
            key: 'name',
            dataIndex: 'name',
            title: `${modelType?.name} Name`,
            render: (_, item) => (
                <Row
                    align={'middle'}
                    style={{
                        columnGap: '16px',
                    }}
                >
                    {modelType?.name !== 'skills' &&
                        modelType?.name !== 'degree' &&
                        modelType?.name !== 'fieldOfStudy' &&
                        modelType?.name !== 'designation' &&
                        modelType?.name !== 'employmentType' && (
                            <div
                                style={{
                                    border: `1px solid ${token.colorPalette.iconColor.primary}`,
                                    height: '40px',
                                    width: '40px',
                                    borderRadius: '50%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <Image
                                    src={item?.logo}
                                    height={'30px'}
                                    preview={false}
                                    width={'30px'}
                                    style={{
                                        borderRadius: '50%',
                                    }}
                                />
                            </div>
                        )}
                    <Text>{item?.name}</Text>
                </Row>
            ),
        },
        {
            title: 'Created Date',
            key: 'updatedAt',
            dataIndex: 'updatedAt',
            render: (val) => {
                const date = new Date(val)

                return (
                    <div style={{ fontWeight: 500 }}>
                        {date.getDate() +
                            '-' +
                            date.getMonth() +
                            1 +
                            '-' +
                            date.getFullYear()}
                    </div>
                )
            },
        },
        {
            key: 'createdBy',
            dataIndex: 'createdBy',
            title: 'Created By',
            render: (_, item) => (
                <Row
                    align={'middle'}
                    style={{ columnGap: '10px', textTransform: 'capitalize' }}
                >
                    {item?.createdBy === null ? (
                        <Avatar src="https://xsgames.co/randomusers/avatar.php?g=pixel&key=1" />
                    ) : item?.createdBy?.profilePicture ? (
                        <Avatar
                            size={30}
                            src={item?.createdBy?.profilePicture}
                        ></Avatar>
                    ) : (
                        <Avatar size={'small'}>
                            {item?.createdBy?.name[0].toUpperCase()}
                            {item?.createdBy?.name.split(' ')[1] !==
                                undefined &&
                                item?.createdBy?.name
                                    .split(' ')[1][0]
                                    .toUpperCase()}
                        </Avatar>
                    )}
                    <Text>
                        {item?.createdBy === null
                            ? 'Other Workspace User'
                            : item?.createdBy?.name}
                    </Text>
                </Row>
            ),
        },
        {
            key: 'isActive',
            dataIndex: 'isActive',
            title: 'Status',
            render: (_, item) => (
                <Badge
                    count={item?.isActive ? 'Active' : 'InActive'}
                    color={
                        item?.isActive
                            ? 'hsla(155, 54%, 46%, 0.16)'
                            : 'hsla(11, 100%, 59%, 0.16)'
                    }
                    style={{
                        minWidth: '30px',
                        cursor: 'auto',
                        borderRadius: '6px',
                        display: 'flex',
                        alignItems: 'center',
                        color: item?.isActive
                            ? token.colorPalette.iconColor.tertiary
                            : token.colorPalette.iconColor.quaternary,
                        padding: '12px 8px',
                        fontWeight: 700,
                    }}
                />
            ),
        },
        {
            key: 'menu',
            dataIndex: 'menu',
            title: '  ',
            render: (_, item) => {
                return (
                    <Row justify="end">
                        <Dropdown
                            trigger={['click']}
                            placement={'bottomRight'}
                            menu={{
                                items: [
                                    {
                                        key: '1',
                                        label: (
                                            <Text
                                                style={{
                                                    fontSize:
                                                        token.fontSizeHeading5,
                                                }}
                                            >
                                                View / Edit
                                            </Text>
                                        ),
                                        icon: (
                                            <div
                                                style={{
                                                    height: '22px',
                                                    width: '22px',
                                                    marginRight: '16px',
                                                }}
                                            >
                                                <ViewIcon />
                                            </div>
                                        ),
                                        onClick: () => {
                                            // getUserDetail(row?._id)
                                            dispatch(
                                                masterDataItemGet({
                                                    model: modelType?.name,
                                                    _id: item?._id,
                                                })
                                            )
                                            dispatch(
                                                switchMasterDataModal({
                                                    ...modalVariations,
                                                    open: true,
                                                })
                                            )
                                        },
                                    },
                                    {
                                        key: '2',
                                        icon: (
                                            <div
                                                style={{
                                                    height: '22px',
                                                    marginRight: '16px',
                                                    width: '22px',
                                                }}
                                            >
                                                {item?.isActive ? (
                                                    <InActiveIcon />
                                                ) : (
                                                    <ActiveIcon />
                                                )}
                                            </div>
                                        ),
                                        label: (
                                            <Text
                                                style={{
                                                    fontSize:
                                                        token.fontSizeHeading5,
                                                    color:
                                                        item?.isActive &&
                                                        token.colorPalette
                                                            .baseColor.error,
                                                }}
                                            >
                                                {item?.isActive
                                                    ? 'InActive'
                                                    : 'Active'}
                                            </Text>
                                        ),
                                        onClick: () => {
                                            updateStatus(
                                                item?._id,
                                                item?.isActive
                                            )
                                        },
                                    },
                                ],
                            }}
                        >
                            <EllipsisOutlined rotate={90} />
                        </Dropdown>
                    </Row>
                )
            },
        },
    ]

    return (
        <>
            <div
                // className={'container'}
                style={{
                    zIndex: 10,
                    minHeight: '100vh',
                    padding: '40px 25px 40px 25px',
                }}
            >
                <MasterDataHeader
                    props={{
                        onClickButton: () => {
                            dispatch(
                                switchMasterDataModal({
                                    ...modalVariations,
                                    open: true,
                                })
                            )
                        },
                        search: searchData,
                        count: masterDataListCount,
                        pageLimit: pageLimit,
                        skipPage: skipPage,
                        filterDropDown: filter,
                        selectedRowKeys: selectedRowData,
                        searchValue: search,
                        modelType: modelType,
                        setSelectedRow: () => {
                            setSelectedRowData([])
                            setSelectedRowKeys([])
                        },
                    }}
                />
                <Spin spinning={status === 'loading' ? true : false}>
                    {masterDataList?.length > 0 ? (
                        <Table
                            dataSource={masterDataList}
                            scroll={{
                                x: 'scroll',
                            }}
                            rootClassName="tableGlobal"
                            style={{
                                borderRadius: '16px',
                            }}
                            columns={columns}
                            pagination={false}
                            rowSelection={rowSelection}
                        />
                    ) : (
                        status !== 'loading' && (
                            <Empty
                                description={'No Data Fount'}
                                style={{ margin: 'auto' }}
                            />
                        )
                    )}
                </Spin>
                {masterDataList?.length > 0 && (
                    <Row
                        justify={'end'}
                        align={'middle'}
                        style={{
                            border: `2px solid ${token.colorPalette.baseColor.quaternary}`,
                            padding: '25px',
                            borderRadius: '0px 0px 8px 8px',
                        }}
                    >
                        <Text>
                            {masterDataList?.length} - {pageLimit} of{' '}
                            {dataCount}
                        </Text>
                        <Pagination
                            defaultCurrent={currentPage}
                            defaultPageSize={pageLimit}
                            pageSizeOptions={[5, 10, 20, 50]}
                            showSizeChanger={true}
                            total={
                                isNext === false &&
                                // filterView &&
                                currentPage === 1
                                    ? masterDataList?.length
                                    : dataCount
                            }
                            onChange={async (value, pageSize) => {
                                await dispatch(
                                    changePage({
                                        currentPage: value,
                                        skip: (value - 1) * pageLimit,
                                        pageLimit: pageSize,
                                    })
                                )
                                const dropDownValue = {
                                    ...filter,
                                }
                                Object.keys(dropDownValue).forEach((key) => {
                                    if (dropDownValue[key] === '') {
                                        delete dropDownValue[key]
                                    }
                                })
                                await dispatch(
                                    masterDataListGet({
                                        skip: (value - 1) * pageSize,
                                        limit: pageSize,
                                        filter: dropDownValue,
                                        id:
                                            modelType?.name?.length > 0
                                                ? modelType?.name
                                                : params.pathname.split('/')[3],
                                    })
                                )
                            }}
                        />
                    </Row>
                )}
            </div>
            <ModalComponent
                visibility={modalVariations?.open}
                content={<MasterDataModelContent modalType={modelType?.name} />}
                modalTitle={modelType?.name}
                showTitle={modelType?.name}
                icon={
                    <InstituteIcon color={token.colorPalette.baseColor.white} />
                }
                buttonName="Save"
                masterDataState={modalVariations}
                selectedDataMaster={selectedData}
                handleCancel={() => {
                    dispatch(
                        switchMasterDataModal({
                            ...modalVariations,
                            open: false,
                        })
                    )
                }}
            />
        </>
    )
}

export default MasterDataItem
