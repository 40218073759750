import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

// antd
import {
    Avatar,
    Badge,
    Dropdown,
    Empty,
    Image,
    message,
    Modal,
    Pagination,
    Row,
    Spin,
    Table,
    theme,
    Typography,
} from 'antd'
import { EllipsisOutlined } from '@ant-design/icons'

// slices
import {
    asCurrentProject,
    changePage,
    clearFilterDropDown,
    clearProjects,
    defaultTab,
    projectGet,
    projectsList,
    projectsState,
    projectStepData,
    searchProjects,
    setCurrentProject,
    switchFilter,
    switchFormModal,
    totalProjects,
} from './projects.slice'
import { loginState } from '../login/login.slice'
import { teamListGet, teamState, totalTeam } from '../team/team.slice'

// section
import FormModal from './form.section'

// components
import PanelHeader from '../../components/panelHeader/panelHeader'
import NoProjectsAssign from './noProjectsAssign'

// helpers
import { unique } from '../../helpers/uniqueArray'
import { addPermissionCheck } from '../../helpers/permissionCheck'

// assets
import { EditIcon } from '../../assets/icons'
import { PRIVATE_ROUTES } from '../../routes'

const Projects = () => {
    const { Text } = Typography

    const { useToken } = theme
    const { token } = useToken()

    const dispatch = useDispatch()

    const navigate = useNavigate()

    // SELECTORS
    const { userProfile } = useSelector(loginState)
    const {
        status,
        projects,
        projectsCount,
        formModal,
        pageLimit,
        currentPage,
        isNext,
    } = useSelector(projectsState)
    const { pageLimit: teamPageLimit } = useSelector(teamState)

    // STATES
    const [filteredProjects, setFilteredProjects] = useState([])
    const [projectDisable, setProjectDisable] = useState(false)

    // GET TOTAL PROJECTS COUNT AND PROJECTS LIST
    useEffect(() => {
        dispatch(
            changePage({
                currentPage: 1,
                skip: 0,
                pageLimit,
            })
        )
        dispatch(totalProjects())
        dispatch(projectsList({ limit: pageLimit, skip: 0 }))
        if (userProfile?.activeProject !== null) {
            dispatch(
                totalTeam({
                    projectId: userProfile?.activeProject?._id,
                })
            )
            dispatch(
                teamListGet({
                    limit: teamPageLimit,
                    skip: 0,
                    projectId: userProfile?.activeProject?._id,
                })
            )
        }
        dispatch(switchFilter(false))
        dispatch(clearFilterDropDown())
        dispatch(defaultTab('overview'))

        return () => dispatch(clearProjects())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // CHECK FOR LAZY LOADING IS_NEXT
    useEffect(() => {
        if (projects?.length > 0) {
            let array = []
            projects?.length > 0 &&
                projects.forEach((data) => {
                    array = [...array, data]
                })
            let newArray = [...filteredProjects, ...array]
            let finalArray = unique(newArray, '_id')
            setFilteredProjects(finalArray)

            if (!isNext) {
                return
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projects])

    // SELECT CURRENT PROJECT
    const currentProject = async (id, name) => {
        setProjectDisable(true)
        const result = await dispatch(
            asCurrentProject({
                id: id,
                project: {
                    isActive: true,
                },
            })
        )
        const data = result?.payload?.data
        if (data) {
            const { success, message: checkMessage } = data
            if (success) {
                dispatch(
                    changePage({
                        currentPage: 1,
                        skip: 0,
                        pageLimit,
                    })
                )
                localStorage.setItem(
                    'currentProject',
                    JSON.stringify({ id: id, name: name })
                )

                localStorage.setItem('currentSprint', JSON.stringify({}))
                message.success(checkMessage)
                setTimeout(() => {
                    setProjectDisable(false)
                    navigate(PRIVATE_ROUTES.overview)
                    window.location.reload()
                }, 1000)
            } else {
                setProjectDisable(false)
                if (checkMessage) {
                    message.error(checkMessage)
                } else {
                    message.error('Something went wrong, try again later.')
                }
            }
        }
        dispatch(
            setCurrentProject({
                id: id,
                name: name,
            })
        )
    }

    const columns = [
        {
            title: 'Name',
            key: 'name',
            dataIndex: 'name',
            render: (val, item) => (
                <Row
                    align={'middle'}
                    style={{
                        columnGap: '16px',
                        cursor: projectDisable ? 'not-allowed' : 'pointer',
                    }}
                    onClick={() => {
                        if (
                            userProfile?.activeProject?._id !== item?._id &&
                            !projectDisable
                        ) {
                            currentProject(item?._id, item?.name)
                        }
                    }}
                >
                    <Image
                        src={item?.projectProfile}
                        height={'30px'}
                        preview={false}
                        width={'30px'}
                        style={{
                            borderRadius: '50%',
                            objectFit: 'cover',
                        }}
                    />
                    <Text>{val}</Text>
                    {userProfile?.activeProject?._id === item?._id && (
                        <Badge
                            count={'Active'}
                            color={'hsla(155, 54%, 46%, 0.16)'}
                            style={{
                                minWidth: '30px',
                                cursor: 'auto',
                                borderRadius: '6px',
                                display: 'flex',
                                alignItems: 'center',
                                color: token.colorPalette.iconColor.tertiary,
                                padding: '12px 8px',
                                fontWeight: 700,
                            }}
                        />
                    )}
                </Row>
            ),
        },
        {
            key: 'projectKey',
            dataIndex: 'projectKey',
            title: 'Key',
            render: (key) => (
                <Row style={{ paddingRight: 5, textTransform: 'capitalize' }}>
                    {key || 'NA'}
                </Row>
            ),
        },
        {
            key: 'projectType',
            dataIndex: 'projectType',
            title: 'Type',
            render: (val) => (
                <div style={{ fontWeight: 500 }}>{val || 'NA'}</div>
            ),
        },
        {
            key: 'lead',
            dataIndex: 'lead',
            title: 'Lead',
            render: (val, item) => (
                <Row
                    style={{ fontWeight: 500, columnGap: '10px' }}
                    align={'middle'}
                >
                    {item?.projectLead?.profilePicture ? (
                        <Image
                            src={item?.projectLead?.profilePicture}
                            width={40}
                            preview={false}
                            height={40}
                            style={{
                                borderRadius: '50%',
                                objectFit: 'cover',
                            }}
                        />
                    ) : (
                        <Avatar
                            style={{
                                width: '40px',
                                height: '40px',
                            }}
                        >
                            {item?.projectLead?.name[0].toUpperCase()}
                            {item?.projectLead?.name.split(' ')[1] !==
                                undefined &&
                                item?.projectLead?.name
                                    .split(' ')[1][0]
                                    .toUpperCase()}
                        </Avatar>
                    )}
                    <Text>{item?.projectLead?.name}</Text>
                </Row>
            ),
        },
        {
            key: 'context',
            dataIndex: 'context',
            title: '  ',
            render: (_, row) => (
                <Row justify="end" style={{ paddingRight: 5 }}>
                    <Dropdown
                        trigger={['click']}
                        placement={'bottomRight'}
                        menu={{
                            items: [
                                {
                                    key: '1',
                                    label: 'Edit',
                                    icon: (
                                        <div
                                            style={{
                                                height: '20px',
                                                width: '20px',
                                            }}
                                        >
                                            <EditIcon />
                                        </div>
                                    ),
                                    style: {
                                        columnGap: '5px',
                                    },
                                    onClick: () => {
                                        dispatch(
                                            switchFormModal({ open: true })
                                        )
                                        dispatch(projectGet({ id: row?._id }))
                                    },
                                },
                            ],
                        }}
                    >
                        <EllipsisOutlined rotate={90} />
                    </Dropdown>
                </Row>
            ),
        },
    ]

    const refreshProject = () => {
        setFilteredProjects([])
        dispatch(
            changePage({
                currentPage: 1,
                skip: 0,
                pageLimit,
            })
        )
    }

    return (
        <>
            <Modal
                centered
                footer={null}
                closable={false}
                open={formModal}
                destroyOnClose={true}
                width={700}
                styles={{
                    body: {
                        padding: '25px',
                    },
                }}
                onCancel={() => {
                    dispatch(switchFormModal({ open: false }))
                    dispatch(projectStepData())
                }}
            >
                <FormModal
                    onCancel={() => {
                        dispatch(switchFormModal({ open: false }))
                        dispatch(projectStepData())
                    }}
                    refreshProject={async () => {
                        refreshProject()
                    }}
                />
            </Modal>
            <div
                className={'container'}
                style={{
                    zIndex: 10,
                    minHeight: '100vh',
                    padding: '35px',
                }}
            >
                <>
                    {userProfile?.activeProject === null ? (
                        <NoProjectsAssign
                            props={{
                                filteredProjects: filteredProjects,
                                currentProject: (id, name) =>
                                    currentProject(id, name),
                            }}
                        />
                    ) : (
                        <>
                            <PanelHeader
                                props={{
                                    panelName: 'create project',
                                    searchBar: true,
                                    search: searchProjects,
                                    teamMembersBar: false,
                                    count: totalProjects,
                                    action: projectsList,
                                    pageLimit: pageLimit,
                                    permissionAdd: addPermissionCheck(
                                        'projects',
                                        userProfile
                                    ),
                                    refreshPage: () => refreshProject,
                                    onClick: () => {
                                        dispatch(
                                            switchFormModal({ open: true })
                                        )
                                    },
                                }}
                            />
                            <Spin
                                spinning={status === 'loading' ? true : false}
                            >
                                {projects?.length > 0 ? (
                                    <Table
                                        dataSource={projects}
                                        scroll={{
                                            x: 'scroll',
                                        }}
                                        rootClassName="tableGlobal"
                                        style={{
                                            borderRadius: '16px',
                                        }}
                                        columns={columns}
                                        pagination={false}
                                    />
                                ) : (
                                    status !== 'loading' && (
                                        <Empty
                                            description={'No Projects  Found'}
                                            style={{ margin: 'auto' }}
                                        />
                                    )
                                )}
                                {projects?.length > 0 && (
                                    <Row
                                        justify={'end'}
                                        align={'middle'}
                                        style={{
                                            border: `2px solid ${token.colorPalette.baseColor.quaternary}`,
                                            padding: '25px',
                                            borderRadius: '0px 0px 8px 8px',
                                        }}
                                    >
                                        <Text>
                                            {projects?.length} - {pageLimit} of{' '}
                                            {projectsCount}
                                        </Text>
                                        <Pagination
                                            defaultCurrent={currentPage}
                                            defaultPageSize={pageLimit}
                                            pageSizeOptions={[5, 10, 20, 50]}
                                            showSizeChanger={true}
                                            total={
                                                isNext === false &&
                                                currentPage === 1
                                                    ? projects?.length
                                                    : projectsCount
                                            }
                                            onChange={async (
                                                value,
                                                pageSize
                                            ) => {
                                                await dispatch(
                                                    changePage({
                                                        currentPage: value,
                                                        skip:
                                                            (value - 1) *
                                                            pageLimit,
                                                        pageLimit: pageSize,
                                                    })
                                                )
                                                await dispatch(
                                                    projectsList({
                                                        skip:
                                                            (value - 1) *
                                                            pageSize,
                                                        limit: pageSize,
                                                    })
                                                )
                                            }}
                                        />
                                    </Row>
                                )}
                            </Spin>
                        </>
                    )}
                </>
            </div>
        </>
    )
}

export default Projects
