import React from 'react'

function NoExperienceIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 791 500"
        >
            <g clipPath="url(#clip0_1474_3761)">
                <path
                    fill="#F2F2F2"
                    d="M661.514 39.145H.736A46.937 46.937 0 016.04 24.31C14.176 9.816 29.69 0 47.468 0h567.315c17.766 0 33.292 9.816 41.417 24.31a46.959 46.959 0 015.314 14.835z"
                ></path>
                <path
                    fill="#3F3D56"
                    d="M790.059 498.172c0 .725-.582 1.307-1.307 1.307H517.223a1.303 1.303 0 01-1.307-1.307c0-.351.132-.659.351-.889h98.515c2.097 0 4.15-.143 6.171-.418h167.799c.725 0 1.307.582 1.307 1.307z"
                ></path>
                <path
                    fill="#E6E6E6"
                    d="M485.565 231.836H176.677c-2.871 0-5.206-2.955-5.206-6.588s2.335-6.588 5.206-6.588h308.888c2.871 0 5.206 2.955 5.206 6.588s-2.335 6.588-5.206 6.588z"
                ></path>
                <path
                    fill="#3F3D56"
                    d="M661.514 39.145a46.96 46.96 0 00-5.315-14.835C648.074 9.816 632.548 0 614.782 0H47.467C29.691 0 14.176 9.816 6.04 24.31A46.937 46.937 0 00.736 39.145 47.213 47.213 0 000 47.466v404.544c0 26.177 21.29 47.468 47.468 47.468h567.314c5.424 0 10.651-.923 15.515-2.614 18.578-6.434 31.952-24.112 31.952-44.854V47.467c0-2.832-.252-5.621-.735-8.322zm-1.461 412.866c0 22.905-17.019 41.846-39.1 44.854a45.673 45.673 0 01-6.171.418H114.897c-62.247 0-112.701-50.465-112.701-112.712V47.467c0-2.843.264-5.621.769-8.322a44.763 44.763 0 015.6-14.835C16.459 11.068 30.93 2.196 47.468 2.196h567.314c16.536 0 31.008 8.872 38.903 22.114a44.76 44.76 0 015.6 14.835c.505 2.7.768 5.479.768 8.322v404.544z"
                ></path>
                <path
                    fill="#FFB6B6"
                    d="M632.378 237.26l-3.716-13.624 16.72-6.193 1.858 12.386-14.862 7.431z"
                ></path>
                <path
                    fill="#000"
                    d="M632.378 237.26l-3.716-13.624 9.847-4.153-3.059 16.061-3.072 1.716z"
                    opacity="0.1"
                ></path>
                <path
                    fill="#FFB6B6"
                    d="M583.432 354.021c-3.194-1.9-3.362-7.508-.376-12.527 1.307-2.197 3.002-3.916 4.762-4.996l1.753-2.865 22.183-39.766s9.71-37.504 13.663-42.039c3.953-4.535 9.669-3.466 9.669-3.466l5.069 4.503-18.094 53.119-22.329 31.446-2.993 4.376c-.109 2.062-.811 4.372-2.118 6.569-2.986 5.018-7.996 7.546-11.189 5.646z"
                ></path>
                <path
                    fill="#4F46E5"
                    d="M631.463 233.368s7.056-.272 11.507 5.208c4.452 5.481-18.869 67.253-18.869 67.253l-18.996-.673 26.358-71.788z"
                ></path>
                <path
                    fill="#FFB6B6"
                    d="M633.308 225.803c9.577 0 17.34-7.763 17.34-17.339 0-9.576-7.763-17.339-17.34-17.339-9.576 0-17.339 7.763-17.339 17.339 0 9.576 7.763 17.339 17.339 17.339zM592.746 460.195l-11.766 29.106-6.811-6.812-2.478-13.005 9.909-12.385 11.146 3.096zM702.975 449.668l11.766 13.624-5.573 11.147-12.386-1.858-6.192-17.34 12.385-5.573zM624.638 287.111l-1.548 25.08 38.394-6.812-2.477-15.481-34.369-2.787z"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M622.78 312.501l38.704-12.695s14.243 28.486-1.239 46.445c-15.481 17.958-66.881 120.137-66.881 120.137l-15.481-7.431 22.293-65.023 24.771-60.069-2.167-21.364z"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M660.246 335.723l14.862 65.023 30.964 50.161-16.101 9.289-43.349-59.45-14.243-45.826 27.867-19.197zM566.117 467.007h-10.725s-12.514-4.954-15.447 0c-2.933 4.955 13.086 19.005 13.086 19.005s22.375 12.578 25.471 11.959c3.097-.619 4.335-8.051 4.335-8.051l3.716-8.669-5.574 1.857s-7.431-4.334-5.573-11.766l-9.289-4.335zM712.264 465.15v-6.193l7.199 6.193s11.998 4.335 2.709 13.624c-9.289 9.289-21.055 19.197-21.055 19.197s-18.578 4.954-19.817-.619c-1.238-5.574 0-7.829 0-7.829l6.812-3.318 6.193-16.101 9.908 1.238 8.051-6.192zM638.571 211.87l-5.574-.619-10.218-10.218s-9.289 4.954-9.289 1.238c0-17.339 10.528-16.72 19.198-17.958 8.669-1.239 7.74-1.549 10.527 3.096 1.274 2.124 5.883 3.406 5.883 3.406s8.67-11.766 13.005 2.477c4.335 14.243-1.239 58.211 3.096 61.927 4.335 3.715-17.799 7.425-15.402-7.125 2.397-14.55 6.732-36.843 4.255-41.797-2.477-4.955-3.096-3.716-3.096-3.716 3.07-1.253 5.075 20.823-6.499 21.904l-.313-10.757s-1.858-5.574-5.573-1.858z"
                ></path>
                <path
                    fill="#4F46E5"
                    d="M630.83 233.235l18.578-7.432 24.77 74.312-50.965-1.856-4.589-22.18s-4.481-11.544-.765-18.355c3.715-6.812 12.971-24.489 12.971-24.489z"
                ></path>
                <path
                    fill="#FFB6B6"
                    d="M679.172 357.717c-3.702.318-7.109-4.141-7.609-9.959-.218-2.547.156-4.932.957-6.836l-.245-3.35-5.161-45.241s-9.605-37.007-9.037-42.996c.568-5.988 1.466-7.578 1.466-7.578l6.744.703 16.266 53.707.19 38.566.12 5.301c1.114 1.739 1.89 4.025 2.108 6.572.5 5.818-2.096 10.793-5.799 11.111z"
                ></path>
                <path
                    fill="#4F46E5"
                    d="M647.859 231.686s5.574-4.334 12.386-2.477c6.812 1.858 23.876 65.643 23.876 65.643l-15.826 10.527-20.436-73.693zM325.24 338.893h-74.287c-4.534 0-8.223-3.689-8.223-8.222v-7.43c0-4.534 3.689-8.221 8.223-8.221h74.287c4.534 0 8.222 3.687 8.222 8.221v7.43c0 4.533-3.688 8.222-8.222 8.222zM610.725 96.089h-74.288c-4.533 0-8.222-3.689-8.222-8.222v-7.43c0-4.534 3.689-8.221 8.222-8.221h74.288c4.533 0 8.222 3.687 8.222 8.22v7.43c0 4.534-3.689 8.223-8.222 8.223zM435.445 339.442h-74.287c-4.837 0-8.771-3.935-8.771-8.771v-7.43c0-4.836 3.934-8.77 8.771-8.77h74.287c4.837 0 8.772 3.934 8.772 8.77v7.43c0 4.836-3.935 8.771-8.772 8.771zm-74.287-22.775a6.582 6.582 0 00-6.575 6.574v7.43a6.583 6.583 0 006.575 6.575h74.287a6.583 6.583 0 006.576-6.575v-7.43a6.582 6.582 0 00-6.576-6.574h-74.287z"
                ></path>
                <path
                    fill="#E6E6E6"
                    d="M526.569 197.798H135.674a6.596 6.596 0 01-6.588-6.589 6.596 6.596 0 016.588-6.588h390.895a6.596 6.596 0 016.589 6.588 6.597 6.597 0 01-6.589 6.589zM110.778 90.74h-49.41a6.595 6.595 0 01-6.589-6.588 6.595 6.595 0 016.588-6.588h49.411a6.595 6.595 0 016.588 6.588 6.595 6.595 0 01-6.588 6.588zM406.148 90.74h-49.411a6.596 6.596 0 01-6.589-6.588 6.596 6.596 0 016.589-6.588h49.411a6.596 6.596 0 016.588 6.588 6.596 6.596 0 01-6.588 6.588zM499.48 90.74h-49.411a6.596 6.596 0 01-6.589-6.588 6.596 6.596 0 016.589-6.588h49.411a6.596 6.596 0 016.588 6.588 6.596 6.596 0 01-6.588 6.588zM439.824 265.875H222.416a6.595 6.595 0 01-6.588-6.588 6.596 6.596 0 016.588-6.589h217.408a6.597 6.597 0 016.589 6.589 6.596 6.596 0 01-6.589 6.588zM449.298 409.996H212.943a3.988 3.988 0 01-3.984-3.983 3.988 3.988 0 013.984-3.984h236.355a3.987 3.987 0 013.983 3.984 3.988 3.988 0 01-3.983 3.983z"
                ></path>
                <path
                    fill="#fff"
                    d="M451.499 23.493H215.144a3.988 3.988 0 01-3.984-3.983 3.988 3.988 0 013.984-3.984h236.355a3.988 3.988 0 013.984 3.984 3.988 3.988 0 01-3.984 3.983z"
                ></path>
            </g>
            <defs>
                <clipPath id="clip0_1474_3761">
                    <path fill="#fff" d="M0 0H790.059V500H0z"></path>
                </clipPath>
            </defs>
        </svg>
    )
}

export default NoExperienceIcon
