export const estimateFn = (value, currentProjectData, name) => {
    let estimateMinutes = `0m`
    let estimateHours = `0h`
    let estimateWeeks = `0w`
    let estimateDays = `0d`
    const defaultTime = currentProjectData?.defaultTime
    const defaultWeek = currentProjectData?.defaultWeek

    let minutes = value
    if (minutes > 60) {
        estimateMinutes = `${parseInt(minutes % 60)}m`
        const hours = parseInt(minutes / 60)
        if (hours >= defaultTime) {
            estimateHours = `${hours % defaultTime}h`
            const days = parseInt(hours / defaultTime)
            if (days > defaultWeek) {
                estimateDays = `${days % defaultWeek}d`
                estimateWeeks = `${parseInt(days / defaultWeek)}w`
            } else {
                estimateDays = `${parseInt(days)}d`
            }
        } else {
            estimateHours = `${parseInt(hours)}h`
        }
    } else {
        if (name === 'reports') {
            estimateMinutes = `${parseInt(minutes)}m`
        } else {
            estimateMinutes = `${parseInt(minutes)}m`
        }
    }

    return `${estimateWeeks.split('w')[0] !== '0' ? estimateWeeks : ''} ${
        estimateDays.split('d')[0] !== '0' ? estimateDays : ''
    } ${estimateHours.split('h')[0] !== '0' ? estimateHours : ''} ${
        estimateMinutes.split('m')[0] !== '0' ? estimateMinutes : ''
    }`
}
