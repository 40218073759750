import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// antd
import {
    Table,
    Row,
    Typography,
    Dropdown,
    theme,
    Badge,
    Pagination,
} from 'antd'
import { EllipsisOutlined } from '@ant-design/icons'

// slices
import {
    changePage,
    changeTab,
    inquiriesListGet,
    inquiryCount as inquiryCountAPI,
    inquiryGet,
    inquiryState,
    switchInquiryDrawer,
} from './inquiries.slice'
import { loginState } from '../login/login.slice'

// assets
import { EditIcon } from '../../assets/icons'

// constants
import { colorRandom } from '../../constants/tasks'

import dayjs from 'dayjs'

const InquiryTableView = () => {
    const { Text } = Typography

    const { useToken } = theme

    const { token } = useToken()

    const {
        inquiryList,
        drawerVariations,
        currentPage,
        isNext,
        pageLimit,
        inquiryCount,
    } = useSelector(inquiryState)

    const { userProfile } = useSelector(loginState)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(
            inquiriesListGet({
                workspaceId: userProfile?.currentWorkSpace?._id,
                skip: 0,
                limit: pageLimit,
            })
        )
        dispatch(
            inquiryCountAPI({
                workspaceId: userProfile?.currentWorkSpace?._id,
            })
        )
    }, [])

    // COLUMNS
    const columns = [
        {
            title: 'Inquiry Name',
            key: 'inquiryName',
            dataIndex: 'inquiryName',
            render: (val) => (
                <Row>
                    <Text>{val}</Text>
                </Row>
            ),
        },
        {
            key: 'projectName',
            dataIndex: 'projectName',
            title: 'Project Name',
            render: (val) => (
                <Row
                    style={{
                        textTransform: 'capitalize',
                    }}
                >
                    {val || 'NA'}
                </Row>
            ),
        },
        {
            title: 'Created Date',
            key: 'createdAt',
            dataIndex: 'createdAt',
            render: (val) => (
                <Row
                    align={'middle'}
                    style={{
                        columnGap: '16px',
                    }}
                >
                    {dayjs(new Date(val)).format('DD/MM/YYYY')}
                </Row>
            ),
        },
        {
            key: 'status',
            dataIndex: 'status',
            title: 'Status',
            render: (val) => (
                <Badge
                    style={{
                        backgroundColor: colorRandom[val],
                        borderRadius: '5px',
                    }}
                    count={val || 'NA'}
                />
            ),
        },
        {
            key: 'client',
            dataIndex: 'client',
            title: 'Client Name',
            render: (val) => (
                <Row
                    style={{
                        textTransform: 'capitalize',
                    }}
                >
                    {val?.name || 'NA'}
                </Row>
            ),
        },
        {
            key: 'context',
            dataIndex: 'context',
            title: '  ',
            render: (_, row) => (
                <Row justify="end" style={{ paddingRight: 5 }}>
                    <Dropdown
                        trigger={['click']}
                        placement={'bottomRight'}
                        menu={{
                            items: [
                                {
                                    key: '1',
                                    label: 'Edit',
                                    icon: (
                                        <div
                                            style={{
                                                height: '20px',
                                                width: '20px',
                                            }}
                                        >
                                            <EditIcon
                                                color={
                                                    token.colorPalette.baseColor
                                                        .tertiary
                                                }
                                            />
                                        </div>
                                    ),
                                    style: {
                                        columnGap: '3px',
                                    },
                                    onClick: () => {
                                        dispatch(changeTab('summary'))
                                        dispatch(
                                            inquiryGet({
                                                id: row?._id,
                                                workspaceId:
                                                    userProfile
                                                        ?.currentWorkSpace?._id,
                                            })
                                        )
                                        dispatch(
                                            switchInquiryDrawer({
                                                ...drawerVariations,
                                                open: true,
                                            })
                                        )
                                    },
                                },
                            ],
                        }}
                    >
                        <EllipsisOutlined rotate={90} />
                    </Dropdown>
                </Row>
            ),
        },
    ]

    return (
        <>
            <Table
                dataSource={inquiryList}
                scroll={{
                    x: 'scroll',
                }}
                rootClassName="tableGlobal"
                style={{
                    borderRadius: '16px',
                }}
                columns={columns}
                pagination={false}
            />
            {inquiryList?.length > 0 && (
                <Row
                    justify={'end'}
                    align={'middle'}
                    style={{
                        border: `2px solid ${token.colorPalette.baseColor.quaternary}`,
                        padding: '25px',
                        borderRadius: '0px 0px 8px 8px',
                    }}
                >
                    <Text>
                        {inquiryList?.length} - {pageLimit} of {inquiryCount}
                    </Text>
                    <Pagination
                        defaultCurrent={currentPage}
                        current={currentPage}
                        defaultPageSize={pageLimit}
                        pageSizeOptions={[5, 10, 20, 30]}
                        showSizeChanger={true}
                        total={
                            isNext === false && currentPage === 1
                                ? inquiryList?.length
                                : inquiryCount
                        }
                        onChange={async (value, pageSize) => {
                            dispatch(
                                changePage({
                                    currentPage: value,
                                    skip: (value - 1) * pageLimit,
                                    pageLimit: pageSize,
                                })
                            )
                            dispatch(
                                inquiriesListGet({
                                    skip: (value - 1) * pageSize,
                                    limit: pageSize,
                                    workspaceId:
                                        userProfile?.currentWorkSpace?._id,
                                })
                            )
                        }}
                    />
                </Row>
            )}
        </>
    )
}

export default InquiryTableView
