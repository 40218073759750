import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// antd
import { Divider, Menu, Row, Skeleton, Spin, Typography, theme } from 'antd'

// slices
import { fieldGet, moduleChangeName, roleState } from '../role/role.slice'

// components
import FoF from '../404/404.index'

// permission component
import PermissionRole from './PermissionRole'

const PermissionFieldModule = () => {
    const { useToken } = theme
    const { token } = useToken()

    const { Title } = Typography
    const dispatch = useDispatch()

    const params = useParams()

    const { selectedFieldData, moduleSelected, fieldGetStatus, roleName } =
        useSelector(roleState)

    const [moduleList, setModuleList] = useState([])

    const listDataInitially = (id) => {
        dispatch(fieldGet({ _id: id }))
    }

    // SET ALL THE PARENT AND CHILD ARRAY
    useEffect(() => {
        if (selectedFieldData?.length > 0) {
            const parentModules = selectedFieldData
                .filter((data) => data?.isParent)
                .map((data) => {
                    if (data?.isParent && data?.hasSubModule) {
                        return { ...data, children: [] }
                    } else {
                        return { ...data }
                    }
                })
                .sort((a, b) => a.rolePosition - b.rolePosition)

            const childModules = selectedFieldData
                .filter(
                    (data) => data?.isParent === false || data?.hasSubModule
                )
                .map((data) => {
                    const { children, ...rest } = data // Destructure and exclude the 'children' key
                    return rest
                })
                .sort((a, b) => a.rolePosition - b.rolePosition)

            parentModules.map((parentData) =>
                childModules.map((childData) => {
                    if (
                        childData?.key === parentData?.key ||
                        childData?.parentName === parentData?.key
                    ) {
                        parentData.children.push(childData)
                    }
                })
            )

            if (Object.keys(moduleSelected)?.length === 0) {
                dispatch(moduleChangeName(parentModules[0]))
            }

            setModuleList(parentModules)
        }
    }, [selectedFieldData])

    // LIST MASTER DATA INITIALLY
    useEffect(() => {
        listDataInitially(params?.id)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params?.id])

    return (
        <>
            <div
                className={'container'}
                style={{
                    zIndex: 10,
                    minHeight: '100vh',
                    padding: '0px',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                    }}
                >
                    <div
                        style={{
                            padding: '40px 25px 0px 25px',
                            minWidth: '265px',
                        }}
                    >
                        <Row
                            align={'middle'}
                            style={{
                                columnGap: '8px',
                            }}
                        >
                            <Title level={4}>Role:</Title>
                            {fieldGetStatus === 'loading' ? (
                                <Skeleton.Input active={true} />
                            ) : (
                                <Title
                                    level={4}
                                    style={{
                                        textTransform: 'capitalize',
                                        margin: 0,
                                    }}
                                >
                                    {roleName}
                                </Title>
                            )}
                        </Row>
                        <Row
                            style={{
                                marginTop: '30px',
                            }}
                        >
                            {fieldGetStatus === 'loading' ? (
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        rowGap: '13px',
                                    }}
                                >
                                    <Skeleton.Input
                                        style={{
                                            width: '100%',
                                        }}
                                        active={true}
                                    />
                                    <Skeleton.Input
                                        style={{
                                            width: '100%',
                                        }}
                                        active={true}
                                    />
                                    <Skeleton.Input
                                        style={{
                                            width: '100%',
                                        }}
                                        active={true}
                                    />
                                </div>
                            ) : (
                                <Menu
                                    rootClassName="menuRole"
                                    mode="inline"
                                    theme="light"
                                    items={moduleList}
                                    style={{
                                        color: token.colorPalette.baseColor
                                            .black,
                                        background: 'transparent',
                                        padding: 0,
                                    }}
                                    selectedKeys={[moduleSelected?.key]}
                                    defaultOpenKeys={[
                                        moduleSelected?.parentName,
                                    ]}
                                    onClick={({ key }) => {
                                        const findModule =
                                            selectedFieldData.find(
                                                (data) => data?.key === key
                                            )
                                        if (
                                            findModule?.key !==
                                            moduleSelected?.key
                                        ) {
                                            dispatch(
                                                moduleChangeName(findModule)
                                            )
                                        }
                                    }}
                                />
                            )}
                        </Row>
                    </div>
                    <div>
                        <Divider
                            type="vertical"
                            style={{
                                height: '100%',
                            }}
                        />
                    </div>
                    <div
                        style={{
                            width: '100%',
                        }}
                    >
                        <Spin
                            spinning={
                                fieldGetStatus === 'loading' ? true : false
                            }
                        >
                            {selectedFieldData?.length > 0 &&
                            selectedFieldData.some(
                                (data) => data?.key === moduleSelected?.key
                            ) ? (
                                <PermissionRole />
                            ) : (
                                fieldGetStatus !== 'loading' && <FoF />
                            )}
                        </Spin>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PermissionFieldModule
