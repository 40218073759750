export const ROOT_ROUTE = '/'
export const PRIVATE_ROOT_ROUTE = '/dashboard'
export const PUBLIC_ROUTES = {
    login: '/login',
    loginWithApp: '/login-with-app',
    signUp: '/sign-up',
    schedules: '/schedules',
    forgotPassword: '/forgot-password',
    updatePassword: '/update-password',
    setPassword: '/set-password',
    verifyOtp: '/verify-otp',
    successfullyResetPassword: '/successfully-reset-password',
    linkExpired: '/link-expired',
    timeTrackingApp: '/time-tracking-app',
    callBackUrl: '/linkedin/callback-url',
    workSpaceLogin: '/workspace/login',
    talentLogin: '/workspace/user-workspace-login',
}

export const PRIVATE_ROUTES = {
    users: `${PRIVATE_ROOT_ROUTE}/users`,
    profile: `${PRIVATE_ROOT_ROUTE}/profile`,
    globalSettings: {
        root: `${PRIVATE_ROOT_ROUTE}/global-settings`,
    },
    masterData: {
        root: (id) => `${PRIVATE_ROOT_ROUTE}/global-settings/master-data/${id}`,
    },
    roleSetting: {
        root: `${PRIVATE_ROOT_ROUTE}/global-settings/role`,
        permissionModule: (id) =>
            `${PRIVATE_ROOT_ROUTE}/global-settings/role/${id}`,
    },
    projectSetting: {
        root: `${PRIVATE_ROOT_ROUTE}/global-settings/project`,
    },
    projects: {
        root: `${PRIVATE_ROOT_ROUTE}/projects`,
        projectId: (id) => `${PRIVATE_ROOT_ROUTE}/projects/${id}`,
    },
    notes: {
        root: `${PRIVATE_ROOT_ROUTE}/notes`,
        createNote: `${PRIVATE_ROOT_ROUTE}/notes/create-note`,
        editNote: (id) => `${PRIVATE_ROOT_ROUTE}/notes/${id}`,
        viewNote: (id) => `${PRIVATE_ROOT_ROUTE}/notes/view-note/${id}`,
    },
    workSpace: `${PRIVATE_ROOT_ROUTE}/workspace`,
    chat: `${PRIVATE_ROOT_ROUTE}/chat`,
    talent: `${PRIVATE_ROOT_ROUTE}/talent`,
    recruit: `${PRIVATE_ROOT_ROUTE}/recruit`,
    jobs: `${PRIVATE_ROOT_ROUTE}/jobs`,
    jobApplication: (id) => `${PRIVATE_ROOT_ROUTE}/jobs/${id}/applications`,
    inquiries: `${PRIVATE_ROOT_ROUTE}/inquiries`,
    clients: `${PRIVATE_ROOT_ROUTE}/clients`,
    sprints: `${PRIVATE_ROOT_ROUTE}/sprints`,
    tasks: `${PRIVATE_ROOT_ROUTE}/tasks`,
    roadmap: `${PRIVATE_ROOT_ROUTE}/roadmap`,
    settings: `${PRIVATE_ROOT_ROUTE}/settings`,
    reports: `${PRIVATE_ROOT_ROUTE}/reports`,
    team: `${PRIVATE_ROOT_ROUTE}/teams`,
    files: `${PRIVATE_ROOT_ROUTE}/files`,
    overview: `${PRIVATE_ROOT_ROUTE}/overview`,
    standups: `${PRIVATE_ROOT_ROUTE}/standups`,
    userWorkSpace: `${PRIVATE_ROOT_ROUTE}/workspace`,
}
