import React from 'react'

function UploadImageIcon({ color }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="24"
            fill="none"
            viewBox="0 0 25 24"
        >
            <path
                fill={color ? color : '#fff'}
                d="M3.5 8c0 .55.45 1 1 1s1-.45 1-1V6h2c.55 0 1-.45 1-1s-.45-1-1-1h-2V2c0-.55-.45-1-1-1s-1 .45-1 1v2h-2c-.55 0-1 .45-1 1s.45 1 1 1h2v2zM16.5 14a3 3 0 11-6 0 3 3 0 016 0z"
            ></path>
            <path
                fill={color ? color : '#fff'}
                fillRule="evenodd"
                d="M18.33 6h3.17c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2h-16c-1.1 0-2-.9-2-2V9.72c.3.17.63.28 1 .28 1.1 0 2-.9 2-2V7h1c1.1 0 2-.9 2-2 0-.37-.11-.7-.28-1h6.4c.56 0 1.1.24 1.47.65L18.33 6zM8.5 14c0 2.76 2.24 5 5 5s5-2.24 5-5-2.24-5-5-5-5 2.24-5 5z"
                clipRule="evenodd"
            ></path>
        </svg>
    )
}

export default UploadImageIcon
