// antd
import { Typography } from 'antd'

// assets
import { NoWorkSpaceIcon } from '../../assets/icons'

const NoProjectsAssign = () => {
    const { Title, Text } = Typography

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    columnGap: '35px',
                    height: 'inherit',
                }}
            >
                <NoWorkSpaceIcon />
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Title level={2}>Projects is not active yet.</Title>
                    <Text
                        style={{
                            fontSize: '16px',
                        }}
                    >
                        Kindly contact your administrator.
                    </Text>
                </div>
            </div>
        </>
    )
}

export default NoProjectsAssign
