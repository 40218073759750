import { useDispatch, useSelector } from 'react-redux'

// antd
import { Drawer, Modal, Spin } from 'antd'

// slices
import {
    changeRoleView,
    changeTab,
    inquiryState,
    switchInquiryDrawer,
} from './inquiries.slice'
import { loginState } from '../login/login.slice'

// components
import PanelHeader from '../../components/panelHeader/panelHeader'

// inquiry components
import InquiryList from './inquiryList'
import InquiryDrawer from './inquiryDrawer'
import InquiryTableView from './inquiryTableView'

// tabs
import AddClientModal from './tabs/addClientModal'
import FilterWorkspace from './filterWorkspace'

// constants
import {
    DOMAIN_LIST,
    SUPER_ADMIN,
    WORKSPACE_ADMIN,
} from '../../constants/roles'
import { talentViewShow } from './tabs/roles/rolesInquiry.slice'

const Inquiries = () => {
    const dispatch = useDispatch()

    const { drawerVariations, updatedDataStatus, status } =
        useSelector(inquiryState)

    const { userProfile } = useSelector(loginState)

    return (
        <>
            <div
                className={'container'}
                style={{
                    zIndex: 10,
                    minHeight: '100vh',
                    padding: '35px',
                }}
            >
                <>
                    <PanelHeader
                        props={{
                            panelName: 'add inquiry',
                            searchBar: false,
                            filterBar: false,
                            teamMembersBar: false,
                            permissionAdd: true,
                            filterWorkSpaceComponent: () => <FilterWorkspace />,
                            filterWorkSpace:
                                userProfile?.role?.roleName === SUPER_ADMIN &&
                                DOMAIN_LIST.includes(
                                    userProfile?.currentWorkSpace?.domain
                                ),
                            onClick: () => {
                                dispatch(changeTab('summary'))
                                dispatch(
                                    switchInquiryDrawer({
                                        ...drawerVariations,
                                        open: true,
                                    })
                                )
                            },
                        }}
                    />
                    <Spin spinning={status === 'loading' ? true : false}>
                        {(userProfile?.role?.roleName === WORKSPACE_ADMIN ||
                            userProfile?.role?.roleName === SUPER_ADMIN) &&
                        !DOMAIN_LIST.includes(
                            userProfile?.currentWorkSpace?.domain
                        ) ? (
                            <InquiryTableView />
                        ) : (
                            <InquiryList />
                        )}
                    </Spin>
                </>
            </div>
            <Drawer
                placement="right"
                size={'default'}
                style={{
                    height: '100vh',
                    minWidth: '1200px',
                    width: '1200px',
                }}
                styles={{
                    body: {
                        padding: '0px',
                    },
                    wrapper: {
                        width: 'auto !important',
                    },
                }}
                rootClassName="standUpHeaderDrawer"
                onClose={() => {
                    dispatch(
                        switchInquiryDrawer({
                            ...drawerVariations,
                            open: false,
                        })
                    )
                    dispatch(changeRoleView(false))
                    dispatch(talentViewShow(false))
                }}
                open={drawerVariations?.open}
            >
                <Spin spinning={updatedDataStatus === 'loading' ? true : false}>
                    <InquiryDrawer />
                </Spin>
            </Drawer>
            <Modal
                footer={null}
                closable={false}
                centered
                open={drawerVariations?.clientModal}
                destroyOnClose={true}
                style={{
                    minWidth: '940px',
                }}
                styles={{
                    body: {
                        padding: '25px',
                    },
                }}
                onCancel={() =>
                    dispatch(
                        switchInquiryDrawer({
                            ...drawerVariations,
                            clientModal: false,
                        })
                    )
                }
            >
                <AddClientModal />
            </Modal>
        </>
    )
}

export default Inquiries
