import { useDispatch, useSelector } from 'react-redux'

// antd
import {
    Avatar,
    Image,
    Row,
    Table,
    Typography,
    theme,
    Badge,
    Dropdown,
    Pagination,
    Tooltip,
} from 'antd'
import { EllipsisOutlined } from '@ant-design/icons'

// slices
import {
    changePage,
    getTalentDetails,
    talentListGet,
    talentState,
} from './talent.slice'
import { updateUserGet } from '../profile/profile.slice'

// assets
import {
    AssignIcon,
    NonVerifyTalentIcon,
    UnAssignIcon,
    VerifyTalentIcon,
    BypassIcon,
    ChangeCostIcon,
    ViewIcon,
} from '../../assets/icons'

// constants
import { TALENT_FILTER_STATUS } from '../../constants/general'
import { DOMAIN_LIST } from '../../constants/roles'

const TalentList = ({ props = {} }) => {
    const {
        talentView,
        modalOpen,
        unAssignModalOpen,
        addRatesModalOpen,
        talentLogin,
        updateStatus,
        talentModalOpen,
    } = props
    const { Text } = Typography

    const dispatch = useDispatch()

    const { useToken } = theme

    const { token } = useToken()

    const {
        pageLimit,
        currentPage,
        dataCount,
        isNext,
        talentList,
        filter,
        search,
    } = useSelector(talentState)

    // COLUMNS
    const columns = [
        {
            title: 'Name',
            key: 'name',
            dataIndex: 'name',
            render: (val, item) => (
                <Row
                    align={'middle'}
                    style={{
                        columnGap: '16px',
                    }}
                >
                    {item?.profilePicture ? (
                        <Image
                            src={item?.profilePicture}
                            height={'30px'}
                            preview={false}
                            width={'30px'}
                            style={{
                                borderRadius: '50%',
                            }}
                        />
                    ) : (
                        <Avatar
                            style={{
                                cursor: 'pointer',
                                backgroundColor: 'rgb(0 10 129 / 25%)',
                            }}
                        >
                            {item?.name[0].toUpperCase()}
                            {item?.name.split(' ')[1] !== undefined &&
                                item?.name.split(' ')[1] !== '' &&
                                item?.name.split(' ')[1][0].toUpperCase()}
                        </Avatar>
                    )}
                    <Text>{val}</Text>
                </Row>
            ),
        },
        {
            key: 'email',
            dataIndex: 'email',
            title: 'Email ID',
            render: (val) => (
                <div style={{ fontWeight: 500 }}>{val || 'NA'}</div>
            ),
        },
        {
            key: 'phone',
            dataIndex: 'phone',
            title: 'Mobile Number',
            render: (val, item) => (
                <div style={{ fontWeight: 500 }}>
                    {item?.countryCode}
                    {val || 'NA'}
                </div>
            ),
        },
        {
            title: 'Workspace',
            key: 'workspace',
            dataIndex: 'workspace',
            render: (_, item) =>
                item?.workspace?.length > 1 ? (
                    <Row
                        align="middle"
                        style={{
                            columnGap: '0px', // No gap between images
                            flexWrap: 'nowrap', // Ensure all items stay in one line
                            position: 'relative', // Positioning for overlap
                            overflow: 'visible', // Ensure container doesn't clip images
                        }}
                    >
                        {item?.workspace
                            ?.filter((w) => !DOMAIN_LIST.includes(w?.domain))
                            ?.map((w, index) => (
                                <Tooltip
                                    title={w?.companyName}
                                    key={w?.companyName}
                                >
                                    <Image
                                        src={w.logo}
                                        height="30px"
                                        preview={false}
                                        width="30px"
                                        style={{
                                            borderRadius: '50%',
                                            objectFit: 'contain',
                                            border: '2px solid #d9d9d9', // Gray border
                                            cursor: 'pointer', // Pointer cursor on hover
                                            marginLeft:
                                                index === 0
                                                    ? '0'
                                                    : `-${10 * index}px`, // Apply negative margin for overlap
                                            zIndex:
                                                item.workspace.length - index, // Ensure correct stacking order
                                        }}
                                    />
                                </Tooltip>
                            ))}
                    </Row>
                ) : (
                    <Text
                        style={{
                            color: token.colorPalette.baseColor.error,
                        }}
                    >
                        Unassigned
                    </Text>
                ),
        },
        {
            key: 'isOurUser',
            dataIndex: 'isOurUser',
            title: 'Verification',
            render: (item) => (
                <Text
                    style={{
                        color: item
                            ? token.colorPalette.baseColor.tertiary
                            : token.colorPalette.baseColor.error,
                    }}
                >
                    {item ? 'Yes' : 'No'}
                </Text>
            ),
        },
        {
            key: 'isActive',
            dataIndex: 'isActive',
            title: 'Status',
            render: (item) => (
                <Badge
                    count={item ? 'Active' : 'InActive'}
                    color={
                        item
                            ? 'hsla(155, 54%, 46%, 0.16)'
                            : 'hsla(11, 100%, 59%, 0.16)'
                    }
                    style={{
                        minWidth: '30px',
                        cursor: 'auto',
                        borderRadius: '6px',
                        display: 'flex',
                        alignItems: 'center',
                        color: item
                            ? token.colorPalette.iconColor.tertiary
                            : token.colorPalette.iconColor.quaternary,
                        padding: '12px 8px',
                        fontWeight: 700,
                    }}
                />
            ),
        },
        {
            key: 'context',
            dataIndex: 'context',
            title: '  ',
            render: (_, row) => (
                <Row justify="end" style={{ paddingRight: 5 }}>
                    <Dropdown
                        trigger={['click']}
                        placement={'bottomRight'}
                        menu={{
                            items: [
                                {
                                    key: '1',
                                    label: 'View',
                                    icon: <ViewIcon />,
                                    style: {
                                        columnGap: '10px',
                                    },
                                    onClick: () => {
                                        dispatch(
                                            updateUserGet({
                                                _id: row?._id,
                                            })
                                        )
                                        talentModalOpen()
                                    },
                                },
                                row?.isActive &&
                                    row?.phone && {
                                        key: '2',
                                        label: 'Login',
                                        icon: <BypassIcon />,
                                        style: {
                                            columnGap: '10px',
                                        },
                                        onClick: () => {
                                            talentLogin(row)
                                        },
                                    },
                                {
                                    key: '3',
                                    style: {
                                        columnGap: '10px',
                                        cursor:
                                            row?.workspace?.length === 1 ||
                                            (row?.isActive && row?.isOurUser)
                                                ? 'pointer'
                                                : 'no-drop',
                                    },
                                    icon: (
                                        <div
                                            style={{
                                                width: '20px',
                                                height: '20px',
                                                margin: '0px',
                                            }}
                                        >
                                            <AssignIcon
                                                color={
                                                    (!row?.isActive ||
                                                        !row?.isOurUser) &&
                                                    token.colorPalette.textColor
                                                        .senary
                                                }
                                            />
                                        </div>
                                    ),
                                    label: (
                                        <Text
                                            style={{
                                                color:
                                                    row?.isActive &&
                                                    row?.isOurUser
                                                        ? token.colorPalette
                                                              .baseColor.black
                                                        : token.colorPalette
                                                              .textColor.senary,
                                            }}
                                        >
                                            Workspace Assign
                                        </Text>
                                    ),
                                    onClick: () => {
                                        dispatch(getTalentDetails(row))
                                        if (
                                            row?.isActive &&
                                            row?.isOurUser &&
                                            !row?.isWorkspaceAssigned
                                        ) {
                                            modalOpen()
                                        }
                                    },
                                },
                                row?.isWorkspaceAssigned && {
                                    key: '4',
                                    style: {
                                        columnGap: '10px',
                                    },
                                    icon: (
                                        <div
                                            style={{
                                                width: '20px',
                                                height: '20px',
                                                margin: '0px',
                                            }}
                                        >
                                            <div
                                                style={{
                                                    height: '17px',
                                                    width: '17px',
                                                }}
                                            >
                                                <UnAssignIcon />
                                            </div>
                                        </div>
                                    ),
                                    label: <Text>Workspace UnAssign</Text>,
                                    onClick: () => {
                                        dispatch(getTalentDetails(row))
                                        unAssignModalOpen()
                                    },
                                },
                                row?.isOurUser &&
                                    !row?.isWorkspaceAssigned && {
                                        key: '5',
                                        style: {
                                            columnGap: '10px',
                                        },
                                        icon: (
                                            <div
                                                style={{
                                                    width: '20px',
                                                    height: '20px',
                                                    margin: '0px',
                                                }}
                                            >
                                                <NonVerifyTalentIcon />
                                            </div>
                                        ),
                                        label: (
                                            <Text
                                                style={{
                                                    color: token.colorPalette
                                                        .baseColor.error,
                                                }}
                                            >
                                                {'Not Verified'}
                                            </Text>
                                        ),
                                        onClick: () => {
                                            updateStatus(
                                                row?._id,
                                                row?.isOurUser
                                            )
                                        },
                                    },
                                !row?.isOurUser && {
                                    key: '5',
                                    style: {
                                        columnGap: '10px',
                                    },
                                    icon: (
                                        <div
                                            style={{
                                                width: '20px',
                                                height: '20px',
                                                margin: '0px',
                                            }}
                                        >
                                            <VerifyTalentIcon />
                                        </div>
                                    ),
                                    label: (
                                        <Text
                                            style={{
                                                color: token.colorPalette
                                                    .iconColor.tertiary,
                                            }}
                                        >
                                            {'Verified'}
                                        </Text>
                                    ),
                                    onClick: () => {
                                        updateStatus(row?._id, row?.isOurUser)
                                    },
                                },
                                {
                                    key: '6',
                                    style: {
                                        columnGap: '10px',
                                    },
                                    icon: (
                                        <div
                                            style={{
                                                width: '20px',
                                                height: '20px',
                                                margin: '0px',
                                            }}
                                        >
                                            <ChangeCostIcon />
                                        </div>
                                    ),
                                    label: <Text>Change Rates</Text>,
                                    onClick: () => {
                                        dispatch(getTalentDetails(row))
                                        addRatesModalOpen()
                                    },
                                },
                            ],
                        }}
                    >
                        <EllipsisOutlined rotate={90} />
                    </Dropdown>
                </Row>
            ),
        },
    ]

    return (
        <>
            <Table
                dataSource={talentList}
                scroll={{
                    x: 'scroll',
                }}
                rootClassName="tableGlobal"
                style={{
                    borderRadius: '16px',
                }}
                columns={columns}
                pagination={false}
            />
            {talentList?.length > 0 && talentView === 'list' && (
                <Row
                    justify={'end'}
                    align={'middle'}
                    style={{
                        border: `2px solid ${token.colorPalette.baseColor.quaternary}`,
                        padding: '25px',
                        borderRadius: '0px 0px 8px 8px',
                    }}
                >
                    <Text>
                        {talentList?.length} - {pageLimit} of {dataCount}
                    </Text>
                    <Pagination
                        defaultCurrent={currentPage}
                        current={currentPage}
                        defaultPageSize={pageLimit}
                        pageSizeOptions={[5, 10, 20, 50]}
                        showSizeChanger={true}
                        total={
                            isNext === false && currentPage === 1
                                ? talentList?.length
                                : dataCount
                        }
                        onChange={async (value, pageSize) => {
                            await dispatch(
                                changePage({
                                    currentPage: value,
                                    skip: (value - 1) * pageLimit,
                                    pageLimit: pageSize,
                                })
                            )
                            await dispatch(
                                talentListGet({
                                    skip: (value - 1) * pageSize,
                                    limit: pageSize,
                                    filter: {
                                        ...filter,
                                        status: TALENT_FILTER_STATUS,
                                    },
                                    search,
                                })
                            )
                        }}
                    />
                </Row>
            )}
        </>
    )
}

export default TalentList
