import React from 'react'

function NoEducationIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 690 500"
        >
            <g clipPath="url(#clip0_1474_3598)">
                <path
                    fill="#F2F2F2"
                    d="M439.576 374.834v120.53H137.604c-34.406 0-62.399-27.035-62.399-60.265 0-33.229 27.993-60.265 62.399-60.265h301.972z"
                ></path>
                <path
                    fill="#4F46E5"
                    d="M446.993 494.437a4.636 4.636 0 01-4.636 4.636H132.225a63.508 63.508 0 01-63.51-63.51 63.508 63.508 0 0163.51-63.51h310.132a4.636 4.636 0 010 9.271H132.225a54.238 54.238 0 000 108.477h310.132a4.636 4.636 0 014.636 4.636z"
                ></path>
                <path
                    fill="#CCC"
                    d="M439.577 402.649H115.074a.928.928 0 010-1.854h324.503a.923.923 0 01.927.927.924.924 0 01-.272.655.924.924 0 01-.655.272zM439.577 424.901H115.074a.928.928 0 010-1.855h324.503a.928.928 0 010 1.855zM439.577 447.152H115.074a.927.927 0 110-1.854h324.503a.926.926 0 110 1.854zM439.577 469.404H115.074a.928.928 0 010-1.854h324.503a.923.923 0 01.927.927.928.928 0 01-.927.927z"
                ></path>
                <path
                    fill="#F2F2F2"
                    d="M672.957 397.523l-75.623 93.854-235.14-189.464c-26.791-21.587-31.626-60.202-10.777-86.077 20.849-25.875 59.609-29.364 86.4-7.777l235.14 189.464z"
                ></path>
                <path
                    fill="#CCC"
                    d="M603.691 495.309a4.64 4.64 0 01-6.519.701L355.678 301.426a63.51 63.51 0 1179.695-98.907l241.494 194.583a4.633 4.633 0 011.542 4.917 4.63 4.63 0 01-2.227 2.764 4.652 4.652 0 01-3.529.378 4.647 4.647 0 01-1.603-.839L429.556 209.738a54.244 54.244 0 00-76.281 8.191 54.244 54.244 0 00-11.692 39.842 54.23 54.23 0 0019.912 36.436L602.989 488.79a4.637 4.637 0 01.702 6.519z"
                ></path>
                <path
                    fill="#CCC"
                    d="M655.504 419.181l-252.684-203.6a.91.91 0 01-.34-.623.932.932 0 01.2-.68.923.923 0 01.983-.308.916.916 0 01.32.167l252.685 203.6a.926.926 0 01.141 1.305.926.926 0 01-1.305.139zM641.544 436.508l-252.684-203.6a.925.925 0 01-.139-1.303.93.93 0 011.303-.141l252.684 203.6a.926.926 0 01.141 1.305.941.941 0 01-.623.34.917.917 0 01-.682-.201zM627.582 453.835l-252.684-203.6a.926.926 0 111.164-1.444l252.684 203.6a.941.941 0 01.34.623.932.932 0 01-.2.681.914.914 0 01-.623.34.932.932 0 01-.681-.2zM613.621 471.162l-252.684-203.6a.937.937 0 01-.34-.623.932.932 0 01.2-.68.914.914 0 01.278-.232.911.911 0 01.705-.076.93.93 0 01.321.167l252.684 203.6a.926.926 0 01.141 1.305.914.914 0 01-.623.34.918.918 0 01-.682-.201zM688.874 500H.927a.927.927 0 010-1.854h687.947a.923.923 0 01.927.927.924.924 0 01-.272.655.924.924 0 01-.655.272z"
                ></path>
                <path
                    fill="#FFB8B8"
                    d="M313.453 362.166l-10.6-.001-5.041-40.884 15.644.002-.003 40.883z"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M295.281 359.136h20.441v12.871H282.41a12.864 12.864 0 013.77-9.101 12.878 12.878 0 019.101-3.77z"
                ></path>
                <path
                    fill="#FFB8B8"
                    d="M205.166 362.166l-10.6-.001-5.041-40.884 15.644.002-.003 40.883z"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M186.996 359.136h20.441v12.871h-33.312a12.864 12.864 0 013.77-9.101 12.878 12.878 0 019.101-3.77zM240.568 58.905v-4.636a29.903 29.903 0 0151.044-21.143 29.903 29.903 0 018.758 21.143v4.636a21.555 21.555 0 01-21.557 21.556h-16.688a21.562 21.562 0 01-15.243-6.314 21.563 21.563 0 01-6.314-15.242z"
                ></path>
                <path
                    fill="#FFB8B8"
                    d="M270.469 82.056c12.577 0 22.772-10.195 22.772-22.771 0-12.577-10.195-22.772-22.772-22.772-12.576 0-22.772 10.195-22.772 22.772 0 12.576 10.196 22.771 22.772 22.771zM146.605 137.548a8.697 8.697 0 016.983-2.313 8.678 8.678 0 013.558 1.24 8.68 8.68 0 012.699 2.628l19.521-3.692 5.137 11.315-27.64 4.828a8.741 8.741 0 01-10.258-14.006z"
                ></path>
                <path
                    fill="#CCC"
                    d="M252.972 96.784l-32.011 21.087-54.014 17.248a3.26 3.26 0 00-1.738 1.334 3.245 3.245 0 00-.498 2.132l1.178 10.15a3.251 3.251 0 001.333 2.262 3.232 3.232 0 002.57.536l50.836-10.896a18.382 18.382 0 006.834-3.009l35.963-25.587a9.296 9.296 0 003.855-8.296 9.245 9.245 0 00-5.171-7.556 9.247 9.247 0 00-9.137.595z"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M303.043 350.564a4.192 4.192 0 01-3.836-2.504l-48.592-120.067a1.386 1.386 0 00-1.353-.833 1.388 1.388 0 00-1.251.979l-39.643 116.89a4.19 4.19 0 01-2.148 2.518 4.178 4.178 0 01-3.306.162l-13.293-4.985a4.16 4.16 0 01-2.686-3.435c-5.981-52.098 46.172-183.603 46.699-184.925l.146-.366 47.542 10.516.099.108c18.968 20.692 34.558 151.202 37.491 177.154a4.152 4.152 0 01-2.755 4.401l-11.734 4.15c-.443.157-.91.237-1.38.237z"
                ></path>
                <path
                    fill="#CCC"
                    d="M262.08 168.747c-11.519 0-24.361-2.297-28.313-11.776l-.091-.217.122-.202c3.121-5.12 7.244-13.852 5.028-15.492-4.366-3.228-6.485-8.539-6.297-15.785.408-15.73 11.126-29.695 26.67-34.752a118.375 118.375 0 0113.225-3.404 22.509 22.509 0 0118.665 4.61 22.731 22.731 0 018.434 17.495c.163 16.81-2.425 40.223-15.68 56.297a4.13 4.13 0 01-2.441 1.42 113.434 113.434 0 01-19.322 1.806z"
                ></path>
                <path
                    fill="#FFB8B8"
                    d="M257.795 168.118a8.86 8.86 0 0110.828-6.179c.46.129.909.295 1.342.497l14.822-13.489 10.308 6.945-21.196 18.757a8.848 8.848 0 01-10.025 4.252 8.713 8.713 0 01-5.289-4.124 8.7 8.7 0 01-1.061-3.251 8.696 8.696 0 01.271-3.408z"
                ></path>
                <path
                    fill="#CCC"
                    d="M282.439 168.289a4.164 4.164 0 01-2.638-.941l-5.845-4.777a4.166 4.166 0 01-1.532-3.295 4.172 4.172 0 011.633-3.246l24.515-18.803a1.391 1.391 0 00.263-1.942l-15.131-20.004a12.315 12.315 0 01.823-15.868 12.282 12.282 0 0116.584-1.326l.096.1 15.801 22.625a14.091 14.091 0 013.833 17.531 14.09 14.09 0 01-4.167 4.893l-31.751 24.235a4.182 4.182 0 01-2.484.818z"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M250.299 52.878V41.58l20.171-8.808 19.233 8.808v11.298a1.857 1.857 0 01-1.855 1.855h-35.695a1.854 1.854 0 01-1.854-1.855z"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M269.543 25.867c-2.966-9.492 3.263-20.372 12.471-24.129 9.208-3.757 20.224-1.13 27.994 5.077 7.77 6.208 12.59 15.506 15.145 25.118 2.554 9.611 3.039 19.634 3.51 29.568 11.314 2.078 19.009 15.497 15.09 26.31-3.919 10.815-18.425 16.188-28.443 10.535-10.018-5.652-12.919-20.846-5.689-29.792a24.455 24.455 0 00-28.452-37.93c-5.576 2.332-12.572-3.727-11.626-4.757z"
                ></path>
                <path
                    fill="#4F46E5"
                    d="M142.423 93.72a96.84 96.84 0 01-39.263-8.6 4.148 4.148 0 01-2.459-3.805V47.55a4.175 4.175 0 014.172-4.172h75.1a4.18 4.18 0 014.172 4.172v33.765a4.146 4.146 0 01-2.459 3.805 96.838 96.838 0 01-39.263 8.6z"
                ></path>
                <path
                    fill="#4F46E5"
                    d="M142.414 66.365c-.557 0-1.108-.11-1.621-.326L81.24 40.914a4.171 4.171 0 01.142-7.745l59.553-22.592a4.159 4.159 0 012.978.006l58.755 22.593a4.176 4.176 0 012.674 3.817 4.174 4.174 0 01-2.531 3.913l-58.756 25.125a4.178 4.178 0 01-1.641.334z"
                ></path>
                <path
                    fill="#3F3D56"
                    d="M107.655 87.417a5.563 5.563 0 100-11.126 5.563 5.563 0 000 11.126z"
                ></path>
                <path
                    fill="#000"
                    d="M203.013 37.34l-58.757 25.125a3.71 3.71 0 01-2.899.007L81.804 37.347a3.64 3.64 0 01-1.773-1.558 3.705 3.705 0 001.773 5.267l59.553 25.125a3.708 3.708 0 002.899-.007l58.757-25.126a3.702 3.702 0 002.233-3.78 3.695 3.695 0 00-.477-1.48 3.64 3.64 0 01-1.756 1.552z"
                    opacity="0.2"
                ></path>
                <path
                    fill="#3F3D56"
                    d="M108.122 81.833a.928.928 0 00.928-.927V49.882l30.578-11.14a.929.929 0 00.679-1.573.925.925 0 00-.651-.281l-30.579 11.14a1.819 1.819 0 00-1.329.533 1.853 1.853 0 00-.553 1.32v31.025a.927.927 0 00.927.927z"
                ></path>
            </g>
            <defs>
                <clipPath id="clip0_1474_3598">
                    <path fill="#fff" d="M0 0H689.801V500H0z"></path>
                </clipPath>
            </defs>
        </svg>
    )
}

export default NoEducationIcon
