import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import {
    apiGetJob,
    // apiJobDelete,
    apiJobList,
    // apiJobUpdate,
    apiJobCreate,
} from './job.service'
import {
    addMasterDataDropDown,
    listMasterDataDropDown,
} from '../../profile/profile.service'

const initialState = {
    search: '',
    status: 'loaded',
    updatedDataStatus: 'loaded',
    formButtonType: 'saveButtonLoading',
    selectedData: undefined,
    modalVariations: {
        open: false,
        formName: 'addEditJobAntdForm',
        saveButtonLoading: false,
        isEdit: false,
        selectedJobID: null,
    },
    jobList: [],
    isNext: false,
    pageLimit: 10,
    currentPage: 1,
    skipPage: 0,
    filter: null,
    modelType: '',
    masterDataDropDownStatus: 'loaded',
    masterDataDropDown: {
        institute: [],
        certificateProvider: [],
        company: [],
        degree: [],
        designation: [],
        employmentType: [],
        fieldOfStudy: [],
        linkType: [],
        skills: [],
        tools: [],
    },
    jobDetails: null,
    jobLoading: false,
}

export const createJob = createAsyncThunk(
    'jobSlice/create',
    async (payload = {}) => {
        const response = await apiJobCreate(payload)
        return response
    }
)

export const getListJobs = createAsyncThunk(
    'jobSlice/list',
    async (payload = {}) => {
        const response = await apiJobList(payload)
        return response
    }
)

export const getJobDetails = createAsyncThunk(
    'jobSlice/details',
    async (payload = {}) => {
        const response = await apiGetJob(payload)
        return response
    }
)

export const masterDataDropDownList = createAsyncThunk(
    'jobSlice/listMasterDataDropDown',
    async (payload = {}) => {
        const response = await listMasterDataDropDown(payload)
        return response
    }
)

export const masterDataAddDropDown = createAsyncThunk(
    'jobSlice/addMasterDataDropDown',
    async (payload = {}) => {
        const response = await addMasterDataDropDown(payload)
        return response
    }
)

export const jobSlice = createSlice({
    name: 'jobSlice',
    initialState,
    reducers: {
        switchJobModal: (state, action) => {
            state.modalVariations = action.payload
            state.selectedData = undefined
        },
        changePage: (state, action) => {
            state.currentPage = action.payload.currentPage
            state.skipPage = action.payload.skip
            state.pageLimit = action.payload.pageLimit
            state.status = 'loading'
        },
        searchJob: (state, action) => {
            state.search = action.payload
            state.status = 'loading'
        },
        filterChange: (state, action) => {
            const dropDownValue = {
                ...state.filter,
                ...action.payload,
            }
            Object.keys(dropDownValue).forEach((key) => {
                if (dropDownValue[key] === '') {
                    delete dropDownValue[key]
                }
            })
            state.filter = dropDownValue
        },
        clearFilter: (state) => {
            state.filter = {}
        },
        changeModel: (state, action) => {
            state.modelType = action.payload
        },
        clearJobDetails: (state) => {
            state.jobDetails = null
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getListJobs.pending, (state) => {
                state.status = 'loading'
                state.pageLimit = 10
                state.skipPage = 0
                state.jobList = []
                state.isNext = false
            })
            .addCase(getListJobs.fulfilled, (state, action) => {
                state.jobList = action.payload.data.data
                state.isNext = action.payload.data.isNext
                state.status = 'loaded'
            })
            .addCase(getListJobs.rejected, (state) => {
                state.status = 'loaded'
                state.jobList = []
                state.isNext = false
            })
            .addCase(masterDataDropDownList.pending, (state) => {
                state.masterDataDropDownStatus = 'loading'
            })
            .addCase(masterDataDropDownList.fulfilled, (state, action) => {
                state.masterDataDropDownStatus = 'loaded'
                state.masterDataDropDown[state.modelType] =
                    action?.payload.data?.result?.length > 0
                        ? action.payload.data?.result.map((data) => {
                              return {
                                  label: data?.name,
                                  value: data?._id,
                                  logo: data?.logo,
                              }
                          })
                        : []
            })
            .addCase(masterDataDropDownList.rejected, (state) => {
                state.masterDataDropDownStatus = 'loaded'
                state.masterDataDropDown[state.modelType] = []
            })
            .addCase(getJobDetails.pending, (state) => {
                state.status = 'loaded'
                state.jobLoading = true
            })
            .addCase(getJobDetails.fulfilled, (state, action) => {
                state.jobDetails = action.payload.data.data
                state.status = 'loaded'
                state.jobLoading = false
            })
            .addCase(getJobDetails.rejected, (state) => {
                state.status = 'loaded'
                state.jobDetails = null
                state.jobLoading = false
            })
    },
})

export default jobSlice.reducer

export const jobState = (state) => {
    const { jobSlice } = state

    const {
        search,
        status,
        updatedDataStatus,
        formButtonType,
        selectedData,
        modalVariations,
        jobList,
        isNext,
        pageLimit,
        currentPage,
        skipPage,
        filter,
        modelType,
        masterDataDropDownStatus,
        masterDataDropDown,
        jobDetails,
        jobLoading,
    } = jobSlice

    return {
        search,
        status,
        updatedDataStatus,
        formButtonType,
        selectedData,
        modalVariations,
        jobList,
        isNext,
        pageLimit,
        currentPage,
        skipPage,
        filter,
        modelType,
        masterDataDropDownStatus,
        masterDataDropDown,
        jobDetails,
        jobLoading,
    }
}

export const {
    switchJobModal,
    changePage,
    searchJob,
    clearFilter,
    filterChange,
    changeModel,
    clearJobDetails,
} = jobSlice.actions
