// antd
import { Modal } from 'antd'

// components
import ModelHeader from './modelHeader'
import ModalFooter from './modalFooter'

function ModalComponent({
    visibility,
    handleCancel,
    content,
    modalTitle,
    showTitle,
    selectedDataMaster,
    icon,
    masterDataState,
    buttonName,
    selectedDataProfile,
}) {
    return (
        <>
            <Modal
                className="profileModal"
                open={visibility}
                title={
                    <ModelHeader
                        selectedDataMaster={selectedDataMaster}
                        modalTitle={modalTitle}
                        showTitle={showTitle}
                        handleCancel={handleCancel}
                        selectedDataProfile={selectedDataProfile}
                        icon={icon}
                    />
                }
                onCancel={handleCancel}
                destroyOnClose={true}
                closeIcon={<></>}
                cancelButtonProps={{
                    hidden: true,
                }}
                footer={
                    <ModalFooter
                        buttonName={buttonName}
                        selectedState={masterDataState}
                    />
                }
                closable={false}
                centered
                style={{
                    minWidth: '790px',
                    overflowY: 'hidden',
                    padding: 0,
                    borderRadius: '20px',
                }}
                styles={{
                    body: {
                        padding: '25px 50px',
                    },
                }}
            >
                <div
                    style={{
                        overflowY: 'scroll',
                        maxHeight: '50vh',
                    }}
                >
                    {content}
                </div>
            </Modal>
        </>
    )
}
export default ModalComponent
