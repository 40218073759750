import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import InfiniteScroll from 'react-infinite-scroll-component'

// antd
import { Avatar, Empty, Row, Space, Spin, Typography } from 'antd'

// slices
import { changeHistoryPage, historyTaskGet, tasksState } from './tasks.slice'

// helpers
import { unique } from '../../helpers/uniqueArray'

import dayjs from 'dayjs'

function ActivitySection() {
    const dispatch = useDispatch()
    const { Text } = Typography

    const {
        pageLimitHistory,
        skipPageHistory,
        currentPageHistory,
        historyTask,
        isNextHistory,
        selectedTask,
        historyStatus,
        selectedSubTask,
        formSubTask,
    } = useSelector(tasksState)

    const [filteredHistoryTask, setFilteredHistoryTask] = useState([])

    useEffect(() => {
        dispatch(
            historyTaskGet({
                skip: skipPageHistory,
                limit: pageLimitHistory,
                taskId:
                    selectedSubTask && formSubTask
                        ? selectedSubTask
                        : selectedTask?._id,
            })
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // CHECK FOR LAZY LOADING IS_NEXT
    useEffect(() => {
        if (historyTask?.length > 0) {
            let array = []
            historyTask?.length > 0 &&
                historyTask.forEach((data) => {
                    array = [...array, data]
                })
            let newArray = [...array, ...filteredHistoryTask]
            let finalArray = unique(newArray, '_id')
            setFilteredHistoryTask(finalArray)

            if (!isNextHistory) {
                return
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [historyTask])

    // FETCH MORE HISTORY
    async function fetchMoreHistory() {
        if (isNextHistory) {
            await dispatch(
                historyTaskGet({
                    skip: skipPageHistory + pageLimitHistory,
                    limit: pageLimitHistory,
                    taskId: selectedTask?._id,
                })
            )
            dispatch(
                changeHistoryPage({
                    currentPage: currentPageHistory + 1,
                    skip: skipPageHistory + pageLimitHistory,
                    pageLimit: pageLimitHistory,
                })
            )
        }
    }
    return (
        <>
            <Spin spinning={historyStatus === 'loading' ? true : false}>
                {historyTask?.length === 0 ? (
                    <div
                        className={'sub-container'}
                        style={{ display: 'flex' }}
                    >
                        {historyStatus !== 'loading' && (
                            <Empty
                                description={'No Activity Found'}
                                style={{ margin: 'auto' }}
                            />
                        )}
                    </div>
                ) : (
                    <div className={'sub-container'}>
                        <div style={{ margin: 10 }} />
                        <InfiniteScroll
                            dataLength={
                                historyTask?.length > 0
                                    ? historyTask?.length
                                    : 0
                            }
                            next={fetchMoreHistory}
                            hasMore={isNextHistory}
                            style={{
                                overflow: 'inherit',
                            }}
                        >
                            {filteredHistoryTask?.length > 0 &&
                                filteredHistoryTask.map((task, index) => (
                                    <Row
                                        style={{
                                            flexWrap: 'wrap',
                                            marginTop: '10px',
                                        }}
                                    >
                                        <Space>
                                            <Avatar
                                                style={{
                                                    backgroundColor: '#f56a00',
                                                    cursor: 'pointer',
                                                    fontSize: '11px',
                                                }}
                                            >
                                                {task?.createdBy[0]?.name[0].toUpperCase()}
                                                {task?.createdBy[0]?.name.split(
                                                    ' '
                                                )[1] !== undefined &&
                                                    task?.createdBy[0]?.name
                                                        .split(' ')[1][0]
                                                        .toUpperCase()}
                                            </Avatar>
                                            <Row
                                                style={{
                                                    flexWrap: 'wrap',
                                                }}
                                            >
                                                <Text
                                                    style={{
                                                        fontWeight: 500,
                                                        fontSize: '14px',
                                                        marginRight: '5px',
                                                    }}
                                                >
                                                    {task?.createdBy[0]?.name}
                                                </Text>
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: task?.description,
                                                    }}
                                                    style={{
                                                        marginRight: '10px',
                                                    }}
                                                />
                                                <Text
                                                    style={{
                                                        fontWeight: 500,
                                                        fontSize: '14px',
                                                        marginRight: '10px',
                                                    }}
                                                >
                                                    {dayjs(task?.time).format(
                                                        'DD MMM, YYYY'
                                                    )}
                                                </Text>
                                                <Text
                                                    style={{
                                                        fontWeight: 500,
                                                        fontSize: '14px',
                                                    }}
                                                >
                                                    {dayjs(task?.time).format(
                                                        'HH:mm A'
                                                    )}
                                                </Text>
                                            </Row>
                                        </Space>
                                    </Row>
                                ))}
                        </InfiniteScroll>
                    </div>
                )}
            </Spin>
        </>
    )
}

export default ActivitySection
