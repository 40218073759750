import { useSelector } from 'react-redux'

// antd
import { Card, Steps, theme } from 'antd'

// slices
import { profileState } from '../profile.slice'

// assets
import { CompleteStepIcon, IncompleteStepIcon } from '../../../assets/icons'

function StepperSection() {
    const { useToken } = theme
    const { token } = useToken()

    const { profileData } = useSelector(profileState)

    // CHECK IF STEP IS COMPLETE OR NOT AND SER ICON

    function checkCompleteStep(name) {
        if (profileData !== undefined && Object.keys(profileData)?.length > 0) {
            const isFound =
                Object.keys(profileData?.data).includes(name) &&
                name !== 'summary' &&
                name !== 'videos' &&
                name !== 'education'
                    ? profileData?.data[name] &&
                      profileData?.data[name].some(
                          (element) => element?.isCompleted
                      )
                    : name === 'education'
                    ? profileData?.data['education'].some(
                          (element) => element?.isCompleted
                      ) ||
                      profileData?.data['certificates'].some(
                          (element) => element?.isCompleted
                      )
                    : name === 'summary'
                    ? profileData?.data['summary']?.isCompleted &&
                      profileData?.data?.profilePicture?.length > 0 &&
                      profileData?.data?.location !== ''
                    : name === 'videos' &&
                      profileData?.data['videos'].some(
                          (element) => element?.isCompleted
                      )
                    ? true
                    : false

            return isFound ? (
                <CompleteStepIcon />
            ) : (
                <div
                    style={{
                        height: '43px',
                        width: '43px',
                    }}
                >
                    <IncompleteStepIcon />
                </div>
            )
        }
    }

    const items = [
        {
            title: 'Summary',
            icon: checkCompleteStep('summary'),
        },
        {
            title: 'Education',
            icon: checkCompleteStep('education'),
        },
        // {
        //     title: 'Certificates',
        //     icon: checkCompleteStep('certificates'),
        // },
        {
            title: 'Experience',
            icon: checkCompleteStep('experience'),
        },
        {
            title: 'Projects',
            icon: checkCompleteStep('projects'),
        },
        {
            title: 'Videos',
            icon: checkCompleteStep('videos'),
        },
    ]

    return (
        <>
            <Card
                style={{
                    backgroundColor: '#F7F8FD',
                }}
            >
                <Steps
                    current={0}
                    labelPlacement="vertical"
                    items={items}
                    style={{
                        color: token.colorPalette.textColor.secondary,
                    }}
                    className="stepperProfile"
                />
            </Card>
        </>
    )
}

export default StepperSection
