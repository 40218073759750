// FILE TYPES
export const fileTypes = ['.png', '.jpg', '.jpeg', '.gif', '.svg', '.webp']

// VIDEO TYPES
export const videoTypes = ['.mp4', '.mov', '.wmv', '.mkv', '.webm']

// PDF TYPES
export const pdfTypes = ['.pdf']

// OTHER TYPES
export const otherTypesFile = ['.xls', 'xlsx', '.zip', '.mp3', '.txt', '.csv']
