import React from 'react'

function WorkSpaceIcon(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 24 24"
            {...props}
        >
            <path
                fill={props.color ? props.color : '#fff'}
                d="M21 8h-8a1 1 0 110-2h8a1 1 0 110 2zm0 4h-8a1 1 0 010-2h8a1 1 0 010 2zM21 16H3a1 1 0 010-2h18a1 1 0 010 2zm-8 4H3a1 1 0 010-2h10a1 1 0 010 2z"
            ></path>
            <rect
                width="8"
                height="8"
                x="2"
                y="4"
                fill={props.color ? props.color : '#fff'}
                rx="2"
            ></rect>
        </svg>
    )
}

export default WorkSpaceIcon
