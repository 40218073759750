import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'

// antd
import { Row, message, theme } from 'antd'

// slices
import {
    changeEditInput,
    setEpics,
    setPointerEvent,
    setSprintTasks,
    sprintsState,
    updateEpicList,
} from './sprints.slice'
import { loginState } from '../login/login.slice'
import { taskUpdate } from '../tasks/tasks.slice'

// helpers
import { viewFieldCheck } from '../../helpers/permissionCheck'

// section
import EpicCardSectionFinal from './epicCardFinal.section'

const SprintsTabDetail = ({ props }) => {
    const { state } = props

    const { useToken } = theme

    const { token } = useToken()

    const dispatch = useDispatch()

    const { userProfile } = useSelector(loginState)
    const { sprints, listTasks, epics, editInput, pointerEvent } =
        useSelector(sprintsState)

    // CHANGE EPIC LOGIC
    const orderColumns = useCallback(
        async (initial, final, draggableId) => {
            const newEpics = structuredClone(epics)
            newEpics.forEach((column) => {
                if (column.position === initial) {
                    column.position = final
                } else {
                    if (
                        column.position < Math.min(initial, final) ||
                        column.position > Math.max(initial, final)
                    ) {
                        return
                    } else {
                        if (initial < final) {
                            column.position--
                        } else {
                            column.position++
                        }
                    }
                }
            })
            newEpics.sort((a, b) => a.position - b.position)
            dispatch(setEpics(newEpics))
            dispatch(setPointerEvent(false))

            const result = await dispatch(
                updateEpicList({
                    epicEdit: {
                        position: final,
                        initialPosition: initial,
                        projectId: userProfile?.activeProject?._id,
                    },
                    id: draggableId,
                })
            )
            const data = result?.payload?.data
            if (data) {
                const { success, message: checkMessage } = data

                if (success) {
                    // dispatch(
                    //     epicsList({ skip: skipPageEpic, limit: pageLimitEpic })
                    // )
                    dispatch(setPointerEvent(true))
                } else {
                    dispatch(setPointerEvent(true))
                    if (checkMessage) {
                        message.error(checkMessage)
                    } else {
                        message.error('Something went wrong, try again later.')
                    }
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [epics, dispatch]
    )

    // CHANGE TASK OF EPIC IN SAME EPIC OR DIFFERENT EPIC
    const orderCards = useCallback(
        async (source, destination, cardId) => {
            const newTasks = structuredClone(listTasks)
            if (source.droppableId === destination.droppableId) {
                const { list } = newTasks.find(
                    (x) => x?._id === source.droppableId
                )
                list.forEach((card) => {
                    if (card.epicPosition === source.index) {
                        card.epicPosition = destination.index
                    } else {
                        if (
                            card.epicPosition <
                                Math.min(source.index, destination.index) ||
                            card.epicPosition >
                                Math.max(source.index, destination.index)
                        ) {
                            return
                        } else {
                            if (source.index < destination.index) {
                                card.epicPosition--
                            } else {
                                card.epicPosition++
                            }
                        }
                    }
                })
                list.sort((a, b) => a.epicPosition - b.epicPosition)
                dispatch(setSprintTasks(newTasks))
                dispatch(setPointerEvent(false))
                const result = await dispatch(
                    taskUpdate({
                        updateTask: {
                            initialPosition: source?.index,
                            position: destination?.index,
                            projectId: userProfile?.activeProject?._id,
                            epic: destination?.droppableId,
                        },
                        _id: cardId,
                    })
                )

                const data = result?.payload?.data
                if (data) {
                    const { success } = data
                    if (success) {
                        // dispatch(await sprintsList())
                        dispatch(setPointerEvent(true))
                    } else {
                        message.error('Something went wrong, try again later.')
                        dispatch(setPointerEvent(true))
                    }
                }
            } else {
                const sourceColumnIndex = newTasks.findIndex(
                    (x) => x?._id === source.droppableId
                )
                const sourceCards = newTasks[sourceColumnIndex].list
                const destinationColumnIndex = newTasks.findIndex(
                    (x) => x?._id === destination.droppableId
                )

                const destinationCards = newTasks[destinationColumnIndex].list
                const cardIndex = sourceCards.findIndex(
                    (x) => x?._id === cardId
                )
                const draggedCard = sourceCards[cardIndex]
                sourceCards.forEach((card) => {
                    if (card.epicPosition > source.index) {
                        card.epicPosition--
                    }
                })
                if (destinationCards?.length > 0) {
                    destinationCards.forEach((card) => {
                        if (card.epicPosition >= destination.index) {
                            card.epicPosition++
                        }
                    })
                }

                delete sourceCards[cardIndex]
                draggedCard.epicPosition = destination.index
                if (destinationCards?.length > 0) {
                    destinationCards.splice(destination.index, 0, draggedCard)
                } else {
                    destinationCards.push(draggedCard)
                }
                sourceCards.sort((a, b) => a.epicPosition - b.epicPosition)
                if (destinationCards?.length > 0) {
                    destinationCards.sort(
                        (a, b) => a.epicPosition - b.epicPosition
                    )
                }
                dispatch(setSprintTasks(newTasks))
                dispatch(setPointerEvent(false))

                const result = await dispatch(
                    taskUpdate({
                        updateTask: {
                            initialPosition: source?.index,
                            position: destination?.index,
                            // status: destination?.droppableId,
                            projectId: userProfile?.activeProject?._id,
                            // epic: draggedCard?.epic[0]?._id,
                            epic: destination?.droppableId,
                        },
                        _id: cardId,
                    })
                )

                const data = result?.payload?.data
                if (data) {
                    const { success } = data
                    if (success) {
                        // dispatch(await sprintsList())
                        dispatch(setPointerEvent(true))
                    } else {
                        message.error('Something went wrong, try again later.')
                        dispatch(setPointerEvent(true))
                    }
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [listTasks, dispatch]
    )

    // ONDRAG CARD AND EPIC INITIALLY CHECK CONDITION
    const onDragEnd = useCallback(
        (draggable, args) => {
            const { draggableId } = draggable

            console.log(draggable, args)
            if (!draggable.destination) {
                return
            } else {
                const source = draggable?.source
                const destination = draggable?.destination
                if (destination?.droppableId.includes(':')) {
                    return
                }

                console.log(source, destination)

                if (source !== null && destination !== null) {
                    if (
                        source?.droppableId === destination?.droppableId &&
                        source?.index === destination?.index
                    ) {
                        return
                    } else {
                        if (draggable?.type === 'cards') {
                            orderColumns(
                                source?.index,
                                destination?.index,
                                draggableId
                            )
                        } else {
                            orderCards(source, destination, draggableId)
                        }
                    }
                }
            }
        },
        [orderCards, orderColumns]
    )

    return (
        <>
            {viewFieldCheck('epics', 'title', userProfile) && (
                <div
                    className={'sub-container'}
                    style={{
                        overflowX: 'scroll',
                        height: 'auto',
                        position: 'relative',
                        backgroundColor:
                            token.colorPalette.baseColor.quaternary,
                        padding: '24px',
                        borderRadius: '12px',
                    }}
                    onClick={(e) => {
                        if (
                            editInput?.open === true &&
                            !e.target.classList.contains('active-epic')
                        ) {
                            dispatch(
                                changeEditInput({
                                    open: false,
                                    id: '',
                                })
                            )
                        }
                    }}
                >
                    <div
                        style={{
                            pointerEvents: pointerEvent ? 'auto' : 'none',
                        }}
                    />
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable
                            droppableId={'cards'}
                            type={'cards'}
                            direction={'horizontal'}
                        >
                            {(provided) => {
                                return (
                                    <Row
                                        wrap={false}
                                        gutter={13}
                                        ref={provided.innerRef}
                                        style={{
                                            position: 'relative',
                                        }}
                                        {...provided.droppableProps}
                                    >
                                        {epics?.length > 0 &&
                                            epics.map((card) => (
                                                <EpicCardSectionFinal
                                                    key={card?._id}
                                                    props={{
                                                        card,
                                                        sprints: sprints,
                                                        filteredAssignees:
                                                            state?.filteredAssignees,
                                                        epicList: epics,
                                                        tasks: listTasks,
                                                        sprintId:
                                                            state?.activeTab,
                                                    }}
                                                />
                                            ))}
                                        {provided.placeholder}
                                    </Row>
                                )
                            }}
                        </Droppable>
                    </DragDropContext>
                </div>
            )}
        </>
    )
}

export default SprintsTabDetail
