import React from 'react'

function UserInactiveIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="252"
            height="240"
            fill="none"
            viewBox="0 0 252 240"
        >
            <g clipPath="url(#clip0_1914_4949)">
                <path
                    fill="#3F3D56"
                    d="M137.265 227.729c63.367 0 114.736-5.737 114.736-12.814s-51.369-12.813-114.736-12.813c-63.367 0-114.736 5.736-114.736 12.813 0 7.077 51.37 12.814 114.736 12.814z"
                ></path>
                <path
                    fill="#FFB8B8"
                    d="M194.166 200.738l1.979 6.094s9.53 9.841 6.083 12.509c-3.447 2.667-11.18-11.394-11.18-11.394l-1.425-6.88 4.543-.329z"
                ></path>
                <path
                    fill="#4F46E5"
                    d="M175.883 153.57l2.884-.961s4.487 1.282 5.128 5.767c.641 4.484 5.448 27.229 5.448 27.229s1.923.961.641 2.562c-1.282 1.602 6.41 16.018 6.41 16.018l-6.089 2.883s-12.179-12.814-12.179-14.095c0-1.282.321-2.563.321-3.845 0-1.281-.321-1.281 0-2.242.214-.837.321-1.698.32-2.563l-2.243-5.125-.641-25.628zM28.978 175.543c16.004 0 28.978-26.46 28.978-59.1s-12.974-59.1-28.978-59.1S0 83.804 0 116.444s12.974 59.1 28.978 59.1z"
                ></path>
                <path
                    fill="#3F3D56"
                    d="M28.712 117.058c1.445-25.726 4.357-43.103 4.387-43.275l-.632-.107c-.03.172-2.947 17.577-4.395 43.336-1.336 23.774-1.658 59.733 4.395 96.034l.632-.105c-6.043-36.239-5.721-72.144-4.387-95.883z"
                ></path>
                <path
                    fill="#3F3D56"
                    d="M54.879 104.46l-26.201 14.017.302.564 26.2-14.016-.301-.565zM1.32 111.482l-.302.565 26.204 14.01.302-.565-26.204-14.01z"
                ></path>
                <path
                    fill="#E6E6E6"
                    d="M97.611 161.746c21.901 0 39.654-36.208 39.654-80.873C137.265 36.208 119.512 0 97.611 0c-21.9 0-39.654 36.208-39.654 80.873 0 44.665 17.754 80.873 39.654 80.873z"
                ></path>
                <path
                    fill="#3F3D56"
                    d="M97.128 81.702c1.978-35.21 5.964-58.992 6.004-59.228l-.632-.107c-.04.236-4.031 24.047-6.012 59.29-1.828 32.526-2.269 81.724 6.012 131.389l.633-.105c-8.271-49.603-7.831-98.747-6.005-131.239z"
                ></path>
                <path
                    fill="#3F3D56"
                    d="M133.108 64.577l-35.854 19.18.302.566 35.854-19.181-.302-.565zM59.707 74.189l-.303.565 35.86 19.171.302-.565-35.86-19.171z"
                ></path>
                <path
                    fill="#E6E6E6"
                    d="M64.576 193.261c8.182 0 14.815-13.528 14.815-30.216 0-16.689-6.633-30.217-14.815-30.217-8.183 0-14.816 13.528-14.816 30.217 0 16.688 6.633 30.216 14.816 30.216z"
                ></path>
                <path
                    fill="#3F3D56"
                    d="M64.562 164.347l.088.164 13.396-7.167-.302-.565-13.154 7.037c.728-13.39 2.217-22.38 2.247-22.557l-.632-.107c-.033.194-1.802 10.868-2.43 26.315l-13.266-7.098-.302.565 13.396 7.167.16-.299c-.502 12.749-.221 28.69 2.442 44.659l.632-.105c-2.903-17.411-2.967-34.79-2.275-48.009z"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M179.805 123.159c-5.152-8.775-15.344-9.184-15.344-9.184s-9.931-1.281-16.302 12.085c-5.939 12.458-14.134 24.487-1.32 27.403l2.315-7.263 1.433 7.804c1.825.132 3.655.163 5.483.094 13.723-.447 26.793.131 26.372-4.834-.559-6.6 2.32-17.66-2.637-26.105zM143.897 193.453a19.516 19.516 0 01-.624 2.712c-.307.868-.78 1.672-1.06 2.55-.893 2.797.345 5.917 2.323 8.076a13.045 13.045 0 006.373 3.816c1.784.453 3.639.518 5.478.58 5.091.175 10.312.33 15.136-1.319a26.307 26.307 0 004.398-2.014 3.3 3.3 0 001.141-.906c.368-.528.415-1.213.412-1.859-.011-2.175-.449-4.34-.345-6.513.058-1.204.281-2.407.171-3.607a6.64 6.64 0 00-5.043-5.732c-1.369-.296-2.788-.135-4.181.001-3.617.35-7.249.514-10.882.49-3.712-.024-7.401-.595-11.094-.655-1.092-.017-1.07.322-1.418 1.388-.32.981-.582 1.98-.785 2.992z"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M129.382 191.686a10.881 10.881 0 00-3.055-.328 7.7 7.7 0 00-3.096.867 7.7 7.7 0 00-2.474 2.053 7.853 7.853 0 00-1.402 2.912 7.856 7.856 0 00-.107 3.231 12.987 12.987 0 001.21 3.335c.81 1.658 1.782 3.312 3.282 4.374a12.707 12.707 0 002.897 1.39l7.9 3.001c1.162.441 2.325.883 3.498 1.292a67.926 67.926 0 0018.272 3.662c1.897.114 3.823.146 5.674-.289.452-.06.866-.286 1.161-.633.151-.265.24-.561.26-.865l.486-3.431a2.892 2.892 0 00-.041-1.446c-.223-.577-.781-.94-1.313-1.248-5.194-3.007-11.258-4.667-15.63-8.794-1.029-.972-1.891-2.512-3.141-3.172-1.418-.749-3.088-1.176-4.554-1.866-3.204-1.508-6.354-3.241-9.827-4.045z"
                ></path>
                <path
                    fill="#000"
                    d="M137.268 196.312c2.792.882 14.582 6.994 16.603 9.127-.271.099-9.461-5.094-9.737-5.177-2.507-.758-5.027-1.522-7.408-2.632-.479-.223-3.111-1.273-2.992-1.882.135-.687 3.038.407 3.534.564z"
                    opacity="0.1"
                ></path>
                <path
                    fill="#FFB8B8"
                    d="M172.106 219.255a.651.651 0 00.443-.131.693.693 0 00.139-.514l-.013-4.674c-1.334-.64-2.825-.846-4.289-1.043l-6.83-.92c.157.021-1.068 2.966-.88 3.359.331.693 2.861 1.307 3.574 1.634 2.482 1.137 5.061 2.387 7.856 2.289z"
                ></path>
                <path
                    fill="#FFB8B8"
                    d="M150.676 213.92c-1.29.207-2.729.39-3.564 1.402-1.059 1.283-.608 3.197-.103 4.787.075.321.225.62.439.871.345.343.873.393 1.357.424 1.176.075 2.427.134 3.455-.448.466-.31.914-.646 1.342-1.006a8.556 8.556 0 012.468-1.137 24.79 24.79 0 014.954-1.03 7.665 7.665 0 001.951-.346 2.166 2.166 0 001.374-1.346c.238-.849-.251-1.715-.721-2.46-.585-.928-1.205-1.905-2.077-2.588-1.273-.997-1.902-.232-3.184.406a26.906 26.906 0 01-7.691 2.471z"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M147.306 215.09l-8.189-2.44a10.742 10.742 0 00-3.047-.595c-1.038 0-2.125.344-2.798 1.141-.635.751-.817 1.778-.975 2.752l-.339 2.084a10.51 10.51 0 00-.189 3.199c.154 1.062.692 2.122 1.626 2.636 1.133.624 2.522.319 3.787.058a40.873 40.873 0 017.759-.838c.98-.012 2.008 0 2.875-.463 1.029-.55 1.645-1.713 1.751-2.883.161-1.78-.428-4.105-2.261-4.651zM175.324 212.964c1.062-.187 1.998-.796 3.016-1.155 1.431-.505 2.983-.505 4.5-.499.556.002 1.149.014 1.613.325.668.449.839 1.346.953 2.147l.685 4.834c.129.909.257 1.842.056 2.738-.201.896-.806 1.759-1.688 1.99-1.071.281-2.12-.409-3.131-.861-2.527-1.133-5.464-.843-8.099-1.69-.489-.157-1.013-.397-1.212-.873a2.027 2.027 0 01-.095-.836c.046-1.553-.279-3.675.185-5.16.417-1.335 2.032-.96 3.217-.96z"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M192.653 189.739a14.108 14.108 0 015.009-.345c1.339.149 2.719.524 3.697 1.459 1.19 1.137 1.572 2.896 1.604 4.549a14.284 14.284 0 01-3.064 9.077 8.188 8.188 0 01-1.752 1.7c-.716.452-1.481.823-2.279 1.107l-17.692 7.036c-4.655 1.851-9.325 3.707-14.18 4.927a1.105 1.105 0 01-1.233-.186 1.099 1.099 0 01-.254-.357 37.076 37.076 0 01-3.878-7.152.64.64 0 01-.05-.535.639.639 0 01.261-.225l12.514-7.248a12.666 12.666 0 002.547-1.77 6.378 6.378 0 001.308-1.835c.259-.546.283-1.835.615-2.239.324-.395 1.434-.427 1.946-.582a17.852 17.852 0 002.12-.808c2.799-1.263 5.291-3.097 7.975-4.575a20.368 20.368 0 014.786-1.998z"
                ></path>
                <path
                    fill="#000"
                    d="M185.559 195.155c-2.15 1.628-4.934 3.124-7.065 4.795-.727.57-12.85 7.442-13.021 8.355 1.988.337 14.651-8.594 16.463-9.485 1.811-.892 3.417-2.148 5.009-3.396.405-.318 2.124-1.274.96-1.629-.656-.2-1.862 1.035-2.346 1.36z"
                    opacity="0.1"
                ></path>
                <path
                    fill="#FFB8B8"
                    d="M164.026 145.241c6.726 0 12.179-5.45 12.179-12.173s-5.453-12.173-12.179-12.173-12.178 5.45-12.178 12.173 5.452 12.173 12.178 12.173z"
                ></path>
                <path
                    fill="#FFB8B8"
                    d="M169.153 143.317s-1.923 4.805 1.603 6.727c3.525 1.922-10.897 17.298-10.897 17.298l-6.41-18.58s5.449-1.922 4.487-7.047l11.217 1.602z"
                ></path>
                <path
                    fill="#4F46E5"
                    d="M161.142 156.452l7.762-8.565 10.506 5.361-1.603 37.801-2.564 2.883s-10.896 9.61-27.562 1.602c0 0-5.769-4.805-5.448-12.173.32-7.368-.641-8.329-.641-8.329l-.321-25.948 15.823-3.104 4.048 10.472z"
                ></path>
                <path
                    fill="#FFB8B8"
                    d="M125.552 197.211l-1.979 6.094s-9.53 9.841-6.083 12.508c3.446 2.668 11.18-11.394 11.18-11.394l1.425-6.879-4.543-.329z"
                ></path>
                <path
                    fill="#4F46E5"
                    d="M143.834 150.047l-2.885-.961s-4.487 1.281-5.128 5.766-5.448 27.229-5.448 27.229-1.923.961-.641 2.563c1.282 1.602-6.41 16.017-6.41 16.017l6.09 2.883s12.178-12.813 12.178-14.095c0-1.281-.32-2.563-.32-3.844 0-1.281.32-1.281 0-2.242a10.31 10.31 0 01-.321-2.563l2.244-5.126.641-25.627z"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M177.527 122.313l-10.069-5.317-13.905 2.175-2.877 12.811 7.161-.278 2.001-4.706v4.628l3.305-.128 1.918-7.492 1.198 7.976 11.748-.242-.48-9.427z"
                ></path>
                <path
                    fill="#FF6584"
                    d="M106.148 204.725v18.477H49.684c0-4.9 1.948-9.6 5.414-13.065a18.49 18.49 0 0113.072-5.412c5.294-1.65 11.415-2.175 18.056-2.002 6.266.16 12.993.945 19.922 2.002z"
                ></path>
                <path
                    fill="#3F3D56"
                    d="M98.082 219.172h26.552a1.68 1.68 0 110 3.359H98.082v-3.359zM102.68 188.914l7.117-8.623a1.867 1.867 0 013.482.298l2.806 9.904-13.405-1.579z"
                ></path>
                <path
                    fill="#FF6584"
                    d="M94.049 193.304l2.689-10.851a1.866 1.866 0 012.318-1.812c.368.091.7.292.951.577l6.814 7.719-12.772 4.367zM49.684 223.203h5.377v11.422a5.371 5.371 0 01-3.32 4.966 5.382 5.382 0 01-2.057.409v-16.797zM97.074 219.844h26.552a1.68 1.68 0 110 3.359H97.074v-3.359z"
                ></path>
                <path
                    fill="#000"
                    d="M106.148 204.725V223.1a18.16 18.16 0 01-19.136-12.294 18.136 18.136 0 01-.785-8.083c6.265.16 12.992.945 19.921 2.002z"
                    opacity="0.2"
                ></path>
                <path
                    fill="#FF6584"
                    d="M106.147 223.204c10.024 0 18.15-8.122 18.15-18.141 0-10.019-8.126-18.141-18.15-18.141-10.023 0-18.149 8.122-18.149 18.141 0 10.019 8.126 18.141 18.149 18.141z"
                ></path>
                <path
                    fill="#3F3D56"
                    d="M123.626 211.11c1.114 0 2.017-2.106 2.017-4.704 0-2.597-.903-4.703-2.017-4.703-1.114 0-2.017 2.106-2.017 4.703 0 2.598.903 4.704 2.017 4.704zM118.752 202.711c.279 0 .504-.526.504-1.176 0-.649-.225-1.176-.504-1.176-.278 0-.504.527-.504 1.176 0 .65.226 1.176.504 1.176zM122.449 200.359c.279 0 .505-.526.505-1.175 0-.65-.226-1.176-.505-1.176-.278 0-.504.526-.504 1.176 0 .649.226 1.175.504 1.175zM104.626 186.718s-1.009-1.754-1.009-2.823zM105.07 185.586s.55-1.947 1.315-2.695z"
                ></path>
            </g>
            <defs>
                <clipPath id="clip0_1914_4949">
                    <path fill="#fff" d="M0 0H252V240H0z"></path>
                </clipPath>
            </defs>
        </svg>
    )
}

export default UserInactiveIcon
