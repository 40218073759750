export const departmentOptions = [
    {
        label: 'Development',
        value: 'Development',
    },
    {
        label: 'Quality Analysis',
        value: 'Quality Analysis',
    },
    {
        label: 'Business Analysis',
        value: 'Business Analysis',
    },
    {
        label: 'Design & Marketing',
        value: 'Design & Marketing',
    },
    {
        label: 'Management',
        value: 'Management',
    },
]
export const designationOptions = [
    {
        label: 'Software Development Engineer 1',
        value: 'Software Development Engineer 1',
    },
    {
        label: 'Software Development Engineer 2',
        value: 'Software Development Engineer 2',
    },
    {
        label: 'Software Development Engineer 3',
        value: 'Software Development Engineer 3',
    },
    {
        label: 'Tech Lead',
        value: 'Tech Lead',
    },
    {
        label: 'Quality Assurance Engineer',
        value: 'Quality Assurance Engineer',
    },
    {
        label: 'UI/UX Designer',
        value: 'UI/UX Designer',
    },
    {
        label: 'Graphic Designer',
        value: 'Graphic Designer',
    },
    {
        label: 'Digital Marketing Specialist',
        value: 'Digital Marketing Specialist',
    },
    {
        label: 'Project Manager',
        value: 'Project Manager',
    },
    {
        label: 'Human Resource Manager',
        value: 'Human Resource Manager',
    },
    {
        label: 'Operations Head',
        value: 'Operations Head',
    },
    {
        label: 'Business Development Manager',
        value: 'Business Development Manager',
    },
    {
        label: 'Director',
        value: 'Director',
    },
]

export const employmentOptions = [
    { label: 'Full Time', value: 'Full Time' },
    { label: 'Part Time', value: 'Part Time' },
]

export const roleOptions = [
    { label: 'Admin', value: 'admin' },
    { label: 'Member', value: 'member' },
]
