import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// antd
import { Row, Typography, theme } from 'antd'

// slices
import {
    changePage,
    roleListGet,
    roleState,
    searchRole,
    switchRoleModal,
    totalRoleCount,
} from './role.slice'

// components
import PanelHeader from '../../components/panelHeader/panelHeader'

// pages
import RoleList from './roleList'

// assets
import { BackIcon, TeamIcon } from '../../assets/icons'

// modal
import AddRoleModal from './addRoleModal'

function RoleModule() {
    const { Title } = Typography

    const { useToken } = theme
    const { token } = useToken()

    const dispatch = useDispatch()

    const navigate = useNavigate()

    const { pageLimit, skipPage, search, modalVariations } =
        useSelector(roleState)

    // GET TOTAL PROJECTS COUNT AND PROJECTS LIST
    useEffect(() => {
        dispatch(
            totalRoleCount({
                search: search,
            })
        )
        dispatch(
            roleListGet({
                limit: pageLimit,
                skip: skipPage,
                search: search,
            })
        )
        dispatch(
            changePage({
                currentPage: 1,
                skip: 0,
                pageLimit,
            })
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function refreshPage() {
        dispatch(
            changePage({
                currentPage: 1,
                skip: 0,
                pageLimit,
            })
        )
    }

    return (
        <>
            <div
                className={'container'}
                style={{
                    zIndex: 10,
                    minHeight: '100vh',
                    padding: '35px',
                }}
            >
                <>
                    <Row
                        align={'middle'}
                        justify={'space-between'}
                        style={{
                            marginBottom: '20px',
                        }}
                    >
                        <Row
                            align={'middle'}
                            style={{
                                columnGap: '13px',
                            }}
                        >
                            <div
                                style={{
                                    height: '30px',
                                    width: '30px',
                                }}
                            >
                                <TeamIcon
                                    color={
                                        token.colorPalette.baseColor.tertiary
                                    }
                                />
                            </div>
                            <Title
                                level={3}
                                style={{
                                    textTransform: 'capitalize',
                                }}
                            >
                                Add Role
                            </Title>
                        </Row>
                        <div
                            style={{
                                height: '30px',
                                width: '30px',
                                cursor: 'pointer',
                            }}
                            onClick={() => navigate(-1)}
                        >
                            <BackIcon />
                        </div>
                    </Row>
                    <PanelHeader
                        props={{
                            panelName: 'add role',
                            searchBar: true,
                            filterBar: false,
                            teamMembersBar: false,
                            permissionAdd: true,
                            icon: true,
                            showProject: false,
                            count: totalRoleCount,
                            action: roleListGet,
                            search: searchRole,
                            refreshPage: refreshPage,
                            pageLimit: pageLimit,
                            panelIcon: (
                                <TeamIcon
                                    color={
                                        token.colorPalette.baseColor.tertiary
                                    }
                                />
                            ),
                            onClick: () => {
                                dispatch(
                                    switchRoleModal({
                                        open: true,
                                    })
                                )
                                // setModalVisibility(true)
                            },
                        }}
                    />
                    <RoleList
                        openModal={() =>
                            dispatch(
                                switchRoleModal({
                                    open: true,
                                })
                            )
                        }
                    />
                    <AddRoleModal
                        onCancel={() => {
                            dispatch(
                                switchRoleModal({
                                    open: false,
                                })
                            )
                        }}
                        visibility={modalVariations?.open}
                    />
                </>
            </div>
        </>
    )
}

export default RoleModule
