import axiosNew from '../../helpers/axios'

// LIST INQUIRY FOR SUPER ADMIN SWIMLANE WISE
export const listInquirySwimLanes = (payload) => {
    const { skip = 0, limit = 0, filter, search = '', workspaceId } = payload
    return new Promise(async (resolve) => {
        try {
            let data = {
                skip,
                limit,
                filter,
            }
            if (search?.length > 0) {
                data['search'] = search
            }
            const inquiries = await axiosNew({
                // url: `inquiry/list-as-swimlanes/${workspaceId}`,
                url: `inquiry/list-as-swimlanes/${
                    workspaceId ? workspaceId : 'all'
                }`,
                data,
            })
            resolve({ data: inquiries?.data?.data ?? [] })
        } catch (error) {
            resolve({ data: [] })
        }
    })
}

// COUNT TOTAL INQUIRIES
export const countInquiry = async (payload) => {
    const { workspaceId } = payload

    return new Promise(async (resolve) => {
        try {
            let data = {}
            if (payload?.length > 0) {
                data['search'] = {
                    inquiryName: payload,
                }
            }
            const count = await axiosNew({
                url: `inquiry/count?workspaceId=${workspaceId}`,
                data,
            })
            resolve({ data: count?.data?.result ?? 0 })
        } catch (error) {
            resolve({ data: 0 })
        }
    })
}

// GET INQUIRY FOR SUPER ADMIN SWIMLANE WISE AND ID WISE
export const getInquiry = (payload) => {
    return new Promise(async (resolve) => {
        try {
            const inquiryGet = await axiosNew({
                url: `inquiry/list/${payload?.id}/${payload?.workspaceId}`,
            })
            resolve({ data: inquiryGet?.data?.result ?? undefined })
        } catch (error) {
            resolve({ data: [] })
        }
    })
}

// LIST INQUIRY FOR WORKSPACE ADMIN
export const listInquiry = (payload) => {
    const { skip = 0, limit = 0, filter, search = '', workspaceId } = payload
    return new Promise(async (resolve) => {
        try {
            let data = {
                skip,
                limit,
                filter,
            }
            if (search?.length > 0) {
                data['search'] = search
            }
            const inquiries = await axiosNew({
                url: `inquiry/list?workspaceId=${workspaceId}`,
                data,
            })
            resolve({
                data: inquiries?.data?.result ?? [],
                isNext: inquiries?.data?.isNext,
            })
        } catch (error) {
            resolve({ data: [] })
        }
    })
}

// CREATE INQUIRY
export const createInquiry = async (payload) => {
    return new Promise(async (resolve) => {
        try {
            let data = payload ?? {}
            const create = await axiosNew({
                url: 'inquiry/create',
                data,
            })
            resolve({ data: create?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// UPDATE INQUIRY
export const updateInquiry = async (payload) => {
    return new Promise(async (resolve) => {
        const { _id, workspaceId } = payload
        try {
            let data = payload.updatedData ?? {}
            const update = await axiosNew({
                url: `inquiry/update/${_id}/${workspaceId}`,
                data,
            })
            resolve({ data: update?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// UPDATE INQUIRY POSITION
export const updateInquiryPosition = async (payload) => {
    return new Promise(async (resolve) => {
        const { _id, workspaceId } = payload
        try {
            let data = payload.updatedData ?? {}
            const updatePosition = await axiosNew({
                url: `inquiry/update-position/${_id}/${workspaceId}`,
                data,
            })
            resolve({ data: updatePosition?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// LIST INQUIRY FILES
export const listInquiryFiles = async (payload) => {
    const { id, workspaceId, search = '', skip = 0, limit = 0 } = payload
    return new Promise(async (resolve) => {
        try {
            let data = {
                skip,
                limit,
            }
            if (search?.length > 0) {
                data['search'] = {
                    displayName: search,
                }
            }
            const listFiles = await axiosNew({
                url: `inquiry/files/${id}/${workspaceId}`,
                data,
            })
            resolve({ data: listFiles?.data?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// ADD / REMOVE INQUIRY FILES
export const addRemoveInquiryFiles = async (payload) => {
    return new Promise(async (resolve) => {
        const { id, workspaceId } = payload
        try {
            let data = payload.fileObject ?? {}
            const fileUpdate = await axiosNew({
                url: `inquiry/add-remove-attachment/${id}/${workspaceId}`,
                data,
                method: 'PUT',
            })
            resolve({ data: fileUpdate?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// COUNT INQUIRY FILES
export const countInquiryFiles = async (payload) => {
    const { search, id, workspaceId } = payload

    return new Promise(async (resolve) => {
        try {
            let data = {}
            if (search?.length > 0) {
                data['search'] = {
                    displayName: search,
                }
            }
            const count = await axiosNew({
                url: `inquiry/files-count/${id}/${workspaceId}`,
                data,
            })
            resolve({ data: count?.data?.result ?? 0 })
        } catch (error) {
            resolve({ data: 0 })
        }
    })
}

// GET INDIVIDUAL FILE
export const getInquiryAttachment = (payload) => {
    const { inquiryId, fileId, workspaceId } = payload
    return new Promise(async (resolve) => {
        try {
            const filesData = await axiosNew({
                url: `inquiry/files/${inquiryId}/${workspaceId}/${fileId}`,
            })
            resolve({
                data: filesData?.data?.data ?? [],
                isNext: filesData?.data?.isNext,
            })
        } catch (error) {
            resolve({ data: [] })
        }
    })
}

// UPDATE ATTACHMENT NAME
export const updateInquiryAttachment = async (payload) => {
    return new Promise(async (resolve) => {
        try {
            let { updatedData, id, workspaceId, fileId } = payload ?? {}
            const update = await axiosNew({
                url: `inquiry/update-file-name/${id}/${fileId}/${workspaceId}`,
                data: updatedData,
            })
            resolve({ data: update?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}
