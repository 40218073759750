import axiosNew from '../../../../helpers/axios'

// ADD INQUIRY ROLE
export const createInquiryRole = async (payload) => {
    const { inquiryId, workspaceId } = payload
    return new Promise(async (resolve) => {
        try {
            let data = payload.addData ?? {}
            const create = await axiosNew({
                url: `inquiry-role/create/${workspaceId}`,
                data,
            })
            resolve({ data: create?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// LIST OF INQUIRY ROLES LIST
export const listInquiryRoles = (payload) => {
    const {
        search = '',
        skip = 0,
        inquiryId,
        workspaceId,
        filter = {},
        limit = 0,
    } = payload
    return new Promise(async (resolve) => {
        try {
            let data = {
                skip,
                filter,
                limit,
                populate: {
                    name: [
                        {
                            path: 'skills',
                            model: 'skills',
                        },
                        {
                            path: 'shortListedTalent',
                            model: 'User',
                        },
                        {
                            path: 'matchedTalent',
                            model: 'User',
                        },
                    ],
                },
            }
            if (search?.length > 0) {
                data['search'] = {
                    title: search,
                }
            }
            const listRoles = await axiosNew({
                url: `inquiry-role/list/${inquiryId}/${workspaceId}`,
                data,
            })
            resolve({
                data: listRoles?.data?.result ?? [],
                isNext: listRoles?.data?.isNext,
            })
        } catch (error) {
            resolve({ data: [] })
        }
    })
}

// COUNT INQUIRY ROLES
export const countInquiryRole = async (payload) => {
    const { inquiryId, workspaceId, search } = payload
    return new Promise(async (resolve) => {
        try {
            let data = {}
            if (search?.length > 0) {
                data['search'] = {
                    title: search,
                }
            }
            const count = await axiosNew({
                url: `inquiry-role/count/${inquiryId}/${workspaceId}`,
                data,
            })
            resolve({ data: count?.data?.result ?? 0 })
        } catch (error) {
            resolve({ data: 0 })
        }
    })
}

// INQUIRY PARTICULAR ROLE GET
export const getInquiryRole = (payload) => {
    const { inquiryId, workspaceId, roleId } = payload

    return new Promise(async (resolve) => {
        try {
            let data = {
                populate: {
                    name: [
                        {
                            path: 'skills',
                            model: 'skills',
                        },
                        {
                            path: 'shortListedTalent',
                            model: 'User',
                        },
                        {
                            path: 'matchedTalent',
                            model: 'User',
                        },
                    ],
                },
            }
            const getRole = await axiosNew({
                url: `inquiry-role/list/${inquiryId}/${workspaceId}/${roleId}`,
                data,
            })
            resolve({
                data: getRole?.data?.result ?? [],
            })
        } catch (error) {
            resolve({ data: [] })
        }
    })
}

// INQUIRY PARTICULAR ROLE UPDATE
export const updateInquiryRole = (payload) => {
    return new Promise(async (resolve) => {
        const { roleId, workspaceId } = payload
        try {
            let data = payload.updatedData ?? {}
            const updateRole = await axiosNew({
                url: `inquiry-role/update/${roleId}/${workspaceId}`,
                data,
            })
            resolve({ data: updateRole?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// LIST SHORTLISTED AND OFFERED TALENT
export const listTypeTalent = (payload) => {
    return new Promise(async (resolve) => {
        const { roleId, workspaceId } = payload
        try {
            let data = payload.updatedData ?? {}
            const updateTalent = await axiosNew({
                url: `inquiry-role/list-short-list-offered-talent/${roleId}/${workspaceId}`,
                data,
            })
            resolve({ data: updateTalent?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// ADD / REMOVE SHORTLISTED AND OFFERED TALENT
export const addRemoveTypeTalent = (payload) => {
    return new Promise(async (resolve) => {
        const { roleId, workspaceId } = payload
        try {
            let data = payload.updatedData ?? {}
            const updateTalent = await axiosNew({
                url: `inquiry-role/short-list-talent/${roleId}/${workspaceId}`,
                data,
                method: 'PUT',
            })
            resolve({ data: updateTalent?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// ADD / REMOVE OFFER TO HIRE
export const addRemoveOfferToHire = async (payload) => {
    const { roleId, workspaceId } = payload
    return new Promise(async (resolve) => {
        try {
            let data = payload?.addRemoveData ?? {}
            const createRemoveOffer = await axiosNew({
                url: `inquiry-role/add-offer/${roleId}/${workspaceId}`,
                data,
            })
            resolve({ data: createRemoveOffer?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// UPDATE OFFER TO HIRE
export const updateOfferToHire = (payload) => {
    const { roleId, talentId, workspaceId } = payload
    return new Promise(async (resolve) => {
        try {
            let data = payload.updatedData ?? {}
            const updateOffer = await axiosNew({
                url: `inquiry-role/update-offer/${roleId}/${talentId}/${workspaceId}`,
                data,
            })
            resolve({ data: updateOffer?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// GET PARTICULAR TALENT
export const getTalent = async (payload) => {
    return new Promise(async (resolve) => {
        const { userId } = payload
        try {
            const talentData = await axiosNew({
                url: 'inquiry-role/get-talent/' + userId,
                method: 'GET',
            })
            resolve({ data: talentData?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// MATCH TALENT DATA
export const matchTalentData = (payload) => {
    return new Promise(async (resolve) => {
        const { roleId, workspaceId } = payload
        try {
            let data = payload.updatedData ?? {}
            const matchTalent = await axiosNew({
                url: `inquiry-role/match-talent/${roleId}/${workspaceId}`,
                data,
            })
            resolve({ data: matchTalent?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// ADD / REMOVE ATTACHMENTS INQUIRY ROLE
export const addRemoveRoleAttachments = async (payload) => {
    return new Promise(async (resolve) => {
        const { inquiryId, workspaceId } = payload
        try {
            let data = payload.fileObject ?? {}
            const attachmentUpdate = await axiosNew({
                url: `inquiry-role/add-remove-attachment/${inquiryId}/${workspaceId}`,
                data,
                method: 'PUT',
            })
            resolve({ data: attachmentUpdate?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}
