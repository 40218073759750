import axiosNew from '../../../helpers/axios'

export const getProjectReports = (payload) => {
    let data = payload ?? {}

    return new Promise(async (resolve) => {
        try {
            const projectReportsGet = await axiosNew({
                url: `reports/project-logs`,
                data,
            })
            resolve({ data: projectReportsGet?.data ?? [] })
        } catch (error) {
            resolve({ data: [] })
        }
    })
}
