import React from 'react'

function EducationIcon({ color }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="30"
            fill="none"
            viewBox="0 0 40 30"
        >
            <path
                fill={color ? color : '#B9B9B9'}
                fillRule="evenodd"
                d="M19.95 0L0 9.71v.906l19.95 9.71 19.968-9.719V9.72L19.95 0z"
                clipRule="evenodd"
            ></path>
            <path
                fill={color ? color : '#B9B9B9'}
                fillRule="evenodd"
                d="M6.912 15.75v5.81l12.028 5.853h2.02l11.995-5.837a1.95 1.95 0 00.034-.077V15.75L19.95 22.097 6.912 15.75zM37.815 24.636v-11.3l-1.579.805v10.495l-1.029 3.168 1.555 2.103h.527l1.555-2.104-1.029-3.167z"
                clipRule="evenodd"
            ></path>
        </svg>
    )
}

export default EducationIcon
