import React from 'react'

function AddressIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="17"
            fill="none"
            viewBox="0 0 12 17"
        >
            <path
                fill="#252525"
                d="M5.991 16.396a.703.703 0 01-.605-.345l-.77-1.303C3.035 12.075 1.541 9.55 1.031 8.526a5.467 5.467 0 01-.557-2.419A5.524 5.524 0 015.99.59a5.524 5.524 0 014.963 7.93l-.014.028c-.52 1.038-2.004 3.546-3.574 6.2l-.77 1.303a.703.703 0 01-.605.345zm0-14.401a4.117 4.117 0 00-3.7 5.91c.485.973 2.034 3.592 3.533 6.125l.167.283.165-.28c1.5-2.534 3.05-5.155 3.537-6.133l.007-.014a4.117 4.117 0 00-3.709-5.891z"
            ></path>
            <path
                fill="#252525"
                d="M5.991 8.613c-1.488 0-2.699-1.21-2.699-2.699 0-1.488 1.21-2.7 2.7-2.7a2.702 2.702 0 012.698 2.7 2.702 2.702 0 01-2.699 2.699z"
            ></path>
        </svg>
    )
}

export default AddressIcon
