import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'

// antd
import {
    Avatar,
    Image,
    Row,
    Table,
    Typography,
    theme,
    Dropdown,
    Pagination,
    Space,
    message,
    Drawer,
    Spin,
} from 'antd'
import { EllipsisOutlined } from '@ant-design/icons'

// slices
import { changePage, talentListGet, talentState } from '../talent/talent.slice'
import { loginState } from '../login/login.slice'
import {
    changeTab,
    clearProfileData,
    profileState,
    updateUserGet,
} from '../profile/profile.slice'
import { recruitListGet, recruitState } from './recruit.slice'

// profile section
import ProfileSection from '../profile'

// assets
import {
    BypassIcon,
    ViewIcon,
    NotesRecruitIcon,
    NoRecruitIcon,
} from '../../assets/icons'

// notes drawer
import NotesDrawer from './notesDrawer'

import dayjs from 'dayjs'

const RecruitList = () => {
    const { Text } = Typography
    const { useToken } = theme

    const dispatch = useDispatch()

    const { token } = useToken()

    const navigate = useNavigate()

    const {
        pageLimit,
        currentPage,
        dataCount,
        isNext,
        talentList,
        filter,
        search,
        status,
    } = useSelector(talentState)
    const { userProfile } = useSelector(loginState)
    const { status: profileStatus } = useSelector(profileState)
    const { pageLimit: recruitPageLimit, defaultTab } =
        useSelector(recruitState)

    const initialState = {
        talentOpen: false,
        notesOpen: false,
    }

    const [state, setState] = useState(initialState)

    // TALENT LOGIN
    const talentLogin = async (talent) => {
        if (window.location.hostname === 'localhost') {
            try {
                const response = await axios({
                    url: `http://localhost:5000/workspace/user-workspace-login?workSpaceId=${userProfile?.currentWorkSpace?._id}&userId=${talent?._id}`,
                    method: 'get',
                })
                if (response?.data?.success) {
                    navigate('/')
                    window.location.reload()
                }
            } catch (error) {
                message.error(error?.response?.data?.message)
            }
        } else {
            const response = await axios({
                url: `https://${userProfile?.currentWorkSpace?.domain}/backend/workspace/user-workspace-login?workSpaceId=${userProfile?.currentWorkSpace?._id}&userId=${talent?._id}`,
                method: 'get',
            })
            if (response?.data?.success) {
                navigate('/')
                window.location.reload()
                // const checkLogin = await dispatch(isLoggedInLogin())
                // const checkSuccessLogin = checkLogin?.payload?.data
                // const { success } = checkSuccessLogin
                // if (success) {
                // navigate(PRIVATE_ROUTES.overview)
                // }
            }
        }
    }

    // COLUMNS
    const columns = [
        {
            title: 'Name',
            key: 'name',
            dataIndex: 'name',
            render: (val, item) => (
                <Row
                    align={'middle'}
                    style={{
                        columnGap: '16px',
                    }}
                >
                    {item?.profilePicture ? (
                        <Image
                            src={item?.profilePicture}
                            height={'30px'}
                            preview={false}
                            width={'30px'}
                            style={{
                                borderRadius: '50%',
                            }}
                        />
                    ) : (
                        <Avatar
                            style={{
                                cursor: 'pointer',
                                backgroundColor: 'rgb(0 10 129 / 25%)',
                            }}
                        >
                            {item?.name[0].toUpperCase()}
                            {item?.name.split(' ')[1] !== undefined &&
                                item?.name.split(' ')[1] !== '' &&
                                item?.name.split(' ')[1][0].toUpperCase()}
                        </Avatar>
                    )}
                    <Text>{val}</Text>
                </Row>
            ),
        },
        {
            key: 'email',
            dataIndex: 'email',
            title: 'Email ID',
            render: (val) => (
                <div style={{ fontWeight: 500 }}>{val || 'NA'}</div>
            ),
        },
        {
            key: 'phone',
            dataIndex: 'phone',
            title: 'Mobile Number',
            render: (val, item) => (
                <div style={{ fontWeight: 500 }}>
                    {item?.countryCode}
                    {val || 'NA'}
                </div>
            ),
        },
        {
            title: 'Date',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (_, item) => (
                <>
                    <Space size={'small'} align="middle">
                        <Text>
                            {dayjs(item?.createdAt).format('DD MMM YY')}
                        </Text>
                    </Space>
                </>
            ),
        },
        {
            key: 'contextNote',
            dataIndex: 'contextNote',
            title: '  ',
            render: (_, row) => (
                <div
                    style={{
                        cursor: 'pointer',
                    }}
                    onClick={() => {
                        dispatch(
                            updateUserGet({
                                _id: row?._id,
                            })
                        )
                        dispatch(
                            recruitListGet({
                                limit: recruitPageLimit,
                                skip: 0,
                                userId: row?._id,
                            })
                        )
                        setState((prevState) => ({
                            ...prevState,
                            notesOpen: true,
                        }))
                    }}
                >
                    <NotesRecruitIcon />
                </div>
            ),
        },
        {
            key: 'context',
            dataIndex: 'context',
            title: '  ',
            render: (_, row) => (
                <Row justify="end" style={{ paddingRight: 5 }}>
                    <Dropdown
                        trigger={['click']}
                        placement={'bottomRight'}
                        menu={{
                            items: [
                                {
                                    key: '1',
                                    label: 'View',
                                    icon: <ViewIcon />,
                                    style: {
                                        columnGap: '10px',
                                    },
                                    onClick: () => {
                                        dispatch(
                                            updateUserGet({
                                                _id: row?._id,
                                            })
                                        )
                                        setState((prevState) => ({
                                            ...prevState,
                                            talentOpen: true,
                                        }))
                                    },
                                },
                                row?.status !== 'rejected' && {
                                    key: '2',
                                    label: 'Login',
                                    icon: <BypassIcon />,
                                    style: {
                                        columnGap: '10px',
                                    },
                                    onClick: () => {
                                        talentLogin(row)
                                    },
                                },
                            ],
                        }}
                    >
                        <EllipsisOutlined rotate={90} />
                    </Dropdown>
                </Row>
            ),
        },
    ]

    return (
        <>
            <Spin spinning={status === 'loading' ? true : false}>
                {talentList?.length > 0 ? (
                    <>
                        <Table
                            dataSource={talentList}
                            scroll={{
                                x: 'scroll',
                            }}
                            rootClassName="tableGlobal"
                            style={{
                                borderRadius: '16px',
                            }}
                            columns={columns}
                            pagination={false}
                        />
                        {talentList?.length > 0 && (
                            <Row
                                justify={'end'}
                                align={'middle'}
                                style={{
                                    border: `2px solid ${token.colorPalette.baseColor.quaternary}`,
                                    padding: '25px',
                                    borderRadius: '0px 0px 8px 8px',
                                }}
                            >
                                <Text>
                                    {talentList?.length} - {pageLimit} of{' '}
                                    {dataCount}
                                </Text>
                                <Pagination
                                    defaultCurrent={currentPage}
                                    current={currentPage}
                                    defaultPageSize={pageLimit}
                                    pageSizeOptions={[5, 10, 20, 50]}
                                    showSizeChanger={true}
                                    total={
                                        isNext === false && currentPage === 1
                                            ? talentList?.length
                                            : dataCount
                                    }
                                    onChange={async (value, pageSize) => {
                                        await dispatch(
                                            changePage({
                                                currentPage: value,
                                                skip: (value - 1) * pageLimit,
                                                pageLimit: pageSize,
                                            })
                                        )
                                        await dispatch(
                                            talentListGet({
                                                skip: (value - 1) * pageSize,
                                                limit: pageSize,
                                                filter: {
                                                    ...filter,
                                                    status: defaultTab,
                                                },
                                                search,
                                            })
                                        )
                                    }}
                                />
                            </Row>
                        )}
                    </>
                ) : (
                    status !== 'loading' && (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '100%',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginTop: '120px',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                }}
                            >
                                <div
                                    style={{
                                        width: '400px',
                                    }}
                                >
                                    <NoRecruitIcon />
                                </div>
                            </div>
                            <Text
                                className="titleSecondary"
                                style={{
                                    marginTop: '20px',
                                }}
                            >
                                No Candidate Found yet.
                            </Text>
                        </div>
                    )
                )}
            </Spin>
            <Drawer
                placement="right"
                closable={false}
                open={state?.talentOpen}
                onClose={() => {
                    dispatch(clearProfileData())
                    setState((prevState) => ({
                        ...prevState,
                        talentOpen: false,
                    }))
                    dispatch(changeTab('summary'))
                }}
                footer={null}
                title={null}
                style={{
                    height: '100vh',
                    minWidth: '1200px',
                    width: '1200px',
                }}
                styles={{
                    body: {
                        padding: '0px',
                    },
                    wrapper: {
                        width: 'auto !important',
                    },
                }}
                rootClassName="talentViewDrawer"
            >
                <Spin
                    spinning={profileStatus === 'loading' ? true : false}
                    style={{
                        width: '1200px',
                    }}
                >
                    <ProfileSection
                        profileOpenKey="talent"
                        pageKeyProfile="recruit"
                    />
                </Spin>
            </Drawer>
            <NotesDrawer
                openDrawer={state?.notesOpen}
                onClose={() => {
                    dispatch(clearProfileData())
                    setState((prevState) => ({
                        ...prevState,
                        notesOpen: false,
                    }))
                }}
            />
        </>
    )
}

export default RecruitList
