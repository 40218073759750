import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

// antd
import { Modal, Row, Typography, theme, Select, Col } from 'antd'

// components
import Button from '../button/button'

// slices
import { sprintsState } from '../../modules/sprints/sprints.slice'

const CompleteSprintPopUp = ({
    previewDelete,
    onCancel,
    onComplete,
    modalTitle,
    loadingButton,
    selectedSprint,
    sprintsList,
    selectedSprintId,
}) => {
    const { Title, Text } = Typography

    const { Option } = Select

    const { useToken } = theme
    const { token } = useToken()

    const { listTasks } = useSelector(sprintsState)

    const [filteredSprintsList, setFilteredSprintsList] = useState([])
    const [unDoneTask, setUnDoneTask] = useState([])
    const [doneTask, setDoneTask] = useState([])
    const [sprintId, setSprintId] = useState()

    // MOVE TO SPRINT LIST
    useEffect(() => {
        if (sprintsList?.length > 0) {
            let filterSprints = []
            let undoneTasks = []
            let doneTask = []

            sprintsList
                .filter(
                    (data) =>
                        data?._id !== selectedSprintId &&
                        data?.isComplete === false
                )
                .map((data) => {
                    filterSprints = [
                        ...filterSprints,
                        {
                            label: data?.title,
                            value: data?._id,
                        },
                    ]
                })

            setFilteredSprintsList(filterSprints)
            listTasks?.length > 0 &&
                listTasks.map(
                    (data) =>
                        data?.list?.length > 0 &&
                        data?.list
                            .filter((data) => data?.status !== 'Done')
                            .map((data) => undoneTasks.push(data))
                )
            setUnDoneTask(undoneTasks)
            listTasks?.length > 0 &&
                listTasks.map(
                    (data) =>
                        data?.list?.length > 0 &&
                        data?.list
                            .filter((data) => data?.status === 'Done')
                            .map((data) => doneTask.push(data))
                )
            setDoneTask(doneTask)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSprintId, listTasks])

    return (
        <Modal
            footer={null}
            closable={false}
            open={previewDelete}
            destroyOnClose={true}
            onCancel={() => {
                onCancel()
                setSprintId()
            }}
            width={700}
            styles={{
                body: {
                    padding: '30px 25px',
                },
            }}
            centered
        >
            <Row
                align={'middle'}
                style={{
                    marginBottom: '16px',
                }}
            >
                <Title level={4} style={{ margin: 0 }}>
                    {modalTitle} {selectedSprint}
                </Title>
            </Row>
            <Row
                style={{
                    marginBottom: 8,
                }}
            >
                <Text
                    style={{
                        fontSize: '16px',

                        color: token.colorPalette.textColor.secondary,
                    }}
                >
                    {`${doneTask?.length} ${
                        doneTask?.length > 1 ? 'issues are' : 'issue is'
                    } Done`}
                </Text>
            </Row>
            <Row
                style={{
                    marginBottom: 8,
                }}
            >
                <Text
                    style={{
                        fontSize: '16px',
                        color: token.colorPalette.textColor.secondary,
                    }}
                >
                    {`${unDoneTask?.length} ${
                        unDoneTask?.length > 1 ? 'issues are' : 'issue is'
                    } incomplete`}
                </Text>
            </Row>
            <Row
                style={{
                    marginBottom: 8,
                }}
            >
                <Text
                    style={{
                        color: token.colorPalette.textColor.secondary,
                        fontSize: '16px',
                    }}
                >
                    Select where all the issues should be moved!
                </Text>
            </Row>
            <Row
                style={{
                    marginBottom: '8px',
                }}
            >
                <Text
                    style={{
                        fontSize: '14px',
                    }}
                >
                    Move To
                </Text>
            </Row>
            <Col span={24}>
                <Select
                    placeholder="Select a Sprint"
                    filterOption={false}
                    allowClear={false}
                    defaultValue={
                        filteredSprintsList[0]?.length > 0
                            ? filteredSprintsList[0]
                            : undefined
                    }
                    onChange={(value) => setSprintId(value)}
                    style={{
                        width: '100%',
                    }}
                >
                    {filteredSprintsList.map((option, index) => {
                        return (
                            <Option
                                style={{ textTransform: 'capitalize' }}
                                key={index}
                                value={option.value}
                            >
                                {option.label}
                            </Option>
                        )
                    })}
                </Select>
            </Col>
            <Row>
                <Col span={24}>
                    <Row
                        style={{
                            marginTop: '40px',
                            float: 'right',
                            columnGap: '10px',
                        }}
                    >
                        <Button
                            props={{
                                text: 'Cancel',
                                buttonType: 'link',
                                style: {
                                    color: token.colorPalette.baseColor.black,
                                    fontWeight: token.fontWeightStrong,
                                },
                                onClick: () => {
                                    onCancel()
                                    setSprintId()
                                },
                            }}
                        />
                        <Button
                            props={{
                                loadingButton: loadingButton,
                                disabled: sprintId === undefined,
                                onClick: () => {
                                    onComplete(sprintId)
                                },
                                text: 'Complete Sprint',
                            }}
                        />
                    </Row>
                </Col>
            </Row>
        </Modal>
    )
}

export default CompleteSprintPopUp
