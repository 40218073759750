import React from 'react'

function ExperienceIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="11"
            fill="none"
            viewBox="0 0 16 11"
        >
            <path
                fill="#252525"
                d="M15.206 7.68V4.771l.247-.08a.768.768 0 000-1.461L8.916 1.094a2.815 2.815 0 00-1.759 0L.621 3.23a.768.768 0 000 1.46l2.675.875v3.688c0 .55.375 1.04.912 1.19 1.27.357 2.55.535 3.829.535 1.279 0 2.558-.178 3.829-.535.537-.15.912-.64.912-1.19V5.565l1.369-.448V7.68c-.335.186-.561.54-.561.948 0 .407.226.762.56.947v.878c0 .291.238.527.53.527.293 0 .53-.236.53-.527v-.878c.335-.185.562-.54.562-.947 0-.407-.227-.762-.562-.948zM7.488 2.095c.359-.117.739-.117 1.098 0l5.707 1.865-5.707 1.865c-.36.117-.74.117-1.098 0L1.78 3.96l5.708-1.865zm4.23 7.158a.184.184 0 01-.14.176c-2.35.66-4.733.66-7.082 0a.184.184 0 01-.14-.176V5.911l2.801.915a2.825 2.825 0 001.76 0l2.8-.915v3.342z"
            ></path>
        </svg>
    )
}

export default ExperienceIcon
