import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// antd
import { Col, Divider, Image, Row, Typography, theme } from 'antd'

// slices
import {
    changeModel,
    changePage,
    masterDataListCount,
    masterDataListGet,
    masterDataState,
    searchData,
} from './masterDataPanel.slice'

// assets
import MasterDataImage from '../../assets/images/masterData/masterDataSetting.png'
import { BackIcon } from '../../assets/icons'

// constants
import { masterDataMenu } from '../../constants/masterData'

// components
import MasterDataItem from './masterDataItem'
import FoF from '../404/404.index'

// routes
import { PRIVATE_ROUTES } from '../../routes'

import styles from './masterData.module.css'

function MasterDataPanel() {
    const { useToken } = theme
    const { token } = useToken()

    const navigate = useNavigate()

    const dispatch = useDispatch()

    const params = useLocation()

    const { modelType, pageLimit, skipPage, search } =
        useSelector(masterDataState)

    const { Title } = Typography

    const listDataInitially = (item) => {
        dispatch(
            masterDataListCount({
                _id: item?.length > 0 ? item : params.pathname.split('/')[4],
            })
        )
        dispatch(
            masterDataListGet({
                limit: pageLimit,
                skip: skipPage,
                search: search,
                id: item?.length > 0 ? item : params.pathname.split('/')[4],
            })
        )
        dispatch(
            changePage({
                currentPage: 1,
                skip: 0,
                pageLimit: 10,
            })
        )
        dispatch(searchData(''))
        dispatch(
            changeModel({
                name: item,
            })
        )
    }

    useEffect(() => {
        listDataInitially(modelType?.name)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <div
                className={'container'}
                style={{
                    zIndex: 10,
                    minHeight: '100vh',
                    padding: '0px',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                    }}
                >
                    <div
                        style={{
                            padding: '40px 25px 0px 25px',
                            minWidth: '265px',
                        }}
                    >
                        <Row align={'middle'} justify={'space-between'}>
                            <Row
                                align={'middle'}
                                style={{
                                    columnGap: '13px',
                                }}
                            >
                                <Image
                                    src={MasterDataImage}
                                    preview={false}
                                    height={22}
                                    width={22}
                                />
                                <Title level={3}>Master Data</Title>
                            </Row>
                            <div
                                style={{
                                    height: '22px',
                                    width: '22px',
                                    cursor: 'pointer',
                                }}
                                onClick={() => navigate(-1)}
                            >
                                <BackIcon />
                            </div>
                        </Row>
                        <Row
                            style={{
                                marginTop: '35px',
                            }}
                        >
                            <Col span={24}>
                                {masterDataMenu?.length > 0 &&
                                    masterDataMenu.map((data) => (
                                        <div key={data?.heading}>
                                            <Col>
                                                {data?.items?.length > 0 &&
                                                    data.items.map((item) => (
                                                        <div
                                                            style={{
                                                                padding: '10px',
                                                                cursor: 'pointer',
                                                                borderRadius:
                                                                    '5px',
                                                            }}
                                                            key={item?.key}
                                                            className={
                                                                item?.key ===
                                                                    modelType?.name &&
                                                                styles.activeMasterData
                                                            }
                                                        >
                                                            <Title
                                                                level={5}
                                                                onClick={() => {
                                                                    listDataInitially(
                                                                        item?.key
                                                                    )
                                                                    navigate(
                                                                        PRIVATE_ROUTES.masterData.root(
                                                                            item?.key
                                                                        )
                                                                    )
                                                                }}
                                                                style={{
                                                                    color:
                                                                        item?.key ===
                                                                        modelType?.name
                                                                            ? token
                                                                                  .colorPalette
                                                                                  .baseColor
                                                                                  .tertiary
                                                                            : token
                                                                                  .colorPalette
                                                                                  .baseColor
                                                                                  .black,
                                                                }}
                                                            >
                                                                {item?.label}
                                                            </Title>
                                                        </div>
                                                    ))}
                                            </Col>
                                        </div>
                                    ))}
                            </Col>
                        </Row>
                    </div>
                    <div>
                        <Divider
                            type="vertical"
                            style={{
                                height: '100%',
                            }}
                        />
                    </div>
                    <div
                        style={{
                            width: '100%',
                        }}
                    >
                        {masterDataMenu[0].items.some(
                            (data) => data?.key === modelType?.name
                        ) ||
                        masterDataMenu[0].items.some(
                            (data) =>
                                data?.key === params.pathname.split('/')[4]
                        ) ? (
                            <MasterDataItem />
                        ) : (
                            <FoF />
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default MasterDataPanel
