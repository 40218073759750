import React from 'react'

function DesignationIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="23"
            fill="none"
            viewBox="0 0 19 23"
        >
            <path
                fill="#4F46E5"
                d="M8 13v8H0a8 8 0 018-8zm6 7.5l-2.939 1.545.561-3.273-2.377-2.317 3.286-.477L14 13l1.47 2.977 3.285.478-2.377 2.318.56 3.272L14 20.5zM8 12c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6z"
            ></path>
        </svg>
    )
}

export default DesignationIcon
