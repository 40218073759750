import React from 'react'

function NotificationIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="20"
            fill="none"
            viewBox="0 0 18 20"
        >
            <path
                fill="#080325"
                d="M17.19 12.06l-1.13-1.88c-.25-.41-.47-1.2-.47-1.68V6.63C15.59 3 12.64.05 9.02.05 5.39.06 2.44 3 2.44 6.63v1.86c0 .48-.22 1.27-.46 1.68L.85 12.05c-.43.73-.53 1.56-.26 2.28.27.73.88 1.31 1.68 1.57 1.08.36 2.17.62 3.28.81.11.02.22.03.33.05l.44.06c.26.04.52.07.79.09 1.26.119 2.529.119 3.79 0 .23-.02.46-.04.68-.07.18-.02.36-.04.54-.07.11-.01.22-.03.33-.05 1.12-.18 2.23-.46 3.31-.82a2.65 2.65 0 001.64-1.58c.28-.75.2-1.57-.21-2.26zM9.75 8a.76.76 0 11-1.52 0V4.9a.76.76 0 111.52 0V8zm2.08 10.01A3.014 3.014 0 019 20c-.79 0-1.57-.32-2.12-.89-.32-.3-.56-.7-.7-1.11.13.02.26.03.4.05.23.03.47.06.71.08.57.05 1.15.08 1.73.08.57 0 1.14-.03 1.7-.08.21-.02.42-.03.62-.06l.49-.06z"
            ></path>
        </svg>
    )
}

export default NotificationIcon
