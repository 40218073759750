import React from 'react'

function NoAttachmentsIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="342"
            height="180"
            fill="none"
            viewBox="0 0 342 180"
        >
            <g clipPath="url(#clip0_4612_16252)">
                <path
                    fill="#F2F2F2"
                    d="M340.338 130.542c-1.786 11.355-7.322 22.377-16.456 29.352-8.99 6.87-20.508 9.31-31.626 11.408a1254.657 1254.657 0 01-40.116 6.909c-1.9.303-3.802.596-5.706.878h-78.097c-.08-.105-.163-.215-.242-.324-.127-.185-.254-.369-.377-.554-.071-.105-.141-.215-.211-.324h48.986a5.25 5.25 0 005.246-5.241V107.26c.316-.992.61-1.988.878-2.998a79.006 79.006 0 002.427-14.358c.264-3.38.343-6.76.285-10.144-.162-9.301-1.378-18.603-2.699-27.838l-.294-2.046c-.931-6.417-1.778-13.4 1.457-19.024 3.7-6.435 11.913-9.117 19.31-8.458 13.304 1.19 24.51 11.377 30.335 23.4 5.821 12.019 7.124 25.687 7.655 39.031.281 7.076.251 14.468-.737 21.566 3.336-7.629 6.675-15.258 10.017-22.887 1.782-4.069 3.656-8.27 6.957-11.246 6.9-6.228 18.313-4.973 25.683.698 7.366 5.676 11.404 14.644 14.09 23.541 3.327 11.004 5.026 22.689 3.235 34.045z"
                ></path>
                <path
                    fill="#fff"
                    d="M310.906 68.866c4.825 8.546 7.039 18.345 6.986 28.123a68.067 68.067 0 01-6.291 28.062 70.494 70.494 0 01-15.129 21.201 64.226 64.226 0 01-21.742 13.888 56.822 56.822 0 01-29.502 2.804 57.363 57.363 0 01-4.099-.861c-.823-.203-1.174 1.067-.35 1.27a58.282 58.282 0 0031.272-.899 64.437 64.437 0 0022.905-13.031 71.503 71.503 0 0016.428-20.943 70.021 70.021 0 007.715-28.033c.575-9.925-1.089-20.027-5.381-29.03a51.824 51.824 0 00-1.675-3.215.659.659 0 00-1.137.664z"
                ></path>
                <path
                    fill="#fff"
                    d="M241.021 22.221a142.8 142.8 0 0112.421 31.158 150.483 150.483 0 012.56 66.527 142.22 142.22 0 01-10.003 32.036c-4.573 10.16-11.158 20.866-22.405 24.423-1.269.4-2.57.691-3.889.87-.835.114-.48 1.383.35 1.269 11.635-1.596 19.719-11.262 24.789-21.114 5.246-10.195 8.981-21.266 11.465-32.443a151.669 151.669 0 00-.518-67.65 145 145 0 00-11.645-31.955 138.48 138.48 0 00-1.988-3.785c-.405-.745-1.542-.081-1.137.664z"
                ></path>
                <path
                    fill="#CCC"
                    d="M185.956 20.305H107.12a6.042 6.042 0 00-6.035 6.035v39.501h12.133a5.216 5.216 0 014.437 2.454l4.205 6.694 4.021 6.395.755 1.203 4.6 7.317.053.084h60.702V26.34a6.045 6.045 0 00-6.035-6.035z"
                ></path>
                <path
                    fill="#fff"
                    d="M154.707 32.379h-39.062a3.904 3.904 0 100 7.809h39.062a3.907 3.907 0 003.904-3.905 3.905 3.905 0 00-3.904-3.904zM176.369 51.555H116a4.26 4.26 0 00-4.262 4.262A4.26 4.26 0 00116 60.079h60.369a4.264 4.264 0 004.262-4.262 4.263 4.263 0 00-4.262-4.262zM180.223 68.922a4.262 4.262 0 00-3.854-2.453h-60.368c-.092 0-.18.004-.272.009a5.237 5.237 0 011.927 1.817l4.205 6.694h54.508a4.254 4.254 0 004.249-3.987 4.247 4.247 0 00-.395-2.08zM180.588 85.07a4.265 4.265 0 00-4.219-3.687h-50.487l.755 1.203 4.6 7.317h45.132a4.216 4.216 0 002.472-.795 4.265 4.265 0 001.314-1.517 4.268 4.268 0 00.433-2.521z"
                ></path>
                <path
                    fill="#4F46E5"
                    d="M228.775 54.323l-6.145-2.401-30.638-11.957-36.657-14.305a6.045 6.045 0 00-7.818 3.432l-1.281 3.288-3.051 7.809-4.433 11.364-3.328 8.525-2.493 6.39-3.327 8.52-2.498 6.396-.07.184-.399 1.019 4.6 7.317.053.083h85.204a5.232 5.232 0 014.078 1.95l.359-.914 4.399-11.263 6.874-17.615a6.047 6.047 0 00-3.429-7.822z"
                ></path>
                <path
                    fill="#fff"
                    d="M195.275 54.21l-3.283-1.282-33.106-12.918a3.919 3.919 0 00-5.061 2.217 3.919 3.919 0 002.221 5.061l10.934 4.267 13.524 5.276 11.488 4.486.443.171a3.907 3.907 0 002.84-7.278zM208.479 79.95l-16.487-6.435-11.768-4.591-6.286-2.454-16.373-6.39-5.32-2.077a4.264 4.264 0 00-5.368 2.076 4.277 4.277 0 00-.441 1.979 4.263 4.263 0 002.71 3.881l1.361.531 21.824 8.52 19.661 7.673 13.388 5.228c.492.192 1.016.29 1.545.29a4.26 4.26 0 001.554-8.23zM191.992 89.523l-1.058-.413-10.346-4.038-9.451-3.688-16.39-6.395-7.928-3.095a4.26 4.26 0 10-3.094 7.941l3.968 1.55 19.796 7.725 2.037.794.211.084h23.444l-1.189-.465zM151.946 89.906l-2.037-.794-8.511-3.323a4.256 4.256 0 00-5.807 4.117.465.465 0 00.004.084h16.566l-.215-.084z"
                ></path>
                <path
                    fill="#3F3D56"
                    d="M169.526 89.906l-2.037-.795h-31.85a4.063 4.063 0 00-.048.795h-4.354l.053.083h38.447l-.211-.083zm0 0h-38.289l.053.083h38.447l-.211-.083zm0 0h-38.289l.053.083h38.447l-.211-.083zm.211.083l-.211-.083h-38.289l.053.083h38.447zm-76.82 87.9a5.251 5.251 0 01-5.241-5.242V71.088a5.25 5.25 0 015.24-5.245h8.17v-.878h-8.17a6.13 6.13 0 00-6.118 6.123v101.559a6.125 6.125 0 003.586 5.566h77.334c-.07-.105-.141-.215-.211-.324h-74.59zM220.93 91.025a6.082 6.082 0 00-4.437-1.914h-84.717l-4.741-7.54-.114-.185-4.021-6.396-4.503-7.163a6.14 6.14 0 00-5.18-2.862H92.917a6.13 6.13 0 00-6.12 6.123v101.559a6.125 6.125 0 003.587 5.566 6.019 6.019 0 002.533.553h123.577a6.042 6.042 0 002.537-.553 6.121 6.121 0 003.586-5.565V95.235a6.089 6.089 0 00-1.686-4.21zm.808 81.622a5.247 5.247 0 01-5.245 5.242H92.917a5.251 5.251 0 01-5.241-5.242V71.088a5.25 5.25 0 015.24-5.245h20.302a5.213 5.213 0 014.438 2.453l4.205 6.694 4.021 6.396.755 1.203 4.6 7.317.053.083h85.204a5.249 5.249 0 015.245 5.246v77.412zm-52.213-82.741h-38.289l.053.083h38.447l-.211-.083zm0 0h-38.289l.053.083h38.447l-.211-.083zm0 0h-38.289l.053.083h38.447l-.211-.083zm0 0l-2.037-.795h-35.712l-4.741-7.54-.399 1.018 4.6 7.317.053.083h38.447l-.211-.083z"
                ></path>
                <path
                    fill="#FFB8B8"
                    d="M68.883 126.315l-2.514 5.257-21.472-7.196 3.71-7.759 20.276 9.698z"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M62.838 135.097l5.082-10.626 6.383 3.053-7.901 16.521-.489-.233a6.534 6.534 0 01-3.075-8.715z"
                ></path>
                <path
                    fill="#FFB8B8"
                    d="M73.945 173.26l-5.824-.196-.245-22.669 6.827.402-.758 22.463z"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M63.477 171.24l11.772.397-.239 7.072-18.302-.618.018-.541a6.533 6.533 0 016.751-6.31zM83.323 78.387s-17.824-4.515-25.904 2.14l-4.515 13.07-18.061 25.666 26.14 15.684 4.754-11.882-11.407-5.704 13.783-11.882-2.376 62.739 9.03 1.426s19.963-77.473 8.556-91.257z"
                ></path>
                <path
                    fill="#4F46E5"
                    d="M56.282 82.46l.217-.387c2.29-4.071 1.92-10.7 1.205-15.545-.599-4.058-.58-11.208-.46-16.493a7.853 7.853 0 016.26-7.559c5.422-1.128 12.079-1.367 15.564-1.41a3.62 3.62 0 013.632 3.53c.106 5.691.073 16.068-1.339 21.636-2.096 8.263 1.182 13.879 1.215 13.934l.198.33-26.492 1.963z"
                ></path>
                <path
                    fill="#FFB8B8"
                    d="M47.114 105.249a4.406 4.406 0 002.362-2.878 4.4 4.4 0 00-.69-3.659l10.06-43.376H51.24l-8.878 42.521a4.424 4.424 0 004.75 7.392zM108.276 62.551L91.49 46.671l-4.537 6.352 11.342 9.982L83.42 74.817c-.107-.007-.214-.016-.323-.016a4.764 4.764 0 104.716 5.397l.047.047 20.416-17.694z"
                ></path>
                <path
                    fill="#4F46E5"
                    d="M62.436 43.032s-10.482-1.72-11.433 6.36c-.95 8.08-1.426 8.08-1.426 8.08s7.605-1.425 9.506.476c1.901 1.901 3.353-14.916 3.353-14.916zM78.095 41.79s8.555-1.427 12.357 2.376c3.803 3.802 5.704 4.753 5.704 4.753s-8.555 4.753-5.228 9.03c0 0-9.03-4.277-10.457-2.852-1.426 1.426-2.376-13.308-2.376-13.308z"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M70.591 14.195c-2.699-2.155-3.408-6.315-1.842-9.393 1.566-3.079 5.106-4.933 8.557-4.795 3.45.139 6.702 2.12 8.701 4.936 2.809 3.956 3.398 9.454 7.132 12.551 3.373 2.798 8.205 2.718 12.581 2.495 1.475-.075 2.998-.162 4.322-.817 1.324-.656 2.405-2.038 2.235-3.505 1.099 1.74.461 4.18-1.014 5.616s-3.575 2.038-5.62 2.277a20.88 20.88 0 01-18.94-8.31c-1.523-2.1-2.699-4.547-4.788-6.085-2.09-1.538-5.573-1.645-6.856.61-.634 1.116-.572 2.515-1.155 3.658-.584 1.143-2.478 1.759-3.04.605"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M70.178 37.114c7.273 0 13.169-5.895 13.169-13.168S77.45 10.777 70.178 10.777c-7.272 0-13.168 5.896-13.168 13.169 0 7.272 5.896 13.168 13.168 13.168z"
                ></path>
                <path
                    fill="#FFB8B8"
                    d="M70.1 38.148c5.954 0 10.78-4.827 10.78-10.781 0-5.954-4.826-10.781-10.78-10.781-5.955 0-10.782 4.827-10.782 10.78 0 5.955 4.827 10.782 10.781 10.782z"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M60.269 19.449c1.465.32.985 2.778 2.143 3.732 1.044.86 2.549-.025 3.863-.345 1.66-.404 3.397.188 4.929.943 1.532.755 3.01 1.692 4.681 2.043 1.672.352 3.653-.044 4.59-1.473.896-1.365.566-3.207-.135-4.682a10.872 10.872 0 00-19.507-.27"
                ></path>
                <path
                    fill="#3F3D56"
                    d="M314.287 178.65a.444.444 0 01-.439.439H.438A.437.437 0 010 178.65a.436.436 0 01.439-.439h313.409a.442.442 0 01.439.439z"
                ></path>
            </g>
            <defs>
                <clipPath id="clip0_4612_16252">
                    <path fill="#fff" d="M0 0H341.063V179.09H0z"></path>
                </clipPath>
            </defs>
        </svg>
    )
}

export default NoAttachmentsIcon
