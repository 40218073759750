import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import {
    create,
    count,
    read,
    update,
    deleteProject,
    updateStatus,
    addRemoveTeamMember,
    addRemoveFile,
    addNotes,
    listNotes,
    updateNotes,
    deleteNotes,
    countNotes,
    getNote,
    addRemoveComment,
    setAsCurrentProject,
    addRemoveSwimlanes,
    addRemoveTaskType,
    defaultTimeSet,
    defaultWeekSet,
    getProjectFiles,
    defaultIdleTime,
    defaultScreenshotTime,
    countProjectFiles,
    getProject,
} from './projects.service'
import { read as readUser, count as countUser } from '../users/users.service'

const initialState = {
    search: '',
    searchNote: '',
    status: 'loaded',
    noteStatus: 'loaded',
    filesStatus: 'loaded',
    listStatus: 'loaded',
    updatedDataStatus: 'loaded',
    projects: [],
    taskType: [],
    notesList: [],
    filesList: [],
    getTeamProjects: [],
    projectsListData: [],
    notesIsNext: false,
    notesPageLimit: 10,
    notesCurrentPage: 1,
    notesSkipPage: 0,
    notesCount: 0,
    filesIsNext: false,
    filesPageLimit: 10,
    filesCurrentPage: 1,
    filesSkipPage: 0,
    filesCount: 0,
    projectsCount: 0,
    isNext: false,
    pageLimit: 10,
    currentPage: 1,
    skipPage: 0,
    isNextList: false,
    pageLimitList: 10,
    currentPageList: 1,
    skipPageList: 0,
    progressCount: 0,
    isUpdate: false,
    formModal: false,
    viewModal: false,
    filterView: false,
    defaultTab: 'overview',
    selectedProject: undefined,
    currentProject: undefined,
    currentProjectData: undefined,
    selectedNote: undefined,
    editModeEditor: false,
    filterDropDown: {
        start: '',
        end: '',
        status: '',
        client: '',
    },
    stepProjectData: undefined,
    searchUserStatus: 'loaded',
    searchUsersList: [],
    peopleSearch: '',
    searchUsersCount: 0,
    isNextUser: false,
    pageLimitUser: 10,
    currentPageUser: 1,
    skipPageUser: 0,
}

export const addProject = createAsyncThunk(
    'projectsSlice/create',
    async (payload = {}) => {
        const response = await create(payload)
        return response
    }
)

export const updateProject = createAsyncThunk(
    'projectsSlice/update',
    async (payload = {}) => {
        const response = await update(payload)
        return response
    }
)

export const totalProjects = createAsyncThunk(
    'projectsSlice/count',
    async (payload = {}) => {
        const response = await count(payload)
        return response
    }
)

export const projectsList = createAsyncThunk(
    'projectsSlice/read',
    async (payload = {}) => {
        const response = await read(payload)
        return response
    }
)

export const projectGet = createAsyncThunk(
    'projectsSlice/getProject',
    async (payload = {}) => {
        const response = await getProject(payload)
        return response
    }
)

export const projectsListDropDown = createAsyncThunk(
    'projectsSlice/readDropDown',
    async (payload = {}) => {
        const response = await read(payload)
        return response
    }
)

export const projectDelete = createAsyncThunk(
    'projectsSlice/deleteProject',
    async (payload = {}) => {
        const response = await deleteProject(payload)
        return response
    }
)

export const updateStatusHistory = createAsyncThunk(
    'projectsSlice/updateStatus',
    async (payload = {}) => {
        const response = await updateStatus(payload)
        return response
    }
)

export const addRemoveMember = createAsyncThunk(
    'projectsSlice/addRemoveTeamMember',
    async (payload = {}) => {
        const response = await addRemoveTeamMember(payload)
        return response
    }
)

export const addRemoveProjectFiles = createAsyncThunk(
    'projectsSlice/addRemoveFile',
    async (payload = {}) => {
        const response = await addRemoveFile(payload)
        return response
    }
)

export const countTotalNotes = createAsyncThunk(
    'projectsSlice/countNotes',
    async (payload = {}) => {
        const response = await countNotes(payload)
        return response
    }
)

export const addProjectNotes = createAsyncThunk(
    'projectsSlice/addNotes',
    async (payload = {}) => {
        const response = await addNotes(payload)
        return response
    }
)

export const listProjectNotes = createAsyncThunk(
    'projectsSlice/listNotes',
    async (payload = {}) => {
        const response = await listNotes(payload)
        return response
    }
)

export const updateProjectNotes = createAsyncThunk(
    'projectsSlice/updateNotes',
    async (payload = {}) => {
        const response = await updateNotes(payload)
        return response
    }
)

export const deleteProjectNotes = createAsyncThunk(
    'projectsSlice/deleteNotes',
    async (payload = {}) => {
        const response = await deleteNotes(payload)
        return response
    }
)

export const getProjectNote = createAsyncThunk(
    'projectsSlice/getNote',
    async (payload = {}) => {
        const response = await getNote(payload)
        return response
    }
)

export const addRemoveProjectComments = createAsyncThunk(
    'projectsSlice/addRemoveComment',
    async (payload = {}) => {
        const response = await addRemoveComment(payload)
        return response
    }
)

export const asCurrentProject = createAsyncThunk(
    'projectsSlice/setAsCurrentProject',
    async (payload = {}) => {
        const response = await setAsCurrentProject(payload)
        return response
    }
)

export const swimLanesUpdate = createAsyncThunk(
    'projectsSlice/addRemoveSwimlanes',
    async (payload = {}) => {
        const response = await addRemoveSwimlanes(payload)
        return response
    }
)

export const taskTypeUpdate = createAsyncThunk(
    'projectsSlice/addRemoveTaskType',
    async (payload = {}) => {
        const response = await addRemoveTaskType(payload)
        return response
    }
)

export const timeUpdate = createAsyncThunk(
    'projectsSlice/defaultTimeSet',
    async (payload = {}) => {
        const response = await defaultTimeSet(payload)
        return response
    }
)

export const weekUpdate = createAsyncThunk(
    'projectsSlice/defaultWeekSet',
    async (payload = {}) => {
        const response = await defaultWeekSet(payload)
        return response
    }
)
export const idleTimeUpdate = createAsyncThunk(
    'projectsSlice/defaultIdleTime',
    async (payload = {}) => {
        const response = await defaultIdleTime(payload)
        return response
    }
)
export const screenShotUpdate = createAsyncThunk(
    'projectsSlice/defaultScreenshotTime',
    async (payload = {}) => {
        const response = await defaultScreenshotTime(payload)
        return response
    }
)

export const filesGetProject = createAsyncThunk(
    'projectsSlice/getProjectFiles',
    async (payload = {}) => {
        const response = await getProjectFiles(payload)
        return response
    }
)

export const filesGetCount = createAsyncThunk(
    'projectsSlice/countProjectFiles',
    async (payload = {}) => {
        const response = await countProjectFiles(payload)
        return response
    }
)

export const searchUsersCount = createAsyncThunk(
    'projectsSlice/searchUsersCount',
    async (payload = {}) => {
        const response = await countUser(payload)
        return response
    }
)

export const searchUsers = createAsyncThunk(
    'projectsSlice/searchUsers',
    async (payload = {}) => {
        const response = await readUser(payload)
        return response
    }
)

export const projectsSlice = createSlice({
    name: 'projectsSlice',
    initialState,
    reducers: {
        switchFormModal: (state, action) => {
            state.formModal = action.payload.open
            state.selectedProject = undefined
        },
        clearProjectCreate: (state, action) => {
            state.selectedProject = undefined
        },
        checkUpdate: (state, action) => {
            state.isUpdate = action.payload
        },
        switchFilter: (state, action) => {
            state.filterView = action.payload || false
        },
        filterDropdown: (state, action) => {
            state.filterDropDown = {
                ...state.filterDropDown,
                ...action.payload,
            }
        },
        searchPeople: (state, action) => {
            state.peopleSearch = action.payload
        },
        clearFilterDropDown: (state) => {
            state.filterDropDown = initialState.filterDropDown
        },
        searchProjects: (state, action) => {
            state.search = action.payload
        },
        projectStepData: (state, action) => {
            state.stepProjectData = action.payload
        },
        clearProjects: (state) => {
            state.search = ''
            state.projects = []
            state.formModal = false
            // state.selectedProject = undefined
        },
        changeTab: (state, action) => {
            state.defaultTab = action.payload
        },
        defaultTab: (state, action) => {
            state.defaultTab = action.payload
        },
        changePage: (state, action) => {
            state.currentPage = action.payload.currentPage
            state.skipPage = action.payload.skip
            state.pageLimit = action.payload.pageLimit
        },
        changePageList: (state, action) => {
            state.currentPageList = action.payload.currentPage
            state.skipPageList = action.payload.skip
            state.pageLimitList = action.payload.pageLimit
        },
        changeNotesPage: (state, action) => {
            state.notesCurrentPage = action.payload.currentPage
            state.notesSkipPage = action.payload.skip
            state.notesPageLimit = action.payload.pageLimit
        },
        changeFilesPage: (state, action) => {
            state.filesCurrentPage = action.payload.currentPage
            state.filesSkipPage = action.payload.skip
            state.filesPageLimit = action.payload.pageLimit
        },
        getProjectDetails: (state, action) => {
            state.getTeamProjects = action.payload
        },
        searchNotes: (state, action) => {
            state.searchNote = action.payload
        },
        setCurrentProject: (state, action) => {
            state.currentProject = action.payload
        },
        setGeneralSettingsData: (state, action) => {
            state.currentProjectData = action.payload
        },
        clearNote: (state) => {
            state.selectedNote = undefined
        },
        switchEditor: (state, action) => {
            if (action.payload?.name === 'add') {
                state.editModeEditor = false
            } else {
                state.editModeEditor = state.editModeEditor ? false : true
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(projectsList.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(projectsList.fulfilled, (state, action) => {
                state.status = 'loaded'
                if (action.payload.skip > 0) {
                    state.projects = [...state.projects, ...action.payload.data]
                } else {
                    state.projects = action.payload.data
                }
                state.isNext = action.payload.isNext
            })
            .addCase(projectGet.pending, (state) => {
                state.updatedDataStatus = 'loading'
            })
            .addCase(projectGet.fulfilled, (state, action) => {
                state.updatedDataStatus = 'loaded'
                state.selectedProject = action?.payload.data
            })
            .addCase(projectsListDropDown.pending, (state) => {
                state.listStatus = 'loading'
            })
            .addCase(projectsListDropDown.fulfilled, (state, action) => {
                state.listStatus = 'loaded'
                if (action.payload.skip > 0) {
                    state.projectsListData = [
                        ...state.projectsListData,
                        ...action.payload.data,
                    ]
                } else {
                    state.projectsListData = action.payload.data
                }
                state.isNextList = action.payload.isNext
            })
            .addCase(filesGetProject.pending, (state) => {
                state.filesStatus = 'loading'
            })
            .addCase(filesGetProject.fulfilled, (state, action) => {
                state.filesStatus = 'loaded'
                if (action.payload.skip > 0) {
                    state.filesList = [
                        ...state.filesList,
                        ...action.payload.data.data,
                    ]
                } else {
                    state.filesList = action.payload.data.data
                }

                state.filesIsNext = action.payload.data.isNext
            })
            .addCase(filesGetCount.fulfilled, (state, action) => {
                state.filesCount = action.payload.data
            })
            .addCase(totalProjects.fulfilled, (state, action) => {
                state.projectsCount = action.payload.data
            })
            .addCase(countTotalNotes.fulfilled, (state, action) => {
                state.notesCount = action.payload.data
            })
            .addCase(addRemoveProjectFiles.fulfilled, (state, action) => {
                state.progressCount = action.payload.progressCount
            })
            .addCase(listProjectNotes.pending, (state) => {
                state.noteStatus = 'loading'
            })
            .addCase(listProjectNotes.fulfilled, (state, action) => {
                state.noteStatus = 'loaded'
                if (action.payload.skip > 0) {
                    state.notesList = [
                        ...state.notesList,
                        ...action.payload.data,
                    ]
                } else {
                    state.notesList = action.payload.data
                }
                state.notesIsNext = action.payload.isNext
            })
            .addCase(getProjectNote.fulfilled, (state, action) => {
                state.selectedNote = action.payload.data
            })
            .addCase(searchUsers.pending, (state) => {
                state.searchUserStatus = 'loading'
            })
            .addCase(searchUsers.fulfilled, (state, action) => {
                state.searchUserStatus = 'loaded'
                if (action.payload.skip > 0) {
                    state.searchUsersList = [
                        ...state.searchUsersList,
                        ...action.payload.data,
                    ]
                } else {
                    state.searchUsersList = action.payload.data
                }
                state.isNextUser = action.payload.isNext
                state.currentUser = action.payload.currentCount
            })
            .addCase(searchUsersCount.fulfilled, (state, action) => {
                state.searchUsersCount = action.payload.data
            })
    },
})

export default projectsSlice.reducer

export const projectsState = (state) => {
    const { projectsSlice } = state
    const {
        search,
        status,
        projects,
        projectsCount,
        formModal,
        viewModal,
        selectedProject,
        filterView,
        filterDropDown,
        currentProject,
        pageLimit,
        progressCount,
        currentPage,
        skipPage,
        isNext,
        isUpdate,
        defaultTab,
        notesList,
        notesIsNext,
        notesPageLimit,
        notesCurrentPage,
        notesSkipPage,
        editModeEditor,
        notesCount,
        selectedNote,
        searchNote,
        noteStatus,
        currentProjectData,
        filesIsNext,
        filesPageLimit,
        filesCurrentPage,
        filesSkipPage,
        filesCount,
        filesList,
        filesStatus,
        isNextList,
        pageLimitList,
        currentPageList,
        skipPageList,
        projectsListData,
        getTeamProjects,
        stepProjectData,
        updatedDataStatus,
        searchUserStatus,
        searchUsersList,
        searchUsersCount,
        isNextUser,
        pageLimitUser,
        currentPageUser,
        skipPageUser,
        peopleSearch,
    } = projectsSlice
    return {
        search,
        status,
        projects,
        projectsCount,
        formModal,
        viewModal,
        selectedProject,
        filterView,
        filterDropDown,
        currentProject,
        pageLimit,
        currentPage,
        skipPage,
        progressCount,
        isNext,
        isUpdate,
        defaultTab,
        notesList,
        notesIsNext,
        notesPageLimit,
        notesCurrentPage,
        notesSkipPage,
        editModeEditor,
        notesCount,
        selectedNote,
        searchNote,
        noteStatus,
        currentProjectData,
        filesIsNext,
        filesPageLimit,
        filesCurrentPage,
        filesSkipPage,
        filesCount,
        filesList,
        filesStatus,
        isNextList,
        pageLimitList,
        currentPageList,
        skipPageList,
        projectsListData,
        getTeamProjects,
        stepProjectData,
        updatedDataStatus,
        searchUserStatus,
        searchUsersList,
        searchUsersCount,
        isNextUser,
        pageLimitUser,
        currentPageUser,
        skipPageUser,
        peopleSearch,
    }
}

export const {
    switchFormModal,
    switchViewModal,
    switchFilter,
    searchProjects,
    filterDropdown,
    clearFilterDropDown,
    clearProjects,
    setCurrentProject,
    changePage,
    checkUpdate,
    changeTab,
    defaultTab,
    switchEditor,
    changeNotesPage,
    searchNotes,
    clearNote,
    changeFilesPage,
    setGeneralSettingsData,
    changePageList,
    getProjectDetails,
    projectStepData,
    clearProjectCreate,
    searchPeople,
} = projectsSlice.actions
