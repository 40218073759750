import { useSelector } from 'react-redux'

// slices
import { loginState } from '../../modules/login/login.slice'

// assets
import {
    // ChatIcon,
    InquiriesIcon,
    TeamIcon,
    WorkSpaceIcon,
    RecruitIcon,
} from '../../assets/icons'

// styles
import styles from './navigation.module.css'

// constants
import { DOMAIN_LIST, SUPER_ADMIN } from '../../constants/roles'

// routes
import { PRIVATE_ROUTES } from '../../routes'

function SuperAdminRoutesNavigation(selected) {
    // console.log(selected)
    const { status, userProfile } = useSelector(loginState)

    return status !== 'loading' &&
        userProfile &&
        userProfile?.role?.roleName !== undefined &&
        userProfile?.role?.roleName === SUPER_ADMIN &&
        DOMAIN_LIST.includes(userProfile?.currentWorkSpace?.domain)
        ? [
            {
                key: 'workspace',
                label: 'Workspace',
                route: PRIVATE_ROUTES.workSpace,
                icon: (
                    <WorkSpaceIcon
                        className={styles.icon + ' in-btn'}
                        color={selected === 'workspace'}
                    />
                ),
            },
            {
                key: 'inquiries',
                route: PRIVATE_ROUTES.inquiries,
                label: 'Inquiries',
                icon: <InquiriesIcon className={styles.icon + ' in-btn'} />,
            },
            {
                key: 'recruit',
                route: PRIVATE_ROUTES.recruit,
                label: 'Recruit',
                icon: <RecruitIcon className={styles.icon + ' in-btn'} />,
            },
            {
                key: 'talent',
                route: PRIVATE_ROUTES.talent,
                label: 'Talent',
                icon: <TeamIcon className={styles.icon + ' in-btn'} />,
            },
            {
                key: 'jobs',
                route: PRIVATE_ROUTES.jobs,
                label: 'Jobs',
                icon: <TeamIcon className={styles.icon + ' in-btn'} />,
            },
            //   {
            //       key: 'chat',
            //       route: PRIVATE_ROUTES.chat,
            //       label: 'Chat',
            //       icon: <ChatIcon className={styles.icon + ' in-btn'} />,
            //   },
        ]
        : []
}

export default SuperAdminRoutesNavigation
