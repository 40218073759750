import React from 'react'

function InquirySummaryIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="18"
            fill="none"
            viewBox="0 0 15 18"
        >
            <path
                fill="#000"
                d="M14.167 17.335H.833A.833.833 0 010 16.5v-15c0-.46.373-.833.833-.833h13.334c.46 0 .833.373.833.833v15c0 .46-.373.834-.833.834zm-10-12.5V6.5h6.666V4.835H4.167zm0 3.333v1.667h6.666V8.168H4.167zm0 3.333v1.667h6.666v-1.667H4.167z"
            ></path>
        </svg>
    )
}

export default InquirySummaryIcon
