import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import S3FileUpload from 'react-s3'
import { debounce } from 'lodash'

// antd
import {
    Card,
    Checkbox,
    Col,
    Collapse,
    DatePicker,
    Divider,
    Form,
    Image,
    Input,
    Row,
    Select,
    Space,
    Spin,
    Typography,
    Upload,
    message,
    theme,
} from 'antd'
import { SearchOutlined } from '@ant-design/icons'

// slices
import {
    addSummary,
    checkButtonType,
    iconGet,
    profileLoading,
    profileState,
    switchProfileModal,
    updateUserGet,
} from '../profile.slice'
import { loginState } from '../../login/login.slice'

// components
import Button from '../../../components/button/button'
import ModalProfile from '../../../components/modal'

// assets
import {
    BinIcon,
    CompanyDefaultIcon,
    CrossIcon,
    ExpandIcon,
    LinkIcon,
    PreviewIcon,
    ProjectsIcon,
    VideosIcon,
} from '../../../assets/icons'

// modal
import ViewCertificatesModal from '../certificates/viewCertificatesModal'

// helpers
import { isEmpty } from '../../../helpers/fieldCheck'

import dayjs from 'dayjs'

function ProjectsModal({ modalType }) {
    const [form] = Form.useForm()

    const { Text, Title } = Typography

    const { TextArea } = Input

    const { Option } = Select

    const { Dragger } = Upload

    const { useToken } = theme
    const { token } = useToken()

    const dispatch = useDispatch()

    // SELECTORS
    const {
        profileModal,
        formButtonType,
        selectedData,
        profileData,
        updatedDataStatus,
    } = useSelector(profileState)
    const { userProfile } = useSelector(loginState)

    // DISABLE START DATE
    const disabledDateEnd = (current) => {
        const startDate = form.getFieldValue('start')
        // Disable dates that are before the selected start date or dates that are in the past
        return startDate
            ? (current && current.valueOf() < startDate.valueOf()) ||
                  (current && current.valueOf() > Date.now())
            : current && current.valueOf() > Date.now()
    }

    // DISABLE END DATE
    const disabledDateStart = (current) => {
        const endDate = form.getFieldValue('end')
        // Disable dates that are after the selected end date or dates that are in the future
        return (
            (endDate && current && current.valueOf() > endDate.valueOf()) ||
            (current && current.valueOf() > Date.now())
        )
    }

    // FORMAT DATEPICKER
    const formatDatePicker = (value) => {
        return `${dayjs(value).format('DD MMM YY')}`
    }

    const [fileURL, setFileURL] = useState([])
    const [linkExperienceOption, setLinkExperienceOption] = useState([])
    const [stream, setStream] = useState(false)
    const [viewMediaModal, setViewMediaModal] = useState({
        open: false,
        fileName: '',
    })
    const [checked, setChecked] = useState(false)
    const [errorFile, setErrorFile] = useState('')
    const [linksMoreData, setLinksMoreData] = useState([])
    const [linkLoading, setLinkLoading] = useState({
        index: undefined,
        loading: false,
    })

    function onFinishFailed() {}

    // PROFILE DATA
    useEffect(() => {
        if (profileData?.data?.experience?.length > 0) {
            let options = []
            profileData?.data?.experience.forEach((data) => {
                if (data?.isCompleted) {
                    options.push({
                        label: `${`${data?.title} | ${data?.companyName?.name}`}`,
                        value: `${`${data?.title} | ${data?.companyName?.name}`}`,
                    })
                }
            })
            setLinkExperienceOption(options)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profileData])

    // EDIT MODAL SET DATA INITIALLY
    useEffect(() => {
        if (selectedData) {
            if (selectedData?.currentWorking) {
                setChecked(true)
            }
            setFileURL(selectedData?.media)

            let linksMoreData = []

            if (selectedData?.links?.length > 0) {
                // const linkData = selectedData?.links.slice(1)
                linksMoreData = selectedData?.links.map((data) => {
                    return {
                        logo: data?.type?.logo,
                        title: data?.type?.title,
                        url: data?.url,
                    }
                })
                setLinksMoreData(linksMoreData)
            }

            form.setFieldsValue({
                ...selectedData,
                links: selectedData?.links?.length > 0 ? linksMoreData : [],
                linkToExperience: selectedData?.linkToExperience?.title,
                start: selectedData?.start ? dayjs(selectedData?.start) : '',
                end: selectedData?.end ? dayjs(selectedData?.end) : '',
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form, selectedData])

    const searchFn = debounce(async (value, index) => {
        if (value?.length > 0) {
            setLinkLoading({ index: index, loading: true })
            const result = await dispatch(iconGet(value))
            const data = result?.payload?.data

            if (data) {
                const { success } = data
                if (success) {
                    // Example usage

                    if (index in linksMoreData) {
                        const newData = {
                            url: value,
                            logo:
                                data?.data?.faviconUrl?.length === 0
                                    ? 'https://pms-bucket123.s3.amazonaws.com/Vector_1692851233.png'
                                    : data?.data?.faviconUrl,
                            title: data?.data?.title,
                        }

                        let linksNewData = [...linksMoreData]
                        linksNewData[index] = newData

                        setLinksMoreData(linksNewData)
                        form.setFieldValue('links', linksNewData)
                    } else {
                        const newData = {
                            url: value,
                            logo:
                                data?.data?.faviconUrl?.length === 0
                                    ? 'https://pms-bucket123.s3.amazonaws.com/Vector_1692851233.png'
                                    : data?.data?.faviconUrl,
                            title: data?.data?.title,
                        }
                        setLinksMoreData([...linksMoreData, newData])
                        form.setFieldValue('links', [...linksMoreData, newData])
                    }

                    setLinkLoading({ index: index, loading: false })
                } else {
                    if (index in linksMoreData) {
                        const newData = {
                            url: value,
                            logo: 'https://pms-bucket123.s3.amazonaws.com/Vector_1692851233.png',
                            title: '',
                        }

                        let linksNewData = [...linksMoreData]
                        linksNewData[index] = newData

                        setLinksMoreData(linksNewData)
                        form.setFieldValue('links', linksNewData)
                    } else {
                        const newData = {
                            url: value,
                            logo: 'https://pms-bucket123.s3.amazonaws.com/Vector_1692851233.png',

                            title: '',
                        }
                        setLinksMoreData([...linksMoreData, newData])
                        form.setFieldValue('links', [...linksMoreData, newData])
                    }
                    setLinkLoading({ index: index, loading: false })
                }
            }
        }
    }, 1000)

    // SAVE / SAVE AND ADD MORE / SAVE AS DRAFT  PROJECTS
    async function onFinishProjects(value) {
        let modalState = { ...profileModal }
        setErrorFile('')

        delete value['fileLink']

        if (formButtonType === 'draftButtonLoading') {
            if (
                Object.keys(value).every((item) => {
                    if (typeof value[item] === 'object') {
                        return value[item].every(
                            (data) =>
                                data.url === '' &&
                                data.title === '' &&
                                data.logo === ''
                        )
                    } else {
                        return isEmpty(value[item])
                    }
                }) &&
                fileURL?.length === 0
            ) {
                message.info('Please Fill at-least one Field')
                form.resetFields()
                return
            }
        }

        dispatch(
            profileLoading({
                name: modalType,
                buttonName: formButtonType,
                loading: true,
            })
        )

        const groupLinks =
            value?.links?.length > 0
                ? value?.links.map((data) => {
                      return {
                          type: {
                              logo: data?.logo,
                              title: data?.title,
                          },
                          url: data?.url,
                      }
                  })
                : []

        const payload = {
            ...value,
            links: groupLinks,
            linkToExperience: {
                logo: 'https://pms-bucket123.s3.amazonaws.com/Page 1_1689584485.svg',
                title: value.linkToExperience,
            },
            media: fileURL?.length > 0 ? fileURL : [],
            _id: selectedData && selectedData?._id,
            start: new Date(value?.start).getTime(),
            end: !checked && new Date(value?.end).getTime(),
            isCompleted: formButtonType === 'draftButtonLoading' ? false : true,
        }

        delete payload.type
        delete payload.url

        const result = await dispatch(
            addSummary({
                _id: userProfile?._id,
                summary: {
                    step: modalType,
                    data: payload,
                },
            })
        )
        const data = result?.payload?.data

        if (data) {
            const { success, message: errorMessage } = data
            if (success) {
                dispatch(
                    profileLoading({
                        name: modalType,
                        buttonName: formButtonType,
                        loading: false,
                    })
                )
                dispatch(updateUserGet({ _id: userProfile?._id }))
                if (formButtonType === 'addMoreButtonLoading') {
                    form.resetFields()
                    setFileURL([])
                    dispatch(
                        switchProfileModal({ name: 'projects', open: true })
                    )
                } else {
                    dispatch(
                        switchProfileModal({ name: 'projects', open: false })
                    )
                }
                message.success(errorMessage)
            } else {
                dispatch(switchProfileModal(modalState))
                if (errorMessage) {
                    message.error(errorMessage)
                } else {
                    message.error('Something went wrong, try again later.')
                }
            }
        }
    }

    // ON CHANGE FILE UPLOAD PROPS
    const props = {
        name: 'fileLink',
        multiple: true,
        onChange(info) {
            let fileUpload = []

            setErrorFile('')
            if (
                fileURL?.length > 6 ||
                (fileURL?.length < 6 &&
                    info?.fileList?.length + fileURL?.length > 6)
            ) {
                setErrorFile('Maximum limit of Media is 6')
                return
            }

            const isLt5M =
                info?.fileList?.length > 0 &&
                info?.fileList.some((data) => data?.size / 1024 / 1024 < 5)

            if (!isLt5M) {
                message.error('File must be smaller than 5MB')
                return
            }

            const fileUploadCheck =
                info?.fileList?.length > 0 &&
                info?.fileList
                    .filter((data) => data?.size / 1024 / 1024 < 5)
                    .map((data) => data)

            setStream(true)
            fileUploadCheck?.length > 0 &&
                fileUploadCheck.forEach((data) => {
                    const nameChange = data?.name
                        .split('.')[0]
                        .replaceAll('\\s+', '')
                        .concat(`_${dayjs(new Date()).unix()}`)
                        .concat(`.${data?.name.split('.')[1]}`)

                    const newFileData = new File(
                        [data?.originFileObj],
                        nameChange,
                        { type: data?.type }
                    )
                    window.Buffer = window.Buffer || require('buffer').Buffer

                    S3FileUpload.uploadFile(newFileData, userProfile?.s3Config)
                        .then(async (data) => {
                            if (
                                fileUploadCheck?.length ===
                                fileUpload?.length + 1
                            ) {
                                setStream(false)
                            }
                            fileUpload = [...fileUpload, data?.location]
                            setFileURL([...fileURL, ...fileUpload])
                        })
                        .catch(() => {
                            message.error('Upload Failed!. Please Upload again')
                        })
                })
        },
        beforeUpload() {
            return false
        },
        onDrop() {},
    }

    // COLLAPSE LABEL COMPONENT
    const CollapseLabel = ({ name, icon, description }) => (
        <Row
            align={'middle'}
            style={{
                columnGap: '20px',
                marginBottom: '18px',
            }}
        >
            <Row
                style={{
                    height: '50px',
                    width: '50px',
                    borderRadius: '10px',
                    padding: '12px',
                    backgroundColor: token.colorPalette.baseColor.tertiary,
                }}
                align={'middle'}
                justify={'center'}
            >
                <div
                    style={{
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '30px',
                    }}
                >
                    {icon}
                </div>
            </Row>
            <div>
                <Title
                    style={{
                        fontSize: token.fontSizeHeading4,
                    }}
                >
                    {name}
                </Title>
                <Text className="sub-title">{description}</Text>
            </div>
        </Row>
    )

    // COLLAPSE ITEMS
    const getItems = () => [
        {
            key: '1',
            label: (
                <CollapseLabel
                    name="Add Links"
                    icon={
                        <LinkIcon color={token.colorPalette.baseColor.white} />
                    }
                    description="Please add required links to your project respectively."
                />
            ),
            children: (
                <>
                    <div>
                        <Row align={'middle'}>
                            <Form.List name="links">
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map(
                                            (
                                                { key, name, ...restField },
                                                index
                                            ) => (
                                                <Row
                                                    gutter={14}
                                                    align={'middle'}
                                                    style={{
                                                        width: '100%',
                                                        marginTop: '10px',
                                                    }}
                                                >
                                                    <Col span={11}>
                                                        <Form.Item
                                                            label="URL Link"
                                                            {...restField}
                                                            name={[name, 'url']}
                                                            colon={false}
                                                            className="linkProjectsUrl"
                                                        >
                                                            <Input
                                                                name="url"
                                                                placeholder="Enter URL Link"
                                                                type="url"
                                                                suffix={
                                                                    linkLoading?.index !==
                                                                        undefined &&
                                                                    linkLoading?.index ===
                                                                        index &&
                                                                    linkLoading?.loading && (
                                                                        <Spin />
                                                                    )
                                                                }
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    searchFn(
                                                                        e.target
                                                                            .value,
                                                                        index
                                                                    )
                                                                }}
                                                                prefix={
                                                                    <SearchOutlined
                                                                        style={{
                                                                            fontSize: 16,
                                                                            marginRight: 5,
                                                                        }}
                                                                    />
                                                                }
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={11}>
                                                        <div
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                        >
                                                            <Space.Compact>
                                                                <Form.Item
                                                                    label="Type"
                                                                    {...restField}
                                                                    name={[
                                                                        name,
                                                                        'logo',
                                                                    ]}
                                                                    colon={
                                                                        false
                                                                    }
                                                                >
                                                                    <div
                                                                        style={{
                                                                            border: `1px solid ${token.colorPalette.textColor.quaternary}`,
                                                                            height: '50px',
                                                                            width: '50px',
                                                                            borderTopLeftRadius:
                                                                                '4px',
                                                                            borderBottomLeftRadius:
                                                                                '4px',
                                                                        }}
                                                                        className="linkProjects"
                                                                    >
                                                                        {linksMoreData[
                                                                            index
                                                                        ]?.logo
                                                                            ?.length >
                                                                            0 && (
                                                                            <Image
                                                                                preview={
                                                                                    false
                                                                                }
                                                                                style={{
                                                                                    display:
                                                                                        'flex',
                                                                                    alignItems:
                                                                                        'center',
                                                                                    justifyContent:
                                                                                        'center',
                                                                                    margin: 'auto',
                                                                                }}
                                                                                src={
                                                                                    linksMoreData[
                                                                                        index
                                                                                    ]
                                                                                        ?.logo
                                                                                }
                                                                                fallback="https://pms-bucket123.s3.amazonaws.com/Vector_1692851233.png"
                                                                            />
                                                                        )}
                                                                    </div>
                                                                </Form.Item>
                                                                <Form.Item
                                                                    {...restField}
                                                                    label="  "
                                                                    name={[
                                                                        name,
                                                                        'title',
                                                                    ]}
                                                                    colon={
                                                                        false
                                                                    }
                                                                >
                                                                    <Input
                                                                        name="title"
                                                                        // value={
                                                                        //     linksMoreData[
                                                                        //         index
                                                                        //     ]
                                                                        //         ?.title
                                                                        // }
                                                                        onChange={(
                                                                            e
                                                                        ) => {
                                                                            const dataLink =
                                                                                [
                                                                                    ...linksMoreData,
                                                                                ]
                                                                            dataLink[
                                                                                index
                                                                            ].title =
                                                                                e.target.value
                                                                            setLinksMoreData(
                                                                                dataLink
                                                                            )
                                                                        }}
                                                                    />
                                                                </Form.Item>
                                                            </Space.Compact>
                                                        </div>
                                                    </Col>
                                                    {index > 0 && (
                                                        <Col span={2}>
                                                            <div
                                                                style={{
                                                                    width: '15px',
                                                                    height: '15px',
                                                                    cursor: 'pointer',
                                                                    float: 'right',
                                                                }}
                                                                onClick={() => {
                                                                    remove(name)

                                                                    const arrayRemove =
                                                                        [
                                                                            ...linksMoreData,
                                                                        ]
                                                                    arrayRemove.splice(
                                                                        name,
                                                                        1
                                                                    )
                                                                    setLinksMoreData(
                                                                        arrayRemove
                                                                    )
                                                                    setLinkLoading(
                                                                        {
                                                                            index: name,
                                                                            loading: false,
                                                                        }
                                                                    )
                                                                }}
                                                            >
                                                                <CrossIcon
                                                                    color={
                                                                        token
                                                                            .colorPalette
                                                                            .textColor
                                                                            .tertiary
                                                                    }
                                                                />
                                                            </div>
                                                        </Col>
                                                    )}
                                                </Row>
                                            )
                                        )}
                                        <Button
                                            props={{
                                                text: 'Add More',
                                                onClick: () => add(),
                                                disabled:
                                                    fields?.length !==
                                                        linksMoreData?.length ||
                                                    (linksMoreData?.length >
                                                        0 &&
                                                        Object.keys(
                                                            linksMoreData[
                                                                linksMoreData?.length -
                                                                    1
                                                            ]
                                                        ).some(
                                                            (key) =>
                                                                linksMoreData[
                                                                    linksMoreData?.length -
                                                                        1
                                                                ][key]
                                                                    ?.length ===
                                                                0
                                                        )) ||
                                                    linksMoreData?.length ===
                                                        0 ||
                                                    linkLoading?.loading,
                                                style: {
                                                    margin: '20px 0px 0px auto',
                                                },
                                            }}
                                        />
                                    </>
                                )}
                            </Form.List>
                        </Row>
                    </div>
                </>
            ),
        },
        {
            key: '2',
            label: (
                <CollapseLabel
                    name="Upload Media"
                    icon={
                        <VideosIcon
                            color={token.colorPalette.baseColor.white}
                        />
                    }
                    description="Please upload the files to show the experience."
                />
            ),
            children: (
                <Row
                    style={{
                        overflowX: 'hidden',
                    }}
                    justify={'space-between'}
                    gutter={16}
                >
                    <Col span={24}>
                        {fileURL?.length > 0 && !stream ? (
                            <>
                                <Card
                                    style={{
                                        border: `2px dashed ${token.colorPalette.textColor.quaternary}`,
                                        margin: '4px 0px 14px 0px',
                                        padding: '0px',
                                        minHeight: '110px',
                                    }}
                                    styles={{
                                        body: {
                                            padding: '15px',
                                        },
                                    }}
                                >
                                    <Row
                                        style={{
                                            columnGap: '13px',
                                            rowGap: '13px',
                                        }}
                                    >
                                        {fileURL.map((link) => (
                                            <Col span={6}>
                                                <div className="uploadImage">
                                                    <Image
                                                        src={link}
                                                        height="162px"
                                                        width={'100%'}
                                                        style={{
                                                            objectFit: 'cover',
                                                            borderRadius: '8px',
                                                            cursor: 'pointer',
                                                        }}
                                                        preview={false}
                                                    />

                                                    <div
                                                        className={
                                                            'hoverLayerImageProjects'
                                                        }
                                                    ></div>
                                                    <div
                                                        className={
                                                            'hoverShowImageProjects'
                                                        }
                                                    >
                                                        <div
                                                            style={{
                                                                width: '25px',
                                                                height: '25px',
                                                                cursor: 'pointer',
                                                                marginRight:
                                                                    '5px',
                                                            }}
                                                            onClick={() =>
                                                                setViewMediaModal(
                                                                    {
                                                                        open: true,
                                                                        fileName:
                                                                            link,
                                                                    }
                                                                )
                                                            }
                                                        >
                                                            <PreviewIcon />
                                                        </div>
                                                        <div
                                                            style={{
                                                                width: '25px',
                                                                height: '25px',
                                                                cursor: 'pointer',
                                                            }}
                                                            onClick={() => {
                                                                const removeData =
                                                                    [...fileURL]
                                                                removeData.splice(
                                                                    removeData.findIndex(
                                                                        (
                                                                            item
                                                                        ) =>
                                                                            item ===
                                                                            link
                                                                    ),
                                                                    1
                                                                )
                                                                setFileURL(
                                                                    removeData
                                                                )
                                                            }}
                                                        >
                                                            <BinIcon
                                                                color={
                                                                    token
                                                                        .colorPalette
                                                                        .baseColor
                                                                        .error
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        ))}
                                    </Row>
                                </Card>
                                <div
                                    style={{
                                        width: '100%',
                                    }}
                                >
                                    <Upload {...props}>
                                        <Button
                                            props={{
                                                text: 'Upload Files',
                                                onClick: () => {},
                                            }}
                                        />
                                    </Upload>
                                </div>
                            </>
                        ) : (
                            <Form.Item
                                name={'fileLink'}
                                rules={
                                    formButtonType !== 'draftButtonLoading' && [
                                        {
                                            validator: async () => {
                                                if (fileURL?.length === 0) {
                                                    return Promise.reject(
                                                        new Error(
                                                            'Please Upload at-lease one media'
                                                        )
                                                    )
                                                }
                                            },
                                        },
                                    ]
                                }
                                help={fileURL?.length === 0 ? null : ''}
                                className="fileDragger"
                            >
                                <Dragger
                                    {...props}
                                    fileList={[]}
                                    accept=".png,.jpg,.jpeg,.svg"
                                    previewFile={false}
                                    style={{
                                        border: `2px dashed ${token.colorPalette.textColor.quaternary}`,
                                        padding: '20px',
                                        minHeight: '185px',
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    {stream ? (
                                        <>
                                            <Text
                                                className="titleLight titleMiddle"
                                                style={{}}
                                            >
                                                Uploading....
                                            </Text>
                                        </>
                                    ) : (
                                        <>
                                            <Text
                                                className="titleLight titleMiddle"
                                                style={{}}
                                            >
                                                Click or Drag
                                            </Text>
                                            <br />
                                            <Text
                                                className="titleLight"
                                                style={{
                                                    fontSize:
                                                        token.fontSizeHeading5,
                                                }}
                                            >
                                                File to Upload (Max 6
                                                Certificates)
                                            </Text>
                                        </>
                                    )}
                                </Dragger>
                                {errorFile?.length > 0 && (
                                    <span style={{ color: 'red', margin: 2 }}>
                                        {errorFile}
                                    </span>
                                )}
                            </Form.Item>
                        )}
                    </Col>
                </Row>
            ),
        },
    ]

    return updatedDataStatus === 'loading' ? (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
            }}
        >
            <Spin size="large" />
        </div>
    ) : (
        <div>
            <Form
                layout="vertical"
                name="addProjects"
                onFinish={onFinishProjects}
                onFinishFailed={onFinishFailed}
                requiredMark={false}
                onSubmitCapture={async (e) => {
                    await dispatch(checkButtonType(e.nativeEvent.submitter.id))
                }}
                form={form}
                initialValues={{
                    links: [{ url: '', logo: '', title: '' }],
                }}
                style={{
                    marginBottom: '20px',
                    overflowX: 'hidden',
                }}
            >
                <Row
                    style={{
                        rowGap: '20px',
                    }}
                    justify={'space-between'}
                    gutter={16}
                >
                    <Col span={12}>
                        <Form.Item
                            label="Project Name*"
                            name="projectName"
                            rules={
                                formButtonType !== 'draftButtonLoading' && [
                                    {
                                        required: true,
                                        message: 'Please Enter Project Name',
                                        max: 25,
                                        transform: (value) =>
                                            value?.trimStart(),
                                    },
                                ]
                            }
                            colon={false}
                        >
                            <Input
                                name="projectName"
                                placeholder="Enter Project Name"
                                onChange={(e) => {
                                    form.setFieldValue(
                                        'projectName',
                                        e.target.value.trimStart()
                                    )
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name={'linkToExperience'}
                            label="Link to Experience"
                        >
                            <Select
                                placeholder="Search Link"
                                showSearch
                                allowClear={false}
                                getPopupContainer={(trigger) =>
                                    trigger.parentNode
                                }
                            >
                                {linkExperienceOption?.length > 0 &&
                                    linkExperienceOption.map((option) => {
                                        return (
                                            <Option
                                                key={option?.value}
                                                value={option?.value}
                                            >
                                                <Space size={'middle'}>
                                                    <div
                                                        style={{
                                                            width: '30px',
                                                            height: '30px',
                                                            padding: '8px 5px',
                                                            display: 'flex',
                                                            alignItems:
                                                                'center',
                                                            justifyContent:
                                                                'center',
                                                            border: `1px solid ${token.colorPalette.textColor.quinary}`,
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                width: '20px',
                                                                height: '20px',
                                                            }}
                                                        >
                                                            <CompanyDefaultIcon />
                                                        </div>
                                                    </div>
                                                    <Text>{option?.label}</Text>
                                                </Space>
                                            </Option>
                                        )
                                    })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            label="Summary*"
                            name="summary"
                            rules={
                                formButtonType !== 'draftButtonLoading' && [
                                    {
                                        required: true,
                                        message: 'Please Enter Summary',
                                    },
                                    {
                                        min: 8,
                                        max: 250,
                                        message:
                                            'Summary is minimum 8 and maximum 250 characters long',
                                    },
                                ]
                            }
                            colon={false}
                        >
                            <TextArea
                                rows={4}
                                placeholder={'Enter Summary...'}
                                name={'summary'}
                                onChange={(e) => {
                                    form.setFieldValue(
                                        'summary',
                                        e.target.value.trimStart()
                                    )
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Start Date*"
                            colon={false}
                            name={'start'}
                            rules={
                                formButtonType !== 'draftButtonLoading' && [
                                    {
                                        required: true,
                                        message: 'Please Select Start Date',
                                    },
                                ]
                            }
                        >
                            <DatePicker
                                allowClear={false}
                                name="start"
                                disabledDate={disabledDateStart}
                                format={formatDatePicker}
                                // getPopupContainer={(trigger) =>
                                //     trigger.parentNode
                                // }
                                getPopupContainer={() => document.body}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="End Date*"
                            colon={false}
                            name={'end'}
                            rules={
                                formButtonType !== 'draftButtonLoading' &&
                                !checked && [
                                    {
                                        required: true,
                                        message: 'Please Select End Date',
                                    },
                                ]
                            }
                        >
                            <DatePicker
                                allowClear={false}
                                name="end"
                                disabledDate={disabledDateEnd}
                                disabled={checked}
                                format={formatDatePicker}
                                // getPopupContainer={(trigger) =>
                                //     trigger.parentNode
                                // }
                                getPopupContainer={() => document.body}
                            />
                        </Form.Item>
                    </Col>

                    <Col>
                        <Form.Item
                            name="currentWorking"
                            valuePropName="checked"
                        >
                            <Checkbox
                                onChange={(e) => {
                                    setChecked(e.target.checked)
                                    if (e.target.checked === true) {
                                        form.setFields([
                                            {
                                                name: 'end',
                                                errors: [],
                                            },
                                        ])
                                    }
                                    form.setFieldValue('end', '')
                                }}
                            >
                                I am currently working in this project
                            </Checkbox>
                        </Form.Item>
                    </Col>
                </Row>
                <Divider />
                <Collapse
                    bordered={false}
                    defaultActiveKey={['1', '2', '3']}
                    expandIcon={({ isActive }) => {
                        return <ExpandIcon isActive={isActive} />
                    }}
                    expandIconPosition="right"
                    style={{
                        backgroundColor: 'transparent',
                    }}
                    items={getItems()}
                />
            </Form>
            <ModalProfile
                visibility={viewMediaModal?.open}
                handleCancel={() => {
                    setViewMediaModal({ open: false, fileName: '' })
                }}
                icon={<ProjectsIcon />}
                modalKey="viewVideo"
                footer={false}
                modalTitle="Media"
                showTitle="Media"
                content={
                    <ViewCertificatesModal
                        fileName={viewMediaModal?.fileName}
                    />
                }
            />
        </div>
    )
}

export default ProjectsModal
