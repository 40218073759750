// antd
import { Col, Divider, Typography, theme } from 'antd'

const NoInquiriesComponent = ({ column }) => {
    const { Text } = Typography

    const { useToken } = theme

    const { token } = useToken()

    return (
        <>
            <Col span={6}>
                <div
                    className={'sub-title'}
                    style={{
                        padding: '4px 12px',
                        borderRadius: 1000,
                        backgroundColor: '#CEE0F2',
                        width: 'fit-content',
                    }}
                >
                    <Text
                        style={{
                            color: token.colorPalette.baseColor.black,
                        }}
                    >
                        {column?._id}
                    </Text>
                </div>
                <Divider
                    style={{
                        marginBottom: '0px',
                        marginTop: '16px',
                    }}
                />
            </Col>
        </>
    )
}

export default NoInquiriesComponent
