import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Mention, MentionsInput } from 'react-mentions'
import InfiniteScroll from 'react-infinite-scroll-component'
import { FileIcon, defaultStyles } from 'react-file-icon'

// antd
import {
    Row,
    Typography,
    Space,
    Avatar,
    Image,
    message,
    Drawer,
    Spin,
    theme,
} from 'antd'

// slices
import { profileState, updateUserGet } from '../profile/profile.slice'
import { loginState } from '../login/login.slice'
import {
    changePage,
    clearComments,
    recruitAddRemoveComments,
    recruitFilesRemove,
    recruitListGet,
    recruitState,
} from './recruit.slice'

// components
import Button from '../../components/button/button'
import DeletePopUp from '../../components/deletePopUp/deletePopUp'

// attachment component
import RecruitViewMediaModal from './recruitViewMediaModal'
import RecruitAttachment from './recruitAttachment'

// section
import TalentBasicDetail from '../profile/talentBasicDetail'

// helpers
import { unique } from '../../helpers/uniqueArray'

// constants
import { fileTypes, videoTypes } from '../../constants/mediaTypes'

// assets
import {
    BinIcon,
    NoNotesIcon,
    NotesRecruitIcon,
    PreviewIcon,
} from '../../assets/icons'

const NotesDrawer = ({ openDrawer, onClose }) => {
    const { Title, Text } = Typography

    const { useToken } = theme

    const { token } = useToken()

    const dispatch = useDispatch()

    const { profileData, status: profileStatus } = useSelector(profileState)
    const { userProfile } = useSelector(loginState)
    const {
        commentsList,
        isNext,
        pageLimit,
        skipPage,
        currentPage,
        recruitFiles,
        status,
    } = useSelector(recruitState)

    const [filteredMembers, setFilteredMembers] = useState([])

    const initialState = {
        addCommentLoader: false,
        comment: '',
        deleteModal: false,
        deleteButtonLoading: false,
        files: [],
        mentionData: {
            name: '',
            value: '',
            mentionData: null,
            mentionDataList: [],
            comment: '',
            users: [],
        },
        filteredCommentsData: [],
        viewMedia: {
            open: false,
            file: {},
        },
    }

    const [state, setState] = useState(initialState)

    useEffect(() => {
        if (commentsList?.length > 0) {
            let array = []
            commentsList?.length > 0 &&
                commentsList.forEach((data) => {
                    array = [...array, data]
                })
            let newArray = [...state?.filteredCommentsData, ...array]
            let finalArray = unique(newArray, '_id')
            setState((prevState) => ({
                ...prevState,
                filteredCommentsData: finalArray,
            }))
            if (!isNext) {
                return
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [commentsList])

    function refreshPage() {
        setState((prevState) => ({
            ...prevState,
            filteredCommentsData: [],
        }))

        dispatch(
            changePage({
                currentPage: 1,
                skip: 0,
                pageLimit: pageLimit,
            })
        )
    }

    useEffect(() => {
        refreshPage()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openDrawer])

    const fetchMoreComments = async () => {
        if (isNext) {
            dispatch(
                changePage({
                    currentPage: currentPage + 1,
                    skip: skipPage + pageLimit,
                    pageLimit: pageLimit,
                })
            )
            await dispatch(
                recruitListGet({
                    skip: skipPage + pageLimit,
                    limit: pageLimit,
                    userId: profileData?.data?._id,
                })
            )
        }
    }

    // HANDLE CHANGE MENTIONS COMMENT
    const handleChangeMentions = (_, newValue, newPlainTextValue, mentions) => {
        const mentionNew =
            mentions?.length > 0 ? mentions.map((data) => data?.id) : []

        setState((prevState) => ({
            ...prevState,
            mentionData: {
                ...prevState?.mentionData,
                value: newValue,
                comment: newPlainTextValue,
                mentionDataList: [...new Set(mentionNew)],
            },
        }))
    }

    // ADD COMMENT
    async function addRemoveComment(fieldName = 'add') {
        setState((prevState) => ({ ...prevState, addCommentLoader: true }))

        let payload = {
            recruitComment: {
                uId: userProfile?._id,
                createdDate: new Date().getTime(),
                content: state?.mentionData?.comment,
                mentionId: state?.mentionData?.mentionDataList,
                attachments: recruitFiles?.length > 0 ? recruitFiles : [],
            },
        }

        if (fieldName === 'remove') {
            payload = { recruitCommentId: state?.comment?._id }
        }

        const result = await dispatch(
            recruitAddRemoveComments({
                id: profileData?.data?._id,
                data: {
                    fieldName: fieldName,
                    ...payload,
                },
            })
        )

        const data = result?.payload?.data
        if (data) {
            setState((prevState) => ({
                ...prevState,
                mentionData: {
                    ...prevState?.mentionData,
                    value: '',
                    comment: '',
                },
                files: [],
                comment: '',
            }))
            const { success, message: checkMessage } = data
            if (success) {
                setState((prevState) => ({
                    ...prevState,
                    addCommentLoader: false,
                    deleteButtonLoading: false,
                    deleteModal: false,
                    filteredCommentsData: [],
                }))
                message.success(checkMessage)
                dispatch(recruitFilesRemove([]))
                dispatch(
                    recruitListGet({
                        userId: profileData?.data?._id,
                        limit: pageLimit,
                        skip: 0,
                    })
                )
                dispatch(
                    updateUserGet({
                        _id: profileData?.data?._id,
                    })
                )
            } else {
                if (checkMessage) {
                    message.error(checkMessage)
                    dispatch(recruitFilesRemove([]))
                    setState((prevState) => ({
                        ...prevState,
                        addCommentLoader: false,
                        deleteButtonLoading: false,
                        deleteModal: false,
                    }))
                } else {
                    dispatch(recruitFilesRemove([]))
                    setState((prevState) => ({
                        ...prevState,
                        addCommentLoader: false,
                        deleteModal: false,
                    }))
                    if (typeof checkMessage === 'object') {
                        for (const key in checkMessage) {
                            message.error(checkMessage[key])
                        }
                    } else if (typeof checkMessage === 'string') {
                        message.error(checkMessage)
                    } else {
                        message.error('Something went wrong, try again later.')
                    }
                }
            }
        }
    }

    return (
        <>
            <DeletePopUp
                previewDelete={state?.deleteModal}
                onDelete={() => {
                    setState((prevState) => ({
                        ...prevState,
                        deleteButtonLoading: true,
                    }))
                    addRemoveComment('remove')
                }}
                loadingButton={state?.deleteButtonLoading}
                onCancel={() => {
                    setState((prevState) => ({
                        ...prevState,
                        deleteButtonLoading: false,
                        deleteModal: false,
                    }))
                }}
                modalTitle="Delete Comment"
                pageKey="Comment"
            />
            <Drawer
                placement="right"
                closable={false}
                open={openDrawer}
                onClose={() => {
                    onClose()
                    setState((prevState) => ({
                        ...prevState,
                        filteredCommentsData: [],
                    }))
                    dispatch(recruitFilesRemove([]))
                    dispatch(clearComments([]))
                }}
                footer={null}
                title={null}
                style={{
                    height: '100vh',
                    minWidth: '1000px',
                    width: '1000px',
                }}
                styles={{
                    body: {
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                        padding: 0,
                    },
                    wrapper: {
                        width: 'auto !important',
                    },
                }}
                rootClassName="talentViewDrawer"
            >
                <Spin
                    style={{
                        height: '100%',
                    }}
                    wrapperClassName="spin-wrapper"
                    spinning={profileStatus === 'loading' ? true : false}
                >
                    <div
                        style={{
                            padding: '35px 32px',
                            height: '100%',
                        }}
                    >
                        <TalentBasicDetail
                            profileData={profileData}
                            pageKeyProfile={'recruit'}
                            onCancel={() => onClose()}
                        />
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                height: '84%',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Row
                                align={'middle'}
                                style={{
                                    columnGap: '8px',
                                    marginTop: '30px',
                                }}
                            >
                                <NotesRecruitIcon />
                                <Title level={4}>Notes</Title>
                            </Row>
                            {state?.filteredCommentsData?.length > 0 &&
                            status === 'loaded' ? (
                                <>
                                    <InfiniteScroll
                                        dataLength={
                                            state?.filteredCommentsData
                                                ?.length > 0
                                                ? state?.filteredCommentsData
                                                      ?.length
                                                : 0
                                        }
                                        next={fetchMoreComments}
                                        hasMore={isNext}
                                        style={{
                                            overflowY: 'scroll',
                                            overflowX: 'hidden',
                                            marginBottom: '20px',
                                        }}
                                        height={'55vh'}
                                    >
                                        {state?.filteredCommentsData?.length >
                                            0 &&
                                            state?.filteredCommentsData.map(
                                                (data) => (
                                                    <>
                                                        <Row
                                                            style={{
                                                                minWidth:
                                                                    '100%',
                                                                width: '100%',
                                                                flexFlow:
                                                                    'row nowrap',
                                                                marginTop: 20,
                                                            }}
                                                            justify={
                                                                'space-between'
                                                            }
                                                            key={data?._id}
                                                        >
                                                            <Row
                                                                style={{
                                                                    flexFlow:
                                                                        'row nowrap',
                                                                    width: '100%',
                                                                }}
                                                            >
                                                                <div
                                                                    style={{
                                                                        marginRight:
                                                                            '10px',
                                                                    }}
                                                                >
                                                                    {data?.user &&
                                                                    data?.user
                                                                        ?.profilePicture ? (
                                                                        <>
                                                                            <Image
                                                                                src={
                                                                                    data
                                                                                        ?.user
                                                                                        ?.profilePicture
                                                                                }
                                                                                height={
                                                                                    36
                                                                                }
                                                                                width={
                                                                                    36
                                                                                }
                                                                                style={{
                                                                                    borderRadius:
                                                                                        '50%',
                                                                                }}
                                                                                preview={
                                                                                    false
                                                                                }
                                                                            />
                                                                        </>
                                                                    ) : (
                                                                        <Avatar
                                                                            style={{
                                                                                cursor: 'pointer',
                                                                                backgroundColor:
                                                                                    'rgb(0 10 129 / 25%)',
                                                                                height: '36px',
                                                                                width: '36px',
                                                                            }}
                                                                        >
                                                                            {data?.user?.name[0].toUpperCase()}
                                                                            {data?.user?.name.split(
                                                                                ' '
                                                                            )[1] !==
                                                                                undefined &&
                                                                                data?.user?.name
                                                                                    .split(
                                                                                        ' '
                                                                                    )[1][0]
                                                                                    .toUpperCase()}
                                                                        </Avatar>
                                                                    )}
                                                                </div>
                                                                <div>
                                                                    <Row
                                                                        align={
                                                                            'middle'
                                                                        }
                                                                    >
                                                                        <Space>
                                                                            <Title
                                                                                level={
                                                                                    5
                                                                                }
                                                                                style={{
                                                                                    margin: 0,
                                                                                }}
                                                                            >
                                                                                {
                                                                                    data
                                                                                        ?.user
                                                                                        ?.name
                                                                                }
                                                                            </Title>
                                                                            <Text
                                                                                style={{
                                                                                    color: '#a9a4a4',
                                                                                    margin: 0,
                                                                                }}
                                                                            >
                                                                                {new Date(
                                                                                    data?.createdDate
                                                                                )?.toLocaleString(
                                                                                    'default',
                                                                                    {
                                                                                        hour: '2-digit',
                                                                                        minute: '2-digit',
                                                                                    }
                                                                                )}
                                                                            </Text>
                                                                        </Space>
                                                                    </Row>
                                                                    <div
                                                                        dangerouslySetInnerHTML={{
                                                                            __html: data?.content,
                                                                        }}
                                                                        style={{
                                                                            wordBreak:
                                                                                'break-word',
                                                                        }}
                                                                    />
                                                                    <Row>
                                                                        {data
                                                                            ?.attachments
                                                                            ?.length >
                                                                            0 && (
                                                                            <>
                                                                                <Row
                                                                                    style={{
                                                                                        columnGap: 5,
                                                                                        marginTop:
                                                                                            '20px',
                                                                                    }}
                                                                                >
                                                                                    {data
                                                                                        ?.attachments
                                                                                        ?.length >
                                                                                        0 &&
                                                                                        // !stream &&
                                                                                        data?.attachments.map(
                                                                                            (
                                                                                                data
                                                                                            ) => {
                                                                                                const str =
                                                                                                    data?.name.split(
                                                                                                        '.com/'
                                                                                                    )[1]
                                                                                                const substring =
                                                                                                    '_'
                                                                                                const lastIndex =
                                                                                                    str.lastIndexOf(
                                                                                                        substring
                                                                                                    )
                                                                                                const after =
                                                                                                    str
                                                                                                        .slice(
                                                                                                            lastIndex +
                                                                                                                1
                                                                                                        )
                                                                                                        .split(
                                                                                                            '.'
                                                                                                        )[1]
                                                                                                return (
                                                                                                    <div
                                                                                                        style={{
                                                                                                            marginRight:
                                                                                                                '10px',
                                                                                                        }}
                                                                                                        className="uploadImage"
                                                                                                    >
                                                                                                        {fileTypes.some(
                                                                                                            (
                                                                                                                r
                                                                                                            ) =>
                                                                                                                data?.name
                                                                                                                    ?.toLowerCase()
                                                                                                                    .includes(
                                                                                                                        r
                                                                                                                    )
                                                                                                        ) ? (
                                                                                                            <>
                                                                                                                <Image
                                                                                                                    src={
                                                                                                                        data?.name
                                                                                                                    }
                                                                                                                    preview={
                                                                                                                        false
                                                                                                                    }
                                                                                                                    width={
                                                                                                                        '75px'
                                                                                                                    }
                                                                                                                    height={
                                                                                                                        '75px'
                                                                                                                    }
                                                                                                                    style={{
                                                                                                                        borderRadius:
                                                                                                                            '5px',
                                                                                                                        border: '1px solid rgba(0, 0, 0, 0.2)',
                                                                                                                        cursor: 'pointer',
                                                                                                                    }}
                                                                                                                />
                                                                                                            </>
                                                                                                        ) : videoTypes.some(
                                                                                                              (
                                                                                                                  r
                                                                                                              ) =>
                                                                                                                  data?.name
                                                                                                                      ?.toLowerCase()
                                                                                                                      .includes(
                                                                                                                          r
                                                                                                                      )
                                                                                                          ) ? (
                                                                                                            <video
                                                                                                                width="75px"
                                                                                                                height="75px"
                                                                                                                style={{
                                                                                                                    borderRadius:
                                                                                                                        '5px',
                                                                                                                    border: '1px solid rgba(0, 0, 0, 0.2)',
                                                                                                                    cursor: 'pointer',
                                                                                                                }}
                                                                                                            >
                                                                                                                <source
                                                                                                                    src={
                                                                                                                        data?.name
                                                                                                                    }
                                                                                                                    type="video/mp4"
                                                                                                                />
                                                                                                            </video>
                                                                                                        ) : (
                                                                                                            <div
                                                                                                                style={{
                                                                                                                    height: '75px',
                                                                                                                    width: '75px',
                                                                                                                    maxHeight:
                                                                                                                        '75px',
                                                                                                                    borderRadius:
                                                                                                                        '5px',
                                                                                                                    border: '1px solid rgba(0, 0, 0, 0.2)',
                                                                                                                    cursor: 'pointer',
                                                                                                                }}
                                                                                                                className="recruitFileUpload"
                                                                                                            >
                                                                                                                <FileIcon
                                                                                                                    extension={
                                                                                                                        after
                                                                                                                    }
                                                                                                                    {...defaultStyles[
                                                                                                                        after
                                                                                                                    ]}
                                                                                                                />
                                                                                                            </div>
                                                                                                        )}
                                                                                                        <div
                                                                                                            className={
                                                                                                                'hoverLayerImageAttachment'
                                                                                                            }
                                                                                                        ></div>
                                                                                                        <div
                                                                                                            className={
                                                                                                                'hoverShowImageAttachment'
                                                                                                            }
                                                                                                        >
                                                                                                            <div
                                                                                                                style={{
                                                                                                                    width: '25px',
                                                                                                                    height: '25px',
                                                                                                                    cursor: 'pointer',
                                                                                                                    marginRight:
                                                                                                                        '5px',
                                                                                                                }}
                                                                                                                onClick={() =>
                                                                                                                    setState(
                                                                                                                        (
                                                                                                                            prevState
                                                                                                                        ) => ({
                                                                                                                            ...prevState,
                                                                                                                            viewMedia:
                                                                                                                                {
                                                                                                                                    open: true,
                                                                                                                                    file: {
                                                                                                                                        files: data,
                                                                                                                                    },
                                                                                                                                },
                                                                                                                        })
                                                                                                                    )
                                                                                                                }
                                                                                                            >
                                                                                                                <PreviewIcon />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                )
                                                                                            }
                                                                                        )}
                                                                                </Row>
                                                                            </>
                                                                        )}
                                                                    </Row>
                                                                </div>
                                                            </Row>
                                                            {(userProfile?.role
                                                                ?.roleName ===
                                                                'admin' ||
                                                                data?.user
                                                                    ?._id ===
                                                                    userProfile?._id) && (
                                                                <div
                                                                    style={{
                                                                        height: '22px',
                                                                        width: '22px',
                                                                        cursor: 'pointer',
                                                                    }}
                                                                    onClick={() => {
                                                                        setState(
                                                                            (
                                                                                prevState
                                                                            ) => ({
                                                                                ...prevState,
                                                                                deleteModal: true,
                                                                                comment:
                                                                                    data,
                                                                            })
                                                                        )
                                                                    }}
                                                                >
                                                                    <BinIcon
                                                                        color={
                                                                            token
                                                                                .colorPalette
                                                                                .baseColor
                                                                                .error
                                                                        }
                                                                    />
                                                                </div>
                                                            )}
                                                        </Row>
                                                    </>
                                                )
                                            )}
                                    </InfiniteScroll>
                                </>
                            ) : (
                                state?.filteredCommentsData?.length === 0 &&
                                status === 'loaded' && (
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            width: '100%',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            marginTop: '120px',
                                            height: '50vh',
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: '400px',
                                                }}
                                            >
                                                <NoNotesIcon />
                                            </div>
                                        </div>
                                        <Text
                                            className="titleSecondary"
                                            style={{
                                                marginTop: '20px',
                                            }}
                                        >
                                            No Notes Found yet.
                                        </Text>
                                    </div>
                                )
                            )}
                            <div
                                style={{
                                    marginTop: 'auto',
                                }}
                            >
                                <RecruitAttachment />
                                {state?.files?.length > 0 && (
                                    <div
                                        style={{
                                            marginTop: '20px',
                                        }}
                                    >
                                        <Title level={5}>
                                            Attached Documents
                                        </Title>
                                        {state?.files.map((data) => (
                                            <Row
                                                style={{
                                                    backgroundColor: '#f1efef',
                                                    marginTop: 10,
                                                    padding: '5px 10px',
                                                    borderRadius: 20,
                                                }}
                                            >
                                                <Text>{data?.name}</Text>
                                            </Row>
                                        ))}
                                    </div>
                                )}
                                <Row
                                    style={{
                                        width: '100%',
                                        marginTop: 20,
                                        marginBottom: 20,
                                    }}
                                >
                                    <MentionsInput
                                        value={state?.mentionData?.value}
                                        onChange={handleChangeMentions}
                                        placeholder="Write a note."
                                        className="mentions"
                                        allowSpaceInQuery={true}
                                        style={{
                                            minWidth: '100%',
                                        }}
                                    >
                                        <Mention
                                            type="user"
                                            trigger="@"
                                            displayTransform={(_, display) => {
                                                return `@${display}`
                                            }}
                                            appendSpaceOnAdd={true}
                                            data={filteredMembers}
                                            className="mentions__mention"
                                        />
                                    </MentionsInput>
                                </Row>
                                <Row
                                    justify={'end'}
                                    style={{
                                        columnGap: 10,
                                        marginTop: 20,
                                    }}
                                >
                                    <Button
                                        props={{
                                            text: 'Add',
                                            loadingButton:
                                                state?.addCommentLoader,
                                            onClick: () =>
                                                addRemoveComment('add'),
                                            disabled:
                                                state?.mentionData?.comment
                                                    ?.length === 0,
                                        }}
                                    />
                                </Row>
                            </div>
                        </div>
                    </div>
                </Spin>
            </Drawer>
            <RecruitViewMediaModal
                attachmentData={state?.viewMedia?.file}
                open={state?.viewMedia?.open}
                onClose={() => {
                    setState((prevState) => ({
                        ...prevState,
                        viewMedia: {
                            open: false,
                            file: {},
                        },
                    }))
                }}
            />
        </>
    )
}

export default NotesDrawer
