import axiosNew from '../../helpers/axios'

// TOTAL NOTES COUNT
export const countNotes = async (payload) => {
    const { search = '', filter = {} } = payload
    return new Promise(async (resolve) => {
        try {
            let data = {
                filter,
            }
            if (search?.length > 0) {
                data['search'] = search
            }
            const count = await axiosNew({
                url: 'notes/total',
                data,
            })
            resolve({ data: count?.data?.data ?? 0 })
        } catch (error) {
            resolve({ data: 0 })
        }
    })
}

// CREATE NOTE
export const addNotes = async (payload) => {
    return new Promise(async (resolve) => {
        try {
            let data = payload ?? {}
            const addNotes = await axiosNew({
                url: 'notes/add',
                data,
            })
            resolve({ data: addNotes?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// LIST NOTES
export const listNotes = async (payload) => {
    const { search = '', skip = 0, filter = {}, limit = 0 } = payload

    return new Promise(async (resolve) => {
        try {
            let data = {
                skip,
                limit,
                filter,
                populate: {
                    name: 'userId',
                    val: 'name profilePicture',
                },
            }
            if (search?.length > 0) {
                data['search'] = search
            }
            const listNotes = await axiosNew({
                url: 'notes/list/',
                data,
            })
            resolve({
                data: listNotes?.data?.data ?? {},
                isNext: listNotes?.data?.isNext,
            })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// UPDATE NOTE
export const updateNotes = async (payload) => {
    return new Promise(async (resolve) => {
        try {
            let data = payload?.editNote ?? {}
            const editNotes = await axiosNew({
                url: 'notes/edit/' + payload.id,
                data,
                method: 'put',
            })
            resolve({ data: editNotes?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// DELETE NOTE
export const deleteNotes = async (payload) => {
    return new Promise(async (resolve) => {
        try {
            const deleteNotes = await axiosNew({
                url: 'notes/delete/' + payload,
                method: 'delete',
            })
            resolve({ data: deleteNotes?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// GET NOTE BY ID
export const getNote = async (payload) => {
    return new Promise(async (resolve) => {
        try {
            const getNote = await axiosNew({
                url: 'notes/get/' + payload._id,
                method: 'get',
            })
            resolve({ data: getNote?.data?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// ADD / REMOVE COMMENT
export const addRemoveComment = async (payload) => {
    return new Promise(async (resolve) => {
        try {
            let data = payload?.comment ?? {}
            const addRemoveComment = await axiosNew({
                url: 'notes/add-remove-comments/' + payload?.id,
                method: 'put',
                data,
            })
            resolve({ data: addRemoveComment?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}
