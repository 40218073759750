import React from 'react'

function TimerIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
        >
            <path
                fill="#07C023"
                d="M11.969 2c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.47-10-10-10z"
            ></path>
            <path
                fill="#fff"
                d="M9 11.5v-1.462c0-1.815 1.368-2.558 3.043-1.65l1.35.73 1.351.731c1.675.908 1.675 2.394 0 3.302l-1.35.73-1.351.732C10.368 15.52 9 14.777 9 12.962V11.5z"
            ></path>
        </svg>
    )
}

export default TimerIcon
