import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { read, addRemoveRecruitComments } from './recruit.service'
import { RECRUIT_FILTER_STATUS } from '../../constants/general'

const initialState = {
    status: 'loaded',
    commentsList: [],
    dataCount: 0,
    isNext: false,
    pageLimit: 10,
    currentPage: 1,
    skipPage: 0,
    defaultTab: RECRUIT_FILTER_STATUS,
    fileError: '',
    recruitFiles: [],
}

export const recruitListGet = createAsyncThunk(
    'recruitSlice/read',
    async (payload = {}) => {
        const response = await read(payload)
        return response
    }
)

export const recruitAddRemoveComments = createAsyncThunk(
    'recruitSlice/addRemoveRecruitComments',
    async (payload = {}) => {
        const response = await addRemoveRecruitComments(payload)
        return response
    }
)

export const recruitSlice = createSlice({
    name: 'recruitSlice',
    initialState,
    reducers: {
        changePage: (state, action) => {
            state.currentPage = action.payload.currentPage
            state.skipPage = action.payload.skip
            state.pageLimit = action.payload.pageLimit
        },
        clearComments: (state) => {
            state.commentsList = []
        },
        changeRecruitTab: (state, action) => {
            state.defaultTab = action.payload
        },
        recruitFileError: (state, action) => {
            state.fileError = action.payload
        },
        recruitFilesUpload: (state, action) => {
            state.recruitFiles = [...state.recruitFiles, action.payload]
        },
        recruitFilesRemove: (state, action) => {
            state.recruitFiles = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(recruitListGet.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(recruitListGet.fulfilled, (state, action) => {
                state.status = 'loaded'
                state.commentsList = action.payload.data
                state.isNext = action.payload.isNext
            })
    },
})

export default recruitSlice.reducer

export const recruitState = (state) => {
    const { recruitSlice } = state
    const {
        status,
        commentsList,
        dataCount,
        isNext,
        pageLimit,
        currentPage,
        skipPage,
        defaultTab,
        fileError,
        recruitFiles,
    } = recruitSlice
    return {
        status,
        commentsList,
        dataCount,
        isNext,
        pageLimit,
        currentPage,
        skipPage,
        defaultTab,
        fileError,
        recruitFiles,
    }
}

export const {
    changePage,
    changeRecruitTab,
    recruitFileError,
    recruitFilesUpload,
    recruitFilesRemove,
    clearComments,
} = recruitSlice.actions
