import React from 'react'

function NoRolFoundIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="300"
            height="300"
            fill="none"
            viewBox="0 0 536 400"
        >
            <g clipPath="url(#clip0_5149_11577)">
                <path
                    fill="#9E616A"
                    d="M390.791 380.06h-10.649l-5.068-41.073h15.717v41.073z"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M396.411 398.511h-7.636l-1.363-7.21-3.491 7.21h-20.255a4.557 4.557 0 01-4.346-3.198 4.558 4.558 0 011.759-5.102l16.175-11.171v-7.289l17.012 1.016 2.145 25.744z"
                ></path>
                <path
                    fill="#9E616A"
                    d="M454.057 366.621l-8.546 6.355-28.575-29.936 12.611-9.378 24.51 32.959z"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M469.576 378.073l-6.128 4.557-5.397-4.972 1.501 7.869-16.253 12.086a4.555 4.555 0 01-7.057-2.277 4.554 4.554 0 01.029-2.839l6.313-18.616-4.349-5.849 14.257-9.337 17.084 19.378zM403.19 168.143s20.204 17.455 10.882 67.217l-11.381 48.758 37.116 61.575-11.834 14.397-48.252-66.648-17.485-41.014-4.906-72.997 45.86-11.288z"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M371.874 264.116l-1.216 18.821 3.719 80.201 17.5-3.048 3.344-57.102-23.347-38.872z"
                ></path>
                <path
                    fill="#FFB6B6"
                    d="M147.302 389.717h9.523l4.53-36.731h-14.054l.001 36.731z"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M144.873 386.608h2.68l10.465-4.255 5.609 4.255h.001a11.953 11.953 0 0111.951 11.951v.388l-30.705.002-.001-12.341z"
                ></path>
                <path
                    fill="#FFB6B6"
                    d="M184.205 383.717l9.289 2.097 12.51-34.831-13.709-3.096-8.09 35.83z"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M182.52 380.149l2.615.59 11.145-1.846 4.534 5.386h.001a11.953 11.953 0 019.025 14.291l-.085.378-29.952-6.762 2.717-12.037zM135.241 165.162c-4.465 13.466-3.993 28.083 1.073 43.771l2.165 88.325s-3.226 57.923 7.185 78.305h15.1l7.476-83.594.939-56.109 32.372 47.337-20.619 91 18.584 1.661 33.459-98.756-41.282-95.81-56.452-16.13z"
                ></path>
                <path
                    fill="#E6E6E6"
                    d="M309.372 126.807c11.55-10.408 28.729-15.641 43.139-9.805a71.629 71.629 0 00-32.19 37.181c-2.294 6.103-4.21 13.131-9.852 16.398-3.511 2.034-7.879 2.133-11.815 1.152-3.937-.982-7.545-2.95-11.104-4.898l-1.013-.023c4.546-14.867 11.286-29.598 22.835-40.005z"
                ></path>
                <path
                    fill="#fff"
                    d="M352.43 117.318a61.23 61.23 0 00-34.062 16.086 26.381 26.381 0 00-5.177 6.438 15.124 15.124 0 00-1.748 7.764c.063 2.517.418 5.085-.132 7.574a9.262 9.262 0 01-4.606 6.011c-2.82 1.621-6.055 2.132-9.235 2.522-3.531.432-7.206.826-10.262 2.807-.371.24-.721-.349-.352-.589 5.318-3.446 12.048-2.192 17.766-4.491 2.668-1.074 5.029-2.957 5.865-5.805.73-2.491.366-5.139.281-7.688a16.133 16.133 0 011.426-7.655 24.384 24.384 0 014.911-6.613 59.376 59.376 0 0115.095-10.806 62.297 62.297 0 0120.224-6.24c.436-.057.44.629.006.685z"
                ></path>
                <path
                    fill="#fff"
                    d="M321.222 130.39a9.185 9.185 0 011.013-11.851c.314-.31.809.164.495.474a8.508 8.508 0 00-.92 11.026c.258.358-.332.707-.588.351zM311.243 146.333a17.705 17.705 0 0012.652-2.533c.371-.239.722.35.351.588a18.414 18.414 0 01-13.17 2.61c-.435-.079-.265-.743.167-.665zM338.592 120.561a5.194 5.194 0 003.888 3.037c.436.072.266.736-.167.664a5.824 5.824 0 01-4.31-3.349.359.359 0 01.119-.47.345.345 0 01.47.118z"
                ></path>
                <path
                    fill="#E6E6E6"
                    d="M368.379 167.192a42.013 42.013 0 00-.824-.109 68.562 68.562 0 00-11.093-.564c-.287.005-.578.013-.864.026a72.185 72.185 0 00-25.9 5.961 70.141 70.141 0 00-9.406 4.998c-4.149 2.626-8.438 5.805-13.076 6.876-.483.117-.972.204-1.466.261l-19.162-16.592c-.024-.059-.053-.115-.078-.174l-.795-.628c.148-.109.304-.219.452-.327.086-.064.176-.124.261-.187.059-.042.117-.083.168-.123l.054-.037c.051-.04.102-.073.148-.109.87-.616 1.746-1.227 2.628-1.833.003-.004.003-.004.012-.005a103.089 103.089 0 0121.465-11.538c.227-.086.458-.177.695-.255a66.956 66.956 0 0110.508-2.957 59.133 59.133 0 015.926-.828 49.192 49.192 0 0115.338 1.087c10.014 2.322 19.175 7.821 24.601 16.398.138.219.273.435.408.659z"
                ></path>
                <path
                    fill="#fff"
                    d="M368.124 167.398a61.225 61.225 0 00-36.882-7.664c-2.759.25-5.462.932-8.009 2.023a15.116 15.116 0 00-6.07 5.147c-1.466 2.048-2.729 4.312-4.667 5.968a9.254 9.254 0 01-7.296 2.026c-3.228-.404-6.118-1.943-8.892-3.546-3.08-1.781-6.251-3.679-9.884-3.938-.44-.031-.366-.712.074-.681 6.321.45 10.939 5.503 16.889 7.11 2.777.749 5.796.667 8.178-1.104 2.083-1.549 3.386-3.883 4.853-5.969a16.14 16.14 0 015.748-5.254 24.384 24.384 0 017.902-2.323 59.412 59.412 0 0118.559.46 62.29 62.29 0 0119.904 7.194c.382.218-.028.767-.407.551z"
                ></path>
                <path
                    fill="#fff"
                    d="M335.336 159.046a9.183 9.183 0 017.944-8.853c.437-.058.548.618.11.676a8.506 8.506 0 00-7.373 8.251c-.01.44-.691.364-.681-.074zM317.771 165.768a17.703 17.703 0 0011.626 5.594c.44.033.366.714-.073.682a18.418 18.418 0 01-12.087-5.847c-.3-.324.235-.752.534-.429zM355.123 161.656a5.192 5.192 0 001.277 4.766c.304.32-.232.748-.534.429a5.818 5.818 0 01-1.424-5.269.352.352 0 01.377-.303.344.344 0 01.304.377z"
                ></path>
                <path
                    fill="#E6E6E6"
                    d="M31.808 389.051c-.9 3.374-3.263 6.185-6.117 8.232-.465.335-.943.645-1.427.937-.149.086-.297.179-.453.26-.167.099-.335.192-.502.279H9.891c-.242-.49-.478-.986-.714-1.476-5.75-12.085-9.79-25.125-9.1-38.407a49.159 49.159 0 013.207-15.043c3.685-9.597 10.41-17.904 19.659-22.085.23-.106.471-.211.707-.31l-.223.794a68.714 68.714 0 00-2.097 10.912 71.995 71.995 0 002.215 27.345l.018.062a65.194 65.194 0 001.837 5.627 66.808 66.808 0 001.78 4.311c2.692 5.937 6.303 12.265 4.628 18.562z"
                ></path>
                <path
                    fill="#fff"
                    d="M23.817 321.717c-.13.173-.26.341-.39.514a60.89 60.89 0 00-6.899 11.638c-.1.205-.192.41-.273.621a60.68 60.68 0 00-4.864 18.269 55.195 55.195 0 00-.285 4.417c-.138 2.768.161 5.54.887 8.214a14.747 14.747 0 003.387 5.844c.155.167.31.335.478.496.124.13.26.261.39.385 1.254 1.197 2.625 2.307 3.804 3.592.54.575 1.028 1.198 1.457 1.861a9.244 9.244 0 01.993 7.506c-.844 3.139-2.773 5.788-4.746 8.313-.986 1.265-2.004 2.543-2.884 3.896-.323.484-.62.974-.894 1.476h-.781c.26-.502.546-.992.856-1.476 2.264-3.567 5.484-6.632 7.152-10.559 1.13-2.649 1.47-5.651.044-8.251a10.987 10.987 0 00-1.508-2.096c-1.135-1.285-2.493-2.383-3.728-3.543-.31-.291-.614-.589-.906-.899a15.494 15.494 0 01-3.498-5.521 24.344 24.344 0 01-1.204-8.146c0-1.762.093-3.536.267-5.298.018-.223.043-.453.068-.682a62.508 62.508 0 0112.202-30.54c.125-.174.255-.342.385-.509.267-.348.757.136.49.478z"
                ></path>
                <path
                    fill="#fff"
                    d="M10.99 353.029a9.187 9.187 0 01-7.664-9.096.343.343 0 01.685-.015 8.505 8.505 0 007.147 8.447c.435.071.265.735-.168.664zM15.209 371.359a17.706 17.706 0 007.154-10.738c.094-.431.758-.263.665.168a18.414 18.414 0 01-7.468 11.158c-.363.252-.712-.338-.351-.588zM16.323 333.797a5.198 5.198 0 004.897-.603c.359-.256.708.334.35.588a5.82 5.82 0 01-5.415.679.352.352 0 01-.248-.416.34.34 0 01.157-.209.341.341 0 01.259-.039z"
                ></path>
                <path
                    fill="#E6E6E6"
                    d="M70.358 345.706c-.218.167-.44.335-.658.509a68.436 68.436 0 00-8.245 7.444 28.61 28.61 0 00-.589.627l-.006.006a72.175 72.175 0 00-14.058 22.42l-.018.055c-.006.019-.013.031-.019.05a71.034 71.034 0 00-3.12 10.186c-.757 3.388-1.377 7.017-2.562 10.28-.18.502-.379.999-.59 1.476h-27.58c.092-.49.191-.986.29-1.476a99.429 99.429 0 016.54-20.906c.099-.224.198-.447.31-.67a67.17 67.17 0 015.34-9.523l.001-.002.002-.002.002-.002h.002a59.045 59.045 0 013.598-4.771 49.081 49.081 0 0111.613-10.074c.025-.019.056-.031.08-.05 8.71-5.403 19.033-7.978 28.903-5.751h.007c.254.056.502.112.757.174z"
                ></path>
                <path
                    fill="#fff"
                    d="M70.327 346.035c-.211.056-.422.118-.627.18a61.167 61.167 0 00-12.512 5.13c-.199.105-.397.217-.596.329a60.966 60.966 0 00-14.758 11.532.024.024 0 00-.007.013 55.695 55.695 0 00-3.003 3.474 26.539 26.539 0 00-4.23 7.09 14.686 14.686 0 00-.813 6.706c.019.23.05.453.081.683.025.18.05.36.08.546.41 2.487 1.117 4.981.919 7.519a9.25 9.25 0 01-3.728 6.594 14.75 14.75 0 01-2.284 1.452 22.902 22.902 0 01-3.579 1.476h-2.438l.98-.279a29.92 29.92 0 003.43-1.197c.64-.267 1.261-.574 1.862-.918 2.494-1.433 4.572-3.63 5.006-6.564.379-2.568-.353-5.136-.788-7.649a15.751 15.751 0 01-.18-1.29 15.16 15.16 0 01.528-6.489 24.242 24.242 0 013.945-7.234 51.75 51.75 0 013.4-4.063c.149-.168.304-.335.465-.503A62.607 62.607 0 0156.5 350.954h.006a61.596 61.596 0 0113.09-5.422h.006c.205-.062.416-.124.62-.18.428-.111.528.565.106.683z"
                ></path>
                <path
                    fill="#fff"
                    d="M41.234 363.311a9.182 9.182 0 01-.643-11.877c.268-.35.824.05.556.401a8.505 8.505 0 00.62 11.047c.305.318-.23.746-.533.429zM33.567 380.486c4.45.16 8.799-1.363 12.177-4.266.334-.288.764.247.43.534a18.418 18.418 0 01-12.681 4.413c-.442-.017-.365-.698.074-.681zM57.07 351.166a5.203 5.203 0 004.273 2.467c.441.012.364.693-.074.681a5.833 5.833 0 01-4.733-2.718.355.355 0 01.052-.482.345.345 0 01.482.052z"
                ></path>
                <path
                    fill="#FFB8B8"
                    d="M283.122 107.403a7.198 7.198 0 01-11.022.519l-23.45 10.199 2.285-13.09 21.918-7.678a7.234 7.234 0 0110.269 10.05z"
                ></path>
                <path
                    fill="#E6E6E6"
                    d="M267.473 111.49l-54.466 24.261-.117-.041-62.078-22.122a20.445 20.445 0 01-9.17-26.524 20.428 20.428 0 0121.057-11.861 20.42 20.42 0 018.092 2.781l46.198 27.829 44.797-5.806 5.687 11.483z"
                ></path>
                <path
                    fill="#CCC"
                    d="M535.188 399.262a.743.743 0 01-.215.522.732.732 0 01-.523.216H.76a.74.74 0 01-.739-.738.737.737 0 01.739-.739h533.69a.744.744 0 01.523.216.748.748 0 01.215.523z"
                ></path>
                <path
                    fill="#E6E6E6"
                    d="M194.386 184.121l-54.905-8.782a10.488 10.488 0 01-6.463-5.914 10.484 10.484 0 01.287-8.755l2.489-4.981-.967-52.325a38.764 38.764 0 0118.82-33.905l5.354-3.202 2.706-6.871 24.951.608.073 9.013 8.935 14.248v.06l-.561 77.553-2.462 8.003 3.723 9.309-1.98 5.941z"
                ></path>
                <path
                    fill="#FFB8B8"
                    d="M176.467 50.841c12.521 0 22.671-10.15 22.671-22.67 0-12.521-10.15-22.671-22.671-22.671-12.52 0-22.67 10.15-22.67 22.67 0 12.521 10.15 22.671 22.67 22.671z"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M152.861 33.113c-.677-2.849-.366-6.857.016-9.726 1.002-7.52 5.178-14.668 11.522-18.902a8.302 8.302 0 014.135-1.643c1.501-.063 3.127.74 3.588 2.17A7.438 7.438 0 01176.319.85a13.114 13.114 0 015.975-.805 21.834 21.834 0 0118.215 13.021c.606 1.439 2.297-3.874 3.065-2.515A6.044 6.044 0 00207 13.56c1.504.42 2.114 6.614 2.706 5.17a6.885 6.885 0 01-6.583 9.6c-1.53-.057-2.993-.62-4.505-.863-5.406-.866-11.141 3.055-12.297 8.407a14.88 14.88 0 00-1.958-4.356 5.033 5.033 0 00-4.04-2.22c-1.538.058-2.891 1.063-3.889 2.235-.998 1.172-1.747 2.54-2.753 3.705-2.95 3.42-6.94 11.396-11.144 10.814-3.318-.459-8.753-9.05-9.676-12.94z"
                ></path>
                <path
                    fill="#fff"
                    d="M313.508 133.092a4.427 4.427 0 00-2.982-4.872l-61.052-20.333a4.422 4.422 0 00-1.82-.209l-15.302 1.447-8.78.833-16.203 1.531a4.439 4.439 0 00-3.96 3.716l-8.227 51.389a4.455 4.455 0 002.782 4.836l61.689 23.812c.289.113.59.194.897.241.447.073.902.076 1.35.011l39.861-5.908a4.42 4.42 0 003.737-3.722l8.01-52.772z"
                ></path>
                <path
                    fill="#4F46E5"
                    d="M199.362 167.81l61.689 23.81c.09.036.187.048.283.034l39.857-5.907a.554.554 0 00.468-.467l8.015-52.772a.561.561 0 00-.372-.609l-.411-.139-60.645-20.19a.35.35 0 00-.085-.02.522.522 0 00-.142-.008l-37.324 3.532-2.962.278a.559.559 0 00-.493.464l-8.225 51.387a.56.56 0 00.347.607z"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M210.695 115.074l37.324-3.532a.527.527 0 01.143.008c.029.003.057.01.085.02l60.645 20.19-15.997 1.694-5.987.63-17.035 1.8c-.05.003-.1.002-.15-.002a3.456 3.456 0 00-.097-.028l-58.931-20.78z"
                ></path>
                <path
                    fill="#3F3D56"
                    d="M197.962 171.434l61.689 23.81c.29.112.592.191.9.236.446.074.902.08 1.35.017l39.857-5.907a4.424 4.424 0 003.739-3.725l8.009-52.773a4.455 4.455 0 00-.259-2.305c-.041-.098-.081-.195-.129-.287a4.336 4.336 0 00-1.404-1.673 4.42 4.42 0 00-1.189-.606l-.072-.026-60.976-20.309a5.679 5.679 0 00-.762-.185 4.396 4.396 0 00-1.063-.02l-15.3 1.442-8.78.837-16.206 1.531a4.386 4.386 0 00-1.872.618.997.997 0 00-.206.136 4.437 4.437 0 00-1.878 2.956l-8.232 51.392a4.462 4.462 0 002.784 4.841zm6.54-56.054c.1-.616.373-1.191.788-1.657.125-.151.266-.287.419-.409a3.31 3.31 0 011.757-.717l27.287-2.578 13.005-1.237c.089-.011.169-.009.251-.014.197-.004.394.011.588.044.178.023.354.065.524.125l61.049 20.334.138.051a3.227 3.227 0 011.41 1.026 3.263 3.263 0 01.695 2.571l-8.015 52.779a3.32 3.32 0 01-2.797 2.792l-39.863 5.907a3.356 3.356 0 01-1.689-.193L198.36 170.4a3.352 3.352 0 01-2.084-3.627l8.226-51.393z"
                ></path>
                <path
                    fill="#3F3D56"
                    d="M205.155 112.526a.537.537 0 01.246-.295c.026-.01.052-.026.078-.036a.538.538 0 01.387-.006l1.284.452 62.729 22.128 14.681-1.551 8.215-.866 17.388-1.841 1.555-.163.302-.034a.568.568 0 01.591.406.552.552 0 01-.325.661.63.63 0 01-.153.041l-2.115.222-17.124 1.81-5.987.63-17.035 1.8c-.05.003-.1.002-.15-.002a3.456 3.456 0 00-.097-.028l-63.916-22.54-.217-.077a.412.412 0 01-.136-.075.564.564 0 01-.201-.636z"
                ></path>
                <path
                    fill="#3F3D56"
                    d="M260.719 195.642a.553.553 0 01-.461-.63l9.006-59.762a.557.557 0 01.631-.466l.005.001a.556.556 0 01.46.63l-9.005 59.762a.555.555 0 01-.631.465h-.005zM283.546 191.851l-5.989.632 7.006-59.266s7.999-1.449 8.213-.868c.132.347-9.173 59.121-9.23 59.502z"
                ></path>
                <path
                    fill="#3F3D56"
                    d="M205.495 113.236l-.161 27.028 47.846 18.231 17.182-23.08-64.867-22.179zM311.722 130.349a3.296 3.296 0 00-1.549-1.078l-61.047-20.334a3.292 3.292 0 00-.527-.128 3.14 3.14 0 00-.589-.044l.099-.619.611-.442 14.497-10.496 48.413 14.837.084 16.782.008 1.522z"
                ></path>
                <path
                    fill="#fff"
                    d="M207.932 156.624a.941.941 0 01.203.043l18.731 6.152a.934.934 0 01.594 1.174.926.926 0 01-.465.54.927.927 0 01-.71.054l-18.731-6.152a.925.925 0 01-.515-.419.928.928 0 01.893-1.392z"
                ></path>
                <path
                    fill="#F2F2F2"
                    d="M283.507 270.003a7.444 7.444 0 100-14.889 7.444 7.444 0 000 14.889zM93.677 297.299a7.444 7.444 0 100-14.888 7.444 7.444 0 000 14.888zM248.767 51.016a7.444 7.444 0 100-14.889 7.444 7.444 0 000 14.889z"
                ></path>
                <path
                    fill="#FFB8B8"
                    d="M260.98 167.573a7.204 7.204 0 01-6.086-.165 7.188 7.188 0 01-3.782-4.772l-25.442-2.57 8.394-10.302 22.873 4.021a7.232 7.232 0 018.146 5.098 7.236 7.236 0 01-4.103 8.69z"
                ></path>
                <path
                    fill="#E6E6E6"
                    d="M245.331 163.486l-59.373-5.47-.082-.094-43.333-49.652a20.45 20.45 0 014.972-27.62 20.418 20.418 0 0116.355-3.54 20.43 20.43 0 0113.511 9.873l26.689 46.865 41.915 16.841-.654 12.797z"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M394.357 68.002c-1.475-10.815-3.324-22.427-10.952-30.235a25.659 25.659 0 00-28.668-5.557 25.654 25.654 0 00-8.395 5.941c-5.553 5.924-8.005 14.241-8.229 22.358-.225 8.116 1.583 16.142 3.588 24.01a74.001 74.001 0 0030.885-6.08c2.436-1.056 4.832-2.252 7.405-2.907 2.574-.654 4.408.994 6.801 2.143l1.314-2.516c1.076 2.002 4.406 1.408 5.614-.516 1.208-1.925.944-4.39.637-6.641z"
                ></path>
                <path
                    fill="#9E616A"
                    d="M352.293 128.368l-43.349.928a6.856 6.856 0 00-12.544 5.265 6.858 6.858 0 009.48 4.396c5.749.327 50.728 6.711 53.411 2.596 2.724-4.175 17.261-18.912 17.261-18.912l-11.174-15.908-13.085 21.635zM362.857 78.702c11.248 0 20.366-9.118 20.366-20.366s-9.118-20.367-20.366-20.367-20.367 9.119-20.367 20.367c0 11.248 9.119 20.366 20.367 20.366z"
                ></path>
                <path
                    fill="#3F3D56"
                    d="M395.477 97.523c-2.4-4.777-3.566-10.678-8.683-12.226-3.455-1.046-17.43.521-20.579 2.286-5.238 2.937-.492 8.439-3.582 13.588-3.359 5.597-12.489 16.83-15.848 22.427-2.31 3.848 5.47 15.143 4.221 19.453-1.249 4.311-1.309 9.149.818 13.101 1.904 3.537-.85 6.665 1.064 10.197 1.991 3.672 4.435 17.402 2.582 21.146l-1.241 3.723c12.312.723 22.665-13.986 34.9-15.536 3.039-.384 6.195-.887 8.699-2.651 3.689-2.599 5.148-7.314 6.059-11.733a98.843 98.843 0 00-8.41-63.775z"
                ></path>
                <path
                    fill="#9E616A"
                    d="M413.935 147.96c-1.715-4.681-4.82-25.148-4.82-25.148l-19.432-.57 9.5 23.433-25.925 36.293.136.222a6.857 6.857 0 105.75 7.286.476.476 0 00.594-.102c2.885-3.62 35.912-36.733 34.197-41.414z"
                ></path>
                <path
                    fill="#3F3D56"
                    d="M415.91 135.034c-1.783-7.528-3.58-15.097-6.587-22.225-3.007-7.128-7.312-13.86-13.367-18.676-1.936-1.54-4.1-2.898-6.527-3.377-2.426-.479-5.161.057-6.865 1.85-2.727 2.868-1.907 7.472-.906 11.301l8.848 33.873 25.551-2.709-.147-.037z"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M387.812 61.16c-.75-4.926-1.534-9.894-3.296-14.533-1.762-4.64-4.602-8.985-8.677-11.613-6.451-4.16-14.803-3.358-22.035-.965-5.594 1.851-11.054 4.665-14.998 9.187-3.944 4.523-6.16 10.977-4.693 16.89l23.145-5.468-.844.597a18.632 18.632 0 019.945 12.961 19.309 19.309 0 01-4.073 16.035l15.785-5.6c3.248-1.152 6.724-2.48 8.649-5.434 2.233-3.426 1.714-7.968 1.092-12.056z"
                ></path>
            </g>
            <defs>
                <clipPath id="clip0_5149_11577">
                    <path fill="#fff" d="M0 0H535.187V400H0z"></path>
                </clipPath>
            </defs>
        </svg>
    )
}

export default NoRolFoundIcon
