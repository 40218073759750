import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// antd
import { Row, Typography, message, theme } from 'antd'

// section
import StepperSection from './tabSection/stepper.section'
import TabSectionProfile from './tabSection/tab.section'

// slices
import {
    addSummary,
    deleteDataProfile,
    profileState,
    retakeVideoChange,
    switchIntroductionModal,
    switchProfileModal,
    switchVideoPlayModal,
    updateUserGet,
    videoRecordSummary,
} from './profile.slice'
import { loginState } from '../login/login.slice'

// components
import ModalProfile from '../../components/modal'
import DeletePopUp from '../../components/deletePopUp/deletePopUp'

// modals
import SummaryModal from './summary/summaryModal'
import EducationModal from './education/educationModal'
import IntroductionVideoModal from './summary/introductionVideoModal'
import ViewVideoModal from './summary/viewVideoModal'
import CertificateModal from './certificates/certificateModal'
import ExperienceModal from './experience/experienceModal'
import ProjectsModal from './projects/projectsModal'
import VideosModal from './videos/videosModal'
import EditProfileModal from './editProfile/editProfileModal'

// assets
import {
    CertificateIcon,
    EditProfileIcon,
    EducationIcon,
    ExperienceIcon,
    ProjectsIcon,
    SummaryIcon,
    UserInactiveIcon,
    VideosIcon,
} from '../../assets/icons'
import { EditIcon } from '../../assets/icons'

// components
import TalentBasicDetail from './talentBasicDetail'

function ProfileSection({ profileOpenKey, pageKeyProfile }) {
    const { useToken } = theme
    const { token } = useToken()

    const { Text } = Typography

    const {
        profileModal,
        introductionVideoModal,
        videoPlayModal,
        selectedData,
        profileData,
        retakeVideo,
        deleteModalTitle,
    } = useSelector(profileState)
    const { userProfile } = useSelector(loginState)

    const dispatch = useDispatch()

    const [deleteModal, setDeleteModal] = useState({
        open: false,
        buttonLoading: false,
    })

    let type = ''

    // RENDER MODAL CONDITION WISE
    function checkContent() {
        let data = { ...profileModal }
        Object.keys(data).forEach((item) => {
            if (profileModal[item].open === true) {
                type = item
            }
        })
        switch (type) {
            case 'summary':
                return <SummaryModal modalType={type} />

            case 'education':
                return <EducationModal modalType={type} />

            case 'certificates':
                return <CertificateModal modalType={type} />

            case 'experience':
                return <ExperienceModal modalType={type} />

            case 'projects':
                return <ProjectsModal modalType={type} />

            case 'videos':
                return <VideosModal modalType={type} />

            case 'profile':
                return <EditProfileModal modalType={type} />

            default:
                break
        }
    }

    // CHECK DESCRIPTION
    function checkDescription() {
        let data = { ...profileModal }
        Object.keys(data).forEach((item) => {
            if (profileModal[item].open === true) {
                type = item
            }
        })
        switch (type) {
            case 'summary':
                return 'Please add your short description to the profile.'

            case 'education':
                return 'Please add your qualification details to the profile.'

            case 'certificates':
                return 'Please add all the certificates to show the achievements.'

            case 'experience':
                return 'Please add your experience details.'

            case 'projects':
                return 'Please add all the projects done by you.'

            case 'videos':
                return 'Please add your video here as per the preference to the profile'

            case 'profile':
                return 'Please add your personal details to the profile.'

            default:
                break
        }
    }

    // SHOW TITLE
    function titleShow() {
        let data = { ...profileModal }
        let name = {}
        Object.keys(data).forEach((item) => {
            if (profileModal[item].open === true) {
                name = item
            }
        })

        switch (name) {
            case 'certificates':
                return 'Certificate'

            case 'projects':
                return 'Project'

            case 'videos':
                return 'Video'

            default:
                return name
        }
    }

    // CALL USER GET API
    useEffect(() => {
        if (profileOpenKey !== 'talent' && profileOpenKey !== 'job') {
            dispatch(updateUserGet({ _id: userProfile?._id }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // CHECK TITLE
    function checkTitle() {
        let data = { ...profileModal }
        let name = {}
        Object.keys(data).forEach((item) => {
            if (profileModal[item].open === true) {
                name = item
            }
        })
        return name
    }

    // CHECK TITLE
    function checkIcon() {
        let data = { ...profileModal }
        Object.keys(data).forEach((item) => {
            if (profileModal[item].open === true) {
                type = item
            }
        })
        switch (type) {
            case 'summary':
                return (
                    <SummaryIcon color={token.colorPalette.baseColor.white} />
                )

            case 'education':
                return (
                    <EducationIcon color={token.colorPalette.baseColor.white} />
                )

            case 'certificates':
                return (
                    <CertificateIcon
                        color={token.colorPalette.baseColor.white}
                    />
                )

            case 'experience':
                return (
                    <ExperienceIcon
                        color={token.colorPalette.baseColor.white}
                    />
                )

            case 'projects':
                return (
                    <ProjectsIcon color={token.colorPalette.baseColor.white} />
                )

            case 'videos':
                return <VideosIcon color={token.colorPalette.baseColor.white} />

            case 'profile':
                return (
                    <EditProfileIcon
                        color={token.colorPalette.baseColor.white}
                    />
                )

            default:
                break
        }
    }

    //  DELETE PROFILE DATA
    async function deleteProfileData() {
        let modalState = { ...profileModal }
        setDeleteModal((prevState) => ({ ...prevState, buttonLoading: true }))

        const result = await dispatch(
            deleteDataProfile({
                deleteData: {
                    step: videoPlayModal?.delete ? 'videos' : checkTitle(),
                    stepField: '_id',
                    stepFieldValue: videoPlayModal?.delete
                        ? videoPlayModal?.videoData?.deleteId
                        : selectedData?._id,
                },
                _id: userProfile?._id,
            })
        )

        const data = result?.payload?.data

        if (data) {
            const { success, message: errorMessage } = data
            if (success) {
                setDeleteModal(() => ({
                    open: false,
                    buttonLoading: false,
                }))

                if (videoPlayModal?.delete) {
                    const videosCheck =
                        introductionVideoModal?.videos?.length > 0 &&
                        introductionVideoModal?.videos
                            .filter(
                                (data) =>
                                    data?._id !==
                                    videoPlayModal?.videoData?.deleteId
                            )
                            .map((data) => data)

                    const deleteVideo =
                        introductionVideoModal?.videos?.length > 0 &&
                        introductionVideoModal?.videos
                            .filter(
                                (data) =>
                                    data?._id ===
                                    videoPlayModal?.videoData?.deleteId
                            )
                            .map((data) => data)

                    if (
                        deleteVideo[0]?.type === 'Introduction Video' &&
                        profileData?.data?.summary?.isCompleted
                    ) {
                        dispatch(
                            addSummary({
                                _id: userProfile?._id,
                                summary: {
                                    step: 'summary',
                                    data: {
                                        summary: {
                                            summaryText:
                                                profileData?.data?.summary
                                                    ?.summaryText,
                                            isCompleted: false,
                                        },
                                    },
                                },
                            })
                        )
                    }

                    if (videoPlayModal?.delete === 'summary') {
                        dispatch(
                            switchProfileModal({
                                ...profileModal,
                                open: true,
                                name: videoPlayModal?.delete
                                    ? 'videos'
                                    : 'summary',
                            })
                        )
                    }

                    dispatch(
                        switchIntroductionModal({
                            ...introductionVideoModal,
                            videos: videosCheck,
                        })
                    )
                    dispatch(
                        switchVideoPlayModal({
                            delete: false,
                            videoData: {
                                deleteId: '',
                                link: '',
                            },
                            open: false,
                            title: '',
                        })
                    )
                } else {
                    dispatch(
                        switchProfileModal({ name: checkTitle(), open: false })
                    )
                }
                dispatch(updateUserGet({ _id: userProfile?._id }))
                message.success(errorMessage)
            } else {
                dispatch(switchProfileModal(modalState))
                if (errorMessage) {
                    message.error(errorMessage)
                } else {
                    message.error('Something went wrong, try again later.')
                }
            }
        }
    }

    return (
        <>
            <div
                className={profileOpenKey !== 'job' ? 'container' : ''}
                style={{
                    zIndex: 10,
                    minHeight: '100vh',
                }}
            >
                <>
                    {Object.keys(profileData)?.length > 0 ? (
                        <>
                            {profileOpenKey !== 'talent' &&
                                profileOpenKey !== 'job' && <StepperSection />}
                            {profileOpenKey !== 'job' && (
                                <Row
                                    style={{
                                        margin: '37px 0px',
                                    }}
                                    align={'middle'}
                                    justify={'space-between'}
                                >
                                    <TalentBasicDetail
                                        profileData={profileData}
                                        pageKeyProfile={pageKeyProfile}
                                    />
                                    {profileOpenKey !== 'talent' &&
                                        profileOpenKey !== 'job' && (
                                            <div
                                                style={{
                                                    cursor: 'pointer',
                                                    height: '26px',
                                                    width: '26px',
                                                }}
                                                onClick={() => {
                                                    dispatch(
                                                        switchProfileModal({
                                                            name: 'profile',
                                                            open: true,
                                                            mode: 'edit',
                                                        })
                                                    )
                                                }}
                                            >
                                                <EditIcon />
                                            </div>
                                        )}
                                </Row>
                            )}

                            <TabSectionProfile />
                        </>
                    ) : (
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                flexDirection: 'column',
                                height: '100vh',
                            }}
                        >
                            <UserInactiveIcon />
                            <Text
                                className="titleLight"
                                style={{
                                    fontSize: token.fontSizeHeading4,
                                }}
                            >
                                No talent details added.
                            </Text>
                        </div>
                    )}
                </>
                <DeletePopUp
                    previewDelete={deleteModal?.open}
                    onDelete={() => {
                        deleteProfileData()
                    }}
                    profileModalState={videoPlayModal}
                    loadingButton={deleteModal?.buttonLoading}
                    onCancel={() => {
                        setDeleteModal({ open: false, buttonLoading: false })
                    }}
                    pageKey={deleteModalTitle}
                    modalTitle={`Delete ${
                        videoPlayModal?.delete ? 'Video' : deleteModalTitle
                    }`}
                    // pageKey={titleShow()}
                    // modalTitle={`Delete ${
                    //     videoPlayModal?.delete ? 'Video' : titleShow()
                    // }`}
                />
                <ModalProfile
                    visibility={Object.values(profileModal).some(
                        (item) => item?.open
                    )}
                    profileModalState={profileModal}
                    selectedDataProfile={selectedData}
                    modalKey="addVideo"
                    footer={true}
                    content={checkContent()}
                    description={checkDescription()}
                    icon={checkIcon()}
                    modalTitle={checkTitle()}
                    showTitle={titleShow()}
                    handleDelete={() => {
                        setDeleteModal({ ...deleteModal, open: true })
                    }}
                    handleCancel={() => {
                        let data = { ...profileModal }
                        Object.keys(data).forEach((item) => {
                            data[item] = {
                                ...data[item],
                                open: false,
                            }
                        })
                        dispatch(switchProfileModal(data))
                    }}
                />

                <ModalProfile
                    profileModalState={videoPlayModal}
                    visibility={videoPlayModal?.open}
                    handleDelete={() => {
                        setDeleteModal({ ...deleteModal, open: true })
                    }}
                    handleCancel={() => {
                        dispatch(
                            switchVideoPlayModal({
                                delete: false,
                                videoData: {
                                    deleteId: '',
                                    link: '',
                                },
                                open: false,
                                title: '',
                            })
                        )
                    }}
                    footer={true}
                    modalKey="viewVideo"
                    modalTitle={videoPlayModal?.title}
                    showTitle={videoPlayModal?.title}
                    icon={
                        <VideosIcon
                            color={token.colorPalette.baseColor.white}
                        />
                    }
                    content={<ViewVideoModal content={videoPlayModal} />}
                />
                <ModalProfile
                    visibility={introductionVideoModal?.open}
                    handleCancel={() => {
                        dispatch(
                            switchIntroductionModal({
                                ...introductionVideoModal,
                                open: false,
                            })
                        )
                        dispatch(videoRecordSummary(false))
                        dispatch(
                            retakeVideoChange({
                                ...retakeVideo,
                                visibility: false,
                            })
                        )
                    }}
                    profileModalState={profileModal}
                    wholeObjectProfile={retakeVideo}
                    icon={
                        <VideosIcon
                            color={token.colorPalette.baseColor.white}
                        />
                    }
                    footer={true}
                    modalKey="saveVideo"
                    modalTitle="Introduction Video"
                    showTitle={introductionVideoModal?.type}
                    buttonText="Save Video"
                    content={
                        <IntroductionVideoModal
                            pageKey={checkTitle()}
                            showTitle={introductionVideoModal?.type}
                        />
                    }
                />
            </div>
        </>
    )
}

export default ProfileSection
