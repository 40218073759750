import React from 'react'

function InstituteIcon({ color }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 22 20"
        >
            <path
                fill={color ? color : '#4F46E5'}
                d="M22 18h-1V9a1 1 0 00-1-1h-3V6.414A2 2 0 0016.414 5l-4-4a2 2 0 00-2.828 0l-4 4A2 2 0 005 6.414V8H2a1 1 0 00-1 1v9H0v2h22v-2zM5 18H3v-8h2v8zm12-8h2v8h-2v-8zm-7 1h2v7h-2v-7z"
            ></path>
        </svg>
    )
}

export default InstituteIcon
