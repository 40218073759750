import React from 'react'

function RoleStepLoadingIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="none"
            viewBox="0 0 20 20"
            className="rotateRoleLoader"
        >
            <path
                fill="#000"
                d="M10-.004a1 1 0 011 1v3a1 1 0 11-2 0v-3a1 1 0 011-1zm0 15a1 1 0 011 1v3a1 1 0 11-2 0v-3a1 1 0 011-1zm10-5a1 1 0 01-1 1h-3a1 1 0 110-2h3a1 1 0 011 1zm-15 0a1 1 0 01-1 1H1a1 1 0 110-2h3a1 1 0 011 1zm12.071 7.071a1 1 0 01-1.414 0l-2.121-2.121a1 1 0 011.414-1.414l2.121 2.121a1 1 0 010 1.414zM6.464 6.461a1 1 0 01-1.414 0L2.93 4.339a1 1 0 011.414-1.414l2.121 2.121a1 1 0 010 1.415zM2.93 17.067a1 1 0 010-1.414l2.121-2.121a1 1 0 011.414 1.414l-2.12 2.121a1 1 0 01-1.415 0zM13.536 6.461a1 1 0 010-1.415l2.12-2.121a1 1 0 111.415 1.414L14.95 6.461a1 1 0 01-1.414 0z"
            ></path>
        </svg>
    )
}

export default RoleStepLoadingIcon
