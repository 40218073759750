import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import S3FileUpload from 'react-s3'
import { debounce } from 'lodash'

// antd
import {
    Card,
    Col,
    DatePicker,
    Form,
    Image,
    Input,
    Row,
    Typography,
    theme,
    Upload,
    message,
    Select,
    Tag,
    Spin,
    InputNumber,
} from 'antd'

// slices
import { loginState } from '../../../../login/login.slice'
import {
    changeRoleStep,
    roleInquiryGet,
    roleInquiryUpdate,
    roleStepData,
    rolesInquiryState,
    switchRoleModal,
} from '../rolesInquiry.slice'
import {
    changeModel,
    masterDataDropDownList,
    profileState,
} from '../../../../profile/profile.slice'
import { inquiryState } from '../../../inquiries.slice'

// components
import Button from '../../../../../components/button/button'
import ModalProfile from '../../../../../components/modal'

// profile modal
import ViewMediaModal from '../../../../profile/experience/viewMediaModal'

// constants
import { employmentOptions } from '../../../../../constants/users.index'
import { MASTER_DATA_FIELD } from '../../../../../constants/masterData'

// assets
import {
    BinIcon,
    CrossIcon,
    PreviewIcon,
    RoleExperienceIcon,
} from '../../../../../assets/icons'

import dayjs from 'dayjs'

const AddRoleForm = ({ setStep }) => {
    const { TextArea } = Input

    const { Text } = Typography

    const { Dragger } = Upload

    const { Option } = Select

    const { useToken } = theme

    const { token } = useToken()

    const [form] = Form.useForm()

    const dispatch = useDispatch()

    const { userProfile } = useSelector(loginState)
    const { masterDataDropDown, masterDataDropDownStatus } =
        useSelector(profileState)
    const { selectedRole, stepRoleData } = useSelector(rolesInquiryState)
    const { selectedInquiry } = useSelector(inquiryState)

    const [fileURL, setFileURL] = useState([])
    const [stream, setStream] = useState(false)
    const [errorFile, setErrorFile] = useState('')
    const [skillsData, setSkillsData] = useState([])
    const [loadingButton, setLoadingButton] = useState(false)
    const [timezones, setTimezones] = useState([])
    const [viewMediaModal, setViewMediaModal] = useState({
        open: false,
        fileName: '',
    })

    useEffect(() => {
        if (selectedRole) {
            form.setFieldsValue({
                ...selectedRole,
                start: selectedRole?.start ? dayjs(selectedRole?.start) : '',
            })
            setSkillsData(
                selectedRole?.skills?.length > 0
                    ? selectedRole?.skills.map((data) => {
                        return {
                            label: data?.name,
                            value: data?._id,
                        }
                    })
                    : []
            )
        }
        if (selectedRole?.files?.length > 0) {
            setFileURL(selectedRole?.files)
        } else {
            setFileURL([])
        }
    }, [form, selectedRole])

    useEffect(() => {
        if (stepRoleData !== undefined) {
            form.setFieldsValue({
                ...stepRoleData,
                start: stepRoleData?.start ? dayjs(stepRoleData?.start) : '',
            })
            setSkillsData(
                stepRoleData?.skillsDisplay?.length > 0
                    ? stepRoleData?.skillsDisplay.map((data) => {
                        return {
                            label: data?.label,
                            value: data?.value,
                        }
                    })
                    : []
            )
            if (stepRoleData?.files?.length > 0) {
                setFileURL(stepRoleData?.files)
            } else {
                setFileURL([])
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stepRoleData])

    // useEffect(() => {
    //     var aryIanaTimeZones = Intl.supportedValuesOf('timeZone')

    //     let newData = []
    //     aryIanaTimeZones.map((timeZone) => {
    //         const timeZoneOffset = new Date().toLocaleTimeString('en', {
    //             timeZoneName: 'longOffset',
    //             timeZone,
    //         })
    //         const gmtOffset = timeZoneOffset.split(' ')[2]

    //         newData.push({
    //             label: `${timeZone}`,
    //             value: `${timeZone}`,
    //             offset: gmtOffset,
    //         })
    //     })
    //     setTimezones(newData)
    // }, [])

    useEffect(() => {
        const aryIanaTimeZones = Intl.supportedValuesOf('timeZone')

        const newData = aryIanaTimeZones.map((timeZone) => {
            const date = new Date()
            const formatter = new Intl.DateTimeFormat('en-US', {
                timeZone,
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                timeZoneName: 'shortOffset',
                hour12: false,
            })

            const parts = formatter.formatToParts(date)
            const timeZoneOffset = parts.find(
                (part) => part.type === 'timeZoneName'
            ).value

            return {
                label: `${timeZone}`,
                value: `UTC${timeZoneOffset.replace('GMT', '')} ${timeZone} `,
                offset: `UTC${timeZoneOffset.replace('GMT', '')}`,
            }
        })

        setTimezones(newData)
    }, [])

    // FORMAT DATEPICKER
    const formatDatePicker = (value) => {
        return `${dayjs(value).format('DD MMM YY')}`
    }

    // ON FINISH ROLE DETAILS
    const onFinishRoleDetails = async (value) => {
        setLoadingButton(true)

        const payload = {
            ...value,
            skills:
                skillsData?.length > 0
                    ? skillsData.map((data) => data?.value)
                    : [],
            files: fileURL?.length > 0 ? fileURL : [],
            start: new Date(value?.start).getTime(),
        }

        if (selectedRole) {
            const result = await dispatch(
                roleInquiryUpdate({
                    updatedData: {
                        ...payload,
                    },
                    roleId: selectedRole?._id,
                    workspaceId: selectedInquiry?.client?.workspaceId,
                })
            )
            const data = result?.payload?.data
            if (data) {
                const { success, message: checkMessage } = data
                if (success) {
                    dispatch(
                        roleInquiryGet({
                            inquiryId: selectedInquiry?._id,
                            roleId: selectedRole?._id,
                            workspaceId: selectedInquiry?.client?.workspaceId,
                        })
                    )
                    dispatch(switchRoleModal({ open: false }))
                    setLoadingButton(false)
                    message.success(checkMessage)
                } else {
                    setLoadingButton(false)
                    dispatch(switchRoleModal({ open: false }))
                    if (checkMessage) {
                        message.error(checkMessage)
                    } else {
                        message.error('Something went wrong, try again later.')
                    }
                }
            }
        } else {
            dispatch(
                roleStepData({
                    ...payload,
                    skillsDisplay: skillsData?.length > 0 ? skillsData : [],
                })
            )
            setStep()
            dispatch(changeRoleStep(2))
        }
    }

    // ON FINISH FAILED
    const onFinishFailed = () => { }

    // ON CHANGE FILE UPLOAD PROPS
    const props = {
        name: 'files',
        multiple: true,
        onChange(info) {
            let fileUpload = []

            setErrorFile('')
            const isLt50M = info?.file.size / 1024 / 1024 < 50
            if (!isLt50M) {
                message.error('Maximum File size would be 50 MB')
                return
            }
            if (
                fileURL?.length > 5 ||
                (fileURL?.length < 5 &&
                    info?.fileList?.length + fileURL?.length > 5)
            ) {
                setErrorFile('Maximum limit of file upload is 5')
                return
            }

            const fileUploadCheck =
                info?.fileList?.length > 0 &&
                info?.fileList
                    .filter((data) => data?.size / 1024 / 1024 < 50)
                    .map((data) => data)

            setStream(true)
            fileUploadCheck?.length > 0 &&
                fileUploadCheck.forEach((data) => {
                    const nameChange = data?.name
                        .split('.')[0]
                        .replaceAll('\\s+', '')
                        .concat(`_${dayjs(new Date()).unix()}`)
                        .concat(`.${data?.name.split('.')[1]}`)

                    const displayName = data?.name?.slice(
                        0,
                        data?.name?.lastIndexOf('.')
                    )

                    const newFileData = new File(
                        [data?.originFileObj],
                        nameChange,
                        { type: data?.type }
                    )
                    window.Buffer = window.Buffer || require('buffer').Buffer

                    S3FileUpload.uploadFile(newFileData, userProfile?.s3Config)
                        .then(async (fileData) => {
                            if (
                                fileUploadCheck?.length ===
                                fileUpload?.length + 1
                            ) {
                                setStream(false)
                            }
                            fileUpload = [
                                ...fileUpload,
                                {
                                    name: fileData?.location,
                                    displayName: displayName,
                                    fileSize: fileData?.size,
                                    createdBy: userProfile?.name,
                                },
                            ]
                            setFileURL([...fileURL, ...fileUpload])
                        })
                        .catch(() =>
                            message.error('Upload Failed!. Please Upload again')
                        )
                })
        },
        beforeUpload() {
            return false
        },
    }

    // SEARCH MASTER DATA
    const searchFn = debounce((value, model) => {
        if (value?.length > 0) {
            dispatch(changeModel(model))
            dispatch(
                masterDataDropDownList({
                    search: value,
                    id: model,
                })
            )
        }
    }, 800)

    return (
        <>
            <Form
                labelCol={{
                    style: {
                        padding: '0 0 3px',
                    },
                }}
                layout="vertical"
                name="addProjectForm"
                onFinish={onFinishRoleDetails}
                onFinishFailed={onFinishFailed}
                requiredMark={false}
                form={form}
                style={{
                    overflowX: 'hidden',
                }}
            >
                <Row
                    gutter={30}
                    style={{
                        rowGap: '15px',
                    }}
                >
                    <Col span={12}>
                        <Form.Item
                            label="Role Title*"
                            name="title"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Enter Role Title',
                                },
                                {
                                    max: 25,
                                    message:
                                        'Role Title is maximum 25 characters long',
                                },
                            ]}
                            colon={false}
                        >
                            <Input
                                placeholder={'Enter Role Title'}
                                name={'title'}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Start Date*"
                            name="start"
                            colon={false}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Enter Start Date',
                                },
                            ]}
                        >
                            <DatePicker
                                allowClear={false}
                                name="start"
                                format={formatDatePicker}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            label="Employment*"
                            name="employmentType"
                            colon={false}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Enter Employment',
                                },
                            ]}
                        >
                            <Select options={employmentOptions} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Working Hours*"
                            name="workingHours"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Enter Working Hours',
                                },
                            ]}
                            colon={false}
                        >
                            <InputNumber
                                style={{
                                    width: '100%',
                                }}
                                placeholder={'Enter Working Hours'}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Your Timezone*"
                            name="timeZone"
                            colon={false}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Enter Timezone',
                                },
                            ]}
                        >
                            <Select showSearch placeholder="Enter Timezone">
                                {timezones?.length > 0 &&
                                    timezones.map((data) => (
                                        <Option key={data?.value}>
                                            <Row
                                                align={'middle'}
                                                style={{
                                                    columnGap: '8px',
                                                }}
                                            >
                                                <Text>({data?.offset})</Text>
                                                <Text>{data?.label}</Text>
                                            </Row>
                                        </Option>
                                    ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="No. Of Talent*"
                            name="noOfTalent"
                            colon={false}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Enter No. of Talent',
                                },
                            ]}
                        >
                            <InputNumber
                                placeholder={'Enter No. of Talent'}
                                name={'noOfTalent'}
                                style={{
                                    width: '100%',
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            label="Role Description*"
                            name="description"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Enter Role description',
                                },
                                {
                                    max: 500,
                                    message:
                                        'Role description is maximum 500 characters long',
                                },
                            ]}
                            colon={false}
                        >
                            <TextArea
                                rows={5}
                                placeholder={'Enter Role Description...'}
                                name={'description'}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        {!selectedRole && (
                            <Form.Item
                                name="skills"
                                colon={false}
                                rules={[
                                    {
                                        validator: async () => {
                                            if (skillsData?.length === 0) {
                                                return Promise.reject(
                                                    new Error(
                                                        'Please enter at-least one skill'
                                                    )
                                                )
                                            }
                                        },
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Search Skills"
                                    showSearch
                                    onSearch={(value) => {
                                        searchFn(
                                            value,
                                            MASTER_DATA_FIELD.skills
                                        )
                                    }}
                                    tagRender={() => null}
                                    onSelect={(e, options) => {
                                        const trimSkills =
                                            options?.key.trimStart()
                                        if (trimSkills?.length > 0) {
                                            setSkillsData([
                                                ...skillsData,
                                                { label: trimSkills, value: e },
                                            ])
                                        }
                                    }}
                                    onDeselect={(e) => {
                                        const removeData = [...skillsData]
                                        removeData.splice(
                                            removeData.findIndex(
                                                (item) => item.label === e
                                            ),
                                            1
                                        )
                                        setSkillsData(removeData)
                                    }}
                                    style={{
                                        width: '100%',
                                    }}
                                    mode="multiple"
                                    rootClassName={[
                                        'p-0-multiSelect',
                                        skillsData?.length > 0
                                            ? 'p-0-multiselect-setMargin'
                                            : '',
                                    ]}
                                    filterOption={false}
                                    allowClear={false}
                                >
                                    {masterDataDropDown?.skills?.length > 0 &&
                                        masterDataDropDownStatus === 'loaded'
                                        ? masterDataDropDown?.skills.map(
                                            (option) => {
                                                return (
                                                    <Option
                                                        key={option?.label}
                                                        value={option?.value}
                                                    >
                                                        <Text>
                                                            {option?.label}
                                                        </Text>
                                                    </Option>
                                                )
                                            }
                                        )
                                        : masterDataDropDownStatus ===
                                        'loading' && <Spin></Spin>}
                                </Select>
                            </Form.Item>
                        )}
                        <div
                            style={{
                                marginTop: '30px',
                            }}
                        >
                            <Text>My Skills</Text>
                            <Card
                                style={{
                                    border: `2px dashed ${token.colorPalette.textColor.quaternary}`,
                                    margin: '4px 0px 14px 0px',
                                    padding: '0px',
                                    minHeight: '110px',
                                }}
                                styles={{
                                    body: {
                                        padding: '15px',
                                    },
                                }}
                            >
                                <Row style={{ rowGap: '10px' }}>
                                    {skillsData?.length > 0 &&
                                        skillsData.map((skill, index) => (
                                            <Tag
                                                style={{
                                                    padding: '10px 20px',
                                                    whiteSpace: 'normal',
                                                    overflow: 'hidden',
                                                }}
                                                color={
                                                    token.colorPalette.baseColor
                                                        .tertiary
                                                }
                                            >
                                                <Row
                                                    align={'middle'}
                                                    style={{
                                                        columnGap: '30px',
                                                    }}
                                                >
                                                    <Text
                                                        style={{
                                                            color: token
                                                                .colorPalette
                                                                .baseColor
                                                                .white,
                                                        }}
                                                    >
                                                        {skill?.label?.toUpperCase()}
                                                    </Text>
                                                    {!selectedRole && (
                                                        <div
                                                            style={{
                                                                width: '13px',
                                                            }}
                                                            onClick={() => {
                                                                const removeData =
                                                                    [
                                                                        ...skillsData,
                                                                    ]
                                                                removeData.splice(
                                                                    index,
                                                                    1
                                                                )
                                                                setSkillsData(
                                                                    removeData
                                                                )
                                                            }}
                                                        >
                                                            <CrossIcon />
                                                        </div>
                                                    )}
                                                </Row>
                                            </Tag>
                                        ))}
                                </Row>
                            </Card>
                        </div>
                    </Col>
                    <Col span={24}>
                        {fileURL?.length > 0 && !stream ? (
                            <>
                                <Card
                                    style={{
                                        border: `2px dashed ${token.colorPalette.textColor.quaternary}`,
                                        margin: '4px 0px 14px 0px',
                                        padding: '0px',
                                        minHeight: '110px',
                                    }}
                                    styles={{
                                        body: {
                                            padding: '15px',
                                        },
                                    }}
                                >
                                    <Row
                                        style={{
                                            columnGap: '13px',
                                            rowGap: '13px',
                                        }}
                                    >
                                        {fileURL.map((link) => (
                                            <Col span={6}>
                                                <div className="uploadImage">
                                                    <Image
                                                        src={link?.name}
                                                        height="162px"
                                                        width={'100%'}
                                                        style={{
                                                            objectFit: 'cover',
                                                            borderRadius: '8px',
                                                            cursor: 'pointer',
                                                        }}
                                                        preview={false}
                                                    />

                                                    <div
                                                        className={
                                                            'hoverLayerImageProjects'
                                                        }
                                                    ></div>
                                                    <div
                                                        className={
                                                            'hoverShowImageProjects'
                                                        }
                                                    >
                                                        <div
                                                            style={{
                                                                width: '25px',
                                                                height: '25px',
                                                                cursor: 'pointer',
                                                                marginRight:
                                                                    '5px',
                                                            }}
                                                            onClick={() => {
                                                                setViewMediaModal(
                                                                    {
                                                                        open: true,
                                                                        fileName:
                                                                            link?.name,
                                                                    }
                                                                )
                                                            }}
                                                        >
                                                            <PreviewIcon />
                                                        </div>
                                                        <div
                                                            style={{
                                                                width: '25px',
                                                                height: '25px',
                                                                cursor: 'pointer',
                                                            }}
                                                            onClick={() => {
                                                                const removeData =
                                                                    [...fileURL]
                                                                removeData.splice(
                                                                    removeData.findIndex(
                                                                        (
                                                                            item
                                                                        ) =>
                                                                            item ===
                                                                            link
                                                                    ),
                                                                    1
                                                                )
                                                                setFileURL(
                                                                    removeData
                                                                )
                                                            }}
                                                        >
                                                            <BinIcon
                                                                color={
                                                                    token
                                                                        .colorPalette
                                                                        .baseColor
                                                                        .error
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        ))}
                                    </Row>
                                </Card>
                                <div
                                    style={{
                                        width: '100%',
                                    }}
                                >
                                    <Upload {...props}>
                                        <Button
                                            props={{
                                                text: 'Upload Files',
                                                onClick: () => { },
                                            }}
                                        />
                                    </Upload>
                                </div>
                            </>
                        ) : (
                            <Form.Item
                                name={'files'}
                                // rules={[
                                //     {
                                //         validator: async () => {
                                //             if (
                                //                 fileURL?.length === 0 &&
                                //                 errorFile?.length === 0
                                //             ) {
                                //                 return Promise.reject(
                                //                     new Error(
                                //                         'Please Upload at-least one attachment'
                                //                     )
                                //                 )
                                //             }
                                //         },
                                //     },
                                // ]}
                                help={fileURL?.length === 0 ? null : ''}
                                className="fileDragger"
                            >
                                <Dragger
                                    {...props}
                                    fileList={[]}
                                    accept=".png,.jpg,.jpeg,.svg,.pdf"
                                    previewFile={false}
                                    style={{
                                        border: `2px dashed ${token.colorPalette.textColor.quaternary}`,
                                        padding: '20px',
                                        minHeight: '185px',
                                    }}
                                >
                                    {stream ? (
                                        <>
                                            <Text
                                                className="titleLight titleMiddle"
                                                style={{}}
                                            >
                                                Uploading....
                                            </Text>
                                        </>
                                    ) : (
                                        <>
                                            <Text
                                                className="titleLight titleMiddle"
                                                style={{}}
                                            >
                                                Click or Drag
                                            </Text>
                                            <br />
                                            <Text
                                                className="titleLight"
                                                style={{
                                                    fontSize:
                                                        token.fontSizeHeading5,
                                                }}
                                            >
                                                File to Upload (Max files would
                                                be 5 and size 50 MB)
                                            </Text>
                                        </>
                                    )}
                                </Dragger>
                                {errorFile?.length > 0 && (
                                    <span style={{ color: 'red', margin: 2 }}>
                                        {errorFile}
                                    </span>
                                )}
                            </Form.Item>
                        )}
                    </Col>
                    {selectedRole && (
                        <>
                            <Col span={12}>
                                <Form.Item
                                    label="From"
                                    name="startBudget"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Please Enter Start Budget',
                                        },
                                    ]}
                                    colon={false}
                                >
                                    <Input
                                        placeholder={'Enter Start Budget'}
                                        name={'startBudget'}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="To"
                                    name="endBudget"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please Enter End Budget',
                                        },
                                    ]}
                                    colon={false}
                                >
                                    <Input
                                        placeholder={'Enter end Budget'}
                                        name={'endBudget'}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <div
                                    style={{
                                        marginTop: '24px',
                                    }}
                                >
                                    <Text
                                        style={{
                                            fontSize: token.fontSizeHeading4,
                                        }}
                                    >
                                        Salary ranges between $25 - $45 /hour
                                        (USD) for 'ReactJS' developers based on
                                        their years & quality of experience.
                                    </Text>
                                </div>
                            </Col>
                        </>
                    )}
                </Row>
                <Row
                    gutter={12}
                    style={{
                        float: 'right',
                        marginTop: '35px',
                    }}
                >
                    <Col span={24}>
                        <Button
                            props={{
                                htmlType: 'submit',
                                loadingButton: loadingButton,
                                text: selectedRole ? 'Update' : 'Continue',
                                style: {
                                    padding: '8px 27px',
                                },
                            }}
                        />
                    </Col>
                </Row>
            </Form>
            <ModalProfile
                visibility={viewMediaModal?.open}
                handleCancel={() => {
                    setViewMediaModal({ open: false, fileName: '' })
                }}
                footer={false}
                icon={<RoleExperienceIcon />}
                modalKey="viewVideo"
                modalTitle="Media"
                showTitle="Media"
                content={<ViewMediaModal fileName={viewMediaModal?.fileName} />}
            />
        </>
    )
}

export default AddRoleForm
