import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import {
    // getSprintsList,
    getSprintsCount,
    create,
    read,
    update,
    getEpicsList,
    createEpic,
    deleteEpic,
    updateEpic,
    deleteSprint,
    getSprintById,
    listTaskSprintEpicWise,
} from './sprints.service'

const initialState = {
    search: '',
    status: 'loaded',
    getSprintStatus: 'loaded',
    sprints: [],
    epics: [],
    listTasks: [],
    isNextTask: false,
    pageLimitTask: 30,
    currentPageTask: 1,
    skipPageTask: 0,
    isNextEpic: false,
    pageLimitEpic: 30,
    currentPageEpic: 1,
    skipPageEpic: 0,
    sprintsCount: 0,
    formModal: false,
    modalVariations: {
        open: false,
        formName: 'sprintAddForm',
        saveButtonLoading: false,
        create: true,
    },
    editInput: { open: false, id: '' },
    editTaskInput: { open: false, id: '' },
    viewModal: false,
    pointerEvent: true,
    selectedSprint: undefined,
    selectedSprintData: undefined,
    selectedSprintTab: undefined,
}

// GET EPICS LIST
export const epicsList = createAsyncThunk(
    'sprintsSlice/getEpicsList',
    async (payload) => {
        const response = await getEpicsList(payload)
        return response
    }
)

// CREATE EPIC
export const createEpicList = createAsyncThunk(
    'sprintsSlice/createEpic',
    async (payload) => {
        const response = await createEpic(payload)
        return response
    }
)

// DELETE EPIC
export const deleteEpicList = createAsyncThunk(
    'sprintsSlice/deleteEpic',
    async (payload) => {
        const response = await deleteEpic(payload)
        return response
    }
)

// UPDATE EPIC
export const updateEpicList = createAsyncThunk(
    'sprintsSlice/updateEpic',
    async (payload) => {
        const response = await updateEpic(payload)
        return response
    }
)

// LIST SPRINTS
export const sprintsList = createAsyncThunk(
    'sprintsSlice/read',
    async (payload = {}) => {
        // const response = await getSprintsList(payload)
        const response = await read(payload)
        return response
    }
)

// TOTAL SPRINTS
export const totalSprints = createAsyncThunk(
    'sprintsSlice/getSprintsCount',
    async (payload) => {
        const response = await getSprintsCount(payload)
        return response
    }
)

// CREATE SPRINT
export const createSprint = createAsyncThunk(
    'sprintsSlice/create',
    async (payload) => {
        const response = await create(payload)
        return response
    }
)

// UPDATE SPRINT
export const updateSprint = createAsyncThunk(
    'sprintsSlice/update',
    async (payload) => {
        const response = await update(payload)
        return response
    }
)

// DELETE SPRINT
export const deleteSprintList = createAsyncThunk(
    'sprintsSlice/deleteSprint',
    async (payload) => {
        const response = await deleteSprint(payload)
        return response
    }
)
// GET SPRINT BY ID
export const getSprint = createAsyncThunk(
    'sprintsSlice/getSprintById',
    async (payload) => {
        const response = await getSprintById(payload)
        return response
    }
)

// SPRINT AND EPIC WISE TASKS
export const taskSprintList = createAsyncThunk(
    'sprintsSlice/listTaskSprint',
    async (payload) => {
        const response = await listTaskSprintEpicWise(payload)
        return response
    }
)
// // SPRINT AND EPIC WISE TASKS
// export const taskSprintList = createAsyncThunk(
//     'sprintsSlice/listTaskSprint',
//     async (payload) => {
//         const response = await listTaskSprint(payload)
//         return response
//     }
// )

export const sprintsSlice = createSlice({
    name: 'sprintsSlice',
    initialState,
    reducers: {
        switchFormModal: (state, action) => {
            state.modalVariations = action.payload
            // state.selectedSprint = undefined
            // state.selectedSprintData = undefined
        },
        sprintSelect: (state, action) => {
            state.selectedSprintData = action.payload
        },
        clearSprintSelect: (state) => {
            state.selectedSprintData = undefined
        },
        switchTab: (state, action) => {
            state.selectedSprintTab = action.payload
        },
        searchSprints: (state, action) => {
            state.search = action.payload
        },
        setSprintTasks: (state, action) => {
            state.listTasks = action.payload
        },
        setSprints: (state, action) => {
            state.sprints = action.payload
        },
        setEpics: (state, action) => {
            state.epics = action.payload
        },
        clearSprints: (state) => {
            state.search = ''
            state.sprints = []
            state.selectedSprint = undefined
        },
        setPointerEvent: (state, action) => {
            state.pointerEvent = action.payload
        },
        changeEditInput: (state, action) => {
            state.editInput = {
                id: action.payload.id,
                open: action.payload.open,
            }
        },
        changeEditTaskInput: (state, action) => {
            state.editTaskInput = {
                id: action.payload.id,
                open: action.payload.open,
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(epicsList.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(epicsList.fulfilled, (state, action) => {
                state.status = 'loaded'
                if (action.payload.data?.length > 0) {
                    state.epics = action.payload.data.sort(function (a, b) {
                        return a.position - b.position
                    })
                } else {
                    state.epics = []
                }
            })
            .addCase(sprintsList.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(sprintsList.fulfilled, (state, action) => {
                state.status = 'loaded'
                state.sprints = action.payload.data
            })
            .addCase(getSprint.pending, (state) => {
                state.getSprintStatus = 'loading'
            })
            .addCase(getSprint.fulfilled, (state, action) => {
                state.getSprintStatus = 'loaded'
                state.selectedSprint = action.payload.data.data
            })
            .addCase(totalSprints.fulfilled, (state, action) => {
                state.sprintsCount = action.payload.data
            })
            .addCase(taskSprintList.fulfilled, (state, action) => {
                state.status = 'loaded'
                // state.listTasks = action.payload.data
                state.listTasks =
                    action.payload.data?.length > 0 &&
                    action.payload.data.map((item) => {
                        return {
                            ...item,
                            list:
                                item?.list?.length > 0
                                    ? item?.list.sort(function (a, b) {
                                          return (
                                              a?.epicPosition - b?.epicPosition
                                          )
                                      })
                                    : [],
                        }
                    })
            })
    },
})

export default sprintsSlice.reducer

export const sprintsState = (state) => {
    const { sprintsSlice } = state
    const {
        search,
        status,
        sprints,
        sprintsCount,
        formModal,
        viewModal,
        selectedSprint,
        epics,
        editInput,
        editTaskInput,
        isNextTask,
        pageLimitTask,
        currentPageTask,
        skipPageTask,
        listTasks,
        selectedSprintTab,
        selectedSprintData,
        isNextEpic,
        pageLimitEpic,
        currentPageEpic,
        skipPageEpic,
        pointerEvent,
        getSprintStatus,
        modalVariations,
    } = sprintsSlice
    return {
        search,
        status,
        sprints,
        sprintsCount,
        formModal,
        viewModal,
        selectedSprint,
        epics,
        editInput,
        editTaskInput,
        listTasks,
        isNextTask,
        pageLimitTask,
        currentPageTask,
        skipPageTask,
        selectedSprintTab,
        selectedSprintData,
        isNextEpic,
        pageLimitEpic,
        currentPageEpic,
        skipPageEpic,
        pointerEvent,
        getSprintStatus,
        modalVariations,
    }
}

export const {
    switchFormModal,
    switchViewModal,
    searchSprints,
    setSprints,
    clearSprints,
    setEpics,
    changeEditInput,
    changeEditTaskInput,
    setPointerEvent,
    switchTab,
    sprintSelect,
    clearSprintSelect,
    setSprintTasks,
} = sprintsSlice.actions
