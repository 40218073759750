import React, { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { debounce } from 'lodash'

// antd
import {
    Col,
    Drawer,
    Row,
    Input,
    Tabs,
    Typography,
    message,
    Button,
} from 'antd'
import {
    ProjectOutlined,
    SearchOutlined,
    DownloadOutlined,
} from '@ant-design/icons'

import dayjs from 'dayjs'

// slices
import {
    clearTaskFileUpload,
    filterDropdown,
    searchTasks,
    selectTaskUpdate,
    switchFormModal,
    switchFormModalSubTask,
    taskById,
    taskFileError,
    taskList,
    tasksState,
} from './tasks.slice'
import { setGeneralSettingsData, projectGet } from '../projects/projects.slice'
import { epicsList, sprintsList, sprintsState } from '../sprints/sprints.slice'
import { loginState } from '../login/login.slice'
import { teamListGet, teamState } from '../team/team.slice'

// routes
import { PRIVATE_ROUTES } from '../../routes'

// components
import PanelHeader from '../../components/panelHeader/panelHeader'

// assets
import { SprintCreateIcon } from '../../assets/icons'

// taskForm
import DrawerTaskForm from './taskForm.index'

// helpers
import { addPermissionCheck } from '../../helpers/permissionCheck'

// sections
import TasksFilter from './tasks.filter'
import TaskBoard from './taskBoard'
import RoadMap from '../roadmap'
import TaskLoading from './taskLoading'
import { downloadSprintsData } from '../sprints/sprints.service'

const initialDownloadState = {
    isDownloadExcel: false,
    activeSprint: null,
}

const Tasks = () => {
    const { Text } = Typography

    const dispatch = useDispatch()

    const params = useLocation()
    const navigate = useNavigate()

    const {
        tasks,
        formModal,
        skipPage,
        pageLimit,
        status,
        filterDropDown: taskFilterDropdown,
        search,
    } = useSelector(tasksState)
    const { sprints, skipPageEpic, pageLimitEpic } = useSelector(sprintsState)
    const { userProfile } = useSelector(loginState)
    const {
        teamList,
        pageLimit: teamPageLimit,
        skipPage: teamSkipPage,
    } = useSelector(teamState)

    const [filteredAssignees, setFilteredAssignees] = useState([])
    const [selectedTeamMembers, setSelectedTeamMembers] = useState([])
    const [tabName, setTabName] = useState('board')
    const [downloadState, setDownloadState] = useState(initialDownloadState)

    // ACTIVE PROJECT DETAIL
    async function activeProjectDetails() {
        const result = await dispatch(
            projectGet({
                id: userProfile?.activeProject?._id,
            })
        )
        const data = result?.payload?.data
        if (data) {
            dispatch(setGeneralSettingsData(data))
        }
    }

    // GET SPRINT
    async function getSprint() {
        await dispatch(sprintsList())
    }

    useEffect(() => {
        if (
            userProfile?.activeProject !== null &&
            userProfile?.activeProject?._id
        ) {
            getSprint()
            activeProjectDetails()
            dispatch(
                epicsList({
                    skip: skipPageEpic,
                    limit: pageLimitEpic,
                })
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (sprints?.length > 0) {
            dispatch(
                filterDropdown({
                    sprintId: JSON.parse(localStorage.getItem('currentSprint'))
                        ?.id,
                })
            )
            dispatch(
                taskList({
                    skip: skipPage,
                    limit: pageLimit,
                    filter: {
                        projectId: userProfile?.activeProject?._id,
                        sprintId: JSON.parse(
                            localStorage.getItem('currentSprint')
                        )?.id,
                    },
                })
            )
        }
        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sprints])

    // SEARCH USERS
    const searchFn = useCallback(
        debounce(async (value) => {
            dispatch(
                taskList({
                    skip: skipPage,
                    limit: pageLimit,
                    filter: {
                        projectId: userProfile?.activeProject?._id,
                        sprintId: taskFilterDropdown?.sprintId,
                    },
                    search: value,
                })
            )
        }, 1000),
        [dispatch, skipPage, pageLimit, userProfile, taskFilterDropdown]
    )

    // TEAM LIST GET
    useEffect(() => {
        dispatch(
            teamListGet({
                limit: teamPageLimit,
                skip: teamSkipPage,
                projectId: userProfile?.activeProject?._id,
            })
        )

        setDownloadState((prevState) => ({
            ...prevState,
            activeSprint: JSON.parse(localStorage.getItem('currentSprint')),
        }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // CHECK FOR LAZY LOADING IS_NEXT
    useEffect(() => {
        if (teamList?.length > 0) {
            let array = []
            teamList?.length > 0 &&
                teamList.forEach((data) => {
                    // if (userProfile?._id !== data?._id) {
                    array = [
                        ...array,
                        {
                            label: data?.name,
                            value: data?._id,
                            profilePicture: data?.profilePicture,
                        },
                    ]
                    // }
                })

            setFilteredAssignees(array)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [teamList])

    // VIEW TASK
    async function viewTask(taskID) {
        dispatch(
            switchFormModal({
                open: true,
            })
        )
        const result = await dispatch(taskById({ _id: taskID }))
        const data = result?.payload?.data
        if (data) {
            const { success } = data
            if (success) {
            } else {
                dispatch(
                    switchFormModal({
                        open: false,
                    })
                )
                message.error('Something went wrong, try again later.')
            }
        }
    }

    // VIEW TASK
    async function viewSubTask(taskID) {
        dispatch(switchFormModalSubTask({ open: true }))
        const result = await dispatch(taskById({ _id: taskID }))
        const data = result?.payload?.data
        if (data) {
            const { success } = data
            if (success) {
            } else {
                dispatch(switchFormModalSubTask({ open: false }))

                message.error('Something went wrong, try again later.')
            }
        }
    }

    useEffect(() => {
        if (
            params?.search.includes('subTaskId') &&
            params.search.includes('perentId')
        ) {
            viewTask(params?.search.split('perentId=')[1])
            viewSubTask(params?.search.split('subTaskId=')[1])
        } else if (params?.search.includes('taskId')) {
            viewTask(params?.search.split('taskId=')[1])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, params])

    // APPLY FILTER
    function applyAssigneeFilter(name, value) {
        dispatch(filterDropdown({ ...taskFilterDropdown, [name]: value }))

        dispatch(
            taskList({
                limit: pageLimit,
                skip: skipPage,
                filter: {
                    ...taskFilterDropdown,
                    [name]: value,
                    projectId: userProfile?.activeProject?._id,
                },
            })
        )

        const backlogId = sprints
            .filter((data) => data?.title === 'Backlog')
            .map((data) => data)
        localStorage.setItem(
            'currentSprint',
            JSON.stringify({
                id: backlogId[0]?._id,
                name: backlogId[0]?.title,
            })
        )
    }

    const Content = () => {
        return (
            <>
                <div style={{ margin: 10 }} />
                {userProfile?.activeProject &&
                userProfile?.activeProject?._id &&
                JSON.parse(localStorage.getItem('currentSprint'))?.id &&
                status === 'loaded' &&
                tasks?.length > 0 ? (
                    <TaskBoard props={{ tasks, filteredAssignees }} />
                ) : status === 'loading' ? (
                    <TaskLoading />
                ) : tasks?.length === 0 &&
                  status === 'loaded' &&
                  userProfile?.activeProject !== null ? (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            rowGap: 10,
                            marginTop: 50,
                        }}
                    >
                        <ProjectOutlined
                            style={{
                                fontSize: 30,
                            }}
                        />
                        <Text
                            style={{
                                fontSize: 18,
                            }}
                        >
                            Task is not created yet in this Sprint, Please
                            create a task
                        </Text>
                    </div>
                ) : (
                    tasks?.length === 0 &&
                    userProfile?.activeProject === null && (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                rowGap: 10,
                                marginTop: 50,
                            }}
                        >
                            <ProjectOutlined
                                style={{
                                    fontSize: 30,
                                }}
                            />
                            <Text
                                style={{
                                    fontSize: 18,
                                }}
                            >
                                Project is not Active yet in this Task, Please
                                Select the project to make active
                            </Text>
                        </div>
                    )
                )}
            </>
        )
    }

    const items = [
        {
            key: 'board',
            label: 'Board',
            children: <Content />,
        },
        // {
        //     key: 'roadMap',
        //     label: 'Roadmap',
        //     children: (
        //         <RoadMap
        //             props={{
        //                 tasks: tasks,
        //                 tabName: tabName,
        //             }}
        //         />
        //     ),
        // },
    ]

    // EXPORT CSV
    const handleExportCsv = async () => {
        try {
            setDownloadState((prevState) => ({
                ...prevState,
                isDownloadExcel: true,
            }))

            const sprintId =
                downloadState?.activeSprint?.id ||
                JSON.parse(localStorage.getItem('currentSprint'))?.id

            const response = await downloadSprintsData(sprintId)

            // Create a link element
            if (response) {
                setDownloadState((prevState) => ({
                    ...prevState,
                    isDownloadExcel: false,
                }))

                const url = window.URL.createObjectURL(
                    new Blob([response.data])
                )
                const link = document.createElement('a')
                link.href = url
                link.setAttribute(
                    'download',
                    `${userProfile?.activeProject?.name || ''}_${
                        downloadState?.activeSprint?.name || ''
                    }_${dayjs(new Date()).format('DMMMMYYYY')}.csv`
                ) // Name of the file to be downloaded
                document.body.appendChild(link)
                link.click()

                // Clean up and remove the link
                link.parentNode.removeChild(link)
            }
        } catch (error) {
            setDownloadState((prevState) => ({
                ...prevState,
                isDownloadExcel: false,
            }))
            message.error('Something went wrong, try again later.')
        }
    }

    return (
        <>
            <Drawer
                placement="right"
                size={'large'}
                style={{
                    height: '100vh',
                }}
                styles={{
                    body: {
                        padding: '0px',
                    },
                }}
                rootClassName="taskHeader"
                onClose={() => {
                    dispatch(switchFormModal({ open: false }))
                    dispatch(clearTaskFileUpload())
                    dispatch(taskFileError(''))
                    if (params?.search.includes('taskId')) {
                        navigate(PRIVATE_ROUTES.tasks)
                    }
                }}
                open={formModal}
            >
                <DrawerTaskForm
                    assigneeList={filteredAssignees}
                    onCancel={() => {
                        dispatch(switchFormModal({ open: false }))
                    }}
                />
            </Drawer>
            <div
                className={'container'}
                style={{
                    zIndex: 10,
                    minHeight: '100vh',
                    padding: '35px',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        gap: '20px',
                        marginBottom: '37px',
                    }}
                >
                    <PanelHeader
                        props={{
                            panelName: 'create',
                            panelButtonIcon: <SprintCreateIcon />,
                            searchBar: false,
                            filterBar: false,
                            icon: false,
                            showProject: true,
                            permissionAdd: addPermissionCheck(
                                'tasks',
                                userProfile
                            ),
                            teamMembersBar: true,
                            teamMembers: filteredAssignees,
                            selectedTeamMembers: selectedTeamMembers,
                            tooltipAvatar: true,
                            activeProject: userProfile,
                            applyAssigneeFilter: (name, value) => {
                                if (selectedTeamMembers.includes(value)) {
                                    // If the value is already in the array, remove it
                                    const newArray = selectedTeamMembers.filter(
                                        (item) => item !== value
                                    )
                                    setSelectedTeamMembers(newArray)
                                    applyAssigneeFilter(name, newArray)
                                } else {
                                    // If the value is not in the array, add it
                                    const newArray = [
                                        ...selectedTeamMembers,
                                        value,
                                    ]
                                    setSelectedTeamMembers(newArray)
                                    applyAssigneeFilter(name, newArray)
                                }
                            },
                            onClick: () => {
                                dispatch(selectTaskUpdate(undefined))
                                dispatch(
                                    switchFormModal({
                                        open: true,
                                    })
                                )
                            },
                        }}
                    />
                    <div>
                        <Button
                            loading={downloadState?.isDownloadExcel}
                            disabled={!downloadState?.activeSprint}
                            onClick={() => handleExportCsv()}
                            icon={<DownloadOutlined />}
                        >
                            Export Data
                        </Button>
                    </div>
                </div>
                <Tabs
                    items={items}
                    onChange={(activeKey) => {
                        setTabName(activeKey)
                    }}
                    tabBarStyle={
                        {
                            // width: '700px',
                        }
                    }
                    rootClassName="generalTabs"
                    className="tasksTabExtra"
                    tabBarExtraContent={{
                        right: (
                            <Row
                                align={'middle'}
                                gutter={[15, 15]}
                                style={{
                                    width: '100%',
                                }}
                            >
                                <Col span={10}>
                                    <Input
                                        value={search}
                                        onChange={(event) => {
                                            dispatch(
                                                searchTasks(event.target.value)
                                            )
                                            searchFn(event.target.value)
                                        }}
                                        style={{
                                            height: '40px',
                                        }}
                                        placeholder="Search"
                                        prefix={
                                            <SearchOutlined
                                                style={{
                                                    fontSize: 16,
                                                    marginRight: 5,
                                                }}
                                            />
                                        }
                                    />
                                </Col>
                                <Col span={14}>
                                    <TasksFilter
                                        props={{
                                            filteredAssignees,
                                            clearSearch: () => {
                                                dispatch(searchTasks(''))
                                            },
                                        }}
                                    />
                                </Col>
                            </Row>
                        ),
                    }}
                />
            </div>
        </>
    )
}

export default Tasks
