import React from 'react'

function IncompleteStepIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 43 43"
        >
            <circle cx="19.915" cy="19.399" r="19.399" fill="#FF3838"></circle>
            <g fill="#fff" clipPath="url(#clip0_913_14838)">
                <path d="M21.254 22.835h-1.527c-.568-3.703-1.125-7.349-1.682-10.993-.152-.995.06-1.871.972-2.4.962-.558 2.008-.626 2.96-.02.885.567 1.141 1.463.977 2.51-.572 3.611-1.129 7.23-1.7 10.903zM20.5 29a2.442 2.442 0 002.44-2.443A2.442 2.442 0 1020.5 29z"></path>
            </g>
            <defs>
                <clipPath id="clip0_913_14838">
                    <path
                        fill="#fff"
                        d="M0 0H5V20H0z"
                        transform="translate(18 9)"
                    ></path>
                </clipPath>
            </defs>
        </svg>
    )
}

export default IncompleteStepIcon
