import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

// antd
import { Menu, theme } from 'antd'

// styles
import styles from './navigation.module.css'

// navigationRoute
import SuperAdminRoutesNavigation from './superAdminRoutesNavigation'
import MemberRoutesNavigation from './memberRoutesNavigation'

const Navigation = () => {
    const nav = useNavigate()
    const { pathname } = useLocation()

    const { useToken } = theme

    const { token } = useToken()

    const [selected, setSelected] = useState()

    const navigate = async (key, route) => {
        if (pathname.split('/')[2] !== key || pathname.split('/')[3]) nav(route)
    }

    useEffect(() => {
        setSelected(pathname.split('/')[2])
    }, [pathname])

    // SIDEBAR ITEMS
    const [items] = useState([
        ...SuperAdminRoutesNavigation(selected),
        ...MemberRoutesNavigation(selected),
        // status !== 'loading' &&
        //     userProfile &&
        //     (userProfile?.role?.roleName === 'admin' ||
        //         userProfile?.role?.roleName === 'manager') &&
        //     userProfile?.role?.roleName !== undefined && {
        //         key: 'settings',
        //         label: 'Settings',
        //         icon: <SettingOutlined className={styles.icon + ' in-btn'} />,
        //     },
    ])

    return (
        <>
            <Menu
                theme="dark"
                mode="inline"
                items={items}
                className={styles.menu}
                style={{
                    fontSize: 16,
                    color: token.colorPalette.baseColor.white,
                    background: token.colorPalette.baseColor.primary,
                }}
                selectedKeys={[selected]}
                onClick={(data) => {
                    navigate(data?.key, data?.item?.props?.route)
                    setSelected(data?.key)
                }}
            />
        </>
    )
}

export default Navigation
