import React from 'react'

function LinkIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 30 30"
        >
            <path
                fill="#fff"
                fillRule="evenodd"
                d="M10.987 23.8l-1.519 1.508a3.397 3.397 0 01-4.77 0 3.274 3.274 0 01-.979-2.345c0-.887.346-1.72.978-2.348l5.588-5.542c1.157-1.15 3.336-2.841 4.924-1.266a1.86 1.86 0 002.62-2.639c-2.7-2.677-6.689-2.182-10.163 1.266l-5.588 5.542A6.98 6.98 0 000 22.963c0 1.883.738 3.653 2.078 4.984A7.088 7.088 0 007.082 30a7.092 7.092 0 005.007-2.053l1.517-1.51a1.859 1.859 0 00-2.62-2.637z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#fff"
                fillRule="evenodd"
                d="M27.92 2.267c-2.899-2.877-6.95-3.033-9.635-.37l-1.891 1.879a1.855 1.855 0 00-.01 2.627 1.857 1.857 0 002.628.01l1.892-1.877c1.391-1.381 3.211-.808 4.398.37.634.628.981 1.46.981 2.346 0 .887-.348 1.72-.981 2.348l-5.96 5.913c-2.727 2.704-4.005 1.435-4.55.893a1.86 1.86 0 00-2.62 2.639c1.252 1.24 2.68 1.856 4.176 1.856 1.835 0 3.77-.922 5.612-2.75l5.962-5.914A6.98 6.98 0 0030 7.25a6.972 6.972 0 00-2.08-4.984z"
                clipRule="evenodd"
            ></path>
        </svg>
    )
}

export default LinkIcon
