import axiosNew from '../../helpers/axios'

// LIST RECRUIT COMMENTS
export const read = (payload) => {
    const { skip = 0, limit = 0, userId } = payload
    return new Promise(async (resolve) => {
        try {
            let data = {
                skip,
                limit,
            }
            const users = await axiosNew({
                url: 'users/list-recruit-comments/' + userId,
                data,
            })
            resolve({
                data: users?.data?.data ?? [],
                isNext: users?.data?.isNext,
            })
        } catch (error) {
            resolve({ data: [] })
        }
    })
}

// ADD / REMOVE RECRUIT COMMENTS
export const addRemoveRecruitComments = async (payload) => {
    return new Promise(async (resolve) => {
        try {
            let data = payload?.data ?? {}
            const addRemoveComments = await axiosNew({
                url: 'users/add-remove-recruit-comments/' + payload?.id,
                data,
            })
            resolve({ data: addRemoveComments?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}
