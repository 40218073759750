import axiosNew from '../../helpers/axios'

// COUNT WORKSPACE DATA
export const countWorkSpace = async (payload) => {
    const { search = '', filter = {} } = payload

    return new Promise(async (resolve) => {
        try {
            let data = { filter }
            if (search?.length > 0) {
                data['search'] = {
                    companyName: search,
                    domain: search,
                }
            }
            const count = await axiosNew({
                url: 'workspace/count',
                data,
            })
            resolve({ data: count?.data?.result ?? 0 })
        } catch (error) {
            resolve({ data: 0 })
        }
    })
}

// LIST WORKSPACE DATA
export const listWorkSpaceData = async (payload) => {
    const { search = '', skip = 0, limit = 0, filter = {} } = payload
    return new Promise(async (resolve) => {
        try {
            let data = {
                skip,
                filter,
                limit,
                select: [
                    'name',
                    'logo',
                    'companyName',
                    'companyEmail',
                    'domain',
                    'phone',
                    'address',
                    'isActive',
                    'mailConfig',
                    'permission',
                ],
                populate: [],
            }
            if (search?.length > 0) {
                data['search'] = {
                    companyName: search,
                    domain: search,
                }
            }
            const listData = await axiosNew({
                url: `workspace/read`,
                data,
            })
            resolve({ data: listData?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// GET WORKSPACE DATA
export const getWorkSpace = async (payload) => {
    return new Promise(async (resolve) => {
        try {
            const getData = await axiosNew({
                url: `workspace/get/${payload?._id}`,
                method: 'get',
            })
            resolve({ data: getData?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// ADD WORKSPACE DATA
export const addWorkSpaceData = async (payload) => {
    return new Promise(async (resolve) => {
        try {
            let data = payload?.addData ?? {}
            const addData = await axiosNew({
                url: 'workspace/create',
                data,
            })
            resolve({ data: addData?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// UPDATE WORKSPACE DATA
export const updateWorkSpaceData = async (payload) => {
    return new Promise(async (resolve) => {
        try {
            let data = payload?.editData ?? {}
            const updateData = await axiosNew({
                url: 'workspace/update/' + payload?._id,
                data,
            })
            resolve({ data: updateData?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// DELETE WORKSPACE DATA
export const deleteWorkSpaceData = async (payload) => {
    return new Promise(async (resolve) => {
        try {
            let data = payload?.deletedData ?? {}
            const deleteData = await axiosNew({
                url: 'master-data/delete/' + payload.model,
                data,
            })
            resolve({ data: deleteData?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// BULK DATA UPDATE WORKSPACE DATA
export const changePermission = async (payload) => {
    return new Promise(async (resolve) => {
        try {
            let data = payload?.editData ?? {}
            const deleteData = await axiosNew({
                url: 'master-data/bulk-update/' + payload.model,
                data,
            })
            resolve({ data: deleteData?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// VERIFY SMTP
export const verifySMTP = async (payload) => {
    return new Promise(async (resolve) => {
        try {
            let data = payload ?? {}
            const verifySMTP = await axiosNew({
                url: 'workspace/verify-smtp',
                data,
            })
            resolve({ data: verifySMTP?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// ASSIGN WORKSPACE
export const assignWorkspace = async (payload) => {
    // const { workspaceId, userId } = payload
    return new Promise(async (resolve) => {
        try {
            const workSpaceData = await axiosNew({
                url: `workspace/assign-workspace`,
                data: payload,
            })
            resolve({ data: workSpaceData?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// UNASSIGN WORKSPACE
export const unAssignWorkspace = async (payload) => {
    return new Promise(async (resolve) => {
        try {
            const workSpaceData = await axiosNew({
                url: `workspace/unassign-workspace`,
                data: payload,
            })
            resolve({ data: workSpaceData?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}
