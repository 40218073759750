import React from 'react'

function HighPriorityIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
        >
            <circle
                cx="12"
                cy="12"
                r="10"
                stroke="#E94B35"
                strokeWidth="2"
            ></circle>
            <path
                stroke="#E94B35"
                strokeLinecap="round"
                strokeWidth="2"
                d="M12 7v6"
            ></path>
            <circle cx="12" cy="16" r="1.5" fill="#E94B35"></circle>
        </svg>
    )
}

export default HighPriorityIcon
