import { useNavigate } from 'react-router-dom'

// antd
import { Typography, theme } from 'antd'

// components
import Button from '../../components/button/button'

// assets
import { SuccessfullyPasswordIcon } from '../../assets/icons'

// routes
import { PUBLIC_ROUTES } from '../../routes'

function SuccessfullyResetPassword() {
    const { useToken } = theme

    const navigate = useNavigate()

    const { token } = useToken()

    const { Text, Title } = Typography

    return (
        <div
            style={{
                padding: '60px 60px 0px 60px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <SuccessfullyPasswordIcon />
            <div
                style={{
                    margin: '35px 0px 40px 0px',
                    textAlign: 'center',
                }}
            >
                <Text
                    className="titleLight"
                    style={{
                        fontSize: token.fontSizeHeading5,
                    }}
                >
                    Your Password has been reset
                </Text>
                <Title
                    style={{
                        fontSize: token.fontSizeHeading4,
                        margin: '0px',
                    }}
                >
                    Successfully
                </Title>
            </div>
            <Button
                props={{
                    text: 'Continue',
                    onClick: () => navigate(PUBLIC_ROUTES.login),
                    style: {
                        backgroundColor:
                            token.colorPalette.registrationColor.secondary,
                        width: '100%',
                    },
                }}
            />
        </div>
    )
}

export default SuccessfullyResetPassword
