import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

// antd
import { Col, Form, Row, Typography, message, theme } from 'antd'

// slices
import { sendOtpLogin } from './login.slice'

// components
import Button from '../../components/button/button'

// constants
import { PHONE_COUNTRIES } from '../../constants/phone-countries'

// routes
import { PUBLIC_ROUTES } from '../../routes'

function ForgotPassword() {
    const [form] = Form.useForm()

    const { useToken } = theme

    const { token } = useToken()

    const navigate = useNavigate()

    const dispatch = useDispatch()

    const { Title } = Typography

    const initialState = {
        loadingButton: false,
        phoneNumber: {
            phoneNo: null,
            code: null,
        },
        phoneValid: false,
    }

    const [state, setState] = useState(initialState)

    useEffect(() => {
        window.localStorage.clear()
    }, [])

    // CHANGE PHONE NUMBER
    const handleChangePhoneNumber = (number, country) => {
        const phoneNo = number.replace(country.dialCode, '')
        setState((prevState) => ({
            ...prevState,
            phoneNumber: {
                phoneNo,
                code: `+${country.dialCode}`,
            },
        }))
    }

    function onFinishFailed() {}

    // SEND OTP ON RESET PASSWORD
    async function onSendOTP() {
        setState((prevState) => ({ ...prevState, loadingButton: true }))
        // SEND OTP
        if (state?.phoneNumber?.phoneNo?.length > 0) {
            const result = await dispatch(
                sendOtpLogin({
                    countryCode: state?.phoneNumber.code,
                    phone: state?.phoneNumber?.phoneNo,
                })
            )
            const data = result?.payload?.data
            if (data) {
                const { success, message: checkMessage } = data
                if (success) {
                    localStorage.setItem(
                        'phoneData',
                        JSON.stringify({
                            phoneNumber: `${state?.phoneNumber?.phoneNo}`,
                            countryCode: `${state?.phoneNumber?.code}`,
                            step: 'manual',
                        })
                    )
                    setState((prevState) => ({
                        ...prevState,
                        loadingButton: false,
                    }))

                    message.success(checkMessage)
                    navigate(PUBLIC_ROUTES.verifyOtp)
                } else {
                    setState((prevState) => ({
                        ...prevState,
                        loadingButton: false,
                    }))

                    if (checkMessage) {
                        message.error(checkMessage)
                    } else {
                        message.error('Something went wrong, try again later.')
                    }
                }
            }
        }
    }

    return (
        <div
            style={{
                padding: '60px 60px 0px 60px',
            }}
        >
            <Title level={3}>Forgot Password</Title>
            <Form
                layout="vertical"
                name="forgotPassword"
                onFinish={onSendOTP}
                onFinishFailed={onFinishFailed}
                requiredMark={false}
                form={form}
                style={{
                    marginTop: '20px',
                }}
            >
                <Col
                    span={24}
                    style={{
                        padding: '0px',
                        marginBottom: '10px',
                    }}
                >
                    <Form.Item
                        label="Phone Number*"
                        name="phone"
                        rules={[
                            {
                                validator: async () => {
                                    if (
                                        !state?.phoneValid &&
                                        state?.phoneNumber?.phoneNo?.length > 0
                                    ) {
                                        return Promise.reject(
                                            new Error(
                                                'Please Enter a valid Phone Number'
                                            )
                                        )
                                    }
                                    if (
                                        state?.phoneNumber?.phoneNo?.length ===
                                            0 ||
                                        state?.phoneNumber?.phoneNo === null
                                    ) {
                                        return Promise.reject(
                                            new Error(
                                                'Please Enter Phone Number'
                                            )
                                        )
                                    }
                                },
                            },
                        ]}
                        colon={false}
                    >
                        <PhoneInput
                            inputProps={{
                                name: 'phone',
                                required: true,
                            }}
                            onlyCountries={['in', 'au', 'us', 'gb']}
                            country={'in'}
                            specialLabel=""
                            inputStyle={{
                                height: '50px',
                                width: '100%',
                            }}
                            value={state?.phoneNumber?.default ?? null}
                            onChange={(number, country) => {
                                handleChangePhoneNumber(number, country)
                                const correspondingCountry =
                                    PHONE_COUNTRIES.find(
                                        (countryName) =>
                                            countryName.iso2 ===
                                            country.countryCode?.toUpperCase()
                                    )
                                let numberNew = `+${number}`
                                if (
                                    correspondingCountry &&
                                    number &&
                                    correspondingCountry?.validation.test(
                                        numberNew
                                    )
                                ) {
                                    setState((prevState) => ({
                                        ...prevState,
                                        phoneValid: true,
                                    }))
                                } else {
                                    setState((prevState) => ({
                                        ...prevState,
                                        phoneValid: false,
                                    }))
                                }
                            }}
                        />
                    </Form.Item>
                </Col>
                <Row
                    gutter={20}
                    style={{
                        marginTop: '20px',
                    }}
                >
                    <Col span={24}>
                        <Button
                            props={{
                                text: 'Reset Password',
                                htmlType: 'submit',
                                style: {
                                    backgroundColor:
                                        token.colorPalette.registrationColor
                                            .secondary,
                                    width: '100%',
                                },
                                disabled: state?.loadingButton,
                                loadingButton: state?.loadingButton,
                            }}
                        />
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default ForgotPassword
