import axiosNew from '../../../helpers/axios'

// CREATE JOB
export const apiJobCreate = async (payload) => {
    return new Promise(async (resolve) => {
        try {
            let data = payload ?? {}
            const create = await axiosNew({
                url: 'jobs/create',
                data,
            })
            resolve({ data: create?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// GET JOB
export const apiGetJob = async (payload) => {
    return new Promise(async (resolve) => {
        try {
            const getUser = await axiosNew({
                url: 'jobs/get/' + payload,
                method: 'get',
            })
            resolve({ data: getUser?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// LIST JOBS
export const apiJobList = (payload) => {
    return new Promise(async (resolve) => {
        try {
            let query = { ...payload }
            const jobs = await axiosNew({
                url: 'jobs/list',
                method: 'get',
                params: query,
            })
            resolve({
                data: jobs?.data ?? [],
                isNext: jobs?.data?.isNext,
            })
        } catch (error) {
            resolve({ data: [] })
        }
    })
}

// UPDATE JOB
export const apiJobUpdate = async (payload) => {
    return new Promise(async (resolve) => {
        try {
            let data = payload?.updatedData ?? {}
            const update = await axiosNew({
                url: 'jobs/edit/' + payload._id,
                data,
                method: 'put',
            })
            resolve({ data: update?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// DELETE JOB
export const apiJobDelete = async (payload) => {
    return new Promise(async (resolve) => {
        try {
            const deleteUser = await axiosNew({
                url: 'jobs/delete/' + payload,
            })
            resolve({ data: deleteUser?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}
