import { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// antd
import { Col, Row, theme, Typography } from 'antd'

// slices
import { profileState, switchVideoPlayModal } from '../profile.slice'

// assets
import { VideosIcon } from '../../../assets/icons'

function VideosSummaryContent({ contentData }) {
    const { Title, Text } = Typography

    const { useToken } = theme
    const { token } = useToken()

    const dispatch = useDispatch()

    const videoEl = useRef(null)

    const { introductionVideoModal } = useSelector(profileState)

    return (
        <Row
            style={{
                margin: '25px 0px 50px 0px',
            }}
            align={'middle'}
            gutter={16}
        >
            {contentData.map((videosData, index) => {
                const startDate = new Date(videosData?.updatedAt)

                return (
                    <Col span={6} key={videosData?.type}>
                        <Row
                            align={'middle'}
                            style={{
                                columnGap: '20px',
                            }}
                        >
                            <video
                                src={videosData?.link}
                                width={'100%'}
                                height={'200px'}
                                ref={videoEl}
                                style={{
                                    maxHeight: '250px',
                                    objectFit: 'cover',
                                    position: 'relative',
                                }}
                                onLoadedMetadata={() => {
                                    const video = videoEl.current
                                    if (!video) return
                                }}
                                preview={false}
                                id={videosData?.type}
                            />
                            <div
                                style={{
                                    height: '50px',
                                    position: 'absolute',
                                    top: '27%',
                                    right: '40%',
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    dispatch(
                                        switchVideoPlayModal({
                                            ...introductionVideoModal,
                                            open: true,
                                            videoData: {
                                                deleteId: '',
                                                link: videosData?.link,
                                            },
                                            title: videosData?.type,
                                        })
                                    )
                                }}
                            >
                                <VideosIcon
                                    color={token.colorPalette.textColor.quinary}
                                />
                            </div>
                            <Row
                                justify={'space-between'}
                                style={{
                                    marginTop: '22px',
                                    width: '100%',
                                }}
                            >
                                <Title className={'titleSub'}>
                                    {videosData?.type || 'N/A'}
                                </Title>
                            </Row>

                            <Row>
                                <Text>{videosData?.category || 'N/A'} </Text> |
                                {/* {document.getElementById('videoSummary')} */}
                            </Row>
                            <Row>
                                <Text
                                    style={{
                                        fontSize: token.fontSizeLG,
                                    }}
                                    className={'titleLight'}
                                >
                                    {startDate.toLocaleString('default', {
                                        year: 'numeric',
                                        month: 'long',
                                    })}
                                </Text>
                            </Row>
                        </Row>
                    </Col>
                )
            })}
        </Row>
    )
}

export default VideosSummaryContent
