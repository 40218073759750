import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// antd
import {
    Col,
    Divider,
    Form,
    Modal,
    Row,
    Select,
    Spin,
    Typography,
    message,
} from 'antd'

// slices
import { totalUsers, updateUser, usersList, usersState } from './users.slice'

// components
import Button from '../../components/button/button'

const RoleEditModal = ({ modal, onCancel, loading }) => {
    const [form] = Form.useForm()

    const { Title } = Typography

    const { Option } = Select

    const { userRole, filterDropDown, skipPage, pageLimit, roleListStatus } =
        useSelector(usersState)

    const dispatch = useDispatch()

    const [userRoleOptions, setUserRoleOptions] = useState([])

    useEffect(() => {
        if (modal?.userData?.role?.roleName && modal?.userData?.role?._id) {
            form.setFieldsValue({
                role: [
                    {
                        label: modal?.userData?.role?.roleName,
                        value: modal?.userData?.role?._id,
                    },
                ],
            })
        } else {
            form.setFieldsValue({ role: [] })
        }
        if (userRole?.length > 0) {
            const options =
                userRole?.length > 0 &&
                userRole.map((data) => {
                    return {
                        label: data?.roleName,
                        value: data?._id,
                    }
                })
            setUserRoleOptions(options)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userRole, roleListStatus])

    // ON FINISH ROLE EDIT
    const onFinishRoleEdit = async (value) => {
        loading()
        const result = await dispatch(
            updateUser({
                updatedData: {
                    data: {
                        role:
                            typeof value?.role === 'object'
                                ? value?.role[0]?.value
                                : value?.role,
                        isAssigned: true,
                    },
                },
                _id: modal?.userData?._id,
            })
        )
        const data = result?.payload?.data
        if (data) {
            const { success, message: checkMessage } = data
            if (success) {
                dispatch(
                    await totalUsers({
                        filter: filterDropDown,
                    })
                )
                dispatch(
                    await usersList({
                        skip: skipPage,
                        limit: pageLimit,
                        filter: filterDropDown,
                    })
                )
                message.success(checkMessage)
                onCancel()
            } else {
                onCancel()
                if (checkMessage) {
                    message.error(checkMessage)
                } else {
                    message.error('Something went wrong, try again later.')
                }
            }
        }
    }

    // ON FINISH FAILED
    const onFinishFailed = (error) => {}

    return (
        <Modal
            closable={false}
            open={modal?.open}
            destroyOnClose={true}
            onCancel={onCancel}
            centered
            style={{
                width: '426px',
                maxWidth: '426px',
                padding: '0px',
                borderRadius: '10px',
            }}
            title={null}
            footer={null}
        >
            <Spin spinning={roleListStatus === 'loading' ? true : false}>
                <Form
                    labelCol={{
                        style: {
                            padding: '0 0 3px',
                        },
                    }}
                    layout="vertical"
                    name="terminateForm"
                    onFinish={onFinishRoleEdit}
                    onFinishFailed={onFinishFailed}
                    requiredMark={false}
                    form={form}
                    style={{
                        overflowX: 'hidden',
                        marginTop: '25px',
                    }}
                >
                    <Row gutter={30}>
                        <Col span={24}>
                            <Title level={5}>Role</Title>
                            <Form.Item
                                style={{
                                    marginTop: '8px',
                                }}
                                colon={false}
                                name="role"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Select Role',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Select a Role"
                                    allowClear={false}
                                >
                                    {userRoleOptions?.length > 0 &&
                                        userRoleOptions.map((data) => (
                                            <Option
                                                value={data?.value}
                                                key={data?.label}
                                            >
                                                {data?.label
                                                    .charAt(0)
                                                    .toUpperCase() +
                                                    data?.label.slice(1)}
                                            </Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider />
                    <Row
                        align={'middle'}
                        justify={'end'}
                        style={{
                            columnGap: '10px',
                        }}
                    >
                        <Button
                            props={{
                                text: 'Cancel',
                                onClick: onCancel,
                                buttonType: 'text',
                            }}
                        />
                        <Button
                            props={{
                                text: 'Save',
                                htmlType: 'submit',
                                loadingButton: modal?.loading,
                            }}
                        />
                    </Row>
                </Form>
            </Spin>
        </Modal>
    )
}

export default RoleEditModal
