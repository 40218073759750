import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import S3FileUpload from 'react-s3'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { debounce } from 'lodash'

// antd
import {
    Avatar,
    Card,
    Col,
    Empty,
    Form,
    Image,
    Input,
    message,
    Row,
    Select,
    theme,
    Typography,
    Upload,
    Divider,
    Space,
    Spin,
} from 'antd'
import { PlusOutlined } from '@ant-design/icons'

// slices
import {
    changeModel,
    masterDataAddDropDown,
    masterDataDropDownList,
    profileState,
} from '../profile/profile.slice'
import { emailCheck, loginState, numberCheck } from '../login/login.slice'
import {
    addUser,
    checkButtonType,
    switchFormModal,
    totalUsers,
    updateUser,
    usersList,
    usersState,
} from './users.slice'

// assets
import { UploadImageIcon } from '../../assets/icons'

// constants
import { PHONE_COUNTRIES } from '../../constants/phone-countries'
import { EMAIL_PATTERN } from '../../constants/patterns'

// helpers
import { isEmpty } from '../../helpers/fieldCheck'
import { editFieldCheck, viewFieldCheck } from '../../helpers/permissionCheck'

import dayjs from 'dayjs'

function UserAddForm() {
    const { Text } = Typography

    const { Option } = Select

    const { useToken } = theme
    const { token } = useToken()

    const dispatch = useDispatch()

    // SELECTORS
    const { masterDataDropDown, masterDataDropDownStatus, modelType } =
        useSelector(profileState)
    const { userProfile } = useSelector(loginState)
    const {
        selectedUser,
        pageLimit,
        skipPage,
        filterDropDown,
        userRole,
        getUserStatus,
        modalVariations,
    } = useSelector(usersState)

    const [form] = Form.useForm()

    const timeoutRef = useRef()
    const sessionTokenRef = useRef()

    // STATES
    const [phoneNumber, setPhoneNumber] = useState({
        phoneNo: null,
        code: null,
    })
    const [phoneValid, setPhoneValid] = useState(false)
    const [suggestions, setSuggestions] = useState([])
    const [fileURL, setFileURL] = useState([])
    const [userRoleOptions, setUserRoleOptions] = useState([])
    const [searchValue, setSearchValue] = useState('')
    const [timeZoneChange, setTimeZoneChange] = useState('')
    const [emailValid, setEmailValid] = useState(false)

    useEffect(() => {
        if (userRole?.length > 0) {
            const options =
                userRole?.length > 0 &&
                userRole.map((data) => {
                    return {
                        label: data?.roleName,
                        value: data?._id,
                    }
                })
            setUserRoleOptions(options)
        }
    }, [userRole])

    useEffect(() => {
        if (selectedUser) {
            setPhoneNumber({
                phoneNo: selectedUser?.phone,
                code: selectedUser?.countryCode,
            })
            setPhoneValid(true)
            if (selectedUser?.profilePicture?.length > 0) {
                setFileURL([selectedUser?.profilePicture])
            }
            form.setFieldsValue({
                ...selectedUser,
                address: `${selectedUser?.location} (${selectedUser?.timeZone})`,
                role: [
                    {
                        label: selectedUser?.role?.roleName,
                        value: selectedUser?.role?._id,
                    },
                ],
                designation: [
                    {
                        label: selectedUser?.designation?.name,
                        value: selectedUser?.designation?._id,
                    },
                ],
                phone: `${selectedUser?.countryCode}${selectedUser?.phone}`,
            })
        }
    }, [form, selectedUser])

    // CHECK EMAIL ID
    async function checkEmailId(value) {
        const result = await dispatch(
            emailCheck({
                email: value,
            })
        )

        const data = result?.payload?.data
        if (data) {
            const { success, isDuplicate } = data
            if (!success && isDuplicate) {
                setEmailValid(false)
                form.setFieldValue('email', '')
                message.info('Email Id should be unique')
            }
        }
    }

    // ADD MASTER DATA
    async function addMasterData() {
        const result = await dispatch(
            masterDataAddDropDown({
                addData: {
                    type: 'manual',
                    data: {
                        name: searchValue,
                        logo: '',
                        createdBy: userProfile?._id,
                    },
                },
                model: modelType,
            })
        )
        const data = result?.payload?.data

        if (data) {
            const { success, message: errorMessage } = data
            if (success) {
                dispatch(
                    masterDataDropDownList({
                        search: searchValue,
                        id: modelType,
                        // limit: pageLimit,
                        // skip: skipPage,
                    })
                )
            } else {
                if (errorMessage) {
                    message.error(errorMessage)
                } else {
                    message.error('Something went wrong, try again later.')
                }
            }
        }
    }

    // SEARCH MASTER DATA
    const searchFn = debounce((value) => {
        setSearchValue(value)
        if (value?.length > 0) {
            dispatch(
                masterDataDropDownList({
                    search: value,
                    id: modelType,
                })
            )
        }
    }, 800)

    // ONCHANGE FILE PROPS
    const props = {
        name: 'profilePicture',
        multiple: false,
        onChange(info) {
            // setStream(true)
            const isLt5M = info?.file.size / 1024 / 1024 < 5
            if (!isLt5M) {
                message.error('Profile picture must be smaller than 5MB')
                return
            } else {
                const nameChange = info?.fileList[0]?.name
                    .split('.')[0]
                    .concat(`_${dayjs(new Date()).unix()}`)
                    .concat(`.${info?.fileList[0]?.name.split('.')[1]}`)

                const newFileData = new File(
                    [info?.fileList[0]?.originFileObj],
                    nameChange,
                    { type: info?.fileList[0]?.type }
                )
                window.Buffer = window.Buffer || require('buffer').Buffer

                S3FileUpload.uploadFile(newFileData, userProfile?.s3Config)
                    .then(async (data) => {
                        // fileUpload.push(data?.location)
                        setFileURL([data?.location])
                    })
                    .catch(() => {
                        message.error('Upload Failed!. Please Upload again')
                    })
            }
            // info?.preventDefault()
        },

        beforeUpload() {
            return false
        },

        onDrop(e) {},
    }

    function onFinishFailed(value) {}

    async function checkMobileNumber() {
        const result = await dispatch(
            numberCheck({
                countryCode: phoneNumber?.code,
                phone: phoneNumber?.phoneNo,
            })
        )

        const data = result?.payload?.data
        if (data) {
            const { success, isDuplicate } = data
            if (!success && isDuplicate) {
                setPhoneValid(false)
                form.setFieldValue('phone', '+91')
                setPhoneNumber({
                    ...phoneNumber,
                    phoneNo: null,
                    code: null,
                })
                message.info('Phone Number should be unique')
            }
        }
    }

    // CHANGE PHONE NUMBER
    const handleChangePhoneNumber = (number, country) => {
        const phoneNo = number.replace(country.dialCode, '')
        setPhoneNumber({
            default: number,
            phoneNo,
            code: `+${country.dialCode}`,
            flag: country.countryCode.toUpperCase(),
        })
    }

    // GOOGLE PLACE API INTEGRATION
    const loadSuggestions = async (inputValue) => {
        clearTimeout(timeoutRef.current)

        if (!inputValue || inputValue.trim().length <= 1) {
            setSuggestions([])
            return
        }

        timeoutRef.current = setTimeout(async () => {
            if (!sessionTokenRef.current) {
                sessionTokenRef.current =
                    new window.google.maps.places.AutocompleteSessionToken()
            }

            const placesService = new window.google.maps.places.PlacesService(
                document.createElement('div')
            )
            new window.google.maps.places.AutocompleteService().getPlacePredictions(
                {
                    input: inputValue,
                    sessionToken: sessionTokenRef.current,
                },
                async (predictions, status) => {
                    if (
                        status ===
                        window.google.maps.places.PlacesServiceStatus
                            .ZERO_RESULTS
                    ) {
                        setSuggestions([])
                        return
                    }
                    if (
                        status !==
                            window.google.maps.places.PlacesServiceStatus.OK ||
                        !predictions
                    ) {
                        return
                    }

                    const newData = []

                    for (const predictionData of predictions) {
                        const placeId = predictionData?.place_id

                        const placeData = await new Promise(
                            (resolve, reject) => {
                                placesService.getDetails(
                                    { placeId, fields: ['geometry'] },
                                    async (place, status) => {
                                        if (
                                            status ===
                                                window.google.maps.places
                                                    .PlacesServiceStatus.OK &&
                                            place &&
                                            place.geometry &&
                                            place.geometry.location
                                        ) {
                                            const location =
                                                place.geometry.location

                                            try {
                                                const response = await fetch(
                                                    `https://maps.googleapis.com/maps/api/timezone/json?location=${location.lat()},${location.lng()}&timestamp=${Math.floor(
                                                        Date.now() / 1000
                                                    )}&key=AIzaSyC79hoAq6x6PIprpy39EPylaZ6i6ZVEsD4`
                                                )

                                                if (!response.ok) {
                                                    throw new Error(
                                                        `HTTP error! Status: ${response.status}`
                                                    )
                                                }

                                                const data =
                                                    await response.json()
                                                const timeZoneId =
                                                    data.timeZoneId

                                                resolve({
                                                    label: predictionData?.description,
                                                    value: predictionData?.description,
                                                    timeZone: timeZoneId,
                                                })
                                            } catch (error) {
                                                console.error(
                                                    'Error fetching timezone data:',
                                                    error
                                                )
                                                reject(error)
                                            }
                                        } else {
                                            console.error(
                                                'Error getting place details:',
                                                status
                                            )
                                            reject(status)
                                        }
                                    }
                                )
                            }
                        )
                        newData.push(placeData)
                    }

                    setSuggestions(newData)
                }
            )
        }, 350)
    }

    // ONFINISH USER ADD
    const onFinishAddUser = async (value) => {
        dispatch(
            switchFormModal({
                ...modalVariations,
                saveButtonLoading: true,
            })
        )
        const payload = {
            ...value,
            // role: value?.role[0]?.value,
            role: value?.role,
            timeZone: timeZoneChange,
            designation:
                typeof value?.designation === 'object'
                    ? value?.designation[0]?.value
                    : value?.designation,
            phone: phoneNumber?.phoneNo,
            countryCode: phoneNumber?.code,
            profilePicture: fileURL?.length > 0 ? fileURL[0] : '',
            isAssigned: true,
        }

        const updatePayload = {
            data: { ...payload },
        }

        if (selectedUser && selectedUser?.isTalentUser) {
            updatePayload.talentRoleEdit = true
        }

        const result = selectedUser
            ? await dispatch(
                  updateUser({
                      updatedData: updatePayload,
                      _id: selectedUser?._id,
                  })
              )
            : await dispatch(
                  addUser({
                      ...payload,
                  })
              )
        const data = result?.payload?.data
        if (data) {
            const { success, message: errorMessage } = data
            if (success) {
                dispatch(
                    await totalUsers({
                        filter: filterDropDown,
                    })
                )
                dispatch(
                    await usersList({
                        skip: skipPage,
                        limit: pageLimit,
                        filter: filterDropDown,
                    })
                )
                dispatch(
                    switchFormModal({
                        ...modalVariations,
                        saveButtonLoading: false,
                        open: false,
                    })
                )
                setTimeZoneChange('')
                message.success(errorMessage)
            } else {
                dispatch(
                    switchFormModal({
                        ...modalVariations,
                        saveButtonLoading: false,
                    })
                )
                setTimeZoneChange('')
                if (errorMessage) {
                    message.error(errorMessage)
                } else {
                    message.error('Something went wrong, try again later.')
                }
            }
        }
    }

    return (
        <Spin spinning={getUserStatus === 'loading' ? true : false}>
            <Form
                labelCol={{
                    style: {
                        padding: '0 0 3px',
                    },
                }}
                layout="vertical"
                name="userAddForm"
                onFinish={onFinishAddUser}
                onFinishFailed={onFinishFailed}
                onSubmitCapture={async (e) => {
                    await dispatch(checkButtonType(e.nativeEvent.submitter.id))
                }}
                form={form}
                requiredMark={false}
                style={{
                    overflowX: 'hidden',
                }}
            >
                <Row
                    gutter={30}
                    style={{
                        flexFlow: 'row nowrap',
                    }}
                >
                    {(isEmpty(selectedUser) ||
                        viewFieldCheck(
                            'users',
                            'profilePicture',
                            userProfile
                        )) && (
                        <Col flex={'1 0 37.5%'}>
                            <Card
                                style={{
                                    borderRadius: '16px',
                                    height: '100%',
                                }}
                                styles={{
                                    body: {
                                        padding: '30px 44px',
                                    },
                                }}
                            >
                                <Form.Item
                                    name="profilePicture"
                                    rootClassName="imageUpload"
                                >
                                    <Upload
                                        listType="picture-circle"
                                        className="avatar-uploader"
                                        {...props}
                                        fileList={[]}
                                        disabled={
                                            selectedUser?.isTalentUser ||
                                            editFieldCheck(
                                                'users',
                                                'profilePicture',
                                                userProfile
                                            ) === false
                                        }
                                        showUploadList={false}
                                        onPreview={() => {}}
                                        accept=".png,.jpg,.jpeg"
                                        style={{
                                            height: '128px !important',
                                            width: '128px !important',
                                        }}
                                    >
                                        {fileURL?.length > 0 ? (
                                            // && !randomAvatar
                                            <Image
                                                src={fileURL[0]}
                                                preview={false}
                                                style={{
                                                    height: '128px',
                                                    width: '128px',
                                                    objectFit: 'contain',
                                                    borderRadius: '50%',
                                                }}
                                            />
                                        ) : (
                                            <>
                                                <Avatar
                                                    style={{
                                                        color: token
                                                            .colorPalette
                                                            .baseColor.white,
                                                    }}
                                                    className={'uploadAvatar'}
                                                    size="large"
                                                >
                                                    {userProfile?.name[0].toUpperCase()}
                                                    {userProfile?.name.split(
                                                        ' '
                                                    )[1] !== undefined &&
                                                        userProfile?.name
                                                            .split(' ')[1][0]
                                                            .toUpperCase()}
                                                </Avatar>
                                            </>
                                        )}
                                        <div className={'hoverLayer'}>
                                            <div className={'hoverShow'}>
                                                <UploadImageIcon />
                                                <div
                                                    style={{
                                                        marginTop: 8,
                                                        color: token
                                                            .colorPalette
                                                            .baseColor.white,
                                                    }}
                                                >
                                                    Update Picture
                                                </div>
                                            </div>
                                        </div>
                                    </Upload>
                                    <Row
                                        style={{
                                            marginTop: '10px',
                                        }}
                                    >
                                        <Text
                                            className="titleSecondary"
                                            style={{
                                                fontSize: token.fontSizeIcon,
                                                textAlign: 'center',
                                            }}
                                        >
                                            Allowed *.jpeg, *.jpg, *.png, Max
                                            size of 5 MB
                                        </Text>
                                    </Row>
                                </Form.Item>
                            </Card>
                        </Col>
                    )}
                    <Col flex={'1 0 62.5%'}>
                        <Row
                            gutter={20}
                            style={{
                                rowGap: '15px',
                            }}
                        >
                            {(isEmpty(selectedUser) ||
                                viewFieldCheck(
                                    'users',
                                    'name',
                                    userProfile
                                )) && (
                                <Col flex={'1 0 50%'}>
                                    <Form.Item
                                        name={'name'}
                                        label="Name*"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please Enter Name',
                                                max: 25,
                                            },
                                        ]}
                                    >
                                        <Input
                                            placeholder="Enter Name"
                                            readOnly={
                                                selectedUser?.isTalentUser ||
                                                editFieldCheck(
                                                    'users',
                                                    'name',
                                                    userProfile
                                                ) === false
                                            }
                                        />
                                    </Form.Item>
                                </Col>
                            )}
                            {(isEmpty(selectedUser) ||
                                viewFieldCheck(
                                    'users',
                                    'designation',
                                    userProfile
                                )) && (
                                <Col flex={'1 0 50%'}>
                                    <Form.Item
                                        label="Designation*"
                                        name="designation"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    'Please Select Designation',
                                            },
                                        ]}
                                        colon={false}
                                    >
                                        <Select
                                            placeholder="Search a Designation"
                                            showSearch
                                            onSearch={(value) => {
                                                dispatch(
                                                    changeModel('designation')
                                                )
                                                searchFn(value)
                                            }}
                                            disabled={
                                                selectedUser?.isTalentUser ||
                                                editFieldCheck(
                                                    'users',
                                                    'designation',
                                                    userProfile
                                                ) === false
                                            }
                                            filterOption={false}
                                            allowClear={false}
                                            dropdownRender={(menu) => (
                                                <>
                                                    {menu}
                                                    <Divider
                                                        style={{
                                                            margin: '8px 0',
                                                        }}
                                                    />
                                                    <Space
                                                        style={{
                                                            padding:
                                                                '0 8px 4px',
                                                        }}
                                                    >
                                                        <Row
                                                            align={'middle'}
                                                            style={{
                                                                columnGap:
                                                                    '5px',
                                                                cursor:
                                                                    searchValue?.length >
                                                                    0
                                                                        ? 'pointer'
                                                                        : 'not-allowed',
                                                            }}
                                                            onClick={() => {
                                                                if (
                                                                    searchValue?.length >
                                                                    0
                                                                ) {
                                                                    addMasterData()
                                                                }
                                                            }}
                                                        >
                                                            <PlusOutlined
                                                                style={{
                                                                    color: token
                                                                        .colorPalette
                                                                        .baseColor
                                                                        .tertiary,
                                                                    fontSize:
                                                                        token.fontSizeHeading5,
                                                                }}
                                                            />
                                                            <Text
                                                                style={{
                                                                    color: token
                                                                        .colorPalette
                                                                        .baseColor
                                                                        .tertiary,
                                                                    fontSize:
                                                                        token.fontSizeHeading5,
                                                                    fontWeight:
                                                                        token.fontWeightStrong,
                                                                }}
                                                            >
                                                                Add
                                                            </Text>
                                                        </Row>
                                                    </Space>
                                                </>
                                            )}
                                        >
                                            {masterDataDropDown?.designation
                                                ?.length > 0 &&
                                            masterDataDropDownStatus ===
                                                'loaded'
                                                ? masterDataDropDown?.designation.map(
                                                      (option) => {
                                                          return (
                                                              <Option
                                                                  key={
                                                                      option?.value
                                                                  }
                                                                  value={
                                                                      option?.value
                                                                  }
                                                              >
                                                                  <Text>
                                                                      {
                                                                          option?.label
                                                                      }
                                                                  </Text>
                                                              </Option>
                                                          )
                                                      }
                                                  )
                                                : masterDataDropDownStatus ===
                                                      'loading' && (
                                                      <Spin></Spin>
                                                  )}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            )}
                            {(isEmpty(selectedUser) ||
                                viewFieldCheck(
                                    'users',
                                    'role',
                                    userProfile
                                )) && (
                                <Col span={24}>
                                    <Form.Item
                                        label="Role"
                                        name="role"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please Select Role',
                                            },
                                        ]}
                                        colon={false}
                                    >
                                        <Select
                                            placeholder="Select a Role"
                                            allowClear={false}
                                            disabled={
                                                editFieldCheck(
                                                    'users',
                                                    'role',
                                                    userProfile
                                                ) === false
                                            }
                                        >
                                            {userRoleOptions?.length > 0 &&
                                                userRoleOptions.map((data) => (
                                                    <Option
                                                        value={data?.value}
                                                        key={data?.label}
                                                    >
                                                        {data?.label
                                                            .charAt(0)
                                                            .toUpperCase() +
                                                            data?.label.slice(
                                                                1
                                                            )}
                                                    </Option>
                                                ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            )}
                            {(isEmpty(selectedUser) ||
                                viewFieldCheck(
                                    'users',
                                    'email',
                                    userProfile
                                )) && (
                                <Col span={24}>
                                    <Form.Item
                                        name={'email'}
                                        label="Email ID*"
                                        rules={[
                                            {
                                                validator: async (_, value) => {
                                                    const pattern =
                                                        EMAIL_PATTERN

                                                    if (
                                                        value?.length === 0 ||
                                                        value === undefined
                                                    ) {
                                                        return Promise.reject(
                                                            new Error(
                                                                'Please Enter Email'
                                                            )
                                                        )
                                                    }
                                                    if (
                                                        !pattern.test(value) &&
                                                        value?.length > 0
                                                    ) {
                                                        setEmailValid(false)
                                                        return Promise.reject(
                                                            new Error(
                                                                'Please Enter a valid Email Id'
                                                            )
                                                        )
                                                    } else {
                                                        setEmailValid(true)
                                                    }
                                                },
                                            },
                                        ]}
                                    >
                                        <Input
                                            readOnly={
                                                selectedUser?.isTalentUser ||
                                                editFieldCheck(
                                                    'users',
                                                    'email',
                                                    userProfile
                                                ) === false
                                            }
                                            placeholder="Enter Email ID"
                                            onBlur={(e) => {
                                                if (emailValid) {
                                                    checkEmailId(e.target.value)
                                                }
                                            }}
                                            onChange={() => {
                                                setEmailValid(false)
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                            )}
                        </Row>
                    </Col>
                </Row>
                <Row
                    gutter={26}
                    style={{
                        marginTop: '15px',
                        rowGap: '25px',
                    }}
                >
                    {(isEmpty(selectedUser) ||
                        viewFieldCheck('users', 'phone', userProfile)) && (
                        <Col flex={'1 0 50%'}>
                            <Form.Item
                                label="Phone Number*"
                                name="phone"
                                rules={[
                                    {
                                        validator: async () => {
                                            if (
                                                !phoneValid &&
                                                phoneNumber?.phoneNo?.length > 0
                                            ) {
                                                return Promise.reject(
                                                    new Error(
                                                        'Please Enter a valid Phone Number'
                                                    )
                                                )
                                            }

                                            if (
                                                phoneNumber?.phoneNo?.length ===
                                                    0 ||
                                                phoneNumber?.phoneNo === null
                                            ) {
                                                return Promise.reject(
                                                    new Error(
                                                        'Please Enter Phone Number'
                                                    )
                                                )
                                            }
                                        },
                                    },
                                ]}
                                colon={false}
                            >
                                <PhoneInput
                                    inputProps={{
                                        name: 'phone',
                                        required: true,
                                    }}
                                    inputStyle={{
                                        width: '100%',
                                    }}
                                    disabled={
                                        selectedUser?.isTalentUser ||
                                        editFieldCheck(
                                            'users',
                                            'phone',
                                            userProfile
                                        ) === false
                                    }
                                    specialLabel=""
                                    onlyCountries={['in', 'au', 'us', 'gb']}
                                    country={'in'}
                                    onBlur={() => {
                                        if (
                                            phoneValid &&
                                            phoneNumber?.phoneNo?.length > 0 &&
                                            phoneNumber?.phoneNo !== null
                                        ) {
                                            checkMobileNumber()
                                        }
                                    }}
                                    // value={phoneNumber?.phoneNo ?? null}
                                    onChange={(number, country) => {
                                        handleChangePhoneNumber(number, country)
                                        const correspondingCountry =
                                            PHONE_COUNTRIES.find(
                                                (countryName) =>
                                                    countryName.iso2 ===
                                                    country.countryCode?.toUpperCase()
                                            )
                                        let numberNew = `+${number}`
                                        if (
                                            correspondingCountry &&
                                            number &&
                                            correspondingCountry?.validation.test(
                                                numberNew
                                            )
                                        ) {
                                            setPhoneValid(true)
                                        } else {
                                            setPhoneValid(false)
                                        }
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    )}
                    {(isEmpty(selectedUser) ||
                        viewFieldCheck('users', 'address', userProfile)) && (
                        <Col flex={'1 0 50%'}>
                            <Form.Item
                                label="Location"
                                name="address"
                                colon={false}
                            >
                                <Select
                                    placeholder="Search a Location"
                                    allowClear={false}
                                    onSelect={(_, option) =>
                                        setTimeZoneChange(option?.key)
                                    }
                                    disabled={
                                        selectedUser?.isTalentUser ||
                                        editFieldCheck(
                                            'users',
                                            'address',
                                            userProfile
                                        ) === false
                                    }
                                    notFoundContent={
                                        <Empty
                                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                                            description="No Locations Searched"
                                        ></Empty>
                                    }
                                    onSearch={(value) => {
                                        loadSuggestions(value)
                                    }}
                                    showSearch
                                >
                                    {suggestions?.length > 0 &&
                                        suggestions.map((option) => (
                                            <Option
                                                key={option?.timeZone}
                                                value={option?.label}
                                            >
                                                <Text>{option?.label}</Text>
                                                &nbsp;
                                                <Text>
                                                    ({option?.timeZone})
                                                </Text>
                                            </Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Row>
            </Form>
        </Spin>
    )
}

export default UserAddForm
