import axiosNew from '../../helpers/axios'

// GET NOTIFICATIONS LIST
export const getNotifications = (payload) => {
    const { userId, unRead } = payload

    return new Promise(async (resolve) => {
        try {
            const getNotification = await axiosNew({
                url: `notifications/list?userId=${userId}${
                    unRead ? `&unRead=${unRead}` : ''
                }`,
                method: 'get',
            })
            resolve({ data: getNotification?.data ?? [] })
        } catch (error) {
            resolve({ data: [] })
        }
    })
}

// UNREAD NOTIFICATIONS
export const changeStatusNotification = (payload) => {
    const { notificationId, status } = payload

    let data = payload?.notificationClick ?? {}

    return new Promise(async (resolve) => {
        try {
            const changeStatus = await axiosNew({
                url: `notifications/change-status/${notificationId}`,
                method: 'put',
                data,
            })
            resolve({ data: changeStatus?.data ?? [] })
        } catch (error) {
            resolve({ data: [] })
        }
    })
}

// MARK ALL AS READ NOTIFICATIONS
export const getMarkAsRead = (payload) => {
    const { userId } = payload

    return new Promise(async (resolve) => {
        try {
            const getMarkAsRead = await axiosNew({
                url: `notifications/mark-all-read/${userId}`,
                method: 'put',
            })
            resolve({ data: getMarkAsRead?.data ?? [] })
        } catch (error) {
            resolve({ data: [] })
        }
    })
}
