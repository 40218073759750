import axiosNew from '../../helpers/axios'

// GET ROADMAP LISTS
export const getRoadmapList = (payload) => {
    const { skip = 0, limit = 0, filter, search = '', projectId } = payload
    return new Promise(async (resolve) => {
        try {
            let data = {
                filter,
                skip,
                limit,
            }
            if (search?.length > 0) {
                data['search'] = search
            }
            const tasks = await axiosNew({
                url: `tasks/roadmap/${projectId}`,
                data,
            })
            resolve({ data: tasks?.data?.data ?? [] })
        } catch (error) {
            resolve({ data: [] })
        }
    })
}
