export const masterDataMenu = [
    {
        heading: 'Profile',
        items: [
            {
                key: 'institute',
                label: 'Institute',
            },
            {
                key: 'degree',
                label: 'Degree Name',
            },
            {
                key: 'fieldOfStudy',
                label: 'Field of Study',
            },
            {
                key: 'skills',
                label: 'Skills',
            },
            {
                key: 'tools',
                label: 'Tools',
            },
            {
                key: 'certificateProvider',
                label: 'Certificate Provider',
            },
            {
                key: 'company',
                label: 'Companies',
            },
            {
                key: 'employmentType',
                label: 'Employment Type',
            },
            {
                key: 'designation',
                label: 'Designation',
            },
        ],
    },
]

export const MASTER_DATA_FIELD = {
    institute: 'institute',
    degree: 'degree',
    fieldOfStudy: 'fieldOfStudy',
    skills: 'skills',
    tools: 'tools',
    certificateProvider: 'certificateProvider',
    company: 'company',
    employmentType: 'employmentType',
    designation: 'designation',
}
