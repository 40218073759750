export const schedulesListArray = [
    {
        userId: 1,
        width: 2,
        offset: 1,
        content: 'Task 1',
    },
    {
        userId: 1,
        width: 2,
        offset: 2,
        content: 'Task 2',
    },
    {
        userId: 1,
        width: 4,
        offset: 3,
        content: 'Task 3',
    },
    {
        userId: 2,
        width: 3,
        offset: 2,
        content: 'Task 1',
    },
    {
        userId: 2,
        width: 4,
        offset: 3,
        content: 'Task 2',
    },
    {
        userId: 2,
        width: 2,
        offset: 2,
        content: 'Task 3',
    },
    {
        userId: 3,
        width: 3,
        offset: 0,
        content: 'Task 1',
    },
    {
        userId: 3,
        width: 3,
        offset: 1,
        content: 'Task 2',
    },
    {
        userId: 3,
        width: 2,
        offset: 0,
        content: 'Task 3',
    },
]
