import React from 'react'

function ExperienceIcon({ color }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="35"
            height="30"
            fill="none"
            viewBox="0 0 35 30"
        >
            <path
                fill={color ? color : '#B9B9B9'}
                fillRule="evenodd"
                d="M1.287 6.234C.429 7.086 0 8.106 0 9.293V25.4c0 1.188.429 2.208 1.287 3.06.857.85 1.885 1.276 3.081 1.276h1.247V4.956H4.368c-1.196 0-2.224.426-3.081 1.278zM12.479 4.956h9.982V2.478H12.48v2.478zm12.478-3.097c0-.516-.182-.955-.547-1.317A1.812 1.812 0 0023.085 0h-11.23c-.52 0-.962.181-1.327.542-.363.36-.546.8-.546 1.317v3.097H7.486v24.78h19.967V4.956h-2.496V1.859zM33.654 6.234c-.86-.852-1.885-1.278-3.082-1.278h-1.248v24.78h1.248c1.197 0 2.223-.425 3.082-1.277.857-.851 1.286-1.871 1.286-3.06V9.294c0-1.187-.429-2.207-1.286-3.059z"
                clipRule="evenodd"
            ></path>
        </svg>
    )
}

export default ExperienceIcon
