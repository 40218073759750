export const JOB_STATUS = {
    ONGOING: 'Ongoing',
    GATHERING_APPLICATION: 'Gathering Application',
}

export const BUDGET_TYPE = {
    HOURLY: 'Hourly',
    WEEKLY: 'Weekly',
    MONTHLY: 'Monthly',
}

export const JOB_STATUS_OPTIONS = [
    {
        label: JOB_STATUS.ONGOING,
        value: 'ongoing',
    },
    {
        label: JOB_STATUS.GATHERING_APPLICATION,
        value: 'gatheringApplication',
    },
]

export const BUDGET_TYPE_OPTIONS = [
    {
        label: BUDGET_TYPE.HOURLY,
        value: 'hourly',
    },
    {
        label: BUDGET_TYPE.WEEKLY,
        value: 'weekly',
    },
    {
        label: BUDGET_TYPE.MONTHLY,
        value: 'monthly',
    },
]

export const jobRules = {
    title: [
        {
            required: true,
            message: 'Please Enter Title',
        },
    ],
}

export const JOB_APPLICATIONS_STATUS = [
    {
        label: 'Matches',
        value: 'matches',
    },
    {
        label: 'Applied',
        value: 'applied',
    },
    {
        label: 'Phone Screen',
        value: 'phoneScreen',
    },
    {
        label: 'Technical Round',
        value: 'technicalRound',
    },
    {
        label: 'Offered',
        value: 'offered',
    },
    {
        label: 'Accepted',
        value: 'accepted',
    },
    {
        label: 'Rejected',
        value: 'rejected',
    },
]
