import React from 'react'
import dayjs from 'dayjs'

import { Badge, Card, theme } from 'antd'

import { useNavigate } from 'react-router-dom'
import { PRIVATE_ROUTES } from '../../../routes'

const JobCards = ({ job }) => {
    const { useToken } = theme

    const { token } = useToken()

    const navigate = useNavigate()

    const handleClickJobCard = (rowJob) => {
        navigate(PRIVATE_ROUTES.jobApplication(rowJob?._id))
    }

    return (
        <div style={{ width: '32%', cursor: 'pointer' }}>
            <Card onClick={() => handleClickJobCard(job)}>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        fontWeight: 700,
                        fontSize: '18px',
                    }}
                >
                    <div>
                        <span>{job?.title || ''}</span>
                        <span>({job?.noOfPositions || ''})</span>
                    </div>
                    <div>
                        <Badge
                            count={job?.isActiveJob ? 'Active' : 'InActive'}
                            color={
                                job?.isActiveJob
                                    ? 'hsla(155, 54%, 46%, 0.16)'
                                    : 'hsla(11, 100%, 59%, 0.16)'
                            }
                            style={{
                                minWidth: '30px',
                                cursor: 'auto',
                                borderRadius: '6px',
                                display: 'flex',
                                alignItems: 'center',
                                color: job?.isActiveJob
                                    ? token.colorPalette.iconColor.tertiary
                                    : token.colorPalette.iconColor.quaternary,
                                padding: '12px 8px',
                                fontWeight: 700,
                            }}
                        />
                    </div>
                </div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        fontWeight: 400,
                        fontSize: '14px',
                        color: '#919EAB',
                        marginTop: '6px',
                    }}
                >
                    <span>{job?.location || '-'}</span>
                    <span>
                        {job?.createdAt
                            ? dayjs(job?.createdAt).format('DD-MM-YYYY')
                            : '-'}
                    </span>
                </div>
                <div style={{ marginTop: '6px' }}>
                    <span
                        style={{
                            color: '#4F46E5',
                            fontWeight: 600,
                            fontSize: '14px',
                            textTransform: 'capitalize',
                        }}
                    >
                        {job?.status || '-'}
                    </span>
                </div>
                <div
                    style={{
                        marginTop: '20px',
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <span
                        style={{
                            background: '#DBD9FF80',
                            padding: '6px 11px',
                            borderRadius: '40px',
                        }}
                    >
                        {job?.matches || 0} Matches
                    </span>
                    <span
                        style={{
                            background: '#FAD29680',
                            padding: '6px 11px',
                            borderRadius: '40px',
                        }}
                    >
                        {job?.applied || 0} Applied
                    </span>
                    <span
                        style={{
                            background: '#ACDAB080',
                            padding: '6px 11px',
                            borderRadius: '40px',
                        }}
                    >
                        {job?.hired || 0} Hired
                    </span>
                </div>
            </Card>
        </div>
    )
}

export default JobCards
