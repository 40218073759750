import React from 'react'

function ByPassIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="none"
            viewBox="0 0 16 16"
        >
            <path
                fill="#637381"
                d="M8 8.918H.665V7.085h7.333V.668l7.334 7.333-7.334 7.334V8.918z"
            ></path>
        </svg>
    )
}

export default ByPassIcon
