import React, { useEffect, useState } from 'react'
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Image,
    Svg,
    Line,
} from '@react-pdf/renderer'

// assets
import Logo from '../../../assets/images/darkLogo.png'

import dayjs from 'dayjs'

// Create styles
const styles = StyleSheet.create({
    page: {
        padding: '40px 24px 0 24px',
        fontSize: 9,
        lineHeight: 1.6,
        backgroundColor: '#fff',
        textTransform: 'capitalize',
    },
    gridContainer: { flexDirection: 'row', justifyContent: 'space-between' },
    h2: { fontSize: 11, fontWeight: 700 },
    summaryHead: {
        padding: '18px 25px',
        backgroundColor: '#285FD0',
        borderRadius: '7px',
        marginTop: '10px',
    },
    summaryText: {
        color: '#ffffff',
        fontSize: 12,
    },
    taskTime: {
        color: '#000000',
        fontSize: 10,
    },
    memberText: {
        fontWeight: 700,
        fontSize: 10,
    },
})

// Create Document Component
const ReportDocument = ({ pdfData, selectPdfData }) => {
    const [totalHours, setTotalHours] = useState(0)

    useEffect(() => {
        if (pdfData?.tableBody?.length > 0) {
            let hours = pdfData?.tableBody.map(
                (data) =>
                    data?.list?.length > 0 &&
                    data?.list.reduce(function (acc, obj) {
                        return acc + obj?.totalLog?.total
                    }, 0)
            )
            const sum = hours.reduce((partialSum, a) => partialSum + a, 0)
            setTotalHours(sum)
        }
    }, [pdfData?.tableBody])

    function hourMinuteSecond(timeInMilliseconds) {
        let h, m, s
        h = Math.floor(timeInMilliseconds / 1000 / 60 / 60)
        m = Math.floor((timeInMilliseconds / 1000 / 60 / 60 - h) * 60)
        s = Math.floor(
            ((timeInMilliseconds / 1000 / 60 / 60 - h) * 60 - m) * 60
        )
        return `${h}h ${m}m ${s}s`
    }

    return (
        <>
            <Document>
                <Page size="A4" style={styles.page}>
                    <View style={[styles.gridContainer]}>
                        <View style={{ alignItems: 'flex-start' }}>
                            <Image
                                source={Logo}
                                style={{ height: 36, margin: '24px auto' }}
                            />
                        </View>
                        <View
                            style={{
                                justifyContent: 'center',
                                flexDirection: 'column',
                            }}
                        >
                            <Text style={styles.h2}>Progress Report</Text>
                            <Text style={{ fontSize: 11, fontWeight: 400 }}>
                                {`${dayjs(selectPdfData?.start).format(
                                    'DD MMM YYYY'
                                )} - ${dayjs(selectPdfData?.end).format(
                                    'DD MMM YYYY'
                                )}`}
                            </Text>
                            <Text style={{ fontSize: 11, fontWeight: 400 }}>
                                Project: {selectPdfData?.project?.name}
                            </Text>
                        </View>
                    </View>

                    <View style={[styles.gridContainer, styles.summaryHead]}>
                        <View
                            style={{
                                alignItems: 'center',
                            }}
                        >
                            <Text style={styles.summaryText}>Summary</Text>
                        </View>
                        <View
                            style={{
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                            }}
                        >
                            <Text style={styles.summaryText}>
                                {hourMinuteSecond(totalHours)}
                            </Text>
                            <Text style={styles.summaryText}>Total Hours</Text>
                        </View>
                    </View>

                    {pdfData?.tableBody?.length > 0 ? (
                        pdfData?.tableBody.map((item) => (
                            <>
                                {selectPdfData?.assignees?.length > 0 &&
                                    selectPdfData?.assignees.map((assignee) => (
                                        <>
                                            {assignee?.value ===
                                                item?._id?.assigneeId && (
                                                <View style={{ marginTop: 20 }}>
                                                    <Svg
                                                        height="10"
                                                        width="100%"
                                                    >
                                                        {' '}
                                                        <Line
                                                            x1="0"
                                                            y1="5"
                                                            x2="1000"
                                                            y2="5"
                                                            strokeWidth={3}
                                                            stroke="#285FD0"
                                                        />
                                                    </Svg>
                                                    <View
                                                        style={[
                                                            styles.gridContainer,
                                                            styles.memberText,
                                                            { padding: 10 },
                                                        ]}
                                                    >
                                                        <View
                                                            style={{
                                                                fontSize: 10,
                                                                fontWeight: 700,
                                                                alignItems:
                                                                    'center',
                                                            }}
                                                        >
                                                            <Text>
                                                                {
                                                                    assignee?.label
                                                                }
                                                            </Text>
                                                        </View>
                                                        <View
                                                            style={{
                                                                flexDirection:
                                                                    'column',
                                                                alignItems:
                                                                    'flex-start',
                                                                fontWeight: 700,
                                                            }}
                                                        >
                                                            <Text
                                                                style={
                                                                    styles.taskTime
                                                                }
                                                            >
                                                                {hourMinuteSecond(
                                                                    item?.list.reduce(
                                                                        (
                                                                            acc,
                                                                            obj
                                                                        ) => {
                                                                            return (
                                                                                acc +
                                                                                obj
                                                                                    ?.totalLog
                                                                                    ?.total
                                                                            )
                                                                        },
                                                                        0
                                                                    )
                                                                )}
                                                            </Text>
                                                            <Text
                                                                style={
                                                                    styles.taskTime
                                                                }
                                                            >
                                                                Total Time
                                                            </Text>
                                                        </View>
                                                    </View>
                                                    <Svg
                                                        height="5"
                                                        width="100%"
                                                    >
                                                        {' '}
                                                        <Line
                                                            x1="0"
                                                            y1="5"
                                                            x2="1000"
                                                            y2="5"
                                                            strokeWidth={1}
                                                            stroke="#c6c6c6"
                                                        />
                                                    </Svg>
                                                    {item?.list?.length > 0 &&
                                                        item?.list.map(
                                                            (row) => (
                                                                <>
                                                                    {row?.list?.map(
                                                                        (l) => (
                                                                            <View
                                                                                style={[
                                                                                    styles.gridContainer,
                                                                                    {
                                                                                        padding: 10,
                                                                                        marginBottom: 20,
                                                                                    },
                                                                                ]}
                                                                            >
                                                                                <View
                                                                                    style={{
                                                                                        flexDirection:
                                                                                            'column',
                                                                                        justifyContent:
                                                                                            'center',
                                                                                    }}
                                                                                >
                                                                                    <Text
                                                                                        style={{
                                                                                            marginLeft: 5,
                                                                                            flex: 1,
                                                                                        }}
                                                                                    >
                                                                                        -{' '}
                                                                                        {
                                                                                            l?.title
                                                                                        }{' '}
                                                                                    </Text>
                                                                                    <Text
                                                                                        style={{
                                                                                            paddingLeft: 20,
                                                                                            paddingTop: 10,
                                                                                            flex: 1,
                                                                                        }}
                                                                                    >
                                                                                        {
                                                                                            l?.description
                                                                                        }
                                                                                    </Text>
                                                                                </View>
                                                                                <Text>
                                                                                    {l
                                                                                        ?.timelogs
                                                                                        ?.end
                                                                                        ? hourMinuteSecond(
                                                                                              l
                                                                                                  ?.timelogs
                                                                                                  ?.end -
                                                                                                  l
                                                                                                      ?.timelogs
                                                                                                      ?.start
                                                                                          )
                                                                                        : hourMinuteSecond(
                                                                                              new Date().getTime() -
                                                                                                  l
                                                                                                      ?.timelogs
                                                                                                      ?.start
                                                                                          )}
                                                                                </Text>
                                                                            </View>
                                                                        )
                                                                    )}
                                                                </>
                                                            )
                                                        )}
                                                </View>
                                            )}
                                        </>
                                    ))}
                            </>
                        ))
                    ) : (
                        <Text>No Data</Text>
                    )}
                </Page>
            </Document>
        </>
    )
}

export default ReportDocument
