// assets
import {
    FilesIcon,
    NotesIcon,
    OverViewIcon,
    RoadMapIcon,
    // RoadMapIcon,
    SprintsIcon,
    StandUpIcon,
    TasksIcon,
    TeamIcon,
} from '../../assets/icons'

// styles
import styles from '../../layouts/navigation/navigation.module.css'

export const modulePermissions = [
    {
        key: 'overview',
        label: 'Overview',
        value: 'overview',
        checked: false,
        icon: <OverViewIcon className={styles.icon + ' in-btn'} />,
        position: 2,
        parentName: 'projects',
        isParent: false,
    },
    {
        key: 'standups',
        label: 'Standups',
        value: 'standups',
        checked: false,
        icon: <StandUpIcon className={styles.icon + ' in-btn'} />,
        position: 3,
        parentName: 'projects',
        isParent: false,
    },
    {
        key: 'notes',
        label: 'Notes',
        value: 'notes',
        checked: false,
        icon: <NotesIcon className={styles.icon + ' in-btn'} />,
        position: 8,
        parentName: 'projects',
        isParent: false,
    },
    {
        key: 'tasks',
        label: 'Tasks',
        value: 'tasks',
        checked: false,
        icon: <TasksIcon className={styles.icon + ' in-btn'} />,
        position: 7,
        parentName: 'projects',
        isParent: false,
    },
    {
        key: 'roadMap',
        label: 'Roadmap',
        value: 'roadMap',
        checked: false,
        icon: <RoadMapIcon className={styles.icon + ' in-btn'} />,
        position: 8,
        parentName: 'projects',
        isParent: false,
    },
    {
        key: 'sprints',
        label: 'Sprints',
        value: 'sprints',
        checked: false,
        icon: <SprintsIcon className={styles.icon + ' in-btn'} />,
        position: 5,
        parentName: 'projects',
        isParent: false,
    },
    {
        key: 'epics',
        label: 'Epic',
        value: 'epics',
        checked: false,
        icon: <SprintsIcon className={styles.icon + ' in-btn'} />,
        position: 6,
        parentName: 'projects',
        isParent: false,
    },
    {
        key: 'teams',
        label: 'Team',
        value: 'teams',
        checked: false,
        icon: <TeamIcon className={styles.icon + ' in-btn'} />,
        position: 4,
        parentName: 'projects',
        isParent: false,
    },
    {
        key: 'files',
        label: 'Files',
        value: 'files',
        checked: false,
        icon: <FilesIcon className={styles.icon + ' in-btn'} />,
        position: 9,
        parentName: 'projects',
        isParent: false,
    },
    // {
    //     key: 'dashboard',
    //     label: 'Dashboard',
    //     value: 'dashboard',
    //     checked: false,
    // },
    {
        key: 'users',
        label: 'Users',
        value: 'users',
        checked: false,
        position: 0,
        isParent: true,
    },
    {
        key: 'projects',
        label: 'Projects',
        value: 'projects',
        checked: false,
        position: 1,
        isParent: true,
    },
    {
        key: 'reports',
        label: 'Reports',
        value: 'reports',
        checked: false,
        position: 10,
        parentName: 'projects',
        isParent: false,
    },
    // {
    //     key: 'profile',
    //     label: 'Profile',
    //     value: 'profile',
    //     checked: false,
    // },
    // {
    //     key: 'settings',
    //     label: 'Settings',
    //     value: 'settings',
    //     checked: false,
    //     icon: <RoadMapIcon className={styles.icon + ' in-btn'} />,
    // },
    {
        key: 'screenshots',
        label: 'Screenshots',
        value: 'screenshots',
        checked: false,
        icon: <SprintsIcon className={styles.icon + ' in-btn'} />,
        position: 11,
        parentName: 'projects',
        isParent: false,
    },
    // {
    //     key: 'chat',
    //     label: 'Chat',
    //     value: 'chat',
    //     checked: false,
    //     icon: <SprintsIcon className={styles.icon + ' in-btn'} />,
    // },
]

export const headerModulePermission = [
    {
        key: 'dashboard',
        label: 'Dashboard',
        value: 'dashboard',
        checked: false,
        icon: <></>,
    },
    {
        key: 'users',
        label: 'Users',
        value: 'users',
        checked: false,
        icon: <></>,
    },
    {
        key: 'projects',
        label: 'Projects',
        value: 'projects',
        checked: false,
        icon: <></>,
    },
    {
        key: 'reports',
        label: 'Reports',
        value: 'reports',
        checked: false,
        icon: <></>,
    },
]
