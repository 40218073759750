import React from 'react'

function KeyboardIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="none"
            viewBox="0 0 16 16"
        >
            <path
                stroke="#747474"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 6.666h2.333m-6.667 3.667h6.667M4.999 2.666h6c.414 0 .78.013 1.107.06 1.753.193 2.227 1.02 2.227 3.273v4c0 2.254-.474 3.08-2.227 3.274-.327.046-.693.06-1.107.06H5c-.413 0-.78-.014-1.106-.06-1.754-.194-2.227-1.02-2.227-3.274V6c0-2.253.473-3.08 2.227-3.273.326-.047.693-.06 1.106-.06zM6.73 6.666h.006m-2.006 0h.006"
            ></path>
        </svg>
    )
}

export default KeyboardIcon
