import React from 'react'

import {
    Checkbox,
    Col,
    DatePicker,
    Form,
    Input,
    Modal,
    Row,
    Select,
    Button,
} from 'antd'
import { BUDGET_TYPE_OPTIONS } from '../../../constants/jobs'

const { Option } = Select

const JobOfferModel = ({ state, setState }) => {
    const [jobOfferForm] = Form.useForm()

    // For Budget Type
    const selectAfterBudget = (formName) => (
        <Form.Item name={formName}>
            <Select placeholder="Type" style={{ width: '103px' }}>
                {BUDGET_TYPE_OPTIONS &&
                    BUDGET_TYPE_OPTIONS.map((type) => (
                        <Option value={type.value}>{type.label}</Option>
                    ))}
            </Select>
        </Form.Item>
    )

    const onFinishJobOffer = (values) => {
        setState((prevState) => ({
            ...prevState,
            jobOfferModalOpen: false,
            jobOfferModalData: values,
        }))
    }

    return (
        <>
            <Modal
                open={state.jobOfferModalOpen}
                onClose={() =>
                    setState({
                        ...state,
                        jobOfferModalOpen: false,
                        jobOfferModalData: null,
                    })
                }
                footer={null}
                width={900}
                closeIcon={false}
            >
                <div style={{ padding: '20px' }}>
                    <div
                        style={{
                            fontWeight: 600,
                            fontSize: '28px',
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <span>Offer to Hire</span>
                    </div>

                    <div>
                        <Form
                            labelCol={{
                                style: {
                                    padding: '15px 0 3px',
                                },
                            }}
                            layout="vertical"
                            name="jobOfferForm"
                            onFinish={onFinishJobOffer}
                            requiredMark={false}
                            form={jobOfferForm}
                            style={{
                                marginBottom: '20px',
                                overflowX: 'hidden',
                            }}
                        >
                            <Row gutter={[18, 18]}>
                                <Col span={12}>
                                    <Form.Item
                                        name="budgetFrom"
                                        label="Budget From"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    'Please input your Budget From',
                                            },
                                        ]}
                                    >
                                        <Input
                                            placeholder="Budget From"
                                            addonAfter={selectAfterBudget(
                                                'budgetFromType'
                                            )}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="proposalStartDate"
                                        label="Proposal Start Date"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    'Please input your Proposal Start Date',
                                            },
                                        ]}
                                    >
                                        <DatePicker placeholder="Select Date" />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        name="agreement"
                                        valuePropName="checked"
                                        rules={[
                                            {
                                                validator: (_, value) =>
                                                    value
                                                        ? Promise.resolve()
                                                        : Promise.reject(
                                                              new Error(
                                                                  'Please Accept Terms of Service'
                                                              )
                                                          ),
                                            },
                                        ]}
                                    >
                                        <Checkbox>
                                            <span style={{ fontSize: '16px' }}>
                                                Yes, I understand and agree to
                                                the SoftwareCo terms of service,
                                                including the User Agreement and
                                                Privacy Policy
                                            </span>
                                        </Checkbox>
                                    </Form.Item>
                                </Col>

                                <Col
                                    span={24}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                    }}
                                >
                                    <Form.Item>
                                        <Button
                                            htmlType="submit"
                                            style={{
                                                width: '130px',
                                                height: '45px',
                                                fontSize: '20px',
                                                borderRadius: '40px',
                                            }}
                                        >
                                            Offer
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default JobOfferModel
