import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isLoading: false,
    list: [],
    success: false,
    error: null,
    pdfData: null,
    pdfSelectedData: null,
}

const reportsSlice = createSlice({
    name: 'repors',
    initialState,
    reducers: {
        downloadPdfData(state, action) {
            state.pdfData = action.payload.pdfData
            state.pdfSelectedData = action.payload.pdfSelectedData
        },
    },
})

export const {
    actions: reportsActions,
    reducer: reportsReducer,
    name: reportsKey,
} = reportsSlice
