import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// antd
import { Image, Input, Modal, Row, Tabs, Typography, theme } from 'antd'

// slices
import { changeTab, inquiryState } from './inquiries.slice'

// components
import Button from '../../components/button/button'

// assets
import InquiryTitleImage from '../../assets/images/masterData/projectsSetting.png'
import {
    InquiryAttachmentIcon,
    InquiryNotesIcon,
    InquiryRolesIcon,
    InquirySummaryIcon,
} from '../../assets/icons'

// tabs components
import AddInquiryForm from './tabs/addInquiryForm'
import AddInquiryAttachment from './tabs/attachments/addInquiryAttachment'
import AddInquiryNotes from './tabs/notes/addInquiryNotes'
import RoleView from './tabs/roles'

import dayjs from 'dayjs'
import ConvertToProjectForm from './converToProjectForm'

const InquiryDrawer = () => {
    const { useToken } = theme
    const { token } = useToken()

    const { Title, Text } = Typography

    const inquiryNameRef = useRef()

    const dispatch = useDispatch()

    const relativeTime = require('dayjs/plugin/relativeTime')
    dayjs.extend(relativeTime)

    const { selectedInquiry, defaultTab } = useSelector(inquiryState)

    const [inquiryValue, setInquiryValue] = useState('')
    const [projectOpen, setProjectOpen] = useState(false)

    useEffect(() => {
        if (selectedInquiry) {
            setInquiryValue(selectedInquiry?.inquiryName)
        } else {
            setInquiryValue('')
        }
    }, [selectedInquiry])

    const inquiryTab = [
        {
            label: (
                <Row
                    align={'middle'}
                    style={{
                        columnGap: '10px',
                    }}
                >
                    <InquirySummaryIcon />
                    <Title level={5}>Summary</Title>
                </Row>
            ),
            key: 'summary',
            children: (
                <AddInquiryForm
                    inquiryValue={inquiryValue}
                    setInquiry={() => setInquiryValue('')}
                />
            ),
        },
        {
            label: (
                <Row
                    align={'middle'}
                    style={{
                        columnGap: '10px',
                    }}
                >
                    <InquiryAttachmentIcon />
                    <Title level={5}>Attachment</Title>
                </Row>
            ),
            key: 'attachment',
            children: <AddInquiryAttachment keyTab="attachment" />,
            disabled: selectedInquiry === undefined,
        },
        {
            label: (
                <Row
                    align={'middle'}
                    style={{
                        columnGap: '10px',
                    }}
                >
                    <InquiryNotesIcon />
                    <Title level={5}>Notes</Title>
                </Row>
            ),
            key: 'notes',
            children: <AddInquiryNotes />,
            disabled: selectedInquiry === undefined,
        },
        {
            label: (
                <Row
                    align={'middle'}
                    style={{
                        columnGap: '10px',
                    }}
                >
                    <InquiryRolesIcon />
                    <Title level={5}>Roles</Title>
                </Row>
            ),
            key: 'roles',
            children: <RoleView keyTab="roles" />,
            disabled:
                selectedInquiry === undefined ||
                (selectedInquiry && selectedInquiry?.isConvertedToProject),
        },
        // {
        //     label: (
        //         <Row
        //             align={'middle'}
        //             style={{
        //                 columnGap: '10px',
        //             }}
        //         >
        //             <div
        //                 style={{
        //                     height: '20px',
        //                     width: '20px',
        //                     cursor: 'pointer',
        //                 }}
        //             >
        //                 <InquiryChatIcon />
        //             </div>
        //             <Title level={5}>Chat</Title>
        //         </Row>
        //     ),
        //     key: 'chat',
        //     children: <ChatModule />,
        //     disabled: selectedInquiry === undefined,
        // },
    ]

    useEffect(() => {
        if (inquiryNameRef?.current !== undefined) {
            inquiryNameRef?.current?.focus()
        }
    }, [])

    return (
        <>
            <div
                style={{
                    backgroundColor: token.colorPalette.baseColor.quaternary,
                    padding: '22px 30px',
                }}
            >
                <Row justify={'space-between'} align={'middle'}>
                    <Row
                        style={{
                            columnGap: '10px',
                        }}
                    >
                        <Image
                            src={InquiryTitleImage}
                            height={'30px'}
                            alt="inquiryImage"
                            width={'30px'}
                            preview={false}
                        />
                        <div>
                            <Input
                                autoFocus
                                placeholder="Enter Inquiry Name..."
                                variant="borderless"
                                ref={inquiryNameRef}
                                defaultValue={inquiryValue}
                                value={inquiryValue}
                                onChange={(e) => {
                                    const text = e.target.value.trimStart()
                                    if (text?.length > 130) {
                                        return
                                    } else {
                                        setInquiryValue(text)
                                    }
                                }}
                                style={{
                                    paddingLeft: '0px',
                                }}
                            />
                            {inquiryValue?.length > 0 && (
                                <>
                                    <Text
                                        style={{
                                            color: token.colorPalette.textColor
                                                .secondary,
                                        }}
                                    >
                                        Date:{' '}
                                        {dayjs(
                                            selectedInquiry?.createdAt
                                        ).format('DD/MM/YYYY')}
                                    </Text>
                                    &nbsp;&nbsp;
                                    <Text
                                        style={{
                                            color: token.colorPalette.textColor
                                                .secondary,
                                        }}
                                    >
                                        {dayjs(
                                            selectedInquiry?.createdAt
                                        ).fromNow()}
                                    </Text>
                                </>
                            )}
                        </div>
                    </Row>
                    {selectedInquiry &&
                        !selectedInquiry?.isConvertedToProject && (
                            <Row
                                align={'middle'}
                                style={{
                                    columnGap: '20px',
                                }}
                            >
                                <Button
                                    props={{
                                        text: 'Convert to Project',
                                        onClick: () => {
                                            setProjectOpen(true)
                                        },
                                        // disabled: true,
                                    }}
                                />
                            </Row>
                        )}
                </Row>
            </div>
            <div
                style={{
                    padding: '11px 30px',
                }}
            >
                <Tabs
                    items={inquiryTab}
                    rootClassName="generalTabs"
                    defaultActiveKey={defaultTab}
                    activeKey={defaultTab}
                    onChange={(activeKey) => {
                        dispatch(changeTab(activeKey))
                    }}
                />
            </div>
            <Modal
                centered
                footer={null}
                closable={false}
                open={projectOpen}
                destroyOnClose={true}
                width={700}
                styles={{
                    body: {
                        padding: '25px',
                    },
                }}
                onCancel={async () => {
                    setProjectOpen(false)
                }}
            >
                <Row
                    align={'middle'}
                    justify={'center'}
                    style={{
                        marginBottom: '27px',
                    }}
                >
                    <Title level={4} style={{ margin: 0 }}>
                        {`Convert to Project`}
                    </Title>
                </Row>
                <ConvertToProjectForm onCancel={() => setProjectOpen(false)} />
            </Modal>
        </>
    )
}

export default InquiryDrawer
