import React from 'react'

function NoNotesIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 293 180"
        >
            <g clipPath="url(#clip0_4626_17299)">
                <path
                    fill="#E6E6E6"
                    d="M273.575 161.72a8.58 8.58 0 01-8.29-1.461c-2.904-2.437-3.814-6.451-4.555-10.169l-2.191-10.996 4.587 3.158c3.299 2.271 6.672 4.616 8.956 7.906s3.28 7.781 1.446 11.341"
                ></path>
                <path
                    fill="#F2F2F2"
                    d="M272.866 175.799c.578-4.206 1.172-8.467.766-12.718-.36-3.776-1.512-7.463-3.857-10.487a17.448 17.448 0 00-4.476-4.056c-.448-.283-.86.427-.414.708a16.593 16.593 0 016.561 7.915c1.428 3.633 1.657 7.593 1.411 11.452-.148 2.334-.464 4.652-.782 6.968a.424.424 0 00.287.504.412.412 0 00.504-.286z"
                ></path>
                <path
                    fill="#E6E6E6"
                    d="M268.711 169.053a6.325 6.325 0 01-5.507 2.843c-2.788-.133-5.112-2.078-7.203-3.925l-6.188-5.463 4.095-.196c2.945-.141 5.966-.274 8.771.636 2.805.909 5.392 3.093 5.905 5.997"
                ></path>
                <path
                    fill="#F2F2F2"
                    d="M274.499 178.22c-2.78-4.918-6.004-10.384-11.764-12.131a13.121 13.121 0 00-4.948-.511c-.526.045-.394.855.13.81a12.197 12.197 0 017.896 2.089c2.227 1.515 3.96 3.622 5.427 5.856.899 1.369 1.704 2.796 2.509 4.221.258.455 1.01.126.75-.334z"
                ></path>
                <path
                    fill="#fff"
                    d="M175.222 53.36v46.827H67.065v24.864H38.472V53.359h136.75z"
                ></path>
                <path
                    fill="#E5E5E5"
                    d="M37.227 52.117v74.178h29.838v-2.486H39.712V54.602H173.98v45.584h2.485V52.117H37.226z"
                ></path>
                <path
                    fill="#E5E5E5"
                    d="M153.818 79.894H67.134v3.06h86.684v-3.06zM153.818 87.852H67.134v3.06h86.684v-3.06zM153.818 95.809H67.134v3.06h86.684v-3.06zM61.872 83.537a1.997 1.997 0 100-3.994 1.997 1.997 0 000 3.994z"
                ></path>
                <path
                    fill="#fff"
                    d="M137.995 1.242V48.07H29.838v24.864H1.244V1.242h136.751z"
                ></path>
                <path
                    fill="#F1F1F1"
                    d="M0 0v74.177h29.838v-2.485H2.485V2.485h134.269V48.07h2.485V0H0z"
                ></path>
                <path
                    fill="#F1F1F1"
                    d="M116.591 27.777H29.907v3.06h86.684v-3.06zM116.591 35.734H29.907v3.06h86.684v-3.06zM116.591 43.691H29.907v3.06h86.684v-3.06zM24.645 31.42a1.997 1.997 0 100-3.994 1.997 1.997 0 000 3.994z"
                ></path>
                <path
                    fill="#fff"
                    d="M211.128 106.156H74.375v71.692h136.753v-71.692z"
                ></path>
                <path
                    fill="#CBCBCB"
                    d="M212.37 179.088H73.13V104.91h139.24v74.178zm-136.753-2.486h134.267v-69.205H75.617v69.205z"
                ></path>
                <path
                    fill="#4F46E5"
                    d="M189.573 132.805h-86.684v3.06h86.684v-3.06zM189.573 140.758h-86.684v3.06h86.684v-3.06zM189.573 148.715h-86.684v3.06h86.684v-3.06zM97.627 136.447a1.997 1.997 0 100-3.994 1.997 1.997 0 000 3.994z"
                ></path>
                <path
                    fill="#9F616A"
                    d="M190.97 104.914a3.575 3.575 0 01.167-4.347 3.576 3.576 0 011.224-.946l9.89-30.969 6.888 3.579-11.888 29.315a3.59 3.59 0 01-1.644 4.474 3.59 3.59 0 01-4.637-1.106z"
                ></path>
                <path
                    fill="#4F46E5"
                    d="M199.05 72.936a1.598 1.598 0 01.007-1.333l3.388-7.283a4.445 4.445 0 018.53 2.5l-1.132 7.979a1.598 1.598 0 01-2.039 1.31l-7.75-2.297a1.61 1.61 0 01-1.004-.876z"
                ></path>
                <path
                    fill="#9F616A"
                    d="M227.623 74.164l-.552 7.736-18.786 6.63-.276-7.46 19.614-6.906z"
                ></path>
                <path
                    fill="#4F46E5"
                    d="M203.589 62.839l6.077-4.42 9.946-2.763 8.287 1.658 1.382 12.984-1.658 8.288-21.548 4.972-2.21-3.867s-4.973-4.973-1.105-10.498l.829-6.354z"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M201.379 51.493v-7.255a11.571 11.571 0 0111.573-11.574 11.572 11.572 0 0111.574 11.574v7.255a1.556 1.556 0 01-1.555 1.554h-20.037a1.555 1.555 0 01-1.555-1.554z"
                ></path>
                <path
                    fill="#9F616A"
                    d="M212.487 52.974a8.485 8.485 0 10.001-16.97 8.485 8.485 0 00-.001 16.97z"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M203.769 44.459a.872.872 0 01-.203-.689l1.006-7a.858.858 0 01.488-.662c5.131-2.401 10.333-2.404 15.464-.01a.876.876 0 01.493.704l.671 7.009a.855.855 0 01-.22.663.866.866 0 01-.639.283h-1.702a.87.87 0 01-.783-.499l-.734-1.573a.522.522 0 00-.55-.293.519.519 0 00-.434.448l-.145 1.16a.863.863 0 01-.857.757h-11.203a.861.861 0 01-.652-.298z"
                ></path>
                <path
                    fill="#9F616A"
                    d="M240.603 101.302a3.573 3.573 0 01-2.76-3.362 3.558 3.558 0 01.287-1.52l-13.158-29.727 7.527-1.896 10.567 29.817a3.59 3.59 0 01.066 6.362 3.59 3.59 0 01-2.529.326z"
                ></path>
                <path
                    fill="#4F46E5"
                    d="M225.42 72.02a1.597 1.597 0 01-.878-1.002l-2.301-7.696a4.446 4.446 0 018.04-3.792l4.449 6.72a1.606 1.606 0 01.206 1.316 1.592 1.592 0 01-.862 1.016l-7.321 3.427a1.597 1.597 0 01-1.333.01z"
                ></path>
                <path
                    fill="#9F616A"
                    d="M226.678 173.852l-4.789-.001-2.279-18.472h7.068v18.473z"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M227.899 178.495h-15.442v-.195c0-1.595.633-3.123 1.761-4.25a6.007 6.007 0 014.25-1.761h9.432l-.001 6.206z"
                ></path>
                <path
                    fill="#9F616A"
                    d="M223.038 134.852l-3.265 3.503-15.068-10.926 4.819-5.171 13.514 12.594z"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M227.267 137.122l-10.528 11.297-.143-.133a6.01 6.01 0 01-.299-8.495l6.43-6.9 4.54 4.231z"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M228.176 81.899l1.532 1.532c7.661 7.661 9.547 18.284 8.627 29.08a30.259 30.259 0 01-.766 4.749c-2.763 11.051-1.105 46.412-6.631 48.622l-1.105 3.867-10.774-.398.277-3.469s-2.487-5.249-.829-8.564c1.657-3.315 5.249-27.902 5.249-27.902l-1.934-20.167-14.366 7.182s7.459 4.144 6.907 6.907c0 0 4.42 1.105 3.315 3.867l1.658 3.316-3.868 6.077s-25.968-15.47-23.758-23.206c2.21-7.735 16.023-13.26 16.023-13.26l1.105-8.288-.953-4.516 19.329-7.43.962 2.001z"
                ></path>
                <path
                    fill="#CBCBCB"
                    d="M292.633 179.092H135.216a.354.354 0 110-.709h157.417a.35.35 0 01.25.104.352.352 0 01-.25.605z"
                ></path>
            </g>
            <defs>
                <clipPath id="clip0_4626_17299">
                    <path fill="#fff" d="M0 0H292.987V179.09H0z"></path>
                </clipPath>
            </defs>
        </svg>
    )
}

export default NoNotesIcon
