import React from 'react'

function SummaryIcon({ color }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="35"
            fill="none"
            viewBox="0 0 30 35"
        >
            <g
                fill={color ? color : '#B9B9B9'}
                fillRule="evenodd"
                clipRule="evenodd"
            >
                <path d="M6.482 19.19h16.204V15.7H6.482v3.49z"></path>
                <path d="M3.24 31.402h22.685v-19.19H3.24v19.19zM25.926 3.489h-1.62V0h-3.241v3.49H8.102V0h-3.24v3.49H3.24C1.45 3.49.016 5.05.016 6.977L0 31.402c0 1.928 1.45 3.489 3.24 3.489h22.686c1.79 0 3.24-1.561 3.24-3.49V6.979c0-1.928-1.45-3.489-3.24-3.489z"></path>
                <path d="M6.482 26.168h11.343V22.68H6.482v3.49z"></path>
            </g>
        </svg>
    )
}

export default SummaryIcon
