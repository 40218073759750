import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// antd
import {
    Avatar,
    Select,
    Space,
    Typography,
    Spin,
    Image,
    theme,
    Row,
    Col,
} from 'antd'

// slices
import { changePage, teamListGet, teamState } from '../team/team.slice'
import { loginState } from '../login/login.slice'
import { setFilterUser, standUpGet, standUpsState } from './standups.slice'

// helpers
import { unique } from '../../helpers/uniqueArray'

import styles from './standUps.module.css'

const AssigneeFilter = () => {
    const { Text } = Typography

    const { Option } = Select

    const { useToken } = theme
    const { token } = useToken()

    const dispatch = useDispatch()

    const { pageLimit, skipPage, isNext, search, currentPage, teamList } =
        useSelector(teamState)
    const { userProfile } = useSelector(loginState)
    const { timeStamp, pageLimitStandUps, filterUserId } =
        useSelector(standUpsState)

    const [optionLoading, setOptionLoading] = useState(false)
    const [filteredTeam, setFilteredTeam] = useState([])

    useEffect(() => {
        if (userProfile?.activeProject !== null) {
            dispatch(
                teamListGet({
                    limit: pageLimit,
                    skip: skipPage,
                    search: search,
                    projectId: userProfile?.activeProject?._id,
                })
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // CHECK FOR LAZY LOADING IS_NEXT
    useEffect(() => {
        if (teamList?.length > 0) {
            let array = []
            teamList?.length > 0 &&
                teamList.forEach((data) => {
                    if (userProfile?._id !== data?._id) {
                        array = [
                            ...array,
                            {
                                label: data?.name,
                                value: data?._id,
                                profilePicture: data?.profilePicture,
                            },
                        ]
                    }
                })

            const newUser = {
                label: userProfile?.name,
                value: userProfile?._id,
                profilePicture: userProfile?.profilePicture,
            }

            // Add new user to the array
            array.push(newUser)
            let newArray = [...filteredTeam, ...array]
            let finalArray = unique(newArray, 'value')
            setFilteredTeam(finalArray)
            if (!isNext) {
                return
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [teamList])

    // ON SCROLL TEAM MEMBER LIST
    const onScroll = async (e) => {
        let scroll =
            Math.ceil(e.target.offsetHeight + e.target.scrollTop) >=
            e.target.scrollHeight
        if (scroll) {
            if (isNext) {
                setOptionLoading(true)
                dispatch(
                    teamListGet({
                        skip: skipPage + pageLimit,
                        limit: pageLimit,
                    })
                )
                dispatch(
                    changePage({
                        currentPage: currentPage + 1,
                        skip: skipPage + pageLimit,
                        pageLimit: pageLimit,
                    })
                )
                setOptionLoading(false)
            }
        }
    }

    return (
        <Row className="standupSelect">
            <Col flex={'auto'}>
                <Select
                    placeholder="Select a Member"
                    onChange={(userId) => {
                        dispatch(setFilterUser(userId))
                        dispatch(
                            standUpGet({
                                limit: pageLimitStandUps,
                                skip: 0,
                                titleType: 'standup',
                                projectId: userProfile?.activeProject?._id,
                                userId: userId,
                                ...timeStamp,
                            })
                        )
                    }}
                    notFoundContent={
                        optionLoading ? <Spin size="small" /> : null
                    }
                    onClear={() => dispatch(setFilterUser(null))}
                    className={[styles.filterSelectStandup, 'arrowTaskSelect']}
                    allowClear={true}
                    onPopupScroll={onScroll}
                    style={{
                        minWidth: '200px',
                    }}
                    dropdownStyle={{
                        zIndex: '0',
                    }}
                    value={filterUserId}
                    getPopupContainer={() => document.body}
                >
                    {filteredTeam?.length > 0 &&
                        filteredTeam.map((option) => {
                            return (
                                <Option
                                    style={{
                                        textTransform: 'capitalize',
                                    }}
                                    key={option?.value}
                                    value={option?.value}
                                >
                                    <Space size="small">
                                        {option?.profilePicture ? (
                                            <Image
                                                src={option?.profilePicture}
                                                height={'24px'}
                                                width={'24px'}
                                                preview={false}
                                                style={{
                                                    borderRadius: '50%',
                                                }}
                                            />
                                        ) : (
                                            <Avatar
                                                style={{
                                                    cursor: 'pointer',
                                                    backgroundColor:
                                                        'rgb(0 10 129 / 25%)',
                                                    fontSize: '14px',
                                                }}
                                                size={24}
                                            >
                                                {option?.label[0].toUpperCase()}
                                                {option?.label.split(' ')[1] !==
                                                    undefined &&
                                                    option?.label.split(
                                                        ' '
                                                    )[1] !== '' &&
                                                    option?.label
                                                        .split(' ')[1][0]
                                                        .toUpperCase()}
                                            </Avatar>
                                        )}
                                        <Text
                                            style={{
                                                margin: 0,
                                                color: token.colorPalette
                                                    .textColor.primary,
                                            }}
                                        >
                                            {option?.label}
                                        </Text>
                                    </Space>
                                </Option>
                            )
                        })}
                </Select>
            </Col>
        </Row>
    )
}

export default AssigneeFilter
