export const RECRUIT_STATUS = [
    {
        label: 'Applied',
        value: 'applied',
        key: 'applied',
    },
    {
        label: 'Phone Screen',
        value: 'phoneScreen',
        key: 'phoneScreen',
    },
    {
        label: 'Profile Completed',
        value: 'profileCompleted',
        key: 'profileCompleted',
    },
    {
        label: 'Technical Round',
        value: 'technicalRound',
        key: 'technicalRound',
    },
    {
        label: 'Assessment',
        value: 'assessment',
        key: 'assessment',
    },
    {
        label: 'Accepted',
        value: 'accepted',
        key: 'accepted',
    },
    {
        label: 'Rejected',
        value: 'rejected',
        key: 'rejected',
    },
]
