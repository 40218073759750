import React from 'react'

function LowPriorityIcon() {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M12 2L2 22h20L12 2z" fill="#2A9D8F" />
            <path d="M12 8v6" stroke="#fff" stroke-width="2" />
            <circle cx="12" cy="17" r="1" fill="#fff" />
        </svg>
    )
}

export default LowPriorityIcon
