import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { getChatUserData, getMessage } from './chat.service'

const initialState = {
    selectChat: {},
    message: [],
}

export const chatUserDataGet = createAsyncThunk(
    'chatSlice/getChatUserData',
    async (payload = {}) => {
        const response = await getChatUserData(payload)
        return response
    }
)

export const chatMessageGet = createAsyncThunk(
    'chatSlice/getMessage',
    async (payload = {}) => {
        const response = await getMessage(payload)
        return response
    }
)

export const chatSlice = createSlice({
    name: 'chatSlice',
    initialState,
    reducers: {
        changeSelectChat: (state, action) => {
            state.selectChat = action.payload
        },
        sendMessageActionClear: (state, action) => {
            state.message = []
        },
    },
    extraReducers: (builder) => {},
})

export default chatSlice.reducer

export const inquiryState = (state) => {
    const { chatSlice } = state
    const { selectChat, message } = chatSlice
    return { selectChat, message }
}

export const { changeSelectChat, sendMessageActionClear } = chatSlice.actions
