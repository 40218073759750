import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'

// assets
import RegistrationVideo from '../../assets/videos/registration_video.mp4'
import Logo from '../../assets/images/logo.png'

// css
import './login.css'

const LoginPage = (props) => {
    useEffect(() => {
        window.localStorage.clear()
    }, [])

    return (
        <div className={`main-div`}>
            <video
                src={RegistrationVideo}
                // src="https://pms-bucket123.s3.amazonaws.com/production_id_4053002%20(2160p)_1691992100.mp4"
                autoPlay
                loop
                style={{
                    objectFit: 'cover',
                    position: 'relative',
                }}
                width={'66%'}
                height={'100%'}
                muted
            />
            <img
                style={{
                    position: 'absolute',
                    top: '34px',
                    left: '60px',
                    objectFit: 'contain',
                }}
                src={Logo}
                alt="logo"
                height={'45px'}
            />
            <div className="background-img">
                <Outlet />
            </div>
        </div>
    )
}

export default LoginPage
