import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

// antd
import {
    Avatar,
    Badge,
    Col,
    Divider,
    Image,
    Popover,
    Row,
    Space,
    Typography,
    message,
    theme,
    Button as ButtonAnt,
} from 'antd'
import {
    InfoCircleOutlined,
    PlayCircleOutlined,
    PauseOutlined,
    LogoutOutlined,
} from '@ant-design/icons'

// slices
import { isLoggedInLogin, loginState } from '../../modules/login/login.slice'
import { setGlobalTimer, tasksState } from '../../modules/tasks/tasks.slice'

// constants
import {
    DOMAIN_LIST,
    MEMBER,
    SUPER_ADMIN,
    WORKSPACE_ADMIN,
} from '../../constants/roles'

// assets
import { NotificationIcon, SettingsIcon } from '../../assets/icons'

// routes
import { PRIVATE_ROUTES, PUBLIC_ROUTES } from '../../routes'

// modules
import { startStopTimer } from '../../modules/tasks/tasks.service'
import { notificationsGet } from '../../modules/notifications/notifications.slice'

// css
import styles from '../../layouts/layouts.module.css'

import axios from 'axios'
import { useState } from 'react'
import Button from '../../components/button/button'
import { FEEDBACK_LINK } from '../../constants/general'
import { formatTitle } from '../../helpers/formatTitle'
import AddJiraApiTokenModal from './AddJiraApiTokenModal'

const HeaderRightSection = ({ props }) => {
    const { state, setState } = props

    const { useToken } = theme

    const { token } = useToken()

    const { Text } = Typography

    const navigate = useNavigate()

    const dispatch = useDispatch()

    const { userProfile } = useSelector(loginState)
    const { globalTimer } = useSelector(tasksState)

    const [loadingButton, setLoadingButton] = useState(false)
    const [openJiraTokenModal, setOpenJiraTokenModal] = useState(false)

    // OPEN PROFILE POPOVER
    const handleOpenProfile = (newOpen) => {
        setState({ ...state, openProfile: newOpen })
    }

    // START TIMER
    const handleStartTimer = async () => {
        try {
            const res = await startStopTimer({
                id: globalTimer?.lastTask.taskId?._id,
                data: { type: 'start' },
            })

            if (res?.data?.success) {
                const checkLogin = await dispatch(isLoggedInLogin())
                const checkSuccessLogin = checkLogin?.payload?.data
                const { success: checkSuccess, data } = checkSuccessLogin
                if (checkSuccess) {
                    dispatch(setGlobalTimer(data?.runningTask))
                }
            } else {
                message.error(res?.data?.message)
            }
        } catch (error) {
            message.error(error)
        }
    }

    // SUPER ADMIN LOGIN
    const adminLogin = async () => {
        setLoadingButton(true)
        if (window.location.hostname === 'localhost') {
            try {
                const response = await axios({
                    url: `http://localhost:5000/workspace/user-workspace-login?workSpaceId=${userProfile?.backToAdmin?.workspaceId}&userId=${userProfile?.backToAdmin?.userId}`,
                    method: 'get',
                })
                if (response?.data?.success) {
                    setLoadingButton(false)
                    navigate('/')
                    window.location.reload()
                }
            } catch (error) {
                setLoadingButton(false)
                message.error(error?.response?.data?.message)
            }
        } else {
            const response = await axios({
                url: `https://${userProfile?.currentWorkSpace?.domain}/backend/workspace/user-workspace-login?workSpaceId=${userProfile?.backToAdmin?.workspaceId}&userId=${userProfile?.backToAdmin?.userId}`,
                method: 'get',
            })
            if (response?.data?.success) {
                setLoadingButton(false)
                navigate('/')
                window.location.reload()
            } else {
                setLoadingButton(false)
                message.error(response?.data?.message)
            }
        }
    }

    const pauseContent = (
        <Row
            style={{
                gap: 10,
            }}
            align={'middle'}
        >
            <InfoCircleOutlined
                style={{
                    color: '#ffd713',
                }}
            />
            <Text
                style={{
                    fontWeight: 500,
                }}
            >
                If you want to pause the timer, Please pause it from the Time
                Tracking App
            </Text>
        </Row>
    )

    const handleAddModel = () => {
        setOpenJiraTokenModal(true)
    }

    return (
        <>
            <Row style={{ gap: '20px' }} align={'middle'}>
                {((userProfile?.role?.roleName === MEMBER &&
                    userProfile?.activeProject !== null) ||
                    (userProfile?.role?.roleName !== SUPER_ADMIN &&
                        userProfile?.role?.roleName !== MEMBER &&
                        userProfile?.activeProject !== null)) && (
                    <Row
                        style={{
                            height: '40px',
                            background: '#fff',
                            borderRadius: 6,
                            padding: '0px 10px 0px 10px',
                            backgroundColor: '#001529',
                            color: '#fff',
                        }}
                    >
                        {globalTimer?.lastTask === null ||
                        globalTimer?.lastTask === undefined ||
                        globalTimer?.lastTask?.taskId === null ? (
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    height: 'inherit',
                                    gap: 10,
                                }}
                            >
                                <Text
                                    style={{
                                        color: '#fff',
                                        margin: 0,
                                    }}
                                >
                                    Select a task to work upon from your sprint
                                    or task board
                                </Text>
                                <Popover
                                    getPopupContainer={(trigger) =>
                                        trigger.parentNode
                                    }
                                    content={
                                        <Text
                                            style={{
                                                cursor: 'pointer',
                                                textDecoration: 'underline',
                                                color: 'blue',
                                            }}
                                            onClick={() =>
                                                navigate(
                                                    PUBLIC_ROUTES.timeTrackingApp
                                                )
                                            }
                                        >
                                            Time-Tracking App
                                        </Text>
                                    }
                                    trigger={'hover'}
                                >
                                    <InfoCircleOutlined
                                        style={{
                                            fontSize: '16px',
                                        }}
                                    />
                                </Popover>
                            </div>
                        ) : (
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    height: 'inherit',
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        columnGap: 10,
                                        alignItems: 'center',
                                    }}
                                >
                                    {globalTimer?.currentStatus === 'off' ? (
                                        <PlayCircleOutlined
                                            onClick={() => {
                                                handleStartTimer()
                                            }}
                                        />
                                    ) : (
                                        <Popover
                                            content={pauseContent}
                                            trigger={'click'}
                                            getPopupContainer={(trigger) =>
                                                trigger.parentNode
                                            }
                                        >
                                            <PauseOutlined />
                                        </Popover>
                                    )}
                                    {globalTimer?.currentStatus === 'on' ? (
                                        <div
                                            style={{
                                                height: 10,
                                                width: 10,
                                                backgroundColor: '#1ed31e',
                                                borderRadius: '50%',
                                            }}
                                        />
                                    ) : (
                                        <div
                                            style={{
                                                height: 10,
                                                width: 10,
                                                backgroundColor: 'red',
                                                borderRadius: '50%',
                                            }}
                                        />
                                    )}
                                    <Text
                                        style={{
                                            color: '#fff',
                                        }}
                                    >
                                        {formatTitle(
                                            globalTimer?.lastTask?.taskId?.title
                                        )}
                                    </Text>
                                    -
                                    <Text
                                        style={{
                                            color: '#fff',
                                        }}
                                    >
                                        {
                                            globalTimer?.lastTask?.taskId
                                                ?.projectId?.name
                                        }
                                    </Text>
                                    <Text
                                        style={{
                                            color: '#fff',
                                        }}
                                    >
                                        {state?.hours
                                            .toString()
                                            .padStart(2, '0')}
                                        :
                                        {state?.minutes
                                            .toString()
                                            .padStart(2, '0')}
                                        :
                                        {state?.seconds
                                            .toString()
                                            .padStart(2, '0')}
                                    </Text>
                                </div>
                            </div>
                        )}
                    </Row>
                )}
                {((userProfile?.role?.roleName === MEMBER &&
                    userProfile?.activeProject !== null) ||
                    (userProfile?.role?.roleName !== MEMBER &&
                        userProfile?.role?.roleName !== SUPER_ADMIN &&
                        userProfile?.activeProject !== null)) && (
                    <Badge
                        count={0}
                        dot={userProfile?.notificationCount > 0 ? true : false}
                        offset={[-5, 7]}
                    >
                        <div
                            style={{
                                height: '34px',
                                width: '34px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                cursor: 'pointer',
                            }}
                            onClick={() => {
                                dispatch(
                                    notificationsGet({
                                        userId: userProfile?._id,
                                        unRead: false,
                                    })
                                )
                                setState({ ...state, openDrawer: true })
                            }}
                        >
                            <NotificationIcon />
                        </div>
                    </Badge>
                )}
                {((DOMAIN_LIST.includes(
                    userProfile?.currentWorkSpace?.domain
                ) &&
                    userProfile?.role?.roleName === SUPER_ADMIN) ||
                    !DOMAIN_LIST.includes(
                        userProfile?.currentWorkSpace?.domain
                    )) && (
                    <>
                        <Button
                            props={{
                                text: 'Add JIRA Token',
                                onClick: () => {
                                    handleAddModel()
                                },
                                style: {
                                    textTransform: 'capitalize',
                                    height: '40px',
                                    lineHeight: '0px',
                                },
                            }}
                        />

                        <Button
                            props={{
                                text: 'Feedback',
                                onClick: () => {
                                    window.open(FEEDBACK_LINK, '_blank')
                                },
                                style: {
                                    textTransform: 'capitalize',
                                    height: '40px',
                                    lineHeight: '0px',
                                },
                            }}
                        />
                    </>
                )}
                {(userProfile?.role?.roleName === SUPER_ADMIN ||
                    (userProfile?.role?.roleName === WORKSPACE_ADMIN &&
                        userProfile?.activeProject !== null)) && (
                    <div
                        style={{
                            height: '34px',
                            width: '34px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            navigate(PRIVATE_ROUTES.globalSettings.root)
                        }}
                    >
                        <SettingsIcon
                            color={token.colorPalette.baseColor.black}
                        />
                    </div>
                )}

                {userProfile?.backToAdmin && (
                    <ButtonAnt
                        style={{
                            height: '48px',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                        loading={loadingButton}
                        disabled={loadingButton}
                        onClick={() => adminLogin()}
                    >
                        Back to SuperAdmin
                    </ButtonAnt>
                )}
                <Popover
                    overlayInnerStyle={{
                        padding: 0,
                    }}
                    getPopupContainer={(trigger) => trigger.parentNode}
                    open={state?.openProfile}
                    onOpenChange={handleOpenProfile}
                    content={
                        <>
                            <Row
                                style={{
                                    padding: 12,
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    if (
                                        userProfile?.role?.roleName !==
                                            SUPER_ADMIN &&
                                        userProfile?.role?.roleName !==
                                            WORKSPACE_ADMIN &&
                                        DOMAIN_LIST.includes(
                                            userProfile?.currentWorkSpace
                                                ?.domain
                                        )
                                    ) {
                                        setState({
                                            ...state,
                                            openProfile: false,
                                        })
                                        navigate(PRIVATE_ROUTES.profile)
                                    }
                                }}
                            >
                                <Space size={'middle'}>
                                    <Col>
                                        {userProfile?.profilePicture ? (
                                            <>
                                                <Image
                                                    src={
                                                        userProfile?.profilePicture
                                                    }
                                                    height={40}
                                                    width={40}
                                                    rootClassName="headerImage"
                                                    style={{
                                                        borderRadius: '50%',

                                                        cursor: 'pointer',
                                                    }}
                                                    preview={false}
                                                />
                                            </>
                                        ) : (
                                            <Avatar
                                                size={'large'}
                                                style={{
                                                    backgroundColor:
                                                        'rgb(0 10 129 / 25%)',
                                                }}
                                            >
                                                {userProfile?.name
                                                    ? `${userProfile?.name[0].toUpperCase()}${
                                                          userProfile?.name.split(
                                                              ' '
                                                          )[1] !== undefined
                                                              ? userProfile?.name
                                                                    .split(
                                                                        ' '
                                                                    )[1][0]
                                                                    .toUpperCase()
                                                              : ''
                                                      }`
                                                    : ''}
                                            </Avatar>
                                        )}
                                    </Col>
                                    <Col>
                                        <Row>
                                            <Text>{userProfile?.name}</Text>
                                            &nbsp;
                                            <Text
                                                style={{
                                                    textTransform: 'capitalize',
                                                    fontWeight: 500,
                                                }}
                                            >
                                                ({userProfile?.role?.roleName})
                                            </Text>
                                        </Row>
                                        <Text
                                            style={{
                                                fontSize: '12px',
                                            }}
                                        >
                                            {userProfile?.email}
                                        </Text>
                                    </Col>
                                </Space>
                            </Row>
                            <Divider
                                style={{
                                    margin: 0,
                                }}
                            />
                            <div
                                style={{
                                    padding: '5px',
                                }}
                                onClick={() => {
                                    setState({
                                        ...state,
                                        openProfile: false,
                                        deleteModal: true,
                                    })
                                }}
                            >
                                <div className={styles.logoutCard}>
                                    <Row
                                        align={'middle'}
                                        style={{
                                            gap: 10,
                                        }}
                                    >
                                        <LogoutOutlined
                                            style={{
                                                fontSize: '19px',
                                            }}
                                        />
                                        <Text
                                            style={{
                                                fontSize: '15px',
                                            }}
                                        >
                                            Log out
                                        </Text>
                                    </Row>
                                </div>
                            </div>
                        </>
                    }
                    trigger="click"
                    arrow={false}
                    placement="bottomLeft"
                >
                    {userProfile?.profilePicture ? (
                        <Image
                            src={userProfile?.profilePicture}
                            height={36}
                            className="headerImage"
                            width={36}
                            style={{
                                borderRadius: '50%',
                                verticalAlign: 'baseline !important',
                                cursor: 'pointer',
                            }}
                            preview={false}
                        />
                    ) : (
                        <Avatar
                            size={'large'}
                            style={{
                                cursor: 'pointer',
                                backgroundColor: 'rgb(0 10 129 / 25%)',
                            }}
                        >
                            {userProfile?.name[0].toUpperCase()}
                            {userProfile?.name.split(' ')[1] !== undefined &&
                                userProfile?.name
                                    .split(' ')[1][0]
                                    .toUpperCase()}
                        </Avatar>
                    )}
                </Popover>
            </Row>

            <AddJiraApiTokenModal
                open={openJiraTokenModal}
                setOpen={setOpenJiraTokenModal}
                userProfile={userProfile}
            />
        </>
    )
}

export default HeaderRightSection
