export const projectType = [
    {
        label: 'Fixed Cost',
        value: 'Fixed Cost',
    },
    {
        label: 'Dedicated Resource',
        value: 'Dedicated Resource',
    },
    {
        label: 'Hourly Basis',
        value: 'Hourly Basis',
    },
]

export const projectProfilePictures = [
    'https://pms-bucket123.s3.amazonaws.com/10411_1695881013.png',
    'https://pms-bucket123.s3.amazonaws.com/10417_1695881013.png',
    'https://pms-bucket123.s3.amazonaws.com/Page-1+%283%29_1695881013.png',
    'https://pms-bucket123.s3.amazonaws.com/Page-1+%282%29_1695881013.png',
    'https://pms-bucket123.s3.amazonaws.com/yeti_1695881013.png',
    'https://pms-bucket123.s3.amazonaws.com/project_avatar_coffee_1695881013.png',
    'https://pms-bucket123.s3.amazonaws.com/Page-1+%281%29_1695881013.png',
    'https://pms-bucket123.s3.amazonaws.com/Page-1_1695881013.png',
]
