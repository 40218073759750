import React from 'react'

function AddMoreIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="5"
            height="10"
            fill="none"
            viewBox="0 0 5 10"
        >
            <path fill="#4F46E5" d="M0 10l5-5-5-5v10z"></path>
        </svg>
    )
}

export default AddMoreIcon
