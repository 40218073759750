import React from 'react'

function NoCertificatesIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 416 500"
        >
            <g clipPath="url(#clip0_1474_3686)">
                <path
                    fill="#FFB8B8"
                    d="M2.966 239.143a20.31 20.31 0 0014.234 9.622 20.309 20.309 0 0016.515-4.735l62.592 35.899-2.332-37.424-59.081-28.352a20.417 20.417 0 00-27.064-1.686 20.412 20.412 0 00-4.864 26.676z"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M184.177 382.953C165.611 408.94 166.275 452.417 171.455 500h87.785l3.817-8.906 5.089 8.906h82.696s-6.362-120.863-17.812-124.68c-11.45-3.817-148.853 7.633-148.853 7.633z"
                ></path>
                <path
                    fill="#FFB8B8"
                    d="M259.24 111.447c24.592 0 44.528-19.936 44.528-44.529 0-24.592-19.936-44.528-44.528-44.528-24.593 0-44.529 19.936-44.529 44.528 0 24.593 19.936 44.529 44.529 44.529z"
                ></path>
                <path
                    fill="#3F3D56"
                    d="M291.424 123l8.708 16.69 60.229 26.849-15.832 153.21-6.663 15.866 3.6 13.778-8.68 10.168c8.713 7.884 9.82 16.136 5.08 24.673l-27.736 6.353S155.914 404.3 168.911 380.409c14.201-26.104 17.817-103.198-13.532-144.395-26.615-34.976-6.182-80.36-6.182-80.36l63.131-18.867L235.549 123h55.875z"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M279.008 1.69a2.455 2.455 0 012.363-1.402c-20.386-1.22-41.859 1.157-58.914 12.391-4.132 2.722-8.027 5.968-12.523 7.942a1.345 1.345 0 00-.787 1.07 1.348 1.348 0 00.511 1.226l.494.387a1.353 1.353 0 01-.441 2.36 1.356 1.356 0 00-.836 1.86c.107.234.279.433.496.573l3.785 2.446a1.348 1.348 0 01.012 2.268 6.748 6.748 0 01-1.006.51 1.354 1.354 0 00-.853 1.015 1.344 1.344 0 00.424 1.255 13.453 13.453 0 013.662 5.353c1.261 3.28 1.488 6.852 1.701 10.36 1.304-5.062 6.827-8.04 12.044-8.344 5.218-.302 10.306 1.42 15.383 2.657a85.101 85.101 0 0033.778 1.299c-3.814 7.655-.597 16.785 2.641 24.7l10.481-4.491c14.393-8.125 4.367 26.245-1.014 30.963 10.987 3.809 39.746-38.47 30.494-54.506-4.812-8.343-1.396-19.102-10.858-27.015-4.637-4.881-13.895-2.711-18.622-6.769-1.861-1.596.975-5.868-1.582-6.8-.849-.309-10.693-1.619-10.833-1.307z"
                ></path>
                <path
                    fill="#3F3D56"
                    d="M203.583 141.097c-8.171 36.276-29.128 120.973-50.389 138.687a12.415 12.415 0 01-4.732 2.793c-74.734 18.207-98.68-28.235-98.68-28.235s20.027-9.76 20.535-28.837l40.39 12.532 35.208-79.234 5.181-4.833.029-.029 52.458-12.844z"
                ></path>
                <path
                    fill="#FFB8B8"
                    d="M362.929 476.081a20.303 20.303 0 00-2.132-31.062l20.951-69.047-35.948 10.664-14.384 63.934a20.417 20.417 0 004.425 26.753 20.419 20.419 0 0027.088-1.242z"
                ></path>
                <path
                    fill="#3F3D56"
                    d="M343.754 162.676l16.606 3.863s68.635 133.539 52.095 158.984c-16.539 25.445-36.801 109.687-36.801 109.687s-29.967-5.958-41.418-21.225l20.968-99.912-21.628-83.968 10.178-67.429z"
                ></path>
                <path
                    fill="#E6E6E6"
                    d="M28.72 360.978l.597-215.074a16.436 16.436 0 0116.465-16.373l162.428.45a16.44 16.44 0 0116.374 16.464l-.596 215.075a16.437 16.437 0 01-16.465 16.373l-162.429-.45a16.437 16.437 0 01-16.373-16.465z"
                ></path>
                <path
                    fill="#fff"
                    d="M41.36 296.052l.383-138.27a15.393 15.393 0 0115.42-15.334l139.593.387a15.395 15.395 0 0115.335 15.42l-.53 191.386a15.396 15.396 0 01-15.421 15.335l-86.475-.24a68.575 68.575 0 01-68.306-68.684z"
                ></path>
                <path
                    fill="#4F46E5"
                    d="M180.761 203.184H71.914a6.53 6.53 0 110-13.062H180.76a6.53 6.53 0 010 13.062zM95.86 173.069H71.914a6.533 6.533 0 01-6.531-6.53 6.53 6.53 0 016.53-6.531H95.86a6.53 6.53 0 110 13.061zM180.761 233.298H71.914a6.53 6.53 0 110-13.061H180.76a6.534 6.534 0 016.531 6.53 6.53 6.53 0 01-6.531 6.531zM180.761 263.413H71.914a6.53 6.53 0 110-13.062H180.76a6.53 6.53 0 010 13.062zM171.955 342.763c14.352 0 25.986-11.634 25.986-25.986s-11.634-25.986-25.986-25.986-25.986 11.634-25.986 25.986 11.634 25.986 25.986 25.986z"
                ></path>
                <path
                    fill="#fff"
                    d="M168.41 330.301a2.886 2.886 0 01-2.313-1.156l-7.089-9.453a2.878 2.878 0 01-.551-2.144 2.873 2.873 0 011.127-1.905 2.889 2.889 0 014.049.581l4.638 6.183 11.913-17.868a2.88 2.88 0 011.838-1.238 2.89 2.89 0 013.406 3.401 2.9 2.9 0 01-.434 1.044l-14.179 21.268a2.882 2.882 0 01-2.325 1.286l-.08.001z"
                ></path>
                <path
                    fill="#F2F2F2"
                    d="M116.541 79.46c6.813 0 12.336-5.522 12.336-12.335 0-6.813-5.523-12.336-12.336-12.336-6.813 0-12.336 5.523-12.336 12.336 0 6.813 5.523 12.336 12.336 12.336zM71.55 434.303c6.814 0 12.337-5.523 12.337-12.336 0-6.813-5.523-12.336-12.336-12.336-6.813 0-12.336 5.523-12.336 12.336 0 6.813 5.523 12.336 12.336 12.336zM355.281 127.353c6.813 0 12.336-5.523 12.336-12.336 0-6.813-5.523-12.336-12.336-12.336-6.813 0-12.336 5.523-12.336 12.336 0 6.813 5.523 12.336 12.336 12.336z"
                ></path>
            </g>
            <defs>
                <clipPath id="clip0_1474_3686">
                    <path fill="#fff" d="M0 0H415.028V500H0z"></path>
                </clipPath>
            </defs>
        </svg>
    )
}

export default NoCertificatesIcon
