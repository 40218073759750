import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import S3FileUpload from 'react-s3'
import RecordRTC from 'recordrtc'

// antd
import { Col, Form, message, Row, theme, Typography, Upload } from 'antd'

// slices
import {
    addSummary,
    checkButtonType,
    disableVideoSave,
    profileLoading,
    profileState,
    retakeVideoChange,
    switchIntroductionModal,
    switchVideoPlayModal,
    updatedDataProfile,
    updateUserGet,
    videoRecordSummary,
} from '../profile.slice'
import { loginState } from '../../login/login.slice'

// assets
import {
    StartRecordingIcon,
    StopRecordingIcon,
    UploadFileIcon,
    VideoIcon,
    VideosIcon,
} from '../../../assets/icons'
import IntroductionProfileVideo from '../../../assets/videos/introductionProfileVideo.mp4'

// styles
import styles from '../profile.module.css'

import dayjs from 'dayjs'

function IntroductionVideoModal({ pageKey, showTitle }) {
    const { useToken } = theme
    const { token } = useToken()
    const dispatch = useDispatch()

    const [form] = Form.useForm()

    const { Text } = Typography
    const { Dragger } = Upload

    // SELECTORS
    const { userProfile } = useSelector(loginState)
    const {
        videoRecordingSummary,
        formButtonType,
        introductionVideoModal,
        profileData,
        retakeVideo,
    } = useSelector(profileState)

    // STATES
    const [fileList, setFileList] = useState([])
    const [stream, setStream] = useState(false)
    const [fileURL, setFileURL] = useState([])
    const [mediaStream, setMediaStream] = useState(null)
    const [recording, setRecording] = useState(false)
    const [webcamRecordedVideo, setWebcamRecordedVideo] = useState(null)
    const [stopRecordingView, setStopRecordingView] = useState(false)

    // REFS
    const videoRef = useRef(null)
    const draggerRef = useRef()
    const webcamRecorderRef = useRef(null)

    // BASE64 URL
    const getBase64FromUrl = async (url) => {
        const data = await fetch(url)
        const blob = await data.blob()
        return new Promise((resolve) => {
            const reader = new FileReader()
            reader.readAsDataURL(blob)
            reader.onloadend = () => {
                const base64data = reader.result
                resolve(base64data)
            }
        })
    }

    // URL TO FILE
    async function UrlToFile(url, filename, mimeType) {
        return fetch(url)
            .then(function (res) {
                return res.arrayBuffer()
            })
            .then(function (buf) {
                return new File([buf], filename, { type: mimeType })
            })
    }

    // SAVE VIDEO
    async function onFinishVideoUpload() {
        if (formButtonType === 'saveVideoLoading') {
            if (webcamRecordedVideo === null && fileURL?.length === 0) {
                message.info('Please Upload Video')
                return
            }
        }

        if (formButtonType === 'retakeButton') {
            startCameraOn()
            setWebcamRecordedVideo(null)
            setMediaStream(null)
            setFileURL([])
            setRecording(false)
            setStopRecordingView(false)
            return
        }

        dispatch(
            profileLoading({
                name: 'profile',
                buttonName: formButtonType,
                loading: true,
            })
        )

        const result = await dispatch(
            addSummary({
                _id: userProfile?._id,
                summary: {
                    step: 'videos',
                    data: {
                        type: introductionVideoModal?.type,
                        link: fileURL[0],
                        category: introductionVideoModal?.category,
                        isCompleted:
                            fileURL?.length > 0 &&
                            introductionVideoModal?.type ===
                                'Introduction Video'
                                ? // &&
                                  // selectedData?.videos.some(
                                  //     (data) => data?.type === 'Introduction Video'
                                  // )
                                  true
                                : false,
                    },
                },
            })
        )
        const response = result?.payload?.data

        if (response) {
            const { success, message: errorMessage } = response
            if (success) {
                if (
                    introductionVideoModal?.type === 'Introduction Video' &&
                    profileData?.data?.summary &&
                    profileData?.data?.summary?.summaryText !== undefined &&
                    profileData?.data?.summary?.isCompleted === false
                ) {
                    dispatch(
                        addSummary({
                            _id: userProfile?._id,
                            summary: {
                                step: 'summary',
                                data: {
                                    summary: {
                                        summaryText:
                                            profileData?.data?.summary
                                                ?.summaryText,
                                        isCompleted:
                                            fileURL?.length > 0 &&
                                            profileData?.data?.profilePicture
                                                ?.length === 0 &&
                                            profileData?.data?.location === ''
                                                ? false
                                                : true,
                                    },
                                },
                            },
                        })
                    )
                }
                dispatch(
                    updatedDataProfile({
                        editData: {
                            step: 'summary',
                            extraFields: ['videos'],
                        },
                        _id: userProfile?._id,
                    })
                )
                dispatch(updateUserGet({ _id: userProfile?._id }))

                message.success(errorMessage)
            } else {
                if (errorMessage) {
                    message.error(errorMessage)
                } else {
                    message.error('Something went wrong, try again later.')
                }
            }
        }

        dispatch(
            profileLoading({
                name: 'profile',
                buttonName: formButtonType,
                loading: false,
            })
        )
        if (pageKey === 'videos') {
            dispatch(
                switchIntroductionModal({
                    open: false,
                    videosTab: [
                        ...introductionVideoModal?.videosTab,
                        {
                            type: introductionVideoModal?.type,
                            link: fileURL[0],
                            category: introductionVideoModal?.category,
                        },
                    ],
                })
            )
        } else {
            dispatch(
                switchIntroductionModal({
                    open: false,
                    videos: [
                        ...introductionVideoModal?.videos,
                        {
                            type: introductionVideoModal?.type,
                            link: fileURL[0],
                            category: introductionVideoModal?.category,
                        },
                    ],
                })
            )
        }
        // }
    }

    function onFinishFailed() {}

    // ONCHANGE FILE PROPS
    const props = {
        name: 'files',
        multiple: false,
        onChange(info) {
            const isLt16M = info?.file.size / 1024 / 1024 < 16
            if (!isLt16M) {
                message.error('Video must be smaller than 16MB')
                return
            } else {
                setStream(true)
                const nameChange = info?.fileList[0]?.name
                    .split('.')[0]
                    .concat(`_${dayjs(new Date()).unix()}`)
                    .concat(`.${info?.fileList[0]?.name.split('.')[1]}`)

                const newFileData = new File(
                    [info?.fileList[0]?.originFileObj],
                    nameChange,
                    { type: info?.fileList[0]?.type }
                )
                window.Buffer = window.Buffer || require('buffer').Buffer

                S3FileUpload.uploadFile(newFileData, userProfile?.s3Config)
                    .then(async (data) => {
                        setStream(false)
                        setFileURL([data?.location])
                    })
                    .catch(() => {
                        message.error('Upload Failed!. Please Upload again')
                    })
            }
        },

        beforeUpload(file) {
            setFileList([...fileList, file])
            return false
        },

        onDrop() {},
    }

    // CHECK CAMERA AND MICROPHONE PERMISSIONS
    function checkPermission() {
        navigator.mediaDevices
            .getUserMedia({ video: true, audio: true })
            .then(function (res) {
                // Permission granted for both camera and microphone
                // Proceed with further actions here if needed
                if (!stream) {
                    dispatch(videoRecordSummary(true))
                    dispatch(disableVideoSave(true))
                }
            })
            .catch(function (err) {
                // Permission denied or error occurred
                // Check if permission was denied for camera or microphone
                message.info(
                    'Please Grant Permission of Camera and Microphone from the Settings'
                )
            })
    }

    // CAMERA ON
    async function startCameraOn() {
        const webcamStream = await navigator.mediaDevices.getUserMedia({
            video: true,
            audio: {
                noiseSuppression: true,
                echoCancellation: true,
                autoGainControl: true,
            },
        })

        const webcamRecorder = RecordRTC(webcamStream, {
            type: 'video',
            mimeType: 'video/webm',
        })

        setWebcamRecordedVideo(null)

        if (videoRef.current) {
            videoRef.current.srcObject = webcamStream
        }

        webcamRecorderRef.current = webcamRecorder
        setMediaStream([webcamStream])
    }

    // START RECORDING
    function startRecording() {
        dispatch(
            retakeVideoChange({
                ...retakeVideo,
                visibility: true,
            })
        )
        setRecording(true)
        if (webcamRecorderRef) {
            webcamRecorderRef.current.startRecording()
        }
    }

    // STOP RECORDING
    async function stopRecording() {
        if (webcamRecorderRef.current) {
            webcamRecorderRef.current.stopRecording(async () => {
                const webcamBlob = webcamRecorderRef.current.getBlob()
                setWebcamRecordedVideo(webcamBlob)
                webcamRecorderRef.current.reset()
                webcamRecorderRef.current.destroy()
                webcamRecorderRef.current = null
                const webBlob = URL.createObjectURL(webcamBlob)
                const videoName = 'video'
                    .concat(`_${new Date().getTime()}`)
                    .concat(`.mp4`)

                const mediaVideo = await getBase64FromUrl(webBlob)

                window.Buffer = window.Buffer || require('buffer').Buffer

                const mediaVideoGet = await UrlToFile(
                    mediaVideo,
                    videoName,
                    'video/mp4'
                )
                if (mediaVideoGet) {
                    S3FileUpload.uploadFile(
                        mediaVideoGet,
                        userProfile?.s3Config
                    )
                        .then(async (data) => {
                            setStream(false)
                            dispatch(disableVideoSave(false))
                            setFileURL([data?.location])
                        })
                        .catch(() => {
                            message.error('Upload Failed!. Please Upload again')
                        })
                }
            })

            if (mediaStream) {
                mediaStream.forEach((stream) => {
                    stream.getTracks().forEach((track) => track.stop())
                })
                setMediaStream(null)
            }
        }
    }

    // CAMERA START
    useEffect(() => {
        if (videoRecordingSummary === true) {
            startCameraOn()
        }
    }, [videoRecordingSummary])

    return (
        <>
            <Form
                layout="vertical"
                name="saveVideo"
                onFinish={onFinishVideoUpload}
                onFinishFailed={onFinishFailed}
                requiredMark={false}
                onSubmitCapture={async (e) => {
                    await dispatch(checkButtonType(e.nativeEvent.submitter.id))
                }}
                form={form}
            >
                <Row
                    align={'middle'}
                    style={{
                        width: '100%',
                    }}
                >
                    <Col span={12}>
                        <div
                            style={{
                                // width: '100%',
                                // height: '310px',
                                position: 'relative',
                            }}
                        >
                            <video
                                width="100%"
                                height="310px"
                                className={styles.videoContainerNew}
                            >
                                <source
                                    src={IntroductionProfileVideo}
                                    type="video/mp4"
                                />
                            </video>
                            <div
                                className={styles.videoPlay}
                                onClick={() =>
                                    dispatch(
                                        switchVideoPlayModal({
                                            ...introductionVideoModal,
                                            open: true,
                                            videoData: {
                                                deleteId: '',
                                                link: IntroductionProfileVideo,
                                            },
                                            title: showTitle,
                                        })
                                    )
                                }
                            >
                                <VideosIcon
                                    color={token.colorPalette.textColor.quinary}
                                />
                            </div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <Form.Item>
                            {fileURL?.length > 0 ? (
                                <>
                                    <video
                                        src={fileURL[0]}
                                        // autoPlay
                                        loop
                                        height="310px"
                                        width={'100%'}
                                        style={{
                                            borderTopRightRadius: '10px',
                                            borderBottomRightRadius: '10px',
                                            objectFit: 'cover',
                                            position: 'relative',
                                        }}
                                    />
                                    <div
                                        className={styles.videoPlay}
                                        onClick={() =>
                                            dispatch(
                                                switchVideoPlayModal({
                                                    ...introductionVideoModal,
                                                    open: true,
                                                    videoData: {
                                                        deleteId: '',
                                                        link: fileURL[0],
                                                    },
                                                    title: showTitle,
                                                })
                                            )
                                        }
                                    >
                                        <VideosIcon
                                            color={
                                                token.colorPalette.textColor
                                                    .quinary
                                            }
                                        />
                                    </div>
                                </>
                            ) : videoRecordingSummary ? (
                                <>
                                    {!stopRecordingView && videoRef && (
                                        <video
                                            ref={videoRef}
                                            height="310px"
                                            width={'100%'}
                                            autoPlay
                                            style={{
                                                borderTopRightRadius: '10px',
                                                borderBottomRightRadius: '10px',
                                                objectFit: 'cover',
                                                position: 'relative',
                                            }}
                                        />
                                    )}
                                    {recording ? (
                                        <div
                                            style={{
                                                position: 'absolute',
                                                right: '45%',
                                                bottom: '30px',
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => {
                                                setRecording(false)
                                                setStopRecordingView(true)
                                                stopRecording()
                                            }}
                                        >
                                            <StopRecordingIcon />
                                        </div>
                                    ) : stopRecordingView ? (
                                        <>
                                            {webcamRecordedVideo && (
                                                <video
                                                    src={URL.createObjectURL(
                                                        webcamRecordedVideo
                                                    )}
                                                    autoPlay
                                                    loop
                                                    height="310px"
                                                    width={'100%'}
                                                    style={{
                                                        borderTopRightRadius:
                                                            '10px',
                                                        borderBottomRightRadius:
                                                            '10px',
                                                        objectFit: 'cover',
                                                        position: 'relative',
                                                    }}
                                                />
                                            )}
                                        </>
                                    ) : (
                                        <div
                                            style={{
                                                position: 'absolute',
                                                right: '45%',
                                                bottom: '30px',
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => {
                                                startRecording()
                                            }}
                                        >
                                            <StartRecordingIcon />
                                        </div>
                                    )}
                                </>
                            ) : (
                                <>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        <Dragger
                                            {...props}
                                            showUploadList={false}
                                            disabled={stream}
                                            style={{
                                                width: '214px',
                                                border: `1px dashed ${token.colorPalette.baseColor.tertiary}`,
                                            }}
                                            ref={draggerRef}
                                            accept=".MP4,.MOV,.WMV,.AVI,.AVCHD,.FLV,.F4V,.SWF,.MKV,.WEBM,.HTML5"
                                        >
                                            <Row
                                                align={'middle'}
                                                style={{
                                                    flexDirection: 'column',
                                                }}
                                            >
                                                <p
                                                    style={{
                                                        height: '20px',
                                                        width: '20px',
                                                        marginBottom: '5px',
                                                    }}
                                                >
                                                    <UploadFileIcon />
                                                </p>
                                                <p className="ant-upload-text">
                                                    Click or drag file to upload
                                                </p>
                                            </Row>
                                        </Dragger>
                                        <Row
                                            style={{
                                                margin: '15px 0px',
                                            }}
                                        >
                                            <Text
                                                style={{
                                                    fontSize: '16px',
                                                }}
                                            >
                                                OR
                                            </Text>
                                        </Row>
                                        <div
                                            style={{
                                                columnGap: '10px',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                display: 'flex',
                                                cursor: 'pointer',
                                                border: `1px dashed ${token.colorPalette.baseColor.tertiary}`,
                                                width: '214px',
                                                borderRadius: '6px',
                                                padding: '16px 0px',
                                                backgroundColor:
                                                    'rgba(0, 0, 0, 0.02)',
                                            }}
                                            onClick={() => {
                                                checkPermission()
                                            }}
                                        >
                                            <div
                                                style={{
                                                    height: '20px',
                                                    marginBottom: '5px',
                                                    color: '#375BFB',
                                                    width: '20px',
                                                }}
                                            >
                                                <VideoIcon />
                                            </div>
                                            <Text
                                                style={{
                                                    fontSize: '16px',
                                                    textDecoration: 'underline',
                                                }}
                                            >
                                                Record Video
                                            </Text>
                                        </div>
                                    </div>
                                </>
                            )}
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

export default IntroductionVideoModal
