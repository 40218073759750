import React from 'react'

function CompanyIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="18"
            fill="none"
            viewBox="0 0 20 18"
        >
            <path
                fill="#4F46E5"
                d="M7 16h3V9.942L6 6.454 2 9.942V16h3v-4h2v4zm12 2H1a1 1 0 01-1-1V9.487a1 1 0 01.343-.754L4 5.544V1a1 1 0 011-1h14a1 1 0 011 1v16a1 1 0 01-1 1zM14 8v2h2V8h-2zm0 4v2h2v-2h-2zm0-8v2h2V4h-2zm-4 0v2h2V4h-2z"
            ></path>
        </svg>
    )
}

export default CompanyIcon
