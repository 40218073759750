import React from 'react'

function FieldOfStudyIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 18 18"
        >
            <path
                fill="#4F46E5"
                d="M6 0v15h3V0H6zm3 2l4 13 3-1-4-13-3 1zM2 2v13h3V2H2zM0 16v2h18v-2H0z"
            ></path>
        </svg>
    )
}

export default FieldOfStudyIcon
