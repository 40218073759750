import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import S3FileUpload from 'react-s3'

// antd
import {
    Avatar,
    Col,
    Form,
    Image,
    Row,
    Typography,
    Space,
    Select,
    DatePicker,
    theme,
    Input,
    Upload,
    message,
    Card,
} from 'antd'

// slices
import {
    projectStepData,
    projectsList,
    projectsState,
    totalProjects,
    updateProject,
} from './projects.slice'
import { loginState } from '../login/login.slice'

// helpers
import { editFieldCheck, viewFieldCheck } from '../../helpers/permissionCheck'
import { isEmpty } from '../../helpers/fieldCheck'

// assets
import { UploadImageIcon } from '../../assets/icons'

// components
import Button from '../../components/button/button'

// constants
import { projectProfilePictures, projectType } from '../../constants/projects'

import dayjs from 'dayjs'
import { teamState } from '../team/team.slice'

const NewProjectForm = ({ onCancel, refreshProject, setStep }) => {
    const { Text } = Typography
    const { TextArea } = Input

    const { Option } = Select

    const { useToken } = theme
    const { token } = useToken()

    const [form] = Form.useForm()

    const { selectedProject, pageLimit, skipPage, stepProjectData } =
        useSelector(projectsState)

    const { teamList } = useSelector(teamState)

    const dispatch = useDispatch()

    const { userProfile } = useSelector(loginState)

    const [teamLeadData, setTeamLeadData] = useState([])
    const [leadOption, setLeadOption] = useState(null)
    const [fileURL, setFileURL] = useState([])
    const [loadingButtonUpdate, setLoadingButtonUpdate] = useState(false)

    const initialDataCall = (selectedProject) => {
        if (selectedProject?.projectProfile?.length > 0) {
            setFileURL([selectedProject?.projectProfile])
        }

        if (teamList?.length > 0) {
            const teamMembers = teamList.map((data) => {
                return {
                    label: data?.name,
                    value: data?._id,
                    profile: data?.profilePicture,
                }
            })
            setTeamLeadData(teamMembers)
            setLeadOption(selectedProject?.projectLead)
        }

        const {
            _id: valueLead,
            name: labelLead,
            profilePicture: profileLead,
        } = {
            ...selectedProject?.projectLead,
        }

        const new_obj_projectLead = Object.assign(
            {},
            {
                label: labelLead && (
                    <Space size="small">
                        {profileLead?.length > 0 ? (
                            <Image
                                src={profileLead}
                                height={30}
                                width={30}
                                preview={false}
                                style={{
                                    borderRadius: '50%',
                                }}
                            />
                        ) : (
                            <Avatar
                                style={{
                                    backgroundColor: '#f56a00',
                                    cursor: 'pointer',
                                    height: '30px',
                                    width: '30px',
                                }}
                            >
                                {labelLead && labelLead[0].toUpperCase()}

                                {labelLead.split(' ')[1] !== undefined &&
                                labelLead.split(' ')[1] !== ''
                                    ? labelLead.split(' ')[1][0].toUpperCase()
                                    : ''}
                            </Avatar>
                        )}
                        <Text
                            style={{
                                margin: 0,
                            }}
                        >
                            {labelLead && labelLead}
                        </Text>
                    </Space>
                ),
                value: valueLead,
            }
        )

        form.setFieldsValue({
            ...selectedProject,
            projectLead: [{ ...new_obj_projectLead }],
            start: selectedProject?.start ? dayjs(selectedProject?.start) : '',
            end: selectedProject?.end ? dayjs(selectedProject?.end) : '',
        })
    }

    useEffect(() => {
        initialDataCall(selectedProject)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form, selectedProject])

    useEffect(() => {
        if (stepProjectData !== undefined) {
            initialDataCall(stepProjectData)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stepProjectData])

    // ONCHANGE FILE PROPS
    const props = {
        name: 'projectProfile',
        multiple: false,
        onChange(info) {
            const isLt5M = info?.file.size / 1024 / 1024 < 5
            if (!isLt5M) {
                message.error('Profile picture must be smaller than 5MB')
                return
            } else {
                if (
                    ['image/png', 'image/jpg', 'image/jpeg'].includes(
                        info?.file?.type
                    )
                ) {
                    const nameChange = info?.fileList[0]?.name
                        .split('.')[0]
                        .concat(`_${dayjs(new Date()).unix()}`)
                        .concat(`.${info?.fileList[0]?.name.split('.')[1]}`)

                    const newFileData = new File(
                        [info?.fileList[0]?.originFileObj],
                        nameChange,
                        { type: info?.fileList[0]?.type }
                    )
                    window.Buffer = window.Buffer || require('buffer').Buffer

                    S3FileUpload.uploadFile(newFileData, userProfile?.s3Config)
                        .then(async (data) => {
                            setFileURL([data?.location])
                        })
                        .catch(() => {
                            message.error('Upload Failed!. Please Upload again')
                        })
                } else {
                    message.error('Only JPG, JPEG, PNG are allowed')
                }
            }
        },

        beforeUpload() {
            return false
        },

        onDrop() {},
    }

    // FORMAT DATEPICKER
    const formatDatePicker = (value) => {
        return `${dayjs(value).format('DD MMM YY')}`
    }

    // DISABLE START DATE
    const disabledDateEnd = (current) => {
        const startDate = form.getFieldValue('start')
        // Disable dates that are before the selected start date or dates that are in the past
        return startDate
            ? current && current.valueOf() < startDate.valueOf()
            : // ||
              //   (current && current.valueOf() > Date.now())
              null
    }

    // DISABLE END DATE
    const disabledDateStart = (current) => {
        const endDate = form.getFieldValue('end')
        // Disable dates that are after the selected end date or dates that are in the future
        return endDate && current && current.valueOf() > endDate.valueOf()
    }

    const onFinishFailed = () => {}

    // ON FINISH PROJECT
    const onFinishProject = async (value) => {
        setLoadingButtonUpdate(true)

        const payload = {
            ...value,
            start: value?.start ? new Date(value?.start).getTime() : null,
            end: value?.end ? new Date(value?.end).getTime() : null,
            projectKey: value?.projectKey?.toUpperCase(),
            projectLead:
                typeof leadOption === 'object' ? leadOption?._id : leadOption,
            // projectProfile: fileURL?.length > 0 ? fileURL[0] : '',
            projectProfile:
                fileURL?.length > 0
                    ? fileURL[0]
                    : projectProfilePictures[
                          (Math.random() * projectProfilePictures.length) | 0
                      ],
        }

        if (selectedProject) {
            const result = await dispatch(
                updateProject({
                    updatedData: {
                        ...payload,
                    },
                    _id: selectedProject?._id,
                })
            )
            const data = result?.payload?.data
            if (data) {
                const { success, message: checkMessage } = data
                if (success) {
                    dispatch(await totalProjects())
                    dispatch(
                        await projectsList({ limit: pageLimit, skip: skipPage })
                    )
                    refreshProject()
                    onCancel()
                    setLoadingButtonUpdate(false)
                    message.success(checkMessage)
                    // window.location.reload()
                } else {
                    setLoadingButtonUpdate(false)
                    onCancel()
                    if (checkMessage) {
                        message.error(checkMessage)
                    } else {
                        message.error('Something went wrong, try again later.')
                    }
                }
            }
        } else {
            dispatch(projectStepData(payload))
            setStep()
        }
    }
    return (
        <Form
            labelCol={{
                style: {
                    padding: '0 0 3px',
                },
            }}
            layout="vertical"
            name="addProjectForm"
            onFinish={onFinishProject}
            onFinishFailed={onFinishFailed}
            requiredMark={false}
            form={form}
            style={{
                overflowX: 'hidden',
            }}
        >
            <Row gutter={30}>
                {(isEmpty(selectedProject) ||
                    viewFieldCheck(
                        'projects',
                        'projectProfile',
                        userProfile
                    )) && (
                    <Col span={9}>
                        <Card
                            style={{
                                borderRadius: '16px',
                                height: '100%',
                                border: `1px solid ${token.colorPalette.textColor.quaternary}`,
                            }}
                            styles={{
                                body: {
                                    padding: '30px 44px',
                                },
                            }}
                        >
                            <Form.Item
                                name="projectProfile"
                                rootClassName="imageUpload"
                            >
                                <Upload
                                    listType="picture-circle"
                                    className="avatar-uploader"
                                    {...props}
                                    fileList={[]}
                                    showUploadList={false}
                                    onPreview={() => {}}
                                    accept=".png,.jpg,.jpeg"
                                    style={{
                                        height: '128px !important',
                                        width: '128px !important',
                                    }}
                                    disabled={
                                        selectedProject &&
                                        editFieldCheck(
                                            'projects',
                                            'projectProfile',
                                            userProfile
                                        ) === false
                                    }
                                >
                                    {fileURL?.length > 0 ? (
                                        <Image
                                            src={fileURL[0]}
                                            preview={false}
                                            style={{
                                                height: '128px',
                                                width: '128px',
                                                borderRadius: '50%',
                                                objectFit: 'contain',
                                            }}
                                        />
                                    ) : (
                                        <>
                                            <Avatar
                                                style={{
                                                    color: token.colorPalette
                                                        .baseColor.white,
                                                }}
                                                className={'uploadAvatar'}
                                                size="large"
                                            >
                                                {userProfile?.name[0].toUpperCase()}
                                                {userProfile?.name.split(
                                                    ' '
                                                )[1] !== undefined &&
                                                    userProfile?.name
                                                        .split(' ')[1][0]
                                                        .toUpperCase()}
                                            </Avatar>
                                        </>
                                    )}
                                </Upload>
                            </Form.Item>
                            <div className={'hoverLayer'}>
                                <div className={'hoverShow'}>
                                    <UploadImageIcon />
                                    <div
                                        style={{
                                            marginTop: 8,
                                            color: token.colorPalette.baseColor
                                                .white,
                                        }}
                                    >
                                        Update logo
                                    </div>
                                </div>
                            </div>
                            <Row
                                style={{
                                    marginTop: '10px',
                                }}
                            >
                                <Text
                                    className="titleSecondary"
                                    style={{
                                        fontSize: token.fontSizeIcon,
                                        textAlign: 'center',
                                    }}
                                >
                                    Allowed *.jpeg, *.jpg, *.png, Max size of 5
                                    MB
                                </Text>
                            </Row>
                        </Card>
                    </Col>
                )}
                <Col span={15}>
                    <Row
                        gutter={20}
                        style={{
                            rowGap: '15px',
                        }}
                    >
                        {(isEmpty(selectedProject) ||
                            viewFieldCheck(
                                'projects',
                                'name',
                                userProfile
                            )) && (
                            <Col span={24}>
                                <Form.Item
                                    label="Project Name*"
                                    name="name"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Please Enter Project Name',
                                        },
                                        {
                                            max: 25,
                                            message:
                                                'Project Name is maximum 25 characters long',
                                        },
                                    ]}
                                    colon={false}
                                >
                                    <Input
                                        placeholder={'Enter Project Name'}
                                        name={'name'}
                                        readOnly={
                                            selectedProject &&
                                            editFieldCheck(
                                                'projects',
                                                'name',
                                                userProfile
                                            ) === false
                                        }
                                    />
                                </Form.Item>
                            </Col>
                        )}
                        {(isEmpty(selectedProject) ||
                            viewFieldCheck(
                                'projects',
                                'projectType',
                                userProfile
                            )) && (
                            <Col span={24}>
                                <Form.Item
                                    label="Project Type*"
                                    name="projectType"
                                    colon={false}
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Please Enter Project Type',
                                        },
                                    ]}
                                >
                                    <Select
                                        options={projectType}
                                        allowClear={false}
                                        placeholder="Select a Type"
                                        disabled={
                                            selectedProject &&
                                            editFieldCheck(
                                                'projects',
                                                'projectType',
                                                userProfile
                                            ) === false
                                        }
                                    />
                                </Form.Item>
                            </Col>
                        )}
                        {(isEmpty(selectedProject) ||
                            viewFieldCheck(
                                'projects',
                                'projectKey',
                                userProfile
                            )) && (
                            <Col span={24}>
                                <Form.Item
                                    label="Project Key*"
                                    name="projectKey"
                                    colon={false}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please Enter Project Key',
                                        },
                                        {
                                            max: 10,
                                            message:
                                                'Project Key  is maximum 10 characters long',
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder={'Enter Project Key'}
                                        name={'projectKey'}
                                        readOnly={
                                            selectedProject ? true : false
                                        }
                                    />
                                </Form.Item>
                            </Col>
                        )}
                        {selectedProject && (
                            <Col span={24}>
                                <Form.Item
                                    label="Project Lead"
                                    name="projectLead"
                                    colon={false}
                                >
                                    <Select
                                        placeholder="Select a Project Lead"
                                        allowClear={false}
                                        disabled={
                                            selectedProject &&
                                            editFieldCheck(
                                                'projects',
                                                'teamMembers',
                                                userProfile
                                            ) === false
                                        }
                                        onChange={(value) =>
                                            setLeadOption(value)
                                        }
                                    >
                                        {teamLeadData?.length > 0 &&
                                            teamLeadData.map((option) => {
                                                return (
                                                    <Option
                                                        style={{
                                                            textTransform:
                                                                'capitalize',
                                                        }}
                                                        key={option?.value}
                                                        value={option?.value}
                                                    >
                                                        <Space size="small">
                                                            {option?.profile
                                                                ?.length > 0 ? (
                                                                <Image
                                                                    src={
                                                                        option?.profile
                                                                    }
                                                                    height={30}
                                                                    width={30}
                                                                    preview={
                                                                        false
                                                                    }
                                                                    style={{
                                                                        borderRadius:
                                                                            '50%',
                                                                    }}
                                                                />
                                                            ) : (
                                                                <Avatar
                                                                    style={{
                                                                        backgroundColor:
                                                                            '#f56a00',
                                                                        cursor: 'pointer',
                                                                        height: '30px',
                                                                        width: '30px',
                                                                    }}
                                                                >
                                                                    {option?.label &&
                                                                        option?.label[0].toUpperCase()}
                                                                    {option?.label.split(
                                                                        ' '
                                                                    )[1] !==
                                                                        undefined &&
                                                                    option?.label.split(
                                                                        ' '
                                                                    )[1] !== ''
                                                                        ? option?.label
                                                                              .split(
                                                                                  ' '
                                                                              )[1][0]
                                                                              .toUpperCase()
                                                                        : ''}
                                                                </Avatar>
                                                            )}
                                                            <Text
                                                                style={{
                                                                    margin: 0,
                                                                }}
                                                            >
                                                                {option?.label}
                                                            </Text>
                                                        </Space>
                                                    </Option>
                                                )
                                            })}
                                    </Select>
                                </Form.Item>
                            </Col>
                        )}
                    </Row>
                </Col>
            </Row>
            <Row
                gutter={30}
                style={{
                    rowGap: '25px',
                    marginTop: '25px',
                }}
            >
                {(isEmpty(selectedProject) ||
                    viewFieldCheck('projects', 'start', userProfile)) && (
                    <Col flex={'1 0 50%'}>
                        <Form.Item
                            label="Start Date"
                            colon={false}
                            name={'start'}
                        >
                            <DatePicker
                                allowClear={false}
                                name="start"
                                disabledDate={disabledDateStart}
                                format={formatDatePicker}
                                disabled={
                                    selectedProject &&
                                    editFieldCheck(
                                        'projects',
                                        'start',
                                        userProfile
                                    ) === false
                                }
                            />
                        </Form.Item>
                    </Col>
                )}
                {(isEmpty(selectedProject) ||
                    viewFieldCheck('projects', 'end', userProfile)) && (
                    <Col flex={'1 0 50%'}>
                        <Form.Item label="End Date" colon={false} name={'end'}>
                            <DatePicker
                                allowClear={false}
                                disabledDate={disabledDateEnd}
                                name="end"
                                format={formatDatePicker}
                                disabled={
                                    selectedProject &&
                                    editFieldCheck(
                                        'projects',
                                        'end',
                                        userProfile
                                    ) === false
                                }
                            />
                        </Form.Item>
                    </Col>
                )}
                {(isEmpty(selectedProject) ||
                    viewFieldCheck('projects', 'description', userProfile)) && (
                    <Col span={24}>
                        <Form.Item
                            label="Description"
                            name="description"
                            rules={[
                                {
                                    max: 500,
                                    message:
                                        'Project description is maximum 500 characters long',
                                },
                            ]}
                            colon={false}
                        >
                            <TextArea
                                rows={5}
                                placeholder={'Enter Project Description...'}
                                name={'description'}
                                readOnly={
                                    selectedProject &&
                                    editFieldCheck(
                                        'projects',
                                        'description',
                                        userProfile
                                    ) === false
                                }
                            />
                        </Form.Item>
                    </Col>
                )}
            </Row>
            <Row
                gutter={12}
                style={{
                    float: 'right',
                    marginTop: '35px',
                }}
            >
                <Col span={24}>
                    <Button
                        props={{
                            htmlType: 'submit',
                            loadingButton: loadingButtonUpdate,
                            text: selectedProject ? 'Update' : 'Next',
                            // onClick: () => setCurrent(1),
                            style: {
                                padding: '8px 27px',
                            },
                        }}
                    />
                </Col>
            </Row>
        </Form>
    )
}

export default NewProjectForm
