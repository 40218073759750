import React from 'react'

function InquiriesIcon(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 19 22"
            {...props}
        >
            <path
                fill={props.color ? props.color : '#FFF'}
                d="M7.002 13.062c.548-.069.998.386.998.938v6a1 1 0 01-1 1H1c-.552 0-1.007-.45-.938-.998a8.004 8.004 0 016.94-6.94zM8 12c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6zm9.446 7.032l.797.796a1 1 0 01-1.415 1.415l-.796-.797a4 4 0 111.414-1.414zM14 19a2 2 0 100-4 2 2 0 000 4z"
            ></path>
        </svg>
    )
}

export default InquiriesIcon
