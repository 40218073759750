import React from 'react'

function NoRecruitIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 554 400"
        >
            <g clipPath="url(#clip0_6850_12136)">
                <path
                    fill="#F2F2F2"
                    d="M345.151 399.787c5.452-11.775 10.9-23.725 13.266-36.484 2.366-12.759 1.373-26.606-5.403-37.672-6.777-11.067-20.119-18.571-32.852-16.069-10.458 2.055-18.855 10.291-23.772 19.747-4.917 9.456-6.89 20.121-8.802 30.606-.618-11.911-1.273-24.015-5.23-35.267-3.957-11.252-11.748-21.738-22.853-26.092-11.104-4.354-25.549-.93-31.402 9.463-8.246 14.642 2.688 35.18-6.794 49.054-1.626-13.917-16.179-24.912-30.01-22.674-13.832 2.238-24.175 17.262-21.33 30.981 1.693 8.163 7.263 15.107 13.922 20.123 6.659 5.017 14.404 10.831 22.065 14.119l139.195.165z"
                ></path>
                <path
                    fill="#fff"
                    d="M177.231 344.231c10.987 3.841 22.075 7.724 32.105 13.728 8.99 5.381 16.995 12.626 22.051 21.905a38.942 38.942 0 014.818 16.183c.073 1.189 1.932 1.197 1.859 0-.65-10.581-5.807-20.264-12.948-27.922-7.829-8.396-17.974-14.131-28.448-18.58-6.211-2.638-12.576-4.88-18.943-7.106-1.131-.395-1.618 1.4-.494 1.792zM240.606 300.401a167.476 167.476 0 0116.105 35.752 169.489 169.489 0 017.428 38.501c.542 7.32.601 14.669.177 21.997-.069 1.195 1.79 1.191 1.859 0 .75-13.213-.05-26.468-2.383-39.495a171.531 171.531 0 00-11.408-37.883 167.283 167.283 0 00-10.173-19.81.93.93 0 00-1.605.938zM331.575 311.077a270.963 270.963 0 00-19.988 67.24 273.905 273.905 0 00-2.427 19.633c-.103 1.191 1.756 1.184 1.859 0a270.042 270.042 0 0114.875-67.762 267.714 267.714 0 017.286-18.172c.482-1.084-1.12-2.031-1.605-.939z"
                ></path>
                <path
                    fill="#F2F2F2"
                    d="M65.717 398.397A87.84 87.84 0 01.01 312.034c.076-4.902.591-9.907 2.652-14.356 2.06-4.448 5.888-8.296 10.676-9.35 5.226-1.15 10.74 1.201 14.633 4.871 3.895 3.669 6.448 8.51 8.797 13.317 14.15 28.955 24.9 60.023 28.925 91.998l.024-.117z"
                ></path>
                <path
                    fill="#fff"
                    d="M10.967 289.294l9.917 22.532 9.917 22.532c3.14 7.135 6.275 14.274 9.521 21.363 3.218 7.026 6.546 14.004 10.096 20.869 3.545 6.856 7.313 13.599 11.403 20.146.51.815 1.024 1.627 1.543 2.436.36.56 1.258.04.896-.524-4.168-6.498-8.01-13.2-11.612-20.026-3.606-6.835-6.976-13.79-10.223-20.802-3.263-7.049-6.4-14.155-9.53-21.264-3.295-7.483-6.588-14.966-9.881-22.45l-9.917-22.531-1.234-2.804c-.268-.61-1.163-.083-.896.523z"
                ></path>
                <path
                    fill="#F2F2F2"
                    d="M61.58 398.554a51.407 51.407 0 01-57.429-27.1c-1.284-2.566-2.367-5.303-2.498-8.169-.132-2.866.817-5.897 3.018-7.737 2.403-2.008 5.9-2.275 8.913-1.422 3.012.854 5.644 2.678 8.162 4.54 15.163 11.213 29.131 24.441 39.853 39.955l-.02-.067z"
                ></path>
                <path
                    fill="#fff"
                    d="M3.701 356.689l11.231 9.021 11.232 9.021c3.557 2.857 7.111 5.717 10.71 8.521 3.568 2.78 7.18 5.504 10.876 8.11 3.691 2.602 7.468 5.086 11.359 7.381.484.285.97.568 1.459.848.338.194.664-.319.324-.514-3.919-2.248-7.722-4.691-11.436-7.263-3.717-2.574-7.345-5.276-10.923-8.04-3.597-2.778-7.145-5.621-10.689-8.467l-11.19-8.988-11.231-9.021-1.398-1.122c-.304-.244-.626.271-.324.513z"
                ></path>
                <path
                    fill="#3F3D56"
                    d="M539.619 400H27.059a.743.743 0 01-.684-.455.74.74 0 01.4-.965.723.723 0 01.283-.055H539.62a.735.735 0 01.738.737.739.739 0 01-.738.738zM526.155 326.091h-34.702a1.549 1.549 0 01-1.549 1.549h-34.063c-.482.362-.92.778-1.308 1.239a7.688 7.688 0 00-1.797 4.956v1.239a7.747 7.747 0 007.745 7.745h65.674a7.752 7.752 0 007.744-7.745v-1.239a7.755 7.755 0 00-7.744-7.744z"
                ></path>
                <path
                    fill="#3F3D56"
                    d="M497.65 342.511h-8.054v57h8.054v-57z"
                ></path>
                <path
                    fill="#3F3D56"
                    d="M520.574 399.228c0 .87-12.205.337-27.26.337-15.056 0-27.261.533-27.261-.337 0-.87 12.205-7.771 27.261-7.771 15.055 0 27.26 6.901 27.26 7.771z"
                ></path>
                <path
                    fill="#6C63FF"
                    d="M524.636 327.875l-1.351-8.451a4.072 4.072 0 00-4.659-3.376l-13.934 2.224-23.866 3.81-7.763 1.239h16.84a1.55 1.55 0 011.549 1.549v1.221a1.553 1.553 0 01-1.549 1.549h-42.855a3.923 3.923 0 00-1.506 1.239 4.021 4.021 0 00-.768 3.073l.384 2.404.465 2.918.502 3.135a4.064 4.064 0 004.646 3.37l11.072-1.765 31.932-5.099 27.484-4.387a4.08 4.08 0 003.377-4.653z"
                ></path>
                <path
                    fill="#6C63FF"
                    d="M460.994 328.879a3.932 3.932 0 00-1.567-1.239h-11.846a3.906 3.906 0 00-1.574 1.239 4.01 4.01 0 00-.849 2.472v47.217a4.066 4.066 0 004.059 4.058h8.568a4.06 4.06 0 004.058-4.058v-47.217a4.01 4.01 0 00-.849-2.472zM486.78 248.459l-.081-.105-5.316-6.549a3.92 3.92 0 00-1.009-.886 3.01 3.01 0 00-.657-.334 3.871 3.871 0 00-1.06-.26 4.15 4.15 0 00-.731-.013.023.023 0 00-.012.006 4.014 4.014 0 00-2.243.893l-10.911 8.847-14.02 11.375-20.923 16.976-1.524 1.239h26.474l1.53-1.239 5.062-4.108.359-.285 24.448-19.832.019-.012a4.003 4.003 0 001.159-1.518 4.105 4.105 0 00.177-2.796 4.03 4.03 0 00-.741-1.399z"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M486.701 248.354a10.109 10.109 0 00-2.206-4.523 9.985 9.985 0 00-4.12-2.912 8.5 8.5 0 00-.737-.247 10.61 10.61 0 00-1.71-.36.023.023 0 00-.012.006 10.112 10.112 0 00-7.311 2.02l-18.872 14.424a3.992 3.992 0 00-1.332 2.831c-.03.631.087 1.261.341 1.84.062.13.13.26.204.384.145.252.319.485.52.694l3.024 3.395 7.032 7.887.198.224.453.514a4.057 4.057 0 005.743.254l16.276-17.484a10.194 10.194 0 002.509-8.947z"
                ></path>
                <path
                    fill="#6C63FF"
                    d="M493.896 225.919c17.15 0 31.053-13.903 31.053-31.054 0-17.15-13.903-31.053-31.053-31.053-17.151 0-31.054 13.903-31.054 31.053 0 17.151 13.903 31.054 31.054 31.054zM505.542 323.6l-.849-5.328-.502-3.129a4.067 4.067 0 00-4.653-3.37l-22.57 3.599-6.122.979-1.239.198v6.772h20.297a1.55 1.55 0 011.549 1.549v1.221a1.553 1.553 0 01-1.549 1.549h-64.23c0 .012.006.024.006.037l.384 2.404.967 6.047a4.062 4.062 0 004.646 3.37l11.072-1.766 2.41-.384.465-.074 16.22-2.59 11.034-1.766 17.509-2.794.261-.043 11.517-1.834a4.072 4.072 0 003.377-4.647z"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M536.159 300.813c0 25.315-2.571 40.457-42.223 36.12l-.161-.018a117.78 117.78 0 01-20.898-3.997c-3.55-1.036-7-2.388-10.31-4.039-.78-.397-1.53-.812-2.267-1.239h29.603a1.552 1.552 0 001.548-1.549v-1.221a1.55 1.55 0 00-1.548-1.549h-20.297V284.19a4.553 4.553 0 00-1.332-3.21 4.553 4.553 0 00-3.21-1.332h-16.511c.143-.409.285-.824.44-1.239a101.611 101.611 0 015.495-12.503 95.449 95.449 0 0110.273-15.848 65.912 65.912 0 019.194-9.411c6.214-5.13 12.98-8.302 19.845-8.302 25.315 0 42.359 43.152 42.359 68.468zM494.548 206.375l.032-1.16c-2.159-.061-4.062-.196-5.492-1.241a3.558 3.558 0 01-1.382-2.627 2.04 2.04 0 01.669-1.68c.949-.802 2.477-.542 3.59-.032l.961.441-1.842-13.456-1.149.157 1.566 11.446a4.105 4.105 0 00-3.875.558 3.17 3.17 0 00-1.08 2.606 4.718 4.718 0 001.857 3.524c1.838 1.343 4.226 1.41 6.145 1.464zM484.248 187.764h-6.25v1.161h6.25v-1.161zM503.977 187.764h-6.25v1.161h6.25v-1.161z"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M518.678 186.333c-2.415-3.635-6.664-5.176-10.855-5.551-4.717-.422-9.274.487-13.88 1.371-5.068.972-10.432 1.882-15.539.653a15.317 15.317 0 01-10.413-8.412 19.594 19.594 0 01-1.141-13.066c1.197-4.634 4.167-8.712 7.768-11.791 7.35-6.286 17.296-9.111 26.862-8.845a45.409 45.409 0 0126.328 9.258 42.759 42.759 0 0115.171 23.773 44.581 44.581 0 01-2.159 28.039c-2.093 4.857-5.121 9.235-7.457 13.969-2.175 4.407-3.851 9.532-2.497 14.448 1.187 4.314 4.674 8.146 9.051 9.317a9.503 9.503 0 0010.57-4.315c2.188-3.45 2.241-7.969-1.045-10.755a8.07 8.07 0 00-6.066-1.916 8.053 8.053 0 00-5.567 3.077 8.3 8.3 0 00-1.661 6.086c.162 1.096-1.515 1.569-1.678.463a9.814 9.814 0 011.227-6.653 9.815 9.815 0 0112.002-4.157 9.82 9.82 0 015.079 4.469c1.981 3.725.912 8.218-1.603 11.403a11.111 11.111 0 01-11.331 4.074c-4.578-1.057-8.323-4.696-10.084-8.971-1.961-4.76-1.063-10.072.849-14.692 2.066-4.993 5.134-9.471 7.561-14.282a40.672 40.672 0 004.127-13.899 43.972 43.972 0 00-6.272-27.855 40.869 40.869 0 00-22.512-17.11 42.718 42.718 0 00-28.265.747c-8.845 3.486-17.386 11.08-17.283 21.281.044 4.357 1.661 8.801 4.999 11.707 3.988 3.473 9.542 3.77 14.556 3.228 5.046-.545 9.946-2.019 15.013-2.395 4.408-.327 9.163.013 13.099 2.203a13.101 13.101 0 014.55 4.221c.621.935-.886 1.808-1.504.878z"
                ></path>
                <path
                    fill="#6C63FF"
                    d="M512.357 257.338l-2.46-1.71-4.578-3.179a4.066 4.066 0 00-5.651 1.023l-14.925 21.48-13.896 19.999-1.24 1.785v26.585h.112a4.046 4.046 0 002.924-1.716l4.325-6.233 10.687-15.384 11.078-15.935 1.053-1.518 13.581-19.547a3.58 3.58 0 00.205-.322 4.065 4.065 0 00.379-3.078 4.055 4.055 0 00-1.594-2.25z"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M512.399 248.837c-.438-.287-.898-.54-1.375-.756a10.137 10.137 0 00-12.379 3.197l-14.101 19.12a4.036 4.036 0 00.173 4.528 4.06 4.06 0 001.177 1.073l12.838 8.054.161.099.644.403a4.071 4.071 0 005.582-1.363l9.957-19.764.167-.328.006-.006.614-1.233a10.139 10.139 0 00-3.464-13.024z"
                ></path>
                <path
                    fill="#6C63FF"
                    d="M426.062 327.64v46.647a4.064 4.064 0 004.059 4.058h8.568a4.066 4.066 0 004.058-4.058V327.64h-16.685z"
                ></path>
                <path
                    fill="#3F3D56"
                    d="M489.904 322.082h-19.058V284.19a5.787 5.787 0 00-5.78-5.781h-70.674a5.79 5.79 0 00-5.787 5.781v38.908a5.79 5.79 0 005.787 5.781h95.512c.099 0 .192-.006.285-.013.087-.006.174-.024.26-.037a2.8 2.8 0 002.243-2.738v-1.221a2.788 2.788 0 00-2.788-2.788zm0 5.558h-95.512a4.55 4.55 0 01-4.547-4.542V284.19a4.55 4.55 0 014.547-4.542h70.674a4.549 4.549 0 014.541 4.542v39.131h20.297a1.554 1.554 0 011.549 1.549v1.221a1.547 1.547 0 01-1.549 1.549z"
                ></path>
                <path
                    fill="#6C63FF"
                    d="M429.725 308.409a4.764 4.764 0 10.001-9.529 4.764 4.764 0 00-.001 9.529zM75.584 223.873c18.313 0 33.159-14.845 33.159-33.158 0-18.313-14.846-33.159-33.159-33.159s-33.158 14.846-33.158 33.159 14.845 33.158 33.158 33.158z"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M69.121 315.453h-9.145a4.03 4.03 0 00-4.027 4.027v76.213a4.03 4.03 0 004.027 4.027h9.145a4.031 4.031 0 004.027-4.027V319.48a4.03 4.03 0 00-4.027-4.027zM88.445 315.453h-9.144a4.03 4.03 0 00-4.027 4.027v76.213a4.031 4.031 0 004.027 4.027h9.144a4.016 4.016 0 002.848-1.18 4.031 4.031 0 001.18-2.847V319.48a4.023 4.023 0 00-2.487-3.721 4.034 4.034 0 00-1.54-.306z"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M95.068 241.26a25.117 25.117 0 00-27.971-8.399 25.11 25.11 0 00-17.003 23.746v67.495c-.002.205.014.411.05.613a4.335 4.335 0 004.287 3.724h41.554a4.348 4.348 0 004.337-4.337v-67.495a24.937 24.937 0 00-5.254-15.347zM103.585 205.373l-.034-1.24c2.305-.064 4.337-.208 5.864-1.324a3.813 3.813 0 001.476-2.806 2.174 2.174 0 00-.714-1.793c-1.014-.856-2.645-.579-3.834-.034l-1.025.47 1.966-14.368 1.227.169-1.672 12.221c1.615-.475 3.112-.27 4.137.596a3.379 3.379 0 011.153 2.783 5.029 5.029 0 01-1.983 3.763c-1.962 1.434-4.512 1.505-6.561 1.563zM100.191 185.5h-6.673v1.24h6.673v-1.24z"
                ></path>
                <path
                    fill="#6C63FF"
                    d="M65.862 242.214a4 4 0 00-2.8-1.295l-7.206-.297-1.927-.081a4.038 4.038 0 00-4.194 3.854l-1.14 27.459-2.014 48.692a4.033 4.033 0 003.854 4.194l5.514.229 3.625.149a4 4 0 002.893-1.059 4.001 4.001 0 001.295-2.801l.242-5.805 1.778-42.942 1.14-27.403a4.028 4.028 0 00-1.06-2.894z"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M60.583 236.204a11.472 11.472 0 00-13.804 11.221v21.065a4.044 4.044 0 001.816 3.364c.453.299.964.501 1.499.595.235.045.473.067.712.068h14.87a.622.622 0 00.106-.006 4.025 4.025 0 003.921-4.021v-21.065a11.481 11.481 0 00-9.12-11.221z"
                ></path>
                <path
                    fill="#6C63FF"
                    d="M139.999 296.197a4.641 4.641 0 01-2.293-5.521l3.526-11.22-1.029-.855-27.056-22.385-18.08-14.956-2.198-1.821a4.027 4.027 0 00-5.67.532l-5.83 7.051a4.017 4.017 0 00.533 5.669l18.42 15.241 2.094 1.729 38.209 31.61a4.019 4.019 0 005.049.074c.199-.156.382-.332.545-.526a.807.807 0 00.074-.081l.738-.892-7.032-3.649z"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M111.845 251.917l-16.3-13.34a11.466 11.466 0 00-18.235 5.555 11.46 11.46 0 003.711 12.184l16.301 13.339a4.025 4.025 0 005.093.006 3.34 3.34 0 00.576-.57l9.418-11.505a4 4 0 00.737-3.717 4.042 4.042 0 00-1.301-1.952zM67.27 196.329c-1.726-2.885-3.077-6.063-2.436-9.481.573-3.052 2.77-5.429 5.927-5.741 1.902-.187 3.795.32 5.653.651 1.522.267 3.07.368 4.614.299a22.923 22.923 0 008.953-2.27 21.985 21.985 0 0011.807-13.836c1.885-6.861.485-14.652-3.99-20.236l1.553-.411-2.022 6.645a.937.937 0 01-1.366.555 32.018 32.018 0 00-24.906-3.982 30.332 30.332 0 00-6.835 2.629c-1.055.561-1.996-1.043-.938-1.605a33.886 33.886 0 0126.588-2.037 32.36 32.36 0 017.03 3.39l-1.366.556 2.023-6.645a.938.938 0 011.553-.41 24.918 24.918 0 015.106 18.886 23.508 23.508 0 01-10.179 16.306 25.05 25.05 0 01-18.93 3.806c-1.819-.35-3.881-.85-5.666-.089a4.887 4.887 0 00-2.623 3.227c-1.012 3.427.71 6.681 2.467 9.531l-2.016.262z"
                ></path>
                <path
                    fill="#3F3D56"
                    d="M188.238 264.977l-30.65-17.738a5.886 5.886 0 00-8.562 3.327l-8.823 28.035-3.68 11.704a5.877 5.877 0 002.905 6.988l6.791 3.526 22.156 11.493a5.876 5.876 0 004.758.297l.043-.019a5.906 5.906 0 003.408-3.395l14.2-37.019a5.86 5.86 0 00-2.546-7.199zm1.387 6.759l-14.2 37.019a4.643 4.643 0 01-1.063 1.625c-.464.462-1.02.82-1.632 1.052l-.031.012a4.663 4.663 0 01-3.755-.229l-21.914-11.369-7.031-3.649a4.637 4.637 0 01-2.293-5.52l3.525-11.221 8.972-28.518a4.647 4.647 0 012.751-2.93 4.66 4.66 0 014.008.303l30.65 17.738a4.63 4.63 0 012.013 5.687z"
                ></path>
                <path
                    fill="#6C63FF"
                    d="M177.954 276.838c-.311 0-.616-.078-.889-.227l-20.445-11.152a1.861 1.861 0 01-.737-2.519 1.862 1.862 0 012.517-.745l20.445 11.152a1.858 1.858 0 01-.891 3.491zM174.237 284.892c-.311 0-.616-.078-.889-.227l-20.445-11.152a1.86 1.86 0 011.78-3.264l20.445 11.152a1.86 1.86 0 01-.891 3.491zM171.139 293.566a1.85 1.85 0 01-.888-.227l-20.446-11.152a1.862 1.862 0 01-.736-2.519 1.857 1.857 0 012.516-.745l20.446 11.152a1.863 1.863 0 01.911 2.092 1.862 1.862 0 01-1.803 1.399z"
                ></path>
                <path
                    fill="#3F3D56"
                    d="M387.349 118.053h-85.951a4.668 4.668 0 01-4.66-4.66V4.66a4.667 4.667 0 014.66-4.66h85.951a4.666 4.666 0 014.66 4.66v108.733a4.662 4.662 0 01-4.66 4.66zM301.398 1.036a3.626 3.626 0 00-3.624 3.624v108.733a3.626 3.626 0 003.624 3.625h85.951a3.626 3.626 0 003.625-3.625V4.66a3.626 3.626 0 00-3.625-3.624h-85.951z"
                ></path>
                <path
                    fill="#6C63FF"
                    d="M337.224 52.265h-18.919a2.333 2.333 0 01-2.33-2.33V27.61a2.329 2.329 0 012.33-2.33h18.919a2.333 2.333 0 012.33 2.33v22.326a2.329 2.329 0 01-2.33 2.33z"
                ></path>
                <path
                    fill="#CCC"
                    d="M373.988 62.62h-18.92a2.335 2.335 0 01-2.33-2.33V37.966a2.335 2.335 0 012.33-2.33h18.92a2.333 2.333 0 012.33 2.33V60.29a2.333 2.333 0 01-2.33 2.33z"
                ></path>
                <path
                    fill="#FF6584"
                    d="M344.474 94.205h-18.919a2.333 2.333 0 01-2.33-2.33V69.55a2.329 2.329 0 012.33-2.33h18.919a2.333 2.333 0 012.33 2.33v22.326a2.329 2.329 0 01-2.33 2.33z"
                ></path>
                <path
                    fill="#3F3D56"
                    d="M275.233 88.664a4.707 4.707 0 00-4.305-2.881h-85.952a4.66 4.66 0 00-4.66 4.659v108.733a4.662 4.662 0 004.66 4.659h85.952a4.662 4.662 0 004.659-4.659V90.442a4.561 4.561 0 00-.354-1.778zm-.681 110.511a3.628 3.628 0 01-3.624 3.625h-85.952a3.627 3.627 0 01-3.625-3.625V90.442a3.63 3.63 0 013.625-3.625h85.952a3.641 3.641 0 013.5 2.69c.082.304.124.619.124.935v108.733z"
                ></path>
                <path
                    fill="#3F3D56"
                    d="M257.294 111.455h-25.889a2.068 2.068 0 01-1.464-.607 2.068 2.068 0 010-2.929 2.07 2.07 0 011.464-.606h25.889a2.07 2.07 0 110 4.142zM257.294 122.328h-25.889a2.07 2.07 0 110-4.142h25.889c.549 0 1.076.218 1.464.607a2.068 2.068 0 010 2.929 2.07 2.07 0 01-1.464.606z"
                ></path>
                <path
                    fill="#6C63FF"
                    d="M218.121 128.52h-18.92a2.334 2.334 0 01-2.33-2.33v-22.327a2.333 2.333 0 012.33-2.33h18.92a2.34 2.34 0 011.647.683c.436.437.682 1.029.683 1.647v22.327a2.335 2.335 0 01-2.33 2.33z"
                ></path>
                <path
                    fill="#CCC"
                    d="M257.115 144.593h-58.509a2.082 2.082 0 01-1.467-.606 2.067 2.067 0 010-2.931 2.062 2.062 0 011.467-.605h58.509a2.071 2.071 0 010 4.142zM257.115 155.466h-58.509a2.063 2.063 0 01-1.467-.605 2.082 2.082 0 01-.608-1.466 2.074 2.074 0 012.075-2.071h58.509a2.07 2.07 0 110 4.142zM257.115 166.339h-58.509a2.062 2.062 0 01-1.467-.605 2.078 2.078 0 01-.608-1.466 2.074 2.074 0 012.075-2.071h58.509c.549 0 1.076.218 1.464.607a2.068 2.068 0 010 2.929 2.07 2.07 0 01-1.464.606zM257.115 177.213h-58.509a2.082 2.082 0 01-1.467-.606 2.067 2.067 0 010-2.931 2.062 2.062 0 011.467-.605h58.509a2.07 2.07 0 110 4.142zM257.115 188.086h-58.509a2.063 2.063 0 01-1.467-.605 2.082 2.082 0 01-.608-1.466 2.074 2.074 0 012.075-2.071h58.509a2.068 2.068 0 012.067 2.071 2.068 2.068 0 01-2.067 2.071z"
                ></path>
                <path
                    fill="#E6E6E6"
                    d="M340.383 129.928l-48.998 46.65.714.75 48.999-46.65-.715-.75z"
                ></path>
                <path
                    fill="#E6E6E6"
                    d="M290.928 177.727l6.219-.073-.011-1.037-5.107.061.313-5.096-1.035-.063-.379 6.208zM341.554 129.528l-.378 6.208-1.035-.062.312-5.097-5.106.061-.011-1.036 6.218-.074zM316.242 159.324a5.696 5.696 0 100-11.392 5.696 5.696 0 000 11.392zM274.71 19.646l-48.999 46.65.714.75 48.999-46.65-.714-.75z"
                ></path>
                <path
                    fill="#E6E6E6"
                    d="M225.254 67.445l6.219-.074-.011-1.036-5.107.061.313-5.096-1.035-.063-.379 6.208zM275.88 19.245l-.378 6.208-1.035-.061.312-5.098-5.106.062-.011-1.036 6.218-.075zM250.569 49.041a5.695 5.695 0 100-11.39 5.695 5.695 0 000 11.39z"
                ></path>
                <path
                    fill="#3F3D56"
                    d="M470.827 322.739h-1.239v5.576h1.239v-5.576z"
                ></path>
            </g>
            <defs>
                <clipPath id="clip0_6850_12136">
                    <path fill="#fff" d="M0 0H553.807V400H0z"></path>
                </clipPath>
            </defs>
        </svg>
    )
}

export default NoRecruitIcon
