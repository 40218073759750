// antd
import { theme } from 'antd'

// assets
import { GridViewIcon, ListViewIcon } from '../../assets/icons'

const GridViewButton = ({ props }) => {
    const { useToken } = theme

    const { token } = useToken()

    const { onClickButton, viewList, setView } = props

    const iconList = [
        {
            key: 'list',
            icon: <ListViewIcon />,
            onClick: () => {
                setView('list')
            },
        },
        {
            key: 'grid',
            icon: <GridViewIcon />,
            onClick: () => {
                setView('grid')
            },
        },
    ]

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    borderRadius: '8px',
                    height: '44px',
                    width: '84px',
                    padding: '4px',
                    border: `1px solid ${token.colorPalette.textColor.gray}`,
                }}
            >
                {iconList?.length > 0 &&
                    iconList.map((icon) => (
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                cursor: 'pointer',
                                backgroundColor:
                                    icon?.key === viewList &&
                                    token.colorPalette.textColor.gray,
                                height: '36px',
                                width: '36px',
                                borderRadius: '8px',
                                padding: '13px 10px',
                            }}
                            onClick={() => {
                                icon.onClick()
                                onClickButton()
                            }}
                        >
                            {icon?.icon}
                        </div>
                    ))}
            </div>
        </>
    )
}

export default GridViewButton
