import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'

// antd
import {
    Avatar,
    Col,
    Row,
    Space,
    Tooltip,
    message,
    Typography,
    Popover,
    Badge,
    theme,
    Divider,
    Image,
} from 'antd'
import {
    CheckOutlined,
    UserOutlined,
    BugOutlined,
    ProjectOutlined,
} from '@ant-design/icons'

// slices
import {
    setPointerEvent,
    setTasks,
    swiLaneUpdate,
    switchFormModal,
    taskById,
    taskDelete,
    taskList,
    taskUpdate,
    tasksState,
} from './tasks.slice'
import { projectsState } from '../projects/projects.slice'
import { loginState } from '../login/login.slice'

// components
import DeletePopUp from '../../components/deletePopUp/deletePopUp'

// constants
import { colorTaskRandom } from '../../constants/tasks'

// assets
import {
    CriticalPriorityIcon,
    DeleteIcon,
    EllipsisIcon,
    HighPriorityIcon,
    LowPriorityIcon,
    MediumPriorityIcon,
} from '../../assets/icons'

// styles
import styles from './tasks.module.css'

// helpers
import { deletePermissionCheck } from '../../helpers/permissionCheck'
import { estimateFn } from '../../helpers/estimationCalculation'

const TaskBoard = ({ props }) => {
    const { tasks, spread /*filteredAssignees*/ } = props

    const { Text } = Typography

    const { useToken } = theme
    const { token } = useToken()

    const dispatch = useDispatch()

    const { pageLimit, skipPage, pointerEvent } = useSelector(tasksState)
    const { currentProjectData } = useSelector(projectsState)
    const { userProfile } = useSelector(loginState)

    const [deleteModal, setDeleteModal] = useState(false)
    const [deleteButtonLoading, setDeleteButtonLoading] = useState(false)
    const [taskID, setTaskID] = useState('')
    const [deleteCheck, setDeleteCheck] = useState(false)

    // DELETE TASK
    async function deleteTask() {
        const result = await dispatch(taskDelete(taskID))
        const data = result?.payload?.data
        const sprintId = JSON.parse(localStorage.getItem('currentSprint'))?.id

        if (data) {
            const { success, message: checkMessage } = data
            if (success) {
                setDeleteButtonLoading(false)
                setDeleteModal(false)
                setTaskID('')

                message.success(checkMessage)
                dispatch(
                    await taskList({
                        limit: pageLimit,
                        skip: skipPage,
                        filter: {
                            projectId: JSON.parse(
                                localStorage.getItem('currentProject')
                            )?.id,
                            sprintId:
                                localStorage.getItem('currentSprint') &&
                                sprintId,
                        },
                    })
                )
            } else {
                setDeleteModal(false)
                setDeleteButtonLoading(false)
                setTaskID('')
                if (checkMessage) {
                    message.error(checkMessage)
                } else {
                    message.error('Something went wrong, try again later.')
                }
            }
        }
    }

    // VIEW TASK
    async function viewTask(taskID) {
        dispatch(
            switchFormModal({
                open: true,
            })
        )
        dispatch(taskById({ _id: taskID }))
    }

    const orderColumns = useCallback(
        async (initial, final) => {
            const newTasks = structuredClone(tasks)
            newTasks.forEach((column) => {
                if (column.position === initial) {
                    column.position = final
                } else {
                    if (
                        column.position < Math.min(initial, final) ||
                        column.position > Math.max(initial, final)
                    ) {
                        return
                    } else {
                        if (initial < final) {
                            column.position--
                        } else {
                            column.position++
                        }
                    }
                }
            })
            newTasks.sort((a, b) => a.position - b.position)
            dispatch(setTasks(newTasks))
            dispatch(setPointerEvent(false))

            const result = await dispatch(
                swiLaneUpdate({
                    initialPosition: initial,
                    finalPosition: final,
                    projectId: JSON.parse(
                        localStorage.getItem('currentProject')
                    )?.id,
                })
            )

            const data = result?.payload?.data
            if (data) {
                const { success } = data
                if (success) {
                    dispatch(setPointerEvent(true))
                } else {
                    dispatch(setPointerEvent(true))
                    message.error('Something went wrong, try again later.')
                }
            }
        },
        [tasks, dispatch]
    )

    const orderCards = useCallback(
        async (source, destination, cardId) => {
            const newTasks = structuredClone(tasks)
            if (source.droppableId === destination.droppableId) {
                const { list } = newTasks.find(
                    (x) => x?._id === source.droppableId
                )

                list.forEach((card) => {
                    if (card.position === source.index) {
                        card.position = destination.index
                    } else {
                        if (
                            card.position <
                                Math.min(source.index, destination.index) ||
                            card.position >
                                Math.max(source.index, destination.index)
                        ) {
                            return
                        } else {
                            if (source.index < destination.index) {
                                card.position--
                            } else {
                                card.position++
                            }
                        }
                    }
                })

                list.sort((a, b) => a.position - b.position)
                dispatch(setTasks(newTasks))
                dispatch(setPointerEvent(false))

                const result = await dispatch(
                    taskUpdate({
                        updateTask: {
                            initialPosition: source?.index,
                            position: destination?.index,
                            projectId: JSON.parse(
                                localStorage.getItem('currentProject')
                            )?.id,
                            // epic: cardIdList && cardIdList[0],
                            status: destination?.droppableId,
                        },
                        _id: cardId,
                    })
                )

                const data = result?.payload?.data
                if (data) {
                    const { success } = data
                    if (success) {
                        dispatch(setPointerEvent(true))
                    } else {
                        dispatch(setPointerEvent(true))
                        message.error('Something went wrong, try again later.')
                    }
                }
            } else {
                const sourceColumnIndex = newTasks.findIndex(
                    (x) => x?._id === source.droppableId
                )
                const sourceCards = newTasks[sourceColumnIndex].list
                const destinationColumnIndex = newTasks.findIndex(
                    (x) => x?._id === destination.droppableId
                )

                const destinationCards = newTasks[destinationColumnIndex].list
                const cardIndex = sourceCards.findIndex(
                    (x) => x?._id === cardId
                )
                const draggedCard = sourceCards[cardIndex]
                sourceCards.forEach((card) => {
                    if (card.position > source.index) {
                        card.position--
                    }
                })
                if (destinationCards?.length > 0) {
                    destinationCards.forEach((card) => {
                        if (card.position >= destination.index) {
                            card.position++
                        }
                    })
                }

                delete sourceCards[cardIndex]
                draggedCard.position = destination.index
                if (destinationCards?.length > 0) {
                    destinationCards.splice(destination.index, 0, draggedCard)
                } else {
                    destinationCards.push(draggedCard)
                }
                sourceCards.sort((a, b) => a.position - b.position)
                if (destinationCards?.length > 0) {
                    destinationCards.sort((a, b) => a.position - b.position)
                }
                dispatch(setTasks(newTasks))
                dispatch(setPointerEvent(false))

                const result = await dispatch(
                    taskUpdate({
                        updateTask: {
                            initialPosition: source?.index,
                            position: destination?.index,
                            status: destination?.droppableId,
                            projectId: JSON.parse(
                                localStorage.getItem('currentProject')
                            )?.id,
                            // epic: draggedCard?.epic[0]?._id,
                        },
                        _id: cardId,
                    })
                )

                const data = result?.payload?.data
                if (data) {
                    const { success } = data
                    if (success) {
                        dispatch(setPointerEvent(true))
                    } else {
                        dispatch(setPointerEvent(true))
                        message.error('Something went wrong, try again later.')
                    }
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [tasks, dispatch]
    )

    // UPDATE TASK ASSIGNEE
    async function updateTaskAssignee(value, Id) {
        const result = await dispatch(
            taskUpdate({
                updateTask: {
                    assigneeId: value,
                },
                _id: Id,
            })
        )
        const data = result?.payload?.data
        const sprintId = JSON.parse(localStorage.getItem('currentSprint'))?.id

        if (data) {
            const { success } = data
            if (success) {
                dispatch(
                    await taskList({
                        limit: pageLimit,
                        skip: skipPage,
                        filter: {
                            projectId: JSON.parse(
                                localStorage.getItem('currentProject')
                            )?.id,
                            sprintId:
                                localStorage.getItem('currentSprint') &&
                                sprintId,
                        },
                    })
                )
            } else {
                message.error('Something went wrong, try again later.')
            }
        }
    }

    const DeleteTaskComponent = () => 'Delete'

    const onDragEnd = useCallback(
        (draggable) => {
            const { draggableId } = draggable

            if (!draggable?.destination) {
                return
            } else {
                const source = draggable?.source
                const destination = draggable?.destination
                if (source !== null && destination !== null) {
                    if (
                        source?.droppableId === destination?.droppableId &&
                        source?.index === destination?.index
                    ) {
                        return
                    } else {
                        if (draggable?.type === 'cards') {
                            orderColumns(source?.index, destination?.index)
                        } else if (draggable?.type === 'column') {
                            orderCards(source, destination, draggableId)
                        }
                    }
                } else {
                    return
                }
            }
        },
        [orderColumns, orderCards]
    )

    const Card = ({ props }) => {
        const { card } = props
        const { _id, title, position, type } = card

        const [open, setOpen] = useState(false)

        const handleOpenChange = (newOpen) => {
            setOpen(newOpen)
            if (newOpen === true) {
                setDeleteCheck(true)
            } else {
                setDeleteCheck(false)
            }
        }

        const dropDownIcon = [
            {
                title: deletePermissionCheck('tasks', userProfile) ? (
                    <DeleteTaskComponent />
                ) : (
                    <Tooltip
                        arrow={false}
                        placement="bottom"
                        title="You don't have permission to delete task"
                    >
                        <Text
                            style={{
                                margin: '0px',
                            }}
                        >
                            Delete
                        </Text>
                    </Tooltip>
                ),
                icon: <DeleteIcon />,
                onClick: (_id) => {
                    if (deletePermissionCheck('tasks', userProfile)) {
                        setDeleteCheck(true)
                        setTaskID(_id)
                        setDeleteModal(true)
                    }
                },
            },
        ]

        const dropDownContent = (id) => {
            return (
                <>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            rowGap: '8px',
                        }}
                    >
                        {dropDownIcon?.length > 0 &&
                            dropDownIcon.map((data) => (
                                <Row
                                    style={{
                                        cursor: 'pointer',
                                    }}
                                    align={'middle'}
                                    onClick={() => data?.onClick(id)}
                                >
                                    <div
                                        style={{
                                            marginRight: '10px',
                                        }}
                                    >
                                        {data?.icon}
                                    </div>
                                    <Text className="sub-title">
                                        {data?.title}
                                    </Text>
                                </Row>
                            ))}
                    </div>
                </>
            )
        }

        return (
            <Draggable key={_id} draggableId={_id} index={position}>
                {(provided) => (
                    <div
                        className={styles.card}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        onClick={(e) => {
                            if (
                                !e.target.classList.contains(
                                    'active-tooltip'
                                ) &&
                                !e.target.classList.contains(
                                    'ant-avatar-string'
                                ) &&
                                // e.target.classList.contains(
                                //     'ant-popover-content'
                                // ) &&
                                e.target.localName !== 'svg' &&
                                e.target.localName !== 'path' &&
                                !deleteCheck
                            ) {
                                viewTask(_id)
                            }
                        }}
                    >
                        <Row justify={'space-between'} align={'middle'}>
                            <div
                                style={{
                                    backgroundColor: '#f5eef7',
                                    padding: '1px 5px 1px 5px',
                                    borderRadius: 3,
                                }}
                            >
                                <Text
                                    style={{
                                        color: '#981dad',
                                        textTransform: 'capitalize',
                                    }}
                                >
                                    {card?.epic[0]?.title}
                                </Text>
                            </div>
                            <Row
                                align={'middle'}
                                style={{
                                    columnGap: '10px',
                                }}
                            >
                                <Tooltip
                                    title={`${card?.priority} priority`}
                                    arrow={false}
                                >
                                    <div
                                        style={{
                                            cursor: 'auto',
                                        }}
                                    >
                                        {card?.priority === 'High' ? (
                                            <HighPriorityIcon />
                                        ) : card?.priority === 'Medium' ? (
                                            <MediumPriorityIcon />
                                        ) : card?.priority === 'Low' ? (
                                            <LowPriorityIcon />
                                        ) : card?.priority === 'Critical' ? (
                                            <CriticalPriorityIcon />
                                        ) : null}
                                    </div>
                                </Tooltip>

                                <Popover
                                    content={dropDownContent(_id)}
                                    trigger="click"
                                    className={styles.actionCardsTask}
                                    placement="bottom"
                                    open={open}
                                    overlayInnerStyle={{
                                        padding: '10px',
                                    }}
                                    arrow={false}
                                    onOpenChange={handleOpenChange}
                                >
                                    <div
                                        style={{
                                            cursor: 'pointer',
                                        }}
                                    >
                                        <EllipsisIcon />
                                    </div>
                                </Popover>
                            </Row>
                        </Row>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            <Text>{title}</Text>
                        </div>
                        <Row justify={'space-between'} align={'middle'}>
                            <Col>
                                <Row align={'middle'}>
                                    <Space size={'small'}>
                                        <Tooltip title={type} arrow={false}>
                                            {type === 'Bug' ? (
                                                <div
                                                    className={
                                                        styles.taskTypeSection
                                                    }
                                                    style={{
                                                        backgroundColor: 'red',
                                                    }}
                                                >
                                                    <BugOutlined
                                                        style={{
                                                            fontSize: '12px',
                                                            color: 'white',
                                                        }}
                                                    />
                                                </div>
                                            ) : type === 'Task' ? (
                                                <div
                                                    className={
                                                        styles.taskTypeSection
                                                    }
                                                    style={{
                                                        backgroundColor:
                                                            token.colorPalette
                                                                .baseColor
                                                                .tertiary,
                                                    }}
                                                >
                                                    <CheckOutlined
                                                        style={{
                                                            fontSize: '10px',
                                                            color: 'white',
                                                        }}
                                                    />
                                                </div>
                                            ) : (
                                                <div
                                                    className={
                                                        styles.taskTypeSection
                                                    }
                                                    style={{
                                                        backgroundColor:
                                                            '#7a16ff',
                                                    }}
                                                >
                                                    <ProjectOutlined
                                                        style={{
                                                            fontSize: '10px',
                                                            color: 'white',
                                                        }}
                                                    />
                                                </div>
                                            )}
                                        </Tooltip>

                                        <Text className={styles.keyText}>
                                            {card?.taskKey}
                                        </Text>
                                    </Space>
                                </Row>
                            </Col>
                            <Row align={'middle'}>
                                <Space size={'small'}>
                                    <Tooltip
                                        arrow={false}
                                        title={
                                            card?.estimation !== 0
                                                ? estimateFn(
                                                      card?.estimation,
                                                      currentProjectData
                                                  )
                                                : 'Unestimated'
                                        }
                                    >
                                        <Badge
                                            count={
                                                card?.estimation !== 0
                                                    ? estimateFn(
                                                          card?.estimation,
                                                          currentProjectData
                                                      )
                                                    : '-'
                                            }
                                            color="#85BBF9"
                                            style={{
                                                minWidth: '30px',
                                                cursor: 'auto',
                                            }}
                                        />
                                    </Tooltip>
                                    {card?.assigneeId ? (
                                        <div>
                                            {/* <Popover
                                                className={styles.popOverOut}
                                                overlayInnerStyle={{
                                                    padding: 5,
                                                }}
                                                content={filteredAssignees
                                                    .filter(
                                                        (data) =>
                                                            data?.value !==
                                                            card?.assigneeId[0]
                                                                ?._id
                                                    )
                                                    .map((option) => {
                                                        return (
                                                            <Row
                                                                className={
                                                                    styles.popOverClick
                                                                }
                                                                style={{
                                                                    cursor: 'pointer',
                                                                    padding: 10,
                                                                    borderRadius: 5,
                                                                }}
                                                                onClick={() => {
                                                                    updateTaskAssignee(
                                                                        option?.value,
                                                                        card?._id
                                                                    )
                                                                }}
                                                            >
                                                                <Space size="small">
                                                                    <Avatar
                                                                        style={{
                                                                            backgroundColor:
                                                                                '#f56a00',
                                                                            cursor: 'pointer',
                                                                            height: '30px',
                                                                            width: '30px',
                                                                        }}
                                                                    >
                                                                        {option?.label[0].toUpperCase()}
                                                                    </Avatar>
                                                                    <Text
                                                                        style={{
                                                                            margin: 0,
                                                                        }}
                                                                    >
                                                                        {
                                                                            option?.label
                                                                        }
                                                                    </Text>
                                                                </Space>
                                                            </Row>
                                                        )
                                                    })}
                                                trigger="click"
                                                arrow={false}
                                                placement="bottom"
                                            > */}
                                            <Tooltip
                                                title={
                                                    card?.assigneeId?.length > 0
                                                        ? card?.assigneeId[0]
                                                              ?.name
                                                        : Object.keys(
                                                              card?.assigneeId
                                                          )?.length > 0
                                                        ? card?.assigneeId?.name
                                                        : 'Unassigned'
                                                }
                                            >
                                                {card?.assigneeId?.length > 0 ||
                                                Object.keys(card?.assigneeId)
                                                    ?.length > 0 ? (
                                                    card?.assigneeId[0]
                                                        ?.profilePicture ? (
                                                        <Image
                                                            src={
                                                                card
                                                                    ?.assigneeId[0]
                                                                    ?.profilePicture
                                                            }
                                                            height={'24px'}
                                                            width={'24px'}
                                                            preview={false}
                                                            style={{
                                                                borderRadius:
                                                                    '50%',
                                                            }}
                                                        />
                                                    ) : (
                                                        <Avatar
                                                            size={'small'}
                                                            style={{
                                                                cursor: 'pointer',
                                                                backgroundColor:
                                                                    'rgb(0 10 129 / 25%)',
                                                                height: '24px',
                                                                width: '24px',
                                                                minWidth:
                                                                    '24px',
                                                            }}
                                                            className="active-tooltip"
                                                        >
                                                            {card?.assigneeId
                                                                ?.length > 0 &&
                                                                card?.assigneeId[0]?.name[0].toUpperCase()}
                                                            {card?.assigneeId
                                                                ?.length > 0 &&
                                                                card?.assigneeId[0]?.name.split(
                                                                    ' '
                                                                )[1] !==
                                                                    undefined &&
                                                                card?.assigneeId[0]?.name.split(
                                                                    ' '
                                                                )[1] !== '' &&
                                                                card?.assigneeId[0]?.name
                                                                    .split(
                                                                        ' '
                                                                    )[1][0]
                                                                    .toUpperCase()}
                                                        </Avatar>
                                                    )
                                                ) : (
                                                    <Avatar size={'small'}>
                                                        <UserOutlined />
                                                    </Avatar>
                                                )}
                                            </Tooltip>
                                            {/* </Popover> */}
                                        </div>
                                    ) : (
                                        <Tooltip title={'Unassigned'}>
                                            <Avatar
                                                size={'default'}
                                                icon={<UserOutlined />}
                                            />
                                        </Tooltip>
                                    )}
                                </Space>
                            </Row>
                        </Row>
                    </div>
                )}
            </Draggable>
        )
    }

    const Column = ({ props }) => {
        const { column } = props

        const { list, position, _id } = column

        let countTask = list.filter((item) => item !== null)

        return (
            <Draggable key={_id} draggableId={_id} index={position}>
                {(provided) => (
                    <Col
                        xs={12}
                        sm={12}
                        md={12}
                        lg={10}
                        xl={6}
                        xxl={5}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{
                            height: 'max-content',
                            ...provided.draggableProps.style,
                            pointerEvents: pointerEvent ? 'auto' : 'none',
                        }}
                    >
                        <Row
                            align={'middle'}
                            justify={'space-between'}
                            style={{
                                padding: '16px',
                                borderTopLeftRadius: 12,
                                borderTopRightRadius: 12,
                                backgroundColor:
                                    token.colorPalette.baseColor.quaternary,
                            }}
                        >
                            <div
                                className={'sub-title'}
                                style={{
                                    padding: '4px 12px',
                                    borderRadius: 1000,
                                    backgroundColor:
                                        _id === 'To Do'
                                            ? '#d5d5d5'
                                            : _id === 'Done'
                                            ? '#C8E5CE'
                                            : colorTaskRandom[
                                                  (Math.random() *
                                                      colorTaskRandom.length) |
                                                      0
                                              ],
                                }}
                            >
                                {_id} ({countTask?.length || 0})
                            </div>

                            <Divider
                                style={{
                                    marginBottom: '0px',
                                }}
                            />
                        </Row>
                        <Droppable
                            droppableId={_id}
                            type={'column'}
                            direction={'vertical'}
                        >
                            {(provided) => (
                                <div
                                    ref={provided.innerRef}
                                    className={styles.column}
                                    {...provided.droppableProps}
                                >
                                    {list?.length > 0 &&
                                        list.map(
                                            (card, index) =>
                                                card?.parentTask?.length ===
                                                    0 && (
                                                    <Card
                                                        key={index}
                                                        props={{ card }}
                                                    />
                                                )
                                        )}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </Col>
                )}
            </Draggable>
        )
    }

    return (
        <>
            <DeletePopUp
                previewDelete={deleteModal}
                onDelete={() => {
                    setDeleteButtonLoading(true)
                    deleteTask()
                    setDeleteCheck(false)
                }}
                loadingButton={deleteButtonLoading}
                onCancel={() => {
                    setDeleteCheck(false)
                    setDeleteButtonLoading(false)
                    setDeleteModal(false)
                }}
                modalTitle="Delete Task"
                pageKey="Task"
            />
            <div
                className={'sub-container'}
                style={{
                    overflowX: 'scroll',
                    height: spread ? 'max-content' : 'calc(100vh - 140px)',
                    pointerEvents: pointerEvent ? 'auto' : 'none',
                }}
            >
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable
                        droppableId={'cards'}
                        type={'cards'}
                        direction={'horizontal'}
                    >
                        {(provided) => (
                            <Row
                                wrap={false}
                                gutter={14}
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                            >
                                {tasks?.map((column, index) => (
                                    <Column key={index} props={{ column }} />
                                ))}
                                {provided.placeholder}
                            </Row>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
        </>
    )
}

export default TaskBoard
