import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

// antd
import {
    Button,
    Divider,
    Image,
    Popover,
    Row,
    Typography,
    message,
    theme,
} from 'antd'

// slices
import { loginState } from '../../modules/login/login.slice'
import {
    asCurrentProject,
    changePage,
    clearProjectCreate,
    projectsState,
    setCurrentProject,
} from '../../modules/projects/projects.slice'

// routes
import { PRIVATE_ROOT_ROUTE, PRIVATE_ROUTES, ROOT_ROUTE } from '../../routes'

// assets
import darkLogo from '../../assets/images/logo_dark.png'

// constants
import {
    DOMAIN_LIST,
    MEMBER,
    SUPER_ADMIN,
    WORKSPACE_ADMIN,
} from '../../constants/roles'

// helpers
import { checkNavigationHeader } from '../../helpers/checkNavigationPermission'

const HeaderLeftSection = ({ props }) => {
    const { filteredProjects, setState, state } = props
    const { Text, Title } = Typography

    const { useToken } = theme

    const { token } = useToken()

    const dispatch = useDispatch()

    const navigate = useNavigate()

    const params = useLocation()

    const { pageLimit } = useSelector(projectsState)
    const { userProfile, status } = useSelector(loginState)

    const [widthSelected, setWidthSelected] = useState(0)
    const [projectDisable, setProjectDisable] = useState(false)

    const array = checkNavigationHeader(userProfile)

    useEffect(() => {
        for (let obj of array) {
            if (
                obj.hasOwnProperty('key') &&
                params.pathname.includes(obj['key'])
            ) {
                let element = document.querySelector(
                    `.selectedHeader-${
                        params?.pathname.split(
                            `${PRIVATE_ROOT_ROUTE}${ROOT_ROUTE}`
                        )[1]
                    }`
                )
                if (element) {
                    let textWidth = element.getBoundingClientRect().width
                    setWidthSelected(textWidth)
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params?.pathname])

    // SELECT CURRENT PROJECT
    const currentProject = async (id, name) => {
        setProjectDisable(true)
        const result = await dispatch(
            asCurrentProject({
                id: id,
                project: {
                    isActive: true,
                },
            })
        )
        const data = result?.payload?.data
        if (data) {
            const { success, message: checkMessage } = data
            if (success) {
                localStorage.setItem(
                    'currentProject',
                    JSON.stringify({ id: id, name: name })
                )
                // dispatch(totalProjects())
                // dispatch(projectsList({ limit: pageLimit, skip: 0 }))
                dispatch(
                    changePage({
                        currentPage: 1,
                        skip: 0,
                        pageLimit,
                    })
                )
                localStorage.setItem('currentSprint', JSON.stringify({}))
                message.success(checkMessage)
                setTimeout(() => {
                    setProjectDisable(false)
                    window.location.reload()
                }, 1000)
            } else {
                setProjectDisable(false)
                if (checkMessage) {
                    message.error(checkMessage)
                } else {
                    message.error('Something went wrong, try again later.')
                }
            }
        }
        dispatch(
            setCurrentProject({
                id: id,
                name: name,
            })
        )
    }

    const contentProjectsDropdown = (route) => {
        return (
            <>
                {filteredProjects?.length > 0 && (
                    <>
                        <div style={{ padding: '25px' }}>
                            <Title level={5}>RECENT</Title>
                            {filteredProjects.map((data) => (
                                <>
                                    <Row
                                        style={{
                                            marginTop: '20px',
                                            columnGap: '10px',
                                            cursor: projectDisable
                                                ? 'not-allowed'
                                                : 'pointer',
                                        }}
                                        className={
                                            userProfile?.activeProject?._id ===
                                            data?._id
                                                ? 'activeProjectHighlight'
                                                : ''
                                        }
                                        align={'middle'}
                                        onClick={() => {
                                            if (
                                                userProfile?.activeProject
                                                    ?._id !== data?._id &&
                                                !projectDisable
                                            ) {
                                                currentProject(
                                                    data?._id,
                                                    data?.name
                                                )
                                            }
                                        }}
                                        key={data?._id}
                                    >
                                        <Image
                                            src={data?.projectProfile}
                                            height={'35px'}
                                            width={'35px'}
                                            preview={false}
                                            style={{
                                                objectFit: 'cover',
                                                borderRadius: '5px',
                                                minWidth: '35px',
                                            }}
                                        />
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                            }}
                                        >
                                            <Title level={5}>
                                                {data?.name}{' '}
                                                {`(${data?.projectKey})`}
                                            </Title>
                                            <Text
                                                style={{
                                                    fontSize:
                                                        token.fontSizeHeading5,
                                                }}
                                                className="titleLight"
                                            >
                                                {data?.projectType}
                                            </Text>
                                        </div>
                                    </Row>
                                </>
                            ))}
                        </div>
                        <Divider
                            style={{
                                margin: '0px',
                                background: token.colorPalette.textColor.senary,
                            }}
                        />
                    </>
                )}
                <div
                    style={{
                        padding: '20px 25px',
                    }}
                >
                    {filteredProjects?.length > 0 && (
                        <Title
                            level={5}
                            style={{
                                cursor: 'pointer',
                            }}
                            onClick={() => {
                                navigate(route)
                            }}
                        >
                            View All Projects
                        </Title>
                    )}
                    <Title
                        level={5}
                        style={{
                            cursor: 'pointer',
                            marginTop:
                                filteredProjects?.length > 0 ? '10px' : '0px',
                        }}
                        onClick={() => {
                            setState({ ...state, projectFormModal: true })
                            dispatch(clearProjectCreate())
                            // navigate(PRIVATE_ROUTES.projects.root)
                        }}
                    >
                        Create a Project
                    </Title>
                </div>
            </>
        )
    }

    // HEADER NAVIGATION
    const headerNavigation = () => {
        return (
            array?.length > 0 &&
            array.map((data) => (
                <Row
                    style={{
                        columnGap: '40px',
                    }}
                    key={data?.key}
                >
                    {data?.key === 'projects' ? (
                        <Popover
                            placement="bottomLeft"
                            content={contentProjectsDropdown(data?.route)}
                            trigger="hover"
                            arrow={false}
                            overlayInnerStyle={{
                                padding: '0',
                            }}
                        >
                            <Button
                                type="link"
                                style={{
                                    color: token.colorPalette.textColor.primary,
                                    padding: '0px',
                                    fontWeight: token.fontWeightStrong,
                                    fontSize: '18px',
                                }}
                                className={`selectedHeader-${data?.key}`}
                            >
                                {data?.label}
                            </Button>
                            {params.pathname.includes(data?.key) && (
                                <div
                                    style={{
                                        position: 'absolute',
                                        width: `${widthSelected}px`,
                                        height: '3px',
                                        bottom: '0px',
                                        backgroundColor:
                                            token.colorPalette.baseColor
                                                .tertiary,
                                    }}
                                ></div>
                            )}
                        </Popover>
                    ) : (
                        <>
                            <Text
                                style={{
                                    cursor: 'pointer',
                                    fontWeight: 600,
                                }}
                                className={`titleSub selectedHeader-${data?.key}`}
                                onClick={(e) => {
                                    navigate(data?.route)
                                    setWidthSelected(
                                        e.target.getBoundingClientRect().width
                                    )
                                }}
                            >
                                {data?.label}
                            </Text>
                            {params.pathname.includes(data?.key) && (
                                <div
                                    style={{
                                        position: 'absolute',
                                        width: `${widthSelected}px`,
                                        height: '3px',
                                        bottom: '0px',
                                        backgroundColor:
                                            token.colorPalette.baseColor
                                                .tertiary,
                                    }}
                                ></div>
                            )}
                        </>
                    )}
                </Row>
            ))
        )
    }

    return (
        <>
            <Row align={'middle'} style={{ gap: '60px' }}>
                <img
                    alt=""
                    src={
                        userProfile?.currentWorkSpace
                            ? userProfile?.currentWorkSpace?.logo
                            : darkLogo
                    }
                    style={{
                        height: 24,
                        cursor: 'pointer',
                        width: '160px',
                        minWidth: '160px',
                        objectFit: 'contain',
                    }}
                    onClick={() => {
                        if (userProfile?.role?.roleName === SUPER_ADMIN) {
                            navigate(PRIVATE_ROUTES.workSpace)
                        } else if (
                            userProfile?.activeProject === null &&
                            userProfile?.role?.roleName === MEMBER &&
                            DOMAIN_LIST.includes(
                                userProfile?.currentWorkSpace?.domain
                            )
                        ) {
                            navigate(`${PRIVATE_ROUTES.profile}`)
                        } else if (userProfile?.activeProject === null) {
                            navigate(`${PRIVATE_ROUTES.projects.root}`)
                        } else {
                            navigate(`${PRIVATE_ROUTES.overview}`)
                        }
                    }}
                />
                {status === 'loaded' &&
                userProfile?.role?.roleName !== undefined &&
                ((userProfile?.role?.roleName === SUPER_ADMIN &&
                    !DOMAIN_LIST.includes(
                        userProfile?.currentWorkSpace?.domain
                    )) ||
                    (userProfile?.role?.roleName === MEMBER &&
                        userProfile?.activeProject !== null) ||
                    (userProfile?.role?.roleName !== SUPER_ADMIN &&
                        userProfile?.role?.roleName !== MEMBER &&
                        userProfile?.role?.roleName !== WORKSPACE_ADMIN &&
                        userProfile?.activeProject !== null)) ? (
                    headerNavigation()
                ) : userProfile?.role?.roleName !== undefined &&
                  userProfile?.role?.roleName === WORKSPACE_ADMIN &&
                  userProfile?.activeProject === null &&
                  !DOMAIN_LIST.includes(
                      userProfile?.currentWorkSpace?.domain
                  ) ? (
                    array?.length > 0 &&
                    array.map((data) => (
                        <Row
                            style={{
                                columnGap: '40px',
                            }}
                            key={data?.key}
                        >
                            {data?.key === 'projects' && (
                                <Popover
                                    placement="bottomLeft"
                                    content={contentProjectsDropdown(
                                        data?.route
                                    )}
                                    trigger="hover"
                                    arrow={false}
                                    overlayInnerStyle={{
                                        padding: '0',
                                    }}
                                >
                                    <Button
                                        type="link"
                                        style={{
                                            color: token.colorPalette.textColor
                                                .primary,
                                            padding: '0px',
                                            fontWeight: token.fontWeightStrong,
                                            fontSize: '18px',
                                        }}
                                    >
                                        {data?.label}
                                    </Button>
                                </Popover>
                            )}
                        </Row>
                    ))
                ) : userProfile?.role?.roleName !== undefined &&
                  userProfile?.role?.roleName === WORKSPACE_ADMIN &&
                  userProfile?.activeProject !== null ? (
                    headerNavigation()
                ) : (
                    <></>
                )}
            </Row>
        </>
    )
}

export default HeaderLeftSection
