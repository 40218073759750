import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// antd
import {
    Button,
    Checkbox,
    Form,
    Row,
    Space,
    Spin,
    Typography,
    message,
    theme,
} from 'antd'

// constants
import { modulePermissions } from '../../constants/modulesPermission'

// styles
import styles from './workSpace.module.css'

// slices
import {
    checkButtonType,
    switchPermissionModal,
    workSpaceDataListCount,
    workSpaceListGet,
    workSpaceUpdate,
    workspaceState,
} from './workSpace.slice'

function PermissionModalContent() {
    const { Text } = Typography

    const [form] = Form.useForm()

    const dispatch = useDispatch()

    const { useToken } = theme
    const { token } = useToken()

    const {
        selectedData,
        permissionModalVariations,
        pageLimit,
        skipPage,
        search,
        updatedDataStatus,
    } = useSelector(workspaceState)

    const [permissionsData, setPermissionsData] = useState(
        modulePermissions.sort((a, b) => a.position - b.position)
    )

    useEffect(() => {
        if (selectedData) {
            const output = permissionsData.map((data) => {
                const found = selectedData?.permission.find(
                    (a) => a === data?.value
                )
                if (found) {
                    return { ...data, checked: true }
                } else {
                    return { ...data, checked: false }
                }
            })
            setPermissionsData(output)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedData])

    // SELECT ALL CHECKBOX
    function selectAllCheckbox() {
        const changePermission = permissionsData.map((data) => {
            return { ...data, checked: true }
        })
        setPermissionsData(changePermission)
    }

    // DE SELECT ALL CHECKBOX
    function deSelectAllCheckbox() {
        const changePermission = permissionsData.map((data) => {
            return { ...data, checked: false }
        })
        setPermissionsData(changePermission)
    }

    function onFinishFailed() {}

    // SAVE PERMISSION CHANGE
    async function savePermission() {
        dispatch(
            switchPermissionModal({
                ...permissionModalVariations,
                saveButtonLoading: true,
            })
        )

        const permissionDataNew = permissionsData
            .filter((data) => data?.checked)
            .map((data) => data?.value)

        const result = await dispatch(
            workSpaceUpdate({
                editData: {
                    permission: permissionDataNew,
                },
                _id: selectedData?._id,
            })
        )
        const data = result?.payload?.data
        if (data) {
            const { success, message: checkMessage } = data
            if (success) {
                dispatch(workSpaceDataListCount())
                dispatch(
                    workSpaceListGet({
                        limit: pageLimit,
                        skip: skipPage,
                        search: search,
                    })
                )
                message.success(checkMessage)
                dispatch(
                    switchPermissionModal({
                        ...permissionModalVariations,
                        saveButtonLoading: false,
                        open: false,
                    })
                )
            } else {
                dispatch(
                    switchPermissionModal({
                        ...permissionModalVariations,
                        saveButtonLoading: false,
                        open: false,
                    })
                )
                if (checkMessage) {
                    message.error(checkMessage)
                } else {
                    message.error('Something went wrong, try again later.')
                }
            }
        }
    }

    // CHECK DISABLE LOGIC OF CHECKBOX
    const isParentUnchecked = (item) => {
        if (item.isParent || !item.parentName) return false
        const parent = permissionsData.find(
            (obj) => obj.value === item.parentName
        )
        return parent ? !parent.checked : false
    }

    return updatedDataStatus === 'loading' ? (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
            }}
        >
            <Spin size="large" />
        </div>
    ) : (
        <>
            <Row
                style={{
                    marginBottom: '23px',
                }}
            >
                <Space size={'small'}>
                    <Button
                        className={styles.permissionButtons}
                        onClick={() => selectAllCheckbox()}
                    >
                        Select All
                    </Button>
                    <Button
                        className={styles.permissionButtons}
                        onClick={() => deSelectAllCheckbox()}
                    >
                        Unselect All
                    </Button>
                </Space>
            </Row>
            <Form
                labelCol={{
                    style: {
                        padding: '0 0 3px',
                    },
                }}
                layout="vertical"
                name="permissionDataForm"
                onFinish={savePermission}
                onFinishFailed={onFinishFailed}
                onSubmitCapture={async (e) => {
                    await dispatch(checkButtonType(e.nativeEvent.submitter.id))
                }}
                form={form}
                requiredMark={false}
                style={{
                    overflowX: 'hidden',
                    marginBottom: '10px',
                }}
            >
                <Row>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            rowGap: '11px',
                            columnGap: '50px',
                            maxHeight: '152px',
                            flexWrap: 'wrap',
                        }}
                    >
                        {permissionsData?.length > 0 &&
                            permissionsData.map((data) => (
                                <Checkbox
                                    checked={data?.checked}
                                    onChange={() => {
                                        const newArr = permissionsData.map(
                                            (obj) => {
                                                if (obj.value === data?.value) {
                                                    return {
                                                        ...obj,
                                                        checked: !data?.checked,
                                                    }
                                                }
                                                // Uncheck child if parent is unchecked
                                                if (
                                                    obj.isParent === false &&
                                                    obj.checked === true &&
                                                    obj.parentName ===
                                                        data.value
                                                ) {
                                                    return {
                                                        ...obj,
                                                        checked: false,
                                                    }
                                                }
                                                return obj
                                            }
                                        )

                                        setPermissionsData(newArr)
                                    }}
                                    disabled={isParentUnchecked(data)}
                                >
                                    <Text
                                        style={{
                                            color: token.colorPalette.textColor
                                                .primary,
                                        }}
                                    >
                                        {data?.label}
                                    </Text>
                                </Checkbox>
                            ))}
                    </div>
                </Row>
            </Form>
        </>
    )
}

export default PermissionModalContent
