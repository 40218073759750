import axios from 'axios'

// const token = localStorage.getItem('authToken')

export const getToken = () =>
    localStorage.getItem('authToken')
        ? JSON.parse(localStorage.getItem('authToken'))
        : null

export const getAuthorizationHeader = () => `Bearer ${getToken()}`
axios.defaults.withCredentials = true

const axiosNew = axios.create({
    baseURL:
        window.location.hostname === 'localhost'
            ? process.env.REACT_APP_URL + '/backend'
            : `${window.location.origin}/backend`,
    headers: {
        'Content-Type': 'application/json',
    },
    // withCredentials: 'include',
    method: 'post',
})

axiosNew.interceptors.request.use((config) => {
    // const user = JSON.parse(localStorage.getItem('authToken'))

    // if (user?.length > 0) {
    // config.headers.Authorization = `Bearer ${user}`
    // }
    return config
})
export default axiosNew
