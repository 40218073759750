import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { debounce } from 'lodash'

// antd
import { Form, Row, Select, Spin, Typography, message, theme } from 'antd'

// slices
import {
    workSpaceAssign,
    workSpaceListGet,
    workspaceState,
} from '../workSpace/workSpace.slice'
import { talentState } from './talent.slice'

// components
import Button from '../../components/button/button'

const WorkspaceAssignModal = ({ cancelModal, refreshTalent }) => {
    const { useToken } = theme
    const { token } = useToken()

    const dispatch = useDispatch()

    const [form] = Form.useForm()

    const { pageLimit, skipPage } = useSelector(workspaceState)
    const { selectedData } = useSelector(talentState)

    const [fetching, setFetching] = useState(false)
    const [options, setOptions] = useState([])
    const [buttonLoading, setButtonLoading] = useState(false)
    const [errorMessages, setErrorMessages] = useState([]) // State for error messages
    const [successMessages, setSuccessMessages] = useState([])

    const { Title } = Typography

    // SEARCH WORKSPACE
    const searchFn = debounce(async (value) => {
        // setSearchValue(value)
        if (value?.length > 0) {
            setFetching(true)
            const result = await dispatch(
                workSpaceListGet({
                    limit: pageLimit,
                    skip: skipPage,
                    search: value,
                })
            )

            const data = result?.payload?.data

            if (data) {
                const { success } = data
                if (success) {
                    setFetching(false)
                    const filterData =
                        data?.result?.length > 0 &&
                        data?.result.map((workSpace) => {
                            return {
                                label: workSpace?.companyName,
                                value: workSpace?._id,
                                logo: workSpace?.logo,
                                domain: workSpace?.domain,
                            }
                        })
                    setOptions(filterData)
                } else {
                    setOptions([])
                    setFetching(false)
                }
            }
        } else {
            setOptions([])
        }
    }, 800)

    // ASSIGN WORKSPACE
    const assignWorkSpace = async (value) => {
        console.log('value', value)
        setButtonLoading(true)
        value.workSpace = value?.workSpace?.map((w) => {
            return w
        })
        const result = await dispatch(
            workSpaceAssign({
                workspaceId: value?.workSpace,
                userId: selectedData?._id,
            })
        )
        const data = result?.payload?.data
        console.log('data', data)

        if (data && Array.isArray(data.data)) {
            let allSuccess = true
            let errors = []
            let successMessage = []

            data.data.forEach((item) => {
                const { success, message: checkMessage } = item

                if (success) {
                    successMessage.push(
                        `${item?.name || ''}: ${
                            checkMessage || 'Workspace assign successfully'
                        }`
                    )
                } else {
                    allSuccess = false
                    errors.push(
                        `${item?.name || ''}: ${
                            checkMessage ||
                            'Something went wrong, try again later.'
                        }`
                    )
                }
            })

            // Set errors in state to display in modal if any
            if (errors.length > 0) {
                setErrorMessages(errors)
            }

            // Set success in state to display in modal if any
            if (successMessage.length > 0) {
                setSuccessMessages(successMessage)
            }

            setButtonLoading(false)

            if (allSuccess) {
                refreshTalent()
                cancelModal()
            }
        } else {
            const { success, message: checkMessage } = data
            if (success) {
                message.success(checkMessage)
                refreshTalent()
                cancelModal()
                setButtonLoading(false)
            } else {
                setButtonLoading(false)
                cancelModal()
                if (checkMessage) {
                    message.error(checkMessage)
                } else {
                    message.error('Something went wrong, try again later.')
                }
            }
        }
    }

    function onFinishFailed() {}

    return (
        <>
            <Title
                level={4}
                style={{
                    textAlign: 'center',
                }}
            >
                Workspace List
            </Title>
            <Form
                name="assignWorkSpace"
                onFinish={assignWorkSpace}
                onFinishFailed={onFinishFailed}
                form={form}
                requiredMark={false}
            >
                <Form.Item
                    name={'workSpace'}
                    rules={[
                        {
                            required: true,
                            message: 'Please Select WorkSpace',
                        },
                    ]}
                >
                    <Select
                        mode="multiple"
                        filterOption={false}
                        onSearch={searchFn}
                        placeholder="Search Workspace"
                        style={{
                            width: '100%',
                            marginTop: '33px',
                        }}
                        showSearch
                        notFoundContent={
                            fetching ? <Spin size="small" /> : null
                        }
                        options={options}
                    />
                </Form.Item>
                <Row
                    align={'middle'}
                    justify={'start'}
                    style={{
                        marginTop: '10px',
                        color: 'green',
                    }}
                >
                    <div>
                        {successMessages?.length > 0 &&
                            successMessages.map((msg, index) => (
                                <div key={index}>{msg}</div> // Display each message in a separate line
                            ))}
                    </div>
                </Row>
                <Row
                    align={'middle'}
                    justify={'start'}
                    style={{
                        color: 'red',
                    }}
                >
                    <div>
                        {errorMessages?.length > 0 &&
                            errorMessages.map((msg, index) => (
                                <div key={index}>{msg}</div> // Display each message in a separate line
                            ))}
                    </div>
                </Row>
                <Row
                    align={'middle'}
                    justify={'end'}
                    style={{
                        marginTop: '34px',
                    }}
                >
                    <Button
                        props={{
                            text: 'Cancel',
                            onClick: () => cancelModal(),
                            buttonType: 'link',
                            style: {
                                color: token.colorPalette.baseColor.black,
                            },
                        }}
                    />
                    <Button
                        props={{
                            text: 'Assign',
                            htmlType: 'submit',
                            loadingButton: buttonLoading,
                        }}
                    />
                </Row>
            </Form>
        </>
    )
}

export default WorkspaceAssignModal
