import { useState, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import S3FileUpload from 'react-s3'
import ReactQuill from 'react-quill'

// antd
import {
    Form,
    Row,
    Typography,
    message,
    theme,
    Upload,
    Drawer,
    Select,
} from 'antd'

// slices
import { loginState } from '../login/login.slice'
import {
    changePage,
    clearRecordings,
    clearScreen,
    previewVideoModal,
    setFilterUser,
    standUpCreate,
    standUpGet,
    standUpsState,
    switchFormDrawer,
} from './standups.slice'
import { profileState, switchVideoPlayModal } from '../profile/profile.slice'
import { taskList, tasksState } from '../tasks/tasks.slice'

// components
import Button from '../../components/button/button'
import DeletePopUp from '../../components/deletePopUp/deletePopUp'

// constants
import { STAND_UP_POST } from '../../constants/standUps'

// section
import VideoRecordStandUp from './videoRecordstandUp.section'

// assets
import {
    BinIcon,
    StandUpIcon,
    UploadFileIcon,
    VideoIcon,
    VideosIcon,
} from '../../assets/icons'

// helpers
import { editFieldCheck } from '../../helpers/permissionCheck'
import { cleanDescription } from '../../helpers/standUpCleanDescription'

// styles
import styles from './standUps.module.css'

import dayjs from 'dayjs'

const { Option } = Select

const StandUpFormDrawer = () => {
    const { useToken } = theme
    const { token } = useToken()

    const draggerRef = useRef()

    const dispatch = useDispatch()

    const { Title, Text } = Typography

    const { Dragger } = Upload

    // SELECTORS
    const { userProfile } = useSelector(loginState)
    const {
        previewVideo,
        pageLimitStandUps,
        recordings,
        timeStamp,
        formDrawer,
    } = useSelector(standUpsState)
    const { introductionVideoModal } = useSelector(profileState)
    const { tasks, skipPage, pageLimit } = useSelector(tasksState)

    const initialState = {
        fileList: [],
        stream: false,
        fileURL: [],
        videoRecorder: false,
        submitLoader: false,
        description: STAND_UP_POST,
        deleteModal: false,
        deleteButtonLoading: false,
        errors: [],
        taskList: [],
        selectedTaskWithStandup: {
            yesterday: [],
            today: [],
            block: [],
        },
    }

    const [state, setState] = useState(initialState)

    const isEmpty = Object.values(recordings).some((value) => value === '')

    // CHECK CAMERA AND MICROPHONE PERMISSIONS
    async function checkPermission() {
        navigator.mediaDevices
            .getUserMedia({ video: true, audio: true })
            .then(function () {
                // Permission granted for both camera and microphone
                // Proceed with further actions here if needed
                if (!state?.stream) {
                    setState((prevState) => ({
                        ...prevState,
                        videoRecorder: true,
                    }))
                }
            })
            .catch(function () {
                // Permission denied or error occurred
                // Check if permission was denied for camera or microphone
                message.info(
                    'Please Grant Permission of Camera and Microphone from the Settings'
                )
            })
    }

    // FILE UPLOAD PROPS
    const props = {
        name: 'files',
        multiple: false,
        onChange(info) {
            const isLt16M = info?.file.size / 1024 / 1024 < 16
            if (!isLt16M) {
                message.error('Video must be smaller than 16MB')
                return
            } else {
                setState((prevState) => ({
                    ...prevState,
                    fileURL: [],
                    stream: true,
                }))
                const nameChange = info?.fileList[
                    info?.fileList?.length - 1
                ]?.name
                    .split('.')[0]
                    .concat(`_${dayjs(new Date()).unix()}`)
                    .concat(`.${info?.fileList[0]?.name.split('.')[1]}`)

                const newFileData = new File(
                    [info?.fileList[info?.fileList?.length - 1]?.originFileObj],
                    nameChange,
                    { type: info?.fileList[info?.fileList?.length - 1]?.type }
                )
                window.Buffer = window.Buffer || require('buffer').Buffer

                S3FileUpload.uploadFile(newFileData, userProfile?.s3Config)
                    .then(async (data) => {
                        setState((prevState) => ({
                            ...prevState,
                            stream: false,
                            fileURL: [data?.location],
                        }))
                        dispatch(clearScreen())
                    })
                    .catch(() => {
                        message.error('Upload Failed!. Please Upload again')
                    })
            }
        },

        beforeUpload(file) {
            setState((prevState) => ({
                ...prevState,
                fileList: [...prevState?.fileList, file],
            }))
            return false
        },

        onDrop() {},
    }

    // ON FINISH FAILED
    const onFinishFailed = () => {}

    // ON STANDUP POST
    const standUpPost = async () => {
        setState((prevState) => ({ ...prevState, submitLoader: true }))
        let newFilterValue = {}

        Object.keys(recordings).forEach((data) => {
            if (recordings[data] !== '') {
                newFilterValue = { recordings: recordings }
            }
        })

        let payload = {
            titleType: 'standup',
            userId: userProfile?._id,
            projectId: userProfile?.activeProject?._id,
            files: [...state?.fileURL],
            standup:
                state?.description?.length > 0
                    ? state?.description.map((task) => {
                          return {
                              ...task,
                              description: cleanDescription(task?.description),
                          }
                      })
                    : [],
        }

        if (state?.fileURL?.length === 0) {
            payload.recordings = {
                ...newFilterValue?.recordings,
            }
        } else {
            payload.files = [...state?.fileURL]
        }

        payload.standup.forEach((item) => {
            let key = item.label
            if (
                state.selectedTaskWithStandup[key] &&
                state.selectedTaskWithStandup[key].length > 0
            ) {
                if (!item.comment) {
                    item.comment = {}
                }
                item.comment.taskId = state.selectedTaskWithStandup[key]
                item.comment.uId = userProfile?._id
                item.comment.username = userProfile?.name
                item.comment.createdDate = new Date().getTime()
                item.comment.content = ` Posted in standup:- ${item.description}`
            }
        })

        const result = await dispatch(standUpCreate(payload))
        const data = result?.payload?.data
        if (data) {
            const { success, message: checkMessage } = data
            if (success) {
                dispatch(setFilterUser(null))
                setState((prevState) => ({
                    ...prevState,
                    submitLoader: false,
                    selectedTaskWithStandup: {
                        yesterday: [],
                        today: [],
                        block: [],
                    },
                }))
                // refreshFeeds()
                if (localStorage.getItem('videoShow')) {
                    dispatch(clearRecordings())
                }

                setState((prevState) => ({
                    ...prevState,
                    fileURL: [],
                    fileList: [],
                    description: STAND_UP_POST,
                    selectedTaskWithStandup: {
                        yesterday: [],
                        today: [],
                        block: [],
                    },
                }))
                dispatch(previewVideoModal(false))
                dispatch(
                    changePage({
                        currentPage: 1,
                        skip: 0,
                        pageLimit: pageLimitStandUps,
                    })
                )
                dispatch(switchFormDrawer({ open: false }))
                dispatch(
                    standUpGet({
                        limit: pageLimitStandUps,
                        skip: 0,
                        titleType: 'standup',
                        projectId: userProfile?.activeProject?._id,
                        ...timeStamp,
                    })
                )
                message.success(checkMessage)
            } else {
                setState((prevState) => ({
                    ...prevState,
                    submitLoader: false,
                    selectedTaskWithStandup: {
                        yesterday: [],
                        today: [],
                        block: [],
                    },
                }))
                dispatch(switchFormDrawer({ open: false }))
                dispatch(previewVideoModal(false))
                setState((prevState) => ({
                    ...prevState,
                    fileURL: [],
                    fileList: [],
                    description: STAND_UP_POST,
                    selectedTaskWithStandup: {
                        yesterday: [],
                        today: [],
                        block: [],
                    },
                }))
                if (typeof checkMessage === 'object') {
                    for (const key in checkMessage) {
                        message.error(checkMessage[key])
                    }
                } else if (typeof checkMessage === 'string') {
                    message.error(checkMessage)
                } else {
                    message.error('Something went wrong, try again later.')
                }
            }
        }
    }

    // DELETE THE VIDEO
    const deleteVideo = () => {
        setState((prevState) => ({
            ...prevState,
            deleteButtonLoading: true,
        }))
        if (state?.fileURL?.length > 0) {
            setState((prevState) => ({
                ...prevState,
                fileURL: [],
                fileList: [],
            }))
            setState((prevState) => ({
                ...prevState,
                deleteModal: false,
                deleteButtonLoading: false,
            }))
        } else {
            dispatch(clearScreen())
            setState((prevState) => ({
                ...prevState,
                deleteModal: false,
                deleteButtonLoading: false,
            }))
        }
    }

    useEffect(() => {
        if (formDrawer) {
            dispatch(
                taskList({
                    skip: skipPage,
                    limit: pageLimit,
                    filter: {
                        projectId: userProfile?.activeProject?._id,
                    },
                })
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formDrawer])

    useEffect(() => {
        let list = []

        if (tasks?.length > 0) {
            tasks?.map((task) => {
                list.push(...task?.list)
            })
        }

        if (list?.length > 0) {
            list = list.map((data) => {
                return {
                    label: `${data?.taskKey} ${data?.title}`,
                    value: data?._id,
                }
            })
        }

        setState((prevState) => ({
            ...prevState,
            taskList: list,
        }))
    }, [tasks])

    const handleSelectTasks = (event, data) => {
        setState((prevState) => ({
            ...prevState,
            selectedTaskWithStandup: {
                ...prevState?.selectedTaskWithStandup,
                [data]: event,
            },
        }))
    }

    return (
        <>
            <DeletePopUp
                previewDelete={state?.deleteModal}
                onDelete={() => {
                    deleteVideo()
                }}
                loadingButton={state?.deleteButtonLoading}
                onCancel={() => {
                    setState((prevState) => ({
                        ...prevState,
                        deleteModal: false,
                        deleteButtonLoading: false,
                    }))
                }}
                modalTitle="Delete Video"
                pageKey="Video"
            />
            <Drawer
                placement="right"
                size={'default'}
                style={{
                    height: '100vh',
                    minWidth: '950px',
                    width: '950px',
                }}
                styles={{
                    body: {
                        padding: '0px',
                        display: 'flex',
                        flexDirection: 'column',
                    },
                    wrapper: {
                        width: 'auto !important',
                    },
                }}
                rootClassName="standUpHeaderDrawer"
                onClose={() => {
                    dispatch(switchFormDrawer({ open: false }))
                    setState((prevState) => ({
                        ...prevState,
                        fileURL: [],
                        fileList: [],
                        description: STAND_UP_POST,
                    }))
                    dispatch(clearScreen())
                }}
                open={formDrawer}
            >
                {state?.videoRecorder && (
                    <VideoRecordStandUp
                        previewModal={state?.videoRecorder}
                        onFileUrl={() =>
                            setState((prevState) => ({
                                ...prevState,
                                fileURL: [],
                            }))
                        }
                        onCancel={() => {
                            setState((prevState) => ({
                                ...prevState,
                                videoRecorder: false,
                            }))
                        }}
                    />
                )}
                <Form
                    layout="vertical"
                    name="statusUpdate"
                    onFinishFailed={onFinishFailed}
                    onFinish={standUpPost}
                    initialValues={{
                        files: [],
                    }}
                    requiredMark={false}
                >
                    <div
                        style={{
                            backgroundColor:
                                token.colorPalette.baseColor.quaternary,
                            padding: '25px',
                        }}
                    >
                        <Row
                            align={'middle'}
                            style={{
                                columnGap: '5px',
                            }}
                        >
                            <div>
                                <StandUpIcon
                                    color={
                                        token.colorPalette.baseColor.tertiary
                                    }
                                />
                            </div>
                            <Title level={4}>Post Standup</Title>
                        </Row>
                    </div>

                    <div
                        style={{
                            padding: '30px 45px',
                        }}
                    >
                        <Row
                            justify={'space-between'}
                            align={'middle'}
                            style={{
                                width: '100%',
                                marginBottom: '35px',
                            }}
                        >
                            <Form.Item
                                name="files"
                                noStyle
                                className="fileDragger"
                            >
                                <Dragger
                                    {...props}
                                    showUploadList={false}
                                    style={{
                                        borderColor:
                                            token.colorPalette.baseColor
                                                .tertiary,
                                    }}
                                    ref={draggerRef}
                                    disabled={
                                        previewVideo ||
                                        editFieldCheck(
                                            'standups',
                                            'files',
                                            userProfile
                                        ) === false
                                    }
                                    accept=".MP4,.MOV,.WMV,.AVI,.AVCHD,.FLV,.F4V,.SWF,.MKV,.WEBM,.HTML5"
                                >
                                    <Row
                                        align={'middle'}
                                        style={{
                                            flexDirection: 'column',
                                            padding: '30px 60px',
                                        }}
                                    >
                                        <div
                                            style={{
                                                height: '25px',
                                                width: '25px',
                                                marginBottom: '10px',
                                            }}
                                        >
                                            <UploadFileIcon />
                                        </div>
                                        <p className="ant-upload-text">
                                            Click or drag file to upload
                                        </p>
                                    </Row>
                                </Dragger>
                            </Form.Item>
                            <Title level={4}>OR</Title>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    cursor:
                                        editFieldCheck(
                                            'standups',
                                            'files',
                                            userProfile
                                        ) === false
                                            ? 'not-allowed'
                                            : 'pointer',
                                    border: `1px dashed ${token.colorPalette.baseColor.tertiary}`,
                                    borderRadius: '6px',
                                    padding: '50px 118px',
                                    backgroundColor: 'rgba(0, 0, 0, 0.02)',
                                }}
                                onClick={() => {
                                    if (
                                        editFieldCheck(
                                            'standups',
                                            'files',
                                            userProfile
                                        ) === true
                                    ) {
                                        if (state?.fileURL?.length > 0) {
                                            message.info(
                                                'You have already uploaded video'
                                            )
                                        } else {
                                            checkPermission()
                                        }
                                    }
                                }}
                            >
                                <div
                                    style={{
                                        height: '25px',
                                        marginBottom: '10px',
                                        color: '#375BFB',
                                        width: '25px',
                                    }}
                                >
                                    <VideoIcon />
                                </div>
                                <Text
                                    style={{
                                        fontSize: '16px',
                                        color: '#375BFB',
                                    }}
                                >
                                    Record Video
                                </Text>
                            </div>
                        </Row>
                        {state?.fileURL?.length > 0 && (
                            <div
                                style={{
                                    position: 'relative',
                                    height: '140px',
                                    width: '250px',
                                    marginBottom: '20px',
                                }}
                            >
                                <video
                                    src={state?.fileURL[0]}
                                    // autoPlay
                                    loop
                                    height="140px"
                                    width={'250px'}
                                    style={{
                                        borderRadius: '10px',
                                        objectFit: 'cover',
                                    }}
                                />
                                <div
                                    className={styles.videoPlayStandup}
                                    onClick={() =>
                                        dispatch(
                                            switchVideoPlayModal({
                                                ...introductionVideoModal,
                                                open: true,
                                                videoData: {
                                                    deleteId: '',
                                                    link: state?.fileURL[0],
                                                },
                                                title: 'Standup Video',
                                            })
                                        )
                                    }
                                >
                                    <VideosIcon
                                        color={
                                            token.colorPalette.textColor.quinary
                                        }
                                    />
                                </div>
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: 10,
                                        right: 10,
                                        cursor: 'pointer',
                                        height: '22px',
                                        width: '22px',
                                    }}
                                    onClick={() => {
                                        setState((prevState) => ({
                                            ...prevState,
                                            deleteModal: true,
                                        }))
                                    }}
                                >
                                    <BinIcon
                                        color={
                                            token.colorPalette.baseColor.error
                                        }
                                    />
                                </div>
                            </div>
                        )}
                        {!isEmpty && (
                            <div
                                style={{
                                    position: 'relative',
                                    height: '140px',
                                    width: '250px',
                                    marginBottom: '20px',
                                }}
                            >
                                <video
                                    src={recordings?.screen}
                                    loop
                                    height="140px"
                                    width={'250px'}
                                    style={{
                                        borderRadius: '10px',
                                        objectFit: 'cover',
                                    }}
                                />
                                <div
                                    className={styles.videoPlayStandup}
                                    onClick={() =>
                                        dispatch(
                                            switchVideoPlayModal({
                                                ...introductionVideoModal,
                                                open: true,
                                                videoData: {
                                                    deleteId: '',
                                                    link: [
                                                        recordings?.screen,
                                                        recordings?.face,
                                                    ],
                                                },
                                                title: 'Standup Video',
                                            })
                                        )
                                    }
                                >
                                    <VideosIcon
                                        color={
                                            token.colorPalette.textColor.quinary
                                        }
                                    />
                                </div>
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: 10,
                                        right: 10,
                                        cursor: 'pointer',
                                        height: '22px',
                                        width: '22px',
                                    }}
                                    onClick={() => {
                                        setState((prevState) => ({
                                            ...prevState,
                                            deleteModal: true,
                                        }))
                                    }}
                                >
                                    <BinIcon
                                        color={
                                            token.colorPalette.baseColor.error
                                        }
                                    />
                                </div>
                            </div>
                        )}

                        {STAND_UP_POST?.length > 0 &&
                            STAND_UP_POST.map((data) => {
                                return (
                                    <div
                                        key={data?.title}
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        <Text
                                            style={{
                                                color: token.colorPalette
                                                    .textColor.secondary,
                                            }}
                                        >
                                            {data?.title}
                                        </Text>
                                        {data?.isTaskList && (
                                            <>
                                                <Select
                                                    mode="multiple"
                                                    allowClear
                                                    style={{
                                                        width: '100%',
                                                        margin: 0,
                                                    }}
                                                    showSearch={true}
                                                    placeholder="Select task"
                                                    onChange={(event) =>
                                                        handleSelectTasks(
                                                            event,
                                                            data?.label
                                                        )
                                                    }
                                                    value={
                                                        state
                                                            ?.selectedTaskWithStandup[
                                                            data?.label
                                                        ] || []
                                                    }
                                                    rootClassName={[
                                                        'p-0-multiSelect',
                                                    ]}
                                                >
                                                    {state?.taskList.map(
                                                        (task) => (
                                                            <Option
                                                                key={
                                                                    task?.label
                                                                }
                                                                value={
                                                                    task?.value
                                                                }
                                                            >
                                                                <Text>
                                                                    {
                                                                        task?.label
                                                                    }
                                                                </Text>
                                                            </Option>
                                                        )
                                                    )}
                                                </Select>
                                            </>
                                        )}
                                        <Form.Item
                                            label=""
                                            colon={false}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: data?.message,
                                                },
                                            ]}
                                            name={data?.label}
                                            noStyle
                                        >
                                            {state?.description.find(
                                                (descriptionData) =>
                                                    descriptionData?.label ===
                                                    data?.label
                                            )?.open === true ? (
                                                <>
                                                    <ReactQuill
                                                        theme="snow"
                                                        className={
                                                            styles.description
                                                        }
                                                        onChange={(value) => {
                                                            const descriptionSetData =
                                                                state?.description.map(
                                                                    (
                                                                        descriptionData
                                                                    ) => {
                                                                        if (
                                                                            descriptionData?.label ===
                                                                            data?.label
                                                                        ) {
                                                                            return {
                                                                                ...descriptionData,
                                                                                description:
                                                                                    value,
                                                                            }
                                                                        } else {
                                                                            return descriptionData
                                                                        }
                                                                    }
                                                                )
                                                            setState(
                                                                (
                                                                    prevState
                                                                ) => ({
                                                                    ...prevState,
                                                                    description:
                                                                        descriptionSetData,
                                                                })
                                                            )
                                                        }}
                                                        placeholder={
                                                            'Description...'
                                                        }
                                                        style={{
                                                            margin: '10px 0px',
                                                        }}
                                                        modules={{
                                                            toolbar: [
                                                                [
                                                                    {
                                                                        list: 'ordered',
                                                                    },
                                                                    {
                                                                        list: 'bullet',
                                                                    },
                                                                ],
                                                                [
                                                                    {
                                                                        header: [
                                                                            1,
                                                                            2,
                                                                            false,
                                                                        ],
                                                                    },
                                                                ],
                                                                [
                                                                    'bold',
                                                                    'italic',
                                                                    'underline',
                                                                    'strike',
                                                                    'blockquote',
                                                                ],
                                                                ['link'],
                                                                [
                                                                    {
                                                                        indent: '-1',
                                                                    },
                                                                    {
                                                                        indent: '+1',
                                                                    },
                                                                ],
                                                                [{ align: [] }],
                                                                ['clean'],
                                                            ],
                                                        }}
                                                        formats={[
                                                            'list',
                                                            'bullet',
                                                            'indent',
                                                            'header',
                                                            'bold',
                                                            'italic',
                                                            'underline',
                                                            'strike',
                                                            'blockquote',
                                                            'link',
                                                        ]}
                                                    />
                                                    <Button
                                                        props={{
                                                            text: 'Cancel',
                                                            onClick: () => {
                                                                const descriptionSetData =
                                                                    state?.description.map(
                                                                        (
                                                                            descriptionData
                                                                        ) => {
                                                                            if (
                                                                                descriptionData?.label ===
                                                                                data?.label
                                                                            ) {
                                                                                return {
                                                                                    ...descriptionData,
                                                                                    open: false,
                                                                                }
                                                                            } else {
                                                                                return descriptionData
                                                                            }
                                                                        }
                                                                    )
                                                                setState(
                                                                    (
                                                                        prevState
                                                                    ) => ({
                                                                        ...prevState,
                                                                        description:
                                                                            descriptionSetData,
                                                                    })
                                                                )
                                                            },
                                                            style: {
                                                                width: 'fit-content',
                                                                padding:
                                                                    '0px 10px',
                                                                height: '40px',
                                                                marginBottom:
                                                                    '10px',
                                                            },
                                                        }}
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    <Text
                                                        disabled={
                                                            editFieldCheck(
                                                                'standups',
                                                                'standup',
                                                                userProfile
                                                            ) === false
                                                        }
                                                        style={{
                                                            border: `1px solid ${token.colorPalette.baseColor.quinary}`,
                                                        }}
                                                        className={
                                                            styles.standUpTextHover
                                                        }
                                                        onClick={() => {
                                                            if (
                                                                editFieldCheck(
                                                                    'standups',
                                                                    'standup',
                                                                    userProfile
                                                                ) === true
                                                            ) {
                                                                const descriptionSetData =
                                                                    state?.description.map(
                                                                        (
                                                                            descriptionData
                                                                        ) => {
                                                                            if (
                                                                                descriptionData?.label ===
                                                                                data?.label
                                                                            ) {
                                                                                return {
                                                                                    ...descriptionData,
                                                                                    open: true,
                                                                                }
                                                                            } else {
                                                                                return descriptionData
                                                                            }
                                                                        }
                                                                    )
                                                                setState(
                                                                    (
                                                                        prevState
                                                                    ) => ({
                                                                        ...prevState,
                                                                        description:
                                                                            descriptionSetData,
                                                                    })
                                                                )
                                                            }
                                                        }}
                                                    >
                                                        {data?.placeholder}
                                                    </Text>
                                                </>
                                            )}
                                        </Form.Item>
                                    </div>
                                )
                            })}
                    </div>
                    <Row
                        align={'middle'}
                        style={{
                            marginTop: 'auto',
                            padding: '30px 47px',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <Button
                            props={{
                                text: 'Cancel',
                                buttonType: 'link',
                                style: {
                                    color: '#212B36',
                                },
                                onClick: () => {
                                    dispatch(switchFormDrawer({ open: false }))
                                    setState((prevState) => ({
                                        ...prevState,
                                        fileURL: [],
                                        fileList: [],
                                        description: STAND_UP_POST,
                                    }))
                                    dispatch(clearScreen())
                                },
                            }}
                        />
                        <Button
                            props={{
                                text: 'Post Standup',
                                disabled:
                                    state?.submitLoader ||
                                    state?.description.some(
                                        (data) =>
                                            data?.description?.length === 0 ||
                                            data?.description === '<p><br></p>'
                                    ),
                                loadingButton: state?.submitLoader,
                                onClick: () => {
                                    standUpPost()
                                },
                            }}
                        />
                    </Row>
                </Form>
            </Drawer>
        </>
    )
}

export default StandUpFormDrawer
