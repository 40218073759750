import { useSelector } from 'react-redux'

// antd
import { Card, Col, Row, Spin, Tag, Typography, theme } from 'antd'

// slices
import { rolesInquiryState } from './rolesInquiry.slice'

// assets
import { EarthIcon } from '../../../../assets/icons'

import dayjs from 'dayjs'

const BasicRoleDetails = () => {
    const { Title, Text } = Typography

    const { useToken } = theme

    const { token } = useToken()

    const { selectedRoleDetails, updatedDataStatus } =
        useSelector(rolesInquiryState)

    const detailsArray = [
        {
            title: 'Starting Date',
            value: new Date(selectedRoleDetails?.start).toLocaleString(
                'default',
                {
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric',
                }
            ),
        },
        {
            title: 'Timezone',
            value: selectedRoleDetails?.timeZone,
        },
        {
            title: 'Budget',
            value: `$${selectedRoleDetails?.startBudget} to $${selectedRoleDetails?.endBudget}`,
        },
        {
            title: 'Role',
            value: selectedRoleDetails?.title,
        },
        {
            title: 'Working Hours',
            value: `${selectedRoleDetails?.workingHours}h`,
        },
        {
            title: 'No. of Talent',
            value: selectedRoleDetails?.noOfTalent,
        },
    ]

    return (
        <Spin spinning={updatedDataStatus === 'loading' ? true : false}>
            <Card
                style={{
                    borderRadius: '7px',
                    padding: '0px',
                }}
                styles={{
                    body: {
                        padding: '25px 20px',
                    },
                }}
            >
                <Title level={4}>
                    Looking for {selectedRoleDetails?.title} developers
                </Title>
                <div
                    style={{
                        margin: '30px 0px',
                    }}
                >
                    <Row gutter={[20, 20]}>
                        {detailsArray?.length > 0 &&
                            detailsArray.map((data) => (
                                <Col span={8} key={data?.title}>
                                    <Card
                                        style={{
                                            borderRadius: '7px',
                                            padding: '0px',
                                        }}
                                        styles={{
                                            body: {
                                                padding: '23px 20px',
                                            },
                                        }}
                                    >
                                        <Title level={4}>{data?.title}</Title>
                                        <Row
                                            align={'middle'}
                                            style={{
                                                columnGap: '10px',
                                                marginTop: '14px',
                                            }}
                                        >
                                            <EarthIcon />
                                            <Text className="titleSub">
                                                {data?.value}
                                            </Text>
                                        </Row>
                                    </Card>
                                </Col>
                            ))}
                    </Row>
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginTop: '30px',
                    }}
                >
                    <Text
                        className="titleSub"
                        style={{
                            color: token.colorPalette.textColor.secondary,
                        }}
                    >
                        Must have skills:
                    </Text>
                    <Row
                        style={{
                            columnGap: '5px',
                            marginTop: '8px',
                            rowGap: '10px',
                        }}
                    >
                        {selectedRoleDetails?.skills?.length > 0 &&
                            selectedRoleDetails?.skills.map((skill) => (
                                <Tag
                                    style={{
                                        borderRadius: '44px',
                                        color: token.colorPalette.textColor
                                            .primary,
                                        fontWeight: token.fontWeightStrong,
                                    }}
                                    color={token.colorPalette.iconColor.primary}
                                >
                                    <Text>{skill?.name}</Text>
                                </Tag>
                            ))}
                    </Row>
                </div>
            </Card>
        </Spin>
    )
}

export default BasicRoleDetails
