import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import axios from 'axios'

// routes
import { PUBLIC_ROUTES } from '../../routes'

// slices
import { isLoggedInLogin } from '../login/login.slice'

function WorkSpaceLogin() {
    const dispatch = useDispatch()

    const loginInWorkSpace = async () => {
        try {
            const response = await axios({
                url: `${window.location.origin}/backend${PUBLIC_ROUTES.workSpaceLogin}/${window.location.search}`,
                method: 'get',
            })
            if (response?.data?.success) {
                dispatch(isLoggedInLogin())
            } else {
                return
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        loginInWorkSpace()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <></>
}

export default WorkSpaceLogin
