import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getProjectReports } from './projectReports.service'

const initialState = {
    status: 'loaded',
    projectReportsData: [],
    dateRanges: {},
    dateType: 'day',
    userChangeName: '',
    filter: {
        assigneeId: [],
        projectId: '',
    },
}

export const projectReportsGet = createAsyncThunk(
    'projectReportsSlice/getProjectReports',
    async (payload) => {
        const response = await getProjectReports(payload)
        return response
    }
)

export const projectReportsSlice = createSlice({
    name: 'projectReportsSlice',
    initialState,
    reducers: {
        dateRangesList(state, action) {
            state.dateRanges = action.payload.dateRanges
            state.dateType = action.payload.dateType
        },
        userChange(state, action) {
            state.userChangeName = action.payload
        },
        filterChangeReports(state, action) {
            state.filter = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(projectReportsGet.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(projectReportsGet.fulfilled, (state, action) => {
                state.status = 'loaded'
                state.projectReportsData = action.payload.data.data
            })
    },
})

export default projectReportsSlice.reducer

export const projectReportsState = (state) => {
    const { projectReportsSlice } = state
    const {
        status,
        projectReportsData,
        dateRanges,
        dateType,
        userChangeName,
        filter,
    } = projectReportsSlice
    return {
        status,
        projectReportsData,
        dateRanges,
        dateType,
        userChangeName,
        filter,
    }
}

export const { dateRangesList, userChange, filterChangeReports } =
    projectReportsSlice.actions
