import React from 'react'

function LinkIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 20 20"
        >
            <path
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M15.974 11.745l1.068-1.067c2.135-2.136 2.142-5.636 0-7.779-2.136-2.135-5.636-2.142-7.778 0L8.196 3.967M3.96 8.203L2.9 9.263a5.51 5.51 0 000 7.778c2.135 2.136 5.635 2.143 7.778 0l1.06-1.06m-4.596-3.182L12.8 7.142"
            ></path>
        </svg>
    )
}

export default LinkIcon
