import React from 'react'

function CloseModalIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 30 30"
        >
            <path
                stroke="#252525"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M11.226 18.774l7.547-7.547m0 7.547l-7.547-7.547m3.773 17.107c7.334 0 13.334-6 13.334-13.334 0-7.333-6-13.333-13.334-13.333-7.333 0-13.333 6-13.333 13.333 0 7.334 6 13.334 13.333 13.334z"
            ></path>
        </svg>
    )
}

export default CloseModalIcon
