import React from 'react'

function EpicIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="9"
            height="11"
            fill="none"
            viewBox="0 0 9 11"
        >
            <path
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M1 1l4.873 4.616.012-2.561M8 10L3.127 5.39l-.012 2.556"
            ></path>
        </svg>
    )
}

export default EpicIcon
