import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Avatar, Divider } from 'antd'
import { jobApplicantState } from '../store/jobApplicant.slice'
import { updateUserGet } from '../../profile/profile.slice'

const listApplicantStyle = {
    display: 'flex',
    gap: '10px',
    alignItems: 'flex-start',
    padding: '10px 5px',
    cursor: 'pointer',
}

const selectedApplicantStyle = {
    background: '#EAE4FF',
    borderRadius: '5px',
}

const TalentTag = () => {
    return (
        <>
            <span
                style={{
                    background: '#7EEB7C80',
                    padding: '3px 8px',
                    borderRadius: '24px',
                    fontSize: '12px',
                }}
            >
                Talent
            </span>
        </>
    )
}

const NewTag = () => {
    return (
        <>
            <span
                style={{
                    background: '#FFAC7080',
                    padding: '3px 8px',
                    borderRadius: '24px',
                    fontSize: '12px',
                }}
            >
                New
            </span>
        </>
    )
}

const JobApplicationList = ({ state, setState }) => {
    const dispatch = useDispatch()

    const [applicationsList, setApplicationsList] = useState([])

    const jobApplicantStateData = useSelector(jobApplicantState)

    useEffect(() => {
        if (jobApplicantStateData.applicantList?.length > 0) {
            setApplicationsList(jobApplicantStateData?.applicantList)

            setState((prevState) => ({
                ...prevState,
                selectedApplicantData:
                    jobApplicantStateData?.applicantList?.length > 0
                        ? jobApplicantStateData?.applicantList[0]
                        : {},
                selectedApplicantIndex: 0,
            }))

            dispatch(
                updateUserGet({
                    _id: jobApplicantStateData?.applicantList[0]?._id,
                })
            )
        }
    }, [jobApplicantStateData.applicantList])

    const handleSelectApplicant = (application, index) => {
        setState((prevState) => ({
            ...prevState,
            selectedApplicantData: application,
            selectedApplicantIndex: index,
        }))
        dispatch(
            updateUserGet({
                _id: application?._id,
            })
        )
    }

    return (
        <>
            <div>
                {applicationsList?.length > 0 ? (
                    applicationsList?.map((application, index) => (
                        <>
                            <div
                                onClick={() =>
                                    handleSelectApplicant(application, index)
                                }
                                style={{
                                    ...listApplicantStyle,
                                    ...(state.selectedApplicantIndex === index
                                        ? selectedApplicantStyle
                                        : {}),
                                    alignItems: 'flex-start',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <div style={{ ...listApplicantStyle }}>
                                    <span>
                                        <Avatar
                                            src={application?.profilePicture}
                                            size={50}
                                        />
                                    </span>

                                    <span
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'flex-start',
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontWeight: 700,
                                                fontSize: '16px',
                                            }}
                                        >
                                            {application?.name || ''}
                                        </span>
                                        <span
                                            style={{
                                                fontWeight: 400,
                                                fontSize: '14px',
                                                color: '#747474',
                                            }}
                                        >
                                            {application?.designation?.name}
                                        </span>
                                    </span>
                                </div>
                                <div>
                                    <span>
                                        {!application.isTalentUser ? (
                                            <NewTag />
                                        ) : (
                                            <TalentTag />
                                        )}
                                    </span>
                                </div>
                            </div>
                            <Divider style={{ margin: 0 }} />
                        </>
                    ))
                ) : (
                    <>No Data</>
                )}
            </div>
        </>
    )
}

export default JobApplicationList
