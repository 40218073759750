import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// antd
import { Card, Col, Form, message, Row, theme, Typography } from 'antd'

// slices
import {
    checkButtonType,
    profileState,
    switchIntroductionModal,
    switchProfileModal,
    switchVideoPlayModal,
} from '../profile.slice'

// assets
import { VideosIcon } from '../../../assets/icons'

// constants
import { videosTitle } from '../../../constants/profile'

function VideosModal() {
    const { Text } = Typography

    const { useToken } = theme
    const { token } = useToken()

    const dispatch = useDispatch()

    const { introductionVideoModal, selectedData, profileData } =
        useSelector(profileState)

    const [form] = Form.useForm()

    // EDIT MODAL SET DATA INITIALLY
    useEffect(() => {
        if (selectedData) {
            dispatch(
                switchIntroductionModal({
                    open: false,
                    videosTab: selectedData?.videos,
                })
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form, selectedData])

    // PROFILE DATA
    useEffect(() => {
        if (profileData?.data?.videos?.length > 0) {
            dispatch(
                switchIntroductionModal({
                    open: false,
                    videosTab: profileData?.data?.videos,
                })
            )
        } else {
            dispatch(
                switchIntroductionModal({
                    open: false,
                    videosTab: [],
                })
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profileData])

    // ONFINISH VIDEOS
    const onFinishVideos = () => {
        if (
            profileData?.data?.videos?.length > 0 &&
            profileData?.data?.videos.some(
                (data) => data?.type === 'Introduction Video'
            )
        ) {
            dispatch(switchProfileModal({ name: 'videos', open: false }))
        } else {
            message.error('Please Upload Introduction Video')
        }
    }

    // ONFINISH FAILED
    const onFinishFailed = () => {}

    return (
        <>
            <Form
                layout="vertical"
                name="addVideos"
                onFinish={onFinishVideos}
                onFinishFailed={onFinishFailed}
                requiredMark={false}
                onSubmitCapture={async (e) => {
                    await dispatch(checkButtonType(e.nativeEvent.submitter.id))
                }}
                form={form}
                style={{
                    overflowX: 'hidden',
                }}
            >
                <Row
                    style={{
                        margin: '20px 0px',
                        width: '100%',
                        rowGap: '25px',
                    }}
                >
                    {videosTitle?.length > 0 &&
                        videosTitle.map((video) => (
                            <Col span={8}>
                                <Text
                                    style={{
                                        color: token.colorPalette.baseColor
                                            .black,
                                        fontSize: token.fontSize,
                                    }}
                                >
                                    {video?.key === 'Introduction Video'
                                        ? `${video?.title}*`
                                        : video?.title}
                                </Text>
                                {introductionVideoModal?.videosTab?.length >
                                    0 &&
                                introductionVideoModal?.videosTab.some(
                                    (item) => item?.type === video?.key
                                ) ? (
                                    <>
                                        <video
                                            height="138px"
                                            width={'208px'}
                                            src={
                                                introductionVideoModal?.videosTab.find(
                                                    (data) =>
                                                        data?.type === video.key
                                                )?.link
                                            }
                                            style={{
                                                borderRadius: '10px',
                                                objectFit: 'cover',
                                                position: 'relative',
                                            }}
                                        />
                                        <div
                                            style={{
                                                height: '30px',
                                                position: 'absolute',
                                                top: '45%',
                                                right: '45%',
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => {
                                                dispatch(
                                                    switchVideoPlayModal({
                                                        delete: true,
                                                        videoData: {
                                                            deleteId:
                                                                introductionVideoModal?.videosTab.find(
                                                                    (data) =>
                                                                        data?.type ===
                                                                        video.key
                                                                )?._id,
                                                            link: introductionVideoModal?.videosTab.find(
                                                                (data) =>
                                                                    data?.type ===
                                                                    video.key
                                                            )?.link,
                                                        },
                                                        open: true,
                                                        title: video?.title,
                                                    })
                                                )
                                            }}
                                        >
                                            <VideosIcon
                                                color={
                                                    token.colorPalette.textColor
                                                        .quinary
                                                }
                                            />
                                        </div>
                                    </>
                                ) : (
                                    <Card
                                        style={{
                                            width: 208,
                                            height: 138,
                                            marginTop: '5px',
                                            display: 'flex',
                                            cursor: 'pointer',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            border: `1px solid ${token.colorPalette.textColor.quaternary}`,
                                        }}
                                        onClick={() => {
                                            dispatch(
                                                switchIntroductionModal({
                                                    ...introductionVideoModal,
                                                    open: true,
                                                    type: video?.key,
                                                    category: video?.category,
                                                })
                                            )
                                        }}
                                    >
                                        <div
                                            style={{
                                                height: '57px',
                                                marginBottom: '5px',
                                            }}
                                        >
                                            <VideosIcon
                                                color={
                                                    token.colorPalette.textColor
                                                        .quinary
                                                }
                                            />
                                        </div>
                                        <Text
                                            style={{
                                                color: token.colorPalette
                                                    .textColor.senary,
                                            }}
                                        >
                                            Click to Upload
                                        </Text>
                                    </Card>
                                )}
                            </Col>
                        ))}
                </Row>
            </Form>
        </>
    )
}

export default VideosModal
