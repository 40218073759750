import React from 'react'

function VideosIcon({ color }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 30 30"
        >
            <path
                fill={color ? color : '#B9B9B9'}
                fillRule="evenodd"
                d="M19.577 15.278l-7.2 4.583a.888.888 0 01-.914.023.917.917 0 01-.463-.8V9.917c0-.333.177-.64.463-.801a.883.883 0 01.914.024l7.2 4.583A.922.922 0 0120 14.5c0 .316-.16.61-.423.778zM15 0C6.715 0 0 6.716 0 15c0 8.284 6.715 15 15 15 8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15z"
                clipRule="evenodd"
            ></path>
        </svg>
    )
}

export default VideosIcon
