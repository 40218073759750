import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'

// antd
import { Col, Divider, Row, Typography, message, theme } from 'antd'

// slices
import {
    changeTab,
    inquiriesListGetSwimlanes,
    inquiryGet,
    inquiryPositionUpdate,
    inquiryState,
    setInquiries,
    setPointerEventInquiry,
    switchInquiryDrawer,
} from './inquiries.slice'

// components
import NoInquiriesComponent from './noInquiriesComponent'

// slices
import {
    workSpaceDataListCount,
    workSpaceListGet,
    workspaceState,
} from '../workSpace/workSpace.slice'

// assets
import { NoInquiriesIcon } from '../../assets/icons'

import styles from './inquiries.module.css'

import dayjs from 'dayjs'

const InquiryList = ({ spread }) => {
    const { useToken } = theme
    const { token } = useToken()

    const { Text, Title } = Typography

    const dispatch = useDispatch()

    const { inquiryList, drawerVariations, pointerEventInquiry } =
        useSelector(inquiryState)
    const { pageLimit: workSpacePageLimit, skipPage: workSpaceSkipPage } =
        useSelector(workspaceState)

    useEffect(() => {
        dispatch(inquiriesListGetSwimlanes())
        dispatch(workSpaceDataListCount())
        dispatch(
            workSpaceListGet({
                limit: workSpacePageLimit,
                skip: workSpaceSkipPage,
            })
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const Card = ({ props }) => {
        const { card } = props
        const { _id, inquiryName, position, projectName, updatedAt, client } =
            card

        return (
            <Draggable
                key={_id}
                draggableId={_id}
                index={position}
                isDragDisabled={!pointerEventInquiry || card?.status === 'Won'}
            >
                {(provided) => (
                    <div
                        className={styles.card}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        onClick={() => {
                            dispatch(changeTab('summary'))
                            dispatch(
                                inquiryGet({
                                    id: _id,
                                    workspaceId: client?.workspaceId,
                                })
                            )
                            dispatch(
                                switchInquiryDrawer({
                                    ...drawerVariations,
                                    open: true,
                                })
                            )
                        }}
                    >
                        <Title level={5}>{inquiryName}</Title>
                        <Text
                            style={{
                                color: token.colorPalette.textColor.secondary,
                            }}
                        >
                            {projectName}
                        </Text>
                        <Text>
                            Date: {dayjs(updatedAt).format('DD/MM/YYYY')}
                        </Text>
                    </div>
                )}
            </Draggable>
        )
    }

    const Column = ({ props }) => {
        const { column } = props

        const { list, position, _id } = column
        return (
            <Draggable
                key={_id}
                draggableId={_id}
                index={position}
                isDragDisabled={true}
            >
                {(provided) => (
                    <Col
                        xs={12}
                        sm={12}
                        md={12}
                        lg={10}
                        xl={6}
                        xxl={5}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{
                            height: 'max-content',
                            ...provided.draggableProps.style,
                            // pointerEvents: pointerEvent ? 'auto' : 'none',
                        }}
                    >
                        <Row
                            align={'middle'}
                            justify={'space-between'}
                            style={{
                                padding: '16px',
                                borderTopLeftRadius: 12,
                                borderTopRightRadius: 12,
                                backgroundColor:
                                    token.colorPalette.baseColor.quaternary,
                            }}
                        >
                            <div
                                className={'sub-title'}
                                style={{
                                    padding: '4px 12px',
                                    borderRadius: 1000,
                                    backgroundColor: '#CEE0F2',
                                }}
                            >
                                {_id}
                            </div>
                            <Divider
                                style={{
                                    marginBottom: '0px',
                                    marginTop: '16px',
                                }}
                            />
                        </Row>
                        <Droppable
                            droppableId={_id}
                            type={'column'}
                            direction={'vertical'}
                        >
                            {(provided) => (
                                <div
                                    ref={provided.innerRef}
                                    className={styles.column}
                                    {...provided.droppableProps}
                                >
                                    {list?.length > 0 &&
                                        list.map((card, index) => (
                                            <Card
                                                key={index}
                                                props={{ card }}
                                            />
                                        ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </Col>
                )}
            </Draggable>
        )
    }

    const orderCards = useCallback(
        async (source, destination, cardId) => {
            const newInquiries = structuredClone(inquiryList)
            if (source.droppableId === destination.droppableId) {
                const { list } = newInquiries.find(
                    (x) => x?._id === source.droppableId
                )

                let findObject
                // Loop through each list item
                for (let i = 0; i < newInquiries.length; i++) {
                    const list = newInquiries[i].list
                    // Loop through each item in the list
                    for (let j = 0; j < list.length; j++) {
                        // Check if the _id matches
                        if (list[j]._id === cardId) {
                            // Return the object if found
                            findObject = list[j]
                        }
                    }
                }

                list.forEach((card) => {
                    if (card.position === source.index) {
                        card.position = destination.index
                    } else {
                        if (
                            card.position <
                                Math.min(source.index, destination.index) ||
                            card.position >
                                Math.max(source.index, destination.index)
                        ) {
                            return
                        } else {
                            if (source.index < destination.index) {
                                card.position--
                            } else {
                                card.position++
                            }
                        }
                    }
                })

                list.sort((a, b) => a.position - b.position)
                dispatch(setInquiries(newInquiries))
                dispatch(setPointerEventInquiry(false))

                // UPDATE POSITION OF INQUIRIES
                const result = await dispatch(
                    inquiryPositionUpdate({
                        updatedData: {
                            initialPosition: source?.index,
                            position: destination?.index,
                            status: destination?.droppableId,
                        },
                        _id: cardId,
                        workspaceId:
                            Object.keys(findObject)?.length > 0
                                ? findObject?.client?.workspaceId
                                : '',
                    })
                )

                const data = result?.payload?.data
                if (data) {
                    const { success } = data
                    if (success) {
                        dispatch(setPointerEventInquiry(true))
                    } else {
                        dispatch(setPointerEventInquiry(true))
                        message.error('Something went wrong, try again later.')
                    }
                }
            } else {
                // SOURCE COLUMN INDEX AND CARD
                const sourceColumnIndex = newInquiries.findIndex(
                    (x) => x?._id === source.droppableId
                )
                const sourceCards = newInquiries[sourceColumnIndex].list

                // DESTINATION COLUMN INDEX AND CARD
                const destinationColumnIndex = newInquiries.findIndex(
                    (x) => x?._id === destination.droppableId
                )
                const destinationCards =
                    newInquiries[destinationColumnIndex].list

                const cardIndex = sourceCards.findIndex(
                    (x) => x?._id === cardId
                )
                const draggedCard = sourceCards[cardIndex]
                sourceCards.forEach((card) => {
                    if (card.position > source.index) {
                        card.position--
                    }
                })

                if (destinationCards?.length > 0) {
                    destinationCards.forEach((card) => {
                        if (card.position >= destination.index) {
                            card.position++
                        }
                    })
                }

                delete sourceCards[cardIndex]
                draggedCard.position = destination.index
                if (destinationCards?.length > 0) {
                    destinationCards.splice(destination.index, 0, draggedCard)
                } else {
                    destinationCards.push(draggedCard)
                }
                sourceCards.sort((a, b) => a.position - b.position)
                if (destinationCards?.length > 0) {
                    destinationCards.sort((a, b) => a.position - b.position)
                }
                dispatch(setInquiries(newInquiries))
                dispatch(setPointerEventInquiry(false))

                const result = await dispatch(
                    inquiryPositionUpdate({
                        updatedData: {
                            initialPosition: source?.index,
                            position: destination?.index,
                            status: destination?.droppableId,
                        },
                        _id: cardId,
                        workspaceId: draggedCard?.client?.workspaceId,
                    })
                )

                const data = result?.payload?.data
                if (data) {
                    const { success } = data
                    if (success) {
                        dispatch(setPointerEventInquiry(true))
                    } else {
                        dispatch(setPointerEventInquiry(true))
                        message.error('Something went wrong, try again later.')
                    }
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [inquiryList, dispatch]
    )

    const onDragEnd = useCallback(
        (draggable) => {
            const { draggableId } = draggable

            if (!draggable?.destination) {
                return
            } else {
                const source = draggable?.source
                const destination = draggable?.destination
                if (source !== null && destination !== null) {
                    if (destination?.droppableId === 'Won') {
                        message.info(
                            'You can not move inquiries to this swimlanes'
                        )
                        return
                    } else if (
                        source?.droppableId === destination?.droppableId &&
                        source?.index === destination?.index
                    ) {
                        return
                    } else {
                        if (draggable?.type === 'cards') {
                            return
                        } else if (draggable?.type === 'column') {
                            orderCards(source, destination, draggableId)
                        }
                    }
                } else {
                    return
                }
            }
        },
        // [orderColumns, orderCards]
        [orderCards]
    )

    return (
        <>
            {inquiryList?.length > 0 &&
            inquiryList.every((item) => item?.list?.length === 0) === true ? (
                <div
                    style={{
                        padding: '24px',
                        backgroundColor:
                            token.colorPalette.baseColor.quaternary,
                        width: '100%',
                        borderRadius: '14px',
                    }}
                >
                    <Row gutter={[16, 16]}>
                        {inquiryList.map((column) => (
                            <NoInquiriesComponent column={column} />
                        ))}
                    </Row>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            width: '100%',
                            height: '70vh',
                        }}
                    >
                        <NoInquiriesIcon />
                        <Text
                            style={{
                                color: token.colorPalette.textColor.secondary,
                                marginTop: '18px',
                                fontSize: token.fontSizeHeading4,
                            }}
                        >
                            No Inquiry added yet
                        </Text>
                    </div>
                </div>
            ) : (
                <div
                    className={'sub-container'}
                    style={{
                        overflowX: 'scroll',
                        height: spread ? 'max-content' : 'calc(100vh - 140px)',
                        // pointerEvents: pointerEvent ? 'auto' : 'none',
                    }}
                >
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable
                            droppableId={'cards'}
                            type={'cards'}
                            direction={'horizontal'}
                        >
                            {(provided) => (
                                <Row
                                    wrap={false}
                                    gutter={6}
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                >
                                    {inquiryList.map((column, index) => (
                                        <Column
                                            key={index}
                                            props={{ column }}
                                        />
                                    ))}
                                    {provided.placeholder}
                                </Row>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>
            )}
        </>
    )
}

export default InquiryList
