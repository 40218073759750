import React from 'react'

function NoTeamIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 462 400"
        >
            <g clipPath="url(#clip0_6739_211)">
                <path
                    fill="#F2F2F2"
                    d="M317.395 123.64a45.698 45.698 0 01-54.691 44.722 45.697 45.697 0 019.075-90.499 45.58 45.58 0 0145.616 45.777z"
                ></path>
                <path
                    fill="#9E616A"
                    d="M393.448 150.278l6.425-12.269c-5.762-6.957-24.665-15.396-24.665-15.396l-25.586-23.287a6.106 6.106 0 00-4.305-4.348 6.113 6.113 0 10-3.204 11.801l27.64 33.151 23.695 10.348z"
                ></path>
                <path
                    fill="#3F3D56"
                    d="M390.336 149.431l9.072 2.889a31.137 31.137 0 0020.205-.45 11.236 11.236 0 007.261-9.387 11.214 11.214 0 00-9.338-12.139c-8.508-1.398-19.47-2.087-23.381 2.567l-.04.047-3.779 16.473z"
                ></path>
                <path
                    fill="#fff"
                    d="M325.079 186.99c25.238 0 45.697-20.459 45.697-45.697 0-25.237-20.459-45.696-45.697-45.696-25.237 0-45.696 20.459-45.696 45.696 0 25.238 20.459 45.697 45.696 45.697z"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M306.431 123.419a42.928 42.928 0 01-3.064 9.778c-8.061 17.052 14.895 11.494 23.099 11.494 8.97 0 17.552 4.717 16.241-16.241-.56-8.952-7.271-16.241-16.241-16.241-7.661 0-18.433 3.406-20.035 11.21z"
                ></path>
                <path
                    fill="#FFB6B6"
                    d="M325.121 144.687c7.289 0 13.198-5.909 13.198-13.197 0-7.289-5.909-13.198-13.198-13.198-7.288 0-13.197 5.909-13.197 13.198 0 7.288 5.909 13.197 13.197 13.197z"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M316.159 118.037a15.119 15.119 0 0122.984 13.193c-6.725 1.688-13.79 2.98-20.961.404l-1.694-4.145-.998 4.148c-2.184.893-4.374 1.648-6.564-.009a15.443 15.443 0 017.233-13.591z"
                ></path>
                <path
                    fill="#6C63FF"
                    d="M360.61 171.679a45.683 45.683 0 01-63.932 4.62l3.919-10.937a11.761 11.761 0 019.99-6.86l2.494-.153 5.945-5.128s16.021-.572 15.783-.846l9.704 5.837-.008-.04 3.815.234a11.756 11.756 0 019.99 6.86l2.3 6.413z"
                ></path>
                <path
                    fill="#F2F2F2"
                    d="M321.071 359.975l-1.135-15.793a51.1 51.1 0 00-23.985-5.379c11.696 9.041 10.759 27.11 18.859 39.475a30.735 30.735 0 0022.914 13.58l9.733 5.6a51.479 51.479 0 00-11.989-41.414 49.72 49.72 0 00-9.37-8.24c-2.127 6.108-5.027 12.171-5.027 12.171zM25.12 80.053L23.984 64.26A51.086 51.086 0 000 58.88c11.696 9.04 10.759 27.11 18.859 39.475a30.732 30.732 0 0022.913 13.58l9.734 5.599a51.491 51.491 0 00-11.99-41.413 49.743 49.743 0 00-9.369-8.24c-2.127 6.108-5.027 12.17-5.027 12.17z"
                ></path>
                <path
                    fill="#9E616A"
                    d="M363.652 392.118h-7.593l-3.612-29.286h11.206l-.001 29.286z"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M365.587 399.478l-24.482-.001v-.309a9.533 9.533 0 019.529-9.529h.001l4.472-3.393 8.344 3.393h2.137l-.001 9.839z"
                ></path>
                <path
                    fill="#9E616A"
                    d="M430.568 392.118h-7.593l-3.612-29.286h11.206l-.001 29.286z"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M432.505 399.478l-24.482-.001v-.309a9.533 9.533 0 019.529-9.529h.001l4.472-3.393 8.344 3.393h2.137l-.001 9.839zM419.02 235.378l6.386 60.668s8.501 60.745 6.905 69.127c-1.597 8.381.399 9.579.399 9.579l-14.568-1.038s.399-5.188-1.995-7.583c-2.395-2.395-1.198-9.579-1.198-9.579l-28.258-98.823-17.163 51.887s-2.794 57.075-3.193 57.873c-.399.799-1.596 11.575-1.596 11.575h-14.768s1.995-6.386-.798-8.382c-2.794-1.995-5.189-.399-2.794-3.991 2.394-3.592 3.193-7.982 2.394-9.978-.798-1.996-.798-9.978-.798-9.978s1.198-107.765 7.983-111.357c6.785-3.592 63.062 0 63.062 0z"
                ></path>
                <path
                    fill="#9E616A"
                    d="M388.777 112.542c8.675 0 15.707-7.032 15.707-15.707 0-8.674-7.032-15.707-15.707-15.707-8.674 0-15.707 7.033-15.707 15.707 0 8.675 7.033 15.707 15.707 15.707z"
                ></path>
                <path
                    fill="#3F3D56"
                    d="M424.448 241.991c-30.418 6.281-70.729-4.519-70.729-4.519l10.753-46.613-3.823-48.782c-.467-5.965 2.931-11.557 8.217-13.539l7.298-2.733 6.033-8.637 17.233.565 6.888 9.067 5.437 2.885 11.785 6.25.052.026v.026l-3.698 35.876-2.231 21.631s6.775 24.329 5.385 27.555c-1.391 3.222-.182 1.665.155 3.388.337 1.722 1.37 1.831 1.525 5.223.161 3.388-.28 12.331-.28 12.331z"
                ></path>
                <path
                    fill="#CCC"
                    d="M461.22 400H226.251a.523.523 0 01-.524-.526.526.526 0 01.524-.526H461.22a.527.527 0 110 1.052z"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M388.014 111.788a48.35 48.35 0 008.212-.788c2.683-.618 8.602-5.195 10.215-7.899 1.199-2.009 1.918-5.117 2.311-7.37a21.621 21.621 0 00-4.224-17.065 6.832 6.832 0 00-2.82-2.283 3.365 3.365 0 00-.306-.094 8.432 8.432 0 01-4.259-2.486 6.912 6.912 0 00-.715-.7 10.386 10.386 0 00-4.387-2.062c-2.593-.623-6.301.157-11.022 2.32-2.371 1.087-5.004.784-7.033.398a.66.66 0 00-.616.23 4.66 4.66 0 01-3.135 1.344c-.72.05-1.451 1.068-2.332 2.394-.2.301-.433.652-.602.867l-.034-.403-.396.46a5.662 5.662 0 00-1.125 5.343 5.659 5.659 0 003.916 3.805c.73.174 1.477.274 2.227.296.455.03.926.06 1.378.126a8.533 8.533 0 016.479 5.37 2.236 2.236 0 003.394.996 3.683 3.683 0 013.341-.699c.497.26.89.683 1.113 1.199a3.187 3.187 0 001.245 1.38c1.852.895 2.086 5.066 1.52 8.563-.547 3.37-1.735 5.829-2.89 5.979-.889.115-.989.181-1.05.341l-.054.142.104.13a3.662 3.662 0 001.545.166z"
                ></path>
                <path
                    fill="#3F3D56"
                    d="M325.079 187.562a46.268 46.268 0 01-9.026-91.648 46.27 46.27 0 0155.295 45.379 46.323 46.323 0 01-46.269 46.269zm0-91.392a45.124 45.124 0 1045.124 45.123 45.173 45.173 0 00-45.124-45.123z"
                ></path>
                <path
                    fill="#fff"
                    d="M165.37 91.965c25.238 0 45.697-20.459 45.697-45.696 0-25.238-20.459-45.697-45.697-45.697-25.237 0-45.696 20.46-45.696 45.697 0 25.237 20.459 45.696 45.696 45.696z"
                ></path>
                <path
                    fill="#FFB8B8"
                    d="M167.485 50.93c8.229 0 14.901-6.671 14.901-14.9 0-8.23-6.672-14.902-14.901-14.902-8.23 0-14.901 6.672-14.901 14.901 0 8.23 6.671 14.901 14.901 14.901z"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M180.604 26.186s3.88-7.417-4.655-8.091c0 0-7.277-6.601-14.694-1.207 0 0-4.045 0-6.257 4.578 0 0-3.182-1.207-3.881 2.022 0 0-2.328 6.743 0 12.811 2.328 6.069 3.101 6.743 3.101 6.743s-1.633-7.53 7.679-8.205c9.312-.674 17.855-6.508 18.631.909.776 7.417 1.629 4.066 1.629 4.066s7.371-10.592-1.553-13.626z"
                ></path>
                <path
                    fill="#6C63FF"
                    d="M199.735 76.654a45.68 45.68 0 01-63.932 4.62l3.919-10.936a11.764 11.764 0 019.99-6.86l2.494-.153 5.945-5.128s16.021-.572 15.783-.846l9.704 5.837-.008-.04 3.815.233a11.756 11.756 0 019.99 6.86l2.3 6.413z"
                ></path>
                <path
                    fill="#3F3D56"
                    d="M165.37 92.538a46.267 46.267 0 01-45.379-55.296A46.272 46.272 0 01156.344.89a46.27 46.27 0 0155.295 45.38 46.322 46.322 0 01-46.269 46.269zm0-91.393a45.122 45.122 0 00-31.907 77.031 45.125 45.125 0 0077.031-31.907A45.174 45.174 0 00165.37 1.145z"
                ></path>
                <path
                    fill="#fff"
                    d="M58.71 164.092c25.238 0 45.697-20.459 45.697-45.696S83.947 72.699 58.71 72.699c-25.237 0-45.696 20.46-45.696 45.697 0 25.237 20.459 45.696 45.696 45.696z"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M37.53 103.531c1.89-6.022 6.186-11.545 12.129-13.58 5.943-2.036 14.644 1.065 19.112 5.517 8.172 8.145 10.166 36.124 4.552 44.966-1.117-.059-4.97-.103-6.1-.145l-1.6-5.329v5.277a277.72 277.72 0 00-5.444-.116 23.693 23.693 0 01-23.394-23.11c-.146-5.865 0-11.107.745-13.48z"
                ></path>
                <path
                    fill="#9E616A"
                    d="M58.033 123.489c7.192 0 13.023-5.831 13.023-13.023 0-7.193-5.83-13.023-13.023-13.023s-13.023 5.83-13.023 13.023c0 7.192 5.83 13.023 13.023 13.023z"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M46.793 99.028l14.995-7.508a18.847 18.847 0 0111.039 15.615l.373 4.477-6.279-1.599-.688-5.724-1.066 5.278-2.898-.737.028-8.888-2.9 8.881-10.35-2.378-2.254-7.417z"
                ></path>
                <path
                    fill="#6C63FF"
                    d="M92.62 148.636a45.679 45.679 0 01-63.933 4.62l3.92-10.936a11.755 11.755 0 019.99-6.86l2.493-.153 5.946-5.128s16.02-.572 15.783-.846l9.704 5.837-.008-.041 3.815.234a11.75 11.75 0 019.99 6.86l2.3 6.413z"
                ></path>
                <path
                    fill="#3F3D56"
                    d="M58.71 164.665a46.27 46.27 0 1146.269-46.269 46.324 46.324 0 01-46.269 46.269zm0-91.393a45.124 45.124 0 1045.124 45.124A45.172 45.172 0 0058.71 73.272z"
                ></path>
                <path
                    fill="#9E616A"
                    d="M367.283 165l-6.918-11.997c-8.962 1.138-26.214 12.581-26.214 12.581l-33.37 9.13a6.109 6.109 0 00-7.685 3.974 6.112 6.112 0 007.147 7.821 6.104 6.104 0 002.81-1.461l42.799-5.583L367.283 165z"
                ></path>
                <path
                    fill="#3F3D56"
                    d="M364.901 167.175l15.489-12.973 2.253-10.443a11.246 11.246 0 00-4.037-11.16 11.215 11.215 0 00-15.252 1.383c-5.739 6.435-12.193 15.323-10.358 21.119l.018.058 11.887 12.016z"
                ></path>
                <path
                    fill="#E6E6E6"
                    d="M127.509 212.243H69.312a5.89 5.89 0 01-5.882-5.883v-35.844a5.89 5.89 0 015.883-5.883h58.196a5.89 5.89 0 015.883 5.883v35.844a5.89 5.89 0 01-5.883 5.883z"
                ></path>
                <path
                    fill="#fff"
                    d="M104.386 207.626H73.402a5.517 5.517 0 01-5.51-5.51V174.76a5.514 5.514 0 015.51-5.51h50.016a5.519 5.519 0 015.509 5.51v8.325a24.567 24.567 0 01-24.541 24.541z"
                ></path>
                <path
                    fill="#6C63FF"
                    d="M118.428 184.23H78.392a1.305 1.305 0 110-2.611h40.036a1.305 1.305 0 110 2.611zM84.654 189.39h-6.262a1.306 1.306 0 010-2.612h6.263a1.306 1.306 0 010 2.612zM95.746 194.549H78.39a1.306 1.306 0 010-2.611h17.355a1.301 1.301 0 011.303 1.305 1.307 1.307 0 01-1.303 1.306z"
                ></path>
                <path
                    fill="#3F3D56"
                    d="M61.397 176.23c6.799 0 12.31-5.512 12.31-12.311s-5.511-12.311-12.31-12.311c-6.8 0-12.311 5.512-12.311 12.311s5.512 12.311 12.31 12.311z"
                ></path>
                <path
                    fill="#E6E6E6"
                    d="M278.632 49.67h-58.196a5.89 5.89 0 01-5.883-5.882V7.944a5.886 5.886 0 015.883-5.883h58.196a5.89 5.89 0 015.883 5.883v35.844a5.885 5.885 0 01-5.883 5.882z"
                ></path>
                <path
                    fill="#fff"
                    d="M255.509 45.053h-30.984a5.517 5.517 0 01-5.509-5.509V12.187a5.512 5.512 0 015.509-5.51h50.016a5.517 5.517 0 015.509 5.51v8.325a24.566 24.566 0 01-24.541 24.541z"
                ></path>
                <path
                    fill="#6C63FF"
                    d="M269.551 21.658h-40.036a1.306 1.306 0 110-2.612h40.036a1.306 1.306 0 110 2.612zM235.778 26.817h-6.263a1.306 1.306 0 110-2.611h6.263a1.306 1.306 0 010 2.611zM246.871 31.977h-17.355a1.308 1.308 0 01-1.303-1.306 1.306 1.306 0 011.303-1.306h17.355a1.306 1.306 0 010 2.612z"
                ></path>
                <path
                    fill="#3F3D56"
                    d="M212.522 56.59c6.799 0 12.311-5.511 12.311-12.31 0-6.8-5.512-12.311-12.311-12.311s-12.311 5.512-12.311 12.31c0 6.8 5.512 12.312 12.311 12.312z"
                ></path>
            </g>
            <defs>
                <clipPath id="clip0_6739_211">
                    <path fill="#fff" d="M0 0H461.747V400H0z"></path>
                </clipPath>
            </defs>
        </svg>
    )
}

export default NoTeamIcon
