import React from 'react'

function RoadMapIcon(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
            {...props}
        >
            <path
                fill="#fff"
                d="M7.63 3.57c.178-.098.37.052.37.257v13.556c0 .223-.153.412-.35.516a1.49 1.49 0 00-.02.01l-2.35 1.34c-1.64.94-2.99.16-2.99-1.74v-9.73c0-.63.45-1.41 1.01-1.73l4.33-2.48zm7.092 2.533A.5.5 0 0115 6.55v13.153a.5.5 0 01-.717.45l-4.25-2.047a.5.5 0 01-.283-.45V4.446a.5.5 0 01.722-.448l4.25 2.105zM22 6.49v9.73c0 .63-.45 1.41-1.01 1.73l-3.491 2a.5.5 0 01-.749-.433V6.33a.501.501 0 01.252-.434L19.01 4.75C20.65 3.81 22 4.59 22 6.49z"
            ></path>
        </svg>
    )
}

export default RoadMapIcon
