import React from 'react'

function OverViewIcon(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
            {...props}
        >
            <path
                fill={props.color ? props?.color : '#fff'}
                d="M20.861 8.37l-6.93-5.54c-1.07-.86-2.8-.86-3.86-.01l-6.93 5.55c-.78.62-1.28 1.93-1.11 2.91l1.33 7.96c.24 1.42 1.6 2.57 3.04 2.57h11.2c1.43 0 2.8-1.16 3.04-2.57l1.33-7.96c.16-.98-.34-2.29-1.11-2.91zm-8.86 7.13a2.5 2.5 0 110-5 2.5 2.5 0 010 5z"
            ></path>
        </svg>
    )
}

export default OverViewIcon
