// antd
import { Col, Image, Row } from 'antd'

// constants
import { fileTypes, pdfTypes, videoTypes } from '../../../constants/mediaTypes'

// styles
import styles from '../profile.module.css'

function ViewMediaModal({ fileName }) {
    return (
        <>
            <Row align={'middle'}>
                <Col span={24}>
                    {fileTypes.some((r) =>
                        fileName?.toLowerCase().includes(r)
                    ) ? (
                        <>
                            <Image
                                src={fileName}
                                width={'100%'}
                                height={'387px'}
                                style={{
                                    borderRadius: '15px',
                                }}
                                preview={false}
                            />
                        </>
                    ) : videoTypes.some((r) =>
                          fileName?.toLowerCase().includes(r)
                      ) ? (
                        <video
                            width="100%"
                            height="387px"
                            autoPlay
                            controls
                            className={styles.videoViewContainer}
                        >
                            <source src={fileName} type="video/mp4" />
                        </video>
                    ) : pdfTypes.some((r) =>
                          fileName?.toLowerCase().includes(r)
                      ) ? (
                        <>
                            <div>
                                <iframe
                                    src={`${fileName}#view=fitH`}
                                    height={'387px'}
                                    width="100%"
                                    title="PDF"
                                />
                            </div>
                        </>
                    ) : (
                        <></>
                    )}
                </Col>
            </Row>
        </>
    )
}

export default ViewMediaModal
