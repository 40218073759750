// antd
import { Divider, Image, Row, Space, Tag, theme, Typography } from 'antd'

// styles
import styles from '../profile.module.css'

function EducationSummaryContent({ contentData }) {
    const { Title, Text } = Typography

    const { useToken } = theme
    const { token } = useToken()

    return (
        <Row
            style={{
                margin: '25px 0px 50px 0px',
                rowGap: '15px',
            }}
            align={'middle'}
        >
            {contentData?.length > 0 &&
                contentData.map((educationData) => {
                    return (
                        <div key={educationData?.eduInfo?.degree?.name}>
                            <Row
                                style={{
                                    columnGap: '20px',
                                    flexFlow: 'row nowrap',
                                }}
                            >
                                <Row
                                    style={{
                                        height: '100px',
                                        width: '100px',
                                        minWidth: '100px',
                                        borderRadius: '10px',
                                        padding: '20px 20px 0px 20px',
                                        border: `2px solid ${token.colorPalette.textColor.quaternary}`,
                                    }}
                                >
                                    {educationData?.eduInfo?.institute?.logo ? (
                                        <Image
                                            src={
                                                educationData?.eduInfo
                                                    ?.institute?.logo
                                            }
                                            width={'100%'}
                                            height={'100%'}
                                            style={{
                                                objectFit: 'cover',
                                            }}
                                            preview={false}
                                        />
                                    ) : (
                                        <div
                                            className={styles.educationSvgLogo}
                                        />
                                    )}
                                </Row>
                                <div
                                    style={{
                                        marginRight: '50px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <Row>
                                        <Space>
                                            {educationData?.eduInfo?.degree
                                                ?.name && (
                                                <Title className={'titleSub'}>
                                                    {
                                                        educationData?.eduInfo
                                                            ?.degree?.name
                                                    }
                                                </Title>
                                            )}
                                            <Text className="titleLight">
                                                {educationData?.isCompleted ===
                                                false
                                                    ? `(Draft)`
                                                    : ''}
                                            </Text>
                                        </Space>
                                    </Row>
                                    <Space>
                                        {educationData?.eduInfo?.institute
                                            ?.name && (
                                            <Text
                                                style={{
                                                    fontSize: token.fontSize,
                                                }}
                                            >
                                                {
                                                    educationData?.eduInfo
                                                        ?.institute?.name
                                                }
                                            </Text>
                                        )}
                                        {educationData?.eduInfo?.institute
                                            ?.name &&
                                            educationData?.eduInfo?.location &&
                                            '|'}
                                        {educationData?.eduInfo?.location && (
                                            <Text
                                                style={{
                                                    fontSize: token.fontSize,
                                                }}
                                            >
                                                {
                                                    educationData?.eduInfo
                                                        ?.location
                                                }
                                            </Text>
                                        )}
                                    </Space>
                                    <Space>
                                        {educationData?.eduInfo?.start !==
                                            null && (
                                            <Text
                                                style={{
                                                    fontSize: token.fontSizeLG,
                                                    marginTop: '15px',
                                                }}
                                                className={'titleLight'}
                                            >
                                                {new Date(
                                                    educationData?.eduInfo?.start
                                                )?.toLocaleString('default', {
                                                    year: 'numeric',
                                                    month: 'long',
                                                })}
                                            </Text>
                                        )}
                                        {educationData?.eduInfo?.start !==
                                            null &&
                                            (educationData?.eduInfo
                                                ?.currentStudy ||
                                                educationData?.eduInfo?.end !==
                                                    null) &&
                                            '-'}
                                        {(educationData?.eduInfo
                                            ?.currentStudy ||
                                            educationData?.eduInfo?.end !==
                                                null) && (
                                            <Text
                                                style={{
                                                    fontSize: token.fontSizeLG,
                                                    marginTop: '15px',
                                                }}
                                                className={'titleLight'}
                                            >
                                                {educationData?.eduInfo
                                                    ?.currentStudy
                                                    ? 'Present'
                                                    : new Date(
                                                          educationData?.eduInfo?.end
                                                      )?.toLocaleString(
                                                          'default',
                                                          {
                                                              month: 'long',
                                                              year: 'numeric',
                                                          }
                                                      )}
                                            </Text>
                                        )}
                                    </Space>
                                    {educationData?.skills?.length > 0 && (
                                        <div
                                            style={{
                                                marginTop: '15px',
                                            }}
                                        >
                                            <Text
                                                style={{
                                                    fontSize: token.fontSizeLG,
                                                    fontWeight:
                                                        token.fontWeightStrong,
                                                }}
                                            >
                                                Skills
                                            </Text>
                                            <Row
                                                style={{
                                                    rowGap: '10px',
                                                    marginTop: '8px',
                                                    columnGap: '10px',
                                                }}
                                            >
                                                {educationData?.skills.map(
                                                    (skill) => (
                                                        <Tag
                                                            style={{
                                                                padding:
                                                                    '9px 32px',
                                                                cursor: 'text',
                                                            }}
                                                            key={skill?.label}
                                                            color={
                                                                token
                                                                    .colorPalette
                                                                    .baseColor
                                                                    .senary
                                                            }
                                                        >
                                                            <Text
                                                                style={{
                                                                    fontWeight:
                                                                        token.fontWeightStrong,
                                                                }}
                                                            >
                                                                {skill?.name?.toUpperCase()}
                                                            </Text>
                                                        </Tag>
                                                    )
                                                )}
                                            </Row>
                                        </div>
                                    )}
                                    {educationData?.tools?.length > 0 && (
                                        <div
                                            style={{
                                                marginTop: '15px',
                                            }}
                                        >
                                            <Text
                                                style={{
                                                    fontSize: token.fontSizeLG,
                                                    fontWeight:
                                                        token.fontWeightStrong,
                                                }}
                                            >
                                                Tools
                                            </Text>
                                            <Row
                                                style={{
                                                    columnGap: '15px',
                                                    marginTop: '8px',
                                                }}
                                            >
                                                {educationData?.tools.map(
                                                    (tool) => (
                                                        <div key={tool?.label}>
                                                            <div
                                                                className={
                                                                    styles.toolIconDefault
                                                                }
                                                                style={{
                                                                    border: `2px solid ${token.colorPalette.baseColor.tertiary}`,
                                                                }}
                                                            >
                                                                <Image
                                                                    src={
                                                                        tool?.logo
                                                                    }
                                                                    preview={
                                                                        false
                                                                    }
                                                                    width={
                                                                        '100%'
                                                                    }
                                                                />
                                                            </div>
                                                            <div
                                                                style={{
                                                                    width: '60px',
                                                                    whiteSpace:
                                                                        'nowrap',
                                                                    overflow:
                                                                        'hidden',
                                                                    textOverflow:
                                                                        'ellipsis',
                                                                }}
                                                            >
                                                                <Text
                                                                    style={{
                                                                        marginTop:
                                                                            '2px',
                                                                        fontWeight:
                                                                            token.fontWeightStrong,
                                                                        textTransform:
                                                                            'capitalize',
                                                                    }}
                                                                >
                                                                    {tool?.name}
                                                                </Text>
                                                            </div>
                                                        </div>
                                                    )
                                                )}
                                            </Row>
                                        </div>
                                    )}
                                </div>
                            </Row>
                            <Divider type="vertical" />
                        </div>
                    )
                })}
        </Row>
    )
}

export default EducationSummaryContent
