import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
    addMasterData,
    bulkDataUpdate,
    countMasterData,
    deleteMasterData,
    getMasterData,
    listMasterData,
    updateMasterData,
} from './masterDataPanel.service'

const initialState = {
    search: '',
    status: 'loaded',
    listStatus: 'loaded',
    updatedDataStatus: 'loaded',
    defaultTab: 'summary',
    formButtonType: 'saveButtonLoading',
    selectedData: undefined,
    masterDataList: [],
    isNextDropdownList: false,
    pageLimitDropdownList: 10,
    currentPageDropdownList: 1,
    skipPageDropdownList: 0,
    masterDataDropdownList: [],
    dataCount: 0,
    isNext: false,
    pageLimit: 10,
    currentPage: 1,
    skipPage: 0,
    modelType: {
        name: 'institute',
    },
    modalVariations: {
        open: false,
        formName: 'masterDataForm',
        saveButtonLoading: false,
    },
    filter: {
        isActive: '',
    },
}

export const masterDataListCount = createAsyncThunk(
    'masterDataSlice/countMasterData',
    async (payload = {}) => {
        const response = await countMasterData(payload)
        return response
    }
)

export const masterDataListGet = createAsyncThunk(
    'masterDataSlice/listMasterData',
    async (payload = {}) => {
        const response = await listMasterData(payload)
        return response
    }
)

export const masterDataListGetDropdown = createAsyncThunk(
    'masterDataSlice/listMasterDataDropdown',
    async (payload = {}) => {
        const response = await listMasterData(payload)
        return response
    }
)

export const masterDataItemGet = createAsyncThunk(
    'masterDataSlice/getMasterData',
    async (payload = {}) => {
        const response = await getMasterData(payload)
        return response
    }
)

export const masterDataAdd = createAsyncThunk(
    'masterDataSlice/addMasterData',
    async (payload = {}) => {
        const response = await addMasterData(payload)
        return response
    }
)

export const masterDataUpdate = createAsyncThunk(
    'masterDataSlice/updateMasterData',
    async (payload = {}) => {
        const response = await updateMasterData(payload)
        return response
    }
)

export const masterDataDelete = createAsyncThunk(
    'masterDataSlice/deleteMasterData',
    async (payload = {}) => {
        const response = await deleteMasterData(payload)
        return response
    }
)

export const updateBulkData = createAsyncThunk(
    'masterDataSlice/bulkDataUpdate',
    async (payload = {}) => {
        const response = await bulkDataUpdate(payload)
        return response
    }
)

export const masterDataSlice = createSlice({
    name: 'masterDataSlice',
    initialState,
    reducers: {
        switchMasterDataModal: (state, action) => {
            state.modalVariations = action.payload
            state.selectedData = undefined
        },
        checkButtonType: (state, action) => {
            state.formButtonType = action.payload
        },
        changeTab: (state, action) => {
            state.defaultTab = action.payload
        },
        changePage: (state, action) => {
            state.currentPage = action.payload.currentPage
            state.skipPage = action.payload.skip
            state.pageLimit = action.payload.pageLimit
        },
        changePageList: (state, action) => {
            state.currentPageDropdownList = action.payload.currentPage
            state.skipPageDropdownList = action.payload.skip
            state.pageLimitDropdownList = action.payload.pageLimit
        },
        changeModel: (state, action) => {
            state.modelType = action.payload
        },
        searchData: (state, action) => {
            state.search = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(masterDataListGet.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(masterDataListGet.fulfilled, (state, action) => {
                state.status = 'loaded'
                state.masterDataList =
                    action.payload.data?.result?.length > 0
                        ? action.payload.data?.result.map((data) => {
                              return {
                                  ...data,
                                  key: data?._id,
                              }
                          })
                        : []
                state.isNext = action.payload.data.isNext
            })
            .addCase(masterDataListGetDropdown.pending, (state) => {
                state.listStatus = 'loading'
            })
            .addCase(masterDataListGetDropdown.fulfilled, (state, action) => {
                state.listStatus = 'loaded'
                state.masterDataDropdownList = action.payload.data?.result
                state.isNextDropdownList = action.payload.data.isNext
            })
            .addCase(masterDataListCount.fulfilled, (state, action) => {
                state.dataCount = action.payload.data
            })
            .addCase(masterDataItemGet.pending, (state) => {
                state.updatedDataStatus = 'loading'
            })
            .addCase(masterDataItemGet.fulfilled, (state, action) => {
                state.updatedDataStatus = 'loaded'
                state.selectedData = action?.payload.data?.result
            })
    },
})

export default masterDataSlice.reducer

export const masterDataState = (state) => {
    const { masterDataSlice } = state
    const {
        search,
        status,
        formButtonType,
        selectedData,
        updatedDataStatus,
        defaultTab,
        masterDataList,
        dataCount,
        isNext,
        pageLimit,
        currentPage,
        skipPage,
        modelType,
        filter,
        modalVariations,
        isNextDropdownList,
        pageLimitDropdownList,
        currentPageDropdownList,
        skipPageDropdownList,
        masterDataDropdownList,
    } = masterDataSlice
    return {
        search,
        status,
        formButtonType,
        selectedData,
        updatedDataStatus,
        defaultTab,
        masterDataList,
        dataCount,
        isNext,
        pageLimit,
        currentPage,
        skipPage,
        modelType,
        filter,
        modalVariations,
        isNextDropdownList,
        pageLimitDropdownList,
        currentPageDropdownList,
        skipPageDropdownList,
        masterDataDropdownList,
    }
}

export const {
    switchProfileModal,
    profileLoading,
    checkButtonType,
    changeTab,
    changePage,
    changeModel,
    switchMasterDataModal,
    searchData,
    changePageList,
} = masterDataSlice.actions
