import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getScreenShots } from './screenshots.service'

const initialState = {
    status: 'loaded',
    screenshotsData: [],
    isNext: false,
    pageLimit: 10,
    currentPage: 1,
    skipPage: 0,
    count: 0,
    userChangeName: '',
}

export const screenshotsGet = createAsyncThunk(
    'screenshotsSlice/getScreenShots',
    async (payload) => {
        const response = await getScreenShots(payload)
        return response
    }
)

export const screenshotsSlice = createSlice({
    name: 'screenshotsSlice',
    initialState,
    reducers: {
        userChange(state, action) {
            state.userChangeName = action.payload
        },
        changeScreenshotsPage: (state, action) => {
            state.currentPage = action.payload.currentPage
            state.skipPage = action.payload.skip
            state.pageLimit = action.payload.pageLimit
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(screenshotsGet.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(screenshotsGet.fulfilled, (state, action) => {
                state.status = 'loaded'
                state.screenshotsData = action.payload.data
                state.isNext = action.payload.isNext
            })
    },
})

export default screenshotsSlice.reducer

export const screenshotsState = (state) => {
    const { screenshotsSlice } = state
    const {
        status,
        screenshotsData,
        userChangeName,
        isNext,
        pageLimit,
        currentPage,
        skipPage,
        count,
    } = screenshotsSlice
    return {
        status,
        screenshotsData,
        userChangeName,
        isNext,
        pageLimit,
        currentPage,
        skipPage,
        count,
    }
}

export const { userChange, changeScreenshotsPage } = screenshotsSlice.actions
