import React from 'react'

function NotesRecruitIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="18"
            fill="none"
            viewBox="0 0 14 18"
        >
            <path
                fill="#4F46E5"
                d="M10.333 2.543a1.878 1.878 0 01-1.875 1.875H5.54c-.517 0-.983-.208-1.325-.55a1.864 1.864 0 01-.55-1.325c0-1.033.842-1.875 1.875-1.875h2.917c.516 0 .983.208 1.325.55.341.342.55.808.55 1.325z"
            ></path>
            <path
                fill="#4F46E5"
                d="M12.691 3.191a2.359 2.359 0 00-.641-.375c-.242-.091-.484.1-.534.35a3.114 3.114 0 01-3.058 2.5H5.541a3.102 3.102 0 01-2.208-.916 3.083 3.083 0 01-.85-1.575c-.05-.25-.3-.45-.542-.35C.975 3.216.333 4.1.333 5.875V14c0 2.5 1.492 3.333 3.333 3.333h6.667c1.842 0 3.333-.833 3.333-3.333V5.875c0-1.359-.375-2.192-.975-2.684zM3.666 9.208H7a.63.63 0 01.625.625.63.63 0 01-.625.625H3.666a.63.63 0 01-.625-.625.63.63 0 01.625-.625zm6.667 4.583H3.666a.63.63 0 01-.625-.625.63.63 0 01.625-.625h6.667a.63.63 0 01.625.625.63.63 0 01-.625.625z"
            ></path>
        </svg>
    )
}

export default NotesRecruitIcon
