import React from 'react'
import { createRoot } from 'react-dom/client'

import './index.css'
import 'bootstrap/dist/css/bootstrap.min.css'

import RouteContext from './modules/routes'

const root = document.getElementById('pms')
const react = createRoot(root)

react.render(<RouteContext />)
