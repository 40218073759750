import React from 'react'

function CompleteStepIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="43"
            height="43"
            fill="none"
            viewBox="0 0 43 43"
        >
            <circle
                cx="21.534"
                cy="21.411"
                r="19.399"
                fill="#16C612"
                stroke="#16C612"
                strokeOpacity="0.5"
                strokeWidth="4"
            ></circle>
            <path
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M15.116 22.563l3.936 3.935 10.494-10.494"
            ></path>
        </svg>
    )
}

export default CompleteStepIcon
