import React from 'react'

function InquiryRolesIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="19"
            fill="none"
            viewBox="0 0 17 19"
        >
            <path
                fill="#747474"
                d="M7.5 11.665v6.667H.833A6.667 6.667 0 017.5 11.665zm0-.833c-2.763 0-5-2.237-5-5 0-2.762 2.237-5 5-5 2.762 0 5 2.238 5 5 0 2.763-2.238 5-5 5zm2.162 4.843a2.921 2.921 0 010-1.353l-.827-.477.834-1.443.827.477c.327-.31.727-.544 1.17-.676v-.954h1.667v.954c.444.132.843.367 1.17.676l.828-.477.833 1.443-.826.477a2.927 2.927 0 010 1.353l.826.477-.833 1.443-.827-.477c-.328.31-.727.545-1.17.677v.954h-1.668v-.954a2.911 2.911 0 01-1.17-.677l-.827.477-.834-1.443.827-.477zm2.838-1.51a.833.833 0 100 1.667.833.833 0 000-1.667z"
            ></path>
        </svg>
    )
}

export default InquiryRolesIcon
