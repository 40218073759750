// antd
import { Typography } from 'antd'
import { ProjectOutlined } from '@ant-design/icons'

const NoActiveProject = () => {
    const { Text } = Typography

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    rowGap: 10,
                    marginTop: 100,
                }}
            >
                <ProjectOutlined
                    style={{
                        fontSize: 30,
                    }}
                />
                <Text
                    style={{
                        fontSize: 18,
                    }}
                >
                    Project is not Active yet in this Sprint, Please Select the
                    project to make active
                </Text>
            </div>
        </>
    )
}

export default NoActiveProject
