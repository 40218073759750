import React from 'react'

function NoVideosIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 512 500"
        >
            <g clipPath="url(#clip0_1474_3959)">
                <path
                    fill="#F2F2F2"
                    d="M158.139 468.09l.548-12.318a39.794 39.794 0 0118.555-4.697c-8.912 7.286-7.798 21.331-13.84 31.129a23.938 23.938 0 01-17.547 11.058l-7.458 4.566a40.102 40.102 0 018.452-32.492 38.723 38.723 0 017.118-6.613c1.786 4.709 4.172 9.367 4.172 9.367zM466.398 288.619c20.106-15.314 29.71-41.048 23.698-65.596-.15-.61-.307-1.219-.473-1.826-3.986-14.572-13.847-28.421-28.218-33.077-12.068-3.91-25.239-.903-37.829-2.458-24.713-3.052-43.872-23.047-57.353-43.983-13.481-20.936-23.842-44.37-41.756-61.666-29.527-28.51-77.694-34.648-114.304-16.09-36.61 18.557-59.93 59.729-58.935 100.761.994 41.033 25.354 80.263 60.729 101.077 12.996 7.646 28.027 13.063 42.985 11.168 12.98-1.645 24.773-8.619 37.691-10.69 20.311-3.255 40.492 5.928 58.059 16.629s34.535 23.385 54.498 28.346c19.383 4.817 43.398-9.029 61.208-22.595z"
                ></path>
                <path
                    fill="#fff"
                    d="M307.309 105.371H107.007a15.776 15.776 0 00-15.757 15.757v99.69a15.776 15.776 0 0015.757 15.756h200.302a15.768 15.768 0 0015.757-15.756v-99.69a15.773 15.773 0 00-15.757-15.757z"
                ></path>
                <path
                    fill="#CCC"
                    d="M307.31 237.351h-200.3a16.55 16.55 0 01-16.532-16.531v-99.694a16.552 16.552 0 0116.532-16.531h200.3a16.55 16.55 0 0116.531 16.531v99.694a16.55 16.55 0 01-16.531 16.531zm-200.3-131.207a15 15 0 00-14.982 14.982v99.694a15 15 0 0014.982 14.982h200.3a15.005 15.005 0 0010.589-4.393 15.005 15.005 0 004.393-10.589v-99.694a15.005 15.005 0 00-4.393-10.589 15.005 15.005 0 00-10.589-4.393h-200.3z"
                ></path>
                <path
                    fill="#E6E6E6"
                    d="M316.525 264.911H124.574a6.252 6.252 0 01-4.423-1.833 6.252 6.252 0 010-8.847 6.255 6.255 0 014.423-1.832h191.951a6.26 6.26 0 016.256 6.256 6.255 6.255 0 01-6.256 6.256z"
                ></path>
                <path
                    fill="#F2F2F2"
                    d="M186.108 205.256a6.73 6.73 0 01-3.249-.846 6.654 6.654 0 01-3.492-5.903V142.87a6.731 6.731 0 0110.346-5.685l52.778 27.819a6.738 6.738 0 010 11.369l-52.778 27.819a6.697 6.697 0 01-3.605 1.064z"
                ></path>
                <path
                    fill="#fff"
                    d="M496.052 177.517h-83.704a6.596 6.596 0 01-4.654-1.93 6.594 6.594 0 01-1.93-4.654v-41.661a6.592 6.592 0 016.584-6.584h83.704a6.588 6.588 0 016.584 6.584v41.661a6.59 6.59 0 01-6.584 6.584z"
                ></path>
                <path
                    fill="#CCC"
                    d="M496.053 178.292h-83.704a7.365 7.365 0 01-7.359-7.359v-41.661a7.368 7.368 0 017.359-7.359h83.704a7.365 7.365 0 017.359 7.359v41.661a7.365 7.365 0 01-7.359 7.359zm-83.704-54.83a5.814 5.814 0 00-5.809 5.81v41.661a5.813 5.813 0 005.809 5.81h83.704a5.818 5.818 0 005.81-5.81v-41.661a5.818 5.818 0 00-5.81-5.81h-83.704z"
                ></path>
                <path
                    fill="#E6E6E6"
                    d="M482.301 197.903h-56.386a6.257 6.257 0 010-12.512h56.386a6.256 6.256 0 010 12.512z"
                ></path>
                <path
                    fill="#4F46E5"
                    d="M445.2 164.928a2.82 2.82 0 01-1.358-.353 2.78 2.78 0 01-1.459-2.467v-23.25a2.812 2.812 0 012.904-2.813c.504.016.994.167 1.419.437l22.056 11.625a2.814 2.814 0 010 4.752l-22.056 11.625a2.793 2.793 0 01-1.506.444z"
                ></path>
                <path
                    fill="#fff"
                    d="M442.6 356.738h-83.703a6.594 6.594 0 01-6.585-6.584v-41.661a6.595 6.595 0 016.585-6.585H442.6a6.593 6.593 0 016.585 6.585v41.661a6.59 6.59 0 01-6.585 6.584z"
                ></path>
                <path
                    fill="#CCC"
                    d="M442.602 357.513h-83.704a7.365 7.365 0 01-7.359-7.359v-41.661a7.365 7.365 0 017.359-7.359h83.704a7.365 7.365 0 017.359 7.359v41.661a7.365 7.365 0 01-7.359 7.359zm-83.704-54.83a5.814 5.814 0 00-5.81 5.81v41.661a5.818 5.818 0 005.81 5.81h83.704a5.818 5.818 0 005.809-5.81v-41.661a5.813 5.813 0 00-5.809-5.81h-83.704z"
                ></path>
                <path
                    fill="#E6E6E6"
                    d="M428.85 377.236h-56.386a6.255 6.255 0 010-12.512h56.386a6.255 6.255 0 110 12.512z"
                ></path>
                <path
                    fill="#4F46E5"
                    d="M391.546 345.423a2.804 2.804 0 01-1.358-.354 2.77 2.77 0 01-1.459-2.466v-23.251a2.812 2.812 0 014.323-2.375l22.055 11.625a2.815 2.815 0 010 4.751l-22.055 11.625a2.802 2.802 0 01-1.506.445z"
                ></path>
                <path
                    fill="#A0616A"
                    d="M158.371 212.603l-42.841.287s-24.4-7.866-35.465-6.222l-4.231 16.62 30.393 10.07 52.896-7.675a7.574 7.574 0 0010.973-7.192 7.572 7.572 0 00-11.725-5.888z"
                ></path>
                <path
                    fill="#3F3D56"
                    d="M105.864 234.968l-50.828-14.619-4.627-17.242a13.922 13.922 0 011.076-14.657c4.34-5.877 7.731-.04 13.89 3.89 10.795.763 37.74 9.983 48.678 20.659l-.861 6.562-7.328 15.407z"
                ></path>
                <path
                    fill="#4F46E5"
                    d="M150.089 220.894a6.741 6.741 0 01-3.249-.847 6.638 6.638 0 01-3.492-5.902v-55.638a6.74 6.74 0 013.491-5.902 6.73 6.73 0 016.855.217l52.778 27.819a6.734 6.734 0 010 11.37l-52.778 27.819a6.71 6.71 0 01-3.605 1.064z"
                ></path>
                <path
                    fill="#A0616A"
                    d="M114.632 488.876h10.814l5.144-41.708-15.959.001.001 41.707z"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M111.873 485.346h3.044l11.882-4.833 6.369 4.832h.001a13.57 13.57 0 0113.57 13.571v.441l-34.865.001-.001-14.012z"
                ></path>
                <path
                    fill="#A0616A"
                    d="M37.63 478.697l10.243 3.467 18.25-37.853-15.116-5.119-13.376 39.505z"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M36.152 474.468l2.883.976 12.804-.766 4.483 6.619v.001a13.567 13.567 0 018.502 17.206l-.142.417-33.024-11.181 4.494-13.272zM50.166 309.228s-1.263 15.151 1.894 29.671c3.156 14.52 9.95 62.72 9.95 62.72-13.76 22.615-21.734 44.869-23.24 67.395l19.366 1.549 27.73-65.795 2.81-65.869 20.201 44.823-1.039 3.463a3.896 3.896 0 001.668 4.428s-1.891 12.311-.629 12.311c1.263 0 4.419 1.894 4.419 1.894-7.87 24.501-8.926 47.964-1.709 70.168l17.043-.775 11.181-96.539-8.207-52.399-.631-10.101-2.841.117-1.446-4.22-2.657-7.26-73.863 4.419z"
                ></path>
                <path
                    fill="#3F3D56"
                    d="M49.074 312.41l-.112-.158c-6.01-8.413 3.028-28.629 4.391-31.559l-5.741-1.863-5.02-42.482-3.672-14.676a32.22 32.22 0 019.145-31.225l8.487-8.002 10.314-15.261H83.5l5.703 10.772a24.246 24.246 0 0118.758 24.752l-1.293 28.252 5.06 35.177.003 6.366 8.785 14.403 1.913 8.93c.792 1.045 6.019 8.014 6.019 9.92 0 .286-.211.562-.664.869-3.562 2.412-23.251 7.33-31.965 3.843-9.282-3.712-46.18 1.856-46.552 1.913l-.192.029z"
                ></path>
                <path
                    fill="#CCC"
                    d="M218.972 499.078a.924.924 0 01-.565.851.92.92 0 01-.351.071H.922a.921.921 0 110-1.844H218.05a.922.922 0 01.922.917v.005z"
                ></path>
                <path
                    fill="#A0616A"
                    d="M77.33 162.257c12.167 0 22.029-9.862 22.029-22.028 0-12.166-9.862-22.028-22.028-22.028-12.166 0-22.028 9.862-22.028 22.028 0 12.166 9.862 22.028 22.028 22.028zM159.103 157.141l-33.986 26.085s-24.202 8.453-32.033 16.442l6.657 15.805 30.312-10.313 37.545-38.043a7.564 7.564 0 005.774-4.905 7.57 7.57 0 10-14.269-5.071z"
                ></path>
                <path
                    fill="#3F3D56"
                    d="M130.735 206.662l-47.213 13.825-16.233-5.761a13.917 13.917 0 01-7.987-12.336c-.087-7.305 6.14-4.698 13.422-5.281 9.069-5.906 36.118-14.817 51.282-12.906l3.274 5.753 3.455 16.706z"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M92.437 114.796c-1.58-.047-1.037-3.023-2.31-3.972a3.103 3.103 0 01-2.888 2.405 3.102 3.102 0 01-3.055-2.066 4.709 4.709 0 00-2.992-3.139c-.079 2.758-2.165 5.152-4.654 6.344-2.49 1.191-5.327 1.389-8.087 1.43-2.759.041-5.557-.052-8.233.624-2.676.675-5.278 2.262-6.421 4.774-1.143 2.511-.328 5.969 2.172 7.138-1.936.01-3.58 1.608-4.249 3.425a13.6 13.6 0 00-.42 5.747c.544 7.123 1.871 14.792 7.058 19.704 3.953 3.744 9.611 5.253 15.056 5.231 5.444-.022 4.487-.522 9.751-1.912 0 0 5.005-14.348 3.745-15.759-1.26-1.412-2.307-3.218-2.08-5.096.229-1.879 2.248-3.561 4-2.846-.295-.753-.517-.897-.656-.859.089-.214.166-.432.231-.654a7.45 7.45 0 014.653-4.933 5.782 5.782 0 016.314 1.91c5.764-5.406 2.188-17.225-6.935-17.496z"
                ></path>
                <path
                    fill="#CACACA"
                    d="M465.31 26.713H113.39a1.4 1.4 0 010-2.799h351.92a1.403 1.403 0 011.37 1.4 1.4 1.4 0 01-1.37 1.4z"
                ></path>
                <path
                    fill="#3F3D56"
                    d="M137.346 16.769c4.53 0 8.202-3.754 8.202-8.385 0-4.63-3.672-8.384-8.202-8.384-4.529 0-8.201 3.754-8.201 8.384 0 4.631 3.672 8.385 8.201 8.385zM165.678 16.769c4.53 0 8.202-3.754 8.202-8.385 0-4.63-3.672-8.384-8.202-8.384-4.529 0-8.201 3.754-8.201 8.384 0 4.631 3.672 8.385 8.201 8.385zM194.012 16.769c4.53 0 8.202-3.754 8.202-8.385 0-4.63-3.672-8.384-8.202-8.384-4.529 0-8.201 3.754-8.201 8.384 0 4.631 3.672 8.385 8.201 8.385zM448.251 2.078h-20.126a1.523 1.523 0 00-1.058 2.591c.279.285.659.45 1.058.457h20.126a1.527 1.527 0 001.494-1.524 1.525 1.525 0 00-1.494-1.524zM448.251 7.799h-20.126a1.523 1.523 0 00-1.058 2.59c.279.286.659.45 1.058.457h20.126a1.525 1.525 0 000-3.048zM448.251 13.511h-20.126a1.523 1.523 0 00-1.058 2.591c.279.285.659.45 1.058.457h20.126a1.525 1.525 0 000-3.048z"
                ></path>
                <path
                    fill="#CCC"
                    d="M278.16 105.371v21.04c0 1.045-1.828 1.921-4.292 2.161a.163.163 0 00-.062.008c-.41.031-.844.054-1.286.054a11.289 11.289 0 01-3.989-.651c-.116-.046-.233-.1-.349-.147-.108-.062-.224-.116-.333-.178-.612-.364-.968-.79-.968-1.247v-21.04h11.279z"
                ></path>
                <path
                    fill="#3F3D56"
                    d="M449.377 364.21c5.294 0 9.585-4.291 9.585-9.584 0-5.293-4.291-9.584-9.585-9.584a9.584 9.584 0 000 19.168z"
                ></path>
                <path
                    fill="#fff"
                    d="M448.659 358.457l-.061-.002a1.251 1.251 0 01-.929-.483l-1.602-2.059a1.249 1.249 0 01-.255-.925 1.253 1.253 0 01.475-.835l.057-.045a1.257 1.257 0 011.761.22.917.917 0 001.062.287.926.926 0 00.323-.219l3.252-3.434a1.255 1.255 0 011.773-.048l.053.051a1.246 1.246 0 01.391.877 1.242 1.242 0 01-.343.896l-5.047 5.327a1.26 1.26 0 01-.91.392z"
                ></path>
                <path
                    fill="#3F3D56"
                    d="M502.053 186.04c5.293 0 9.584-4.291 9.584-9.585a9.584 9.584 0 00-19.168 0c0 5.294 4.291 9.585 9.584 9.585z"
                ></path>
                <path
                    fill="#fff"
                    d="M501.337 180.286l-.061-.002a1.251 1.251 0 01-.93-.483l-1.601-2.059a1.26 1.26 0 01-.255-.925 1.27 1.27 0 01.154-.465c.082-.143.191-.269.321-.37l.057-.045a1.262 1.262 0 011.39-.1c.144.081.269.19.37.32a.92.92 0 00.677.352.92.92 0 00.708-.284l3.253-3.434a1.255 1.255 0 011.773-.048l.053.051a1.262 1.262 0 01.391.877 1.261 1.261 0 01-.343.896l-5.047 5.327a1.255 1.255 0 01-.91.392z"
                ></path>
            </g>
            <defs>
                <clipPath id="clip0_1474_3959">
                    <path fill="#fff" d="M0 0H511.638V500H0z"></path>
                </clipPath>
            </defs>
        </svg>
    )
}

export default NoVideosIcon
