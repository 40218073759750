import { useSelector } from 'react-redux'
import { Navigate, Outlet } from 'react-router-dom'

// slices
import { loginState } from '../login/login.slice'

// constants
import { DOMAIN_LIST, SUPER_ADMIN } from '../../constants/roles'

// routes
import { PRIVATE_ROUTES } from '../../routes'

// PUBLIC ROUTES
const PublicRoute = () => {
    const { userProfile, status } = useSelector(loginState)
    if (
        status !== 'loading' &&
        userProfile !== undefined &&
        userProfile?.role?.roleName !== undefined &&
        userProfile?.phone
    ) {
        if (
            userProfile?.role?.roleName === SUPER_ADMIN &&
            DOMAIN_LIST.includes(userProfile?.currentWorkSpace?.domain)
        ) {
            return <Navigate to={`${PRIVATE_ROUTES.workSpace}`} />
        } else if (
            userProfile?.activeProject === null &&
            DOMAIN_LIST.includes(userProfile?.currentWorkSpace?.domain)
        ) {
            return <Navigate to={`${PRIVATE_ROUTES.userWorkSpace}`} />
        } else if (
            userProfile?.activeProject === null &&
            !DOMAIN_LIST.includes(userProfile?.currentWorkSpace?.domain)
        ) {
            return <Navigate to={`${PRIVATE_ROUTES.projects.root}`} />
        } else {
            return <Navigate to={`${PRIVATE_ROUTES.overview}`} />
        }
    } else {
        return <Outlet />
    }
}

export default PublicRoute
