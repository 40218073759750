export const isEmpty = (data) => {
    if (typeof data !== 'object' || data === null) {
        if (data === undefined || data === null || data === 0 || data === '') {
            return true
        }
    } else if (typeof data === 'object') {
        if (Object.keys(data).length === 0) {
            return true
        }
    } else if (Array.isArray(data)) {
        if (data.length === 0) {
            return true
        }
    }

    return false
}
