import axiosNew from '../../helpers/axios'

// COUNT TEAMS
export const count = async (payload) => {
    const { filter = {}, search, projectId } = payload
    return new Promise(async (resolve) => {
        try {
            let data = { filter }
            if (search?.length > 0) {
                data['search'] = {
                    name: search,
                    email: search,
                    phone: search,
                }
            }
            const count = await axiosNew({
                url: 'teams/team-count/' + projectId,
                data,
            })
            resolve({ data: count?.data?.count ?? 0 })
        } catch (error) {
            resolve({ data: 0 })
        }
    })
}

// LIST TEAMS
export const read = (payload) => {
    const { search = '', skip = 0, limit = 0, filter = {}, projectId } = payload
    return new Promise(async (resolve) => {
        try {
            let data = {
                skip,
                filter,
                limit,
                select: [
                    'name',
                    'email',
                    'designation',
                    'isActive',
                    'profilePicture',
                ],
                populate: {},
            }
            if (search?.length > 0) {
                data['search'] = {
                    name: search,
                    email: search,
                    phone: search,
                }
            }
            const users = await axiosNew({
                url: 'teams/list-member/' + projectId,
                data,
            })
            resolve({
                data: users?.data?.data ?? [],
                isNext: users?.data?.isNext,
            })
        } catch (error) {
            resolve({ data: [] })
        }
    })
}

// ADD / REMOVE TEAM MEMBER
export const addRemoveTeamMember = async (payload) => {
    return new Promise(async (resolve) => {
        try {
            let data = payload?.member ?? {}
            const addRemoveMember = await axiosNew({
                url: 'teams/add-remove-team-members/' + payload?.id,
                data,
            })
            resolve({ data: addRemoveMember?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// UPDATE USER
export const update = async (payload) => {
    return new Promise(async (resolve) => {
        try {
            let data = payload?.updatedData ?? {}
            const update = await axiosNew({
                url: 'users/update/' + payload._id,
                data,
            })
            resolve({ data: update?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}

// LIST ROLES
export const listRoles = async () => {
    return new Promise(async (resolve) => {
        try {
            let data = { select: ['roleName'] }
            const roleList = await axiosNew({
                url: 'role/list/',
                data,
            })
            resolve({ data: roleList?.data?.data ?? {} })
        } catch (error) {
            resolve({ data: error?.response?.data ?? {} })
        }
    })
}
