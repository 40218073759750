import React from 'react'

function StartRecordingIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="49"
            height="49"
            fill="none"
            viewBox="0 0 49 49"
        >
            <circle cx="24.5" cy="24.5" r="24.5" fill="#4F46E5"></circle>
            <circle cx="24.5" cy="24.5" r="9.5" fill="#fff"></circle>
        </svg>
    )
}

export default StartRecordingIcon
