import React from 'react'

function SkillsIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="none"
            viewBox="0 0 20 20"
        >
            <path
                fill="#4F46E5"
                d="M3.637 10.707l1.829 1.828 1.414-1.414L5.05 9.293l1.415-1.415 1.828 1.829 1.414-1.414L7.88 6.464 9.294 5.05l1.828 1.828 1.415-1.414-1.829-1.828L13.537.807a1 1 0 011.414 0l4.242 4.243a1 1 0 010 1.414L6.466 19.192a1 1 0 01-1.415 0L.81 14.949a1 1 0 010-1.414l2.828-2.828zm8.485 5.656l4.243-4.243 2.636 2.636V19h-4.242l-2.637-2.636zM3.637 7.878L.809 5.049a1 1 0 010-1.414L3.637.807a1 1 0 011.414 0L7.88 3.635 3.637 7.878z"
            ></path>
        </svg>
    )
}

export default SkillsIcon
