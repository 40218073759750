import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import {
    addNotes,
    listNotes,
    updateNotes,
    deleteNotes,
    countNotes,
    getNote,
    addRemoveComment,
} from './notes.service'

const initialState = {
    search: '',
    noteStatus: 'loaded',
    updatedDataStatus: 'loaded',
    notesList: [],
    notesIsNext: false,
    notesPageLimit: 10,
    notesCurrentPage: 1,
    notesSkipPage: 0,
    notesCount: 0,
    selectedNote: undefined,
}

export const countTotalNotes = createAsyncThunk(
    'notesSlice/countNotes',
    async (payload = {}) => {
        const response = await countNotes(payload)
        return response
    }
)

export const addProjectNotes = createAsyncThunk(
    'notesSlice/addNotes',
    async (payload = {}) => {
        const response = await addNotes(payload)
        return response
    }
)

export const listProjectNotes = createAsyncThunk(
    'notesSlice/listNotes',
    async (payload = {}) => {
        const response = await listNotes(payload)
        return response
    }
)

export const updateProjectNotes = createAsyncThunk(
    'notesSlice/updateNotes',
    async (payload = {}) => {
        const response = await updateNotes(payload)
        return response
    }
)

export const deleteProjectNotes = createAsyncThunk(
    'notesSlice/deleteNotes',
    async (payload = {}) => {
        const response = await deleteNotes(payload)
        return response
    }
)

export const getProjectNote = createAsyncThunk(
    'notesSlice/getNote',
    async (payload = {}) => {
        const response = await getNote(payload)
        return response
    }
)

export const addRemoveProjectComments = createAsyncThunk(
    'notesSlice/addRemoveComment',
    async (payload = {}) => {
        const response = await addRemoveComment(payload)
        return response
    }
)

export const notesSlice = createSlice({
    name: 'notesSlice',
    initialState,
    reducers: {
        changeNotesPage: (state, action) => {
            state.notesCurrentPage = action.payload.currentPage
            state.notesSkipPage = action.payload.skip
            state.notesPageLimit = action.payload.pageLimit
        },
        searchNotes: (state, action) => {
            state.search = action.payload
        },
        clearNote: (state) => {
            state.selectedNote = undefined
        },
        switchEditor: (state, action) => {
            if (action.payload?.name === 'add') {
                state.editModeEditor = false
            } else {
                state.editModeEditor = state.editModeEditor ? false : true
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(countTotalNotes.fulfilled, (state, action) => {
                state.notesCount = action.payload.data
            })
            .addCase(listProjectNotes.pending, (state) => {
                state.noteStatus = 'loading'
            })
            .addCase(listProjectNotes.fulfilled, (state, action) => {
                state.noteStatus = 'loaded'
                if (action.payload.skip > 0) {
                    state.notesList = [
                        ...state.notesList,
                        ...action.payload.data,
                    ]
                } else {
                    state.notesList = action.payload.data
                }
                state.notesIsNext = action.payload.isNext
            })
            .addCase(getProjectNote.pending, (state, action) => {
                state.updatedDataStatus = 'loading'
            })
            .addCase(getProjectNote.fulfilled, (state, action) => {
                state.updatedDataStatus = 'loaded'
                state.selectedNote = action.payload.data
            })
    },
})

export default notesSlice.reducer

export const notesState = (state) => {
    const { notesSlice } = state
    const {
        search,
        noteStatus,
        updatedDataStatus,
        notesList,
        notesIsNext,
        notesPageLimit,
        notesCurrentPage,
        notesSkipPage,
        notesCount,
        selectedNote,
    } = notesSlice
    return {
        search,
        noteStatus,
        updatedDataStatus,
        notesList,
        notesIsNext,
        notesPageLimit,
        notesCurrentPage,
        notesSkipPage,
        notesCount,
        selectedNote,
    }
}

export const { changeNotesPage, searchNotes, clearNote, switchEditor } =
    notesSlice.actions
