import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// antd
import { Avatar, Image, Row, Select, Spin } from 'antd'

// slices
import {
    projectsList,
    projectsState,
    changePage as changeProjectPage,
} from '../projects/projects.slice'
import { changePage, teamListGet, teamState } from '../team/team.slice'
import { loginState } from '../login/login.slice'
import {
    dateRangesList,
    filterChangeReports,
    projectReportsState,
} from './projectReports/projectReports.slice'

// constants
import { WORKSPACE_ADMIN, SUPER_ADMIN } from '../../constants/roles'

// filter
import DateFilter from './dateFilter'

const ProjectWiseFilter = ({ name }) => {
    const { Option } = Select

    const dispatch = useDispatch()

    const { pageLimit, currentPage, skipPage, isNext, projects } =
        useSelector(projectsState)

    const { filter } = useSelector(projectReportsState)
    const {
        teamList,
        skipPage: teamSkipPage,
        currentPage: teamCurrentPage,
        pageLimit: teamPageLimit,
        isNext: teamIsNext,
    } = useSelector(teamState)
    const { userProfile } = useSelector(loginState)

    const initialState = {
        filteredAssignees: [],
        optionLoading: false,
        filteredProjects: [],
        projectOptionLoading: false,
        selectAssignees: [],
        selectProjects: [],
    }

    const [state, setState] = useState(initialState)

    // USER DATA SET WITH FILTER
    useEffect(() => {
        if (teamList?.length > 0) {
            const filterUsers = teamList.map((data) => {
                return {
                    label: data?.name,
                    value: data?._id,
                    profilePicture: data?.profilePicture,
                }
            })
            setState((prevState) => ({
                ...prevState,
                filteredAssignees: filterUsers,
            }))
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [teamList])

    // PROJECT DATA SET WITH FILTER
    useEffect(() => {
        if (projects?.length > 0) {
            let filterProjects = []

            const projectsList = projects.map((data) => {
                return {
                    label: data?.name,
                    value: data?._id,
                    projectProfile: data?.projectProfile,
                }
            })
            filterProjects = [...projectsList]

            setState((prevState) => ({
                ...prevState,
                filteredProjects: filterProjects,
            }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projects])

    // ON SCROLL TEAM MEMBER LIST
    const onScrollTeam = async (e) => {
        let scroll =
            Math.ceil(e.target.offsetHeight + e.target.scrollTop) >=
            e.target.scrollHeight
        if (scroll) {
            if (teamIsNext) {
                setState((prevState) => ({ ...prevState, optionLoading: true }))
                dispatch(
                    teamListGet({
                        skip: teamSkipPage + teamPageLimit,
                        limit: teamPageLimit,
                    })
                )
                dispatch(
                    changePage({
                        currentPage: teamCurrentPage + 1,
                        skip: teamSkipPage + teamPageLimit,
                        pageLimit: teamPageLimit,
                    })
                )
                setState((prevState) => ({
                    ...prevState,
                    optionLoading: false,
                }))
            }
        }
    }

    // ON SCROLL TEAM MEMBER LIST
    const onScrollProject = async (e) => {
        let scroll =
            Math.ceil(e.target.offsetHeight + e.target.scrollTop) >=
            e.target.scrollHeight
        if (scroll) {
            if (isNext) {
                setState((prevState) => ({
                    ...prevState,
                    projectOptionLoading: true,
                }))
                dispatch(
                    projectsList({
                        skip: skipPage + pageLimit,
                        limit: pageLimit,
                    })
                )
                dispatch(
                    changeProjectPage({
                        currentPage: currentPage + 1,
                        skip: skipPage + pageLimit,
                        pageLimit: pageLimit,
                    })
                )
                setState((prevState) => ({
                    ...prevState,
                    projectOptionLoading: false,
                }))
            }
        }
    }

    return (
        <Row
            align={'middle'}
            justify={'space-between'}
            style={{
                width: '100%',
                flexFlow: 'row nowrap',
            }}
        >
            <Row
                align={'middle'}
                style={{
                    columnGap: '20px',
                    width: '100%',
                    flexFlow: 'row nowrap',
                }}
            >
                <Select
                    placeholder="Select a Project"
                    onChange={(value) => {
                        setState((prevState) => ({
                            ...prevState,
                            selectProjects: value,
                            filteredAssignees: [],
                        }))
                        dispatch(
                            filterChangeReports({
                                ...filter,
                                projectId: value,
                            })
                        )
                    }}
                    notFoundContent={
                        state?.projectOptionLoading ? (
                            <Spin size="small" />
                        ) : null
                    }
                    onPopupScroll={onScrollProject}
                    style={{
                        width: '100%',
                    }}
                    getPopupContainer={() => document.body}
                    maxTagCount={1}
                    value={filter?.projectId}
                    rootClassName={['projectReportsSelect']}
                >
                    {state?.filteredProjects?.length > 0 &&
                        state?.filteredProjects.map((data) => {
                            return (
                                <Option value={data?.value} key={data?.label}>
                                    <Row
                                        align={'middle'}
                                        style={{
                                            columnGap: '10px',
                                        }}
                                    >
                                        <Image
                                            src={data?.projectProfile}
                                            width={22}
                                            height={22}
                                            style={{
                                                borderRadius: '50%',
                                                objectFit: 'cover',
                                                minWidth: '22px',
                                            }}
                                            preview={false}
                                        />
                                        {data?.label}
                                    </Row>
                                </Option>
                            )
                        })}
                </Select>

                {(userProfile?.role?.roleName === WORKSPACE_ADMIN ||
                    userProfile?.role?.roleName === SUPER_ADMIN) &&
                    state?.filteredAssignees?.length > 0 && (
                        <Select
                            placeholder="Select a User"
                            onChange={(value) => {
                                setState((prevState) => ({
                                    ...prevState,
                                    selectAssignees:
                                        typeof value === 'object'
                                            ? value
                                            : [value],
                                }))
                                dispatch(
                                    filterChangeReports({
                                        ...filter,
                                        assigneeId:
                                            typeof value === 'object'
                                                ? value
                                                : [value],
                                    })
                                )
                            }}
                            value={
                                name === 'screenShots'
                                    ? filter?.assigneeId[0]
                                    : filter?.assigneeId
                            }
                            notFoundContent={
                                state?.optionLoading ? (
                                    <Spin size="small" />
                                ) : null
                            }
                            onPopupScroll={onScrollTeam}
                            style={{
                                width: '100%',
                            }}
                            mode={name !== 'screenShots' && 'multiple'}
                            getPopupContainer={() => document.body}
                            maxTagCount={1}
                            rootClassName={[
                                name !== 'screenShots' ? 'p-0-multiSelect' : '',
                                'projectReportsSelect',
                            ]}
                        >
                            {state?.filteredAssignees?.length > 0 &&
                                state?.filteredAssignees.map((data) => {
                                    return (
                                        <Option
                                            value={data?.value}
                                            key={data?.label}
                                        >
                                            <Row
                                                align={'middle'}
                                                style={{
                                                    columnGap: '10px',
                                                }}
                                            >
                                                {data?.profilePicture ? (
                                                    <>
                                                        <Image
                                                            src={
                                                                data?.profilePicture
                                                            }
                                                            width={25}
                                                            height={25}
                                                            style={{
                                                                borderRadius:
                                                                    '50%',
                                                                minWidth:
                                                                    '25px',
                                                                objectFit:
                                                                    'cover',
                                                            }}
                                                            preview={false}
                                                        />
                                                    </>
                                                ) : (
                                                    <Avatar
                                                        style={{
                                                            backgroundColor:
                                                                'rgb(0 10 129 / 25%)',
                                                            height: '25px',
                                                            width: '25px',
                                                            minWidth: '25px',
                                                            display: 'flex',
                                                            justifyContent:
                                                                'center',
                                                            alignItems:
                                                                'center',
                                                            fontSize: '14px',
                                                        }}
                                                    >
                                                        {data?.label[0].toUpperCase()}
                                                        {data?.label.split(
                                                            ' '
                                                        )[1] !== undefined &&
                                                            data?.label.split(
                                                                ' '
                                                            )[1] !== '' &&
                                                            data?.label
                                                                .split(
                                                                    ' '
                                                                )[1][0]
                                                                .toUpperCase()}
                                                    </Avatar>
                                                )}
                                                {data?.label}
                                            </Row>
                                        </Option>
                                    )
                                })}
                        </Select>
                    )}
            </Row>
            <DateFilter
                props={{
                    dateRangesList: dateRangesList,
                    name: name,
                }}
            />
        </Row>
    )
}

export default ProjectWiseFilter
