import axiosNew from '../../../helpers/axios'

export const getScreenShots = (payload) => {
    let data = payload ?? {}

    return new Promise(async (resolve) => {
        try {
            const getScreenShots = await axiosNew({
                url: `screenshots/list`,
                data,
            })
            resolve({
                data: getScreenShots?.data ?? [],
                isNext: getScreenShots?.data?.isNext,
            })
        } catch (error) {
            resolve({ data: [] })
        }
    })
}
