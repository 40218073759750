import React from 'react'

function MouseIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="none"
            viewBox="0 0 16 16"
        >
            <path
                stroke="#747474"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8 14.667c2.753 0 5-2.246 5-5V6.334c0-2.753-2.247-5-5-5a5.01 5.01 0 00-5 5v3.333c0 2.754 2.247 5 5 5z"
            ></path>
            <path
                stroke="#747474"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8 4c-.553 0-1 .447-1 1v1.334c0 .553.447 1 1 1 .547 0 1-.447 1-1V5.001c0-.554-.453-1-1-1zm0 0V1.335"
            ></path>
        </svg>
    )
}

export default MouseIcon
