import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { io } from 'socket.io-client'

// antd
import { Col, Form, Input, Row, Typography, message, theme } from 'antd'

// slices
import {
    isLoggedInLogin,
    loginForm,
    loginState,
    setEmailDisable,
    setSocketClient,
    setUserProfile,
} from './login.slice'
import { setGlobalTimer } from '../tasks/tasks.slice'

// components
import Button from '../../components/button/button'
import SocialButtonLogin from './socialButtonLogin'

// assets
import { PasswordHideIcon, PasswordShowIcon } from '../../assets/icons'

// constants
import { DOMAIN_LIST } from '../../constants/roles'
import { EMAIL_PATTERN } from '../../constants/patterns'

// routes
import { PUBLIC_ROUTES } from '../../routes'

function LoginForm() {
    const [form] = Form.useForm()

    const { useToken } = theme

    const { token } = useToken()

    const navigate = useNavigate()

    const dispatch = useDispatch()

    const { userProfile } = useSelector(loginState)

    const { Title, Text } = Typography

    const [loadingButton, setLoadingButton] = useState(false)

    function onFinishFailed() {}

    useEffect(() => {
        dispatch(setEmailDisable(false))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const connectClient = (token, userId) => {
        const socket = io.connect(process.env.REACT_APP_SOCKET_URL, {
            query: { token },
        })
        try {
            socket.on('connect', () => {
                dispatch(setSocketClient({ socket: socket, token: token }))

                socket.emit('join chat', userId)
            })
        } catch (error) {
            console.log('error', error)
        }
    }

    // ON LOGIN VIA EMAIL AND PASSWORD
    async function onLogin(value) {
        setLoadingButton(true)
        const result = await dispatch(
            loginForm({
                ...value,
            })
        )
        const data = result?.payload?.data
        if (data) {
            const { success, message: loginMessage } = data
            if (success) {
                const checkLogin = await dispatch(isLoggedInLogin())
                const checkSuccessLogin = checkLogin?.payload?.data
                const {
                    success: checkSuccess,
                    message: checkMessage,
                    data: userProfile,
                } = checkSuccessLogin
                if (checkSuccess) {
                    message.success(loginMessage)
                    const response = await fetch(
                        `${process.env.REACT_APP_SOCKET_URL}/api/v1/get-token?userId=${userProfile?._id}&clientId=${process.env.REACT_APP_CLIENT_ID}`
                    )

                    // if (!response.ok) {
                    //     throw new Error(
                    //         `HTTP error! Status: ${response.status}`
                    //     )
                    // }

                    const tokenData = await response.json()

                    if (tokenData?.success) {
                        connectClient(tokenData?.data, userProfile?._id)
                    }

                    dispatch(setGlobalTimer(userProfile?.runningTask))

                    localStorage.setItem(
                        'currentProject',
                        JSON.stringify({
                            id: checkSuccessLogin?.data?.activeProject?._id,
                            name: checkSuccessLogin?.data?.activeProject?.name,
                        })
                    )
                    setLoadingButton(false)
                } else {
                    setLoadingButton(false)
                    if (checkMessage) {
                        message.error(checkMessage)
                    } else {
                        message.error('Something went wrong, try again later.')
                    }
                }
            } else {
                setLoadingButton(false)
                if (loginMessage) {
                    message.error(loginMessage)
                } else {
                    message.error('Something went wrong, try again later.')
                }
            }
        }
    }

    return (
        <div
            style={{
                padding: '60px 60px 0px 60px',
            }}
        >
            <Title level={3}>Log In to Software Co</Title>
            <Form
                layout="vertical"
                name="loginForm"
                onFinish={onLogin}
                onFinishFailed={onFinishFailed}
                requiredMark={false}
                form={form}
                style={{
                    marginTop: '20px',
                }}
            >
                <Col
                    span={24}
                    style={{
                        padding: '0px',
                        marginBottom: '10px',
                    }}
                >
                    <Form.Item
                        label="Email Id"
                        name="email"
                        rules={[
                            {
                                validator: async (_, value) => {
                                    const pattern = EMAIL_PATTERN

                                    if (
                                        value?.length === 0 ||
                                        value === undefined
                                    ) {
                                        return Promise.reject(
                                            new Error('Please Enter Email')
                                        )
                                    }
                                    if (
                                        !pattern.test(value) &&
                                        value?.length > 0
                                    ) {
                                        return Promise.reject(
                                            new Error(
                                                'Please Enter a valid Email Id'
                                            )
                                        )
                                    }
                                },
                            },
                        ]}
                        colon={false}
                    >
                        <Input placeholder="Enter Email Id" />
                    </Form.Item>
                </Col>
                <Col
                    span={24}
                    style={{
                        padding: '0px',
                        marginBottom: '10px',
                    }}
                >
                    <Form.Item
                        label="Password"
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: 'Please Enter Password',
                            },
                        ]}
                        colon={false}
                    >
                        <Input.Password
                            placeholder="Enter Password"
                            style={{
                                padding: '0px 11px',
                            }}
                            iconRender={(visible) =>
                                visible ? (
                                    <div
                                        onClick={() => {}}
                                        style={{
                                            cursor: 'pointer',
                                        }}
                                    >
                                        <PasswordShowIcon />
                                    </div>
                                ) : (
                                    <div
                                        onClick={() => {}}
                                        style={{
                                            cursor: 'pointer',
                                        }}
                                    >
                                        <PasswordHideIcon />
                                    </div>
                                )
                            }
                        />
                    </Form.Item>
                </Col>
                <Row>
                    <Col span={24}>
                        <Text
                            style={{
                                color: token.colorPalette.registrationColor
                                    .secondary,
                                float: 'right',
                                cursor: 'pointer',
                            }}
                            className="sub-title"
                            onClick={() =>
                                navigate(PUBLIC_ROUTES.forgotPassword)
                            }
                        >
                            Forgot password?
                        </Text>
                    </Col>
                </Row>
                <Row
                    gutter={20}
                    style={{
                        marginTop: '20px',
                    }}
                >
                    <Col span={24}>
                        <Button
                            props={{
                                text: 'Log In',
                                htmlType: 'submit',
                                style: {
                                    backgroundColor:
                                        token.colorPalette.registrationColor
                                            .secondary,
                                    width: '100%',
                                },
                                disabled: loadingButton,
                                loadingButton: loadingButton,
                            }}
                        />
                    </Col>
                </Row>
            </Form>
            {(DOMAIN_LIST.includes(window.location.hostname) ||
                window.location.hostname === 'localhost') && (
                <div
                    style={{
                        marginTop: '20px',
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <Text className="titleLight sub-title">
                        Don’t have an account?{' '}
                        <span
                            style={{
                                color: token.colorPalette.registrationColor
                                    .secondary,
                                textDecoration: 'underline',
                                cursor: 'pointer',
                            }}
                            className="sub-title"
                            onClick={() => {
                                dispatch(
                                    setUserProfile({
                                        ...userProfile,
                                        name: '',
                                        email: '',
                                    })
                                )
                                navigate(PUBLIC_ROUTES.signUp)
                            }}
                        >
                            Register
                        </span>
                    </Text>
                </div>
            )}
            <SocialButtonLogin />
        </div>
    )
}

export default LoginForm
