// antd
import { Typography, theme } from 'antd'

function NoIndividualContent({ content }) {
    const { Title } = Typography

    const { useToken } = theme
    const { token } = useToken()

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: '110px',
                }}
            >
                <div
                    style={{
                        // height: '330px',
                        width: '250px',
                    }}
                >
                    {content?.icon}
                </div>
                <Title
                    className={'titleLight'}
                    style={{
                        marginTop: '30px',
                        fontSize: token.fontSizeHeading4,
                        textTransform: 'capitalize',
                    }}
                >
                    {`No ${content?.key} added`}
                </Title>
            </div>
        </>
    )
}

export default NoIndividualContent
