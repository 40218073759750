import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// antd
import { Tabs } from 'antd'

// slices
import {
    projectsList,
    projectsState,
    setGeneralSettingsData,
} from '../projects/projects.slice'
import { teamListGet, teamState } from '../team/team.slice'
import { loginState } from '../login/login.slice'
import {
    filterChangeReports,
    projectReportsState,
} from './projectReports/projectReports.slice'
import { epicsList, sprintsList, sprintsState } from '../sprints/sprints.slice'

// component
import ReportsTimeSheet from './reportsTimeSheet'
import ScreenShotsPage from './screenshots'
import ProjectsReport from './projectReports'

// helpers
import { isEmpty } from '../../helpers/fieldCheck'

// constants
import { SUPER_ADMIN, WORKSPACE_ADMIN } from '../../constants/roles'

// filter component
import ProjectWiseFilter from './projectwiseFilter'

const Reports = () => {
    const dispatch = useDispatch()

    const [activeTab, setActiveTab] = useState('timeSheet')

    const { pageLimit: teamPageLimit, teamList } = useSelector(teamState)
    const { pageLimit } = useSelector(projectsState)
    const { userProfile } = useSelector(loginState)
    const { filter } = useSelector(projectReportsState)
    const { skipPageEpic, pageLimitEpic } = useSelector(sprintsState)

    const onChange = (key) => {
        if (activeTab === 'screenShots') {
            if (
                userProfile?.role?.roleName !== SUPER_ADMIN &&
                userProfile?.role?.roleName !== WORKSPACE_ADMIN
            ) {
                dispatch(
                    filterChangeReports({
                        assigneeId: [userProfile?._id],
                        projectId: userProfile?.activeProject?._id,
                    })
                )
            } else {
                dispatch(
                    filterChangeReports({
                        assigneeId: [],
                        projectId: userProfile?.activeProject?._id,
                    })
                )
            }
        }

        if (key === 'screenShots') {
            if (
                userProfile?.role?.roleName !== SUPER_ADMIN &&
                userProfile?.role?.roleName !== WORKSPACE_ADMIN
            ) {
                dispatch(
                    filterChangeReports({
                        assigneeId: [userProfile?._id],
                        projectId: userProfile?.activeProject?._id,
                    })
                )
            } else {
                dispatch(
                    filterChangeReports({
                        assigneeId:
                            teamList?.length > 0 ? [teamList[0]?._id] : [],
                        projectId: userProfile?.activeProject?._id,
                    })
                )
            }
        }
        setActiveTab(key)
    }

    async function activeProjectDetails() {
        const result = await dispatch(
            projectsList({
                id: userProfile?.activeProject?._id,
            })
        )
        const data = result?.payload?.data
        if (data) {
            dispatch(setGeneralSettingsData(data))
        }
    }

    // USER LIST DATA
    useEffect(() => {
        dispatch(projectsList({ limit: 100, skip: 0 }))

        if (
            userProfile?.role?.roleName !== SUPER_ADMIN &&
            userProfile?.role?.roleName !== WORKSPACE_ADMIN
        ) {
            dispatch(
                filterChangeReports({
                    assigneeId: [userProfile?._id],
                    projectId: userProfile?.activeProject?._id,
                })
            )
        } else {
            dispatch(
                filterChangeReports({
                    assigneeId: teamList?.length > 0 ? [teamList[0]?._id] : [],
                    projectId: userProfile?.activeProject?._id,
                })
            )
        }

        if (
            userProfile?.activeProject &&
            !isEmpty(userProfile?.activeProject)
        ) {
            dispatch(sprintsList())
            activeProjectDetails()
            dispatch(
                epicsList({
                    skip: skipPageEpic,
                    limit: pageLimitEpic,
                })
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (filter?.projectId?.length !== 0) {
            dispatch(
                teamListGet({
                    limit: teamPageLimit,
                    skip: 0,
                    projectId: filter?.projectId,
                })
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter?.projectId])

    const items = [
        {
            key: 'timeSheet',
            label: 'Timesheet',
        },
        {
            key: 'screenShots',
            label: 'Screenshots',
        },
        {
            key: 'projectsTasks',
            label: 'Projects & Tasks',
        },
    ]

    return (
        <>
            <div className={'container'}>
                <div
                    style={{
                        padding: '0px 22px',
                    }}
                >
                    <Tabs
                        onChange={onChange}
                        items={items}
                        rootClassName="generalTabs"
                    />
                    <ProjectWiseFilter name={activeTab} />
                    {activeTab === 'timeSheet' && (
                        <ReportsTimeSheet userProfile={userProfile} />
                    )}
                    {activeTab === 'screenShots' && <ScreenShotsPage />}
                    {activeTab === 'projectsTasks' && <ProjectsReport />}
                </div>
            </div>
        </>
    )
}

export default Reports
