import { useDispatch, useSelector } from 'react-redux'

// antd
import { Tabs, theme } from 'antd'

// slices
import { changeTab, profileState } from '../profile.slice'

// section
import OverviewTabSection from './overviewTab.section'
import EducationTabContent from '../education/educationTabContent'
import ExperienceTabContent from '../experience/experienceTabContent'
// import CertificatesTabContent from '../certificates/certificatesTabContent'
import ProjectsTabContent from '../projects/projectsTabContent'
import VideosTabContent from '../videos/videosTabContent'

function TabSectionProfile() {
    const { useToken } = theme
    const { token } = useToken()

    const dispatch = useDispatch()

    const { defaultTab } = useSelector(profileState)

    // TAB LIST
    const tabs = [
        {
            key: 'summary',
            label: 'Overview',
            children: <OverviewTabSection />,
        },
        {
            key: 'education',
            label: 'Education',
            children: <EducationTabContent />,
        },
        // {
        //     key: 'certificates',
        //     label: 'Certificates',
        //     children: <CertificatesTabContent />,
        // },
        {
            key: 'experience',
            label: 'Experience',
            children: <ExperienceTabContent />,
        },
        {
            key: 'projects',
            label: 'Projects',
            children: <ProjectsTabContent />,
        },
        {
            key: 'videos',
            label: 'Videos',
            children: <VideosTabContent />,
        },
        // {
        //     key: 'assessments',
        //     label: 'Assessments',
        //     // children: <VideosTabContent />,
        // },
    ]

    return (
        <>
            <Tabs
                defaultActiveKey={defaultTab}
                activeKey={defaultTab}
                onChange={(activeKey) => {
                    dispatch(changeTab(activeKey))
                }}
                rootClassName="profileTabs"
                style={{
                    width: '100%',
                }}
                tabBarStyle={{
                    backgroundColor: token.colorPalette.baseColor.tertiary,
                    color: token.colorPalette.textColor.tertiary,
                    borderTopLeftRadius: '15px',
                    borderTopRightRadius: '15px',
                    padding: '18px 37px',
                    width: '100%',
                    margin: '0px',
                }}
                items={tabs}
            />
        </>
    )
}

export default TabSectionProfile
