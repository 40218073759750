// constants
import { DOMAIN_LIST, SUPER_ADMIN } from '../constants/roles'
import { SETTINGS_MENU } from '../constants/settings'

export const checkSettingModule = (role, workSpace) => {
    let settingsData
    if (role === SUPER_ADMIN && DOMAIN_LIST.includes(workSpace)) {
        settingsData = SETTINGS_MENU.filter((data) =>
            DOMAIN_LIST.some((ai) => data?.workSpace.includes(ai))
        ).map((data) => data)
    } else {
        settingsData = SETTINGS_MENU.filter(
            (data) => !DOMAIN_LIST.some((ai) => data?.workSpace.includes(ai))
        ).map((data) => data)
    }
    return settingsData
}
