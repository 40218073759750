// antd
import { Col, Row, Skeleton, theme } from 'antd'

// constants
import { dummyLoopTask } from '../../constants/general'

const TaskLoading = () => {
    const { useToken } = theme

    const { token } = useToken()

    return (
        <>
            <Row wrap={false} gutter={14}>
                {dummyLoopTask?.length > 0 &&
                    dummyLoopTask.map((_) => (
                        <Col
                            xs={12}
                            sm={12}
                            md={12}
                            lg={10}
                            xl={6}
                            xxl={5}
                            style={{
                                minHeight: '400px',
                            }}
                        >
                            <Skeleton.Input
                                active={true}
                                rootClassName="taskSkeletonLoading"
                                style={{
                                    borderRadius: 12,
                                    height: '600px',
                                    backgroundColor:
                                        token.colorPalette.baseColor.quaternary,
                                }}
                            />
                        </Col>
                    ))}
            </Row>
        </>
    )
}

export default TaskLoading
