import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Resizable } from 're-resizable'
import Draggable from 'react-draggable'
import { debounce } from 'lodash'
import './roadMap.css'

// antd
import {
    Avatar,
    Button,
    Col,
    Drawer,
    Image,
    Input,
    Row,
    Skeleton,
    Tooltip,
    Typography,
    message,
    theme,
} from 'antd'

import {
    CheckOutlined,
    UserOutlined,
    BugOutlined,
    ProjectOutlined,
    DownloadOutlined,
    SearchOutlined,
} from '@ant-design/icons'

// slices
import {
    roadmapList,
    roadmapState,
    setRoadMapTask,
    setSlotWidth,
    setSlots,
} from './roadmap.slice'
import { loginState } from '../login/login.slice'

// css
import styles from './roadmap.module.css'

import dayjs from 'dayjs'
import { colorTaskRandom } from '../../constants/tasks'
import NoTaskFound from './noTaskFound'

import { useLocation, useNavigate } from 'react-router-dom'

// slices
import {
    clearTaskFileUpload,
    filterDropdown,
    searchTasks,
    selectTaskUpdate,
    switchFormModal,
    switchFormModalSubTask,
    taskById,
    taskFileError,
    taskList,
    tasksState,
} from '../tasks/tasks.slice'
import { setGeneralSettingsData, projectGet } from '../projects/projects.slice'
import { epicsList, sprintsList, sprintsState } from '../sprints/sprints.slice'
import { teamListGet, teamState } from '../team/team.slice'

// routes
import { PRIVATE_ROUTES } from '../../routes'

// components
import PanelHeader from '../../components/panelHeader/panelHeader'

// assets
import { SprintCreateIcon } from '../../assets/icons'

// taskForm
import DrawerTaskForm from '../tasks/taskForm.index'

// helpers
import { addPermissionCheck } from '../../helpers/permissionCheck'

// sections
import TaskBoard from '../tasks/taskBoard'
import { downloadSprintsData } from '../sprints/sprints.service'
import { taskUpdate } from '../tasks/tasks.slice'
import TaskLoading from '../tasks/taskLoading'
import TasksFilter from '../tasks/tasks.filter'

const initialDownloadState = {
    isDownloadExcel: false,
    activeSprint: null,
}

const RoadMapMain = () => {
    const { Text } = Typography

    const dispatch = useDispatch()

    const params = useLocation()
    const navigate = useNavigate()

    const {
        tasks,
        formModal,
        skipPage,
        pageLimit,
        status,
        filterDropDown: taskFilterDropdown,
        search,
    } = useSelector(tasksState)
    const { sprints, skipPageEpic, pageLimitEpic } = useSelector(sprintsState)
    const { userProfile } = useSelector(loginState)
    const {
        teamList,
        pageLimit: teamPageLimit,
        skipPage: teamSkipPage,
    } = useSelector(teamState)

    const [filteredAssignees, setFilteredAssignees] = useState([])
    const [selectedTeamMembers, setSelectedTeamMembers] = useState([])
    const [downloadState, setDownloadState] = useState(initialDownloadState)

    // ACTIVE PROJECT DETAIL
    async function activeProjectDetails() {
        const result = await dispatch(
            projectGet({
                id: userProfile?.activeProject?._id,
            })
        )
        const data = result?.payload?.data
        if (data) {
            dispatch(setGeneralSettingsData(data))
        }
    }

    // GET SPRINT
    async function getSprint() {
        await dispatch(sprintsList())
    }

    useEffect(() => {
        if (
            userProfile?.activeProject !== null &&
            userProfile?.activeProject?._id
        ) {
            getSprint()
            activeProjectDetails()
            dispatch(
                epicsList({
                    skip: skipPageEpic,
                    limit: pageLimitEpic,
                })
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (sprints?.length > 0) {
            dispatch(
                filterDropdown({
                    sprintId: JSON.parse(localStorage.getItem('currentSprint'))
                        ?.id,
                })
            )
            dispatch(
                taskList({
                    skip: skipPage,
                    limit: pageLimit,
                    filter: {
                        projectId: userProfile?.activeProject?._id,
                        sprintId: JSON.parse(
                            localStorage.getItem('currentSprint')
                        )?.id,
                    },
                })
            )
        }
        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sprints])

    // SEARCH USERS
    const searchFn = useCallback(
        debounce(async (value) => {
            dispatch(
                taskList({
                    skip: skipPage,
                    limit: pageLimit,
                    filter: {
                        projectId: userProfile?.activeProject?._id,
                        sprintId: taskFilterDropdown?.sprintId,
                    },
                    search: value,
                })
            )
        }, 1000),
        [dispatch, skipPage, pageLimit, userProfile, taskFilterDropdown]
    )

    // TEAM LIST GET
    useEffect(() => {
        dispatch(
            teamListGet({
                limit: teamPageLimit,
                skip: teamSkipPage,
                projectId: userProfile?.activeProject?._id,
            })
        )

        setDownloadState((prevState) => ({
            ...prevState,
            activeSprint: JSON.parse(localStorage.getItem('currentSprint')),
        }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // CHECK FOR LAZY LOADING IS_NEXT
    useEffect(() => {
        if (teamList?.length > 0) {
            let array = []
            teamList?.length > 0 &&
                teamList.forEach((data) => {
                    // if (userProfile?._id !== data?._id) {
                    array = [
                        ...array,
                        {
                            label: data?.name,
                            value: data?._id,
                            profilePicture: data?.profilePicture,
                        },
                    ]
                    // }
                })

            setFilteredAssignees(array)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [teamList])

    // VIEW TASK
    async function viewTask(taskID) {
        dispatch(
            switchFormModal({
                open: true,
            })
        )
        const result = await dispatch(taskById({ _id: taskID }))
        const data = result?.payload?.data
        if (data) {
            const { success } = data
            if (success) {
            } else {
                dispatch(
                    switchFormModal({
                        open: false,
                    })
                )
                message.error('Something went wrong, try again later.')
            }
        }
    }

    // VIEW TASK
    async function viewSubTask(taskID) {
        dispatch(switchFormModalSubTask({ open: true }))
        const result = await dispatch(taskById({ _id: taskID }))
        const data = result?.payload?.data
        if (data) {
            const { success } = data
            if (success) {
            } else {
                dispatch(switchFormModalSubTask({ open: false }))

                message.error('Something went wrong, try again later.')
            }
        }
    }

    useEffect(() => {
        if (
            params?.search.includes('subTaskId') &&
            params.search.includes('perentId')
        ) {
            viewTask(params?.search.split('perentId=')[1])
            viewSubTask(params?.search.split('subTaskId=')[1])
        } else if (params?.search.includes('taskId')) {
            viewTask(params?.search.split('taskId=')[1])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, params])

    // APPLY FILTER
    function applyAssigneeFilter(name, value) {
        dispatch(filterDropdown({ ...taskFilterDropdown, [name]: value }))

        dispatch(
            taskList({
                limit: pageLimit,
                skip: skipPage,
                filter: {
                    ...taskFilterDropdown,
                    [name]: value,
                    projectId: userProfile?.activeProject?._id,
                },
            })
        )

        const backlogId = sprints
            .filter((data) => data?.title === 'Backlog')
            .map((data) => data)
        localStorage.setItem(
            'currentSprint',
            JSON.stringify({
                id: backlogId[0]?._id,
                name: backlogId[0]?.title,
            })
        )
    }

    const Content = () => {
        return (
            <>
                <div style={{ margin: 10 }} />
                {userProfile?.activeProject &&
                userProfile?.activeProject?._id &&
                JSON.parse(localStorage.getItem('currentSprint'))?.id &&
                status === 'loaded' &&
                tasks?.length > 0 ? (
                    <TaskBoard props={{ tasks, filteredAssignees }} />
                ) : status === 'loading' ? (
                    <TaskLoading />
                ) : tasks?.length === 0 &&
                  status === 'loaded' &&
                  userProfile?.activeProject !== null ? (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            rowGap: 10,
                            marginTop: 50,
                        }}
                    >
                        <ProjectOutlined
                            style={{
                                fontSize: 30,
                            }}
                        />
                        <Text
                            style={{
                                fontSize: 18,
                            }}
                        >
                            Task is not created yet in this Sprint, Please
                            create a task
                        </Text>
                    </div>
                ) : (
                    tasks?.length === 0 &&
                    userProfile?.activeProject === null && (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                rowGap: 10,
                                marginTop: 50,
                            }}
                        >
                            <ProjectOutlined
                                style={{
                                    fontSize: 30,
                                }}
                            />
                            <Text
                                style={{
                                    fontSize: 18,
                                }}
                            >
                                Project is not Active yet in this Task, Please
                                Select the project to make active
                            </Text>
                        </div>
                    )
                )}
            </>
        )
    }

    const items = [
        {
            key: 'board',
            label: 'Board',
            children: <Content />,
        },
        // {
        //     key: 'roadMap',
        //     label: 'Roadmap',
        //     children: (
        //         <RoadMap
        //             props={{
        //                 tasks: tasks,
        //                 tabName: tabName,
        //             }}
        //         />
        //     ),
        // },
    ]

    // EXPORT CSV
    const handleExportCsv = async () => {
        try {
            setDownloadState((prevState) => ({
                ...prevState,
                isDownloadExcel: true,
            }))

            const sprintId =
                downloadState?.activeSprint?.id ||
                JSON.parse(localStorage.getItem('currentSprint'))?.id

            const response = await downloadSprintsData(sprintId)

            // Create a link element
            if (response) {
                setDownloadState((prevState) => ({
                    ...prevState,
                    isDownloadExcel: false,
                }))

                const url = window.URL.createObjectURL(
                    new Blob([response.data])
                )
                const link = document.createElement('a')
                link.href = url
                link.setAttribute(
                    'download',
                    `${userProfile?.activeProject?.name || ''}_${
                        downloadState?.activeSprint?.name || ''
                    }_${dayjs(new Date()).format('DMMMMYYYY')}.csv`
                ) // Name of the file to be downloaded
                document.body.appendChild(link)
                link.click()

                // Clean up and remove the link
                link.parentNode.removeChild(link)
            }
        } catch (error) {
            setDownloadState((prevState) => ({
                ...prevState,
                isDownloadExcel: false,
            }))
            message.error('Something went wrong, try again later.')
        }
    }

    return (
        <>
            <Drawer
                placement="right"
                size={'large'}
                style={{
                    height: '100vh',
                }}
                styles={{
                    body: {
                        padding: '0px',
                    },
                }}
                rootClassName="taskHeader"
                onClose={() => {
                    dispatch(switchFormModal({ open: false }))
                    dispatch(clearTaskFileUpload())
                    dispatch(taskFileError(''))
                    if (params?.search.includes('taskId')) {
                        navigate(PRIVATE_ROUTES.tasks)
                    }
                }}
                open={formModal}
            >
                <DrawerTaskForm
                    assigneeList={filteredAssignees}
                    onCancel={() => {
                        dispatch(switchFormModal({ open: false }))
                    }}
                />
            </Drawer>
            <div
                className={'container'}
                style={{
                    zIndex: 10,
                    minHeight: '100vh',
                    padding: '35px',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        gap: '20px',
                        marginBottom: '37px',
                    }}
                >
                    <PanelHeader
                        props={{
                            panelName: 'create',
                            panelButtonIcon: <SprintCreateIcon />,
                            searchBar: false,
                            filterBar: false,
                            icon: false,
                            showProject: true,
                            // permissionAdd: addPermissionCheck(
                            //     'tasks',
                            //     userProfile
                            // ),
                            teamMembersBar: true,
                            teamMembers: filteredAssignees,
                            selectedTeamMembers: selectedTeamMembers,
                            tooltipAvatar: true,
                            activeProject: userProfile,
                            applyAssigneeFilter: (name, value) => {
                                if (selectedTeamMembers.includes(value)) {
                                    // If the value is already in the array, remove it
                                    const newArray = selectedTeamMembers.filter(
                                        (item) => item !== value
                                    )
                                    setSelectedTeamMembers(newArray)
                                    applyAssigneeFilter(name, newArray)
                                } else {
                                    // If the value is not in the array, add it
                                    const newArray = [
                                        ...selectedTeamMembers,
                                        value,
                                    ]
                                    setSelectedTeamMembers(newArray)
                                    applyAssigneeFilter(name, newArray)
                                }
                            },
                            onClick: () => {
                                dispatch(selectTaskUpdate(undefined))
                                dispatch(
                                    switchFormModal({
                                        open: true,
                                    })
                                )
                            },
                        }}
                    />
                    <div>
                        <Button
                            loading={downloadState?.isDownloadExcel}
                            disabled={!downloadState?.activeSprint}
                            onClick={() => handleExportCsv()}
                            icon={<DownloadOutlined />}
                        >
                            Export Data
                        </Button>
                    </div>
                </div>

                <Row
                    align={'middle'}
                    gutter={[15, 15]}
                    style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'end',
                        alignItems: 'center',
                        marginBottom: '10px',
                    }}
                >
                    <Col span={6}>
                        <TasksFilter
                            props={{
                                filteredAssignees,
                                clearSearch: () => {
                                    dispatch(searchTasks(''))
                                },
                            }}
                        />
                    </Col>
                </Row>
                <RoadMap />
            </div>
        </>
    )
}

export default RoadMapMain

const RoadMap = () =>
    // { props }

    {
        // const { tabName } = props

        const dispatch = useDispatch()

        const { Text } = Typography

        const { useToken } = theme
        const { token } = useToken()

        const { userProfile } = useSelector(loginState)
        const {
            roadmapTasks,
            slots,
            slotWidth,
            selectedFormat,
            skipPage,
            pageLimit,
            status,
        } = useSelector(roadmapState)
        const {
            teamList,
            pageLimit: teamPageLimit,
            skipPage: teamSkipPage,
        } = useSelector(teamState)
        const { filterDropDown: taskFilterDropdown, formModal } =
            useSelector(tasksState)

        const [pointerEvent, setPointerEvent] = useState(true)
        const [filteredAssignees, setFilteredAssignees] = useState([])

        const isSameOrBefore = require('dayjs/plugin/isSameOrBefore')
        dayjs.extend(isSameOrBefore)

        // CREATE SLOTS CALENDAR DATE WISE
        const createSlots = useCallback(
            (startDate, endDate) => {
                let slots = []
                // let now = dayjs(startDate).startOf(selectedFormat).clone()
                let now = dayjs(startDate).clone()

                while (now.isSameOrBefore(dayjs(endDate).clone())) {
                    slots.push(now.format('MMM, DD'))
                    now = now.add(1, 'days')
                }
                dispatch(setSlots(slots))
            },
            [selectedFormat, dispatch]
        )

        // CHECK FOR LAZY LOADING IS_NEXT
        useEffect(() => {
            if (teamList?.length > 0) {
                let array = []
                teamList?.length > 0 &&
                    teamList.forEach((data) => {
                        // if (userProfile?._id !== data?._id) {
                        array = [
                            ...array,
                            {
                                label: data?.name,
                                value: data?._id,
                                profilePicture: data?.profilePicture,
                            },
                        ]
                        // }
                    })

                setFilteredAssignees(array)
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [teamList])

        // GET ROADMAP TASKS
        const getRoadMapTasks = () => {
            // if (tabName === 'roadMap')
            dispatch(
                roadmapList({
                    skip: skipPage,
                    limit: pageLimit,
                    projectId: userProfile?.activeProject?._id,
                    filter: {
                        // sprintId: JSON.parse(localStorage.getItem('currentSprint'))
                        //     ?.id,
                        sprintId: taskFilterDropdown?.sprintId,
                    },
                })
            )
        }

        // CALCULATE TIMELOG
        const calculateTime = (estimation, totalLog) => {
            let newEstimation = estimation * 60

            if (totalLog === 0 && newEstimation === 0) {
                return `0%`
            } else if (totalLog < newEstimation) {
                const percentage = parseInt((totalLog / newEstimation) * 100)
                return `${percentage}%`
            } else if (totalLog > newEstimation) {
                const totalSeconds = totalLog - newEstimation // Example total number of seconds
                const hours = Math.floor(totalSeconds / 3600)
                const remainingSecondsAfterHours = totalSeconds % 3600
                const minutes = Math.floor(remainingSecondsAfterHours / 60)
                const remainingSeconds = Math.floor(
                    remainingSecondsAfterHours % 60
                )

                const newHour = hours > 0 ? `${hours}h` : ''
                const newMinutes = minutes > 0 ? `${minutes}m` : ''
                const newSeconds =
                    remainingSeconds > 0 ? `${remainingSeconds}s` : ''

                return `${newHour}${newMinutes}${newSeconds} overLogged`
            } else if (totalLog === newEstimation) {
                return `100%`
            }
        }

        useEffect(() => {
            // createSlots(dayjs())
            getRoadMapTasks()
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [taskFilterDropdown])

        useEffect(() => {
            if (roadmapTasks?.[0]?.projectId?.start) {
                const startDate = dayjs(roadmapTasks[0].projectId.start) // Convert the timestamp to dayjs object
                const endDate = dayjs(roadmapTasks[0].projectId.end)
                createSlots(startDate, endDate)
            }
        }, [roadmapTasks, createSlots])

        // SET CALENDAR SLOTS WIDTH
        useEffect(() => {
            const interval = setInterval(() => {
                const bounds = document
                    .getElementsByClassName('slots')[0]
                    ?.getBoundingClientRect()

                if (bounds) {
                    dispatch(setSlotWidth(bounds.width + 8))
                    clearInterval(interval)
                }
            }, 100)
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [slots])

        // TASK UPDATE ON DRAG / RESIZE
        const taskUpdateDrag = debounce(async (id, start, end) => {
            const result = await dispatch(
                taskUpdate({
                    updateTask: {
                        start: start,
                        end: end,
                    },
                    _id: id,
                })
            )

            const data = result?.payload?.data
            if (data) {
                const { success, message: checkMessage } = data
                if (success) {
                    setPointerEvent(true)
                    // getRoadMapTasks()
                    message.success(checkMessage)
                } else {
                    setPointerEvent(true)
                }
            }
        }, 1500)

        window.onresize = function () {
            createSlots(dayjs())
        }

        // SET ROADMAP TASKS
        const setRoadMapTasks = (data, isParent, isoString, endDateString) => {
            const roadmapNewTasks = structuredClone(roadmapTasks)
            if (isParent) {
                const changeTask = roadmapNewTasks.map((task) => {
                    if (task?._id === data?._id) {
                        return {
                            ...task,
                            start: isoString,
                            end: endDateString,
                        }
                    } else {
                        return task
                    }
                })
                setPointerEvent(false)
                dispatch(setRoadMapTask(changeTask))
            } else {
                const changeChildTask = roadmapNewTasks.map((task) => {
                    let newItem = { ...task }
                    // Check if the item has a subTasks array
                    if (newItem.subTasks && newItem.subTasks.length > 0) {
                        // Find the subtask with the specified _id
                        let subTaskIndex = newItem.subTasks.findIndex(
                            (subTask) => subTask._id === data?._id
                        )

                        // If the subtask is found, update its end date
                        if (subTaskIndex !== -1) {
                            // Clone the subtask to avoid modifying it directly
                            let updatedSubTask = {
                                ...newItem.subTasks[subTaskIndex],
                            }

                            // Update the end date
                            updatedSubTask.start = isoString
                            updatedSubTask.end = endDateString

                            // Replace the old subtask with the updated one
                            newItem.subTasks[subTaskIndex] = updatedSubTask
                        }
                    }

                    return newItem
                })
                setPointerEvent(false)
                dispatch(setRoadMapTask(changeChildTask))
            }
        }

        // ROADMAP DRAGGABLE COMPONENT
        // const RoadmapDraggable = ({ data, keyIndex, isParent }) => {
        //     return (
        //         <>
        //             <Draggable
        //                 axis={'x'}
        //                 key={keyIndex}
        //                 disabled={pointerEvent === false}
        //                 grid={[slotWidth]}
        //                 handle={'.timeline'}
        //                 onStop={(e, dragData) => {
        //                     // END DATE CALCULATION
        //                     const width = e.target.getBoundingClientRect().width
        //                     const slotDate = width / slotWidth
        //                     const endDate = data?.start?.split('T')[0]
        //                     let endNewDate = new Date(endDate)

        //                     endNewDate.setDate(
        //                         endNewDate.getDate() + parseInt(slotDate - 1)
        //                     )

        //                     let endDateString = endNewDate.toISOString()

        //                     // START DATE CALCULATION
        //                     const dateStart = dragData?.x / slotWidth
        //                     const currentYear = new Date().getFullYear()

        //                     const dateString = `${slots[dateStart]} ` + currentYear
        //                     const dateObject = new Date(dateString).setHours(
        //                         23,
        //                         59,
        //                         59,
        //                         999
        //                     )

        //                     const timestamp = dateObject
        //                     const date = new Date(timestamp)
        //                     const isoString = date.toISOString()

        //                     // SET ROADMAP TASK START END
        //                     setRoadMapTasks(
        //                         data,
        //                         isParent,
        //                         isoString,
        //                         endDateString
        //                     )

        //                     taskUpdateDrag(data?._id, isoString, endDateString)
        //                 }}
        //                 defaultPosition={{
        //                     x:
        //                         parseInt(
        //                             dayjs(data?.start.split('T')[0]).diff(
        //                                 dayjs().startOf('year'),
        //                                 'day'
        //                             )
        //                         ) * slotWidth,
        //                     y: 0,
        //                 }}
        //             >
        //                 <Resizable
        //                     bounds={'parent'}
        //                     grid={[slotWidth]}
        //                     minWidth={slotWidth}
        //                     className={styles.resizable}
        //                     onResizeStop={(e, dir, ref) => {
        //                         const element = ref

        //                         if (element) {
        //                             setPointerEvent(false)
        //                             // END DATE CALCULATION
        //                             const width =
        //                                 element.getBoundingClientRect().width
        //                             const slotDate = width / slotWidth
        //                             const date = data?.start.split('T')[0]
        //                             let initialDate = new Date(date)

        //                             initialDate.setDate(
        //                                 initialDate.getDate() +
        //                                     parseInt(slotDate - 1)
        //                             )

        //                             let resultDateString = initialDate.toISOString()

        //                             // SET ROADMAP TASK START END
        //                             setRoadMapTasks(
        //                                 data,
        //                                 isParent,
        //                                 data?.start,
        //                                 resultDateString
        //                             )

        //                             taskUpdateDrag(
        //                                 data?._id,
        //                                 data?.start,
        //                                 resultDateString
        //                             )
        //                         }
        //                     }}
        //                     enable={{
        //                         right: true,
        //                         left: true,
        //                     }}
        //                     defaultSize={{
        //                         width:
        //                             (parseInt(
        //                                 dayjs(data?.end).diff(
        //                                     dayjs(data?.start),
        //                                     'day'
        //                                 )
        //                             ) +
        //                                 1) *
        //                             slotWidth,
        //                     }}
        //                 >
        //                     <div className={styles.timeline + ' timeline'}>
        //                         <Text
        //                             style={{
        //                                 fontSize: '14px',
        //                                 left: '50px',
        //                                 top: '1px',
        //                                 position: 'absolute',
        //                                 color: token.colorPalette.baseColor.white,
        //                             }}
        //                         >
        //                             {calculateTime(
        //                                 data?.estimation,
        //                                 data?.totalLog
        //                             )}
        //                         </Text>
        //                         <div
        //                             style={{
        //                                 position: 'absolute',
        //                                 left: '-15px',
        //                                 top: '-4px',
        //                             }}
        //                         >
        //                             {data?.assigneeId?.length > 0 ? (
        //                                 data?.assigneeId[0]?.profilePicture
        //                                     ?.length > 0 ? (
        //                                     <Image
        //                                         src={
        //                                             data?.assigneeId[0]
        //                                                 ?.profilePicture
        //                                         }
        //                                         height={'32px'}
        //                                         preview={false}
        //                                         width={'32px'}
        //                                         style={{
        //                                             borderRadius: '50%',
        //                                         }}
        //                                     />
        //                                 ) : (
        //                                     <Avatar
        //                                         size={'default'}
        //                                         style={{
        //                                             backgroundColor: '#f56a00',
        //                                             cursor: 'pointer',
        //                                             fontSize: '11px',
        //                                         }}
        //                                     >
        //                                         {data?.assigneeId[0]?.name[0].toUpperCase()}
        //                                         {data?.assigneeId[0]?.name.split(
        //                                             ' '
        //                                         )[1] !== undefined &&
        //                                             data?.assigneeId[0]?.name
        //                                                 .split(' ')[1][0]
        //                                                 .toUpperCase()}
        //                                     </Avatar>
        //                                 )
        //                             ) : (
        //                                 <Avatar
        //                                     size={'default'}
        //                                     icon={<UserOutlined />}
        //                                 />
        //                             )}
        //                         </div>

        //                         <div
        //                             className={styles.dragger + ' dragger'}
        //                             style={{
        //                                 top: 5,
        //                                 right: 5,
        //                                 width: '5px',
        //                                 height: '60%',
        //                                 borderRadius: 5,
        //                                 position: 'absolute',
        //                                 background: 'rgba(0, 0, 0, 0.2)',
        //                             }}
        //                         />
        //                     </div>
        //                 </Resizable>
        //             </Draggable>
        //         </>
        //     )
        // }

        const RoadmapDraggable = ({ data, keyIndex, isParent }) => {
            return (
                <div
                    style={{
                        marginLeft: '15px',
                        marginTop: '20px',
                        marginBottom: '5px',
                    }}
                >
                    <Draggable
                        axis={'x'}
                        key={keyIndex}
                        disabled={pointerEvent === false}
                        grid={[slotWidth]}
                        handle={'.timeline'}
                        onStop={(e, dragData) => {
                            const width = e.target.getBoundingClientRect().width
                            const slotDate = width / slotWidth

                            // End Date Calculation
                            const endDate = data?.start?.split('T')[0]
                            let endNewDate = new Date(endDate)
                            endNewDate.setDate(
                                endNewDate.getDate() + parseInt(slotDate - 1)
                            )
                            const endDateString = endNewDate.toISOString()

                            // Start Date Calculation
                            const dateStart = dragData?.x / slotWidth
                            const startDate = dayjs()
                                .startOf('year')
                                .add(dateStart, 'day')
                            const isoString = startDate.toISOString()

                            // Set Roadmap Task Start and End
                            setRoadMapTasks(
                                data,
                                isParent,
                                isoString,
                                endDateString
                            )
                            taskUpdateDrag(data?._id, isoString, endDateString)
                        }}
                        defaultPosition={{
                            // Calculate the position relative to the start of the timeline (start of the year)
                            x:
                                dayjs(data?.start).diff(
                                    dayjs(data?.projectId?.start),
                                    'day'
                                ) * slotWidth,
                            y: 0,
                        }}
                    >
                        <Resizable
                            bounds={'parent'}
                            grid={[slotWidth]}
                            minWidth={slotWidth}
                            className={styles.resizable}
                            onResizeStop={(e, dir, ref) => {
                                const element = ref

                                if (element) {
                                    setPointerEvent(false)

                                    // Calculate the new width
                                    const width =
                                        element.getBoundingClientRect().width
                                    const slotDate = width / slotWidth

                                    // Parse the current start date
                                    const startDate = dayjs(data?.start)

                                    // Add the slotDate to the start date to get the new end date
                                    const newEndDate = startDate
                                        .add(slotDate - 1, 'day')
                                        .toISOString()

                                    // Update the task with new start and end dates
                                    setRoadMapTasks(
                                        data,
                                        isParent,
                                        data?.start,
                                        newEndDate
                                    )
                                    taskUpdateDrag(
                                        data?._id,
                                        data?.start,
                                        newEndDate
                                    )
                                }
                            }}
                            enable={{
                                right: true,
                                left: true,
                            }}
                            defaultSize={{
                                width:
                                    (dayjs(data?.end).diff(
                                        dayjs(data?.start),
                                        'day'
                                    ) +
                                        1) *
                                    slotWidth,
                            }}
                        >
                            <div
                                className={styles.timeline + ' timeline'}
                                style={{ margin: 0 }}
                            >
                                <Text
                                    style={{
                                        fontSize: '14px',
                                        left: '50px',
                                        top: '1px',
                                        position: 'absolute',
                                        color: token.colorPalette.baseColor
                                            .white,
                                    }}
                                >
                                    {calculateTime(
                                        data?.estimation,
                                        data?.totalLog
                                    )}
                                </Text>
                                <div
                                    style={{
                                        position: 'absolute',
                                        left: '-15px',
                                        top: '-4px',
                                    }}
                                >
                                    {data?.assigneeId?.length > 0 ? (
                                        data?.assigneeId[0]?.profilePicture
                                            ?.length > 0 ? (
                                            <Image
                                                src={
                                                    data?.assigneeId[0]
                                                        ?.profilePicture
                                                }
                                                height={'32px'}
                                                preview={false}
                                                width={'32px'}
                                                style={{ borderRadius: '50%' }}
                                            />
                                        ) : (
                                            <Avatar
                                                size={'default'}
                                                style={{
                                                    backgroundColor: '#f56a00',
                                                    cursor: 'pointer',
                                                    fontSize: '11px',
                                                }}
                                            >
                                                {data?.assigneeId[0]?.name[0].toUpperCase()}
                                                {data?.assigneeId[0]?.name.split(
                                                    ' '
                                                )[1] &&
                                                    data?.assigneeId[0]?.name
                                                        .split(' ')[1][0]
                                                        .toUpperCase()}
                                            </Avatar>
                                        )
                                    ) : (
                                        <Avatar
                                            size={'default'}
                                            icon={<UserOutlined />}
                                        />
                                    )}
                                </div>

                                <div
                                    className={styles.dragger + ' dragger'}
                                    style={{
                                        top: 5,
                                        right: 5,
                                        width: '5px',
                                        height: '60%',
                                        borderRadius: 5,
                                        position: 'absolute',
                                        background: 'rgba(0, 0, 0, 0.2)',
                                    }}
                                />
                            </div>
                        </Resizable>
                    </Draggable>
                </div>
            )
        }

        const count = 5 // Number of skeletons you want to display

        // VIEW TASK
        async function viewTask(taskID) {
            dispatch(
                switchFormModal({
                    open: true,
                })
            )
            const result = await dispatch(taskById({ _id: taskID }))
            const data = result?.payload?.data
            if (data) {
                const { success } = data
                if (success) {
                } else {
                    dispatch(
                        switchFormModal({
                            open: false,
                        })
                    )
                    message.error('Something went wrong, try again later.')
                }
            }
        }

        // VIEW SUB TASK
        async function viewSubTask(taskID) {
            dispatch(switchFormModalSubTask({ open: true }))
            const result = await dispatch(taskById({ _id: taskID }))
            const data = result?.payload?.data
            if (data) {
                const { success } = data
                if (success) {
                } else {
                    dispatch(switchFormModalSubTask({ open: false }))

                    message.error('Something went wrong, try again later.')
                }
            }
        }

        return (
            <>
                <Row
                    gutter={6}
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Col span={10}>
                        <div
                            style={{
                                marginTop: 45,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'start',
                                justifyContent: 'space-between',
                                gap: '2px',
                            }}
                        >
                            {status === 'loading'
                                ? Array.from({ length: count }, (_, index) => (
                                      <Row
                                          key={index}
                                          style={{
                                              minWidth: '100%',
                                              width: '100%',
                                          }}
                                      >
                                          <Skeleton.Input
                                              key={index}
                                              active={true}
                                              size={'default'}
                                              style={{
                                                  marginBottom: '6px',
                                                  width: '100%',
                                              }}
                                              rootClassName="roadmapSkeleton"
                                          />
                                      </Row>
                                  ))
                                : status === 'loaded' &&
                                  roadmapTasks?.length > 0 &&
                                  roadmapTasks.map((data, index) => (
                                      <>
                                          <Row
                                              key={index}
                                              style={{
                                                  minWidth: '100%',
                                                  width: '100%',
                                              }}
                                          >
                                              <div className={styles.container}>
                                                  <div
                                                      style={{
                                                          display: 'flex',
                                                          alignItems: 'center',
                                                          justifyContent:
                                                              'space-between',
                                                          gap: '10px',
                                                      }}
                                                  >
                                                      <div
                                                          style={{
                                                              display: 'flex',
                                                              alignItems:
                                                                  'center',
                                                              gap: '10px',
                                                          }}
                                                      >
                                                          <Tooltip
                                                              title={data?.type}
                                                              arrow={false}
                                                          >
                                                              {data?.type ===
                                                              'Bug' ? (
                                                                  <div
                                                                      className={
                                                                          styles.taskTypeSection
                                                                      }
                                                                      style={{
                                                                          backgroundColor:
                                                                              'red',
                                                                          padding:
                                                                              '3px',
                                                                          borderRadius:
                                                                              '5px',
                                                                          display:
                                                                              'inline-flex',
                                                                          alignItems:
                                                                              'center',
                                                                          height: '20px',
                                                                      }}
                                                                  >
                                                                      <BugOutlined
                                                                          style={{
                                                                              fontSize:
                                                                                  '12px',
                                                                              color: 'white',
                                                                          }}
                                                                      />
                                                                  </div>
                                                              ) : data?.type ===
                                                                'Task' ? (
                                                                  <div
                                                                      className={
                                                                          styles.taskTypeSection
                                                                      }
                                                                      style={{
                                                                          backgroundColor:
                                                                              token
                                                                                  .colorPalette
                                                                                  .baseColor
                                                                                  .tertiary,
                                                                          padding:
                                                                              '3px',
                                                                          borderRadius:
                                                                              '5px',
                                                                          display:
                                                                              'inline-flex',
                                                                          alignItems:
                                                                              'center',
                                                                          height: '20px',
                                                                      }}
                                                                  >
                                                                      <CheckOutlined
                                                                          style={{
                                                                              fontSize:
                                                                                  '10px',
                                                                              color: 'white',
                                                                          }}
                                                                      />
                                                                  </div>
                                                              ) : (
                                                                  //   </div>
                                                                  <div
                                                                      className={
                                                                          styles.taskTypeSection
                                                                      }
                                                                      style={{
                                                                          backgroundColor:
                                                                              '#7a16ff',
                                                                      }}
                                                                  >
                                                                      <ProjectOutlined
                                                                          style={{
                                                                              fontSize:
                                                                                  '10px',
                                                                              color: 'white',
                                                                          }}
                                                                      />
                                                                  </div>
                                                              )}
                                                          </Tooltip>
                                                          {data?.taskKey && (
                                                              <span>
                                                                  {data.taskKey}
                                                              </span>
                                                          )}

                                                          <Tooltip
                                                              title={
                                                                  data?.title
                                                              }
                                                          >
                                                              <div
                                                                  style={{
                                                                      cursor: 'pointer',
                                                                      whiteSpace:
                                                                          'nowrap',
                                                                      overflow:
                                                                          'hidden',
                                                                      textOverflow:
                                                                          'ellipsis',
                                                                      maxWidth:
                                                                          '200px', // Adjust the width as needed
                                                                  }}
                                                                  onClick={() =>
                                                                      viewTask(
                                                                          data._id
                                                                      )
                                                                  }
                                                              >
                                                                  {data?.title
                                                                      ?.length >
                                                                  40
                                                                      ? `${data.title.substring(
                                                                            0,
                                                                            40
                                                                        )}...`
                                                                      : data.title}
                                                              </div>
                                                          </Tooltip>
                                                      </div>
                                                      <div
                                                          style={{
                                                              display: 'flex',
                                                              alignItems:
                                                                  'center',
                                                              gap: '10px',
                                                          }}
                                                      >
                                                          <div
                                                              className={
                                                                  'sub-title'
                                                              }
                                                              style={{
                                                                  padding:
                                                                      '2px 6px',
                                                                  borderRadius: 1000,
                                                                  fontSize:
                                                                      '12px',
                                                                  backgroundColor:
                                                                      data?.status ===
                                                                      'To Do'
                                                                          ? '#d5d5d5'
                                                                          : data?.status ===
                                                                            'Done'
                                                                          ? '#C8E5CE'
                                                                          : colorTaskRandom[
                                                                                (Math.random() *
                                                                                    colorTaskRandom.length) |
                                                                                    0
                                                                            ],
                                                              }}
                                                          >
                                                              {data?.status}
                                                          </div>
                                                          <div>
                                                              {data?.assigneeId ? (
                                                                  <div>
                                                                      <Tooltip
                                                                          title={
                                                                              data
                                                                                  ?.assigneeId
                                                                                  ?.length >
                                                                              0
                                                                                  ? data
                                                                                        ?.assigneeId[0]
                                                                                        ?.name
                                                                                  : Object.keys(
                                                                                        data?.assigneeId
                                                                                    )
                                                                                        ?.length >
                                                                                    0
                                                                                  ? data
                                                                                        ?.assigneeId
                                                                                        ?.name
                                                                                  : 'Unassigned'
                                                                          }
                                                                      >
                                                                          {data
                                                                              ?.assigneeId
                                                                              ?.length >
                                                                              0 ||
                                                                          Object.keys(
                                                                              data?.assigneeId
                                                                          )
                                                                              ?.length >
                                                                              0 ? (
                                                                              data
                                                                                  ?.assigneeId[0]
                                                                                  ?.profilePicture ? (
                                                                                  <Image
                                                                                      src={
                                                                                          data
                                                                                              ?.assigneeId[0]
                                                                                              ?.profilePicture
                                                                                      }
                                                                                      height={
                                                                                          '24px'
                                                                                      }
                                                                                      width={
                                                                                          '24px'
                                                                                      }
                                                                                      preview={
                                                                                          false
                                                                                      }
                                                                                      style={{
                                                                                          borderRadius:
                                                                                              '50%',
                                                                                      }}
                                                                                  />
                                                                              ) : (
                                                                                  <Avatar
                                                                                      size={
                                                                                          'small'
                                                                                      }
                                                                                      style={{
                                                                                          cursor: 'pointer',
                                                                                          backgroundColor:
                                                                                              'rgb(0 10 129 / 25%)',
                                                                                          height: '24px',
                                                                                          width: '24px',
                                                                                          minWidth:
                                                                                              '24px',
                                                                                      }}
                                                                                      className="active-tooltip"
                                                                                  >
                                                                                      {data
                                                                                          ?.assigneeId
                                                                                          ?.length >
                                                                                          0 &&
                                                                                          data?.assigneeId[0]?.name[0].toUpperCase()}
                                                                                      {data
                                                                                          ?.assigneeId
                                                                                          ?.length >
                                                                                          0 &&
                                                                                          data?.assigneeId[0]?.name.split(
                                                                                              ' '
                                                                                          )[1] !==
                                                                                              undefined &&
                                                                                          data?.assigneeId[0]?.name.split(
                                                                                              ' '
                                                                                          )[1] !==
                                                                                              '' &&
                                                                                          data?.assigneeId[0]?.name
                                                                                              .split(
                                                                                                  ' '
                                                                                              )[1][0]
                                                                                              .toUpperCase()}
                                                                                  </Avatar>
                                                                              )
                                                                          ) : (
                                                                              <Avatar
                                                                                  size={
                                                                                      'small'
                                                                                  }
                                                                              >
                                                                                  <UserOutlined />
                                                                              </Avatar>
                                                                          )}
                                                                      </Tooltip>
                                                                      {/* </Popover> */}
                                                                  </div>
                                                              ) : (
                                                                  <Tooltip
                                                                      title={
                                                                          'Unassigned'
                                                                      }
                                                                  >
                                                                      <Avatar
                                                                          size={
                                                                              'default'
                                                                          }
                                                                          icon={
                                                                              <UserOutlined />
                                                                          }
                                                                      />
                                                                  </Tooltip>
                                                              )}
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </Row>
                                          {data?.subTasks?.length > 0 &&
                                              data?.subTasks.map((subTask) => (
                                                  <Row
                                                      key={index}
                                                      style={{
                                                          marginLeft: '20px',
                                                      }}
                                                  >
                                                      <div
                                                          className={
                                                              styles.container
                                                          }
                                                      >
                                                          <div
                                                              style={{
                                                                  display:
                                                                      'flex',
                                                                  alignItems:
                                                                      'center',
                                                                  justifyContent:
                                                                      'space-between',
                                                                  gap: '10px',
                                                              }}
                                                          >
                                                              <div
                                                                  style={{
                                                                      display:
                                                                          'flex',
                                                                      alignItems:
                                                                          'center',
                                                                      gap: '10px',
                                                                  }}
                                                              >
                                                                  <Tooltip
                                                                      title={
                                                                          subTask?.type
                                                                      }
                                                                      arrow={
                                                                          false
                                                                      }
                                                                  >
                                                                      {subTask?.type ===
                                                                      'Bug' ? (
                                                                          <div
                                                                              className={
                                                                                  styles.taskTypeSection
                                                                              }
                                                                              style={{
                                                                                  backgroundColor:
                                                                                      'red',
                                                                                  padding:
                                                                                      '3px',
                                                                                  borderRadius:
                                                                                      '5px',
                                                                                  display:
                                                                                      'inline-flex',
                                                                                  alignItems:
                                                                                      'center',
                                                                                  height: '20px',
                                                                              }}
                                                                          >
                                                                              <BugOutlined
                                                                                  style={{
                                                                                      fontSize:
                                                                                          '12px',
                                                                                      color: 'white',
                                                                                  }}
                                                                              />
                                                                          </div>
                                                                      ) : subTask?.type ===
                                                                        'Task' ? (
                                                                          <div
                                                                              className={
                                                                                  styles.taskTypeSection
                                                                              }
                                                                              style={{
                                                                                  backgroundColor:
                                                                                      token
                                                                                          .colorPalette
                                                                                          .baseColor
                                                                                          .tertiary,
                                                                                  padding:
                                                                                      '3px',
                                                                                  borderRadius:
                                                                                      '5px',
                                                                                  display:
                                                                                      'inline-flex',
                                                                                  alignItems:
                                                                                      'center',
                                                                                  height: '15px',
                                                                              }}
                                                                          >
                                                                              <CheckOutlined
                                                                                  style={{
                                                                                      fontSize:
                                                                                          '10px',
                                                                                      color: 'white',
                                                                                  }}
                                                                              />
                                                                          </div>
                                                                      ) : (
                                                                          <div
                                                                              className={
                                                                                  styles.taskTypeSection
                                                                              }
                                                                              style={{
                                                                                  backgroundColor:
                                                                                      '#7a16ff',
                                                                              }}
                                                                          >
                                                                              <ProjectOutlined
                                                                                  style={{
                                                                                      fontSize:
                                                                                          '10px',
                                                                                      color: 'white',
                                                                                  }}
                                                                              />
                                                                          </div>
                                                                      )}
                                                                  </Tooltip>
                                                                  <Tooltip
                                                                      title={
                                                                          subTask?.title
                                                                      }
                                                                  >
                                                                      <div
                                                                          style={{
                                                                              cursor: 'pointer',
                                                                              whiteSpace:
                                                                                  'nowrap',
                                                                              overflow:
                                                                                  'hidden',
                                                                              textOverflow:
                                                                                  'ellipsis',
                                                                              maxWidth:
                                                                                  '200px', // Adjust the width as needed
                                                                          }}
                                                                          onClick={() =>
                                                                              viewSubTask(
                                                                                  subTask._id
                                                                              )
                                                                          }
                                                                      >
                                                                          {subTask
                                                                              ?.title
                                                                              ?.length >
                                                                          40
                                                                              ? `${subTask.title.substring(
                                                                                    0,
                                                                                    40
                                                                                )}...`
                                                                              : subTask.title}
                                                                      </div>
                                                                  </Tooltip>
                                                              </div>
                                                              <div
                                                                  style={{
                                                                      display:
                                                                          'flex',
                                                                      alignItems:
                                                                          'center',
                                                                      gap: '10px',
                                                                  }}
                                                              >
                                                                  <div
                                                                      className={
                                                                          'sub-title'
                                                                      }
                                                                      style={{
                                                                          padding:
                                                                              '2px 6px',
                                                                          borderRadius: 1000,
                                                                          fontSize:
                                                                              '12px',
                                                                          backgroundColor:
                                                                              subTask?.status ===
                                                                              'To Do'
                                                                                  ? '#d5d5d5'
                                                                                  : subTask?.status ===
                                                                                    'Done'
                                                                                  ? '#C8E5CE'
                                                                                  : colorTaskRandom[
                                                                                        (Math.random() *
                                                                                            colorTaskRandom.length) |
                                                                                            0
                                                                                    ],
                                                                      }}
                                                                  >
                                                                      {
                                                                          subTask?.status
                                                                      }
                                                                  </div>
                                                                  <div>
                                                                      {subTask?.assigneeId ? (
                                                                          <div>
                                                                              <Tooltip
                                                                                  title={
                                                                                      subTask
                                                                                          ?.assigneeId
                                                                                          ?.length >
                                                                                      0
                                                                                          ? subTask
                                                                                                ?.assigneeId[0]
                                                                                                ?.name
                                                                                          : Object.keys(
                                                                                                subTask?.assigneeId
                                                                                            )
                                                                                                ?.length >
                                                                                            0
                                                                                          ? subTask
                                                                                                ?.assigneeId
                                                                                                ?.name
                                                                                          : 'Unassigned'
                                                                                  }
                                                                              >
                                                                                  {subTask
                                                                                      ?.assigneeId
                                                                                      ?.length >
                                                                                      0 ||
                                                                                  Object.keys(
                                                                                      subTask?.assigneeId
                                                                                  )
                                                                                      ?.length >
                                                                                      0 ? (
                                                                                      subTask
                                                                                          ?.assigneeId[0]
                                                                                          ?.profilePicture ? (
                                                                                          <Image
                                                                                              src={
                                                                                                  subTask
                                                                                                      ?.assigneeId[0]
                                                                                                      ?.profilePicture
                                                                                              }
                                                                                              height={
                                                                                                  '24px'
                                                                                              }
                                                                                              width={
                                                                                                  '24px'
                                                                                              }
                                                                                              preview={
                                                                                                  false
                                                                                              }
                                                                                              style={{
                                                                                                  borderRadius:
                                                                                                      '50%',
                                                                                              }}
                                                                                          />
                                                                                      ) : (
                                                                                          <Avatar
                                                                                              size={
                                                                                                  'small'
                                                                                              }
                                                                                              style={{
                                                                                                  cursor: 'pointer',
                                                                                                  backgroundColor:
                                                                                                      'rgb(0 10 129 / 25%)',
                                                                                                  height: '24px',
                                                                                                  width: '24px',
                                                                                                  minWidth:
                                                                                                      '24px',
                                                                                              }}
                                                                                              className="active-tooltip"
                                                                                          >
                                                                                              {subTask
                                                                                                  ?.assigneeId
                                                                                                  ?.length >
                                                                                                  0 &&
                                                                                                  subTask?.assigneeId[0]?.name[0].toUpperCase()}
                                                                                              {subTask
                                                                                                  ?.assigneeId
                                                                                                  ?.length >
                                                                                                  0 &&
                                                                                                  subTask?.assigneeId[0]?.name.split(
                                                                                                      ' '
                                                                                                  )[1] !==
                                                                                                      undefined &&
                                                                                                  subTask?.assigneeId[0]?.name.split(
                                                                                                      ' '
                                                                                                  )[1] !==
                                                                                                      '' &&
                                                                                                  subTask?.assigneeId[0]?.name
                                                                                                      .split(
                                                                                                          ' '
                                                                                                      )[1][0]
                                                                                                      .toUpperCase()}
                                                                                          </Avatar>
                                                                                      )
                                                                                  ) : (
                                                                                      <Avatar
                                                                                          size={
                                                                                              'small'
                                                                                          }
                                                                                      >
                                                                                          <UserOutlined />
                                                                                      </Avatar>
                                                                                  )}
                                                                              </Tooltip>
                                                                              {/* </Popover> */}
                                                                          </div>
                                                                      ) : (
                                                                          <Tooltip
                                                                              title={
                                                                                  'Unassigned'
                                                                              }
                                                                          >
                                                                              <Avatar
                                                                                  size={
                                                                                      'default'
                                                                                  }
                                                                                  icon={
                                                                                      <UserOutlined />
                                                                                  }
                                                                              />
                                                                          </Tooltip>
                                                                      )}
                                                                  </div>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </Row>
                                              ))}
                                      </>
                                  ))}
                        </div>
                    </Col>
                    <Col span={14}>
                        <div
                            id={'timeline-container'}
                            style={{
                                // padding: 2,
                                // width: '100%',
                                overflowX: 'auto', // Enables horizontal scrolling
                                overflowY: 'hidden', // Disables vertical scrolling if you don't want it
                                position: 'relative',
                                whiteSpace: 'nowrap', // Prevents wrapping of content to allow horizontal scrolling
                                // backgroundSize: '1px 40px',
                            }}
                        >
                            {roadmapTasks?.length > 0 && (
                                <>
                                    <Row
                                        gutter={8}
                                        wrap={false}
                                        // style={{ marginLeft: '15px' }}
                                    >
                                        {slots.map((slot, index) => (
                                            <Col key={index} span={3}>
                                                <div
                                                    className={
                                                        styles.slots + ' slots'
                                                    }
                                                >
                                                    {slot}
                                                </div>
                                            </Col>
                                        ))}
                                    </Row>
                                    {/* <div style={{ marginTop: 10 }} /> */}
                                </>
                            )}

                            {status === 'loading'
                                ? Array.from({ length: count }, (_, index) => (
                                      <div
                                          className={styles.roadMapTaskSkeleton}
                                      >
                                          <div
                                              style={{
                                                  position: 'relative',
                                              }}
                                          >
                                              <Skeleton.Input
                                                  key={index}
                                                  active={true}
                                                  size={'default'}
                                                  style={{
                                                      width: '10%',
                                                  }}
                                                  rootClassName="roadmapSkeletonCalendar"
                                              />
                                              <div
                                                  style={{
                                                      position: 'absolute',
                                                      top: -4,
                                                      left: -20,
                                                  }}
                                              >
                                                  <Skeleton.Avatar
                                                      active={true}
                                                      size={32}
                                                      shape={'circle'}
                                                  />
                                              </div>
                                          </div>
                                      </div>
                                  ))
                                : slotWidth &&
                                  roadmapTasks?.length > 0 &&
                                  roadmapTasks.map((data, index) => {
                                      return (
                                          <>
                                              {data?.start === null ? (
                                                  <div
                                                      style={{
                                                          marginBottom: '17px',
                                                          width: '100%',
                                                      }}
                                                  >
                                                      <Text
                                                          style={{
                                                              fontStyle:
                                                                  'italic',
                                                          }}
                                                      >
                                                          Please Select Start
                                                          and End Date
                                                      </Text>
                                                  </div>
                                              ) : (
                                                  <RoadmapDraggable
                                                      data={data}
                                                      keyIndex={index}
                                                      isParent={true}
                                                  />
                                              )}
                                              {data?.subTasks?.length > 0 &&
                                                  data?.subTasks.map(
                                                      (subTask, keyIndex) => (
                                                          <>
                                                              <RoadmapDraggable
                                                                  data={subTask}
                                                                  keyIndex={
                                                                      keyIndex
                                                                  }
                                                                  isParent={
                                                                      false
                                                                  }
                                                              />
                                                          </>
                                                      )
                                                  )}
                                          </>
                                      )
                                  })}
                        </div>
                    </Col>
                    {status !== 'loading' && roadmapTasks?.length === 0 && (
                        <div>
                            <NoTaskFound />
                        </div>
                    )}
                </Row>

                <Drawer
                    placement="right"
                    size={'large'}
                    style={{
                        height: '100vh',
                    }}
                    styles={{
                        body: {
                            padding: '0px',
                        },
                    }}
                    rootClassName="taskHeader"
                    onClose={() => {
                        dispatch(switchFormModal({ open: false }))
                        dispatch(clearTaskFileUpload())
                        dispatch(taskFileError(''))
                    }}
                    open={formModal}
                >
                    <DrawerTaskForm
                        assigneeList={filteredAssignees}
                        onCancel={() => {
                            dispatch(switchFormModal({ open: false }))
                        }}
                        listTaskCall={() => getRoadMapTasks()}
                    />
                </Drawer>
            </>
        )
    }

// export default RoadMap
