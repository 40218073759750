import React from 'react'

function ExpandReportsIcon(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 14 8"
            transform={!props.isActive ? 'rotate(180)' : 'rotate(0)'}
        >
            <path
                fill="#747474"
                d="M7 2.828l-4.95 4.95L.636 6.364 7 0l6.364 6.364-1.415 1.414L7 2.828z"
            ></path>
        </svg>
    )
}

export default ExpandReportsIcon
