import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import InfiniteScroll from 'react-infinite-scroll-component'

// antd
import {
    Avatar,
    Badge,
    Col,
    Dropdown,
    Image,
    message,
    Pagination,
    Row,
    Spin,
    Table,
    theme,
    Tooltip,
    Typography,
} from 'antd'
import { EllipsisOutlined } from '@ant-design/icons'

// slices
import {
    changePage,
    clearFilter,
    filterChange,
    searchTeam,
    teamListGet,
    teamMemberAddRemove,
    teamState,
    totalTeam,
} from './team.slice'
import { loginState } from '../login/login.slice'

// components
import PanelHeader from '../../components/panelHeader/panelHeader'
import TeamModalContent from './teamModalContent'
import DeletePopUp from '../../components/deletePopUp/deletePopUp'
import GridViewButton from '../../components/gridView'
import TeamGrid from './teamGrid'

// assets
import {
    ActiveIcon,
    DeleteIcon,
    InActiveIcon,
    NonVerifyTalentIcon,
    NoTeamIcon,
    VerifyTalentIcon,
} from '../../assets/icons'

// helpers
import { unique } from '../../helpers/uniqueArray'
import {
    addPermissionCheck,
    deletePermissionCheck,
} from '../../helpers/permissionCheck'

function TeamMember() {
    const { Text } = Typography

    const { useToken } = theme
    const { token } = useToken()

    const dispatch = useDispatch()

    const { userProfile } = useSelector(loginState)
    const {
        pageLimit,
        skipPage,
        isNext,
        search,
        currentPage,
        teamList,
        status,
        dataCount,
        filter,
    } = useSelector(teamState)

    const [teamView, setTeamView] = useState('list')
    const [filteredTeamData, setFilteredTeamData] = useState([])
    const [modalVisibility, setModalVisibility] = useState(false)
    const [deleteModal, setDeleteModal] = useState({
        modalOpen: false,
        id: '',
        buttonLoading: false,
    })

    // CHECK FOR LAZY LOADING IS_NEXT
    useEffect(() => {
        if (teamList?.length > 0) {
            let array = []
            teamList?.length > 0 &&
                teamList.forEach((data) => {
                    array = [...array, data]
                })
            let newArray = [...filteredTeamData, ...array]
            let finalArray = unique(newArray, '_id')
            setFilteredTeamData(finalArray)
            if (!isNext) {
                return
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [teamList])

    const initialTeamCall = (search) => {
        dispatch(searchTeam(''))
        setFilteredTeamData([])
        dispatch(
            totalTeam({
                search: search,
                projectId: userProfile?.activeProject?._id,
            })
        )
        dispatch(
            teamListGet({
                limit: pageLimit,
                skip: 0,
                search: search,
                projectId: userProfile?.activeProject?._id,
            })
        )
        dispatch(
            changePage({
                currentPage: 1,
                skip: 0,
                pageLimit,
            })
        )
    }

    // GET TOTAL PROJECTS COUNT AND PROJECTS LIST
    useEffect(() => {
        if (userProfile?.activeProject !== null) {
            initialTeamCall('')
            dispatch(searchTeam(''))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // REFRESH PAGE
    function refreshPage() {
        setFilteredTeamData([])
        dispatch(
            changePage({
                currentPage: 1,
                skip: 0,
                pageLimit,
            })
        )
    }

    // REMOVE TEAM MEMBER
    async function removeMember() {
        setDeleteModal({
            ...deleteModal,
            buttonLoading: true,
        })
        const result = await dispatch(
            teamMemberAddRemove({
                id: userProfile?.activeProject?._id,
                member: {
                    fieldName: 'remove',
                    teamMembers: [deleteModal?.id],
                },
            })
        )
        const data = result?.payload?.data
        if (data) {
            const { success, message: checkMessage } = data
            if (success) {
                message.success(checkMessage)
                setDeleteModal({
                    buttonLoading: false,
                    modalOpen: false,
                    id: '',
                })
                initialTeamCall('')
            } else {
                setDeleteModal({
                    buttonLoading: false,
                    modalOpen: false,
                    id: '',
                })
                if (checkMessage) {
                    message.error(checkMessage)
                } else {
                    message.error('Something went wrong, try again later.')
                }
            }
        }
    }

    // FETCH MORE TEAM
    async function fetchMoreTeam() {
        if (isNext) {
            await dispatch(
                teamListGet({
                    skip: skipPage + pageLimit,
                    limit: pageLimit,
                    projectId: userProfile?.activeProject?._id,
                })
            )
            dispatch(
                changePage({
                    currentPage: currentPage + 1,
                    skip: skipPage + pageLimit,
                    pageLimit: pageLimit,
                })
            )
        }
    }

    const filterType = [
        {
            key: 'verification',
            label: 'Verification',
            children: [
                {
                    key: 'verified',
                    label: 'Yes',
                    name: 'isOurUser',
                    flag: true,
                    icon: (
                        <div
                            style={{
                                height: '20px',
                                width: '20px',
                            }}
                        >
                            <VerifyTalentIcon />
                        </div>
                    ),
                },
                {
                    key: 'nonVerified',
                    label: 'No',
                    name: 'isOurUser',
                    flag: false,
                    icon: (
                        <div
                            style={{
                                height: '20px',
                                width: '20px',
                            }}
                        >
                            <NonVerifyTalentIcon />
                        </div>
                    ),
                },
            ],
        },
        {
            key: 'status',
            label: 'Status',
            children: [
                {
                    label: 'Active',
                    name: 'isActive',
                    key: 'active',
                    flag: true,
                    icon: (
                        <div
                            style={{
                                height: '20px',
                                width: '20px',
                            }}
                        >
                            <ActiveIcon />
                        </div>
                    ),
                },
                {
                    label: 'InActive',
                    name: 'isActive',
                    key: 'inActive',
                    flag: false,
                    icon: (
                        <div
                            style={{
                                height: '20px',
                                width: '20px',
                            }}
                        >
                            <InActiveIcon />
                        </div>
                    ),
                },
            ],
        },
        {
            label: 'Clear Filter',
            name: 'clear',
        },
    ]

    const deleteModalSet = (team) => {
        setDeleteModal({
            ...deleteModal,
            modalOpen: true,
            id: team?._id,
        })
    }

    const columns = [
        {
            title: 'Name',
            key: 'name',
            dataIndex: 'name',
            render: (val, item) => (
                <Row
                    align={'middle'}
                    style={{
                        columnGap: '16px',
                        flexWrap: 'nowrap',
                    }}
                >
                    {item?.profilePicture ? (
                        <Image
                            src={item?.profilePicture}
                            height={'30px'}
                            preview={false}
                            width={'30px'}
                            style={{
                                borderRadius: '50%',
                                objectFit: 'cover',
                            }}
                        />
                    ) : (
                        <Avatar
                            style={{
                                cursor: 'pointer',
                                backgroundColor: 'rgb(0 10 129 / 25%)',
                            }}
                        >
                            {item?.name[0].toUpperCase()}
                            {item?.name.split(' ')[1] !== undefined &&
                            item?.name.split(' ')[1] !== ''
                                ? item?.name.split(' ')[1][0].toUpperCase()
                                : ''}
                        </Avatar>
                    )}
                    <Text
                        style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        {val}
                    </Text>
                </Row>
            ),
        },
        {
            key: 'designation',
            dataIndex: 'designation',
            title: 'Designation',
            render: (designation) => (
                <Row style={{ paddingRight: 5, textTransform: 'capitalize' }}>
                    {designation?.name || 'NA'}
                </Row>
            ),
        },
        {
            key: 'email',
            dataIndex: 'email',
            title: 'Mail Id',
            render: (val) => (
                <div style={{ fontWeight: 500 }}>{val || 'NA'}</div>
            ),
        },
        {
            key: 'isActive',
            dataIndex: 'isActive',
            title: 'Status',
            render: (item) => (
                <Badge
                    count={item ? 'Active' : 'InActive'}
                    color={
                        item
                            ? 'hsla(155, 54%, 46%, 0.16)'
                            : 'hsla(11, 100%, 59%, 0.16)'
                    }
                    style={{
                        minWidth: '30px',
                        cursor: 'auto',
                        borderRadius: '6px',
                        display: 'flex',
                        alignItems: 'center',
                        color: item
                            ? token.colorPalette.iconColor.tertiary
                            : token.colorPalette.iconColor.quaternary,
                        padding: '12px 8px',
                        fontWeight: 700,
                    }}
                />
            ),
        },
        {
            key: 'context',
            dataIndex: 'context',
            title: '  ',
            render: (_, row) =>
                deletePermissionCheck('teams', userProfile) && (
                    <Row justify="end" style={{ paddingRight: 5 }}>
                        <Dropdown
                            trigger={['click']}
                            placement={'bottomRight'}
                            menu={{
                                items: [
                                    {
                                        key: '1',
                                        label:
                                            userProfile?._id !== row?._id ? (
                                                <Row
                                                    align={'middle'}
                                                    style={{
                                                        columnGap: '10px',
                                                    }}
                                                >
                                                    <DeleteIcon />
                                                    <Text>Remove</Text>
                                                </Row>
                                            ) : (
                                                <Tooltip title="You will not be able to self remove">
                                                    <Row
                                                        align={'middle'}
                                                        style={{
                                                            columnGap: '10px',
                                                        }}
                                                    >
                                                        <DeleteIcon />
                                                        <Text>Remove</Text>
                                                    </Row>
                                                </Tooltip>
                                            ),
                                        onClick: () => {
                                            if (userProfile?._id !== row?._id)
                                                setDeleteModal({
                                                    ...deleteModal,
                                                    modalOpen: true,
                                                    id: row?._id,
                                                })
                                        },
                                    },
                                ],
                            }}
                        >
                            <EllipsisOutlined rotate={90} />
                        </Dropdown>
                    </Row>
                ),
        },
    ]

    const GridViewComponent = () => (
        <GridViewButton
            props={{
                onClickButton: () => initialTeamCall(search),
                viewList: teamView,
                setView: (key) => setTeamView(key),
            }}
        />
    )

    return (
        <>
            <div
                className={'container'}
                style={{
                    zIndex: 10,
                    minHeight: '100vh',
                    padding: '35px',
                }}
            >
                <>
                    <PanelHeader
                        props={{
                            panelName: 'add member',
                            searchBar: true,
                            filterBar: false,
                            teamMembersBar: false,
                            gridView: true,
                            gridViewComponent: () => <GridViewComponent />,
                            permissionAdd: addPermissionCheck(
                                'teams',
                                userProfile
                            ),
                            filterType,
                            action: teamListGet,
                            count: totalTeam,
                            changeFilter: filterChange,
                            search: searchTeam,
                            clearFilter: clearFilter,
                            userProfile: userProfile,
                            pageLimit: pageLimit,
                            filterValue: filter,
                            refreshPage: refreshPage,
                            onClick: () => {
                                setModalVisibility(true)
                            },
                        }}
                    />
                    <Spin spinning={status === 'loading' ? true : false}>
                        {teamList?.length > 0 ? (
                            teamView === 'list' ? (
                                <Table
                                    dataSource={teamList}
                                    scroll={{
                                        x: 'scroll',
                                    }}
                                    rootClassName="tableGlobal"
                                    style={{
                                        borderRadius: '16px',
                                    }}
                                    columns={columns}
                                    pagination={false}
                                    // rowSelection={rowSelection}
                                />
                            ) : (
                                <InfiniteScroll
                                    dataLength={
                                        filteredTeamData?.length > 0
                                            ? filteredTeamData?.length
                                            : 0
                                    }
                                    next={fetchMoreTeam}
                                    hasMore={isNext}
                                    style={{
                                        overflow: 'inherit',
                                    }}
                                >
                                    <Row gutter={[20, 20]}>
                                        {filteredTeamData?.length > 0 &&
                                            filteredTeamData.map((team) => (
                                                <Col
                                                    key={team?._id}
                                                    xs={24}
                                                    sm={24}
                                                    md={24}
                                                    lg={12}
                                                    xl={8}
                                                    xxl={8}
                                                >
                                                    <TeamGrid
                                                        props={{
                                                            team: team,
                                                            deleteModal:
                                                                deleteModal,
                                                            setDeleteModal:
                                                                () =>
                                                                    deleteModalSet(
                                                                        team
                                                                    ),
                                                        }}
                                                        team={team}
                                                    />
                                                </Col>
                                            ))}
                                    </Row>
                                </InfiniteScroll>
                            )
                        ) : (
                            status !== 'loading' && (
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        width: '100%',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        marginTop: '120px',
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                        }}
                                    >
                                        <div
                                            style={{
                                                width: '400px',
                                            }}
                                        >
                                            <NoTeamIcon />
                                        </div>
                                    </div>
                                    <Text
                                        className="titleSecondary"
                                        style={{
                                            marginTop: '20px',
                                        }}
                                    >
                                        No Team Member Found yet.
                                    </Text>
                                </div>
                            )
                        )}
                        {teamList?.length > 0 && teamView === 'list' && (
                            <Row
                                justify={'end'}
                                align={'middle'}
                                style={{
                                    border: `2px solid ${token.colorPalette.baseColor.quaternary}`,
                                    padding: '25px',
                                    borderRadius: '0px 0px 8px 8px',
                                }}
                            >
                                <Text>
                                    {teamList?.length} - {pageLimit} of{' '}
                                    {dataCount}
                                </Text>
                                <Pagination
                                    defaultCurrent={currentPage}
                                    defaultPageSize={pageLimit}
                                    pageSizeOptions={[5, 10, 20, 50]}
                                    showSizeChanger={true}
                                    total={
                                        isNext === false && currentPage === 1
                                            ? teamList?.length
                                            : dataCount
                                    }
                                    onChange={async (value, pageSize) => {
                                        await dispatch(
                                            changePage({
                                                currentPage: value,
                                                skip: (value - 1) * pageLimit,
                                                pageLimit: pageSize,
                                            })
                                        )
                                        await dispatch(
                                            teamListGet({
                                                skip: (value - 1) * pageSize,
                                                limit: pageSize,
                                                projectId:
                                                    userProfile?.activeProject
                                                        ?._id,
                                            })
                                        )
                                    }}
                                />
                            </Row>
                        )}
                    </Spin>
                </>
            </div>
            <TeamModalContent
                onCancel={() => setModalVisibility(false)}
                visibility={modalVisibility}
            />
            <DeletePopUp
                previewDelete={deleteModal?.modalOpen}
                onDelete={() => {
                    removeMember()
                }}
                loadingButton={deleteModal?.buttonLoading}
                onCancel={() => {
                    setDeleteModal({
                        modalOpen: false,
                        id: '',
                        buttonLoading: false,
                    })
                }}
                keyModal={'teamMember'}
                pageKey={'Team Member'}
                modalTitle={`Remove Member`}
            />
        </>
    )
}

export default TeamMember
