import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
    createFeed,
    deleteFeed,
    getFeed,
    getIndividualFeed,
} from './home.service'

const initialState = {
    status: 'loaded',
    feeds: [],
    projectStandFeeds: [],
    filterDropDown: {
        start: '',
        end: '',
    },
    isNextFeed: false,
    previewVideo: false,
    previewModal: false,
    selectedFeed: undefined,
    pageLimitFeed: 10,
    currentPageFeed: 1,
    skipPageFeed: 0,
    countFeed: 0,
    recordings: {
        face: '',
        screen: '',
    },
    files: [],
}

export const feedList = createAsyncThunk(
    'feedSlice/getFeed',
    async (payload) => {
        const response = await getFeed(payload)
        return response
    }
)
export const feedCreate = createAsyncThunk(
    'feedSlice/createFeed',
    async (payload) => {
        const response = await createFeed(payload)
        return response
    }
)
export const feedDelete = createAsyncThunk(
    'feedSlice/deleteFeed',
    async (payload) => {
        const response = await deleteFeed(payload)
        return response
    }
)
export const feedIndividual = createAsyncThunk(
    'feedSlice/getIndividualFeed',
    async (payload) => {
        const response = await getIndividualFeed(payload)
        return response
    }
)
let object = {}

export const feedSlice = createSlice({
    name: 'feedSlice',
    initialState,
    reducers: {
        changePage: (state, action) => {
            state.currentPageFeed = action.payload.currentPage
            state.skipPageFeed = action.payload.skip
            state.pageLimitFeed = action.payload.pageLimit
            state.recordings = initialState.recordings
        },
        previewVideoModal: (state, action) => {
            state.previewVideo = action.payload
        },
        filterDropdown: (state, action) => {
            const dropDownValue = {
                ...state.filterDropDown,
                ...action.payload,
            }
            Object.keys(dropDownValue).forEach((key) => {
                if (dropDownValue[key] === '') {
                    delete dropDownValue[key]
                }
            })
            state.filterDropDown = dropDownValue
        },
        selectFeedGet: (state, action) => {
            state.previewModal = state.previewModal ? false : true
            if (state.previewModal === false) {
                state.selectedFeed = undefined
            } else {
                state.selectedFeed = action.payload.data
                if (action.payload.name === 'record') {
                    state.recordings = action.payload.data.recordings
                } else {
                    state.files = action.payload.data.files
                }
            }
        },
        onClickPreview: (state, action) => {
            state.previewModal = state.previewModal ? false : true
            state.recordings = action.payload
            state.files = []
        },
        setRecordings: (state, action) => {
            if (action.payload.includes('screen')) {
                object = {
                    ...object,
                    screen: action.payload,
                }
            }
            if (action.payload.includes('video')) {
                object = {
                    ...object,
                    face: action.payload,
                }
            }
            state.recordings = {
                ...object,
            }
            localStorage.setItem('videoShow', JSON.stringify(object))
            // state.recordings = action.payload
        },
        clearRecordings: (state) => {
            if (
                JSON.parse(localStorage.getItem('videoShow'))?.screen !==
                    state.recordings.screen &&
                JSON.parse(localStorage.getItem('videoShow'))?.face !==
                    state.recordings.face
            ) {
                state.recordings = {
                    face: '',
                    screen: '',
                }
                localStorage.setItem(
                    'videoShow',
                    JSON.stringify({ face: '', screen: '' })
                )
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(feedList.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(feedList.fulfilled, (state, action) => {
                state.status = 'loaded'
                state.feeds = action.payload.data.data
                state.projectStandFeeds = action.payload.data
                state.isNextFeed = action.payload.data.isNext
                state.countFeed = action.payload.data.count
            })
    },
})

export default feedSlice.reducer

export const feedState = (state) => {
    const { feedSlice } = state
    const {
        status,
        feeds,
        isNextFeed,
        pageLimitFeed,
        currentPageFeed,
        skipPageFeed,
        filterDropDown,
        recordings,
        previewModal,
        previewVideo,
        countFeed,
        selectedFeed,
        files,
    } = feedSlice
    return {
        status,
        feeds,
        isNextFeed,
        pageLimitFeed,
        currentPageFeed,
        skipPageFeed,
        filterDropDown,
        recordings,
        previewModal,
        previewVideo,
        countFeed,
        selectedFeed,
        files,
    }
}

export const {
    changePage,
    filterDropdown,
    clearRecordings,
    setRecordings,
    previewVideoModal,
    onClickPreview,
    selectFeedGet,
} = feedSlice.actions
