import React from 'react'

function EditRoleIcon({ fill = "#4F46E5" }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            fill="none"
            viewBox="0 0 25 25"
        >
            <path
                fill={fill}
                d="M17.947 1.745l-9.333 9.333.01 5.309 5.297-.01L23.25 7.05v15.947c0 .69-.56 1.25-1.25 1.25H2c-.69 0-1.25-.56-1.25-1.25v-20c0-.69.56-1.25 1.25-1.25h15.947zm4.66-1.124l1.767 1.768-11.49 11.49-1.765.003-.003-1.77L22.606.622z"
            ></path>
        </svg>
    )
}

export default EditRoleIcon
