import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { GoogleLogin } from '@react-oauth/google'

// antd
import { Divider, Row, Space, Typography, message } from 'antd'

// slices
import {
    loginGoogleSSO,
    loginState,
    setEmailDisable,
    setUserProfile,
} from './login.slice'

// assets
// import { GoogleIcon } from '../../assets/icons'

// constants
import { PUBLIC_ROUTES } from '../../routes'

const SocialButtonLogin = () => {
    const { Text } = Typography

    const dispatch = useDispatch()

    const { userProfile } = useSelector(loginState)

    const navigate = useNavigate()

    // const socialButtons = [
    //     {
    //         name: 'google',
    //         link: `${process.env.REACT_APP_URL}/auth/google`,
    //         icon: <GoogleIcon />,
    //     },
    //     {
    //         name: 'linkedIn',
    //         link: `${process.env.REACT_APP_URL}/auth/linkedIn`,
    //         icon: <LinkedInIcon />,
    //     },
    // ]

    const handleSignInWithGoogle = async (response) => {
        try {
            // setLoading(true)
            if (response?.credential) {
                const result = await dispatch(
                    loginGoogleSSO({
                        id_token: response.credential,
                    })
                )

                const data = result?.payload?.data
                if (data) {
                    const { success, message: loginMessage } = data
                    if (success) {
                        if (!data.hasOwnProperty('error')) {
                            const link = document.createElement('a')

                            link.href = `timetrackingapp2://response=${encodeURIComponent(
                                response.credential
                            )}`
                            document.body.appendChild(link)
                            link.click()
                        }
                        message.success(loginMessage)
                        if (data?.data?.phone) {
                            window.location.reload()
                        } else {
                            dispatch(
                                setUserProfile({
                                    ...userProfile,
                                    name: data?.data?.name,
                                    email: data?.data?.email,
                                })
                            )
                            dispatch(setEmailDisable(true))
                            navigate(PUBLIC_ROUTES.signUp)
                        }
                    } else {
                        message.error(loginMessage)
                    }
                }
            }
        } catch (error) {
            message.error(error)
        }
    }

    const handleErrorWithGoogle = () => {}

    return (
        <>
            <div
                style={{
                    marginTop: '35px',
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <Divider>
                    <Text className="titleSub">Or log in using</Text>
                </Divider>
            </div>
            <Row justify={'center'}>
                <Space size={'large'}>
                    {/* {socialButtons?.length > 0 &&
                        socialButtons.map((data) => (
                            <div
                                key={data?.name}
                                style={{
                                    backgroundColor: '#f7f7f7',
                                    padding: '19px 50px',
                                    borderRadius: '4px',
                                    cursor: 'pointer',
                                }}
                                onClick={() => window.open(data?.link, '_self')}
                            >
                                <div>{data?.icon}</div>
                            </div>
                        ))} */}
                    <GoogleLogin
                        onSuccess={handleSignInWithGoogle}
                        onError={handleErrorWithGoogle}
                    />
                </Space>
            </Row>
        </>
    )
}

export default SocialButtonLogin
