import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import debounce from 'lodash/debounce'
import S3FileUpload from 'react-s3'

// antd
import {
    Col,
    Form,
    Input,
    Modal,
    Popover,
    Row,
    Space,
    Spin,
    Tooltip,
    Typography,
    Upload,
    message,
    theme,
} from 'antd'
import { SearchOutlined, PlusOutlined, InboxOutlined } from '@ant-design/icons'

// components
import Button from '../../components/button/button'

// assets
import {
    ActiveIcon,
    CompanyIcon,
    DegreeIcon,
    DeleteIcon,
    DesignationIcon,
    EmploymentTypeIcon,
    FieldOfStudyIcon,
    FilterIcon,
    ImportIcon,
    InActiveIcon,
    InstituteIcon,
    ProviderIcon,
    SkillsMasterDataIcon,
    ToolsMasterDataIcon,
} from '../../assets/icons'

import styles from './masterData.module.css'

// slices
import {
    masterDataAdd,
    masterDataDelete,
    masterDataListCount,
    masterDataListGet,
    updateBulkData,
} from './masterDataPanel.slice'
import { loginState } from '../login/login.slice'

// modals
import DeleteModalMasterData from './deleteModalMasterData'

import dayjs from 'dayjs'

const MasterDataHeader = ({ props = {} }) => {
    const { Title, Text } = Typography

    const { Dragger } = Upload

    const { useToken } = theme
    const { token } = useToken()

    const { userProfile } = useSelector(loginState)

    const filterType = [
        {
            label: 'Active',
            name: 'active',
            flag: true,
            icon: <ActiveIcon />,
        },
        {
            label: 'InActive',
            name: 'inActive',
            flag: false,
            icon: <InActiveIcon />,
        },
        {
            label: 'Clear Filter',
            name: 'clear',
        },
    ]

    const initialState = {
        previewModal: false,
        replaceId: '',
        loadingButton: false,
        uploadFileModal: false,
        uploadLoadingButton: false,
        fileList: [],
        stream: false,
        fileURL: [],
    }

    const [state, setState] = useState(initialState)

    const {
        count,
        search,
        pageLimit,
        skipPage,
        filterDropDown,
        onClickButton,
        selectedRowKeys,
        modelType,
        searchValue,
        setSelectedRow,
    } = props

    const dispatch = useDispatch()

    const params = useLocation()

    // IMPORT FILE UPLOAD PROPS
    const propsFile = {
        name: 'files',
        multiple: false,
        onChange(info) {
            let fileUpload = []
            if (info?.file?.status !== 'removed') {
                setState((prevState) => ({ ...prevState, stream: true }))
                info?.fileList?.length > 0 &&
                    info?.fileList.forEach((data) => {
                        const nameChange = data?.name
                            .split('.')[0]
                            .concat(`_${dayjs(new Date()).unix()}`)
                            .concat(`.${data?.name.split('.')[1]}`)

                        const newFileData = new File(
                            [data?.originFileObj],
                            nameChange,
                            { type: data?.type }
                        )
                        window.Buffer =
                            window.Buffer || require('buffer').Buffer

                        S3FileUpload.uploadFile(
                            newFileData,
                            userProfile?.s3Config
                        )
                            .then(async (data) => {
                                if (
                                    info?.fileList?.length ===
                                    fileUpload?.length + 1
                                ) {
                                    setState((prevState) => ({
                                        ...prevState,
                                        stream: false,
                                    }))
                                }
                                fileUpload.push(data?.location)
                            })
                            .catch((err) => {
                                message.error(
                                    'Upload Failed!. Please Upload again'
                                )
                            })
                    })
            }

            if (info?.file?.status === 'removed') {
            }
            setState((prevState) => ({ ...prevState, fileURL: fileUpload }))
        },
        beforeUpload(file) {
            setState((prevState) => ({
                ...prevState,
                fileList: [...prevState?.fileList, file],
            }))
            return false
        },
        onDrop(e) {},
    }

    // SEARCH MASTER DATA
    const searchFn = debounce((value) => {
        // dispatch(clear())
        setSelectedRow()
        dispatch(
            count({
                _id: modelType?.name,
            })
        )
        dispatch(search(value))

        let dropDownValue = {
            ...filterDropDown,
        }
        Object.keys(dropDownValue).forEach((key) => {
            if (dropDownValue[key] === '') {
                delete dropDownValue[key]
            }
        })
        dispatch(
            masterDataListGet({
                search: value,
                limit: pageLimit,
                skip: skipPage,
                filter: dropDownValue,
                id: modelType?.name,
            })
        )
    }, 800)

    // CHANGE FILTER
    function filterChange(flag) {
        dispatch(
            masterDataListGet({
                search: searchValue,
                limit: pageLimit,
                skip: skipPage,
                filter: {
                    isActive: flag,
                },
                id: modelType?.name,
            })
        )
        dispatch(
            count({
                _id: modelType?.name,
                filter: {
                    isActive: flag,
                },
            })
        )
    }

    // ON IMPORT FILE
    const onFileUpload = async () => {
        setState((prevState) => ({ ...prevState, uploadLoadingButton: true }))
        const result = await dispatch(
            masterDataAdd({
                addData: {
                    data: {
                        fileLink:
                            state?.fileURL?.length > 0 ? state?.fileURL[0] : '',
                        createdBy: userProfile?._id,
                    },
                    type: 'csv',
                },
                model: modelType?.name,
            })
        )
        const data = result?.payload?.data
        if (data) {
            const { success, message: checkMessage } = data
            if (success) {
                setState((prevState) => ({
                    ...prevState,
                    uploadLoadingButton: false,
                    uploadFileModal: false,
                }))

                message.success(checkMessage)
                dispatch(
                    masterDataListCount({
                        _id:
                            modelType?.name?.length > 0
                                ? modelType?.name
                                : params.pathname.split('/')[3],
                    })
                )
                dispatch(
                    masterDataListGet({
                        limit: pageLimit,
                        skip: skipPage,
                        search: search,
                        id:
                            modelType?.name?.length > 0
                                ? modelType?.name
                                : params.pathname.split('/')[3],
                    })
                )
            } else {
                setState((prevState) => ({
                    ...prevState,
                    uploadLoadingButton: false,
                    uploadFileModal: false,
                }))

                if (checkMessage) {
                    message.error(checkMessage)
                } else {
                    message.error('Something went wrong')
                }
            }
        }
    }

    // ON FINISH FAILED
    const onFinishFailed = () => {}

    // ON DELETE MASTER DATA
    async function onDeleteMasterData() {
        setState((prevState) => ({ ...prevState, loadingButton: true }))
        const result = await dispatch(
            masterDataDelete({
                deletedData: {
                    id: selectedRowKeys.map((data) => data?._id),
                    replaceBy: state?.replaceId,
                },
                model: modelType?.name,
            })
        )
        const data = result?.payload?.data
        if (data) {
            const { success, message: checkMessage } = data
            if (success) {
                dispatch(
                    masterDataListCount({
                        _id:
                            modelType?.name?.length > 0
                                ? modelType?.name
                                : params.pathname.split('/')[3],
                    })
                )
                dispatch(
                    masterDataListGet({
                        limit: pageLimit,
                        skip: skipPage,
                        search: search,
                        id:
                            modelType?.name?.length > 0
                                ? modelType?.name
                                : params.pathname.split('/')[3],
                    })
                )
                setState((prevState) => ({
                    ...prevState,
                    loadingButton: false,
                    previewModal: false,
                }))
                setSelectedRow()
                message.success(checkMessage)
            } else {
                setSelectedRow()
                setState((prevState) => ({
                    ...prevState,
                    loadingButton: false,
                    previewModal: false,
                }))
                if (checkMessage) {
                    message.error(checkMessage)
                } else {
                    message.error('Something went wrong, try again later.')
                }
            }
        }
    }

    // CLEAR FILTER
    function clearFilter() {
        dispatch(
            masterDataListGet({
                search: searchValue,
                limit: pageLimit,
                skip: 0,
                id: modelType?.name,
            })
        )
        dispatch(
            count({
                _id: modelType?.name,
            })
        )
    }

    // UPDATE STATUS OF ACTIVE AND INACTIVE
    async function updateStatus(flag) {
        const result = await dispatch(
            updateBulkData({
                editData: {
                    id: selectedRowKeys.map((data) => data?._id),
                    updateData: {
                        isActive: flag,
                    },
                    key: 'Status',
                },
                model: modelType?.name,
            })
        )
        const data = result?.payload?.data
        if (data) {
            const { success, message: checkMessage } = data
            if (success) {
                dispatch(
                    masterDataListCount({
                        _id: modelType?.name,
                        // filter: { isActive: filter.isActive },
                    })
                )
                dispatch(
                    masterDataListGet({
                        limit: pageLimit,
                        skip: skipPage,
                        search: search,
                        id: modelType?.name,
                    })
                )
                message.success(checkMessage)
                setSelectedRow()
            } else {
                setSelectedRow()
                if (checkMessage) {
                    message.error(checkMessage)
                } else {
                    message.error('Something went wrong, try again later.')
                }
            }
        }
    }

    // FILTER POPOVER CONTENT
    const filterContent = () => {
        return (
            <>
                {filterType?.length > 0 &&
                    filterType.map((data) => (
                        <>
                            <Row
                                className={styles.filterContent}
                                style={{
                                    cursor: 'pointer',
                                    padding: 10,
                                    borderRadius: 5,
                                }}
                                onClick={() => {
                                    setSelectedRow()
                                    if (data?.name === 'clear') {
                                        clearFilter()
                                    } else {
                                        filterChange(data?.flag)
                                    }
                                }}
                            >
                                <Space size="small">
                                    <div
                                        style={{
                                            width: '22px',
                                            height: '22px',
                                        }}
                                    >
                                        {data?.icon}
                                    </div>
                                    <Text
                                        style={{
                                            margin: 0,
                                        }}
                                    >
                                        {data?.label}
                                    </Text>
                                </Space>
                            </Row>
                        </>
                    ))}
            </>
        )
    }

    // ICON RENDER TYPE WISE
    function checkHeaderIcon(type) {
        switch (type) {
            case 'institute':
                return (
                    <InstituteIcon
                        color={token.colorPalette.baseColor.tertiary}
                    />
                )

            case 'degree':
                return <DegreeIcon color={token.colorPalette.baseColor.white} />

            case 'fieldOfStudy':
                return (
                    <FieldOfStudyIcon
                        color={token.colorPalette.baseColor.white}
                    />
                )

            case 'skills':
                return (
                    <SkillsMasterDataIcon
                        color={token.colorPalette.baseColor.white}
                    />
                )

            case 'tools':
                return (
                    <ToolsMasterDataIcon
                        color={token.colorPalette.baseColor.white}
                    />
                )

            case 'certificateProvider':
                return (
                    <ProviderIcon color={token.colorPalette.baseColor.white} />
                )

            case 'company':
                return (
                    <CompanyIcon color={token.colorPalette.baseColor.white} />
                )
            case 'employmentType':
                return (
                    <EmploymentTypeIcon
                        color={token.colorPalette.baseColor.white}
                    />
                )
            case 'designation':
                return (
                    <DesignationIcon
                        color={token.colorPalette.baseColor.white}
                    />
                )

            default:
                break
        }
    }

    return (
        <>
            <Row
                align={'middle'}
                justify={'space-between'}
                style={{
                    marginBottom: '37px',
                }}
            >
                <Col span={4}>
                    <Row style={{ columnGap: '13px' }} align={'middle'}>
                        <div
                            style={{
                                height: '22px',
                                width: '22px',
                            }}
                        >
                            {/* <InstituteIcon /> */}
                            {checkHeaderIcon(modelType?.name)}
                        </div>
                        <Title
                            level={3}
                            style={{
                                textTransform: 'capitalize',
                            }}
                        >
                            {modelType?.name}
                        </Title>
                    </Row>
                </Col>
                <Col span={20}>
                    <Row
                        align={'middle'}
                        justify={'end'}
                        style={{
                            columnGap: '20px',
                        }}
                    >
                        {selectedRowKeys?.length > 0 && (
                            <>
                                {/* <Col span={1}> */}
                                <div
                                    style={{
                                        cursor: 'pointer',
                                        height: '24px',
                                        width: '24px',
                                    }}
                                    onClick={() => {
                                        setState((prevState) => ({
                                            ...prevState,
                                            previewModal: true,
                                        }))
                                    }}
                                >
                                    <DeleteIcon />
                                </div>
                                {/* </Col> */}
                                <>
                                    {selectedRowKeys.some(
                                        (data) => data?.isActive
                                    ) && (
                                        // <Col span={2}>
                                        <div
                                            style={{
                                                cursor: 'pointer',
                                                borderRadius: '6px',
                                                border: `1px solid ${token.colorPalette.iconColor.quaternary}`,
                                                color: token.colorPalette
                                                    .iconColor.quaternary,
                                                padding: '5px 11px',
                                                fontWeight: 700,
                                            }}
                                            onClick={() => updateStatus(false)}
                                        >
                                            <Text
                                                style={{
                                                    color: token.colorPalette
                                                        .iconColor.quaternary,
                                                }}
                                            >
                                                InActive
                                            </Text>
                                        </div>
                                        // </Col>
                                    )}
                                    {selectedRowKeys.some(
                                        (data) => !data?.isActive
                                    ) && (
                                        // <Col span={2}>
                                        <div
                                            style={{
                                                cursor: 'pointer',
                                                border: `1px solid ${token.colorPalette.iconColor.tertiary}`,
                                                borderRadius: '6px',

                                                color: token.colorPalette
                                                    .iconColor.tertiary,
                                                padding: '5px 11px',
                                                fontWeight: 700,
                                            }}
                                            onClick={() => updateStatus(true)}
                                        >
                                            <Text
                                                style={{
                                                    color: token.colorPalette
                                                        .iconColor.tertiary,
                                                }}
                                            >
                                                Active
                                            </Text>
                                        </div>
                                        // </Col>
                                    )}
                                </>
                            </>
                        )}
                        <Col span={1}>
                            <Tooltip title="Import CSV">
                                <div
                                    style={{
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                        setState((prevState) => ({
                                            ...prevState,
                                            uploadFileModal: true,
                                        }))
                                    }}
                                >
                                    <ImportIcon />
                                </div>
                            </Tooltip>
                        </Col>
                        <Popover
                            content={filterContent}
                            title=""
                            trigger="click"
                            arrow={false}
                            placement="bottomRight"
                            overlayInnerStyle={{
                                padding: '8px',
                            }}
                        >
                            <Col span={1}>
                                <Tooltip title="Filter">
                                    <div
                                        style={{
                                            cursor: 'pointer',
                                        }}
                                    >
                                        <FilterIcon />
                                    </div>
                                </Tooltip>
                            </Col>
                        </Popover>
                        <Col span={6}>
                            <Input
                                style={{
                                    height: '48px',
                                }}
                                placeholder="Search"
                                onChange={(event) =>
                                    searchFn(event.target.value)
                                }
                                prefix={
                                    <SearchOutlined
                                        style={{
                                            fontSize: 16,
                                            marginRight: 5,
                                        }}
                                    />
                                }
                            />
                        </Col>
                        <Button
                            props={{
                                text: `Add ${modelType?.name}`,
                                onClick: () => onClickButton(),
                                style: {
                                    marginLeft: '16px',
                                    textTransform: 'capitalize',
                                },
                                icon: <PlusOutlined />,
                            }}
                        />
                    </Row>
                </Col>
            </Row>
            <Modal
                // footer={null}
                closable={false}
                open={state?.previewModal}
                destroyOnClose={true}
                width={700}
                confirmLoading={state?.loadingButton}
                okText={'Delete'}
                okButtonProps={{
                    style: {
                        backgroundColor: token.colorPalette.baseColor.tertiary,
                    },
                }}
                cancelButtonProps={{
                    type: 'text',
                    style: {
                        color: token.colorPalette.baseColor.black,
                    },
                }}
                okType="primary"
                centered
                onOk={() => onDeleteMasterData()}
                onCancel={() => {
                    setState((prevState) => ({
                        ...prevState,
                        previewModal: false,
                    }))
                }}
                title={
                    <Row align={'middle'} style={{ columnGap: 10 }}>
                        <Title level={3}>Delete Master Data</Title>
                    </Row>
                }
            >
                <DeleteModalMasterData
                    modelType={modelType?.name}
                    replaceId={(id) => {
                        setState((prevState) => ({
                            ...prevState,
                            replaceId: id,
                        }))
                    }}
                />
            </Modal>
            <Modal
                footer={null}
                closable={false}
                open={state?.uploadFileModal}
                destroyOnClose={true}
                centered
                onCancel={() => {
                    setState((prevState) => ({
                        ...prevState,
                        fileList: [],
                        uploadFileModal: false,
                    }))
                }}
            >
                <Form
                    layout="vertical"
                    name="importFile"
                    onFinish={onFileUpload}
                    onFinishFailed={onFinishFailed}
                    initialValues={{
                        files: [],
                    }}
                    requiredMark={false}
                >
                    <Form.Item name="dragger" noStyle className="fileDragger">
                        <Dragger
                            {...propsFile}
                            showUploadList={{
                                showPreviewIcon: false,
                            }}
                            accept=".csv"
                        >
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">
                                Click or drag file to this area to upload
                            </p>
                            <p className="ant-upload-hint">
                                Support for a single upload.
                            </p>
                        </Dragger>
                    </Form.Item>
                    {state?.stream && (
                        <Row
                            justify={'center'}
                            style={{
                                marginTop: '10px',
                                width: '100%',
                            }}
                        >
                            <Text
                                style={{
                                    marginRight: '5px',
                                }}
                            >
                                Uploading...
                            </Text>
                            <Spin spinning={true}></Spin>
                        </Row>
                    )}
                    {state?.fileList?.length === 0 ? (
                        <></>
                    ) : (
                        <Row
                            style={{
                                marginTop: 10,
                            }}
                        >
                            <Button
                                props={{
                                    text: 'Upload',
                                    disabled:
                                        state?.stream === true ||
                                        state?.uploadLoadingButton,
                                    loadingButton: state?.uploadLoadingButton,
                                    htmlType: 'submit',
                                    style: {
                                        width: '100%',
                                    },
                                }}
                            />
                        </Row>
                    )}
                </Form>
            </Modal>
        </>
    )
}

export default MasterDataHeader
