import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

// antd
import { message, Drawer, Button } from 'antd'
import { DownloadOutlined } from '@ant-design/icons'

import dayjs from 'dayjs'

// slices
import {
    deleteSprintList,
    epicsList,
    getSprint,
    sprintSelect,
    sprintsList,
    sprintsState,
    switchFormModal,
    switchTab,
    taskSprintList,
    updateSprint,
} from './sprints.slice'
import {
    tasksState,
    switchFormModal as switchFormModalTask,
    selectTaskUpdate,
    taskById,
    switchFormModalSubTask,
} from '../tasks/tasks.slice'
import {
    projectsList,
    setGeneralSettingsData,
} from '../projects/projects.slice'
import { loginState } from '../login/login.slice'
import { teamListGet, teamState } from '../team/team.slice'

// components
import DeletePopUp from '../../components/deletePopUp/deletePopUp'
import CompleteSprintPopUp from '../../components/deletePopUp/completeSprintPopUp'
import DeleteSprintPopUp from '../../components/deletePopUp/deleteSprintPopUp'
import PanelHeader from '../../components/panelHeader/panelHeader'

// section
import FormModalSprint from './form.section'

// assets
import { SprintCreateIcon } from '../../assets/icons'

// routes
import { PRIVATE_ROUTES } from '../../routes'

// taskForm
import DrawerTaskForm from '../tasks/taskForm.index'

// helpers
import { addPermissionCheck } from '../../helpers/permissionCheck'
import { isEmpty } from '../../helpers/fieldCheck'

// sprints component
import NoActiveProject from './noActiveProject'
import SprintsTab from './sprintsTab'
import SprintsTabHeader from './sprintsTabHeader'
import SprintsTabDetail from './sprintsTabDetail'
import { downloadSprintsData } from './sprints.service'

const SprintsSection = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useLocation()

    // SELECTORS
    const {
        sprints,
        pageLimitTask,
        skipPageTask,
        selectedSprintTab,
        selectedSprintData,
        skipPageEpic,
        pageLimitEpic,
        modalVariations,
    } = useSelector(sprintsState)
    const { formModal } = useSelector(tasksState)
    const {
        teamList,
        pageLimit: teamPageLimit,
        skipPage: teamSkipPage,
    } = useSelector(teamState)
    const { userProfile } = useSelector(loginState)

    const initialState = {
        tabSprints: [],
        tabChange: false,
        filteredAssignees: [],
        activeTab: '',
        deleteSprintModal: false,
        deleteSprintPopUp: false,
        deleteButtonLoading: false,
        deleteSprintId: '',
        completeSprintModal: false,
        completedSprint: [],
        completeSprintModalLoading: false,
        activeTabName: '',
        open: false,
        startSprintLoading: false,
        isDownloadExcel: false,
    }

    // STATES

    const [state, setState] = useState(initialState)

    // VIEW TASK
    async function viewTask(taskID) {
        dispatch(
            switchFormModalTask({
                open: true,
            })
        )
        const result = await dispatch(taskById({ _id: taskID }))
        const data = result?.payload?.data
        if (data) {
            const { success } = data
            if (success) {
            } else {
                dispatch(
                    switchFormModalTask({
                        open: false,
                    })
                )
                message.error('Something went wrong, try again later.')
            }
        }
    }

    // VIEW TASK
    async function viewSubTask(taskID) {
        dispatch(switchFormModalSubTask({ open: true }))
        const result = await dispatch(taskById({ _id: taskID }))
        const data = result?.payload?.data
        if (data) {
            const { success } = data
            if (success) {
            } else {
                dispatch(switchFormModalSubTask({ open: false }))

                message.error('Something went wrong, try again later.')
            }
        }
    }

    useEffect(() => {
        if (params?.search.includes('sprintId')) {
            getSprintById(params?.search.split('sprintId=')[1])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, params])

    useEffect(() => {
        if (
            params?.search.includes('subTaskId') &&
            params.search.includes('perentId')
        ) {
            viewTask(params?.search.split('perentId=')[1])
            viewSubTask(params?.search.split('subTaskId=')[1])
        } else if (params?.search.includes('taskId')) {
            viewTask(params?.search.split('taskId=')[1])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, params])

    // useEffect(() => {
    //     if (params?.search.includes('taskId')) {
    //         viewTask(params?.search.split('taskId=')[1])
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [dispatch, params])

    // ACTIVE PROJECT DETAILS
    async function activeProjectDetails() {
        const result = await dispatch(
            projectsList({
                id: userProfile?.activeProject?._id,
            })
        )
        const data = result?.payload?.data
        if (data) {
            dispatch(setGeneralSettingsData(data))
        }
    }

    // GET SPRINTS, EPICS, USERS, PROJECTS
    useEffect(() => {
        if (
            userProfile?.activeProject &&
            !isEmpty(userProfile?.activeProject)
        ) {
            dispatch(sprintsList())
            activeProjectDetails()
            dispatch(
                epicsList({
                    skip: skipPageEpic,
                    limit: pageLimitEpic,
                })
            )
            dispatch(
                teamListGet({
                    limit: teamPageLimit,
                    skip: teamSkipPage,
                    projectId: userProfile?.activeProject?._id,
                })
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch])

    // FILTERED TEAM MEMBER DATA PROJECT WISE
    useEffect(() => {
        // TEAM MEMBER DATA SET WITH FILTER
        if (teamList?.length > 0) {
            let array = []
            teamList?.length > 0 &&
                teamList.forEach((data) => {
                    // if (userProfile?._id !== data?._id) {
                    array = [
                        ...array,
                        {
                            label: data?.name,
                            value: data?._id,
                            profilePicture: data?.profilePicture,
                        },
                    ]
                    // }
                })
            setState((prevState) => ({
                ...prevState,
                filteredAssignees: array,
            }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [teamList])

    // DELETE SPRINT
    async function deleteSprint(id, moveTo, sprintId) {
        setState({
            ...state,
            deleteButtonLoading: true,
        })
        const result =
            moveTo === 'yes'
                ? await dispatch(
                      deleteSprintList({
                          id: id,
                          move: { moveSprintId: sprintId },
                      })
                  )
                : await dispatch(deleteSprintList({ id: id }))
        const data = result?.payload?.data
        if (data) {
            const { success, message: checkMessage } = data
            if (success) {
                setState({
                    ...state,
                    deleteButtonLoading: false,
                    deleteSprintModal: false,
                    deleteSprintPopUp: false,
                    tabChange: false,
                })
                message.success(checkMessage)

                dispatch(await sprintsList())
            } else {
                setState({
                    ...state,
                    deleteButtonLoading: false,
                    deleteSprintModal: false,
                    deleteSprintPopUp: false,
                })
                if (checkMessage) {
                    message.error(checkMessage)
                } else {
                    message.error('Something went wrong, try again later.')
                }
            }
        }
    }

    // GET SPRINT BY ID
    async function getSprintById(id) {
        const result = await dispatch(getSprint({ id: id }))
        const data = result?.payload?.data
        if (data) {
            const { success, message: checkMessage } = data
            if (success) {
                dispatch(sprintSelect(data?.data))
            } else {
                if (checkMessage) {
                    message.error(checkMessage)
                } else {
                    message.error('Something went wrong, try again later.')
                }
            }
        }
    }

    // LIST TASKS SPRINT WISE
    async function listTasksSprint(id, label, sprintData, name) {
        let sprintId = id !== 'Backlog' && id
        const result = await dispatch(
            taskSprintList({
                skip: skipPageTask,
                limit: pageLimitTask,
                projectId: userProfile?.activeProject?._id,
                sprintId,
            })
        )
        const data = result?.payload?.data
        if (data) {
            if (name === 'sprint') {
                setState((prevState) => ({
                    ...prevState,
                    activeTabName: '',
                    activeTab: '',
                }))
                // setSprintTasks(data)
            } else {
                setState((prevState) => ({
                    ...prevState,
                    activeTabName: label,
                    activeTab: id,
                }))
            }
            dispatch(switchTab(sprintData))
        }
    }

    // LIST SPRINTS LIST
    useEffect(() => {
        if (sprints?.length > 0 && !state?.tabChange) {
            let tabsArray = []
            let completeSprintArray = []
            sprints
                .filter((data) => !data?.isComplete)
                .map((data) =>
                    tabsArray.push({
                        key: data?._id,
                        label: data?.title,
                    })
                )

            const backlogData = sprints
                .filter((data) => data?.title === 'Backlog')
                .map((data) => data)
            dispatch(sprintSelect(backlogData[0]))
            sprints
                .filter((data) => data?.isComplete)
                .map((data) =>
                    completeSprintArray.push({
                        label: data?.title,
                        value: data?._id,
                        end: data?.updatedAt,
                    })
                )

            setState((prevState) => ({
                ...prevState,
                tabSprints: tabsArray,
                completedSprint: completeSprintArray,
            }))
            // dispatch(switchTab(sprints[0]))
            getSprintById(tabsArray[0]?.key, 'getSprint')
            listTasksSprint(tabsArray[0]?.key, tabsArray[0]?.label, sprints[0])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sprints])

    // UPDATE SPRINT STATUS (START, COMPLETED)
    async function updateSprintStatus(id, name, sprintId) {
        const result = await dispatch(
            updateSprint({
                editSprint: {
                    isActive: name === 'moveSprint' ? false : true,
                    moveSprintId: name === 'moveSprint' && sprintId,
                },
                _id: id,
            })
        )
        const data = result?.payload?.data
        if (data) {
            const { success, message: checkMessage } = data
            if (success) {
                if (name === 'moveSprint') {
                    setState({
                        ...state,
                        startSprintLoading: false,
                        completeSprintModalLoading: false,
                        completeSprintModal: false,
                    })
                    message.success('Sprint Completed Successfully')
                    listTasksSprint(
                        state?.activeTab,
                        state?.activeTabName,
                        data?.data
                    )
                } else {
                    setState({
                        ...state,
                        startSprintLoading: false,
                        completeSprintModalLoading: false,
                    })
                    getSprintById(state?.activeTab, 'getSprint')
                    message.success('Sprint Started Successfully')
                }
                await dispatch(sprintsList())
            } else {
                setState({
                    ...state,
                    startSprintLoading: false,
                })
                if (name === 'moveSprint') {
                    setState({
                        ...state,
                        completeSprintModalLoading: false,
                        completeSprintModal: false,
                    })
                }
                if (checkMessage) {
                    message.error(checkMessage)
                } else {
                    message.error('Something went wrong, try again later.')
                }
            }
        }
    }

    const sprintRef = useRef()

    // EXPORT CSV
    const handleExportCsv = async () => {
        try {
            setState((prevState) => ({
                ...prevState,
                isDownloadExcel: true,
            }))

            const sprintId = state?.activeTab
            const response = await downloadSprintsData(sprintId)

            // Create a link element
            if (response) {
                setState((prevState) => ({
                    ...prevState,
                    isDownloadExcel: false,
                }))

                const url = window.URL.createObjectURL(
                    new Blob([response.data])
                )
                const link = document.createElement('a')
                link.href = url
                link.setAttribute(
                    'download',
                    `${userProfile?.activeProject?.name || ''}_${
                        state?.activeTabName || ''
                    }_${dayjs(new Date()).format('DMMMMYYYY')}.csv`
                ) // Name of the file to be downloaded
                document.body.appendChild(link)
                link.click()

                // Clean up and remove the link
                link.parentNode.removeChild(link)
            }
        } catch (error) {
            setState((prevState) => ({
                ...prevState,
                isDownloadExcel: false,
            }))
            message.error('Something went wrong, try again later.')
        }
    }

    return (
        <>
            <DeletePopUp
                previewDelete={state?.deleteSprintModal}
                onDelete={() => {
                    deleteSprint(state?.deleteSprintId, 'no')
                }}
                loadingButton={state?.deleteButtonLoading}
                onCancel={() => {
                    setState({
                        ...state,
                        deleteButtonLoading: false,
                        deleteSprintModal: false,
                    })
                }}
                modalTitle="Delete Sprint"
                pageKey="Sprint"
            />
            <Drawer
                placement="right"
                size={'large'}
                style={{
                    height: '100vh',
                    minWidth: '1000px',
                }}
                styles={{
                    body: {
                        padding: '0px',
                    },
                }}
                rootClassName="taskHeader"
                onClose={() => {
                    dispatch(switchFormModalTask({ open: false }))
                    if (params?.search.includes('taskId')) {
                        navigate(PRIVATE_ROUTES.tasks)
                    }
                }}
                open={formModal}
            >
                <DrawerTaskForm
                    assigneeList={state?.filteredAssignees}
                    activeTab={state?.activeTab}
                    onCancel={() => {
                        dispatch(switchFormModalTask({ open: false }))
                    }}
                    listTaskCall={(data) =>
                        listTasksSprint(
                            state?.activeTab,
                            state?.activeTabName,
                            data
                        )
                    }
                />
            </Drawer>
            <FormModalSprint
                setTabSet={() => setState({ ...state, tabChange: false })}
            />
            <div
                className={'container'}
                style={{
                    zIndex: 10,
                    minHeight: '100vh',
                    padding: '35px',
                }}
                ref={sprintRef}
                id="sprintDiv"
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        gap: '20px',
                    }}
                >
                    <PanelHeader
                        props={{
                            panelName: 'create sprint',
                            panelButtonIcon: <SprintCreateIcon />,
                            panelNameSecond: 'add task',
                            searchBar: false,
                            filterBar: false,
                            teamMembersBar: false,
                            permissionAdd: addPermissionCheck(
                                'sprints',
                                userProfile
                            ),
                            permissionAddSecond:
                                !selectedSprintTab?.isComplete &&
                                addPermissionCheck('sprints', userProfile),
                            icon: false,
                            showProject: true,
                            activeProject: userProfile,
                            onClick: () => {
                                dispatch(
                                    switchFormModal({
                                        ...modalVariations,
                                        open: true,
                                        create: true,
                                    })
                                )
                                // setUploadFileModal(true)
                            },
                            onClickSecond: () => {
                                dispatch(selectTaskUpdate(undefined))
                                dispatch(
                                    switchFormModalTask({
                                        open: true,
                                    })
                                )
                            },
                        }}
                    />
                    <div>
                        <Button
                            loading={state?.isDownloadExcel}
                            disabled={!state?.activeTab}
                            onClick={() => handleExportCsv()}
                            icon={<DownloadOutlined />}
                        >
                            Export Data
                        </Button>
                    </div>
                </div>

                <CompleteSprintPopUp
                    previewDelete={state?.completeSprintModal}
                    selectedSprint={state?.activeTabName}
                    selectedSprintId={state?.activeTab}
                    sprintsList={sprints}
                    onComplete={(sprintId) => {
                        setState({
                            ...state,
                            completeSprintModalLoading: true,
                            tabChange: false,
                        })
                        updateSprintStatus(
                            state?.activeTab,
                            'moveSprint',
                            sprintId
                        )
                    }}
                    loadingButton={state?.completeSprintModalLoading}
                    onCancel={() => {
                        setState({
                            ...state,
                            completeSprintModalLoading: false,
                            completeSprintModal: false,
                        })
                    }}
                    modalTitle="Complete Sprint"
                />
                <DeleteSprintPopUp
                    previewDelete={state?.deleteSprintPopUp}
                    selectedSprint={state?.activeTabName}
                    selectedSprintId={state?.activeTab}
                    sprintsList={sprints}
                    onComplete={(sprintId) => {
                        deleteSprint(state?.activeTab, 'yes', sprintId)
                    }}
                    loadingButton={state?.deleteButtonLoading}
                    onCancel={() => {
                        setState({
                            ...state,
                            deleteButtonLoading: false,
                            deleteSprintPopUp: false,
                        })
                    }}
                    modalTitle="Delete Sprint"
                />
                <>
                    {userProfile?.activeProject === null ? (
                        <NoActiveProject />
                    ) : (
                        <>
                            <SprintsTab
                                props={{
                                    state: state,
                                    setState: (data) => {
                                        setState(data)
                                    },
                                }}
                            />
                            <SprintsTabHeader
                                props={{
                                    data: selectedSprintTab,
                                    state: state,
                                    setState: (data) => setState(data),
                                    selectedSprint: selectedSprintData,
                                }}
                            />
                            <SprintsTabDetail
                                props={{
                                    state: state,
                                }}
                            />
                        </>
                    )}
                </>
            </div>
        </>
    )
}

export default SprintsSection
