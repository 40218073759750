import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import S3FileUpload from 'react-s3/lib/ReactS3'
import RecordRTC from 'recordrtc'

// antd
import { Modal, message } from 'antd'

// components
import Button from '../../components/button/button'

// slice
import {
    clearRecordings,
    previewVideoModal,
    setRecordings,
} from './standups.slice'
import { loginState } from '../login/login.slice'

// styles
import styles from './standUps.module.css'

const VideoRecordStandUp = ({ previewModal, onCancel, onFileUrl }) => {
    const dispatch = useDispatch()
    const [recordView, setRecordView] = useState(false)
    const [stopRecordingView, setStopRecordingView] = useState(false)
    const [videoLoading, setVideoLoading] = useState(false)
    const [screenRecordedVideo, setScreenRecordedVideo] = useState(null)
    const [webcamRecordedVideo, setWebcamRecordedVideo] = useState(null)
    const [screenStream, setScreenStream] = useState(null)
    const [mediaStream, setMediaStream] = useState(null)
    const screenRecorderRef = useRef(null)
    const webcamRecorderRef = useRef(null)
    const videoRef = useRef(null)
    const screenRef = useRef(null)

    const { userProfile } = useSelector(loginState)

    // START RECORDING
    const startRecording = async () => {
        try {
            const screenStream = await navigator.mediaDevices.getDisplayMedia({
                video: true,
                audio: true,
            })

            setScreenStream(screenStream)

            const webcamStream = await navigator.mediaDevices.getUserMedia({
                video: true,
                audio: {
                    sampleRate: 48000,
                    channelCount: 1,
                    volume: 1.0,
                    echoCancellation: true,
                    noiseSuppression: false,
                },
            })

            const screenRecorder = RecordRTC(screenStream, {
                type: 'video',
                mimeType: 'video/webm',
            })
            const webcamRecorder = RecordRTC(webcamStream, {
                type: 'video',
                mimeType: 'video/webm',
            })

            setScreenRecordedVideo(null)
            setWebcamRecordedVideo(null)
            screenRef.current.srcObject = screenStream
            videoRef.current.srcObject = webcamStream
            screenRecorderRef.current = screenRecorder
            webcamRecorderRef.current = webcamRecorder
            setMediaStream([screenStream, webcamStream])

            screenRecorder.startRecording()
            webcamRecorder.startRecording()
        } catch (error) {
            console.error('Error accessing media devices:', error)
        }
    }

    // GET CAMERA OFF
    function getCameraOff() {
        if (mediaStream) {
            mediaStream.forEach((stream) => {
                stream.getTracks().forEach((track) => track.stop())
            })
            setMediaStream(null)
        }
    }

    useEffect(() => {
        // getScreenOn()
        startRecording()
        setRecordView(true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // STOP RECORDING
    const stopRecording = () => {
        if (screenRecorderRef.current && webcamRecorderRef.current) {
            screenRecorderRef.current.stopRecording(() => {
                const screenBlob = screenRecorderRef.current.getBlob()
                setScreenRecordedVideo(screenBlob)
                screenRecorderRef.current.reset()
                screenRecorderRef.current.destroy()
                screenRecorderRef.current = null
            })

            webcamRecorderRef.current.stopRecording(() => {
                const webcamBlob = webcamRecorderRef.current.getBlob()
                setWebcamRecordedVideo(webcamBlob)
                webcamRecorderRef.current.reset()
                webcamRecorderRef.current.destroy()
                webcamRecorderRef.current = null
            })

            if (mediaStream) {
                mediaStream.forEach((stream) => {
                    stream.getTracks().forEach((track) => track.stop())
                })
                setMediaStream(null)
            }
        }
    }

    if (screenStream !== null) {
        screenStream.getVideoTracks()[0].onended = function () {
            stopRecording()
            setRecordView(false)
            setStopRecordingView(true)
        }
    }

    // BASE64 URL
    const getBase64FromUrl = async (url) => {
        const data = await fetch(url)
        const blob = await data.blob()
        return new Promise((resolve) => {
            const reader = new FileReader()
            reader.readAsDataURL(blob)
            reader.onloadend = () => {
                const base64data = reader.result
                resolve(base64data)
            }
        })
    }

    // URL TO FILE
    async function UrlToFile(url, filename, mimeType) {
        return fetch(url)
            .then(function (res) {
                return res.arrayBuffer()
            })
            .then(function (buf) {
                return new File([buf], filename, { type: mimeType })
            })
    }

    // UPLOAD RECORDING
    async function recordingUpload() {
        dispatch(previewVideoModal(true))
        const webBlob = URL.createObjectURL(webcamRecordedVideo)
        const screenBlob = URL.createObjectURL(screenRecordedVideo)
        setVideoLoading(true)
        const mediaName = 'screen'
            .concat(`_${new Date().getTime()}`)
            .concat(`.mp4`)
        const videoName = 'video'
            .concat(`_${new Date().getTime()}`)
            .concat(`.mp4`)

        const mediaVideo = await getBase64FromUrl(webBlob)
        const screenVideo = await getBase64FromUrl(screenBlob)

        window.Buffer = window.Buffer || require('buffer').Buffer

        const mediaVideoGet = await UrlToFile(
            mediaVideo,
            videoName,
            'video/mp4'
        )
        const screenVideoGet = await UrlToFile(
            screenVideo,
            mediaName,
            'video/mp4'
        )

        if (mediaVideoGet) {
            S3FileUpload.uploadFile(mediaVideoGet, userProfile?.s3Config)
                .then(async (data) => {
                    dispatch(setRecordings(data.location))

                    if (screenVideoGet) {
                        S3FileUpload.uploadFile(
                            screenVideoGet,
                            userProfile?.s3Config
                        )
                            .then(async (data) => {
                                dispatch(setRecordings(data.location))
                                dispatch(previewVideoModal(false))
                                onFileUrl()
                                setVideoLoading(false)
                                onCancel()
                                getCameraOff()
                            })
                            .catch(() => {
                                message.error(
                                    'Upload Failed!. Please Upload again'
                                )
                            })
                    }
                })
                .catch(() => {
                    message.error('Upload Failed!. Please Upload again')
                })
        }
    }

    return (
        <>
            <Modal
                open={previewModal}
                destroyOnClose={true}
                cancelButtonProps={{
                    hidden: true,
                }}
                width={800}
                footer={[
                    <Button
                        props={{
                            text: recordView
                                ? 'Stop'
                                : stopRecordingView
                                ? 'Done'
                                : 'Record',

                            loadingButton: videoLoading,
                            onClick: async () => {
                                if (recordView) {
                                    stopRecording()
                                    setRecordView(false)
                                    setStopRecordingView(true)
                                }
                                if (stopRecordingView) {
                                    recordingUpload()
                                }
                            },
                        }}
                    />,
                    stopRecordingView && (
                        <Button
                            props={{
                                text: 'Cancel',
                                className: 'textPlainButton',
                                buttonType: 'text',
                                onClick: () => {
                                    onCancel()
                                    dispatch(clearRecordings())
                                },
                            }}
                        />
                    ),
                ]}
                onCancel={() => {
                    getCameraOff()
                    onCancel()
                }}
                title="Record Video Clip"
            >
                {recordView ? (
                    <>
                        {screenRef && (
                            <video
                                ref={screenRef}
                                className="preview"
                                id="screenShare"
                                style={{
                                    position: 'relative',
                                }}
                                autoPlay
                                width="100%"
                                height="100%"
                            />
                        )}
                        {videoRef && (
                            <video
                                ref={videoRef}
                                className={styles.videoContainer}
                                width="200px"
                                height="200px"
                                autoPlay
                                style={{
                                    position: 'absolute',
                                    right: 40,
                                }}
                            />
                        )}
                    </>
                ) : stopRecordingView ? (
                    <>
                        <div>
                            {screenRecordedVideo && (
                                <video
                                    src={URL.createObjectURL(
                                        screenRecordedVideo
                                    )}
                                    style={{
                                        position: 'relative',
                                    }}
                                    loop
                                    className={styles.videoContainer}
                                    autoPlay
                                    width="100%"
                                    height="100%"
                                />
                            )}
                            {webcamRecordedVideo && (
                                <video
                                    src={URL.createObjectURL(
                                        webcamRecordedVideo
                                    )}
                                    autoPlay
                                    className={styles.videoContainer}
                                    loop
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        right: 40,
                                    }}
                                    height="200px"
                                    width="200px"
                                />
                            )}
                        </div>
                    </>
                ) : (
                    <></>
                )}
            </Modal>
        </>
    )
}

export default VideoRecordStandUp
