import React from 'react'

function NoWorkSpaceIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="229"
            height="185"
            fill="none"
            viewBox="0 0 229 185"
        >
            <path
                fill="#F1F1F1"
                d="M18.764 184.806v-34.802s-13.563 24.673 0 34.802z"
            ></path>
            <path
                fill="#F1F1F1"
                d="M17.925 184.801l25.637-23.535s-27.347 6.694-25.637 23.535z"
            ></path>
            <path
                fill="#FFB7B7"
                d="M128.68 92.878a4.374 4.374 0 00-4.74-4.75l-4.621-8.866-6.074 1.494 6.703 12.43a4.396 4.396 0 004.503 3.725 4.402 4.402 0 004.229-4.033zM101.091 179.26h5.125l2.438-19.768-7.564.001.001 19.767z"
            ></path>
            <path
                fill="#2F2E41"
                d="M99.786 184.231h16.525v-.209a6.436 6.436 0 00-6.432-6.432H99.785v6.641z"
            ></path>
            <path
                fill="#FFB7B7"
                d="M59.87 175.435l4.946 1.348 7.553-18.43-7.298-1.99-5.2 19.072z"
            ></path>
            <path
                fill="#2F2E41"
                d="M57.305 179.884l15.943 4.347.055-.201a6.433 6.433 0 00-4.513-7.898l-9.738-2.655-1.747 6.407zM110.447 93.871l2.557 38.822-5.492 43.555-8.995-2.746-.379-38.916-7.196-22.44-21.304 59.936-9.753-3.598 8.049-38.916 4.545-36.549 37.968.852z"
            ></path>
            <path
                fill="#CBCBCB"
                d="M85.593 25.703l15.148 2.234 5.457 5.397 1.694 19.39-1.052 17.177 4.387 30.289s-11.032-6.124-19.581 1.542c-8.55 7.666-20.512 1.355-20.758-3.745l14.705-72.284z"
            ></path>
            <path
                fill="#2F2E41"
                d="M93.287 50.023V50l-7.525-24.528-.097-.006c-.536-.035-13.152-.779-15.973 5.45l-.014.03.857 25.49-1.18 39.92 23.333 5.055.17.037.429-51.426zM101.625 48.32l-1.38-20.793c9.817.611 9.669 7.572 9.658 7.875l.107 31.103 1.967 33.274-7.15-.536-3.202-50.922z"
            ></path>
            <path
                fill="#2F2E41"
                d="M123.764 86.158l-8.716-20.228-6.084-11.172-3.176 20.152 11.027 16.542 6.949-5.294z"
            ></path>
            <path
                fill="#FFB7B7"
                d="M87.74 78.718a4.375 4.375 0 00-5.929-3.14l-7.032-7.11-5.362 3.22 10.071 9.9a4.4 4.4 0 008.253-2.87z"
            ></path>
            <path
                fill="#2F2E41"
                d="M81.828 72.984L67.491 57.256l9.49-13.542-.977-6.62-5.593-6.91-.11.14c-.595.772-14.606 18.975-15.008 25.866-.404 6.916 19.735 24.41 20.592 25.152l.12.103 5.823-8.46z"
            ></path>
            <path
                fill="#FFB7B7"
                d="M94.333 23.994c-5.628 0-10.19-4.563-10.19-10.19 0-5.628 4.562-10.19 10.19-10.19 5.628 0 10.19 4.562 10.19 10.19 0 5.627-4.562 10.19-10.19 10.19z"
            ></path>
            <path
                fill="#2F2E41"
                d="M84.751 5.66l-.494-.995 2.486-1.235s2.743-4.462 7.705-3.208c4.962 1.254 7.194 2.004 7.194 2.004l2.48 1.248-1.245 1.238 2.233.75-1.492.742 1.736.997-.935 5.113s-1.551-3.878-4.534-2.396c-2.983 1.482-8.44-.766-8.44-.766l-4.74 9.174s-.977-3.216-2.72-2.359c0 0-4.455-7.093.766-10.308z"
            ></path>
            <path
                fill="#4F46E5"
                d="M177.042 131.381c-28.696 0-51.958-23.263-51.958-51.958 0-28.696 23.262-51.958 51.958-51.958 28.696 0 51.958 23.262 51.958 51.958 0 28.695-23.262 51.958-51.958 51.958z"
            ></path>
            <path
                fill="#fff"
                d="M162.576 76.353v-9.944a14.354 14.354 0 014.234-10.188 14.462 14.462 0 014.694-3.124 14.527 14.527 0 0111.077 0 14.472 14.472 0 014.694 3.124 14.394 14.394 0 013.135 4.675 14.335 14.335 0 011.098 5.513v9.944a7.534 7.534 0 015.097 2.304 7.467 7.467 0 012.086 5.17v23.499h-43.298V83.828a7.467 7.467 0 012.086-5.171 7.538 7.538 0 015.097-2.304zm14.466-19.273a9.405 9.405 0 00-6.624 2.736 9.318 9.318 0 00-2.748 6.593v9.926h18.744V66.41a9.314 9.314 0 00-2.748-6.593 9.405 9.405 0 00-6.624-2.736z"
            ></path>
            <path
                fill="#4F46E5"
                d="M173.193 97.345c0-.684.171-1.357.498-1.953a3.914 3.914 0 011.364-1.448 3.752 3.752 0 013.786-.109c.587.32 1.083.791 1.441 1.367a4.072 4.072 0 01.21 3.9 3.932 3.932 0 01-1.285 1.523v8.626h-4.33v-8.626a3.933 3.933 0 01-1.237-1.424 4.06 4.06 0 01-.447-1.856z"
            ></path>
            <path
                fill="#CBCBCB"
                d="M.598 184.779h227.804a.552.552 0 00.423-.211.804.804 0 00.175-.51.806.806 0 00-.175-.511.552.552 0 00-.423-.211H.598a.552.552 0 00-.423.211.806.806 0 00-.175.511c0 .191.063.375.175.51a.552.552 0 00.423.211z"
            ></path>
        </svg>
    )
}

export default NoWorkSpaceIcon
