import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { MentionsInput, Mention } from 'react-mentions'
import S3FileUpload from 'react-s3'

// antd
import { Avatar, Row, message, Space, Typography, Modal, Image } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'

// slices
import { loginState } from '../login/login.slice'
import {
    selectTaskUpdate,
    taskById,
    tasksState,
    updateComment,
} from './tasks.slice'
import { usersState } from '../users/users.slice'
import { projectsState } from '../projects/projects.slice'

// components
import Button from '../../components/button/button'
import DeletePopUp from '../../components/deletePopUp/deletePopUp'

// section
import AttachmentSection from './attachments.section'

// helpers
import { splitLastOccurrence } from '../../helpers/fileDownload'

// styles
import styles from './tasks.module.css'

import dayjs from 'dayjs'
import { SUPER_ADMIN, WORKSPACE_ADMIN } from '../../constants/roles'
import { deletePermissionCheck } from '../../helpers/permissionCheck'

function CommentsSection() {
    const { Title, Text } = Typography

    const dispatch = useDispatch()

    const { userProfile } = useSelector(loginState)
    const { users } = useSelector(usersState)
    const { selectedProject, currentProjectData } = useSelector(projectsState)

    const { selectedTask, selectedSubTask, formSubTask } =
        useSelector(tasksState)

    const [addCommentLoader, setAddCommentLoader] = useState(false)
    const [comment, setComment] = useState()
    const [deleteModal, setDeleteModal] = useState(false)
    const [deleteButtonLoading, setDeleteButtonLoading] = useState(false)
    const [files, setFiles] = useState([])
    const [previewFileModal, setPreviewFileModal] = useState(false)
    const [imageData, setImageData] = useState('')
    const [mentionData, setMentionData] = useState({
        name: 'React',
        value: '',
        mentionData: null,
        mentionDataList: [],
        comment: '',
        users: [],
    })
    const [filteredMembers, setFilteredMembers] = useState([])
    const [commentChange, setCommentChange] = useState([])

    const relativeTime = require('dayjs/plugin/relativeTime')
    dayjs.extend(relativeTime)

    useEffect(() => {
        if (selectedSubTask && formSubTask) {
            setCommentChange(selectedSubTask?.comments)
        } else if (selectedTask) {
            setCommentChange(selectedTask?.comments)
        }
    }, [selectedTask, selectedSubTask, formSubTask])

    // ADD COMMENT
    async function addComment(files) {
        setAddCommentLoader(true)
        const result = await dispatch(
            updateComment({
                id: formSubTask ? selectedSubTask?._id : selectedTask?._id,
                data: {
                    fieldName: 'add',
                    comment: {
                        uId: userProfile?._id,
                        username: userProfile?.name,
                        createdDate: new Date().getTime(),
                        content: mentionData?.comment,
                        mentionId: mentionData?.mentionDataList,
                        attachments: files?.length > 0 ? files : [],
                    },
                },
            })
        )

        const data = result?.payload?.data
        if (data) {
            setMentionData({ ...mentionData, value: '', comment: '' })

            setFiles([])
            const { success, message: checkMessage } = data
            if (success) {
                setAddCommentLoader(false)
                message.success(checkMessage)
                const resultFinal = await dispatch(
                    taskById({
                        _id: formSubTask
                            ? selectedSubTask?._id
                            : selectedTask?._id,
                    })
                )
                const dataFinal = resultFinal?.payload?.data
                if (dataFinal) {
                    dispatch(selectTaskUpdate(dataFinal?.data[0]))
                } else {
                    message.error('Something went wrong, try again later.')
                }
            } else {
                if (checkMessage) {
                    message.error(checkMessage)
                    setAddCommentLoader(false)
                } else {
                    message.error('Something went wrong, try again later.')
                    setAddCommentLoader(false)
                }
            }
        }
    }

    // S3 UPLOAD FILE
    async function s3Upload() {
        let fileUpload = []
        let stream = true
        setAddCommentLoader(true)
        if (files?.length > 0) {
            files?.length > 0 &&
                files.forEach((data) => {
                    const nameChange = data?.name
                        .split('.')[0]
                        .concat(`_${dayjs(new Date()).unix()}`)
                        .concat(`.${data?.name.split('.')[1]}`)

                    const newFileData = new File(
                        [data?.originFileObj],
                        nameChange,
                        { type: data?.type }
                    )
                    window.Buffer = window.Buffer || require('buffer').Buffer

                    S3FileUpload.uploadFile(newFileData, userProfile?.s3Config)
                        .then(async (data) => {
                            if (files?.length === fileUpload?.length + 1) {
                                stream = false
                            }
                            fileUpload.push(data?.location)
                            if (stream === false) {
                                addComment(fileUpload)
                            }
                        })
                        .catch(() => {
                            message.error('Upload Failed!. Please Upload again')
                        })
                })
        } else {
            addComment()
        }
    }

    // DELETE COMMENT
    async function deleteComment() {
        const result = await dispatch(
            updateComment({
                id: formSubTask ? selectedSubTask?._id : selectedTask?._id,
                data: {
                    fieldName: 'remove',
                    commentId: comment?._id,
                },
            })
        )
        const data = result?.payload?.data
        if (data) {
            const { success, message: checkMessage } = data
            setComment()
            if (success) {
                message.success(checkMessage)
                setDeleteButtonLoading(false)
                setDeleteModal(false)
                const result = await dispatch(
                    taskById({
                        _id: formSubTask
                            ? selectedSubTask?._id
                            : selectedTask?._id,
                    })
                )
                const data = result?.payload?.data
                if (data) {
                    dispatch(selectTaskUpdate(data?.data[0]))
                } else {
                    message.error('Something went wrong, try again later.')
                }
            } else {
                setDeleteModal(false)
                setDeleteButtonLoading(false)
                if (checkMessage) {
                    message.error(checkMessage)
                } else {
                    message.error('Something went wrong, try again later.')
                }
            }
        }
    }

    const fileTypes = [
        '.png',
        '.jpg',
        '.jpeg',
        '.gif',
        '.svg',
        '.mp4',
        '.mov',
        '.wmv',
        '.mkv',
    ]

    const videoTypes = ['.mp4', '.mov', '.wmv', '.mkv']

    async function checkUser() {
        if (users?.length > 0 && currentProjectData?.teamMembers?.length > 0) {
            let filterUsers = []
            users.filter(
                (data) =>
                    currentProjectData?.teamMembers?.length > 0 &&
                    currentProjectData?.teamMembers.forEach((member) => {
                        if (member === data?._id) {
                            filterUsers = [
                                ...filterUsers,
                                { id: data?._id, display: `${data?.name}` },
                            ]
                        }
                    })
            )
            setFilteredMembers(filterUsers)
        } else {
            setFilteredMembers([])
        }
    }

    useEffect(() => {
        // TEAM MEMBER DATA SET WITH FILTER
        if (currentProjectData) {
            checkUser()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedProject])

    // HANDLE CHANGE MENTIONS COMMENT
    const handleChangeMentions = (_, newValue, newPlainTextValue, mentions) => {
        const mentionNew =
            mentions?.length > 0 ? mentions.map((data) => data?.id) : []

        setMentionData({
            ...mentionData,
            value: newValue,
            comment: newPlainTextValue,
            mentionDataList: [...new Set(mentionNew)],
        })
    }

    return (
        <>
            <DeletePopUp
                previewDelete={deleteModal}
                onDelete={() => {
                    setDeleteButtonLoading(true)
                    deleteComment()
                }}
                loadingButton={deleteButtonLoading}
                onCancel={() => {
                    setDeleteButtonLoading(false)
                    setDeleteModal(false)
                }}
                modalTitle="Delete Comment"
                pageKey="Comment"
            />
            <Modal
                footer={null}
                closable={false}
                open={previewFileModal}
                destroyOnClose={true}
                centered
                onCancel={() => setPreviewFileModal(false)}
            >
                {videoTypes.some((r) => imageData.includes(r)) ? (
                    <video width="100%" controls>
                        <source src={imageData} />
                    </video>
                ) : (
                    <Image
                        width="100%"
                        height="100%"
                        src={imageData}
                        preview={false}
                    />
                )}
            </Modal>
            <AttachmentSection
                getFileList={(files) => {
                    setFiles(files)
                }}
            />
            {files?.length > 0 && (
                <>
                    <Title level={5}>Attached Documents</Title>
                    {files.map((data) => (
                        <Row
                            style={{
                                backgroundColor: '#f1efef',
                                marginTop: 10,
                                padding: '5px 10px',
                                borderRadius: 20,
                            }}
                        >
                            <Text>{data?.name}</Text>
                        </Row>
                    ))}
                </>
            )}
            <Row
                style={{
                    width: '100%',
                    marginTop: 20,
                    marginBottom: 20,
                }}
            >
                <MentionsInput
                    value={mentionData?.value}
                    onChange={handleChangeMentions}
                    placeholder="Write comment. Try @mentioning people to get their attention..."
                    className="mentions"
                    allowSpaceInQuery={true}
                    style={{
                        minWidth: '100%',
                    }}
                >
                    <Mention
                        type="user"
                        trigger="@"
                        displayTransform={(_, display) => {
                            return `@${display}`
                        }}
                        appendSpaceOnAdd={true}
                        data={filteredMembers}
                        className="mentions__mention"
                    />
                </MentionsInput>
            </Row>
            <Row
                justify={'end'}
                style={{
                    columnGap: 10,
                    marginTop: 20,
                }}
            >
                <Button
                    props={{
                        text: 'Add',
                        loadingButton: addCommentLoader,
                        onClick: () => s3Upload(),
                        disabled: mentionData?.comment?.length === 0,
                    }}
                />
            </Row>
            {commentChange?.length > 0 && (
                <>
                    <Row
                        style={{
                            maxHeight: '55vh',
                            overflow: 'auto',
                        }}
                        justify={'space-between'}
                    >
                        {commentChange.map((data) => (
                            <>
                                <Row
                                    style={{
                                        minWidth: '100%',
                                    }}
                                    justify={'space-between'}
                                >
                                    <Row
                                        style={{
                                            marginTop: 20,
                                        }}
                                        align={'middle'}
                                    >
                                        <div
                                            style={{
                                                marginRight: '10px',
                                            }}
                                        >
                                            {data?.user &&
                                            data?.user?.profilePicture ? (
                                                <>
                                                    <Image
                                                        src={
                                                            data?.user
                                                                ?.profilePicture
                                                        }
                                                        height={36}
                                                        width={36}
                                                        style={{
                                                            borderRadius: '50%',
                                                        }}
                                                        preview={false}
                                                    />
                                                </>
                                            ) : (
                                                <Avatar
                                                    style={{
                                                        backgroundColor:
                                                            'rgb(0 10 129 / 25%)',
                                                        cursor: 'pointer',
                                                        height: '36px',
                                                        width: '36px',
                                                    }}
                                                >
                                                    {data?.username[0].toUpperCase()}
                                                    {data?.username.split(
                                                        ' '
                                                    )[1] !== undefined &&
                                                        data?.username
                                                            .split(' ')[1][0]
                                                            .toUpperCase()}
                                                </Avatar>
                                            )}
                                        </div>
                                        <div>
                                            <div>
                                                <Row align={'middle'}>
                                                    <Space>
                                                        <Title
                                                            level={5}
                                                            style={{
                                                                margin: 0,
                                                            }}
                                                        >
                                                            {data?.username}
                                                        </Title>
                                                        <Text
                                                            style={{
                                                                color: '#a9a4a4',
                                                                margin: 0,
                                                            }}
                                                        >
                                                            {dayjs(
                                                                data?.createdDate
                                                            ).fromNow()}
                                                        </Text>
                                                    </Space>
                                                </Row>
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: data?.content,
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </Row>
                                    {userProfile?.role?.roleName ===
                                        WORKSPACE_ADMIN ||
                                        userProfile?.role?.roleName ===
                                            SUPER_ADMIN ||
                                        data?.uId === userProfile?._id ||
                                        (deletePermissionCheck(
                                            'tasks',
                                            userProfile
                                        ) && (
                                            <Row
                                                style={{
                                                    marginTop: 20,
                                                    textDecoration: 'underline',
                                                    color: '#ff4d4f',
                                                }}
                                                onClick={() => {
                                                    setDeleteModal(true)
                                                    setComment(data)
                                                }}
                                            >
                                                <Space>
                                                    <DeleteOutlined
                                                        style={{
                                                            fontSize: '18px',
                                                        }}
                                                    />
                                                </Space>
                                            </Row>
                                        ))}
                                </Row>
                                <Row>
                                    {data?.attachments?.length > 0 && (
                                        <>
                                            <Row
                                                style={{
                                                    columnGap: 5,
                                                }}
                                            >
                                                {data?.attachments.map(
                                                    (item) => (
                                                        <Row
                                                            style={{
                                                                backgroundColor:
                                                                    '#f1efef',
                                                                marginTop: 10,
                                                                padding:
                                                                    '5px 10px',
                                                                borderRadius: 20,
                                                                cursor: 'pointer',
                                                            }}
                                                            onClick={() => {
                                                                setImageData(
                                                                    item
                                                                )
                                                                if (
                                                                    fileTypes.some(
                                                                        (r) =>
                                                                            item
                                                                                ?.toLowerCase()
                                                                                .includes(
                                                                                    r
                                                                                )
                                                                    )
                                                                ) {
                                                                    setPreviewFileModal(
                                                                        true
                                                                    )
                                                                } else {
                                                                    window.open(
                                                                        item,
                                                                        '_blank'
                                                                    )
                                                                }
                                                            }}
                                                        >
                                                            <Text
                                                                className={
                                                                    styles.textHover
                                                                }
                                                            >
                                                                {splitLastOccurrence(
                                                                    item.split(
                                                                        '.com/'
                                                                    )[1],
                                                                    '_'
                                                                )}
                                                            </Text>
                                                        </Row>
                                                    )
                                                )}
                                            </Row>
                                        </>
                                    )}
                                </Row>
                            </>
                        ))}
                    </Row>
                </>
            )}
        </>
    )
}

export default CommentsSection
